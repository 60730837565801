import { AIBots as AIBotsUnion } from './types';

export const AIBots: Record<
  | 'TranscriptTextTags'
  | 'TranscriptTextSummary'
  | 'TranscriptHighlights'
  | 'HighlightTitle'
  | 'StudyTitle'
  | 'HighlightReelTitle'
  | 'SessionTitle'
  | 'SurveyQuestions'
  | 'ExternalPanelForm'
  | 'InterviewChat'
  | 'ArtifactsChat'
  | 'StudyChat',
  AIBotsUnion
> = {
  TranscriptTextTags: 'transcript_text:tags',
  TranscriptTextSummary: 'interview:abstract',
  TranscriptHighlights: 'interview:highlights',
  HighlightTitle: 'highlight:title',
  StudyTitle: 'study:title',
  HighlightReelTitle: 'highlight_reel:title',
  SessionTitle: 'session:title',
  SurveyQuestions: 'survey:questions',
  ExternalPanelForm: 'external_candidates_form',
  StudyChat: 'study',
  InterviewChat: 'interview',
  ArtifactsChat: 'artifacts'
};
