// libs
import classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

// components
import { Heading, Text } from '@components/common';

const StepTitle: React.FC = ({ children }) => (
  <Heading as='h2' className='mb-2'>
    {children}
  </Heading>
);

const StepHelper: React.FC<{ mb?: string }> = ({ children, mb = '6' }) => (
  <Text h='500' color='gray-700' className={`block mb-${mb} font-normal`}>
    {children}
  </Text>
);

interface TabProps {
  active: boolean;
  tabName: string;
  href: string;
}

const Tab: React.FC<TabProps> = ({ active, href, children }) => {
  const createTabClass = (active: boolean) =>
    classNames('cursor-pointer text-center font-bold text-sm pb-2', {
      'text-indigo-600 border-indigo-600 border-b-2': active,
      'text-gray-500 hover:text-indigo-500': !active
    });

  return (
    <Link role='tab' className={createTabClass(active)} to={href}>
      {children}
    </Link>
  );
};

export { StepTitle, StepHelper, Tab };
