import { useRef, useState } from 'react';

import { AI, AIBots, useLazySuggestions, UseLazySuggestionsHookResult } from '@api/chat-gpt';

import { SuggestedQuestion } from '../types';

type Context = { research_goal?: string; questions?: string[] };

type UseSurveyQuestionOptions = {
  limit?: number;
  research_goal?: string;
};

type UseSurveyQuestion = Omit<UseLazySuggestionsHookResult<SuggestedQuestion, Context>, 'fetch' | 'value'> & {
  questions: AI.QueryResult<SuggestedQuestion>[];
  fetch: () => void;
  clear: () => void;
};

const DEFAULT_LIMIT = 5;

export const useGetSurveyQuestions = ({
  limit = DEFAULT_LIMIT,
  research_goal
}: UseSurveyQuestionOptions): UseSurveyQuestion => {
  const [questions, setQuestions] = useState<AI.QueryResult<SuggestedQuestion>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const cache = useRef<AI.QueryResult<SuggestedQuestion>[]>([]);

  const {
    fetch,
    accept,
    reject,
    stop,
    clear,
    isLoading: _,
    isError: __,
    ...rest
  } = useLazySuggestions<SuggestedQuestion, { research_goal?: string; questions?: string[] }>(
    {
      id: AIBots.SurveyQuestions,
      context: { research_goal, questions: [] }
    },
    {
      clearOnAcceptOrReject: false
    }
  );

  const transform = (): string[] => cache.current.map(({ result: { question } }) => question);

  const fetchQuestions = async () => {
    setIsLoading(true);

    if (cache.current.length >= limit) {
      setIsLoading(false);
      return;
    }

    try {
      const suggestions = await fetch({ research_goal, questions: transform() }).unwrap();

      if (suggestions && suggestions.length > 0) {
        const [suggestion] = suggestions;

        cache.current.push(suggestion);

        setQuestions([...cache.current]);

        fetchQuestions();
      } else {
        setIsLoading(false);
        return;
      }
    } catch (error) {
      if (error.message !== 'Aborted') {
        setIsError(true);
      }
      setIsLoading(false);
      return;
    }
  };

  const onAcceptOrReject = (id: number) => {
    cache.current = cache.current.filter((suggestion) => suggestion.id !== id);

    setQuestions(cache.current);
  };

  const acceptQuestion = (id: number) => {
    accept(id);
    onAcceptOrReject(id);
  };

  const rejectQuestion = (id: number) => {
    reject(id);
    onAcceptOrReject(id);
  };

  const stopSuggesting = () => {
    stop();
    setIsLoading(false);
  };

  const clearQuestions = () => {
    cache.current = [];
    clear();
    setQuestions([]);
  };

  return {
    questions,
    isLoading,
    isError,
    fetch: fetchQuestions,
    accept: acceptQuestion,
    reject: rejectQuestion,
    stop: stopSuggesting,
    clear: clearQuestions,
    ...rest
  };
};
