import { Option } from './common';
import { TIMEZONES } from '@components/utils/tz';

export const NOTIFICATION_TIMEOUT: number = process.env.NODE_ENV === 'test' ? 99999 : 5; // Seconds

export const CORE_ATTRS: Attr_[] = [
  { name: 'name', lookup: 'name', label: 'Name', attr_type: 'text', core: true },
  { name: 'opted_in', lookup: 'opted_in', label: 'Opt-in', attr_type: 'boolean', core: true },
  {
    name: 'unsubscribed_at',
    lookup: 'unsubscribed_at',
    label: 'Unsubscribed at',
    attr_type: 'datetime',
    core: true
  },
  {
    name: 'consented_at',
    lookup: 'consented_at',
    label: 'Opt-in date',
    attr_type: 'datetime',
    core: true
  },
  { name: 'first_name', lookup: 'first_name', label: 'First name', attr_type: 'text', core: true, hide: true },
  { name: 'last_name', lookup: 'last_name', label: 'Last name', attr_type: 'text', core: true, hide: true },
  { name: 'phone_number', lookup: 'phone_number', label: 'Phone number', attr_type: 'text', core: true },
  { name: 'email', lookup: 'email', label: 'Email', attr_type: 'text', core: true, hide: true },
  {
    name: 'timezone',
    lookup: 'timezone',
    label: 'Timezone',
    attr_type: 'text',
    core: true,
    values: Object.keys(TIMEZONES)
  },
  {
    name: 'completed_participations_count',
    lookup: 'completed_participations_count',
    label: 'Completed studies',
    attr_type: 'number',
    core: true,
    canSort: false
  },
  {
    name: 'invited_participations_count',
    lookup: 'invited_participations_count',
    label: 'Invited studies',
    attr_type: 'number',
    core: true,
    canSort: false
  },
  {
    name: 'responded_participations_count',
    lookup: 'responded_participations_count',
    label: 'Responded studies',
    attr_type: 'number',
    core: true,
    canSort: false
  },
  {
    name: 'all_time_usd_amount_in_cents',
    lookup: 'all_time_usd_amount_in_cents',
    label: 'All time incentives paid',
    attr_type: 'number',
    core: true,
    canSort: false
  },
  {
    name: 'current_year_usd_amount_in_cents',
    lookup: 'current_year_usd_amount_in_cents',
    label: 'Current year incentives paid',
    attr_type: 'number',
    core: true,
    canSort: false
  },
  {
    name: 'average_rating',
    lookup: 'average_rating',
    label: 'Average rating',
    attr_type: 'number',
    core: true,
    canSort: false
  }
];

export const EXTERNAL_ATTRS: Attr_[] = [
  {
    name: 'respondent_linkedin',
    lookup: 'respondent_linkedin',
    label: 'Linkedin (Respondent)',
    attr_type: 'url'
  },
  {
    name: 'respondent_skills',
    lookup: 'respondent_skills',
    label: 'Skills (Respondent)',
    attr_type: 'multiple_choice'
  },
  {
    name: 'respondent_job_title',
    lookup: 'respondent_job_title',
    label: 'Job Title (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_company',
    lookup: 'respondent_company',
    label: 'Company (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_company_size',
    lookup: 'respondent_company_size',
    label: 'Company Size (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_country_code',
    lookup: 'respondent_country_code',
    label: 'Country Code (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_location',
    lookup: 'respondent_location',
    label: 'Location (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_gender',
    lookup: 'respondent_gender',
    label: 'Gender (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_age',
    lookup: 'respondent_age',
    label: 'Age (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_education_level',
    lookup: 'respondent_education_level',
    label: 'Education Level (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_ethnicity',
    lookup: 'respondent_ethnicity',
    label: 'Ethnicity (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_household_income',
    lookup: 'respondent_household_income',
    label: 'Household Income (Respondent)',
    attr_type: 'text'
  },
  {
    name: 'respondent_industry',
    lookup: 'respondent_industry',
    label: 'Industry (Respondent)',
    attr_type: 'multiple_choice'
  },
  {
    name: 'respondent_seniority_level',
    lookup: 'respondent_seniority_level',
    label: 'Seniority Level (Respondent)',
    attr_type: 'text'
  }
];

export const BASE_ATTRS: Attr_[] = CORE_ATTRS.concat(EXTERNAL_ATTRS);

export const ATTR_TYPES: Option<Attr_['attr_type']>[] = [
  { label: 'Text', value: 'text' },
  { label: 'Number', value: 'number' },
  { label: 'URL', value: 'url' },
  { label: 'Multiple values', value: 'multiple_choice' },
  { label: 'Datetime', value: 'datetime' },
  { label: 'True/False', value: 'boolean' },
  { label: 'Free text', value: 'free_text' }
];

export const INVITE_EMAILS_CTA = {
  video_call: 'Schedule interview',
  online_task: 'Start task',
  unmoderated_test: 'Start test',
  survey: 'Start survey',
  screener: 'Apply now',
  booked: 'View interview details'
};

export const INVITE_EMAILS_GET_STARTED_COPY = {
  video_call: 'If you’re interested and available, please schedule a call through the link below.',
  online_task: 'If you’re interested and available, get started through the link below.',
  unmoderated_test: 'If you’re interested and available, get started through the link below.',
  survey: 'If you’re interested and available, get started through the link below.',
  screener: 'If you’re interested, apply through the link below.'
};

// from custom_attr.rb
// CustomAttr.is_safe_name?
export const UNSAFE_CUSTOM_ATTR_NAMES = [
  'id',
  'account_id',
  'email',
  'created_at',
  'updated_at',
  'deleted_at',
  'first_name',
  'last_name',
  'extra',
  'customer_import_id',
  'source',
  'participations_count',
  'completed_participations_count',
  'last_contacted_at',
  'consented_at',
  'unsubscribed_at',
  'cached_health_status',
  'cached_health_status_at',
  'timezone',
  'link_assets',
  'rich_notes',
  'notes_count',
  'consent_token',
  'contact_preferences',
  'untracked',
  'phone_number',
  'phone_number_valid',
  'email_valid',
  'hashed_email',
  'hashed_phone_number',
  'state',
  'status',
  'name',
  'activities',
  'wallet_entries',
  'account',
  'customer_import',
  'participations',
  'projects',
  'screener_responses',
  'incentives',
  'recordings',
  'versions',
  'avatar_attachment',
  'avatar_blob',
  'insights',
  'file_assets_attachments',
  'file_assets_blobs',
  'messages'
];
