import React, { FC, useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalSubheading, Text } from '@components/common';
import { TagList, TagPill } from '@components/tags';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';

interface Props {
  selectedTags: Tag[];
  allTags: Tag[];
  onSuccess: () => void;
  studyId?: number;
  medium?: boolean;
}

export const TagMerge: FC<Props> = ({ medium, studyId, selectedTags, allTags, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [bulkUpdateMergeTags, { isLoading, isSuccess, isError }] = api.useBulkUpdateMergeTagsMutation();
  const showToast = useToaster();

  const onSubmit = () => {
    if (tagIds.length === 1) {
      bulkUpdateMergeTags({
        ids: selectedTags.map((tag) => tag.id),
        target: { id: tagIds[0] }
      });

      track('submitted_merge_tags_modal', { count: selectedTags.length });
    }
  };

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedMergeTags());
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      const targetName = allTags.find(({ id }) => id === tagIds[0])?.name || '';
      showToast(toasts.successMergeTags(selectedTags.length, targetName));
      setTagIds([]);
      setIsOpen(false);
      onSuccess();
    }
  }, [isSuccess]);

  return (
    <>
      <Button medium={medium} icon='merge' name='Merge tags' onClick={() => setIsOpen(true)} className='ml-4'>
        Merge
      </Button>
      {isOpen && (
        <Modal
          title='Merge'
          size='md'
          closeOnEsc={false}
          onClose={() => setIsOpen(false)}
          renderFooter={() => (
            <div className='flex justify-end space-x-2'>
              <Button onClick={() => setIsOpen(false)} link>
                Cancel
              </Button>
              <Button primary onClick={onSubmit} loading={isLoading} disabled={isLoading || tagIds.length !== 1}>
                Merge
              </Button>
            </div>
          )}
        >
          <ModalSubheading>Combine similar tags into one.</ModalSubheading>
          <div className='my-6'>
            <Text mb='2' bold>
              Merge these tags:
            </Text>
            <div>
              {selectedTags.map((tag) => (
                <TagPill key={tag.name} tag={tag} className='mr-2' />
              ))}
            </div>
          </div>
          <div className='mb-6'>
            <Text as='label' htmlFor='tags-input' mb='2' bold>
              Into:
            </Text>
            <div className='relative'>
              <TagList
                studyId={studyId}
                tabIndex={0}
                tagIds={tagIds}
                onChange={setTagIds}
                inputClassName='w-full'
                dropdownClassName='w-full z-50'
                className='hover:bg-gray-50 flex items-center w-full h-10 px-4 space-x-2 border border-gray-200 rounded-md'
                placement='bottom-start'
                hideManagerLinks
                usePortal={false}
              />
            </div>
            {tagIds.length > 1 && (
              <Text mt='2' color='red-600' h='400'>
                Select exactly 1 tag to merge into.
              </Text>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
