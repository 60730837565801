import { Toast } from '@stores/toaster';

export const failedGet = (): Toast => ({
  heading: 'Failed to load!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
});

export const failedCreate = (): Toast => ({
  heading: 'Failed to create!',
  icon: 'error',
  text: 'Something went wrong creating your insight. Please try again later.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong saving your insight. Please try again later.'
});

export const successUpdate = (): Toast => ({
  heading: 'Insight successfully updated!',
  icon: 'success',
  text: 'Your insight has been updated.'
});

export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that insight. Please try again later.'
});

export const successDelete = (title: string): Toast => ({
  heading: 'Insight successfully deleted!',
  icon: 'success',
  text: `The insight “${title}” has been deleted.`
});
