import React, { HTMLAttributes, forwardRef } from 'react';
import { Text } from 'components/common';

type Props = HTMLAttributes<HTMLLIElement>;

export const EmptyState = forwardRef<HTMLLIElement, Props>((props, ref) => {
  return (
    <li ref={ref} {...props} className='px-3 py-6'>
      <Text h='400' className='text-center text-gray-500'>
        No results
      </Text>
    </li>
  );
});
