import { Table } from '@components/shared/Table';
import * as React from 'react';
import { useMemo } from 'react';

import { Text } from '@components/common';
import { humanize } from '@components/utils';
import { MultiselectDropdownItem } from 'components/shared/MultiselectCombobox';
import { useStudiesColumnLabels } from '@components/StudiesApp/components/StudiesIndex/hooks/useStudiesColumnLabels';
import { INITIAL_COLUMN_ORDER, STATIC_COLUMNS } from '@components/StudiesApp/components/StudiesIndex/contants';
import { useStudiesColumnDefinitions } from '@components/StudiesApp/components/StudiesIndex/hooks/useStudiesColumnDefinitions';
import { useCollectionView } from '@stores/view';
import { useVisibleColumns } from '@components/shared/Table/hooks/useVisibleColumns';
import { TableSidebar } from '@components/shared/TableSidebar';

interface Props {
  onlyBackendFilters?: boolean;
  records: Study[];
  groupBy?: 'owner' | 'status' | 'type';
  borderColor?: string;
  setSort?: (sort: CollectionView['sort']) => void;
}

export const Header: React.FC<any> = ({ column }) => (
  <Text h='400' className='text-left' bold>
    {humanize(column.id)}
  </Text>
);

export const ListViewTable: React.FC<Props> = ({
  onlyBackendFilters,
  records: studies,
  groupBy,
  borderColor = 'gray-200',
  setSort
}) => {
  const { view, setView } = useCollectionView();

  const updateStudyVisibleAttrs = (columnIds: string[]) => setView({ columns: columnIds });

  const columns = useStudiesColumnDefinitions({ groupBy });

  const columnIds = useMemo<string[]>(() => columns.map(({ id }) => id!), [columns]);

  const getColumnLabel = useStudiesColumnLabels();

  const items = useMemo<MultiselectDropdownItem<string>[]>(
    () =>
      columnIds.reduce<MultiselectDropdownItem<string>[]>(
        (acc, id) => (STATIC_COLUMNS.includes(id) ? acc : acc.concat({ label: getColumnLabel(id), value: id })),
        []
      ),
    [columnIds, getColumnLabel]
  );

  const visibleColumns = useVisibleColumns({
    columnIds,
    alwaysVisibleColumns: STATIC_COLUMNS,
    currentVisibleColumns: view?.columns || columnIds
  });

  const selectedValues = useMemo<string[]>(
    () => Object.keys(visibleColumns).filter((col) => visibleColumns[col] && !STATIC_COLUMNS.includes(col)),
    [visibleColumns]
  );
  
  return (
    <div className='flex items-stretch flex-1'>
      <div className={`relative flex-1 max-w-full overflow-auto border-l border-r border-${borderColor}`}>
        <Table<Study>
          manualSorting={onlyBackendFilters}
          manualPagination={onlyBackendFilters}
          columnOrder={INITIAL_COLUMN_ORDER}
          onSort={setSort}
          data={studies}
          columns={columns}
          visibleColumns={visibleColumns}
          className={`w-full bg-white border-t border-${borderColor} table-fixed`}
        />
      </div>
      <TableSidebar
        items={items}
        selectedValues={selectedValues}
        className='w-12 bg-white border border-l-0 border-gray-200'
        onVisibileColumnsChange={updateStudyVisibleAttrs}
      />
    </div>
  );
};
