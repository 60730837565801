import { Menu } from 'components/common/Menu';
import copy from 'copy-to-clipboard';
import React, { FC, MouseEvent, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ArtifactAction } from '@components/shared/Artifact';
import { AlgoliaData, AlgoliaHit, useSearch } from '@components/shared/Search';
import { Table } from '@components/shared/Table';
import { useToaster } from '@components/stores/toaster';
import { FullPageViewSVG, LinkSVG, SolidKebabSVG, TrashSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { formatSeconds } from '@components/VideoPlayer';
import { ColumnDef } from '@tanstack/react-table';
import Tippy from '@tippyjs/react';

import { useRenderAvatars } from '../../hooks/useRenderAvatars';
import { useRenderTagsList } from '../../hooks/useRenderTagsList';
import { useRepositoryContext } from '../../hooks/useRepositoryContext';
import { ArtifactAttributes } from '../../types';
import { ArtifactKind } from './ArtifactKind';

interface Props {
  onDelete?: (h: Pick<ArtifactAttributes, 'href' | 'title' | 'kind' | 'model_id'>) => void;
}

const DELETE_LABEL_MAP = {
  Story: 'Delete insight',
  Clip: 'Delete highlight',
  Highlight: 'Delete highlight',
  HighlightReel: 'Delete highlight reel'
};

export const ArtifactTable: FC<Props> = ({ onDelete }) => {
  const { data } = useSearch<AlgoliaData>();
  const { hits = [], hasMore = false } = data ?? {};
  const { renderTagsList } = useRenderTagsList();
  const { renderAvatars } = useRenderAvatars();
  const { setSelectedArtifacts } = useRepositoryContext();
  const showToast = useToaster();

  const onCopyLink = (e: MouseEvent, href: string) => {
    e.stopPropagation();
    copy(`${location.origin}${href}`);
    showToast({ heading: 'Success!', text: 'Link copied to clipboard', icon: 'success' });
    track('copied_link_to_artifact');
  };

  const onDeleteArtifact = (e: MouseEvent, { href, title, kind, model_id }: ArtifactAttributes) => {
    e.stopPropagation();
    onDelete?.({ href, title, kind, model_id });
  };

  const columns = useMemo<ColumnDef<AlgoliaHit<ArtifactAttributes>>[]>(
    () => [
      {
        accessorKey: 'model_id',
        id: 'model_id',
        size: 48,
        cell: ({ row }) => (
          <Table.Checkbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        header: ({ table }) => (
          <Table.Checkbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) => <Link to={row.original.href}>{row.original.title}</Link>}
          />
        ),
        accessorKey: 'title',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Name
          </Table.ColumnHeader>
        ),
        size: 250
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) => <ArtifactKind kind={row.original.kind} />}
          />
        ),
        accessorKey: 'kind',
        size: 180,
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Type
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => <Table.Cell<AlgoliaHit<ArtifactAttributes>> {...props} type='text' />,
        accessorKey: 'subtitle',
        size: 200,
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Study
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) => (row.original.tag_ids?.length ? renderTagsList(row.original.tag_ids) : <span>-</span>)}
          />
        ),
        size: 250,
        accessorKey: 'tag_ids',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Tags
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) =>
              row.original.study_user_ids?.length ? renderAvatars([row.original.study_user_ids[0]]) : <span>-</span>
            }
          />
        ),
        accessorKey: 'study_user_ids',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Creator
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) =>
              row.original.study_user_ids.slice(1).length ? (
                renderAvatars(row.original.study_user_ids.slice(1))
              ) : (
                <span>-</span>
              )
            }
          />
        ),
        accessorKey: 'study_user_ids',
        id: 'collaborators',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Collaborators
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => <Table.Cell<AlgoliaHit<ArtifactAttributes>> {...props} type='datetime' />,
        accessorKey: 'created_at',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Created
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => <Table.Cell<AlgoliaHit<ArtifactAttributes>> {...props} type='datetime' />,
        accessorKey: 'updated_at',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Last updated
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) => <span>{typeof row.original.views === 'number' ? row.original.views : '-'}</span>}
          />
        ),
        accessorKey: 'views',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            # of views
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) => <span>{row.original.clips ? row.original.clips.length : '-'}</span>}
          />
        ),
        accessorKey: 'clips',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            # of highlights
          </Table.ColumnHeader>
        )
      },
      {
        cell: (props) => (
          <Table.Cell<AlgoliaHit<ArtifactAttributes>>
            {...props}
            render={({ row }) => (
              <span>{typeof row.original.duration === 'number' ? formatSeconds(row.original.duration) : '-'}</span>
            )}
          />
        ),
        accessorKey: 'duration',
        header: (props) => (
          <Table.ColumnHeader {...props} isStatic>
            Duration
          </Table.ColumnHeader>
        )
      },
      {
        cell: ({ row }) => (
          <div className='h400 h-11 w-inherit flex items-center px-2 bg-white border-b border-gray-200'>
            <Tippy content='Copy link' arrow={false}>
              <button
                className='hover:text-indigo-600 focus:outline-none mr-2'
                onClick={(e) => onCopyLink(e, row.original.href)}
              >
                <LinkSVG />
              </button>
            </Tippy>
            <Menu
              className='overflow-hidden bg-white border border-b-0 border-gray-200 rounded'
              renderTrigger={() => (
                <div>
                  <Tippy content='More...' arrow={false}>
                    <button className='hover:text-indigo-600 focus:outline-none'>
                      <SolidKebabSVG className='w-4 h-4' />
                    </button>
                  </Tippy>
                </div>
              )}
            >
              <ArtifactAction href={row.original.href} icon={<FullPageViewSVG />} targetBlank>
                View on full page
              </ArtifactAction>
              {['Story', 'Clip', 'Highlight', 'HighlightReel'].includes(row.original.kind) && (
                <ArtifactAction onClick={(e) => onDeleteArtifact(e, row.original)} icon={<TrashSVG />} remove>
                  {DELETE_LABEL_MAP[row.original.kind]}
                </ArtifactAction>
              )}
            </Menu>
          </div>
        ),
        id: 'actions',
        size: 64,
        header: (props) => <Table.ColumnHeader {...props} isStatic />
      }
    ],
    [renderTagsList]
  );

  return (
    <div className='relative max-w-full overflow-auto border-l border-r border-gray-200'>
      <Table<AlgoliaHit<ArtifactAttributes>>
        data={hits}
        columns={columns}
        className='w-full bg-white border-t border-gray-200 table-fixed'
        stickyColumns={['model_id', 'title']}
        rowIdentifier='model_id'
        onSelectRow={(state) => {
          const ids = Object.keys(state).map(Number);
          const selectedArtifacts = hits.filter((hit) => ids.includes(+hit.model_id));

          setSelectedArtifacts(selectedArtifacts.map((hit) => hit.objectID));
        }}
      />
    </div>
  );
};
