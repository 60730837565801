import { add, format } from 'date-fns';
import * as React from 'react';

import { Select, Text } from '@components/common';
import { ClockSVG } from '@components/svgs';

import { TimezoneDesc } from '../../../shared/TimezonePicker';
import { useTranslation } from 'react-i18next';
import { LOCALES } from '../constants';
import { hasAtLeastOneAvailableSlot } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';

type RenderTimeslotProps = {
  day: string;
  time: string;
  displayText: string;
  use24hr: boolean;
  alerts: ConflictAlert[];
};

export type RenderTimeslot = (props: RenderTimeslotProps) => React.ReactNode;

export const Timeslots: React.FC<{
  selectedDate: Date;
  timeslots: Timeslots | undefined;
  selectedTimezone: TimezoneDesc;
  use24hr: boolean;
  nextMonthCount: number;
  calendarOffset: number;
  setCalendarOffset: (offset: number) => void;
  renderTimeslot: RenderTimeslot;
  troubleshootMode?: boolean;
}> = ({
  selectedDate,
  timeslots = {},
  selectedTimezone,
  use24hr,
  nextMonthCount,
  calendarOffset,
  setCalendarOffset,
  renderTimeslot,
  troubleshootMode
}) => {
  const { t, i18n } = useTranslation('Timeslots');

  const formatTo12hr = (time: string) => {
    const [hours, minutes] = time.split(':');
    const ampm = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
    const hrs = parseInt(hours, 10) % 12 || 12;
    return `${hrs}:${minutes} ${ampm}`;
  };

  const formatAvailability24hr = (availability: string, use24hr = true) =>
    use24hr ? availability : formatTo12hr(availability);

  const hasSlots = () => !!Object.keys(timeslots).length;

  const getNextMonth = () => {
    const nextMonth = add(new Date(), { months: calendarOffset + 1 });
    return format(nextMonth, 'MMMM', { locale: LOCALES[i18n.language] });
  };

  const getDateLabel = (day: string) => {
    const date = new Date(day + 'T00:00:00');
    return format(date, 'PPPP', { locale: LOCALES[i18n.language] });
  };

  const sortedTimeslotEntries = (): [string, DateWithSlotsAndConflicts][] =>
    Object.entries(timeslots).sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime());

  if (selectedDate && hasSlots()) {
    return (
      <section className='desktop:pl-14 desktop:pr-4 overflow-y-auto'>
        <ol className='desktop:mt-0 text-custom-brand mt-6 space-y-6 text-sm leading-6'>
          {sortedTimeslotEntries().map(([day, slots]) => {
            if (!hasAtLeastOneAvailableSlot(slots) && !troubleshootMode) return null;
            return (
              <li key={day}>
                <div className='text-custom-brand mb-4 text-base font-semibold text-left'>
                  <time data-timeslot={day} dateTime={getDateLabel(day)}>
                    {getDateLabel(day)}
                  </time>
                </div>
                <ol className='space-y-1'>
                  {slots.map((slot: string | Record<string, ConflictAlert[] | string[]>) => {
                    if (!troubleshootMode && typeof slot === 'object') return null;
                    const displayText =
                      typeof slot === 'string'
                        ? `${formatAvailability24hr(slot, use24hr)} ${selectedTimezone.abbreviation}`
                        : `${formatAvailability24hr(Object.keys(slot as Record<string, string[]>)[0], use24hr)} ${
                            selectedTimezone.abbreviation
                          }`;

                    const time = typeof slot === 'string' ? slot : Object.keys(slot as Record<string, string[]>)[0];

                    const alerts: ConflictAlert[] =
                      typeof slot === 'string' ? [] : Object.values(slot as Record<string, ConflictAlert[]>)[0];

                    return renderTimeslot({
                      day,
                      time,
                      alerts,
                      use24hr,
                      displayText
                    });
                  })}
                </ol>
              </li>
            );
          })}
        </ol>
        {nextMonthCount > 0 && (
          <footer className='border-custom-brand-secondary pt-4 mt-4 border-t'>
            <button
              onClick={() => setCalendarOffset(calendarOffset + 1)}
              className='hover:bg-gray-100 focus:outline-none border-custom-brand-secondary w-full h-full px-4 py-2 text-sm transition-all duration-150 border rounded-md'
            >
              {t('next_month_slots', { month: getNextMonth(), count: nextMonthCount })}
            </button>
          </footer>
        )}
      </section>
    );
  } else {
    return (
      <section className='desktop:mt-0 desktop:pl-14 pr-4 mt-12 overflow-y-auto'>
        <span className='text-custom-brand font-semibold'>{t('no_times')}</span>
        <footer className='border-custom-brand-secondary pt-4 mt-4 border-t'>
          {nextMonthCount > 0 && (
            <button
              onClick={() => setCalendarOffset(calendarOffset + 1)}
              className='hover:bg-gray-100 focus:outline-none border-custom-brand-secondary w-full h-full px-4 py-2 text-sm transition-all duration-150 border rounded-md'
            >
              {t('next_month_slots', { month: getNextMonth(), count: nextMonthCount })}
            </button>
          )}
        </footer>
      </section>
    );
  }
};

export const TimeslotsFormatSelect: React.FC<{ use24hr: boolean; setUse24hr: any }> = ({ use24hr, setUse24hr }) => {
  const { t } = useTranslation('Timeslots');

  return (
    <span>
      <Select
        className='flex-row truncate'
        value={use24hr ? '24hr' : '12hr'}
        options={[
          { value: '12hr', label: t('12_hour') },
          { value: '24hr', label: t('24_hour') }
        ]}
        prefix={<ClockSVG />}
        renderSelected={(option) => <Text h='400'>{option.label}</Text>}
        onChange={(val) => setUse24hr(val === '24hr')}
      />
    </span>
  );
};
