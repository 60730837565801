import { Text, TimeSelect } from '@components/common';
import { getTime } from '../../NylasCalendar/utils';
import * as React from 'react';
import { SCHEDULING_INCREMENT } from '../../../constants';
import { useTimeSelect } from './hooks/useTimeSelect';

interface Props {
  onChange: (params: Partial<SlotInstance>) => void;
  slot: SlotInstance;
  startHour: number;
  endHour: number;
  disabled?: boolean;
}

export const AvailabilitySlot: React.FC<Props> = ({ disabled, slot, onChange, startHour, endHour }) => {
  const {
    onChangeStartTime,
    onChangeEndTime,
    error,
    currentStartMin,
    currentStartHour,
    currentEndMin,
    currentEndHour
  } = useTimeSelect({ slot, onChange });

  return (
    <div>
      <div className='flex items-center space-x-0.5 tablet:space-x-1.5'>
        <TimeSelect
          disabled={disabled}
          minHour={0}
          maxHour={24}
          increment={SCHEDULING_INCREMENT}
          initialValue={{ hour: currentStartHour, min: currentStartMin }}
          onChange={(time) => onChangeStartTime(getTime(time))}
          label='start time'
          inputWidth='20'
        />
        <span className='self-center text-sm text-gray-700'>-</span>
        <TimeSelect
          disabled={disabled}
          minHour={0}
          maxHour={24}
          increment={SCHEDULING_INCREMENT}
          initialValue={{ hour: currentEndHour, min: currentEndMin }}
          onChange={(time) => onChangeEndTime(getTime(time))}
          label='end time'
          addOneMoreOption
          inputWidth='20'
        />
      </div>
      {error && (
        <Text color='red-600' h='200' className='text-center w-full mt-px'>
          Start time must be before end time
        </Text>
      )}
    </div>
  );
};
