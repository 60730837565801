import * as React from 'react';

import { Select, SelectOption, Text } from '@components/common';
import { GlobeSVG } from '@components/svgs';
import { useDeviceType } from '@hooks/useDeviceType';

import type { TimezoneDesc } from './utils';
import { timezones, timezoneFromName, browserTimezone, timezoneHumanize, timezoneOffset } from './utils';

export const TimezonePicker: React.FC<{
  wrapperClassName?: string;
  selectedTimezone: TimezoneDesc;
  setSelectedTimezone: (tz?: TimezoneDesc) => void;
  readOnly?: boolean;
}> = ({ wrapperClassName, selectedTimezone, setSelectedTimezone, readOnly }) => {
  return (
    <div className={wrapperClassName}>
      <Select
        disabled={readOnly}
        maxH='56'
        className='flex-row truncate'
        value={selectedTimezone?.name}
        options={timezones.map(timezoneDescToSelectOption)}
        onChange={(val) => setSelectedTimezone(timezoneFromName(val))}
        prefix={<GlobeSVG />}
        renderSelected={SelectedTimezone}
      />
    </div>
  );
};

export const browserTimezoneDesc = (): TimezoneDesc => timezoneFromName(browserTimezone()) || timezones[0];

const SelectedTimezone: React.FC<any> = (option) => {
  const { isMobile } = useDeviceType();

  return <Text h={isMobile ? '200' : '400'}>{option.label}</Text>;
};

const timezoneDescToSelectOption = (tz: TimezoneDesc): SelectOption => {
  return {
    label: `${timezoneHumanize(tz.name)} (${tz.abbreviation} ${timezoneOffset(tz.currentTimeFormat)})`,
    value: tz.name
  };
};

export { TimezoneDesc, timezoneFromName };
