import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import * as React from 'react';

export const InsightContent = ({ insight }) => {
  const tiptap = useTiptapFromDocumentId({
    documentId: insight?.document_id,
    studyId: insight?.project_id,
    scrollToTop: false,
    readonly: true,
    config: {
      columns: true,
      image: { enable: true },
      link: { enable: true },
      artifacts: true,
      templates: true,
      tables: true
    },
    slideoutView: true
  });

  return (
    <div className='px-6 py-4'>
      <Tiptap {...tiptap.getTiptapProps()}>
        <tiptap.Content {...tiptap.getContentProps()} />
      </Tiptap>
    </div>
  );
};
