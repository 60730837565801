import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { EditInvite, EditUser, NewInvite, UsersIndex } from './pages';

export const AccountUsersApp: React.FC = () => {
  return (
    <Routes>
      <Route path='new' element={<NewInvite />} />
      <Route path=':token/edit' element={<EditInvite />} />
      <Route path=':account_id/members/:id/edit' element={<EditUser />} />
      <Route path=':id' element={<UsersIndex />} />
    </Routes>
  );
};
