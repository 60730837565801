import React, { useContext, useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Text } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import { DeleteSegmentModal } from '@components/SegmentsApp/components/DeleteSegmentModal';
import { SegmentRow } from '@components/SegmentsApp/components/SegmentRow';
import { SegmentsZDS } from '@components/SegmentsApp/components/SegmentsZDS';
import { Skeleton } from '@components/SegmentsApp/components/Skeleton';
import * as toasts from '@components/SegmentsApp/toasts';
import { buildCandidateFilterDefs, SegmentSlideOut, useTableFilters } from '@components/shared/TableFilters';
import { track } from '@components/tracking';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { usePermission } from '@hooks/usePermission';
import configStore from '@stores/config';
import { useToaster } from '@stores/toaster';
import { RestrictedAction } from 'components/shared/RestrictedAction';

export const SegmentsIndex = () => {
  const { data, isLoading, isError, isSuccess: segmentsFetched } = api.useGetSegmentsQuery();

  const segments = [...(data || [])];

  const sortedSegments = useMemo(() => segments.sort((a, b) => a.name.localeCompare(b.name)), [data]);

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGet());
    }
  }, [isError]);

  const { candidateAttrs, isSuccess: attrsFetched } = useCandidateAttrs();

  const canEdit = usePermission('manageSegments')();

  const [selected, setSelected] = useState<CustomerSegment | null>(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [segmentSlideoutOpen, setSegmentSlideoutOpen] = useState(false);

  const definitions = useMemo(
    () =>
      buildCandidateFilterDefs({
        coreAttrs: CORE_ATTRS,
        customAttrs: candidateAttrs,
        segments: sortedSegments,
        enableTeams: false
      }),
    [candidateAttrs, sortedSegments]
  );

  const filtersHook = useTableFilters({
    trackKey: 'candidateSegments',
    definitions,
    syncWithURL: segmentsFetched && attrsFetched
  });

  const clearSelected = () => {
    setSelected(null);
    filtersHook.setFilters([]);
  };

  return (
    <>
      <div className='desktop:space-y-0 space-y-4 mb-4 monitor:mb-6'>
        <div className='desktop:flex-row desktop:justify-between desktop:space-y-0 flex flex-col items-center space-y-4'>
          <Text as='h1' className='text-center' h='700'>
            Candidate segments
          </Text>
          {!!sortedSegments.length && (
            <RestrictedAction permission='manageSegments'>
              {({ can }) => (
                <Button
                  disabled={!can}
                  icon='plus'
                  className='whitespace-nowrap'
                  onClick={() => {
                    clearSelected();
                    setSegmentSlideoutOpen(true);
                  }}
                  primary
                  medium
                >
                  Create a new segment
                </Button>
              )}
            </RestrictedAction>
          )}
        </div>
        <p className='desktop:text-left mt-2 text-sm text-center text-gray-500'>
          You can change the name, manage the filters, and delete the segment.
        </p>
      </div>
      <div className='desktop:p-10 flex flex-col h-full p-4 bg-white border border-gray-200 rounded-lg'>
        {isLoading && <Skeleton />}
        {attrsFetched && sortedSegments.length > 0 && (
          <table className='min-w-4xl w-full overflow-auto'>
            <thead>
              <tr>
                <th className='p-3 pl-0 text-sm text-left'>Name</th>
                <th className='p-3 text-sm text-left'>Last updated</th>
                <th className='p-3 text-sm text-left'>Filters used</th>
                <th></th>
              </tr>
            </thead>

            <tbody className='divide-y divide-gray-200'>
              {sortedSegments.map((segment, i) => (
                <SegmentRow
                  readOnly={!canEdit}
                  filtersHook={filtersHook}
                  setSegmentSlideoutOpen={setSegmentSlideoutOpen}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setSelected={setSelected}
                  segment={segment}
                  key={i}
                />
              ))}
            </tbody>
          </table>
        )}
        {attrsFetched && sortedSegments.length === 0 && <SegmentsZDS onClick={() => setSegmentSlideoutOpen(true)} />}

        <SegmentSlideOut
          editable
          id={String(selected?.id)}
          segment={selected}
          open={segmentSlideoutOpen}
          hook={filtersHook}
          onClose={() => {
            if (!deleteModalOpen) {
              setSegmentSlideoutOpen(false);
            }
          }}
          onCreate={(segment) => {
            track('created_segment', {
              table: 'candidateSegments',
              name: segment.name,
              op: segment.filters.op,
              count: segment.filters.filters.length
            });
          }}
          onDelete={() => setDeleteModalOpen(true)}
        />
        {selected && (
          <DeleteSegmentModal
            open={deleteModalOpen}
            onClose={() => {
              setDeleteModalOpen(false);
            }}
            onSubmit={() => {
              clearSelected();
              if (segmentSlideoutOpen) {
                setSegmentSlideoutOpen(false);
              }
            }}
            id={selected.id}
            title={selected.name}
          />
        )}
      </div>
    </>
  );
};
