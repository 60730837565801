import { compact } from '@components/utils';

import { isBlockOfKind } from '../helpers/isBlockOfKind';

import * as Enums from '../types/enums';
import * as Models from '../types/models';

interface Result {
  errors: Models.Errors;
}

export const useBlockErrors = (blocks: Models.Block[]): Result => {
  const validate = (block: Models.Block) => {
    const errors: Models.Error[] = [];

    if (!block) return errors;

    if (!block.title) {
      errors.push({ message: 'Title is required' });
    }

    if (isBlockOfKind(block, Enums.Kind.prototypeTest)) {
      if (!block.blockable.url) {
        errors.push({ message: 'Prototype URL is required' });
      }

      const paths = block.blockable.paths;

      if (paths && paths.length > 0 && paths[0].steps.length === 1) {
        errors.push({ message: 'Prototype goal step is required' });
      }
    }

    if (isBlockOfKind(block, Enums.Kind.websiteTest)) {
      if (!block.blockable.url) {
        errors.push({ message: 'Website URL is required' });
      }
    }

    if (isBlockOfKind(block, Enums.Kind.cardSort)) {
      const cards = compact(block.blockable.cards ?? []);
      const categories = compact(block.blockable.categories ?? []);

      if (cards.length === 0) {
        errors.push({ message: 'At least one card is required' });
      }

      if (categories.length === 0 && block.blockable.sort_type === 'closed') {
        errors.push({ message: 'At least one category is required' });
      }
    }

    return errors;
  };

  const parseMessage = (errors: Models.Error[]) => {
    return errors.map((e) => e.message).join('\n');
  };

  const parseErrors = () => {
    const parsed: Models.Errors = {};

    blocks.forEach((block) => {
      const errors = validate(block);

      if (errors.length > 0) {
        parsed[block.id] = { errors: errors, message: parseMessage(errors) };
      }
    });

    return parsed;
  };

  return { errors: parseErrors() };
};
