import copy from 'copy-to-clipboard';
import * as React from 'react';
import { useState } from 'react';

import { Button, ButtonProps } from '@components/common/Button';

interface Props extends Omit<ButtonProps, 'text'> {
  text: string;
  onCopy?: (text: string) => void;
}
export const CopyText: React.FC<Props> = ({ text, children, onCopy, ...props }) => {
  const [copied, setCopied] = useState(false);

  function onClick() {
    copy(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
    if (onCopy) {
      onCopy(text);
    }
  }

  if (copied) {
    return (
      <Button icon='checkInCircle' className='flex items-center' disabled {...props}>
        Copied
      </Button>
    );
  }

  return (
    <Button {...props} onClick={onClick}>
      {children}
    </Button>
  );
};
