import {
  getHourMin,
  getHours,
  getTime
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { useEffect, useState } from 'react';

interface UseTimeSelect {
  error: boolean;
  onChangeStartTime: (time: string) => void;
  onChangeEndTime: (time: string) => void;
  currentStartHour: number;
  currentStartMin: number;
  currentEndHour: number;
  currentEndMin: number;
}

interface Props {
  slot: SlotInstance;
  onChange: (params: Partial<SlotInstance>) => void;
}

export const useTimeSelect = ({ slot, onChange }: Props): UseTimeSelect => {
  const { hour: slotHour, min: slotMin } = getHourMin(slot.time);

  const initialEndTime = getTime({
    hour: Math.floor(slotHour + (slot.duration + slotMin) / 60),
    min: (slotMin + slot.duration) % 60
  });

  const [startTime, setStartTime] = useState<string>(slot.time);
  const [endTime, setEndTime] = useState<string>(initialEndTime);
  const [duration, setDuration] = useState<number>(slot.duration);
  const [error, setError] = useState<boolean>(false);

  const { hour: currentStartHour, min: currentStartMin } = getHourMin(startTime);
  const { hour: currentEndHour, min: currentEndMin } = getHourMin(endTime);

  const startTimeHours = getHours(startTime);
  const endTimeHours = getHours(endTime);

  useEffect(() => {
    setStartTime(slot.time);
  }, [slot.time]);

  useEffect(() => {
    setEndTime(initialEndTime);
  }, [initialEndTime]);

  useEffect(() => {
    setDuration(slot.duration);
  }, [slot.duration]);

  const onChangeStartTime = (time: string) => {
    const hours = getHours(time);
    const hoursDiff = startTimeHours - getHours(time);

    const currentDuration = duration + hoursDiff * 60;

    if (hours >= endTimeHours) {
      setStartTime(time);
      setDuration(duration + hoursDiff * 60);
      setError(true);
      return;
    }

    setStartTime(time);
    setDuration(currentDuration);
    error && setError(false);
    onChange({ time, duration: currentDuration });
  };

  const onChangeEndTime = (time: string) => {
    const hours = getHours(time);
    const hoursDiff = hours - getHours(endTime);
    const currentDuration = duration + hoursDiff * 60;

    if (startTimeHours >= hours) {
      setEndTime(time);
      setDuration(currentDuration);
      setError(true);
      return;
    }

    setEndTime(time);
    setDuration(duration + hoursDiff * 60);
    error && setError(false);
    onChange({ time: startTime, duration: currentDuration });
  };

  return {
    error,
    onChangeStartTime,
    onChangeEndTime,
    currentStartHour,
    currentStartMin,
    currentEndHour,
    currentEndMin
  };
};
