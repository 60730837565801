import React, { SVGProps } from 'react';

export const UnlinkSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.0625 7.21777H1.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0625 8.96777L2.1875 9.84277'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0625 5.46777L2.1875 4.59277'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9375 7.21777H12.6875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9375 8.96777L11.8125 9.84277'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9375 5.46777L11.8125 4.59277'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.8125 5.46777V2.84277C4.81435 2.26318 5.04541 1.70785 5.45524 1.29802C5.86508 0.88818 6.42041 0.657119 7 0.655273V0.655273C7.57964 0.656966 8.13506 0.887978 8.54493 1.29785C8.9548 1.70772 9.18581 2.26313 9.1875 2.84277V5.90527C9.18632 6.33051 9.06166 6.74624 8.82865 7.10195C8.59565 7.45767 8.26435 7.73805 7.875 7.90902C7.59931 8.03053 7.30128 8.09311 7 8.09277'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 8.53027V11.1553C9.18596 11.735 8.955 12.2905 8.5451 12.7004C8.13519 13.1103 7.57969 13.3412 7 13.3428V13.3428C6.42036 13.3411 5.86494 13.1101 5.45507 12.7002C5.0452 12.2903 4.81419 11.7349 4.8125 11.1553V8.53027C4.81435 7.95068 5.04541 7.39535 5.45524 6.98552C5.86508 6.57568 6.42041 6.34462 7 6.34277'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
