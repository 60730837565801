import * as React from 'react';
import { useEffect, useState } from 'react';
import { DragStart, DropResult } from 'react-beautiful-dnd';

import { api } from '@api/reduxApi';
import { ColumnBoard } from '@components/shared/ColumnBoard';

import { GroupColumn } from './GroupColumn';

type Props = {
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
  studyId?: number;
  canManage: boolean;
};

export const TagManager: React.FC<Props> = ({ selectedTags, canManage, setSelectedTags, studyId }) => {
  const [draggingTagId, setDraggingTagId] = useState<number | null>(null);

  const { data: tagsQueryData } = api.useGetTagsQuery({ studyId });
  const { data: tagGroupsData, isLoading } = api.useGetTagGroupsQuery();
  const tagGroups = studyId
    ? tagGroupsData?.filter((t) => t.project_id === studyId)
    : tagGroupsData?.filter((t) => !t.project_id);

  const [create, { isLoading: isCreatingGroup }] = api.useCreateTagGroupMutation();
  const [bulkUpdateTags] = api.useBulkUpdateTagsMutation();

  const [tags, setTags] = useState(tagsQueryData);

  useEffect(() => setTags(studyId ? tagsQueryData : tagsQueryData?.filter((t) => !t.project_id)), [tagsQueryData]);

  const onDragEnd = async (result: DropResult) => {
    if (!canManage) {
      return;
    }
    if (result.reason === 'DROP' && result.destination) {
      if (result.destination.droppableId === 'new_group') {
        const color = tags?.find?.(({ id }) => String(id) === result.draggableId)?.color;
        const tagGroup = await create({ name: '', color, project_id: studyId }).unwrap();

        if (selectedTags.length > 0) {
          const tagIds = selectedTags.map((tag) => tag.id);

          bulkUpdateTags({
            studyId: studyId,
            ids: tagIds,
            tags: { tag_group_id: tagGroup.id }
          });
        } else {
          bulkUpdateTags({
            studyId: studyId,
            ids: [parseInt(result.draggableId)],
            tags: { tag_group_id: tagGroup.id }
          });
        }
      } else {
        const tag_group_id =
          result.destination.droppableId === 'ungrouped' ? null : parseInt(result.destination.droppableId);

        if (selectedTags.length > 0) {
          const tagIds = selectedTags.map((tag) => tag.id);

          bulkUpdateTags({
            studyId: studyId,
            ids: tagIds,
            tags: { tag_group_id }
          });
        } else {
          bulkUpdateTags({
            studyId: studyId,
            ids: [parseInt(result.draggableId)],
            tags: { tag_group_id }
          });
        }

        setTags(tags?.map((tag) => (tag.name === result.draggableId ? { ...tag, tag_group_id } : tag)));
      }

      setDraggingTagId(null);
      setSelectedTags([]);
    }

    if (!result.destination || result.reason === 'CANCEL') {
      setDraggingTagId(null);
      return;
    }
  };

  const onDragStart = (start: DragStart) => {
    const selected = selectedTags.find((tag) => tag.id === parseInt(start.draggableId));

    if (!selected) {
      setSelectedTags([]);
    }
    setDraggingTagId(parseInt(start.draggableId));
  };

  const addTagToTags = (tag: Tag) => {
    setTags([tag, ...(tags || [])]);
  };

  const groupFilter = (groupId: number | null) => (tag: Tag) => tag.tag_group_id === groupId;

  return (
    <ColumnBoard
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      showSkeleton={isLoading}
      showPlaceholder={!isLoading && canManage}
      placeholderColumnText='Drag & drop tags to create a new group'
      onCreateColumn={() => {
        create({ name: '', project_id: studyId });
      }}
    >
      <GroupColumn
        readOnly={!canManage}
        draggingTagId={draggingTagId}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        addTagToTags={addTagToTags}
        tags={tags && tags.filter(groupFilter(null))}
        studyId={studyId}
        ungrouped
      />
      {tagGroups &&
        tagGroups.map((group) => (
          <GroupColumn
            key={group.id}
            tags={tags && tags.filter(groupFilter(group.id))}
            {...group}
            tagGroupId={group.id}
            readOnly={!canManage}
            tagGroupColor={group.color}
            addTagToTags={addTagToTags}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            draggingTagId={draggingTagId}
            studyId={studyId}
          />
        ))}
    </ColumnBoard>
  );
};
