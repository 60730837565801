import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Pill } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { WindowLayout, WindowStickyHeader } from '@components/layouts/WindowLayout';
import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { BackArrowSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import { DeleteTemplateButton } from '../../components/DeleteTemplateButton';
import { PublishedTitle } from '../../components/PublishedTitle';
import * as toasts from '../../toasts';
import { EditableTitle } from './EditableTitle';
import { TextEditorWrapper } from '@components/shared/TextEditorWrapper';

interface Props {
  editable?: boolean;
  interviewTemplate: InterviewTemplate;
  setInterviewTemplate: (i: InterviewTemplate) => void;
}
export const InterviewTemplatePage: React.FC<Props> = ({ editable, interviewTemplate, setInterviewTemplate }) => {
  const navigate = useNavigate();

  const canUpdate = usePermission<InterviewTemplate>('updateInterviewTemplate')(interviewTemplate);

  const [update] = api.useUpdateInterviewTemplateMutation();

  const { gq_admin } = useUser();

  const [loading, setLoading] = useState(false);

  const {
    account: { id: accountId }
  } = useAccount();

  const isFromThisAccount = accountId === interviewTemplate.account_id;

  const readonly = !canUpdate || !editable || !isFromThisAccount;

  const tiptap = useTiptapFromDocumentId({
    documentId: interviewTemplate.document_id,
    readonly,
    autoSave: false,
    config: {
      artifacts: false,
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      templates: false,
      placeholder: 'Write learnings, embed interviews, and so much more…'
    }
  });

  const showToast = useToaster();

  const redirect = () => {
    if (document.referrer === '') {
      navigate('/study_templates/interviews');
    } else {
      navigate(-1);
    }
  };

  function handleClickEdit() {
    navigate(`/study_templates/interviews/${interviewTemplate.id}/edit`);
  }

  function toggleDefault() {
    setInterviewTemplate({ ...interviewTemplate, default: !interviewTemplate.default });
    update({
      id: interviewTemplate.id,
      title: interviewTemplate.title,
      default: !interviewTemplate.default
    });
  }

  async function handleClickSave() {
    const promise = tiptap.saveContent();
    setLoading(true);
    try {
      await promise;
      navigate(`/study_templates/interviews/${interviewTemplate.id}`);
      showToast(toasts.successCreate());
    } catch {
      showToast(toasts.failedCreate());
    }
    setLoading(false);
  }

  return (
    <WindowLayout>
      <PageTitle>{interviewTemplate.title}</PageTitle>
      <WindowStickyHeader>
        <div className={`flex flex-col w-full pt-3 space-y-6 ${editable ? 'h-46' : ''}`}>
          <button onClick={redirect} className='w-6'>
            <BackArrowSVG className='text-indigo-600' />
          </button>
          <div className='tablet:flex-row tablet:justify-between flex flex-col justify-center w-full space-x-4'>
            {editable && canUpdate ? (
              <EditableTitle
                className='tablet:text-left tablet:mb-0 mb-2 text-center truncate'
                placeholder='Template title...'
                interviewTemplate={interviewTemplate}
                setInterviewTemplate={setInterviewTemplate}
              />
            ) : (
              <PublishedTitle className='text-center truncate' template={interviewTemplate} kind='InterviewTemplate' />
            )}
            <div className='tablet:justify-end flex justify-center flex-shrink space-x-2 text-right'>
              {gq_admin && (
                <Button
                  className='whitespace-nowrap'
                  href={`/admin/template/studies/${interviewTemplate.id}/metadata/edit`}
                >
                  Admin settings
                </Button>
              )}
              {editable && (
                <Button primary loading={loading} onClick={handleClickSave}>
                  Save changes
                </Button>
              )}
              {!editable && canUpdate && (
                <>
                  {(!interviewTemplate.global || gq_admin) && (
                    <Button iconSuffix='pencil' onClick={handleClickEdit}>
                      Edit
                    </Button>
                  )}
                  <DeleteTemplateButton
                    buttonClassName='ml-4'
                    onSubmit={redirect}
                    template={interviewTemplate}
                    kind='InterviewTemplate'
                  />
                </>
              )}
            </div>
          </div>
          {!editable && interviewTemplate.default && (
            <div className='flex flex-row'>
              <Pill color='gray'>Default</Pill>
            </div>
          )}
          {canUpdate && editable && (
            <div className='flex-grow'>
              <label className='h400 mb-4'>
                <input
                  type='checkbox'
                  className='mb-1 mr-2'
                  checked={interviewTemplate.default}
                  onChange={toggleDefault}
                />
                Use as default for every customer interview
              </label>
            </div>
          )}
        </div>
      </WindowStickyHeader>
      <TextEditorWrapper tiptap={tiptap} showMenu={!readonly} />
    </WindowLayout>
  );
};
