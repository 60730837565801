import React from 'react';

import { Alert, AlertHeading, AlertMessage, Button } from '@components/common';

import { AlertProps } from '../types';

export const AdminFund: React.FC<AlertProps> = ({ user, study, openFundModal, canAction }) => {
  return (
    <Alert
      className='my-4'
      type='error'
      cta={
        canAction && (
          <Button
            onClick={() => {
              openFundModal?.();
            }}
            primary
          >
            Fund incentives
          </Button>
        )
      }
    >
      <>
        <AlertHeading>Please fund incentives</AlertHeading>
        <AlertMessage>
          {study.owner?.id === user.id && `You need to fund incentives before you can invite people.`}
          {study.owner?.id !== user.id &&
            `Your teammate, ${study.owner?.name}, is waiting on you to fund incentives before they can invite people.`}
        </AlertMessage>
      </>
    </Alert>
  );
};
