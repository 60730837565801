// libs
import React, { forwardRef } from 'react';

// components
import { Text } from '@components/common';

import { DescAndStatus } from './components/descAndStatus';
import { EventDate } from './components/eventDate';
import { NylasEventGuests } from './components/eventGuests';

interface Props {
  event: NylasSlotInstance;
  timezone: Timezone;
  onChange?: (event: Partial<NylasEvent>) => void;
}

export const NylasEventPopup = forwardRef<HTMLDivElement, Props>(({ timezone, event, onChange }, ref) => {
  return (
    <div
      data-testid={event.description}
      tabIndex={0}
      role='menu'
      ref={ref}
      className='min-w-60 max-w-lg mr-2 bg-white rounded-md shadow-lg'
    >
      {event.owner && (
        <div className='flex items-center px-6 py-3 border-b border-gray-200'>
          <div className='w-4 h-4 mr-2 bg-yellow-200 rounded-sm' />
          <Text h='400'>{event.owner}</Text>
        </div>
      )}

      <div className='px-6 pt-3 pb-6'>
        <Text className='pt-2 pb-5 whitespace-pre-line'>{event.title}</Text>

        <EventDate timezone={timezone} when={event.when} />

        {!!event.guests?.length && <NylasEventGuests guests={event.guests} />}

        <DescAndStatus
          description={event.description}
          busy={event.busy}
          onChangeBusy={onChange && ((busy) => onChange({ busy }))}
        />
      </div>
    </div>
  );
});
