import { uniq, uniqBy } from '@components/utils';

export const getValue = (entity: EntityWithCustomAttributes, attr: Attr_): unknown => {
  if (attr.core) {
    return entity[attr.name];
  } else if ('extra' in entity) {
    return entity.extra[attr.name];
  } else if ('custom_attributes' in entity) {
    return entity.custom_attributes?.[attr.name];
  } else {
    return undefined;
  }
};

export const isValueBlank = (type: string, value: unknown): boolean => {
  if (type === 'boolean') {
    return value === undefined || value === null;
  } else {
    return !value || value == '';
  }
};

type BuildVisibleAttrs = (props: {
  attrs: Attr_[];
  newAttr: Attr_[];
  entity: EntityWithCustomAttributes;
  expanded: boolean;
}) => {
  visibleAttrs: Attr_[];
  totalAttrs: number;
};

export const buildVisibleAttrs: BuildVisibleAttrs = ({ attrs, newAttr, entity, expanded }) => {
  const attrsWithValue = attrs.filter((a) => {
    const val = getValue(entity, a);
    return !isValueBlank(a.attr_type, val) || a.name === 'name';
  });

  const uniqAttrs = attrsWithValue.sort((a, b) => {
    const timeA = a.created_at && new Date(a.updated_at as any);
    const timeB = b.created_at && new Date(b.updated_at as any);

    if (!timeA) return 1;
    if (!timeB) return 1;
    return timeA === timeB ? 0 : timeA > timeB ? 1 : -1;
  });

  const sortedAttrs = uniqBy([...uniqAttrs, ...newAttr], ({ name }) => name);

  return { visibleAttrs: expanded ? sortedAttrs : sortedAttrs.slice(0, 5), totalAttrs: sortedAttrs.length };
};
