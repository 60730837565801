import { HideTabsSVG, ShowTabsSVG } from '@components/svgs';
import * as React from 'react';
import { useState } from 'react';
import { Text } from 'components/common';
import { PageTitle } from '@components/common/helmets';

export const PageNavbar: React.FC = ({ children }) => {
  const [showTabs, setShowTabs] = useState(true);

  if (!showTabs) {
    return (
      <button
        className='tablet:absolute desktop:top-3 tablet:top-2 tablet:left-2 desktop:left-6 tablet:m-0 tablet:self-start focus:outline-none hover:text-indigo-600 z-10 self-end mt-3 ml-6 mr-4'
        onClick={() => setShowTabs(true)}
      >
        <ShowTabsSVG />
      </button>
    );
  }
  return (
    <div className='tablet:w-60 tablet:flex-shrink-0 flex flex-col w-full tablet:h-full tablet:overflow-y-auto bg-white tablet:border-r tablet:border-gray-200'>
      <div className='relative flex items-center justify-between flex-shrink-0'>
        <nav className='flex flex-col items-start flex-1 pb-3'>
          <div className='w-full pl-3 flex justify-between items-center'>
            <PageTitle>Settings</PageTitle>
            <Text className='mt-3 mb-1' bold>
              Settings
            </Text>
            <button
              className='tablet:mr-2 focus:outline-none hover:text-indigo-600 self-end mt-3 mb-1 mr-4'
              onClick={() => setShowTabs(false)}
            >
              <HideTabsSVG />
            </button>
          </div>
          {children}
        </nav>
      </div>
    </div>
  );
};
