import { createContext, HTMLAttributes, MutableRefObject, useContext } from 'react';

interface PanelPropsParams {
  ref: MutableRefObject<HTMLDivElement | undefined>;
  index: number;
}

export interface Context {
  currentIndexRef: MutableRefObject<number>;
  isExpandable?: boolean;
  isCollapsible?: boolean;
  defaultExpandedSections?: number[];
  expandedSections: number[];
  getHeaderProps: (index: number) => HTMLAttributes<HTMLDivElement>;
  getPanelProps: (params: PanelPropsParams) => HTMLAttributes<HTMLDivElement>;
  expandSection: (index: number) => void;
}

export const AccordionContext = createContext<Context | undefined>(undefined);

export const useAccordionContext = () => {
  const accordionContext = useContext<Context | undefined>(AccordionContext);

  if (accordionContext === undefined) throw new Error();

  return accordionContext;
};
