import React, { SVGProps } from 'react';

export const MeetingWebcamSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11 2.0625C10.932 2.0625 10.8656 2.08266 10.809 2.12043C10.7525 2.1582 10.7084 2.21189 10.6824 2.2747C10.6564 2.33751 10.6496 2.40663 10.6629 2.47331C10.6761 2.53999 10.7089 2.60124 10.7569 2.64932C10.805 2.69739 10.8663 2.73013 10.9329 2.74339C10.9996 2.75666 11.0687 2.74985 11.1315 2.72383C11.1944 2.69782 11.248 2.65376 11.2858 2.59723C11.3236 2.5407 11.3437 2.47424 11.3437 2.40625C11.3437 2.31508 11.3075 2.22765 11.2431 2.16318C11.1786 2.09872 11.0912 2.0625 11 2.0625Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.9375 8.9375C8.9375 9.48451 9.1548 10.0091 9.54159 10.3959C9.92839 10.7827 10.453 11 11 11C11.547 11 12.0716 10.7827 12.4584 10.3959C12.8452 10.0091 13.0625 9.48451 13.0625 8.9375C13.0625 8.39049 12.8452 7.86589 12.4584 7.47909C12.0716 7.0923 11.547 6.875 11 6.875C10.453 6.875 9.92839 7.0923 9.54159 7.47909C9.1548 7.86589 8.9375 8.39049 8.9375 8.9375V8.9375Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.4375 13.75C14.1052 13.127 13.6099 12.606 13.0044 12.2427C12.3989 11.8794 11.7061 11.6875 11 11.6875C10.2939 11.6875 9.60108 11.8794 8.99561 12.2427C8.39014 12.606 7.89478 13.127 7.5625 13.75'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.4375 2.75H19.9375C20.3022 2.75 20.6519 2.89487 20.9098 3.15273C21.1676 3.41059 21.3125 3.76033 21.3125 4.125V15.125C21.3125 15.4897 21.1676 15.8394 20.9098 16.0973C20.6519 16.3551 20.3022 16.5 19.9375 16.5H2.0625C1.69783 16.5 1.34809 16.3551 1.09023 16.0973C0.832366 15.8394 0.6875 15.4897 0.6875 15.125V4.125C0.6875 3.76033 0.832366 3.41059 1.09023 3.15273C1.34809 2.89487 1.69783 2.75 2.0625 2.75H7.5625'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.4375 20.625H7.5625L8.25 16.5H13.75L14.4375 20.625Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.5 20.625H16.5'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
