import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { api } from '@api/reduxApi';
import { Button, Spinner } from '@components/common';
import { CORE_ATTRS } from '@components/config';
import { buildCandidateFilterDefs } from '@components/shared/TableFilters';
import { track } from '@components/tracking';
import { uid } from '@components/utils';

import { StudyLimitField } from './StudyLimitField';

const buildLimit = (): StudyLimit => ({
  id: uid(),
  value: 10,
  order: 1,
  name: null,
  filters: {
    filters: [],
    op: 'all'
  }
});

const fixOrder = (limits: StudyLimit[]) => {
  let i = 0;
  return limits?.map((l) => ({ ...l, order: ++i }));
};

export const StudyLimitFields: React.FC<{ studyId: number; readOnly?: boolean }> = ({ studyId, readOnly }) => {
  const { data: defaultStudyLimits, isLoading, isSuccess } = api.useGetStudyLimitsQuery(studyId);
  const [updateStudyLimits, { isLoading: isUpdating }] = api.useUpdateStudyLimitsMutation();
  const { data: segments, isLoading: isLoadingSegments } = api.useGetSegmentsQuery();
  const { data: candidateAttrsForDefs, isLoading: isLoadingCandidateAttrs } = api.useGetCandidateAttrsQuery();

  const definitions = useMemo(
    () =>
      buildCandidateFilterDefs({
        coreAttrs: CORE_ATTRS,
        customAttrs: candidateAttrsForDefs || [],
        segments: segments || [],
        enableTeams: false
      }),
    [segments, candidateAttrsForDefs]
  );
  const [studyLimits, setStudyLimits] = useState<StudyLimit[]>([]);
  const [shouldSave, setShouldSave] = useState(false);
  const queueSave = () => setShouldSave(true);
  useEffect(() => {
    if (isSuccess) {
      setStudyLimits(defaultStudyLimits);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (shouldSave) {
      updateStudyLimits({
        studyId,
        limits: studyLimits
      });
      setShouldSave(false);
    }
  }, [studyLimits, shouldSave]);

  const addLimit = React.useCallback(() => {
    setStudyLimits(
      fixOrder([
        ...(defaultStudyLimits || []),
        {
          ...buildLimit(),
          id: uid(),
          order: 1
        }
      ])
    );
    queueSave();
    track('clicked_add_limit');
  }, [defaultStudyLimits]);

  const updateLimit = (id: number, limit: Partial<StudyLimit>) => {
    const newStudyLimits = [...studyLimits];
    const limitIndex = studyLimits.findIndex((i) => id === i.id);
    newStudyLimits[limitIndex] = { ...newStudyLimits[limitIndex], ...limit };
    setStudyLimits(newStudyLimits);
    queueSave();
  };

  const removeLimit = (id: number) => {
    const newStudyLimits: StudyLimit[] = fixOrder(studyLimits.filter((l) => l.id !== id));
    setStudyLimits(newStudyLimits);
    queueSave();
  };

  return (
    <>
      <div className='flex flex-col space-y-3'>
        {isLoading && <Skeleton className='bg-gray-50 h-12' />}
        {isSuccess &&
          fixOrder(studyLimits).map((limit, idx) => (
            <StudyLimitField
              idx={idx}
              key={limit.id}
              readOnly={readOnly}
              onChange={(l) => updateLimit(limit.id, l)}
              limit={limit}
              definitions={definitions}
              removeLimit={() => removeLimit(limit.id)}
            />
          ))}
      </div>

      {!readOnly && (
        <div className='flex items-center space-x-1'>
          <div className='flex-1'>
            <Button className='my-2' link icon='plus' onClick={addLimit}>
              Add segment
            </Button>
          </div>
          {isUpdating && (
            <div className='flex items-center space-x-1'>
              <Spinner className='h-4' />
            </div>
          )}
        </div>
      )}
    </>
  );
};
