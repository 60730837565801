import { CollectionAttribute } from 'components/shared/CollectionAttributes/types';

export const sortStudies = (initRecords: Study[], sortDef?: CollectionAttribute<Study>) => {
  if (!initRecords.length) return;

  const records = [...initRecords];

  if (!sortDef) return records;

  const sortBy = sortDef?.id === 'style' ? 'label' : sortDef?.id;
  switch (sortDef.type) {
    case 'date':
      return records.sort((a, b) => +b[sortBy] - +a[sortBy]);
    case 'number':
      return records.sort((a, b) => a[sortBy] - b[sortBy]);
    case 'multiple_choice':
      return records.sort((a, b) => (a[sortBy] || '').toString().localeCompare((b[sortBy] || '').toString()));
    case 'boolean':
      return records.sort((a, b) => (a == b ? 0 : b ? 1 : 0));
    case 'team_members':
      return records.sort((a, b) => (a?.owner?.name || '').toString().localeCompare((b?.owner?.name || '').toString()));
    case 'text':
    default:
      return records.sort((a, b) => (a[sortBy] || '').toString().localeCompare((b[sortBy] || '').toString()));
  }
};
