import { api } from 'api/reduxApi';
import * as React from 'react';
import tinytime from 'tinytime';

import { Text } from '@components/common';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

export const ConsentForms: React.FC<{ participation: Participation }> = ({ participation }) => {
  const { data: consent_forms, isSuccess } = api.useGetParticipationConsentFormQuery(
    { id: participation.id },
    {
      skip: !participation.consented
    }
  );

  if (!participation.consented) {
    return (
      <Text mb='2' color='gray-500'>
        This participant has not yet provided consent.
      </Text>
    );
  }
  return (
    <div className='gap-y-4 flex flex-col'>
      {participation.consented && <div>This participant has agreed to the standard data and opt out clauses</div>}
      {(consent_forms || []).length > 0 && (
        <Text bold mt='2'>
          {' '}
          Signed consent form
        </Text>
      )}
      {consent_forms?.map((consentForm, index) => (
        <div className=' flex flex-row items-center justify-between'>
          <div>
            {consentForm.filename && (
              <Text mb='2' h='400'>
                {consentForm.filename}
              </Text>
            )}
            <Text color='gray-500' h='400'>
              Signed {template.render(new Date(consentForm.signed_at))}
            </Text>
          </div>
          {consentForm.pdf_url && (
            <a className='h400 cursor-pointer' target='_blank' href={consentForm.pdf_url}>
              Download PDF
            </a>
          )}
        </div>
      ))}
    </div>
  );
};
