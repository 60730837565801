import React, { VFC, useEffect, useMemo, useState } from 'react';

import { Column, Grid } from '@components/common/Grid';
import { Portal } from '@components/shared/Portal';
import { SurveyBuilderSpriteSVG } from '@components/svgs';

import { surveyBuilderApi as api } from './api';
import { Blocks } from './components/Blocks';
import { BlockSettings } from './components/BlockSettings';
import { isBlockOfKind } from './helpers/isBlockOfKind';
import { usePatchBlocksQuery } from './hooks/usePatchBlocksQuery';

import * as Enums from './types/enums';
import * as Forms from './types/forms';
import * as Models from './types/models';

export interface Props {
  surveyId: number;
}

export const SurveyBuilder: VFC<Props> = ({ surveyId }) => {
  const [activeId, setActiveId] = useState<Models.Block['id']>();

  const { data: blocks = [] } = api.useGetSurveyBuilderBlocksQuery(surveyId);
  const [createBlock] = api.useCreateSurveyBuilderBlockMutation();
  const [updateBlock] = api.useUpdateSurveyBuilderBlockMutation();
  const [createBlockable] = api.useCreateSurveyBuilderBlockableMutation();
  const [updateBlockable] = api.useUpdateSurveyBuilderBlockableMutation();

  const [{ update }] = usePatchBlocksQuery(surveyId);

  const activeBlock = useMemo(() => blocks?.find(({ id }) => id === activeId), [blocks, activeId]);

  const handleOnSave = async (formData: Forms.Data) => {
    if (!activeBlock || !activeId) return;

    const { config, description, required, title } = formData;

    if (config) {
      await updateBlockable({ id: activeBlock.blockable_id, kind: activeBlock.kind, config }).unwrap();
    }

    const block = await updateBlock({ description, id: activeId, required, title }).unwrap();

    if (isBlockOfKind(block, Enums.Kind.prototypeTest) || isBlockOfKind(block, Enums.Kind.websiteTest)) {
      update(activeId, { blockable: block.blockable });
    }
  };

  const handleOnChange = (formData: Forms.Data) => {
    if (activeBlock) {
      update(activeBlock.id, {
        title: formData.title,
        description: formData.description,
        required: formData.required,
        blockable: { ...activeBlock.blockable, ...formData.config }
      });
    }
  };

  const handleOnCreate = async (kind: Models.Block['kind']) => {
    try {
      const blockable = await createBlockable({ kind }).unwrap();

      return createBlock({
        blockable_id: blockable.id,
        description: null,
        kind,
        required: false,
        surveyId,
        title: null
      }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (blocks && blocks.length > 0 && !activeId) {
      setActiveId(blocks[0].id);
    }
  }, [blocks, activeId]);

  return (
    <div className='w-full h-auto bg-white border border-gray-200 rounded'>
      <Grid desktop={12} className='overflow-hidden'>
        <Column id='surveyBuilderBlocks' desktop={4} className='border-r border-gray-200' style={{ contain: 'size' }}>
          {blocks && blocks.length > 0 && (
            <Blocks
              active={activeBlock}
              onCreateBlock={handleOnCreate}
              setActive={(block) => setActiveId(block?.id)}
              surveyId={surveyId}
            />
          )}
        </Column>
        <Column id='surveyBuilderBlockSettings' desktop={8} style={{ height: 'fit-content' }}>
          {activeBlock && (
            <BlockSettings
              block={activeBlock}
              onChange={handleOnChange}
              onSave={handleOnSave}
              style={{ minHeight: 628 }}
            />
          )}
        </Column>
      </Grid>

      <Portal>
        {/* Survey builder icons sprite */}
        <SurveyBuilderSpriteSVG style={{ display: 'none' }} />
      </Portal>
    </div>
  );
};
