import React from 'react';

import { cleanUrl } from 'components/utils';
import { LinkSVG } from 'components/svgs';

import { CreateZoomButton } from './CreateZoomButton';
import { eventUrl } from '../../helpers';

interface Props {
  participation: Participation;
  session: RepoSession;
}

export const EventUrl: React.FC<Props> = ({ participation, session }) => {
  const url = eventUrl(participation);

  const inFuture = !participation.interview_at || participation.interview_at > new Date();
  const isZoom = participation.study?.video_url_type === 'zoom';
  const wasBooked =
    ['booked', 'completed', 'canceled', 'no_show'].includes(participation.status) &&
    participation.event_details?.start_time;

  if (!url && inFuture && isZoom && wasBooked) {
    return <CreateZoomButton sessionUuid={session.uuid} participationId={participation.id} />;
  }

  if (!url) return null;

  return (
    <a href={url} target='_blank' className='flex items-center text-sm underline py-1.5 max-w-64'>
      <LinkSVG className='flex-shrink-0 inline mr-2 text-gray-700' />
      <span className='truncate'>{cleanUrl(url)}</span>
    </a>
  );
};
