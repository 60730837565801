import React, { SVGProps } from 'react';

export const ThumbsUpSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1213_3300)'>
      <path
        d='M1.48828 6.98011H2.29911C3.52277 6.98029 4.69755 6.93736 5.5704 6.07975C6.44326 5.22216 6.94437 4.05606 6.96578 2.83258C6.96578 0.858057 9.46128 1.78258 9.46128 3.54425V5.81342C9.46128 6.45775 9.9836 6.98011 10.6279 6.98011H13.3745C13.9846 6.98011 14.4975 7.45063 14.5096 8.06068C14.548 10.0069 14.2236 11.4225 13.4366 12.9292C12.9828 13.7979 12.0405 14.2829 11.0607 14.2609C4.24991 14.1085 5.1309 12.9593 1.48828 12.9593'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1213_3300'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
