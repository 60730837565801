import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { LegalIndex } from './LegalIndex';
import { TeamLegalIndex } from './TeamLegalIndex';

export const AccountLegalApp: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='legal' element={<LegalIndex />} />
        <Route path='teams/:id/legal' element={<TeamLegalIndex />} />
      </Routes>
    </Router>
  );
};
