import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { api } from '@api/reduxApi';
import { Button, SlideOut, Text } from '@components/common';
import { Label } from '@components/fields';
import { useToaster } from '@stores/toaster';

import { UseTableFilters } from '../../hook/useTableFilters';
import { isFilterApplyable } from '../../utils/isFilterApplyable';
import { Filter } from '../Filter';
import { makeSegmentPayload } from './utils';
import { TableFilters } from '@components/shared/TableFilters';
import { TrashSVG } from '@components/svgs';
import * as toasts from '@components/SegmentsApp/toasts';

interface Props {
  open: boolean;
  onClose: () => void;
  onCreate: (segment: CustomerSegment) => void;
  name?: string;
  hook: UseTableFilters<Candidate>;
  id?: string;
  segment?: CustomerSegment | null;
  editable?: boolean;
  onDelete?: () => void;
}

type Form = { name: string };

export const SegmentSlideOut: React.FC<Props> = ({
  editable,
  segment,
  open,
  onClose,
  name: defaultName,
  hook,
  id,
  onCreate,
  onDelete
}) => {
  const submitRef = useRef<HTMLButtonElement>(null);

  const { handleSubmit, register, errors } = useForm<Form>({
    defaultValues: { name: defaultName }
  });

  const [createSegment, { isLoading: creating, isError: creatingError, isSuccess: created, data }] =
    api.useCreateSegmentMutation();
  const [updateSegment, { isLoading: updating, isError: updatingError, isSuccess: updated }] =
    api.useUpdateSegmentMutation();

  const showToast = useToaster();

  useEffect(() => {
    if (creatingError) {
      showToast(toasts.failedCreate());
    }

    if (updatingError) {
      showToast(toasts.failedUpdate());
    }

    if (created && data?.name) {
      showToast(toasts.successCreate(data.name));
      onCreate(data as CustomerSegment);
      onClose();
    }

    if (updated && segment?.name) {
      showToast(toasts.successUpdate(segment.name));
      onClose();
    }
  }, [creatingError, updatingError, created, updated]);

  const onSubmit = (data: Form) => {
    segment
      ? updateSegment({ id: Number(id), name: data.name, filters: makeSegmentPayload(hook) })
      : createSegment({ name: data.name, filters: makeSegmentPayload(hook) });
  };

  const filtersToShow = hook.filters.filter(isFilterApplyable);

  if (!open) {
    return null;
  }

  return (
    <SlideOut
      onClose={onClose}
      closeOnEsc
      title={segment ? (editable ? 'Edit segment' : 'Save as segment') : 'Save as segment'}
      renderFooter={() => (
        <>
          <div className='flex justify-between w-full'>
            <div className='space-x-4'>
              <Button
                primary
                onClick={() => {
                  submitRef.current && submitRef.current.click();
                }}
                disabled={creating || updating}
              >
                {segment ? (editable ? 'Save' : 'Create') : 'Create'}
              </Button>
              {editable && <Button onClick={onClose}>Cancel</Button>}
            </div>

            {editable && segment && (
              <Button onClick={onDelete}>
                <div className='mr-2'>Delete</div>
                <TrashSVG />
              </Button>
            )}
          </div>
        </>
      )}
    >
      <div className='h-full p-6 text-left'>
        <form className='flex flex-col justify-between h-full' onSubmit={handleSubmit(onSubmit)}>
          <div className='flex-1'>
            <div className='mb-10'>
              <Label>Segment name</Label>

              <div className='mt-1.5'>
                <input
                  type='text'
                  name='name'
                  ref={register({ required: true, validate: (value) => value.trim().length > 0 })}
                  className={cn(
                    'focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm block w-full text-gray-700 placeholder-gray-400 border-gray-200 rounded-md',
                    { 'border-red-600': errors.name }
                  )}
                  defaultValue={segment?.name || ''}
                  placeholder='Enter name...'
                  autoComplete='off'
                />
                {errors.name && (
                  <Text h='200' color='red-600' mt='1'>
                    This field is required.
                  </Text>
                )}
              </div>
            </div>
            <Label>Criteria</Label>
            <div className='pt-2'>
              {editable ? (
                <TableFilters<Candidate> hook={hook} editable={editable} />
              ) : (
                <ul className='mt-6 space-y-6'>
                  {filtersToShow.map((filter, i) => (
                    <li className='flex items-center space-x-3' key={filter.definition.id}>
                      <Filter state={filter} />
                      {i !== filtersToShow.length - 1 && (
                        <Text as='span' h='400' color='gray-500'>
                          {hook.op === 'any' ? 'or' : 'and'}
                        </Text>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <button type='submit' className='hidden' ref={submitRef} />
        </form>
      </div>
    </SlideOut>
  );
};
