type CancelAllUploads = () => void;

export const getCancelAllUploads = (
  items: UploadManagerItem[],
  setItems: (items: UploadManagerItem[]) => void
): CancelAllUploads => {
  const cancelAll = () => {
    const inProgress = items.filter((i) => i.status === 'uploading');

    inProgress.forEach((i) => {
      if (i.type === 'recording') {
        i.cancelUpload?.();
      }
    });

    setItems(items.map((i) => ({ ...i, status: i.status === 'done' ? 'done' : 'canceled' })));
  };

  return cancelAll;
};
