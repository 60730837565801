import 'i18n/translations';

import * as React from 'react';

import { Provider as ToastProvider } from '@components/stores/toaster';
import { renderComponent } from '@components/utils';

import { CandidateConsent } from './CandidateConsent';

const Wrapper: React.FC<any> = ({ contact_preferences, consent_token, account, language }) => {
  return (
    <ToastProvider>
      <CandidateConsent
        contact_preferences={contact_preferences}
        consent_token={consent_token}
        account={account}
        language={language}
      />
    </ToastProvider>
  );
};

export function mount(): void {
  renderComponent(Wrapper, 'candidate-consent', ({ consent_token, contact_preferences, account, language }) => ({
    contact_preferences,
    consent_token,
    account,
    language
  }));
}

export default Wrapper;
