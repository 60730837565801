import { api } from 'api/reduxApi';
import { DropdownItem } from 'components/common/DropdownCombobox';
import { humanize } from 'components/utils';
// helpers
import { emailPattern } from 'helpers/emailRegexpValidation';
import { useAccount } from 'hooks/useAccount';
import { useCandidateAttrs } from 'hooks/useCandidateAttrs';
import React, { useEffect, useMemo, useRef } from 'react';
// hooks
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

// api
import { bulkShortlist, createCandidate } from '@api/queries';
import { Checkbox, SlideOut, TeamIcon, Text, Tooltip } from '@components/common';
import { ProtectionSvg } from '@components/svgs';
import { useToaster } from '@stores/toaster';

import { AddAttribute } from '../AddAttribute';
import { AttributesTable } from './components/AttributesTable';
import { ControlledInput } from './components/ControlledInput';
import { SlideOutFooter } from './components/SlideOutFooter';
import { SlideOutHeader } from './components/SlideOutHeader';
// components
import * as toasts from './components/toasts';

const KEY_CODES = [40, 13]; // arrowDown and  Enter. The tab button works by default

const nameValidationRules = {
  required: {
    value: true,
    message: 'Please enter name'
  },
  validate: (value) => !!value.trim() || 'Please enter a valid name'
};

const uniqueIdValidationRules = {
  email: {
    required: { value: true, message: 'Please enter an email address' },
    pattern: { value: emailPattern, message: 'Please enter a valid email address.' }
  },
  phone_number: {
    required: { value: true, message: 'Please enter an phone number' }
  },
  default: {
    required: { value: true, message: 'Please enter a value' }
  }
};

export const STYLES: AttrRowsStyles = {
  candidateDetail: {
    row: 'tablet:grid tablet:grid-cols-7',
    label: 'align-top py-2.5 tablet:align-middle col-span-2 tablet:py-4 text-sm font-medium leading-5',
    value: 'flex flex-1 align-middle tablet:mt-0 tablet:col-span-4 py-2 mt-1 text-sm leading-5',
    divide: 'border-b border-gray-200',
    svgButtons: 'flex align-middle items-center p-2 justify-around group-hover:bg-gray-50 col-span-1'
  },
  slideOut: {
    row: 'tablet:grid tablet:grid-cols-7',
    label: 'align-top py-2.5 tablet:align-middle col-span-2 font-medium tablet:py-4 pr-8 ',
    value: 'align-middle tablet:mt-0 tablet:col-span-4 py-2 mt-1 text-sm leading-5',
    divide: 'border-b border-gray-200',
    svgButtons: 'flex align-middle items-center p-2 justify-around group-hover:bg-gray-50 col-span-1'
  },
  addCandidate: {
    row: 'tablet:grid tablet:grid-cols-3 tablet:gap-2',
    label: 'tablet:py-4 text-sm font-medium leading-5 truncate',
    value: 'align-middle tablet:mt-0 tablet:col-span-2 py-2 mt-1 text-sm leading-5',
    divide: 'border-b border-gray-200',
    svgButtons: ''
  }
};

interface FormValues {
  name: string;
  email: string;
}

interface Props {
  onClose: () => void;
  studyId?: number;
  onAdd?: (params: { candidate: Candidate; background_task?: BackgroundTask }) => void;
  style: keyof typeof STYLES;
  defaultValues?: Partial<FormValues>;
  canAddMore?: boolean;
  team: Team | null;
}

const FORM_DEFAULT_VALUES: FormValues = {
  name: '',
  email: ''
};

const DataProtectionMessage = () => (
  <div className='flex mt-8'>
    <ProtectionSvg className='mr-2' />
    <Text className='text-xs leading-4'>
      We protect your customers’ data like it's our own.
      <a href='https://greatquestion.co/security' target='_blank' className='ml-1 font-medium text-gray-700 underline'>
        Learn More
      </a>
      .
    </Text>
  </div>
);

const DEBOUNCE_DELAY = 300;

export const AddCandidateSlideOut: React.FC<Props> = ({
  canAddMore = true,
  style,
  studyId,
  onClose,
  onAdd,
  defaultValues,
  team
}) => {
  const [loading, setLoading] = useState(false);
  const [newAttr, setNewAttr] = useState<Attr_[]>([]);
  const [sendOptIn, setSendOptIn] = useState(false);
  const [addToTeam, setAddToTeam] = useState(!!team);

  const [search, setSearch] = useState('');

  const { callback: onSearch } = useDebouncedCallback((value) => {
    setSearch(value);
  }, DEBOUNCE_DELAY);

  const { data: fetchedData, isFetching } = api.useGetServerSideCandidatesQuery(
    {
      items: 1,
      page: 1,
      searchQuery: search
    },
    { skip: !search }
  );

  const {
    account: { unique_id_attr }
  } = useAccount();

  const inputRefs = useRef<HTMLInputElement[]>([]);

  const showToast = useToaster();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
    setError,
    clearErrors
  } = useForm({
    defaultValues: { ...FORM_DEFAULT_VALUES, ...defaultValues }
  });

  const resetForm = () => {
    setNewAttr([]);
    reset(FORM_DEFAULT_VALUES);
  };

  const addNewAttrToList = (attr: Attr_): void => {
    setNewAttr([...newAttr, attr]);
  };

  const submitFunc = (addMore?: boolean) => async (data: any) => {
    setLoading(true);
    try {
      const teamId = addToTeam ? team?.id : undefined;

      const candidate = await createCandidate({ team_id: teamId, send_opt_in: sendOptIn, ...data });

      if (candidate) {
        let background_task: BackgroundTask | undefined;
        if (studyId) {
          const resp = await bulkShortlist(studyId, { customer_ids: [candidate.id] });
          background_task = resp.background_task;
        }

        showToast(toasts.SUCCESS(`${candidate.name} has been added.`, candidate.id));
        addMore ? resetForm() : onClose();
        onAdd?.({ candidate, background_task });
      } else {
        showToast(toasts.FAILED_UPLOAD);
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent, id: string) => {
    // If  key is pressed, find and focus next input field.
    if (KEY_CODES.includes(e.keyCode)) {
      e.preventDefault();
      const currentInputNumber = parseInt(id.charAt(id.length - 1));
      inputRefs.current[currentInputNumber + 1]?.focus?.();
    }
  };

  const styles = STYLES[style];

  const { candidateAttrs } = useCandidateAttrs();

  const options: DropdownItem[] = useMemo(
    () => candidateAttrs.map((attr) => ({ value: attr.name, label: attr.label, data: attr })),
    [candidateAttrs]
  );

  useEffect(() => {
    const fetchedValue: string | null | undefined = fetchedData?.data?.[0]?.[unique_id_attr];
    const formValue: string = getValues(unique_id_attr);
    if (!fetchedValue || !formValue) {
      if (errors[unique_id_attr]) {
        clearErrors(unique_id_attr);
      }
      return;
    }
    if (fetchedValue.toLowerCase() === formValue.toLowerCase()) {
      setError(unique_id_attr, {
        type: 'manual',
        message: String(fetchedData?.data?.[0].id)
      });
    } else if (errors[unique_id_attr]) {
      clearErrors(unique_id_attr);
    }
  }, [fetchedData]);

  return (
    <SlideOut
      noHeader
      size='lg'
      onClose={onClose}
      closeOnEsc={false}
      renderFooter={() => (
        <SlideOutFooter
          handleSave={handleSubmit(submitFunc())}
          handleSaveAndAddMore={handleSubmit(submitFunc(true))}
          onClose={onClose}
          loading={loading || isFetching}
          error={Object.keys(errors).length > 0}
          canAddMore={canAddMore}
        />
      )}
    >
      <SlideOutHeader />
      <div className='px-6 py-4'>
        <form id='form1'>
          <table className='w-full'>
            <tbody>
              <ControlledInput
                ref={(el) => {
                  if (el) {
                    inputRefs.current[0] = el;
                  }
                }}
                control={control}
                name='name'
                data-testid='name'
                autoFocus
                label='Name'
                errors={errors}
                id='input-0'
                styles={styles}
                rules={nameValidationRules}
                handleKeyDown={handleKeyDown}
              />

              <ControlledInput
                ref={(el) => {
                  if (el) {
                    inputRefs.current[1] = el;
                  }
                }}
                control={control}
                name={unique_id_attr}
                label={humanize(unique_id_attr)}
                id='input-1'
                data-testid='email'
                styles={styles}
                onChange={onSearch}
                errors={errors}
                rules={uniqueIdValidationRules[unique_id_attr]}
                handleKeyDown={handleKeyDown}
              />

              <AttributesTable newAttr={newAttr} control={control} getValues={getValues} styles={styles} />
            </tbody>
          </table>
        </form>

        <AddAttribute addNewAttrToList={addNewAttrToList} visibleAttrs={newAttr} options={options} />

        {!studyId && (
          <div className='flex flex-row space-x-2'>
            <Checkbox name='send_opt_in' selected={sendOptIn} onChange={(v) => setSendOptIn(v)} />
            <Text h='400'>
              Send{' '}
              <a className='font-bold underline' target='_blank' href='/studies/panel/welcome_email'>
                panel opt in message
              </a>{' '}
              to candidate
            </Text>
            <Tooltip>
              The candidate will receive an automated message where they can confirm their consent to be contacted for
              future research{' '}
            </Tooltip>
          </div>
        )}

        {team && (
          <div className='flex items-center mt-6 space-x-2'>
            <Checkbox id='add_to_team' name='add_to_team' selected={addToTeam} onChange={(v) => setAddToTeam(v)} />
            <label htmlFor='add_to_team' className='h400 inline-flex items-center'>
              <span className='mr-2'>Set Contact Access to</span>
              <TeamIcon team={team} />
              <span className='ml-1'>{team.name}</span>
            </label>
          </div>
        )}

        <DataProtectionMessage />
      </div>
    </SlideOut>
  );
};
