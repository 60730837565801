import React, { SVGProps } from 'react';

export const NoRecordingSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_1556_27411)'>
        <path
          d='M15 23C15 25.1217 15.8429 27.1566 17.3431 28.6569C18.8434 30.1571 20.8783 31 23 31C25.1217 31 27.1566 30.1571 28.6569 28.6569C30.1571 27.1566 31 25.1217 31 23C31 20.8783 30.1571 18.8434 28.6569 17.3431C27.1566 15.8429 25.1217 15 23 15C20.8783 15 18.8434 15.8429 17.3431 17.3431C15.8429 18.8434 15 20.8783 15 23Z'
          stroke='#9FA6B2'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.344 28.6547L28.656 17.344'
          stroke='#9FA6B2'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 27H3C2.46957 27 1.96086 26.7893 1.58579 26.4142C1.21071 26.0391 1 25.5304 1 25V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17.172C17.7021 1.00011 18.2104 1.21064 18.5853 1.58533L22.4147 5.41467C22.7894 5.7896 22.9999 6.29794 23 6.828V11'
          stroke='#9FA6B2'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.32133 16.904C8.18207 16.9735 8.02735 17.0063 7.87185 16.9992C7.71635 16.9922 7.56524 16.9455 7.43287 16.8636C7.30049 16.7817 7.19123 16.6673 7.11547 16.5314C7.03972 16.3954 6.99997 16.2423 7 16.0866V9.91332C6.99997 9.75766 7.03972 9.60457 7.11547 9.4686C7.19123 9.33262 7.30049 9.21826 7.43287 9.13638C7.56524 9.05449 7.71635 9.0078 7.87185 9.00073C8.02735 8.99366 8.18207 9.02645 8.32133 9.09598L14.496 12.184C14.6476 12.2597 14.7752 12.3761 14.8643 12.5202C14.9535 12.6644 15.0007 12.8305 15.0007 13C15.0007 13.1695 14.9535 13.3356 14.8643 13.4797C14.7752 13.6239 14.6476 13.7403 14.496 13.816L8.32133 16.904Z'
          stroke='#9FA6B2'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1556_27411'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
