import cn from 'classnames';
import { Text, Tooltip } from 'components/common';
import React, { forwardRef, HTMLAttributes } from 'react';

import { CheckSVG } from '@components/svgs';
import Tippy from '@tippyjs/react';

import { DropdownItemProps } from '../types';

export type ItemProps = HTMLAttributes<HTMLLIElement> & DropdownItemProps;

export const Item = forwardRef<HTMLLIElement, ItemProps>(
  ({ __dropdownItemProps: { item, active, highlighted }, className, ...rest }, ref) => {
    const text = item.created ? `Create "${item.label}"` : item.label;
    return (
      <li
        ref={ref}
        {...rest}
        aria-selected={active}
        role='option'
        className={cn(
          'flex flex-row items-center justify-between px-3 py-2 cursor-pointer',
          {
            'bg-indigo-600 text-white': highlighted
          },
          className
        )}
      >
        <Tippy content={text}>
          <span className='w-full'>
            <Text h='400' bold={active} truncate>
              {text}
            </Text>
          </span>
        </Tippy>
        {active && (
          <CheckSVG className={cn('w-3 h-3', { 'text-indigo-600': !highlighted, 'text-white': highlighted })} />
        )}
      </li>
    );
  }
);
