import cn from 'classnames';
import { Button } from '@components/common';
import { ImageSVG } from '@components/InsightsApp/svgs';
import { TrashSVG } from '@components/svgs';
import * as React from 'react';

interface Props {
  imageUrl?: string;
  onRemove: () => void;
  openAttachModal: () => void;
}

export const InsightCoverImage: React.FC<Props> = ({ imageUrl, onRemove, openAttachModal }) => (
  <div style={imageUrl ? { paddingBottom: '29.75%' } : {}} className='relative w-full h-32 mb-4'>
    {imageUrl && <img alt='Cover' className='absolute object-cover w-full h-full rounded-md' src={imageUrl} />}
    <div
      className={cn(
        'absolute flex flex-col justify-end items-center space-y-2 tablet:space-y-0 tablet:flex-row tablet:items-end tablet:justify-end p-2 rounded-md w-full h-full',
        { 'bg-gray-200': !imageUrl }
      )}
    >
      <div className='flex space-x-2'>
        {imageUrl ? (
          <Button className='whitespace-nowrap' medium onClick={openAttachModal}>
            <ImageSVG className='mr-2' />
            Change
          </Button>
        ) : (
          <Button className='whitespace-nowrap' medium onClick={openAttachModal}>
            <ImageSVG className='mr-2' />
            Add cover photo
          </Button>
        )}
        {imageUrl && (
          <Button medium onClick={onRemove}>
            <TrashSVG />
          </Button>
        )}
      </div>
    </div>
  </div>
);
