import { useRef } from 'react';
import { Coordinates } from '@dnd-kit/core/dist/types';

type Result = {
  velocity: number;
  onSwipe: (delta: Coordinates) => void;
};

export const useSwipeVelocity = (): Result => {
  const cache = useRef({ distance: 0, timestamp: 0, velocity: 0 });

  const onSwipe = (delta: Coordinates) => {
    const timestamp = Date.now();
    const timeDelta = timestamp - cache.current.timestamp;
    const distance = cache.current.distance - delta.y;
    const velocity = Math.round((distance / timeDelta) * 1000);

    cache.current = { distance: delta.y, velocity, timestamp };
  };

  return { velocity: Math.abs(cache.current.velocity), onSwipe };
};
