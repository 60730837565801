export function removeHash(): void {
  history.replaceState('', document.title, window.location.pathname + window.location.search);
}

export function setHash(hash: string): void {
  history.replaceState('', document.title, window.location.pathname + window.location.search + '#' + hash);
}

const IS_MAC = navigator.platform.includes('Mac'); // https://stackoverflow.com/a/27862868

const MOD = IS_MAC ? '⌘' : 'Ctrl';
const SHIFT = IS_MAC ? '⇧' : 'Shift';

// shortcut is a prosemirror keymap, looks like "Mod+Shift+K"
export const macOrWindowsify = (shortcut: string) => {
  const keys = shortcut.split('+').map((k) => {
    if (k === 'Mod') return MOD;
    if (k === 'Shift') return SHIFT;
    return k;
  });

  return IS_MAC ? keys.join('') : keys.join('+');
};
