import React, { SVGProps } from 'react';

export const LanguageSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M8.5 0.50001C7.25253 0.498212 6.02731 0.830286 4.95149 1.46177C3.87566 2.09326 2.98843 3.00115 2.38186 4.09122C1.7753 5.18129 1.47151 6.41383 1.50201 7.66092C1.53251 8.90802 1.8962 10.1242 2.55533 11.1833L0.5 15.5L4.816 13.4447C5.73647 14.017 6.77726 14.3677 7.85637 14.4692C8.93548 14.5707 10.0234 14.4203 11.0345 14.0297C12.0455 13.6392 12.9521 13.0191 13.6827 12.2185C14.4133 11.4179 14.948 10.4586 15.2447 9.41609C15.5414 8.37361 15.5919 7.27648 15.3924 6.21113C15.1928 5.14579 14.7486 4.14134 14.0947 3.27693C13.4408 2.41253 12.5951 1.71177 11.6242 1.22988C10.6534 0.747997 9.58387 0.498144 8.5 0.50001V0.50001Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M5.49866 5.5H11.4987' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M4.49866 7.5H12.4987' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5.49866 9.5H11.4987' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
