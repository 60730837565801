import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { Checkbox, Select, SelectOption, TeamIcon, Text } from '@components/common';
import { SelectProps } from '@components/common/Select';
import { AATeamMembers } from '@components/AccountTeamsApp/components/icons';
import { BankSVG, NoCircleSVG } from '@components/svgs';
import { usePermission } from '@hooks/usePermission';
import { useTeams } from '@hooks/useTeams';
import { useUser } from '@hooks/useUser';

type Props = {
  className?: string;
  disabled?: boolean;
  initialValue?: ContactAccessType;
  initialTeamIds?: number[];
  onChangeValue?: (value: ContactAccessType) => void;
  onChangeTeamIds: (teamIds: number[]) => void;
  selectProps?: Partial<SelectProps<ContactAccessType>>;
};

const OPTIONS: SelectOption<ContactAccessType | 'no_one'>[] = [
  {
    label: 'Anyone on the workspace',
    value: 'public',
    Icon: BankSVG
  },
  {
    label: 'Only specific teams',
    value: 'needs_team',
    Icon: AATeamMembers
  },
  {
    label: 'No one',
    value: 'no_one',
    Icon: NoCircleSVG
  }
];

export const ContactAccess: React.FC<Props> = ({
  className,
  disabled,
  initialValue,
  initialTeamIds,
  onChangeValue,
  onChangeTeamIds,
  selectProps
}) => {
  const [value, setValue] = React.useState<string>(() =>
    initialValue === 'needs_team' && initialTeamIds?.length === 0 ? 'no_one' : initialValue || 'public'
  );
  const [teamIds, setTeamIds] = useState<number[]>(initialTeamIds || []);

  const user = useUser();
  const canSeeAllTeams = usePermission('manageTeams')();
  const { teams: allTeams } = useTeams();

  const teams = canSeeAllTeams ? allTeams : allTeams?.filter((team) => user.teams?.map((t) => t.id).includes(team.id));

  const disabledTeams = allTeams?.filter(
    (team) => !canSeeAllTeams && teamIds.includes(team.id) && !user.teams?.map((t) => t.id).includes(team.id)
  );

  const onChange = (team: Team) => {
    const ids = teamIds.includes(team.id) ? teamIds.filter((id) => id !== team.id) : [...teamIds, team.id];
    setTeamIds(ids);
    onChangeTeamIds(ids);
  };

  const renderTeam = (team: Team, disabled = false) => (
    <div className='flex items-center space-x-2 py-1.5 px-2' key={team.id}>
      <Checkbox
        disabled={disabled}
        value={team.id.toString()}
        selected={teamIds.includes(team.id)}
        onChange={() => onChange(team)}
      />
      <TeamIcon team={team} />
      <Text h='400' truncate>
        {team.name}
      </Text>
    </div>
  );

  const onSelect = (value: ContactAccessType | 'no_one') => {
    setValue(value);
    onChangeValue?.(value === 'public' ? 'public' : 'needs_team');
    if (value === 'no_one' && teamIds.length > 0) {
      setTeamIds([]);
      onChangeTeamIds([]);
    }
  };

  return (
    <div>
      <Select<ContactAccessType | 'no_one'>
        disabled={disabled}
        options={OPTIONS}
        className={cn('w-full', className)}
        overflowClass='visible'
        value={value}
        onChange={onSelect}
        {...selectProps}
      />

      {value === 'public' && <div className=''></div>}
      {value === 'needs_team' && (
        <div className='max-h-80 pt-2 overflow-y-auto'>
          {disabledTeams?.map((t) => renderTeam(t, true))}
          {teams?.map((t) => renderTeam(t))}
        </div>
      )}
    </div>
  );
};
