import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Avatar, Button, Option, Pill, Text } from '@components/common';
import { PillProps } from '@components/common/Pill';
import { CopyText } from '@components/shared/CopyText';
import { SortDropdown } from '@components/shared/SortDropdown';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { usePermission } from '@hooks/usePermission';

import { SeatInfo } from '../../components/SeatInfo';

export const OPTIONS: Option[] = [
  {
    value: 'role',
    label: 'Role'
  },
  {
    value: 'status',
    label: 'Status'
  },
  {
    value: 'name',
    label: 'Name'
  }
];

type RoleStates = 'deactivated' | 'invited' | 'joined' | 'pending';
const ROLE_COLORS: { [key in RoleStates]: PillProps['color'] } = {
  deactivated: 'orange',
  invited: 'yellow',
  joined: 'green',
  pending: 'blue'
};

interface Props {
  list: 'active' | 'deactivated';
}

interface Member {
  name: string;
  email: string;
  status: RoleStates;
  deactivated: boolean;
  role?: AccountRole;
  link?: string;
  token?: string;
  account_user_id?: number;
}

export const UsersList: React.FC<Props> = ({ list }) => {
  const { account: account } = useAccount();

  const isAdmin = usePermission('isAdmin')();

  const [storedSort, setStoredSort] = useLocalStorage<string>('team-members-sort-value');
  const [sort, setSort] = useState(storedSort);

  const showDeactivated = list === 'deactivated';

  const { data: invitations = [] } = api.useGetInvitationsQuery();

  const { team } = account;

  const members = useMemo(
    () => [
      ...team.map((member) => ({ ...member, status: member.is_phantom ? 'pending' : 'joined' })),
      ...invitations.map((member) => ({ ...member, status: 'invited' }))
    ],
    [team, invitations]
  ) as Member[];

  const sortedMembers = useMemo(() => {
    if (!sort) return members;

    return members.sort((a, b) => {
      if (a[sort] && b[sort]) {
        return a[sort].localeCompare(b[sort]);
      }
      return 0;
    });
  }, [members, sort]);

  const activeMembers = sortedMembers.filter(
    (user) => !user.deactivated && user.role !== 'deactivated' && user.status !== 'invited'
  );
  const invitedMembers = sortedMembers.filter((user) => user.status === 'invited');
  const deactivedMembers = sortedMembers.filter(({ role }) => role === 'deactivated');
  const listMembers = showDeactivated ? deactivedMembers : activeMembers.concat(invitedMembers);

  const onSortChange = (v: CollectionView['sort']) => {
    setSort(v.value);
    setStoredSort(v.value);
  };

  return (
    <>
      <div className='flex justify-between w-full'>
        <Text h='600' bold className='mb-2'>
          {pluralize('member', (showDeactivated ? deactivedMembers : activeMembers).length, true)}
          {!showDeactivated && invitedMembers.length > 0 && (
            <Text h='400' italic className='mb-2'>
              {pluralize('invite', invitedMembers.length, true)}
            </Text>
          )}
        </Text>
        <div className='flex justify-end'>
          <SortDropdown options={OPTIONS} value={sort} onChange={onSortChange} />
        </div>
      </div>

      <SeatInfo />
      <div className='overflow-auto'>
        <table className='w-full mt-6 overflow-x-auto'>
          <thead>
            <tr>
              <th className='p-3 pl-0 text-sm text-left text-gray-500'>Member</th>
              <th className='p-3 text-sm text-left text-gray-500'>Role</th>
              <th className='p-3 text-sm text-left text-gray-500'>Status</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody className='divide-y divide-gray-200'>
            {listMembers.map((member) => (
              <tr className='group text-gray-700'>
                <td className='flex flex-row items-center p-3 pl-0 space-x-3 truncate'>
                  <Avatar user={member} />
                  <span>{member.name || member.email}</span>
                </td>
                <td className='p-3'>{member.role}</td>
                <td className='p-3'>
                  {!showDeactivated && member.status && <Pill color={ROLE_COLORS[member.status]}>{member.status}</Pill>}
                  {showDeactivated && <Pill color={ROLE_COLORS['deactivated']}>Deactivated</Pill>}
                </td>
                <td>
                  {isAdmin && member.link && (
                    <CopyText small link text={member.link}>
                      Copy link
                    </CopyText>
                  )}
                </td>
                <td className='text-right'>
                  {isAdmin && member.status === 'invited' && (
                    <Button spa rounded link href={`/invitations/${member.token}/edit`} icon='pencil' />
                  )}
                  {isAdmin && ['joined', 'pending'].includes(member.status) && (
                    <Button
                      rounded
                      link
                      spa
                      href={`/accounts/${account.id}/members/${member.account_user_id}/edit`}
                      icon='pencil'
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
