import React, { VFC } from 'react';

import { BLOCK_PALETTES } from '../constants';

import * as Models from '../types/models';
import cn from 'classnames';

interface Props {
  kind: Models.BlockKind;
  isCompact?: boolean;
  className?: string;
}

export const BlockIcon: VFC<Props> = ({ className, kind, isCompact }) => {
  return (
    <div
      className={cn(
        'flex items-center justify-center rounded',
        { 'w-6 h-6': isCompact, 'w-10 h-10': !isCompact },
        className
      )}
      style={{ backgroundColor: BLOCK_PALETTES[kind] }}
    >
      <svg className={cn('h-6 w-6 text-white', isCompact && 'transform scale-50')}>
        <use href={`#${kind}`} />
      </svg>
    </div>
  );
};
