import * as React from 'react';

import { Text, Toggle, Tooltip } from '@components/common';

import { Batching } from '../types';

type Props = {
  batch: Batching;
  setBatch: (v: Batching) => void;
};

export const ContinuousFields: React.FC<Props> = ({ batch, setBatch }) => (
  <div className='flex flex-row space-x-2'>
    <Toggle name='auto_restart' on={batch.auto_restart} onToggle={(v) => setBatch({ ...batch, auto_restart: v })} />
    <div className=''>
      <div className='flex items-center mt-1 mb-2'>
        <Text h='400' bold className='mr-2'>
          Continuous invites
        </Text>
        <Tooltip>
          Invites will only be sent to candidates you selected but haven’t received an invite yet, or invited people who
          are on the waitlist. Slots may open up if you increase the participant limit, in the event of a cancellation
          or no-show, or if your calendar settings reveal more slots as time passes.
        </Tooltip>
      </div>
      <div>
        <Text h='400'>Restart sending invites if slots open up.</Text>
      </div>
    </div>
  </div>
);
