import classNames from 'classnames';
// from https://tailwindui.com/components/application-ui/navigation/steps#component-ef491b1515ff05e8cc7429f37bc0fae5
import * as React from 'react';

import { CheckMark2SVG } from '@components/svgs';

const renderATag: StepperProps['component'] = (props) => <a {...props} />; // eslint-disable-line jsx-a11y/anchor-has-content

export interface StepperProps {
  steps: {
    id: string;
    name: string;
    href: string;
    status: 'complete' | 'current' | 'upcoming';
  }[];
  component?: React.FC<{ href: string; className: string }>;
}

function className(status: string) {
  return classNames(
    { 'group flex items-center w-full': status === 'complete' },
    { 'flex items-center px-6 py-3 text-sm font-medium': status === 'current' }
  );
}

export const Stepper: React.FC<StepperProps> = ({ steps, component: Link = renderATag }) => (
  <nav className='bg-white' aria-label='Progress'>
    <ol className='tablet:flex tablet:divide-y-0 px-page -ml-6 border-b border-gray-300 divide-y divide-gray-300'>
      {steps.map((step, stepIdx) => (
        <Link
          key={step.name}
          href={step.href}
          className='tablet:flex-1 tablet:flex relative'
          aria-current={stepIdx === 1 ? 'step' : null}
        >
          {step.status === 'complete' ? (
            <li key={step.name} className={className(step.status)}>
              <span className='flex items-center px-6 py-3 text-sm font-medium'>
                <span className='group-hover:bg-indigo-800 flex items-center justify-center flex-shrink-0 w-6 h-6 bg-indigo-600 rounded-full'>
                  <CheckMark2SVG className='w-4 h-4 text-white' aria-hidden='true' />
                </span>
                <span className='ml-4 text-sm font-medium text-gray-900'>{step.name}</span>
              </span>
            </li>
          ) : step.status === 'current' ? (
            <li key={step.name} className={className(step.status)}>
              <span className='flex items-center justify-center flex-shrink-0 w-6 h-6 border-2 border-indigo-600 rounded-full'>
                <span className='h400 text-indigo-600'>{step.id}</span>
              </span>
              <span className='ml-4 text-sm font-medium text-indigo-600'>{step.name}</span>
            </li>
          ) : (
            <li key={step.name} className='group flex items-center'>
              <span className='flex items-center px-6 py-3 text-sm font-medium'>
                <span className='group-hover:border-gray-400 flex items-center justify-center flex-shrink-0 w-6 h-6 border-2 border-gray-300 rounded-full'>
                  <span className='group-hover:text-gray-900 h400 text-gray-500'>{step.id}</span>
                </span>
                <span className='group-hover:text-gray-900 ml-4 text-sm font-medium text-gray-500'>{step.name}</span>
              </span>
            </li>
          )}

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div className='tablet:block absolute top-0 right-0 hidden w-5 h-full' aria-hidden='true'>
                <svg className='w-full h-full text-gray-300' viewBox='0 0 22 80' fill='none' preserveAspectRatio='none'>
                  <path
                    d='M0 -2L20 40L0 82'
                    vectorEffect='non-scaling-stroke'
                    stroke='currentcolor'
                    strokeLinejoin='round'
                  />
                </svg>
              </div>
            </>
          ) : null}
        </Link>
      ))}
    </ol>
  </nav>
);
