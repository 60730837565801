import cn from 'classnames';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { BaseTagPill } from '@components/tags';
import Tippy from '@tippyjs/react';
import { TagDropdown } from './TagDropdown';
import { AskAiSVG } from '@components/svgs';

type Props = {
  id: number;
  tag: Tag;
  onChange: React.FormEventHandler;
  checked: boolean;
  readOnly?: boolean;
  isGhosting: boolean;
  selectedCount: number;
  studyId: number | undefined;
  onDeleteTag: (e: React.MouseEvent<any>) => void;
};

export const Tag: React.FC<Props> = ({
  id,
  tag,
  studyId,
  onChange,
  checked,
  isGhosting,
  selectedCount,
  readOnly = false,
  onDeleteTag
}) => {
  const backgroundColor = () => {
    if (isGhosting) {
      return 'bg-blue-50';
    }

    return 'bg-white';
  };

  return (
    <Tippy maxWidth='full' arrow={false} content={tag.name}>
      <div>
        <Draggable draggableId={String(id)} index={0} isDragDisabled={readOnly}>
          {(provided, snapshot) => {
            const shouldShowCount = snapshot.isDragging && selectedCount > 0;

            return (
              <div
                role='button'
                className={cn(
                  'relative rounded border border-gray-200 flex flex-row items-center justify-between hover:bg-gray-50',
                  backgroundColor()
                )}
                tabIndex={0}
                onClick={onChange}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div className='py-2.5 px-2 flex items-center flex-nowrap flex-shrink overflow-hidden'>
                  {!readOnly && (
                    <input
                      type='checkbox'
                      onChange={onChange}
                      checked={checked}
                      className='focus:ring-indigo-500 w-4 h-4 text-indigo-600 border-gray-300'
                    />
                  )}
                  <BaseTagPill
                    tag={tag.name}
                    count={tag.taggings_count}
                    color={tag.color}
                    className='ml-2 truncate'
                    size='sm'
                  />
                  {tag.ai && <AskAiSVG className='w-4 h-4 ml-1 opacity-50' />}
                </div>
                {!readOnly && <TagDropdown tag={tag} onDeleteTag={onDeleteTag} studyId={studyId} />}
                {shouldShowCount && (
                  <span className='-top-3 -right-3 absolute px-1.5 text-sm bg-indigo-600 text-white border rounded'>
                    {selectedCount}
                  </span>
                )}
              </div>
            );
          }}
        </Draggable>
      </div>
    </Tippy>
  );
};
