import * as React from 'react';
import { Provider } from 'react-redux';

import { Enums } from '@components/SurveyBuilder';
import { DailyProvider } from '@daily-co/daily-react';

import { store } from './api';
import { RecordingIndicator } from './components/RecordingIndicator';
import { ParticipantRecordingProvider } from './hooks/useParticipantRecording';

export type Props = {
  participation?: Participation;
  permissions?: { [Device in keyof typeof Enums.UnmoderatedDevice]: Enums.DevicePermission };
  device?: Study['device_type'];
};

export const ScreenSharingProvider: React.FC<Props> = ({
  children,
  participation,
  permissions,
  device = 'desktop'
}) => (
  <Provider store={store}>
    <DailyProvider>
      <ParticipantRecordingProvider participation={participation} permissions={permissions} device={device}>
        <RecordingIndicator />

        {children}
      </ParticipantRecordingProvider>
    </DailyProvider>
  </Provider>
);
