import { Button } from 'components/common';
import React from 'react';

export const PlansHeading: React.FC = () => {
  return (
    <div className='tablet:flex-row tablet:justify-between flex flex-col items-center justify-center max-w-screen-xl mx-auto'>
      <div className='tablet:max-w-1/2 w-full mb-6'>
        <h1 className='font-marketing tablet:text-6xl mb-6 text-4xl font-bold'>Plans and pricing</h1>
        <p className='tablet:text-xl mb-6 text-base text-gray-500'>
          Great Question is free to get started for personal use, with paid plans for small teams all the way up to
          enterprise.
        </p>
        <div className='flex'>
          <Button
            href='/users/sign_up'
            primary
            trackEvent='clicked_get_started'
            trackProps={{ position: 'above_fold' }}
            className='whitespace-nowrap tablet:flex-none flex-1 mr-4 text-base font-bold'
          >
            Start for free
          </Button>
          <Button
            href='/enterprise'
            className='whitespace-nowrap tablet:flex-none flex-1 text-base font-bold'
            trackEvent='clicked_schedule_demo'
            trackProps={{ position: 'above_fold' }}
          >
            Contact sales
          </Button>
        </div>
      </div>
      <img
        alt=''
        className='tablet:w-1/4 w-full h-auto max-w-lg mb-6'
        src='/temp-assets/pricing/plans@1x.png'
        srcSet='/temp-assets/pricing/plans@2x.png 2x, /temp-assets/pricing/plans@3x.png 3x'
      />
    </div>
  );
};
