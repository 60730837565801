import * as React from 'react';
import { useRef, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, DropdownLink } from '@components/common';
import { getBackgroundColor } from '@components/tags/colors';
import { useOnClickOutside } from '@components/utils';

type Props = {
  studyId?: number | null;
  tagGroups?: TagGroup[];
  selectedTags: Tag[];
  setSelectedTags: (tags: Tag[]) => void;
  medium?: boolean;
};

const TagGroupColor: React.FC<{ color?: string }> = ({ color = 'default' }) => {
  const backgroundColor = getBackgroundColor(color, 0.2);
  return <div className=' w-4 h-4 rounded' style={{ backgroundColor }} />;
};

export const MoveTagDropdown: React.FC<Props> = ({
  medium,
  studyId,
  tagGroups = [],
  selectedTags,
  setSelectedTags
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [bulkUpdateTags] = api.useBulkUpdateTagsMutation();

  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, closeDropdown);

  const moveTagsToGroup = (id: number | null) => {
    bulkUpdateTags({
      studyId: studyId || undefined,
      ids: selectedTags.map(({ id }) => id),
      tags: { tag_group_id: id }
    });

    setSelectedTags([]);
  };

  return (
    <Button medium={medium} name='close' onClick={toggleDropdown} icon='caretDown' className='mr-4'>
      Move to
      <div ref={dropdownRef} className='whitespace-nowrap relative text-left'>
        {dropdownOpen && (
          <div className='ring-1 ring-black ring-opacity-5 top-5 absolute right-0 z-10 px-2 py-1 bg-white rounded-md shadow-lg'>
            <DropdownLink onClick={() => moveTagsToGroup(null)}>
              <span className='flex flex-row items-center space-x-2'>
                <TagGroupColor />
                <span>Ungrouped</span>
              </span>
            </DropdownLink>
            {tagGroups
              ?.filter((tg) => tg.project_id == studyId)
              .map((g) => (
                <DropdownLink onClick={() => moveTagsToGroup(g.id)}>
                  <span className='flex flex-row items-center space-x-2'>
                    <TagGroupColor color={g.color} />
                    <span>{g.name || 'Untitled group'}</span>
                  </span>
                </DropdownLink>
              ))}
          </div>
        )}
      </div>
    </Button>
  );
};
