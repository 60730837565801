import { failedAttrCreation, successAttrCreation } from 'components/AccountAttrsApp/toasts';
import * as React from 'react';
// hooks
import { useEffect, useState } from 'react';

// components
import { Button, Input, Modal, ModalHeading, Select, Text } from '@components/common';
//constants
import { ATTR_TYPES } from '@components/config';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { useToaster } from '@stores/toaster';

interface Props {
  defaultLabel?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (attr: Attr_) => Promise<void> | void;
  createAttr?: (attr: Partial<Attr_>) => Promise<Attr_ | undefined>;
}

export const NewAttrModal: React.FC<Props> = ({ defaultLabel = '', open, onClose, onSubmit, createAttr }) => {
  const { create } = useCandidateAttrs();
  const createCustomAttr = createAttr || create;
  const [label, setLabel] = useState(defaultLabel);
  const [type, setType] = useState<Attr_['attr_type']>(ATTR_TYPES[0].value);
  const [isPII, setIsPII] = useState(false);
  const [saving, setSaving] = useState(false);

  const showToast = useToaster();

  useEffect(() => {
    setLabel(defaultLabel);
  }, [open]);

  const handleClick = async () => {
    setSaving(true);

    createCustomAttr({ label, attr_type: type, is_pii: isPII })
      .then(async (attr) => {
        if (attr) {
          await onSubmit(attr);
          showToast(successAttrCreation());
        }
      })
      .catch((error) => {
        let errorMessage;
        if (error && error.data && error.data.length > 0) {
          errorMessage = error.data[0];
        }

        showToast(failedAttrCreation(errorMessage));
      })
      .finally(() => {
        onClose();
        setSaving(false);
      });
  };

  const onTypeSelect = (type: Attr_['attr_type']): void => setType(type);

  const onIsPIIChange = () => setIsPII((prev) => !prev);

  if (!open) {
    return null;
  }

  return (
    <Modal
      onClose={onClose}
      renderFooter={() => (
        <Button disabled={saving} primary onClick={handleClick}>
          Done
        </Button>
      )}
    >
      <ModalHeading className='pb-4'>New attribute</ModalHeading>

      <Text className='mb-1' h='400' bold>
        Label
      </Text>
      <Input ariaLabel='label' name='label' className='w-full mb-6' autoFocus value={label} onChange={setLabel} />

      <Text className='mb-1' h='400' bold>
        Type
      </Text>
      <Select options={ATTR_TYPES} value={type} onChange={onTypeSelect} />

      <div className='flex items-center'>
        <Input name='isPII' className='mx-2 my-6' type='checkbox' checked={isPII} onChange={onIsPIIChange} />
        <Text>Is PII</Text>
      </div>
    </Modal>
  );
};
