import React, { SVGProps } from 'react';

export const InvoiceSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='18' height='24' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.5775 5.23969C17.5748 5.0596 17.5065 4.89085 17.3719 4.7529C12.5249 -0.0940658 12.8402 0.155353 12.7067 0.0834945C12.6259 0.0402762 12.5332 0.0117765 12.4358 0.00333913C12.3812 -0.00139519 13.065 0.000292289 1.82821 0.000292289C1.05281 0.000292289 0.421974 0.631129 0.421974 1.40653V22.5938C0.421974 23.3692 1.05281 24 1.82821 24H16.1718C16.9472 24 17.578 23.3692 17.578 22.5938C17.578 22.3933 17.5783 5.29622 17.5775 5.23969ZM13.0781 2.44775L15.1775 4.54712H13.0781V2.44775ZM1.82816 22.5938V1.40653H11.6718V5.25024C11.6718 5.63854 11.9866 5.95335 12.3749 5.95335H16.1718V22.5938H1.82816Z'
      fill='currentColor'
    />
    <path
      d='M13.875 19.5469H11.25C10.8617 19.5469 10.5469 19.8617 10.5469 20.25C10.5469 20.6383 10.8617 20.9531 11.25 20.9531H13.875C14.2633 20.9531 14.5781 20.6383 14.5781 20.25C14.5781 19.8617 14.2633 19.5469 13.875 19.5469Z'
      fill='currentColor'
    />
    <path
      d='M13.8749 12.0471H4.12506C3.73675 12.0471 3.42194 12.3619 3.42194 12.7502C3.42194 13.1385 3.73675 13.4533 4.12506 13.4533H13.8749C14.2633 13.4533 14.5781 13.1385 14.5781 12.7502C14.5781 12.3619 14.2633 12.0471 13.8749 12.0471Z'
      fill='currentColor'
    />
    <path
      d='M13.8749 15.0471H4.12506C3.73675 15.0471 3.42194 15.3619 3.42194 15.7502C3.42194 16.1385 3.73675 16.4533 4.12506 16.4533H13.8749C14.2633 16.4533 14.5781 16.1385 14.5781 15.7502C14.5781 15.3619 14.2633 15.0471 13.8749 15.0471Z'
      fill='currentColor'
    />
    <path
      d='M6.22972 5.87463C5.78151 5.71619 5.28759 5.52617 5.02242 5.31814C5.00498 5.30445 4.97245 5.22261 4.99528 5.1012C5.00625 5.04303 5.05655 4.84892 5.24376 4.79253C5.71269 4.6513 5.9293 4.83191 5.96853 4.8702C6.24083 5.14658 6.68562 5.15019 6.96237 4.87803C7.23925 4.60574 7.24296 4.16057 6.97066 3.88373C6.77899 3.68883 6.49995 3.51202 6.15355 3.41419V3.37524C6.15355 2.98693 5.83874 2.67212 5.45043 2.67212C5.06212 2.67212 4.74731 2.98693 4.74731 3.37524V3.47705C3.48039 3.9374 3.18888 5.6673 4.15454 6.4247C4.57861 6.75732 5.17725 6.99413 5.76102 7.20052C6.10949 7.32366 6.14497 7.57355 6.11338 7.76142C6.07822 7.97072 5.89794 8.32579 5.4456 8.32879C4.97705 8.33203 4.866 8.31454 4.52325 8.09034C4.19827 7.87776 3.76253 7.96884 3.54995 8.29382C3.33738 8.6188 3.4285 9.05455 3.75344 9.26712C4.12735 9.51171 4.42336 9.63293 4.74727 9.68979V9.75724C4.74727 10.1456 5.06208 10.4604 5.45038 10.4604C5.83869 10.4604 6.1535 10.1456 6.1535 9.75724V9.61029C7.85715 8.99431 8.0829 6.52965 6.22972 5.87463Z'
      fill='currentColor'
    />
  </svg>
);
