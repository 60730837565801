import * as React from 'react';

import { ButtonProps } from '@components/common/Button';
import { compact } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

export const ModeratorsNeedZoomSettingChangeAlert: BookabilityRuleAlertComponent = ({ level, extra, onClickCta }) => {
  const {
    account: { team }
  } = useAccount();
  const user = useUser();
  const userIds = extra?.user_ids || [];
  const users = compact(userIds.map((id) => team.find((u) => u.id === id)));

  let heading = 'Set up Zoom to stream and record';
  if (users.length === 1) {
    heading = `${users[0].name} needs to set up Zoom to stream and record`;
    if (users[0].id === user.id) {
      heading = 'Set up your Zoom account to stream and record';
    }
  }

  const instructionCta: ButtonProps = {
    children: 'Read full instructions',
    href: 'https://greatquestion.co/support/studies/observer-rooms#zoom_requirements',
    target: '_blank'
  };

  const ctas: ButtonProps[] = userIds.includes(user.id)
    ? [
        {
          children: 'Re-connect',
          href: '/user/connected_accounts',
          onClick: onClickCta
        },
        instructionCta
      ]
    : [instructionCta];

  return (
    <BookabilityRuleAlert
      level={level}
      heading={heading}
      message='In order to live stream the session and receive the recording afterward, please enable Local Recording on Zoom.'
      ctas={ctas}
    />
  );
};
