import * as React from 'react';

import { Tabs } from '@components/common';

import { useParticipationsStatus } from '../hooks/useParticipationsStatus';

interface Props {
  current: Participation['status'];
  study: Study;
  onSelect: (status: Participation['status']) => void;
  onClick?: (status: Participation['status']) => void;
  counts?: ParticipationCounts;
}

export const ParticipationStatusTabs: React.FC<Props> = ({ current, study, onSelect, onClick, counts }) => {
  const status = useParticipationsStatus(study, counts);

  const visibleStatuses: ParticipationStatus[] = status.map((s) => s.status);

  const labels = status.reduce<Record<ParticipationStatus, string>>((acc, { status, label }) => {
    acc[status] = label;
    return acc;
  }, {} as Record<ParticipationStatus, string>);

  const tooltips = status.reduce<Record<ParticipationStatus, string>>((acc, { status, tooltip }) => {
    acc[status] = tooltip;
    return acc;
  }, {} as Record<ParticipationStatus, string>);

  const handleOptionClick = (status: Participation['status']) => {
    onClick?.(status);
    if (current === status) return;

    onSelect(status);
  };

  return (
    <div className='monitor:space-x-5 relative flex items-center space-x-3 overflow-x-auto'>
      <Tabs tooltips={tooltips} current={current} tabs={visibleStatuses} onClick={handleOptionClick} labels={labels} />
    </div>
  );
};
