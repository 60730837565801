import * as React from 'react';
import useInterval from 'use-interval';

import { getRecording } from '@api/queries';

const INTERVAL = 3000;

interface Props {
  recordingId: number;
  onPoll: (recording: Recording) => void;
}
export const PollRecording: React.FC<Props> = ({ recordingId, onPoll }) => {
  useInterval(async () => {
    const [, rec] = await getRecording(recordingId);

    if (rec) onPoll(rec);
  }, INTERVAL);

  return null;
};
