import React, { SVGProps } from 'react';

export const PlusSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.4375 7H13.5625' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 0.4375V13.5625' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
