import React from 'react';

import { Button } from '@components/common';
import { RMContent, ZDSType } from '@components/shared/ZDSPage/constants';

interface Props {
  buttonClass?: string;
  type: ZDSType;
  onClick?: () => void;
}

export const RecommendedMethod: React.FC<Props> = ({ type, onClick, buttonClass }) => {
  const Icon = RMContent[type].svg;

  const {
    header,
    subheader,
    button: { label, icon, href, trackProps, trackEvent }
  } = RMContent[type];

  return (
    <div className='flex flex-grow flex-col bg-white border border-gray-200 p-6 rounded-lg w-full'>
      <div className='flex flex-col flex-grow items-center justify-center mt-6'>
        <Icon />
        <div className='font-bold text-lg mt-6'>{header}</div>
        <div className='color-gray-500 mt-2 max-w-lg text-center'>{subheader}</div>
        <Button
          className={`my-8 text-sm ${buttonClass}`}
          primary
          icon={icon}
          href={onClick ? undefined : href}
          trackEvent={trackEvent}
          trackProps={trackProps}
          onClick={onClick}
        >
          {label}
        </Button>
      </div>
    </div>
  );
};
