import { TippyOrNot } from 'components/common/TippyOrNot';
import * as React from 'react';

import { Text } from '../Text';

export const Stat: React.FC<{ title: string; tip: string }> = ({ title, tip, children }) => {
  return (
    <div className='flex items-center flex-1 py-3 mr-2 space-x-2'>
      <Text h='400' bold>
        {children === 0 ? '-' : children}
      </Text>
      <div className='flex flex-row items-center space-x-2 cursor-pointer'>
        <TippyOrNot show={!!tip} content={tip}>
          <Text h='400' color='gray-500'>
            {title}
          </Text>
        </TippyOrNot>
      </div>
    </div>
  );
};
