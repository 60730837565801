import React, { SVGProps } from 'react';

export const QuestionBankSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.5 0.5H15.5V5H0.5V0.5Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10.5 0.5V5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12 2.5L13 3.5L14 2.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M0.5 7.5H15.5V15.5H0.5V7.5Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M0.5 11.5H15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M13 13.2793C13.0494 13.2793 13.0978 13.294 13.1389 13.3214C13.18 13.3489 13.212 13.3879 13.231 13.4336C13.2499 13.4793 13.2548 13.5296 13.2452 13.5781C13.2355 13.6266 13.2117 13.6711 13.1768 13.7061C13.1418 13.741 13.0973 13.7648 13.0488 13.7745C13.0003 13.7841 12.95 13.7792 12.9043 13.7603C12.8586 13.7413 12.8196 13.7093 12.7921 13.6682C12.7647 13.6271 12.75 13.5787 12.75 13.5293C12.75 13.463 12.7763 13.3994 12.8232 13.3525C12.8701 13.3056 12.9337 13.2793 13 13.2793Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 9.2207C13.0494 9.2207 13.0978 9.23537 13.1389 9.26284C13.18 9.29031 13.212 9.32935 13.231 9.37503C13.2499 9.42071 13.2548 9.47098 13.2452 9.51948C13.2355 9.56797 13.2117 9.61252 13.1768 9.64748C13.1418 9.68244 13.0973 9.70625 13.0488 9.7159C13.0003 9.72555 12.95 9.72059 12.9043 9.70167C12.8586 9.68275 12.8196 9.65071 12.7921 9.6096C12.7647 9.56848 12.75 9.52015 12.75 9.4707C12.75 9.4044 12.7763 9.34081 12.8232 9.29393C12.8701 9.24704 12.9337 9.2207 13 9.2207Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
