export const TRIAL_LENGTH = '14 day';

export const PLAN_FEATURES = {
  scheduling: {
    title: 'Customer interview scheduling',
    popupText: 'Schedule 1:1 calls with participants to ask questions, share ideas or test features.'
  },
  surveys: {
    title: 'Surveys',
    popupText: 'Ask survey questions with skip logic and save responses as attributes.'
  },
  insights: {
    title: 'Insights',
    popupText: 'Create insights from your research and share them with your team.'
  },
  transcription: {
    title: 'Accurate transcription',
    popupText: 'An advanced speech engine for reliable transcripts across topics, industries, and accents.'
  },
  highlights: {
    title: 'Highlight videos',
    popupText: 'Upload recordings and create video clips of key moments.'
  },
  local_tags: {
    title: 'Local tags',
    popupText: 'Speed up your synthesis process by adding tags to each transcript highlight.'
  },
  text_search: {
    title: 'Full-text search',
    popupText: 'Search across interviews, transcripts, insights, reels, and more.'
  },
  observer_rooms: {
    title: 'Observer rooms',
    popupText: 'Invite your team to watch an interview without overwhelming the participant.'
  },
  integrations: {
    title: 'Video / Calendar integrations',
    popupText: 'Integrate Google, Microsoft, Zoom and more to schedule and conduct interviews.'
  },

  round_robin: {
    title: 'Round robin & rolling customer interview scheduling',
    popupText: 'Share the research load and schedule calls on a weekly basis.'
  },
  highlight_reels: {
    title: 'Highlight reels',
    popupText: 'Stitch clips together into a single, shareable highlight reel for your team to watch.'
  },

  incentives: {
    title: 'Incentives',
    popupText: 'Send global incentives in cash/gift cards, product gifts, coupon codes, and more.'
  },
  unlimited_viewers: {
    title: 'Unlimited view-only viewers',
    popupText: 'Let anyone in your organization see previous and upcoming research.'
  },
  focus_groups: {
    title: 'Focus groups',
    popupText: 'Schedule group calls with participants to ask questions and get feedback.'
  },
  templates: {
    title: 'Study / email templates',
    popupText: 'Create templates for common parts of your research workflow.'
  },
  global_tags: {
    title: 'Global tags',
    popupText: 'Set up a global taxonomy of tags so its easier to identify patterns across studies.'
  },
  custom_consent: {
    title: 'Custom consent / NDA forms',
    popupText: 'Upload your own participant consent forms to stay in compliance.'
  },
  concierge_migration: {
    title: 'Concierge repository migration',
    popupText: 'We’ll help you migrate your data off other tools and onto Great Question.'
  },
  basic_branding: {
    title: 'Basic branding',
    popupText: 'Upload your logo and add your color palettes.'
  },
  study_language: {
    title: 'Internationalization',
    popupText: 'Recruit participants in different regions by ensuring the experience is in their language.'
  },
  data_retention: {
    title: 'Custom data retention',
    popupText: 'Adhere to data governance policies by automatically deleting artifacts after a certain period.'
  },
  custom_eligibility: {
    title: 'Custom eligibility rules',
    popupText: 'Define custom criteria to enforce candidate eligibltiy for recuriting.'
  },
  crm_integrations: {
    title: 'Salesforce, Snowflake integrations',
    popupText: 'Pull in contact data and push out research data.'
  },
  advanced_customization: {
    title: 'Advanced customization',
    popupText: 'Ability to customize your product instance in more ways.'
  },
  reporting: {
    title: 'Reporting',
    popupText: 'Charts that surface how much research is happening, recruitment metrics, and more.'
  },
  qualtrics: {
    title: 'Qualtrics integration',
    popupText: 'Utilize Qualtrics for survey creation, and pull in participant responses automatically.'
  },
  external_candidates: {
    title: 'Recruit external candidates',
    popupText:
      'External recruiting lets you quickly and easily find B2B and B2C candidates that meet your screening criteria, and conduct your study as usual through Great Question.'
  },
  unmoderated: {
    title: 'Unmoderated testing',
    popupText: 'Run asynchronous browser-based prototype and website tests on desktop or mobile'
  }
};

export const PLAN_LIMITS: Record<
  Extract<PlanLimit, 'studies' | 'transcription_hours' | 'storage' | 'candidates' | 'contacted_candidates'>,
  string
> = {
  studies: 'studies',
  transcription_hours: 'interview hours / month',
  storage: 'GB storage',
  candidates: 'research participant panel',
  contacted_candidates: 'tracked candidates'
};

export const PLAN_SUPPORT_FEATURES: Record<PlanSupportFeatures, string> = {
  help_center: 'Help center',
  slack_group: 'Slack user group',
  encrypted_data: 'Encrypted data',
  dpa: 'GDPR DPA',
  security_review: 'Security reviews',
  saml: 'Single sign-on',
  account_manager: 'Customer success manager',
  custom_contract: 'Custom legal contracting',
  payment_terms: 'Custom Payment terms',
  slas: 'Support SLAs'
};
