import { PlanUsage } from 'components/PlansApp/components/PlanFeatures';
import { StartTrialLink } from 'components/PlansApp/components/StartTrialLink';
import * as React from 'react';

import { api } from '@api/reduxApi';
import { Button, List, Loading, Modal, Text } from '@components/common';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';

import { ACTIONS, FEATURES_v2, PlanSVGs } from './constants';
import { UpgradeCopy } from './types';

const firstPlanWithLimit: (params: {
  limit: PlanLimit;
  plans: Plan[];
  interval: Plan['interval'];
  amount: number | null;
}) => Plan | undefined = ({ limit, plans, interval, amount }) => {
  const intervalPlans = plans.filter(({ interval: pInterval }) => interval === pInterval);
  return intervalPlans.find(({ limits }) => (amount && limits[limit] >= amount) || limits[limit] === null);
};
const firstPlanWithFeature: (params: {
  feature: PlanFeature;
  plans: Plan[];
  interval: Plan['interval'];
}) => Plan | undefined = ({ feature, plans, interval }) => {
  const intervalPlans = plans.filter(({ interval: pInterval }) => interval === pInterval);
  return intervalPlans.find(({ features }) => features[feature]);
};

const ADDONS: Partial<Record<PlanFeature, { link: string }>> = {
  hipaa: {
    link: 'https://greatquestion.co/features/hipaa'
  }
};

const NOT_ON_TRIAL: PlanFeature[] = ['incentives', 'external_recruitment'];

export const PlanUpgradeModal: React.FC<{ feature?: PlanFeature; limit?: PlanLimit; onClose: () => void }> = ({
  feature,
  limit,
  onClose
}) => {
  const canUpgrade = usePermission('isAdmin')();
  const { data: plans, isSuccess } = api.useGetPlansQuery();
  // const plans = usePlans();
  const { interval, limits } = usePlan();

  if (!isSuccess) {
    return (
      <Modal size='md'>
        <Loading absolute />
      </Modal>
    );
  }

  let nextPlan: Plan | undefined;
  if (!limit && !feature) {
    return null;
  }
  let copy: UpgradeCopy = { text: '', title: '', benefits: [] };
  let title = '';
  if (feature) {
    copy = FEATURES_v2[feature];
    nextPlan = firstPlanWithFeature({ feature, plans, interval });
    title = `Upgrade for ${copy.title} and more`;
  }
  if (limit) {
    copy = { text: ACTIONS[limit], title: ACTIONS[limit], benefits: [] };
    nextPlan = firstPlanWithLimit({ limit, plans, amount: limits[limit] ? limits[limit] + 1 : null, interval });

    title = `Upgrade to ${nextPlan?.name} to ${copy.text}`;
  }

  const PlanSVG = nextPlan && PlanSVGs[nextPlan.key];

  const addOn = feature && ADDONS[feature];
  const requiresDemo = nextPlan && ['v3_enterprise', 'v2_enterprise', 'enterprise'].includes(nextPlan.key);
  const onTrial = feature && !NOT_ON_TRIAL.includes(feature);

  return (
    <Modal size='lg' onClose={onClose}>
      <div>
        <Text h='700' className='leading-normal' mb='6'>
          {title}
        </Text>
        <div className='flex flex-row items-stretch items-start justify-between'>
          <div>
            <div>
              {feature && (
                <div className='mb-10'>
                  <Text mb='3' bold>
                    With {copy.text}, you can:
                  </Text>
                  <List>
                    {copy.benefits.map((b) => (
                      <li key={b}>
                        <Text>{b}</Text>
                      </li>
                    ))}
                  </List>
                </div>
              )}
              {nextPlan && (
                <>
                  <Text mb='3' bold>
                    On the {nextPlan?.name} plan, you'll {feature && 'also'} get:
                  </Text>
                  <PlanUsage expanded plan={nextPlan} size='md' ulClass='list-disc ml-6' />
                </>
              )}
            </div>
            <div>
              {addOn ? (
                <div className='flex flex-row mt-4 space-x-2'>
                  <Button
                    trackEvent='upgrade_tooltip_clicked_request_demo'
                    trackProps={{ feature, limit }}
                    primary
                    href='/enterprise'
                  >
                    Request demo
                  </Button>
                  <Button
                    trackEvent='upgrade_tooltip_clicked_addon_learn_more'
                    trackProps={{ feature, limit }}
                    disabled={!canUpgrade}
                    target='_blank'
                    href={addOn.link}
                    iconSuffix='externalLink'
                  >
                    Learn more
                  </Button>
                </div>
              ) : (
                <Button
                  trackEvent='upgrade_tooltip_clicked_view_plans'
                  trackProps={{ feature, limit }}
                  disabled={!canUpgrade}
                  className='mt-4'
                  target='_blank'
                  href='/pricing'
                  iconSuffix='externalLink'
                >
                  View all plans
                </Button>
              )}
            </div>
          </div>
          {nextPlan && (
            <div className='bg-gray-50 flex flex-col px-4 py-4 text-center rounded'>
              <div className='flex-1'>
                {PlanSVG && <PlanSVG className='w-20 h-20 mx-auto mb-4' />}
                <Text h='600' bold mb='4'>
                  {nextPlan.name}
                </Text>
                <Text h='500' mb='4'>
                  {nextPlan.self_serve ? `$${nextPlan.seat_cost} / ${nextPlan.interval} per seat` : 'Custom pricing'}
                </Text>
              </div>
              <div>
                {requiresDemo ? (
                  <>
                    <Button
                      trackEvent='upgrade_tooltip_clicked_request_demo'
                      trackProps={{ feature, limit }}
                      primary
                      className='mt-4'
                      href='/enterprise'
                      fullWidth
                    >
                      Request demo
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      trackEvent='upgrade_tooltip_clicked_upgrade'
                      trackProps={{ feature, limit }}
                      primary
                      fullWidth
                      // disabled={!canUpgrade}
                      className='mt-4'
                      href={`/subscription/new?plan=${nextPlan.id}`}
                    >
                      Upgrade now
                    </Button>
                    {onTrial && <StartTrialLink plan={nextPlan} />}
                    <Text h='400' color='gray-500' mt='6'>
                      You won't be charged yet
                    </Text>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
