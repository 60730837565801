import { Button, DropdownCombobox, RadioGroup, Select } from '@components/common';
import { CloseIcon } from '@components/common/Modal';
import * as React from 'react';
import { Checkboxes } from './Inputs/Checkboxes';
import { Multiselect } from './Inputs/Multiselect';

export interface Filter {
  filter_id: FilterId | string;
  selected_values?: any;
  selected_range?: any;
}

export type FilterStateLookup = {
  [filter_id in FilterId]: {
    type: 'select' | 'range';
    selected_values: string[];
    selected_range: {
      lower: number;
      upper: number;
    };
  };
};

interface Props {
  object: ExternalCandidatesFilterListResponse;
  disabled: boolean;
  error?: boolean;
  storeFilterValues: (filters: Filter[]) => void;
  setChanged: (changed: boolean) => void;
  onSearch?: (searchValue: string) => void;
  filters: FilterStateLookup;
  storeFilterRange: (range: Filter[]) => void;
  limit?: number;
  allOptionValue?: string;
}

export const FilterInput: React.FC<Props> = ({
  storeFilterValues,
  setChanged,
  onSearch,
  object,
  storeFilterRange,
  filters,
  disabled,
  error = false,
  limit,
  allOptionValue
}) => {
  return (
    <>
      {object.type === 'single' && (
        <div key={`filter-${object.filter_id}`}>
          <Select
            className='w-full mt-2'
            options={Object.keys(object.choices).map((c) => ({
              label: object.choices[c],
              value: c
            }))}
            disabled={disabled}
            error={error}
            onChange={(value) => {
              storeFilterValues([
                {
                  filter_id: object.filter_id,
                  selected_values: [value]
                }
              ]);
              setChanged(true);
            }}
            placeholder={`Select ${object.title.toLowerCase()}`}
            value={filters[object.filter_id]?.selected_values?.[0]}
          />
        </div>
      )}
      {object.type === 'select' && (
        <div className='mt-2' key={`filter-${object.filter_id}`}>
          <DropdownCombobox
            items={Object.keys(object.choices)
              .filter((c) => !filters[object.filter_id]?.selected_values?.includes(c))
              .map((c) => ({ label: object.choices[c], value: c }))}
            unlimitedItems
            disabled={disabled}
            error={error}
            onSelect={(item) => {
              storeFilterValues([
                {
                  filter_id: object.filter_id,
                  selected_values: [...(filters[object.filter_id]?.selected_values || []), item?.value]
                }
              ]);
              setChanged(true);
            }}
            resetOnSelect
            blurOnSelect
            resetOnBlur
            placeholder={`Select ${object.title.toLowerCase()}`}
          />
          <div className='my-1'>
            {!!filters[object.filter_id]?.selected_values?.length &&
              filters[object.filter_id].selected_values.map((value) => (
                <Button
                  key={`removeFilter-${object.filter_id}-${value}`}
                  className='my-1 mr-2'
                  primary
                  outline
                  medium
                  disabled={disabled}
                  onClick={() => {
                    storeFilterValues([
                      {
                        filter_id: object.filter_id,
                        selected_values: (filters[object.filter_id].selected_values || []).filter(
                          (selected_value) => selected_value !== value
                        )
                      }
                    ]);
                    setChanged(true);
                  }}
                >
                  {object.choices[value]}
                  <CloseIcon className='ml-2' />
                </Button>
              ))}
          </div>
        </div>
      )}
      {object.type === 'range' && (
        <div key={`filter-${object.filter_id}`}>
          <div className='flex space-x-4 mt-2'>
            <Select
              className='w-full'
              options={Array.from({ length: object.max - object.min + 1 }, (_, i) => i + object.min).map((a) => ({
                label: a.toString(),
                value: a.toString()
              }))}
              disabled={disabled}
              error={error}
              onChange={(value) => {
                storeFilterRange([
                  {
                    filter_id: object.filter_id,
                    selected_range: {
                      lower: Number(value),
                      upper: filters[object.filter_id]?.selected_range?.upper
                    }
                  }
                ]);
                setChanged(true);
              }}
              placeholder='Minimum (Optional)'
              value={filters[object.filter_id]?.selected_range?.lower?.toString()}
            />
            <Select
              className='w-full'
              options={Array.from({ length: object.max - object.min + 1 }, (_, i) => i + object.min).map((a) => ({
                label: a.toString(),
                value: a.toString()
              }))}
              disabled={disabled}
              error={error}
              onChange={(value) => {
                storeFilterRange([
                  {
                    filter_id: object.filter_id,
                    selected_range: {
                      lower: filters[object.filter_id]?.selected_range?.lower,
                      upper: Number(value)
                    }
                  }
                ]);
                setChanged(true);
              }}
              placeholder='Maximum (Optional)'
              value={filters[object.filter_id]?.selected_range?.upper?.toString()}
            />
          </div>
        </div>
      )}
      {object.type === 'radios' && (
        <div className='mt-6' key={`filter-${object.filter_id}`}>
          <RadioGroup
            options={Object.keys(object.choices).map((c) => ({
              label: object.choices[c],
              value: c
            }))}
            border={false}
            selectedColor='none'
            selected={filters[object.filter_id]?.selected_values?.[0] || object.default_value}
            disabled={disabled}
            onChange={(value) => {
              storeFilterValues([
                {
                  filter_id: object.filter_id,
                  selected_values: [value]
                }
              ]);
              setChanged(true);
            }}
          />
        </div>
      )}
      {object.type === 'multiselect' && (
        <Multiselect
          allOptionValue={allOptionValue}
          limit={limit}
          storeFilterRange={storeFilterRange}
          disabled={disabled}
          error={error}
          object={object}
          filters={filters}
          storeFilterValues={storeFilterValues}
          setChanged={setChanged}
          onSearch={onSearch}
        />
      )}
      {object.type === 'checkboxes' && (
        <Checkboxes
          limit={limit}
          disabled={disabled}
          object={object}
          filters={filters}
          storeFilterValues={storeFilterValues}
          setChanged={setChanged}
        />
      )}
    </>
  );
};
