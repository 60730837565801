import { Extension } from '@tiptap/core';

import { Column } from './Column';
import { ColumnBlock } from './ColumnBlock';

export interface ColumnExtensionOptions {
  column?: boolean;
  columnBlock?: boolean;
}

export const ColumnExtension = Extension.create<ColumnExtensionOptions>({
  name: 'columnExtension',

  addExtensions() {
    return [Column, ColumnBlock];
  }
});

export default { ColumnExtension };
