import { api } from 'api/reduxApi';
import { DropdownItem } from 'components/common/DropdownCombobox';
import * as React from 'react';
import { useMemo, useState } from 'react';

import { Option } from '@components/common';
import { SelectDropdown } from '@components/shared/SelectDropdown';
import { useDebouncedCallback } from 'use-debounce';
import { CandidateItem } from './CandidateItem';
import { ItemProps } from '@components/shared/SelectDropdown/components/Item';
import { SelectorSkeleton } from './SelectorSkeleton';

interface Props {
  candidate?: Candidate;
  setCandidate: (candidate: Candidate) => void;
  onNew: (params: { text: string }) => void;
}

const DEBOUNCE_RATE = 300;

export const CandidateSelector: React.FC<Props> = ({ candidate, setCandidate, onNew }) => {
  const [search, setSearch] = useState<string>('');

  const { data: fetchedData, isLoading } = api.useGetServerSideCandidatesQuery({
    items: 30,
    page: 1,
    searchQuery: search
  });

  const candidates = useMemo(() => (fetchedData ? fetchedData.data : []), [fetchedData]);

  const { callback: debouncedSetSearch } = useDebouncedCallback(setSearch, DEBOUNCE_RATE);

  function handleSelect(selectedId) {
    const selectedCandidate = candidates?.find(({ id }) => id.toString() === selectedId);

    if (selectedCandidate) {
      setCandidate(selectedCandidate);
    }
  }

  const options = useMemo(
    () =>
      candidates
        ? candidates.map(({ id, name, email }) => ({
            label: name || 'Unnamed candidate',
            value: id.toString(),
            search: [name, email].join(' ')
          }))
        : [],
    [candidates]
  );

  const selected: DropdownItem | undefined = useMemo(
    () =>
      candidate && candidate.id
        ? { label: `${candidate.name} (${candidate.email})`, value: candidate.id?.toString() }
        : candidate
        ? { label: `New Candidate`, value: '' }
        : undefined,
    [candidate]
  );

  if (isLoading) {
    return <SelectorSkeleton />;
  }
  return (
    <div className='mb-6'>
      <SelectDropdown<Option>
        loading={isLoading}
        creatable
        displayAllItems
        onInputValueChange={debouncedSetSearch}
        onChange={(items) => {
          handleSelect(items[0].value);
        }}
        options={options}
        onCreate={(item) => onNew({ text: item.value })}
        value={selected ? [selected] : []}
        overrides={{
          Input: {
            props: {
              placeholder: 'Enter name or email address',
              className: 'xx-combo-input',
              ['aria-label']: 'Search candidate by name or email'
            }
          },
          Item: {
            component: (props: ItemProps) => <CandidateItem candidates={candidates} {...props} />
          }
        }}
      />
    </div>
  );
};
