import * as React from 'react';

export const boolean: React.FC<any> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 7.71333L4.92917 9.74158C4.98338 9.82255 5.05614 9.88941 5.14141 9.93659C5.22668 9.98377 5.32198 10.0099 5.41938 10.0128C5.51679 10.0157 5.61348 9.99532 5.70141 9.95332C5.78934 9.91132 5.86597 9.84892 5.92492 9.77133L10.5 3.98291'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6875 0.437012H1.3125C0.829251 0.437012 0.4375 0.828763 0.4375 1.31201V12.687C0.4375 13.1703 0.829251 13.562 1.3125 13.562H12.6875C13.1707 13.562 13.5625 13.1703 13.5625 12.687V1.31201C13.5625 0.828763 13.1707 0.437012 12.6875 0.437012Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
