import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import { DropdownMenuProps } from '../types';

type Props = HTMLAttributes<HTMLUListElement> & DropdownMenuProps;

export const Menu = forwardRef<HTMLUListElement, Props>(
  ({ __dropdownMenuProps: { isOpen, loading, inputValue, items }, className, children, ...rest }, ref) => {
    return (
      <ul
        ref={ref}
        {...rest}
        data-testid='combobox-menu'
        className={cn(
          'w-full z-50 mt-1 bg-white border border-gray-200 rounded shadow-lg overflow-hidden max-h-60 overflow-y-auto',
          {
            invisible: !isOpen
          },
          className
        )}
      >
        {children}
      </ul>
    );
  }
);
