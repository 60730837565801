import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { Button } from '@components/common';
import { AddAttribute } from '@components/shared/AddAttribute';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { usePermission } from '@hooks/usePermission';
import { Toast, useToaster } from '@stores/toaster';

import { Attribute, isPii } from './components';
import { buildVisibleAttrs } from './helpers';
import { api } from '@api/reduxApi';
import { capitalize } from '@components/utils';

const HIDDEN_CORE_ATTRS = ['first_name', 'last_name'];

export const successAttrUpdate = (): Toast => ({
  heading: 'Profile updated!',
  icon: 'success',
  text: 'The profile was updated.'
});

export const failedAttrUpdate = (e): Toast => {
  const attr = Object.keys(e.data)?.[0];
  const message = e.data?.[attr]?.[0];

  return {
    heading: 'Failed to update the profile!',
    icon: 'error',
    text:
      attr && message
        ? capitalize(`${attr} ${message}`)
        : 'Something went wrong updating the profile. Please try again later.'
  };
};

interface Props {
  customer: Candidate;
  onUpdate?: (candidate: Candidate) => void;
  attrsToShow?: string[];
  onClickDeleteAttr?: (attr: string) => void;
  style: StyleType;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  createAttr?: (attr: Partial<Attr_>) => Promise<Attr_ | undefined>;
}

export const CandidateProfile: React.FC<Props> = ({
  customer: initialCustomer,
  onUpdate,
  attrsToShow,
  onClickDeleteAttr,
  style,
  setLoading,
  createAttr
}) => {
  const { allAttrs, isSuccess } = useCandidateAttrs();

  const [customer, setCustomer] = useState<Candidate>(initialCustomer);
  const canPII = usePermission('showPII')();
  const canUpdate = usePermission<Candidate>('updateCandidate')(customer);
  const [newAttr, setNewAttr] = useState<Attr_[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [updateCandidate] = api.useUpdateCandidateMutation();

  const showToast = useToaster();

  const attrs = useMemo(
    () => allAttrs.filter(({ name }) => !HIDDEN_CORE_ATTRS.includes(name)),
    [attrsToShow, allAttrs, newAttr]
  );

  const addNewAttrToList = (attr: Attr_): void => {
    setNewAttr([...newAttr, attr]);
  };

  const updateValue = async (name, value) => {
    setLoading?.(true);
    try {
      const newCustomer = await updateCandidate({ id: customer.id, [name]: value }).unwrap();
      setCustomer(newCustomer);
      onUpdate?.(newCustomer);
      showToast(successAttrUpdate());
    } catch (e) {
      showToast(failedAttrUpdate(e));
    } finally {
      setLoading?.(false);
    }
  };

  const { visibleAttrs, totalAttrs } = buildVisibleAttrs({ attrs, newAttr, entity: customer, expanded });

  const options = useMemo(
    () =>
      attrs
        ?.filter((attr) => {
          // Filter out attrs that are already visible
          return !visibleAttrs.find((visibleAttr) => visibleAttr.name === attr.name);
        })
        .map((attr: Attr_) => ({ label: attr.label, value: attr.name, data: attr })),
    [attrs, visibleAttrs]
  );

  useEffect(() => {
    if (isSuccess && totalAttrs < 6 && !expanded) {
      setExpanded(true);
    }
  }, [totalAttrs]);

  return (
    <div className='mt-4'>
      <table className='w-full'>
        <tbody>
          {visibleAttrs.map((attr, i) => (
            <Attribute
              key={`${attr.label}${i}`}
              attr={attr}
              index={i}
              canEdit={canUpdate && (!isPii(attr) || canPII)}
              entity={customer}
              onUpdate={(value) => updateValue(attr.name, value)}
              style={style}
              customerName={customer.name || ''}
              onClickDelete={() => onClickDeleteAttr?.(attr.core ? attr.name : `extra.${attr.name}`)}
            />
          ))}
        </tbody>
      </table>
      {canUpdate && (
        <AddAttribute
          addNewAttrToList={addNewAttrToList}
          onAdd={() => setExpanded(true)}
          defaultButton
          visibleAttrs={visibleAttrs}
          createAttr={createAttr}
          options={options.filter((option) => !option.data.core)}
        />
      )}
      {!expanded && (
        <Button data-testid='show-all' link className='inline px-4 py-3 my-4 ml-2' onClick={() => setExpanded(true)}>
          {`Show all ${totalAttrs}`}
        </Button>
      )}
    </div>
  );
};
