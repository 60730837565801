import { SelectOption } from 'components/common';

import { MetricDef } from './types';

export const COLORS = ['#9F46F4', '#16BDCA', '#FF7511', '#FFD100', '#E02424', '#5850ec'];

export const LABELS: Record<Study['style'], string> = {
  video_call: 'Interview',
  survey: 'Survey',
  online_task: 'Online task',
  unmoderated_test: 'Unmoderated test',
  panel: 'Panel',
  repo_only: 'Repository only'
};

export const GROUP_PERIOD_OPTIONS: SelectOption<'week' | 'month'>[] = [
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Week',
    value: 'week'
  }
];

export const RANGE_OPTIONS = [
  {
    label: 'Last month',
    value: '1'
  },
  {
    label: 'Last 3 months',
    value: '3'
  },
  {
    label: 'Last 6 months',
    value: '6'
  },
  {
    label: 'Last 12 months',
    value: '12'
  },
  {
    label: 'Last 24 months',
    value: '24'
  }
];

export const METRIC_DEFS: MetricDef[] = [
  {
    id: 'closed_studies',
    label: 'Completed Studies',
    info: 'Number of studies closed',
    type: 'number'
  },
  {
    id: 'time_to_fulfill_studies',
    label: 'Average time to recruit',
    info: 'Average time to fulfill candidates for each study',
    type: 'number'
  },
  {
    id: 'booked_participations',
    label: 'Booked interviews',
    info: 'Total number of interviews booked',
    type: 'number'
  },
  {
    id: 'interview_hours',
    label: 'Interview hours',
    info: 'Total number of hours spent talking to customers',
    type: 'number'
  },
  {
    id: 'published_studies',
    label: 'Published studies',
    info: 'Number of studies successfully published.',
    type: 'stacked_bar'
  },
  {
    id: 'booked_participations',
    label: 'Booked interviews',
    info: 'Number of interviews booked by month',
    type: 'single_bar'
  },
  {
    id: 'closed_studies', // fulfilled_studies
    label: 'Completed studies',
    info: 'Number of studies closed by style.',
    type: 'stacked_bar'
    // group_by: 'projects.style'
  },
  {
    id: 'completed_participations',
    label: 'Completed participations',
    info: 'Number of participants who successfully completed a research participation.',
    type: 'stacked_bar'
  },
  {
    id: 'minutes_to_first_booked',
    label: 'Time to schedule first interview',
    info: 'Average time to schedule the first interview from when a study is published.',
    type: 'single_bar'
  },
  {
    id: 'time_to_fulfill_studies',
    label: 'Average time to fulfill a study',
    info: 'Average time it takes to book all candidates from when a study is published.',
    type: 'single_bar'
  }
  // {
  //   id: 'minutes_to_booked',
  //   label: 'Average time to schedule',
  //   info: 'Average time it takes to book all candidates from when a study is published.',
  //   type: 'single_bar'
  // }
];
