// libs
import cn from 'classnames';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { format } from 'timeago.js';

// components
import { Button, Text } from '@components/common';
import { Player } from '@components/RepoSessionApp/RecordingWidget/Player';

interface Props {
  className?: string;
  href?: string; // Im not sure why this is optional.. but sure.
  interviewAt: string | Date | null;
  loading?: boolean;
  candidateName?: string;
  token?: string;
  studyTitle?: string;
  userIds?: number[];
  recordingId?: number;
  recordingFilename?: string;
  renderAvatars?: (userIds: number[]) => React.ReactNode;
  showLink?: boolean;
  truncateText?: boolean;
}

const MIN_CARD_HEIGHT = '16rem';

export const InterviewCard: React.FC<Props> = ({
  className,
  interviewAt,
  href,
  loading,
  candidateName,
  token,
  studyTitle,
  userIds,
  recordingId,
  recordingFilename,
  renderAvatars,
  showLink,
  truncateText
}) => {
  const cardClass = cn(className, 'w-full bg-white border border-gray-200 rounded-md flex flex-col', {
    'hover:border-indigo-600 hover:bg-indigo-50': href
  });

  const candidateNameClass = cn('block font-bold', { truncate: truncateText });
  const studyTitleClass = cn('block mb-2 text-sm text-gray-500', { truncate: truncateText });

  return (
    <Link to={href || ''} className={cardClass} style={{ minHeight: MIN_CARD_HEIGHT }}>
      <Player bgClass='bg-gray-200' thumbnailOnly recordingId={recordingId as any} processing={loading} />

      <div className='flex flex-col flex-1 h-full p-4 text-gray-700'>
        {loading && <Skeleton className='bg-gray-50' count={4} />}
        {!loading && (
          <>
            <span className={candidateNameClass}>{candidateName || recordingFilename}</span>
            <span className={studyTitleClass}>{studyTitle}</span>

            <section className='flex flex-wrap justify-between mt-auto'>
              <div className='flex flex-row items-center space-x-2'>
                {renderAvatars?.(userIds as any)}
                {interviewAt && (
                  <Text h='200' color='gray-500'>
                    {format(interviewAt)}
                  </Text>
                )}
              </div>
            </section>
          </>
        )}
      </div>

      {!loading && showLink && (
        <div className='p-1 mt-auto text-center border-t border-gray-200'>
          <Button link href={href || ''} target='_blank'>
            Interview room
          </Button>
        </div>
      )}
    </Link>
  );
};
