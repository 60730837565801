import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Toggle } from '@components/common';

import { SETTING_LABELS } from './constants';

function settingLabel(study: Study, key: keyof typeof SETTING_LABELS) {
  const str = SETTING_LABELS[key];
  return typeof str === 'string' ? str : str?.(study);
}

interface SettingProps {
  study: Study;
  settingKey: SettingsKey;
  value?: boolean;
  onToggle: () => void;
  disabled?: boolean;
}

export const Setting: React.FC<SettingProps> = ({ study, settingKey, value, onToggle, disabled = false }) => {
  const navigate = useNavigate();

  const renderEmailLink = () => {
    switch (settingKey) {
      case 'invited_reminder':
        return (
          <Button className='ml-2' link onClick={() => navigate(`/studies/${study.id}/emails/invited_reminder`)}>
            View email
          </Button>
        );
      case 'booked_reminder':
        return (
          <Button className='ml-2' link onClick={() => navigate(`/studies/${study.id}/emails/booked_reminder`)}>
            View email
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`flex flex-row py-4 ${disabled ? 'opacity-50' : ''}`} key={settingKey + 'setting'}>
      <div className='h400 flex-1 text-gray-700'>
        {settingLabel(study, settingKey)}
        {renderEmailLink()}
      </div>
      <div className='flex-0'>
        <Toggle disabled={disabled} key={settingKey + (value ? 'on' : 'off')} on={value} onToggle={() => !disabled && onToggle()} />
      </div>
    </div>
  );
};
