import React, { useEffect } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { CloseSVG, PlusSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';
import { useEditorState } from '@components/RepoSessionApp/ClipPage/hooks/useEditorState';
import { useTags } from '@stores/tags';
import { HighlightCard } from './components/HighlightCard';

interface Props {
  recording?: Recording;
  onFilter?: (v: number) => void;
  allTagIds?: number[];
  appliedFilters?: number[];
  highlights?: Highlight[];
  readOnly?: boolean;
}

export const HighlightsTab: React.FC<Props> = ({
  allTagIds,
  onFilter,
  appliedFilters,
  highlights,
  recording,
  readOnly
}) => {
  const {
    account: { team }
  } = useAccount();

  const { getTag } = useTags();

  const { getTextNodes, state } = useEditorState({ documentId: recording?.transcript?.document_id });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(api.util.invalidateTags([{ type: 'Highlight', id: 'LIST' }]));
  }, []);

  return (
    <section contentEditable={false} className='overflow-hidden'>
      {highlights?.length ? (
        <div className='flex flex-col overflow-auto'>
          {!readOnly && (
            <div className='flex items-center px-3 py-3 space-x-4 overflow-auto border-b border-gray-200'>
              {allTagIds?.map((tagId) => (
                <div
                  key={tagId}
                  data-testid={tagId}
                  role='button'
                  tabIndex={0}
                  onClick={() => onFilter?.(tagId)}
                  className={cn(
                    'flex-shrink-0 inline-flex space-x-4 flex-nowrap cursor-pointer items-center truncate py-1.5 px-2.5 rounded-md border hover:text-indigo-600',
                    {
                      'border-indigo-600 bg-indigo-50': appliedFilters?.includes(tagId),
                      'border-gray-200': !appliedFilters?.includes(tagId)
                    }
                  )}
                >
                  <div className='max-w-72 flex items-center space-x-2'>
                    <Text h='400' className='max-w-64 overflow-hidden truncate'>
                      {getTag(tagId)?.name}
                    </Text>
                    {appliedFilters?.includes(tagId) ? <CloseSVG className='h-3.5 w-3.5' /> : <PlusSVG />}
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className='flex flex-col p-4 space-y-3'>
            {highlights?.map((highlight) => {
              const isClip = !!highlight.clip;

              const creator = team.find(({ id }) => id === highlight?.creator_id);

              if (isClip && !recording) return null;

              return (
                <HighlightCard
                  getTextNodes={getTextNodes}
                  state={state}
                  key={highlight.id}
                  highlight={highlight}
                  creator={creator}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <Text className='px-6 mt-6' color='gray-500'>
          Once highlights are created, we’ll show them here.
        </Text>
      )}
    </section>
  );
};
