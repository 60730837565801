import React, { SVGProps } from 'react';

export const ChangeUserSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_299_14250)'>
      <path
        d='M13.2008 8.6734L8.75 13.1248L6.5625 13.5623L7 11.3748L11.4508 6.9234C11.6823 6.692 11.9962 6.56201 12.3235 6.56201C12.6508 6.56201 12.9647 6.692 13.1962 6.9234L13.2008 6.92865C13.3155 7.04314 13.4065 7.17912 13.4686 7.32881C13.5307 7.4785 13.5627 7.63897 13.5627 7.80103C13.5627 7.96308 13.5307 8.12355 13.4686 8.27324C13.4065 8.42294 13.3155 8.55892 13.2008 8.6734Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.96875 2.84375C1.96875 3.48193 2.22226 4.09397 2.67352 4.54523C3.12478 4.99648 3.73682 5.25 4.375 5.25C5.01318 5.25 5.62522 4.99648 6.07648 4.54523C6.52774 4.09397 6.78125 3.48193 6.78125 2.84375C6.78125 2.20557 6.52774 1.59353 6.07648 1.14227C5.62522 0.691015 5.01318 0.4375 4.375 0.4375C3.73682 0.4375 3.12478 0.691015 2.67352 1.14227C2.22226 1.59353 1.96875 2.20557 1.96875 2.84375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.78052 8.11937C7.35348 7.36329 6.68778 6.77015 5.88763 6.43282C5.08749 6.09548 4.19808 6.03299 3.35862 6.25512C2.51917 6.47725 1.77707 6.97147 1.2485 7.66041C0.719922 8.34935 0.434714 9.19411 0.437521 10.0624'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_299_14250'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
