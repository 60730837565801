import React, { SVGProps } from 'react';

export const LinkIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.3848 11.8284L7.5564 14.6569C6.38968 15.8236 4.48049 15.8236 3.31376 14.6569C2.14703 13.4901 2.14703 11.581 3.31376 10.4142L6.14219 7.58581L4.72797 6.17159L1.89955 9.00002C-0.0520672 10.9516 -0.0520672 14.1195 1.89955 16.0711C3.85116 18.0227 7.019 18.0227 8.97062 16.0711L11.799 13.2427L10.3848 11.8284ZM8.97062 1.92895L6.14219 4.75738L7.5564 6.17159L10.3848 3.34316C11.5516 2.17644 13.4607 2.17644 14.6275 3.34316C15.7942 4.50989 15.7942 6.41908 14.6275 7.58581L11.799 10.4142L13.2133 11.8284L16.0417 9.00002C17.9933 7.0484 17.9933 3.88057 16.0417 1.92895C14.0901 -0.0226632 10.9222 -0.0226634 8.97062 1.92895ZM11.0919 5.46449L5.43508 11.1213L6.84929 12.5356L12.5061 6.8787L11.0919 5.46449Z'
      fill='currentColor'
    />
  </svg>
);
