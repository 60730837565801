import { useUpdateAccountMutation } from 'components/stores/whoami';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { api } from '@api/reduxApi';
import { context } from '@stores/account';
import { Toast, useToaster } from '@stores/toaster';

interface Hook {
  account: GQAccount;
  getUserById: (user_id?: number) => TeamUser | null;
  update: (
    params: Partial<
      Pick<
        GQAccount,
        | 'name'
        | 'workspace'
        | 'permissions'
        | 'auth_methods'
        | 'ai_enabled'
        | 'interview_room_access'
        | 'highlight_reel_access'
        | 'clip_access'
        | 'insight_access'
        | 'team_wallets_enabled'
      >
    >
  ) => Promise<void>;
  refresh: () => Promise<void>;
}

export const successUpdate = (): Toast => ({
  heading: 'Account updated',
  icon: 'success',
  text: 'The account was successfully updated.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating the account. Please try again later.'
});

export const useAccountOrNull = (): GQAccount | null => {
  const state = useContext<AccountContext>(context);

  return state?.account;
};

export const useAccount = (): Hook => {
  const { account } = useContext<AccountContext>(context);
  const dispatch = useDispatch();
  const showToast = useToaster();
  const [updateAccount, { isSuccess: updateSuccess, isError: updateError }] = useUpdateAccountMutation();

  // TODO: Move this to redux
  const update: Hook['update'] = async (params) => {
    updateAccount(params);
  };

  useEffect(() => {
    if (updateSuccess) {
      showToast(successUpdate());
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateError) {
      showToast(failedUpdate());
    }
  }, [updateError]);

  const refresh: Hook['refresh'] = async () => {
    dispatch(api.util.invalidateTags(['Whoami']));
  };

  const getUserById = (user_id: number): TeamUser | null => {
    return (user_id && account.team.filter((u) => u.id === user_id)[0]) || null;
  };

  return {
    account,
    getUserById,
    update,
    refresh
  };
};
