import React from 'react';

export const Loupe: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.53759 17.3674C4.07517 18.6478 4.86034 19.8093 5.84803 20.7854C6.83571 21.7615 8.00646 22.5329 9.29305 23.0553C10.5797 23.5778 11.9568 23.8409 13.3453 23.8297C14.7339 23.8185 16.1066 23.5332 17.3846 22.9901C18.6626 22.447 19.8208 21.6568 20.7926 20.6649C21.7644 19.673 22.5307 18.499 23.0476 17.2101C23.5645 15.9213 23.8217 14.543 23.8045 13.1545C23.7873 11.766 23.496 10.3945 22.9474 9.1189C22.4098 7.83856 21.6247 6.677 20.637 5.70092C19.6493 4.72483 18.4785 3.95343 17.1919 3.431C15.9053 2.90857 14.5282 2.6454 13.1397 2.65659C11.7511 2.66779 10.3784 2.95313 9.10038 3.49624C7.82238 4.03935 6.66422 4.82954 5.69241 5.82143C4.72059 6.81332 3.95426 7.98738 3.43739 9.27623C2.92052 10.5651 2.6633 11.9433 2.6805 13.3318C2.69769 14.7203 2.98896 16.0918 3.53759 17.3674Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.6978 20.6992L27.3024 27.3016'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.72754 9.72594C9.72754 10.6582 10.0979 11.5522 10.7571 12.2114C11.4162 12.8706 12.3103 13.2409 13.2425 13.2409C14.1748 13.2409 15.0688 12.8706 15.728 12.2114C16.3872 11.5522 16.7575 10.6582 16.7575 9.72594C16.7575 8.7937 16.3872 7.89965 15.728 7.24046C15.0688 6.58127 14.1748 6.21094 13.2425 6.21094C12.3103 6.21094 11.4162 6.58127 10.7571 7.24046C10.0979 7.89965 9.72754 8.7937 9.72754 9.72594Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13.2426 15V20.2725' stroke='#374151' strokeWidth='1.88' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M18.1914 18.516C17.8332 17.4888 17.1643 16.5984 16.2774 15.9684C15.3905 15.3385 14.3296 15 13.2417 15C12.1538 15 11.0929 15.3385 10.206 15.9684C9.31912 16.5984 8.6502 17.4888 8.29199 18.516'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Charts: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1_357)'>
        <path
          d='M10.6064 24.0808L15.2931 19.3941C15.4579 19.2295 15.6812 19.1371 15.9141 19.1371C16.147 19.1371 16.3703 19.2295 16.5351 19.3941L18.8081 21.6671C18.9729 21.8317 19.1962 21.9241 19.4291 21.9241C19.662 21.9241 19.8853 21.8317 20.0501 21.6671L24.7367 16.9805'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.57635 1.82031H26.4238C26.4238 1.82031 28.1813 1.82031 28.1813 3.57781V26.4253C28.1813 26.4253 28.1813 28.1828 26.4238 28.1828H3.57635C3.57635 28.1828 1.81885 28.1828 1.81885 26.4253V3.57781C1.81885 3.57781 1.81885 1.82031 3.57635 1.82031Z'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.33398 10.9014C5.33398 11.5938 5.47036 12.2794 5.73533 12.9191C6.0003 13.5588 6.38867 14.14 6.87826 14.6296C7.36786 15.1192 7.9491 15.5076 8.58879 15.7726C9.22847 16.0375 9.91409 16.1739 10.6065 16.1739C11.2989 16.1739 11.9845 16.0375 12.6242 15.7726C13.2639 15.5076 13.8451 15.1192 14.3347 14.6296C14.8243 14.14 15.2127 13.5588 15.4776 12.9191C15.7426 12.2794 15.879 11.5938 15.879 10.9014C15.879 9.50305 15.3235 8.16197 14.3347 7.17319C13.3459 6.1844 12.0048 5.62891 10.6065 5.62891C9.20813 5.62891 7.86705 6.1844 6.87826 7.17319C5.88948 8.16197 5.33398 9.50305 5.33398 10.9014Z'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.6064 5.62891V10.9014L14.3323 14.6273'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.2725 7.38672H24.3733'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.2725 11.4883H24.3733'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_357'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Case: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.57635 7.96875H26.4238C26.4238 7.96875 28.1813 7.96875 28.1813 9.72625V25.5437C28.1813 25.5437 28.1813 27.3012 26.4238 27.3012H3.57635C3.57635 27.3012 1.81885 27.3012 1.81885 25.5437V9.72625C1.81885 9.72625 1.81885 7.96875 3.57635 7.96875Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.09131 14.1212H22.9088'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.09131 21.1523H22.9088'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.9157 3.90018C18.799 3.55039 18.5751 3.24619 18.2759 3.03066C17.9767 2.81512 17.6172 2.69917 17.2485 2.69922H12.7516C12.3828 2.69917 12.0234 2.81512 11.7242 3.03066C11.425 3.24619 11.2011 3.55039 11.0843 3.90018L9.72754 7.97172H20.2725L18.9157 3.90018Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.33398 7.96844V6.21094'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M24.666 7.96844V6.21094'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Stationery: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.09117 28.1824C6.15894 28.1824 5.26488 27.812 4.60569 27.1529C3.9465 26.4937 3.57617 25.5996 3.57617 24.6674V8.59211C3.57766 8.04676 3.70603 7.50925 3.95111 7.02207L6.29444 2.33541C6.36761 2.18995 6.47977 2.06768 6.61838 1.98224C6.757 1.8968 6.91663 1.85156 7.07946 1.85156C7.24229 1.85156 7.40191 1.8968 7.54053 1.98224C7.67915 2.06768 7.7913 2.18995 7.86447 2.33541L10.2078 7.02207C10.4529 7.50925 10.5812 8.04676 10.5827 8.59211V24.6674C10.5828 25.5956 10.2156 26.4861 9.56152 27.1446C8.90739 27.8031 8.01934 28.1762 7.09117 28.1824Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.57617 8.55859H10.6062'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.57617 22.6174H10.6062'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.6362 1.82031H24.6662C24.6662 1.82031 26.4237 1.82031 26.4237 3.57781V26.4253C26.4237 26.4253 26.4237 28.1828 24.6662 28.1828H17.6362C17.6362 28.1828 15.8787 28.1828 15.8787 26.4253V3.57781C15.8787 3.57781 15.8787 1.82031 17.6362 1.82031Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8789 7.09375H19.9797'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8789 12.3672H19.9797'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8789 17.6367H19.9797'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.8787 22.9103H19.9795'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Filter: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28.1229 3.34749C28.1307 3.22749 28.1139 3.10717 28.0734 2.99393C28.0329 2.88069 27.9697 2.77695 27.8876 2.68912C27.8054 2.60128 27.7062 2.53121 27.5959 2.48323C27.4856 2.43525 27.3667 2.41038 27.2465 2.41016H2.6977C2.5774 2.41021 2.45839 2.43496 2.34804 2.48288C2.2377 2.53081 2.13837 2.60087 2.05621 2.68875C1.97404 2.77662 1.9108 2.88043 1.87039 2.99374C1.82998 3.10705 1.81327 3.22746 1.82129 3.34749C2.02736 6.21691 3.16619 8.94021 5.06411 11.1021C6.96204 13.2641 9.51492 14.746 12.3335 15.3219V26.8921C12.3335 27.0591 12.3812 27.2226 12.4708 27.3635C12.5604 27.5044 12.6883 27.6168 12.8396 27.6876C12.9908 27.7584 13.159 27.7847 13.3246 27.7633C13.4902 27.742 13.6463 27.6739 13.7746 27.567L17.2896 24.6378C17.3887 24.5555 17.4685 24.4524 17.5231 24.3357C17.5778 24.2191 17.6061 24.0918 17.606 23.963V15.3219C20.4255 14.7471 22.9795 13.2656 24.8784 11.1035C26.7773 8.94151 27.9167 6.21762 28.1229 3.34749Z'
        stroke='#374151'
        strokeWidth='1.88'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Topics: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_143_842)'>
        <path
          d='M7.09135 3.5752H3.57635C3.11023 3.5752 2.6632 3.76036 2.33361 4.08996C2.00401 4.41955 1.81885 4.86658 1.81885 5.3327V26.4227C1.81885 26.8888 2.00401 27.3358 2.33361 27.6654C2.6632 27.995 3.11023 28.1802 3.57635 28.1802H26.4238C26.89 28.1802 27.337 27.995 27.6666 27.6654C27.9962 27.3358 28.1813 26.8888 28.1813 26.4227V5.3327C28.1813 4.86658 27.9962 4.41955 27.6666 4.08996C27.337 3.76036 26.89 3.5752 26.4238 3.5752H14.1213'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.1213 14.1201L10.6063 11.485L7.09131 14.1213V2.69755C7.09116 2.58205 7.11377 2.46766 7.15786 2.36091C7.20196 2.25416 7.26666 2.15714 7.34828 2.07542C7.42989 1.9937 7.52681 1.92886 7.63351 1.88463C7.7402 1.8404 7.85456 1.81763 7.97006 1.81763H13.2426C13.4756 1.81763 13.6991 1.91021 13.8639 2.07501C14.0287 2.2398 14.1213 2.46332 14.1213 2.69638V14.1201Z'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.09131 22.9075H19.3938'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.09131 17.635H22.9088'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.9087 12.3625H17.6362'
          stroke='#374151'
          strokeWidth='1.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_143_842'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Seniority: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21745)'>
        <path
          d='M8.37305 7.64844C8.37305 8.51024 8.54279 9.3636 8.87259 10.1598C9.20238 10.956 9.68577 11.6794 10.2952 12.2888C10.9045 12.8982 11.628 13.3816 12.4242 13.7114C13.2204 14.0412 14.0737 14.2109 14.9355 14.2109C15.7973 14.2109 16.6507 14.0412 17.4469 13.7114C18.2431 13.3816 18.9666 12.8982 19.5759 12.2888C20.1853 11.6794 20.6687 10.956 20.9985 10.1598C21.3283 9.3636 21.498 8.51024 21.498 7.64844C21.498 6.78664 21.3283 5.93328 20.9985 5.13708C20.6687 4.34088 20.1853 3.61743 19.5759 3.00805C18.9666 2.39866 18.2431 1.91527 17.4469 1.58548C16.6507 1.25568 15.7973 1.08594 14.9355 1.08594C14.0737 1.08594 13.2204 1.25568 12.4242 1.58548C11.628 1.91527 10.9045 2.39866 10.2952 3.00805C9.68577 3.61743 9.20238 4.34088 8.87259 5.13708C8.54279 5.93328 8.37305 6.78664 8.37305 7.64844Z'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.27344 4.32812C10.2909 5.38193 11.5106 6.21958 12.8595 6.79091C14.2083 7.36223 15.6586 7.65549 17.1234 7.65312C18.6066 7.6562 20.0745 7.3541 21.4359 6.76562'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.74805 29.2109C2.74805 27.6105 3.06329 26.0256 3.67576 24.547C4.28824 23.0683 5.18597 21.7248 6.31768 20.5931C7.4494 19.4614 8.79294 18.5636 10.2716 17.9512C11.7502 17.3387 13.3351 17.0234 14.9355 17.0234C16.536 17.0234 18.1208 17.3387 19.5995 17.9512C21.0782 18.5636 22.4217 19.4614 23.5534 20.5931C24.6851 21.7248 25.5828 23.0683 26.1953 24.547C26.8078 26.0256 27.123 27.6105 27.123 29.2109'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.6855 29.2266L14.9355 17.0391L11.8105 29.2266'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21745'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CompanySize: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21757)'>
        <path
          d='M8.4375 8.4375V9.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.4375 13.4375V14.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.4375 18.4375V19.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 8.4375V9.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 13.4375V14.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 18.4375V19.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5625 8.4375V9.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5625 13.4375V14.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5625 18.4375V19.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.25 4.6875V29.0625H3.75V4.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.125 4.6875V0.9375H6.875V4.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.0625 29.0625H0.9375'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M27.8125 4.6875H2.1875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.4375 23.4375V24.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5625 23.4375V24.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.5 29.0625V25.9375C17.5 24.5625 16.375 23.4375 15 23.4375C13.625 23.4375 12.5 24.5625 12.5 25.9375V29.0625'
          stroke='#374151'
          strokeWidth='1.875'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21757'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HouseholdIncome: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21780)'>
        <path
          d='M10.3125 24.6875H6.5625C6.06522 24.6875 5.58831 24.49 5.23667 24.1383C4.88504 23.7867 4.6875 23.3098 4.6875 22.8125V13.4375'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.9375 11.5594L11.9138 2.27187C12.2523 1.98535 12.6815 1.82812 13.125 1.82812C13.5685 1.82813 13.9977 1.98535 14.3362 2.27187L23.435 9.97062'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.6875 2.1875H23.4375V5.9375'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.4363 17.1875H20.8951C20.5056 17.1878 20.1284 17.3235 19.8281 17.5715C19.5278 17.8195 19.3231 18.1643 19.2492 18.5467C19.1753 18.9291 19.2368 19.3252 19.423 19.6673C19.6093 20.0093 19.9088 20.2759 20.2701 20.4212L22.8501 21.4538C23.2114 21.5991 23.5109 21.8657 23.6971 22.2077C23.8834 22.5498 23.9448 22.9459 23.8709 23.3283C23.797 23.7107 23.5924 24.0555 23.2921 24.3035C22.9918 24.5515 22.6145 24.6872 22.2251 24.6875H19.6863'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5625 17.1875V16.25'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.5625 25.625V24.6875'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.0625 20.9375C14.0625 22.9266 14.8527 24.8343 16.2592 26.2408C17.6657 27.6473 19.5734 28.4375 21.5625 28.4375C23.5516 28.4375 25.4593 27.6473 26.8658 26.2408C28.2723 24.8343 29.0625 22.9266 29.0625 20.9375C29.0625 18.9484 28.2723 17.0407 26.8658 15.6342C25.4593 14.2277 23.5516 13.4375 21.5625 13.4375C19.5734 13.4375 17.6657 14.2277 16.2592 15.6342C14.8527 17.0407 14.0625 18.9484 14.0625 20.9375Z'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21780'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Location: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21794)'>
        <path
          d='M11.25 9.375C11.25 10.3696 11.6451 11.3234 12.3483 12.0267C13.0516 12.7299 14.0054 13.125 15 13.125C15.9946 13.125 16.9484 12.7299 17.6517 12.0267C18.3549 11.3234 18.75 10.3696 18.75 9.375C18.75 8.38044 18.3549 7.42661 17.6517 6.72335C16.9484 6.02009 15.9946 5.625 15 5.625C14.0054 5.625 13.0516 6.02009 12.3483 6.72335C11.6451 7.42661 11.25 8.38044 11.25 9.375Z'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 0.9375C17.2378 0.9375 19.3839 1.82645 20.9662 3.40879C22.5486 4.99112 23.4375 7.13724 23.4375 9.375C23.4375 13.4387 17.0225 22.5337 15.38 24.7962C15.3362 24.8559 15.279 24.9045 15.213 24.9379C15.147 24.9714 15.074 24.9888 15 24.9888C14.926 24.9888 14.853 24.9714 14.787 24.9379C14.721 24.9045 14.6638 24.8559 14.62 24.7962C12.9775 22.5337 6.5625 13.4387 6.5625 9.375C6.5625 7.13724 7.45145 4.99112 9.03379 3.40879C10.6161 1.82645 12.7622 0.9375 15 0.9375Z'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22.4738 22.2344C25.8888 22.9106 28.125 24.0394 28.125 25.3169C28.125 27.3881 22.25 29.0669 15 29.0669C7.75 29.0669 1.875 27.3881 1.875 25.3169C1.875 24.0419 4.10125 22.9156 7.5 22.2381'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21794'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Gender: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15.0015 8.21062C16.2309 9.19684 17.1185 10.5453 17.538 12.0646C17.9576 13.5839 17.8878 15.1967 17.3386 16.6741C16.7895 18.1515 15.7888 19.4183 14.4788 20.2947C13.1688 21.1711 11.6159 21.6125 10.0408 21.5562C8.46565 21.4999 6.94828 20.9489 5.70413 19.9812C4.45998 19.0136 3.5523 17.6786 3.10998 16.1658C2.66766 14.6529 2.71319 13.0392 3.24009 11.5538C3.76699 10.0683 4.74849 8.78659 6.04522 7.89062'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3125 21.5625V29.0625'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.0432 17.2814C10.9184 16.3333 10.0973 15.0754 9.68201 13.6641C9.26672 12.2529 9.27563 10.7507 9.70762 9.34447C10.1396 7.93825 10.9756 6.69013 12.1115 5.75543C13.2475 4.82073 14.6333 4.24073 16.0963 4.08762C17.5594 3.9345 19.0352 4.21504 20.3401 4.8943C21.6449 5.57357 22.7212 6.62157 23.435 7.90789C24.1487 9.1942 24.4685 10.662 24.3544 12.1286C24.2402 13.5953 23.6973 14.996 22.7932 16.1564'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.1777 6.24L27.1865 0.9375'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 0.9375H27.1875V5.625'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.5625 25.3125H14.0625'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Education: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21817)'>
        <path
          d='M15 5.71402C10.9552 3.23452 6.34603 1.82489 1.60625 1.61777C1.52009 1.6141 1.43407 1.62782 1.35333 1.6581C1.27258 1.68838 1.19875 1.7346 1.13625 1.79402C1.0739 1.85287 1.02413 1.92376 0.989972 2.00239C0.95581 2.08103 0.937959 2.16578 0.9375 2.25152V23.179C0.93767 23.3439 1.00145 23.5023 1.11556 23.6213C1.22966 23.7403 1.38529 23.8107 1.55 23.8178C3.69823 23.5118 5.8859 23.637 7.98516 24.1863C10.0844 24.7355 12.0531 25.6977 13.7762 27.0165C14.1633 27.1769 14.5852 27.235 15.0013 27.1853'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.9993 5.71402C19.0441 3.23452 23.6533 1.82489 28.393 1.61777C28.4792 1.6141 28.5652 1.62782 28.646 1.6581C28.7267 1.68838 28.8005 1.7346 28.863 1.79402C28.9259 1.8533 28.9759 1.9248 29.0101 2.00412C29.0442 2.08343 29.0618 2.1689 29.0618 2.25527V23.179C29.0616 23.3439 28.9978 23.5023 28.8837 23.6213C28.7696 23.7403 28.614 23.8107 28.4493 23.8178C26.3011 23.5118 24.1134 23.637 22.0141 24.1863C19.9149 24.7355 17.9462 25.6977 16.223 27.0165C15.8359 27.1769 15.4141 27.235 14.998 27.1853'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15 5.71094V27.1947'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.8125 23.7153V27.7478C2.81223 27.8437 2.83358 27.9385 2.87494 28.025C2.91631 28.1116 2.97663 28.1877 3.05145 28.2477C3.12626 28.3078 3.21363 28.3502 3.30708 28.3719C3.40053 28.3935 3.49766 28.3938 3.59125 28.3728C7.34376 27.5904 11.1668 27.1958 15 27.1953C18.6078 27.1934 22.2051 27.5862 25.7275 28.3666C25.821 28.3871 25.918 28.3864 26.0113 28.3646C26.1045 28.3427 26.1917 28.3003 26.2664 28.2403C26.3411 28.1804 26.4014 28.1045 26.4429 28.0182C26.4844 27.9318 26.5061 27.8373 26.5063 27.7416V23.6953'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21817'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Ethnicity: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21828)'>
        <path
          d='M29.0629 29.0625C28.4877 27.4192 27.4162 25.9953 25.9964 24.9876C24.5767 23.9799 22.8789 23.4382 21.1379 23.4375C19.3969 23.4382 17.6991 23.9799 16.2793 24.9876C14.8596 25.9953 13.788 27.4192 13.2129 29.0625'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.6895 13.75C17.4886 14.577 18.4461 15.2345 19.5049 15.6834C20.5636 16.1322 21.702 16.3632 22.852 16.3625C24.0174 16.3764 25.1738 16.1554 26.252 15.7125'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.9883 16.4C15.9883 17.7659 16.5309 19.0758 17.4967 20.0416C18.4625 21.0074 19.7724 21.55 21.1383 21.55C22.5041 21.55 23.8141 21.0074 24.7799 20.0416C25.7457 19.0758 26.2883 17.7659 26.2883 16.4C26.2883 15.0341 25.7457 13.7242 24.7799 12.7584C23.8141 11.7926 22.5041 11.25 21.1383 11.25C19.7724 11.25 18.4625 11.7926 17.4967 12.7584C16.5309 13.7242 15.9883 15.0341 15.9883 16.4Z'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.9746 23.4375V29.0625'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.17525 26.6923C5.28252 25.4248 3.72603 23.7165 2.63968 21.7143C1.55333 19.7121 0.969626 17.4759 0.938789 15.1982C0.907952 12.9205 1.4309 10.6693 2.46266 8.63844C3.49441 6.60756 5.00408 4.85772 6.8618 3.53944C8.71952 2.22116 10.8697 1.37391 13.1273 1.07056C15.385 0.767215 17.6825 1.01686 19.8223 1.79801C21.9622 2.57917 23.8802 3.86845 25.4114 5.55496C26.9426 7.24147 28.0413 9.27472 28.6128 11.4798'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.0023 24.2594C8.49381 19.4783 8.10882 14.4136 8.87727 9.45938C9.2006 6.59481 10.1341 3.83279 11.6148 1.35938'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.960938 14.0625H12.1859'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.10156 7.5H26.9016'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.11133 20.625H12.1863'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.3887 1.35938C19.5633 3.24271 20.3772 5.32822 20.7887 7.50938'
          stroke='#374151'
          strokeWidth='1.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21828'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AgeRange: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.54914 6.84753C8.24414 10.8588 10.6866 13.6063 15.0016 15C19.3166 13.6063 21.7591 10.8588 22.4541 6.84753C22.4954 6.57947 22.4779 6.30565 22.4031 6.04497C22.3282 5.78429 22.1977 5.54295 22.0205 5.3376C21.8433 5.13226 21.6237 4.9678 21.3768 4.85557C21.1299 4.74334 20.8616 4.686 20.5904 4.68753H9.41288C9.14167 4.686 8.87336 4.74334 8.62645 4.85557C8.37955 4.9678 8.15993 5.13226 7.98275 5.3376C7.80557 5.54295 7.67504 5.78429 7.60018 6.04497C7.52532 6.30565 7.50791 6.57947 7.54914 6.84753Z'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.6875 0.9375H25.3125'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.41269 25.3125C9.1416 25.314 8.87341 25.2567 8.62661 25.1445C8.3798 25.0324 8.16026 24.868 7.9831 24.6628C7.80594 24.4576 7.67538 24.2165 7.60043 23.9559C7.52548 23.6954 7.50791 23.4217 7.54894 23.1538C8.24394 19.1425 10.6864 16.395 15.0014 15C19.3164 16.395 21.7589 19.1425 22.4539 23.1538C22.495 23.4217 22.4774 23.6954 22.4025 23.9559C22.3275 24.2165 22.1969 24.4576 22.0198 24.6628C21.8426 24.868 21.6231 25.0324 21.3763 25.1445C21.1295 25.2567 20.8613 25.314 20.5902 25.3125H9.41269Z'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.6875 29.0625H25.3125'
        stroke='#374151'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const PastParticipation: React.FC = () => {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2683_21860)'>
        <path
          d='M23.1973 3.55469H23.8223'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.7701 4.60938C27.1944 5.00912 27.5225 5.49989 27.7298 6.04476C27.9371 6.58963 28.0181 7.17442 27.9667 7.75511C27.9153 8.33581 27.7329 8.89729 27.4332 9.3973C27.1335 9.89732 26.7243 10.3229 26.2363 10.6419'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.86 2.57'
        />
        <path
          d='M24.75 11.2188C24.5434 11.2531 24.3344 11.2702 24.125 11.27'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.6133 23.8594L21.6658 24.4819'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.9727 28.1641L22.0252 28.7866H22.6502'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.6055 28.7866H26.2305L26.2817 28.1641'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.4883 25.6981L26.6595 23.6406'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.66 1.98'
        />
        <path
          d='M26.7617 22.4037L26.813 21.7812H27.438'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.4082 21.7812H29.0332V21.1562'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M29.0327 18.0203V17.5741C29.0327 16.5051 28.6836 15.4654 28.0385 14.613C27.3935 13.7606 26.4877 13.1421 25.459 12.8516'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.84 2.51'
        />
        <path
          d='M23.9082 12.6719C23.6987 12.6803 23.4899 12.7025 23.2832 12.7381'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.8535 5.415C10.8535 6.52791 11.2956 7.59525 12.0826 8.3822C12.8695 9.16915 13.9369 9.61125 15.0498 9.61125C16.1627 9.61125 17.23 9.16915 18.017 8.3822C18.8039 7.59525 19.246 6.52791 19.246 5.415C19.246 4.30209 18.8039 3.23475 18.017 2.4478C17.23 1.66085 16.1627 1.21875 15.0498 1.21875C13.9369 1.21875 12.8695 1.66085 12.0826 2.4478C11.2956 3.23475 10.8535 4.30209 10.8535 5.415Z'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.70961 16.4698C9.69877 15.7616 9.82891 15.0583 10.0924 14.4009C10.356 13.7434 10.7476 13.1449 11.2446 12.6403C11.7416 12.1356 12.334 11.7349 12.9874 11.4613C13.6407 11.1878 14.3419 11.0469 15.0502 11.0469C15.7585 11.0469 16.4598 11.1878 17.1131 11.4613C17.7664 11.7349 18.3588 12.1356 18.8558 12.6403C19.3528 13.1449 19.7445 13.7434 20.008 14.4009C20.2716 15.0583 20.4017 15.7616 20.3909 16.4698V21.0473H17.9759L17.3396 28.6723H12.7621L12.1259 21.0473H9.70961V16.4698Z'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.80273 3.55469H6.17773'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.22919 4.60938C2.80486 5.00912 2.47672 5.49989 2.26946 6.04476C2.06219 6.58963 1.9812 7.17442 2.03258 7.75511C2.08396 8.33581 2.26637 8.89729 2.56609 9.3973C2.8658 9.89732 3.27502 10.3229 3.76294 10.6419'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.86 2.57'
        />
        <path
          d='M5.25 11.2188C5.45656 11.2531 5.66561 11.2702 5.875 11.27'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.38648 23.8594L8.33398 24.4819'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.02711 28.1641L7.97461 28.7866H7.34961'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.395 28.7866H3.77L3.71875 28.1641'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.51305 25.6981L3.3418 23.6406'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.66 1.98'
        />
        <path
          d='M3.2368 22.4037L3.18555 21.7812H2.56055'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.5918 21.7812H0.966797V21.1562'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.966797 18.0203V17.5741C0.966858 16.5051 1.31595 15.4654 1.96101 14.613C2.60607 13.7606 3.51181 13.1421 4.54055 12.8516'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeDasharray='0.84 2.51'
        />
        <path
          d='M6.0918 12.6719C6.30135 12.6803 6.51013 12.7025 6.7168 12.7381'
          stroke='#374151'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2683_21860'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

