import { TYPE_FILTERS } from '@components/RepositoryApp/constants';

export const getTabLabels = (style?: Study['style']): Record<string, string> => {
  if (!style)
    return {
      insights: 'Insights',
      reels: 'Reels',
      highlights: 'Highlights',
      interviews: 'Interviews',
      survey_results: 'Survey results',
      all: 'All'
    };

  switch (style) {
    case 'video_call':
      return {
        insights: 'Insights',
        reels: 'Reels',
        highlights: 'Highlights',
        interviews: 'Interviews',
        all: 'All'
      };
    case 'unmoderated_test':
      return {
        insights: 'Insights',
        reels: 'Reels',
        highlights: 'Highlights',
        interviews: 'Recordings',
        summary_responses: 'Summary responses',
        individual_responses: 'Individual responses'
      };
    default:
      return {
        insights: 'Insights',
        summary_responses: 'Summary responses',
        individual_responses: 'Individual responses'
      };
  }
};

export const extraFilter = (study: Study | undefined, storedTab: string | undefined, currentTab: string) => {
  if (!study) {
    return TYPE_FILTERS[currentTab];
  }
  if (storedTab === 'all') {
    return `study_ids:${study.id}`;
  }
  return `${TYPE_FILTERS[currentTab]} AND study_ids:${study.id}`;
};
