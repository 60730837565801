import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';

import { useDestroyRecordingMutation, useResetTranscriptMutation } from '../api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  recordingId: number;
}

export const DeleteRecordingModal = ({ isOpen, onClose, recordingId }: Props) => {
  const [destroyRecording, { isLoading }] = useDestroyRecordingMutation();

  const handleConfirmButtonClick = async () => {
    await destroyRecording({ id: recordingId });
    window.location.reload();
  };

  if (!isOpen) return null;

  return (
    <Modal onClose={onClose}>
      <ModalHeading className='mb-4'>Are you sure?</ModalHeading>
      <section className='mt-4 mb-6'>
        <Text h='400'>Deleting the video will also remove the transcript and any data associated with it.</Text>
      </section>
      <div className='flex justify-end'>
        <Button onClick={handleConfirmButtonClick} danger>
          Confirm &amp; delete
        </Button>
        <Button onClick={onClose} link>
          Don't delete
        </Button>
      </div>
    </Modal>
  );
};
