type ItemWithGroupHeader = {
  id: string;
  type: 'with_group_header';
  header: string;
};
type ItemWithManageTagsLink = {
  id: string;
  type: 'with_manage_tags';
};
type ItemWithCreateLink = {
  id: string;
  type: 'with_create_link';
};
type ItemWithNameAndCreateLink = {
  id: string;
  type: 'with_name_and_create_link';
  name: string;
};
export type ItemWithTagGroup = {
  id: string;
  tagGroup: TagGroup;
  open: boolean;
  type: 'with_tag_group';
  count: number;
};
type ItemWithTag = {
  id: string;
  tag: Tag;
  type: 'with_tag';
  suggested?: boolean;
};
type ItemWithGroupPicker = {
  id: string;
  tagGroup: TagGroup | null;
  type: 'with_group_picker';
};
export type Item =
  | ItemWithTag
  | ItemWithTagGroup
  | ItemWithCreateLink
  | ItemWithNameAndCreateLink
  | ItemWithManageTagsLink
  | ItemWithGroupPicker
  | ItemWithGroupHeader;

export const buildGroupHeader = (header: string): Item => ({
  id: `with-header-${header}`,
  type: 'with_group_header',
  header
});
export const buildTagItemId = (id: number | null, name: string) => `tag-${id}-${name}`;
export const buildTagItem = (tag: Tag, suggested = false): Item => ({
  id: buildTagItemId(tag.project_id, tag.name),
  tag,
  suggested,
  type: 'with_tag'
});
export const buildTagGroupItem = (tagGroup: TagGroup, open: boolean, count: number): Item => ({
  id: String(tagGroup.id),
  tagGroup,
  type: 'with_tag_group',
  open,
  count
});
export const buildCreateLinkItem = (): Item => ({ id: 'with_create_link', type: 'with_create_link' });
export const buildManageTagsItem = (): Item => ({ id: 'with_manage_tags', type: 'with_manage_tags' });
export const buildNameAndCreateLinkItem = (name: string): Item => ({
  id: 'with_name_and_create_link',
  type: 'with_name_and_create_link',
  name
});
export const buildGroupPickerItem = (tagGroup: TagGroup | null): Item => ({
  id: 'with_group_picker',
  type: 'with_group_picker',
  tagGroup
});
