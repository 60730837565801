import cn from 'classnames';
import { Portal } from 'components/shared/Portal';
import type { SyntheticEvent } from 'react';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import * as portals from 'react-reverse-portal';
import { Rnd } from 'react-rnd';

import { useFreqTimeUpdate, useVideoPlayer, useVideoPlayerHls } from '../hooks';
import { ControlsLite } from './';
import { ControlsWithTimecodes } from './ControlsWithTimecodes';
import { PrePlayControls } from './PrePlayControls';
import type { PlayerProps, UseVideoPlayer } from '../types';

export const Player = forwardRef<UseVideoPlayer, PlayerProps>(
  (
    {
      src,
      controls,
      poster,
      clip,
      testId,
      autoPlay,
      onPlayerTimeUpdate,
      miniPlayerEnabled = true,
      currentTime: initialCurrentTime,
      className,
      liveStream,
      storyboardUrl,
      chapters,
      renderPreview,
      ...rest
    },
    ref
  ) => {
    const {
      getVideoProps,
      player,
      state: { isReady, isPlaying, duration, currentTime, buffer, volume, isFullscreen, playbackSpeed },
      play,
      pause,
      setCurrentTime,
      setVolume,
      enterFullscreen,
      exitFullscreen,
      setPlaybackSpeed,
      rewind,
      fastForward
    } = useVideoPlayer({
      showNativeControls: controls,
      autoPlay,
      clip,
      videoRef: ref,
      currentTime: initialCurrentTime
    });

    const [isHover, setIsHover] = useState<boolean>(true);
    const [hasPlayed, setHasPlayed] = useState<boolean>(false);
    const [showMiniPlayer, setShowMiniPlayer] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const showControls = isHover || !isPlaying;

    const { ref: inViewRef, inView } = useInView({ initialInView: true });

    const portalNode = useMemo(() => portals.createHtmlPortalNode(), []);

    const handleOnMouseEnter = () => {
      setIsHover(true);
    };

    const handleOnMouseLeave = () => {
      setIsHover(false);
    };

    const handleOnVideoClick = (event: SyntheticEvent) => {
      event.stopPropagation();

      if (isPlaying) pause();
      else play();
    };

    const handleOnProgressBarChange = (value: number) => {
      onPlayerTimeUpdate?.((value * duration) / 100);
      setCurrentTime((value * duration) / 100);
    };

    const getRndDefaults = () => ({
      width: 375,
      height: 'auto',
      x: window.innerWidth - 375 - 24,
      y: window.innerHeight - 190 - 42
    });

    useVideoPlayerHls({ player: player as any, src: src as any });

    useFreqTimeUpdate({ player, callback: onPlayerTimeUpdate });

    useEffect(() => {
      if (isPlaying && !hasPlayed) setHasPlayed(true);
    }, [isPlaying]);

    useEffect(() => {
      setShowMiniPlayer(!inView && hasPlayed);
    }, [inView, hasPlayed]);

    const renderControls = () => {
      if (showMiniPlayer) {
        return (
          <ControlsLite
            isPlaying={isPlaying}
            currentTime={currentTime}
            volume={volume}
            play={play}
            pause={pause}
            setVolume={setVolume}
            rewind={rewind}
            fastForward={fastForward}
            onClose={() => setShowMiniPlayer(false)}
            buffer={buffer}
            duration={duration}
            handleOnProgressBarChange={handleOnProgressBarChange}
          />
        );
      } else {
        return (
          <ControlsWithTimecodes
            isPlaying={isPlaying}
            duration={duration}
            currentTime={currentTime}
            buffer={buffer}
            volume={volume}
            isFullscreen={isFullscreen}
            playbackSpeed={playbackSpeed}
            player={player}
            show={showControls}
            play={play}
            pause={pause}
            setVolume={setVolume}
            setPlaybackSpeed={setPlaybackSpeed}
            enterFullscreen={() => (wrapperRef.current ? enterFullscreen(wrapperRef.current) : undefined)}
            exitFullscreen={exitFullscreen}
            rewind={rewind}
            fastForward={fastForward}
            hasMiniPlayer={!clip}
            handleOnProgressBarChange={handleOnProgressBarChange}
            setMiniPlayerEnabled={setShowMiniPlayer}
            chapters={chapters}
            textTracks={Array.from(player?.textTracks ?? [])}
            renderPreview={renderPreview}
          />
        );
      }
    };

    return (
      <>
        <section
          className={cn('html5-video relative', { 'html5-video-hide-controls': !controls })}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          data-testid={testId}
          ref={inViewRef}
          style={{ paddingBottom: '56.25%' }}
        >
          <div className='absolute inset-0 w-full h-full overflow-hidden'>
            <portals.InPortal node={portalNode}>
              <div
                ref={wrapperRef}
                className={cn('absolute w-full h-full', { 'rounded-lg overflow-hidden': showMiniPlayer })}
              >
                <video
                  {...getVideoProps()}
                  className={cn('w-full h-full', className)}
                  controls={controls}
                  poster={poster}
                  onClick={handleOnVideoClick}
                  crossOrigin='anonymous'
                  {...rest}
                >
                  {storyboardUrl && <track label='thumbnails' kind='metadata' src={storyboardUrl} default />}
                </video>
                {hasPlayed ? renderControls() : <PrePlayControls play={play} setPlaybackSpeed={setPlaybackSpeed} />}
              </div>
            </portals.InPortal>
            {!showMiniPlayer && <portals.OutPortal node={portalNode} />}
          </div>
        </section>
        <Portal>
          <div className='fixed inset-0 z-30 pointer-events-none'>
            {showMiniPlayer ? (
              <Rnd default={getRndDefaults()} className='relative pointer-events-auto' bounds='parent' lockAspectRatio>
                <div style={{ paddingBottom: '56.25%' }}>
                  <portals.OutPortal node={portalNode} />
                </div>
              </Rnd>
            ) : null}
          </div>
        </Portal>
      </>
    );
  }
);
