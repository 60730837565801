import React, { SVGProps } from 'react';

export const AvailiabilityBasedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.6875 2.1875H1.3125C0.829251 2.1875 0.4375 2.57925 0.4375 3.0625V12.6875C0.4375 13.1707 0.829251 13.5625 1.3125 13.5625H12.6875C13.1707 13.5625 13.5625 13.1707 13.5625 12.6875V3.0625C13.5625 2.57925 13.1707 2.1875 12.6875 2.1875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.9375 3.5V0.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0625 3.5V0.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.6875 8.3125H3.0625V10.9375H5.6875V8.3125Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 5.6875V10.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9375 5.6875H5.6875V10.9375H10.9375V5.6875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.6875 8.3125H10.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
