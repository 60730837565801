import pluralize from 'pluralize';
import React from 'react';
import { useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Checkbox, SlideOut, Text, TippyOrNot, Toggle } from '@components/common';
import { BookabilityAlerts } from '@components/shared/BookabilityAlerts';
import { IneligibleCandidates } from '@components/shared/IneligibleCandidates/IneligibleCandidates';
import { getCtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { getCtaUrl } from '@components/StudyMessages/utils/getCtaUrl';
import { useAccount } from '@hooks/useAccount';
import { useGetWhoamiQuery } from '@components/stores/whoami';

import { Batching } from './components/Batching';
import { DailyInviteLimitAlert } from './components/DailyInviteLimitAlert';
import { useMessageEditor } from './hooks/useMessageEditor';
import { EditorComponent } from './hooks/useMessageEditor/components/EditorComponent';
import { useStudyBookability } from './hooks/useStudyBookability';
import { Batching as BatchingType, SendMessageParams } from './types';
import { overDailyLimit } from './utils';

interface Props {
  study: Study;
  inviteableCount: number;
  disabled?: boolean;
  resendCount?: number;
  ineligibleCount?: number;
  title: string;
  subtitle?: string;
  batchingTitle?: string;
  batchingTarget?: boolean;
  cta?: string;
  optional?: OptionalParams;
  customizable?: boolean;
  continuousable?: boolean;
  batchable?: boolean;
  batchingRequired?: boolean;
  defaultBatching?: BatchingType;
  defaultResend?: boolean;
  event: StudyMessageEvent;
  onSend?: (props: SendMessageParams) => Promise<void>;
  onClose: () => void;
  previewId?: number;
  previewCandidate?: Candidate;
  previewParticipation?: Participation;
  studyLimits?: StudyLimit[];
  studyLimitMatches?: Record<number, number>;
}

type OptionalParams = {
  type?: 'toggle' | 'checkbox';
  disabled?: boolean;
  send: boolean;
  setSend: (v: boolean) => void;
};

const TOGGLE_TEXTS: Partial<Record<StudyMessageEvent, string>> = {
  thanks: 'Send thanks',
  booked: 'Send scheduled confirmation',
  cancel_interview: 'Include a cancellation message',
  cancel_task: 'Include a cancellation message'
};

export const StudyMessageSlideOut: React.FC<Props> = ({
  title,
  subtitle,
  batchingTitle,
  batchingTarget,
  cta,
  study,
  event,
  inviteableCount,
  disabled,
  resendCount = 0,
  ineligibleCount = 0,
  children,
  customizable = false,
  optional,
  onSend,
  onClose,
  continuousable,
  batchable,
  batchingRequired = false,
  defaultResend = false,
  defaultBatching = {
    on: false,
    size: 5,
    target: 5,
    wait_hours: 12,
    auto_restart: false
  },
  previewId,
  previewCandidate: initialPreviewCandidate,
  previewParticipation,
  studyLimits,
  studyLimitMatches
}) => {
  const [resend, setResend] = useState<boolean>(defaultResend);
  const sendMessage = !optional || optional.send;
  const { data: fetchedPreviewCandidate } = api.useGetCandidateQuery(
    { id: previewId as number },
    {
      skip: !!initialPreviewCandidate || previewId === undefined
    }
  );
  const previewCandidate = initialPreviewCandidate || fetchedPreviewCandidate;

  const {
    account: { workspace }
  } = useAccount();

  const {
    editorRef,
    editorProps,
    edit,
    message,
    save,
    setEdit,
    PreviewButton,
    sender,
    setUpdateModal,
    handleSubmit,
    sendPreview,
    setIsDirty,
    isDirty,
    setCancelModal
  } = useMessageEditor({
    study,
    event,
    previewCandidate,
    previewParticipation
  });
  const { data: dailyInviteLimit } = api.useGetDailyInviteLimitQuery(sender as EmailSender, {
    skip: !['invite', 'screener_invite'].includes(event) || !sender
  });
  const [batch, setBatch] = useState<BatchingType>(defaultBatching);
  const [addToBatch, setAddToBatch] = useState<boolean>(defaultBatching.on);
  const [excludeIneligible, setExcludeIneligible] = useState<boolean>(inviteableCount > 1);
  const [invalidBatching, setInvalidBatching] = useState<boolean>(false);

  const {
    bookability,
    hasError: studyMessageInvalid,
    startPolling
  } = useStudyBookability({
    studyId: study.id,
    event: message?.event
  });

  const onSave = () => {
    if (customizable && !message?.custom) {
      setIsDirty(false);
      setUpdateModal(true);
    } else {
      save();
    }
  };

  const onSubmit = () => {
    onSend?.({
      message,
      batch,
      sender: sender as EmailSender,
      addToBatch,
      resend,
      send: sendMessage,
      excludeIneligible: excludeIneligible
    });
  };

  const handleBatchingChange = (batch, addToBatch, valid) => {
    setInvalidBatching(!valid);
    setAddToBatch(addToBatch);
    setBatch(batch);
  };

  const sendCta = sendMessage ? cta || `Send ${event.replace('_', ' ')}` : 'Confirm';

  const noRecipients = (resend ? inviteableCount + resendCount : inviteableCount) == 0;
  const noSubject = message && (!message.subject || message.subject.trim() === '');

  const sendDisabled =
    (batchable && batchingRequired && !batch.on) ||
    invalidBatching ||
    noRecipients ||
    noSubject ||
    (sendMessage && !sender) ||
    overDailyLimit(dailyInviteLimit, inviteableCount) ||
    studyMessageInvalid;
  const canSubmit = (!disabled && !sendDisabled) || (optional && !optional.send);

  const { data: whoami } = useGetWhoamiQuery();
  const accountPublicBaseUrl = whoami?.account_public_domain?.base_url;

  return (
    <SlideOut
      size='xl'
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      renderFooter={() => (
        <div className='flex flex-row w-full space-x-6'>
          {!edit && (
            <TippyOrNot show={!canSubmit} content='Please fix issues in the email in order to save changes.'>
              <Button
                data-testid='xx-send-email'
                disabled={!canSubmit}
                icon={sendMessage ? 'send' : undefined}
                className='xx-send-email'
                onClick={onSubmit}
                aria-label={sendCta}
                primary
              >
                {sendCta}
              </Button>
            </TippyOrNot>
          )}
          {sendMessage && edit && (
            <Button primary data-testid='save-email' onClick={handleSubmit(onSave)}>
              Save changes
            </Button>
          )}
          {sendMessage && edit && (
            <Button
              link
              onClick={() => {
                isDirty ? setCancelModal(true) : setEdit(false);
              }}
            >
              Cancel changes
            </Button>
          )}
          {sendMessage && !edit && (
            <Button icon='editEmail' data-testid='edit-email' onClick={() => setEdit(true)}>
              Edit email
            </Button>
          )}
          {sendMessage && !edit && <PreviewButton aboveButton />}
        </div>
      )}
    >
      <div className='p-6 border-t border-gray-200'>
        {children && <div className='mb-6'>{children}</div>}
        {!!optional && !edit && (
          <div className='flex flex-row items-center mb-6 space-x-2'>
            {optional.type === 'checkbox' ? (
              <Checkbox
                name='send_email'
                disabled={optional.disabled}
                selected={optional.send}
                onChange={optional.setSend}
              />
            ) : (
              <Toggle name='send_email' disabled={optional.disabled} on={optional.send} onToggle={optional.setSend} />
            )}
            <Text as='span' color={optional.disabled ? 'gray-500' : 'gray-700'}>
              {TOGGLE_TEXTS[event]}
            </Text>
          </div>
        )}
        {sendMessage && (
          <div className='mb-6'>
            {dailyInviteLimit && <DailyInviteLimitAlert dailyInviteLimit={dailyInviteLimit} count={inviteableCount} />}
            {bookability && !edit && (
              <BookabilityAlerts study={study} bookability={bookability} onStartPolling={startPolling} />
            )}
            <IneligibleCandidates
              inviteableCount={inviteableCount}
              previewCandidate={previewCandidate}
              resendCount={resendCount}
              excludeIneligible={excludeIneligible}
              setExcludeIneligible={setExcludeIneligible}
              ineligibleCount={ineligibleCount}
              studyLimits={studyLimits}
              studyLimitMatches={studyLimitMatches}
            />
            {resendCount > 0 && (
              <label className='h500 flex flex-row items-center mt-2 space-x-2'>
                <Checkbox selected={resend} value={true} onChange={() => setResend(!resend)} />
                <span>
                  Resend to {resendCount} already invited {pluralize('candidates', resendCount)}
                </span>
              </label>
            )}
          </div>
        )}
        {sendMessage && (
          <EditorComponent
            {...editorProps}
            sendPreview={sendPreview}
            customizable={customizable && !message?.custom}
            ref={editorRef}
            ctaProps={getCtaProps({
              ctaUrl: getCtaUrl(workspace, study, message, accountPublicBaseUrl) || '',
              videoUrlType: study.video_url_type,
              studyStyle: study.style,
              screenerInviteCta: study.screener_invite_cta,
              event: message?.event || 'ad_hoc',
              videoUrl: study.video_url_type === 'in_person' ? study.location.address : study.video_url || '',
              tremendous: study.incentive_method === 'tremendous'
            })}
          />
        )}
        {batchable && !!inviteableCount && inviteableCount > 1 && (
          <Batching
            title={batchingTitle}
            target={batchingTarget}
            addToBatch={addToBatch}
            batch={batch}
            continuous={continuousable}
            count={inviteableCount}
            event={event}
            existingBatch={defaultBatching.on}
            onChange={handleBatchingChange}
            required={batchingRequired}
          />
        )}
      </div>
    </SlideOut>
  );
};
