import React, { SVGProps } from 'react';

export const TagsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1093_4203)'>
      <path
        d='M6.93751 15.4922C6.93715 15.4922 6.93669 15.4922 6.93623 15.4922C6.46684 15.4918 6.02547 15.3087 5.69368 14.9764L1.02046 10.2965C0.336929 9.61167 0.336929 8.49738 1.02046 7.81266L7.32002 1.50101C7.96922 0.850525 8.83302 0.492188 9.75221 0.492188H13.7489C14.7181 0.492188 15.5068 1.28064 15.5068 2.25V6.23447C15.5068 7.15302 15.1488 8.01645 14.4989 8.66556L8.17979 14.9781C7.84782 15.3096 7.40663 15.4922 6.93751 15.4922ZM9.75221 1.66406C9.1465 1.66406 8.57732 1.90018 8.14949 2.32883L1.84984 8.64048C1.62196 8.86881 1.62196 9.24015 1.84984 9.46848L6.52306 14.1484C6.63356 14.2591 6.78059 14.3202 6.93715 14.3203H6.93761C7.09398 14.3203 7.24101 14.2594 7.35161 14.149L13.6708 7.83655C14.099 7.40882 14.3349 6.83972 14.3349 6.23447V2.25C14.3349 1.92691 14.072 1.66406 13.7489 1.66406H9.75221ZM11.2586 6.43946C10.2894 6.43946 9.50081 5.651 9.50081 4.68164C9.50081 3.71228 10.2894 2.92383 11.2586 2.92383C12.2279 2.92383 13.0164 3.71228 13.0164 4.68164C13.0164 5.651 12.2279 6.43946 11.2586 6.43946ZM11.2586 4.0957C10.9355 4.0957 10.6727 4.35855 10.6727 4.68164C10.6727 5.00473 10.9355 5.26758 11.2586 5.26758C11.5818 5.26758 11.8446 5.00473 11.8446 4.68164C11.8446 4.35855 11.5818 4.0957 11.2586 4.0957Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1093_4203'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
