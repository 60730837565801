import React from 'react';

import { usePermission } from '@hooks/usePermission';

import * as AlertEls from './components';
import { getAlerts } from './getAlerts';
import { AlertKey, AlertProps, AlertsProps } from './types';

const AlertMapping: { [key in AlertKey]?: React.FC<AlertProps> } = {
  batching_on: AlertEls.BatchingOn,
  must_fund: AlertEls.MustFund,
  filled: AlertEls.Filled,
  cal_slots: AlertEls.CalSlots,
  good_cal: AlertEls.GoodCal,
  pending_funding: AlertEls.PendingFunding,
  funding_required: AlertEls.AdminFund,
  sample: AlertEls.Sample
};

export const Alerts: React.FC<AlertsProps> = ({ className = '-mt-6', slots, user, study, openFundModal, canAction, keys }) => {
  const canFund = usePermission('fundStudy')();
  const includedKeys = keys || Object.keys(AlertMapping);

  if (!slots) {
    return null;
  }

  const alerts: Record<AlertKey, boolean> = getAlerts({ study, slots, canFund });

  function renderAlert(key) {
    const AlertEl = AlertMapping[key];
    return (
      <AlertEl
        key={`study_alert_${key}`}
        user={user}
        study={study}
        slots={slots}
        openFundModal={openFundModal}
        canAction={canAction}
      />
    );
  }

  const hasAlerts = includedKeys.map((key) => alerts[key]).some((v) => v);

  if (hasAlerts) {
    return <div className={className}>{includedKeys.map((key) => (alerts[key] ? renderAlert(key) : ''))}</div>;
  }
  return <></>;
};
