import participationStatus from 'helpers/participationStatus';
import * as React from 'react';
import { useState } from 'react';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import SearchSelector from '@components/shared/SearchSelector';
// import Mustache from 'mustache';
// TODO: Move utisl to somehwere that makes more sense...
import { humanize } from '@components/utils';

const ShortlistForm = ({ candidate, study }) => {
  return (
    <>
      <span>
        Shortlisting <span className='font-semibold'>{candidate.first_name || 'unnamed candidate'}</span> to{' '}
        <span className='font-semibold'>{study.title}</span>.
      </span>
      <div className='form-group flex justify-between mt-3'>
        <button className={`btn btn-primary`}>Shortlist</button>
      </div>
    </>
  );
};

const Shortlist = ({ candidate, studies, participations, onSuccess }) => {
  const [study, setStudy] = useState<Study>();
  const [loading, setLoading] = useState(false);

  const [createParticipation] = api.useCreateParticipationMutation();

  async function handleStudySelect(id) {
    setLoading(true);
    setStudy(studies.find(({ id: studyId }) => studyId === id));
    setLoading(false);
  }

  function getParticipation(studyId) {
    return participations.find(({ project_id }) => project_id === studyId);
  }

  async function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    // const participation = getParticipation(study.id)
    if (study) {
      try {
        const participation = await createParticipation({
          customer_id: candidate.id,
          project_id: study.id,
          transition: 'shortlist',
          source: 'profile'
        }).unwrap();

        onSuccess(participation);
      } catch (_) {
        console.error('Error creating participation');
      }
    }

    setLoading(false);
  }

  // TODO: SHOULD this logic really live on the future
  // TODO: add 'shortlistable to particpation - server side permissions'
  // const options = studies.map(({title: label, id: value}) => ({label, value }))
  const options: DropdownItem[] = studies.map(({ title, id, state: studyState }) => {
    const participation = getParticipation(id);
    const status = participation?.status;
    // if status
    let disabled = false;
    let statusText;
    if (['requested', 'started', 'booked', 'completed', 'shortlisted'].includes(status)) {
      disabled = true;
      statusText = `Already ${participationStatus(status, participation.style).toLowerCase()}`;
    } else if (!['active', 'pending'].includes(studyState)) {
      disabled = true;
      statusText = `${humanize(studyState)} study`;
    } else if (status) {
      statusText = participationStatus(status, participation.style);
    }

    return {
      label: title,
      text: statusText,
      disabled: disabled,
      value: id
    };
  });

  return (
    <div>
      {loading && <Loading absolute />}
      <form onSubmit={onSubmit}>
        <div className='form-group mt-4'>
          <SearchSelector onSelect={handleStudySelect} options={options} label='Select study' />
        </div>
        {study && <ShortlistForm candidate={candidate} study={study} />}
      </form>
    </div>
  );
};
export default Shortlist;
