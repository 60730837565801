import React, { SVGProps } from 'react';

export const HashtagSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2.5 5.49866H15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.5 11.4987H13.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8 0.498657L3 15.4987' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13 0.498657L8 15.4987' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
