import React from 'react';

import { Button } from '@components/common';
import { ConnectionErrorSVG } from '@components/svgs';
import { SUPPORT_EMAIL } from '@constants/emails';

export const ConnectionError: React.FC = () => {
  return (
    <div className='py-gutter px-page flex flex-col items-center justify-center flex-1 w-full max-w-2xl mx-auto'>
      <ConnectionErrorSVG className='mx-auto mb-4' />
      <span className='block mt-5 text-2xl font-bold text-center text-gray-700'>Something went wrong!</span>
      <span className='block mt-2 text-center text-gray-700'>
        We could not connect to our cloud provider. Please try to reload the page. If the issue keeps happening, contact
        our support at {SUPPORT_EMAIL}.
      </span>

      <Button primary className='mt-6' icon='reload' onClick={() => window.location.reload()}>
        Reload page
      </Button>
    </div>
  );
};
