import { compact } from '@components/utils';

import { GetCalendarStateSet } from '../store/getters';
import {
  buildSlotInstance,
  fixSlots,
  getAllTimesBetween,
  getHourMin,
  isFreeAt,
  normalizeSlots,
  rawNylasEventToSlotInstance
} from '../utils';

export type ConflictChecker = {
  isConflictAt: (time: string, duration?: number) => boolean; // returns if the time is during (overlapping) a conflict
  getConflictAt: (time: string) => SlotInstance | undefined; // returns a conflict that starts at the time
};

type Params = {
  availabilityBlocks: SlotInstance[];
  calendarStates: GetCalendarStateSet;
  date: string;
  startHour: number;
  endHour: number;
};

export const conflictChecker = ({
  availabilityBlocks,
  calendarStates,
  date,
  startHour,
  endHour,
}: Params): ConflictChecker => {
  const getConflictDurationAt = (time: string): number | null => {
    let conflictLengthInMinutes = 0;
    const anyConflicts =
      calendarStates.filter(({ userId, events: rawNylasEvents, conflicts }, i) => {
        if (!conflicts) return false;

        const eventsAsSlots = normalizeSlots(
          compact(
            (rawNylasEvents || []).filter((e) => e.busy).map((e) => rawNylasEventToSlotInstance(userId, e))
          ) as any,
          startHour,
          endHour
        ).filter((e) => e.date === date && e.time === time);

        return (
          eventsAsSlots.filter((e) => {
            const { hour, min } = getHourMin(e.time);
            const isOverlapped = !isFreeAt(availabilityBlocks, hour, min, e.duration);

            if (isOverlapped) {
              conflictLengthInMinutes = Math.max(conflictLengthInMinutes, e.duration);
            }
            return isOverlapped;
          }).length > 0
        );
      }).length > 0;

    return anyConflicts ? conflictLengthInMinutes : null;
  };

  const conflicts: SlotInstance[] = fixSlots(
    compact(
      getAllTimesBetween(startHour, endHour).map((time) => {
        const duration = getConflictDurationAt(time);
        return duration ? buildSlotInstance(date, time, duration) : false;
      })
    )
  );

  const getConflictAt_v2 = (time: string) => {
    return conflicts.find((c) => {
      const conflictStartHour = getHourMin(c.time);
      const conflictStart = conflictStartHour.hour + conflictStartHour.min / 60;
      const conflictEnd = conflictStartHour.hour + conflictStartHour.min / 60 + c.duration / 60;

      const current = getHourMin(time);
      const currentStart = current.hour + current.min / 60;

      return currentStart >= conflictStart && currentStart <= conflictEnd;
    });
  };
  const isConflictAt = (time: string, duration = 0) => {
    const { hour, min } = getHourMin(time);
    return !isFreeAt(conflicts, hour, min, duration);
  };

  return { isConflictAt, getConflictAt: getConflictAt_v2 };
};
