import * as React from 'react';

export const incentives: React.FC = () => (
  <svg
    className='group-hover:text-gray-300 w-4 h-4 mr-3 text-white'
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M2.40625 4.375C2.44951 4.375 2.49181 4.38783 2.52778 4.41187C2.56375 4.4359 2.59179 4.47007 2.60835 4.51004C2.62491 4.55001 2.62924 4.59399 2.6208 4.63643C2.61236 4.67886 2.59152 4.71784 2.56093 4.74843C2.53034 4.77902 2.49136 4.79986 2.44893 4.8083C2.40649 4.81674 2.36251 4.81241 2.32254 4.79585C2.28257 4.77929 2.2484 4.75125 2.22437 4.71528C2.20033 4.67931 2.1875 4.63701 2.1875 4.59375C2.1875 4.53573 2.21055 4.48009 2.25157 4.43907C2.29259 4.39805 2.34823 4.375 2.40625 4.375Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M9.84375 7.4375C9.88701 7.4375 9.92931 7.45033 9.96528 7.47437C10.0013 7.4984 10.0293 7.53257 10.0458 7.57254C10.0624 7.61251 10.0667 7.65649 10.0583 7.69893C10.0499 7.74136 10.029 7.78034 9.99843 7.81093C9.96784 7.84152 9.92886 7.86236 9.88643 7.8708C9.84399 7.87924 9.80001 7.87491 9.76004 7.85835C9.72007 7.84179 9.6859 7.81375 9.66187 7.77778C9.63783 7.74181 9.625 7.69951 9.625 7.65625C9.625 7.59823 9.64805 7.54259 9.68907 7.50157C9.73009 7.46055 9.78573 7.4375 9.84375 7.4375Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M0.4375 2.625H11.8125V9.625H0.4375V2.625Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M4.375 6.125C4.375 6.58913 4.55937 7.03425 4.88756 7.36244C5.21575 7.69063 5.66087 7.875 6.125 7.875C6.58913 7.875 7.03425 7.69063 7.36244 7.36244C7.69063 7.03425 7.875 6.58913 7.875 6.125C7.875 5.66087 7.69063 5.21575 7.36244 4.88756C7.03425 4.55937 6.58913 4.375 6.125 4.375C5.66087 4.375 5.21575 4.55937 4.88756 4.88756C4.55937 5.21575 4.375 5.66087 4.375 6.125Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M13.5625 5.25V10.5C13.5625 10.7321 13.4703 10.9546 13.3062 11.1187C13.1421 11.2828 12.9196 11.375 12.6875 11.375H3.0625'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
