import React, { ReactNode, useState } from 'react';
import { track } from '@components/tracking';
import { NewStudySlideout } from '../NewStudySlideout';

type PropsWithChildrenFn<A extends Record<string, any>> = {
  children: ReactNode | ((args: A) => ReactNode);
};

type SlideoutProps = {
  teamId?: number | null;
  externalCandidatesDefaulted?: boolean;
};

interface RenderProps {
  openSlideout: (props?: SlideoutProps) => void;
}

export const NewStudyAction = ({ children }: PropsWithChildrenFn<RenderProps>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [slideoutProps, setSlideoutProps] = useState<SlideoutProps>({});

  const openSlideout: RenderProps['openSlideout'] = (props = {}) => {
    track('opened_new_study_slideout');
    setIsOpen(true);
    setSlideoutProps(props);
  };

  const closeSlideout = () => setIsOpen(false);

  const context: RenderProps = {
    openSlideout
  };

  return (
    <>
      {typeof children === 'function' ? children(context) : children}
      <NewStudySlideout isVisible={isOpen} onClose={closeSlideout} {...slideoutProps} />
    </>
  );
};
