import React, { SVGProps } from 'react';

export const NotificationErrorSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 15.125C10.932 15.125 10.8656 15.1452 10.809 15.1829C10.7525 15.2207 10.7084 15.2744 10.6824 15.3372C10.6564 15.4 10.6496 15.4691 10.6629 15.5358C10.6761 15.6025 10.7089 15.6637 10.7569 15.7118C10.805 15.7599 10.8663 15.7926 10.9329 15.8059C10.9996 15.8192 11.0687 15.8124 11.1315 15.7863C11.1944 15.7603 11.248 15.7163 11.2858 15.6597C11.3236 15.6032 11.3437 15.5367 11.3437 15.4687C11.3437 15.3776 11.3075 15.2901 11.2431 15.2257C11.1786 15.1612 11.0912 15.125 11 15.125Z'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M11 12.375V5.5' stroke='white' strokeWidth='1.375' strokeMiterlimit='10' strokeLinecap='round' />
    <path
      d='M1.17334 12.1743C0.862282 11.8628 0.687561 11.4406 0.687561 11.0005C0.687561 10.5603 0.862282 10.1381 1.17334 9.82667L9.82668 1.17425C9.98063 1.01993 10.1635 0.897491 10.3648 0.813951C10.5662 0.73041 10.782 0.687408 11 0.687408C11.218 0.687408 11.4338 0.73041 11.6352 0.813951C11.8365 0.897491 12.0194 1.01993 12.1733 1.17425L20.8267 9.82667C21.1377 10.1381 21.3125 10.5603 21.3125 11.0005C21.3125 11.4406 21.1377 11.8628 20.8267 12.1743L12.1733 20.8267C11.8621 21.1377 11.44 21.3124 11 21.3124C10.56 21.3124 10.138 21.1377 9.82668 20.8267L1.17334 12.1743Z'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
