import * as React from 'react';

export const guides: React.FC = () => (
  <svg
    className='group-hover:text-gray-300 w-4 h-4 mr-3 text-white'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.00001 12.9062V2.74866C7.00001 2.74866 5.56793 1.15791 0.739677 1.09374C0.700038 1.09324 0.660704 1.10073 0.624023 1.11577C0.587341 1.1308 0.554064 1.15307 0.526177 1.18124C0.469278 1.23842 0.437389 1.31583 0.43751 1.39649V10.9486C0.436866 11.0272 0.467042 11.1029 0.521567 11.1595C0.576091 11.2161 0.650622 11.249 0.729177 11.2513C5.56676 11.3167 7.00001 12.9062 7.00001 12.9062Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.24997 6.52167C4.25729 6.18778 3.2263 5.98099 2.18164 5.90625'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.24997 8.96875C4.25729 8.63486 3.2263 8.42807 2.18164 8.35333'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M8.75 6.52167C9.74269 6.18778 10.7737 5.98099 11.8183 5.90625'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M8.75 8.96875C9.74269 8.63486 10.7737 8.42807 11.8183 8.35333'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M7 12.9062V2.74866C7 2.74866 8.43208 1.15791 13.2603 1.09374C13.3 1.09324 13.3393 1.10073 13.376 1.11577C13.4127 1.1308 13.4459 1.15307 13.4738 1.18124C13.5307 1.23842 13.5626 1.31583 13.5625 1.39649V10.9486C13.5631 11.0272 13.533 11.1029 13.4784 11.1595C13.4239 11.2161 13.3494 11.249 13.2708 11.2513C8.43325 11.3167 7 12.9062 7 12.9062Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
