import * as React from 'react';
import { useState } from 'react';

import { Column, Grid } from '@components/common/Grid';
import { DeleteModal } from '@components/RepositoryApp/hits/artifacts/DeleteModal';
import * as toasts from '@components/RepositoryApp/hits/artifacts/toasts';
import { sortToIndex } from '@components/RepositoryApp/RepositoryIndex';
import { AlgoliaData, useSearch } from '@components/shared/Search';
import { UseTableFilters } from '@components/shared/TableFilters/hook/useTableFilters';
import { useCollectionView } from '@components/stores/view';
import { useToaster } from '@stores/toaster';

import { useRepositoryContext } from '../../hooks/useRepositoryContext';
import { ArtifactAttributes, ArtifactHit } from '../../types';
import { ArtifactTable } from './ArtifactTable';
import { Count } from './Count';
import { useInfiniteScroll } from './useInfiniteScroll';
import { ArtifactCard } from '@components/RepositoryApp/hits/artifacts/ArtifactCard';
import { api } from '@api/reduxApi';
import { decode } from '@components/shared/TableFilters/utils/decode';

interface ArtifactHitsProps {
  hook: UseTableFilters<ArtifactHit>;
  canSelect?: boolean;
  layout: LayoutMode;
}

export const ArtifactHits: React.FC<ArtifactHitsProps> = ({ hook, canSelect, layout }) => {
  const { data, nextPage } = useSearch<AlgoliaData>();
  const { hits = [], hasMore = false } = data ?? {};
  const { sentinel } = useInfiniteScroll({ hasMore, refineNext: nextPage });

  const { reload, selectedArtifacts, setSelectedArtifacts, setPreviewSlideout, study } = useRepositoryContext();
  const {
    view: { sort }
  } = useCollectionView();

  const [destroyHighlight] = api.useDestroyHighlightMutation();

  const sortIndex = sortToIndex(sort);

  const results = hook.rawFilter(hits as ArtifactHit[]);

  const [selected, setSelected] = useState<ArtifactAttributes>();

  const [modalOpen, setModalOpen] = useState(false);

  const showToast = useToaster();

  const handleOnHighlightDelete = async () => {
    if (!selected) return;

    try {
      await destroyHighlight({ highlightUuid: String(selected.model_id) }).unwrap();

      setModalOpen(false);
      showToast(toasts.successDeleteClip(selected.title));
      reload?.();
    } catch {
      showToast(toasts.failedDeleteClip());
    }
  };

  const onDelete = (h: ArtifactAttributes): void => {
    setSelected(h);
    setModalOpen(true);
  };

  const onClickTag = (params: string) => {
    const decoded = decode(params, hook.definitions);

    if (decoded.length > 0) {
      hook.setFilters(decoded);
    }
  };

  const renderer = () => {
    switch (layout) {
      case 'grid':
        return (
          <Grid gap={6} monitor={5} desktop={3} tablet={2} mobile={2} tiny={1} className='pb-4'>
            {results.map((hit) => (
              <Column key={hit.objectID} className='flex'>
                <ArtifactCard
                  {...hit}
                  page={study ? 'study_data' : 'data'}
                  canSelect={canSelect}
                  selectedArtifacts={selectedArtifacts}
                  setSelectedArtifacts={setSelectedArtifacts}
                  sort={sortIndex}
                  onClickTag={onClickTag}
                  onDelete={onDelete}
                  setPreviewSlideout={setPreviewSlideout}
                />
              </Column>
            ))}
          </Grid>
        );
      case 'list':
        return <ArtifactTable onDelete={onDelete} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className='flex items-center mb-4'>
        <Count className='flex-1' />
      </div>
      <div className='pb-10'>
        {renderer()}
        <DeleteModal
          onConfirm={async () => {
            if (selected && ['Clip', 'Highlight'].includes(selected.kind)) {
              await handleOnHighlightDelete();
            }
            setModalOpen(false);
          }}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          reload={reload}
          selected={selected}
        />

        <div ref={sentinel} className='h-24'></div>
      </div>
    </div>
  );
};
