import { RadioGroupOption } from '@components/common/RadioGroup';

export const respondentFiltersObjects: ExternalCandidatesFilterListResponse[] = [
  {
    filter_id: 'targetCountries',
    title: 'Location',
    type: 'multiselect',
    all_option: 'All locations',
    choices: {
      all: 'All locations',
      AX: 'Åland Islands',
      AL: 'Albania',
      DZ: 'Algeria',
      AS: 'American Samoa',
      AD: 'Andorra',
      AI: 'Anguilla',
      AQ: 'Antarctica',
      AG: 'Antigua and Barbuda',
      AR: 'Argentina',
      AM: 'Armenia',
      AW: 'Aruba',
      AU: 'Australia',
      AT: 'Austria',
      BS: 'Bahamas',
      BH: 'Bahrain',
      BD: 'Bangladesh',
      BB: 'Barbados',
      BY: 'Belarus',
      BE: 'Belgium',
      BZ: 'Belize',
      BJ: 'Benin',
      BM: 'Bermuda',
      BT: 'Bhutan',
      BO: 'Bolivia',
      BA: 'Bosnia and Herzegovina',
      BW: 'Botswana',
      BV: 'Bouvet Island',
      BR: 'Brazil',
      VG: 'British Virgin Islands',
      IO: 'British Indian Ocean Territory',
      BN: 'Brunei Darussalam',
      BG: 'Bulgaria',
      BF: 'Burkina Faso',
      BI: 'Burundi',
      CV: 'Cabo Verde',
      CM: 'Cameroon',
      CA: 'Canada',
      KY: 'Cayman Islands',
      CF: 'Central African Republic',
      TD: 'Chad',
      CL: 'Chile',
      CX: 'Christmas Island',
      CC: 'Cocos (Keeling) Islands',
      CO: 'Colombia',
      KM: 'Comoros',
      CG: 'Congo',
      CK: 'Cook Islands',
      CR: 'Costa Rica',
      HR: 'Croatia',
      CU: 'Cuba',
      CW: 'Curaçao',
      CY: 'Cyprus',
      CZ: 'Czech Republic',
      CI: "Côte d'Ivoire",
      CD: 'Democratic Republic of the Congo',
      DK: 'Denmark',
      DJ: 'Djibouti',
      DM: 'Dominica',
      EC: 'Ecuador',
      EG: 'Egypt',
      SV: 'El Salvador',
      GQ: 'Equatorial Guinea',
      ER: 'Eritrea',
      EE: 'Estonia',
      SZ: 'Eswatini',
      ET: 'Ethiopia',
      FK: 'Falkland Islands (Malvinas)',
      FO: 'Faroe Islands',
      FJ: 'Fiji',
      FI: 'Finland',
      FR: 'France',
      GF: 'French Guiana',
      PF: 'French Polynesia',
      TF: 'French Southern Territories',
      GA: 'Gabon',
      GM: 'Gambia',
      GE: 'Georgia',
      DE: 'Germany',
      GI: 'Gibraltar',
      GR: 'Greece',
      GL: 'Greenland',
      GD: 'Grenada',
      GP: 'Guadeloupe',
      GU: 'Guam',
      GT: 'Guatemala',
      GG: 'Guernsey',
      GN: 'Guinea',
      GW: 'Guinea-Bissau',
      GY: 'Guyana',
      HT: 'Haiti',
      HM: 'Heard Island and McDonald Islands',
      VA: 'Holy See',
      HN: 'Honduras',
      HK: 'Hong Kong',
      HU: 'Hungary',
      IS: 'Iceland',
      IN: 'India',
      ID: 'Indonesia',
      IR: 'Iran (Islamic Republic of)',
      IQ: 'Iraq',
      IE: 'Ireland',
      IM: 'Isle of Man',
      IL: 'Israel',
      IT: 'Italy',
      JM: 'Jamaica',
      JP: 'Japan',
      JE: 'Jersey',
      JO: 'Jordan',
      KZ: 'Kazakhstan',
      KE: 'Kenya',
      KI: 'Kiribati',
      KW: 'Kuwait',
      KG: 'Kyrgyzstan',
      LA: `Lao People's Democratic Republic`,
      LV: 'Latvia',
      LB: 'Lebanon',
      LS: 'Lesotho',
      LR: 'Liberia',
      LY: 'Libya',
      LI: 'Liechtenstein',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MG: 'Madagascar',
      MW: 'Malawi',
      MY: 'Malaysia',
      MV: 'Maldives',
      ML: 'Mali',
      MT: 'Malta',
      MH: 'Marshall Islands',
      MQ: 'Martinique',
      MR: 'Mauritania',
      MU: 'Mauritius',
      YT: 'Mayotte',
      MX: 'Mexico',
      FM: 'Micronesia (Federated States of)',
      MD: 'Moldova (Republic of)',
      MC: 'Monaco',
      MN: 'Mongolia',
      ME: 'Montenegro',
      MS: 'Montserrat',
      MA: 'Morocco',
      MZ: 'Mozambique',
      MM: 'Myanmar',
      NA: 'Namibia',
      NR: 'Nauru',
      NP: 'Nepal',
      NL: 'Netherlands',
      NC: 'New Caledonia',
      NZ: 'New Zealand',
      NI: 'Nicaragua',
      NE: 'Niger',
      NG: 'Nigeria',
      NU: 'Niue',
      NF: 'Norfolk Island',
      KP: 'North Korea',
      MK: 'North Macedonia',
      MP: 'Northern Mariana Islands',
      NO: 'Norway',
      OM: 'Oman',
      PK: 'Pakistan',
      PW: 'Palau',
      PS: 'Palestine, State of',
      PA: 'Panama',
      PG: 'Papua New Guinea',
      PY: 'Paraguay',
      PE: 'Peru',
      PH: 'Philippines',
      PN: 'Pitcairn',
      PL: 'Poland',
      PT: 'Portugal',
      PR: 'Puerto Rico',
      QA: 'Qatar',
      RO: 'Romania',
      RU: 'Russian Federation',
      RW: 'Rwanda',
      RE: 'Réunion',
      BL: 'Saint Barthélemy',
      SH: 'Saint Helena, Ascension and Tristan da Cunha',
      KN: 'Saint Kitts and Nevis',
      LC: 'Saint Lucia',
      MF: 'Saint Martin (French part)',
      PM: 'Saint Pierre and Miquelon',
      VC: 'Saint Vincent and the Grenadines',
      WS: 'Samoa',
      SM: 'San Marino',
      ST: 'Sao Tome and Principe',
      SA: 'Saudi Arabia',
      SN: 'Senegal',
      RS: 'Serbia',
      SC: 'Seychelles',
      SL: 'Sierra Leone',
      SG: 'Singapore',
      SX: 'Sint Maarten (Dutch part)',
      SK: 'Slovakia',
      SI: 'Slovenia',
      SB: 'Solomon Islands',
      SO: 'Somalia',
      ZA: 'South Africa',
      GS: 'South Georgia and the South Sandwich Islands',
      SS: 'South Sudan',
      ES: 'Spain',
      LK: 'Sri Lanka',
      SD: 'Sudan',
      SR: 'Suriname',
      SJ: 'Svalbard and Jan Mayen',
      SE: 'Sweden',
      CH: 'Switzerland',
      SY: 'Syrian Arab Republic',
      TW: 'Taiwan',
      TJ: 'Tajikistan',
      TZ: 'Tanzania (United Republic of)',
      TH: 'Thailand',
      TL: 'Timor-Leste',
      TG: 'Togo',
      TK: 'Tokelau',
      TO: 'Tonga',
      TT: 'Trinidad and Tobago',
      TN: 'Tunisia',
      TR: 'Turkey',
      TM: 'Turkmenistan',
      TC: 'Turks and Caicos Islands',
      TV: 'Tuvalu',
      UG: 'Uganda',
      UA: 'Ukraine',
      AE: 'United Arab Emirates',
      GB: 'United Kingdom',
      UM: 'United States Minor Outlying Islands',
      US: 'United States',
      UY: 'Uruguay',
      UZ: 'Uzbekistan',
      VU: 'Vanuatu',
      VE: 'Venezuela (Bolivarian Republic of)',
      VN: 'Viet Nam',
      WF: 'Wallis and Futuna',
      EH: 'Western Sahara',
      YE: 'Yemen',
      ZM: 'Zambia',
      ZW: 'Zimbabwe'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'targetHouseholdIncome',
    title: 'Household Income',
    all_option: 'All incomes',
    type: 'checkboxes',
    choices: {
      '<30000': '<$30,000',
      '30000-39999': '$30,000 - $39,999',
      '40000-59999': '$40,000 - $59,999',
      '60000-79999': '$60,000 - $79,999',
      '80000-99999': '$80,000 - $99,999',
      '100000-149999': '$100,000 - $149,999',
      '150000-199999': '$150,000 - $199,999',
      '200000+': '$200,000+'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'targetGenders',
    title: 'Gender',
    default_value: 'N/A',
    type: 'radios',
    choices: {
      'N/A': 'All genders',
      male: 'All male',
      female: 'All female',
      other: 'Other'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'targetEducation',
    title: 'Education',
    type: 'checkboxes',
    all_option: 'All education',
    choices: {
      somehighschool: 'Some High School',
      highschoolgraduate: 'High School Graduate',
      somecollegenodegree: 'Some College, no degree',
      tradetechnicalvocationaltraining: 'Trade/Technical/Vocational training',
      associatedegree: 'Associate Degree',
      bachelordegree: "Bachelor's Degree",
      masterdegree: "Master's Degree",
      doctoratedegree: 'Doctorate Degree'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'targetEthnicities',
    title: 'Ethnicity',
    all_option: 'All ethnicities',
    type: 'checkboxes',
    choices: {
      americanindianalaskannative: 'American Indian / Alaskan Native',
      asianpacificislander: 'Asian or Pacific Islander',
      blackorafricanamerican: 'Black or African American',
      hispaniclatino: 'Hispanic or Latino',
      whitecaucasian: 'White / Caucasian',
      middleeastern: 'Middle Eastern',
      multipleethnicity: 'Mixed Ethnicity',
      prefernotdisclose: 'Prefer not to disclose'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'targetAgeGroups',
    title: 'Age Range',
    all_option: 'All age ranges',
    type: 'checkboxes',
    choices: {
      '18-20': '18-20',
      '21-29': '21-29',
      '30-39': '30-39',
      '40-54': '40-54',
      '55+': '55+'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'targetCompanySize',
    title: 'Company Size',
    all_option: 'All company sizes',
    type: 'checkboxes',
    choices: {
      selfemployed: 'Self Employed',
      '1-10': '1 - 10 Employees',
      '11-50': '11 - 50 Employees',
      '51-200': '51 - 200 Employees',
      '201-500': '201 - 500 Employees',
      '501-1000': '501 - 1,000 Employees',
      '1001-5000': '1,001 - 5,000 Employees',
      '5001-10000': '5,001 - 10,000 Employees',
      '10000+': '10,000+ Employees'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  },
  {
    filter_id: 'seniority',
    title: 'Seniority',
    all_option: 'All seniority',
    type: 'checkboxes',
    choices: {
      unpaid: 'Unpaid',
      training: 'Training',
      senior: 'Senior',
      director: 'Director',
      partner: 'Partner',
      student: 'Student',
      entrylevel: 'Entry level',
      vicepresident: 'Vice president',
      chiefofficer: 'Chief officer',
      manager: 'Manager'
    },
    min: 0,
    max: 0,
    data_type: 'ChoiceID'
  }
];

export const REQUEST_STATE_OPTIONS: RadioGroupOption<ExternalCandidatesState>[] = [
  {
    label: 'Submit now: Upon study publish, the external recruitment request will be submitted.',
    value: 'submitted_publish'
  },
  {
    label: 'Submit later: External recruitment request will be kept in draft for you to submit later.',
    value: 'draft'
  }
];

export const MARKET_TYPE_OPTIONS: RadioGroupOption<ExternalCandidatesMarketType>[] = [
  {
    label: 'Business-to-business (B2B)',
    value: 'b2b'
  },
  {
    label: 'Business-to-consumer (B2C)',
    value: 'b2c'
  }
];

export const FILTER_PAST_PARTICIPATION_OPTIONS: RadioGroupOption<ExternalCandidatesFilterPastParticipation>[] = [
  {
    label: 'N/A ',
    value: ''
  },
  {
    label: 'Has never completed a study with your account',
    value: '-1'
  },
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  },
  {
    label: '3',
    value: '3'
  },
  {
    label: '6',
    value: '6'
  },
  {
    label: '9',
    value: '9'
  },
  {
    label: '12',
    value: '12'
  }
];

export const DEFAULT_PROLIFIC_FILTER_IDS = [
  'age',
  'gender',
  'sex',
  'current-country-of-residence',
  'nationality',
  'ethnicity',
  'first-language',
  'fluent-languages',
  'employment-status',
  'relationshipmarital-status'
];

export const DEFAULT_RESPONDENT_FILTER_IDS = [];

export const DEFAULT_RESPONDENT = {
  industries: [],
  job_titles: [],
  skills: [],
  topics: []
};

export const ALL_INDUSTRIES_VALUE = '64e6396e4b306cf92ec915fc';
