import { useToaster } from 'components/stores/toaster';
import { failedUpdate } from 'components/StudiesApp/toasts';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Loading, Modal, Text } from '@components/common';
import { TemplatePreview } from '@components/TemplatePreview';
import { SurveyCard } from '@components/TemplatesApp/components/SurveyCard';

interface Props {
  screener: Screener;
  templates?: (SurveyTemplate | StudyTemplate)[];
  activeTemplate?: SurveyTemplate['id'];
  setActiveTemplate: (templateId?: number) => void;
  onClose: () => void;
  resetBankQuestionsState: () => void;
  onSave?: (v: Study) => void;
}

export const Templates: FC<Props> = ({
  screener,
  templates,
  activeTemplate,
  setActiveTemplate,
  onClose,
  resetBankQuestionsState,
  onSave
}) => {
  const [previewingId, setPreviewingId] = useState<number>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [updateStudy, { isLoading, isError }] = api.useUpdateStudyScreenerTemplateMutation();
  const { id: studyId } = useParams<{ id: string }>();
  const previewingIdRef = useRef<number>();

  const showToast = useToaster();

  const handleOnUpdateStudyTemplate = async () => {
    try {
      const res = await updateStudy({ id: Number(studyId), screener_template_id: activeTemplate, screener_id: screener.id }).unwrap();
      setModalIsOpen(false);
      setPreviewingId(undefined);
      setActiveTemplate(undefined);
      resetBankQuestionsState();
      onSave?.(res);
      onClose();
    } catch {
      onClose();
      showToast(failedUpdate());
    }
    setModalIsOpen(false);
  };

  const handleOnPreview = (templateId: number) => {
    setPreviewingId(templateId);
    previewingIdRef.current = templateId;
  };

  const handleOnPreviewClose = () => {
    setPreviewingId(undefined);
    previewingIdRef.current = undefined;
  };

  const handleOnPreviewSubmit = () => {
    setPreviewingId(undefined);
    setModalIsOpen(true);
  };

  const handleOnModalClose = () => {
    setModalIsOpen(false);
    setPreviewingId(previewingIdRef.current);
  };

  return (
    <div className='p-4'>
      {templates?.map((template) => (
        <div className='mb-4' key={template.id}>
          <SurveyCard<SurveyTemplate>
            // @ts-expect-error TODO: SurveyTemplate should have the same type as StudyTemplate
            template={template}
            kind='SurveyTemplate'
            hasLink={false}
            isActive={activeTemplate === template.id}
            onClick={(_, templateId) => setActiveTemplate?.(activeTemplate === template.id ? undefined : templateId)}
          />
          {activeTemplate === template.id && (
            <div className='p-4 mt-4 border border-gray-200 rounded'>
              {'description' in template && template.description && (
                <Text h='400' className='mb-4'>
                  {template.description}
                </Text>
              )}
              <Button onClick={() => setModalIsOpen(true)} loading={isLoading} fullWidth primary>
                Use this template
              </Button>
              {template.global && (
                <Button className='mt-2' onClick={() => handleOnPreview(template.id)} fullWidth>
                  Preview
                </Button>
              )}
            </div>
          )}
          {previewingId === template.id && (
            <Modal size='xl' onClose={handleOnPreviewClose} noPadding>
              <TemplatePreview
                // @ts-expect-error TODO: SurveyTemplate should have the same type as StudyTemplate
                template={template}
                onSubmit={handleOnPreviewSubmit}
              />
            </Modal>
          )}
        </div>
      ))}
      {modalIsOpen && (
        <Modal
          title='Confirm change'
          onClose={handleOnModalClose}
          renderFooter={() => (
            <>
              <Button onClick={handleOnModalClose}>Cancel</Button>
              <Button primary onClick={handleOnUpdateStudyTemplate} disabled={isLoading}>
                Confirm
              </Button>
            </>
          )}
        >
          {isLoading && <Loading absolute bg='white' />}
          <Text h='400' className='mb-6'>
            All existing questions will be removed and replaced with the template.
          </Text>
        </Modal>
      )}
    </div>
  );
};
