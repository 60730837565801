import React from 'react';

import { RecordingZone } from './RecordingZone';

export const EndRecording: React.FC = () => {
  return (
    <div>
      <RecordingZone show bottom className='h-10' />
      <div className='h-10' w-full />
    </div>
  );
};
