import { Button, FormGroup, Heading, Input, Text } from 'components/common';
import { track } from 'components/tracking';
import React, { FC, useEffect } from 'react';

import { ERROR_NAME, ERROR_ROLE } from '../constants';
import { useFormContext } from '../hooks';

export const BasicProfile: FC = () => {
  const { formData, errors, register, isLoading } = useFormContext();

  useEffect(() => {
    track('viewed_onboarding_step', { step: 'profile' });
  }, []);

  return (
    <section className='tablet:mt-24 desktop:mt-36 mt-8 w-full max-w-3xl px-4 mx-auto'>
      <Heading as='h1' h='700' className='mb-2'>
        Set up a basic profile
      </Heading>
      <Text as='p' h='500' className='mb-10'>
        This will help speed up your research process later, and help teammates recognize you.
      </Text>
      <FormGroup>
        <Text h='500' as='label' htmlFor='name' className='mb-2' bold>
          What's your name?
        </Text>
        <Input
          id='name'
          type='text'
          name='name'
          placeholder='Enter...'
          className='w-full'
          defaultValue={formData?.name ?? ''}
          error={!!errors.name}
          ref={register({ required: ERROR_NAME })}
        />
        {errors.name && (
          <Text role='alert' aria-live='assertive' as='p' h='200' className='mt-1 text-red-600'>
            {errors.name.message}
          </Text>
        )}
      </FormGroup>
      <FormGroup>
        <Text h='500' as='label' htmlFor='job_title' className='mb-2' bold>
          What's your job title?
        </Text>
        <Input
          id='job_title'
          type='text'
          name='job_title'
          placeholder='Enter...'
          className='w-full'
          defaultValue={formData?.job_title ?? ''}
          error={!!errors.job_title}
          ref={register({ required: ERROR_ROLE })}
        />

        {errors.job_title && (
          <Text role='alert' aria-live='assertive' as='p' h='200' className='mt-1 text-red-600'>
            {errors.job_title.message}
          </Text>
        )}
      </FormGroup>

      <Button aria-label='Save' loading={isLoading} type='submit' iconSuffix='chevronRight' className='mt-4' primary>
        Save & continue
      </Button>
    </section>
  );
};
