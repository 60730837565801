import pluralize from 'pluralize';

const suffixes = [
  {
    multiplier: 24 * 60,
    label: 'days'
  },
  {
    multiplier: 60,
    label: 'hours'
  },
  {
    multiplier: 1,
    label: 'minutes'
  }
];

export const duration = (value: number): string => {
  const suffix = suffixes.filter((s) => (value / s.multiplier) % 1 === 0)[0];
  const durationValue = value / suffix.multiplier;

  return pluralize(suffix.label, durationValue, true);
};
