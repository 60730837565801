import React, { SVGProps } from 'react';

export const CashPaymentSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0.5 9.5V15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M0.5 14.5H11.5C11.5 13.9696 11.2893 13.4609 10.9142 13.0858C10.5391 12.7107 10.0304 12.5 9.5 12.5H7C7 11.9696 6.78929 11.4609 6.41421 11.0858C6.03914 10.7107 5.53043 10.5 5 10.5H0.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M4.5 12.5H7' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M4.42002 0.5H14.58C14.8264 0.503514 15.0614 0.604311 15.2337 0.780406C15.4061 0.956501 15.5018 1.19361 15.5 1.44V7.22667C15.5018 7.47306 15.4061 7.71016 15.2337 7.88626C15.0614 8.06236 14.8264 8.16315 14.58 8.16667H4.42002C4.17365 8.16315 3.93866 8.06236 3.76631 7.88626C3.59396 7.71016 3.49824 7.47306 3.50002 7.22667V1.44C3.49824 1.19361 3.59396 0.956501 3.76631 0.780406C3.93866 0.604311 4.17365 0.503514 4.42002 0.5Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8 4.33333C8 4.73116 8.15804 5.11269 8.43934 5.39399C8.72064 5.6753 9.10218 5.83333 9.5 5.83333C9.89782 5.83333 10.2794 5.6753 10.5607 5.39399C10.842 5.11269 11 4.73116 11 4.33333C11 3.93551 10.842 3.55398 10.5607 3.27267C10.2794 2.99137 9.89782 2.83333 9.5 2.83333C9.10218 2.83333 8.72064 2.99137 8.43934 3.27267C8.15804 3.55398 8 3.93551 8 4.33333Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M5.33325 2.66667H6.33325' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M12.6667 6H13.6667' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
