import { useFeature } from 'hooks/useFeature';
import pluralize from 'pluralize';
import React, { useState } from 'react';

import { Button, Modal, RadioGroup } from '@components/common';

type DeletionType = 'forget' | 'archive';

export const BulkDeleteConfirmationModal: React.FC<{
  count: number;
  onConfirm: (deletion_type: Candidate['deletion_type']) => Promise<void>;
  onCancel: () => void;
  loading: boolean;
}> = ({ count, loading, onConfirm, onCancel }) => {
  const candidate_s = pluralize('candidate', count);
  const canSetDeletionType = useFeature('can_rehydrate_deleted_on_import');
  const [deletionType, setDeletionType] = useState<DeletionType>('forget');
  return (
    <Modal
      size='lg'
      icon='danger'
      onClose={onCancel}
      renderFooter={() => (
        <div className='flex flex-row justify-end space-x-4'>
          <Button onClick={onCancel} disabled={loading}>
            Cancel
          </Button>
          <Button onClick={() => onConfirm(deletionType)} danger loading={loading} disabled={loading}>
            Delete {candidate_s}
          </Button>
        </div>
      )}
    >
      <div>
        <h4 className='mb-3 text-xl font-semibold tracking-tight text-gray-800'>Delete {candidate_s}</h4>
        <p className='mb-2'>
          You are about to delete {count} {candidate_s}.
        </p>
        <p className='mb-2'>All information will be deleted including profile data, recordings, and transcripts.</p>
        <p className='mb-2'>
          Candidates who are booked or invited to current studies will automatically be removed from the study.
        </p>
        <p className='mb-2'>
          Candidates who have pending incentives will automatically be sent their incentives before deleting their data.
        </p>
        <p className='mb-2'>This action is permanent.</p>
        {canSetDeletionType && (
          <div className='mt-2'>
            <RadioGroup<DeletionType>
              options={[
                {
                  label: 'Forget',
                  value: 'forget',
                  helper: 'Forget the data. A new candidate will be created if imported again'
                },
                {
                  label: 'Archive',
                  value: 'archive',
                  helper: 'Anonymize and archive the data. Candidate will be undeleted if imported again'
                }
              ]}
              selected={deletionType}
              onChange={setDeletionType}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
