import * as React from 'react';

import { TagSVG } from '@components/svgs';
import { compact } from '@components/utils';
import { useTags } from '@stores/tags';

import { Hook as UseMultiselectTags } from '../../useMultiselectTags';
import { BaseTagPill } from '../../TagPill';
import { TagListInput } from './TagListInput';

type Props = {
  tabIndex?: number;
  className?: string;
  pillClassName?: string;
  readOnly?: boolean;
  previewShowLimit?: number;
  tagIds: number[];
  multiselectTags: UseMultiselectTags;
  inputRef: React.RefObject<HTMLInputElement>;
  triggerRef?: React.RefObject<HTMLDivElement>;
  studyId?: number | null;
};

export const TagListButton: React.FC<Props> = ({
  tabIndex,
  className,
  pillClassName,
  readOnly,
  previewShowLimit,
  tagIds,
  multiselectTags,
  inputRef,
  triggerRef,
  studyId
}) => {
  const { isOpen, getComboboxProps, getToggleButtonProps } = multiselectTags;

  const { getTag } = useTags();

  const tags = compact(tagIds.map(getTag));

  const previewTags = previewShowLimit ? tags.slice(0, previewShowLimit) : tags;
  const hiddenPreviewTags = tags.length - previewTags.length;

  return (
    <div {...getComboboxProps({ ref: triggerRef }, { suppressRefError: true })} role='none'>
      {!isOpen && (
        <button
          {...getToggleButtonProps({
            onFocus: (event) => {
              const input = event.target.querySelector('input');
              if (input) input.focus();
            }
          })}
          aria-label={'toggle menu'}
          disabled={readOnly}
          className={className}
        >
          <TagSVG className='flex-shrink-0 mr-1' />
          {previewTags.map((tag, i) => (
            <BaseTagPill
              key={`${tag}${i}`}
              color={tag.color}
              tag={tag.name}
              count={tag.taggings_count}
              pillClassName={pillClassName}
              size='sm'
              className='flex-shrink'
            />
          ))}
          {hiddenPreviewTags > 0 && <div className='text-xs font-medium text-gray-500'>+{hiddenPreviewTags}</div>}
          <TagListInput
            tabIndex={tabIndex}
            isOpen={isOpen}
            readOnly={readOnly}
            className={previewTags.length ? 'w-0' : 'w-auto'}
            inputRef={inputRef}
            multiselectTags={multiselectTags}
          />
        </button>
      )}
      {isOpen && (
        <div className={className}>
          <TagSVG className='flex-shrink-0 mr-1' />
          <TagListInput
            tabIndex={tabIndex}
            isOpen={isOpen}
            readOnly={readOnly}
            className='w-full'
            inputRef={inputRef}
            multiselectTags={multiselectTags}
          />
        </div>
      )}
    </div>
  );
};
