import cn from 'classnames';
import { Pill } from 'components/common';
import { validateEmail } from 'helpers/emailRegexpValidation';
import { useProfileContext } from 'hooks/useProfileContext';
import * as React from 'react';
import { useState } from 'react';

import { getWrapperClass, INPUTS } from '@components/CandidateAttrs';
import { getValue, isValueBlank } from '@components/CandidateProfile/helpers';
import { STYLES } from '@components/shared/AddCandidateSlideOut';
import { CheckMark2SVG, ExternalLinkSVG, PencilSVG, XSVG } from '@components/svgs';
import { humanize } from '@components/utils';

import { DateValue } from './DateValue';
import { Tooltip } from 'components/shared/Tooltip';

export interface AttrValue {
  value: any;
  candidate?: Candidate;
}

const FreeTextValue: React.FC<AttrValue> = ({ value }) => (
  <div>
    {value.split('\n').map((p, i) => (
      <p key={p + i}>{p}</p>
    ))}
  </div>
);
const TextValue: React.FC<AttrValue> = ({ value }) => value;

const MultiItem = ({ children }) => (
  <div className='inline-block m-1.5 ml-0'>
    <span className='border border-gray-200 py-1 px-3 py-1.5 text-sm bg-gray-50 rounded-full'>{children}</span>
  </div>
);

const MultiValue: React.FC<AttrValue> = ({ value }) =>
  value instanceof Array ? (
    <span className='inline w-full'>
      {value.map((v, i) => (
        <MultiItem key={`multi_value_${i}`}>{v}</MultiItem>
      ))}
    </span>
  ) : (
    value
  );

export const BooleanValue: React.FC<AttrValue> = ({ value }) => {
  const renderValue = (value: boolean) =>
    value ? <CheckMark2SVG className='text-green-500 mx-1' /> : <XSVG className='text-red-500' />;

  return (
    <>
      {value === undefined || value === null ? (
        <span>
          -<span className='sr-only'>Not set</span>
        </span>
      ) : (
        <span>
          {renderValue(value)}
          <span className='sr-only'>{value ? 'Yes' : 'No'}</span>
        </span>
      )}
    </>
  );
};

const UrlValue: React.FC<AttrValue> = ({ value }) => {
  const newValue = value && !value.match(/^https?/) ? 'http://' + value : value;

  return (
    <>
      {newValue.replace(/^https?:\/\//, '')}
      <a target='_blank' className='ml-2' href={newValue}>
        <ExternalLinkSVG />
      </a>
    </>
  );
};

export const VALUES = {
  text: TextValue,
  free_text: FreeTextValue,
  datetime: DateValue,
  boolean: BooleanValue,
  url: UrlValue,
  multiple_choice: MultiValue,
  default: TextValue
};

const PII_KEYS = ['name', 'email'];

export const isPii = (attr: Attr_): boolean => Boolean(PII_KEYS.includes(attr.name) || attr.is_pii);

interface EntityAttr {
  attr: Attr_;
  index: number;
  canEdit: boolean;
  entity: EntityWithCustomAttributes;
  onUpdate: (value: any) => void;
}

interface AttrProps extends EntityAttr {
  style: StyleType;
  onClickDelete?: () => void;
  customerName?: string;
  isStudyAttr?: boolean;
}

export const Attribute: React.FC<AttrProps> = ({
  customerName,
  attr,
  canEdit,
  entity,
  onUpdate,
  style,
  isStudyAttr
}) => {
  const [editing, setEditing] = useState(false);
  const { name, attr_type: type, values } = attr;
  const label = attr.label || humanize(name);
  const initValue = getValue(entity, attr);
  const [value, setValue] = useState(initValue);

  const { setIsDirty, isDirty } = useProfileContext();

  const handleEdit = () => {
    if (canEdit) {
      setEditing(true);
    }
  };

  const save = async (val: string) => {
    if (editing && initValue !== val) {
      onUpdate(val);
    }

    setEditing(false);
    setIsDirty?.({ ...isDirty, [attr.name]: false });
  };

  const getErrorMessage = (value: string, attrName: string) => {
    switch (attrName) {
      case 'email':
        if (!value) return 'Please enter an email address.';
        if (value && !validateEmail(value)) return 'Please enter a valid email address.';
        return '';
      default:
        return '';
    }
  };

  const ATTR_INPUT = INPUTS[type] || INPUTS.default;
  const ATTR_VALUE = VALUES[type] || VALUES.default;

  const isBlank = isValueBlank(type, value);

  const styles = STYLES[style];

  const attrValueClass = cn('flex col-span-4 group-hover:bg-gray-50 bg-white text-sm items-center w-full py-2.5 px-4', {
    'text-gray-400': isBlank,
    'cursor-pointer': canEdit
  });

  React.useEffect(() => {
    if (!attr.name || type !== 'text') return;
    if (editing) {
      setIsDirty?.({ ...isDirty, [attr.name]: true });
    } else {
      setIsDirty?.({ ...isDirty, [attr.name]: false });
    }
  }, [editing]);

  return (
    <>
      {editing || (isBlank && type !== 'boolean') ? (
        <ATTR_INPUT
          value={value}
          onFocus={() => setEditing(true)}
          autoFocus
          setValue={setValue}
          onSave={save}
          label={label}
          attr={attr}
          styles={styles}
          customerName={customerName}
          errorMessage={getErrorMessage(value as string, attr.name)}
          isStudyAttr={isStudyAttr}
        />
      ) : (
        <tr data-testid='attr-field' className={getWrapperClass(styles)}>
          <td className={styles.label}>
            <Tooltip content={label}>
              <div className='max-w-full'>
                <div className='truncate'>{label}</div>
              </div>
            </Tooltip>
          </td>
          <td className={attrValueClass} onClick={handleEdit}>
            <ATTR_VALUE value={value} candidate={entity} />
          </td>
          <td className={styles.svgButtons}>
            <div>
              {attr.primary && (
                <Pill color='indigo' className='group-hover:hidden'>
                  primary
                </Pill>
              )}
            </div>
            {canEdit && (
              <button className='group-hover:opacity-100 opacity-0' onClick={handleEdit}>
                <PencilSVG className='text-gray-500' />
              </button>
            )}
          </td>
        </tr>
      )}
    </>
  );
};
