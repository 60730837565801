import React, { SVGProps } from 'react';

export const ThinArrowRightSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M15.5 8.33008H0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13 10.8301L15.5 8.33008L13 5.83008'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
