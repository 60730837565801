import React, { SVGProps } from 'react';

export const RepositorySVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.5625 13.5625H2.1875C1.8394 13.5625 1.50556 13.4242 1.25942 13.1781C1.01328 12.9319 0.875 12.5981 0.875 12.25'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.375 4.8125V0.875C11.375 0.758968 11.3289 0.647688 11.2469 0.565641C11.1648 0.483594 11.0535 0.4375 10.9375 0.4375H2.625C2.16087 0.4375 1.71575 0.621874 1.38756 0.950063C1.05937 1.27825 0.875 1.72337 0.875 2.1875V12.25C0.875 11.9019 1.01328 11.5681 1.25942 11.3219C1.50556 11.0758 1.8394 10.9375 2.1875 10.9375H4.375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 9.1875C5.6875 9.58967 5.76671 9.98791 5.92062 10.3595C6.07452 10.731 6.30011 11.0686 6.58449 11.353C6.86886 11.6374 7.20647 11.863 7.57803 12.0169C7.94959 12.1708 8.34783 12.25 8.75 12.25C9.15217 12.25 9.55041 12.1708 9.92197 12.0169C10.2935 11.863 10.6311 11.6374 10.9155 11.353C11.1999 11.0686 11.4255 10.731 11.5794 10.3595C11.7333 9.98791 11.8125 9.58967 11.8125 9.1875C11.8125 8.78533 11.7333 8.38709 11.5794 8.01553C11.4255 7.64397 11.1999 7.30636 10.9155 7.02199C10.6311 6.73761 10.2935 6.51202 9.92197 6.35812C9.55041 6.20421 9.15217 6.125 8.75 6.125C8.34783 6.125 7.94959 6.20421 7.57803 6.35812C7.20647 6.51202 6.86886 6.73761 6.58449 7.02199C6.30011 7.30636 6.07452 7.64397 5.92062 8.01553C5.76671 8.38709 5.6875 8.78533 5.6875 9.1875V9.1875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1257 13.5624L10.916 11.3528'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
