import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['value', 'input'];

  display(evt) {
    const fileName = evt.target.value.split('\\').pop();
    this.valueTarget.textContent = fileName;
  }
}
