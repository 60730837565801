import React from 'react';
import { Link } from 'react-router-dom';

export const Url: React.FC<{ href: string }> = ({ children, href }) => {
  return (
    <a className='hover:underline text-blue-500' href={href} target='_blank'>
      {children}
    </a>
  );
};
