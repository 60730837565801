import React, { forwardRef, HTMLAttributes } from 'react';
import cn from 'classnames';

import { PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { Tooltip } from '@components/shared/Tooltip';
import { DragSVG, DuplicateSVG, SolidKebabSVG, TrashSVG, WarningSVG } from '@components/svgs';

import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'title'> {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  icon?: React.ReactNode;
  isActive?: boolean;
  isDraggable?: boolean;
  hasOptions?: boolean;
  onDelete?: () => void;
  onDuplicate?: () => void;
  subtitle?: string;
  warningMessage?: string;
  title: string;
  isCompact?: boolean;
}

export const Block = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      dragHandleProps,
      icon,
      isActive,
      isDraggable,
      hasOptions,
      onDelete,
      onDuplicate,
      subtitle,
      warningMessage,
      title,
      isCompact,
      ...rest
    },
    ref
  ) => {
    const handleOnOptionSelect = (value: 'delete' | 'duplicate') => {
      if (value === 'delete' && onDelete) {
        onDelete();
      } else if (value === 'duplicate' && onDuplicate) {
        onDuplicate();
      }
    };

    return (
      <div
        ref={ref}
        className={cn('survey-builder-block w-full bg-white', { 'border-indigo-600': isActive }, className)}
        aria-current={isActive}
        tabIndex={0}
        role='button'
        {...(isDraggable && dragHandleProps)}
        {...rest}
      >
        <div className='flex items-stretch'>
          <div
            className={cn('flex-shrink-0', {
              'w-11 h-11 py-2.5 pl-3 pr-2': isCompact,
              'p-4': !isCompact
            })}
          >
            {icon}
          </div>
          <div className={cn('flex items-center w-full min-w-0 py-1.5 pr-3', isCompact ? 'py-1.5' : 'py-3')}>
            <div className='min-w-0 pr-3'>
              <Text truncate h={isCompact ? '400' : '500'}>
                {title}
              </Text>
              {subtitle && (
                <Text truncate className='mt-1 text-sm text-gray-500'>
                  {subtitle}
                </Text>
              )}
            </div>
            <div className='flex items-center ml-auto space-x-3'>
              {warningMessage && (
                <Tooltip content={warningMessage}>
                  <span aria-label={warningMessage}>
                    <WarningSVG className='w-4 h-4 text-red-600' />
                  </span>
                </Tooltip>
              )}
              {hasOptions && (
                <PopperDropdown
                  text
                  hideCaret
                  aria-label='Open block menu'
                  className='flex-shrink-0 hover:bg-gray-50 p-1 rounded-full'
                  renderIcon={() => <SolidKebabSVG className='w-4 h-4 flex-shrink-0' />}
                >
                  <PopperDropdownButton
                    className='hover:bg-gray-50 flex items-center px-4 py-2 text-sm'
                    aria-label='Duplicate'
                    onClick={() => handleOnOptionSelect('duplicate')}
                  >
                    <DuplicateSVG className='mr-2' /> Duplicate
                  </PopperDropdownButton>
                  <PopperDropdownButton
                    className='hover:bg-gray-50 flex items-center px-4 py-2 text-sm text-red-600'
                    aria-label='Delete'
                    onClick={() => handleOnOptionSelect('delete')}
                  >
                    <TrashSVG className='mr-2' /> Delete
                  </PopperDropdownButton>
                </PopperDropdown>
              )}
              {isDraggable && (
                <span aria-label='Drag block'>
                  <DragSVG />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
