import React, { SVGProps } from 'react';

export const ExternalCandidatesSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.22314 9.02087V14.2709'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9751 13.3959C10.9751 12.9317 10.7907 12.4866 10.4625 12.1584C10.1343 11.8302 9.68923 11.6459 9.2251 11.6459H7.0376C7.0376 11.1817 6.85322 10.7366 6.52503 10.4084C6.19685 10.0802 5.75173 9.89587 5.2876 9.89587H2.2251V13.3959H10.9751Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.28564 11.6459H7.03564'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.93164 3.62496C8.93164 4.12777 9.13138 4.60998 9.48692 4.96552C9.84245 5.32105 10.3247 5.52079 10.8275 5.52079C11.3303 5.52079 11.8125 5.32105 12.168 4.96552C12.5236 4.60998 12.7233 4.12777 12.7233 3.62496C12.7233 3.12215 12.5236 2.63994 12.168 2.2844C11.8125 1.92886 11.3303 1.72913 10.8275 1.72913C10.3247 1.72913 9.84245 1.92886 9.48692 2.2844C9.13138 2.63994 8.93164 3.12215 8.93164 3.62496Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.7752 8.43753C13.5573 7.839 13.1606 7.322 12.6388 6.95667C12.117 6.59134 11.4955 6.39539 10.8586 6.39539C10.2216 6.39539 9.60007 6.59134 9.0783 6.95667C8.55653 7.322 8.15978 7.839 7.94189 8.43753'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
