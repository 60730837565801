import * as React from 'react';

import { Avatar, Text } from '@components/common';
import { TextNode } from '@components/RepoSessionApp/ClipPage/hooks/useEditorState';
import { formatSeconds } from '@components/VideoPlayer/utils';

import type { FC } from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  textNodes: TextNode[];
  slideoutView?: boolean;
};

export const ClipTranscript: FC<Props> = ({ slideoutView, className, textNodes }) => {
  const formatSpeakerTimestamp = (timestamp: number) => {
    const [seconds, minutes, hours] = formatSeconds(timestamp / 1000, 'H:m:s')
      .split(':')
      .reverse();

    return [+hours > 0 && `${hours}h`, +minutes > 0 && `${minutes}m`, +seconds >= 0 && `${seconds}s`]
      .filter(Boolean)
      .join(' ');
  };

  return (
    <div className={className}>
      {textNodes.map(({ speaker, text, timestamp }, i) => (
        <div key={i} className='mb-4'>
          <div className={cn('flex items-center', slideoutView ? 'mb-2' : 'mb-3.5')}>
            <Avatar user={speaker as TeamUser} size={slideoutView ? 'sm' : 'md'} noTippy />
            <Text as='span' h='400' className='ml-2 font-medium text-gray-700'>
              {speaker.name}
            </Text>
            {!slideoutView && (
              <Text as='span' h='400' className='ml-4 text-gray-400'>
                {formatSpeakerTimestamp(timestamp)}
              </Text>
            )}
          </div>
          <Text as='p' h='400' className='normal-case'>
            {text}
          </Text>
        </div>
      ))}
    </div>
  );
};
