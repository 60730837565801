const HAS_NO_VALUE = ['stop_loom', 'start_loom', 'info'];
export function hasFormKey(field: ScreenerField): boolean {
  return !HAS_NO_VALUE.includes(field.field_type);
}
export function getFormKey(field: ScreenerField): string {
  const name = field.id.toString();
  switch (field.field_type) {
    case 'multiple_choice':
    case 'single_choice':
      return `${name}.label`;
    case 'stop_loom':
      return 'loom_recording_id';
    default:
      return name;
  }
}
