import React, { SVGProps } from 'react';

export const EyeSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 4.06308C5.64858 4.02342 3.21667 5.66667 1.68775 7.34958C1.52726 7.52775 1.43845 7.75904 1.43845 7.99883C1.43845 8.23862 1.52726 8.46991 1.68775 8.64808C3.18342 10.2954 5.60833 11.9766 8 11.9363C10.3917 11.9766 12.8172 10.2954 14.314 8.64808C14.4745 8.46991 14.5633 8.23862 14.5633 7.99883C14.5633 7.75904 14.4745 7.52775 14.314 7.34958C12.7833 5.66667 10.3514 4.02342 8 4.06308Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1875 7.99997C10.1874 8.4326 10.059 8.85547 9.81855 9.21512C9.57812 9.57478 9.23644 9.85507 8.83671 10.0205C8.43699 10.186 7.99717 10.2293 7.57288 10.1448C7.14858 10.0603 6.75886 9.85193 6.45299 9.54598C6.14713 9.24003 5.93884 8.85025 5.85449 8.42594C5.77013 8.00162 5.81348 7.56181 5.97907 7.16214C6.14465 6.76246 6.42503 6.42085 6.78475 6.18051C7.14447 5.94017 7.56738 5.81189 8 5.81189C8.28733 5.81181 8.57187 5.86836 8.83734 5.9783C9.10281 6.08824 9.34402 6.24942 9.54717 6.45263C9.75032 6.65583 9.91144 6.89708 10.0213 7.16258C10.1312 7.42808 10.1877 7.71264 10.1875 7.99997Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
