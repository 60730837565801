import * as React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';

import { Button } from '@components/common';
import { compact } from '@components/utils';

import { CreatedBy } from '../../components/cells/CreatedBy';
import { IsDefault } from '../../components/cells/IsDefault';
import { LastUpdated } from '../../components/cells/LastUpdated';
import { DeleteTemplateButton } from '../../components/DeleteTemplateButton';
import { DEFAULT_TITLES } from '../../consts';

type Params = {
  canUpdate: (template: InterviewTemplate) => boolean;
  isGQAdmin?: boolean;
};
export const buildColumns = (params: Params): Column<InterviewTemplate>[] =>
  compact([
    {
      id: 'name',
      Cell: ({ row }) => <Link to={`${row.original.id}`}>{row.original.title || DEFAULT_TITLES.InterviewTemplate}</Link>
    },
    {
      id: 'created by',
      Cell: CreatedBy
    },
    {
      id: 'last updated',
      Cell: LastUpdated
    },
    {
      id: 'default',
      Header: () => <></>,
      Cell: IsDefault
    },
    {
      id: 'extra',
      Header: () => <></>,
      Cell: ({ row }) => (
        <div className='pr-6 text-right whitespace-nowrap'>
          {params.canUpdate(row.original) && (
            <>
              {(!row.original.global || params.isGQAdmin) && (
                <Button text rounded small href={`${row.original.id}/edit`} spa icon='pencil' />
              )}
              <DeleteTemplateButton template={row.original} kind='InterviewTemplate' />
            </>
          )}
        </div>
      )
    }
  ]);
