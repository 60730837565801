import React, { SVGProps } from 'react';

export const HighlightSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m8.87495 11.4598-4.33125-4.33128 5.26458-4.76058c.33272-.30148.76852-.46355 1.21742-.45269.4488.01085.8763.19381 1.1941.51102l1.358 1.358c.3172.31774.5002.74525.511 1.1941.0109.44885-.1512.8847-.4527 1.2174z' />
      <path d='m4.54365 7.12854-.791.79158c-.13056.13011-.2167.29812-.24614.48007-.02943.18195-.00066.36855.08222.53318.10444.20904.1588.4395.1588.67317s-.05436.46416-.1588.67316l-.28233.5641 1.85616 1.8562.56467-.2824c.20893-.1044.4393-.1588.67287-.1588.23358 0 .46395.0544.67288.1588.16423.0821.35011.1104.53132.081.18122-.0294.34859-.1151.47843-.2449l.79217-.7939z' />
      <path d='m3.55617 11.0905-2.11867 2.1193h2.625l.80617-.8068' />
      <path d='m6.25 14.0848h8.3125' />
    </g>
  </svg>
);
