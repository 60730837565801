import React, { SVGProps } from 'react';

export const PlayButtonSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='78' height='78' viewBox='0 0 78 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M50.865 37.4751L34.6685 25.7015C34.0741 25.2706 33.2851 25.2057 32.6336 25.5405C31.9769 25.8728 31.5668 26.5476 31.5668 27.277V50.8164C31.5668 51.5535 31.9769 52.2258 32.6336 52.558C32.9113 52.6982 33.215 52.7683 33.5213 52.7683C33.921 52.7683 34.3259 52.6411 34.6685 52.3893L50.865 40.6261C51.3763 40.2497 51.6748 39.6683 51.6748 39.0506C51.6774 38.4224 51.3711 37.8436 50.865 37.4751Z'
        fill='currentColor'
      />
      <path
        d='M39.0013 0.00317383C17.4579 0.00317383 0 17.4611 0 39.0045C0 60.5401 17.4579 77.9928 39.0013 77.9928C60.5395 77.9928 78 60.5375 78 39.0045C78.0026 17.4611 60.5395 0.00317383 39.0013 0.00317383ZM39.0013 71.4856C21.0606 71.4856 6.51492 56.9478 6.51492 39.0045C6.51492 21.069 21.0606 6.51291 39.0013 6.51291C56.9394 6.51291 71.4825 21.0664 71.4825 39.0045C71.4851 56.9478 56.9394 71.4856 39.0013 71.4856Z'
        fill='currentColor'
      />
    </svg>
  );
};
