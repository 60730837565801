import { SelectOptionGroup } from '@components/StudyMessages/components/GroupSelect';

export const getSendOptionGroups = (groupedSenders, userId): SelectOptionGroup[] =>
  Object.keys(groupedSenders)
    .map((id) => {
      let label = '';
      let position = 0;
      if (id === '0') {
        label = 'Other senders';
        position = 3;
      } else if (parseInt(id) === userId) {
        label = `Send as you`;
        position = 2;
      } else {
        label = `Send as study owner`;
        position = 1;
      }
      return {
        label,
        value: id,
        position,
        options: groupedSenders[id]
      };
    })
    .sort((a, b) => a.position - b.position);
