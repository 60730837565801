import { CSSProperties, useMemo } from 'react';
import { CSS, Transform } from '@dnd-kit/utilities';

import { useDraggableCardContext } from './useDraggableCardContext';

type Styles = {
  styles: CSSProperties;
};

export const useStyles = (transform: Transform | null): Styles => {
  const {
    state: { width, height, coordinates, startPosition }
  } = useDraggableCardContext();

  const styles = useMemo(() => {
    const defaultStyles: CSSProperties = { width, left: coordinates.x, top: coordinates.y };

    if (width === 0) {
      defaultStyles.visibility = 'hidden';
    }

    if (transform) {
      defaultStyles.transform = CSS.Transform.toString(transform);
    }

    return defaultStyles;
  }, [coordinates, width, height, startPosition, transform]);

  return { styles };
};
