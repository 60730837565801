import * as React from 'react';

export const tag: React.FC<any> = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.00148 15C7.00114 15 7.00071 15 7.00028 15C6.56215 14.9997 6.15017 14.8288 5.84048 14.5186L1.47851 10.1507C0.840498 9.51151 0.840498 8.47151 1.47851 7.83243L7.35851 1.94156C7.96447 1.33445 8.77074 1 9.62871 1H13.3593C14.2639 1 15 1.73589 15 2.64062V6.35946C15 7.21677 14.6659 8.02264 14.0593 8.62848L8.16102 14.5202C7.85116 14.8296 7.43935 15 7.00148 15ZM9.62871 2.09375C9.06334 2.09375 8.53207 2.31412 8.13273 2.7142L2.25265 8.60507C2.03995 8.81818 2.03995 9.16476 2.25265 9.37787L6.61462 13.7458C6.71777 13.8491 6.85501 13.9062 7.00114 13.9062H7.00156C7.14752 13.9062 7.28476 13.8494 7.38799 13.7464L13.2863 7.85474C13.686 7.45552 13.9062 6.92437 13.9062 6.35946V2.64062C13.9062 2.33907 13.6608 2.09375 13.3593 2.09375H9.62871ZM11.0348 6.55078C10.1301 6.55078 9.39405 5.81489 9.39405 4.91016C9.39405 4.00542 10.1301 3.26953 11.0348 3.26953C11.9395 3.26953 12.6755 4.00542 12.6755 4.91016C12.6755 5.81489 11.9395 6.55078 11.0348 6.55078ZM11.0348 4.36328C10.7332 4.36328 10.4879 4.60861 10.4879 4.91016C10.4879 5.21171 10.7332 5.45703 11.0348 5.45703C11.3364 5.45703 11.5817 5.21171 11.5817 4.91016C11.5817 4.60861 11.3364 4.36328 11.0348 4.36328Z'
      fill='currentColor'
    />
  </svg>
);
