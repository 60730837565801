import React, { SVGProps } from 'react';

export const TrashSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.875 2.625H13.125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.3125 0.875H5.6875C5.45544 0.875 5.23288 0.967187 5.06878 1.13128C4.90469 1.29538 4.8125 1.51794 4.8125 1.75V2.625H9.1875V1.75C9.1875 1.51794 9.09531 1.29538 8.93122 1.13128C8.76712 0.967187 8.54456 0.875 8.3125 0.875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 10.0625V5.6875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.3125 10.0625V5.6875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0046 12.3223C10.9864 12.5411 10.8867 12.7451 10.7251 12.8938C10.5636 13.0425 10.3521 13.125 10.1325 13.125H3.86808C3.64853 13.125 3.43699 13.0425 3.27544 12.8938C3.11389 12.7451 3.01415 12.5411 2.996 12.3223L2.1875 2.625H11.8125L11.0046 12.3223Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
