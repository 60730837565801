import cn from 'classnames';
import { useOnClickOutside } from 'components/utils';
import * as React from 'react';
// state
import { HTMLAttributes, useRef, useState } from 'react';

// components
import { SolidKebabSVG } from '@components/svgs';

type Props = {
  dropdownClassName?: string;
  className?: string;
  svg?: React.ReactNode;
};

export const OptionsDropdown: React.FC<Props & HTMLAttributes<HTMLButtonElement>> = ({
  dropdownClassName,
  className,
  children,
  svg,
  ...rest
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const closeDropdown = () => setDropdownOpen(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, closeDropdown);

  return (
    <button onClick={toggleDropdown} className={cn('relative focus:outline-none', className)} name='more' {...rest}>
      {svg || <SolidKebabSVG className='w-4 h-4' />}
      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className={cn(
            'ring-1 ring-black ring-opacity-5 absolute right-0 z-10 py-1 bg-white rounded-md shadow-lg',
            dropdownClassName
          )}
        >
          {children}
        </div>
      )}
    </button>
  );
};
