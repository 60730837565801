import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { Avatar, AvatarFromId } from '@components/common/Avatar';
import { useSessionContext } from '@components/RepoSessionApp/SessionPage';
import { secondsToTimestamp } from '@components/utils';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { useTiptapContext } from '../hooks';
import * as Icons from '../icons';
import { getTimestamp } from '../utils';

export const ReadonlyTranscriptView = ({ editor, node }: NodeViewProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const startTimestamp = getTimestamp(node, editor.schema.marks.transcript_word);
  const speakerAttrs = node.attrs.speaker;

  const { slideoutView } = useTiptapContext();
  const { videoPlayerRef } = useSessionContext();

  const onTimestampClick = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.setCurrentTime(startTimestamp);
      videoPlayerRef.current.play();
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <NodeViewWrapper>
      <div className='px-3'>
        <section contentEditable={false} className='flex items-center justify-between'>
          <div className={cn('flex my-2', !slideoutView && 'space-x-2')}>
            {speakerAttrs.type === 'user' ? (
              <AvatarFromId userId={speakerAttrs.id} size={slideoutView ? 'sm' : 'lg'} />
            ) : (
              <Avatar user={speakerAttrs} size={slideoutView ? 'sm' : 'lg'} />
            )}
            <div>
              <div
                className={cn(
                  'group focus:outline-none flex items-center h-full px-2 font-bold rounded-md',
                  slideoutView ? 'text-sm' : 'text-base'
                )}
              >
                {speakerAttrs.name || 'Unnamed candidate'}
              </div>
            </div>
          </div>

          {videoPlayerRef && videoPlayerRef.current && (
            <div
              role='button'
              tabIndex={0}
              className='focus:outline-none hover:underline flex items-center text-sm text-indigo-600'
              onClick={onTimestampClick}
            >
              {secondsToTimestamp(startTimestamp, 4)}
              <Icons.Play className='ml-1' />
            </div>
          )}
        </section>

        <section className={cn(!slideoutView && 'pl-12')}>
          <NodeViewContent as='span' className={slideoutView ? 'text-sm leading-5' : 'leading-7'} />
        </section>
      </div>
    </NodeViewWrapper>
  );
};
