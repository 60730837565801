import React, { SVGProps } from 'react';

export const TransferVerticalSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='14' viewBox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.25 6.125V13.5625' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7 11.8125L5.25 13.5625L3.5 11.8125' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.75 7.875V0.4375' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M7 2.1875L8.75 0.4375L10.5 2.1875' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
