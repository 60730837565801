import pluralize from 'pluralize';

const UNITS = {
  d: 'days',
  w: 'week',
  m: 'month',
  y: 'year'
};

export const getPeriodCopy = (period: string): string => {
  const value = period.slice(0, -1);
  const unit = period.slice(-1);

  if (period === 'all' || !unit || !value) return '';

  return `in last ${pluralize(UNITS[unit], parseInt(value), true)}`;
};
