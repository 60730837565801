import React, { FC, useEffect, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import { UploadFileModal } from '@components/shared/UploadFileModal';
import { useToaster } from '@components/stores/toaster';

import { UPLOAD_ALERT } from '../constants';
import { useDirectUpload } from '../hooks';
import * as toasts from '../toasts';

interface Props {
  isOpen: boolean;
  onUpload: (signedId: string) => void;
  onClose: () => void;
}

export const UploadImageModal = ({ isOpen, onUpload, onClose }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const showToast = useToaster();
  const { signedId, isError, isLoading } = useDirectUpload({ file, skip: !file });

  const handleOnDrop = (files: File[]) => {
    const [file] = files;

    if (!file) return;

    setFile(file);
  };

  const handleOnclose = () => {
    setFile(null);
    onClose();
  };

  useBeforeunload(() => isLoading && UPLOAD_ALERT);

  useEffect(() => {
    if (signedId) {
      onUpload?.(signedId);
    }
  }, [signedId]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedImageUpload());
    }
  }, [isError]);

  return (
    <UploadFileModal
      uploading={isLoading}
      open={isOpen}
      onClose={isLoading ? undefined : handleOnclose}
      onDrop={handleOnDrop}
      supportedFileTypes={['png', 'jpg', 'jpeg']}
    />
  );
};
