import * as React from 'react';
import { useCallback, useContext, useState } from 'react';

import { usePermission } from '@hooks/usePermission';
import userStore from '@stores/user';

import { CalendarModePicker } from './components/CalendarModePicker';
import { CalendlyCalendar } from './components/CalendlyCalendar';
import { NylasEventsProvider } from './components/NylasCalendar/store/context';
import { SessionsCalendar } from './components/SessionsCalendar';
import { GQCalendar } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/GQCalendar';

interface Props extends PageProps {
  studyShowTitle?: boolean;
  isLoading?: boolean;
  refetch: () => void;
}

export const Calendar: React.FC<Props> = (props) => {
  const {
    state: { user }
  } = useContext(userStore);

  const isCalendly = props.study.calendly_event_type_uuid || props.study.booking_url;
  // if no nylas_calendar_id, no calendly but owner.default_nylas_calendar_id is present
  // open gq calendar instead of mode picker

  const isNylas = props.study.nylas_calendar_id || (props.study.owner?.default_nylas_calendar_id && !isCalendly);

  const [mode, setMode] = useState<'nylas' | 'calendly' | null>(() => {
    if (isNylas) return 'nylas';
    if (isCalendly) return 'calendly';
    return null;
  });

  const canEdit = usePermission<Study>('updateStudy')(props.study);

  const onBack = useCallback(() => setMode(null), []);

  if (!mode) {
    return (
      <CalendarModePicker
        currentUser={user}
        study={props.study}
        studyOwner={props.study.owner}
        refetch={props.refetch}
        onClick={(mode) => {
          setMode(mode === 'custom' ? 'calendly' : mode);
        }}
      />
    );
  }

  if (props.study.focus_group) {
    return <SessionsCalendar {...props} readOnly={!canEdit} onBack={() => setMode(null)} />;
  }

  if (mode === 'nylas') {
    return (
      <NylasEventsProvider>
        <GQCalendar {...props} readOnly={!canEdit} onBack={onBack} />
      </NylasEventsProvider>
    );
  }

  return <CalendlyCalendar {...props} onBack={() => setMode(null)} />;
};
