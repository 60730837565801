import * as React from 'react';

export const monetizationOn: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM11.41 18V16.09C13.15 15.76 14.52 14.76 14.53 12.93C14.53 10.41 12.37 9.54 10.35 9.02C8.33 8.5 7.68 7.95 7.68 7.11C7.68 6.15 8.58 5.47 10.08 5.47C11.66 5.47 12.25 6.23 12.3 7.34H14.26C14.2 5.81 13.27 4.4 11.41 3.95V2H8.74V3.93C7.02 4.3 5.63 5.42 5.63 7.14C5.63 9.19 7.33 10.21 9.81 10.81C12.04 11.34 12.48 12.12 12.48 12.95C12.48 13.56 12.04 14.54 10.08 14.54C8.25 14.54 7.53 13.72 7.43 12.67H5.47C5.58 14.61 7.03 15.71 8.74 16.07V18H11.41Z'
      fill='#9FA6B2'
    />
  </svg>
);

export const checkBox: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM13.58 4.58L14.99 6L6.99 14L2.99 10.01L4.41 8.6L6.99 11.17L13.58 4.58Z'
      fill='#9FA6B2'
    />
  </svg>
);

export const alarm: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.94456 2.44107L4.66336 0.905273L0.0560608 4.74887L1.33716 6.28467L5.94456 2.44107ZM15.3366 0.905873L19.944 4.74947L18.6628 6.28527L14.0554 2.44167L15.3366 0.905873ZM10.5001 7.09487H9.00006V13.0949L13.7501 15.9449L14.5001 14.7149L10.5001 12.3449V7.09487ZM10.0001 3.09487C5.03006 3.09487 1.00006 7.12487 1.00006 12.0949C1.00006 17.0649 5.03006 21.0949 10.0001 21.0949C14.9701 21.0949 19.0001 17.0649 19.0001 12.0949C19.0001 7.12487 14.9701 3.09487 10.0001 3.09487ZM3.00006 12.0949C3.00006 15.9549 6.14006 19.0949 10.0001 19.0949C13.8601 19.0949 17.0001 15.9549 17.0001 12.0949C17.0001 8.23487 13.8601 5.09487 10.0001 5.09487C6.14006 5.09487 3.00006 8.23487 3.00006 12.0949Z'
      fill='#9FA6B2'
    />
  </svg>
);

export const videoCall: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0H22C23.1 0 24 0.9 24 2L23.99 16C23.99 17.1 23.1 18 22 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM11 6C11 4.34 9.66 3 8 3C6.34 3 5 4.34 5 6C5 7.66 6.34 9 8 9C9.66 9 11 7.66 11 6ZM2 15V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15H2ZM19.49 11H17.85C17.63 10.37 17.5 9.7 17.5 9C17.5 8.3 17.63 7.63 17.85 7H19.49L21 5L19.01 3.01C17.7 3.99 16.73 5.38 16.28 7C16.1 7.64 16 8.31 16 9C16 9.69 16.1 10.36 16.28 11C16.73 12.61 17.7 14.01 19.01 14.99L21 13L19.49 11Z'
      fill='#9FA6B2'
    />
  </svg>
);

export const task: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      id='icon/communication/screen_share_24px'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20 18C21.1 18 21.99 17.1 21.99 16L22 6C22 4.89 21.1 4 20 4H4C2.89 4 2 4.89 2 6V16C2 17.1 2.89 18 4 18H0V20H24V18H20ZM4 16V6H20V16.01L4 16ZM7 15C7.56 12.33 9.11 9.67 13 9.13V7L17 10.73L13 14.47V12.28C10.22 12.28 8.39 13.13 7 15Z'
      fill='#9FA6B2'
    />
  </svg>
);

export const survey: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      id='icon/action/assignment_24px'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.82 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C4.86 22 4.73 21.99 4.6 21.97C4.21 21.89 3.86 21.69 3.59 21.42C3.41 21.23 3.26 21.02 3.16 20.78C3.06 20.54 3 20.27 3 20V6C3 5.72 3.06 5.46 3.16 5.23C3.26 4.99 3.41 4.77 3.59 4.59C3.86 4.32 4.21 4.12 4.6 4.04C4.73 4.01 4.86 4 5 4H9.18C9.6 2.84 10.7 2 12 2C13.3 2 14.4 2.84 14.82 4ZM7 10V8H17V10H7ZM17 14V12H7V14H17ZM14 16H7V18H14V16ZM12 3.75C12.41 3.75 12.75 4.09 12.75 4.5C12.75 4.91 12.41 5.25 12 5.25C11.59 5.25 11.25 4.91 11.25 4.5C11.25 4.09 11.59 3.75 12 3.75ZM5 20H19V6H5V20Z'
      fill='#9FA6B2'
    />
  </svg>
);

export const gift: React.FC<any> = (props) => (
  <svg className='h-4 mr-4 text-gray-400 fill-current' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <path d='M14.83 4H20v6h-1v10H1V10H0V4h5.17A3 3 0 0 1 10 .76 3 3 0 0 1 14.83 4zM8 10H3v8h5v-8zm4 0v8h5v-8h-5zM8 6H2v2h6V6zm4 0v2h6V6h-6zM8 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z' />
  </svg>
);

export const ticket: React.FC<any> = (props) => (
  <svg className='h-4 mr-4 text-gray-400 fill-current' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <path d='M20 12v5H0v-5a2 2 0 1 0 0-4V3h20v5a2 2 0 1 0 0 4zM3 5v10h14V5H3zm7 7.08l-2.92 2.04L8.1 10.7 5.27 8.56l3.56-.08L10 5.12l1.17 3.36 3.56.08-2.84 2.15 1.03 3.4L10 12.09z' />
  </svg>
);

export const council: React.FC<any> = (props) => (
  <svg className='h-4 mr-4' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.9677 11.68L19.5087 15H22.4878C22.639 14.9941 22.7885 15.035 22.9156 15.1172C23.0428 15.1994 23.1414 15.3189 23.1981 15.4593C23.2548 15.5997 23.2667 15.7542 23.2323 15.9016C23.1979 16.0491 23.1188 16.1823 23.0057 16.283L20.4237 18.845L21.8657 22.159C21.9287 22.3108 21.9426 22.4784 21.9054 22.6384C21.8682 22.7985 21.7817 22.9428 21.6583 23.0511C21.5348 23.1595 21.3804 23.2265 21.2169 23.2426C21.0535 23.2587 20.889 23.2232 20.7467 23.141L17.2607 21.18L13.7737 23.141C13.6315 23.2232 13.467 23.2587 13.3036 23.2426C13.1401 23.2265 12.9857 23.1595 12.8622 23.0511C12.7388 22.9428 12.6523 22.7985 12.6151 22.6384C12.5779 22.4784 12.5918 22.3108 12.6547 22.159L14.0997 18.848L11.4997 16.286C11.3857 16.1863 11.3053 16.0536 11.2696 15.9063C11.234 15.7591 11.2448 15.6044 11.3005 15.4635C11.3563 15.3226 11.4543 15.2024 11.5811 15.1194C11.7079 15.0364 11.8573 14.9947 12.0087 15H15.0087L16.5527 11.677C16.621 11.5484 16.723 11.4409 16.8478 11.366C16.9727 11.2911 17.1156 11.2516 17.2612 11.252C17.4067 11.2523 17.5495 11.2923 17.674 11.3677C17.7985 11.4432 17.9001 11.5511 17.9677 11.68V11.68Z'
      stroke='#9FA6B2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.832 11.7332C11.2815 10.8886 10.5277 10.1958 9.63972 9.71824C8.75179 9.24073 7.75817 8.99383 6.75 9.00018C5.2805 8.98866 3.85788 9.51684 2.75194 10.4845C1.64599 11.4522 0.933647 12.7922 0.75 14.2502'
      stroke='#9FA6B2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.375 4.125C3.375 5.02011 3.73058 5.87855 4.36351 6.51149C4.99645 7.14442 5.85489 7.5 6.75 7.5C7.64511 7.5 8.50355 7.14442 9.13649 6.51149C9.76942 5.87855 10.125 5.02011 10.125 4.125C10.125 3.22989 9.76942 2.37145 9.13649 1.73851C8.50355 1.10558 7.64511 0.75 6.75 0.75C5.85489 0.75 4.99645 1.10558 4.36351 1.73851C3.73058 2.37145 3.375 3.22989 3.375 4.125V4.125Z'
      stroke='#9FA6B2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.875 3.375C13.875 4.07119 14.1516 4.73887 14.6438 5.23116C15.1361 5.72344 15.8038 6 16.5 6C17.1962 6 17.8639 5.72344 18.3562 5.23116C18.8484 4.73887 19.125 4.07119 19.125 3.375C19.125 2.67881 18.8484 2.01113 18.3562 1.51884C17.8639 1.02656 17.1962 0.75 16.5 0.75C15.8038 0.75 15.1361 1.02656 14.6438 1.51884C14.1516 2.01113 13.875 2.67881 13.875 3.375V3.375Z'
      stroke='#9FA6B2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.4002 9.00018C20.0422 8.38067 19.5415 7.85546 18.9399 7.46815C18.3382 7.08085 17.6529 6.8426 16.9407 6.77318C16.2285 6.70376 15.5101 6.80518 14.8449 7.069C14.1798 7.33283 13.5872 7.75147 13.1162 8.29018'
      stroke='#9FA6B2'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
