export const MESSAGES: AlertMsgs = {
  pending: {
    title: 'One or more calendars are still being synced',
    body: "This should take just a few minutes. Once the calendars are finished syncing, you'll see their events displayed on the calendar and it will prevent conflicts."
  },
  pending24h: {
    title: "It's taking longer than expected to sync the calendar",
    body: "It may take up to 24 hours. Once the calendars are finished syncing, you'll see their events displayed on the calendar and it will prevent conflicts."
  },
  failed: {
    title: 'Calendar failed to sync',
    body: 'Please retry or contact support.'
  },
  finished: {
    title: 'Calendar synced!',
    body: "You're good to go, but you can also set your account defaults."
  }
};
