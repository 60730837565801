import type { ArtifactKind } from './Info';

export const formatDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  const h = hours.toString();
  const m = hours > 0 ? minutes.toString().padStart(2, '0') : minutes.toString();
  const s = seconds.toString().padStart(2, '0');

  return hours > 0 ? `${h}:${m}:${s}` : `${m}:${s}`;
};

export type Icon =
  | 'reel'
  | 'clock'
  | 'eye'
  | 'highlight'
  | 'lightBulb'
  | 'checklist'
  | 'tag'
  | 'user'
  | 'checkmark'
  | 'interview'
  | 'film'
  | 'person'
  | 'changedAt';

const DEFAULT_ARTIFACT_LABELS: Record<ArtifactKind, { icon: Icon; text: string }> = {
  Participation: { icon: 'checklist', text: 'Survey' },
  Highlight: { icon: 'highlight', text: 'Highlight' },
  HighlightReel: { icon: 'reel', text: 'Reel' },
  'Repo::Session': { icon: 'interview', text: 'Interview' },
  Story: { icon: 'lightBulb', text: 'Insight' },
  Clip: { icon: 'highlight', text: 'Highlight' },
  ScreenerResponse: { icon: 'checklist', text: 'Survey' },
  'Feedbacks::Feedback': { icon: 'checklist', text: 'Feedback' }
};

export const getArtifactInfoLabel = (kind: ArtifactKind, style?: Study['style']) => {
  if (kind === 'Repo::Session' && style === 'unmoderated_test') {
    return 'Recording';
  }
  return DEFAULT_ARTIFACT_LABELS[kind].text;
};

export const getArtifactInfoIcon = (kind: ArtifactKind) => {
  return DEFAULT_ARTIFACT_LABELS[kind].icon;
};
