import * as React from 'react';

import { api } from '@api/reduxApi';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';

import { TemplatePage } from '../TemplatePage';
import { TemplatesIndexPage } from '../TemplatesIndexPage';
import { buildColumns } from './buildColumns';
import { InterviewTemplatePage } from './InterviewTemplatePage';

export const InterviewTemplatesIndex: React.FC = () => {
  return (
    <TemplatesIndexPage<InterviewTemplate>
      kind='InterviewTemplate'
      buildColumns={buildColumns}
      getQuery={api.useGetInterviewTemplatesQuery as any}
      createMutation={api.useCreateInterviewTemplateMutation as any}
    />
  );
};
export const InterviewTemplatesEdit: React.FC = () => (
  <TemplatePage<InterviewTemplate>
    kind='InterviewTemplate'
    getQuery={api.useGetInterviewTemplateQuery as any}
    render={([interviewTemplate, setInterviewTemplate]) => (
      <InterviewTemplatePage
        editable
        interviewTemplate={interviewTemplate}
        setInterviewTemplate={setInterviewTemplate}
      />
    )}
  />
);

export const InterviewTemplatesShow: React.FC = () => (
  <TemplatePage<InterviewTemplate>
    kind='InterviewTemplate'
    getQuery={api.useGetInterviewTemplateQuery as any}
    render={([interviewTemplate, setInterviewTemplate]) => (
      <InterviewTemplatePage interviewTemplate={interviewTemplate} setInterviewTemplate={setInterviewTemplate} />
    )}
    trackEvent='viewed_template_study'
  />
);
