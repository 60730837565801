import React, { useMemo } from 'react';

import { Button, TippyOrNot } from '@components/common';

import { useCreateIntegrationJobMutation } from '../api';
import { LABELS } from '../constants';
import { Job, Provider } from '../types';

interface Props {
  job: Job;
  provider: Provider;
  disabled?: boolean;
}
export const JobButton: React.FC<Props> = ({ job, provider, disabled }) => {
  const [create, { isLoading, isSuccess, isError }] = useCreateIntegrationJobMutation();

  const handleClick = () => {
    create({ provider, job });
  };
  const text = useMemo(() => {
    if (isLoading) {
      return `Queueing...`;
    } else if (isSuccess) {
      return `Job queued!`;
    } else if (isError) {
      return `Error`;
    } else {
      return `Run ${LABELS[job]} now`;
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <TippyOrNot content='Jobs can only be run every ten minutes.' show={disabled}>
      <Button onClick={handleClick} disabled={disabled || isLoading || isSuccess || isError} small>
        {text}
      </Button>
    </TippyOrNot>
  );
};
