import React, { VFC, useEffect, useState } from 'react';
import cn from 'classnames';

import { ModalHeading, Text } from '@components/common';
import { Column, Grid } from '@components/common/Grid';

import { ScreenThumbnail } from './ScreenThumbnail';

interface Props {
  prototypeTestId: number;
  path: string[];
  clicks: PrototypeClick[];
}

export const ScreensPreview: VFC<Props> = ({ clicks, path, prototypeTestId }) => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);

  useEffect(() => () => setActiveStepIndex(-1), []);

  return (
    <Grid desktop={12} gap={8} className='h-full' style={{ maxHeight: 'inherit' }}>
      <Column desktop={3} tablet={3} mobile={3} className='px-2 overflow-y-auto'>
        {path.map((step, index) => (
          <div key={index} className='mb-4'>
            <div
              className={cn(
                'h-32 hover:border-indigo-600 relative w-full bg-gray-200 border border-transparent rounded cursor-pointer',
                { 'border-indigo-600': index === activeStepIndex }
              )}
              role='button'
              tabIndex={0}
              onClick={() => setActiveStepIndex(index)}
            >
              <ScreenThumbnail
                prototypeTestId={prototypeTestId}
                className='absolute flex items-center justify-center w-full h-full'
                clicks={clicks.filter((click) => click.response_path_index === index)}
                screenExternalElementId={step}
                thumbnailSize={400}
              />
            </div>
            <Text className='mt-1 text-xs text-gray-500'>Frame {index + 1}</Text>
          </div>
        ))}
      </Column>
      <Column desktop={9} tablet={9} mobile={9}>
        <div className='flex flex-col h-full'>
          <ModalHeading>Frame {activeStepIndex + 1}</ModalHeading>

          {activeStepIndex >= 0 && (
            <ScreenThumbnail
              prototypeTestId={prototypeTestId}
              className='flex items-center justify-center w-full h-full'
              clicks={clicks.filter((click) => click.response_path_index === activeStepIndex)}
              screenExternalElementId={path[activeStepIndex]}
              pointerSize='lg'
            />
          )}
        </div>
      </Column>
    </Grid>
  );
};
