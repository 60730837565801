import { useToaster } from 'components/stores/toaster';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getCandidateActivities } from '@api/queries';
import Activities from '@components/Activities';

interface Props {
  candidateId: number;
}
export const ActivitySection: React.FC<Props> = ({ candidateId }) => {
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState<PublicActivity[]>([]);

  const showToast = useToaster();

  useEffect(() => {
    getCandidateActivities(candidateId).then((activities) => {
      if (activities) {
        setActivities(activities);
      } else {
        showToast({
          heading: 'Something went wrong!',
          icon: 'error',
          text: `We cannot load the activities list. Please try again later.`
        });
      }
      setLoading(false);
    });
  }, []);

  return (
    <div className='divide-y-gray-200 divide-y'>
      {loading && <Skeleton className='bg-gray-50 h-12' />}
      {!loading && <Activities activities={activities} />}
    </div>
  );
};
