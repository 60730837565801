import { getTailwindColor } from '@components/utils';

export const colors = [
  getTailwindColor('Gray', 400),
  getTailwindColor('Red', 400),
  getTailwindColor('Orange', 400),
  getTailwindColor('Yellow', 400),
  getTailwindColor('Green', 400),
  getTailwindColor('Teal', 400),
  getTailwindColor('Blue', 400),
  getTailwindColor('Indigo', 400),
  getTailwindColor('Purple', 400),
  getTailwindColor('Pink', 400)
];
