import React, { SVGProps } from 'react';

export const ChevronDownSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.5625 4.26477L7.30917 10.5175C7.26859 10.5582 7.22039 10.5904 7.16734 10.6124C7.11429 10.6344 7.05743 10.6457 7 10.6457C6.94257 10.6457 6.8857 10.6344 6.83266 10.6124C6.77961 10.5904 6.73141 10.5582 6.69083 10.5175L0.4375 4.26477'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
