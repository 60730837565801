import * as React from 'react';

import { Text } from '@components/common';
import { WarningSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';
import { useUser } from 'hooks/useUser';

type Props = {
  request: ApprovalRequest;
};

export const ApprovalRequestBanner: React.VFC<Props> = ({ request }) => {
  const {
    account: { team }
  } = useAccount();
  const { id: userId } = useUser();

  if (!request) {
    return null;
  }

  const getName = (id: number) => team.find((t) => t.id === id)?.name;

  const isCreator = request.creator_id === userId;
  const isApprover = request.approver_id === userId;

  const text = isCreator
    ? `You’ve requested ${getName(request.approver_id)} to review this study.`
    : isApprover
    ? `${getName(request.creator_id)} requested you review this study.`
    : `This study is pending approval from ${getName(request.approver_id)}.`;

  return (
    <div className='bg-yellow-50 flex items-center justify-center w-full px-4 py-3 space-x-2 text-center'>
      <WarningSVG className='w-4 h-4 text-yellow-600' />
      <Text h='400'>{text}</Text>
    </div>
  );
};
