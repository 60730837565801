import { api } from 'api/reduxApi';
import * as React from 'react';
import { useState } from 'react';

import { dismissRoleRequest } from '@api/queries';
import { Alert } from '@components/common';

const CloseIcon: React.FC = () => {
  return (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 16L16 1' stroke='#374151' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 16L1 1' stroke='#374151' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const RequestAlerts: React.FC = () => {
  const { data } = api.useGetRoleRequestsQuery();
  const [requests, setRequests] = useState<any[]>([]);

  React.useEffect(() => {
    if (data?.length > 0) {
      setRequests(data);
    }
  }, [data]);

  async function handleDismiss(request) {
    await dismissRoleRequest(request.id);
    setRequests(requests.filter((r) => r.id !== request.id));
  }

  return (
    <div className='space-y-4'>
      {(requests || []).map((request) => (
        <Alert
          border
          type='promo'
          bg='white'
          cta={
            <a className='cursor-pointer' onClick={() => handleDismiss(request)}>
              <CloseIcon />
            </a>
          }
        >
          <span className='flex flex-row'>
            {request.requester.name} requested {request.role} permissions
            <a
              className='ml-2 font-bold text-indigo-600 underline'
              href={`/accounts/${request.account_id}/members/${request.account_user_id}/edit`}
            >
              {' '}
              Review request
            </a>
          </span>
        </Alert>
      ))}
    </div>
  );
};
