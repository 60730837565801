import React, { SVGProps } from 'react';

export const OnlineTaskSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={22} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.823 9.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 0 1.5h-2.5ZM11.823 9.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 0 1 0 1.5h-7.5ZM4.823 13.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 0 1.5h-2.5Z'
      fill='currentColor'
    />
    <path
      d='M19.323 13.5h-7.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 0 1 0 1.5ZM7.323 17.5h-2.5a.75.75 0 0 1 0-1.5h2.5a.75.75 0 0 1 0 1.5ZM19.323 17.5h-7.5a.75.75 0 0 1 0-1.5h7.5a.75.75 0 0 1 0 1.5Z'
      fill='currentColor'
    />
    <path
      d='M21.25 22H2.75A2.752 2.752 0 0 1 0 19.25V2.75A2.752 2.752 0 0 1 2.75 0h18.5A2.752 2.752 0 0 1 24 2.75v16.5A2.752 2.752 0 0 1 21.25 22ZM2.75 1.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25V2.75c0-.689-.561-1.25-1.25-1.25H2.75Z'
      fill='currentColor'
    />
    <path d='M23.25 5H.75a.75.75 0 0 1 0-1.5h22.5a.75.75 0 0 1 0 1.5Z' fill='currentColor' />
  </svg>
);
