import * as React from 'react';

export const templates: React.FC = () => (
  <svg
    className='group-hover:text-gray-300 w-4 h-4 mr-3 text-white'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m13.6875 1.4375h-11.375c-.48325 0-.875.39175-.875.875v2.1875c0 .48325.39175.875.875.875h11.375c.4832 0 .875-.39175.875-.875v-2.1875c0-.48325-.3918-.875-.875-.875z'></path>
      <path d='m10.1875 1.4375v3.9375'></path>
      <path d='m11.5 3.1875.875.875.875-.875'></path>
      <path d='m13.6875 7.5625h-11.375c-.48325 0-.875.39175-.875.875v5.25c0 .4832.39175.875.875.875h11.375c.4832 0 .875-.3918.875-.875v-5.25c0-.48325-.3918-.875-.875-.875z'></path>
      <path d='m1.4375 11.0625h13.125'></path>
      <path d='m12.375 12.6194c.0433 0 .0856.0128.1215.0369.036.024.064.0582.0806.0982.0166.0399.0209.0839.0124.1263-.0084.0425-.0292.0815-.0598.112-.0306.0306-.0696.0515-.112.0599-.0425.0085-.0864.0041-.1264-.0124-.04-.0166-.0741-.0446-.0982-.0806-.024-.036-.0369-.0783-.0369-.1215 0-.0581.0231-.1137.0641-.1547s.0967-.0641.1547-.0641z'></path>
      <path d='m12.375 9.06808c.0433 0 .0856.01283.1215.03687.036.02404.064.0582.0806.09817s.0209.08396.0124.12639c-.0084.04243-.0292.08141-.0598.112-.0306.0306-.0696.05143-.112.05987-.0425.00844-.0864.00411-.1264-.01245-.04-.01655-.0741-.04459-.0982-.08056-.024-.03598-.0369-.07827-.0369-.12154 0-.05801.0231-.11365.0641-.15468.041-.04102.0967-.06407.1547-.06407z'></path>
    </g>
  </svg>
);
