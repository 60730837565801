import cn from 'classnames';
import * as React from 'react';

import { useHover } from '@hooks/useHover';

type AnimatedOnHoverImageProps = {
  className?: string;
  imageUrl: string;
  gifUrl: string;
};
export const AnimatedOnHoverImage: React.FC<AnimatedOnHoverImageProps> = ({ className, imageUrl, gifUrl }) => {
  const { hover, hoverRootProps } = useHover();

  return (
    <>
      <img
        alt='Thumbnail preview of the video clip'
        className={cn('w-auth h-auto max-w-full max-h-full object-contain', className)}
        src={hover ? gifUrl : imageUrl}
        {...hoverRootProps}
      />
      <PreloadedImage url={gifUrl} />
    </>
  );
};

export const PreloadedImage: React.FC<{ url: string }> = ({ url }) => (
  <img alt='' className='absolute' style={{ left: -9999 }} src={url} />
);
