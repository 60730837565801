import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

const namespaces = [
  'ConfirmationPage',
  'GQSurvey',
  'InsertLoom',
  'OptedOut',
  'PreviewSchedulingPage',
  'PreviewSignupPage',
  'StartLoom',
  'SurveyQuestion',
  'UnsupportedLoomPage',
  'UpdateMyPreferencesForm',
  'Scheduler',
  'BookingPage',
  'Calendar',
  'Timeslots',
  'TimeProposal',
  'ConsentFormPage'
];

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      // TODO: make this depend on an env var
      import(`../../../config/locales/frontend/${namespace}/${language}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    })
  )
  .init({
    ns: namespaces,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });
