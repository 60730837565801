import React from 'react';
import { CalendarWithClockSVG, ChevronRightSVG, CogSVG } from '@components/svgs';
import { Text } from '@components/common';
import { capitalize, humanize } from '@components/utils';
import { AttendeesSVG } from '@components/svgs/Attendees';
import { AdditionalOptionsSVG } from '@components/svgs/AdditionalOptions';
import cn from 'classnames';

export type PanelType = 'event_details' | 'attendees' | 'availability' | 'additional_options';

interface Props {
  onClick: (v: PanelType) => void;
  type: PanelType;
  className?: string;
}

const BUTTON_ICONS = {
  event_details: <CogSVG className='w-6 h-6 flex-shrink-0' />,
  attendees: <AttendeesSVG className='flex-shrink-0' />,
  availability: <CalendarWithClockSVG className='w-6 h-6 flex-shrink-0' />,
  additional_options: <AdditionalOptionsSVG className='flex-shrink-0' />
};

export const PanelButton: React.FC<Props> = ({ className, onClick, type, children }) => {
  return (
    <button
      aria-label={type}
      className={cn(
        className,
        'text-left z-10 focus:ring focus:outline-none border-b border-gray-200 p-6 flex w-full cursor-pointer hover:bg-gray-50 hover:text-indigo-600 transition duration-150 ease-in-out'
      )}
      onClick={() => onClick(type)}
    >
      {BUTTON_ICONS[type]}
      <div className='mx-3 flex-grow'>
        <div className='flex items-center justify-between mb-3'>
          <Text bold>{humanize(capitalize(type))}</Text>

          <ChevronRightSVG className='w-4 h-4 flex-shrink-0' />
        </div>
        <div className='flex flex-col space-y-2'>{children}</div>
      </div>
    </button>
  );
};
