import { Toast } from '@stores/toaster';

export const successDeleteHighlight = (): Toast => ({
  heading: 'Highlight deleted.',
  icon: 'success',
  text: 'The highlight and related data have been deleted.'
});

export const failedDeleteHighlight = (): Toast => ({
  heading: 'Failed to delete the highlight!',
  icon: 'error',
  text: 'Something went wrong deleting the highlight. Please try again later.'
});

export const failedImageUpload = (): Toast => ({
  heading: 'Failed to upload file!',
  icon: 'error',
  text: 'Something went wrong uploading your image. Please try again.'
});

export const failedUseTemplate = (): Toast => ({
  heading: 'We couldn’t apply this template',
  icon: 'error',
  text: 'Something may be wrong with the template. Please try again.'
});

export const successCopyLink = (): Toast => ({
  heading: 'Success!',
  text: 'Link copied to clipboard',
  icon: 'success'
});

export const failedUpdateHighlight = (): Toast => ({
  heading: 'Failed to update highlight!',
  text: 'Something went wrong updating the highlight. Please try again later.',
  icon: 'error'
});

export const failedUpdateSummary = (): Toast => ({
  heading: 'Failed to update summary!',
  text: 'Something went wrong updating the summary. Please try again later.',
  icon: 'error'
});
