import React, { SVGProps } from 'react';

export const FullscreenSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M4.8 11.2L2 14' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14 5.2V2H10.8' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2 10.8V14H5.2' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14 2L11.2 4.8' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.2 11.2L14 14' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2 5.2V2H5.2' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14 10.8V14H10.8' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2 2L4.8 4.8' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 6H10V10H6V6Z' stroke='currentColor' strokeWidth='0.8' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
