import React, { SVGProps } from 'react';

export const ReloadIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.3147 2.38254L12.322 3.84093C10.7684 2.22888 8.41188 1.59437 6.22178 2.27413C3.71513 3.05215 2.00301 5.37252 2.00017 7.99635C1.98685 8.50755 2.75948 8.51058 2.75016 7.99933C2.75271 5.70085 4.2467 3.67297 6.44297 2.99129C8.4432 2.37046 10.5945 3.00164 11.9485 4.54857L10.1636 4.55807C9.66314 4.56076 9.66608 5.31161 10.1666 5.30887L12.7044 5.29498C12.9029 5.29389 13.0798 5.12937 13.0786 4.91555L13.0647 2.37966C13.0636 2.16674 12.8923 1.99442 12.6829 2.00014C12.4734 2.00585 12.3136 2.17649 12.3147 2.38254ZM13.25 8.00075C13.2475 10.2992 11.7534 12.3271 9.55714 13.0088C7.55691 13.6296 5.40566 12.9984 4.05161 11.4515L5.83649 11.442C6.33697 11.4393 6.33403 10.6885 5.83353 10.6912C4.98759 10.6959 3.47336 10.7041 3.29572 10.7051C3.09716 10.7062 2.92029 10.8707 2.92146 11.0845L2.93535 13.6204C2.93652 13.8333 3.10778 14.0057 3.3206 13.9999C3.52656 13.9942 3.68638 13.8236 3.68534 13.6175C3.68293 13.1314 3.67797 12.1591 3.67797 12.1591C5.23156 13.7712 7.58812 14.4057 9.77822 13.7259C12.2849 12.9479 13.997 10.6275 13.9998 8.00368C14.0065 7.74808 13.8166 7.61986 13.6264 7.61912C13.4361 7.61837 13.2453 7.7451 13.25 8.00075Z'
        fill='currentColor'
      />
    </svg>
  );
};
