import { api } from 'api/reduxApi';
import { WindowLayout } from 'components/layouts/WindowLayout';
import { useToaster } from 'components/stores/toaster';
import { useAccount } from 'hooks/useAccount';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Loading, Text } from '@components/common';

import { Account } from './Account';

interface Props {}

export const AccountSwitcher: React.FC<Props> = () => {
  const showToast = useToaster();
  const navigate = useNavigate();
  const { data: accounts } = api.useGetAccountsQuery();
  const {
    account: { id: currentId }
  } = useAccount();

  const [switchAccount] = api.useSwitchAccountMutation();
  const [destroyUserSession] = api.useDestroyUserSessionMutation();

  async function handleSelect(id: number) {
    try {
      await switchAccount(id).unwrap();
      window.location.href = '/';
    } catch (_) {
      showToast({ icon: 'error', heading: 'Failed to switch account', text: 'Please try again' });
      navigate(-1);
    }
  }

  async function handleSignOut() {
    await destroyUserSession().unwrap();

    window.location.href = '/users/sign_in';
  }

  return (
    <div className='fixed inset-0 z-30 bg-white'>
      <Button link onClick={handleSignOut} className='float-right'>
        Sign out
      </Button>
      <WindowLayout>
        <div className='flex flex-col flex-grow w-full max-w-2xl pt-10 pb-10 mx-auto overflow-y-auto'>
          <div className='text-center'>
            <Text h='800' mb='6'>
              Select a workspace
            </Text>
          </div>
          {accounts ? (
            <ul className='mb-4 space-y-6 overflow-y-auto'>
              {accounts.map((a) => (
                <Account account={a} onSelect={handleSelect} current={a.id === currentId} />
              ))}
            </ul>
          ) : (
            <Loading />
          )}
        </div>
      </WindowLayout>
    </div>
  );
};
