export const AD_HOC_MESSAGE_SLIDEOUT = 'AD_HOC_MESSAGE_SLIDEOUT';
export const ADD_DROPDOWN = 'ADD_DROPDOWN';
export const ADD_EXISTING = 'ADD_EXISTING';
export const ADD_MANUAL = 'ADD_MANUAL';
export const FUND_STUDY = 'FUND_STUDY';
export const ACTIVATE_STUDY = 'ACTIVATE_STUDY';
export const CLOSE_STUDY = 'CLOSE_STUDY';
export const SEND_INVITES_DROPDOWN = 'SEND_INVITES_DROPDOWN';
export const SEND_INVITES = 'SEND_INVITES';
export const SEND_INVITED_REMINDER = 'SEND_INVITED_REMINDER';
export const SEND_REMINDERS = 'SEND_REMINDERS';
export const SEND_SCREENER = 'SEND_SCREENER';
export const SEND_THANKS = 'SEND_THANKS';
export const INVITE_VIA_LINK = 'INVITE_VIA_LINK';
export const MANUAL_SCHEDULE_SLIDEOUT = 'MANUAL_SCHEDULE_SLIDEOUT';
export const RESCHEDULE_REQUEST_SLIDEOUT = 'RESCHEDULE_REQUEST_SLIDEOUT';
export const CANCEL_INTERVIEW_SLIDEOUT = 'CANCEL_INTERVIEW_SLIDEOUT';
export const CANCEL_TASK_SLIDEOUT = 'CANCEL_TASK_SLIDEOUT';
export const COMPLETE_AND_THANKS = 'COMPLETE_AND_THANKS';
export const UPDATE_MODERATOR = 'UPDATE_MODERATOR';
export const MANUAL_RESCHEDULE_MODAL = 'MANUAL_RESCHEDULE_MODAL';
export const REVIEW_TIME_PROPOSAL = 'REVIEW_TIME_PROPOSAL';
export const ADD_INTERVIEW = 'ADD_INTERVIEW';
export const EDIT_CUSTOM_ATTRIBUTES = 'EDIT_CUSTOM_ATTRIBUTES';

export type StudyAction =
  | typeof AD_HOC_MESSAGE_SLIDEOUT
  | typeof ADD_DROPDOWN
  | typeof ADD_EXISTING
  | typeof ADD_MANUAL
  | typeof FUND_STUDY
  | typeof CLOSE_STUDY
  | typeof SEND_INVITES_DROPDOWN
  | typeof SEND_INVITES
  | typeof SEND_INVITED_REMINDER
  | typeof SEND_REMINDERS
  | typeof SEND_SCREENER
  | typeof INVITE_VIA_LINK
  | typeof MANUAL_SCHEDULE_SLIDEOUT
  | typeof SEND_THANKS
  | typeof COMPLETE_AND_THANKS
  | typeof CANCEL_INTERVIEW_SLIDEOUT
  | typeof CANCEL_TASK_SLIDEOUT
  | typeof RESCHEDULE_REQUEST_SLIDEOUT
  | typeof ADD_INTERVIEW
  | typeof UPDATE_MODERATOR
  | typeof MANUAL_RESCHEDULE_MODAL
  | typeof REVIEW_TIME_PROPOSAL
  | typeof EDIT_CUSTOM_ATTRIBUTES;
