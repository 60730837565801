import * as React from 'react';
import { useEffect, useState } from 'react';

interface Props {
  id?: string;
  disabled?: boolean;
  className?: string;
  name?: string;
  on?: boolean;
  onToggle: (value: boolean) => void;
  secondary?: boolean;
  testId?: string;
  customOnClick?: (value: boolean) => void;
}

export const Toggle: React.FC<Props> = ({
  id,
  customOnClick,
  disabled,
  name,
  on: initialOn,
  onToggle,
  secondary,
  className = '',
  testId
}) => {
  const [on, setOn] = useState(initialOn);
  const handleClick = () => {
    onToggle(!on);
    setOn(!on);
  };

  useEffect(() => {
    if (initialOn !== on) {
      setOn(initialOn);
    }
  }, [initialOn]);

  const selectedClassName = disabled
    ? secondary
      ? 'bg-gray-300'
      : 'bg-indigo-400'
    : secondary
    ? 'bg-gray-500'
    : 'bg-indigo-600';

  const onClick = () => {
    if (!disabled) {
      customOnClick ? customOnClick(!on) : handleClick();
    }
  };

  return (
    <span
      id={id}
      data-testid={testId}
      role='checkbox'
      tabIndex={0}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      aria-checked={on}
      aria-label={name}
      aria-disabled={disabled}
      className={`${className} ${
        on ? selectedClassName : 'bg-gray-200'
      } underline-none relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring xx-toggle m-1`}
    >
      <span
        aria-hidden='true'
        className={`${
          on ? 'translate-x-5' : 'translate-x-0'
        } inline-block h-4 w-4 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
      ></span>
    </span>
  );
};
