import { Text, Tooltip } from '@components/common';
import React from 'react';
import { TextProps } from '@components/common/Text';

interface Props extends TextProps {
  tooltip?: string;
  h?: TextProps['h'];
}

export const Label: React.FC<Props> = ({ h = '400', children, tooltip, ...rest }) => (
  <label className='flex items-center mb-2 space-x-2'>
    <Text h={h} bold {...rest}>
      {children}
    </Text>
    {tooltip && <Tooltip>{tooltip}</Tooltip>}
  </label>
);
