import * as React from 'react';

import { capitalize } from '@components/utils';
import { usePopUp } from '@hooks/usePopUp';

import { FilterDefinition, Operator } from '../types';
import { getOperatorCopy } from '../utils/getOperatorCopy';

type Props = {
  filterDefType: FilterDefinition<any>['type'];
  operator: Operator | null;
  options: Operator[];
  onChange: (op: Operator) => void;
};
export const OperatorDropdown: React.FC<Props> = ({ filterDefType, operator, options, onChange }) => {
  const { ref, PopUp, open, closePopUp, togglePopUp } = usePopUp();

  const handleClick = (op: Operator) => () => {
    onChange(op);
    closePopUp();
  };

  return (
    <div ref={ref} className='inline-block'>
      <button
        type='button'
        name='operator'
        className='h400 py-1.5 px-2.5 border border-gray-200 rounded-md hover:border-indigo-600 hover:text-indigo-600'
        onClick={togglePopUp}
      >
        {operator ? capitalize(getOperatorCopy(filterDefType, operator)) : 'Choose…'}
      </button>
      <PopUp open={open} className='pt-2'>
        <ul className='bg-white border border-gray-200 rounded-md shadow-lg'>
          {options.map((op) => (
            <li
              className='tw-ui-dropdown-link hover:text-indigo-600 text-gray-700'
              key={op}
              role='option'
              aria-selected={op === operator}
              onClick={handleClick(op)}
            >
              {capitalize(getOperatorCopy(filterDefType, op))}
            </li>
          ))}
        </ul>
      </PopUp>
    </div>
  );
};
