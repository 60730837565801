export enum Kind {
  prototypeTest = 'prototype_test',
  websiteTest = 'website_test',
  cardSort = 'card_sort',
  date = 'date',
  email = 'email',
  info = 'info',
  linearScale = 'linear_scale',
  location = 'location',
  longText = 'long_text',
  multiSelect = 'multi_select',
  number = 'number',
  permissions = 'permissions',
  shortText = 'short_text',
  singleSelect = 'single_select',
  thankYou = 'thank_you',
  website = 'website',
  welcome = 'welcome',
  yesNo = 'yes_no'
}

export enum UnmoderatedDevice {
  camera = 'camera',
  microphone = 'microphone',
  screen = 'screen'
}

export enum DevicePermission {
  disabled = 'disabled',
  required = 'required'
}
