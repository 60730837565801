import React, { SVGProps } from 'react';

export const ResetSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.4375 4.375L5.6875 2.625L7.4375 0.875'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.76809 7.4375C1.75642 7.58158 1.75 7.72917 1.75 7.875C1.74949 8.31775 1.80516 8.75877 1.91567 9.1875'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.97864 4.50049C2.69358 4.83927 2.45268 5.21287 2.26172 5.61232'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.99219 11.2651C3.08552 11.3756 3.1843 11.4829 3.28852 11.5871C3.60129 11.9005 3.95254 12.173 4.33385 12.398'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5625 13.1069C6.70658 13.1186 6.85417 13.125 7 13.125C8.39239 13.125 9.72774 12.5719 10.7123 11.5873C11.6969 10.6027 12.25 9.26739 12.25 7.875C12.25 6.48261 11.6969 5.14726 10.7123 4.16269C9.72774 3.17812 8.39239 2.625 7 2.625H5.6875'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
