import React, { FC } from 'react';
import { Button, Popper } from '@components/common';

interface Props {
  markAsComplete?: () => void;
  markAsNoShow?: () => void;
  markAsCanceled?: () => void;
}

export const MarkDropdown: FC<Props> = ({ markAsComplete, markAsNoShow, markAsCanceled }) => (
  <Popper
    content={() => (
      <div className='ring-1 ring-black ring-opacity-5 relative flex flex-col py-2 bg-white border border-gray-200 rounded-md'>
        {markAsComplete && (
          <button
            className='hover:text-indigo-600 hover:bg-gray-100 xx-mark-completed w-full px-4 py-2 text-sm text-left text-gray-700'
            onClick={markAsComplete}
          >
            Mark as completed...
          </button>
        )}
        {markAsCanceled && (
          <button
            className='hover:text-indigo-600 hover:bg-gray-100 xx-mark-canceled w-full px-4 py-2 text-sm text-left text-gray-700'
            onClick={markAsCanceled}
          >
            Mark as canceled...
          </button>
        )}
        {markAsNoShow && (
          <button
            className='hover:text-indigo-600 hover:bg-gray-100 w-full px-4 py-2 text-sm text-left text-gray-700'
            onClick={markAsNoShow}
          >
            Mark as no-show
          </button>
        )}
      </div>
    )}
    placement='bottom-end'
    offset={[0, 16]}
    closeOnClickOutside
    zIndex={30}
  >
    <Button className='whitespace-nowrap' aria-label='Mark as…' iconSuffix='caretDown' primary>
      Mark as…
    </Button>
  </Popper>
);
