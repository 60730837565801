import React from 'react';

import { Button, Text } from '@components/common';
import { TagSVG } from '@components/svgs';

import { Preview } from './Preview';

// import { Icon } from './Icon';

interface Props {
  handleAddQuestion: (question: ScreenerField) => void;
  history: ScreenerField[];
}

export const History: React.FC<Props> = ({ handleAddQuestion, history }) => (
  <div>
    {history.length === 0 && (
      <Text h='400' className='px-4 pt-10 text-center'>
        When you ask questions in a screener or survey, we’ll show them here.
      </Text>
    )}

    {history.map((question) => (
      <Preview question={question}>
        <div key={question.label} className='py-3 pl-4 pr-0 border-b border-gray-200'>
          <div className='flex flex-row items-center justify-between'>
            <Text h='400'>{question.label}</Text>
            <Button
              link
              small
              onClick={() => handleAddQuestion(question)}
              icon='plus'
              className='bg-transparent border-none'
            />
          </div>

          {question.candidate_attr && (
            <div className='flex items-center mt-2 space-x-2'>
              <TagSVG className='text-gray-500' />
              <Text h='200' color='gray-500'>
                {question.candidate_attr}
              </Text>
            </div>
          )}
        </div>
      </Preview>
    ))}
  </div>
);
