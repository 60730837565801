import * as React from 'react';

export const gettingStarted: React.FC = () => (
  <svg
    className='group-hover:text-gray-300 w-4 h-4 mr-3 text-white'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.125 1.91406L12.8661 2.05406C11.8788 2.16381 10.9582 2.60632 10.2557 3.30881L4.53497 9.02956L7.00831 11.5041L12.7302 5.78331C13.4329 5.08103 13.8755 4.16034 13.985 3.1729L14.125 1.91406Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M4.53497 9.02946L2.42213 8.3248C2.35138 8.3013 2.28781 8.26009 2.23749 8.20508C2.18716 8.15007 2.15175 8.08311 2.13462 8.01055C2.11748 7.93799 2.11921 7.86225 2.13962 7.79054C2.16002 7.71884 2.19844 7.65354 2.25122 7.60088L2.38655 7.46555C2.84613 7.00588 3.42463 6.68328 4.05724 6.53388C4.68984 6.38449 5.35155 6.41421 5.96822 6.61971L6.7003 6.86355L4.53497 9.02946Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M4.53497 9.02946L2.42213 8.3248C2.35138 8.3013 2.28781 8.26009 2.23749 8.20508C2.18716 8.15007 2.15175 8.08311 2.13462 8.01055C2.11748 7.93799 2.11921 7.86225 2.13962 7.79054C2.16002 7.71884 2.19844 7.65354 2.25122 7.60088L2.38655 7.46555C2.84613 7.00588 3.42463 6.68328 4.05724 6.53388C4.68984 6.38449 5.35155 6.41421 5.96822 6.61971L6.7003 6.86355L4.53497 9.02946Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M7.0083 11.504L7.71297 13.6168C7.73647 13.6876 7.77768 13.7511 7.83268 13.8014C7.88769 13.8518 7.95466 13.8872 8.02722 13.9043C8.09978 13.9215 8.17552 13.9197 8.24722 13.8993C8.31893 13.8789 8.38422 13.8405 8.43688 13.7877L8.5728 13.6524C9.03237 13.1927 9.35486 12.6142 9.50415 11.9816C9.65344 11.349 9.62363 10.6873 9.41805 10.0707L9.17538 9.34155L7.0083 11.504Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M7.0083 11.504L7.71297 13.6168C7.73647 13.6876 7.77768 13.7511 7.83268 13.8014C7.88769 13.8518 7.95466 13.8872 8.02722 13.9043C8.09978 13.9215 8.17552 13.9197 8.24722 13.8993C8.31893 13.8789 8.38422 13.8405 8.43688 13.7877L8.5728 13.6524C9.03237 13.1927 9.35486 12.6142 9.50415 11.9816C9.65344 11.349 9.62363 10.6873 9.41805 10.0707L9.17538 9.34155L7.0083 11.504Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.53773 11.8138C5.53795 11.9862 5.50411 12.1569 5.43814 12.3162C5.37217 12.4755 5.27537 12.6201 5.15331 12.7419C4.64115 13.254 2.06165 13.9791 2.06165 13.9791C2.06165 13.9791 2.78673 11.3979 3.2989 10.8875C3.52388 10.6619 3.82334 10.5261 4.14123 10.5054'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
