import * as React from 'react';
import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import { Column } from '@components/shared/ColumnBoard';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import { useDeleteEntityGroupMutation, useUpdateEntityGroupMutation } from '../api';
import { useSynthesisContext } from '../hooks/useSynthesisContext';
import * as toasts from '../toasts';
import { DeleteHighlightGroupModal } from './DeleteHighlightGroupModal';
import { HighlightCard } from './HighlightCard';
import { UngroupedZDS } from './UngroupedZDS';
import { HighlightArtifact } from '@components/RepositoryApp/components/PreviewContentSlideout';

type Props = {
  group?: EntityGroup;
  ungrouped?: boolean;
  readOnly?: boolean;
  highlights: Highlight[];
  setPreviewSlideout: (v: HighlightArtifact) => void;
};

export const HighlightGroupColumn: React.FC<Props> = ({
  setPreviewSlideout,
  group,
  ungrouped,
  readOnly = false,
  highlights
}) => {
  const id = group?.id;
  const groupTrackKey = ungrouped ? 'ungrouped' : 'group';
  const { selectedIds, setSelectedIds, isZDS } = useSynthesisContext();

  const [deleteGroup, deleteGroupState] = useDeleteEntityGroupMutation();
  const [updateGroup, { isLoading: isUpdating }] = useUpdateEntityGroupMutation();

  const showToast = useToaster();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (deleteGroupState.isSuccess) {
      showToast(toasts.successDeleteEntityGroup());
    }
  }, [deleteGroupState.isSuccess]);

  useEffect(() => {
    if (deleteGroupState.isError) {
      showToast(toasts.failedDeleteEntityGroup());
    }
  }, [deleteGroupState.isError]);

  const onSelectAll = () => {
    const ids = highlights.map((h) => h.id);
    setSelectedIds([...selectedIds, ...ids]);
    track('board_clicked_select_all', { group: groupTrackKey, count: ids.length });
  };

  const onUnselectAll = () => {
    const ids = highlights.map((h) => h.id);
    setSelectedIds(selectedIds.filter((id) => !ids.includes(id)));
    track('board_clicked_deselect_all', { group: groupTrackKey });
  };

  const onClickDelete = (closeDropdown: () => void) => {
    if (!group) {
      return;
    }
    return () => {
      if (highlights.length === 0) {
        deleteGroup(group.id);
      } else {
        setDeleteModalOpen(true);
      }
      closeDropdown();
    };
  };

  return (
    <Column
      id={id}
      name={group?.title}
      description={group?.description}
      color={group?.color || 'default'}
      isSolidWhite={isZDS}
      ungrouped={ungrouped}
      ungroupedCount={highlights.length}
      isUpdating={isUpdating}
      onChangeTitle={(value) => {
        if (id && value !== group?.title) {
          updateGroup({ id, title: value });
          track('board_updated_group_title', { title: value, length: value.length });
        }
      }}
      onChangeDescription={(value) => {
        if (id && value !== group?.description) {
          updateGroup({ id, description: value });
          track('board_updated_group_description', { length: value.length });
        }
      }}
      onChangeColor={(color) => {
        if (id && color !== group?.color) {
          updateGroup({ id, color });
          track('board_updated_group_color', { color });
        }
      }}
      ungroupedLabel='Existing highlights that haven’t been grouped'
      shouldRenderDroppablePlaceholder={highlights && highlights.length === 0}
      dropdownOptions={
        !isZDS &&
        (({ closeDropdown }) => ({
          onClickSelectAll: onSelectAll,
          onClickDeselectAll: onUnselectAll,
          onClickDelete: onClickDelete(closeDropdown)
        }))
      }
      readOnly={readOnly}
    >
      {isZDS && <UngroupedZDS />}

      <DeleteHighlightGroupModal
        open={deleteModalOpen}
        isLoading={deleteGroupState.isLoading}
        onClose={() => setDeleteModalOpen(false)}
        onSubmit={() => {
          if (!group) return;
          deleteGroup(group.id);
        }}
      />
      {highlights &&
        highlights.slice(0).map((highlight) => {
          const isSelected = selectedIds.some((id) => id === highlight.id);

          const onToggleSelected = () =>
            setSelectedIds(
              isSelected ? selectedIds.filter((id) => id !== highlight.id) : [...selectedIds, highlight.id]
            );

          return (
            <Draggable key={highlight.id} draggableId={String(highlight.id)} index={0} isDragDisabled={readOnly}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <HighlightCard
                    setPreviewSlideout={setPreviewSlideout}
                    isSelected={isSelected}
                    onToggleSelected={onToggleSelected}
                    highlight={highlight}
                  />
                </div>
              )}
            </Draggable>
          );
        })}
    </Column>
  );
};
