import { AlgoliaData } from './types';

export const HIGHLIGHT_PRE_TAG = '<highlight-tag>';
export const HIGHLIGHT_POST_TAG = '</highlight-tag>';

export const getHighlightedParts: AlgoliaData['getHighlightedParts'] = (hit) => {
  const value = hit._highlightResult?.body?.value;
  const matchedWords = hit._highlightResult?.body?.matchedWords ?? [];

  if (!value) {
    return [];
  }

  return value.split(new RegExp(`${HIGHLIGHT_PRE_TAG}(.*?)${HIGHLIGHT_POST_TAG}`)).map((part) => ({
    value: part,
    isHighlighted: !!matchedWords.includes(part.toLowerCase())
  }));
};
