import { useMemo } from 'react';

type UseVisibleColumnsReturnType = Record<string, boolean>;

interface UseVisibleColumnsProps {
  columnIds: string[];
  study: Study;
}

export const useVisibleColumns = ({ columnIds, study }: UseVisibleColumnsProps): UseVisibleColumnsReturnType => {
  const visibleColumns = useMemo<UseVisibleColumnsReturnType>(() => {
    const columns: UseVisibleColumnsReturnType = columnIds.reduce((acc, columnId) => {
      return { ...acc, [columnId]: false };
    }, {} as UseVisibleColumnsReturnType);

    // don't touch these
    columns.id = true;
    columns.name = true;

    study.visible_attrs.forEach((columnId) => {
      columns[columnId] = true;
    });

    return columns;
  }, [columnIds, study.visible_attrs]);

  return visibleColumns;
};
