import { InputProps } from 'components/Public/GQSurvey/types';
import * as React from 'react';

import { Control, Controller, FieldError } from 'react-hook-form';

import { Input } from '@components/common';

type Props = InputProps & {
  error?: FieldError;
  placeholder?: string;
  control: Control;
  onFocus: () => void;
};

export const DateInput: React.FC<Props> = ({ field: { id, required }, onFocus, control, error, autofocus }) => {
  return (
    <Controller
      onFocus={onFocus}
      control={control}
      name={String(id)}
      id={String(id)}
      rules={{ required }}
      render={({ onChange, value }: any) => (
        <div className='relative flex items-center mt-1'>
          <Input
            type='date'
            error={!!error}
            selected={value}
            autoFocus={autofocus}
            aria-describedby={`description-for-${id} instructions`}
            aria-required={required ? 'true' : 'false'}
            id={String(id)}
            onChange={onChange}
            className='w-full focus-ring-custom-brand focus-border-custom-brand'
          />
          <p className='hidden' id='instructions'>
            Please enter the date in mm/dd/yyyy format.
          </p>
        </div>
      )}
    />
  );
};
