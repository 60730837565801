import React, { SVGProps } from 'react';

export const CaretSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='9' height='14' viewBox='0 0 9 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.26465 0.4375L7.5174 6.69083C7.55804 6.73141 7.59027 6.77961 7.61227 6.83266C7.63427 6.88571 7.64559 6.94257 7.64559 7C7.64559 7.05743 7.63427 7.1143 7.61227 7.16734C7.59027 7.22039 7.55804 7.26859 7.5174 7.30917L1.26465 13.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
