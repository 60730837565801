import React, { createContext } from 'react';

import { useDrawer, Drawer } from '../hooks/useDrawer';

export const DrawerContext = createContext<Drawer | undefined>(undefined);

export const DrawerProvider: React.FC = ({ children }) => {
  const drawer = useDrawer();

  return <DrawerContext.Provider value={drawer}>{children}</DrawerContext.Provider>;
};

DrawerContext.displayName = 'DrawerContext';
