import React, { SVGProps } from 'react';

export const CircleArrowsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.7112 4.08333C10.0805 3.31941 9.22941 2.76872 8.27413 2.50645C7.31885 2.24417 6.30591 2.28309 5.37357 2.61788C4.44123 2.95268 3.63492 3.56703 3.0647 4.37709C2.49448 5.18716 2.18815 6.15345 2.1875 7.14408V8.45833'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 10.4463C4.16138 11.1471 5.01791 11.6332 5.95871 11.8417C6.8995 12.0502 7.88122 11.9715 8.77676 11.6157C9.6723 11.26 10.4404 10.6436 10.9817 9.8463C11.5229 9.04904 11.8123 8.10766 11.8125 7.14404V6.26904'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.4375 6.70654L2.1875 8.45654L3.9375 6.70654'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5625 8.01904L11.8125 6.26904L10.0625 8.01904'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
