import { Editor, MarkRange, Range } from '@tiptap/core';

import * as Queries from './queries';

export const replaceMark = (editor: Editor, ranges: [MarkRange, MarkRange]) => {
  const {
    view: { dispatch },
    state
  } = editor;
  let { tr } = state;
  const [oldRange, newRange] = ranges;

  tr = tr.removeMark(oldRange.from, oldRange.to, oldRange.mark);
  tr = tr.addMark(newRange.from, newRange.to, newRange.mark);

  dispatch(tr);
};

export const replaceTextAtRange = (editor: Editor, text: string, range: Range) => {
  if (!text) return;

  const {
    state: { tr },
    view: { dispatch }
  } = editor;

  dispatch(tr.insertText(text, range.from, range.to));
};

export const deleteAllNodesOfType = (editor: Editor, type: string) => {
  const nodes = Queries.getAllNodesOfType(editor.state, type);

  nodes.forEach(({ node, pos }) => {
    editor.commands.deleteRange({ from: pos, to: pos + node.nodeSize });
  });
};

export const updateNodeAttributesAt = <A extends Record<string, any>>(editor: Editor, pos: number, attrs: A) => {
  const { tr } = editor.state;
  const { dispatch } = editor.view;
  tr.setNodeMarkup(pos, undefined, attrs);
  dispatch(tr);
};
