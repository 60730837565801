import * as React from 'react';

import { Text } from '@components/common';
import { default as Select, SelectOption, SelectProps } from '@components/common/Select';
import { ACCESS_DESCRIPTION } from '@components/shared/ShareAccess/consts';
import { LockSVG, WorldSVG } from '@components/svgs';

const SHARE_ACCESS_OPTIONS: SelectOption<ShareAccess>[] = [
  { value: 'private', label: `Restricted — ${ACCESS_DESCRIPTION.private}` },
  { value: 'public', label: `Public — ${ACCESS_DESCRIPTION.public}` }
];

type Props = Omit<SelectProps<ShareAccess>, 'options'>;

const renderOption = (v: typeof SHARE_ACCESS_OPTIONS[0]) => (
  <div className='flex items-center space-x-2 truncate'>
    {v.value === 'public' && <WorldSVG />}
    {v.value === 'private' && <LockSVG />}
    <Text h='400' truncate>
      {v.label}
    </Text>
  </div>
);

export const DefaultShareAccessSelect: React.FC<Props> = ({ ...rest }) => (
  <Select<ShareAccess>
    {...rest}
    options={SHARE_ACCESS_OPTIONS}
    renderSelected={renderOption}
    renderLabel={renderOption}
    wrapperClass='w-full max-w-md'
  />
);
