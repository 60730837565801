import { useEffect, useRef } from 'react';

type Params = {
  hasMore: boolean;
  refineNext: () => void;
};
type Hook = {
  sentinel: React.RefObject<HTMLDivElement>;
};
export const useInfiniteScroll = (params: Params): Hook => {
  const sentinel = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver>();
  const refineNextRef = useRef<() => void>();
  const hasMoreRef = useRef<boolean>();
  refineNextRef.current = params.refineNext;
  hasMoreRef.current = params.hasMore;
  useEffect(() => {
    if (sentinel.current && !observer.current) {
      observer.current = new IntersectionObserver((entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting && hasMoreRef.current) {
            refineNextRef.current?.();
          }
        })
      );
      observer.current.observe(sentinel.current);
      return () => {
        observer.current?.disconnect();
      };
    }
  }, [sentinel]);
  return {
    sentinel
  };
};
