import { useEffect } from 'react';

export function useOnEscape(handler: (e: KeyboardEvent) => void, deps?: any[]): void {
  // State for keeping track of whether key is pressed
  // const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      handler(e);
    }
  }

  // If released key is our target key then set to false
  // const upHandler = ({ key }) => {
  //   if (key === targetKey) {
  //     setKeyPressed(false);
  //   }
  // };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, deps || []); // Empty array ensures that effect is only run on mount and unmount
}
