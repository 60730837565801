import cn from 'classnames';
import React from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { Item, Menu } from '@components/common/Menu';
import { useGetWhoamiQuery } from '@components/stores/whoami';
import { ChevronDownSVG, PublicAssetLogoSVG } from '@components/svgs';

export const Header: React.FC = () => {
  const { data: whoami } = useGetWhoamiQuery();
  const [deleteUserSession] = api.useDeleteUserSessionMutation();

  async function handleOnItemSelect(item: string) {
    if (item === 'logout') {
      await deleteUserSession();
      window.localStorage.removeItem('ajs_user_id');
      window.localStorage.removeItem('ajs_user_traits');
      window.location.assign('/users/sign_in');
    }
  }
  
  return (
    <header className='tablet:px-12 flex justify-between px-4 py-4'>
      <PublicAssetLogoSVG />
      <Menu
        className='w-56 py-1 bg-white border border-gray-200 rounded-md shadow-lg'
        onItemSelect={handleOnItemSelect}
        renderTrigger={({ isOpen }) => (
          <button type='button' className='flex' aria-label='Open user menu'>
            <Text h='400' className='cursor-pointer'>
              {whoami?.user.email}
            </Text>
            <ChevronDownSVG className={cn('w-4 h-4 ml-2 transform', { ['rotate-180']: isOpen })} />
          </button>
        )}
      >
        <Item value='logout'>
          <Text h='400' className='px-4 py-2'>
            Logout
          </Text>
        </Item>
      </Menu>
    </header>
  );
};
