import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Modal, ModalHeading } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';
import { api } from '@api/reduxApi';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  id: number;
  title: string;
}

export const DeleteSegmentModal: React.FC<Props> = ({ open, onClose, onSubmit, title, id }) => {
  const [destroySegment, { isSuccess, isError }] = api.useDestroySegmentMutation();

  const [deleting, setDeleting] = useState(false);

  const showToast = useToaster();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDelete(title));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedDelete());
    }
  }, [isError]);

  if (!open) {
    return null;
  }

  const handleClick = async () => {
    setDeleting(true);
    await destroySegment({ id });
    setDeleting(false);
    onClose();
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleClose = (e) => {
    onClose?.();
  };

  return (
    <Modal onClose={deleting ? undefined : onClose}>
      <ModalHeading className='mb-2 text-left'>Delete a segment</ModalHeading>
      <p className='pb-12 text-gray-700 text-left'>
        You are about to delete the segment "{title}". Candidate records will not be deleted.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={deleting} onClick={handleClose}>
          Don’t delete
        </Button>
        <Button disabled={deleting} onClick={handleClick} danger>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
