import React, { SVGProps } from 'react';

export const ErrorSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 9.625C6.95674 9.625 6.91444 9.63783 6.87847 9.66187C6.8425 9.6859 6.81446 9.72007 6.7979 9.76004C6.78134 9.80001 6.77701 9.84399 6.78545 9.88643C6.79389 9.92886 6.81473 9.96784 6.84532 9.99843C6.87591 10.029 6.91489 10.0499 6.95732 10.0583C6.99976 10.0667 7.04374 10.0624 7.08371 10.0458C7.12368 10.0293 7.15785 10.0013 7.18188 9.96528C7.20592 9.92931 7.21875 9.88701 7.21875 9.84375C7.21875 9.78573 7.1957 9.73009 7.15468 9.68907C7.11366 9.64805 7.05802 9.625 7 9.625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7 7.875V3.5' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' />
      <path
        d='M0.746635 7.74731C0.548686 7.54912 0.4375 7.28046 0.4375 7.00035C0.4375 6.72024 0.548686 6.45158 0.746635 6.25339L6.2533 0.747311C6.35127 0.649104 6.46765 0.571189 6.59577 0.518027C6.7239 0.464865 6.86125 0.4375 6.99997 0.4375C7.13868 0.4375 7.27604 0.464865 7.40416 0.518027C7.53229 0.571189 7.64867 0.649104 7.74663 0.747311L13.2533 6.25339C13.4512 6.45158 13.5624 6.72024 13.5624 7.00035C13.5624 7.28046 13.4512 7.54912 13.2533 7.74731L7.74663 13.2534C7.54855 13.4513 7.27998 13.5625 6.99997 13.5625C6.71995 13.5625 6.45139 13.4513 6.2533 13.2534L0.746635 7.74731Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
