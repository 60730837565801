import * as React from 'react';

import { Alert, Button, Modal, Text, TippyOrNot } from '@components/common';
import { Error } from '@components/shared/AI';
import { moneyFormat } from '@components/utils';
import { PaymentMethodSelect } from '@components/Wallet';
import { StudyFunder } from '@components/Wallet';

import { RocketSVG } from './RocketSVG';

interface Props {
  study: Study;
  funder: StudyFunder;
  saving: boolean;
  onClose: () => void;
  onSubmit: (delayFunding: boolean) => void;
  setError: (value: 'funding' | 'external_incentive' | 'external_credit' | 'not_selected' | null) => void;
  error: 'funding' | 'external_incentive' | 'external_credit' | 'not_selected' | null;
}

// TODO: I think this is wrong and should actually be FundMode.
const titles: Record<AllocationMethod, string> = {
  none: 'Create study',
  wallet: 'Create & allocate funding',
  card: 'Create & fund',
  external_incentive: 'Create & allocate incentives',
  external_credit: 'Create & allocate credits',
  request: 'Create & request approval'
};

const ERROR_MESSAGES: Record<'funding' | 'external_incentive' | 'external_credit' | 'not_selected', string> = {
  funding: 'Your payment method has insufficient funds. Please add funds or change your payment method.',
  external_incentive: 'Your recruitment incentive credits cannot fund recruitment fees.  Please submit the recruitment request after creating the study.',
  external_credit: 'Your recruitment fee credits cannot fund incentives.  Please submit the recruitment request after creating the study.',
  not_selected: 'Please select the payment method.'
};

const ctas: Record<AllocationMethod, (amount: string) => string> = {
  none: () => 'Create study',
  wallet: (amount) => `Create & Allocate ${amount}`,
  card: (amount) => `Create & Fund ${amount}`,
  external_incentive: (amount) => `Create & Allocate ${amount}`,
  external_credit: (amount) => `Create & Allocate ${amount}`,
  request: (amount) => `Create & Request ${amount}`
};

const PublishModal: React.FC<Props> = ({ study, error, setError, onClose, onSubmit, saving, funder }) => {
  const fundMethod = funder.mode === 'fund' ? funder.selectedMethod?.kind || 'card' : funder.mode;

  return (
    <Modal onClose={onClose}>
      <div className='py-4 text-center'>
        <RocketSVG className='mx-auto mb-4' />
        <Text h='600' bold className='mb-4'>
          {titles[funder.mode] || 'Create study'}
        </Text>
        <Text className='mb-8'>
          {funder.mode === 'request'
            ? 'Your account administrator needs to approve incentives funding.  When you create, we’ll send the request.'
            : "Once your study is created, you can begin inviting people to participate. You can always make changes to the study even after it's created."}
        </Text>
        {funder.mode === 'fund' && funder.methods.length === 0 && (
          <Alert className='my-6 text-left' type='error' heading='No payment method found'>
            Please add a payment method in the billing section in your{' '}
            <a href='/subscription' target='_blank'>
              account settings.
            </a>
          </Alert>
        )}
        {funder.mode === 'fund' && funder.methods.length > 0 && (
          <div className='my-6 text-left'>
            <Text bold mb='2'>
              Funding method
            </Text>
            <PaymentMethodSelect
              isError={error}
              disabled={saving}
              selected={funder.selectedMethod}
              methods={funder.methods}
              onSelect={(v) => {
                if (error) setError(null);
                funder.selectMethod(v);
              }}
              allowExternalIncentive
            />
            {error && (
              <Error aria-label='Error message' className='mt-4'>
                {ERROR_MESSAGES[error]}
              </Error>
            )}
          </div>
        )}
        <Button
          onClick={() => {
            if (!funder.selectedMethod && funder.mode === 'fund') {
              setError('not_selected');
              return;
            }
            onSubmit(false);
          }}
          primary
          disabled={saving || (funder.mode === 'fund' && !funder.methods.length)}
        >
          {ctas[fundMethod](funder.displayTotal)}
        </Button>
        {study.could_delay_funding && (
          <div className='mt-3'>
            <TippyOrNot show={!study.can_delay_funding} content='In order to fund later, your screener must be set to manual review.'>
              <Button
                onClick={() => onSubmit(true)}
                disabled={saving || !study.can_delay_funding}
              >
                Create and fund later
              </Button>
            </TippyOrNot>
          </div>
        )}
        {!funder.isUSD && fundMethod === 'wallet' && funder.allocation && (
          <p className='h400 mt-6 text-gray-700'>
            <span className='font-bold'>${moneyFormat(funder.allocation.usd_amount_in_cents / 100)}</span> USD will be
            allocated from your wallet based on the current USD/{funder.currency} exchange rate of{' '}
            <span className='font-bold'>{funder.allocation.exchange_rate}</span>.
          </p>
        )}
        {!funder.isUSD && funder.selectedMethod?.kind === 'card' && !!funder.allocation?.usd_amount_in_cents && (
          <Text h='400' className='mt-6'>
            The {funder.selectedMethod?.brand} card ending in {funder.selectedMethod?.last4} will be charged{' '}
            <span className='font-bold'>${moneyFormat(funder.allocation.usd_amount_in_cents / 100)}</span> USD based on
            the current USD/{funder.currency} exchange rate of{' '}
            <span className='font-bold'>{funder.allocation.exchange_rate}</span>.
          </Text>
        )}
      </div>
    </Modal>
  );
};
export { PublishModal };
