import React, { SVGProps } from 'react';

export const IncentivesRecommendedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width={256} height={100} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x='0.5' y='15.5' width='69' height='69' rx='3.5' fill='white' stroke='#E5E7EB' />
    <g clipPath='url(#clip0_535_1542)'>
      <path
        d='M43.1964 55.5612C43.2138 53.1877 42.2756 51.3107 40.4042 49.9851C38.9588 48.9645 37.2047 48.4321 35.5082 47.9206C31.9741 46.8492 30.9489 46.2848 30.9489 44.5948C30.9489 42.7122 33.3954 42.0424 35.4898 42.0424C37.0151 42.0424 38.7699 42.5166 39.8598 43.2195L42.0203 39.8733C40.596 38.95 38.6467 38.3201 36.7217 38.1247V35H32.7361V38.3438C29.1933 39.1231 26.9614 41.4387 26.9614 44.5948C26.9614 46.8051 27.8799 48.558 29.6862 49.7943C31.06 50.7382 32.7354 51.245 34.3539 51.7357C37.8204 52.7835 39.2281 53.422 39.2121 55.5365L39.212 55.5521C39.212 57.3268 36.8601 57.9577 34.8451 57.9577C32.9418 57.9577 30.872 57.1235 29.6987 55.8833L26.8033 58.6221C28.2891 60.1936 30.4478 61.3046 32.736 61.7401V65.0001H36.7217V61.8103C40.6586 61.2468 43.1925 58.8766 43.1964 55.5612Z'
        fill='#E5E7EB'
      />
    </g>
    <rect x='78.5' y='0.5' width='99' height='99' rx='3.5' fill='white' stroke='#9FA6B2' />
    <path d='M151.499 40.1294H104.848V79.8643H151.499V40.1294Z' fill='white' />
    <path
      d='M151.635 80.0001H104.712V39.9939H151.635V80.0001ZM104.984 79.7288H151.364V40.2651H104.984V79.7288Z'
      fill='#374151'
    />
    <path d='M134.005 47.8594H122.342V79.8643H134.005V47.8594Z' fill='#5850EC' />
    <path d='M134.005 47.8594H122.342V79.8643H134.005V47.8594Z' fill='#5850EC' />
    <path opacity='0.2' d='M104.983 43.0454V49.492L151.499 52.4704L151.362 44.5157L104.983 43.0454Z' fill='#374151' />
    <path d='M154.212 36.4678H102.136V47.8594H154.212V36.4678Z' fill='white' />
    <path
      d='M154.347 47.9951H102V36.3323H154.347V47.9951ZM102.271 47.7239H154.076V36.6035H102.271L102.271 47.7239Z'
      fill='#374151'
    />
    <path d='M134.005 35.3831H122.342V47.7239H134.005V35.3831Z' fill='#5850EC' />
    <path
      d='M126.925 24.6228C130.004 29.1864 127.103 36.5264 127.103 36.5264C127.103 36.5264 119.211 36.4672 116.132 31.9036C113.054 27.3399 115.954 20 115.954 20C115.954 20 123.846 20.0592 126.925 24.6228Z'
      fill='#5850EC'
    />
    <path
      d='M128.337 24.6228C125.259 29.1864 128.159 36.5264 128.159 36.5264C128.159 36.5264 136.051 36.4672 139.13 31.9036C142.209 27.3399 139.308 20 139.308 20C139.308 20 131.416 20.0592 128.337 24.6228Z'
      fill='#5850EC'
    />
    <path
      d='M120.037 28.0597C120.711 28.0597 121.257 27.5133 121.257 26.8392C121.257 26.1651 120.711 25.6187 120.037 25.6187C119.363 25.6187 118.816 26.1651 118.816 26.8392C118.816 27.5133 119.363 28.0597 120.037 28.0597Z'
      fill='#374151'
    />
    <path
      d='M127.36 40.4005C128.034 40.4005 128.58 39.8541 128.58 39.18C128.58 38.5059 128.034 37.9595 127.36 37.9595C126.686 37.9595 126.139 38.5059 126.139 39.18C126.139 39.8541 126.686 40.4005 127.36 40.4005Z'
      fill='#374151'
    />
    <path
      d='M127.36 67.1166C128.034 67.1166 128.58 66.5701 128.58 65.8961C128.58 65.222 128.034 64.6755 127.36 64.6755C126.686 64.6755 126.139 65.222 126.139 65.8961C126.139 66.5701 126.686 67.1166 127.36 67.1166Z'
      fill='#374151'
    />
    <path
      d='M123.292 32.1281C123.741 32.1281 124.105 31.7638 124.105 31.3144C124.105 30.865 123.741 30.5007 123.292 30.5007C122.842 30.5007 122.478 30.865 122.478 31.3144C122.478 31.7638 122.842 32.1281 123.292 32.1281Z'
      fill='#374151'
    />
    <path
      d='M135.497 25.2118C135.946 25.2118 136.31 24.8475 136.31 24.3982C136.31 23.9488 135.946 23.5845 135.497 23.5845C135.047 23.5845 134.683 23.9488 134.683 24.3982C134.683 24.8475 135.047 25.2118 135.497 25.2118Z'
      fill='#374151'
    />
    <path
      d='M130.208 44.3334C130.657 44.3334 131.021 43.9691 131.021 43.5197C131.021 43.0704 130.657 42.7061 130.208 42.7061C129.758 42.7061 129.394 43.0704 129.394 43.5197C129.394 43.9691 129.758 44.3334 130.208 44.3334Z'
      fill='#374151'
    />
    <path
      d='M125.326 54.9113C125.775 54.9113 126.139 54.547 126.139 54.0976C126.139 53.6482 125.775 53.2839 125.326 53.2839C124.876 53.2839 124.512 53.6482 124.512 54.0976C124.512 54.547 124.876 54.9113 125.326 54.9113Z'
      fill='#374151'
    />
    <path
      d='M129.53 58.5729C129.979 58.5729 130.343 58.2086 130.343 57.7592C130.343 57.3099 129.979 56.9456 129.53 56.9456C129.08 56.9456 128.716 57.3099 128.716 57.7592C128.716 58.2086 129.08 58.5729 129.53 58.5729Z'
      fill='#374151'
    />
    <path
      d='M128.58 73.8974C129.03 73.8974 129.394 73.5331 129.394 73.0837C129.394 72.6343 129.03 72.27 128.58 72.27C128.131 72.27 127.767 72.6343 127.767 73.0837C127.767 73.5331 128.131 73.8974 128.58 73.8974Z'
      fill='#374151'
    />
    <rect x='186.5' y='15.5' width='69' height='69' rx='3.5' fill='white' stroke='#E5E7EB' />
    <g clipPath='url(#clip1_535_1542)'>
      <path
        d='M229.196 55.5612C229.214 53.1877 228.276 51.3107 226.404 49.9851C224.959 48.9645 223.205 48.4321 221.508 47.9206C217.974 46.8492 216.949 46.2848 216.949 44.5948C216.949 42.7122 219.395 42.0424 221.49 42.0424C223.015 42.0424 224.77 42.5166 225.86 43.2195L228.02 39.8733C226.596 38.95 224.647 38.3201 222.722 38.1247V35H218.736V38.3438C215.193 39.1231 212.961 41.4387 212.961 44.5948C212.961 46.8051 213.88 48.558 215.686 49.7943C217.06 50.7382 218.735 51.245 220.354 51.7357C223.82 52.7835 225.228 53.422 225.212 55.5365L225.212 55.5521C225.212 57.3268 222.86 57.9577 220.845 57.9577C218.942 57.9577 216.872 57.1235 215.699 55.8833L212.803 58.6221C214.289 60.1936 216.448 61.3046 218.736 61.7401V65.0001H222.722V61.8103C226.659 61.2468 229.192 58.8766 229.196 55.5612Z'
        fill='#E5E7EB'
      />
    </g>
    <defs>
      <clipPath id='clip0_535_1542'>
        <rect width='30' height='30' fill='white' transform='translate(20 35)' />
      </clipPath>
      <clipPath id='clip1_535_1542'>
        <rect width='30' height='30' fill='white' transform='translate(206 35)' />
      </clipPath>
    </defs>
  </svg>
);
