import { Provider as CollectionView } from 'components/stores/view';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { EmailTemplatesIndex, EmailTemplatesShow } from './pages/emails';
import { InterviewTemplatesEdit, InterviewTemplatesIndex, InterviewTemplatesShow } from './pages/interviews';
import { SurveyTemplatesEdit, SurveyTemplatesIndex, SurveyTemplatesShow } from './pages/surveys';
import { TemplatesHome } from './TemplatesHome';

export const TemplatesApp: React.FC = () => {
  return (
    <Routes>
      <Route
        path='emails'
        element={
          <CollectionView scope='email_templates'>
            <EmailTemplatesIndex />
          </CollectionView>
        }
      />
      <Route path='emails/:id' element={<EmailTemplatesShow />} />

      <Route
        path='interviews'
        element={
          <CollectionView scope='interview_templates'>
            <InterviewTemplatesIndex />
          </CollectionView>
        }
      />
      <Route path='interviews/:id/edit' element={<InterviewTemplatesEdit />} />
      <Route path='interviews/:id' element={<InterviewTemplatesShow />} />

      <Route
        path='surveys'
        element={
          <CollectionView scope='survey_templates'>
            <SurveyTemplatesIndex />
          </CollectionView>
        }
      />
      <Route path='surveys/:id/edit' element={<SurveyTemplatesEdit />} />
      <Route path='surveys/:id' element={<SurveyTemplatesShow />} />

      <Route path='' element={<TemplatesHome />} />
    </Routes>
  );
};
