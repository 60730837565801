import Tippy from '@tippyjs/react';
import { Link } from 'react-router-dom';
import { track } from '@components/tracking';
import { FullPageViewSVG, LinkSVG, SolidKebabSVG } from '@components/svgs';
import copy from 'copy-to-clipboard';
import { Popper } from '@components/common';
import { mapReactChildren } from '@helpers/mapReactChildren';
import React, { ChangeEvent } from 'react';
import { useToaster } from '@stores/toaster';
import { AskAiButton } from '@components/AiChat/components/AskAiButton';
import { getContextObjFromUrl } from '@components/AiChat/utils/context';

export const ActionsBar = ({ title, setSelected, selected, copyLink, kind, actions, canSelect, page, linkTo }) => {
  const handleOnCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.checked);
  };

  const showToast = useToaster();

  const aiChatContext = kind === 'Repo::Session' && linkTo ? getContextObjFromUrl(linkTo) : null;

  return (
    <div className='flex z-20 top-2 right-2 left-2 items-center justify-between absolute flex'>
      {canSelect ? (
        <Tippy content='Select' arrow={false}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setSelected(!selected);
            }}
            className='bg-white w-6 h-6 flex items-center justify-center rounded-full flex-shrink-0'
          >
            <input
              id='checkbox'
              type='checkbox'
              checked={selected}
              onChange={handleOnCheckboxChange}
              aria-label='Select artifact'
              onClick={(e) => e.stopPropagation()}
              className='w-3 h-3 p-0'
            />
          </div>
        </Tippy>
      ) : (
        <div className='flex-1' />
      )}

      <div className='flex space-x-2'>
        {linkTo && (
          <Tippy content='View on full page' arrow={false}>
            <Link
              to={linkTo}
              className='p-1 flex-shrink-0 bg-white hover:bg-indigo-100 hover:text-indigo-600 focus:outline-none flex items-center text-gray-700 rounded-full'
              onClick={(e) => {
                e.stopPropagation();
                track('viewed_artifact_in_full_page', { page, kind });
              }}
            >
              <FullPageViewSVG />
            </Link>
          </Tippy>
        )}
        {copyLink && (
          <Tippy content='Copy link' arrow={false}>
            <button
              className='bg-white hover:bg-indigo-100 hover:text-indigo-600 focus:outline-none p-1 rounded-full'
              onClick={(e) => {
                e.stopPropagation();
                copy(copyLink);
                showToast({
                  heading: 'Success!',
                  text: 'Link copied to clipboard',
                  icon: 'success'
                });
                track('copied_link_to_artifact', { page, kind });
              }}
            >
              <LinkSVG />
            </button>
          </Tippy>
        )}
        {aiChatContext && (
          <AskAiButton
            pageContext={{ ...aiChatContext, context_name: title }}
            style='small'
            showWhenUnavailable={false}
          />
        )}
        {actions.length > 0 && (
          <Popper
            placement='bottom-end'
            zIndex={20}
            offset={[0, 8]}
            content={({ closePopper }) => (
              <ul className='bg-white border border-gray-200 rounded'>{mapReactChildren(actions, closePopper)}</ul>
            )}
            closeOnEscape
            closeOnClickOutside
          >
            <button
              aria-label='Artifact actions'
              className='bg-white hover:bg-indigo-100 hover:text-indigo-600 focus:outline-none p-1 rounded-full'
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <SolidKebabSVG className='w-4 h-4' />
            </button>
          </Popper>
        )}
      </div>
    </div>
  );
};
