import React, { SVGProps } from 'react';

export const GroupBySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3_7352)'>
      <path
        d='M0.875 0.874512H13.125V13.1245H0.875V0.874512Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 0.874512V13.1245'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.875 6.99951H13.125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3_7352'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
