import * as React from 'react';
import { Link } from 'react-router-dom';

import { PencilSVG } from '@components/svgs';

const ICONS: Record<Props['icon'], React.FC<any>> = {
  pencil: PencilSVG
};

interface Props {
  as?: 'Link' | 'a';
  className?: string;
  name?: string;
  href: string;
  icon: 'pencil';
}
export const IconButton: React.FC<Props> = ({ as = 'a', href, className = '', name, icon }) => {
  const Icon = ICONS[icon];

  const El = { Link }[as] || 'a';

  const theProps: Record<string, any> = {
    type: 'button',
    name,
    className: `${className} cursor-pointer inline-flex items-center justify-center h-10 w-10 border border-gray-200 rounded-full bg-white hover:text-indigo-600 hover:border-indigo-600 focus:ring-2 focus:ring-indigo-500`,
    children: <Icon className='text-gray-700' />
  };

  if (as === 'Link') {
    theProps.to = href;
  } else {
    theProps.href = href;
  }

  return React.createElement(El, theProps);
};
