import { Button, Popper, Text } from '@components/common';
import * as React from 'react';
import { Role } from '../TeamUserRow';
import { ChevronDownSVG } from '@components/svgs';

interface Props {
  disabled?: boolean;
  studyRole: Role;
}

export const MemberDropdown: React.FC<Props> = ({ children, disabled = false, studyRole }) => {
  return (
    <Popper
      zIndex={50}
      offset={[0, 8]}
      placement='bottom-end'
      renderOnBodyRoot={false}
      closeOnClickOutside
      closeOnEscape
      content={({ closePopper }) => (
        <div className='w-40 bg-white rounded shadow-lg overflow-hidden' onClick={closePopper}>
          {typeof children === 'function' ? children() : children}
        </div>
      )}
    >
      <Button className='w-32 justify-between m-1' aria-label={`Change role, currently ${studyRole}`} disabled={disabled}>
        <Text>{studyRole}</Text>
        <ChevronDownSVG className='ml-2' />
      </Button>
    </Popper>
  );
};
