import { track as baseTrack } from '@components/tracking';
import { pick } from '@components/utils';

export const track =
  (participation: Participation) =>
  (eventName: string, properties: Record<string, any>): void => {
    const analytics = (window as any).analytics;

    const baseProperties: any = {
      participation_id: participation.id,
      ...pick(participation, 'interview_at', 'status', 'style', 'project_id')
    };

    if (participation.interview_at) {
      const now = new Date();
      const msUntil = +participation.interview_at - +now;
      const hoursUntil = msUntil / 1000 / 60;

      baseProperties.hours_until_interview = hoursUntil;
      baseProperties.is_before_interview = hoursUntil > 0;
      baseProperties.is_during_interview = hoursUntil > -1 && hoursUntil <= 0;
      baseProperties.is_after_interview = hoursUntil <= -1;
    }

    baseTrack(eventName, {
      ...baseProperties,
      ...properties
    });
  };
