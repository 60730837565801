import { api } from '@api/reduxApi';

import { Job, Provider } from './types';

export interface StatusResponse {
  connected: boolean;
  provider: Provider | undefined;
  valid: boolean;
  jobs: Job[];
  latest_pull: string | undefined;
  latest_pull_task: BackgroundTask | undefined;
  latest_push: string | undefined;
  latest_push_task: BackgroundTask | undefined;
}
const integrationStatusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrationStatus: builder.query<StatusResponse, void>({
      query: () => '/integrations/status',
      transformResponse: (resp: any) => resp
    })
  })
});

const integrationsJobsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createIntegrationJob: builder.mutation<BackgroundTask, { provider: string; job: 'push' | 'pull' }>({
      query: ({ provider, job }) => ({
        url: '/users/integrations/jobs',
        method: 'POST',
        body: { provider, job },
        transformResponse: (resp: any) => resp.data
      })
    })
  })
});

export const { useGetIntegrationStatusQuery } = integrationStatusApi;
export const { useCreateIntegrationJobMutation } = integrationsJobsApi;
