import React from 'react';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';
import { AvatarFromId } from '@components/common';

export const LandingPagePreview: React.FC<{ brand: Brand; accountName: string }> = ({ brand, accountName }) => {
  const { text_color, logo, logo_alignment } = brand;

  const { first_name, name: userName, id, job_title } = useUser();

  return (
    <div className='relative flex flex-col items-center justify-center min-h-screen bg-white'>
      {logo && (
        <div
          className={`flex-0 tablet:pt-8 tablet:px-10 tablet:px-16 desktop:px-20 monitor:px-24 monitor:px-36 desktop:py-4 block logo-align-brand w-full pt-6 bg-white`}
        >
          <div className='inline-block'>
            <img alt={`${accountName} logo`} className='desktop:max-h-32 max-h-24 w-auto' src={logo} />
          </div>
        </div>
      )}
      <div className='tablet:bg-none pt-6 tablet:flex tablet:items-start justify-center flex-1 w-full mx-auto'>
        <main className='desktop:flex screener max-w-7xl desktop:max-w-none monitor:px-36 monitor:pt-20 tablet:px-16 desktop:px-20 monitor:px-24 w-full px-10 mb-10'>
          <div className='desktop:ml-0 desktop:w-8/12 screener-main w-full pl-0 mx-auto'>
            <h1 className='tablet:text-5xl desktop:text-5xl monitor:text-6xl monitor:leading-snug text-custom-brand text-4xl font-bold tracking-tight'>
              Help us build a better product experience at {accountName}.
            </h1>
            <p className='desktop:mt-6 desktop:mx-0 desktop:text-2xl monitor:text-3xl tablet:text-2xl tablet:max-w-3xl desktop:max-w-none tablet:max-w-md text-custom-brand mt-4 text-lg whitespace-pre-line'>
              We're doing customer research to better understand our customers, their needs, and where we can improve
              our product. Join us to learn about new products &amp; provide your input to help us build the future of{' '}
              {accountName}.
            </p>
            <div className='desktop:mt-12 mt-6'>
              <span className='tablet:w-auto inline-flex w-full'>
                <a
                  className='w-full tablet:w-auto cursor-pointer justify-center inline-flex items-center px-6 py-3 desktop:py-4 desktop:px-8 border border-transparent text-sm desktop:text-lg leading-5 font-semibold rounded-md btn-custom-brand'
                  href='#'
                >
                  Book a time
                </a>
              </span>
            </div>

            <div className='desktop:mt-14 desktop:pr-10 flex w-full mt-10 text-left'>
              <AvatarFromId size='3xl' userId={id} />
              <div className='bg-gray-50 flex-1 p-4 ml-4 border border-gray-200 rounded-lg'>
                <h3 style={{ color: text_color }} className='text-custom-brand text-lg leading-normal'>
                  {userName}
                </h3>
                <p className='text-custom-brand text-sm font-light leading-normal'>
                  {job_title} at {accountName}
                </p>
                <div className='text-custom-brand mt-2'>
                  <p>
                    Hey there! 👋
                    <br />
                    I'd love to have you involved in our next research project to help us build a better {accountName}.
                    It would be great if you'd consider signing up to participate.
                    <br />- {first_name}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='tablet:block tablet:p-10 desktop:pr-0 desktop:mr-0 hidden pt-10 mx-auto'>
            <div className='desktop:py-15 desktop:px-12 p-8 bg-white border border-gray-200 rounded-lg'>
              <h2 className='desktop:mb-8 desktop:text-2xl monitor:text-4xl mb-5 text-xl font-semibold'>
                About the study
              </h2>
              <ul className='border-b border-gray-200'>
                <li className='desktop:mb-6 monitor:mb-8 flex items-center mb-5'>
                  <div className='flex justify-center w-10'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='18'
                      viewBox='0 0 24 18'
                      fill='none'
                      role='img'
                      aria-labelledby='a9eudhbwwnkd8wbdzaoshfbqt2790mb8'
                      className='h-8 mr-4'
                    >
                      <title id='a9eudhbwwnkd8wbdzaoshfbqt2790mb8'>Icons/contact phone</title>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M2 0H22C23.1 0 24 0.9 24 2L23.99 16C23.99 17.1 23.1 18 22 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM11 6C11 4.34 9.66 3 8 3C6.34 3 5 4.34 5 6C5 7.66 6.34 9 8 9C9.66 9 11 7.66 11 6ZM2 15V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15H2ZM19.49 11H17.85C17.63 10.37 17.5 9.7 17.5 9C17.5 8.3 17.63 7.63 17.85 7H19.49L21 5L19.01 3.01C17.7 3.99 16.73 5.38 16.28 7C16.1 7.64 16 8.31 16 9C16 9.69 16.1 10.36 16.28 11C16.73 12.61 17.7 14.01 19.01 14.99L21 13L19.49 11Z'
                        fill='#9FA6B2'
                      ></path>
                    </svg>
                  </div>
                  <p className='desktop:text-base monitor:text-lg text-custom-brand text-sm font-normal leading-tight'>
                    Interviews
                  </p>
                </li>
                <li className='desktop:mb-6 monitor:mb-8 flex items-center mb-5'>
                  <div className='flex justify-center w-10'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='22'
                      viewBox='0 0 20 22'
                      fill='none'
                      role='img'
                      aria-labelledby='agppkqndlt411npmkeldnrz34jdpkehz'
                      className='h-8 mr-4'
                    >
                      <title id='agppkqndlt411npmkeldnrz34jdpkehz'>Icons/alarm</title>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.94456 2.44107L4.66336 0.905273L0.0560608 4.74887L1.33716 6.28467L5.94456 2.44107ZM15.3366 0.905873L19.944 4.74947L18.6628 6.28527L14.0554 2.44167L15.3366 0.905873ZM10.5001 7.09487H9.00006V13.0949L13.7501 15.9449L14.5001 14.7149L10.5001 12.3449V7.09487ZM10.0001 3.09487C5.03006 3.09487 1.00006 7.12487 1.00006 12.0949C1.00006 17.0649 5.03006 21.0949 10.0001 21.0949C14.9701 21.0949 19.0001 17.0649 19.0001 12.0949C19.0001 7.12487 14.9701 3.09487 10.0001 3.09487ZM3.00006 12.0949C3.00006 15.9549 6.14006 19.0949 10.0001 19.0949C13.8601 19.0949 17.0001 15.9549 17.0001 12.0949C17.0001 8.23487 13.8601 5.09487 10.0001 5.09487C6.14006 5.09487 3.00006 8.23487 3.00006 12.0949Z'
                        fill='#9FA6B2'
                      ></path>
                    </svg>
                  </div>
                  <p className='desktop:text-base monitor:text-lg text-custom-brand text-sm font-normal leading-tight'>
                    30 minutes
                  </p>
                </li>
              </ul>

              <h2 className='desktop:mb-6 monitor:mb-8 desktop:text-2xl monitor:text-4xl desktop:mt-10 mt-6 mb-5 text-xl font-semibold'>
                What you'll need
              </h2>
              <ul>
                <li className='desktop:mb-6 flex items-center mb-3'>
                  <div className='flex justify-center w-10'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='none'
                      role='img'
                      aria-labelledby='aafni4s9wbf0vnlhyxq7q4gom2ruxcb'
                      className='h-8 mr-4'
                    >
                      <title id='aafni4s9wbf0vnlhyxq7q4gom2ruxcb'>Icons/check box</title>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM13.58 4.58L14.99 6L6.99 14L2.99 10.01L4.41 8.6L6.99 11.17L13.58 4.58Z'
                        fill='#9FA6B2'
                      ></path>
                    </svg>
                  </div>
                  <p className='desktop:text-base monitor:text-lg text-custom-brand text-sm font-normal leading-tight'>
                    Laptop with working webcam
                  </p>
                </li>
                <li className='desktop:mb-6 flex items-center mb-3'>
                  <div className='flex justify-center w-10'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='18'
                      height='18'
                      viewBox='0 0 18 18'
                      fill='none'
                      role='img'
                      aria-labelledby='a2boqr6nw5gha2rs76fio2j2kf2yr7h5'
                      className='h-8 mr-4'
                    >
                      <title id='a2boqr6nw5gha2rs76fio2j2kf2yr7h5'>Icons/check box</title>
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM13.58 4.58L14.99 6L6.99 14L2.99 10.01L4.41 8.6L6.99 11.17L13.58 4.58Z'
                        fill='#9FA6B2'
                      ></path>
                    </svg>
                  </div>
                  <p className='desktop:text-base monitor:text-lg text-custom-brand text-sm font-normal leading-tight'>
                    Reliable internet connection
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </div>
      <div className='flex-0 monitor:px-36 flex justify-center w-full py-10 text-center bg-white'>
        <div className='inline-block'>
          <a
            href='/?utm_campaign=study_12146_screener_footer&utm_source=powered_by&utm_medium=gq.fyi'
            className='xx-powered-by flex flex-col justify-center text-center'
          >
            <span className='text-xs font-light text-gray-500 uppercase'>Powered by</span>
            <img
              className='w-auto h-6'
              alt='Great Question'
              src='/assets/logo-6ab7960ffc021e5e7be2ef08a23455e84957c18becac88029a7f18e4640bc361.png'
            />
          </a>
          <div></div>
        </div>
      </div>
    </div>
  );
};
