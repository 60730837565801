import { compact, uid } from '@components/utils';

import { UseTableFilters } from '../../hook/useTableFilters';
import { FilterDefinition, FilterState, Operator } from '../../types';
import { isFilterApplyable } from '../../utils/isFilterApplyable';

export const makeProxyFilterSet = (op: FilterOp, filters: FilterState<any>[]): ProxyFilterSet => ({
  op,
  filters: filters.filter(isFilterApplyable).map((f) => ({
    id: f.definition.id,
    name: f.definition.name,
    value: f.value,
    operator: f.operator,
    allowNotSet: f.allowNotSet,
    range: f.range
  }))
});

export const makeSegmentPayload = (hook: UseTableFilters<any>): ProxyFilterSet =>
  makeProxyFilterSet(hook.op, hook.filters);

const transformRange = (
  defType: string,
  range:
    | undefined
    | {
        min: number | string;
        max: number | string;
      }
) => {
  return range && defType === 'date'
    ? { min: new Date(range.min as string), max: new Date(range.max as string) }
    : range;
};

export const makeStateFromProxy = <T extends object>(
  definition: FilterDefinition<T>,
  segment: ProxyFilterState,
  filterMetas?: { [id: string]: any }
): FilterState<T> => ({
  id: uid(),
  definition,
  operator: segment.operator as Operator,
  value: definition.type === 'date' ? (segment.value ? new Date(segment.value as string) : null) : segment.value,
  range: transformRange(definition.type, segment.range),
  allowNotSet: segment.allowNotSet,
  meta: filterMetas?.[definition.id],
  period: segment?.period
});

export const makeStatesFromProxies = <T extends object>(
  definitions: FilterDefinition<T>[],
  proxies: ProxyFilterState[],
  filterMetas?: { [id: string]: any }
): FilterState<T>[] => {
  return compact(
    proxies.map((filter) => {
      const def = definitions.find((d) => d.id === filter.id);
      if (def) {
        return makeStateFromProxy(def, filter, filterMetas);
      }
    })
  );
};
