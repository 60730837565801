import cn from 'classnames';
import * as React from 'react';

interface Props {
  rates: number[];
  activeRate: number;
  className?: string;
  onChange: (rate: number) => void;
  orientation?: 'horizontal' | 'vertical';
}

export const PlaybackRates: React.FC<Props> = ({
  activeRate: initialActiveRate,
  rates,
  orientation = 'horizontal',
  className,
  onChange
}) => {
  const [activeRate, setActiveRate] = React.useState<number>(initialActiveRate);

  const formatPlaybackRate = (rate: number): string => `${rate.toString()}x`;

  React.useEffect(() => {
    if (activeRate && activeRate !== initialActiveRate) onChange?.(activeRate);
  }, [activeRate]);

  return (
    <div
      className={cn(
        'w-full bg-transparent flex justify-center overflow-hidden',
        { 'flex-row': orientation === 'horizontal', 'flex-col': orientation === 'vertical' },
        className
      )}
    >
      {rates.map((rate, index) => (
        <button
          data-testid={`speed-${rate}`}
          key={rate}
          className={cn(
            'focus:outline-none',
            'hover:bg-gray-50',
            'hover:text-gray-700',
            'h-8',
            'text-sm',
            'font-bold',
            'text-gray-400',
            'border-b',
            'border-gray-200',
            'flex-1',
            'max-w-20',
            'px-1',
            {
              'border-r': orientation === 'horizontal',
              'border-b': orientation === 'vertical',
              'text-gray-700': rate === activeRate,
              'bg-gray-50': rate === activeRate,
              'bg-white': rate !== activeRate,
              'rounded-l': index === 0 && orientation === 'horizontal',
              'rounded-r': index === rates.length - 1 && orientation === 'horizontal',
              'rounded-b': index === rates.length - 1 && orientation === 'vertical',
              'rounded-t': index === 0 && orientation === 'vertical'
            }
          )}
          onClick={() => setActiveRate(rate)}
        >
          {formatPlaybackRate(rate)}
        </button>
      ))}
    </div>
  );
};
