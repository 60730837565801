import React, { SVGProps } from 'react';

export const Sort0to9SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.8125 14.5625V1.4375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.1875 11.9375L5.8125 14.5625L8.4375 11.9375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9375 6.68633V1.875C11.9375 1.79375 11.9149 1.71411 11.8722 1.64499C11.8294 1.57588 11.7683 1.52002 11.6957 1.48369C11.623 1.44735 11.5416 1.43197 11.4607 1.43927C11.3798 1.44656 11.3025 1.47625 11.2375 1.525L10.1875 2.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0625 6.6875H12.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1875 10.625C10.1875 10.9731 10.3258 11.3069 10.5719 11.5531C10.8181 11.7992 11.1519 11.9375 11.5 11.9375C11.8481 11.9375 12.1819 11.7992 12.4281 11.5531C12.6742 11.3069 12.8125 10.9731 12.8125 10.625C12.8125 10.2769 12.6742 9.94306 12.4281 9.69692C12.1819 9.45078 11.8481 9.3125 11.5 9.3125C11.1519 9.3125 10.8181 9.45078 10.5719 9.69692C10.3258 9.94306 10.1875 10.2769 10.1875 10.625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.0625 14.5613C11.5266 14.5613 11.9717 14.377 12.2999 14.0488C12.6281 13.7206 12.8125 13.2755 12.8125 12.8113V10.625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
