import Hls from 'hls.js';

const DEFAULT_ERROR_MSG = 'Error loading video';

export class Player {
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  layers: VideoLayer[] = [];
  activeLayerIndex = 0;
  duration = 0;
  playing = false;
  onTimeUpdate: ((t: number) => void) | null;
  onPlay: (() => void) | null;
  onPause: (() => void) | null;
  onStop: (() => void) | null;
  onReady: (() => void) | null;
  onError: (() => void) | null;
  lock = false;
  _currentTime = 0;
  _volume = 1;
  _playbackRate = 1;

  get ready() {
    return !!this.layers.length && this.layers.every(({ ready }) => ready);
  }

  get activeLayer() {
    return this.layers[this.activeLayerIndex];
  }

  get errors(): ICanvasLayerError[] {
    return this.layers.reduce(
      (acc, layer) =>
        layer.errors.length
          ? [
              ...acc,
              {
                id: layer.id,
                name: layer.name,
                errors: layer.errors.join(', '),
                metadata: layer.metadata
              }
            ]
          : acc,
      []
    );
  }

  get width() {
    return this.canvas.width;
  }

  set width(w: number) {
    this.canvas.width = w;
  }

  get height() {
    return this.canvas.height;
  }

  set height(h: number) {
    this.canvas.height = h;
  }

  get currentTime() {
    return this._currentTime;
  }

  private set currentTime(t: number) {
    this._currentTime = t;
    if (this.onTimeUpdate) {
      this.onTimeUpdate(t);
    }
  }

  get volume() {
    return this._volume;
  }

  set volume(n: number) {
    this._volume = n;
    this.layers.forEach((l) => {
      l.video.volume = n;
    });
  }

  get playbackRate() {
    return this._playbackRate;
  }

  set playbackRate(r: number) {
    this._playbackRate = r;
    this.layers.forEach((layer) => {
      layer.video.playbackRate = this._playbackRate;
    });
  }

  set poster(src: string) {
    const layer = new ImageLayer(src, this.ctx);
    layer.onLoad = layer.render;
  }

  constructor(element: HTMLElement) {
    this.onTimeUpdate = null;
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    element.appendChild(this.canvas);
  }

  addLayer(layer: VideoLayer) {
    return new Promise<void>((resolve) => {
      layer.onEnd = this.onLayerEnd.bind(this);
      layer.onLoad = () => {
        this.duration += layer.duration;
        layer.video.volume = this.volume;
        layer.video.playbackRate = this.playbackRate;
        resolve();
      };
      layer.onSeek = () => {
        if (this.ready) {
          layer.render();
        }
      };
      layer.onError = () => {
        if (this.onError) {
          this.onError();
        }
      };
      this.layers.push(layer);
    });
  }

  onLayerEnd() {
    if (this.activeLayerIndex < this.layers.length - 1) {
      this.activeLayerIndex++;
      this.play();
    } else {
      this.stop();
    }
  }

  getFrame(t: number) {
    for (let i = 0; i < this.layers.length; i++) {
      const layer = this.layers[i];
      const previousLayers = this.layers.filter((_, li) => li < i);
      const duration = previousLayers.reduce((a, layer) => a + layer.duration, 0);
      if (t > layer.duration + duration) {
        continue;
      } else {
        return layer.getFrame(t - duration);
      }
    }
  }

  getPlayerCurrentTime() {
    const previousLayers = this.layers.filter((_, li) => li < this.activeLayerIndex);
    const duration = previousLayers.reduce((a, layer) => a + layer.duration, 0);
    return duration + this.activeLayer.currentTime;
  }

  loop() {
    if (this.playing) {
      const currentTime = this.getPlayerCurrentTime();
      if (currentTime < 0 || currentTime >= this.duration) {
        return;
      }
      this.currentTime = currentTime;
      this.activeLayer.render();
      requestAnimationFrame(this.loop.bind(this));
    }
  }

  async setTime(t: number) {
    const resume = this.playing;
    if (!this.lock) {
      let i = 0;
      let time = t;
      while (time - this.layers[i].duration > 0) {
        time -= this.layers[i].duration;
        ++i;
      }
      this.activeLayer.stop();
      this.activeLayerIndex = i;
      this.activeLayer.currentTime = time;
      this.currentTime = t;
      this.lock = true;
      if (resume) {
        await this.play();
      }
      this.lock = false;
    }
  }

  async play() {
    this.playing = true;
    await this.activeLayer.play();
    if (this.onPlay) {
      this.onPlay();
    }
    requestAnimationFrame(this.loop.bind(this));
  }

  pause() {
    this.playing = false;
    this.activeLayer.pause();
    if (this.onPause) {
      this.onPause();
    }
  }

  stop() {
    this.playing = false;
    this.currentTime = 0;
    this.activeLayer.stop();
    this.activeLayerIndex = 0;
    if (this.onStop) {
      this.onStop();
    }
  }

  mute() {
    this.volume = 0;
  }

  unmute() {
    this.volume = 1;
  }

  forward(t: number) {
    const duration = this.currentTime + t;
    if (duration >= this.duration) {
      return this.stop();
    }
    this.setTime(duration);
  }

  back(t: number) {
    const duration = this.currentTime - t;
    if (duration < 0) {
      return this.stop();
    }
    this.setTime(duration);
  }

  reset() {
    if (this.ready) {
      this.stop();
      this.layers = [];
      this.duration = 0;
      this.ctx.clearRect(0, 0, this.width, this.height);
    }
  }

  format(t: number) {
    return +(t / 1000).toFixed(2);
  }
}

class Layer {
  id = this.generateId();
  name = '';
  ctx: CanvasRenderingContext2D;
  ready = true;
  width = 0;
  height = 0;
  metadata: Record<string, any>;
  errors: string[] = [];
  onLoad: (() => void) | null = null;
  onError: (() => void) | null = null;

  constructor(ctx: CanvasRenderingContext2D) {
    this.ctx = ctx;
  }

  generateId() {
    return (Math.random() + 1).toString(36).substring(2);
  }

  drawScaled(src: HTMLVideoElement | HTMLImageElement, ctx: CanvasRenderingContext2D = this.ctx) {
    const ratio = Math.min(ctx.canvas.width / this.width, ctx.canvas.height / this.height);
    const width = this.width * ratio;
    const height = this.height * ratio;
    const x = ctx.canvas.width / 2 - width / 2;
    const y = ctx.canvas.height / 2 - height / 2;
    ctx.drawImage(src, x, y, width, height);
  }
}

export class ImageLayer extends Layer {
  src: string;
  image: HTMLImageElement;

  constructor(src: string, ctx: CanvasRenderingContext2D) {
    super(ctx);
    this.src = src;
    this.image = new Image();
    this.image.src = this.src;
    this.image.addEventListener('load', () => {
      this.width = this.image.width;
      this.height = this.image.height;
      if (this.onLoad) {
        this.onLoad();
      }
    });
  }

  render() {
    this.drawScaled(this.image);
  }
}

export class VideoLayer extends Layer {
  thumbCtx: CanvasRenderingContext2D | null;
  fileUrl: string;
  video: HTMLVideoElement;
  playing = false;
  thumbWidth = 426;
  thumbHeight = 240;
  onSeek: (() => void) | null = null;
  onEnd: (() => void) | null = null;

  get videoProps() {
    return {
      playsInline: true,
      crossOrigin: 'Anonymous',
      muted: false,
      hidden: true
    };
  }

  get style(): Partial<CSSStyleDeclaration> {
    return {
      display: 'none'
    };
  }

  get stream() {
    const ext = this.fileUrl.split(/[#?]/)[0].split('.').pop() || ''.trim();
    return ext === 'm3u8';
  }

  set currentTime(t) {
    this.video.currentTime = t / 1000;
  }

  get currentTime() {
    return this.video.currentTime * 1000;
  }

  get duration() {
    return this.video.duration * 1000;
  }

  constructor(fileUrl: string, ctx: CanvasRenderingContext2D) {
    super(ctx);
    this.thumbCtx = null;
    this.fileUrl = fileUrl;
    this.video = this.createVideo();
    this.video.addEventListener('loadedmetadata', () => {
      this.width = this.video.videoWidth;
      this.height = this.video.videoHeight;
      this.ready = true;
      if (this.onLoad) {
        this.onLoad();
      }
    });
    this.video.addEventListener('ended', () => {
      if (this.onEnd) {
        this.onEnd();
      }
    });
    this.video.addEventListener('seeked', () => {
      if (this.onSeek) {
        this.onSeek();
      }
    });
    this.video.addEventListener('error', (e) => {
      if (this.onError) {
        this.errors = [this.video.error?.message ?? DEFAULT_ERROR_MSG];
        this.onError();
      }
    });
  }

  createVideo() {
    const video = document.createElement('video');
    if (this.stream) {
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.fileUrl;
      } else if (Hls.isSupported()) {
        const hls = new Hls();
        hls.on(Hls.Events.ERROR, (_: unknown, d: Record<string, any>) => {
          if (d.type === 'networkError') {
            this.errors = [d.details ?? DEFAULT_ERROR_MSG];
            if (this.onError) {
              this.onError();
            }
          }
        });
        hls.loadSource(this.fileUrl);
        hls.attachMedia(video);
      }
    } else {
      video.src = this.fileUrl;
    }
    Object.assign(video, this.videoProps);
    Object.assign(video.style, this.style);
    document.body.appendChild(video);
    return video;
  }

  render() {
    this.drawScaled(this.video);
  }

  async getFrame(t: number) {
    return await new Promise<string>((resolve) => {
      const video = this.createVideo();
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      canvas.width = this.thumbWidth;
      canvas.height = this.thumbHeight;
      video.currentTime = t / 1000;
      video.addEventListener('seeked', () => {
        this.drawScaled(video, ctx);
        const image = canvas.toDataURL('image/jpeg');
        resolve(image);
      });
      video.remove();
      canvas.remove();
    });
  }

  play() {
    return this.video.play();
  }

  pause() {
    this.video.pause();
  }

  stop() {
    this.video.pause();
    this.video.currentTime = 0;
  }
}
