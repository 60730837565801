import React, { SVGProps } from 'react';

export const SortAzSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.8125 13.5615V0.436523'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.1875 10.9365L4.8125 13.5615L7.4375 10.9365'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.18753 8.31152H11.2875C11.3733 8.3115 11.4578 8.3325 11.5336 8.37269C11.6094 8.41288 11.6742 8.47103 11.7223 8.54206C11.7705 8.61309 11.8004 8.69483 11.8096 8.78013C11.8189 8.86543 11.807 8.95169 11.7752 9.03136L9.22486 12.8411C9.1929 12.9208 9.18097 13.0071 9.19013 13.0925C9.19928 13.1779 9.22924 13.2597 9.27738 13.3308C9.32551 13.4019 9.39035 13.4601 9.46622 13.5004C9.54208 13.5406 9.62666 13.5616 9.71252 13.5615H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8125 5.68633V1.75C11.8125 1.4019 11.6742 1.06806 11.4281 0.821922C11.1819 0.575781 10.8481 0.4375 10.5 0.4375C10.1519 0.4375 9.81806 0.575781 9.57192 0.821922C9.32578 1.06806 9.1875 1.4019 9.1875 1.75V5.6875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 3.93652H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
