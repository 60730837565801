import { useAccount } from 'hooks/useAccount';
import React from 'react';

import { ContinuousFundingInfo } from '../ContinuousFundingInfo';

interface Props {
  amount: string;
  study: Study;
}

export const RequestFundInfo: React.FC<Props> = ({ amount, study }) => {
  const {
    account: {
      owner: { name }
    }
  } = useAccount();
  return (
    <div className='py-6'>
      {study.continuous && <ContinuousFundingInfo study={study} />}
      <div className='flex flex-row items-center justify-between mb-2'>
        <span className='h500-bold block'>Incentives to request</span>
        <span data-testid='budget-amount' className='text-md block font-bold'>
          {amount}
        </span>
      </div>
      <span className='block text-sm text-gray-500'>
        Upon publish, we’ll send a request to {name} (your account administrator) to fund the incentives. Any unused
        incentives will be refunded after you close the study.
      </span>
    </div>
  );
};
