import React, { SVGProps } from 'react';

export const FullPageViewSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.8542 3.0625H8.04167C7.57754 3.0625 7.13242 3.24687 6.80423 3.57506C6.47605 3.90325 6.29167 4.34837 6.29167 4.8125V7'
      stroke='currentColor'
      strokeWidth='0.583333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2292 5.6875L12.8542 3.0625L10.2292 0.4375'
      stroke='currentColor'
      strokeWidth='0.583333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.1042 8.3125V12.6875C11.1042 12.9196 11.012 13.1421 10.8479 13.3062C10.6838 13.4703 10.4612 13.5625 10.2292 13.5625H2.35417C2.12211 13.5625 1.89955 13.4703 1.73545 13.3062C1.57136 13.1421 1.47917 12.9196 1.47917 12.6875V5.6875C1.47917 5.45544 1.57136 5.23288 1.73545 5.06878C1.89955 4.90469 2.12211 4.8125 2.35417 4.8125H3.66667'
      stroke='currentColor'
      strokeWidth='0.583333'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
