import { Mark, mergeAttributes } from '@tiptap/core';

interface TranscriptWordOptions {
  HTMLAttributes: Record<string, any>;
}

export interface TranscriptWordAttributes {
  start_ts: number;
  end_ts: number;
}

export const TranscriptWord = Mark.create<TranscriptWordOptions>({
  name: 'transcript_word',

  group: 'block',

  content: 'inline*',

  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },

  addAttributes() {
    return {
      start_ts: {
        default: 0,
        renderHTML: (attributes: TranscriptWordAttributes) => ({
          'data-start-time': attributes.start_ts
        })
      },
      end_ts: {
        default: 0,
        renderHTML: (attributes: TranscriptWordAttributes) => ({
          'data-end-time': attributes.end_ts
        })
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  }
});
