import cn from 'classnames';
import * as React from 'react';

import { getBackgroundColor, TAG_COLORS } from '@components/tags/colors';

type Props = {
  value: string;
  onChange: (value: string) => void;
};
export const ColorPicker: React.FC<Props> = ({ value, onChange }) => {
  const renderColor = (color: string, isSelected?: boolean) => (
    <button
      key={`${color}${isSelected}`}
      disabled={value === color}
      name={color}
      className={cn('border-gray-700 bg-green-50 w-6 h-6 ml-1 mt-1 rounded-sm flex items-center justify-center', {
        border: isSelected,
        'hover:border': !isSelected
      })}
      style={{ backgroundColor: getBackgroundColor(color, 0.2) }}
      onClick={value === color ? undefined : () => onChange(color)}
    >
      {isSelected && (
        <svg
          className='text-gray-700'
          width='10'
          height='8'
          viewBox='0 0 10 8'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1 4.6L3.28571 7L9 1'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    </button>
  );

  return (
    <div className='flex flex-wrap -m-1'>{Object.keys(TAG_COLORS).map((key) => renderColor(key, value === key))}</div>
  );
};
