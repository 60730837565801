import React, { SVGProps } from 'react';

export const ConnectionErrorSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='126' height='36' viewBox='0 0 126 36' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M31.3599 15.3394C33.7899 13.1592 37.4099 11.5117 40.6899 11.3933C41.3299 11.3736 41.8199 11.7682 42.0199 12.291C42.5099 12.3995 42.9299 12.7645 42.9199 13.3663C42.8899 15.438 42.5099 17.5393 42.2499 19.6011C42.0999 20.8343 42.0699 22.2055 41.0199 23.0539C38.8399 24.8198 34.3299 23.4781 32.2899 22.0871C29.9799 20.5186 29.0899 17.3814 31.3499 15.3492L31.3599 15.3394Z'
      fill='#7770F8'
    />
    <path
      d='M62.4504 8.5721C60.7404 9.15415 59.0904 9.92363 57.4404 10.6635C57.7204 9.93349 58.0004 9.21334 58.2804 8.48331C58.4204 8.1183 57.9104 7.73356 57.5904 7.96046C55.9704 9.08509 54.3304 10.1801 52.7804 11.3935C52.4404 11.6599 52.8804 12.2123 53.2404 11.9855C54.4604 11.2357 55.6504 10.4268 56.8304 9.61781C56.5904 10.2294 56.3504 10.8312 56.1104 11.4429C55.9404 11.8769 56.4804 12.1236 56.8104 11.9756C58.8004 11.1272 60.8604 10.3774 62.7704 9.37118C63.2004 9.14428 62.9104 8.42412 62.4304 8.58196L62.4504 8.5721Z'
      fill='#374151'
    />
    <path
      d='M66.1298 18.2609C65.1498 18.0932 64.1598 17.955 63.1798 17.8268L63.2098 17.8071C63.5798 17.5308 63.4398 17.0474 62.9898 16.9883C61.4598 16.7811 59.9198 16.5049 58.3798 16.4259C58.0798 16.4161 58.0298 16.86 58.3098 16.9389C59.4798 17.2645 60.6798 17.4618 61.8698 17.669C61.8398 17.6887 61.8098 17.7084 61.7898 17.7281C61.4998 17.9255 61.4698 18.4976 61.8998 18.5667C63.2698 18.7837 64.6398 19.0303 66.0098 19.1981C66.5498 19.2671 66.6798 18.3497 66.1398 18.2609H66.1298Z'
      fill='#374151'
    />
    <path
      d='M41.71 19.524C41.73 19.2774 41.5 18.9617 41.25 18.9222C37.41 18.3402 33.52 17.7582 29.62 17.9061C29.24 17.916 29.11 18.5276 29.52 18.5967C29.59 18.6066 29.65 18.6164 29.72 18.6263C29.72 18.7052 29.72 18.794 29.74 18.8828C30.5 21.8719 35.65 24.7131 38.79 23.7266C39.33 23.6378 39.77 23.4306 40.14 23.1248C40.49 23.0854 40.7 22.7894 40.75 22.454C41.31 21.645 41.6 20.5599 41.69 19.5142L41.71 19.524Z'
      fill='#5850EC'
    />
    <path
      d='M2.45944 18.2983C2.96944 19.265 4.91944 19.2946 5.15944 18.0023C5.20944 17.7458 5.24944 17.4893 5.29944 17.2328C5.36944 17.1046 5.43944 16.9763 5.49944 16.8481C11.2394 17.8839 16.9394 18.466 21.9494 18.9198C23.9694 19.0973 25.9894 19.2552 28.0094 19.3933C28.4894 21.6524 30.2094 23.4873 32.3394 24.4541C35.1994 25.7465 38.3194 25.4702 41.3294 24.9573C41.7394 25.9734 42.4294 26.802 43.5494 27.2953C48.2394 29.3374 50.1294 23.1815 50.7294 19.9655C51.4594 16.049 51.3794 10.8599 47.2094 8.78823C46.6394 8.50214 46.1194 8.5712 45.7394 8.81783C45.0294 8.87702 44.3194 9.17297 43.6594 9.70569C43.1894 10.0904 42.8394 10.5541 42.5694 11.0572C38.1594 9.4492 29.8194 11.4124 28.1594 16.3154C24.8994 16.0983 21.6494 15.832 18.3994 15.4768C14.7194 15.0723 10.6594 14.5692 6.53944 13.8096C6.69944 12.9415 6.74944 12.0437 6.68944 11.146C6.61944 10.1102 5.64944 9.57744 4.82944 9.75502C4.30944 9.77475 3.80944 10.0115 3.60944 10.485C2.68944 12.6356 2.16944 14.8356 1.99944 17.1736C1.95944 17.6768 2.14944 18.0516 2.44944 18.2884L2.45944 18.2983ZM44.2294 18.2983C44.3794 16.7297 44.1094 12.3397 46.0094 11.4913C47.6994 12.8724 48.2194 14.7567 48.1294 16.927C48.0894 17.8741 47.9594 19.6695 47.5794 21.2677C47.5794 21.1296 47.5394 21.0013 47.4794 20.8928C47.3894 20.4291 46.9894 20.0247 46.5394 19.8964C46.0794 19.7682 45.5994 19.9161 45.2594 20.2318C44.8694 20.587 44.7894 21.1888 44.9894 21.6426C45.1494 22.0174 45.5094 22.3331 45.9294 22.412C46.3494 22.6389 46.9194 22.4811 47.2394 22.1753C47.2894 22.1358 47.3294 22.0766 47.3694 22.0273C47.0794 22.9842 46.6694 23.7932 46.1194 24.1779C42.9694 26.3877 44.1394 19.2552 44.2294 18.2785V18.2983ZM30.7594 17.6669C31.1194 15.467 33.8694 14.3522 35.7794 13.78C37.8494 13.1585 39.7894 13.3262 41.8594 13.3361C41.7494 13.9674 41.6794 14.6087 41.6194 15.2203C41.4194 17.0158 40.7794 19.7583 40.8494 22.2246C38.8294 22.5896 36.7294 22.8658 34.7294 22.343C32.5994 21.7905 30.3694 20.1135 30.7694 17.6669H30.7594Z'
      fill='#374151'
    />
    <path
      d='M46.4103 13.9766C48.0203 13.9766 48.0303 16.4527 46.4103 16.4527C44.7903 16.4527 44.7903 13.9766 46.4103 13.9766Z'
      fill='#374151'
    />
    <path
      d='M81.7306 20.4413C81.8206 18.705 81.9506 16.9786 82.1806 15.2522C82.1306 14.976 82.1806 14.6997 82.2906 14.4433C82.2906 14.4038 82.3006 14.3643 82.3106 14.315C82.4306 13.5653 83.0506 13.3285 83.6406 13.4469C83.8106 13.4271 83.9906 13.4469 84.1706 13.4962C87.2106 14.4038 90.5306 15.6369 91.7606 18.8135C92.8006 21.5067 91.6006 24.2394 89.1106 25.66C87.4606 26.607 82.9906 28.1657 81.7906 25.8474C81.0506 24.4071 81.6506 21.9901 81.7406 20.4413H81.7306Z'
      fill='#7770F8'
    />
    <path
      d='M92.1711 20.7659C91.6711 19.8386 90.2911 20.0951 89.4011 20.0753C87.2811 20.0162 85.0811 19.7597 82.9411 19.8484C82.9311 19.8484 82.9211 19.8287 82.9011 19.8287C82.6711 19.7103 82.3611 19.6709 82.1311 19.8287C82.0911 19.8583 82.0611 19.8879 82.0211 19.9175C81.9411 19.9175 81.8611 19.9274 81.7811 19.9372C81.4611 19.9767 81.3011 20.3713 81.5011 20.5686C81.0311 21.4565 80.9211 22.7192 80.8611 23.5578C80.8211 24.1398 80.9011 24.7317 81.2111 25.1658C81.3011 25.5505 81.5011 25.9057 81.9011 26.1622C83.0811 26.9415 85.4411 26.2608 86.6911 25.9747C88.2311 25.6294 89.7311 25.0079 90.8811 23.903C91.5911 23.2223 92.7411 21.8215 92.1711 20.7758V20.7659Z'
      fill='#5850EC'
    />
    <path
      d='M71.8206 23.016C74.0306 23.3218 76.4206 23.7361 78.7106 23.7361C78.6406 24.6437 78.6706 25.5415 78.9806 26.3208C78.8906 26.9127 78.8106 27.5046 78.7206 28.0965C78.4406 29.9808 81.2606 30.8489 81.6806 28.9055C81.7306 28.6983 81.7606 28.4813 81.8006 28.2741C82.5306 28.3136 83.3206 28.2149 84.0806 28.1163C86.2706 27.84 88.7706 27.8006 90.8006 26.8535C92.8206 25.9163 93.9406 24.1011 94.1906 22.1182C96.4706 21.99 98.7406 21.842 101.021 21.6447C107.451 21.0824 113.961 20.2932 120.361 19.0206C120.471 19.2771 120.591 19.9874 120.721 20.2439C121.421 21.6546 123.571 20.6286 123.211 19.208C122.901 18.0143 122.661 16.1301 122.151 14.9364C122.001 14.4234 121.851 13.4467 121.701 12.9338C121.291 11.4934 118.851 11.7401 119.001 13.2988C119.081 14.1077 119.211 15.3606 119.401 16.1399C111.001 17.7776 102.481 18.695 93.9406 19.1488C93.7606 18.5569 93.5106 17.9847 93.1906 17.452C91.1306 14.1077 87.1506 12.7167 83.3606 12.2235C83.3606 11.8979 83.3706 11.5724 83.3806 11.2468C83.4006 9.27378 80.6006 9.41189 80.3706 11.2468C80.1706 12.8548 79.9606 14.4629 79.7606 16.0709C77.7806 15.1633 75.0406 15.0054 73.2306 15.3409C71.5706 15.6467 72.1306 17.9551 73.6006 18.0637C74.8306 18.1623 76.0306 18.1031 77.2506 18.33C77.9706 18.4681 78.6806 18.7049 79.3906 18.8627L79.1706 20.5891C79.1506 20.7568 79.1206 20.9246 79.1006 21.1021C76.8806 20.5793 74.4306 20.4214 72.2106 20.2143C70.6006 20.0663 70.2406 22.7792 71.8306 22.9962L71.8206 23.016ZM82.3406 25.2948C82.8806 21.8321 83.1506 18.3201 83.2806 14.818C85.8406 15.3803 88.4806 16.0413 90.2106 18.0932C91.2206 19.2869 91.6906 20.895 91.1706 22.3945C90.6306 23.963 89.1706 24.4267 87.6706 24.693C86.4506 24.9002 83.7406 25.6302 82.5106 25.354C82.4406 25.3343 82.3906 25.3146 82.3306 25.2948H82.3406Z'
      fill='#374151'
    />
  </svg>
);
