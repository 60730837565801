import cn from 'classnames';
import { useCombobox } from 'downshift';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';

import { Spinner } from '@components/common';
import { Error } from '@components/shared/AI';
import { UserNameTag } from './UserNameTag';
import { api } from 'api/reduxApi';
import { useDebouncedCallback } from 'use-debounce';
import { CaretDownSVG } from 'components/svgs';

type UserItem = { type: 'user'; user: TeamUser };
type EmailItem = { type: 'email'; email: string };
export type UserComboboxItem = UserItem | EmailItem;

const isEmail = (email: string) => email.includes('@');

const parseEmailList = (input: string): string[] => {
  return input
    .split(',')
    .map((s) => s.trim())
    .map((value) => {
      const match = value.match(/.*<(.+@.+)>.*/);
      return match?.[1] || value;
    });
};

type Props = {
  disabled?: boolean;
  placeholder?: string | React.ReactNode;
  onSelect: (items: UserComboboxItem[]) => void;
  accountDomain?: string | null;
  acceptRawEmails?: boolean;
  context?: UserSuggestionContext;
  showCurrentlySelected?: TeamUser;
  errorMessage?: string;
};
export const UserCombobox: React.FC<Props> = ({
  disabled,
  placeholder,
  onSelect,
  accountDomain,
  showCurrentlySelected,
  acceptRawEmails = false,
  errorMessage: parentErrorMessage,
  context
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [query, setQuery] = useState<string>();
  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 200);
  const { data: usersSuggestionsData = [], isFetching } = api.useGetUsersSuggestionsQuery({ query, context });

  const usersSuggestions: TeamUser[] = useMemo(() => {
    if (showCurrentlySelected) {
      return [
        showCurrentlySelected,
        ...usersSuggestionsData.filter((user) => user.email !== showCurrentlySelected.email)
      ];
    }
    return usersSuggestionsData;
  }, [usersSuggestionsData, showCurrentlySelected]);

  const {
    isOpen,
    openMenu,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    closeMenu,
    inputValue,
    setInputValue,
    reset
  } = useCombobox<TeamUser>({
    items: usersSuggestions,
    onSelectedItemChange: ({ selectedItem }) => {
      inputRef.current?.blur();
      reset();
      closeMenu();
      if (selectedItem) {
        onSelect([{ type: 'user', user: selectedItem }]);
      }
    },
    itemToString: (item) => item?.name || '',
    onInputValueChange: ({ inputValue }) => {
      callback(inputValue);
      if (errorMessage) {
        setErrorMessage(parentErrorMessage);
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen) {
        setInputValue('');
      }
    }
  });

  const inputProps = getInputProps({
    disabled,
    ref: inputRef,
    onFocus: openMenu,
    onKeyDown: (e) => {
      if (acceptRawEmails && e.key === 'Enter' && highlightedIndex === -1) {
        const values = parseEmailList(inputValue);
        const results: UserComboboxItem[] = [];
        const invalidEmails: string[] = [];

        for (const value of values) {
          const match = usersSuggestions.find((u) => u.email === value);
          if (match) {
            results.push({ type: 'user', user: match });
          } else if (value) {
            if (!isEmail(value)) {
              invalidEmails.push(value);
            } else if (accountDomain && !value.includes(accountDomain)) {
              invalidEmails.push(value);
            } else {
              results.push({ type: 'email', email: value });
            }
          }
        }
        closeMenu();
        setInputValue('');
        if (invalidEmails.length) {
          let msg = `These emails could not be added: ${invalidEmails.join(', ')}.`;
          if (accountDomain) {
            msg += ` You can only add emails matching the ${accountDomain} domain.`;
          }
          setErrorMessage(msg);
        }
        if (results.length) {
          onSelect(results);
        }
      }
    }
  });

  const wrapperClass = cn('relative', { 'w-full': true });

  const dropdownVisible = isOpen && !isFetching;

  const dropdownClass = cn('xx-user-combobox absolute z-40 overflow-y-auto w-full rounded-b-md', {
    'bg-white pt-2 pb-2 divide-y divide-y-gray-200 border border-gray-200 shadow-lg': dropdownVisible
  });

  const inputPlaceholder = typeof placeholder === 'string' ? placeholder : '';

  const errorMessageToDisplay = parentErrorMessage || errorMessage;

  return (
    <div ref={ref} className={wrapperClass}>
      <div {...getComboboxProps()}>
        <input
          {...inputProps}
          className={cn(
            'border border-gray-200 h400 focus:border-indigo-600 rounded-md w-full py-2.5 px-4 text-gray-700 placeholder-gray-400 focus:outline-none',
            { 'border-red-600': !!errorMessageToDisplay }
          )}
          placeholder={inputPlaceholder}
          autoComplete='off'
          aria-label='Select sender'
          name='dropdown_combobox'
        />
        {!!placeholder && typeof placeholder !== 'string' && !isOpen && (
          <div className='absolute inset-0 pointer-events-none flex items-center justify-between'>
            {placeholder}
            <CaretDownSVG className='mr-2' />
          </div>
        )}
      </div>
      <ul {...getMenuProps()} className={dropdownClass}>
        {isOpen && isFetching && (
          <li className='group h-52 flex items-center bg-white justify-center w-full'>
            <Spinner className='text-indigo-600 w-4 h-4' />
          </li>
        )}
        {dropdownVisible &&
          usersSuggestions.map((user, index) => (
            <UserNameTag
              key={index}
              user={user}
              index={index}
              highlightedIndex={highlightedIndex}
              getItemProps={getItemProps}
              showCurrentlySelected={showCurrentlySelected}
            />
          ))}
      </ul>
      {errorMessageToDisplay && <Error className='mt-3'>{errorMessageToDisplay}</Error>}
    </div>
  );
};
