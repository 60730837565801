export class VideoUrl  {
  value: string;

  constructor(value) {
    this.value = value;
  }

  isSupported() {
    return this.supportedProviders().includes(this.domain());
  }

  toString() {
    return this.value;
  }

  private

  domain() {
    try {
      return new URL(this.value).hostname.split('.').slice(-2).join('.');
    } catch (e) {
      return '';
    }
  }

  supportedProviders() {
    return ['zoom.us', 'webex.com']
  }
}


