import { Text } from 'components/common';
import { Tooltip } from '@components/shared/Tooltip';
import React from 'react';

export const LimitCell: React.FC<{ value: string }> = ({ value }) => {
  return (
    <Tooltip content="Doesn't match any segment" isDisabled={value !== 'No match'} width={200}>
      <div className='truncate px-2'>
        <Text h='400' truncate>
          {value}
        </Text>
      </div>
    </Tooltip>
  );
};
