import * as React from 'react';

import { Button, Modal } from '@components/common';

interface Props {
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmChangeModal: React.FC<Props> = ({ onCancel, onConfirm }) => {
  return (
    <Modal
      title='Confirm changing calendar'
      size='md'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={onConfirm}>
            Confirm
          </Button>
        </>
      )}
    >
      <ul className='ml-6 space-y-1 list-disc'>
        <li>All existing bookings will remain on the current calendar</li>
        <li>All new bookings will be added to the new calendar</li>
        <li>All reschedules will be added to the new calendar</li>
      </ul>
    </Modal>
  );
};
