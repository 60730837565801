import { api } from '@api/reduxApi';

export type BackfillBotId = 'interview:abstract' | 'highlight:title' | 'interview:chapters';

const backfillsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createBackfills: builder.mutation<void, BackfillBotId[]>({
      query: (backfills) => ({
        url: `backfill_ai_fields`,
        method: 'POST',
        body: { backfills }
      })
    }),
    updateAiSettings: builder.mutation<void, { ai_highlights?: boolean; ai_tags?: boolean }>({
      query: (config) => ({
        url: `ai/config`,
        method: 'PUT',
        body: { config }
      })
    }),
    fetchAiSettings: builder.query<{ auto_highlights: boolean; auto_tags: boolean }, void>({
      query: () => `ai/config`
    })
  })
});

export const { useCreateBackfillsMutation, useUpdateAiSettingsMutation, useFetchAiSettingsQuery } = backfillsApi;
