import cn from 'classnames';
import { ConnectionError } from 'components/shared/ConnectionError';
import {
  CandidateSubset,
  SelectSubsetDropdown
} from 'components/shared/GridTable/components/SelectSubsetDropdownServerSide';
import { useCollectionView } from 'components/stores/view';
import { CandidatesScreenerSlideOut } from 'components/StudyMessages/CandidatesScreenerSlideOut';
import { ProfileContextProvider } from 'hooks/useProfileContext';
import pluralize from 'pluralize';
import qs from 'qs';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { api } from '@api/reduxApi';
import { useGetBackgroundTasksQuery } from '@components/CandidatesApp/CandidatesIndex/api';
import { buildCandidatesColumns } from '@components/CandidatesApp/CandidatesIndex/buildCandidatesTable';
import { BulkActions } from '@components/CandidatesApp/CandidatesIndex/BulkActions';
import { Button, Loading, Option, SlideOut, Spinner, Text, Toggle, Tooltip } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { CORE_ATTRS } from '@components/config';
import { DashboardLayout } from '@components/layouts/DashboardLayout';
import { Actions, ProfileSlideout } from '@components/RepoSessionApp/ProfileSlideout';
import { AddCandidateSlideOut } from '@components/shared/AddCandidateSlideOut';
import { BackgroundTasks } from '@components/shared/BackgroundTasks';
import { GridTable } from '@components/shared/GridTable';
import { Paginator } from '@components/shared/Paginator';
import { UpdateCellData } from '@components/shared/GridTable/components/inputs/types';
import { UseRowSelect } from 'components/shared/GridTable/hooks/UseRowSelect';
import {
  getHiddenColumnNames,
  getSelectedString,
  updateAttrValues
} from '@components/shared/GridTable/components/utils';
import { IntegrationStatus } from '@components/shared/IntegrationStatus';
import { PageHeader } from '@components/shared/PageHeader/PageHeader';
import { SortDropdown } from '@components/shared/SortDropdown';
import {
  AnyAllToggle,
  buildCandidateFilterDefs,
  SegmentActions,
  SegmentSlideOut,
  TableFilters,
  useTableFilters
} from '@components/shared/TableFilters';
import { defaultEligibilityFilterState } from '@components/shared/TableFilters/hook/useTableFilters';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { makeStatesFromProxies } from '@components/shared/TableFilters/components/segments/utils';
import { ZDSPage } from '@components/shared/ZDS/components/ZDSPage';
import { track } from '@components/tracking';
import { compact, without } from '@components/utils';
import { ELocalStorageKeys } from '@constants/localStorageKeys';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';
import { useStudies } from '@hooks/useStudies';
import { useTeams } from '@hooks/useTeams';
import {
  useAbsoluteLayout,
  useColumnOrder,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useRowState,
  useTable
} from '@lib/react-table.production.min.js';
import { useToaster } from '@stores/toaster';

import { BulkEditSlideOut } from './BulkEditSlideOut';
import { CandidatesInviteSlideOut } from './CandidatesInviteSlideOut';
import { CandidatesLimitWidget } from './CandidatesLimitWidget';
import { PageHeader as Header } from './PageHeader';
import { PanelStudyModal } from './PanelStudyModal';
import ShortlistToStudy from './ShortlistToStudy';
import { TableSkeleton } from 'components/shared/GridTable/components';
import { FAILED_SUBSET } from './toasts';
import { CandidateSlideOut, FilterQuery, ServerFilterQuery } from './types';

import { StudySlideOut } from '@components/StudiesApp/types';
import { NewRecruitingStudySlideout } from '@components/StudiesApp/components/StudyNew/components/NewStudySlideout';
import { useColumnResizeObserver } from '@components/shared/GridTable/hooks/useColumnResizeObserver';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { useDisabledFeatures } from 'hooks/useDisabledFeatures';
import { failedAttrUpdate } from '@components/CandidateProfile';

interface Props {
  addCandidatesModal?: boolean;
  onAdd?: (selectedIds: number[], query?: FilterQuery) => void;
  studySegment?: StudyLimit | null;
  teamId?: number | null;
}

interface Context {
  state: Record<string, any>;
  setState: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  updateParticipation?: (participation: Participation) => void;
}

export const GridContext = React.createContext<Context>({} as Context);

export const TableStateProvider: React.FC<{ updateParticipation?: (participation: Participation) => void }> = ({
  children,
  updateParticipation
}) => {
  const [state, setState] = useState<Record<string, any>>({});

  return <GridContext.Provider value={{ state, setState, updateParticipation }}>{children}</GridContext.Provider>;
};

const PAGE_SIZE = 100;

const CandidatesListPage: React.FC<Props> = ({ addCandidatesModal, onAdd, studySegment, teamId }) => {
  useEffect(() => {
    const q = String(qs.parse(window.location.search, { ignoreQueryPrefix: true }).q || '');
    setQuery(q);
    setSearchQuery(q);
  }, []);

  const {
    candidateAttrs,
    setCandidateAttrs,
    isLoading: isLoadingCustomAttrs,
    create: createCustomAttr,
    isSuccess: attrsLoaded
  } = useCandidateAttrs();

  const canPII = usePermission('showPII')();

  const enableTeams = useFeature('teams');

  const { teams, findTeam } = useTeams({ skip: !enableTeams });

  const team = enableTeams && teamId ? findTeam(teamId) : undefined;

  const [loading, setLoading] = useState(false);

  const [updateCandidate] = api.useUpdateCandidateMutation();

  // Pagination + search state
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>(query);

  // Segment state
  const [segmentSlideoutOpen, setSegmentSlideoutOpen] = useState(false);
  const [newlyCreatedSegment, setNewlyCreatedSegment] = useState<CustomerSegment>();
  const { data: segments, isSuccess: segmentsLoaded, isLoading: isLoadingSegments } = api.useGetSegmentsQuery();

  const showSegments = !teamId;

  // Study segment state - when the modal is launched from a study "Add candidates via segment"
  const [segmentOn, setSegmentOn] = useState(!!studySegment);

  // Selected state
  const [showSelectAll, setShowSelectAll] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedSubset, setSelectedSubset] = useState<{ candidate_ids: number[] }>({ candidate_ids: [] });
  const [previewCandidate, setPreviewCandidate] = useState<Candidate>();
  const [createCandidateSubset, { isLoading: isLoadingSubset, data }] = api.useCreateCandidateSubsetMutation();

  // Slideout state
  const [slideOut, setSlideOut] = useState<CandidateSlideOut | StudySlideOut | null>();

  const definitions = useMemo(
    () =>
      buildCandidateFilterDefs({
        coreAttrs: CORE_ATTRS,
        customAttrs: candidateAttrs,
        segments: segments || [],
        enableTeams: enableTeams && !teamId,
        teams
      }),
    [candidateAttrs, segments, teams]
  );

  const onFilterChange = () => {
    setPage(1);
    gotoPage(0);
    setAllSelected(false);
    setSelectedIds([]);
  };

  const [columnsWidth, setColumnsWidth] = useLocalStorage<Record<string, number>>(
    ELocalStorageKeys.CANDIDATES_TABLE_COLUMNS_WIDTH
  );

  const viewHook = useCollectionView();

  const { view, setView } = viewHook;

  const eligibleFilter = defaultEligibilityFilterState<Candidate>(definitions);

  const defsLoaded = attrsLoaded && segmentsLoaded;

  const filtersHook = useTableFilters<Candidate>({
    trackKey: 'candidates',
    definitions,
    defaultFilters: view.filters === undefined && eligibleFilter && defsLoaded ? [eligibleFilter] : undefined,
    syncWithURL: defsLoaded && !addCandidatesModal,
    query: searchQuery,
    onChange: onFilterChange
  });

  // this needs for displaying the segment instead of filters after segment creation
  useEffect(() => {
    if (segments && newlyCreatedSegment) {
      const def = definitions.find((d) => d.id === `segment_${newlyCreatedSegment.id}`);
      if (def) {
        setNewlyCreatedSegment(undefined);
        filtersHook.replaceFilters([def]);
      }
    }
  }, [segments, newlyCreatedSegment]);

  const { filters } = filtersHook;

  const addFilter = (filter: string) => {
    const def = definitions.find((d) => d.id === filter);

    if (def) {
      filtersHook.addFilter(def);
    }
  };

  const urlEncodedFilters: string[] = useMemo(() => {
    const segmentFilters =
      studySegment && segmentOn
        ? makeStatesFromProxies(filtersHook.definitions, studySegment.filters.filters, filtersHook.filterMetas)
        : [];

    const encodedFilters = compact([...filters, ...segmentFilters].map((filter) => parse<Candidate>(filter) || ''));

    if (enableTeams && teamId) {
      encodedFilters.push(`team_ids includes_any ${teamId}`);
    }

    return encodedFilters;
  }, [filters, studySegment, segmentOn, filtersHook.definitions, teamId]);

  const { data: countData } = api.useGetCandidatesStatsQuery({ items: 1 });

  const queryArgs = {
    items: PAGE_SIZE,
    page,
    searchQuery: searchQuery,
    op: filtersHook.op,
    sort: view.sort.value,
    sortDesc: view.sort.desc,
    filters: urlEncodedFilters
  };

  const {
    data: fetchedData,
    refetch,
    isLoading,
    isFetching,
    isError,
    isUninitialized
  } = api.useGetServerSideCandidatesQuery(queryArgs, {
    skip: isLoadingSegments || isLoadingCustomAttrs
  });

  const {
    data: statsData,
    refetch: refetchStats,
    isLoading: isLoadingStats,
    isFetching: isFetchingStats
  } = api.useGetCandidatesStatsQuery(
    {
      items: 100,
      searchQuery: searchQuery,
      op: filtersHook.op,
      filters: urlEncodedFilters
    },
    {
      skip: isLoadingSegments || isLoadingCustomAttrs
    }
  );

  const refetchCandidatesAndStats = () => {
    refetch();
    refetchStats();
  };

  const candidatesFullyLoaded = !isFetchingStats && !isFetching && !isLoading && !isUninitialized && !isError;

  const { panelStudies, studies } = useStudies();

  const [panelStudiesModal, setPanelStudiesModal] = React.useState(false);
  const [backgroundTasks, setBackgroundTasks] = useState<BackgroundTask[]>([]);
  const canCreate = usePermission('canCreate')();

  const canUpdate = usePermission<Candidate>('updateCandidate');

  const showToast = useToaster();

  const candidates = useMemo(() => (fetchedData ? fetchedData.data : []), [fetchedData]);
  const candidatesCount = statsData ? statsData.count : 0;
  const candidatesCountText = isFetchingStats
    ? 'Counting candidates...'
    : `${candidatesCount.toLocaleString()} ${pluralize('candidate', candidatesCount)}`;
  const pageCount = Math.max(Math.ceil(candidatesCount / PAGE_SIZE), fetchedData?.meta?.pages || 0);

  useEffect(() => {
    if (allSelected) {
      setSelectedRowIds(candidates.reduce((a, v) => ({ ...a, [v.id]: true }), {}));
    }
  }, [candidates]);

  const refetchCandidate = () => {
    refetchCandidatesAndStats();
  };

  const handleClickCandidate = useCallback((candidate) => {
    setPreviewCandidate(candidate);

    setSlideOut('CandidateProfile');
  }, []);

  const { handleMouseOver, handleToggleCheckBox, resetOnClick, hoveredRows } = UseRowSelect();

  const toggleColVisibility = React.useCallback(
    (name: string) => {
      const cols = view.columns?.includes(name)
        ? compact(without(view.columns, name))
        : [...(view.columns || []), name];
      setView({ columns: cols });
    },
    [view.columns]
  );

  const setSortValue = useCallback(
    (value: string, desc: boolean): void => {
      setPage(1);
      setView({
        sort: {
          ...view.sort,
          value: value,
          desc: desc
        }
      });
    },
    [view]
  );

  useEffect(() => {
    if (!allSelected) {
      setShowSelectAll(false);
    }
  }, [page, view.sort]);

  useEffect(() => {
    setSelectedRowIds({});
    toggleAllRowsSelected(false);
    setShowSelectAll(false);
    onFilterChange();
  }, [searchQuery, filters]);

  const allColumns = useMemo(() => {
    const builtColumns = buildCandidatesColumns({
      columnsWidth,
      customAttrs: candidateAttrs,
      canPII,
      enableTeams,
      teams: enableTeams ? teams : [],
      onClickCandidate: handleClickCandidate,
      handleToggleCheckBox: (e) => {
        setAllSelected(false);
        handleToggleCheckBox(e);
      },
      resetOnClick: () => {
        if (selectedSubset.candidate_ids.length) {
          setSelectedRowIds({});
          setSelectedSubset({ candidate_ids: [] });
        }
        resetOnClick();
      },
      handleMouseOver,
      hoveredRows,
      setSortValue,
      pageCount,
      setAllSelected,
      setShowSelectAll,
      addFilter,
      definitions
    });

    return builtColumns.map((c) => ({
      ...c,
      toggleColVisibility
    }));
  }, [
    pageCount,
    setSortValue,
    handleMouseOver,
    view.columns,
    toggleColVisibility,
    handleClickCandidate,
    handleToggleCheckBox,
    hoveredRows,
    candidateAttrs,
    selectedSubset,
    definitions,
    filters
  ]);

  const closeSlideOut = () => {
    setSlideOut(null);
  };

  const reset = () => {
    setSlideOut(null);
    setSelectedIds([]);
    setPreviewCandidate(undefined);
  };

  const appendBackgroundTask = (backgroundTask: BackgroundTask) => {
    setBackgroundTasks([...backgroundTasks, backgroundTask]);
    refetchCandidatesAndStats();
    toggleAllRowsSelected(false);
    setAllSelected(false);
    closeSlideOut();
  };

  const columnOptions: Option[] = useMemo(
    () =>
      [
        ...(canPII ? [{ name: 'email', label: 'Email' }] : []),
        ...CORE_ATTRS.filter((c) => !c.hide),
        { name: 'participations_count', label: '# of studies' },
        { name: 'last_contacted_at', label: 'Last invited' },
        { name: 'created_at', label: 'Join date' },
        ...candidateAttrs.map((a) => ({ name: `extra.${a.name}`, label: a.label }))
      ].map(({ name, label }) => ({ label, value: name })),
    [candidateAttrs]
  );

  // Pulling this out so that we filter out unsortable attributes.
  // Which is currently these new counts
  // Ideally this uses the new attribute definitions.
  const sortOptions: Option[] = useMemo(
    () =>
      [
        ...(canPII ? [{ name: 'email', label: 'Email' }] : []),
        ...CORE_ATTRS.filter((c) => !c.hide && c.canSort !== false),
        { name: 'participations_count', label: '# of studies' },
        { name: 'last_contacted_at', label: 'Last invited' },
        { name: 'created_at', label: 'Join date' },
        ...candidateAttrs.map((a) => ({ name: `extra.${a.name}`, label: a.label }))
      ].map(({ name, label }) => ({ label, value: name })),
    [candidateAttrs]
  );

  const hiddenColumns = useMemo(() => {
    return getHiddenColumnNames(columnOptions, view.columns || []);
  }, [columnOptions, view.columns]);

  const updateCandidateData: UpdateCellData = useCallback(
    async (rowIndex, columnId, value) => {
      if (candidates) {
        try {
          await updateCandidate({
            id: rowIndex,
            [columnId]: value,
            originalQueryArgs: queryArgs
          }).unwrap();

          if (value || value === 0) {
            updateAttrValues(candidateAttrs, columnId, value, setCandidateAttrs);
          }

          showToast({
            heading: 'Profile updated!',
            icon: 'success',
            text: 'The profile was updated.'
          });
        } catch (e) {
          showToast(failedAttrUpdate(e));
        }
      }
    },
    [candidates, candidateAttrs, setCandidateAttrs]
  );

  const getRowId = React.useCallback((row) => {
    return row.id;
  }, []);

  const tableInstance = useTable<Candidate>(
    {
      columns: allColumns,
      data: candidates,
      updateCellData: updateCandidateData,
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      defaultColumn: { disableFilters: true },
      manualPagination: true,
      manualSortBy: true,
      pageCount,
      getRowId,
      initialState: {
        pageIndex: page - 1,
        hiddenColumns,
        sortBy: [{ id: view.sort.value, desc: view.sort.desc }],
        selectedRowIds: {}
      }
    },
    useAbsoluteLayout,
    useResizeColumns,
    useColumnOrder,
    useRowState,
    usePagination,
    useRowSelect
  );

  const onResize = (columnId: string, columnSize: number) => {
    setColumnsWidth({ ...columnsWidth, [columnId]: columnSize });
  };

  useColumnResizeObserver(tableInstance.state, onResize);

  const {
    rows,
    selectedFlatRows,
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageOptions,
    toggleAllRowsSelected,
    totalColumnsWidth,
    setSelectedRowIds,
    state: { pageIndex, sortBy, selectedRowIds, columnOrder }
  } = tableInstance;

  const selectedIds = useMemo(() => Object.keys(selectedRowIds).map((str) => parseInt(str)), [selectedRowIds]);

  const setSelectedIds = (ids: number[]) => {
    setSelectedRowIds(ids.reduce((a, v) => ({ ...a, [v]: true }), {}));
  };

  useEffect(() => {
    if (selectedFlatRows.length > 0) {
      setPreviewCandidate(selectedFlatRows[0].original);
    }
  }, [selectedFlatRows]);

  const handleCreateSubset = async (subset: CandidateSubset) => {
    setLoading(true);
    createCandidateSubset({
      subset,
      query: {
        q: searchQuery,
        op: filtersHook.op,
        sort: view.sort.value,
        sort_desc: view.sort.desc,
        filters: urlEncodedFilters
      }
    })
      .unwrap()
      .then(({ candidate_ids }) => {
        setSelectedSubset({ candidate_ids });
        setSelectedIds(candidate_ids);
      })
      .catch((_) => {
        setSelectedSubset({ candidate_ids: [] });
        showToast(FAILED_SUBSET);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectedString = getSelectedString(allSelected, selectedIds.length);

  const onSortChange = ({ value }: { value: string }): void => {
    setSortValue(value, view.sort.desc);
  };

  const gridTableProps = React.useMemo(
    () => ({
      columnOptions,
      tableInstance,
      visibleColumnNames: view.columns,
      setVisibleColumnNames: (columns) => setView({ columns }),
      tableColumnsOrder: ELocalStorageKeys.CANDIDATES_TABLE_ORDER
    }),
    [columnOrder, selectedFlatRows, totalColumnsWidth, rows, selectedRowIds, columnOptions, view.columns, setView]
  );

  const Table = React.useMemo(
    () => (
      <TableStateProvider>
        <div className='px-page'>
          <GridTable {...gridTableProps} />
        </div>
      </TableStateProvider>
    ),
    [gridTableProps]
  );

  React.useEffect(() => setPage(pageIndex + 1), [pageIndex]);
  React.useEffect(() => {
    setView({
      sort: {
        value: sortBy[0].id,
        desc: sortBy[0].desc || false
      }
    });
  }, [sortBy]);

  const bulkQuery: ServerFilterQuery = useMemo<ServerFilterQuery>(() => {
    return {
      count: candidatesCount,
      op: filtersHook.op,
      q: searchQuery,
      sort: view.sort.value,
      sort_desc: view.sort.desc,
      filters: urlEncodedFilters
    };
  }, [fetchedData, filtersHook.op, searchQuery, view.sort, urlEncodedFilters]);

  const disabledFeatures = useDisabledFeatures();

  const slideoutActions = useMemo(() => {
    if (!canCreate) return {};

    const actions: Partial<Actions> = {
      invite: (id) => {
        setSlideOut('InviteSlideOut');
        setSelectedIds([id]);
      },
      shortlist: (id) => {
        setSlideOut('AddToStudyShortlist');
        setSelectedIds([id]);
      },
      sendScreener: (id) => {
        setSlideOut('ScreenerSlideOut');
        setSelectedIds([id]);
      }
    };

    if (panelStudies?.length) {
      actions.inviteToPanel = (id) => {
        setSlideOut('PanelInviteSlideOut');
        setSelectedIds([id]);
      };
    }

    return actions;
  }, [canCreate, panelStudies?.length]);

  function renderSlideOut() {
    switch (slideOut) {
      case 'InviteSlideOut':
        return studies ? (
          <CandidatesInviteSlideOut
            teamId={teamId}
            ids={selectedIds}
            studies={studies}
            onClose={closeSlideOut}
            onSuccess={reset}
            query={bulkQuery}
            allSelected={allSelected}
            selectedCount={allSelected ? candidatesCount : selectedIds.length}
            previewCandidate={previewCandidate}
          />
        ) : (
          <SlideOut title='Participation invite'>
            <Loading absolute />
          </SlideOut>
        );
        break;
      case 'PanelInviteSlideOut':
        return (
          <CandidatesInviteSlideOut
            teamId={teamId}
            ids={selectedIds}
            study={panelStudies?.[0]}
            studies={panelStudies}
            onClose={closeSlideOut}
            onSuccess={reset}
            query={bulkQuery}
            allSelected={allSelected}
            selectedCount={allSelected ? candidatesCount : selectedIds.length}
            previewCandidate={previewCandidate}
          />
        );
      case 'ScreenerSlideOut':
        return (
          <CandidatesScreenerSlideOut
            teamId={teamId}
            ids={selectedIds}
            onClose={closeSlideOut}
            onSuccess={reset}
            query={bulkQuery}
            allSelected={allSelected}
            selectedCount={allSelected ? candidatesCount : selectedIds ? selectedIds.length : 0}
            previewCandidate={previewCandidate}
          />
        );
      case 'AddToStudyShortlist':
        return (
          <SlideOut title='Shortlist Candidates' onClose={closeSlideOut}>
            <div className='px-4'>
              {!studies && <Loading absolute />}
              {studies && selectedIds.length > 0 && (
                <ShortlistToStudy
                  teamId={teamId}
                  studies={studies}
                  selectedCount={allSelected ? candidatesCount : selectedIds.length}
                  selectedIds={selectedIds}
                  allSelected={allSelected}
                  query={bulkQuery}
                  onFinished={appendBackgroundTask}
                  onClose={closeSlideOut}
                />
              )}
            </div>
          </SlideOut>
        );
      case 'BulkEdit':
        return (
          <BulkEditSlideOut
            onClose={closeSlideOut}
            onFinished={appendBackgroundTask}
            ids={selectedIds}
            allSelected={allSelected}
            query={bulkQuery}
            selectedCount={allSelected ? candidatesCount : selectedIds.length}
          />
        );
      case 'AddCandidate':
        return (
          <AddCandidateSlideOut
            style='addCandidate'
            onClose={closeSlideOut}
            onAdd={refetchCandidatesAndStats}
            team={team || null}
          />
        );
      case 'CandidateProfile':
        if (previewCandidate == null)
          return (
            <SlideOut title='Candidate Profile' onClose={closeSlideOut}>
              <Loading absolute />
            </SlideOut>
          );
        return (
          <ProfileContextProvider>
            <ProfileSlideout
              canUpdate={canUpdate(previewCandidate)}
              candidate={previewCandidate} // preview candidate?
              onChangeCandidate={refetchCandidate}
              setLoading={setLoading}
              createAttr={createCustomAttr}
              open
              onClose={closeSlideOut}
              actions={slideoutActions}
              showSections={{
                about: true,
                generalSections: true
              }}
            />
          </ProfileContextProvider>
        );
      case 'NewRecruitingStudySlideout':
        return <NewRecruitingStudySlideout teamId={teamId} isVisible onClose={closeSlideOut} />;
      default:
        return null;
        break;
    }
  }

  const renderFilters = () => (
    <>
      <div className='px-page flex items-center border-t border-gray-200'>
        <div className='flex-1'>
          <TableFilters<Candidate> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
        </div>
        {!showSegments && <div className='w-6 h-12' />}
        {showSegments && (
          <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
            <AnyAllToggle className='' value={filtersHook.op} onChange={filtersHook.setOp} />
            <SegmentActions
              anyFilters={filtersHook.filters.length > 0}
              onSave={() => setSegmentSlideoutOpen(true)}
              onClear={filtersHook.clearFilters}
            />
          </div>
        )}
      </div>
      <SegmentSlideOut
        open={segmentSlideoutOpen}
        hook={filtersHook}
        onClose={() => setSegmentSlideoutOpen(false)}
        onCreate={(segment) => {
          track('created_segment', {
            table: 'candidates',
            name: segment.name,
            op: segment.filters.op,
            count: segment.filters.filters.length
          });
          setNewlyCreatedSegment(segment);
        }}
      />
    </>
  );

  const renderHeaderButtons = () =>
    addCandidatesModal ? (
      <Button
        medium
        onClick={() => onAdd?.(selectedIds, allSelected ? bulkQuery : undefined)}
        className='my-6'
        primary
        loading={loading}
        disabled={loading || selectedIds.length === 0}
      >
        Shortlist to study
      </Button>
    ) : (
      <Header
        teamId={teamId}
        setSlideOut={setSlideOut}
        panelStudy={!!panelStudies?.length}
        openPanelStudyModal={() => setPanelStudiesModal(true)}
        selectedIds={selectedIds}
        allSelected={allSelected}
      />
    );

  const renderBackgroundTasks = () => (
    <div className='px-page'>
      <BackgroundTasks
        onFinished={refetchCandidatesAndStats}
        setBackgroundTasks={setBackgroundTasks}
        backgroundTasks={backgroundTasks}
        params={{
          actions: [
            'bulk_shortlist',
            'bulk_delete',
            'bulk_edit_candidates',
            'integration_sync_snowflake',
            'integration_sync_salesforce',
            'candidate_import'
          ] as BackgroundTaskAction[]
        }}
        backgroundTasksQuery={useGetBackgroundTasksQuery}
      />
    </div>
  );

  const renderPanelStudyModal = () =>
    panelStudiesModal &&
    !!panelStudies?.length && (
      <PanelStudyModal
        onClose={() => setPanelStudiesModal(false)}
        options={panelStudies.map((study) => ({
          label: study.title,
          value: study.public_path || ''
        }))}
        panelStudies={panelStudies}
      />
    );

  if (countData && countData.count === 0) {
    return (
      <>
        {renderSlideOut()}
        <ZDSPage
          types={compact(['candidates', !disabledFeatures.external_recruitment && 'external_recruitment'])}
          teamId={teamId}
          openPanelStudyModal={() => setPanelStudiesModal(true)}
          setSlideOut={setSlideOut}
          renderBackgroundTasks={renderBackgroundTasks}
        />
        {renderPanelStudyModal()}
      </>
    );
  }

  return (
    <DashboardLayout>
      {!addCandidatesModal && <PageTitle>Candidates</PageTitle>}
      {renderSlideOut()}
      <div className={cn('flex-1 flex flex-col', loading && 'h-full', 'relative')}>
        <PageHeader
          hideDefaultButtons={!!selectedIds.length}
          filtersApplied={!!filtersHook.filters.length}
          renderToggleSection={() => {
            if (!studySegment) {
              return null;
            }
            return (
              <div className='flex items-center space-x-2'>
                <Toggle
                  name='Show segment matches'
                  on={segmentOn}
                  onToggle={(v) => {
                    setSegmentOn(v);
                  }}
                />
                <Text h='400'>Segment matches only</Text>
                <Tooltip
                  content={`Filter the list of candidates to only those matching ${
                    studySegment.name ? studySegment.name : `Segment #${studySegment.order}`
                  }`}
                />
              </div>
            );
          }}
          renderSortBy={() => (
            <SortDropdown wrapperClass='w-full' options={sortOptions} value={view.sort.value} onChange={onSortChange} />
          )}
          searchProps={{
            placeholder: 'Search by name or email…',
            addDebounce: true,
            onSearch: setQuery,
            onEnd: setSearchQuery,
            value: query
          }}
          renderBulkActions={() => (
            <BulkActions
              selectedCount={allSelected ? candidatesCount : selectedIds.length}
              selectedIds={selectedIds}
              allSelected={allSelected}
              setSlideOut={setSlideOut}
              currentViewColumns={view.columns || []}
              addCandidatesModal={addCandidatesModal}
              onStartBulkDelete={(ids, task) => {
                appendBackgroundTask(task);
              }}
              resetSelection={() => {
                refetchCandidatesAndStats();
                toggleAllRowsSelected(false);
                setAllSelected(false);
              }}
              onEditTeams={() => {
                refetchCandidatesAndStats();
              }}
              query={bulkQuery}
              refetchCandidates={refetchCandidatesAndStats}
            />
          )}
          renderCta={renderHeaderButtons}
          renderFilters={renderFilters}
          h1={addCandidatesModal ? 'Add existing candidates' : 'Candidates'}
          team={team}
        />

        {renderBackgroundTasks()}

        <div className='px-page flex items-center justify-between py-4'>
          <div className='relative flex items-center'>
            <div className='flex items-center leading-4 text-gray-500'>
              {loading && <Spinner className='w-4 h-4 mr-2' />}
              <Text h='400' color='gray-500'>
                {candidatesCountText} {selectedString}
                {showSelectAll && (
                  <button
                    className='p-0 m-0 text-indigo-600'
                    onClick={() => {
                      setAllSelected(true);
                      setShowSelectAll(false);
                    }}
                  >
                    Select all {candidatesCount} instead
                  </button>
                )}
              </Text>
            </div>

            {candidatesFullyLoaded && (
              <SelectSubsetDropdown
                subsetButtonIsHidden={(fetchedData && candidatesCount === 0) || allSelected || selectedIds.length > 0}
                onSelect={handleCreateSubset}
              />
            )}
          </div>

          {!isFetchingStats && !isLoadingStats && (
            <div className='flex justify-end'>
              <div className='mr-2'>
                <IntegrationStatus />
              </div>
              <CandidatesLimitWidget />
              <Paginator
                onClickNext={() => nextPage()}
                onClickPrevious={() => previousPage()}
                canClickNext={canNextPage}
                canClickPrevious={canPreviousPage}
                current={pageIndex + 1}
                total={pageOptions.length}
              />
            </div>
          )}

          {(isFetchingStats || isLoadingStats) && (
            <ReactSkeleton duration={1} height={28} width={275} className='bg-gray-50 h-8 rounded-md' />
          )}
        </div>

        {candidatesFullyLoaded && Table}

        {isFetching && (
          <TableSkeleton data-testid='candidates-table-skeleton' className='px-page w-full min-h-screen' />
        )}

        {isError && <ConnectionError />}
      </div>
      {renderPanelStudyModal()}
    </DashboardLayout>
  );
};

export default CandidatesListPage;

export type { Props as CandidatesListPageProps };
