import * as React from 'react';

import { TagPill } from '@components/tags';
import { compact } from '@components/utils';
import { useTags } from '@stores/tags';

type Props = {
  ids: number[];
};
export const TagsValue: React.FC<Props> = ({ ids }) => {
  const { getTag } = useTags();

  const displayTags = compact(ids.map(getTag));

  return (
    <div className='flex space-x-1'>
      {displayTags.map((tag) => (
        <TagPill key={tag.id} tag={tag} count={undefined} size='sm' />
      ))}
    </div>
  );
};
