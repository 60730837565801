import * as React from 'react';
import { useEffect, useState } from 'react';
import tinytime from 'tinytime';

import { api } from '@api/reduxApi';
import { Button, SlideOut, Text } from '@components/common';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../toasts';
import { TimeSlotRadioButton } from './TimeSlotRadioButton';
import { getTimesGroupedByDay } from '../utils';

const timeFormat = tinytime('{h}:{mm} {a}');

type Props = {
  participation: Participation;
  onClose: () => void;
  onSubmit: (task: BackgroundTask) => void;
};

export const ReviewTimeProposalSlideout: React.FC<Props> = ({ participation, onClose, onSubmit }) => {
  const [selectedTime, setSelectedTime] = useState<string>('');

  const showToast = useToaster();

  const [accept, { isLoading, isError }] = api.useAcceptParticipationTimeProposalMutation();

  useEffect(() => {
    track('opened_review_time_proposal_slideout');
  }, []);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedAcceptTimeProposal());
    }
  }, [isError]);

  const handleClickAccept = () => {
    accept({ participationId: participation.id, time: selectedTime })
      .unwrap()
      .then((task) => onSubmit(task));
  };

  const timesGroupedByDay = getTimesGroupedByDay(participation.time_proposal?.times || []);

  return (
    <SlideOut
      title='Review proposed times'
      subtitle={`${
        participation.name || 'This candidate'
      } sent an alternate time proposal. If you accept one of the proposed times, the call will be scheduled and we’ll notify the participant.`}
      renderFooter={() => (
        <Button disabled={!selectedTime || isLoading} onClick={handleClickAccept} primary>
          Accept & schedule
        </Button>
      )}
      onClose={onClose}
    >
      <div className='px-6 pb-4'>
        {Object.keys(timesGroupedByDay).map((day, i) => (
          <div key={i}>
            <Text mb='2' bold>
              {day}
            </Text>
            {timesGroupedByDay[day].map(({ label, value, isPast }, i) => (
              <ul className='mb-2' key={i}>
                <li className='flex items-center space-x-2'>
                  <TimeSlotRadioButton
                    label={label}
                    value={value}
                    isPast={isPast}
                    onChange={() => setSelectedTime(value)}
                    checked={selectedTime === value}
                  />
                </li>
              </ul>
            ))}
          </div>
        ))}
      </div>
    </SlideOut>
  );
};
