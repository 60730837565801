import { Toast } from '@components/stores/toaster';

export const failedGet = (): Toast => ({
  heading: 'Something went wrong!',
  icon: 'error',
  text: 'We ran into a problem trying to load this highlight reel',
  action: {
    text: 'Back to Repository',
    href: '/repository'
  }
});

export const successCreateReel = (): Toast => ({
  heading: 'Highlight reel created',
  icon: 'success',
  text: 'The highlight reel was created'
});

export const successUpdateReel = (): Toast => ({
  heading: 'Highlight reel saved',
  icon: 'success',
  text: 'The highlight reel was saved'
});

export const failedUpdateReel = (): Toast => ({
  heading: 'Something went wrong',
  icon: 'error',
  text: 'We had a problem saving that highlight reel.'
});

export const successDeleteReel = (): Toast => ({
  heading: 'Highlight reel deleted',
  icon: 'success',
  text: 'The highlight reel was deleted.'
});

export const successDiscardReel = (): Toast => ({
  heading: 'Highlight reel discarded',
  icon: 'success',
  text: 'The highlight reel was discarded.'
});

export const failedDeleteReel = (): Toast => ({
  heading: 'Something went wrong',
  icon: 'error',
  text: 'We had a problem deleting that highlight reel.'
});

export const failedDownload = (): Toast => ({
  heading: 'Something went wrong',
  icon: 'error',
  text: 'We had a problem downloading the video.'
});
