import cn from 'classnames';
import * as React from 'react';

import { XSVG } from '@components/GQSurveyBuilder/questions/shared';
import { InfoCircleIcon } from '@components/svgs';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { AlertPosition, AlertType } from './consts';
import { AdvertisingMegaphoneIcon, CheckCircleIcon, AlertTriangleIcon } from './svgs';

export type AlertProps = {
  Icon?: React.FC<any>;
  className?: string;
  position?: AlertPosition;
  border?: boolean;
  heading?: string;
  bg?: string;
  type?: AlertType;
  cta?: React.ReactNode;
  noIcon?: boolean;
  dismissKey?: string;
};

const COLORS: Record<AlertType, string> = {
  info: 'bg-gray-50',
  warning: 'bg-yellow-50',
  error: 'bg-red-50',
  success: 'bg-green-50',
  promo: 'bg-indigo-50'
};

const ACCENT_COLORS: Record<AlertType, string> = {
  info: 'gray-700',
  warning: 'yellow-600',
  error: 'red-600',
  success: 'green-600',
  promo: 'indigo-600'
};

const ICONS: Record<AlertType, React.FC<{ className?: string }>> = {
  info: InfoCircleIcon,
  warning: AlertTriangleIcon,
  error: AlertTriangleIcon,
  success: CheckCircleIcon,
  promo: AdvertisingMegaphoneIcon
};

export const AlertHeading: React.FC = ({ children }) => <h3 className='h500-bold pb-2'>{children}</h3>;
export const AlertMessage: React.FC = ({ children }) => <div className='h400'>{children}</div>;
export const AlertLink: React.FC<{ href: string }> = ({ href, children }) => (
  <a className='ml-2 font-bold text-gray-700 underline' href={href}>
    {children}
  </a>
);

export const Alert: React.FC<AlertProps> = ({
  Icon: customIcon,
  noIcon = false,
  children,
  heading,
  cta,
  bg = '',
  border = false,
  position = 'center',
  type = 'info',
  className = '',
  dismissKey: dismissKey
}) => {
  const [dismissed, setDismissed] = useLocalStorage<'true'>(dismissKey || null);

  const Icon = customIcon || ICONS[type];
  const accentColor = ACCENT_COLORS[type];
  const color = COLORS[type];

  if (dismissed) {
    return null;
  }

  return (
    <div
      className={cn('rounded', className, bg ? `bg-${bg}` : color, 'flex border-l-4 relative', `border-${accentColor}`)}
    >
      {dismissKey && (
        <button aria-label='Close' name='Close' onClick={() => setDismissed('true')}>
          <XSVG className='top-2 right-2 absolute' />
        </button>
      )}
      <div className={border ? 'w-full rounded p-4 border-t border-b border-r border-gray-200' : 'p-4 w-full'}>
        <div
          className={cn('flex w-full', {
            'items-center': position === 'center',
            'items-start': position === 'top'
          })}
        >
          {!noIcon && (
            <div className='flex items-center flex-shrink-0'>
              <Icon className={cn(`text-${accentColor}`, 'w-6 h-6')} />
            </div>
          )}
          <div className='h400 flex flex-col items-start justify-between flex-1 ml-4'>
            {heading ? (
              <>
                <AlertHeading>{heading}</AlertHeading>
                <AlertMessage>{children}</AlertMessage>
              </>
            ) : (
              children
            )}
          </div>
          <div className='flex-0'>{cta || ''}</div>
        </div>
      </div>
    </div>
  );
};
