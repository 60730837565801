import React from 'react';
import { Text } from 'components/common';

export const SlideOutHeader: React.FC = () => {
  return (
    <div className='flex flex-col border-b border-gray-200 px-6 py-4'>
      <h1 className='h700'>Add candidate</h1>
      <Text h='400' className='mt-1' color='gray-500'>
        This won’t contact them yet.
      </Text>
    </div>
  );
};
