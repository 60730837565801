import * as React from 'react';

import { Input } from '@components/common';

import { DropdownProps } from '../../types';

type Props<D = any> = DropdownProps<D, string>;

export const Text: React.FC<Props> = ({ value, onChange, operator }) => (
  <div>
    <Input value={value || ''} onChange={(value) => onChange({ value })} />
  </div>
);
