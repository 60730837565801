import pluralize from 'pluralize';

import { Toast } from '@stores/toaster';

export const failedCreateTag = (): Toast => ({
  heading: 'An error occured',
  text: 'We couldn’t create that tag. Please try again later or contact support.',
  icon: 'error'
});

export const successCreateTag = (): Toast => ({
  heading: 'Tag was created',
  text: 'We have created a new tag',
  icon: 'success'
});

export const successDeleteTagGroup = (): Toast => ({
  heading: 'Tag group was deleted',
  text: 'We have deleted that group and moved all tags to ungrouped',
  icon: 'success'
});

export const successDeleteTags = (): Toast => ({
  heading: 'Successfully deleted',
  text: 'The selected tags were deleted',
  icon: 'success'
});

export const successMergeTags = (count: number, name: string): Toast => ({
  heading: 'Successfully merged',
  text: `We have merged ${pluralize('tags', count)} into ${name}`,
  icon: 'success'
});

export const failedMergeTags = (): Toast => ({
  heading: 'Something went wrong',
  text: 'We encountered an error merging tags. Please try again later or contact support.',
  icon: 'error'
});
