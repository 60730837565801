import * as React from 'react';
import { useState } from 'react';

import { Button, Text } from '@components/common';

import { CreateTeamModal } from '../../modals/CreateTeamModal';

export const ZDS: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      <div className='p-12 text-center'>
        <div className='flex justify-center mb-6 text-gray-500'>
          <svg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80' fill='none'>
            <path
              d='M47.4969 67.5H44.1635C43.7132 67.4912 43.2848 67.3041 42.9723 66.9798C42.6598 66.6554 42.4888 66.2203 42.4969 65.77V11.7267C42.4929 11.5038 42.5328 11.2824 42.6145 11.0751C42.6961 10.8677 42.8177 10.6784 42.9725 10.5181C43.1273 10.3577 43.3121 10.2295 43.5165 10.1406C43.7208 10.0517 43.9407 10.0039 44.1635 10H47.4969'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M34.9966 40H42.4966'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M57.4966 10C57.4966 10.9849 57.6906 11.9602 58.0675 12.8701C58.4444 13.7801 58.9968 14.6069 59.6933 15.3033C60.3897 15.9997 61.2165 16.5522 62.1265 16.9291C63.0364 17.306 64.0117 17.5 64.9966 17.5C65.9815 17.5 66.9568 17.306 67.8667 16.9291C68.7766 16.5522 69.6034 15.9997 70.2999 15.3033C70.9963 14.6069 71.5488 13.7801 71.9257 12.8701C72.3026 11.9602 72.4966 10.9849 72.4966 10C72.4966 9.01509 72.3026 8.03982 71.9257 7.12987C71.5488 6.21993 70.9963 5.39314 70.2999 4.6967C69.6034 4.00026 68.7766 3.44781 67.8667 3.0709C66.9568 2.69399 65.9815 2.5 64.9966 2.5C64.0117 2.5 63.0364 2.69399 62.1265 3.0709C61.2165 3.44781 60.3897 4.00026 59.6933 4.6967C58.9968 5.39314 58.4444 6.21993 58.0675 7.12987C57.6906 8.03982 57.4966 9.01509 57.4966 10Z'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M77.4967 30C76.7539 27.2175 75.1402 24.7458 72.8916 22.9465C70.643 21.1471 67.8776 20.1146 65 20C62.1218 20.1139 59.3557 21.1461 57.1064 22.9455C54.8572 24.7449 53.2429 27.217 52.5 30H77.4967Z'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M57.4966 57.5C57.4966 58.4849 57.6906 59.4602 58.0675 60.3701C58.4444 61.2801 58.9968 62.1069 59.6933 62.8033C60.3897 63.4997 61.2165 64.0522 62.1265 64.4291C63.0364 64.806 64.0117 65 64.9966 65C65.9815 65 66.9568 64.806 67.8667 64.4291C68.7766 64.0522 69.6034 63.4997 70.2999 62.8033C70.9963 62.1069 71.5488 61.2801 71.9257 60.3701C72.3026 59.4602 72.4966 58.4849 72.4966 57.5C72.4966 56.5151 72.3026 55.5398 71.9257 54.6299C71.5488 53.7199 70.9963 52.8931 70.2999 52.1967C69.6034 51.5003 68.7766 50.9478 67.8667 50.5709C66.9568 50.194 65.9815 50 64.9966 50C64.0117 50 63.0364 50.194 62.1265 50.5709C61.2165 50.9478 60.3897 51.5003 59.6933 52.1967C58.9968 52.8931 58.4444 53.7199 58.0675 54.6299C57.6906 55.5398 57.4966 56.5151 57.4966 57.5Z'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M77.4966 77.5C76.7536 74.717 75.1394 72.2449 72.8902 70.4455C70.6409 68.6461 67.8748 67.6139 64.9966 67.5C62.1184 67.6139 59.3522 68.6461 57.103 70.4455C54.8538 72.2449 53.2395 74.717 52.4966 77.5H77.4966Z'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M7.49658 30C7.49658 32.6522 8.55015 35.1957 10.4255 37.0711C12.3009 38.9464 14.8444 40 17.4966 40C20.1487 40 22.6923 38.9464 24.5676 37.0711C26.443 35.1957 27.4966 32.6522 27.4966 30C27.4966 27.3478 26.443 24.8043 24.5676 22.9289C22.6923 21.0536 20.1487 20 17.4966 20C14.8444 20 12.3009 21.0536 10.4255 22.9289C8.55015 24.8043 7.49658 27.3478 7.49658 30Z'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M2.49658 60C2.49658 56.0218 4.07693 52.2064 6.88998 49.3934C9.70303 46.5804 13.5183 45 17.4966 45C21.4748 45 25.2901 46.5804 28.1032 49.3934C30.9162 52.2064 32.4966 56.0218 32.4966 60H2.49658Z'
              stroke='currentColor'
              strokeWidth='5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
        <Text mb='2' bold>
          Organize research around your Teams
        </Text>
        <Text h='400' color='gray-500' mb='6'>
          Set up Teams of people that regularly work together to organize your studies, and govern candidate contact
          access by Teams as needed.
        </Text>
        <Button primary small onClick={() => setModalOpen(true)}>
          Create team
        </Button>
      </div>
      <CreateTeamModal trackKey='zds' open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
};
