import * as React from 'react';
import tinytime from 'tinytime';

import { Text } from '@components/common';

import { CellFC } from './types';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

export const Date: CellFC = ({ row: { original: item } }) => {
  if (item.type === 'recording') {
    return null;
  }

  return <Text>{dateTemplate.render(item.cloudRecording.start_time)}</Text>;
};
