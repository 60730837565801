import { api } from '@api/reduxApi';

const backgroundTaskApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBackgroundTask: builder.query<BackgroundTask, { id: number }>({
      query: ({ id }) => `background_tasks/${id}`,
      providesTags: (_, __, { id }) => [{ type: 'BackgroundTask', id }]
    }),
    cancelBackgroundTask: builder.mutation<BackgroundTask, { id: number }>({
      query: ({ id }) => ({
        url: `/background_tasks/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const { useGetBackgroundTaskQuery, useCancelBackgroundTaskMutation } = backgroundTaskApi;
