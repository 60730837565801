import { useEffect, useState } from 'react';

import { DirectUpload } from '@rails/activestorage';

interface Params {
  file: File | null | undefined;
  skip?: boolean;
}

export const useDirectUpload = ({ file, skip }: Params) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [signedId, setSignedId] = useState<string | null>(null);

  useEffect(() => {
    if (skip) {
      return;
    }

    if (file) {
      setIsLoading(true);

      const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');

      upload.create((error, blob) => {
        if (error) {
          setIsError(true);
        } else {
          setSignedId(blob.signed_id);
        }

        setIsLoading(false);
      });
    }
  }, [file, skip]);

  return { isLoading, isError, signedId };
};
