import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { DropdownLink } from '@components/common';
import { useToaster } from '@components/stores/toaster';
import {
  CloseStudyModal,
  DeleteStudyModal,
  DuplicateStudyModal,
  ReactivateStudyModal
} from '@components/StudiesApp/components/modals';
import { useStudyActionOptions } from '@components/StudiesApp/hooks/useStudyActionOptions';
import {
  ArchiveSVG,
  CompleteSVG,
  DuplicateSVG,
  HorizontalEllipsisSVG,
  PauseSVG,
  TrashSVG,
  UndoSVG
} from '@components/svgs';
import { capitalize, useOnClickOutside } from '@components/utils';
import { useUser } from '@hooks/useUser';

import * as toasts from '../../../../toasts';

interface Props {
  study: Study;
  setLoading: (loading: boolean) => void;
}

export const OptionsDropdown: React.FC<Props> = ({ study, setLoading }) => {
  const [open, setOpen] = useState(false);
  // if !incentive? $dont need to fund
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
  const [reactivateModalOpen, setReactivateModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const closeDropdown = () => setOpen(false);

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, closeDropdown);

  const { id, title } = study;

  const [transitionStudy] = api.useTransitionStudyMutation();

  const showToast = useToaster();

  const options = useStudyActionOptions(study);

  const user = useUser();

  const navigate = useNavigate();

  const handleStateChange = async (state: StudyTransition) => {
    setLoading(true);
    setOpen(false);
    try {
      await transitionStudy({ id, transition: state }).unwrap();
      showToast(toasts[`success${capitalize(state)}`]?.());
    } catch {
      showToast(toasts[`failed${capitalize(state)}`]?.());
    }

    setLoading(false);
  };

  const onRemoveStudy = () => {
    navigate(`/studies`);
  };

  const canArchiveStudy = ['draft', 'closed'].includes(study.state);

  const onClick = () => options.length > 0 && setOpen(!open);

  return (
    <div className='relative'>
      <button
        name='options-menu'
        aria-label='options-menu'
        onClick={onClick}
        className='focus:ring focus:ring-blue focus:outline-none flex items-center justify-center flex-shrink-0 w-10 h-10 border rounded-full'
      >
        <HorizontalEllipsisSVG />
      </button>

      <div ref={dropdownRef} className='whitespace-nowrap relative text-left'>
        {open && (
          <div className='ring-1 ring-black ring-opacity-5 top-2 absolute right-0 z-10 py-1 bg-white rounded-md shadow-lg'>
            {options.includes('reactivate') && (
              <DropdownLink className='xx-reactivate-study' onClick={() => setReactivateModalOpen(true)}>
                <UndoSVG className='inline mr-1 -mt-1' />
                Reactivate…
              </DropdownLink>
            )}
            {options.includes('duplicate') && (
              <DropdownLink className='xx-duplicate-study' onClick={() => setDuplicateModalOpen(true)}>
                <DuplicateSVG className='inline mr-1 -mt-0.5' />
                Duplicate
              </DropdownLink>
            )}
            {options.includes('pause') && (
              <DropdownLink className='xx-pause-study' onClick={() => handleStateChange('pause')}>
                <PauseSVG className='inline mr-1 -mt-0.5' />
                Pause
              </DropdownLink>
            )}
            {options.includes('unpause') && (
              <DropdownLink className='xx-unpause-study' onClick={() => handleStateChange('unpause')}>
                <PauseSVG className='inline mr-1 -mt-0.5' />
                Unpause
              </DropdownLink>
            )}
            {options.includes('close') && (
              <DropdownLink
                className='xx-close-study'
                onClick={() => {
                  setOpen(false);
                  setCloseModalOpen(true);
                }}
              >
                <CompleteSVG className='inline mr-1 -mt-0.5' />
                Close
              </DropdownLink>
            )}
            {options.includes('archive') && (
              <DropdownLink disabled={!canArchiveStudy} onClick={() => handleStateChange('archive')}>
                <ArchiveSVG className='inline mr-1 -mt-0.5' />
                Archive
              </DropdownLink>
            )}
            {options.includes('delete') && (
              <DropdownLink className='xx-delete-study' color='red-600' onClick={() => setDeleteModalOpen(true)}>
                <TrashSVG className='h-4 w-4 inline mr-1 -mt-0.5' />
                Delete
              </DropdownLink>
            )}
          </div>
        )}
      </div>

      <ReactivateStudyModal
        id={study.id}
        title={study.title}
        open={reactivateModalOpen}
        onClose={() => setReactivateModalOpen(false)}
      />
      <DuplicateStudyModal
        initialStudy={study}
        initialOwner={user}
        open={duplicateModalOpen}
        onClose={() => setDuplicateModalOpen(false)}
      />
      <DeleteStudyModal
        id={id}
        title={title}
        style={study.style}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onAfterSubmit={onRemoveStudy}
      />
      {closeModalOpen && <CloseStudyModal study={study} onClose={() => setCloseModalOpen(false)} />}
    </div>
  );
};
