import { useReducer } from 'react';

enum Actions {
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_BUFFER_TIME = 'SET_BUFFER_TIME',
  SET_IS_DRAGGING = 'SET_IS_DRAGGING',
  SET_IS_HOVERING = 'SET_IS_HOVERING',
  SET_TRACK_WIDTH = 'SET_TRACK_WIDTH',
  SET_TRACK_X = 'SET_TRACK_X',
  SET_TRACK_Y = 'SET_TRACK_Y',
  SET_MAX = 'SET_MAX',
  SET_HOVER_TIME = 'SET_HOVER_TIME'
}

type Action =
  | GenericAction<Actions.SET_CURRENT_TIME, number>
  | GenericAction<Actions.SET_BUFFER_TIME, number>
  | GenericAction<Actions.SET_IS_DRAGGING, boolean>
  | GenericAction<Actions.SET_IS_HOVERING, boolean>
  | GenericAction<Actions.SET_TRACK_WIDTH, number>
  | GenericAction<Actions.SET_MAX, number>
  | GenericAction<Actions.SET_HOVER_TIME, number>
  | GenericAction<Actions.SET_TRACK_X, number>
  | GenericAction<Actions.SET_TRACK_Y, number>;

type State = {
  currentTime: number;
  bufferTime: number;
  isDragging: boolean;
  isHovering: boolean;
  trackWidth: number;
  trackX: number;
  trackY: number;
  max: number;
  hoverTime: number;
};

export type VideoSeekBar = {
  state: State;
  setCurrentTime: (currentTime: number) => void;
  setBufferTime: (bufferTime: number) => void;
  setIsDragging: (isDragging: boolean) => void;
  setIsHovering: (isHovering: boolean) => void;
  setTrackWidth: (trackWidth: number) => void;
  setTrackX: (trackX: number) => void;
  setTrackY: (trackY: number) => void;
  setMax: (max: number) => void;
  setHoverTime: (hoverTime: number) => void;
};

export const INITIAL_STATE: State = {
  currentTime: 0,
  bufferTime: 0,
  hoverTime: 0,
  isDragging: false,
  isHovering: false,
  trackWidth: 0,
  trackX: 0,
  trackY: 0,
  max: 0
};

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Actions.SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload };
    case Actions.SET_BUFFER_TIME:
      return { ...state, bufferTime: action.payload };
    case Actions.SET_IS_DRAGGING:
      return { ...state, isDragging: action.payload };
    case Actions.SET_IS_HOVERING:
      return { ...state, isHovering: action.payload };
    case Actions.SET_TRACK_WIDTH:
      return { ...state, trackWidth: action.payload };
    case Actions.SET_MAX:
      return { ...state, max: action.payload };
    case Actions.SET_HOVER_TIME:
      return { ...state, hoverTime: action.payload };
    case Actions.SET_TRACK_X:
      return { ...state, trackX: action.payload };
    case Actions.SET_TRACK_Y:
      return { ...state, trackY: action.payload };
    default:
      return state;
  }
};

export const useVideoSeekBar = (): VideoSeekBar => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setCurrentTime = (currentTime: number) => {
    dispatch({ type: Actions.SET_CURRENT_TIME, payload: currentTime });
  };

  const setBufferTime = (bufferTime: number) => {
    dispatch({ type: Actions.SET_BUFFER_TIME, payload: bufferTime });
  };

  const setIsDragging = (isDragging: boolean) => {
    dispatch({ type: Actions.SET_IS_DRAGGING, payload: isDragging });
  };

  const setIsHovering = (isHovering: boolean) => {
    dispatch({ type: Actions.SET_IS_HOVERING, payload: isHovering });
  };

  const setTrackWidth = (trackWidth: number) => {
    dispatch({ type: Actions.SET_TRACK_WIDTH, payload: trackWidth });
  };

  const setTrackX = (trackX: number) => {
    dispatch({ type: Actions.SET_TRACK_X, payload: trackX });
  };

  const setTrackY = (trackY: number) => {
    dispatch({ type: Actions.SET_TRACK_Y, payload: trackY });
  };

  const setMax = (max: number) => {
    dispatch({ type: Actions.SET_MAX, payload: max });
  };

  const setHoverTime = (hoverTime: number) => {
    dispatch({ type: Actions.SET_HOVER_TIME, payload: hoverTime });
  };

  return {
    state,
    setCurrentTime,
    setBufferTime,
    setIsDragging,
    setIsHovering,
    setTrackWidth,
    setTrackX,
    setTrackY,
    setMax,
    setHoverTime
  };
};
