// Without the symbol
export const moneyFormat = (num: number, digits: number = 2): string =>
  num.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export * from './ids';

export const sum = (arr: number[]): number => arr.reduce((acc, v) => acc + v, 0);

export const toPositiveNumber = (v: string | number): number =>
  !!Number(v) && Math.abs(Number(v)) >= 0 ? Math.abs(Number(v)) : 0;

export const pct = (a: number, b: number) => Math.round((100 * a) / b);
