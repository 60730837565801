import React from 'react';

import { Table } from '@components/shared/Table';

import { CellWithConfirmation } from '../components/CellWithConfirmation';
import * as Types from '../types';

export const piiColumnDefinitions: Types.DefinitionFn = ({ addFilter, canPII, getColumnLabel }) => {
  if (!canPII) {
    return [];
  }

  return [
    {
      id: 'email',
      accessorKey: 'customer.email',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          type='text'
          render={(props) => <CellWithConfirmation<Participation> {...props} />}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('email')}
        </Table.ColumnHeader>
      )
    },
    {
      id: 'phone_number',
      accessorKey: 'customer.phone_number',
      cell: (props) => (
        <Table.Cell<Participation> {...props} type='text' render={(props) => <CellWithConfirmation {...props} />} />
      ),
      header: (props) => (
        <Table.ColumnHeader addFilter={addFilter} {...props}>
          {getColumnLabel?.('phone_number')}
        </Table.ColumnHeader>
      )
    }
  ];
};
