import React, { SVGProps } from 'react';

export const WorldSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <path
      d='M0.96875 8C0.96875 8.92336 1.15062 9.83767 1.50397 10.6907C1.85733 11.5438 2.37524 12.3189 3.02816 12.9718C3.68107 13.6248 4.45619 14.1427 5.30926 14.496C6.16233 14.8494 7.07664 15.0313 8 15.0313C8.92336 15.0313 9.83767 14.8494 10.6907 14.496C11.5438 14.1427 12.3189 13.6248 12.9718 12.9718C13.6248 12.3189 14.1427 11.5438 14.496 10.6907C14.8494 9.83767 15.0313 8.92336 15.0313 8C15.0313 7.07664 14.8494 6.16233 14.496 5.30926C14.1427 4.45619 13.6248 3.68107 12.9718 3.02816C12.3189 2.37524 11.5438 1.85733 10.6907 1.50397C9.83767 1.15062 8.92336 0.96875 8 0.96875C7.07664 0.96875 6.16233 1.15062 5.30926 1.50397C4.45619 1.85733 3.68107 2.37524 3.02816 3.02816C2.37524 3.68107 1.85733 4.45619 1.50397 5.30926C1.15062 6.16233 0.96875 7.07664 0.96875 8Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.675 14.9063C5.69375 13.5313 5.03125 10.95 5.03125 8.03125C5.03125 5.1125 5.69375 2.46875 6.675 1.125'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.32617 14.9063C10.3074 13.5313 10.9699 10.95 10.9699 8.03125C10.9699 5.1125 10.3074 2.46875 9.32617 1.125'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.96875 8H15.0313' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1.78125 11.2812H14.2188' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M1.78125 4.71875H14.2188' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
