import React, { SVGProps } from 'react';

export const DuplicateSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.2812 14.5625H3.40625C3.17419 14.5625 2.95163 14.4703 2.78753 14.3062C2.62344 14.1421 2.53125 13.9196 2.53125 13.6875V4.28125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4687 11.5928C13.4752 11.8704 13.3714 12.1392 13.18 12.3405C12.9887 12.5417 12.7255 12.659 12.4479 12.6667H5.30206C5.02448 12.659 4.76123 12.5417 4.56991 12.3405C4.3786 12.1392 4.2748 11.8704 4.28123 11.5928V2.51142C4.2748 2.23381 4.3786 1.96495 4.56991 1.76369C4.76123 1.56242 5.02448 1.44514 5.30206 1.4375H10.4937C10.6291 1.43818 10.763 1.46641 10.8871 1.52046C11.0113 1.57451 11.1231 1.65326 11.2159 1.75192L13.1695 3.80758C13.3626 4.01278 13.4697 4.28417 13.4687 4.56592V11.5928Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
