import * as React from 'react';
import { TippyOrNot } from '@components/common';

interface Props {
  request?: ExternalCandidatesRequest;
}
export const StateBadge: React.FC<Props> = ({ request }) => {
  const state = request?.state || 'draft';
  const paused: boolean = request?.paused || false;
  const valid: boolean = (request?.valid_request as boolean) && (request?.valid_study as boolean);
  const requestErrors = (request?.all_errors || []).join('; ');

  let copy: string = state;
  let color: string = 'indigo';
  switch (state) {
    case 'draft':
      copy = 'draft';
      color = 'yellow';
      break;
    case 'submitted_publish':
      if (valid) {
        copy = 'publishing';
        color = 'indigo';
      } else {
        copy = 'unable to publish';
        color = 'red';
      }
      break;
    case 'published':
      if (paused) {
        copy = 'paused';
        color = 'yellow';
      } else {
        copy = 'active';
        color = 'indigo';
      }
      break;
    case 'submitted_close':
      copy = 'closing';
      color = 'green';
      break;
    case 'closed':
      copy = 'closed';
      color = 'green';
      break;
  }

  return (
    <TippyOrNot show={requestErrors.length > 0} content={requestErrors}>
      <div className={`px-1 text-xs text-${color}-700 bg-${color}-50 font-bold uppercase`}>{copy}</div>
    </TippyOrNot>
  );
};
