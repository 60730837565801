import * as React from 'react';
import { useState, useEffect } from 'react';
import { api } from 'api/reduxApi';
import { useToaster } from 'components/stores/toaster';

import { SlideOut, Button, Text, HelperText, Select, InputWithAddons } from '@components/common';
import { moneyFormat } from '@components/utils';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  account: GQAccount;
  teams: Team[];
  initialFrom?: string;
  initialTo?: string;
}

const FieldError: React.FC<{ message: string; className?: string }> = ({ message, className = '' }) => (
  <Text className={className} h='200' color='red-600' mt='1'>
    {message}
  </Text>
);

export const MoveFundsSlideout: React.FC<Props> = ({
  onClose,
  onSuccess,
  account,
  teams,
  initialFrom = '',
  initialTo = ''
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const [amount, setAmount] = useState('');
  const [amountError, setAmountError] = useState<string | null>(null);

  const [fromWallet, setFromWallet] = useState(initialFrom);
  const [fromError, setFromError] = useState<string | null>(null);

  const [toWallet, setToWallet] = useState(initialTo);
  const [toError, setToError] = useState<string | null>(null);

  const [
    createTeamAllocation,
    {
      data: createdTeamAllocation,
      isLoading: createTeamAllocationLoading,
      isSuccess: createTeamAllocationSuccess,
      isError: createTeamAllocationError
    }
  ] = api.useCreateTeamAllocationMutation();

  const showToast = useToaster();

  const wallets = [
    {
      value: 'account',
      maximum_in_cents: account.wallet.available,
      name: 'General',
      label: `General ($${moneyFormat(account.wallet.available / 100)})`
    },
    ...teams.map((team) => ({
      value: team.id.toString(),
      maximum_in_cents: team.wallet_available || 0,
      name: team.name,
      label: `${team.name} ($${moneyFormat((team.wallet_available || 0) / 100)})`
    }))
  ];

  const swapWallets = () => {
    setFromWallet(toWallet);
    setToWallet(fromWallet);
  };

  const onConfirm = () => {
    setAmountError(null);
    setFromError(null);
    setToError(null);

    const from = wallets.find((w) => w.value === fromWallet);
    if (!(Number(amount) > 0)) {
      setAmountError('Amount is required');
    } else if (from && Number(amount) * 100 > from.maximum_in_cents) {
      setAmountError(`Amount of $${moneyFormat(Number(amount))} exceeds the balance of ${from.label}`);
    }

    if (!fromWallet) {
      setFromError('From is required');
    }
    if (!toWallet) {
      setToError('To is required');
    }

    setConfirmed(true);
  };

  useEffect(() => {
    if (createTeamAllocationLoading) {
      setIsLoading(true);
    }
  }, [createTeamAllocationLoading]);

  useEffect(() => {
    if (createTeamAllocationSuccess) {
      const to = wallets.find((w) => w.value === toWallet);
      if (to) {
        showToast({
          heading: `Funds moved`,
          text: `${to.name} increased by $${moneyFormat(Number(amount))}`,
          icon: 'success'
        });
      }
      setIsLoading(false);
      onSuccess();
      onClose();
    }
  }, [createTeamAllocationSuccess]);

  useEffect(() => {
    if (createTeamAllocationError) {
      showToast({
        heading: 'Something went wrong!',
        text: 'Please try again later.',
        icon: 'error'
      });
      setIsLoading(false);
      setConfirmed(false);
    }
  }, [createTeamAllocationError]);

  useEffect(() => {
    if (confirmed) {
      if (amountError || fromError || toError) {
        setConfirmed(false);
      } else {
        const amountInCents = Number(amount) * 100;
        if (fromWallet === 'account') {
          createTeamAllocation({ id: Number(toWallet), usd_amount_in_cents: amountInCents });
        } else if (toWallet === 'account') {
          createTeamAllocation({
            id: Number(fromWallet),
            usd_amount_in_cents: amountInCents,
            source_team_id: Number(fromWallet)
          });
        } else {
          createTeamAllocation({
            id: Number(toWallet),
            usd_amount_in_cents: amountInCents,
            source_team_id: Number(fromWallet)
          });
        }
      }
    }
  }, [confirmed]);

  return (
    <>
      <SlideOut
        onClose={onClose}
        closeOnEsc
        title='Move funds'
        subtitle='Add or transfer funds from wallet to wallet.'
        renderFooter={() => (
          <div className='flex justify-between w-full'>
            <div className='flex space-x-4'>
              <Button disabled={isLoading} onClick={onConfirm} primary>
                Confirm & move
              </Button>
              <Button disabled={isLoading} className='mr-2' onClick={onClose}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      >
        <hr></hr>
        <div className='p-6'>
          <Text className='mb-1' h='400' bold>
            Amount
          </Text>
          <HelperText>Enter how much you’d like to move.</HelperText>
          <InputWithAddons
            type='number'
            ariaLabel='Amount'
            className={`no_arrows ${amountError ? 'mb-2' : 'mb-10'}`}
            value={amount}
            onChange={(v: string) => {
              setAmount(v);
              setAmountError(null);
            }}
            prefix=''
            pr='16'
            onEnter={(e) => e.currentTarget.blur()}
          />
          {amountError && <FieldError className='mb-10' message={amountError} />}

          <Text className='mb-1' h='400' bold>
            From
          </Text>
          <HelperText>Select the wallet to move funds from.</HelperText>
          <Select
            options={wallets.filter((w) => w.value !== toWallet)}
            name='from-wallet'
            className={fromError ? 'mb-2' : 'mb-4'}
            value={fromWallet}
            onChange={(v: string) => {
              setFromWallet(v);
              setFromError(null);
            }}
          />
          {fromError && <FieldError className='mb-4' message={fromError} />}

          <Button
            icon='transferVertical'
            link
            className='mb-4'
            disabled={!fromWallet || !toWallet}
            onClick={() => swapWallets()}
          >
            Swap wallets
          </Button>

          <Text className='mb-1' h='400' bold>
            To
          </Text>
          <HelperText>Select the wallet to move funds to.</HelperText>
          <Select
            options={wallets.filter((w) => w.value !== fromWallet)}
            name='to-wallet'
            className={toError ? 'mb-2' : 'mb-10'}
            value={toWallet}
            onChange={(v: string) => {
              setToWallet(v);
              setToError(null);
            }}
          />
          {toError && <FieldError className='mb-10' message={toError} />}
        </div>
      </SlideOut>
    </>
  );
};
