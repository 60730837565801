import React, { SVGProps } from 'react';

export const LocationSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.50001 7.84617C5.37284 7.84604 5.24684 7.82184 5.12867 7.77483L1.18601 6.20017C1.10993 6.16968 1.02753 6.15836 0.946059 6.1672C0.864583 6.17604 0.786529 6.20477 0.718768 6.25087C0.651007 6.29697 0.595615 6.35901 0.557468 6.43155C0.519321 6.50408 0.499588 6.58488 0.500007 6.66683V12.9002C0.499973 13.1001 0.55985 13.2954 0.67191 13.4609C0.783969 13.6265 0.943068 13.7546 1.12867 13.8288L5.12867 15.4288C5.36705 15.5242 5.63296 15.5242 5.87134 15.4288L10.1287 13.7255C10.367 13.6302 10.633 13.6302 10.8713 13.7255L14.814 15.3028C14.8901 15.3333 14.9725 15.3446 15.054 15.3358C15.1354 15.327 15.2135 15.2982 15.2812 15.2521C15.349 15.206 15.4044 15.144 15.4425 15.0715C15.4807 14.9989 15.5004 14.9181 15.5 14.8362V8.60017C15.5 8.40027 15.4402 8.20495 15.3281 8.03941C15.216 7.87388 15.0569 7.74573 14.8713 7.6715L10.8713 6.0715C10.633 5.97617 10.367 5.97617 10.1287 6.0715L9.52467 6.3135'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.5 7.8457V15.4997' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10.5 6V13.654' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 4.5V10.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5.5 2.5C5.5 3.03043 5.71071 3.53914 6.08579 3.91421C6.46086 4.28929 6.96957 4.5 7.5 4.5C8.03043 4.5 8.53914 4.28929 8.91421 3.91421C9.28929 3.53914 9.5 3.03043 9.5 2.5C9.5 1.96957 9.28929 1.46086 8.91421 1.08579C8.53914 0.710714 8.03043 0.5 7.5 0.5C6.96957 0.5 6.46086 0.710714 6.08579 1.08579C5.71071 1.46086 5.5 1.96957 5.5 2.5V2.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
