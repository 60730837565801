import React, { SVGProps } from 'react';

export const FairnessBasedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7 13.5625V3.0625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.375 13.5625H9.625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.875 2.1875H10.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.0625 2.1875H6.125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.125 2.1875C6.125 2.41956 6.21719 2.64212 6.38128 2.80622C6.54538 2.97031 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 2.97031 7.61872 2.80622C7.78281 2.64212 7.875 2.41956 7.875 2.1875C7.875 1.95544 7.78281 1.73288 7.61872 1.56878C7.45462 1.40469 7.23206 1.3125 7 1.3125C6.76794 1.3125 6.54538 1.40469 6.38128 1.56878C6.21719 1.73288 6.125 1.95544 6.125 2.1875V2.1875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.25 8.3125C5.25 8.89266 5.01953 9.44906 4.6093 9.8593C4.19906 10.2695 3.64266 10.5 3.0625 10.5C2.48234 10.5 1.92594 10.2695 1.5157 9.8593C1.10547 9.44906 0.875 8.89266 0.875 8.3125H5.25Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.0625 2.1875L0.875 6.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.0625 2.1875L5.25 6.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.125 8.3125C13.125 8.89266 12.8945 9.44906 12.4843 9.8593C12.0741 10.2695 11.5177 10.5 10.9375 10.5C10.3573 10.5 9.80094 10.2695 9.3907 9.8593C8.98047 9.44906 8.75 8.89266 8.75 8.3125H13.125Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9375 2.1875L8.75 6.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9375 2.1875L13.125 6.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
