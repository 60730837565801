import React, { useEffect, useState } from 'react';
import { useOnEscape } from '@components/utils';

type Hook = <T>(
  value: T,
  setValue: (value: T) => void,
  onBlur: (() => void) | undefined,
  onFocus: (() => void) | undefined
) => {
  initValue: T;
  handleBlur: () => void;
  handleFocus: () => void;
  focused: boolean;
  setFocused: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useInputState: Hook = (value, setValue, onBlur, onFocus) => {
  const [focused, setFocused] = useState(false);
  const [initValue, setInitValue] = useState(value);

  useEffect(() => {
    setInitValue(value);
  }, []);

  useOnEscape(() => {
    setValue(initValue);
    onBlur?.();
  });

  const handleFocus = () => {
    onFocus?.();
    setFocused(true);
  };

  const handleBlur = () => {
    onBlur?.();
    setFocused(false);
  };

  return { initValue, handleBlur, handleFocus, focused, setFocused };
};
