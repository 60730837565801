import { docReady } from 'src/docReady';

docReady(function () {
  const toggleShowPasswordButton = document.getElementById('show-password');
  if (toggleShowPasswordButton) {
    toggleShowPasswordButton.onclick = function () {
      const passwordInput = document.querySelector('input[name=user\\[password\\]]');
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        toggleShowPasswordButton.innerText = 'Hide password';
      } else {
        passwordInput.type = 'password';
        toggleShowPasswordButton.innerText = 'Show password';
      }
    };
  }

  const passwordErrors = document.getElementById('password-errors');
  // <p class="mt-3 text-sm text-gray-600">(8 character minimum - 1 uppercase, 1 special character, 1 digit)</p>

  if (passwordErrors) {
    const validators = [
      ['8 character minimum', (pass) => pass.length >= 8],
      ['70 character maximum', (pass) => pass.length <= 70, true],
      ['1 uppercase', (pass) => !!pass.match(/[A-Z]/)],
      ['1 lowercase', (pass) => !!pass.match(/[a-z]/), true],
      ['1 special character', (pass) => !!pass.match(/[{}#?!@$%^&*-]/)],
      ['1 digit', (pass) => !!pass.match(/[0-9]/)]
    ];

    function validate(pass) {
      passwordErrors.innerHTML = '';
      for (const row of validators) {
        const p = document.createElement('p');
        const success = row[1](pass);
        if (success && row[2]) {
          continue;
        }

        if (success) {
          p.innerHTML =
            '<span class="text-green-600 mr-2"><svg class="inline" fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><g stroke="#057a55" stroke-linecap="round" stroke-linejoin="round" stroke-width=".875"><path d="m4.5 8.71357 1.42917 2.02823c.05421.081.12697.1479.21224.195.08527.0472.18057.0733.27797.0763.09741.0029.1941-.0175.28203-.0595s.16456-.1044.22351-.182l4.57508-5.78845"/><path d="m1.4375 7.99951c0 1.74048.6914 3.40969 1.92211 4.64039s2.89991 1.9221 4.64039 1.9221 3.4097-.6914 4.6404-1.9221 1.9221-2.89991 1.9221-4.64039-.6914-3.40968-1.9221-4.64039c-1.2307-1.2307-2.89992-1.92211-4.6404-1.92211s-3.40968.69141-4.64039 1.92211c-1.23071 1.23071-1.92211 2.89991-1.92211 4.64039z"/></g></svg></span>';
        } else {
          p.innerHTML =
            '<span class="mr-2"><svg class="inline" fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><circle cx="8" cy="8" fill="#fff" r="6.5" stroke="#e5e7eb"/></svg>';
        }

        p.className = 'pt-2';

        const span = document.createElement('span');
        span.innerText = row[0];
        p.appendChild(span);
        passwordErrors.appendChild(p);
      }
    }

    const input = document.querySelector('input[type=password]');
    input.oninput = (e) => {
      validate(e.currentTarget.value);
    };
  }
});
