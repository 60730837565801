import * as React from 'react';
import cn from 'classnames';

import * as icons from '@components/AccountTeamsApp/components/icons';

type Props = {
  className?: string;
  team?: Team | null;
};
export const TeamIcon: React.FC<Props> = ({ className, team }) => {
  const backgroundStyle = { backgroundColor: team?.color || '#f3f3f3' };
  const iconStyle = { color: team?.color || '#3f3d56' };
  const iconClass = 'absolute inset-0 z-10 flex-shrink-0';
  const IconComponent = team ? team.icon && icons[team.icon] : icons.AATeamMembers;

  return (
    <div className={cn('relative bg-white w-4 h-4 rounded-sm flex-shrink-0 overflow-hidden', className)}>
      <div className='opacity-10 w-full h-full rounded-sm' style={backgroundStyle} />

      {IconComponent && <IconComponent className={iconClass} style={iconStyle} />}

      {!IconComponent && (
        <span
          className={cn(iconClass, 'h200 flex items-center justify-center font-normal leading-none')}
          style={iconStyle}
        >
          {team?.initial || '?'}
        </span>
      )}
    </div>
  );
};
