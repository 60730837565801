import React from 'react';

import { FilterSearch } from 'components/CandidatesApp/CandidatesIndex/FilterSearch';
import { humanize } from '@components/utils';

import { DropdownProps } from '../../types';
import { useSearchWithinFilter } from 'components/CandidatesApp/CandidatesIndex/hooks';
import { Option } from '@components/shared/TableFilters/components/dropdowns/MultiSelectWithSearch';

type Props<D = any> = DropdownProps<D, string[]> & { sort?: 'alpha'; options: { label: string; value: string }[] };

export const Choose: React.FC<Props> = ({ value: initValue, onChange, operator, options, sort }) => {
  const value = initValue || [];

  const { searchValue, setSearchValue, filteredOptions } = useSearchWithinFilter<Option>({
    options,
    getFilteredOptions: (option, search) =>
      option.label.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || ''),
    sort
  });

  return (
    <div>
      <FilterSearch value={searchValue} setValue={setSearchValue} />
      <div className='space-y-1.5 max-h-32 overflow-auto py-1'>
        {filteredOptions.map((option, i) => (
          <label
            key={'filterOption' + `${option}${i}`}
            className='max-w-md hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 flex items-center text-sm leading-5 text-gray-700 normal-case'
          >
            <input
              type='checkbox'
              className='form-checkbox ml-1 mr-2'
              onChange={() =>
                onChange({
                  value: value.includes(option.value)
                    ? value.filter((opt) => opt !== option.value)
                    : value.concat(option.value)
                })
              }
              value={option.value}
              checked={value?.includes(option.value) || false}
            />
            {option ? (
              <span className='truncate'>{humanize(option.label)}</span>
            ) : (
              <span className='italic text-gray-500'>none</span>
            )}
          </label>
        ))}
      </div>
    </div>
  );
};
