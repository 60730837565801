import { api } from '@api/reduxApi';

const aiSearchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSearch: builder.query<AiSearch, { id?: string }>({
      query: ({ id }) => `/ai/searches/${id}`,
      transformResponse: (resp: any) => resp.data
    }),
    createSearch: builder.mutation<{ id: number }, { q: string }>({
      query: ({ q }) => ({
        url: '/ai/searches',
        method: 'POST',
        body: { q }
      }),
      transformResponse: (resp: any) => resp.data
    })
  })
});

export const { useGetSearchQuery, useCreateSearchMutation } = aiSearchApi;
