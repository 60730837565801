import React, { SVGProps } from 'react';

export const UnmoderatedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.10938 21.8438H21.8906C23.0537 21.8438 24 20.8975 24 19.7344V4.26562C24 3.10252 23.0537 2.15625 21.8906 2.15625H2.10938C0.946266 2.15625 0 3.10252 0 4.26562V19.7344C0 20.8975 0.946266 21.8438 2.10938 21.8438ZM1.40625 4.26562C1.40625 3.87792 1.72167 3.5625 2.10938 3.5625H21.8906C22.2783 3.5625 22.5938 3.87792 22.5938 4.26562V19.7344C22.5938 20.1221 22.2783 20.4375 21.8906 20.4375H2.10938C1.72167 20.4375 1.40625 20.1221 1.40625 19.7344V4.26562Z'
      fill='currentColor'
    />
    <path
      d='M3.51562 17.625H4.38656C4.67681 18.4433 5.45836 19.0312 6.375 19.0312C7.29164 19.0312 8.07319 18.4433 8.36344 17.625H20.4844C20.8727 17.625 21.1875 17.3102 21.1875 16.9219C21.1875 16.5336 20.8727 16.2188 20.4844 16.2188H8.36344C8.07319 15.4004 7.29164 14.8125 6.375 14.8125C5.45836 14.8125 4.67681 15.4004 4.38656 16.2188H3.51562C3.12731 16.2188 2.8125 16.5336 2.8125 16.9219C2.8125 17.3102 3.12731 17.625 3.51562 17.625ZM6.375 16.2188C6.7627 16.2188 7.07812 16.5342 7.07812 16.9219C7.07812 17.3096 6.7627 17.625 6.375 17.625C5.9873 17.625 5.67188 17.3096 5.67188 16.9219C5.67188 16.5342 5.9873 16.2188 6.375 16.2188Z'
      fill='currentColor'
    />
    <path
      d='M8.81784 13.3013C9.0255 13.4296 9.28444 13.4408 9.50194 13.3321L15.1269 10.5196C15.3652 10.4004 15.5156 10.157 15.5156 9.89066C15.5156 9.62432 15.3652 9.3809 15.1269 9.26174L9.50194 6.44924C9.28402 6.34026 9.02512 6.35188 8.81784 6.48004C8.61056 6.60815 8.48438 6.83446 8.48438 7.07816V12.7032C8.48438 12.9469 8.61056 13.1732 8.81784 13.3013ZM9.89062 8.21587L13.2403 9.89066L9.89062 11.5655V8.21587Z'
      fill='currentColor'
    />
  </svg>
);
