import React from 'react';

import { Choose, Date } from '@components/shared/TableFilters/components/dropdowns';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import { date, multipleChoice } from '@components/shared/TableFilters/utils/funcs';
import { EMAIL_TEMPLATE_PURPOSES } from '@components/TemplatesApp/consts';

import { SearchTeam } from '../components/SearchTeam';

const EMAIL_USE_CASES = Object.values(EMAIL_TEMPLATE_PURPOSES);

export const buildEmailTemplateDefinitions = (): FilterDefinition<EmailTemplate>[] => [
  {
    defaultOperator: 'includes_any',
    func: ({ creator }, { operator, value }) => multipleChoice(creator.name || '', operator, value),
    id: 'created_by',
    name: 'Created by',
    type: 'text',
    Icon: svgs.text,
    Component: SearchTeam
  },
  {
    defaultOperator: 'includes_any',
    func: ({ event }, { operator, value }) => multipleChoice(EMAIL_TEMPLATE_PURPOSES[event] || '', operator, value),
    id: 'use_case',
    name: 'Use case',
    type: 'text',
    Icon: svgs.multipleChoice,
    Component: (props) => (
      <Choose {...props} options={EMAIL_USE_CASES.map((c) => ({ label: c, value: c }))} sort='alpha' />
    )
  },
  {
    defaultOperator: 'is',
    func: (template, state) => {
      if (!state.value && !state.range) return true;
      return date(template.updated_at, state.range || {}, state.operator, state.value);
    },
    id: 'updated_at',
    name: 'Last updated',
    type: 'date',
    Icon: svgs.date,
    Component: Date
  }
];
