import * as React from 'react';

export const url: React.FC<any> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.9375 10.062L10.0625 3.93701'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.5121 7.01238C4.14518 6.99124 3.77794 7.04786 3.43444 7.17855C3.09094 7.30924 2.77893 7.51103 2.51885 7.77071L1.20635 9.08321C0.714063 9.5755 0.4375 10.2432 0.4375 10.9394C0.4375 11.2841 0.505398 11.6254 0.637317 11.9439C0.769237 12.2624 0.962594 12.5518 1.20635 12.7955C1.4501 13.0393 1.73948 13.2327 2.05797 13.3646C2.37645 13.4965 2.71779 13.5644 3.06252 13.5644C3.75871 13.5644 4.4264 13.2878 4.91868 12.7955L6.23118 11.483C6.49086 11.223 6.69266 10.911 6.82334 10.5675C6.95403 10.2239 7.01066 9.85671 6.98952 9.48979'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.48797 6.9861C9.85488 7.0072 10.2221 6.95056 10.5656 6.81987C10.9091 6.68919 11.2211 6.48741 11.4812 6.22776L12.7937 4.91526C13.286 4.42298 13.5626 3.7553 13.5626 3.0591C13.5626 2.3629 13.286 1.69522 12.7937 1.20293C12.3014 0.710645 11.6338 0.434082 10.9376 0.434082C10.2414 0.434082 9.57367 0.710645 9.08139 1.20293L7.76889 2.51543C7.50982 2.77583 7.30863 3.08796 7.17846 3.43144C7.04828 3.77491 6.99206 4.14199 7.01347 4.50868'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
