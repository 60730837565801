import { slValueToArray } from 'components/Public/GQSurvey/skipLogic';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import tinytime from 'tinytime';
import Tippy from '@tippyjs/react';

import { api } from '@api/reduxApi';
import { Button, Input, Loading, Spinner, Toggle, ZoomableImage } from '@components/common';
import { UploadButton } from '@components/common/UploadButton';
import { Date as DateQuestion } from '@components/GQSurveyBuilder/questions/Date';
import { Location } from '@components/GQSurveyBuilder/questions/Location';
import { preparePayload } from '@components/GQSurveyBuilder/utils';
import * as toasts from '@components/GQSurveyBuilder/toasts';
import { BubblePictureSVG, DragSVG, DuplicateSVG, PencilSVG, SkipLogicSVG, TrashSVG } from '@components/svgs';
import { find, last } from '@components/utils';
import { useToaster } from '@stores/toaster';

import { AttributeSelector } from './components/AttributeSelector';
import { Button as IdealAnswer } from './components/IdealAnswers/Button';
import {
  shouldClearSkipLogic,
  SkipLogicSlideOut,
  END_OF_SURVEY,
  END_OF_SURVEY_WITH_AUTO_REVIEW,
  END_OF_SURVEY_WITHOUT_SUBMIT,
  endOfSurveyIds,
  isEndOfSurvey
} from './components/SkipLogicSlideOut';
import {
  Checkboxes,
  Email,
  Info,
  LongText,
  MultipleChoice,
  Number,
  NumberRange,
  ShortText,
  Task,
  Website,
  YesNo
} from './questions';
import { calculateNumber } from './utils';
import { useTranslation } from 'react-i18next';

import { useFeature } from '@hooks/useFeature';
import { Label } from '@components/common/Label';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const NotImpl: QuestionCardBodyComponent = ({ question }) => <>haven't implemented {question.field_type} yet</>;

interface FieldConfig {
  label: string;
  helper: boolean;
  comp: QuestionCardBodyComponent;
  simple?: boolean;
  defaults?: {
    label?: string;
    helper?: string;
    required?: boolean;
  };
}

export const FIELD_CONFIGS: Record<
  Exclude<ScreenerField['field_type'], 'start_loom' | 'stop_loom' | 'task' | 'prototype_test'>,
  FieldConfig
> = {
  short_text: { label: 'Short Text', helper: true, comp: ShortText },
  long_text: { label: 'Long Text', helper: true, comp: LongText },
  email: { label: 'Email', helper: true, comp: Email },
  number: { label: 'Number', helper: true, comp: Number },
  website: { label: 'Website', helper: true, comp: Website },
  yes_no: { label: 'Yes / No', helper: true, comp: YesNo },
  multiple_choice: { label: 'Multi Select', helper: true, comp: Checkboxes },
  number_range: { label: 'Linear Scale', helper: true, comp: NumberRange },
  single_choice: { label: 'Single Select', helper: true, comp: MultipleChoice },
  info: { label: 'Info', helper: false, comp: Info, simple: true },
  date: { label: 'Date', helper: true, comp: DateQuestion },
  location: { label: 'Location', helper: true, comp: Location }
};

export const DEFAULT_UNMODERATED_FIELD_TITLE = 'Please complete the following task';
const UNMODERATED_FIELDS: Partial<Record<ScreenerField['field_type'], FieldConfig>> = {
  task: {
    label: 'Prototype',
    helper: false,
    comp: Task,
    simple: false,
    defaults: {
      label: DEFAULT_UNMODERATED_FIELD_TITLE,
      required: true
    }
  }
};

export interface QuestionCardProps {
  screener: Screener;
  screener_review: ScreenerReview;
  questions: ScreenerField[];
  question: ScreenerField;
  attrs: Attr_[];
  style: Study['style'];
  editable: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  debouncedChange: (question: ScreenerField) => void;
  onChange: (question: ScreenerField) => void;
  onDuplicate: () => void;
  onReplace: (id: number, question: ScreenerField) => void;
  onDelete: () => void;
  disableImgUpload: boolean;
  number: number;
  isUpdating?: boolean;
}

const SKIP_LOGIC_OP = {
  equal: 'is',
  not_equal: 'is not',
  less_than: 'is less than',
  greater_than: 'is greater than',
  ends_with: 'ends with',
  starts_with: 'starts with',
  contains: 'contains',
  matches_all: 'contains all of',
  matches_any: 'contains any of',
  matches_none: 'contains none of',
  match_not_exact: 'does not perfectly match',
  any_answer: 'is any',
  no_answer: 'was not given'
};

const EMPTY_QUESTION = '<deleted question>';

const EMPTY_SKIP_LOGIC_VALUE = '<none>';

const SKIP_LOGIC_FINAL_STEP = 'the final step';

const SKIP_LOGIC_FINAL_STEP_EXCLUDE_FROM_AUTO_REVIEW = (): React.ReactNode => (
  <span>
    the final step (and <span className='underline'>exclude</span> from automatic review)
  </span>
);

const SKIP_LOGIC_FINAL_STEP_ALLOW_AS_IDEAL = (): React.ReactNode => (
  <span>
    the final step (but <span className='underline'>include</span> in automatic review)
  </span>
);

const SKIP_LOGIC_FINAL_STEP_SKIP_SUBMIT = (): React.ReactNode => (
  <span>
    the final step (<span className='underline'>without</span> collecting the response)
  </span>
);

const UNTITLED_QUESTION = '(Untitled question)';

export const QuestionCard: React.FC<QuestionCardProps> = ({
  screener,
  screener_review,
  questions,
  question,
  attrs,
  style,
  editable: canEdit,
  dragHandleProps,
  number,
  debouncedChange,
  onChange,
  onDelete,
  onDuplicate,
  disableImgUpload,
  onReplace: handleReplaceQuestion,
  isUpdating
}) => {
  const [updateStudyScreener] = api.useUpdateStudyScreenerMutation();
  const [updateImage] = api.useUpdateStudyScreenerFieldImageMutation();

  const { label, helper, image_url } = question;

  const external = !!question.external_field_id;
  const editable = canEdit && !external;

  const [skipLogicOpen, setSkipLogicOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [altText, setAltText] = useState(!!question.image_alt);

  const showToast = useToaster();

  const hasIdealAnswers = screener.screener_type === 'pre';

  const CONFIGS = style === 'unmoderated_test' ? { ...FIELD_CONFIGS, ...UNMODERATED_FIELDS } : FIELD_CONFIGS;

  const config = CONFIGS[question.field_type] || ({ comp: NotImpl } as FieldConfig);

  const allowSkipSubmit = useFeature('allow_skip_screener_submit') && screener.screener_type === 'pre';

  const autoReview = screener_review === 'auto' && screener.screener_type === 'pre';
  const hasAnIdealAnswer = !!question.ideal_answer?.value;

  const change = <K extends keyof ScreenerField>(key: K, value: ScreenerField[K]): void => {
    const updatedQuestion = { ...question, [key]: value };
    debouncedChange(updatedQuestion);
  };

  useEffect(() => {
    if (isLoading && !isUpdating) {
      setIsLoading(false);
    }
  }, [isLoading, isUpdating]);

  const handleIdealAnswerSave = (answer: IdealAnswer) => {
    change('ideal_answer', answer);
  };

  const handleDrop = async (file: File[]) => {
    setUploading(true);
    const body = new FormData();
    body.append('screener_field[image]', file[0]);

    const endOfSurvey = isEndOfSurvey(question.id);
    let id = question.id;

    // if endOfSurvey, but we have screener field, it means the question is already created but id
    // is not yet updated. So we can use the first field id.
    if (endOfSurvey && screener.fields.length === 1) {
      id = screener.fields[0].id;
    }

    // if endOfSurvey and no fields, it means we need to create a new screener field
    if (endOfSurvey && !screener.fields.length) {
      try {
        const newScreener = await updateStudyScreener({
          ...screener,
          fields: preparePayload(questions)
        }).unwrap();
        id = newScreener.fields[0].id;
      } catch (e) {
        setUploading(false);
        showToast(toasts.FAILED_UPLOAD);
        return;
      }
    }
    try {
      const model = await updateImage({ id, body }).unwrap();

      if (model) {
        handleReplaceQuestion(question.id, model);
      }
    } catch (e) {
      showToast(toasts.FAILED_UPLOAD);
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveImage = async () => {
    setUploading(true);
    const body = new FormData();
    body.append('screener_field[image]', 'remove');

    try {
      const model = await updateImage({ id: question.id, body }).unwrap();

      if (model) {
        handleReplaceQuestion(question.id, model);
      }
    } catch (e) {
      showToast(toasts.FAILED_REMOVE);
    } finally {
      setUploading(false);
    }
  };

  function handleQuestionChange(field: ScreenerField) {
    const ideal_answer = { ...field.ideal_answer };
    if (
      field.options !== question.options &&
      ['multiple_choice', 'single_choice'].includes(field.field_type) &&
      ideal_answer?.value
    ) {
      ideal_answer.operator = ideal_answer.operator || null;
      ideal_answer.value = Array.isArray(ideal_answer.value)
        ? ideal_answer.value
        : [ideal_answer.value].filter((v) => (field.options || []).includes(v))[0];
    }

    const updatedQuestion = { ...field, ideal_answer: ideal_answer };
    debouncedChange(updatedQuestion);
  }

  const handleSkipLogicChange = (skip_logic: SkipLogic[] | null, next_screener_field_id: number | null = null) => {
    const updatedQuestion = { ...question, skip_logic, next_screener_field_id };
    debouncedChange(updatedQuestion);
    setSkipLogicOpen(false);
  };

  const handleChangeFieldType = (field_type: string, t: any) => {
    // if switching to a options-style question, give a freebie
    // if switching off of options-style, don't erase the options yet
    let { helper, options, label, skip_logic, required, number_range_options, ideal_answer } = question;
    // let param = null;

    if (['multiple_choice', 'single_choice'].includes(field_type) && (options || []).length === 0) {
      options = ['Option 1'];
    }

    if (field_type === 'multiple_choice' && question.helper === '') {
      helper = t('select_all_that_apply');
    }

    if (field_type != 'multiple_choice' && question.helper === t('select_all_that_apply')) {
      helper = '';
    }
    if (field_type === 'number_range' && !number_range_options) {
      number_range_options = { min: 1, max: 5, labels: {} };
    }
    if (['task'].includes(field_type)) {
      const defaults = CONFIGS[field_type].defaults || {};
      label = defaults.label;
      helper = defaults.helper;
      required = defaults.required;
    }

    if (shouldClearSkipLogic(question.field_type, field_type)) {
      skip_logic = null;
      // This is probably fine.
    }
    ideal_answer = { operator: null, value: null };

    const updatedQuestion = {
      ...question,
      label,
      helper,
      required,
      options,
      ideal_answer,
      field_type: field_type as ScreenerFieldType,
      skip_logic,
      number_range_options
    };
    debouncedChange(updatedQuestion);
  };

  const getSkipLogicValueLabel = (skipLogic: SkipLogic): string => {
    if (!skipLogic.value) return '';

    if (question.field_type === 'date') {
      return dateTemplate.render(new Date(+skipLogic.value));
    }

    if (['single_choice', 'multiple_choice'].includes(question.field_type)) {
      return slValueToArray(skipLogic.value).join(', ');
    }

    if (['any_answer', 'no_answer'].includes(question.field_type)) {
      return EMPTY_SKIP_LOGIC_VALUE;
    }

    return skipLogic.value;
  };

  const getNextQuestionFieldName = (skipLogic: SkipLogic): string | React.ReactNode => {
    if (!skipLogic) return EMPTY_QUESTION;

    if (skipLogic.to === END_OF_SURVEY) {
      if (autoReview && !hasAnIdealAnswer) {
        return SKIP_LOGIC_FINAL_STEP_EXCLUDE_FROM_AUTO_REVIEW();
      } else {
        return SKIP_LOGIC_FINAL_STEP;
      }
    }

    if (skipLogic.to === END_OF_SURVEY_WITH_AUTO_REVIEW) {
      return SKIP_LOGIC_FINAL_STEP;
    }

    if (skipLogic.to === END_OF_SURVEY_WITHOUT_SUBMIT) {
      return SKIP_LOGIC_FINAL_STEP_SKIP_SUBMIT();
    }

    const next = find(questions, (q) => q.id === skipLogic.to);
    const number = calculateNumber(
      questions,
      questions.findIndex((q) => q.id === next?.id)
    );

    return `#${number} ${next?.label ?? UNTITLED_QUESTION}`;
  };

  const skipLogicRenderer = React.useCallback(() => {
    const { skip_logic } = question;

    if (skip_logic) {
      return (
        <>
          {skip_logic.map((skipLogic, i) => {
            if (!skipLogic?.to) return null;

            const operator = SKIP_LOGIC_OP[skipLogic.op];
            const value = getSkipLogicValueLabel(skipLogic);
            const next = getNextQuestionFieldName(skipLogic);

            return (
              <span key={i}>
                If response {operator} <span className='font-bold'>{value}</span> skip to{' '}
                <span className='font-bold'>{next}</span>{' '}
              </span>
            );
          })}
        </>
      );
    }

    return null;
  }, [question, questions, autoReview, hasAnIdealAnswer]);

  const QuestionType = config.comp;

  const isSkipLogicAllowed = questions.length > 1 && question !== last(questions) && !config.simple;
  const attributable = !config.simple;

  const openSkipLogic = () => setSkipLogicOpen(true);
  const { t } = useTranslation('SurveyQuestion');

  return (
    <div id={`question-card-${question.id}`} className='relative bg-white border border-gray-200 rounded-sm'>
      {isLoading && <Loading absolute />}
      <SkipLogicSlideOut
        open={skipLogicOpen}
        onClose={() => setSkipLogicOpen(false)}
        autoReview={autoReview}
        hasAnIdealAnswer={hasAnIdealAnswer}
        questions={questions}
        question={question}
        onChange={handleSkipLogicChange}
        allowSkipSubmit={allowSkipSubmit}
      />

      <div className='flex justify-between p-1 border-b border-gray-200'>
        <div className='pt-1 pl-1' {...dragHandleProps}>
          {editable && (
            <Tippy maxWidth={240} arrow={false} content='Drag to reorder position'>
              <span className='hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'>
                <DragSVG className='text-gray-700' />
              </span>
            </Tippy>
          )}
        </div>
        <div className='flex items-center pr-2'>
          {question.field_type !== 'info' && (
            <>
              <Toggle
                disabled={!editable}
                className='mx-2'
                on={question.required}
                onToggle={(on) => change('required', on)}
              />
              <span className='mr-4 text-sm text-gray-500'>Required</span>
            </>
          )}
          {editable && (
            <Tippy maxWidth={240} arrow={false} content='Duplicate'>
              <button
                aria-label='Duplicate question'
                name='duplicate'
                type='button'
                onClick={() => {
                  setIsLoading(true);
                  onDuplicate();
                }}
                className='hover:bg-gray-100 flex items-center justify-center w-8 h-8 text-gray-500 rounded-full'
              >
                <DuplicateSVG />
              </button>
            </Tippy>
          )}
          {editable && (
            <Tippy maxWidth={240} arrow={false} content='Delete'>
              <button
                name='delete'
                aria-label='Delete question'
                type='button'
                onClick={onDelete}
                className='hover:bg-gray-100 flex items-center justify-center w-8 h-8 text-gray-500 rounded-full'
                data-testid='xx-delete'
              >
                <TrashSVG />
              </button>
            </Tippy>
          )}
        </div>
      </div>
      <div className='tablet:flex relative p-4 pb-6 border-b border-gray-200'>
        <div className='tablet:px-6'>
          <div className='text-lg font-bold text-gray-700'>{number}.</div>
        </div>

        <div className='flex-1'>
          <div className='tablet:flex flex-1'>
            <div className='xx-name-helper flex-1'>
              <TextareaAutosize
                name='title'
                disabled={!editable}
                className='focus:ring-indigo-500 focus:border-indigo-500 block w-full px-0 py-0 mb-2 text-lg font-bold leading-normal text-gray-700 placeholder-gray-400 border-0 outline-none resize-none'
                placeholder='Write your question…'
                value={label}
                onChange={(e) => debouncedChange({ ...question, label: e.currentTarget.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') e.currentTarget.blur();
                }}
              />
              {config.helper && (
                <TextareaAutosize
                  disabled={!editable}
                  className='block w-full px-0 py-0 mb-8 leading-normal text-gray-700 placeholder-gray-400 border-0 outline-none resize-none'
                  placeholder='Add helper text (optional)'
                  value={helper}
                  onChange={(e) => debouncedChange({ ...question, helper: e.currentTarget.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) e.currentTarget.blur();
                  }}
                />
              )}
            </div>

            {!disableImgUpload && editable && (
              <div>
                <UploadButton
                  supportedFileTypes={['png', 'eps', 'jpg', 'jpeg', 'gif']}
                  aria-label='Add image'
                  icon='picture'
                  text
                  uploadZoneProps={{
                    heading: 'Upload image',
                    subheading: 'The image will be displayed within the question block.',
                    fileTypesText: 'We support PNG, EPS, JPEG or GIF files of any size.'
                  }}
                  onUploadFiles={handleDrop}
                  hasFileUploadZone
                >
                  Add image
                </UploadButton>
              </div>
            )}

            <div className='tablet:ml-2 tablet:my-0 my-4'>
              <select
                className='h400'
                disabled={!editable}
                name='field_type'
                data-testid='Question type select'
                value={question.field_type}
                onChange={(e) => handleChangeFieldType(e.currentTarget.value, t)}
              >
                {Object.keys(CONFIGS).map((key) => (
                  <option key={key} value={key}>
                    {CONFIGS[key].label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {image_url && !uploading && (
            <div className='relative flex justify-center mt-4 mb-6 border border-gray-200 rounded-md'>
              <ZoomableImage
                src={image_url}
                alt={question.image_alt || 'Question image'}
                className='w-96 object-contain object-center h-48'
              />
              <div className='top-2 right-2 absolute flex items-center space-x-2'>
                <Tippy
                  maxWidth={240}
                  arrow={false}
                  content='Add Alt text, or alternative text, to describe the image to help visually impaired users understand through screen readers.'
                >
                  <Button
                    text
                    aria-label='Add alt text'
                    onClick={() => setAltText(!altText)}
                    className='pt-2 pr-2 pb-2 pl-2 rounded-full'
                  >
                    <BubblePictureSVG />
                  </Button>
                </Tippy>
                <Tippy maxWidth={240} arrow={false} content='Delete image'>
                  <Button text onClick={handleRemoveImage} className='pt-2 pr-2 pb-2 pl-2 rounded-full'>
                    <TrashSVG />
                  </Button>
                </Tippy>
              </div>
            </div>
          )}
          {image_url && altText && (
            <div>
              <Label
                htmlFor={`alt-${question.id}`}
                tooltip='Add description of images which are read aloud to blind users on a screen reader.'
              >
                ALT text
              </Label>
              <Input
                name={`alt-${question.id}`}
                value={question.image_alt || 'Question image'}
                className='mb-6 w-full'
                placeholder='Add your ALT text here...'
                onChange={(v) => change('image_alt', v)}
              />
            </div>
          )}
          {uploading && <Spinner className='w-4 h-4 mx-auto my-6' />}

          <QuestionType
            question={question}
            onChange={handleQuestionChange}
            config={{ hasIdealAnswers }}
            readOnly={!editable}
          />
        </div>
      </div>

      {question.skip_logic && question.skip_logic.length > 0 && (
        <div className='flex items-center p-1 border-b border-gray-200'>
          <div className='flex-1'>
            <div className='flex items-center p-3 space-x-2 text-gray-700'>
              <SkipLogicSVG className='flex-shrink-0 text-indigo-600' />
              <span className='text-sm'>{skipLogicRenderer()}</span>
            </div>
          </div>
          {editable && (
            <div className='pr-2'>
              <Tippy maxWidth={240} interactive arrow={false} content='Edit skip logic'>
                <button
                  type='button'
                  className='hover:bg-gray-100 flex items-center justify-center w-10 h-10 text-gray-500 rounded-full'
                  onClick={openSkipLogic}
                >
                  <PencilSVG />
                </button>
              </Tippy>
            </div>
          )}
        </div>
      )}

      <div className='flex items-center p-4 space-x-4'>
        <div className='flex'>
          <AttributeSelector
            editable={canEdit}
            attributable={attributable}
            question={question}
            hasResponses={screener.responses_count > 0}
            onChange={debouncedChange}
            attrs={attrs}
          />
        </div>

        {hasIdealAnswers && (
          <div className='flex'>
            <IdealAnswer
              field={question}
              editable={editable}
              onSave={handleIdealAnswerSave}
              key={`ideal-${question.id}-${question.field_type}`}
            />
          </div>
        )}

        <div className='flex'>
          {isSkipLogicAllowed && (!question.skip_logic || question.skip_logic.length === 0) && (
            <Button small onClick={openSkipLogic} disabled={!editable}>
              <SkipLogicSVG />
              <span className='whitespace-nowrap ml-2'>Skip logic</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
