import * as React from 'react';

import { Avatar, AvatarFromId, DropdownCombobox, Text } from '@components/common';
import { DropdownComboboxProps } from '@components/common/DropdownCombobox';

type U = User | TeamUser;

interface Props extends Partial<Omit<DropdownComboboxProps, 'onSelect'>> {
  disabled?: boolean;
  placeholder?: string;
  users: U[];
  selectedId?: number | null;
  onSelect: (id: number) => void;
  onSelectEmailOnly?: (value: string) => void;
  renderNewItem?: DropdownComboboxProps['renderItem'];
}
export const UserSelect: React.FC<Props> = ({
  disabled,
  placeholder,
  users,
  selectedId,
  onSelect,
  onSelectEmailOnly,
  renderNewItem,
  ...props
}) => {
  const items = users.map(({ name, id, email }) => ({
    label: name,
    value: String(id),
    email,
    search: [name, email].join(' ')
  }));

  const selectedUser = users.find(({ id }) => id === selectedId);

  return (
    <DropdownCombobox
      key={items.length}
      inputClassName='xx-user-select border-gray-300'
      disabled={disabled}
      items={items}
      resetOnBlur
      resetOnSelect
      allowCreate={!!onSelectEmailOnly}
      placeholder={
        selectedUser ? (
          <div className='flex items-center h-full px-3'>
            <AvatarFromId userId={selectedUser.id} className='mr-2' />
            <Text h='400'>{selectedUser.name}</Text>
          </div>
        ) : (
          'Enter a name or email'
        )
      }
      onSelect={(item) => {
        if (item?._isNew) {
          onSelectEmailOnly?.(item.value);
        } else {
          onSelect(parseInt(item?.value as any));
        }
      }}
      renderItem={(item, isHighlighted) => {
        if (item._isNew && renderNewItem) {
          return renderNewItem(item);
        }
        const user = users.find(({ id }) => id === parseInt(item.value));

        return (
          <div className='flex items-center py-1'>
            {user && <Avatar size='lg' className='mr-4' user={user} />}
            <div>
              <Text h='400'>{item.label}</Text>
              {user && (
                <Text className='group-hover:text-white' color={isHighlighted ? 'white' : 'gray-500'} h='200'>
                  {user.email}
                </Text>
              )}
            </div>
          </div>
        );
      }}
      {...props}
    />
  );
};
