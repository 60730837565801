import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { BooleanValue } from '@components/CandidateProfile/components';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { useOnClickOutside } from '@components/utils';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';
import { getStateKey } from '@components/shared/GridTable/components/utils';

export const BooleanCell: EditableCell = (props) => {
  const { state, setState } = useContext(GridContext);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setEditing(false));

  const { value: initialValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const [editing, setEditing] = useState(false);

  const isCustomAttr = column.id.slice(0, 5) === 'extra';

  if (!isCustomAttr) {
    return <CellWrapper>{initialValue === undefined ? '' : <BooleanValue value={initialValue} />}</CellWrapper>;
  }

  const onSave = async (currentValue) => {
    setState?.((prev) => {
      return {
        ...prev,
        [stateKey]: { ...prev[stateKey], [column.id]: currentValue }
      };
    });

    const attr = column.id.slice(0, 5) === 'extra' ? column.id.slice(6) : column.id;

    setEditing(false);

    await updateCellData(+stateKey, attr, currentValue);
  };

  const getCellValue = () => {
    if (typeof state?.[stateKey]?.[column?.id] === 'boolean') {
      return state?.[stateKey]?.[column?.id];
    }

    if (state?.[stateKey]?.[column?.id] !== null && typeof initialValue === 'boolean') {
      return initialValue;
    }

    return '';
  };

  const cellValue = getCellValue();

  if (!editing) {
    return (
      <CellWrapper wrapperClass='cursor-pointer hover:bg-indigo-50' onClick={() => setEditing(true)}>
        {typeof cellValue === 'boolean' ? <BooleanValue value={cellValue} /> : ''}
      </CellWrapper>
    );
  }

  return (
    <div ref={ref} className='flex flex-row items-center space-x-3 px-4 py-2.5'>
      <label>
        <input className='mr-1' type='radio' value={'1'} checked={cellValue === true} onChange={() => onSave(true)} />
        Yes
      </label>
      <label>
        <input className='mr-1' type='radio' value={'0'} checked={cellValue === false} onChange={() => onSave(false)} />
        No
      </label>
      <a className='pl-2 text-xs cursor-pointer' onClick={() => onSave(null)}>
        Clear
      </a>
    </div>
  );
};
