import * as React from 'react';

import { CellFC } from './types';

export const Thumbnail: CellFC = ({ row: { original: item }, cell }) => {
  const src = item.session.recording?.mux_video?.thumbnail_url;

  const style = src
    ? {
        backgroundImage: `url(${src})`
      }
    : {};

  return <div className='w-16 h-10 bg-gray-200 bg-center bg-cover rounded-sm' style={style} />;
};
