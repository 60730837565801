import { useRef } from 'react';

import { AIBots, useLazySuggestions, AI } from '@api/chat-gpt';
import { useTags } from '@stores/tags';

interface Args {
  studyId?: number | null;
}

type Result = { fetch: (text: string) => Promise<Tag | void>; abort: () => void };

export const useTagAiSuggestions = ({ studyId }: Args): Result => {
  const { fetch } = useLazySuggestions<string, { text?: string; study_id?: number | null }>({
    id: AIBots.TranscriptTextTags,
    context: { text: '', study_id: studyId }
  });

  const promisesRef = useRef<AI.FetchResult<string, { text?: string; study_id?: number | null }>[]>([]);

  const { tags = [], getTag } = useTags();

  const fetchSuggestions = async (text: string) => {
    const promise = fetch({ text });

    promisesRef.current.push(promise);

    const data = await promise.unwrap();

    if (data && data.length > 0) {
      const suggestions = data.map(({ result }) => result);
      // should not have to do this
      const suggestion = suggestions.find((s) => !s.includes('none'));

      const tag = tags.find(({ name }) => name === suggestion);

      return tag;
    }
  };

  const abort = () => {
    promisesRef.current.forEach((p) => p.abort());
  };

  return { fetch: fetchSuggestions, abort };
};
