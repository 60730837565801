import { useEffect, useMemo, useState } from 'react';

import consumer from '@helpers/consumer';
import { api } from 'api/reduxApi';

const CHANNEL = 'Users::CalendarsChannel';

interface Args {
  userId: number;
}

export const useUserCalendars = ({ userId }: Args) => {
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const { data = [], isFetching, isError, error } = api.useGetUserCalendarsQuery(userId);

  useEffect(() => {
    if (!isFetching && !isError) {
      const sortedCalendars = [...data].sort((a, b) => (b.write_access ? 1 : 0) - (a.write_access ? 1 : 0));
      setCalendars(sortedCalendars);
    }
  }, [data, isFetching]);

  const channel = useMemo(
    () =>
      userId
        ? consumer.subscriptions.create({
            channel: CHANNEL,
            user_id: userId
          })
        : null,
    [consumer, userId]
  );

  useEffect(() => {
    if (channel) {
      channel.received = (data) => {
        setCalendars(data.message);
      };
    }
  }, [channel]);

  return { calendars, isLoading: isFetching };
};
