import React, { SVGProps } from 'react';

export const SurveyTemplateSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='120' height='100' viewBox='0 0 120 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x='0.5' y='0.5' width='119' height='99' rx='2.5' fill='white' stroke='#E5E7EB' />
    <circle cx='16' cy='18' r='6.5' stroke='#FF5A1F' />
    <circle cx='16' cy='18' r='3.5' fill='#FF5A1F' stroke='#FF5A1F' />
    <rect x='27' y='12' width='85' height='4' fill='#E5E7EB' />
    <rect x='27' y='20' width='50' height='4' fill='#E5E7EB' />
    <line x1='8' y1='33.5' x2='112' y2='33.5' stroke='#E5E7EB' />
    <path
      d='M9.4375 49.999C9.4375 51.7395 10.1289 53.4087 11.3596 54.6394C12.5903 55.8701 14.2595 56.5615 16 56.5615C17.7405 56.5615 19.4097 55.8701 20.6404 54.6394C21.8711 53.4087 22.5625 51.7395 22.5625 49.999C22.5625 48.2585 21.8711 46.5893 20.6404 45.3586C19.4097 44.1279 17.7405 43.4365 16 43.4365C14.2595 43.4365 12.5903 44.1279 11.3596 45.3586C10.1289 46.5893 9.4375 48.2585 9.4375 49.999V49.999Z'
      stroke='#9FA6B2'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='27' y='44' width='72' height='4' fill='#E5E7EB' />
    <rect x='27' y='52' width='42' height='4' fill='#E5E7EB' />
    <line x1='8' y1='65.5' x2='112' y2='65.5' stroke='#E5E7EB' />
    <path
      d='M9.4375 81.999C9.4375 83.7395 10.1289 85.4087 11.3596 86.6394C12.5903 87.8701 14.2595 88.5615 16 88.5615C17.7405 88.5615 19.4097 87.8701 20.6404 86.6394C21.8711 85.4087 22.5625 83.7395 22.5625 81.999C22.5625 80.2585 21.8711 78.5893 20.6404 77.3586C19.4097 76.1279 17.7405 75.4365 16 75.4365C14.2595 75.4365 12.5903 76.1279 11.3596 77.3586C10.1289 78.5893 9.4375 80.2585 9.4375 81.999V81.999Z'
      stroke='#9FA6B2'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect x='27' y='76' width='72' height='4' fill='#E5E7EB' />
    <rect x='27' y='84' width='42' height='4' fill='#E5E7EB' />
  </svg>
);
