import React, { SVGProps } from 'react';

export const RightAlignSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1491_1803)'>
      <path
        d='M3.77083 9.3125H13.9792C13.9792 9.3125 14.5625 9.3125 14.5625 9.89583V12.2397C14.5625 12.2397 14.5625 12.823 13.9792 12.823H3.77083C3.77083 12.823 3.1875 12.823 3.1875 12.2397V9.89583C3.1875 9.89583 3.1875 9.3125 3.77083 9.3125Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.77083 3.17773H9.16667C9.16667 3.17773 9.75 3.17773 9.75 3.76107V6.1049C9.75 6.1049 9.75 6.68823 9.16667 6.68823H3.77083C3.77083 6.68823 3.1875 6.68823 3.1875 6.1049V3.76107C3.1875 3.76107 3.1875 3.17773 3.77083 3.17773Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.4375 1.4375V14.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1491_1803'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
