import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Text } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../toasts';
import { StudyActionModal, StudyActionModalProps } from '../StudyActionModal/StudyActionModal';

export const RequestApprovalStudyModal: React.FC<StudyActionModalProps> = (props) => {
  const showToast = useToaster();
  const [createRequest, { isSuccess, isError, isLoading }] = api.useCreateApprovalRequestMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successRequestedApproval(props.title));
      props.onAfterSubmit?.();
      props.onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedRequestedApproval());
    }
  }, [isError]);

  return (
    <StudyActionModal
      {...props}
      heading='Request invite approval'
      loading={isLoading}
      primaryButtonProps={{
        primary: true,
        children: 'Submit for approval'
      }}
      onSubmit={async () => {
        createRequest({ id: props.id });
      }}
    >
      <div className='my-1'>
        <Text mb='6'>Approval is required by your account Admin before you can invite participants to your study.</Text>
        <Text mb='6'>
          On submit, your Admin will be notified and will review the study to ensure it meets company guidelines.
        </Text>
        <Text mb='4'>
          Once your study has been approved you will be notified and will be able to invite participants.
        </Text>
      </div>
    </StudyActionModal>
  );
};
