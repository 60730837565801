import { useContext } from 'react';

import { DraggableCardContext } from '../context/draggableCardContext';

import { DraggableCard } from './useDraggableCard';

export const useDraggableCardContext = () => {
  const draggableCardContext = useContext<DraggableCard | undefined>(DraggableCardContext);

  if (draggableCardContext === undefined)
    throw new Error("Don't forget to wrap your component with <DraggableCardProvider>!");

  return draggableCardContext;
};
