import React from 'react';

import { Button, Input, Text } from '@components/common';
import { Tab } from '@components/GQSurveyBuilder/QuestionBank/index';

interface Props {
  tabs: Tab[];
  getClassName: (tab: Tab) => string;
  onClick: (tab: Tab) => void;
}

export const Tabs: React.FC<Props> = ({ tabs, getClassName, onClick }) => (
  <div className='border-b border-gray-200'>
    {tabs.map((tab) => {
      return (
        <button key={tab} className={getClassName(tab)} onClick={() => onClick(tab)}>
          {tab}
        </button>
      );
    })}
  </div>
);
