import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Loading } from '@components/common';
import { track } from '@components/tracking';

type Props<T extends AbstractTemplate> = {
  kind: TemplateKind;
  getQuery: (id: number) => { data: T; isLoading: boolean };
  render: RenderTemplateKind<T>;
  trackEvent?: string;
};
export const TemplatePage = <T extends AbstractTemplate>(props: Props<T>): React.ReactElement => {
  const params = useParams() as { id: string };
  const id = parseInt(params.id);
  const { data, isLoading } = props.getQuery(id);
  const { render, trackEvent } = props;
  const [template, setTemplate] = useState<T>();

  useEffect(() => {
    if (data) {
      setTemplate(data);
    }
  }, [data]);

  useEffect(() => {
    if (trackEvent) {
      track(trackEvent, { id });
    }
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      {template && render([template, setTemplate])}
    </>
  );
};
