import { TimezoneDesc } from '../../../shared/TimezonePicker';

export const buildTimeProposalSlots = (now: Date, startDate: Date, endDate: Date, tz: TimezoneDesc): Timeslots => {
  const dayCount = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

  const dates = Array.from({ length: dayCount + 14 }, (_, i) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    return date;
  }).map((d) => d.toISOString().split('T')[0]);

  return Object.fromEntries(
    dates.map((d) => [
      d,
      Array.from(Array(24))
        .map((_, i) => [`${i.toString().padStart(2, '0')}:00`, `${i.toString().padStart(2, '0')}:30`])
        .flat()
        .filter((a) => {
          const date = new Date(`${d}T${a}:00.000Z`);
          const timestamp = date.getTime() - tz.currentTimeOffsetInMinutes * 60 * 1000;

          return timestamp >= +now;
        })
    ])
  );
};
