import * as React from 'react';

import { Spinner } from '@components/common';

import { DropdownProps } from '../../types';
import { MultiSelectWithSearch } from './MultiSelectWithSearch';

export type Meta = { participations: Pick<Participation, 'customer_id' | 'name'>[] };

type Props<D = any> = DropdownProps<D, number[], Meta>;

export const Participant: React.FC<Props> = (props) => {
  const { meta } = props;

  type Option = { label: string; value: number };
  let options: Option[] = [];

  if (meta) {
    options = meta.participations.filter(Boolean).map((p) => ({
      value: p.customer_id,
      label: p.name || 'Unnamed'
    }));
  }

  if (!meta) {
    return (
      <div className='flex items-center justify-center h-40'>
        <Spinner />
      </div>
    );
  }

  return <MultiSelectWithSearch {...props} options={options} />;
};
