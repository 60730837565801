import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Show } from './pages/Show';
import { Index } from './pages/Index';

export const AiSearchApp: React.FC = () => {
  return (
    <Routes>
      <Route path=':id' element={<Show />} />
      <Route path='' element={<Index />} />
    </Routes>
  );
};
