import React, { SVGProps } from 'react';

export const ProposeTimeSVG = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 15.5H13C13.6628 15.4993 14.2983 15.2357 14.767 14.767C15.2357 14.2983 15.4993 13.6628 15.5 13V4C15.4993 3.33717 15.2357 2.70168 14.767 2.23299C14.2983 1.7643 13.6628 1.50069 13 1.5H12.5V1C12.5 0.867392 12.4473 0.740215 12.3536 0.646447C12.2598 0.552678 12.1326 0.5 12 0.5C11.8674 0.5 11.7402 0.552678 11.6464 0.646447C11.5527 0.740215 11.5 0.867392 11.5 1V1.5H4.5V1C4.5 0.867392 4.44732 0.740215 4.35355 0.646447C4.25979 0.552678 4.13261 0.5 4 0.5C3.86739 0.5 3.74021 0.552678 3.64645 0.646447C3.55268 0.740215 3.5 0.867392 3.5 1V1.5H3C2.33717 1.50069 1.70169 1.7643 1.23299 2.23299C0.764301 2.70168 0.500688 3.33717 0.5 4V13C0.500688 13.6628 0.764301 14.2983 1.23299 14.767C1.70169 15.2357 2.33717 15.4993 3 15.5ZM13 14.5H3C2.60231 14.4995 2.22104 14.3414 1.93984 14.0602C1.65863 13.779 1.50045 13.3977 1.5 13V5.5H14.5V13C14.4995 13.3977 14.3414 13.779 14.0602 14.0602C13.779 14.3414 13.3977 14.4995 13 14.5ZM3 2.5H3.5V3C3.5 3.13261 3.55268 3.25979 3.64645 3.35355C3.74021 3.44732 3.86739 3.5 4 3.5C4.13261 3.5 4.25979 3.44732 4.35355 3.35355C4.44732 3.25979 4.5 3.13261 4.5 3V2.5H11.5V3C11.5 3.13261 11.5527 3.25979 11.6464 3.35355C11.7402 3.44732 11.8674 3.5 12 3.5C12.1326 3.5 12.2598 3.44732 12.3536 3.35355C12.4473 3.25979 12.5 3.13261 12.5 3V2.5H13C13.3977 2.50045 13.779 2.65863 14.0602 2.93984C14.3414 3.22104 14.4995 3.60231 14.5 4V4.5H1.5V4C1.50045 3.60231 1.65863 3.22104 1.93984 2.93984C2.22104 2.65863 2.60231 2.50045 3 2.5Z'
      fill='currentColor'
    />
    <path
      d='M10.8529 8.14586L9.8535 7.14646C9.7592 7.05538 9.6329 7.00498 9.5018 7.00612C9.3707 7.00726 9.24529 7.05985 9.15259 7.15255C9.05989 7.24525 9.0073 7.37066 9.00616 7.50176C9.00502 7.63286 9.05542 7.75916 9.1465 7.85346L9.293 7.99996H5.5C5.36739 7.99996 5.24021 8.05264 5.14645 8.14641C5.05268 8.24017 5 8.36735 5 8.49996C5 8.63257 5.05268 8.75974 5.14645 8.85351C5.24021 8.94728 5.36739 8.99996 5.5 8.99996H9.293L9.1465 9.14646C9.09874 9.19258 9.06065 9.24776 9.03445 9.30876C9.00824 9.36976 8.99445 9.43537 8.99387 9.50176C8.9933 9.56815 9.00595 9.63399 9.03109 9.69544C9.05623 9.75688 9.09336 9.81271 9.1403 9.85966C9.18725 9.9066 9.24308 9.94373 9.30452 9.96887C9.36597 9.99401 9.43181 10.0067 9.4982 10.0061C9.56459 10.0055 9.6302 9.99171 9.6912 9.96551C9.7522 9.93931 9.80738 9.90121 9.8535 9.85346L10.8529 8.85406C10.9466 8.76004 10.9992 8.63271 10.9992 8.49996C10.9992 8.36721 10.9466 8.23988 10.8529 8.14586Z'
      fill='currentColor'
    />
    <path
      d='M10.5 11H6.707L6.8535 10.8535C6.94458 10.7592 6.99498 10.6329 6.99384 10.5018C6.9927 10.3707 6.94012 10.2453 6.84741 10.1526C6.75471 10.0598 6.6293 10.0073 6.4982 10.0061C6.3671 10.005 6.2408 10.0554 6.1465 10.1465L5.1471 11.1459C5.05339 11.2399 5.00077 11.3672 5.00077 11.5C5.00077 11.6327 5.05339 11.76 5.1471 11.8541L6.1465 12.8535C6.2408 12.9445 6.3671 12.9949 6.4982 12.9938C6.6293 12.9927 6.75471 12.9401 6.84741 12.8474C6.94012 12.7547 6.9927 12.6293 6.99384 12.4982C6.99498 12.3671 6.94458 12.2408 6.8535 12.1465L6.707 12H10.5C10.6326 12 10.7598 11.9473 10.8536 11.8535C10.9473 11.7597 11 11.6326 11 11.5C11 11.3674 10.9473 11.2402 10.8536 11.1464C10.7598 11.0526 10.6326 11 10.5 11Z'
      fill='currentColor'
    />
  </svg>
);
