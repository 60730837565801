import React, { SVGProps } from 'react';

export const InterviewGuideSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1774_19182)'>
      <path
        d='M2.91732 1.7487C2.91732 1.43928 2.7944 1.14253 2.57561 0.92374C2.35682 0.704948 2.06007 0.582031 1.75065 0.582031C1.44123 0.582031 1.14449 0.704948 0.925693 0.92374C0.706901 1.14253 0.583984 1.43928 0.583984 1.7487'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.75 0.582031H11.0833C11.3928 0.582031 11.6895 0.704948 11.9083 0.92374C12.1271 1.14253 12.25 1.43928 12.25 1.7487V3.20703L11.865 3.72036'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.91602 1.75V6.70833L3.79102 7.875L2.91602 9.04167V12.25C2.91602 12.5594 3.03893 12.8562 3.25772 13.075C3.47652 13.2937 3.77326 13.4167 4.08268 13.4167H6.41602'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.16602 2.91797H9.91602'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.54102 2.91797H6.41602'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.54102 5.30859H9.30702'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.54102 7.88281H7.29102'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.54102 10.582H6.70768'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.0957 13.3102L10.5177 9.73438'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.1615 5.83203C12.7709 5.86473 12.3849 5.93884 12.01 6.05311L11.2966 7.88595L10.4385 6.8307C8.36239 8.39636 7.98848 11.2874 9.00114 11.9734C10.3574 12.8939 14.4757 10.032 13.1615 5.83203Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1774_19182'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
