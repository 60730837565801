import React, { useState, VFC } from 'react';
import { DailyVideo, useLocalSessionId } from '@daily-co/daily-react';
import cn from 'classnames';

import { Text } from '@components/common';
import { StopSVG } from '@components/svgs';
import { useDeviceType } from '@hooks/useDeviceType';

import { useParticipantRecording } from '../hooks/useParticipantRecording';

import { CancelConfirmModal } from './CancelConfirmationModal';

export const RecordingIndicator: VFC = () => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const { isEnabled, endRecording, isRecording } = useParticipantRecording();

  const sessionId = useLocalSessionId();

  const { isMobile } = useDeviceType();

  const buttonClass = 'hover:bg-gray-100 hover:text-indigo-600 m-1 text-gray-700 rounded-full cursor-pointer';

  if (!isEnabled) {
    return null;
  }

  return (
    <div
      className={cn('fixed', {
        'top-2 left-1/2 absolute transform -translate-x-1/2': isMobile,
        'left-6 bottom-6': !isMobile
      })}
      style={{ zIndex: 9999 }}
    >
      <div className='flex items-center p-2 space-x-2 bg-white border border-gray-200 rounded-full shadow-lg'>
        {!isMobile && (
          <div className='flex items-center w-6 h-6 overflow-hidden bg-gray-100 rounded-full'>
            <DailyVideo className='w-full h-full' sessionId={sessionId} type='video' fit='cover' />
          </div>
        )}

        <div
          className={cn('w-2 h-2 rounded-full', {
            'bg-red-500': isRecording,
            'bg-gray-200': !isRecording
          })}
        />

        <Text h='300'>{isRecording ? 'recording' : 'not recording'}</Text>

        {isRecording && (
          <button
            name='Stop Recording'
            aria-label='Stop Recording'
            className={buttonClass}
            onClick={() => setConfirmModalIsOpen(true)}
          >
            <StopSVG className='w-4 h-4' />
          </button>
        )}
      </div>

      {confirmModalIsOpen && (
        <CancelConfirmModal
          onCancel={() => setConfirmModalIsOpen(false)}
          onConfirm={() => {
            endRecording(true);

            setTimeout(() => {
              window.location.reload();
            }, 100);
          }}
        />
      )}
    </div>
  );
};
