import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import { Text } from '@components/common';
import { CheckSVG } from '@components/svgs';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  isComplete?: boolean;
  level?: number;
  position?: number;
}

export const Heading = forwardRef<HTMLHeadingElement, Props>(({ children, level = 3, position, isComplete }, ref) => {
  return (
    <div className='flex items-center'>
      <div
        className={cn('text-sm font-bold leading-none w-6 h-6 mr-6 rounded-full flex items-center justify-center', {
          'bg-indigo-600': isComplete,
          'border border-gray-200': !isComplete
        })}
      >
        {isComplete ? <CheckSVG className='w-3 h-3 text-white' /> : position}
      </div>
      <Text ref={ref} role='heading' aria-level={level} bold>
        {children}
      </Text>
    </div>
  );
});
