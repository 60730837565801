import React from 'react';

export const BuildingSVG = () => {
  return (
    <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2001_526)'>
        <path
          d='M12.6875 3.9375H1.3125V13.5625H12.6875V3.9375Z'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.4375 10.9375H6.5625C6.33044 10.9375 6.10788 11.0297 5.94378 11.1938C5.77969 11.3579 5.6875 11.5804 5.6875 11.8125V13.5625H8.3125V11.8125C8.3125 11.5804 8.22031 11.3579 8.05622 11.1938C7.89212 11.0297 7.66956 10.9375 7.4375 10.9375Z'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.0625 5.6875V6.5625'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.6875 5.6875V6.5625'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.3125 5.6875V6.5625'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.9375 5.6875V6.5625'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.0625 8.3125V9.1875'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.6875 8.3125V9.1875'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.3125 8.3125V9.1875'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.9375 8.3125V9.1875'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M0.4375 3.9375H13.5625'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.1294 0.4375H2.87058C2.67548 0.437521 2.48598 0.502747 2.3322 0.622813C2.17842 0.742879 2.06918 0.910897 2.02183 1.10017L1.3125 3.9375H12.6875L11.9782 1.10017C11.9308 0.910897 11.8216 0.742879 11.6678 0.622813C11.514 0.502747 11.3245 0.437521 11.1294 0.4375V0.4375Z'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.0625 10.9375V11.8125'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.9375 10.9375V11.8125'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2001_526'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
