export interface Keys {
  shift: boolean;
  alt: boolean;
  meta: boolean;
  [key: string]: boolean;
}

const SYMBOLS = {
  cmd: '\u2318',
  option: '\u2325',
  shift: '\u21E7',
  ctrl: '\u2303',
  alt: 'alt'
};

const IS_MAC = window.navigator.userAgent.includes('Macintosh');

export const getOSShortcut = (keys: Partial<Keys>) => {
  const { shift, alt, meta, ...rest } = keys;
  const keyStrings = [...Object.keys(rest)];
  const shortcut: string[] = [];

  if (meta) shortcut.push(IS_MAC ? SYMBOLS.cmd : SYMBOLS.ctrl);

  if (shift) shortcut.push(SYMBOLS.shift);

  if (alt) shortcut.push(IS_MAC ? SYMBOLS.option : SYMBOLS.alt);

  if (keyStrings.length) shortcut.push(...keyStrings);

  return shortcut;
};
