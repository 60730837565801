import { renderComponent } from '@components/utils';
import { buildProject } from '@api/builders';

import { BookingPage } from './pages/BookingPage';
import { NewTimeProposalPage } from './pages/NewTimeProposalPage';

export function mount(): void {
  renderComponent(BookingPage, 'BookingPage', ({ study, participation }) => ({
    study: buildProject(study.data),
    participation: participation ? participation.data.attributes : undefined
  }));
  renderComponent(NewTimeProposalPage, 'NewTimeProposalPage', ({ study, participation }) => ({
    study: buildProject(study.data),
    participation: participation ? participation.data.attributes : undefined
  }));
}
