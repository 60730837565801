import React, { SVGProps } from 'react';

export const IncentiveIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.5 9C6.5 9.29667 6.58797 9.58668 6.7528 9.83336C6.91762 10.08 7.15189 10.2723 7.42598 10.3858C7.70007 10.4994 8.00166 10.5291 8.29264 10.4712C8.58361 10.4133 8.85088 10.2704 9.06066 10.0607C9.27044 9.85088 9.4133 9.58361 9.47118 9.29264C9.52906 9.00167 9.49935 8.70007 9.38582 8.42598C9.27229 8.15189 9.08003 7.91762 8.83336 7.7528C8.58668 7.58797 8.29667 7.5 8 7.5C7.70333 7.5 7.41332 7.41203 7.16665 7.24721C6.91997 7.08238 6.72771 6.84811 6.61418 6.57403C6.50065 6.29994 6.47094 5.99834 6.52882 5.70737C6.5867 5.41639 6.72956 5.14912 6.93934 4.93934C7.14912 4.72956 7.41639 4.5867 7.70737 4.52882C7.99834 4.47094 8.29994 4.50065 8.57403 4.61418C8.84811 4.72771 9.08238 4.91997 9.24721 5.16665C9.41203 5.41332 9.5 5.70333 9.5 6'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M8 3.5V4.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 10.5V11.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M15 6.25C15.0039 8.28756 14.3123 10.2654 13.0394 11.8565C11.7665 13.4476 9.98874 14.5565 8.00002 15C6.01129 14.5565 4.2335 13.4476 2.96064 11.8565C1.68778 10.2654 0.996112 8.28756 1.00002 6.25V2.75C1.00002 2.28587 1.18439 1.84075 1.51258 1.51256C1.84077 1.18437 2.28589 1 2.75002 1H13.25C13.7141 1 14.1593 1.18437 14.4875 1.51256C14.8156 1.84075 15 2.28587 15 2.75V6.25Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
