import * as React from 'react';
import { createContext } from 'react';

// Wondering if we should just call this role store?
interface PermissionsStore {
  role: AccountRole;
}

export const store = createContext<PermissionsStore>({} as PermissionsStore);

const { Provider: ProviderEl } = store;

export const Provider: React.FC<{ state: PermissionsStore }> = ({ state, children }) => {
  return <ProviderEl value={state}>{children}</ProviderEl>;
};
