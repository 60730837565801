import { forwardRef } from 'react';
import { ItemProps } from '@components/shared/SelectDropdown/components/Item';
import { useStudies } from '@hooks/useStudies';
import cn from 'classnames';
import { Avatar } from '@components/common';
import { CheckSVG } from '@components/svgs';
import * as React from 'react';

export const Item = forwardRef<HTMLLIElement, ItemProps>(
  ({ __dropdownItemProps: { item, active, highlighted }, ...rest }, ref) => {
    const { studies } = useStudies();

    const study = item.created ? null : studies?.find(({ id }) => id.toString() === item.value);

    return (
      <li
        ref={ref}
        {...rest}
        data-testid='create-item'
        aria-selected={active}
        role='option'
        className={cn('xx-combo-option flex flex-row items-center justify-between px-3 py-2 cursor-pointer', {
          'bg-indigo-600 text-white': highlighted
        })}
      >
        {item.created ? (
          <div className='xx-save-new-study'>
            <span className={highlighted ? 'text-white' : 'text-indigo-600'}>{`”${item.value}”`}</span>
            <span className='text-xs'>Save this as a new study</span>
          </div>
        ) : (
          <div className='flex flex-row items-center space-x-4'>
            {study?.owner && <Avatar user={study?.owner} />}
            <span className='flex-grow'>
              <span>{item.label}</span>
            </span>
          </div>
        )}

        {active && (
          <CheckSVG className={cn('w-3 h-3', { 'text-indigo-600': !highlighted, 'text-white': highlighted })} />
        )}
      </li>
    );
  }
);
