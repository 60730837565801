import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { Card, CardTitle } from '@components/common';

import { EditSchedulingPage } from '../../StudyDraft/pages/Assets/EditSchedulingPage';
import { EditSignupPage } from '../../StudyDraft/pages/Assets/EditSignupPage';
import { PanelLandingPage } from '../components/PanelLandingPage';
import { StepHelper, Tab } from './shared';

const StudyEditSignup = (props: PageProps) => {
  return (
    <>
      <StepHelper>If you want to post something online to get participants, use the landing page.</StepHelper>
      {props.study.style === 'panel' ? (
        <PanelLandingPage {...props} />
      ) : (
        <EditSignupPage {...props} />
      )}
    </>
  );
};

const StudyEditScheduling = (props: PageProps) => {
  return (
    <>
      <StepHelper>If you want someone to schedule time, send them this page.</StepHelper>
      <EditSchedulingPage {...props} />
    </>
  );
};

export const Pages: React.FC<PageProps> = (props) => {
  const match = useMatch('/studies/:id/pages/:tab');
  const path = `/studies/${props.study.id}/pages`;

  return (
    <div className='px-page py-gutter'>
      <div className='w-full p-6 pb-1.5 bg-white border border-gray-200 border-b-0 rounded-t'>
        <CardTitle className='pb-4'>Pages</CardTitle>
        <div className='flex'>
          <div className='z-10 space-x-3'>
            <Tab active={match?.params.tab === 'landing'} href={`${path}/landing`} tabName='landing'>
              Landing Page
            </Tab>
            {props.study.style === 'video_call' && (
              <Tab active={match?.params.tab === 'scheduling'} href={`${path}/scheduling`} tabName='scheduling'>
                Scheduling Page
              </Tab>
            )}
          </div>
        </div>
      </div>
      <Card className='p-6 rounded-t-none'>
        <Routes>
          <Route path='landing' element={<StudyEditSignup {...props} />} />
          <Route path='scheduling' element={<StudyEditScheduling {...props} />} />
          <Route path='redirect' element={<StudyEditSignup {...props} />} />
          <Route path='*' element={<Navigate to='landing' replace />} />
        </Routes>
      </Card>
    </div>
  );
};
