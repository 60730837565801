import React, { VFC, useState } from 'react';

import { Tabs } from '@components/common';
import { compact } from '@components/utils';

import { Paths } from './components/Paths';
import { ResponsesTable } from './components/ResponsesTable';
import { Screens } from './components/Screens';
import { Summary } from './components/Summary';
import { Timelines } from './components/Timelines';

type TabValues = 'table' | 'paths' | 'timelines' | 'screens';

interface Props {
  prototypeTestId: number;
  answers: ScreenerResponseAnswerValue<'prototype_test'>[];
}

export const PrototypeTestResponse: VFC<Props> = ({ answers, prototypeTestId }) => {
  const [activeTab, setActiveTab] = useState<TabValues>('table');

  const validAnswers = compact(answers) as NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];

  if (validAnswers.length === 0) {
    return null;
  }

  return (
    <div>
      <Summary answers={validAnswers} />

      <Tabs<TabValues>
        current={activeTab}
        tabs={['table', 'paths', 'timelines', 'screens']}
        labels={{ table: 'Table', paths: 'Paths', timelines: 'Timelines', screens: 'Screens' }}
        onSelect={setActiveTab}
        className='mb-4'
      />

      {activeTab === 'table' && <ResponsesTable prototypeTestId={prototypeTestId} answers={validAnswers} />}

      {activeTab === 'paths' && <Paths prototypeTestId={prototypeTestId} answers={validAnswers} />}

      {activeTab === 'timelines' && <Timelines answers={validAnswers} />}

      {activeTab === 'screens' && <Screens answers={validAnswers} prototypeTestId={prototypeTestId} />}
    </div>
  );
};
