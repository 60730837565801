import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Modal, ModalHeading } from '@components/common';
import { track } from '@components/tracking';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import { useCreateTeamMutation } from '../api';
import * as toasts from '../toasts';
import { TeamForm, TeamFormErrors } from '../components/TeamForm';
import { TEAM_COLORS } from '../constants';

type Props = {
  trackKey: string;
  open: boolean;
  onClose: () => void;
};

export const CreateTeamModal: React.FC<Props> = ({ trackKey, open, onClose }) => {
  const [name, setName] = useState('');
  const user = useUser();
  const [userIds, setUserIds] = useState<number[]>([user.id]);
  const [color, setColor] = useState(TEAM_COLORS[0]);
  const [icon, setIcon] = useState<string | null>(null);

  const reset = () => {
    setName('');
    setUserIds([user.id]);
    setColor(TEAM_COLORS[0]);
    setIcon(null);
  };

  const [errors, setErrors] = useState<TeamFormErrors>({});

  const showToast = useToaster();

  const [createTeam, { isLoading, isSuccess, isError }] = useCreateTeamMutation();

  useEffect(() => {
    if (isSuccess) {
      track('created_team', { name });
      reset();
      onClose();
      showToast(toasts.successCreateTeam());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedCreateTeam());
    }
  }, [isError]);

  useEffect(() => {
    if (open) {
      track('viewed_create_team_modal', { page: trackKey });
    }
  }, [open]);

  useEffect(() => {
    if (!!name && errors.name) {
      setErrors({});
    }
  }, [name, errors]);

  if (!open) {
    return null;
  }

  const handleSubmit = () => {
    if (!name) {
      setErrors({ name: 'required' });
      return;
    }
    createTeam({
      name,
      user_ids: userIds,
      icon,
      color
    });
  };

  return (
    <Modal
      onClose={onClose}
      size='md'
      renderFooter={() => (
        <div className='flex justify-end space-x-4'>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel
          </Button>
          <Button loading={isLoading} disabled={isLoading} primary onClick={handleSubmit}>
            Create team
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-6'>Create Team</ModalHeading>
      <TeamForm
        errors={errors}
        icon={icon}
        color={color}
        setColor={setColor}
        setIcon={setIcon}
        name={name}
        userIds={userIds}
        onChangeUserIds={setUserIds}
        onChangeName={setName}
      />
    </Modal>
  );
};
