import React, { SVGProps } from 'react';

export const GreenCheckBubbleSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.6875 10.999C0.6875 13.7341 1.77399 16.3571 3.70796 18.2911C5.64193 20.225 8.26496 21.3115 11 21.3115C13.735 21.3115 16.3581 20.225 18.292 18.2911C20.226 16.3571 21.3125 13.7341 21.3125 10.999C21.3125 8.26398 20.226 5.64095 18.292 3.70698C16.3581 1.77302 13.735 0.686523 11 0.686523C8.26496 0.686523 5.64193 1.77302 3.70796 3.70698C1.77399 5.64095 0.6875 8.26398 0.6875 10.999Z'
        fill='#31C48D'
        stroke='#31C48D'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.5 12.1209L7.74583 15.3081C7.83102 15.4354 7.94537 15.5404 8.07936 15.6146C8.21335 15.6887 8.36311 15.7298 8.51617 15.7343C8.66924 15.7389 8.82118 15.7069 8.95936 15.6409C9.09753 15.5749 9.21795 15.4768 9.31058 15.3549L16.5 6.25879'
        stroke='white'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
