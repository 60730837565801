import React, { SVGProps } from 'react';

export const DeselectSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.333333 2.66667C0.149333 2.66667 0 2.51733 0 2.33333V1.66667C0 0.748 0.748 0 1.66667 0H2.33333C2.51733 0 2.66667 0.149333 2.66667 0.333333C2.66667 0.517333 2.51733 0.666667 2.33333 0.666667H1.66667C1.11533 0.666667 0.666667 1.11533 0.666667 1.66667V2.33333C0.666667 2.51733 0.517333 2.66667 0.333333 2.66667Z'
      fill='currentColor'
    />
    <path
      d='M13.0001 2.66667C12.8161 2.66667 12.6667 2.51733 12.6667 2.33333V1.66667C12.6667 1.11533 12.2181 0.666667 11.6667 0.666667H11.0001C10.8161 0.666667 10.6667 0.517333 10.6667 0.333333C10.6667 0.149333 10.8161 0 11.0001 0H11.6667C12.5854 0 13.3334 0.748 13.3334 1.66667V2.33333C13.3334 2.51733 13.1841 2.66667 13.0001 2.66667Z'
      fill='currentColor'
    />
    <path
      d='M8.33343 0.666667H5.00008C4.81608 0.666667 4.66675 0.517333 4.66675 0.333333C4.66675 0.149333 4.81608 0 5.00008 0H8.33343C8.51743 0 8.66676 0.149333 8.66676 0.333333C8.66676 0.517333 8.51743 0.666667 8.33343 0.666667Z'
      fill='currentColor'
    />
    <path
      d='M7.00009 13.3333H5.00008C4.81608 13.3333 4.66675 13.184 4.66675 13C4.66675 12.816 4.81608 12.6667 5.00008 12.6667H7.00009C7.18409 12.6667 7.33342 12.816 7.33342 13C7.33342 13.184 7.18409 13.3333 7.00009 13.3333Z'
      fill='currentColor'
    />
    <path
      d='M2.33333 13.3333H1.66667C0.748 13.3333 0 12.5853 0 11.6667V11C0 10.816 0.149333 10.6667 0.333333 10.6667C0.517333 10.6667 0.666667 10.816 0.666667 11V11.6667C0.666667 12.218 1.11533 12.6667 1.66667 12.6667H2.33333C2.51733 12.6667 2.66667 12.816 2.66667 13C2.66667 13.184 2.51733 13.3333 2.33333 13.3333Z'
      fill='currentColor'
    />
    <path
      d='M0.333333 8.66667C0.149333 8.66667 0 8.51734 0 8.33334V4.99999C0 4.81599 0.149333 4.66666 0.333333 4.66666C0.517333 4.66666 0.666667 4.81599 0.666667 4.99999V8.33334C0.666667 8.51734 0.517333 8.66667 0.333333 8.66667Z'
      fill='currentColor'
    />
    <path
      d='M13.0001 7.33333C12.8161 7.33333 12.6667 7.184 12.6667 7V4.99999C12.6667 4.81599 12.8161 4.66666 13.0001 4.66666C13.1841 4.66666 13.3334 4.81599 13.3334 4.99999V7C13.3334 7.184 13.1841 7.33333 13.0001 7.33333Z'
      fill='currentColor'
    />
    <path
      d='M9.26792 16C9.02792 16 8.83325 15.8053 8.83325 15.5653V6.43467C8.83325 6.19467 9.02792 6 9.26792 6C9.38392 6 9.49325 6.04533 9.57525 6.12733L15.8639 12.416C15.9459 12.4973 15.9926 12.6093 15.9926 12.7247C15.9926 12.9647 15.7979 13.1593 15.5579 13.1593H12.1919L9.58059 15.8673C9.49592 15.954 9.38592 16 9.26792 16ZM9.49992 6.99467V14.9913L11.8106 12.5953C11.8733 12.53 11.9599 12.4933 12.0506 12.4933H14.9979L9.49992 6.99467ZM15.3959 12.8907H15.4026H15.3959Z'
      fill='currentColor'
    />
  </svg>
);
