import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading } from '@components/common';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../toasts';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  token: string;
}
export const DeleteHighlightReelModal: React.FC<Props> = ({ open, onClose, onSubmit, token }) => {
  const showToast = useToaster();

  const [deleteHighlightReel, { isLoading, isError, isSuccess }] = api.useDestroyHighlightReelMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDeleteReel());
      onSubmit?.();
      track('deleted_highlight_reel', {});
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedDeleteReel());
      onClose();
    }
  }, [isError]);

  if (!open) {
    return null;
  }

  const handleClick = () => deleteHighlightReel(token);

  return (
    <Modal icon='danger' onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2'>Delete highlight reel?</ModalHeading>
      <p className='pb-12 text-gray-700'>
        The individual clips that make up this highlight reel will stay in your repository.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Don’t delete
        </Button>
        <Button data-testid='confirm-delete' disabled={isLoading} onClick={handleClick} danger>
          Confirm & delete
        </Button>
      </div>
    </Modal>
  );
};
