import React, { SVGProps } from 'react';

export const LogoExpandSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='120' height='40' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M72.2041 19.901C72.881 20.1495 73.5314 20.4192 74.1712 20.7048C75.2024 20.2659 76.3604 20.0438 77.6401 20.0438C80.2894 20.0438 82.4628 21.1913 84.303 23.481C87.4018 22.4498 88.893 20.2712 88.893 16.9397C88.893 12.9208 85.6832 9.59998 80.7442 9.59998C75.5778 9.59998 71.1835 13.0636 68.9414 18.854C70.5119 19.2559 72.0349 19.8375 72.2041 19.901Z'
      fill='currentColor'
    />
    <path
      d='M69.4917 73.3206C66.2766 72.7442 60.7665 72.1732 57.8951 71.7131C56.4039 71.4857 54.9074 71.253 53.4162 70.9093C50.5448 70.3329 49.1699 69.0744 49.1699 67.5779C49.1699 65.0502 52.1577 63.7864 58.0115 63.7864H58.6989C66.0492 63.7864 72.1304 61.9515 76.9531 58.16C81.7757 54.3685 84.187 49.091 84.187 42.5445C84.187 33.3593 79.0207 27.0454 70.6392 23.9413C70.6392 23.9413 69.1638 23.3702 67.7678 23.0212C65.0128 22.3338 62.025 21.9901 58.8099 21.9901C51.3486 21.9901 45.2621 23.825 40.3231 27.5002C35.3841 31.059 32.9728 36.109 32.9728 42.6556C32.9728 52.6446 39.5193 59.8786 49.5084 62.6336C40.4394 63.6648 34.813 67.6836 34.813 74.0028C34.813 77.7943 36.7643 80.5493 40.7832 82.2679C44.802 83.9918 47.6734 84.4519 53.5272 85.3667C58.2336 85.9431 61.4487 86.3979 63.0562 86.7469C71.2103 88.3544 75.3402 91.5695 75.3402 96.3922C75.3402 102.939 68.5663 107.069 59.8411 107.069C50.3121 107.069 45.9496 102.13 45.6005 95.8158C45.3732 93.2881 45.6005 90.7657 46.288 88.2381C36.299 88.3544 30.8999 91.7969 30.8999 97.656C30.8999 101.902 33.4276 105.006 38.4776 107.185C43.644 109.369 49.9578 110.4 57.4245 110.4C77.9789 110.4 88.6554 102.135 88.6554 89.7346C88.666 79.6398 81.7757 75.5046 69.4917 73.3206ZM52.385 29.462C53.6489 26.5906 55.8275 25.2157 58.6989 25.2157C61.5703 25.2157 63.6379 26.5959 64.9017 29.462C66.1656 32.217 66.853 36.5796 66.853 42.6661C66.853 54.6064 64.0979 60.5766 58.6989 60.5766C52.8451 60.5766 50.4338 54.7228 50.4338 42.6661C50.4338 36.5796 51.1212 32.217 52.385 29.462Z'
      fill='currentColor'
    />
  </svg>
);
