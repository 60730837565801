import { FilterDefinition } from '@components/shared/TableFilters/types';
import { EligibilityRule } from '@components/GovernanceApp/components/Eligiblity/types';
import { number } from '@components/shared/TableFilters/components/svgs';

export const buildEligibilityRuleFilterDefs = (): FilterDefinition<EligibilityRule>[] => {
  return [
    {
      id: 'contacted_count',
      onlyAllowOne: true,
      type: 'number',
      name: 'Invite count',
      defaultOperator: 'is',
      Icon: number,
      inputSuffix: 'times',
      tooltip: '“Invite count” refers to number of invites to participant or invites to screeners.',
      periodOptions: [
        { label: 'Last 1 month', value: '1m' },
        { label: 'Last 2 months', value: '2m' },
        { label: 'Last 6 months', value: '6m' },
        { label: 'Last 1 year', value: '1y' },
        { label: 'Indefinitely', value: 'all' }
      ]
    },
    {
      id: 'polled_rate',
      onlyAllowOne: true,
      type: 'number',
      name: 'Participation rate',
      defaultOperator: 'is',
      Icon: number,
      tooltip:
        '“Participation rate” refers to the number of studies a user participated in, excluding panel recruitment studies, divided by the total number of those studies.',
      periodOptions: [
        { label: 'Last 1 month', value: '1m' },
        { label: 'Last 3 months', value: '3m' },
        { label: 'Last 6 months', value: '6m' },
        { label: 'Last 9 months', value: '9m' },
        { label: 'Last 1 year', value: '12m' },
        { label: 'All time', value: 'all' }
      ],
      transformValue: (value: any) => String([null, undefined].includes(value) ? '' : 1 / parseFloat(value)),
      changeFn: (value: string) => 1 / parseFloat(value),
      inputSuffix: '%'
    },
    {
      id: 'response_rate',
      onlyAllowOne: true,
      type: 'number',
      name: 'Response rate',
      defaultOperator: 'is',
      Icon: number,
      tooltip: '“Response rate” refers to responding to invites to participate, excluding screener invites.',
      periodOptions: [
        { label: 'Last 1 month', value: '1m' },
        { label: 'Last 3 months', value: '3m' },
        { label: 'Last 6 months', value: '6m' },
        { label: 'Last 9 months', value: '9m' },
        { label: 'Last 1 year', value: '12m' },
        { label: 'All time', value: 'all' }
      ],
      inputSuffix: '%',
      transformValue: (value: any) => String([null, undefined].includes(value) ? '' : parseFloat(value) * 100),
      changeFn: (value: string) => parseFloat(value) / 100
    },
    {
      id: 'no_show_count',
      onlyAllowOne: true,
      type: 'number',
      name: 'No-show count',
      defaultOperator: 'is',
      defaultValue: '0.0',
      inputSuffix: 'times',
      Icon: number,
      tooltip:
        '“No-show count” refers to the number of times a participant was marked as a no-show for an interview study.',
      periodOptions: [
        { label: 'Last 1 month', value: '1m' },
        { label: 'Last 2 months', value: '2m' },
        { label: 'Last 6 months', value: '6m' },
        { label: 'Last 1 year', value: '1y' },
        { label: 'Indefinitely', value: 'all' }
      ],
      transformValue: (value: string) => parseFloat(value)
    }
  ];
};

