import React, { createContext, useContext, useState } from 'react';

interface Context {
  isDirty: Record<string, boolean>;
  setIsDirty: (v: Record<string, boolean>) => void;
  cancelModal: boolean;
  setCancelModal: (v: boolean) => void;
}

export const ProfileContext = createContext<Context>({} as Context);

export const useProfileContext = () => {
  const profileContext = useContext(ProfileContext);

  if (profileContext === undefined) throw new Error();

  return profileContext;
};

export const ProfileContextProvider: React.FC = ({ children }) => {
  const [cancelModal, setCancelModal] = useState(false);
  const [isDirty, setIsDirty] = useState<Record<string, boolean>>({});
  return (
    <ProfileContext.Provider value={{ isDirty, setIsDirty, cancelModal, setCancelModal }}>
      {children}
    </ProfileContext.Provider>
  );
};
