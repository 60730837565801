import React, { HTMLAttributes, forwardRef, useState } from 'react';
import cn from 'classnames';

import { Modal, Text } from '@components/common';
import { Column, Grid } from '@components/common/Grid';
import { VideoCameraSVG } from '@components/svgs';

import { ClickPointer } from './ClickPointer';
import { ScreenThumbnail } from './ScreenThumbnail';
import { ScreensPreview } from './ScreensPreview';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  prototypeTestId: number;
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
}

export const ResponsesTable = forwardRef<HTMLDivElement, Props>(
  ({ answers, className, prototypeTestId, ...rest }, ref) => {
    const [activePath, setActivePath] = useState<string[]>([]);
    const [activeClicks, setActiveClicks] = useState<PrototypeClick[]>([]);

    const handleOnModalClose = () => {
      setActivePath([]);
      setActiveClicks([]);
    };

    return (
      <>
        <div ref={ref} className={cn('w-full text-sm text-left border border-gray-200 rounded', className)} {...rest}>
          <div role='rowgroup'>
            <Grid role='row' desktop={5}>
              <Column role='columnheader' className='p-3 font-bold'>
                Name
              </Column>
              <Column role='columnheader' className='p-3 font-bold'>
                Total time
              </Column>
              <Column role='columnheader' className='p-3 font-bold'>
                Outcome
              </Column>
              <Column role='columnheader' className='p-3 font-bold'>
                Mis-click rate
              </Column>
              <Column role='columnheader' className='p-3 font-bold'>
                Path
              </Column>
            </Grid>
          </div>
          <div role='rowgroup' className='overflow-x-hidden overflow-y-auto' style={{ maxHeight: 384 }}>
            {answers.map((response, index) => (
              <Grid key={index} role='row' desktop={5} className='border-b border-gray-200'>
                <Column role='cell' className='p-3'>
                  <div className='flex items-center space-x-2'>
                    <div className='w-20 truncate'>{response.participation_name}</div>

                    <a href={`/sessions/${response.session_uuid}`} target='_blank'>
                      <VideoCameraSVG className='w-4 h-4 ml-2' />
                    </a>
                  </div>
                </Column>
                <Column role='cell' className='p-3'>
                  {response.time_in_task ? `${(response.time_in_task / 1000).toFixed(1)}s` : ''}
                </Column>
                <Column role='cell' className='p-3'>
                  <ClickPointer size='lg' failure={!response.completed} />
                </Column>
                <Column role='cell' className='p-3'>
                  {response.miss_clicks ? response.miss_clicks.toFixed(1) : 0}%
                </Column>
                <Column role='cell' className='flex items-center px-3'>
                  <div
                    aria-label={`View paths ${response.response_path.join(', ')}`}
                    role='button'
                    tabIndex={0}
                    className='hover:bg-gray-100 inline-flex items-center px-2 space-x-2 border border-gray-200 rounded cursor-pointer'
                    onClick={() => {
                      setActivePath(response.response_path);
                      setActiveClicks(response.clicks);
                    }}
                  >
                    {response.response_path.slice(0, 2).map((step, index) => (
                      <ScreenThumbnail
                        key={index}
                        prototypeTestId={prototypeTestId}
                        className='flex items-center justify-center w-10 h-8'
                        clicks={[]}
                        screenExternalElementId={step}
                        thumbnailSize={50}
                        hideClicks
                      />
                    ))}
                    {response.response_path.length > 2 && (
                      <Text className='text-sm text-gray-500'>+{response.response_path.length - 2}</Text>
                    )}
                  </div>
                </Column>
              </Grid>
            ))}
          </div>
        </div>

        {activePath.length > 0 && (
          <Modal onClose={handleOnModalClose} size='xl'>
            <ScreensPreview prototypeTestId={prototypeTestId} path={activePath} clicks={activeClicks} />
          </Modal>
        )}
      </>
    );
  }
);
