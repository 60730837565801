import cn from 'classnames';
import { ExportSVG } from 'components/svgs';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import { TippyOrNot } from '@components/common/TippyOrNot';

interface Props extends CommonPropTypes {
  className?: string;
  medium?: boolean;
  type?: 'button' | 'link';
  disabled?: boolean;
  disabledTooltip?: string;
}

export const ExportCsvButton: React.FC<Props> = ({
  type = 'button',
  disabled,
  onClick,
  className,
  medium,
  disabledTooltip,
  ...rest
}) => {
  const linkClassName = cn(
    'w-full inline-flex items-center tw-ui-dropdown-link whitespace-nowrap text-gray-700 hover:text-indigo-600',
    disabled && 'opacity-50'
  );

  const buttonClassName = cn(
    'xx-bulk-export cursor-pointer inline-flex items-center justify-center relative font-medium focus:ring focus:ring-blue focus:outline-none border border-gray-200 bg-white text-gray-700 hover:bg-gray-50 hover:text-gray-700 active:bg-gray-50 active:text-gray-800 focus:border-blue-300 text-sm leading-5 rounded-md',
    className,
    medium ? 'h-8 px-3 py-1.5' : 'px-4 py-2.5',
    disabled && 'opacity-50'
  );

  if (disabled) {
    return (
      <TippyOrNot content={disabledTooltip} show={!!disabledTooltip}>
        <div>
          <button disabled className={type === 'link' ? linkClassName : buttonClassName}>
            <ExportSVG />
            <div className='whitespace-nowrap ml-2'>Export CSV</div>
          </button>
        </div>
      </TippyOrNot>
    );
  }

  return (
    <CSVLink {...rest} aria-label='Export CSV' className={type === 'link' ? linkClassName : buttonClassName}>
      <ExportSVG />
      <div className='whitespace-nowrap ml-2'>Export CSV</div>
    </CSVLink>
  );
};
