import React, { HTMLAttributes, forwardRef } from 'react';

import { Button, Text } from '@components/common';
import { Column, Grid } from '@components/common/Grid';

interface Props extends HTMLAttributes<HTMLDivElement> {
  showHeading?: boolean;
  surveyId?: number;
}

export const SurveyBuilderHeader = forwardRef<HTMLDivElement, Props>(({ showHeading, surveyId, ...rest }, ref) => {
  return (
    <header ref={ref} {...rest}>
      <Grid desktop={12}>
        <Column desktop={2}></Column>
        <Column desktop={8} className='text-center'>
          {showHeading && (
            <>
              <Text as='h2' className='mb-2 text-2xl' bold>
                Let's set up your unmoderated usability test
              </Text>
              <Text className='text-sm text-gray-500'>You can always edit this later if things change.</Text>
            </>
          )}
        </Column>
        <Column desktop={2} className='text-right'>
          {showHeading && (
            <Button icon='eye' href={`/studies/${surveyId}/preview/survey`} target='_blank'>
              Preview
            </Button>
          )}
        </Column>
      </Grid>
    </header>
  );
});
