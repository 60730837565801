import React, { SVGProps } from 'react';

export const SearchSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.858715 7.79185C1.40714 9.08228 2.44572 10.102 3.74598 10.6267C5.04624 11.1514 6.50168 11.138 7.79211 10.5896C9.08254 10.0412 10.1023 9.0026 10.6269 7.70234C11.1516 6.40207 11.1383 4.94664 10.5899 3.65621C10.0414 2.36577 9.00287 1.34605 7.7026 0.821373C6.40234 0.296692 4.9469 0.310029 3.65647 0.858449C2.36604 1.40687 1.34632 2.44545 0.821639 3.74571C0.296958 5.04598 0.310295 6.50142 0.858715 7.79185V7.79185Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.46228 9.46191L13.5625 13.5627' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
