import React, { HTMLAttributes, Ref, forwardRef } from 'react';
import cn from 'classnames';
import TextArea, { TextareaAutosizeProps } from 'react-textarea-autosize';

import { Text } from '../Text';

import { Icon } from './components/Icon';

interface Props extends HTMLAttributes<HTMLDivElement> {
  innerRef?: Ref<HTMLTextAreaElement>;
  resizable?: boolean;
  showSupport?: boolean;
  textAreaProps?: TextareaAutosizeProps;
}

const DEFAULT_MIN_ROWS = 3;

export const MarkdownEditor = forwardRef<HTMLDivElement, Props>(
  ({ innerRef, resizable = true, showSupport = true, textAreaProps = {}, ...rest }, ref) => {
    const { className: textAreaClassName, ...textAreaPropsRest } = textAreaProps;

    return (
      <div ref={ref} className='bg-gray-50 p-2 pb-1 overflow-hidden border border-gray-200 rounded-md' {...rest}>
        <TextArea
          ref={innerRef}
          className={cn(
            'border-gray-200 rounded-md placeholder-gray-400 tablet:text-sm focus:ring-indigo-500 focus:border-indigo-500 w-full',
            {
              'resize-none': !resizable
            },
            textAreaClassName
          )}
          minRows={DEFAULT_MIN_ROWS}
          style={{ outline: 'none', boxShadow: 'none' }}
          {...textAreaPropsRest}
        />

        <a
          href='https://www.markdownguide.org/cheat-sheet/'
          target='_blank'
          className=' hover:text-indigo-600 inline-flex items-center px-1 mt-1 space-x-1 text-gray-500'
        >
          <Icon className='w-6 h-6' />
          <Text className='text-xs'>Markdown is supported</Text>
        </a>
      </div>
    );
  }
);
