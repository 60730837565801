import React from 'react';
import { StepHelper, StepTitle } from './shared';

import { ExternalCandidatesRequestForm } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/ExternalCandidatesRequestForm';
import { useRecruitingRequest } from '@components/StudiesApp/components/StudyDraft/hooks/useRecruitingRequest';
import { useDebouncedCallback } from 'use-debounce';
import { Loading } from '@components/common';

export const Recruiting: React.FC<PageProps> = ({ study }) => {
  const { requestInitialized, request, handleChange } = useRecruitingRequest({ studyId: study.id });

  const { callback: debouncedHandleChange } = useDebouncedCallback(handleChange, 1000);

  if (requestInitialized) {
    return <ExternalCandidatesRequestForm study={study} request={request} onChange={debouncedHandleChange} />;
  }

  return <Loading absolute />;
};
