import React, { SVGProps } from 'react';

export const LightBulbSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 3.06342V1.68842'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.8337 5.48066L18.8063 4.50808'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.25 11.3134H21.625'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.8337 17.1471L18.8063 18.1197'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.16634 5.48066L5.19376 4.50808'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 11.3134H2.375'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.16634 17.1471L5.19376 18.1197'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.25 16.0782V18.1866C9.25 18.9159 9.53973 19.6154 10.0555 20.1311C10.5712 20.6469 11.2707 20.9366 12 20.9366C12.7293 20.9366 13.4288 20.6469 13.9445 20.1311C14.4603 19.6154 14.75 18.9159 14.75 18.1866V16.0782'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.5 11.3134C6.5 12.7721 7.07946 14.1711 8.11091 15.2025C9.14236 16.234 10.5413 16.8134 12 16.8134C13.4587 16.8134 14.8576 16.234 15.8891 15.2025C16.9205 14.1711 17.5 12.7721 17.5 11.3134C17.5 9.85473 16.9205 8.45578 15.8891 7.42433C14.8576 6.39288 13.4587 5.81342 12 5.81342C10.5413 5.81342 9.14236 6.39288 8.11091 7.42433C7.07946 8.45578 6.5 9.85473 6.5 11.3134V11.3134Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 20.9384V22.3134'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
