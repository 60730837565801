import React, { SVGProps } from 'react';

export const NewTabSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.563 5.37378V1.43628H10.6255'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.563 1.43628L5.81299 10.1863'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.56299 4.06128H2.31299C2.08092 4.06128 1.85836 4.15347 1.69427 4.31756C1.53018 4.48166 1.43799 4.70421 1.43799 4.93628V13.6863C1.43799 13.9183 1.53018 14.1409 1.69427 14.305C1.85836 14.4691 2.08092 14.5613 2.31299 14.5613H11.063C11.2951 14.5613 11.5176 14.4691 11.6817 14.305C11.8458 14.1409 11.938 13.9183 11.938 13.6863V8.43628'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
