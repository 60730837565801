import React, { SVGProps } from 'react';

export const ArchiveSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m13.6875 8.07812v-5.24999c0-.23207-.0922-.45463-.2563-.61872-.1641-.1641-.3866-.25628-.6187-.25629h-5.6875c-.23206.00001-.45462.09219-.61872.25629-.16409.16409-.25628.38665-.25628.61872v5.24999' />
      <path d='m2.3125 8.07812v-3.5c0-.23206.09219-.45462.25628-.61871.1641-.1641.38666-.25628.61872-.25629h1.3125' />
      <path d='m9.75 4.57812h2.1875' />
      <path d='m8 6.76562h3.9375' />
      <path d='m14.125 9.82422h-4.375c0 .46408-.18437.90928-.51256 1.23748-.32819.3281-.77331.5125-1.23744.5125s-.90925-.1844-1.23744-.5125c-.32819-.3282-.51256-.7734-.51256-1.23748h-4.375c-.11556.00151-.22597.04809-.30769.12981-.08172.08177-.1283.19217-.12981.30767v2.9167c0 .2321.09219.4546.25628.6187.1641.1641.38666.2563.61872.2563h11.375c.2321 0 .4546-.0922.6187-.2563s.2563-.3866.2563-.6187v-2.9167c-.0015-.1155-.0481-.2259-.1298-.30767-.0817-.08172-.1921-.1283-.3077-.12981z' />
    </g>
  </svg>
);
