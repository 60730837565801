import cn from 'classnames';
import copy from 'copy-to-clipboard';
import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AIBots, useLazySuggestions } from '@api/chat-gpt';
import { Text, TippyOrNot } from '@components/common';
import { useSessionContext } from '@components/RepoSessionApp/SessionPage';
import { BulkAddToHighlightReel } from '@components/RepositoryApp/components/BulkAddToHighlightReel';
import { BulkAddToInsightDropdown } from '@components/RepositoryApp/components/BulkAddToInsightDropdown';
import {
  AddToInsightSVG,
  ChevronLeftSVG,
  CloseSVG,
  FullPageViewSVG,
  HighlightReelSVG,
  HighlightSVG
} from '@components/svgs';
import { TagListButton, TagListItems, useTagList } from '@components/tags/TagList';
import { track } from '@components/tracking';
import { compact, useOnKeypress } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useTags } from '@stores/tags';
import { useToaster } from '@stores/toaster';
import { Editor } from '@tiptap/react';

import {
  useCreateDocumentHighlightMutation,
  useCreateHighlightWithClipMutation,
  useDestroyDocumentHighlightMutation,
  useLazyGetDocumentHighlightQuery,
  useUpdateDocumentHighlightMutation
} from '../api';
import { HighlightAttributes } from '../extensions';
import { TranscriptWordAttributes } from '../extensions/TranscriptWord';
import * as Queries from '../helpers/queries';
import { useTiptapContext } from '../hooks';
import * as Icons from '../icons';
import * as toasts from '../toasts';
import { useFeature } from '@hooks/useFeature';
import { getHighlightContent, getSuggestionIdsFromTags } from '@components/shared/Tiptap/utils';
import { HighlightCreatorInfo } from './HighlightCreatorInfo';
import { HighlightMenuSkeleton } from './HighlightMenuSkeleton';
import { HighlightMenuTitle } from './HighlightMenuTitle';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  editor: Editor;
  documentId: number;
  studyId?: number | null;
  isVisible?: boolean;
  hide?: () => void;
  highlightId?: number;
}

export const HighlightMenu = forwardRef<HTMLDivElement, Props>(
  ({ hide, editor, documentId, studyId, isVisible, className, highlightId: initialHighlightId, ...rest }, ref) => {
    const [createDocumentHighlight, createHiglightMutationOptions] = useCreateDocumentHighlightMutation();
    const [createHighlightWithClip, createClipMutationOptions] = useCreateHighlightWithClipMutation();

    const canCreate = usePermission('canCreate')();

    const nonVideoHlEnabled = useFeature('non_video_highlights');

    const isCreatingHighlight = createHiglightMutationOptions.isLoading || createClipMutationOptions.isLoading;
    const isNewlyCreated = createHiglightMutationOptions.isSuccess || createClipMutationOptions.isSuccess;

    const highlightAttributes = editor.getAttributes('highlight') as HighlightAttributes | undefined;
    const highlightId = initialHighlightId ?? highlightAttributes?.highlightId;

    const { sessionUuid, recordingId } = useTiptapContext();
    const { videoPlayerRef } = useSessionContext();
    const { account } = useAccount();
    const { getTag } = useTags();
    const aiEnabled = account.ai_enabled;

    const showToast = useToaster();

    const [getDocumentHighlight, { currentData: highlight, isFetching, isLoading: isLoadingHighlight }] =
      useLazyGetDocumentHighlightQuery();

    const [updateDocumentHighlight] = useUpdateDocumentHighlightMutation();
    const [destroyDocumentHighlight, { isError, isSuccess, isLoading: isDeleting }] =
      useDestroyDocumentHighlightMutation();

    const {
      fetch: fetchTagSuggestions,
      value: tagSuggestions,
      accept: acceptSuggestion
    } = useLazySuggestions<string, { text?: string; study_id?: number | null }>({
      id: AIBots.TranscriptTextTags,
      context: { text: '', study_id: studyId }
    });

    const [tags, setTags] = useState<Highlight['tag_ids']>([]);

    const [subMenu, setSubMenu] = useState<'reel' | 'insight' | 'tags' | null>(null);

    useEffect(() => {
      if (highlightId) {
        const [{ node }] = Queries.findMarksByHighlightId(editor.state, highlightId);

        const mark = node.marks.find(({ type }) => type.name === 'highlight');

        if (!mark?.attrs.isTmp) {
          getDocumentHighlight({ documentId, highlightId }, true)
            .unwrap()
            .then((data) => {
              setTags(data.tag_ids);
            });
        }
      } else {
        setSubMenu('tags');
      }
    }, [highlightId]);

    useEffect(() => {
      if (isSuccess) {
        showToast(toasts.successDeleteHighlight());
      }
    }, [isSuccess]);

    useEffect(() => {
      if (isError) {
        showToast(toasts.failedDeleteHighlight());
      }
    }, [isError]);

    useEffect(() => {
      if (isVisible) {
        let text = '';

        if (editor.state.selection.empty) {
          const activeMarkRange = Queries.getActiveHighlightRange(editor.state);
          text = Queries.getTextSelection(editor, activeMarkRange);
        } else {
          text = Queries.getTextSelection(editor);
        }

        if (aiEnabled && text) {
          fetchTagSuggestions({ text });
        }
      }
    }, [isVisible]);

    const clipUrl = `${window.location.origin}/${highlight?.clip ? 'clips' : 'h'}/${highlight?.uuid}`;

    const acceptTagSuggestions = (tags: string[]) => {
      const suggestedIds = getSuggestionIdsFromTags(tags, tagSuggestions);
      suggestedIds.forEach((id) => acceptSuggestion(id));
    };

    const handleSaveWithoutTags = () => {
      handleOnFirstTagChange([]);
    };

    const handleOnFirstTagChange = async (newTagIds: number[], newTag?: Tag) => {
      setTags(newTagIds);

      const tempId = Date.now();

      const attributes: Partial<HighlightAttributes> = {
        highlightId: tempId,
        color: newTag?.color ?? 'default',
        isTmp: true
      };

      editor.chain().focus().setMeta('preventUpdate', true).toggleHighlight(attributes).run();

      let newHighlight: Highlight;

      try {
        const { from, to } = editor.state.selection;
        const tWordMarks = Queries.getMarksInRange(editor, { from, to }, 'transcript_word');

        const text = Queries.getTextSelection(editor);

        if (recordingId) {
          const data = await createHighlightWithClip({
            recordingId,
            documentId,
            sessionUuid,
            text,
            tag_ids: newTagIds,
            from: tWordMarks[0]?.attrs.start_ts,
            to: tWordMarks[tWordMarks.length - 1]?.attrs.end_ts
          }).unwrap();

          newHighlight = data.highlight;

          track('created_highlight', { highlight: newHighlight.id, has_video: true });
        } else {
          const content = getHighlightContent({ editor, from, to });

          newHighlight = await createDocumentHighlight({ documentId, text, tag_ids: newTagIds, content }).unwrap();

          const withImages = content.some((c) => c.type === 'image');
          const onlyImages = content.every((c) => c.type === 'image');

          track('created_highlight', {
            highlight: newHighlight.id,
            has_video: false,
            withImages,
            onlyImages
          });
        }

        editor
          .chain()
          .setMeta('immidiateUpdate', true)
          .updateHighlight(tempId, { ...attributes, highlightId: newHighlight.id, isTmp: false })
          .run();

        const newTagNames = compact(newTagIds.map(getTag)).map((t) => t.name);

        acceptTagSuggestions(newTagNames);
      } catch (error) {
        editor.chain().focus().unsetHighlight(tempId).run();
      }
    };

    const handleOnTagsChange = async (newTagIds: number[]) => {
      setTags(newTagIds);

      const newTagNames = compact(newTagIds.map(getTag)).map((t) => t.name);

      try {
        const updatedHighlight = await updateDocumentHighlight({
          document_id: documentId,
          id: highlightId!,
          tag_ids: newTagIds
        }).unwrap();

        editor
          .chain()
          .setMeta('immidiateUpdate', true)
          .toggleHighlight({ highlightId: updatedHighlight.id, color: updatedHighlight.color })
          .run();

        acceptTagSuggestions(newTagNames);

        track('added_tag_to_highlight', {
          highlightId: updatedHighlight.id,
          has_video: !!recordingId
        });
      } catch {
        showToast(toasts.failedUpdateHighlight());
      }
    };

    const handleOnDeleteClick = () => {
      if (highlightId) {
        destroyDocumentHighlight({ documentId, highlightId });
        editor.commands.unsetHighlight(highlightId);
        hide?.();

        track('deleted_highlight', { page: 'interview_room' });
        track('deleted_clip', { highlight: highlightId });
      }
    };

    const handleOnPlayClick = () => {
      if (highlightId) {
        const [{ node }] = Queries.findMarksByHighlightId(editor.state, highlightId);

        if (node) {
          const tWordMark = node.marks.find(({ type }) => type.name === 'transcript_word');

          if (tWordMark && videoPlayerRef.current) {
            const tWordAttrs = tWordMark.attrs as TranscriptWordAttributes;

            videoPlayerRef.current.setCurrentTime(tWordAttrs.start_ts / 1000);
            videoPlayerRef.current.play();
          }
        }
      }
    };

    const handleOnCopyLink = () => {
      copy(clipUrl);
      showToast(toasts.successCopyLink());
    };

    const onTagCreate = (tag?: Tag) => {
      track('created_tag', { page: 'interview_room' });
    };

    const readOnly = isFetching || isLoadingHighlight || isCreatingHighlight;
    const onChange = highlightId ? handleOnTagsChange : handleOnFirstTagChange;

    const { canManage, multiselectTags, inputRef } = useTagList({
      tagIds: tags,
      defaultOpen: true,
      readOnly,
      onChange,
      onTagCreate,
      suggest: tagSuggestions,
      studyId,
      closeOnEsc: false
    });
    const { isOpen } = multiselectTags;

    useEffect(() => {
      if (isOpen) {
        setSubMenu('tags');
      }
    }, [isOpen]);

    useOnKeypress('Escape', {}, () => {
      if (isOpen) {
        inputRef.current?.blur?.();
      } else {
        hide?.();
      }
    });

    const getTitle = () => {
      if (subMenu === 'tags') return 'Manage tags';
      if (subMenu === 'reel') return 'Add to Reel';
      if (subMenu === 'insight') return 'Add to Insight';
      return '';
    };

    if (!canCreate || (!recordingId && !nonVideoHlEnabled)) {
      return null;
    }

    return (
      <section ref={ref} className={cn('w-80', className)} {...rest}>
        <div className='w-full border-b border-gray-200'>
          {highlight ? (
            <>
              {subMenu ? (
                <div className='flex items-center justify-between h-10 px-4'>
                  <button
                    data-testid='back'
                    className='hover:text-indigo-500 text-gray-700'
                    name='back'
                    aria-label='back'
                    onClick={() => {
                      setSubMenu(null);
                    }}
                  >
                    <ChevronLeftSVG className='w-4 h-4' />
                  </button>

                  <Text className='flex-grow px-2 py-1 text-sm text-center' bold>
                    {getTitle()}
                  </Text>

                  <button
                    aria-label='Close highlight menu'
                    className='hover:text-indigo-500 text-gray-700'
                    onClick={hide}
                  >
                    <CloseSVG className='w-4 h-4' />
                  </button>
                </div>
              ) : (
                <HighlightMenuTitle
                  highlight={highlight}
                  onSave={(value) =>
                    updateDocumentHighlight({
                      document_id: documentId,
                      title: value,
                      id: highlightId!
                    })
                  }
                />
              )}
            </>
          ) : (
            <>
              {isLoadingHighlight || isCreatingHighlight ? (
                <HighlightMenuSkeleton rowClassName='px-4 py-2' />
              ) : (
                <div className='flex items-center px-4 py-3'>
                  <Text className='flex-grow text-sm' bold>
                    Save highlight
                  </Text>
                  <button
                    aria-label='Close highlight menu'
                    className='hover:text-indigo-500 text-gray-700'
                    onClick={hide}
                  >
                    <CloseSVG className='w-4 h-4' />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className={cn('relative')}>
          {highlightId && !isNewlyCreated && isLoadingHighlight && (
            <HighlightMenuSkeleton rows={8} rowClassName='px-4 py-3 border-b border-gray-200' />
          )}

          {((highlight && !subMenu) || subMenu === 'tags') && (
            <TagListButton
              tagIds={tags}
              multiselectTags={multiselectTags}
              previewShowLimit={2}
              className='focus:bg-gray-50 focus:outline-none hover:bg-gray-50 flex items-center w-full h-10 px-4 space-x-2 border-b border-gray-200'
              readOnly={readOnly}
              inputRef={inputRef}
              triggerRef={undefined}
              studyId={studyId}
              pillClassName={cn('min-w-4', {
                'max-w-1/2': !!highlight?.tag_ids?.length
              })}
            />
          )}
          {subMenu === 'tags' && (
            <>
              <TagListItems
                alwaysOpen
                className='TagListDropdown pt-2'
                canManage={canManage}
                multiselectTags={multiselectTags}
                studyId={studyId}
              />
              {!highlight && (
                <div
                  onClick={handleSaveWithoutTags}
                  className='xx-save-without-tags cursor-pointer hover:bg-gray-50 flex items-center px-4 py-1.5 text-sm text-gray-700 border-t border-gray-200'
                >
                  <HighlightSVG className='h4 w-4 mr-2' />
                  Save without tags
                </div>
              )}
            </>
          )}
          {subMenu === 'reel' && highlight?.clip_id && (
            <BulkAddToHighlightReel
              hide={() => setSubMenu(null)}
              studyId={studyId}
              selectedArtifacts={[`Clip_${highlight.clip_id}`]}
              onlyContent
            />
          )}
          {subMenu === 'insight' && (
            <BulkAddToInsightDropdown
              studyId={studyId}
              hide={() => setSubMenu(null)}
              selectedArtifacts={highlight?.clip_id ? [`Clip_${highlight.clip_id}`] : [`Highlight_${highlight?.id}`]}
              onlyContent
            />
          )}
        </div>
        {!subMenu && (
          <>
            {highlight?.clip_id && (
              <button
                className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                onClick={handleOnPlayClick}
              >
                <Icons.Play className='w-4 h-4 mr-2' /> Play clip
              </button>
            )}
            {highlight && (
              <>
                <TippyOrNot show={!highlight?.clip_id} content='Only available for highlights with video'>
                  <button
                    disabled={!highlight?.clip_id}
                    onClick={() => setSubMenu('reel')}
                    data-testid='add-to-reel'
                    className={cn(
                      highlight?.clip_id ? 'text-gray-700' : 'text-gray-400',
                      'focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm border-b border-gray-200'
                    )}
                  >
                    <HighlightReelSVG className='w-4 h-4 mr-2' /> Add to reel
                  </button>
                </TippyOrNot>
                <button
                  onClick={() => setSubMenu('insight')}
                  data-testid='add-to-insight'
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                >
                  <AddToInsightSVG className='w-4 h-4 mr-2' /> Add to insight
                </button>
                <button
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                  onClick={handleOnCopyLink}
                >
                  <Icons.Link className='w-4 h-4 mr-2' /> Copy link
                </button>
                <a
                  href={clipUrl}
                  target='_blank'
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 hover:text-gray-700 focus:text-gray-700 flex items-center w-full h-10 px-4 text-sm text-gray-700 border-b border-gray-200'
                >
                  <FullPageViewSVG className='w-4 h-4 mr-2' /> View on full page
                </a>
                <button
                  className='focus:outline-none hover:bg-gray-50 focus:bg-gray-50 flex items-center w-full h-10 px-4 text-sm text-red-700'
                  onClick={handleOnDeleteClick}
                >
                  <Icons.Trash className='w-4 h-4 mr-2' /> Delete highlight
                </button>
              </>
            )}
            {highlight && (
              <HighlightCreatorInfo
                createdAt={new Date(highlight.created_at)}
                creatorId={highlight.creator_id}
                ai={highlight.ai}
              />
            )}
          </>
        )}
      </section>
    );
  }
);

HighlightMenu.displayName = 'HighlightMenu';
