import * as React from 'react';
import { MouseEventHandler } from 'react';
import { Button, Text } from '@components/common';
import { CloseSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useLocalStorage } from '@hooks/useLocalStorage';

interface Props {
  popupKey: string;
  title: string;
  description: string;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
}

const TryItPopUp: React.FC<Props> = ({ popupKey, title, description, onConfirm }) => {
  const [isHidden, setIsHidden] = useLocalStorage<boolean>(`tryit-popup-${popupKey}`);

  const handleOnConfirm: MouseEventHandler<HTMLButtonElement> = (e) => {
    onConfirm(e);
    setIsHidden(true);
    track('handled_confirm_from_tryit_popup', { popup_key: popupKey });
  };

  const handleOnCancel = () => {
    setIsHidden(true);
    track('handled_cancel_from_tryit_popup', { popup_key: popupKey });
  };

  return (
    <>
      {!isHidden && (
        <section className='z-10 max-w-sm p-4 bg-white border border-gray-200 rounded shadow-md'>
          <header className='flex justify-between mb-2'>
            <Text as='h3' h='500' bold>
              {title}
            </Text>
            <button className='focus:outline-none'>
              <CloseSVG className='w-4 h-4' onClick={handleOnCancel} />
            </button>
          </header>
          <Text h='500' className='mb-4 whitespace-normal'>
            {description}
          </Text>
          <footer className='flex space-x-4'>
            <Button icon='externalLink' small primary onClick={handleOnConfirm}>
              Connect
            </Button>
            <Button small onClick={handleOnCancel}>
              Not now
            </Button>
          </footer>
        </section>
      )}
    </>
  );
};

export default TryItPopUp;
