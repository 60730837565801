import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';

export const Recording = Node.create({
  name: 'recording',

  group: 'block',

  atom: true,

  selectable: false,

  isolating: true,

  parseHTML() {
    return [
      {
        tag: 'recording-component'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['recording-component', mergeAttributes(HTMLAttributes)];
  }
});
