import { api } from '@api/reduxApi';

const plansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createTrial: builder.mutation<void, void>({
      query: (body) => ({
        url: '/plans/trial',
        method: 'POST',
        body: { config: body }
      })
    })
  })
});

export const { useCreateTrialMutation } = plansApi;
