import React, { SVGProps } from 'react';

export const PlaySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.36389 1.89852V12.101C1.36388 12.2523 1.40309 12.401 1.47769 12.5326C1.5523 12.6642 1.65975 12.7742 1.78956 12.8519C1.91937 12.9296 2.0671 12.9723 2.21834 12.9759C2.36958 12.9794 2.51916 12.9437 2.65247 12.8722L12.1742 7.77093C12.3137 7.69615 12.4303 7.58494 12.5116 7.44914C12.5929 7.31334 12.6358 7.15804 12.6358 6.99977C12.6358 6.8415 12.5929 6.6862 12.5116 6.5504C12.4303 6.4146 12.3137 6.30338 12.1742 6.2286L2.65247 1.12735C2.51916 1.05585 2.36958 1.02013 2.21834 1.02368C2.0671 1.02723 1.91937 1.06994 1.78956 1.14762C1.65975 1.22531 1.5523 1.33532 1.47769 1.46693C1.40309 1.59854 1.36388 1.74724 1.36389 1.89852V1.89852Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
