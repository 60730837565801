import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

import { uid } from '@components/utils';

export function reposition(qs: ScreenerField[]): ScreenerField[] {
  return qs.map((q, idx) => ({
    ...q,
    position: idx + 1
  }));
}

export function fixedXAxisStyling(provided: DraggableProvided, snapshot: DraggableStateSnapshot): any {
  // Restrict dragging to vertical axis
  let transform = provided.draggableProps?.style?.transform;

  if (snapshot.isDragging && transform) {
    transform = transform.replace(/\(.+,/, '(0,');
  }

  return {
    ...provided.draggableProps.style,
    transform
  };
}

export function createDuplicate(q: ScreenerField): ScreenerField {
  const { helper, label, options, param, number_range_options, other, required, field_type, layout, meta } = q;
  return {
    id: uid(),
    helper,
    label,
    options,
    param,
    number_range_options,
    other,
    required,
    field_type,
    layout,
    position: q.position + 1,
    meta,
    next_screener_field_id: null,
    external_field_id: null
  };
}

export function calculateNumber(questions: ScreenerField[], idx: number): number {
  const startIndex = questions.findIndex((q) => q.field_type === 'start_loom');
  const endIndex = questions.findIndex((q) => q.field_type === 'stop_loom');
  const numberDiff = (startIndex > -1 && idx > startIndex ? 1 : 0) + (endIndex > -1 && idx > endIndex ? 1 : 0);
  return idx + 1 - numberDiff;
}

export const getPortalId = (page: 'study' | 'template' | 'studyBuilder' | 'templateBuilder') => {
  switch (page) {
    case 'study':
      return 'post-publish-survey-actions';
    case 'studyBuilder':
      return 'pre-publish-survey-actions';
    case 'templateBuilder':
    case 'template':
      return 'survey-template-actions';
  }
};

export const scrollTo = (id: number) => {
  document.getElementById(`question-card-${id}`)?.scrollIntoView({ behavior: 'smooth' });
};

export const getTrackKind = (screener: Screener): string => {
  if (!screener.project_id) {
    return 'template';
  } else if (screener.style === 'video_call') {
    return 'screener';
  } else {
    return screener.style;
  }
};

export const getTrackProps = (screener: Screener): Record<string, any> => ({
  kind: getTrackKind(screener),
  study_status: screener.project?.state || 'draft',
  total_question_count: screener.fields.length
});

export const preparePayload = (fields: ScreenerField[]) =>
  fields.map((q) => ({
    ...q,
    id: (q.id < 0 ? null : q.id) as any,
    layout: undefined,
    options: ['multiple_choice', 'single_choice', 'task'].includes(q.field_type) ? q.options : undefined
  }));
