import { mergeAttributes, Node } from '@tiptap/core';

export const DEFAULT_EMAIL_MERGE_TAGS = [
  'candidate.first_name',
  'candidate.name',
  'study.style',
  'study.duration',
  'study.incentive',
  'study.incentive_instructions',
  'study.live_stream',
  'study.live_stream_disclaimer',
  'sender.first_name',
  'sender.name',
  'account.company_name'
];

export interface MergeTagAttributes {
  attr: string;
}

export const MergeTag = Node.create({
  name: 'merge_tag',

  inline: true,

  group: 'inline',

  selectable: true,

  draggable: true,

  editable: false,

  addOptions() {
    return {
      HTMLAttributes: {
        class: 'bg-gray-50 border border-gray-200 px-1 text-gray-700 inline-block'
      }
    };
  },

  addAttributes() {
    return {
      attr: {
        default: null
      }
    };
  },

  parseHTML() {
    return [{ tag: 'div' }];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      `{{${(node.attrs as MergeTagAttributes).attr}}}`
    ];
  }
});
