import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { baseStudiesColumnDefs } from '../helpers/baseStudiesColumnDefs';
import { useStudiesColumnLabels } from '../hooks/useStudiesColumnLabels';
import { activityStudiesColumnDefs } from '@components/StudiesApp/components/StudiesIndex/helpers/activityStudiesColumnDefs';

export const useStudiesColumnDefinitions = ({ groupBy }: { groupBy?: string }): ColumnDef<Study>[] => {
  const getColumnLabel = useStudiesColumnLabels();

  const definitions = useMemo<ColumnDef<Study>[]>(() => {
    return [...baseStudiesColumnDefs({ getColumnLabel, groupBy }), ...activityStudiesColumnDefs({ getColumnLabel })];
  }, [getColumnLabel, groupBy]);

  return definitions;
};
