import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { TeamsIndex, TeamProfile } from './pages';

export const AccountTeamsApp: React.FC = () => {
  return (
    <Routes>
      <Route path='/:id/profile' element={<TeamProfile />} />
      <Route path='/' element={<TeamsIndex />} />
    </Routes>
  );
};
