import * as React from 'react';
import { Link } from 'react-router-dom';
import { track } from '@components/tracking';
import cn from 'classnames';
import { getClassName } from '@components/common/Dropdown/components/utils';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  color?: string;
  hoverColor?: string;
  trackEvent?: string;
  trackProps?: Record<string, any>;
}

export const PopperDropdownButton: React.FC<Props> = ({
  className,
  disabled,
  color = 'gray-700',
  hoverColor = 'indigo-600',
  trackEvent,
  trackProps,
  onClick,
  children,
  ...rest
}) => {
  const onButtonClick = (e) => {
    onClick?.(e);

    if (trackEvent) {
      track(trackEvent, trackProps);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClick?.(e as any);

      if (trackEvent) {
        track(trackEvent, trackProps);
      }
    }
  };

  const buttonProps = {
    tabIndex: 0,
    type: 'button' as const,
    onClick: onButtonClick,
    onKeyDown,
    disabled,
    className: getClassName({ disabled, color, hoverColor, className }),
    ...rest
  };

  return <button {...buttonProps}>{children}</button>;
};
