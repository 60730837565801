import React, { forwardRef, HTMLAttributes, useState } from 'react';
import cn from 'classnames';
import { Spinner } from '@components/common';
import { SearchSVG, CloseSVG, CaretDownSVG } from '@components/svgs';
import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { DropdownInputProps } from '../types';

type Props = HTMLAttributes<HTMLInputElement> & DropdownInputProps;

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ __dropdownInputProps: { loading, inputValue, clearable, clear }, className, onFocus, onBlur, ...rest }, ref) => {
    const [active, setActive] = useState<boolean>(false);

    return (
      <div
        className={cn(
          'flex flex-row pr-3 border placeholder-gray-400 border-gray-200 rounded-md overflow-hidden items-center cursor-text',
          {
            'ring-indigo-600 border-indigo-600 ring-1': active
          }
        )}
      >
        <input
          ref={ref}
          className={cn(
            'focus:border-transparent focus:ring-0 focus:outline-none block w-full px-3 py-2 text-sm placeholder-gray-400',
            className
          )}
          placeholder='Search...'
          {...rest}
          onFocus={composeEventHandlers(onFocus, () => setActive(true))}
          onBlur={composeEventHandlers(onBlur, () => setActive(false))}
        />
        <div className='flex flex-row items-center flex-shrink-0 space-x-2 text-indigo-600'>
          {clearable && inputValue && !loading && (
            <CloseSVG
              aria-label='Clear value'
              className='w-3 h-3'
              onClick={(e) => {
                e.stopPropagation();
                clear();
              }}
            />
          )}
          {loading && <Spinner className='w-3 h-3' />}
          <CaretDownSVG className='w-3 h-3' />
        </div>
      </div>
    );
  }
);
