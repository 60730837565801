import React, { SVGProps } from 'react';

export const AskAiZDS = (props: SVGProps<SVGSVGElement>) => (
  <svg width='80' height='80' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1291_5981)'>
      <path
        d='M9.69775 8.00608C6.78826 8.98655 5.82911 10.0783 5.11987 12.7973C4.41063 10.0783 3.4515 8.98655 0.541992 8.00608C3.4515 7.02567 4.41063 5.93396 5.11987 3.21484C5.82911 5.93396 6.78826 7.02567 9.69775 8.00608Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.3071 3.46923C11.6659 4.02006 11.1249 4.63345 10.7248 6.1611C10.3248 4.63345 9.78373 4.02006 8.14258 3.46923C9.78373 2.9184 10.3248 2.30502 10.7248 0.777344C11.1249 2.30502 11.6659 2.9184 13.3071 3.46923Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5209 11.4115C12.3062 11.8107 11.9058 12.2552 11.6096 13.3622C11.3135 12.2552 10.913 11.8107 9.69824 11.4115C10.913 11.0124 11.3135 10.5679 11.6096 9.46094C11.9058 10.5679 12.3062 11.0124 13.5209 11.4115Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1291_5981'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
