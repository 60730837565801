import React, { SVGProps } from 'react';

export const AddSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 0C5.36766 0 0 5.36705 0 12C0 18.6323 5.36705 24 12 24C18.6323 24 24 18.633 24 12C24 5.36766 18.633 0 12 0ZM12 22.4C6.26545 22.4 1.59998 17.7346 1.59998 12C1.59998 6.26541 6.26545 1.59998 12 1.59998C17.7345 1.59998 22.4 6.26545 22.4 12C22.4 17.7345 17.7345 22.4 12 22.4Z'
      fill='currentColor'
    />
    <path
      d='M17.4068 11.2H12.8V6.5932C12.8 6.1514 12.4418 5.79318 12 5.79318C11.5582 5.79318 11.2 6.15135 11.2 6.5932V11.2H6.5932C6.1514 11.2 5.79319 11.5581 5.79319 12C5.79319 12.4418 6.15136 12.8 6.5932 12.8H11.2V17.4068C11.2 17.8486 11.5582 18.2068 12 18.2068C12.4418 18.2068 12.8 17.8486 12.8 17.4068V12.8H17.4068C17.8486 12.8 18.2068 12.4418 18.2068 12C18.2068 11.5581 17.8486 11.2 17.4068 11.2Z'
      fill='currentColor'
    />
  </svg>
);
