import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'text', 'link'];

  connect() {}

  open(event) {
    this.linkTarget.classList.remove('hidden');
    this.textTarget.classList.add('hidden');
  }

  clickLink(event) {
    this.linkTarget.classList.add('hidden');
    this.textTarget.classList.remove('hidden');
    this.selectTarget.value = 'other';
  }
}
