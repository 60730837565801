import React, { SVGProps } from 'react';

export const UploadInterviewSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_357_2658)'>
      <path
        d='M7 1.3125C6.95674 1.3125 6.91444 1.32533 6.87847 1.34937C6.8425 1.3734 6.81446 1.40757 6.7979 1.44754C6.78134 1.48751 6.77701 1.53149 6.78545 1.57393C6.79389 1.61636 6.81473 1.65534 6.84532 1.68593C6.87591 1.71652 6.91489 1.73736 6.95732 1.7458C6.99976 1.75424 7.04374 1.74991 7.08371 1.73335C7.12368 1.71679 7.15785 1.68875 7.18188 1.65278C7.20592 1.61681 7.21875 1.57451 7.21875 1.53125C7.21875 1.47323 7.1957 1.41759 7.15468 1.37657C7.11366 1.33555 7.05802 1.3125 7 1.3125Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 5.6875C5.6875 6.0356 5.82578 6.36944 6.07192 6.61558C6.31806 6.86172 6.6519 7 7 7C7.3481 7 7.68194 6.86172 7.92808 6.61558C8.17422 6.36944 8.3125 6.0356 8.3125 5.6875C8.3125 5.3394 8.17422 5.00556 7.92808 4.75942C7.68194 4.51328 7.3481 4.375 7 4.375C6.6519 4.375 6.31806 4.51328 6.07192 4.75942C5.82578 5.00556 5.6875 5.3394 5.6875 5.6875V5.6875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 8.75C8.97605 8.35353 8.66082 8.02198 8.27552 7.7908C7.89022 7.55962 7.44933 7.4375 7 7.4375C6.55067 7.4375 6.10978 7.55962 5.72448 7.7908C5.33918 8.02198 5.02395 8.35353 4.8125 8.75'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 1.75H12.6875C12.9196 1.75 13.1421 1.84219 13.3062 2.00628C13.4703 2.17038 13.5625 2.39294 13.5625 2.625V9.625C13.5625 9.85706 13.4703 10.0796 13.3062 10.2437C13.1421 10.4078 12.9196 10.5 12.6875 10.5H1.3125C1.08044 10.5 0.857876 10.4078 0.693782 10.2437C0.529687 10.0796 0.4375 9.85706 0.4375 9.625V2.625C0.4375 2.39294 0.529687 2.17038 0.693782 2.00628C0.857876 1.84219 1.08044 1.75 1.3125 1.75H4.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 13.125H4.8125L5.25 10.5H8.75L9.1875 13.125Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.5 13.125H10.5'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_357_2658'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
