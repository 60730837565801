import React, { useState } from 'react';
import { cloneElement, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import mergeRefs from 'react-merge-refs';

import { useTrapFocus } from '@hooks/useTrapFocus';

interface Props {
  children: ReactElement | null;
  // CSS selector
  selector?: string;
}

export const Portal = ({ selector, children }: Props) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const rootRef = useRef<HTMLElement | null>(null);
  const { ref } = useTrapFocus();

  useEffect(() => {
    if (selector) {
      rootRef.current = document.querySelector(selector);
    }

    if (!rootRef.current) {
      rootRef.current = document.createElement('div');
      document.body.appendChild(rootRef.current);
    }

    setIsReady(true);

    return () => {
      if (rootRef.current && !selector) {
        rootRef.current.remove();
      }
    };
  }, [selector, rootRef]);

  if (!isReady) return null;

  return createPortal(
    <div ref={ref} className='contents'>
      {children}
    </div>,
    rootRef.current as HTMLElement
  );
};
