import { Textarea } from '@components/common';
import * as React from 'react';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  disabled?: boolean;
  study: Study;
  onSave: (v: { id: number } & Partial<Study>) => void;
}

export const ResearchGoal: React.FC<Props> = ({ study, disabled, onSave }) => {
  const [researchGoal, setResearchGoal] = useState<string>(study.research_goal);

  const { callback: debouncedSave } = useDebouncedCallback(onSave, process.env.NODE_ENV === 'test' ? 10 : 300);
  const onChange = (research_goal: string) => {
    setResearchGoal(research_goal);
    debouncedSave({ id: study.id, research_goal });
  };

  return (
    <div className='w-full pt-6'>
      <label htmlFor='research_goal' className='h500-bold block mb-2'>
        Research goal
      </label>
      <span className='h400 block mb-2 text-gray-500'>Describe what you’re hoping the research will achieve.</span>
      <Textarea
        id='research_goal'
        name='research_goal'
        ariaLabel='Research goal'
        disabled={disabled}
        className='h-10'
        minRows={2}
        placeholder='e.g. understand customer perception of feature X'
        onChange={onChange}
        value={researchGoal}
      />
    </div>
  );
};
