import React, { SVGProps } from 'react';

export const DataSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.99874 0C4.66455 0 1.28906 0.88671 1.28906 2.58065V13.4194C1.28906 15.1133 4.66455 16 7.99874 16C11.3329 16 14.7084 15.1133 14.7084 13.4194V2.58065C14.7084 0.88671 11.3329 0 7.99874 0ZM13.6762 13.4194C13.6762 13.9453 11.672 14.9677 7.99874 14.9677C4.32545 14.9677 2.32132 13.9453 2.32132 13.4194V11.2428C3.57345 11.9964 5.79229 12.3871 7.99874 12.3871C10.2052 12.3871 12.424 11.9964 13.6762 11.2428V13.4194ZM13.6762 9.80645C13.6762 10.3324 11.672 11.3548 7.99874 11.3548C4.32545 11.3548 2.32132 10.3324 2.32132 9.80645V7.62994C3.57345 8.38348 5.79229 8.77419 7.99874 8.77419C10.2052 8.77419 12.424 8.38348 13.6762 7.62994V9.80645ZM13.6762 6.19355C13.6762 6.71948 11.672 7.74194 7.99874 7.74194C4.32545 7.74194 2.32132 6.71948 2.32132 6.19355V4.01703C3.57345 4.77058 5.79229 5.16129 7.99874 5.16129C10.2052 5.16129 12.424 4.77058 13.6762 4.01703V6.19355ZM7.99874 4.12903C4.32545 4.12903 2.32132 3.10658 2.32132 2.58065C2.32132 2.05471 4.32545 1.03226 7.99874 1.03226C11.672 1.03226 13.6762 2.05471 13.6762 2.58065C13.6762 3.10658 11.672 4.12903 7.99874 4.12903Z'
      fill='currentColor'
    />
  </svg>
);
