import { Toast } from '@stores/toaster';

export const successDeleteEntityGroup = (): Toast => ({
  heading: `Group deleted`,
  text: `You’ve removed this group`,
  icon: 'success'
});

export const failedDeleteEntityGroup = (): Toast => ({
  heading: 'Failed to delete group',
  text: 'Please try again later, or contact support.',
  icon: 'error'
});

export const successCopied = (): Toast => ({
  heading: 'Highlights copied',
  text: 'Successfully copied to clipboard',
  icon: 'success'
});

export const failedUpdateClip = (): Toast => ({
  heading: 'Failed to update clip',
  text: 'Please try again later, or contact support.',
  icon: 'error'
});
