import { Toast } from 'components/stores/toaster';

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating the session. Please try again later.'
});

export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting the session. Please try again later.'
});

export const failedCreate = (): Toast => ({
  heading: 'Failed to create!',
  icon: 'error',
  text: 'Something went wrong creating the session. Please try again later.'
});

export const failedFetch = (): Toast => ({
  heading: 'Error fetching sessions',
  text: 'There was an error fetching sessions. Please try again later.',
  icon: 'error'
});
