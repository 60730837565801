import * as React from 'react';

import { Text } from '@components/common';
import Tippy from '@tippyjs/react';

import { CellWrapper } from '../shared';

type Props = {
  teamIds?: number[];
  teams?: Team[];
  candidate: Candidate;
};

export const ContactAccessCell: React.FC<Props> = ({ candidate, teamIds, teams }) => {
  const teamsToShow = teams?.filter((team) => teamIds?.includes(team.id));

  const tooltip =
    teamsToShow
      ?.map((t) => t.name)
      .filter((name) => !!name)
      .join(', ') || 'No one can contact this candidate';

  return (
    <CellWrapper wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 overflow-hidden'>
      {candidate.contact_access === 'public' && <Text className='h400 truncate'>Anyone on the workspace</Text>}
      {candidate.contact_access === 'needs_team' && teamsToShow && (
        <Tippy content={tooltip}>
          <div className='h400 truncate'>{teamsToShow.map((t) => t.name).join(', ') || 'No one'}</div>
        </Tippy>
      )}
    </CellWrapper>
  );
};
