import { Text } from '@components/common';
import { noop } from '@components/utils';
import React from 'react';

import { FIELD_CONFIGS } from '@components/GQSurveyBuilder/QuestionCard';
import Tippy from '@tippyjs/react/headless';

const Question = ({ question, ...rest }) => {
  const config = FIELD_CONFIGS[question.field_type];
  let inner: JSX.Element | null = null;
  if (config && question.field_type !== 'info') {
    inner = React.createElement(config.comp, { question, onChange: noop, readOnly: true });
  }
  return (
    <div
      className='w-full p-4 custom-tippy text-gray-900 bg-white border border-gray-200 shadow'
      style={{ width: 500 }}
      {...rest}
    >
      <Text h='500' bold mb='1'>
        {question.label}
      </Text>
      <Text h='400' mb='4'>
        {question.helper}
      </Text>
      {inner}
    </div>
  );
};
export const Preview: React.FC<{ question: ScreenerField; children: JSX.Element }> = ({ question, children }) => (
  <Tippy interactive placement='left' arrow={false} render={(attrs) => <Question question={question} {...attrs} />}>
    {children}
  </Tippy>
);
