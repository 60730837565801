import React, { HTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

import { Text } from '@components/common';
import { StickyNoteSVG } from '@components/svgs';

export const FormExample = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...rest }, ref) => (
    <div ref={ref} className={cn('flex items-center mt-4 mb-2 space-x-2', className)} {...rest}>
      <StickyNoteSVG className='text-gray-500' />

      <Text h='200' color='gray-500'>
        {children}
      </Text>
    </div>
  )
);
