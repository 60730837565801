import { Toast } from '@stores/toaster';

export const failedGetTeams = (): Toast => ({
  heading: 'Failed to load teams',
  icon: 'error',
  text: 'Please try again later, or contact support.'
});
export const successCreateTeam = (): Toast => ({
  heading: 'Team created',
  icon: 'success',
  text: "We've added this team to your account."
});
export const failedCreateTeam = (): Toast => ({
  heading: 'Failed to create team',
  icon: 'error',
  text: 'Please try again later, or contact support.'
});
export const successUpdateTeam = (): Toast => ({
  heading: 'Team updated',
  icon: 'success',
  text: "We've updated this team."
});
export const failedUpdateTeam = (): Toast => ({
  heading: 'Failed to update team',
  icon: 'error',
  text: 'Please try again later, or contact support.'
});
export const successDeleteTeam = (): Toast => ({
  heading: 'Team deleted',
  icon: 'success',
  text: 'This team is no longer accessible.'
});
export const failedDeleteTeam = (): Toast => ({
  heading: 'Failed to delete team',
  icon: 'error',
  text: 'Please try again later, or contact support.'
});
