import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@components/common';
import { useCollectionView } from '@components/stores/view';
import { SUPPORT_EMAIL } from '@constants/emails';

import { NoResultsSVG } from '../NoResultsSVG';

export const NoFilterResultsZDS: React.FC = () => {
  const { setView, view } = useCollectionView();
  const navigate = useNavigate();

  const { search } = useLocation();
  const onClick = () => {
    setView({ tab: 'all' });
    navigate(`all${search}`);
  };

  return (
    <div className='max-w-xl py-6 mx-auto text-center'>
      <NoResultsSVG className='mx-auto mb-4' />
      <span className='block mb-2 text-2xl font-bold text-gray-700'>No results found matching your filters</span>
      <span className='block mb-6 text-gray-700'>
        It may be hiding somewhere. Double check your selected filters. If you need help finding something, you can
        always drop us a line at {SUPPORT_EMAIL}
      </span>
      {view?.tab !== 'all' && (
        <Button medium onClick={onClick}>
          View all repository results
        </Button>
      )}
    </div>
  );
};
