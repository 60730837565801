import { Modifier, Modifiers } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import { useDrawerContext } from './useDrawerContext';

export const useModifiers = (): Modifiers => {
  const {
    state: { headerHeight, contentHeight }
  } = useDrawerContext();

  const restrictToDrawerHeight: Modifier = ({ transform, draggingNodeRect, windowRect }) => {
    if (!draggingNodeRect || !windowRect || !headerHeight || !contentHeight) {
      return transform;
    }

    const height = headerHeight + contentHeight;
    const top = windowRect.height - height;

    const value = { ...transform };

    if (draggingNodeRect.top + transform.y <= top) {
      value.y = top - draggingNodeRect.top;
    } else if (draggingNodeRect.bottom + transform.y >= top + height) {
      value.y = top + height - draggingNodeRect.bottom;
    }

    return value;
  };

  return [restrictToVerticalAxis, restrictToDrawerHeight];
};
