import qs from 'qs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import { studiesToOptions } from '@components/CandidatesApp/CandidatesIndex/ShortlistToStudy';
import { ContactAccess } from '@components/CandidatesApp/CandidatesShow/components/ContactAccess';
import {
  Button,
  Checkbox,
  Heading,
  HelperText,
  Input,
  LabelText,
  Loading,
  RadioGroup,
  Select,
  Text,
  Tooltip
} from '@components/common';
import { useFeature } from '@hooks/useFeature';
import { useStudies } from '@hooks/useStudies';

import { AdditionalAttrsForm } from '../components/AdditionalAttrsForm';
import { PageLayout } from '../components/PageLayout';
import { useCustomerImport } from '../hooks/useCustomerImport';

export const ManageStep: React.FC = () => {
  const hook = useCustomerImport();
  const { id, loading, model, setModel, save, saving } = hook;
  const { studies, loading: studiesLoading } = useStudies();
  const { study_id } = qs.parse(location.search.slice(1));

  const navigate = useNavigate();

  const enableTeams = useFeature('teams');

  const [name, setName] = useState<string | null>(null);
  const [studyId, setStudyId] = useState<number | null>(null);

  const onChange = (id: string) => {
    setStudyId(parseInt(String(id)));
    save({ ...model, project_id: parseInt(String(id)) } as CustomerImport);
  };

  useEffect(() => {
    if (study_id && !loading) {
      onChange(study_id.toString());
    }
  }, [loading]);

  useEffect(() => {
    if (!studiesLoading && !loading && !studyId && model?.project_id) {
      setStudyId(model?.project_id ? model.project_id : null);
    }
    if (!loading && name === null && model?.name) {
      setName(model?.name);
    }
  }, [loading, studiesLoading]);

  return (
    <PageLayout
      hook={hook}
      step={3}
      prev={`/customer_imports/${id}/edit${study_id ? `?study_id=${study_id}` : ''}`}
      next={
        <Button
          disabled={saving}
          primary
          onClick={async () => {
            const ok = await save({ ...model, name } as any);
            if (ok) {
              navigate(`/customer_imports/${id}/import${study_id ? `?study_id=${study_id}` : ''}`);
            }
          }}
        >
          Next
        </Button>
      }
    >
      {loading && <Loading />}
      {model && (
        <div className='py-gutter pb-20'>
          <div className='max-w-2xl mx-auto'>
            <Heading as='h2' className='mb-6'>
              Manage the import
            </Heading>
            <Text mb='2' bold>
              Confirm how you want the data to be imported.
            </Text>
            <HelperText>How would you like to manage existing candidates?</HelperText>
            <div className='pb-6'>
              {loading && <Skeleton className='bg-gray-50 h-24' count={2} />}
              {!loading && (
                <RadioGroup<'true' | 'false'>
                  selected={model.update_existing ? 'true' : 'false'}
                  onChange={(v) => setModel({ ...model, update_existing: v === 'true' })}
                  disabled={!model.can_create_new}
                  options={[
                    {
                      value: 'true',
                      label: 'Update existing candidates',
                      helper:
                        'If any imported candidates are already in your database, we’ll automatically update or add information with the data from your import.'
                    },
                    {
                      value: 'false',
                      label: 'Don’t update existing candidates',
                      helper:
                        'If any imported candidates are already in your database, we won’t import any of the data for those candidates.'
                    }
                  ]}
                />
              )}
            </div>
            {enableTeams && (
              <div className='pb-6'>
                <div className='flex items-center mb-2 space-x-2'>
                  <Text bold>Contact Access</Text>
                  <Tooltip content='Which teams can contact this candidate' />
                </div>
                <HelperText>This determines which users on your account can contact these candidates.</HelperText>
                <ContactAccess
                  className='bg-white border border-gray-200'
                  initialValue={model.contact_access}
                  initialTeamIds={model.team_ids}
                  onChangeValue={(contact_access) => {
                    if (contact_access === 'public') {
                      save({ ...model, contact_access, team_ids: [], team_ids_str: '' });
                    } else {
                      save({ ...model, contact_access });
                    }
                  }}
                  onChangeTeamIds={(team_ids) => {
                    save({ ...model, team_ids, team_ids_str: team_ids.join(',') });
                  }}
                />
              </div>
            )}
            <div className='pb-6'>
              <Text mb='2' bold>
                Name your import
              </Text>
              <HelperText>This will make it easier to find the candidates on this list later.</HelperText>
              {loading && <Skeleton className='bg-gray-50 h-12' />}
              {!loading && <Input name='name' size='md' value={name || ''} onChange={setName} />}
            </div>
            {studies && studies?.length > 0 && (
              <div className='pb-6'>
                <LabelText optional>Shortlist for study</LabelText>
                <HelperText>Everyone on the list will be added to your general candidate panel by default.</HelperText>
                {studiesLoading && <Skeleton className='bg-gray-50 h-12' />}
                {!studiesLoading && (
                  <Select
                    className='bg-white'
                    options={studies ? studiesToOptions(studies).map((o) => ({ ...o, value: String(o.value) })) : []}
                    value={String(studyId)}
                    onChange={onChange}
                  />
                )}
                <div className='flex items-center py-4 space-x-2'>
                  <Checkbox
                    name='skip_ineligible'
                    onChange={(val) => save({ ...model, skip_ineligible: val })}
                    selected={model.skip_ineligible}
                  />
                  <Text h='400'>Exclude ineligible people from the shortlist</Text>
                  <Tooltip>
                    Don't shortlist candidates who don't meet your eligibility criteria set in your account settings.
                  </Tooltip>
                </div>
              </div>
            )}
            <div className='pb-6'>
              <LabelText optional>Add attributes in bulk</LabelText>
              <HelperText>
                Include more information on the imported customers that wasn’t included in your .CSV file.
              </HelperText>
              {loading && <Skeleton className='bg-gray-50 h-12' />}
              {!loading && (
                <AdditionalAttrsForm
                  disabled={saving}
                  value={model.attrs}
                  onChange={(attrs) => setModel({ ...model, attrs })}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </PageLayout>
  );
};
