import * as React from 'react';

import { Button, Modal, Text } from '@components/common';

interface Props {
  onClose: (e) => void;
  previousValue: any;
  currentValue: any;
  candidateName: string;
  attrName: string;
  onConfirm: () => void;
}

export const EditConfirmationModal: React.FC<Props> = ({
  attrName,
  candidateName,
  onClose,
  onConfirm,
  previousValue,
  currentValue
}) => {
  const renderFooter = () => (
    <div className='space-x-6'>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onConfirm} primary>
        Confirm &amp; update
      </Button>
    </div>
  );

  return (
    <Modal title={`Confirm ${attrName} update`} size='md' onClose={onClose} renderFooter={renderFooter}>
      {!currentValue && (
        <Text className='mb-2'>
          You are trying to remove{' '}
          <span className='font-semibold'>
            {candidateName}'s {attrName}
          </span>
          . This value is used to contact the candidate for studies, and for any identity resolution when importing
          data. Are you sure you want to proceed?
        </Text>
      )}
      {currentValue && (
        <Text className='mb-2'>
          You are changing{' '}
          <span className='font-semibold'>
            {candidateName}'s {attrName}
          </span>{' '}
          from <span className='font-semibold'>{previousValue}</span> to {''}
          <span className='font-semibold'>{currentValue}</span>. This value is used to contact the candidate for
          studies, and for any identity resolution when importing data. Are you sure you want to proceed?
        </Text>
      )}
    </Modal>
  );
};
