import * as React from 'react';

import { Button, Modal } from '@components/common';
import { humanize } from '@components/utils';
import { usePlan } from '@hooks/usePlan';

import { PERMISSIONS } from './constants';
import { ModalProps } from './types';

export const UpgradeModal: React.FC<ModalProps> = ({ role, onSubmit, onCancel, cta = 'Confirm & Send' }) => {
  const { costs, interval } = usePlan();
  return (
    <Modal
      size='md'
      title='Confirm invitation'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button link onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button primary onClick={() => onSubmit()}>
            {cta}
          </Button>
        </>
      )}
    >
      <>
        <p className='h500 mb-4 font-bold'>You understand that:</p>
        <ul className='ml-6 space-y-1 list-disc'>
          <li>They will be invited as a {humanize(role)}</li>
          <li>{PERMISSIONS[role]}</li>
          <li>
            The cost of your plan will increase by an additional <b>${costs.seat}</b> per {interval}, prorated
            immediately.
          </li>
        </ul>
      </>
    </Modal>
  );
};
