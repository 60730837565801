import * as React from 'react';

import { Button, Heading } from '@components/common';
import {
  WindowFooter,
  WindowHeader,
  WindowLayout,
  WindowLayoutBody,
  WindowStickyContainer
} from '@components/layouts/WindowLayout';
import { Stepper, StepperProps } from '@components/shared/Stepper';
import { ChevronLeftSVG, ChevronRightSVG } from '@components/svgs';

import { UseCustomerImport } from '../hooks/useCustomerImport';

interface Props {
  customerImportId?: number;
  step: number;
  next?: React.ReactNode | false;
  beforeNext?: () => boolean;
  prev?: React.ReactNode | false;
  disabled?: boolean;
  className?: string;
  hook?: UseCustomerImport;
}
export const PageLayout: React.FC<Props> = ({
  customerImportId,
  className,
  next,
  beforeNext,
  prev,
  step,
  children,
  disabled,
  hook
}) => {
  const href = (thisStep: number, path: string) =>
    customerImportId && step > thisStep ? `/customer_imports/${path.replace(':id', String(customerImportId))}` : '';

  const steps: StepperProps['steps'] = [
    { id: '1', name: 'Upload', href: href(1, 'new'), status: 'current' },
    { id: '2', name: 'Classify', href: href(2, ':id/edit'), status: 'upcoming' },
    { id: '3', name: 'Manage', href: href(3, ':id/manage'), status: 'upcoming' },
    { id: '4', name: 'Import', href: href(4, ':id/import'), status: 'upcoming' }
  ];

  if (step > 1) steps[0].status = 'complete';
  if (step > 2) steps[1].status = 'complete';
  if (step > 3) steps[2].status = 'complete';
  steps[step - 1].status = 'current';

  return (
    <WindowLayout noSidebar>
      <WindowStickyContainer>
        <WindowHeader>
          <Heading className='flex-1'>New import</Heading>
        </WindowHeader>
        <Stepper steps={steps} />
      </WindowStickyContainer>

      <WindowLayoutBody className={className} fixedHeader>
        {children}
      </WindowLayoutBody>

      <WindowFooter>
        <div className='flex items-center justify-center w-full space-x-6'>
          {typeof prev === 'string' ? (
            <Button disabled={disabled} href={prev} spa>
              <ChevronLeftSVG className='mr-2' /> Back
            </Button>
          ) : (
            prev
          )}
          <span className='text-sm text-gray-500'>Step {step} of 4</span>
          {typeof next === 'string' ? (
            <Button
              onClick={beforeNext ? (e) => !beforeNext() && e.preventDefault() : undefined}
              disabled={disabled}
              href={next}
              spa
              primary
            >
              Next <ChevronRightSVG className='ml-2' />
            </Button>
          ) : (
            next
          )}
        </div>
      </WindowFooter>
    </WindowLayout>
  );
};
