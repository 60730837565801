import React from 'react';

export const incentiveStr = (study: Study, participation?: Participation): string | JSX.Element | null => {
  const { incentive_method, incentive, incentive_title, currencySymbol } = study;
  const partyIncentive = participation ? participation.incentive_in_whole_currency : null;
  const incentiveAmount = partyIncentive === null ? incentive : partyIncentive;
  const empty = <i>No incentive</i>;
  switch (incentive_method) {
    case 'manual':
    case 'tremendous':
      return incentiveAmount ? `${currencySymbol}${incentiveAmount} reward` : empty;
    case 'coupon':
      return incentive_title + ' coupon';
    case 'product':
      return incentive_title + ' product gift';
    case 'other':
      return incentive_title;
    default:
      return empty;
  }
};
