import React, { SVGProps } from 'react';

export const DashboardZDSSVG = () => (
  <svg width='168' height='100' viewBox='0 0 168 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='167' height='99' rx='3.5' fill='white' stroke='#9FA6B2' />
    <circle cx='29' cy='15' r='4.5' fill='white' stroke='#9FA6B2' />
    <circle cx='139' cy='15' r='4.5' fill='white' stroke='#9FA6B2' />
    <rect x='8' y='38' width='24' height='24' rx='2' fill='#E5E7EB' />
    <rect x='40' y='38' width='24' height='24' rx='2' fill='#E5E7EB' />
    <rect x='72' y='38' width='24' height='24' rx='2' fill='#E5E7EB' />
    <rect x='104' y='38' width='24' height='24' rx='2' fill='#E5E7EB' />
    <rect x='136' y='38' width='24' height='24' rx='2' fill='#E5E7EB' />
    <g clipPath='url(#clip0_535_1659)'>
      <path
        d='M152.95 45.0502C151.628 43.7281 149.87 43 148 43C146.13 43 144.372 43.7281 143.05 45.0502C141.728 46.3724 141 48.1302 141 50C141 51.8697 141.728 53.6276 143.05 54.9498C144.372 56.2719 146.13 57 148 57C149.87 57 151.628 56.2719 152.95 54.9498C154.272 53.6276 155 51.8697 155 50C155 48.1302 154.272 46.3724 152.95 45.0502ZM144.509 55.0965C144.802 53.4108 146.263 52.1674 148 52.1674C149.737 52.1674 151.198 53.4108 151.491 55.0965C150.497 55.7794 149.294 56.1797 148 56.1797C146.706 56.1797 145.503 55.7794 144.509 55.0965ZM145.774 49.1214C145.774 47.894 146.773 46.8956 148 46.8956C149.227 46.8956 150.226 47.8941 150.226 49.1214C150.226 50.3486 149.227 51.3471 148 51.3471C146.773 51.3471 145.774 50.3486 145.774 49.1214ZM152.201 54.5275C151.98 53.7428 151.542 53.0308 150.927 52.4746C150.55 52.1333 150.121 51.8641 149.66 51.6743C150.494 51.1303 151.046 50.1892 151.046 49.1214C151.046 47.4418 149.68 46.0753 148 46.0753C146.32 46.0753 144.954 47.4418 144.954 49.1214C144.954 50.1892 145.506 51.1303 146.34 51.6743C145.879 51.8641 145.45 52.1332 145.073 52.4745C144.458 53.0307 144.02 53.7427 143.799 54.5274C142.583 53.3981 141.82 51.7865 141.82 50C141.82 46.5925 144.592 43.8203 148 43.8203C151.408 43.8203 154.18 46.5925 154.18 50C154.18 51.7866 153.417 53.3982 152.201 54.5275Z'
        fill='#5850EC'
      />
    </g>
    <rect x='8' y='68' width='24' height='24' rx='2' fill='#E5E7EB' />
    <rect x='40' y='68' width='24' height='24' rx='2' fill='#E5E7EB' />
    <g clipPath='url(#clip1_535_1659)'>
      <path
        d='M56.9498 75.0502C55.6276 73.7281 53.8697 73 52 73C50.1302 73 48.3724 73.7281 47.0502 75.0502C45.7281 76.3724 45 78.1302 45 80C45 81.8697 45.7281 83.6276 47.0502 84.9498C48.3724 86.2719 50.1302 87 52 87C53.8697 87 55.6276 86.2719 56.9498 84.9498C58.2719 83.6276 59 81.8697 59 80C59 78.1302 58.2719 76.3724 56.9498 75.0502ZM48.5093 85.0965C48.8021 83.4108 50.2628 82.1674 52 82.1674C53.7373 82.1674 55.1979 83.4108 55.4907 85.0965C54.4968 85.7794 53.2943 86.1797 52 86.1797C50.7057 86.1797 49.5032 85.7794 48.5093 85.0965ZM49.7743 79.1214C49.7743 77.894 50.7727 76.8956 52 76.8956C53.2273 76.8956 54.2257 77.8941 54.2257 79.1214C54.2257 80.3486 53.2273 81.3471 52 81.3471C50.7727 81.3471 49.7743 80.3486 49.7743 79.1214ZM56.2012 84.5275C55.9804 83.7428 55.542 83.0308 54.9274 82.4746C54.5503 82.1333 54.1212 81.8641 53.6597 81.6743C54.4937 81.1303 55.0462 80.1892 55.0462 79.1214C55.0462 77.4418 53.6796 76.0753 52 76.0753C50.3204 76.0753 48.9539 77.4418 48.9539 79.1214C48.9539 80.1892 49.5064 81.1303 50.3403 81.6743C49.8789 81.8641 49.4497 82.1332 49.0726 82.4745C48.4581 83.0307 48.0196 83.7427 47.7988 84.5274C46.5827 83.3981 45.8203 81.7865 45.8203 80C45.8203 76.5925 48.5925 73.8203 52 73.8203C55.4075 73.8203 58.1797 76.5925 58.1797 80C58.1797 81.7866 57.4173 83.3982 56.2012 84.5275Z'
        fill='#5850EC'
      />
    </g>
    <rect x='72' y='68' width='24' height='24' rx='2' fill='#E5E7EB' />
    <rect x='104' y='68' width='24' height='24' rx='2' fill='#E5E7EB' />
    <g clipPath='url(#clip2_535_1659)'>
      <path
        d='M120.95 75.0502C119.628 73.7281 117.87 73 116 73C114.13 73 112.372 73.7281 111.05 75.0502C109.728 76.3724 109 78.1302 109 80C109 81.8697 109.728 83.6276 111.05 84.9498C112.372 86.2719 114.13 87 116 87C117.87 87 119.628 86.2719 120.95 84.9498C122.272 83.6276 123 81.8697 123 80C123 78.1302 122.272 76.3724 120.95 75.0502ZM112.509 85.0965C112.802 83.4108 114.263 82.1674 116 82.1674C117.737 82.1674 119.198 83.4108 119.491 85.0965C118.497 85.7794 117.294 86.1797 116 86.1797C114.706 86.1797 113.503 85.7794 112.509 85.0965ZM113.774 79.1214C113.774 77.894 114.773 76.8956 116 76.8956C117.227 76.8956 118.226 77.8941 118.226 79.1214C118.226 80.3486 117.227 81.3471 116 81.3471C114.773 81.3471 113.774 80.3486 113.774 79.1214ZM120.201 84.5275C119.98 83.7428 119.542 83.0308 118.927 82.4746C118.55 82.1333 118.121 81.8641 117.66 81.6743C118.494 81.1303 119.046 80.1892 119.046 79.1214C119.046 77.4418 117.68 76.0753 116 76.0753C114.32 76.0753 112.954 77.4418 112.954 79.1214C112.954 80.1892 113.506 81.1303 114.34 81.6743C113.879 81.8641 113.45 82.1332 113.073 82.4745C112.458 83.0307 112.02 83.7427 111.799 84.5274C110.583 83.3981 109.82 81.7865 109.82 80C109.82 76.5925 112.592 73.8203 116 73.8203C119.408 73.8203 122.18 76.5925 122.18 80C122.18 81.7866 121.417 83.3982 120.201 84.5275Z'
        fill='#5850EC'
      />
    </g>
    <rect x='136' y='68' width='24' height='24' rx='2' fill='#E5E7EB' />
    <defs>
      <clipPath id='clip0_535_1659'>
        <rect width='14' height='14' fill='white' transform='translate(141 43)' />
      </clipPath>
      <clipPath id='clip1_535_1659'>
        <rect width='14' height='14' fill='white' transform='translate(45 73)' />
      </clipPath>
      <clipPath id='clip2_535_1659'>
        <rect width='14' height='14' fill='white' transform='translate(109 73)' />
      </clipPath>
    </defs>
  </svg>
);
