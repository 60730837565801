import * as React from 'react';
import { useState } from 'react';
import Calendar, { CalendarProps } from 'react-calendar';

const RightArrowSVG: React.FC<any> = ({ onClick, ...props }) => (
  <div
    onClick={onClick}
    className='hover:bg-gray-200 hover:text-indigo-700 flex items-center justify-center w-10 h-10 text-indigo-600 rounded-full'
  >
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.26465 0.4375L10.5174 6.69083C10.558 6.73141 10.5903 6.77961 10.6123 6.83266C10.6343 6.88571 10.6456 6.94257 10.6456 7C10.6456 7.05743 10.6343 7.1143 10.6123 7.16734C10.5903 7.22039 10.558 7.26859 10.5174 7.30917L4.26465 13.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </div>
);
const LeftArrowSVG: React.FC<any> = ({ onClick, ...props }) => (
  <div onClick={onClick} className='transform rotate-180'>
    <RightArrowSVG {...props} />
  </div>
);

interface Props {
  double?: boolean;
  startDate: Date;
  endDate: Date | null;
  isRange: boolean;
  onChange: (startDate: Date, endDate?: Date) => void;
}
export const BookingCalendar: React.FC<Props> = ({
  double,
  startDate: initialStartDate,
  endDate: initialEndDate,
  isRange,
  onChange
}) => {
  const [startDate, setStartDate] = useState<Date>(initialStartDate);
  const [endDate, setEndDate] = useState<Date | null | undefined>(initialEndDate);

  const [activeStartDate, setActiveStartDate] = useState(startDate);

  let modeProps: Pick<CalendarProps, 'value' | 'activeStartDate' | 'onChange' | 'selectRange' | 'minDate'>;

  if (isRange) {
    modeProps = {
      activeStartDate,
      selectRange: true,
      value: [startDate, endDate ?? new Date()],
      onChange: ([start, end]: [Date, Date]) => {
        setStartDate(start);
        setEndDate(end);
        onChange(start, end);
      }
    };
  } else {
    modeProps = {
      minDate: endDate ? startDate : undefined,
      selectRange: false,
      value: startDate,
      onChange: (date: Date) => {
        setStartDate(date);
        onChange(date);
      }
    };
  }

  const nextMonth = () => {
    const date = new Date(activeStartDate);
    date.setMonth(date.getMonth() + 1);
    setActiveStartDate(date);
  };
  const prevMonth = () => {
    const date = new Date(activeStartDate);
    date.setMonth(date.getMonth() - 1);
    setActiveStartDate(date);
  };

  return (
    <div className='flex'>
      <Calendar
        // minDate={new Date()}
        // defaultValue={date}
        className='mx-auto'
        showDoubleView={double}
        minDetail='month'
        maxDetail='month'
        view='month'
        prev2Label={null}
        next2Label={null}
        prevLabel={<LeftArrowSVG onClick={prevMonth} />}
        nextLabel={<RightArrowSVG onClick={nextMonth} />}
        onActiveStartDateChange={() => null}
        {...modeProps}
      />
    </div>
  );
};
