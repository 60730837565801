import { CardTitle, Text } from '@components/common';
import { NoParticipantsSVG } from './NoParticipantsSVG';
import * as React from 'react';

export const NoParticipantsZDS = () => (
  <div className='flex flex-col px-page py-gutter h-full'>
    <CardTitle className='mb-6'>Participants</CardTitle>
    <div className='p-4 bg-white rounded border border-gray-200 flex flex-col items-center justify-center flex-grow'>
      <NoParticipantsSVG />
      <Text h='700' bold className='mb-2 mt-6'>
        No participants
      </Text>
      <Text color='gray-500'>There were no participants in this study.</Text>
    </div>
  </div>
);
