import React from 'react';

import { DashboardHeader, DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';

import { NewStudyButtons } from './components/NewStudySlideout/NewStudySlideout';

export const StudyNew: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeader h1='New Study' />
      <DashboardLayoutBody>
        <div className='pb-10'>
          <NewStudyButtons />
        </div>
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
