import React, { SVGProps } from 'react';

export const ChevronRightSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='14' viewBox='0 0 9 14' width='9' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m1.20831.4375 6.25334 6.25333c.04063.04058.07287.08878.09487.14183.022.05304.03332.10991.03332.16734s-.01132.11429-.03332.16734-.05424.10125-.09487.14183l-6.25334 6.25333'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='.875'
    />
  </svg>
);
