import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { isSupported, SDKResult, SDKUnsupportedReasons, setup } from '@loomhq/loom-sdk';

export type RecorderStatus = 'init' | 'new' | 'starting' | 'started' | 'completed' | 'done' | 'canceled' | 'error';
interface Params {
  onCancel?: () => void;
  onStart?: () => void;
  onComplete?: (id: string) => void;
}
interface Hook {
  ref: React.MutableRefObject<HTMLButtonElement>;
  status: RecorderStatus;
  reset: () => void;
  errorMsg: string;
  recordingId: string | null;
  button: ReturnType<SDKResult['configureButton']>;
  error: SDKUnsupportedReasons;
}
type SDKButton = ReturnType<SDKResult['configureButton']>;

const ERROR_MESSAGES: Record<SDKUnsupportedReasons, string> = {
  'incompatible-browser':
    'a modern browser that supports screen and video share. Please try a different browser like Chrome.',
  'third-party-cookies-disabled': 'to enable third party cookies in your browser settings',
  'no-media-streams-support':
    'a modern browser that supports screen and video share. Please try a different browser like Chrome.'
};

function getApiKey(): string | undefined {
  const tokenEl = document.getElementById('loom-sdk-api-key');
  if (tokenEl && tokenEl instanceof HTMLMetaElement) {
    return tokenEl.content;
  }
}
const LOOM_API_KEY = getApiKey();

export const useRecorder = ({ onCancel, onStart, onComplete }: Params): Hook => {
  // TODO: potentialy combine error into status here
  const [status, setStatus] = useState<RecorderStatus>('init');
  const [error, setError] = useState<SDKUnsupportedReasons>();
  const ref = useRef<HTMLButtonElement>();
  const [button, setButton] = useState<SDKButton>();
  const [recordingId, setRecordingId] = useState<string | null>(null);
  // const button_id = useMemo(() => uid().toString(), []);

  const errorMsg = status === 'error' && error ? ERROR_MESSAGES[error] : null;

  useEffect(() => {
    (async () => {
      const { supported, error: supportError } = await isSupported();
      if (supported) {
        init();
      } else {
        setStatus('error');
        setError(supportError);
      }
    })();
  }, []);

  async function init(): Promise<boolean> {
    if (error) {
      // TODO: some sort of tracking for this.
      console.log('Not supported');
      return false;
    }
    const { configureButton } = await setup({ apiKey: LOOM_API_KEY });
    const button = configureButton({
      element: ref.current
    });
    button.on('start', () => {
      setStatus('starting');
    });

    button.on('complete', () => {
      setStatus('completed');
    });

    button.on('insert-click', (video) => {
      setStatus('done');
      setRecordingId(video.id);
      onComplete?.(video.id);
    });

    button.on('cancel', () => {
      setStatus('canceled');
      onCancel && onCancel();
    });

    button.on('lifecycle-update', (e) => {
      if (e === 'pre-recording') {
        // button.moveBubble({ x: window.innerWidth - 400, y: 0 });
      }
    });

    button.on('recording-start', () => {
      setStatus('started');
      onStart && onStart();
    });
    setStatus('new');
    setButton(button);
    return true;
  }

  function reset() {
    if (['canceled', 'completed', 'done'].includes(status)) {
      setStatus('new');
      setRecordingId(null);
    }
  }

  return {
    status,
    reset,
    recordingId,
    error: error as any,
    errorMsg: errorMsg as any,
    button: button as any,
    ref: ref as any
  };
};
