import * as React from 'react';

import { labelFormatter } from '@components/ScreenerResults/ScreenerChart/helpers';
import Tippy from '@tippyjs/react';

const LABEL_STYLE = { fontFamily: 'inherit', fontSize: '14px' };

export const CustomLabel: React.FC<{ text?: any } & Record<string, any>> = (props) => (
  <Tippy content={props.text}>
    <text {...props} className='focus:outline-none' style={LABEL_STYLE} dy='4'>
      {labelFormatter(props.text)}
    </text>
  </Tippy>
);
