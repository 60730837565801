import { DropzoneState, useDropzone } from 'react-dropzone';

import { useToaster } from '@stores/toaster';

import { useBulkUploadActions, useBulkUploadState } from '../../context';
import { useCreateRecordingItems } from '../../modals/useCreateRecordingItems';
import { SUPPORTED_FILE_TYPES } from '../UploadManagerZDS/UploadManagerZDS';

type UseDrop = (disabled?: boolean) => DropzoneState;
export const useDrop: UseDrop = (disabled) => {
  const { addItems } = useBulkUploadActions();
  const { studyId } = useBulkUploadState();

  const showToast = useToaster();

  const { createItems } = useCreateRecordingItems({ studyId });

  const isFileAllowed = (file: File) => SUPPORTED_FILE_TYPES.some((ext) => file.name.toLowerCase().endsWith(ext));

  const handleDrop = (files: File[]) => {
    const badFiles = files.filter((f) => !isFileAllowed(f));
    const goodFiles = files.filter((f) => isFileAllowed(f));

    if (badFiles.length > 0) {
      let heading = 'We can’t upload that file.';
      if (badFiles.length > 1) {
        heading = 'We can’t upload some of those files!';
      }
      showToast({
        heading,
        text: `Sorry, we only support ${SUPPORTED_FILE_TYPES.join(', ')} files.`,
        icon: 'error'
      });
    }

    if (goodFiles.length > 0) {
      createItems(goodFiles).then(addItems);
    }
  };
  const dropzone = useDropzone({
    disabled,
    onDrop: handleDrop,
    noClick: true,
    accept: {
      'video/mp4': ['.mp4'],
      'video/x-m4v': ['.mv4'],
      'video/quicktime': ['.mov'],
      'audio/mpeg': ['.mp3']
    }
  });

  return dropzone;
};
