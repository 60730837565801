import React from 'react';

import { Text } from '@components/common';
import { useTranslation } from 'react-i18next';

const LoomLogo = () => (
  <svg role='img' width='67' height='20' viewBox='0 0 67 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <title>Loom Logo</title>
    <path d='M66.4286 0H0V20H66.4286V0Z' fill='white' />
    <path d='M25.4454 17.2795V2.67871H28.1349V17.2795H25.4454Z' fill='#222222' />
    <path
      d='M51.241 7.35741H53.8094V8.56787C54.3551 7.59997 55.6298 7.0752 56.7214 7.0752C58.0769 7.0752 59.1686 7.66031 59.6741 8.7288C60.4622 7.51832 61.5148 7.0752 62.8292 7.0752C64.6692 7.0752 66.4286 8.1845 66.4286 10.8462V17.2795H63.8199V11.3911C63.8199 10.322 63.2936 9.51565 62.06 9.51565C60.9071 9.51565 60.2193 10.4031 60.2193 11.4722V17.2802H57.5507V11.3911C57.5507 10.322 57.0048 9.51565 55.7908 9.51565C54.6177 9.51565 53.9299 10.383 53.9299 11.4722V17.2802H51.241V7.35741Z'
      fill='#222222'
    />
    <path
      d='M34.2128 17.5674C31.183 17.5674 28.989 15.3275 28.989 12.3191C28.989 9.35857 31.1759 7.06543 34.2128 7.06543C37.2645 7.06543 39.4367 9.37986 39.4367 12.3191C39.4367 15.3056 37.2414 17.5674 34.2128 17.5674ZM34.2128 9.49405C32.6501 9.49405 31.379 10.7613 31.379 12.3197C31.379 13.878 32.6501 15.1453 34.2128 15.1453C35.7755 15.1453 37.046 13.878 37.046 12.3197C37.046 10.7613 35.7755 9.49405 34.2128 9.49405Z'
      fill='#222222'
    />
    <path
      d='M45.1816 17.5674C42.1517 17.5674 39.9577 15.3275 39.9577 12.3191C39.9577 9.35857 42.1447 7.06543 45.1816 7.06543C48.2333 7.06543 50.4055 9.37986 50.4055 12.3191C50.4055 15.3056 48.209 17.5674 45.1816 17.5674ZM45.1816 9.46032C43.601 9.46032 42.3151 10.743 42.3151 12.3184C42.3151 13.894 43.601 15.1766 45.1816 15.1766C46.762 15.1766 48.048 13.894 48.048 12.3184C48.0474 10.743 46.7615 9.46032 45.1816 9.46032Z'
      fill='#222222'
    />
    <path
      d='M20.0248 8.8697H14.1692L19.2404 5.95147L18.1268 4.02834L13.0556 6.94656L15.9828 1.89243L14.0533 0.781766L11.1261 5.83588V0H8.89866V5.83644L5.97036 0.781766L4.04149 1.89187L6.96923 6.946L1.89805 4.02834L0.78432 5.95092L5.8555 8.86915H0V11.0894H5.85494L0.78432 14.0076L1.89805 15.9307L6.96867 13.0131L4.04093 18.0672L5.97036 19.1773L8.8981 14.1226V19.9591H11.1256V14.1232L14.0528 19.1773L15.9821 18.0672L13.0544 13.0125L18.1256 15.9307L19.2394 14.0076L14.1687 11.0899H20.0236V8.8697H20.0248ZM10.0124 12.9991C8.33284 12.9991 6.97147 11.6422 6.97147 9.96808C6.97147 8.29399 8.33284 6.93706 10.0124 6.93706C11.692 6.93706 13.0533 8.29399 13.0533 9.96808C13.0533 11.6422 11.692 12.9991 10.0124 12.9991Z'
      fill='#625DF5'
    />
  </svg>
);

export const PoweredByLoom: React.FC = () => {
  const { t } = useTranslation('StartLoom');

  return (
    <div className='flex flex-row mb-6 space-x-2'>
      <Text h='400'>{t('powered_by_loom')}</Text>
      <LoomLogo />
    </div>
  );
};
