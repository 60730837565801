import * as React from 'react';

import { LocationSVG } from '@components/svgs';

export const Location: QuestionCardBodyComponent = () => {
  return (
    <>
      <div className='max-w-md px-2 py-2 text-gray-400 border border-gray-200 rounded-md'>
        <div className='flex items-center'>
          <LocationSVG className='mx-2 text-gray-700' />
          <span>Location</span>
        </div>
      </div>
      <img src='/temp-assets/powered_by_google.png' alt='powered_by_google_logo' className='pt-4' />
    </>
  );
};
