import * as React from 'react';
import { useContext, useState } from 'react';

import { Input } from '@components/common';
import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { getStateKey, onSelectValue as defaultOnSelectValue } from '@components/shared/GridTable/components/utils';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';

const checkForZero = (stateValue, initialValue) => {
  if (stateValue === 0 || stateValue) {
    return stateValue;
  } else if (initialValue === 0 || initialValue) {
    return initialValue;
  } else {
    return null;
  }
};

export const NumberCell: EditableCell = (props) => {
  const { state, setState } = useContext(GridContext);

  const { value: initialValue, labelPrefix, onSelectValue: passedOnSelectValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const [value, setValue] = useState(initialValue);

  const [editing, setEditing] = useState(false);

  const isCustomAttr = column.id.slice(0, 5) === 'extra';

  if (!isCustomAttr && !passedOnSelectValue) {
    return (
      <CellWrapper>
        {labelPrefix}
        {initialValue !== undefined && initialValue !== null && String(initialValue)}
      </CellWrapper>
    );
  }

  const onChange = (v) => {
    setValue(v);
  };

  const onBlur = async (currentValue) => {
    setEditing(false);
    if (currentValue === initialValue) {
      return;
    }

    if (passedOnSelectValue) {
      passedOnSelectValue(currentValue);
      return;
    }

    await defaultOnSelectValue({
      currentValue,
      initialValue,
      rowId: String(stateKey),
      setState,
      updateCellData,
      columnId: column.id
    });
  };

  const currentValue =
    value !== '' && value !== initialValue ? value : checkForZero(state?.[stateKey]?.[column?.id], initialValue);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onBlur(currentValue);
    }
  };

  if (!editing) {
    return (
      <CellWrapper
        wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600'
        onClick={() => setEditing(true)}
      >
        {labelPrefix}
        {currentValue}
      </CellWrapper>
    );
  }

  return (
    <Input
      type='number'
      onBlur={() => {
        onBlur(currentValue);
      }}
      onKeyDown={onKeyDown}
      autoFocus
      value={currentValue}
      onChange={(v) => onChange(v ? parseInt(v) : null)}
      placeholder='Enter…'
      className='h400 w-full px-1 py-3 outline-none border border-transparent rounded-md focus:border-indigo-600'
    />
  );
};
