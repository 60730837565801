import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';

import { Provider } from './hooks/useSearch';

export const Search = <D extends unknown, C extends Record<string, any>>({
  query,
  filters,
  config,
  adapter,
  infiniteResults,
  children
}: PropsWithChildrenFn<SearchProps<D, C>, SearchContext<D>>): ReactElement => {
  const [data, setData] = useState<D>();
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [results, setResults] = useState<AdapterResults<D>>({});
  const resultsRef = useRef<AdapterResults<D>>({});

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    setPage(page + 1);
  };

  const getKey = () => `${query}_${filters}_${page}`;

  const context = useMemo<SearchContext<D>>(
    () => ({
      query,
      data,
      page,
      loading: !data && !error,
      error,
      nextPage,
      prevPage,
      isFetching,
      setData
    }),
    [data, page, error, isFetching]
  );

  useEffect(() => {
    setData(undefined);
    setPage(0);
  }, [query, filters, config]);

  useEffect(() => {
    if (!infiniteResults) {
      setData(undefined);
    }
  }, [page, infiniteResults]);

  useEffect(() => {
    (async () => {
      try {
        if (infiniteResults && page === 0) {
          setIsFetching(true);
        }
        /** This strategy helps us get the most recent result from multiple concurrent requests */
        resultsRef.current[getKey()] = await adapter({ query, filters, page, infiniteResults, prevData: data }, config);
        setResults({ ...resultsRef.current });
      } catch (_) {
        setError(true);
      } finally {
        setIsFetching(false);
      }
    })();
  }, [query, filters, page, config]);

  useEffect(() => {
    setData(results[getKey()]);
  }, [results]);

  return <Provider<D> value={context}>{typeof children === 'function' ? children(context) : children}</Provider>;
};
