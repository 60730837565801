import { CollectionAttribute } from './types';

type GetGroupFunc = <D>(root: CollectionAttribute<D>) => (records: D[], option: string) => D[];

export const getGroupFunc: GetGroupFunc = (root) => (records, option) =>
  records.filter((record) => {
    if (option === '') {
      return [null, undefined, ''].includes(root.getValue(record));
    } else if (option === 'focus_groups') {
      return (record as any).focus_group;
    } else {
      const value = root.getValue(record);
      if (Array.isArray(value)) {
        return option === 'video_call'
          ? value.includes(option) && !(record as any).focus_group
          : value.includes(option);
      } else {
        return option === 'video_call' ? value === option && !(record as any).focus_group : value === option;
      }
    }
  });
