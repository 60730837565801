import * as React from 'react';
import { Text } from '@components/common';
import { MeetingProviderIcon } from './MeetingProviderIcon';

export const MeetingProviderDropdownContent: React.FC<{ option: MeetingProvider | undefined }> = ({ option }) => {
  if (option == undefined) {
    return (<div className='flex-grow'>Add location</div>)
  }

  return (
    <div className='flex items-center flex-1 w-full text-left'>
      <MeetingProviderIcon option={option} />

      <Text h='400' className='flex-1'>
        {option.name}
      </Text>
    </div>
  );
};

