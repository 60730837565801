import React, { SVGProps } from 'react';

export const LinkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M4.91131 14C4.14472 14 3.42079 13.6962 2.86225 13.1377C2.27742 12.5529 1.97194 11.7866 2.00203 10.98C2.03313 10.1469 2.40946 9.34671 3.09037 8.66581L4.31633 7.43484C4.499 7.2514 4.79579 7.25079 4.97924 7.43348C5.16266 7.61616 5.16329 7.91297 4.98059 8.09639L3.75398 9.32806C2.75997 10.3221 2.66617 11.6158 3.5252 12.4748C4.38425 13.3339 5.67798 13.2401 6.67125 12.2467L8.3561 10.5619C8.86677 10.0512 9.1484 9.46812 9.1705 8.87567C9.19077 8.33262 8.98255 7.81416 8.58421 7.41581C8.45074 7.28236 8.30306 7.16911 8.14525 7.07923C7.9203 6.95109 7.84181 6.66485 7.96994 6.43992C8.0981 6.21497 8.38434 6.1365 8.60924 6.26461C8.83982 6.39595 9.05444 6.56025 9.24714 6.75293C9.83197 7.33777 10.1375 8.10405 10.1074 8.91066C10.0763 9.7437 9.69993 10.5439 9.01902 11.2248L7.33418 12.9097C6.65325 13.5906 5.85305 13.9669 5.02002 13.998C4.98374 13.9993 4.94743 14 4.91131 14ZM8.03008 9.56009C8.15821 9.33513 8.0797 9.04889 7.85477 8.92078C7.69697 8.8309 7.54926 8.71765 7.41581 8.58422C6.55678 7.72516 6.65058 6.43144 7.6439 5.43811L9.32875 3.75328C10.3221 2.75997 11.6158 2.66615 12.4748 3.52518C13.3338 4.38421 13.24 5.67794 12.246 6.67195L11.0194 7.90362C10.8367 8.08704 10.8373 8.38385 11.0208 8.56652C11.2042 8.74922 11.501 8.74856 11.6837 8.56516L12.9096 7.33418C13.5905 6.65327 13.9668 5.85304 13.998 5.02003C14.0281 4.21342 13.7226 3.44711 13.1377 2.86227C12.5529 2.27741 11.7862 1.97198 10.98 2.00202C10.147 2.03312 9.34675 2.40946 8.66587 3.09037L6.981 4.7752C6.3001 5.45611 5.92379 6.25633 5.89266 7.08935C5.86257 7.89595 6.16805 8.66227 6.75288 9.24713C6.94556 9.43978 7.16018 9.60406 7.39078 9.73542C7.464 9.77712 7.54369 9.79695 7.62234 9.79695C7.7853 9.79692 7.94365 9.7118 8.03008 9.56009Z'
      fill='currentColor'
    />
  </svg>
);
