import { DirectUpload } from '@rails/activestorage';

export function uploadToS3(file: File) {
  return new Promise<string>((resolve, reject) => {
    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads');
    upload.create((error, blob) => {
      if (error) {
        return reject(error);
      }
      resolve(blob.signed_id);
    });
  });
}
