import React, { forwardRef } from 'react';
import { StudiesSVG } from '@components/svgs';
import cn from 'classnames';
import { ItemProps } from 'components/shared/SelectDropdown/components/Item';
import { Avatar, Text } from '@components/common';

const STUDY_STYLES: Record<Study['style'] & 'focus_group', string> = {
  video_call: 'Interviews',
  survey: 'Survey',
  online_task: 'Online Task',
  unmoderated_test: 'Unmoderated Test',
  panel: 'Panel',
  repo_only: 'Recordings',
  focus_group: 'Focus Group'
};

interface Props extends ItemProps {
  studies?: Study[];
  canFund: boolean;
}

export const studyValid = (study: PartialStudy, canFund: boolean): boolean => {
  return (
    (study.state === 'active' && study.incentive_method === 'tremendous') || (study.state === 'pending' && canFund)
  );
  // TODO: check funds and pending canFund (MUST)
};

const studyIncentiveText = (study: PartialStudy): string => {
  if (study.incentive_method && ['manual', 'tremendous'].includes(study.incentive_method)) {
    return study.currencySymbol + study.incentive;
  } else {
    return study.incentive_method || 'none';
  }
};

export const StudyItem = forwardRef<HTMLLIElement, Props>(
  ({ canFund, studies, __dropdownItemProps: { item, active, highlighted }, ...rest }, ref) => {
    const study = studies?.find(({ id }) => id.toString() === item.value);

    return (
      <li
        ref={ref}
        {...rest}
        data-testid='create-item'
        aria-selected={active}
        role='option'
        className={cn('xx-combo-option flex flex-row items-center justify-between px-3 py-2 cursor-pointer', {
          'bg-indigo-600 text-white': highlighted
        })}
      >
        {(() => {
          if (item.created) {
            return (
              <div className='flex items-center space-x-2'>
                {/*// this should be changed to another study SVG with plus*/}
                <StudiesSVG className='flex-shrink-0' />
                <Text h='400' bold={active} truncate>
                  {`Create "${item.label}"`}
                </Text>
              </div>
            );
          }

          if (!study) return null;

          return (
            <div
              className={`flex flex-row items-center justify-between w-full space-x-4 ${
                studyValid(study, canFund) ? '' : 'opacity-50'
              }`}
            >
              {study.owner && <Avatar user={study.owner} />}
              <div className='flex flex-col flex-grow'>
                <div>{item.label}</div>
                <div className='mt-1 text-xs'>{STUDY_STYLES[study.style]}</div>
              </div>
              <span className='mt-1 text-sm font-bold'>{studyIncentiveText(study)}</span>
            </div>
          );
        })()}
      </li>
    );
  }
);
