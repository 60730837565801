import { useEffect } from 'react';

export const useLoadColumnOrderFromLocalStorage = (storageKey: string, setColumnOrder: (order: any) => void): void => {
  useEffect(() => {
    let savedColumnsOrder = [];

    try {
      savedColumnsOrder = JSON.parse(localStorage.getItem(storageKey) as any);
    } catch (error) {
      console.log('localStorage load  error: ', error);
    }

    const columnOrderExists = Array.isArray(savedColumnsOrder) && savedColumnsOrder.length;

    if (columnOrderExists) {
      setColumnOrder(savedColumnsOrder);
    }
  }, []);
};
