import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import * as Types from '../types';

interface UseCardSortTaskTrackingArgs {
  debounceDelay?: number;
  onEvent?: (events: Types.CardSortEvent[]) => void;
}

interface UseCardSortTaskTrackingReturn {
  events: Types.CardSortEvent[];
  onMoveCard: (card: string, from: string | null, to: string | null) => void;
  onCreateCategory: (category: string) => void;
  onEditCategory: (previous: string, category: string) => void;
  onDeleteCategory: (category: string) => void;
}

export const useCardSortTaskTracking = (args: UseCardSortTaskTrackingArgs = {}): UseCardSortTaskTrackingReturn => {
  const { debounceDelay = 500, onEvent } = args;

  const [events, setEvents] = useState<Types.CardSortEvent[]>([]);
  const cache = useRef<Types.CardSortEvent[]>([]);

  const send = () => {
    onEvent?.(cache.current);
    setEvents(cache.current);
  };

  const { callback: debouncedSend } = useDebouncedCallback(send, debounceDelay);

  const onMoveCard = (card: string, from: string | null, to: string | null) => {
    const event: Types.CardSortEvent<'move_card'> = {
      at: new Date(),
      card,
      from,
      to,
      event: 'move_card'
    };

    cache.current.push(event);

    debouncedSend();
  };

  const onCreateCategory = (category: string) => {
    const event: Types.CardSortEvent<'create_category'> = {
      at: new Date(),
      category,
      event: 'create_category'
    };

    cache.current.push(event);

    debouncedSend();
  };

  const onEditCategory = (category: string, previous: string) => {
    const event: Types.CardSortEvent<'edit_category'> = {
      at: new Date(),
      category,
      previous,
      event: 'edit_category'
    };

    cache.current.push(event);

    debouncedSend();
  };

  const onDeleteCategory = (category: string) => {
    const event: Types.CardSortEvent<'delete_category'> = {
      at: new Date(),
      category,
      event: 'delete_category'
    };

    cache.current.push(event);

    debouncedSend();
  };

  return { events, onMoveCard, onCreateCategory, onEditCategory, onDeleteCategory };
};
