import { humanize } from '@components/utils';
import { Toast } from '@stores/toaster';

export const MISSING_FIELD: Toast = {
  heading: 'Required fields incomplete',
  text: 'Please assign attributes or unselect columns.',
  icon: 'error'
};

export const UNIQUE_ID_REQUIRED = (id_attr: string): Toast => ({
  heading: `'${id_attr}' must be mapped to import`,
  text: `Please select a column for candidates’ ${id_attr} to continue`,
  icon: 'error'
});
export const MULTIPLE_UNIQUE_IDS: Toast = {
  heading: 'Multiple unique IDs selected',
  text: 'Cannot import email alongside another unique ID',
  icon: 'error'
};

export const FAILED_UPLOAD: Toast = {
  heading: 'Failed to upload CSV file!',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};

export const FAILED_RUN: Toast = {
  heading: 'Failed to import CSV file!',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};

export const SUCCESS = (text: string): Toast => ({
  heading: 'Importing!',
  text,
  icon: 'success'
});

export const SUCCESS_RUN: Toast = {
  heading: 'Candidates importing',
  text: 'We are importing candidates now. This make take a few moments.',
  icon: 'success'
};
