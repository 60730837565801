import { Toast } from '@stores/toaster';

export const updatedTitle: Toast = {
  heading: 'Changes saved',
  text: 'We’ve updated your highlight’s title',
  icon: 'success'
};
export const updatedDescription: Toast = {
  heading: 'Changes saved',
  text: 'We’ve updated your highlight’s description',
  icon: 'success'
};
export const failedUpdate: Toast = {
  heading: 'Failed to update highlight',
  text: 'Please try again in a few moments, or contact support.',
  icon: 'error'
};

export const failedDelete: Toast = {
  heading: 'Failed to delete highlight',
  text: 'Please try again in a few moments, or contact support.',
  icon: 'error'
};
export const failedGet: Toast = {
  heading: 'Failed to load highlight',
  text: 'Please try again in a few moments, or contact support.',
  icon: 'error'
};
