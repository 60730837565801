import { api } from 'api/reduxApi';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { Text } from '@components/common';
import Tippy from '@tippyjs/react';

const renderCount = (heading, count, href) => {
  switch (heading) {
    case 'Total Candidates':
    case 'Total Studies':
      return count ? <Link to={href}>{count}</Link> : <span className='text-gray-700'>-</span>;
    case 'Customer Hours':
      return <span>{count ? Math.round(count / 60) : '-'}</span>;
    default:
      return <span className=''>{count || '-'}</span>;
  }
};

const renderCell = ({ heading, count, href, thisMonth }) => {
  return (
    <div className='flex items-center'>
      {thisMonth > 0 && (
        <svg
          className='inline mr-1 flex-shrink-0 self-center'
          xmlns='http://www.w3.org/2000/svg'
          width='10'
          height='10'
          viewBox='0 0 10 10'
          fill='none'
        >
          <g clipPath='url(#clip0_1317_1445)'>
            <path
              d='M5 9.39464V0.605469'
              stroke='#057A55'
              strokeWidth='1.25'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.10177 4.70714L5.0001 0.605469L0.898438 4.70714'
              stroke='#057A55'
              strokeWidth='1.25'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_1317_1445'>
              <rect width='10' height='10' fill='white' />
            </clipPath>
          </defs>
        </svg>
      )}
      <Text h='400' bold className='mr-1'>
        <Tippy content={`${thisMonth} this month`}>{renderCount(heading, count, href)}</Tippy>{' '}
      </Text>
      <div className='flex flex-row items-center space-x-2'>
        <Text h='400' color='gray-500'>
          {heading}
        </Text>
      </div>
    </div>
  );
};

export const StatsTable = () => {
  const { data: stats, isLoading } = api.useGetDashboardStatsQuery();

  if (isLoading) {
    return <Skeleton className='h-14 w-full' />;
  }

  const { total_count, this_month, total_time, time_this_month } = stats.studies_data.table;
  const {
    total_count: candidatesTotal,
    this_month: candidatesThisMonth,
    total_opt_outs,
    month_opt_outs
  } = stats.candidates_data.table;
  const {
    completed_interview_count,
    this_month_completed_interview_count,
    completed_survey_count,
    this_month_completed_survey_count
  } = stats.participations_data.table;

  const cells = [
    {
      heading: 'Total Studies',
      count: total_count,
      href: '/studies',
      thisMonth: this_month
    },
    {
      href: '',
      heading: 'Interviews',
      count: completed_interview_count,
      thisMonth: this_month_completed_interview_count
    },
    {
      heading: 'Survey Responses',
      count: completed_survey_count,
      href: '/studies',
      thisMonth: this_month_completed_survey_count
    },
    { heading: 'Customer Hours', count: total_time, href: '', thisMonth: time_this_month },
    {
      heading: 'Total Candidates',
      count: candidatesTotal,
      href: '/candidates',
      thisMonth: candidatesThisMonth
    },
    {
      heading: 'Opt outs',
      count: total_opt_outs,
      href: '/studies',
      thisMonth: month_opt_outs
    }
  ];

  return (
    <div className='flex flex-1 justify-between px-page py-2 tablet:py-3 desktop:py-4 space-x-3 tablet:space-x-4 desctop:space-x-6 monitor:space-x-12 border-t border-gray-200 overflow-x-auto'>
      {cells.map((data) => (
        <div className='flex-shrink-0' key={data.heading}>
          {renderCell(data)}
        </div>
      ))}
    </div>
  );
};
