import * as React from 'react';
import { useRef, useState } from 'react';

import { PlayButtonSVG } from '@components/svgs';
import { renderComp } from '@components/utils';

const BACKGROUND_STYLE = { backgroundColor: 'rgba(18, 16, 47, 0.75)' };

interface Props {
  link: string;
  poster: string;
}

const MarketingVideo: React.FC<Props> = ({ link, poster }) => {
  const [playing, setPlaying] = useState(false);
  const ref = useRef<HTMLVideoElement>(null);

  const onPlayClick = () => {
    setPlaying(true);
    ref.current?.play();
  };

  const onPause = () => setPlaying(false);

  return (
    <div className='relative w-full h-full border border-indigo-600'>
      <video
        ref={ref}
        poster={poster}
        className='object-cover w-full h-full'
        onPause={onPause}
        controls={playing}
        src={link}
      />

      {!playing && (
        <>
          <div className='absolute inset-0' style={BACKGROUND_STYLE} />
          <div onClick={onPlayClick} className='absolute inset-0 flex items-center justify-center cursor-pointer'>
            <PlayButtonSVG />
          </div>
        </>
      )}
    </div>
  );
};

export function mountJillianVideo(): void {
  renderComp('jillian-video', () => (
    <MarketingVideo
      poster='/temp-assets/jillian_video_poster.png'
      link='https://stream.mux.com/2zbHnGr8h01gLe0201BlzTMJAlXbakaIYHaR4b3imoONfY/low.mp4'
    />
  ));
}

export function mountDaniVideo(): void {
  renderComp('dani-video', () => (
    <MarketingVideo
      poster='/temp-assets/dani_video_poster.png'
      link='https://stream.mux.com/O4SK1YqeFN8oJprCYdJn79TTDvOyzR9NTUgjT5bBYY4/low.mp4'
    />
  ));
}
