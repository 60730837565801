import utf8 from 'crypto-js/enc-utf8';
import Rabbit from 'crypto-js/rabbit';

export const encrypt = (blob: unknown, password: string): Promise<string> =>
  new Promise((resolve, reject) => {
    try {
      const encrypted = Rabbit.encrypt(JSON.stringify(blob), password).toString();
      return resolve(encrypted);
    } catch (e) {
      return reject(e);
    }
  });

export const decrypt = <T>(blob: string, password: string): Promise<T> =>
  new Promise((resolve, reject) => {
    try {
      const decrypted = Rabbit.decrypt(blob, password);
      return resolve(JSON.parse(decrypted.toString(utf8)) as T);
    } catch (e) {
      return reject(e);
    }
  });
