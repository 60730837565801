import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Text, Toggle, TippyOrNot, TeamIcon } from '@components/common';
import { moneyFormat } from '@components/utils';
import { useToaster } from '@stores/toaster';
import { useAccount } from '@hooks/useAccount';
import { useTeams } from '@hooks/useTeams';

import * as toasts from '../toasts';
import { MoveFundsSlideout } from '../MoveFundsSlideout';

const WalletMetric: React.FC<{ label: string; value: number; tippyContent: string }> = ({
  label,
  value,
  tippyContent
}) => {
  return (
    <div className='flex w-60 px-6 align-middle items-center'>
      <Text h='500' className='mr-2'>
        ${moneyFormat(value / 100)}
      </Text>
      <TippyOrNot content={tippyContent} show={!!tippyContent}>
        <Text h='200' color='gray-500'>
          {label}
        </Text>
      </TippyOrNot>
    </div>
  );
};

type WalletRowProps = {
  available: number;
  allocated: number;
  spent: number;
  teamWalletsEnabled: boolean;
  onClick: () => void;
  showTippy?: boolean;
};
const WalletsRow: React.FC<WalletRowProps> = ({
  available,
  allocated,
  spent,
  teamWalletsEnabled,
  onClick,
  showTippy = false
}) => {
  return (
    <div className='pb-2 flex divide-x divide-gray-200'>
      <WalletMetric
        label='Available'
        value={available}
        tippyContent={showTippy ? 'The amount that is available for use' : ''}
      />
      <WalletMetric
        label='Allocated'
        value={allocated}
        tippyContent={showTippy ? 'The amount that is allocated to studies and teams' : ''}
      />
      <WalletMetric
        label='Spent'
        value={spent}
        tippyContent={showTippy ? 'The amount that was spent in incentives' : ''}
      />
      <div className='flex w-60 pl-6'>
        <Button icon='transferVertical' medium disabled={!teamWalletsEnabled} onClick={onClick}>
          Move funds
        </Button>
      </div>
    </div>
  );
};

type TeamWalletsTableProps = {
  teams: Team[];
  teamWalletsEnabled: boolean;
  openSlideout: (to?: string) => void;
};
const TeamWalletsTable: React.FC<TeamWalletsTableProps> = ({ teams, teamWalletsEnabled, openSlideout }) => {
  return (
    <>
      {teams.map((team) => (
        <div className='mt-6 pt-6 border-t border-gray-200'>
          <div className='flex items-center space-x-2 mb-2'>
            <TeamIcon team={team} />
            <Text h='500' bold>
              {team.name}
            </Text>
          </div>
          <div className='flex flex-col space-y-2'>
            <WalletsRow
              available={team.wallet_available || 0}
              allocated={team.wallet_allocated || 0}
              spent={team.wallet_spent || 0}
              teamWalletsEnabled={teamWalletsEnabled}
              onClick={() => openSlideout(team.id.toString())}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export const WalletsIndex: React.FC = () => {
  const [slideoutOpen, setSlideoutOpen] = useState(false);

  const {
    account,
    update,
    refresh,
    account: { wallet }
  } = useAccount();
  const { teams = [], isError } = useTeams({ includeWallet: true });

  const [teamWalletsEnabled, setTeamWalletsEnabled] = useState(account.team_wallets_enabled);
  const [hasTeamBalances, setHasTeamBalances] = useState(false);

  const [initialTo, setInitialTo] = useState('');

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGetTeams());
    }
  }, [isError]);

  useEffect(() => {
    setHasTeamBalances(!!teams.find((t) => (t.wallet_available || 0) > 0));
  }, [teams]);

  useEffect(() => {
    setTeamWalletsEnabled(account.team_wallets_enabled);
  }, [account]);

  async function handleTeamWalletsEnabled(v: boolean) {
    setTeamWalletsEnabled(v);
    await update({ team_wallets_enabled: v });
    refresh();
  }

  const openSlideout = (to?: string) => {
    setInitialTo(to || '');
    setSlideoutOpen(true);
  };

  const onClose = () => {
    setSlideoutOpen(false);
  };

  const onSuccess = () => {
    refresh();
  };

  return (
    <>
      <div className='flex items-center justify-between mb-4 monitor:mb-6'>
        <Text h='700' as='h1' bold>
          Wallets
        </Text>

        <div className='space-x-3'>
          {teamWalletsEnabled && (
            <Button medium icon='transferVertical' onClick={() => openSlideout()}>
              Move funds
            </Button>
          )}
          <Button medium icon='accountingDocument' onClick={() => (window.location.href = '/incentives/invoice')}>
            Create invoice
          </Button>
          <Button medium icon='cashPayment' primary onClick={() => (window.location.href = '/incentives/deposit')}>
            Deposit funds
          </Button>
        </div>
      </div>

      <div className='p-6 mb-12 bg-white border border-gray-200 rounded-md'>
        <Text h='500' bold mb='2'>
          General
        </Text>
        <WalletsRow
          available={wallet.available}
          allocated={wallet.allocated}
          spent={wallet.spent}
          teamWalletsEnabled={teamWalletsEnabled}
          onClick={() => openSlideout()}
          showTippy
        />
      </div>

      <div className='p-6 bg-white border border-gray-200 rounded-md'>
        <div className='flex mb-6'>
          <div className='flex-1'>
            <Text h='500' bold>
              Team wallets
            </Text>
          </div>
          <TippyOrNot content='All teams wallets must be empty before disabling' show={hasTeamBalances}>
            <Toggle
              on={teamWalletsEnabled}
              disabled={hasTeamBalances}
              onToggle={handleTeamWalletsEnabled}
              testId='team-wallets-enabled-toggle'
            />
          </TippyOrNot>
        </div>
        {teamWalletsEnabled ? (
          teams.length ? (
            <TeamWalletsTable teams={teams} teamWalletsEnabled={teamWalletsEnabled} openSlideout={openSlideout} />
          ) : (
            <Text h='400' color='gray-500'>
              Once you've added one or more teams, you'll be able make funds available to them here.
            </Text>
          )
        ) : (
          <Text h='400' color='gray-500'>
            When enabled, studies created with a Team will be required to use the Team Wallet for incentives.
          </Text>
        )}
      </div>
      {slideoutOpen && (
        <MoveFundsSlideout
          account={account}
          teams={teams}
          initialFrom='account'
          initialTo={initialTo}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};
