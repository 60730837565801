import React, { SVGProps } from 'react';

export const ChecklistSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.7414 13.6306L13.75 20.6248L10.3125 21.3123L11 17.8748L17.9951 10.8806C18.175 10.7004 18.3887 10.5574 18.6239 10.4598C18.8591 10.3622 19.1113 10.312 19.366 10.312C19.6206 10.312 19.8728 10.3622 20.108 10.4598C20.3432 10.5574 20.5569 10.7004 20.7368 10.8806L20.7451 10.8879C21.1082 11.2522 21.3118 11.7457 21.3111 12.26C21.3105 12.7743 21.1055 13.2673 20.7414 13.6306Z'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.55883 18.5623H2.05883C1.69416 18.5623 1.34442 18.4174 1.08656 18.1595C0.8287 17.9017 0.683834 17.5519 0.683834 17.1873V2.06226C0.683834 1.69758 0.8287 1.34785 1.08656 1.08998C1.34442 0.832122 1.69416 0.687256 2.05883 0.687256H15.8088C16.1735 0.687256 16.5232 0.832122 16.7811 1.08998C17.039 1.34785 17.1838 1.69758 17.1838 2.06226V8.24976'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.55883 4.81226H13.0588'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.80883 8.93726H13.0588'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.80883 13.0623H11.6838'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
