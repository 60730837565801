import React, { useState } from 'react';
import SanitizedHTML from 'react-sanitized-html';

import { Dropdown, DropdownLink, Text } from '@components/common';

import { BusySVG, InfoSVG } from '../eventPopupSvgs';

interface Props {
  disabled?: boolean;
  description?: string;
  busy?: boolean;
  onChangeBusy?: (busy: boolean) => void;
}

export const DescAndStatus: React.FC<Props> = ({ disabled, description, busy: initialBusy, onChangeBusy }) => {
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(initialBusy);

  const onChange = (v: boolean) => {
    setBusy(v);
    onChangeBusy?.(v);
    setOpen(false);
  };

  return (
    <>
      {description && (
        <div className='flex mb-6'>
          <div className='flex items-center h-6 mr-4'>
            <InfoSVG />
          </div>

          <SanitizedHTML className='max-h-40 overflow-y-auto whitespace-pre-line' html={description} />
        </div>
      )}

      <div className='flex'>
        <div className='flex items-center h-6 mr-4'>
          <BusySVG />
        </div>

        {!onChangeBusy && <Text>{busy ? 'Busy' : 'Free'}</Text>}

        {onChangeBusy && (
          <Dropdown
            disabled={disabled}
            isOpen={open}
            onClick={() => setOpen(!open)}
            onClose={() => setOpen(false)}
            position='left'
            dropdownWidth='24'
            buttonClassName='w-24'
            className='bg-white'
            text={busy ? 'Busy' : 'Free'}
            aboveButton
          >
            <DropdownLink onClick={() => onChange(false)}>Free</DropdownLink>
            <DropdownLink onClick={() => onChange(true)}>Busy</DropdownLink>
          </Dropdown>
        )}
      </div>
    </>
  );
};
