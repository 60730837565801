import React, { SVGProps } from 'react';

export const EmailSearchSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M6.17969 9.04969C6.17969 9.42658 6.25392 9.79978 6.39815 10.148C6.54238 10.4962 6.75379 10.8126 7.02029 11.0791C7.2868 11.3456 7.60318 11.557 7.95139 11.7012C8.29959 11.8455 8.67279 11.9197 9.04969 11.9197C9.42658 11.9197 9.79978 11.8455 10.148 11.7012C10.4962 11.557 10.8126 11.3456 11.0791 11.0791C11.3456 10.8126 11.557 10.4962 11.7012 10.148C11.8455 9.79978 11.9197 9.42658 11.9197 9.04969C11.9197 8.67279 11.8455 8.29959 11.7012 7.95139C11.557 7.60318 11.3456 7.2868 11.0791 7.02029C10.8126 6.75379 10.4962 6.54238 10.148 6.39815C9.79978 6.25392 9.42658 6.17969 9.04969 6.17969C8.67279 6.17969 8.29959 6.25392 7.95139 6.39815C7.60318 6.54238 7.2868 6.75379 7.02029 7.02029C6.75379 7.2868 6.54238 7.60318 6.39815 7.95139C6.25392 8.29959 6.17969 8.67279 6.17969 9.04969Z'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.1509 13.1499L11.0801 11.0791'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.76961 12.3304H1.66961C1.45213 12.3304 1.24356 12.244 1.08978 12.0902C0.936002 11.9364 0.849609 11.7278 0.849609 11.5104V5.36035L4.94961 8.61193'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3296 5.3602L7.78845 1.36953C7.63501 1.20516 7.4494 1.0741 7.24317 0.984485C7.03693 0.894875 6.81447 0.848633 6.58961 0.848633C6.36475 0.848633 6.14229 0.894875 5.93605 0.984485C5.72982 1.0741 5.54421 1.20516 5.39077 1.36953L0.849609 5.3602'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.50868 8.26172L2.90039 9.87001'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
