import cn from 'classnames';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import { Portal } from '@components/shared/Portal';
import { useOnClickOutside, useOnEscape } from '@components/utils';

import { useSlideOutContext } from '../SlideOut';
import { Text } from '../Text';
import { NO_OUTLINE } from '@components/CandidateAttrs';
import { useTrapFocus } from '@hooks/useTrapFocus';

const ICONS = {
  danger: () => (
    <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 7V9M10 13H10.01M3.07183 17H16.9282C18.4678 17 19.4301 15.3333 18.6603 14L11.7321 2C10.9623 0.666667 9.03778 0.666667 8.26798 2L1.33978 14C0.56998 15.3333 1.53223 17 3.07183 17Z'
        stroke='#DC2626'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
};

export const CloseIcon = ({ className = '' }) => (
  <svg
    className={`stroke-current ${className}`}
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M1.4375 14.5619L14.5625 1.43689' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14.5625 14.5619L1.4375 1.43689' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

type SIZE = 'sm' | 'md' | 'lg' | 'xl';
interface ModalProps {
  className?: string;
  title?: string;
  subtitle?: string;
  hideClose?: boolean;
  noPadding?: boolean;
  size?: SIZE;
  onClose?: (e?: any) => void;
  renderFooter?: () => React.ReactNode;
  icon?: keyof typeof ICONS;
  scrollOverflow?: boolean;
  fullScreen?: boolean;
  footerHeight?: string;
  closeOnEsc?: boolean;
  disableAutofocus?: boolean;
}

const SIZES: { [key in SIZE]: [number, number | null] } = {
  sm: [400, null],
  md: [600, null],
  lg: [800, null],
  xl: [1000, 800]
};

interface BasicElProps {
  className?: string;
  disableAutofocus?: boolean;
}

const ModalHeading: React.FC<BasicElProps> = ({ disableAutofocus, className = '', children }) => {
  const ref = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    // we should disable the autofocus on modal only if we want some specific element to be focused on mount
    if (!disableAutofocus) {
      ref.current?.focus();
    }
  }, [disableAutofocus]);

  return (
    <h3
      aria-level={2}
      ref={ref}
      tabIndex={-1}
      className={cn('text-gray-700 text-2xl font-bold', className)}
      style={NO_OUTLINE}
    >
      {children}
    </h3>
  );
};
const ModalSubheading: React.FC<BasicElProps> = ({ className = '', children }) => {
  return (
    <Text color='gray-500' className={className}>
      {children}
    </Text>
  );
};
const ModalFixedHeading: React.FC<BasicElProps> = ({ children, disableAutofocus }) => {
  return (
    <div className='absolute inset-x-0 top-0 p-6 pb-4 bg-white border-b border-gray-200 rounded-t-lg'>
      <ModalHeading disableAutofocus={disableAutofocus}>{children}</ModalHeading>
    </div>
  );
};

const ModalFooter: React.FC = ({ children }) => <div className={`pt-6 text-right space-x-4`}>{children}</div>;

// TODO: this would be nicer as a flex box i reckon
const ModalFixedFooter: React.FC = ({ children }) => (
  <div
    className={`p-6 absolute bottom-0 right-0 w-full text-right border-t border-gray-200 bg-white rounded-b-lg z-20`}
  >
    {children}
  </div>
);

const ModalBackdrop: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children }) => (
  <div
    className={`xx-modal animated fadeIn fixed z-50 inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50`}
  >
    {children}
  </div>
);

const Modal: React.FC<ModalProps> = ({
  className = '',
  children,
  size = 'sm',
  noPadding = false,
  title,
  subtitle,
  renderFooter,
  icon,
  onClose,
  hideClose = false,
  scrollOverflow = true,
  fullScreen,
  footerHeight = '24',
  closeOnEsc = true,
  disableAutofocus
}) => {
  const outerStyle = fullScreen ? undefined : { maxWidth: SIZES[size][0], maxHeight: SIZES[size][1] };
  const scrollable = scrollOverflow && !!SIZES[size][1];
  const sizeClass = scrollable || fullScreen ? 'w-full h-full' : 'w-full';

  const ref = useRef<HTMLDivElement>(null);
  const { ref: trapRef } = useTrapFocus();

  const { setChildModalOpen } = useSlideOutContext();

  useEffect(() => {
    setChildModalOpen?.(true);
    return () => setChildModalOpen?.(false);
  }, []);

  useOnEscape(
    (e) => {
      if (closeOnEsc) {
        onClose?.(e);
      }
    },
    [onClose]
  );

  useOnClickOutside(ref, (e) => {
    if (!fullScreen) {
      onClose?.(e);
    }
  });

  const wrapperClass = cn('relative bg-white', size ? sizeClass : 'm-1', { 'rounded-lg': !fullScreen }, className);

  return (
    <Portal>
      <ModalBackdrop>
        <div
          ref={trapRef}
          data-testid='modal'
          className={`${sizeClass} max-h-screen w-full ${scrollable ? 'h-full' : ''} relative`}
          style={outerStyle}
        >
          {!hideClose && (
            <div onClick={onClose} className='h-7 absolute top-0 right-0 z-40 flex items-center mt-6 mr-6'>
              <button aria-label='Close panel' className='hover:text-indigo-500 text-gray-700'>
                <CloseIcon className='w-4 h-4' />
              </button>
            </div>
          )}
          <div className={wrapperClass} ref={ref}>
            {scrollable && title && <ModalFixedHeading disableAutofocus={disableAutofocus}>{title}</ModalFixedHeading>}
            <div className={cn('flex', 'h-full', { 'overflow-y-auto': scrollable, 'p-6': !noPadding })}>
              {icon && (
                <div className='pr-4'>
                  <div className='flex items-center justify-center w-10 h-10 bg-red-100 rounded-full'>
                    <div className='text-red-600'>{ICONS[icon]()}</div>
                  </div>
                </div>
              )}
              <div className={`flex flex-col flex-1 w-full`}>
                {title && scrollable && <div className='pt-16 mt-2'></div>}
                {title && !scrollable && (
                  <ModalHeading disableAutofocus={disableAutofocus} className={subtitle ? 'pb-1' : 'pb-4'}>
                    {title}
                  </ModalHeading>
                )}
                {subtitle && !scrollable && <ModalSubheading className='pb-4'>{subtitle}</ModalSubheading>}
                {children}
                {renderFooter && scrollable && <div className={`pt-${footerHeight}`}></div>}
                {renderFooter && !scrollable && <ModalFooter>{renderFooter()}</ModalFooter>}
              </div>
            </div>
            {renderFooter && scrollable && <ModalFixedFooter>{renderFooter()}</ModalFixedFooter>}
          </div>
        </div>
      </ModalBackdrop>
    </Portal>
  );
};
export { Modal, ModalHeading, ModalSubheading, ModalBackdrop };
