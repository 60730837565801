import cn from 'classnames';
import React, { useState } from 'react';

import { Button } from '@components/common';
import Tippy from '@tippyjs/react';

import { DeleteTemplateModal } from './DeleteTemplateModal';

interface Props {
  template: AbstractTemplate;
  onSubmit?: () => void;
  buttonClassName?: string;
  kind: TemplateKind;
}

export const DeleteTemplateButton: React.FC<Props> = ({ template, onSubmit, buttonClassName, kind }) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const modalOpen = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const modalClose = (e?: React.MouseEvent) => {
    e?.preventDefault();
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Tippy className='bg-gray-500 rounded-lg' content='Delete template' arrow={false}>
        <Button
          small
          text
          className={cn('xx-delete-template', buttonClassName)}
          rounded
          onClick={modalOpen}
          icon='trash'
        />
      </Tippy>
      <DeleteTemplateModal
        id={template.id}
        title={template.title}
        open={deleteModalOpen}
        onSubmit={onSubmit}
        onClose={modalClose}
        kind={kind}
      />
    </>
  );
};
