export const STUDY_MUTATIONS = [
  'updateStudy',
  'updateStudyLandingPage',
  'updateStudyUser',
  'createStudyUser',
  'deleteStudyUser',
  'updateStudyCalendar',
  'updateStudyLocation',
  'updateRepoSession',
  'createRepoSession',
  'destroyRepoSession',
  'updateStudySettings',
  'updateDocument',
  'createExternalCandidatesRequest',
  'updateExternalCandidatesRequest',
  'updateStudyScreener',
  'updateStudyScreenerFieldImage'
];

type StyleKey = 'all' | Study['style'];

export const STYLES: Partial<Record<StyleKey & 'focus_groups', string>> = {
  video_call: 'Customer Interviews',
  survey: 'Surveys',
  online_task: 'Online Tasks',
  unmoderated_test: 'Unmoderated Tests',
  panel: 'Panel',
  focus_groups: 'Focus Groups'
};

export const PARTICIPATION_STATUSES = [
  'fresh',
  'invited',
  'applied',
  'shortlisted',
  'approved',
  'requested',
  'booked',
  'completed',
  'rejected',
  'started',
  'no_show',
  'opted_out',
  'canceled'
];
