export type Response = NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>;

export interface IPath {
  path: string[];
  pathLength: number;
  count: number;
  countAsPercentage: number;
  totalTime: number;
  avgTime: number;
  totalMissClicks: number;
  avgMissClickRate: number;
  totalClicksToGoal: number;
  avgClicksToGoal: number;
  responses: Response[];
  shortestPath: boolean;
  successful: boolean;
}

type Map = Record<string, IPath>;

export class PrototypePathsProcessor {
  private map: Map = {};
  private shortestPaths: string[][] = [];
  private validCount: number = 0;

  private get mapped(): IPath[] {
    return this.sort(Object.values(this.map));
  }

  public responses: Response[] = [];
  public avgTimeChartRange: [number, number] = [0, 0];

  public get paths(): IPath[] {
    return this.mapped;
  }

  public async call(): Promise<IPath[]> {
    this.map = await new Promise((resolve, reject) =>
      // yield processing to task queue so we don't block rendering
      setTimeout(() => {
        try {
          resolve(this.process());
        } catch (error) {
          this.reset();
          reject(error);
        }
      }, 0)
    );

    return this.mapped;
  }

  private reset() {
    this.map = {};
    this.shortestPaths = [];
    this.validCount = 0;
  }

  private process(): Map {
    this.reset();

    let shortestPathLength = 0;

    for (const response of this.responses) {
      const { response_path, clicks_to_goal, completed, time_in_task, miss_clicks } = response;
      const pathLength = response_path.length;

      if (pathLength === 0) {
        continue;
      }

      this.validCount++;

      if ((completed && pathLength <= shortestPathLength) || shortestPathLength === 0) {
        shortestPathLength = pathLength;
      }

      const key = this.uniqKey(response_path);
      this.map[key] = this.buildPath(key, { path: response_path, pathLength });

      this.map[key].count++;
      this.map[key].totalTime += time_in_task ?? 0;
      this.map[key].totalClicksToGoal += clicks_to_goal ?? 0;
      this.map[key].totalMissClicks += miss_clicks ?? 0;
      this.map[key].responses.push(response);
    }

    let maxAvgTime = 0;
    const avgTimes: number[] = [];

    Object.entries(this.map).forEach(([key, path]) => {
      const avgTime = path.totalTime / path.count;

      avgTimes.push(avgTime);

      if (avgTime > maxAvgTime) {
        maxAvgTime = avgTime;
      }

      this.map[key].avgTime = avgTime;
      this.map[key].avgClicksToGoal = path.totalClicksToGoal / path.count;
      this.map[key].countAsPercentage = path.count / this.validCount;
      this.map[key].avgMissClickRate = path.totalMissClicks / path.responses.length;
      this.map[key].successful = path.responses.every((response) => response.completed);
      this.map[key].shortestPath = path.pathLength === shortestPathLength;
    });

    // Determine range of avgTime chart in seconds [0, 100%]
    // Use max of (median * 3) to prevent large outliers from skewing the chart
    this.avgTimeChartRange = [0, Math.min(this.getMedianAvgTime(avgTimes) * 3, maxAvgTime)];

    return this.map;
  }

  private sort(paths: IPath[]) {
    return paths.sort((a, b) => {
      if (a.successful !== b.successful) {
        return a.successful ? -1 : 1;
      }

      if (a.pathLength === b.pathLength) {
        return b.count - a.count;
      }

      return a.pathLength - b.pathLength;
    });
  }

  private getMedianAvgTime(avgTimes: number[]): number {
    const sortedAvgTimes = avgTimes.sort();
    const medianIndex = Math.floor(sortedAvgTimes.length / 2);

    return sortedAvgTimes.length % 2 === 0
      ? (sortedAvgTimes[medianIndex] + sortedAvgTimes[medianIndex - 1]) / 2
      : sortedAvgTimes[medianIndex];
  }

  private buildPath(key: string, initial: Pick<IPath, 'path' | 'pathLength'>): IPath {
    if (this.map[key]) {
      return this.map[key];
    }

    return {
      ...initial,
      count: 0,
      countAsPercentage: 0,
      totalTime: 0,
      avgTime: 0,
      totalMissClicks: 0,
      avgMissClickRate: 0,
      totalClicksToGoal: 0,
      avgClicksToGoal: 0,
      responses: [],
      shortestPath: false,
      successful: false
    };
  }

  private uniqKey(path: string[]): string {
    return path.join('_');
  }
}
