import algoliasearch from 'algoliasearch';
import { algoliaAdapter, AlgoliaConfig, AlgoliaData, Search, useSearch } from 'components/shared/Search';
import pluralize from 'pluralize';
import React from 'react';
import { useContext, useMemo, useState } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { buildRepoFilterDefs } from '@components/RepositoryApp/filters/buildRepoFilterDefs';
import { ArtifactCard } from '@components/RepositoryApp/hits/artifacts/ArtifactCard';

import { useAlgoliaFilters } from '@components/RepositoryApp/hooks/useAlgoliaFilters';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { SearchInput } from '@components/shared/SearchInput';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { compact, uniq } from '@components/utils';
import configStore from '@stores/config';

import { useTiptapContext } from '../hooks';

type HitResultsProps = {
  selected: ArtifactHit[];
  setSelected: (hits: ArtifactHit[]) => void;
  initialSelectedHits?: ArtifactHit['objectID'][];
};

type Props = {
  isOpen: boolean;
  selectedHits?: ArtifactHit['objectID'][];
  onClose: () => void;
  onSelect?: (hits: ArtifactHit[]) => void;
};

const Hits = ({ selected, setSelected, initialSelectedHits }: HitResultsProps) => {
  const { data } = useSearch<AlgoliaData>();
  const { hits = [] } = data ?? {};
  const selectedHitIds = selected.map(({ objectID }) => objectID).concat(initialSelectedHits ?? []);

  return (
    <div className={'grid grid-cols-3 gap-4'}>
      {hits.map((hit) => {
        return (
          <ArtifactCard
            key={hit.objectID}
            {...(hit as ArtifactHit)}
            isLink={false}
            page='embedded_artifacts_modal'
            canSelect
            selectedArtifacts={uniq(selectedHitIds)}
            setSelectedArtifacts={(artifactIds) => {
              setSelected(compact(artifactIds.map((id) => hits.find((h) => h.objectID === id))) as ArtifactHit[]);
            }}
          />
        );
      })}
    </div>
  );
};

export const EmbedArtifactModal = ({ isOpen, selectedHits: initialSelectedHits, onClose, onSelect }: Props) => {
  const {
    state: {
      config: {
        algolia: { index_suffix, app_id, api_key }
      }
    }
  } = useContext<ConfigStore>(configStore);
  const { studyId } = useTiptapContext();
  const searchClient = useMemo(() => algoliasearch(app_id, api_key), []);
  const addSuffix = (indexName: string) => `${indexName}${index_suffix}`;

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedHits, setSelectedHits] = useState<ArtifactHit[]>([]);

  const definitions = useMemo(() => buildRepoFilterDefs(studyId), []);

  const filtersHook = useTableFilters<ArtifactHit>({
    definitions,
    defaultFilters: [],
    trackKey: 'embed_artifact_modal'
  });
  const algoliaFilters = useAlgoliaFilters(filtersHook);

  const algoliaConfig = useMemo<AlgoliaConfig>(
    () => ({ index: addSuffix('Artifact'), client: searchClient, hitsPerPage: 100 }),
    [searchClient]
  );

  const reset = () => {
    setSearchValue('');
    setSelectedHits([]);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      footerHeight='20'
      noPadding
      size='xl'
      onClose={reset}
      scrollOverflow
      renderFooter={() => (
        <div>
          <div className='flex items-center justify-end'>
            <Text className='flex-grow text-left text-gray-400'>
              {pluralize('artifact', selectedHits.length, true)} selected
            </Text>
            <div className='space-x-2'>
              <Button text onClick={onClose}>
                Cancel
              </Button>
              <Button
                primary
                disabled={selectedHits.length < 1}
                onClick={() => {
                  onSelect?.(selectedHits);
                  reset();
                }}
              >
                Embed
              </Button>
            </div>
          </div>
        </div>
      )}
    >
      <Search<AlgoliaData, AlgoliaConfig>
        query={searchValue}
        filters={algoliaFilters}
        adapter={algoliaAdapter}
        config={algoliaConfig}
        infiniteResults
      >
        <div className='p-6'>
          <ModalHeading>Embed {pluralize('artifact')}</ModalHeading>

          <div className='pt-6'>
            <SearchInput
              placeholder={`Search ${pluralize('artifact')}…`}
              onSearch={setSearchValue}
              value={searchValue}
            />
          </div>
          <div className='flex items-center -mb-4'>
            <div className='z-50 flex-1'>
              <TableFilters<ArtifactHit> hook={filtersHook} />
            </div>
            <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
              <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
              <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
            </div>
          </div>
        </div>

        <div className='p-6 overflow-y-scroll border-t border-b border-gray-200'>
          <Hits selected={selectedHits} setSelected={setSelectedHits} initialSelectedHits={initialSelectedHits} />
        </div>
      </Search>
    </Modal>
  );
};
