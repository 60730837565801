import React, { FC } from 'react';

import { AlgoliaData, useSearch } from '@components/shared/Search';

import { ArtifactHit } from '../types';

interface Props {
  hit: ArtifactHit;
  attribute: string;
}

export const HighlightedText: FC<Props> = ({ hit, attribute }) => {
  const wrapperRef = React.useRef<HTMLSpanElement | null>(null);
  const wordRef = React.useRef<HTMLSpanElement | null>(null);
  const { data } = useSearch<AlgoliaData>();

  const parsedHit = data && data.getHighlightedParts(hit);

  const highlightedIndex = parsedHit?.findIndex(({ isHighlighted }) => isHighlighted);

  const clippedHit = highlightedIndex ? parsedHit?.slice(highlightedIndex - 1, highlightedIndex + 2) : [];

  const renderValue = (value, index) => {
    if (highlightedIndex && index < highlightedIndex) {
      return value.length > 75 ? `...${value.slice(-75)}` : value;
    } else if (highlightedIndex && index > highlightedIndex) {
      return value.length > 75 ? `${value.slice(-75)}...` : value;
    } else {
      return value;
    }
  };

  return (
    <span ref={wrapperRef} className='line-clamp-6'>
      “
      {parsedHit &&
        clippedHit?.map((part, index) =>
          part.isHighlighted ? (
            <span
              ref={(e) => {
                if (!wordRef.current) {
                  wordRef.current = e;
                }
              }}
              className='font-bold bg-indigo-100'
              key={index}
            >
              {part.value}
            </span>
          ) : (
            <span key={index}>{renderValue(part.value, index)}</span>
          )
        )}
      ”
    </span>
  );
};
