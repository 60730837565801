import { useAccount } from '@hooks/useAccount';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import tinytime from 'tinytime';

import { AvatarFromId, Button, Text } from '@components/common';
import { UserAvatars } from '@components/shared/UserAvatars';
import { compact } from '@components/utils';
import { usePopUp } from '@hooks/usePopUp';
import { sortViews } from '@components/shared/Views/utils';
import cn from 'classnames';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

interface Props {
  views: ViewCount[];
  onOpen?: () => void;
  wrapperClass?: string;
  aboveButton?: boolean;
  maxCount?: number;
}

const GuestSVG = () => (
  <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg' className='w-8 h-8'>
    <path
      d='M10.6875 15.4375C10.6875 17.6421 11.5633 19.7564 13.1222 21.3153C14.6811 22.8742 16.7954 23.75 19 23.75C21.2046 23.75 23.3189 22.8742 24.8778 21.3153C26.4367 19.7564 27.3125 17.6421 27.3125 15.4375C27.3125 13.2329 26.4367 11.1186 24.8778 9.55967C23.3189 8.00078 21.2046 7.125 19 7.125C16.7954 7.125 14.6811 8.00078 13.1222 9.55967C11.5633 11.1186 10.6875 13.2329 10.6875 15.4375V15.4375Z'
      stroke='#9FA6B2'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M29.9456 33.0537C28.5115 31.6105 26.8061 30.4652 24.9276 29.6837C23.0491 28.9021 21.0346 28.4998 19 28.4998C16.9654 28.4998 14.951 28.9021 13.0725 29.6837C11.194 30.4652 9.4886 31.6105 8.05444 33.0537'
      stroke='#9FA6B2'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const UserView: React.FC<{ view: ViewCount }> = ({ view }) => {
  const {
    account: { team }
  } = useAccount();

  const user = team.find((u) => u.id === view.user_id);

  return (
    <div className='flex flex-row items-center space-x-4'>
      <AvatarFromId size='lg' userId={view.user_id} />
      <div>
        {user && <Text>{user.name}</Text>}
        <Text h='400' color='gray-500'>
          Last viewed at {dateTemplate.render(new Date(view.last_viewed_at))}
        </Text>
      </div>
    </div>
  );
};
const GuestView: React.FC<{ view: ViewCount }> = ({ view }) => {
  return (
    <div className='flex flex-row items-center space-x-4'>
      <GuestSVG />
      <div>
        <Text>Someone</Text>
        <Text h='400' color='gray-500'>
          Last viewed at {dateTemplate.render(new Date(view.last_viewed_at))}
        </Text>
      </div>
    </div>
  );
};
export const Views: React.FC<Props> = ({ aboveButton, maxCount = 3, views, onOpen, wrapperClass }) => {
  const { ref, togglePopUp, PopUp, open } = usePopUp();

  const total = views.map((view) => view.count).reduce((a, b) => a + b, 0);
  const viewedUserIds = compact(views.map(({ user_id }) => user_id));

  const sortedViews = sortViews(views);

  useEffect(() => {
    onOpen?.();
  }, []);

  return (
    <div className={cn('relative flex flex-shrink-0', wrapperClass)} ref={ref}>
      <Button data-testid='views-button' onClick={togglePopUp} className='h-10 whitespace-nowrap pt-0 pb-0'>
        {viewedUserIds.length > 0 && (
          <UserAvatars className='mr-2' user_ids={viewedUserIds.slice(0, maxCount)} noTippy />
        )}
        {pluralize('Views', views.length, true)}
      </Button>
      <PopUp
        zIndex={50}
        open={open}
        className={cn(
          'w-80 right-0 mt-4 bg-white border border-gray-200 shadow-lg',
          aboveButton ? 'bottom-12' : 'top-8'
        )}
      >
        <div className='p-6 border-b border-gray-200'>
          <Text h='600' mb='1' bold>
            Engagement insights
          </Text>
          <Text h='400' color='gray-500'>
            {pluralize('total views', total, true)}, {pluralize('unique views', views.length, true)}
          </Text>
        </div>
        <div className='p-6'>
          <ul className='flex flex-col space-y-5 max-h-96 overflow-y-auto'>
            {sortedViews.map((view, i) => (
              <li key={`view_${i}`}>{view.user_id ? <UserView view={view} /> : <GuestView view={view} />}</li>
            ))}
          </ul>
        </div>
      </PopUp>
    </div>
  );
};
