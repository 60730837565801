import { Mark } from '@tiptap/core';

export const If = Mark.create({
  name: 'if',

  addAttributes() {
    return {
      attr: {
        default: null
      }
    };
  },

  renderHTML({ mark }) {
    return ['span', { attr: mark.attrs.attr }, 0];
  }
});
