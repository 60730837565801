import * as React from 'react';
import { useState } from 'react';

import { Button } from '@components/common';

import { DropdownProps, FilterDefinition, FilterState, Operator } from '../types';
import { getRelevantOperators } from '../utils/getRelevantOperators';
import * as dropdowns from './dropdowns';
import { OperatorDropdown } from './OperatorDropdown';

const OPERATOR_DROPDOWNS: Partial<Record<Operator, React.FC<DropdownProps<any, any>>>> = {
  contains: dropdowns.Text
};

const DEFAULT_DROPDOWNS: Partial<Record<FilterDefinition<any>['type'], React.FC<DropdownProps<any, any>>>> = {
  text: dropdowns.MultipleChoice,
  free_text: dropdowns.Text,
  boolean: dropdowns.Boolean,
  number: dropdowns.Number,
  multiple_choice: dropdowns.MultipleChoice,
  date: dropdowns.Date
};

type Props<Def> = {
  state: FilterState<Def>;
  onChange: (state: FilterState<Def>) => void;
  onClose: () => void;
  onClear: () => void;
  values?: FilterDefinition<Def>['values'];
};

export const DropdownMenu = <D, Def extends FilterDefinition<D>>(props: Props<Def>): React.ReactElement => {
  const { state: initialState, onChange, onClear, onClose, values } = props;

  const [state, setState] = useState<FilterState<Def>>(initialState);

  const Dropdown =
    state.definition.Component || OPERATOR_DROPDOWNS[state.operator] || DEFAULT_DROPDOWNS[state.definition.type];

  const handleClickApply = () => {
    onClose();
    onChange(state);
  };

  return (
    <div className='bg-white border border-gray-200 rounded-md shadow-lg'>
      <div className='p-4 border-b'>
        <OperatorDropdown
          filterDefType={state.definition.type}
          operator={state.operator}
          options={getRelevantOperators(state.definition)}
          onChange={(op) => {
            const { id, definition } = state;

            setState({ id, definition, operator: op, value: null });
            if (state.definition.applyOnChange) {
              onChange({ id, definition, operator: op, value: null });
            }
          }}
        />
        <div className='pt-4'>
          {!['is_blank', 'is_present'].includes(state.operator) && Dropdown && (
            <Dropdown
              {...state}
              values={values}
              onChange={(s: FilterState<Def>) => {
                setState({ ...state, ...s });
                if (state.definition.applyOnChange) {
                  onChange({ ...state, ...s });
                }
              }}
            />
          )}
        </div>
      </div>
      {!state.definition.applyOnChange && (
        <div className='flex p-4'>
          <div className='flex-1 mr-4'>
            <Button text onClick={onClear}>
              Remove
            </Button>
          </div>
          <div>
            <Button primary onClick={handleClickApply}>
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
