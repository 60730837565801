import { Alert } from 'components/common';
import React from 'react';
import { overDailyLimit } from '../utils';

interface Props {
  dailyInviteLimit: DailyInviteLimit;
  count: number;
}

export const DailyInviteLimitAlert: React.FC<Props> = ({ dailyInviteLimit, count }) => {
  if (overDailyLimit(dailyInviteLimit, count)) {
    return (
      <Alert type='error' heading='You’ve exceeded your daily participation invite limit'>
        You’ve already invited {dailyInviteLimit.current_count} people of your daily {dailyInviteLimit.limit} person limit.
        <br />
        Select fewer candidates to invite, or ask your account administrator to increase your limit.
      </Alert>
    );
  }

  return <></>;
};
