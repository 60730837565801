import { TippyOrNot } from '@components/common';
import cn from 'classnames';
import { CaretSVG } from '@components/svgs';
import * as React from 'react';

interface Props {
  disableNextWeekButton?: boolean;
  onClick: () => void;
  withBorder?: boolean;
}

export const NextWeekButton: React.FC<Props> = ({ withBorder, disableNextWeekButton, onClick }) => (
  <TippyOrNot show={disableNextWeekButton} content='Next week is out of the date range'>
    <button
      disabled={disableNextWeekButton}
      className={cn('focus:outline-none disabled:text-gray-400 flex items-center justify-center w-6 h-6 rounded-full', {
        'hover:bg-gray-50 hover:text-indigo-600': !disableNextWeekButton,
        'border border-gray-200': withBorder
      })}
      onClick={onClick}
      name='next week'
      aria-label='Next week'
    >
      <CaretSVG />
    </button>
  </TippyOrNot>
);
