import { useMemo, useState } from 'react';

interface Hook<T> {
  results: T[];
}
export const useSearch = <T extends unknown>(
  records: T[],
  keys: (keyof T)[],
  search: string,
  deps?: any[]
): Hook<T> => {
  const results = useMemo(
    () =>
      search
        ? records.filter((g) => keys.some((k) => g[k] && (g[k] as any).toLowerCase().includes(search.toLowerCase())))
        : records,
    [records, keys, search, ...(deps || [])]
  );

  return {
    results
  };
};
