import React, { useState } from 'react';
import { Alert, AlertHeading, AlertMessage, Button } from '@components/common';
import { AlertProps } from '../types';
import { NewStudySlideout } from '@components/StudiesApp/components/StudyNew/components/NewStudySlideout';

export const Sample: React.FC<AlertProps> = () => {
  const [showStudySlideOut, setShowStudySlideOut] = useState<boolean>(false);

  return (
    <>
      <Alert className='my-4' type='info'>
        <AlertHeading>
          This is a sample study automatically generated to show you what is possible on Great Question
        </AlertHeading>
        <AlertMessage>
          Get started and <Button link onClick={() => setShowStudySlideOut(true)}>create your own study</Button>
        </AlertMessage>
      </Alert>
      {showStudySlideOut && (
        <NewStudySlideout isVisible onClose={() => setShowStudySlideOut(false)} />
      )}
    </>
  );
};
