import { useEffect, useState } from 'react';
import { Editor, EditorContent, EditorContentProps, useEditor } from '@tiptap/react';

import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';

import { MenuBar, MenuBarProps } from './components';
import { BASE_CONFIG } from './constants';
import { Transcript, TranscriptAttributes } from './extensions';
import { getDependencyList } from './helpers/dependencies';
import { buildExtensions, Config } from './helpers/extensions';
import { useModalManager, useEditorUpdate } from './hooks';
import { isEmptyDocument } from './utils';

import type { ModalIds, TiptapProps } from './Tiptap';
import { useHighlightAITitle } from './hooks/useHighlightAITitle';

export type ArtifactApiEndpoint = 'public' | 'private';

export interface Props {
  data?: ApiDocument;
  documentId?: number;
  studyId?: number | null;
  muxVideo?: MuxVideo;
  readonly?: boolean;
  config?: Partial<Config>;
  onEditorUpdate?: (data: { editor: Editor; bytesAdded: number }) => void;
  onTemplatePick?: (data: { editor: Editor; templateId: number }) => void;
  onReady?: (editor: Editor) => void;
  autoSave?: boolean;
  scrollToTop?: boolean;
  onRecordingTimeUpdate?: (ts: number) => void;
  sessionUuid?: string;
  editorClass?: string;
  withoutPlayer?: boolean;
  slideoutView?: boolean;
  apiEndpoint?: ArtifactApiEndpoint;
  highlightReelToken?: string;
  artifactIds?: string[];
  ctaProps?: CtaProps;
  recordingId?: number;
}

export interface TiptapHook {
  ready: boolean;
  editor: Editor | null;
  Content: typeof EditorContent;
  Menu: typeof MenuBar;
  getTiptapProps: () => TiptapProps;
  getMenuProps: (props?: Partial<MenuBarProps>) => MenuBarProps;
  getContentProps: (props?: Partial<Omit<EditorContentProps, 'ref'>>) => Omit<EditorContentProps, 'ref'>;
  saveContent: (id?: number) => Promise<void>;
  slideoutView?: boolean;
  isLoading?: boolean;
}

export const useTiptap = (props: Props): TiptapHook => {
  const {
    data,
    documentId,
    studyId,
    muxVideo,
    readonly = false,
    config = {},
    onEditorUpdate,
    onTemplatePick,
    autoSave = true,
    scrollToTop = true,
    onReady,
    sessionUuid,
    editorClass,
    slideoutView,
    apiEndpoint = 'private',
    highlightReelToken,
    artifactIds,
    ctaProps,
    recordingId
  } = props;

  const { activeModal, setActiveModal, closeModal } = useModalManager<ModalIds>();

  const [speaker, setSpeaker] = useState<Partial<TranscriptAttributes['speaker']> | null>(null);
  const [ready, setReady] = useState(false);

  const dependencyList = getDependencyList(config);

  const editor = useEditor(
    {
      extensions: buildExtensions({ ...BASE_CONFIG, ...config }, [
        Transcript.configure({
          readonly,
          defaultSpeakers: config.transcript?.defaultSpeakers ?? [],
          onSpeakerChange: (speaker) => {
            setSpeaker(speaker);
            setActiveModal('speakers');
          }
        })
      ]),
      editable: !readonly,
      editorProps: { api: apiEndpoint } as any,
      shouldRerenderOnTransaction: false
    },
    dependencyList
  );

  const [saveContent, { isLoading }] = useEditorUpdate({
    autoSave,
    editor,
    documentId,
    readonly,
    onUpdate: onEditorUpdate
  });

  useEffect(() => {
    if (editor && !editor.isDestroyed && data && !isEmptyDocument(data)) {
      // Set timeout is for a known tiptap error with setting content on initial render
      // https://github.com/ueberdosis/tiptap/issues/3580
      const timeoutId = setTimeout(() => {
        // @ts-expect-error - isEmptyDocument check above ensures doc is not null
        editor.commands.loadContent(data.doc);

        setReady(true);
        onReady?.(editor);
      });

      return () => clearTimeout(timeoutId);
    }
  }, [editor, data, documentId, muxVideo, sessionUuid, recordingId]);

  useHighlightAITitle(editor);

  return {
    getContentProps: (props = {}) => ({
      editor,
      ...props
    }),
    getMenuProps: (props = {}) => ({
      editor,
      documentId: documentId || 0,
      activeModal,
      config: { ...BASE_CONFIG, ...config },
      readonly,
      setActiveModal,
      closeModal,
      onTemplatePick,
      ...props
    }),
    getTiptapProps: () => ({
      editor,
      data,
      documentId,
      studyId,
      config,
      speaker,
      activeModal,
      setActiveModal,
      closeModal,
      scrollToTop,
      sessionUuid,
      editorClass,
      slideoutView,
      highlightReelToken,
      artifactIds,
      ctaProps,
      recordingId
    }),
    Menu: MenuBar,
    Content: EditorContent,
    editor,
    saveContent,
    ready,
    isLoading
  };
};
