import { useMemo } from 'react';

type UseVisibleColumnsReturnType = Record<string, boolean>;

interface UseVisibleColumnsProps {
  columnIds: string[];
  alwaysVisibleColumns?: string[];
  currentVisibleColumns?: string[];
}

export const useVisibleColumns = ({
  columnIds,
  alwaysVisibleColumns = [],
  currentVisibleColumns = []
}: UseVisibleColumnsProps): UseVisibleColumnsReturnType => {
  const visibleColumns = useMemo<UseVisibleColumnsReturnType>(() => {
    const columns: UseVisibleColumnsReturnType = columnIds.reduce((acc, columnId) => {
      return { ...acc, [columnId]: false };
    }, {} as UseVisibleColumnsReturnType);

    alwaysVisibleColumns.forEach((columnId) => {
      columns[columnId] = true;
    });

    currentVisibleColumns.forEach((columnId) => {
      columns[columnId] = true;
    });

    return columns;
  }, [columnIds, alwaysVisibleColumns, currentVisibleColumns]);

  return visibleColumns;
};
