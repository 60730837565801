import React, { SVGProps } from 'react';

export const GiftIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.6875 7.5625H2.3125V13.6875C2.3125 13.9196 2.40469 14.1421 2.56878 14.3062C2.73288 14.4703 2.95544 14.5625 3.1875 14.5625H12.8125C13.0446 14.5625 13.2671 14.4703 13.4312 14.3062C13.5953 14.1421 13.6875 13.9196 13.6875 13.6875V7.5625Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.6875 4.9375H2.3125C2.08044 4.9375 1.85788 5.02969 1.69378 5.19378C1.52969 5.35788 1.4375 5.58044 1.4375 5.8125V7.125C1.4375 7.24103 1.48359 7.35231 1.56564 7.43436C1.64769 7.51641 1.75897 7.5625 1.875 7.5625H14.125C14.241 7.5625 14.3523 7.51641 14.4344 7.43436C14.5164 7.35231 14.5625 7.24103 14.5625 7.125V5.8125C14.5625 5.58044 14.4703 5.35788 14.3062 5.19378C14.1421 5.02969 13.9196 4.9375 13.6875 4.9375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.5625 4.9375C5.62933 4.9375 3.625 3.37067 3.625 1.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.625 1.4375C5.55817 1.4375 7.5625 3.00433 7.5625 4.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.4375 4.9375C10.3707 4.9375 12.375 3.37067 12.375 1.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.375 1.4375C10.4418 1.4375 8.4375 3.00433 8.4375 4.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.6875 4.9375H9.3125V14.5625H6.6875V4.9375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
