import * as React from 'react';

export const SVG: React.FC<any> = (props) => (
  <svg {...props} width='80' height='72' viewBox='0 0 80 72' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M40.9693 0.155498L38.3662 0.826774L6.29602 9.09704L3.69296 9.76832C2.42947 10.0957 1.34741 10.9108 0.684194 12.035C0.0209749 13.1591 -0.169248 14.5005 0.155257 15.7647L11.4427 59.5348C11.77 60.7983 12.5852 61.8804 13.7094 62.5436C14.8335 63.2068 16.1748 63.397 17.4391 63.0725L17.4458 63.0708L54.7087 53.4615L54.7154 53.4597C55.9789 53.1324 57.0609 52.3172 57.7242 51.1931C58.3874 50.0689 58.5776 48.7276 58.2531 47.4634L46.9657 3.69322C46.6383 2.42973 45.8231 1.34767 44.699 0.684446C43.5748 0.0212228 42.2335 -0.169005 40.9693 0.155498Z'
      fill='#F2F2F2'
    />
    <path
      d='M41.3239 1.52915L38.2407 2.32423L7.13058 10.3469L4.04748 11.142C3.14771 11.3751 2.37715 11.9556 1.90486 12.7561C1.43256 13.5567 1.29709 14.5118 1.52818 15.4121L12.8156 59.1823C13.0487 60.082 13.6292 60.8526 14.4298 61.3249C15.2303 61.7972 16.1855 61.9326 17.0858 61.7015L17.0925 61.6998L54.3554 52.0905L54.3622 52.0887C55.2619 51.8556 56.0325 51.2751 56.5047 50.4746C56.977 49.674 57.1125 48.7189 56.8814 47.8186L45.594 4.04845C45.3609 3.14869 44.7804 2.37814 43.9798 1.90584C43.1793 1.43354 42.2241 1.29807 41.3239 1.52915Z'
      fill='white'
    />
    <path
      d='M38.9963 15.7027L20.1459 20.5639C19.9353 20.6182 19.7118 20.5866 19.5246 20.4761C19.3373 20.3656 19.2016 20.1853 19.1473 19.9747C19.093 19.7642 19.1246 19.5407 19.2351 19.3534C19.3455 19.1662 19.5259 19.0305 19.7364 18.9762L38.5868 14.115C38.7973 14.0609 39.0206 14.0926 39.2077 14.2031C39.3948 14.3136 39.5304 14.4939 39.5847 14.7043C39.6389 14.9148 39.6074 15.1381 39.4971 15.3253C39.3868 15.5125 39.2066 15.6483 38.9963 15.7027Z'
      fill='#F2F2F2'
    />
    <path
      d='M42.962 17.5374L20.837 23.2429C20.6265 23.2972 20.403 23.2657 20.2157 23.1552C20.0285 23.0447 19.8928 22.8644 19.8385 22.6538C19.7842 22.4433 19.8157 22.2198 19.9262 22.0325C20.0367 21.8453 20.2171 21.7096 20.4276 21.6553L42.5526 15.9497C42.7631 15.8954 42.9866 15.927 43.1739 16.0374C43.3611 16.1479 43.4968 16.3283 43.5511 16.5388C43.6054 16.7493 43.5739 16.9728 43.4634 17.1601C43.3529 17.3474 43.1726 17.4831 42.962 17.5374Z'
      fill='#F2F2F2'
    />
    <path
      d='M42.0919 27.7094L23.2416 32.5705C23.1373 32.5974 23.0288 32.6035 22.9222 32.5884C22.8156 32.5734 22.713 32.5375 22.6203 32.4828C22.5276 32.4281 22.4465 32.3556 22.3818 32.2696C22.3171 32.1836 22.2699 32.0857 22.243 31.9814C22.2161 31.8772 22.21 31.7686 22.2251 31.662C22.2402 31.5554 22.2761 31.4528 22.3308 31.3601C22.3855 31.2674 22.4579 31.1863 22.5439 31.1216C22.63 31.0569 22.7279 31.0097 22.8321 30.9828L41.6825 26.1217C41.7867 26.0948 41.8952 26.0887 42.0018 26.1038C42.1084 26.1188 42.211 26.1547 42.3038 26.2094C42.3965 26.2641 42.4775 26.3366 42.5423 26.4226C42.607 26.5086 42.6541 26.6066 42.681 26.7108C42.7079 26.8151 42.714 26.9236 42.6989 27.0302C42.6839 27.1368 42.648 27.2394 42.5933 27.3321C42.5386 27.4248 42.4661 27.5059 42.3801 27.5706C42.2941 27.6353 42.1961 27.6825 42.0919 27.7094Z'
      fill='#F2F2F2'
    />
    <path
      d='M46.058 29.5444L23.933 35.25C23.8287 35.2769 23.7202 35.283 23.6136 35.2679C23.507 35.2529 23.4044 35.217 23.3117 35.1623C23.219 35.1076 23.1379 35.0351 23.0732 34.9491C23.0085 34.8631 22.9613 34.7652 22.9344 34.6609C22.9075 34.5567 22.9014 34.4481 22.9165 34.3415C22.9316 34.2349 22.9675 34.1323 23.0222 34.0396C23.0769 33.9469 23.1493 33.8659 23.2353 33.8011C23.3214 33.7364 23.4193 33.6892 23.5235 33.6624L45.6485 27.9568C45.8591 27.9025 46.0825 27.934 46.2698 28.0445C46.4571 28.155 46.5928 28.3353 46.6471 28.5459C46.7014 28.7564 46.6698 28.9799 46.5593 29.1672C46.4488 29.3544 46.2685 29.4901 46.058 29.5444Z'
      fill='#F2F2F2'
    />
    <path
      d='M45.1888 39.7165L26.3384 44.5776C26.128 44.6318 25.9046 44.6001 25.7174 44.4896C25.5303 44.3791 25.3947 44.1988 25.3404 43.9884C25.2862 43.7779 25.3177 43.5546 25.428 43.3673C25.5384 43.1801 25.7186 43.0444 25.929 42.99L44.7794 38.1289C44.9899 38.0746 45.2134 38.1062 45.4007 38.2166C45.5879 38.3271 45.7236 38.5075 45.7779 38.718C45.8322 38.9285 45.8007 39.152 45.6902 39.3393C45.5797 39.5265 45.3994 39.6623 45.1888 39.7165Z'
      fill='#F2F2F2'
    />
    <path
      d='M49.1539 41.5512L27.0289 47.2567C26.9246 47.2837 26.816 47.2899 26.7094 47.2749C26.6027 47.2599 26.5 47.2241 26.4072 47.1694C26.3144 47.1147 26.2333 47.0422 26.1685 46.9562C26.1037 46.8701 26.0565 46.7721 26.0296 46.6678C26.0027 46.5635 25.9967 46.4549 26.0117 46.3482C26.0268 46.2416 26.0628 46.139 26.1176 46.0462C26.1724 45.9534 26.2449 45.8724 26.331 45.8077C26.4171 45.743 26.5151 45.6959 26.6195 45.6691L48.7444 39.9635C48.955 39.9092 49.1785 39.9407 49.3657 40.0512C49.553 40.1617 49.6887 40.3421 49.743 40.5526C49.7973 40.7631 49.7657 40.9866 49.6553 41.1739C49.5448 41.3612 49.3644 41.4969 49.1539 41.5512Z'
      fill='#F2F2F2'
    />
    <path
      d='M16.9276 25.5482L10.2566 27.2685C10.1557 27.2944 10.0486 27.2793 9.9588 27.2263C9.86904 27.1733 9.80394 27.0869 9.77781 26.986L8.24649 21.0479C8.22058 20.947 8.23576 20.8399 8.28872 20.7501C8.34168 20.6603 8.42809 20.5952 8.52898 20.5691L15.2 18.8488C15.3009 18.8229 15.408 18.8381 15.4978 18.891C15.5875 18.944 15.6526 19.0304 15.6788 19.1313L17.2101 25.0694C17.236 25.1704 17.2208 25.2775 17.1679 25.3672C17.1149 25.457 17.0285 25.5221 16.9276 25.5482Z'
      fill='#E6E6E6'
    />
    <path
      d='M20.0252 37.555L13.3543 39.2754C13.2533 39.3013 13.1462 39.2861 13.0565 39.2331C12.9667 39.1802 12.9016 39.0938 12.8755 38.9929L11.3441 33.0548C11.3182 32.9538 11.3334 32.8467 11.3864 32.7569C11.4393 32.6672 11.5257 32.6021 11.6266 32.5759L18.2976 30.8556C18.3986 30.8297 18.5057 30.8449 18.5954 30.8979C18.6852 30.9508 18.7503 31.0372 18.7764 31.1381L20.3077 37.0762C20.3336 37.1772 20.3185 37.2843 20.2655 37.3741C20.2125 37.4638 20.1261 37.5289 20.0252 37.555Z'
      fill='#E6E6E6'
    />
    <path
      d='M23.121 49.562L16.45 51.2823C16.349 51.3082 16.2419 51.293 16.1522 51.24C16.0624 51.1871 15.9973 51.1007 15.9712 50.9998L14.4399 45.0617C14.4139 44.9607 14.4291 44.8536 14.4821 44.7639C14.535 44.6741 14.6214 44.609 14.7223 44.5829L21.3933 42.8626C21.4943 42.8367 21.6014 42.8519 21.6911 42.9048C21.7809 42.9578 21.846 43.0442 21.8721 43.1451L23.4034 49.0831C23.4294 49.1841 23.4142 49.2912 23.3612 49.381C23.3083 49.4707 23.2219 49.5358 23.121 49.562Z'
      fill='#E6E6E6'
    />
    <path
      d='M66.9696 11.2518H28.4738C27.1686 11.2533 25.9172 11.7724 24.9943 12.6954C24.0714 13.6183 23.5522 14.8696 23.5508 16.1748V61.3769C23.5522 62.6822 24.0714 63.9335 24.9943 64.8564C25.9172 65.7793 27.1686 66.2985 28.4738 66.2999H66.9696C68.2748 66.2985 69.5262 65.7793 70.4491 64.8564C71.372 63.9335 71.8912 62.6822 71.8926 61.3769V16.1748C71.8912 14.8696 71.372 13.6183 70.4491 12.6954C69.5262 11.7724 68.2748 11.2533 66.9696 11.2518Z'
      fill='#E6E6E6'
    />
    <path
      d='M66.9687 12.6688H28.4728C27.5434 12.6699 26.6523 13.0396 25.995 13.6968C25.3378 14.354 24.9681 15.2451 24.967 16.1746V61.3767C24.9681 62.3062 25.3378 63.1973 25.995 63.8545C26.6523 64.5118 27.5434 64.8815 28.4728 64.8825H66.9687C67.8982 64.8815 68.7893 64.5118 69.4465 63.8545C70.1037 63.1973 70.4734 62.3062 70.4745 61.3767V16.1746C70.4734 15.2451 70.1037 14.354 69.4465 13.6968C68.7893 13.0396 67.8982 12.6699 66.9687 12.6688Z'
      fill='white'
    />
    <path
      d='M71.0126 71.7996C75.9758 71.7996 79.9992 67.7761 79.9992 62.8129C79.9992 57.8497 75.9758 53.8262 71.0126 53.8262C66.0494 53.8262 62.0259 57.8497 62.0259 62.8129C62.0259 67.7761 66.0494 71.7996 71.0126 71.7996Z'
      fill='#6C63FF'
    />
    <path
      d='M75.3114 61.6403H72.1856V58.5145C72.1856 58.2036 72.0621 57.9054 71.8423 57.6856C71.6224 57.4658 71.3243 57.3423 71.0134 57.3423C70.7025 57.3423 70.4044 57.4658 70.1845 57.6856C69.9647 57.9054 69.8412 58.2036 69.8412 58.5145V61.6403H66.7154C66.4045 61.6403 66.1064 61.7638 65.8865 61.9836C65.6667 62.2034 65.5432 62.5016 65.5432 62.8125C65.5432 63.1234 65.6667 63.4215 65.8865 63.6413C66.1064 63.8612 66.4045 63.9847 66.7154 63.9847H69.8412V67.1105C69.8412 67.4214 69.9647 67.7195 70.1845 67.9393C70.4044 68.1592 70.7025 68.2827 71.0134 68.2827C71.3243 68.2827 71.6224 68.1592 71.8423 67.9393C72.0621 67.7195 72.1856 67.4214 72.1856 67.1105V63.9847H75.3114C75.6223 63.9847 75.9204 63.8612 76.1403 63.6413C76.3601 63.4215 76.4836 63.1234 76.4836 62.8125C76.4836 62.5016 76.3601 62.2034 76.1403 61.9836C75.9204 61.7638 75.6223 61.6403 75.3114 61.6403Z'
      fill='white'
    />
    <path
      d='M61.1748 37.5077H41.7077C41.6 37.5078 41.4933 37.4867 41.3937 37.4455C41.2941 37.4044 41.2036 37.344 41.1274 37.2679C41.0512 37.1917 40.9907 37.1013 40.9495 37.0018C40.9082 36.9023 40.887 36.7956 40.887 36.6879C40.887 36.5801 40.9082 36.4734 40.9495 36.3739C40.9907 36.2744 41.0512 36.184 41.1274 36.1078C41.2036 36.0317 41.2941 35.9713 41.3937 35.9302C41.4933 35.889 41.6 35.8679 41.7077 35.868H61.1748C61.3921 35.8683 61.6003 35.9548 61.7539 36.1085C61.9074 36.2622 61.9937 36.4706 61.9937 36.6879C61.9937 36.9051 61.9074 37.1135 61.7539 37.2672C61.6003 37.421 61.3921 37.5074 61.1748 37.5077Z'
      fill='#E6E6E6'
    />
    <path
      d='M64.5565 40.2743H41.7077C41.6 40.2745 41.4933 40.2534 41.3937 40.2122C41.2941 40.1711 41.2036 40.1107 41.1274 40.0346C41.0512 39.9584 40.9907 39.868 40.9495 39.7685C40.9082 39.669 40.887 39.5623 40.887 39.4546C40.887 39.3468 40.9082 39.2401 40.9495 39.1406C40.9907 39.0411 41.0512 38.9507 41.1274 38.8746C41.2036 38.7984 41.2941 38.738 41.3937 38.6969C41.4933 38.6558 41.6 38.6346 41.7077 38.6348H64.5565C64.7739 38.6348 64.9825 38.7211 65.1362 38.8749C65.2899 39.0286 65.3763 39.2371 65.3763 39.4546C65.3763 39.672 65.2899 39.8805 65.1362 40.0342C64.9825 40.188 64.7739 40.2743 64.5565 40.2743Z'
      fill='#E6E6E6'
    />
    <path
      d='M61.1748 49.9068H41.7077C41.6 49.9069 41.4933 49.8858 41.3937 49.8447C41.2941 49.8035 41.2036 49.7431 41.1274 49.667C41.0512 49.5909 40.9907 49.5005 40.9495 49.4009C40.9082 49.3014 40.887 49.1947 40.887 49.087C40.887 48.9793 40.9082 48.8726 40.9495 48.7731C40.9907 48.6735 41.0512 48.5831 41.1274 48.507C41.2036 48.4309 41.2941 48.3705 41.3937 48.3294C41.4933 48.2882 41.6 48.2671 41.7077 48.2672H61.1748C61.3922 48.2672 61.6007 48.3536 61.7545 48.5073C61.9082 48.6611 61.9946 48.8696 61.9946 49.087C61.9946 49.3044 61.9082 49.5129 61.7545 49.6667C61.6007 49.8204 61.3922 49.9068 61.1748 49.9068Z'
      fill='#E6E6E6'
    />
    <path
      d='M64.5565 52.6738H41.7077C41.6 52.6739 41.4933 52.6528 41.3937 52.6117C41.2941 52.5705 41.2036 52.5102 41.1274 52.434C41.0512 52.3579 40.9907 52.2675 40.9495 52.1679C40.9082 52.0684 40.887 51.9617 40.887 51.854C40.887 51.7463 40.9082 51.6396 40.9495 51.5401C40.9907 51.4405 41.0512 51.3501 41.1274 51.274C41.2036 51.1978 41.2941 51.1375 41.3937 51.0963C41.4933 51.0552 41.6 51.0341 41.7077 51.0342H64.5565C64.6642 51.0341 64.7709 51.0552 64.8705 51.0963C64.9701 51.1375 65.0606 51.1978 65.1368 51.274C65.213 51.3501 65.2735 51.4405 65.3147 51.5401C65.356 51.6396 65.3772 51.7463 65.3772 51.854C65.3772 51.9617 65.356 52.0684 65.3147 52.1679C65.2735 52.2675 65.213 52.3579 65.1368 52.434C65.0606 52.5102 64.9701 52.5705 64.8705 52.6117C64.7709 52.6528 64.6642 52.6739 64.5565 52.6738Z'
      fill='#E6E6E6'
    />
    <path
      d='M37.3485 41.5303H30.4593C30.355 41.5302 30.2551 41.4888 30.1814 41.4151C30.1077 41.3414 30.0663 41.2414 30.0662 41.1372V35.0048C30.0663 34.9006 30.1077 34.8007 30.1814 34.727C30.2551 34.6533 30.355 34.6118 30.4593 34.6117H37.3485C37.4527 34.6118 37.5526 34.6533 37.6263 34.727C37.7 34.8007 37.7415 34.9006 37.7416 35.0048V41.1372C37.7415 41.2414 37.7 41.3414 37.6263 41.4151C37.5526 41.4888 37.4527 41.5302 37.3485 41.5303Z'
      fill='#E6E6E6'
    />
    <path
      d='M37.3485 53.9299H30.4593C30.355 53.9298 30.2551 53.8884 30.1814 53.8147C30.1077 53.741 30.0663 53.641 30.0662 53.5368V47.4045C30.0663 47.3002 30.1077 47.2003 30.1814 47.1266C30.2551 47.0529 30.355 47.0115 30.4593 47.0114H37.3485C37.4527 47.0115 37.5526 47.0529 37.6263 47.1266C37.7 47.2003 37.7415 47.3002 37.7416 47.4045V53.5368C37.7415 53.641 37.7 53.741 37.6263 53.8147C37.5526 53.8884 37.4527 53.9298 37.3485 53.9299Z'
      fill='#E6E6E6'
    />
    <path
      d='M61.1975 23.704H46.8452C46.6278 23.704 46.4193 23.6177 46.2655 23.4639C46.1118 23.3102 46.0254 23.1017 46.0254 22.8842C46.0254 22.6668 46.1118 22.4583 46.2655 22.3046C46.4193 22.1508 46.6278 22.0645 46.8452 22.0645H61.1975C61.4149 22.0645 61.6234 22.1508 61.7772 22.3046C61.9309 22.4583 62.0173 22.6668 62.0173 22.8842C62.0173 23.1017 61.9309 23.3102 61.7772 23.4639C61.6234 23.6177 61.4149 23.704 61.1975 23.704Z'
      fill='#CCCCCC'
    />
    <path
      d='M64.5792 26.4708H46.8452C46.7375 26.4708 46.6309 26.4496 46.5315 26.4084C46.432 26.3672 46.3416 26.3068 46.2655 26.2307C46.1894 26.1546 46.129 26.0642 46.0878 25.9647C46.0466 25.8653 46.0254 25.7587 46.0254 25.651C46.0254 25.5433 46.0466 25.4367 46.0878 25.3373C46.129 25.2378 46.1894 25.1474 46.2655 25.0713C46.3416 24.9952 46.432 24.9348 46.5315 24.8936C46.6309 24.8524 46.7375 24.8312 46.8452 24.8312H64.5792C64.7966 24.8312 65.0052 24.9175 65.1589 25.0713C65.3127 25.225 65.399 25.4336 65.399 25.651C65.399 25.8684 65.3127 26.077 65.1589 26.2307C65.0052 26.3845 64.7966 26.4708 64.5792 26.4708Z'
      fill='#CCCCCC'
    />
    <path
      d='M43.6681 29.8241H30.4366C30.3323 29.824 30.2324 29.7826 30.1587 29.7089C30.085 29.6352 30.0436 29.5352 30.0435 29.431V19.1045C30.0436 19.0003 30.085 18.9004 30.1587 18.8267C30.2324 18.753 30.3323 18.7115 30.4366 18.7114H43.6681C43.7724 18.7115 43.8723 18.753 43.946 18.8267C44.0197 18.9004 44.0611 19.0003 44.0612 19.1045V29.431C44.0611 29.5352 44.0197 29.6352 43.946 29.7089C43.8723 29.7826 43.7724 29.824 43.6681 29.8241Z'
      fill='#6C63FF'
    />
  </svg>
);
