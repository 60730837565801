import React from 'react';
import tinytime from 'tinytime';

import { Text } from '@components/common';

import { getNylasEventTimezoneOffset } from '../../../utils';
import { CalendarSVG } from '../eventPopupSvgs';

const time = tinytime('{h}:{mm} {a}');

const date = tinytime('{dddd}, {MM} {DD}, {YYYY}');

interface Props {
  when: NylasSlotInstance['when'];
  timezone: Timezone;
}

export const EventDate: React.FC<Props> = ({ when, timezone }) => {
  const eventStartTime = when?.start_time && time.render(new Date(when.start_time * 1000));

  const eventEndTime = when?.end_time && time.render(new Date(when.end_time * 1000));

  const eventTimeRange = when?.start_time && `${eventStartTime} - ${eventEndTime}`;

  const eventDate = when?.start_time && `${date.render(new Date(when.start_time * 1000))}`;

  return (
    <div className='flex mb-6'>
      <div className='flex items-center h-6 mr-4'>
        <CalendarSVG />
      </div>

      <div>
        {eventDate && <Text h='400'>{eventDate}</Text>}
        {!!eventTimeRange && (
          <Text color='gray-500' h='200'>
            {eventTimeRange} ({timezone.abbr})
          </Text>
        )}
      </div>
    </div>
  );
};
