import { Basic } from '@components/shared/Skeleton';
import React from 'react';

export const SkeletonLoader = () => {
  return (
    <div aria-label='Loading skeleton' className='w-full divide-gray-200 divide-y'>
      {Array(6)
        .fill(6)
        .map((_, idx) => (
          <div key={idx} className='w-full px-6 py-4 flex-1'>
            <Basic width='100%' h='4' />
          </div>
        ))}
    </div>
  );
};
