import * as React from 'react';

import { Button, Text } from '@components/common';

type Props = {
  canClickPrevious: boolean;
  onClickPrevious: () => void;
  canClickNext: boolean;
  onClickNext: () => void;
  current: number;
  total: number;
};

export const Paginator: React.VFC<Props> = ({
  canClickNext,
  canClickPrevious,
  onClickNext,
  onClickPrevious,
  current,
  total
}) => (
  <div className='focus:outline-none flex items-center space-x-2 bg-white border border-gray-200 rounded-md'>
    <Button
      aria-label='Previous page'
      className='xx-prev-page'
      small
      text
      icon='chevronLeft'
      onClick={onClickPrevious}
      disabled={!canClickPrevious}
    />
    <Text h='400' className='whitespace-nowrap'>
      Page {current} of {total}
    </Text>
    <Button
      aria-label='Next page'
      className='xx-next-page'
      small
      text
      icon='chevronRight'
      onClick={onClickNext}
      disabled={!canClickNext}
    />
  </div>
);
