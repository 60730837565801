import React, { SVGProps } from 'react';

export const TemplatesSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2228_625)'>
      <path
        d='M12.6875 0.4375H1.3125C0.829251 0.4375 0.4375 0.829251 0.4375 1.3125V3.5C0.4375 3.98325 0.829251 4.375 1.3125 4.375H12.6875C13.1707 4.375 13.5625 3.98325 13.5625 3.5V1.3125C13.5625 0.829251 13.1707 0.4375 12.6875 0.4375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 0.4375V4.375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 2.1875L11.375 3.0625L12.25 2.1875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6875 6.5625H1.3125C0.829251 6.5625 0.4375 6.95425 0.4375 7.4375V12.6875C0.4375 13.1707 0.829251 13.5625 1.3125 13.5625H12.6875C13.1707 13.5625 13.5625 13.1707 13.5625 12.6875V7.4375C13.5625 6.95425 13.1707 6.5625 12.6875 6.5625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 10.0625H13.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.375 11.6194C11.4183 11.6194 11.4606 11.6322 11.4965 11.6563C11.5325 11.6803 11.5605 11.7145 11.5771 11.7544C11.5937 11.7944 11.598 11.8384 11.5895 11.8808C11.5811 11.9232 11.5603 11.9622 11.5297 11.9928C11.4991 12.0234 11.4601 12.0442 11.4177 12.0527C11.3752 12.0611 11.3313 12.0568 11.2913 12.0402C11.2513 12.0237 11.2172 11.9956 11.1931 11.9597C11.1691 11.9237 11.1562 11.8814 11.1562 11.8381C11.1562 11.7801 11.1793 11.7245 11.2203 11.6835C11.2613 11.6424 11.317 11.6194 11.375 11.6194Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.375 8.06812C11.4183 8.06812 11.4606 8.08094 11.4965 8.10498C11.5325 8.12902 11.5605 8.16318 11.5771 8.20315C11.5937 8.24312 11.598 8.28711 11.5895 8.32954C11.5811 8.37197 11.5603 8.41095 11.5297 8.44154C11.4991 8.47214 11.4601 8.49297 11.4177 8.50141C11.3752 8.50985 11.3313 8.50552 11.2913 8.48896C11.2513 8.47241 11.2172 8.44437 11.1931 8.4084C11.1691 8.37242 11.1562 8.33013 11.1562 8.28687C11.1562 8.22885 11.1793 8.17321 11.2203 8.13219C11.2613 8.09116 11.317 8.06812 11.375 8.06812Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2228_625'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
