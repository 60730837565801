export const addDaysToDate = (date: Date, n: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + n);

  return newDate;
};

export const addMinutesToDate = (date: Date, n: number): Date => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() + n);

  return newDate;
};

export const startOfWeek = (date: Date): Date => {
  const ret = new Date(date);
  const day = ret.getDay();
  const diff = ret.getDate() - day + (day == 0 ? -6 : 1); // get the monday
  return new Date(ret.setDate(diff));
};

export const getSunday = (date: Date): Date => {
  const ret = new Date(date);
  const day = ret.getDay();
  const diff = ret.getDate() - day + (day == 0 ? -7 : 0); // get the monday
  return new Date(ret.setDate(diff));
};

export const getDatesBetween = (start: Date, end: Date): Date[] => {
  const dates: Date[] = [];

  let d = start;
  while (d <= end) {
    dates.push(d);
    d = addDaysToDate(d, 1);

    if (dates.length > 100) throw new Error();
  }

  return dates;
};

export const secondsToTimestamp = (seconds?: number, emptyPlaces?: number): string => {
  const zeroesToRemove = 8 - (emptyPlaces || 1);
  const pattern = new RegExp(`^[0:]{0,${zeroesToRemove}}`);

  if (!seconds || seconds < 1) return '00:00:00'.replace(pattern, '');

  return new Date(seconds * 1000).toISOString().slice(11, 19).replace(pattern, '');
};

export const isValidDate = (value: unknown): boolean => {
  if (typeof value === 'string' || typeof value === 'number') {
    return !isNaN(new Date(value).getTime());
  }

  if (value instanceof Date) {
    return !isNaN(value.getTime());
  }

  return false;
};
