import React from 'react';
import { SVGProps } from 'react';

export const CloudSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_2_335)'>
      <path
        d='M6.56201 10.063C6.56201 10.5226 6.65254 10.9777 6.82843 11.4024C7.00433 11.827 7.26213 12.2129 7.58714 12.5379C7.91214 12.8629 8.29798 13.1207 8.72262 13.2966C9.14726 13.4725 9.60239 13.563 10.062 13.563C10.5216 13.563 10.9768 13.4725 11.4014 13.2966C11.826 13.1207 12.2119 12.8629 12.5369 12.5379C12.8619 12.2129 13.1197 11.827 13.2956 11.4024C13.4715 10.9777 13.562 10.5226 13.562 10.063C13.562 9.60336 13.4715 9.14824 13.2956 8.7236C13.1197 8.29896 12.8619 7.91312 12.5369 7.58811C12.2119 7.26311 11.826 7.0053 11.4014 6.82941C10.9768 6.65352 10.5216 6.56299 10.062 6.56299C9.60239 6.56299 9.14726 6.65352 8.72262 6.82941C8.29798 7.0053 7.91214 7.26311 7.58714 7.58811C7.26213 7.91312 7.00433 8.29896 6.82843 8.7236C6.65254 9.14824 6.56201 9.60336 6.56201 10.063V10.063Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6218 9.04517L9.93016 11.305C9.89254 11.3551 9.84459 11.3965 9.78957 11.4265C9.73454 11.4565 9.67372 11.4743 9.61122 11.4787C9.54873 11.4831 9.48601 11.4741 9.42731 11.4521C9.36861 11.4302 9.3153 11.396 9.271 11.3517L8.396 10.4767'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.475 5.63432C13.3612 5.19063 13.1418 4.78104 12.8356 4.44045C12.5293 4.09986 12.1452 3.83838 11.7161 3.67829C11.2869 3.5182 10.8254 3.46426 10.3709 3.52106C9.91639 3.57785 9.48236 3.74371 9.1058 4.00448C8.95224 3.18786 8.5693 2.43185 8.00178 1.82491C7.43426 1.21797 6.70562 0.785197 5.90113 0.57723C5.09664 0.369262 4.24956 0.394699 3.45899 0.650564C2.66843 0.906428 1.96708 1.38214 1.437 2.02204C0.906915 2.66194 0.570026 3.43956 0.465745 4.26393C0.361463 5.0883 0.494102 5.92532 0.848143 6.67706C1.20218 7.4288 1.76298 8.06417 2.46493 8.50884C3.16688 8.9535 3.98095 9.18907 4.81188 9.18799'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_2_335'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
