import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal, ModalHeading } from '@components/common';

import { useHighlightReelsContext } from '../../../hooks/useHighlightReelsContext';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const DiscardModal = ({ isOpen, onConfirm, onClose }: Props) => {
  return isOpen ? (
    <Modal size='sm' onClose={onClose}>
      <ModalHeading className='mb-6'>Discard highlight reel?</ModalHeading>
      <p className='pb-12 text-gray-700'>Are you sure you want to discard this highlight reel?</p>
      <div className='flex justify-end space-x-4'>
        <Button onClick={onClose}>Don't discard</Button>
        <Button onClick={onConfirm} danger>
          Discard now
        </Button>
      </div>
    </Modal>
  ) : null;
};
