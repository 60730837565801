import React, { SVGProps } from 'react';

export const AttributeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.6875 3.0625H1.3125C1.08044 3.0625 0.857876 3.15469 0.693782 3.31878C0.529687 3.48288 0.4375 3.70544 0.4375 3.9375V11.8125C0.4375 12.0446 0.529687 12.2671 0.693782 12.4312C0.857876 12.5953 1.08044 12.6875 1.3125 12.6875H12.6875C12.9196 12.6875 13.1421 12.5953 13.3062 12.4312C13.4703 12.2671 13.5625 12.0446 13.5625 11.8125V3.9375C13.5625 3.70544 13.4703 3.48288 13.3062 3.31878C13.1421 3.15469 12.9196 3.0625 12.6875 3.0625H8.3125'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 2.625C8.3125 2.2769 8.17422 1.94306 7.92808 1.69692C7.68194 1.45078 7.3481 1.3125 7 1.3125C6.6519 1.3125 6.31806 1.45078 6.07192 1.69692C5.82578 1.94306 5.6875 2.2769 5.6875 2.625V4.375C5.6875 4.49103 5.73359 4.60231 5.81564 4.68436C5.89769 4.76641 6.00897 4.8125 6.125 4.8125H7.875C7.99103 4.8125 8.10231 4.76641 8.18436 4.68436C8.26641 4.60231 8.3125 4.49103 8.3125 4.375V2.625Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.8125 9.625C5.65819 9.625 6.34375 8.93944 6.34375 8.09375C6.34375 7.24806 5.65819 6.5625 4.8125 6.5625C3.96681 6.5625 3.28125 7.24806 3.28125 8.09375C3.28125 8.93944 3.96681 9.625 4.8125 9.625Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.1875 12.6875C2.1875 11.9913 2.46406 11.3236 2.95634 10.8313C3.44863 10.3391 4.11631 10.0625 4.8125 10.0625C5.50869 10.0625 6.17637 10.3391 6.66865 10.8313C7.16094 11.3236 7.4375 11.9913 7.4375 12.6875H2.1875Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 7.4375H10.9375'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 9.1875H11.8125'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
