import { FilterDefinition, Operator } from '../types';

export const DEFAULTS: Record<Operator, string> = {
  is: 'is equal to',
  is_not: 'is not',
  includes_any: 'includes any of',
  includes_none: 'includes none of',
  includes_all: 'includes all of',
  is_greater: 'is greater than',
  is_lesser: 'is less than',
  is_between: 'is between',
  is_blank: 'is blank',
  is_present: 'is present',
  contains: 'contains',
  contains_not: 'doesn’t contain'
};
export const TEXT_OVERRIDES: Partial<Record<Operator, string>> = {
  contains: 'contains',
  contains_not: 'doesn’t contain',
  is_not: 'is not',
  is_blank: 'is blank',
  is_present: 'is present'
};
export const CHOOSE_OVERRIDES: Partial<Record<Operator, string>> = {
  includes_any: 'is any of',
  includes_none: 'is none of'
};
export const BOOLEAN_OVERRIDES: Partial<Record<Operator, string>> = {
  is: 'is'
};
export const DATE_OVERRIDES: Partial<Record<Operator, string>> = {
  is_greater: 'is after',
  is_lesser: 'is before'
};
export const PARTICIPATED_IN_STUDIES_OVERRIDES: Partial<Record<Operator, string>> = {
  includes_any: 'has participated in any of',
  includes_none: 'hasn’t participated in any of',
  includes_all: 'has participated in all of'
};
export const TEAM_MEMBERS_OVERRIDES: Partial<Record<Operator, string>> = {
  includes_any: 'is',
  includes_none: 'is not'
};

export const getOperatorCopy = (type: FilterDefinition<any>['type'], operator: Operator): string => {
  switch (type) {
    case 'segment':
      return 'is';
    case 'text':
      return TEXT_OVERRIDES[operator] || DEFAULTS[operator];
    case 'eligibility':
      return operator === 'is' ? 'is' : 'is not';
    case 'boolean':
      return BOOLEAN_OVERRIDES[operator] || DEFAULTS[operator];
    case 'date':
      return DATE_OVERRIDES[operator] || DEFAULTS[operator];
    case 'participated_in_studies':
      return PARTICIPATED_IN_STUDIES_OVERRIDES[operator] || DEFAULTS[operator];
    case 'owner':
    case 'artifact_type':
      return TEAM_MEMBERS_OVERRIDES[operator] || DEFAULTS[operator];
    default:
      return DEFAULTS[operator];
  }
};
