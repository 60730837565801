import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Loading } from '@components/common';

import { GuidePage } from './GuidePage';
import { useGuide } from './hooks/useGuide';

export const GuidesEdit: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { loading, guide } = useGuide(token || '');

  return (
    <>
      {loading && <Loading />}
      {guide && <GuidePage editable guide={guide} />}
    </>
  );
};
