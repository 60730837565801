import * as React from 'react';

import { ScreenSharingProvider } from '@components/ScreenSharingProvider';
import { Models } from '@components/SurveyBuilder';
import { render } from '@helpers/render';

import { Unmoderated, Props } from './Unmoderated';
import { inferPermissionsFromBlocks } from './utils';

interface ServerProps {
  blocks: Models.Block[];
  participation: Participation;
  device_type: Study['device_type'];
}

export const mount = () =>
  render<Props, ServerProps>({
    component: ({ blocks, surveyId, participation, deviceType }) => (
      <ScreenSharingProvider
        participation={participation}
        permissions={inferPermissionsFromBlocks(blocks)}
        device={deviceType ?? 'desktop'}
      >
        <Unmoderated participation={participation} blocks={blocks} surveyId={surveyId} deviceType={deviceType} />
      </ScreenSharingProvider>
    ),
    id: 'unmoderated',
    props: ({ blocks, participation, device_type }) => ({
      blocks,
      participation,
      surveyId: participation.project_id,
      deviceType: device_type
    })
  });
