import { useState } from 'react';

const pad = (n: number) => (String(n) as any).padStart(2, '0').substring(0, 2);

type StopwatchType = {
  time: number;
  start: () => void;
  stop: () => void;
  reset: () => void;
};

export const useStopwatch = (): StopwatchType => {
  const [timerId, setTimerId] = useState<NodeJS.Timeout>();
  const [time, setTime] = useState(0);

  const start: () => void = () => {
    setTimerId(
      setInterval(() => {
        setTime((time: number) => {
          return time + 10;
        });
      }, 10)
    );
  };

  const stop: () => void = () => {
    clearInterval(timerId as any);
  };

  const reset: () => void = () => {
    clearInterval(timerId as any);
    setTime(0);
  };

  return { time, start, stop, reset };
};
