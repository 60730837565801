import React, { SVGProps } from 'react';

export const ComposeEmailSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_576_10622)'>
      <path
        d='M14.2008 9.67417L9.75 14.125L7.5625 14.5625L8 12.375L12.4508 7.92417C12.5654 7.80945 12.7014 7.71844 12.8511 7.65635C13.0009 7.59426 13.1614 7.56229 13.3235 7.56229C13.4856 7.56229 13.6461 7.59426 13.7959 7.65635C13.9456 7.71844 14.0816 7.80945 14.1962 7.92417L14.2008 7.92883C14.3155 8.04337 14.4066 8.1794 14.4687 8.32914C14.5307 8.47888 14.5627 8.6394 14.5627 8.8015C14.5627 8.96361 14.5307 9.12412 14.4687 9.27386C14.4066 9.4236 14.3155 9.55963 14.2008 9.67417V9.67417Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.5625 10.1875H2.3125C2.08044 10.1875 1.85788 10.0953 1.69378 9.93122C1.52969 9.76712 1.4375 9.54456 1.4375 9.3125V2.3125C1.4375 2.08044 1.52969 1.85788 1.69378 1.69378C1.85788 1.52969 2.08044 1.4375 2.3125 1.4375H12.8125C13.0446 1.4375 13.2671 1.52969 13.4312 1.69378C13.5953 1.85788 13.6875 2.08044 13.6875 2.3125V5.8125'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.4898 1.75834L8.73909 5.41234C8.40176 5.67184 7.9881 5.81254 7.5625 5.81254C7.13691 5.81254 6.72325 5.67184 6.38592 5.41234L1.63525 1.75834'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_576_10622'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
