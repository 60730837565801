import cn from 'classnames';
import * as React from 'react';

import { Checkbox, Tabs, Text } from '@components/common';
import { ChevronDownSVG, ChevronRightSVG, ChevronUpSVG, PlusSVG, SearchSVG } from '@components/svgs';
import { BaseTagPill, Swatch, TagPill } from '@components/tags';
import { getBackgroundColor, TAG_COLORS } from '@components/tags/colors';
import { useMultiselectTags } from '@components/tags/useMultiselectTags';
import { compact, without } from '@components/utils';
import { useFeature } from '@hooks/useFeature';
import { useTags } from '@stores/tags';

import { DropdownProps } from '../../types';

type Props<D = any> = DropdownProps<D, number[]> & {
  studyId?: number | null;
};

export const Tag: React.FC<Props> = ({ value, onChange, studyId }) => {
  const { tags, tagGroups } = useTags();

  const {
    inputValue,
    setInputValue,
    selectedItems,
    getMenuProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    visibleItems,
    mode,
    setMode
  } = useMultiselectTags({
    closeOnEsc: true,
    canManage: false,
    tags: tags || [],
    tagGroups: tagGroups || [],
    studyId,
    initialSelectedTagIds: value || [],
    onAddTag: (tag) => onChange?.({ value: [...(value || []), tag.id] }),
    onRemoveTag: (tag) => onChange?.({ value: without(value || [], tag.id) })
  });
  const lastSelected = [...selectedItems].pop();
  const showLastSelectedBorder = (itemId: string) =>
    lastSelected?.id === itemId && selectedItems.length !== visibleItems.length - 1;
  return (
    <div>
      <div className='relative'>
        <div {...getComboboxProps()} role='none'>
          <div className='relative flex items-center mb-4'>
            <SearchSVG className='left-4 absolute text-indigo-600' />
            <input
              className='focus:ring-indigo-600 focus:border-indigo-600 tablet:text-sm block w-full pl-10 border border-gray-200 rounded-md py-1.5'
              placeholder='Search...'
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </div>
        {studyId && (
          <div className='px-2 pb-2'>
            <Tabs
              customTabPaddings='py-1'
              current={mode}
              tabs={['study', 'global']}
              onSelect={setMode}
              labels={{ study: 'Study tags', global: 'Global tags' }}
            />
          </div>
        )}
        <ul className='max-h-60 -mx-4 overflow-y-scroll' {...getMenuProps()}>
          {visibleItems.map((item, index) => (
            <li
              key={item.id}
              className={cn('cursor-pointer w-full', { 'bg-gray-50': highlightedIndex === index })}
              {...getItemProps({ item, index })}
            >
              {item.type === 'with_tag' && (
                <div
                  className={cn('flex items-center w-full px-4 py-2 space-x-2', {
                    'border-b border-gray-200': showLastSelectedBorder(item.id)
                  })}
                >
                  <Checkbox selected={selectedItems.some((i) => i.id === item.id)} className='rounded' />
                  <TagPill tag={item.tag} size='sm' />
                </div>
              )}
              {item.type === 'with_tag_group' && (
                <div
                  className={cn('flex items-center w-full px-4 py-2 space-x-2', {
                    'border-b border-gray-200': !item.open
                  })}
                >
                  <div
                    className='flex items-center justify-center w-4 h-4 mr-2 rounded-sm'
                    style={{ backgroundColor: getBackgroundColor(item.tagGroup.color, 0.2) }}
                  />
                  <Text h='400' color={item.tagGroup.name ? undefined : 'gray-500'} className='flex-1'>
                    {item.tagGroup.name || 'Unnamed Group'}
                  </Text>
                  <div>
                    {!!item.count && (
                      <div
                        className={cn('rounded-sm inline h200 px-2', {
                          'text-white': TAG_COLORS[item.tagGroup.color].whiteText
                        })}
                        style={{ backgroundColor: getBackgroundColor(item.tagGroup.color, 0.2) }}
                      >
                        {item.count}
                      </div>
                    )}
                  </div>
                  {item.open && <ChevronUpSVG />}
                  {!item.open && <ChevronDownSVG />}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
