import { useFeature } from 'hooks/useFeature';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Select, Text, TippyOrNot, Toggle } from '@components/common';
import { without } from '@components/utils';
import Tippy from '@tippyjs/react';

interface Props {
  disabled?: boolean;
  study: Study;
  options: PlanOption[];
  setOptions: (v: PlanOption[]) => void;
  change?: (key: string, value: any) => void;
}
export const ScreenerOptions: React.FC<Props> = ({ disabled, study, setOptions, options, change }) => {
  const navigate = useNavigate();

  const hasScreenerInviteCta = useFeature('screener_invite_cta');

  // TODO: confirm this is true always
  const requiresScreener = options.includes('external_candidates');

  function handleToggle(v: boolean) {
    v ? setOptions([...options, 'screener']) : setOptions(without(options, 'screener'));
  }

  return (
    <div className='py-6 border-b border-gray-200'>
      <div className='flex justify-between'>
        <label htmlFor='screener-toggle' className='h500-bold block'>
          Screener
        </label>
        <TippyOrNot
          content='At least two screener questions are required for external recruitment'
          show={requiresScreener}
        >
          <Toggle
            id='screener-toggle'
            disabled={disabled || requiresScreener}
            testId='screener-toggle'
            name='Screener'
            on={options.includes('screener')}
            onToggle={handleToggle}
          />
        </TippyOrNot>
      </div>
      <span className='h400 block text-gray-500'>
        Add a screener if you want to ask people questions before deciding to invite them.
      </span>

      {options.includes('screener') && study.pre_screener?.id && (
        <Button
          disabled={disabled}
          className='mt-4'
          medium
          name='Set up screener'
          onClick={() => navigate(`/studies/${study.id}${study.state === 'draft' ? '/edit' : ''}/screener`)}
        >
          Set up screener
        </Button>
      )}
      {hasScreenerInviteCta && study.has_screener && (
        <div className='mt-4'>
          <span className='h400 block mb-2 text-gray-500'>
            Where should the URLs for the screener invite CTA point to?
          </span>
          <Select<Study['screener_invite_cta']>
            className='max-w-xs'
            options={[
              { label: 'Landing page', value: 'landing_page' },
              { label: 'Screener form', value: 'form' }
            ]}
            value={study.screener_invite_cta}
            onChange={(v) => change?.('screener_invite_cta', v)}
          />
        </div>
      )}
    </div>
  );
};
