import { AiChatPage } from '../types';

type PathRegex = {
  re: RegExp;
  page: AiChatPage;
  constructPath: (id: string) => string;
};

const PATH_REGEXES: PathRegex[] = [
  {
    re: /^\/studies\/(\d+)/,
    page: 'study',
    constructPath: (id: string) => `/studies/${id}`
  },
  {
    re: /^\/sessions\/(\w+)/,
    page: 'interview',
    constructPath: (id: string) => `/sessions/${id}`
  }
];

export function getContextObjFromUrl(url: string): AiChatContext | null {
  for (const { re, page } of PATH_REGEXES) {
    const match = url.match(re);
    if (match) {
      return { id: match[1], context_type: page };
    }
  }
  return null;
}

export function getUrlFromContextObj(context: AiChatContext): string | null {
  const pathRegex = PATH_REGEXES.find(({ page }) => page === context.context_type);
  if (pathRegex) {
    return `${pathRegex.constructPath(context.id)}`;
  }
  return null;
}

export function scopeLookup(context: AiChatContext, scopes: AiChatContext[] | undefined): AiChatContext | null {
  return scopes?.find((scope) => scope.id === context.id && scope.context_type === context.context_type) ?? null;
}

export function isValidScope(context: AiChatContext, scopes: AiChatContext[] | undefined): boolean {
  return scopeLookup(context, scopes) ? true : false;
}
