import * as React from 'react';

import { api } from '@api/reduxApi';

interface UseSavedViewHook {
  view: CollectionView | null;
  isLoading: boolean;
  update: (data: CollectionView) => void;
}

export function useDefaultView(scope: SavedView['scope']): UseSavedViewHook {
  const { data: views, isLoading } = api.useGetSavedViewsQuery();
  const [updateDefaultView] = api.useUpdateSavedDefaultViewMutation();

  const savedView: SavedView | null = (views || []).find((v) => v.scope === scope) || null;

  function update(data: CollectionView) {
    updateDefaultView({ scope: scope, data });
  }

  return {
    view: savedView?.data || null,
    isLoading,
    update
  };
}
