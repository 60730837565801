import * as React from 'react';

import { Button, Card, CardTitle, Pill, Text, Tooltip } from '@components/common';
import { PillProps } from '@components/common/Pill';

import { EVENT_TITLES, EVENT_DEFAULT_HEADERS, EVENT_HEADERS, TOOLTIPS, DEFAULT_TOOLTIPS } from './consts';

const Stat: React.FC<{ title: string; tooltip: string; value: number; diffPct?: number }> = ({
  title,
  tooltip,
  value,
  diffPct
}) => {
  const pctValue = diffPct || 0;
  let pillColor: PillProps['color'] = 'gray';
  if (pctValue < 0.3) {
    pillColor = 'red';
  } else if (pctValue < 0.6) {
    pillColor = 'yellow';
  } else {
    pillColor = 'green';
  }

  return (
    <div className='flex-grow px-6'>
      <div className='flex space-x-1'>
        <Text h='400' bold>
          {title}
        </Text>
        <Tooltip content={tooltip} />
      </div>
      <div className='flex'>
        <Text h='700' className='mt-1.5'>
          {value === 0 ? '-' : value}
        </Text>
        {pctValue > 0 && (
          <div className='self-center'>
            <Pill color={pillColor} className='top-0 ml-2'>
              {Math.trunc(pctValue * 100)}%
            </Pill>
          </div>
        )}
      </div>
    </div>
  );
};

type Props = {
  studyId: number;
  event: string;
  stats: StudyStats;
  showTitle?: boolean;
};

export const EventStats: React.FC<Props> = ({ studyId, event, stats, showTitle = false }) => {
  const headers = EVENT_HEADERS[event] || EVENT_DEFAULT_HEADERS;

  const tooltips = { ...DEFAULT_TOOLTIPS, ...TOOLTIPS[event] };

  return (
    stats && (
      <Card className='mb-4'>
        {showTitle && (
          <div className='flex'>
            <div className='flex-1'>
              <CardTitle className='mb-6'>{EVENT_TITLES[event]}</CardTitle>
            </div>
            <div>
              <Button icon='emailSearch' small href={`/studies/${studyId}/stats/${event}`} spa>
                View detail
              </Button>
            </div>
          </div>
        )}
        <div className='flex flex-row -ml-6 divide-x divide-gray-200'>
          <Stat title={headers[0]} value={stats.sent} tooltip={tooltips.sent} />
          <Stat
            title={headers[1]}
            value={stats.opened}
            diffPct={stats.opened / Math.max(stats.sent, 1)}
            tooltip={tooltips.opened}
          />
          <Stat
            title={headers[2]}
            value={stats.clicked}
            diffPct={stats.clicked / Math.max(stats.opened, 1)}
            tooltip={tooltips.clicked}
          />
          <Stat title={headers[3]} value={stats.converted} tooltip={tooltips.converted} />
        </div>
      </Card>
    )
  );
};
