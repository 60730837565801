import React, { SVGProps } from 'react';

export const PlayerMute = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M30.0625 12.7188L23.5 19.2812'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M23.5 12.7188L30.0625 19.2812'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.27625 12.2501H2.875C2.62636 12.2501 2.3879 12.3489 2.21209 12.5247C2.03627 12.7005 1.9375 12.9389 1.9375 13.1876V18.8126C1.9375 19.0612 2.03627 19.2997 2.21209 19.4755C2.3879 19.6513 2.62636 19.7501 2.875 19.7501H8.27625C8.96695 21.5186 10.1323 23.0623 11.6439 24.2111C13.1555 25.3599 14.9548 26.0694 16.8438 26.2613C16.9741 26.2742 17.1056 26.2597 17.23 26.2188C17.3543 26.1778 17.4688 26.1113 17.5659 26.0234C17.663 25.9356 17.7407 25.8285 17.7939 25.7088C17.8471 25.5892 17.8748 25.4598 17.875 25.3288V6.67133C17.875 6.54034 17.8475 6.4108 17.7943 6.29108C17.7411 6.17137 17.6634 6.06412 17.5663 5.97626C17.4691 5.8884 17.3546 5.82188 17.2302 5.78099C17.1057 5.74009 16.9741 5.72573 16.8438 5.73883C14.9548 5.93078 13.1555 6.64022 11.6439 7.78904C10.1323 8.93787 8.96695 10.4815 8.27625 12.2501V12.2501Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
