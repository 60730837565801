import { AvatarFromId, Text } from '@components/common';
import { StudyDetailsList } from '@components/StudiesApp/components/StudyDraft/pages/Assets/StudyDetailsList';
import * as React from 'react';

import * as svgs from '@components/StudiesApp/components/StudyDraft/pages/Assets/svgs';
import { useAccount } from '@hooks/useAccount';

interface Props {
  study: Study;
  landing_page: LandingPage;
}

export const StudyInfo: React.FC<Props> = ({ landing_page, study }) => {
  const {
    account: { name: accountName }
  } = useAccount();

  const { owner } = study;
  const { personal_note, requirements } = landing_page;

  return (
    <div className='flex-2 p-6'>
      {personal_note && (
        <div className='text-left'>
          <div className='flex mb-4'>
            <AvatarFromId userId={owner?.id} size='3xl' />
            {owner && (
              <div className='ml-6'>
                <Text h='600' bold>
                  {owner.name}
                </Text>
                <Text className='text-custom-brand-secondary' h='500' color='gray-500'>
                  {accountName}
                </Text>
                <Text h='400'>{owner.job_title}</Text>
              </div>
            )}
          </div>

          {landing_page && (
            <div className='my-4'>
              <StudyDetailsList study={study} />
              <div className='pt-3' />
              <ul className='h400 space-y-3'>
                {requirements.map((r) => (
                  <li key={r} className='flex items-center'>
                    <div className='flex justify-center w-10'>
                      <svgs.checkBox />
                    </div>
                    {r.replace(/^\s*-\s*/, '')}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className='bg-gray-50 h400 max-w-md p-4 text-left border border-gray-200 rounded-lg'>
            {personal_note.split('\n').map((p, i) => (
              <div key={i}>
                <p className='my-1'>{p}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
