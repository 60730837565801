import { useMemo } from 'react';
import { useFeature } from '@hooks/useFeature';

import { HIDDEN_BLOCK_TYPES, LOCKED_BLOCK_TYPES } from '../constants';
import * as Enums from '../types/enums';

export const useGetAvailableBlockKinds = (): `${Enums.Kind}`[] => {
  const cardSortEnabled = useFeature('unmoderated_card_sort');

  const kinds: `${Enums.Kind}`[] = useMemo(() => {
    const available: `${Enums.Kind}`[] = [];

    Object.values(Enums.Kind).forEach((kind) => {
      if (kind === Enums.Kind.cardSort && !cardSortEnabled) return;

      if (LOCKED_BLOCK_TYPES.includes(kind) || HIDDEN_BLOCK_TYPES.includes(kind)) {
        return;
      }

      available.push(kind);
    });

    return available;
  }, [cardSortEnabled]);

  return kinds;
};
