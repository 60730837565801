import React, { SVGProps } from 'react';

export const PictureSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.6875 0.4375H1.3125C0.829251 0.4375 0.4375 0.829251 0.4375 1.3125V12.6875C0.4375 13.1707 0.829251 13.5625 1.3125 13.5625H12.6875C13.1707 13.5625 13.5625 13.1707 13.5625 12.6875V1.3125C13.5625 0.829251 13.1707 0.4375 12.6875 0.4375Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.0625 10.0625L5.082 7.36983C5.1572 7.2695 5.25309 7.1865 5.36317 7.12645C5.47325 7.06641 5.59495 7.03072 5.72002 7.02182C5.84509 7.01291 5.97062 7.03099 6.0881 7.07483C6.20557 7.11867 6.31226 7.18725 6.40092 7.27592L7 7.875L8.925 5.30833C9.0065 5.19966 9.11219 5.11146 9.23369 5.05071C9.35519 4.98996 9.48916 4.95833 9.625 4.95833C9.76084 4.95833 9.89481 4.98996 10.0163 5.05071C10.1378 5.11146 10.2435 5.19966 10.325 5.30833L11.8825 7.385'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.71875 4.8125C4.32281 4.8125 4.8125 4.32281 4.8125 3.71875C4.8125 3.11469 4.32281 2.625 3.71875 2.625C3.11469 2.625 2.625 3.11469 2.625 3.71875C2.625 4.32281 3.11469 4.8125 3.71875 4.8125Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.4375 10.0625H13.5625'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
