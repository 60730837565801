import { Extension } from '@tiptap/core';

import { TextSelection as TextSelectionPlugin } from '../plugins';

export const TextSelection = Extension.create({
  name: 'textSelection',

  addProseMirrorPlugins() {
    return [TextSelectionPlugin()];
  }
});
