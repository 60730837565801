import { DOMElement, useEffect, useState } from 'react';

// https://usehooks.com/useWindowSize/
export function useWindowSize(ref?: React.RefObject<HTMLElement>): { width?: number; height?: number } {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<{ width?: number; height?: number }>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: ref?.current ? ref?.current.offsetWidth : window.innerWidth,
        height: ref?.current ? ref?.current.offsetWidth : window.innerHeight
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => (ref?.current || window).removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
