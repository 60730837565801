import * as React from 'react';

export const AlertTriangleIcon: React.FC<{ className?: string }> = ({ className = '' }) => (
  <svg
    className={`stroke-current ${className}`}
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11 17.1875C10.932 17.1875 10.8656 17.2077 10.809 17.2454C10.7525 17.2832 10.7084 17.3369 10.6824 17.3997C10.6564 17.4625 10.6496 17.5316 10.6629 17.5983C10.6761 17.665 10.7089 17.7262 10.7569 17.7743C10.805 17.8224 10.8663 17.8551 10.9329 17.8684C10.9996 17.8817 11.0687 17.8749 11.1315 17.8488C11.1944 17.8228 11.248 17.7788 11.2858 17.7222C11.3236 17.6657 11.3437 17.5992 11.3437 17.5312C11.3437 17.4401 11.3075 17.3526 11.2431 17.2882C11.1786 17.2237 11.0912 17.1875 11 17.1875Z'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M11 14.4375V7.5625' stroke='currentColor' strokeWidth='1.375' strokeLinecap='round' />
    <path
      d='M12.4859 1.61333C12.3495 1.33559 12.138 1.10165 11.8753 0.938044C11.6127 0.774437 11.3094 0.687714 11 0.687714C10.6906 0.687714 10.3873 0.774437 10.1247 0.938044C9.86205 1.10165 9.65052 1.33559 9.51409 1.61333L0.830509 19.3032C0.725981 19.5158 0.677367 19.7516 0.689257 19.9882C0.701146 20.2248 0.773146 20.4546 0.898459 20.6556C1.02377 20.8567 1.19827 21.0226 1.40547 21.1375C1.61267 21.2524 1.84574 21.3127 2.08268 21.3125H19.9173C20.1543 21.3127 20.3874 21.2524 20.5946 21.1375C20.8018 21.0226 20.9762 20.8567 21.1016 20.6556C21.2269 20.4546 21.2989 20.2248 21.3108 19.9882C21.3226 19.7516 21.274 19.5158 21.1695 19.3032L12.4859 1.61333Z'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
