import { GoogleMeetSVG, LinkSVG, LocationSVG, WebexSVG, ZoomSVG, MicrosoftTeamsSVG } from '@components/svgs';
import React from 'react';

export const PROVIDERS: Record<
  Study['video_url_type'],
  {
    name: string;
    icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  }
> = {
  zoom: {
    name: 'Zoom',
    icon: ZoomSVG
  },
  google_meet: {
    name: 'Google Meet',
    icon: GoogleMeetSVG
  },
  webex: {
    name: 'Webex',
    icon: WebexSVG
  },
  microsoft_teams: {
    name: 'Microsoft Teams',
    icon: MicrosoftTeamsSVG
  },
  in_person: {
    name: 'In Person',
    icon: LocationSVG
  },
  manual: {
    name: 'Manual',
    icon: LinkSVG
  }
};
