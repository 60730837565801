import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { DataRetentionIndex } from './DataRetentionIndex';

export const AccountDataRetentionApp: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path='data_retention' element={<DataRetentionIndex />} />
      </Routes>
    </Router>
  );
};
