import cn from 'classnames';
import * as React from 'react';

import { without } from '@components/utils';

export type CheckboxesGroupOption = {
  label: string;
  helper: string;
  value: string;
  extra?: any;
  name?: string;
  disabled?: boolean;
  className?: string;
};
interface CheckboxesGroupProps {
  options: CheckboxesGroupOption[];
  selected: string[];
  disabled?: boolean;
  borderColor?: string;
  onChange: (selected: string[]) => void;
  disableRule?: (v) => boolean;
}

function optionClass(i, length) {
  if (i === 0) {
    return 'rounded-tl-md rounded-tr-md';
  } else if (i === length - 1) {
    return 'rounded-bl-md rounded-br-md';
  } else {
    return '';
  }
}

interface CheckboxProps<T extends any = any> {
  id?: string;
  value?: T;
  name?: string;
  disabled?: boolean;
  selected: boolean;
  onChange?: (selected: boolean) => void;
  className?: string;
  register?: any;
}
export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  value,
  name,
  disabled = false,
  selected,
  onChange,
  className,
  register
}) => {
  return (
    <div className='flex items-center h-5'>
      <input
        id={id}
        type='checkbox'
        data-testid='checkbox'
        aria-label={value}
        value={value}
        name={name}
        ref={register}
        disabled={disabled}
        readOnly={!onChange}
        onChange={onChange ? () => onChange(!selected) : undefined}
        checked={selected}
        className={cn(
          'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300',
          { 'cursor-not-allowed': disabled, 'cursor-pointer': !disabled },
          className
        )}
      />
    </div>
  );
};

export const CheckboxesGroup: React.FC<CheckboxesGroupProps> = ({
  options,
  selected,
  disabled: allDisabled,
  onChange,
  borderColor = 'gray-200',
  disableRule
}) => {
  return (
    <div className={` rounded-md -space-y-px bg-white ${allDisabled ? 'opacity-75' : ''}`}>
      {options.map(({ label, helper, value, name, extra, disabled, className = '' }, i) => (
        <div
          key={label}
          className={`${className} ${
            disabled ? 'opacity-50' : ''
          } relative border p-4 flex border-${borderColor} ${optionClass(i, options.length)}`}
        >
          <label
            className={`space-x-3 flex flex-row ${disabled || allDisabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          >
            <Checkbox
              value={value}
              name={name}
              disabled={disabled || allDisabled || disableRule?.(value)}
              selected={selected.includes(value)}
              onChange={(v) => (v ? onChange([...selected, value]) : onChange(without(selected, value)))}
            />
            <div className='flex flex-col'>
              <span className='h400-medium block font-bold'>{label}</span>
              <span className='h400 block mt-1 text-gray-500'>{helper}</span>
              {extra}
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};
