import { api } from '@api/reduxApi';

const studiesCalendarApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateStudyCalendar: builder.mutation<StudyCalendar, { studyId: number; calendar: Partial<StudyCalendar> }>({
      query: ({ studyId, calendar }) => ({
        url: `/studies/${studyId}/calendar`,
        method: 'PUT',
        body: { calendar: calendar }
      }),
      invalidatesTags: (q, i, { studyId }) => [{ type: 'StudyCalendar', id: studyId }]
    }),
    getStudyCalendar: builder.query<StudyCalendar, { studyId: number }>({
      query: ({ studyId }) => `/studies/${studyId}/calendar`,
      providesTags: (q, i, { studyId }) => [{ type: 'StudyCalendar', id: studyId }]
    }),
    getMeetingProviders: builder.query<MeetingProvider[], { studyId: number }>({
      query: ({ studyId }) => `/studies/${studyId}/meeting_providers`
    })
  })
});

export const { useUpdateStudyCalendarMutation, useGetStudyCalendarQuery, useGetMeetingProvidersQuery } = studiesCalendarApi;
