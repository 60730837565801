import { Button, Modal } from 'components/common';
import React from 'react';

export const InvalidIncentiveModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Modal
      size='md'
      title='Incentive cannot be modified'
      onClose={onClose}
      renderFooter={() => <Button onClick={onClose}>Close</Button>}
    >
      As there are candidates that have already participated in your study, the incentive can not be modified.
    </Modal>
  );
};
