import * as React from 'react';
import { useState } from 'react';

import { Button, Text } from '@components/common';
import { RequestUpgradeModal } from '@components/RoleRequests/RequestUpgradeModal';
import { NewStudyAction } from '@components/StudiesApp/components/StudyNew';
import { useDeviceType } from '@hooks/useDeviceType';
import Tippy from '@tippyjs/react';

const PermissionsTip: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <div className='p-2'>
      <Text h='500' bold className='mt-2 mb-2'>
        Request upgrade to access
      </Text>
      <Text h='400' className='leading-normal'>
        You’re an <b>Observer</b> on this account. To use more functionality and features, you can request an upgrade
        from your account administrator.
      </Text>
      <Button primary className='mt-4' onClick={onClick}>
        Request upgrade
      </Button>
    </div>
  );
};

export const CTA: React.FC<{ permission: boolean }> =
  ({ permission }) => {
    const { isMobile } = useDeviceType();
    const [modal, setModal] = useState<'upgrade' | null>();
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const showUpgradeModal = () => {
      setModal('upgrade');
    };

    const handleRequestSuccess = () => {
      setModal(null);
    };

    if (!permission) {
      return (
        <>
          <Tippy
            disabled={isMobile}
            maxWidth={400}
            interactive={true}
            arrow={false}
            content={<PermissionsTip onClick={showUpgradeModal} />}
            className='custom-tippy text-left text-gray-700 bg-white rounded-md shadow'
          >
            <span>
              <Button medium inactive iconSuffix='plus' fullWidth>
                New study
              </Button>
            </span>
          </Tippy>
          {modal == 'upgrade' && (
            <RequestUpgradeModal onCancel={() => setModal(null)} onSuccess={handleRequestSuccess} />
          )}
        </>
      );
    } else {
      return (
        <Tippy
          disabled={isMobile}
          maxWidth={400}
          interactive={true}
          arrow={false}
          content='New Study (N then S)'
          placement='right'
        >
          <NewStudyAction>
            {({ openSlideout }) => (
              <Button medium ref={buttonRef} primary iconSuffix='plus' onClick={() => openSlideout()} fullWidth>
                New study
              </Button>
            )}
          </NewStudyAction>
        </Tippy>
      );
    }
  };
