import React, { SVGProps } from 'react';

export const LanguageIconSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none' {...props}>
      <path
        d='M3.75 6.875V4.375C3.75 4.04348 3.8817 3.72554 4.11612 3.49112C4.35054 3.2567 4.66848 3.125 5 3.125C5.33152 3.125 5.64946 3.2567 5.88388 3.49112C6.1183 3.72554 6.25 4.04348 6.25 4.375V6.875'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M3.75 5.625H6.25' stroke='#6B7280' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.75 8.75V10' stroke='#6B7280' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.25 10H16.25' stroke='#6B7280' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15 10C15 10 13.75 13.75 11.25 13.75'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.75 12.3057C14.0287 12.7213 14.399 13.0676 14.8324 13.3179C15.2657 13.5683 15.7507 13.716 16.25 13.7498'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.375 15.625C9.04348 15.625 8.72554 15.4933 8.49112 15.2589C8.2567 15.0245 8.125 14.7065 8.125 14.375V8.125C8.125 7.79348 8.2567 7.47554 8.49112 7.24112C8.72554 7.0067 9.04348 6.875 9.375 6.875H18.125C18.4565 6.875 18.7745 7.0067 19.0089 7.24112C19.2433 7.47554 19.375 7.79348 19.375 8.125V14.375C19.375 14.7065 19.2433 15.0245 19.0089 15.2589C18.7745 15.4933 18.4565 15.625 18.125 15.625H16.875V19.375L13.125 15.625H9.375Z'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.625 10.625L3.125 13.125V9.375H1.875C1.54348 9.375 1.22554 9.2433 0.991117 9.00888C0.756696 8.77446 0.625 8.45652 0.625 8.125V1.875C0.625 1.54348 0.756696 1.22554 0.991117 0.991117C1.22554 0.756696 1.54348 0.625 1.875 0.625H10.625C10.9565 0.625 11.2745 0.756696 11.5089 0.991117C11.7433 1.22554 11.875 1.54348 11.875 1.875V4.375'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
