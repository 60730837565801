import { useUser } from 'hooks/useUser';
import React, { useState } from 'react';

import { Button, Loading } from '@components/common';
import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

interface Props {
  cta?: string;
  onSuccess: () => void;
}
export const PaymentDetails: React.FC<Props> = ({ onSuccess, cta = 'Submit' }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { email } = useUser();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError(submitError);
      return;
    }

    const host = window.location.origin;
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${host}/billing/setup_intent_status`
      }
    });

    if (error) {
      handleError(error);
      return;
    }

    onSuccess();
  };

  return (
    <form onSubmit={handleSubmit} className='relative'>
      {loading && <Loading absolute />}
      <div className='mb-2'>
        <LinkAuthenticationElement
          options={{
            defaultValues: {
              email
            }
          }}
        />
      </div>
      <PaymentElement />
      <Button primary className='mt-2'>
        {cta}
      </Button>
      {errorMessage && (
        <p role='alert' aria-live='assertive' className='mt-1 text-red-600'>
          {errorMessage}
        </p>
      )}
    </form>
  );
};
