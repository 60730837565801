import * as React from 'react';
import { useState } from 'react';

import { ArrowButton } from '@components/ScreenerResults/ScreenerChart/components/ArrowButton';

export const TextCarousel: React.FC<{ data: any[]; total: number }> = ({ data, total }) => {
  const [index, setIndex] = useState(0);

  const currentAnswer = data[index];
  const canPrev = index > 0;
  const canNext = index + 1 < total;

  return (
    <div className='w-full max-w-3xl'>
      <div className='bg-indigo-50 font-xl relative flex flex-col items-start justify-center h-48 px-12 text-gray-700'>
        <span className='absolute top-0 left-0 mt-4 ml-4 text-4xl text-indigo-600'>“</span>
        <span className='absolute bottom-0 right-0 mb-4 mr-4 text-4xl text-indigo-600 transform rotate-180'>“</span>
        <div className='w-full max-h-full py-4 overflow-y-auto'>
          {currentAnswer ? currentAnswer.split('\n').map((a) => <p key={a}>{a}</p>) : <p>No response</p>}
        </div>
      </div>

      {total > 1 && (
        <div className='flex flex-row items-center justify-end mt-4 space-x-4'>
          <ArrowButton type='prev' disabled={!canPrev} onClick={() => canPrev && setIndex(index - 1)} />
          <p className='text-sm text-gray-500'>
            {index + 1} of {total}
          </p>
          <ArrowButton type='next' disabled={!canNext} onClick={() => canNext && setIndex(index + 1)} />
        </div>
      )}
    </div>
  );
};
