import { Extension } from '@tiptap/core';

import { HighlightWidget as HighlightWidgetPlugin } from '../plugins';

export const HighlightWidget = Extension.create({
  name: 'highlightWidget',

  addProseMirrorPlugins() {
    return [HighlightWidgetPlugin({ editor: this.editor })];
  }
});
