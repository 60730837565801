import React from 'react';

import { Text, Tooltip } from '@components/common';

import { RecordingZone } from './RecordingZone';

export const StartRecording: React.FC = () => {
  return (
    <RecordingZone show top className='pt-3 pb-4'>
      <div className='flex flex-row items-center space-x-2'>
        <div className='w-3 h-3 bg-red-600 rounded-full' />
        <Text h='400'>Recorded zone</Text>
        <Tooltip>Configure the questions that are captured with screen recording.</Tooltip>{' '}
      </div>
    </RecordingZone>
  );
};
