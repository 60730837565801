export type Tab = 'recurring' | 'date_specific';

export const TABS: Tab[] = ['recurring', 'date_specific'];

export const TAB_LABELS: Record<Tab, string> = {
  recurring: 'Recurring hours',
  date_specific: 'Date-specific hours'
};

export const DAYS_SHORT_TEXT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];


export const DAYS_SHORT_FROM_SUNDAY = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
