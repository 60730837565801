import { getFormKey } from 'components/Public/GQSurvey/helpers/getFormKey';
import * as React from 'react';

import { rangeMap } from '@components/utils';

import { CommonProps } from '../types';
import { FieldError } from 'react-hook-form';

export const NumberRangeInput: React.FC<CommonProps & { error?: FieldError }> = ({
  field,
  field: { id, required, number_range_options },
  register,
  watch,
  error,
  autofocus
}) => {
  if (!number_range_options) return null; // at least dont break
  const { min, max, labels } = number_range_options;

  const labelName = getFormKey(field);

  const selected = watch(labelName);
  if (min >= max) return null;

  const options = rangeMap(min, max, (i) => ({ label: labels[i], value: i }));

  return (
    <fieldset
      className={`grid grid-cols-${options.length}`}
      role='radiogroup'
      aria-labelledby={String(id)}
      id={`radiogroup${String(id)}`}
    >
      <legend className='sr-only'>{field.label}</legend>
      {options.map(({ label, value }, i) => (
        <div className='flex flex-col items-center' key={value}>
          <span className='text-custom-brand text-sm'>{value}</span>
          <input
            aria-checked={value === parseInt(selected)}
            id={`${String(id)}${label}`}
            className='focus-ring-custom-brand mb-4 input-custom-brand'
            type='radio'
            name={labelName}
            value={value}
            autoFocus={i === 0 && autofocus}
            ref={register({ required })}
            aria-describedby={`description-for-${id}`}
            aria-labelledby={`${String(id)}${label}`}
          />
          {label && (
            <label
              htmlFor={`${String(id)}${label}`}
              id={`${String(id)}${label}`}
              className='text-custom-brand text-sm text-center'
            >
              {label}
            </label>
          )}
        </div>
      ))}
    </fieldset>
  );
};
