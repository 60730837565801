import { useMemo } from 'react';

import { useGetTeamsQuery } from '@components/AccountTeamsApp/api';

type Params = {
  skip?: boolean;
  includeWallet?: boolean;
};

type Hook = {
  teams: Team[] | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  findTeam: (id: number) => Team | undefined;
};

export const useTeams = (params?: Params): Hook => {
  const queryParams = params?.skip ? { skip: true } : undefined;

  const { data: teams, isLoading, isSuccess, isError } = useGetTeamsQuery({ includeWallet: !!params?.includeWallet }, queryParams);

  const findTeam = useMemo<Hook['findTeam']>(
    () => (id) => {
      return teams?.find((team) => team.id === id);
    },
    [teams]
  );

  return {
    teams,
    isLoading,
    isSuccess,
    isError,
    findTeam
  };
};
