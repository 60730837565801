import React, { SVGProps } from 'react';

export const FilterSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_3_6657)'>
      <path
        d='M5.65846 6.88346V13.1251C5.65846 13.3585 5.83346 13.5335 6.0668 13.5918H7.8168C8.05013 13.5918 8.28346 13.4168 8.28346 13.1251V6.88346C11.1418 6.30013 13.3001 3.85013 13.5335 0.933464C13.5335 0.70013 13.3585 0.466797 13.1251 0.466797H0.87513C0.641797 0.466797 0.466797 0.641797 0.466797 0.933464C0.641797 3.85013 2.80013 6.30013 5.65846 6.88346Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3_6657'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
