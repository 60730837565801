import * as React from 'react';
import tinytime from 'tinytime';

import { AttrValue } from './';

const dateTemplate = tinytime('{Do} {MMMM} {YYYY}');

export const DateValue: React.FC<AttrValue> = ({ value }) => {
  const date = new Date(value);
  const isValidDate = !Number.isNaN(date.valueOf());
  const dateString = isValidDate ? dateTemplate.render(date) : '';
  return <>{dateString}</>;
};
