import { useToaster } from 'components/stores/toaster';
import * as React from 'react';
import { forwardRef, useEffect } from 'react';

import { Button, Props as ButtonProps } from './Button';

interface Props extends ButtonProps {
  isSuccess?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  successMessage: string;
  errorMessage: string;
  onClick: () => void;
}

export const CreateCsvExportButton = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, isSuccess, isError, isLoading, successMessage, errorMessage, ...rest }, ref) => {
    const showToast = useToaster();

    useEffect(() => {
      if (!isSuccess) return;

      showToast({ icon: 'success', heading: 'Done!', text: successMessage });
    }, [isSuccess]);

    useEffect(() => {
      if (!isError) return;

      showToast({ icon: 'error', heading: 'Failed to export!', text: errorMessage });
    }, [isError]);

    return (
      <Button ref={ref} iconSuffix='export' loading={isLoading} disabled={isLoading} onClick={onClick} {...rest}>
        Export to CSV
      </Button>
    );
  }
);
