import React, { createContext } from 'react';

import { useDraggableCard, DraggableCard, State } from '../hooks/useDraggableCard';

export const DraggableCardContext = createContext<DraggableCard | undefined>(undefined);

export const DraggableCardProvider: React.FC<{ value?: Partial<State> }> = ({ children, value }) => {
  const draggableCard = useDraggableCard(value);

  return <DraggableCardContext.Provider value={draggableCard}>{children}</DraggableCardContext.Provider>;
};

DraggableCardContext.displayName = 'DraggableCardContext';
