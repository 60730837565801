import { DropdownLink } from 'components/common';
import React from 'react';
import { MemberDropdown } from './MemberDropdown';
import cn from 'classnames';
import { Role } from '../TeamUserRow';

interface Props {
  isStudyOwner?: boolean;
  isStudyCreator?: boolean;
  isCurrentUser?: boolean;
  onRemove?: () => void;
  isAdmin?: boolean;
  setOwner: () => void;
  studyRole: Role;
}

export const CanEditDropdowns: React.FC<Props> = ({
  isStudyOwner,
  isStudyCreator,
  isCurrentUser,
  onRemove,
  isAdmin,
  setOwner,
  studyRole
}) => {
  return isStudyOwner ? (
    <div className='px-4 w-32 text-sm text-gray-700'>{studyRole}</div>
  ) : isStudyCreator ? (
    <MemberDropdown studyRole={studyRole}>
      <div>
        {isAdmin && (
          <DropdownLink aria-label='Set as owner' onClick={setOwner}>
            Set as owner
          </DropdownLink>
        )}
        <DropdownLink onClick={onRemove} color='red-600'>
          Leave
        </DropdownLink>
      </div>
    </MemberDropdown>
  ) : (
    <MemberDropdown studyRole={studyRole}>
      <div className={cn('px-4', isCurrentUser && 'border-b')}>
        <p className='py-2 text-sm text-gray-700'>
          To give creator or owner access, <a>upgrade role permissions</a>
        </p>
      </div>
      <DropdownLink color='red-600' onClick={onRemove}>
        Remove from study
      </DropdownLink>
    </MemberDropdown>
  );
};
