import React from 'react';
import tinytime from 'tinytime';

import { Button } from '@components/common';
import { makeStatesFromProxies } from '@components/shared/TableFilters/components/segments/utils';
import { UseTableFilters } from '@components/shared/TableFilters/hook/useTableFilters';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

interface Props {
  readOnly?: boolean;
  segment: CustomerSegment;
  setSelected: React.Dispatch<React.SetStateAction<CustomerSegment | null>>;
  setDeleteModalOpen: (v: boolean) => void;
  setSegmentSlideoutOpen: (v: boolean) => void;
  filtersHook: UseTableFilters<Candidate>;
}

export const SegmentRow: React.FC<Props> = ({
  readOnly,
  segment,
  setSelected,
  setDeleteModalOpen,
  setSegmentSlideoutOpen,
  filtersHook
}) => {
  const getFilters = (filters: ProxyFilterState[]): string => {
    const states = makeStatesFromProxies(filtersHook.definitions, filters, filtersHook.filterMetas);
    return states.map((state) => state.definition.name).join(', ');
  };

  const segmentFilters = makeStatesFromProxies(
    filtersHook.definitions,
    segment.filters.filters,
    filtersHook.filterMetas
  );

  return (
    <tr className='group text-sm text-gray-700'>
      <td className='p-3 pl-0'>{segment.name}</td>
      <td className='p-3'>{dateTemplate.render(segment.updated_at)}</td>
      <td className='p-3'>{getFilters(segment.filters.filters)}</td>
      <td />
      <td className='flex justify-end space-x-2'>
        {!readOnly && (
          <>
            <Button
              rounded
              link
              data-testid='edit'
              onClick={() => {
                setSelected(segment);
                filtersHook.setFilters(segmentFilters);
                setSegmentSlideoutOpen(true);
              }}
              icon='pencil'
            />
            <Button
              rounded
              link
              data-testid='delete'
              onClick={() => {
                setSelected(segment);
                setDeleteModalOpen(true);
              }}
              icon='trash'
            />
          </>
        )}
      </td>
    </tr>
  );
};
