import React, { SVGProps } from 'react';

export const DragSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='14' cy='8' r='1' transform='rotate(90 14 8)' fill='currentColor' />
      <circle cx='10' cy='8' r='1' transform='rotate(90 10 8)' fill='currentColor' />
      <circle cx='14' cy='12' r='1' transform='rotate(90 14 12)' fill='currentColor' />
      <circle cx='10' cy='12' r='1' transform='rotate(90 10 12)' fill='currentColor' />
      <circle cx='14' cy='16' r='1' transform='rotate(90 14 16)' fill='currentColor' />
      <circle cx='10' cy='16' r='1' transform='rotate(90 10 16)' fill='currentColor' />
    </svg>
  );
};
