import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getFormKey } from '../helpers/getFormKey';
import { InputProps } from '../types';

export const SingleChoiceInput: React.FC<InputProps> = ({ autofocus, field, watch, onFocus, register, error }) => {
  const { id, required, options = [], other } = field;
  const { t } = useTranslation('SurveyQuestion');
  const name = id.toString();
  const labelName = getFormKey(field);

  const selected = watch(labelName);

  return (
    <fieldset role='radiogroup' aria-labelledby={String(id)} id={`radiogroup${String(id)}`}>
      <legend className='sr-only'>{field.label}</legend>
      {options
        .filter((e) => e != '')
        .map((o, i) => (
          <div key={o} className='flex items-center'>
            <input
              id={`${String(id)}${o}`}
              type='radio'
              className='focus-ring-custom-brand w-4 h-4 mr-2 input-custom-brand border-gray-400'
              name={`${name}.label`}
              value={o}
              autoFocus={i === 0 && autofocus}
              ref={register({ required })}
              aria-checked={selected === o}
              aria-describedby={`description-for-${id}`}
              aria-labelledby={`${String(id)}${o}`}
            />
            <label
              id={`${String(id)}${o}`}
              htmlFor={`${String(id)}${o}`}
              onFocus={onFocus}
              className='py-2 text-base font-normal normal-case cursor-pointer'
            >
              {t(o)}
            </label>
          </div>
        ))}

      {other && (
        <div className='flex items-center'>
          <input
            type='radio'
            id={`${String(id)}${name}-other`}
            className='focus-ring-custom-brand w-4 h-4 mr-2 input-custom-brand border-gray-400'
            name={`${name}.label`}
            value='other'
            ref={register({ required })}
            aria-checked={selected === 'other'}
            aria-labelledby={`${String(id)}${name}-other`}
          />
          <label
            htmlFor={`${String(id)}${name}-other`}
            id={`${String(id)}${name}-other`}
            onFocus={onFocus}
            className='py-2 text-base font-normal normal-case cursor-pointer'
          >
            {t('other')}
          </label>
        </div>
      )}

      {selected === 'other' && (
        <input
          type='text'
          className='rounded-d block w-full px-10 py-3 mt-2 border border-gray-200'
          placeholder={t('please_specify')}
          onFocus={onFocus}
          autoFocus
          name={`${name}.other`}
          ref={register({ required: required || selected === 'other' })}
          aria-invalid={error ? 'true' : 'false'}
        />
      )}
    </fieldset>
  );
};

export const BooleanInput: React.FC<InputProps> = (props) => {
  return (
    <div>
      <SingleChoiceInput {...props} field={{ ...props.field, options: ['Yes', 'No'] }}></SingleChoiceInput>
    </div>
  );
};
