import * as React from 'react';
import { useState } from 'react';

export type UseHover = {
  hover: boolean;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
  hoverRootProps: React.DOMAttributes<any>;
};
export const useHover = (): UseHover => {
  const [hover, setHover] = useState(false);

  return {
    hover,
    setHover,
    hoverRootProps: {
      onMouseEnter: () => setHover(true),
      onMouseLeave: () => setHover(false)
    }
  };
};
