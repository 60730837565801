import React from 'react';

import { humanize } from '@components/utils';

import * as svgs from '../components/svgs';
import { FilterDefinition, FilterType, PartialFilterDefinition } from '../types';
import { getDefaultFunc } from '../utils/getDefaultFunc';
import { getDefaultOperator } from '../utils/getDefaultOperator';

const FILTER_ICONS: Partial<Record<FilterType, React.FC>> = {
  text: svgs.text,
  free_text: svgs.text,
  number: svgs.number,
  boolean: svgs.boolean,
  date: svgs.date,
  multiple_choice: svgs.multipleChoice
};

export function buildFilterDefinition<T, U = any>(def: PartialFilterDefinition<T>): FilterDefinition<T, U> {
  const { id, type = 'text', ...rest } = def;
  return {
    id,
    name: humanize(id),
    defaultOperator: getDefaultOperator(type),
    func: (record, state) => {
      // add lookup here? it would need to come from the attribute definition.
      // attr.getValue...
      // This is a fine assumption for now
      let value = record;
      state.definition.id.split('.').forEach((key) => (value = value[key]));
      return getDefaultFunc(value, state);
    },
    type,
    Icon: FILTER_ICONS[type] || svgs.text,
    ...rest
  };
}

export const buildFilterDefinitions = <T>(partials: PartialFilterDefinition<T>[]): FilterDefinition<T>[] =>
  partials.map(buildFilterDefinition);
