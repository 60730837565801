import { useState } from 'react';
import { useWebsocket, RecordWithId } from '@hooks/useWebsocket';

export const useRecordSync = <Type extends RecordWithId>(record: Type, channel: string): Type => {
  const [syncedRecord, setRecord] = useState(record);

  useWebsocket<Type>({
    channel: channel,
    record: syncedRecord,
    onReceived: setRecord
  });

  return syncedRecord;
};
