import { useEffect, useRef } from 'react';

interface Args {
  player: HTMLVideoElement | undefined;
  callback?: (seconds: number) => void;
}

export const useFreqTimeUpdate = ({ player, callback }: Args): void => {
  const intervalRef = useRef<NodeJS.Timeout | null>();
  const savedCallback = useRef<Args['callback']>();

  const handleTimeUpdate = () => {
    if (!player || !savedCallback.current) return;

    intervalRef.current = setInterval(() => {
      savedCallback.current?.(player.currentTime);
    }, 100) as any;
  };

  const handlePlayerStop = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (player) {
      player.addEventListener('play', handleTimeUpdate);
      player.addEventListener('pause', handlePlayerStop);
      player.addEventListener('ended', handlePlayerStop);

      return () => {
        player.removeEventListener('play', handleTimeUpdate);
        player.removeEventListener('pause', handlePlayerStop);
        player.removeEventListener('ended', handlePlayerStop);
      };
    }
  }, [player, callback]);
};
