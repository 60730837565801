import React, { SVGProps } from 'react';

export const PlayerFullScreen = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_797:179)'>
      <path
        d='M13.1875 18.8101L1.9375 30.0601'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.0625 10.3726V1.93506H21.625'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.9375 21.6226V30.0601H10.375'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.0625 1.93506L18.8125 13.1851'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.8125 18.8101L30.0625 30.0601'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.9375 10.3726V1.93506H10.375'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M30.0625 21.6226V30.0601H21.625'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.9375 1.93506L13.1875 13.1851'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_797:179'>
        <rect width='30' height='30' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
