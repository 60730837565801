import { FeatureBanner } from '@components/shared/FeatureBanner/FeatureBanner';
import { FeatureType } from './constants';
import React from 'react';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { StudySlideOut } from '@components/StudiesApp/types';
import cn from 'classnames';

interface Props {
  types: FeatureType[];
  source: 'dashboard' | 'dashboard_zds';
  setSlideOut?: (v: StudySlideOut | null) => void;
}

export const FeatureBannerStack: React.FC<Props> = ({ types, source, setSlideOut }) => {
  const [dismissedFeatures, setDismissedFeatures] = useLocalStorage<FeatureType[]>('feature-banners-dismissed');
  const notDismissed = types.filter((type) => !dismissedFeatures?.includes(type));

  if (!notDismissed.length) return null;

  return (
    <div className='mb-4'>
      {notDismissed.map((type, i) => {
        if (i !== notDismissed.length - 1) {
          return (
            <div
              key={type}
              className={cn(
                'h-4 bg-indigo-50 border border-gray-200 rounded-md',
                i !== 0 && `transform -translate-y-${i * 2}`
              )}
            />
          );
        }
        return (
          <FeatureBanner
            setDismissedFeatures={setDismissedFeatures}
            dismissedFeatures={dismissedFeatures}
            setSlideOut={setSlideOut}
            translateY={i * 2}
            key={type}
            type={type}
            source={source}
          />
        );
      })}
    </div>
  );
};
