import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading, ModalSubheading, Select, Text, Tooltip } from '@components/common';
import { track } from '@components/tracking';
import { useTeams } from '@hooks/useTeams';
import { useToaster } from '@stores/toaster';

import * as toasts from './toasts';
import { ContactAccess } from '../CandidatesShow/components/ContactAccess';
import { FilterQuery } from './types';

type Props = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  ids?: number[];
  query?: FilterQuery;
};

export const EditContactAccessModal: React.FC<Props> = ({ open, onClose, onSuccess, ids, query }) => {
  const [value, setValue] = useState<'public' | 'needs_team'>('public');
  const [teamIds, setTeamIds] = useState<number[]>([]);

  const showToast = useToaster();

  const count = query?.count || ids?.length;

  const [updateCandidateContactAccess, { isLoading, isSuccess, isError }] =
    api.useUpdateCandidateContactAccessMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.SUCCESS_UPDATE_CONTACT_ACCESS);
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.FAILED_UPDATE_CONTACT_ACCESS);
    }
  }, [isError]);

  useEffect(() => {
    if (open) {
      track('viewed_edit_candidate_contact_access_modal', { count });
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const handleSubmit = () => {
    track('updated_candidate_contact_access', {
      count,
      contact_access: value,
      teams_count: teamIds.length,
      source: 'modal'
    });
    updateCandidateContactAccess({
      contact_access: value,
      team_ids: teamIds,
      candidates: { ids, query }
    });
  };

  return (
    <Modal
      onClose={onClose}
      size='md'
      renderFooter={() => (
        <div className='flex justify-end space-x-4'>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel changes
          </Button>
          <Button loading={isLoading} disabled={isLoading} primary onClick={handleSubmit}>
            Save changes
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-1'>Change contact access</ModalHeading>
      <ModalSubheading className='mb-10'>This determines who will be able to contact the candidates.</ModalSubheading>

      <div className='flex items-center mb-4'>
        <Text bold className='mr-2' h='400'>
          Contact access
        </Text>
        <Tooltip content='Which teams can contact this candidate' />
      </div>
      <div>
        <ContactAccess
          className='max-w-sm'
          initialValue={value}
          initialTeamIds={teamIds}
          onChangeValue={(value) => {
            setValue(value);
          }}
          onChangeTeamIds={(teamIds) => {
            setTeamIds(teamIds);
          }}
        />
      </div>
    </Modal>
  );
};
