import React, { useCallback, useMemo, VFC } from 'react';

import { api } from '@api/reduxApi';
import { uniq } from '@components/utils';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { Table } from '../Table';
import { TableSidebar } from '../TableSidebar';

import { useColumnDefinitions } from './hooks/useColumnDefinitions';
import { useVisibleColumns } from './hooks/useVisibleColumns';
import { useUpdateParticipation } from './hooks/useUpdateParticipation';
import { EDITABLE_COLUMNS, STATIC_COLUMNS } from './constants';
import { buildColumnTabs } from '@components/StudiesApp/components/StudyPublished/utils';
import { MultiselectDropdownItem } from '@components/shared/MultiselectCombobox';
import { useColumnLabels } from './hooks/useColumnLabels';
import ReactSkeleton from 'react-loading-skeleton';

interface Props {
  participations: Participation[];
  study: Study;
  currentPage?: number;
  sortBy?: CollectionView['sort'];
  selectedRowIds?: number[];
  onSelectRowIds?: (rows: number[]) => void;
  updateEntireParticipation?: (participation: Participation) => void;
  onParticipationClick?: (participation: Participation) => void;
  onTimeProposalClick?: (participation: Participation) => void;
  setBackgroundTask: (task: BackgroundTask) => void;
  participationStatus: ParticipationStatus;
  addFilter: (filter: string) => void;
}

export const ParticipationsTable: VFC<Props> = ({
  participations,
  currentPage,
  selectedRowIds = [],
  sortBy,
  study,
  onSelectRowIds,
  updateEntireParticipation,
  onParticipationClick,
  setBackgroundTask,
  participationStatus,
  addFilter,
  onTimeProposalClick
}) => {
  const { candidateAttrs, isLoading } = useCandidateAttrs();

  const { data: studyLimits, isLoading: limitsLoading } = api.useGetStudyLimitsQuery(study.id);
  const { data: studyLimitsProgress, isLoading: limitsProgressLoading } = api.useGetStudyLimitsProgressQuery(study.id, {
    skip: !studyLimits || studyLimits.length < 1
  });

  const partyIdToLimit = useCallback(
    (partyId: number) => {
      const limits = studyLimitsProgress || {};

      const limitId = Object.keys(limits).find((id) => limits[id].participation_ids.includes(partyId));
      const limit = studyLimits?.find((l) => String(l.id) === limitId);
      return limit ? limit.name || `Segment #${limit.order}` : 'No match';
    },
    [studyLimits, studyLimitsProgress]
  );

  const columns = useColumnDefinitions({
    candidateAttrs,
    study,
    onParticipationClick,
    setBackgroundTask,
    participationStatus,
    limits: studyLimits && studyLimits.length > 0,
    addFilter,
    onTimeProposalClick,
    partyIdToLimit
  });

  const columnIds = useMemo<string[]>(() => columns.map(({ id }) => id!), [columns]);
  const columnOrder = useMemo<string[]>(() => uniq(STATIC_COLUMNS.concat(study.visible_attrs)), [study.visible_attrs]);

  const visibleColumns = useVisibleColumns({ columnIds, study });

  const [updateStudy] = api.useUpdateStudyMutation();

  const selectedRows = useMemo<Record<number, boolean>>(() => {
    const selectedRowIdsMap = {};

    for (const id of selectedRowIds) {
      selectedRowIdsMap[id] = true;
    }

    return selectedRowIdsMap;
  }, [selectedRowIds]);

  const updateStudyVisibleAttrs = useCallback(
    (columns: string[] | Record<string, boolean>) => {
      let ids: string[] = [];

      if (Array.isArray(columns)) {
        ids = columns;
      } else {
        ids = Object.keys(columns).filter((key) => columns[key]);
      }

      // TODO: update RTK cache directly for instant UI update
      updateStudy({ id: study.id, visible_attrs: ids }).unwrap();
    },
    [updateStudy, study.id]
  );

  const handleOnSelectRow = (rows: Record<number, boolean>) => {
    const ids: number[] = [];

    for (const key in rows) {
      ids.push(Number(key));
    }

    onSelectRowIds?.(ids);
  };

  const updateCell = useUpdateParticipation({ updateEntireParticipation });

  const editableColumns = useMemo(
    () => [...EDITABLE_COLUMNS, ...candidateAttrs.map(({ name }) => `extra.${name}`)],
    [candidateAttrs]
  );

  const getColumnLabel = useColumnLabels({ study });

  const tabs = useMemo(() => buildColumnTabs(study), [study, buildColumnTabs]);

  const items = useMemo<MultiselectDropdownItem<string>[]>(
    () =>
      columnIds.reduce<MultiselectDropdownItem<string>[]>(
        (acc, id) => (STATIC_COLUMNS.includes(id) ? acc : acc.concat({ label: getColumnLabel(id), value: id })),
        []
      ),
    [columnIds, getColumnLabel]
  );

  const selectedValues = useMemo<string[]>(() => study.visible_attrs, [items, study.visible_attrs]);

  if (isLoading || limitsLoading || limitsProgressLoading) {
    return (
      <div className='w-full min-h-screen'>
        <ReactSkeleton duration={1} className='bg-gray-50 block w-full h-8 rounded-md' count={15} />
      </div>
    );
  }

  return (
    <div className='flex items-stretch flex-1'>
      <div className='relative flex-1 max-w-full overflow-auto border border-t-0 border-gray-200'>
        <Table<Participation>
          id='participations-table'
          className='w-full bg-white border-t border-gray-200 table-fixed'
          data={participations}
          columns={columns}
          stickyColumns={['id', 'name']}
          currentPage={currentPage}
          sortBy={sortBy}
          editableColumns={editableColumns}
          visibleColumns={visibleColumns}
          rowIdentifier='id'
          selectedRows={selectedRows}
          columnOrder={columnOrder}
          onSelectRow={handleOnSelectRow}
          onColumnOrderChange={updateStudyVisibleAttrs}
          onColumnVisibilityChange={updateStudyVisibleAttrs}
          onUpdateCell={updateCell}
        />
      </div>
      <TableSidebar
        className='w-12 bg-white border border-l-0 border-gray-200'
        selectedValues={selectedValues}
        tabs={tabs}
        items={items}
        onVisibileColumnsChange={updateStudyVisibleAttrs}
      />
    </div>
  );
};
