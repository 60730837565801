import React, { VFC } from 'react';
import { GridGallery, GridGalleryBlock } from '@components/common';
import { CategoryCard } from './components/CategoryCard';
import { PlusSVG } from '@components/svgs';
import { Droppable } from 'react-beautiful-dnd';
import { BREAKPOINT_COLS } from './constants';

interface Props {
  canCreate?: boolean;
  categories: { name: string; custom: boolean; cards: string[] }[];
  onCategorySelect: (params: { card: string; newCategory: string; initialCategory?: string }) => void;
  onNameChange: (categoryName: string, value: string) => void;
  onCategoryCreate: () => void;
}

export const Categories: VFC<Props> = ({ onCategoryCreate, canCreate, categories, onCategorySelect, onNameChange }) => {
  return (
    <div className='flex-grow h-full p-4 pl-0 bg-gray-50'>
      <GridGallery breakpointCols={BREAKPOINT_COLS}>
        {categories.map((category) => (
          <GridGalleryBlock key={category.name}>
            <Droppable droppableId={category.name}>
              {(dropProvided, { isDraggingOver }) => (
                <CategoryCard
                  isDraggingOver={isDraggingOver}
                  categories={categories}
                  dropProvided={dropProvided}
                  cards={category.cards || []}
                  category={category}
                  onCategorySelect={onCategorySelect}
                  onNameChange={onNameChange}
                />
              )}
            </Droppable>
          </GridGalleryBlock>
        ))}
        {canCreate && (
          <GridGalleryBlock>
            <button
              type='button'
              aria-label='Create new category'
              onClick={onCategoryCreate}
              className='rounded whitespace-nowrap truncate p-4 text-indigo-600 w-full flex items-center space-x-2 bg-white border border-gray-200'
            >
              <PlusSVG className='flex-shrink-0' />
              <span className='text-indigo-600 no-branding truncate'>New category</span>
            </button>
          </GridGalleryBlock>
        )}
      </GridGallery>
    </div>
  );
};
