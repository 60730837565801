import classNames from 'classnames';
import * as React from 'react';

import { GridViewSVG, ListViewSVG, RowViewSVG } from '@components/svgs';

interface Props {
  value: LayoutMode;
  onChange: (value: LayoutMode) => void;
  withRow?: boolean;
}

export const LayoutToggle: React.FC<Props> = ({ value, onChange, withRow }) => {
  const buttonClasses = (on: boolean) =>
    classNames('flex flex-1 py-1.5 items-center justify-center', {
      'text-gray-700': on,
      'bg-gray-50 text-gray-200 hover:bg-white hover:text-gray-700': !on
    });

  const handleGridClick = () => onChange('grid');

  const handleListClick = () => onChange('list');

  const handleRowClick = () => onChange('row');

  return (
    <div className='flex w-full overflow-hidden bg-white border border-gray-200 divide-x divide-gray-200 rounded-md'>
      <button
        disabled={value === 'grid'}
        name='grid'
        data-testid='grid'
        aria-label='Grid view'
        className={buttonClasses(value === 'grid')}
        onClick={handleGridClick}
      >
        <GridViewSVG />
      </button>
      <button
        disabled={value === 'list'}
        name='list'
        data-testid='list'
        aria-label='List view'
        className={buttonClasses(value === 'list')}
        onClick={handleListClick}
      >
        <ListViewSVG />
      </button>
      {withRow && (
        <button
          aria-label='Row view'
          disabled={value === 'row'}
          name='row'
          data-testid='row'
          className={buttonClasses(value === 'row')}
          onClick={handleRowClick}
        >
          <RowViewSVG />
        </button>
      )}
    </div>
  );
};
