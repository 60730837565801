import { useToaster } from 'components/stores/toaster';
import { uniq } from 'components/utils';
import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Loading, Modal, Text } from '@components/common';

import { BackfillBotId, useCreateBackfillsMutation } from './api';

const BOTS: BackfillBotId[] = ['interview:abstract', 'interview:chapters', 'highlight:title'];
const LABELS: Record<BackfillBotId, string> = {
  'interview:abstract': 'Interview summaries',
  'interview:chapters': 'Interview chapters',
  'highlight:title': 'Highlight titles'
};

export const BackfillsModal: React.FC<{ onClose: () => void; onSuccess: () => void }> = ({ onClose, onSuccess }) => {
  const [createBackfills, { isSuccess, isLoading, isError }] = useCreateBackfillsMutation();
  const [bots, setBots] = useState<BackfillBotId[]>(BOTS);
  const showToast = useToaster();

  function toggleBot(bot: BackfillBotId, selected: boolean) {
    if (selected) {
      setBots(uniq([...bots, bot]));
    } else {
      setBots(bots.filter((b) => b !== bot));
    }
  }

  function onSubmit() {
    createBackfills(bots);
  }
  useEffect(() => {
    if (isSuccess) {
      showToast({
        heading: 'Backfilling data',
        text: 'We have queued a job to backfill your data. This will be completed shortly',
        icon: 'success'
      });
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'An error occurred',
        text: 'Something went wrong backfilling data. Please try again later',
        icon: 'error'
      });
    }
  }, [isError]);
  return (
    <Modal
      title='Backfill existing data'
      subtitle='Use AI to backfill empty summaries and titles'
      size='md'
      onClose={() => onClose()}
      renderFooter={() => (
        <>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            No thanks
          </Button>
          <Button
            primary
            onClick={() => {
              onSubmit();
            }}
          >
            Backfill data
          </Button>
        </>
      )}
    >
      {isLoading && <Loading absolute />}
      <Text mb='4'>Select what you’d like to backfill with AI:</Text>
      {BOTS.map((bot) => (
        <div className='flex items-center space-x-2'>
          <Checkbox selected={bots.includes(bot)} onChange={(v) => toggleBot(bot, v)} />
          <Text>{LABELS[bot]}</Text>
        </div>
      ))}
    </Modal>
  );
};
