import React from 'react';
import { Link } from 'react-router-dom';

import { UrnProps } from '../types';

export const User: React.FC<UrnProps> = ({ children, id }) => {
  const href = `#`;
  return (
    <Link className='hover:underline text-blue-500' to={href} target='_blank'>
      {children}
    </Link>
  );
};
