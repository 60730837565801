import { createContext, useContext } from 'react';

interface Context {
  closeMenu: () => void;
  setSelectedItem: (item: string) => void;
  selectedItem: string | undefined;
}

export const MenuContext = createContext<Context | undefined>(undefined);

export const useMenuContext = () => {
  const menuContext = useContext<Context | undefined>(MenuContext);

  if (!menuContext) throw new Error();

  return menuContext;
};
