import { useCallback, useMemo } from 'react';

import { api } from '@api/reduxApi';
import { failedAttrUpdate } from '@components/CandidateProfile';
import { updateAttrValues } from '@components/shared/GridTable/components/utils';
import { CellError, OnUpdateCellParams } from '@components/shared/Table/types';
import { useToaster } from '@components/stores/toaster';
import * as toasts from '@components/StudiesApp/components/StudyDraft/toasts';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

type UseUpdateParticipationReturnType = (
  params: OnUpdateCellParams<Participation> & { errors: CellError[]; setErrors: (errors: CellError[]) => void }
) => void;

type UseUpdateParticipationArgs = {
  updateEntireParticipation?: (participation: Participation) => void;
};

const UPDATE_KEY_MAP = {
  incentive_in_whole_currency: 'incentive_in_cents'
};

export const useUpdateParticipation = ({
  updateEntireParticipation
}: UseUpdateParticipationArgs): UseUpdateParticipationReturnType => {
  const [updateCandidate] = api.useUpdateCandidateMutation();
  const [updateParticipation] = api.useUpdateParticipationMutation();

  const { candidateAttrs, setCandidateAttrs } = useCandidateAttrs();
  const showToast = useToaster();

  const candidateAttrsKeys = useMemo(() => candidateAttrs.map(({ name }) => name), [candidateAttrs]);

  return useCallback(
    async ({ row, columnId, value, errors, setErrors }) => {
      const oldParticipation = row.original;
      const oldCustomer = oldParticipation.customer;

      const key = columnId.startsWith('extra.') ? columnId.slice(6) : columnId;

      if (row.getValue(columnId) === value) {
        return;
      }

      try {
        let participation: Participation = { ...oldParticipation };
        const updateKey = UPDATE_KEY_MAP[key] ?? key;

        if (oldCustomer && (key in oldCustomer || candidateAttrsKeys.includes(key))) {
          const customer = await updateCandidate({ id: row.original.customer_id, [updateKey]: value }).unwrap();
          participation.customer = customer;
        } else if (oldParticipation && key in oldParticipation) {
          participation = await updateParticipation({ id: row.original.id, [updateKey]: value }).unwrap();
        }

        updateAttrValues(candidateAttrs, key, value, setCandidateAttrs);
        // this dependency should be removed once serverside participations are implemented
        updateEntireParticipation?.(participation);

        // remove error if it exists in the list
        if (errors.length > 0) {
          const updatedError = errors.filter((error) => error.rowIndex !== row.index && error.columnId !== columnId);

          setErrors(updatedError);
        }

        showToast(toasts.successUpdateProfile());
      } catch (e) {
        // add error if it doesn't exist in the list
        const error = errors.find((error) => error.rowIndex === row.index && error.columnId === columnId);

        if (!error) {
          setErrors([...errors, { rowIndex: row.index, columnId }]);
        }

        showToast(failedAttrUpdate(e));
      }
    },
    [candidateAttrsKeys, updateEntireParticipation]
  );
};
