import React, { ReactNode, VFC } from 'react';

import { Text } from '@components/common';

interface Props {
  icon: ReactNode;
  text: string;
}

export const Pill: VFC<Props> = ({ icon, text }) => {
  return (
    <div className='flex items-center max-w-full px-2 py-px space-x-2 overflow-hidden bg-white border border-gray-200 rounded-full'>
      <span className='text-gray-500'>{icon}</span>
      <Text as='span' h='400' className='leading-5' color='gray-500' truncate>
        {text}
      </Text>
    </div>
  );
};
