import { Toast } from '@stores/toaster';

export const failedGet = (): Toast => ({
  heading: 'Failed to load!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
});

export const failedCreate = (): Toast => ({
  heading: 'Failed to create!',
  icon: 'error',
  text: 'Something went wrong creating your guide. Please try again later.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong saving your guide. Please try again later.'
});

export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that guide. Please try again later.'
});

export const successDelete = (): Toast => ({
  heading: 'Guide deleted',
  icon: 'success',
  text: `Your guide was deleted`
});

export const successPublish = (): Toast => ({
  heading: 'Guide published',
  icon: 'success',
  text: 'Your guide was published'
});
