import { PersonSVG, TagSVG } from 'components/svgs';
import * as React from 'react';
import { useMemo, useState } from 'react';

import { Button } from '@components/common';
import { SortBy } from '@components/shared/SortBy';
import { track } from '@components/tracking';
import { compact } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

import { useSynthesisData } from '../hooks/useSynthesisData';
import * as toasts from '../toasts';
import { RegroupBy } from './RegroupBy';
import { useDeviceType } from '@hooks/useDeviceType';
import { BulkAddToHighlightReel } from '@components/RepositoryApp/components/BulkAddToHighlightReel';
import { BulkAddToInsightDropdown } from '@components/RepositoryApp/components/BulkAddToInsightDropdown';

type Props = {
  studyId: number;
  boardId?: number;
};

export const SORT_BY_OPTIONS = [
  { label: 'Title', value: 'clips.title' },
  { label: 'Tag name', value: 'tags.name' },
  { label: 'Participant', value: 'customers.first_name' },
  { label: 'Creation date', value: 'created_at' },
  { label: 'Updated date', value: 'updated_at' }
];

export const REGROUP_BY_OPTIONS = [
  { label: 'Participant', value: 'customers.first_name', Icon: PersonSVG },
  { label: 'Tag', value: 'tags.name', Icon: TagSVG }
];

export const HeaderButtons: React.FC<Props> = ({ studyId, boardId }) => {
  const {
    isZDS,
    highlights,
    copyToClipboard,
    selectedIds,
    setSelectedIds,
    sortHighlights,
    groupHighlights,
    draggingId
  } = useSynthesisData(studyId, boardId);
  const [sortBy, setSortBy] = useState<string>('');

  const canCreate = usePermission<Study>('createStudy')();

  const selectedArtifacts = useMemo(
    () =>
      compact(
        highlights.map((artifact) => {
          if (selectedIds.includes(artifact.id)) {
            return artifact.objectID;
          }
        })
      ),
    [selectedIds, highlights]
  );
  const { isMobile } = useDeviceType();

  const showToast = useToaster();

  if (isZDS || draggingId !== null) {
    return null;
  }

  return (
    <div className='flex space-x-2'>
      {canCreate && selectedIds.length === 0 && (
        <RegroupBy
          options={REGROUP_BY_OPTIONS}
          onClick={(value) => {
            groupHighlights(value);
            track('board_clicked_regroup_by', { key: value });
          }}
        />
      )}
      {selectedIds.length > 0 && (
        <Button
          medium
          weight='normal'
          icon='deselect'
          onClick={() => {
            setSelectedIds([]);
            track('board_clicked_deselect_all');
          }}
        >
          {!isMobile ? 'Unselect all' : ''}
        </Button>
      )}
      <Button
        medium
        weight='normal'
        icon='copy'
        onClick={() => {
          copyToClipboard();
          showToast(toasts.successCopied());
          track('board_clicked_copy', { count: selectedIds.length });
        }}
      >
        {!isMobile ? 'Copy highlights' : ''}
      </Button>
      {canCreate && selectedIds.length > 0 && (
        <BulkAddToHighlightReel
          trackAddClick={(count) => track('board_clicked_add_to_highlight_reel', { count })}
          trackCreateClick={(count) => track('board_clicked_create_highlight_reel', { count })}
          selectedArtifacts={selectedArtifacts}
          studyId={studyId}
        />
      )}
      {canCreate && selectedIds.length > 0 && (
        <BulkAddToInsightDropdown
          trackCreateClick={(count) => track('board_clicked_create_insight', { count })}
          trackAddClick={(count) => track('board_clicked_add_to_insight', { count })}
          selectedArtifacts={selectedArtifacts}
          studyId={studyId}
        />
      )}
      {canCreate && selectedIds.length === 0 && (
        <SortBy
          options={SORT_BY_OPTIONS}
          value={sortBy}
          onChange={(value) => {
            sortHighlights(value);
            setSortBy(value);
            track('board_clicked_sort_by', { key: value });
          }}
        />
      )}
    </div>
  );
};
