import cn from 'classnames';
import { Portal } from 'components/shared/Portal';
import { composeEventHandlers } from 'helpers/composeEventHandlers';
import React, { forwardRef, HTMLAttributes } from 'react';
import { useInView } from 'react-intersection-observer';

import * as Icons from '../icons';
import { MenuBarButton } from './MenuBarButton';

export const ScrollToTop = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  ({ className, ...rest }, ref) => {
    const { ref: anchorRef, inView } = useInView({ initialInView: false });

    return (
      <>
        <Portal selector='#gq-editor'>
          <>
            <div
              ref={anchorRef}
              style={{ top: '100vh', height: `calc(100% - 100vh)` }}
              className='absolute w-px h-px pointer-events-none'
            />
            <MenuBarButton
              ref={ref}
              className={cn(
                'transition-opacity duration-150 opacity-0',
                'sticky bottom-4 right-2 bg-white w-8 h-8 border border-gray-200 ml-auto mr-4',
                {
                  'pointer-events-none': !inView,
                  'opacity-100 pointer-events-all': inView
                },
                className
              )}
              tooltip='Back to top'
              {...rest}
            >
              <Icons.ChevronUp />
            </MenuBarButton>
          </>
        </Portal>
      </>
    );
  }
);
