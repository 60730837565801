export const getAutomationKeys = (study: Study): SettingsKey[] => {
  const keys: SettingsKey[] = [];
  keys.push('invited_reminder');
  if (study.style === 'video_call') {
    keys.push('booked_reminder');
    keys.push('auto_complete');
  }
  if (!study.moderated) {
    keys.push('started_reminder');
  }
  if (study.is_nylas) {
    keys.push('has_team_event');
  }
  return keys;
};
