import React from 'react';

export const type: React.FC<any> = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.8333 8.38889C14.4547 8.38889 14.998 8.8947 15 9.55556V13.8333C15 14.4547 14.5005 15 13.8333 15H9.55556C8.93424 15 8.39087 14.4938 8.38889 13.8333V9.55556C8.38889 8.93424 8.88986 8.38889 9.55556 8.38889H13.8333ZM6.44444 8.38889C7.06576 8.38889 7.60913 8.8947 7.61111 9.55556V13.8333C7.61111 14.4547 7.11159 15 6.44444 15H2.16667C1.54535 15 1.00198 14.4938 1 13.8333V9.55556C1 8.93424 1.50097 8.38889 2.16667 8.38889H6.44444ZM13.8333 9.16667H9.55556C9.35612 9.16667 9.16667 9.3286 9.16667 9.55556V13.8333C9.16667 14.0328 9.32329 14.2222 9.55556 14.2222H13.8333C14.0328 14.2222 14.2222 14.0656 14.2222 13.8333V9.55556C14.2222 9.35612 14.0693 9.16667 13.8333 9.16667ZM6.44444 9.16667H2.16667C1.96723 9.16667 1.77778 9.3286 1.77778 9.55556V13.8333C1.77778 14.0328 1.9344 14.2222 2.16667 14.2222H6.44444C6.64388 14.2222 6.83333 14.0656 6.83333 13.8333V9.55556C6.83333 9.35612 6.68039 9.16667 6.44444 9.16667ZM13.8333 1C14.4547 1 14.998 1.50581 15 2.16667V6.44444C15 7.06576 14.5005 7.61111 13.8333 7.61111H9.55556C8.93424 7.61111 8.39087 7.10492 8.38889 6.44444V2.16667C8.38889 1.54535 8.88986 1 9.55556 1H13.8333ZM6.44444 1C7.06576 1 7.60913 1.50581 7.61111 2.16667V6.44444C7.61111 7.06576 7.11159 7.61111 6.44444 7.61111H2.16667C1.54535 7.61111 1.00198 7.10492 1 6.44444V2.16667C1 1.54535 1.50097 1 2.16667 1H6.44444ZM13.8333 1.77778H9.55556C9.35612 1.77778 9.16667 1.93971 9.16667 2.16667V6.44444C9.16667 6.64388 9.32329 6.83333 9.55556 6.83333H13.8333C14.0328 6.83333 14.2222 6.67671 14.2222 6.44444V2.16667C14.2222 1.96723 14.0693 1.77778 13.8333 1.77778ZM6.44444 1.77778H2.16667C1.96723 1.77778 1.77778 1.93971 1.77778 2.16667V6.44444C1.77778 6.64388 1.9344 6.83333 2.16667 6.83333H6.44444C6.64388 6.83333 6.83333 6.67671 6.83333 6.44444V2.16667C6.83333 1.96723 6.68039 1.77778 6.44444 1.77778Z'
      fill='currentColor'
    />
  </svg>
);
