import * as React from 'react';
import { TableState } from 'react-table';

export const useColumnResizeObserver = (
  state: TableState<any>,
  callback: (columnId: string, columnSize: number) => void
) => {
  // This Ref will contain the id of the column being resized or undefined
  const columnResizeRef = React.useRef<string | undefined>();
  React.useEffect(() => {
    // We are interested in calling the resize event only when "state.columnResizing?.isResizingColumn" changes from
    // a string to undefined, because it indicates that it WAS resizing but it no longer is.
    if (state.columnResizing && !state.columnResizing?.isResizingColumn && columnResizeRef.current) {
      // Trigger resize event
      callback(columnResizeRef.current, state.columnResizing.columnWidths[columnResizeRef.current]);
    }
    columnResizeRef.current = state.columnResizing?.isResizingColumn;
  }, [state.columnResizing?.isResizingColumn]);
};
