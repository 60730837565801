import React, { SVGProps } from 'react';

export const EllipsisSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.4375 8.01318C1.4375 8.4193 1.59883 8.80878 1.88599 9.09594C2.17316 9.38311 2.56264 9.54443 2.96875 9.54443C3.37486 9.54443 3.76434 9.38311 4.05151 9.09594C4.33867 8.80878 4.5 8.4193 4.5 8.01318C4.5 7.60707 4.33867 7.21759 4.05151 6.93043C3.76434 6.64326 3.37486 6.48193 2.96875 6.48193C2.56264 6.48193 2.17316 6.64326 1.88599 6.93043C1.59883 7.21759 1.4375 7.60707 1.4375 8.01318Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.5 8.01318C11.5 8.21427 11.5396 8.41339 11.6166 8.59917C11.6935 8.78495 11.8063 8.95375 11.9485 9.09594C12.0907 9.23813 12.2595 9.35092 12.4453 9.42787C12.631 9.50483 12.8302 9.54443 13.0312 9.54443C13.2323 9.54443 13.4315 9.50483 13.6172 9.42787C13.803 9.35092 13.9718 9.23813 14.114 9.09594C14.2562 8.95375 14.369 8.78495 14.4459 8.59917C14.5229 8.41339 14.5625 8.21427 14.5625 8.01318C14.5625 7.8121 14.5229 7.61298 14.4459 7.4272C14.369 7.24142 14.2562 7.07262 14.114 6.93043C13.9718 6.78824 13.803 6.67545 13.6172 6.59849C13.4315 6.52154 13.2323 6.48193 13.0312 6.48193C12.8302 6.48193 12.631 6.52154 12.4453 6.59849C12.2595 6.67545 12.0907 6.78824 11.9485 6.93043C11.8063 7.07262 11.6935 7.24142 11.6166 7.4272C11.5396 7.61298 11.5 7.8121 11.5 8.01318Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.46875 8.01318C6.46875 8.4193 6.63008 8.80878 6.91724 9.09594C7.20441 9.38311 7.59389 9.54443 8 9.54443C8.40611 9.54443 8.79559 9.38311 9.08276 9.09594C9.36992 8.80878 9.53125 8.4193 9.53125 8.01318C9.53125 7.60707 9.36992 7.21759 9.08276 6.93043C8.79559 6.64326 8.40611 6.48193 8 6.48193C7.59389 6.48193 7.20441 6.64326 6.91724 6.93043C6.63008 7.21759 6.46875 7.60707 6.46875 8.01318Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
