import React, { forwardRef, HtmlHTMLAttributes } from 'react';
import cn from 'classnames';
import { ErrorSvg } from '@components/svgs';
import { Text } from '@components/common';
import { TextProps } from '@components/common/Text';

type Size = 'medium' | 'small';

interface Props extends HtmlHTMLAttributes<HTMLDivElement> {
  size?: Size;
}

const H: Record<Size, TextProps['h']> = {
  small: '200',
  medium: '400'
};

const ICON_SIZE = {
  small: 3,
  medium: 4
};

export const ErrorMessage = forwardRef<HTMLDListElement, Props>(
  ({ size = 'medium', className, children, ...rest }, ref) => {
    return (
      <section ref={ref} className={cn('flex items-center text-red-600', className)} {...rest}>
        <ErrorSvg className={`w-${ICON_SIZE[size]} h-${ICON_SIZE[size]} mr-2 flex-shrink-0`} />
        <Text h={H[size]} role='alert' aria-live='assertive' className='text-red-600 no-branding'>
          {children || 'This field is required.'}
        </Text>
      </section>
    );
  }
);
