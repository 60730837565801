import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';

import { useToaster } from '@components/stores/toaster';
import { track } from '@components/tracking';
import * as toasts from '../toasts';
import { useUpdateGuideMutation } from '@components/GuidesApp/api';

interface Props {
  className?: string;
  guide: Guide;
}

export const EditableTitle: React.FC<Props> = ({ className, guide: { id, token, title } }) => {
  const [updateGuide] = useUpdateGuideMutation();

  const [value, setValue] = useState(title || '');
  const showToast = useToaster();

  async function handleBlur() {
    track('updated_guide_title', { guide_id: id });
    try {
      await updateGuide({ token, title: value }).unwrap();
    } catch {
      showToast(toasts.failedUpdate());
    }
  }

  return (
    <input
      name='title'
      className={classNames(className, 'focus:outline-none h700 placeholder-gray-400')}
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      placeholder='Enter title...'
    />
  );
};
