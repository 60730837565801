import React, { SVGProps } from 'react';

export const CenterAlignSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1491_1822)'>
      <path
        d='M8 12.8125V14.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 6.6875V9.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 1.4375V3.1875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.89583 9.3125H13.1042C13.1042 9.3125 13.6875 9.3125 13.6875 9.89583V12.2292C13.6875 12.2292 13.6875 12.8125 13.1042 12.8125H2.89583C2.89583 12.8125 2.3125 12.8125 2.3125 12.2292V9.89583C2.3125 9.89583 2.3125 9.3125 2.89583 9.3125Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.64583 3.1875H11.3542C11.3542 3.1875 11.9375 3.1875 11.9375 3.77083V6.10417C11.9375 6.10417 11.9375 6.6875 11.3542 6.6875H4.64583C4.64583 6.6875 4.0625 6.6875 4.0625 6.10417V3.77083C4.0625 3.77083 4.0625 3.1875 4.64583 3.1875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1491_1822'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
