import React from 'react';

import { Grid as GridLayout } from '@components/common/Grid';

import { Card } from './Card';

interface Props {
  cardsNumber?: number;
  height?: string | number;
  monitor?: number;
  desktop?: number;
  tablet?: number;
  mobile?: number;
  gap?: number;
  tiny?: number;
}

export const Grid: React.FC<Props> = ({
  cardsNumber = 3,
  height,
  monitor = 4,
  desktop = 3,
  tablet = 2,
  mobile = 1,
  gap = 6,
  tiny = 1
}) => (
  <GridLayout gap={gap} tiny={tiny} monitor={monitor} desktop={desktop} tablet={tablet} mobile={mobile}>
    {Array(cardsNumber)
      .fill(cardsNumber)
      .map((_, i) => (
        <Card height={height} key={'skeleton_card_' + i} />
      ))}
  </GridLayout>
);
