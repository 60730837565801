import { useState } from 'react';

export interface ModalManager<T> {
  activeModal: T;
  setActiveModal: (modal: T) => void;
  closeModal: () => void;
}

export const useModalManager = <T extends string>(): ModalManager<T> => {
  const [activeModal, setActiveModal] = useState<T>('' as T);

  const closeModal = () => {
    setActiveModal('' as T);
  };

  return { activeModal, setActiveModal, closeModal };
};
