import { Toast } from '@stores/toaster';

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating the survey. Please try again later.'
});

export const FAILED_REMOVE: Toast = {
  heading: 'Failed to remove image file!',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};

export const FAILED_UPLOAD: Toast = {
  heading: 'Failed to upload image file!',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};
