import React from 'react';
import { Provider as CollectionView } from 'components/stores/view';

import { Phantom } from './Phantom';
import { StudyShow } from './StudyShow';

interface Props {
  study: Study;
  refetch: () => void;
}

export const StudyPublished: React.FC<Props> = ({ study, refetch }) => (
  <CollectionView
    scope='study'
    page={study.id.toString()}
    default={{
      sort: { value: '', desc: false },
      filters: '',
      layout: 'grid'
    }}
  >
    {study.is_phantom ? (
      <Phantom study={study} />
    ) : (
      <StudyShow key={`study_${study.id}`} study={study} refetch={refetch} />
    )}
  </CollectionView>
);
