import React, { SVGProps } from 'react';

export const MultipleActionsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.68927 5.70511C5.15492 5.40511 4.55207 5.24838 3.93927 5.25011C3.08206 5.24339 2.2522 5.55149 1.60707 6.11598C0.961933 6.68047 0.546398 7.4621 0.43927 8.31261'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.9375 4.375C5.02481 4.375 5.90625 3.49356 5.90625 2.40625C5.90625 1.31894 5.02481 0.4375 3.9375 0.4375C2.85019 0.4375 1.96875 1.31894 1.96875 2.40625C1.96875 3.49356 2.85019 4.375 3.9375 4.375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.625 3.5C10.4707 3.5 11.1562 2.81444 11.1562 1.96875C11.1562 1.12306 10.4707 0.4375 9.625 0.4375C8.77931 0.4375 8.09375 1.12306 8.09375 1.96875C8.09375 2.81444 8.77931 3.5 9.625 3.5Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9 5.24897C11.6911 4.88758 11.3991 4.58121 11.0481 4.35528C10.6972 4.12935 10.2974 3.99038 9.88194 3.94988C9.46651 3.90939 9.04741 3.96855 8.65942 4.12245C8.27142 4.27635 7.92572 4.52055 7.651 4.8348'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.6875 6.5625H7.4375C6.95425 6.5625 6.5625 6.95425 6.5625 7.4375V10.9375C6.5625 11.4207 6.95425 11.8125 7.4375 11.8125H12.6875C13.1707 11.8125 13.5625 11.4207 13.5625 10.9375V7.4375C13.5625 6.95425 13.1707 6.5625 12.6875 6.5625Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 13.5625H11.8125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0625 13.5625V11.8125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
