import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';

import { DropdownCombobox } from '@components/common';
import { MultiselectCombobox } from '@components/shared/MultiselectCombobox';
import { CellProps } from '../../Table/types';
import { useDebouncedCallback } from 'use-debounce';
import { api } from '@api/reduxApi';

type Props<D> = Omit<CellProps<D, string>, 'renderValue' | 'getValue'> & {
  attrId?: number;
  value: any;
  setValue: (value: any) => void;
};

export const CustomAttrsCell = <D extends Record<string, any>>(props: Props<D>) => {
  const { type = 'text', table, row, column, cell, value, setValue, render, attrId } = props;
  const [query, setQuery] = useState<string>('');

  const [editMode, setEditMode] = useState<boolean>(false);

  const {
    data: options = [],
    isLoading,
    isFetching
  } = api.useGetCandidateAttrsSuggestionsQuery(
    {
      id: attrId,
      q: query
    },
    { skip: !editMode }
  );

  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleOnBlur = () => {
    setEditMode(false);
  };

  const handleCellUpdate = (newValue: any) => {
    setEditMode(false);
    setValue(newValue);
  };

  const itemsToChoose = options.map((v) => ({ value: v, label: v }));

  const renderEdit = useCallback(() => {
    switch (type) {
      case 'text':
        return (
          <DropdownCombobox
            isLoading={isLoading || isFetching}
            onClickOutside={() => setEditMode(false)}
            items={itemsToChoose}
            placeholder='Enter…'
            onSelect={(selected) => handleCellUpdate(selected?.value)}
            onInputChange={callback}
            fullWidth
            allowCreate
            submitOnEnter
            submitLastValue
            autoFocus
          />
        );
      case 'multiple_choice':
        return (
          <MultiselectCombobox
            value={value}
            options={itemsToChoose}
            onSave={handleCellUpdate}
            onBlur={handleOnBlur}
            withInput
            canCreate
            rightDropdown
          />
        );
      default:
        return null;
    }
  }, [value, options]);

  const renderValue = useCallback(() => {
    if (value == null || value === '') return '-';

    return value;
  }, [value]);

  return (
    <div
      className='w-full h-full inline-flex items-center'
      onClick={() => !editMode && setEditMode((editMode) => !editMode)}
    >
      {!editMode ? <span className='px-2 truncate'>{renderValue()}</span> : <div ref={wrapperRef}>{renderEdit()}</div>}
    </div>
  );
};
