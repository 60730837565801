import { Modal } from 'tailwindcss-stimulus-components';

import { Controller } from 'stimulus';

export default class extends Controller {
  // export default class extends Controller {
  static targets = ['customerId', 'container', 'customerRow'];

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden';
    this.currentRow = null;
  }

  open(event) {
    event.preventDefault();
    const element = event.target;
    const customerId = element.dataset.customerId;
    this.customerIdTarget.value = customerId;
    this.containerTarget.classList.remove(this.toggleClass);
    const rows = this.customerRowTargets;
    this.currentRow = rows.find(function (r) {
      return r.dataset.id === customerId;
    });
    this.currentRow.classList.add('bg-blue-100');
  }

  close(event) {
    event.preventDefault();
    this.containerTarget.classList.add(this.toggleClass);
    this.currentRow.classList.remove('bg-blue-100');
  }
}
