import React, { SVGProps } from 'react';

export const OverviewSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1492_23066)'>
      <path
        d='M13.125 12.6875C13.125 12.9196 13.0328 13.1421 12.8687 13.3062C12.7046 13.4703 12.4821 13.5625 12.25 13.5625H1.75C1.51794 13.5625 1.29538 13.4703 1.13128 13.3062C0.967187 13.1421 0.875 12.9196 0.875 12.6875V1.3125C0.875 1.08044 0.967187 0.857876 1.13128 0.693782C1.29538 0.529687 1.51794 0.4375 1.75 0.4375H10.5262C10.7547 0.437371 10.974 0.526554 11.1376 0.686L12.8608 2.36775C12.9444 2.44926 13.0108 2.54668 13.0562 2.65428C13.1016 2.76188 13.125 2.87747 13.125 2.99425V12.6875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.52637 4.8125H10.5264'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.52637 7.4375H10.5264'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.52637 10.0625H7.02637'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1492_23066'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
