import { AddRecallSurveySVG } from 'components/svgs/AddRecallSurveySVG';
import React, { useEffect } from 'react';

import { Button, Text } from '@components/common';
import { Grid } from '@components/common/Grid';
import { DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { PageHeader } from '@components/shared/PageHeader';
import { ContentTestSurveySVG, DemographicSurveySVG, SatisfactionInterviewSVG } from '@components/shared/ZDSPage/svg';
import { track } from '@components/tracking';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';
import { FeatureBanner, FeatureType } from 'components/shared/FeatureBanner';

import { StudySlideOut } from '@components/StudiesApp/types';
import { useLocalStorage } from '@hooks/useLocalStorage';

const ZDSButtons = [
  {
    Icon: SatisfactionInterviewSVG,
    heading: 'Build your research repository',
    subheading: 'Upload and transcribe recordings.  Create highlights, reels and insights.',
    link: '/repository'
  },
  {
    Icon: DemographicSurveySVG,
    heading: 'Create research panel',
    subheading: 'Import your customer database and send them invites to join your panel.',
    link: '/candidates'
  },
  {
    Icon: ContentTestSurveySVG,
    heading: 'Get a product tour',
    subheading: 'Be guided through the product experience to see what it offers.',
    link: '/getting_started'
  }
];

interface Props {
  setSlideOut: (v: StudySlideOut | null) => void;
}

export const DashboardZDS: React.FC<Props> = ({ setSlideOut }) => {
  const { hasQuota } = usePlan();
  const user = useUser();

  const [dismissedFeatures, setDismissedFeatures] = useLocalStorage<FeatureType[]>('feature-banners-dismissed');


  useEffect(() => {
    track('viewed_zds', { type: 'page', page: 'dashboard' });
  }, []);

  return (
    <>
      <PageHeader h1='Dashboard' hideDefaultButtons />
      <DashboardLayoutBody className='flex flex-col flex-grow'>
        <div className='flex flex-col flex-grow w-full p-6 bg-white border border-gray-200 rounded-lg mb-10'>
          <div className='flex flex-col items-center justify-center flex-grow mt-6'>
            <AddRecallSurveySVG />
            <Text color='gray-500' bold h='400' mt='6' className='text-center'>
              Welcome, {user.name}
            </Text>
            <div className='mt-4 text-lg font-bold'>Launch a new research study</div>
            <div className='max-w-lg mt-4 text-sm text-center'>
              Build a research panel, schedule customer interviews, send out a survey, and run unmoderated usability
              tests.
            </div>
            {hasQuota('studies') && (
              <Button
                className='my-8 text-sm'
                trackEvent='clicked_zds_page_cta'
                trackProps={{ page: 'dashboard', cta: 'Create new study' }}
                primary
                onClick={() => setSlideOut('NewStudySlideout')}
              >
                Create new study
              </Button>
            )}
          </div>
        </div>
        <FeatureBanner
          dismissedFeatures={dismissedFeatures}
          setDismissedFeatures={setDismissedFeatures}
          type='external_recruitment'
          source='dashboard_zds'
          setSlideOut={setSlideOut}
        />
        <Text color='gray-500' bold h='400' className='text-center'>
          Other ways to get started
        </Text>
        <Grid className='mt-4' gap={6} mobile={1} tablet={2} desktop={3} monitor={3}>
          {ZDSButtons.map(({ Icon, heading, subheading, link }) => (
            <div
              key={link}
              className='w-96 box-border relative flex flex-col items-center w-full p-6 text-left text-gray-700 bg-white border border-gray-200 rounded-md'
            >
              <div className='flex-shrink-0 mb-4 text-center'>
                <Icon />
              </div>
              <div className='h500 mb-4 font-bold leading-normal text-center'>{heading}</div>
              <div className='h400 mb-6 leading-normal text-center'>{subheading}</div>
              <Button
                trackEvent='clicked_zds_page_link'
                trackProps={{ page: 'dashboard', link }}
                key={heading}
                spa
                href={link}
                className='w-full text-center'
              >
                Get started
              </Button>
            </div>
          ))}
        </Grid>
      </DashboardLayoutBody>
    </>
  );
};
