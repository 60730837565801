import cn from 'classnames';
import * as React from 'react';
import Tippy from '@tippyjs/react';

import { Text } from '@components/common';
import { XSVG } from '@components/svgs';

import { getHexWithOpacity, TAG_COLORS } from './colors';

interface Props {
  tag: string;
  color: string;
  onClickDelete?: () => void;
  size?: 'base' | 'sm';
  count?: number;
  className?: string;
  pillClassName?: string;
  onClick?: () => void;
}
export const BaseTagPill: React.FC<Props> = ({
  tag,
  count,
  color,
  onClickDelete,
  size = 'base',
  className,
  pillClassName,
  onClick
}) => {
  const whiteText = TAG_COLORS[color || 'default']?.whiteText || false;

  const pillClass = cn('inline-flex items-center px-2 space-x-2 text-sm rounded-full xx-tag', pillClassName, {
    'p-1': size === 'base',
    'text-white': whiteText,
    'hover:text-gray-200': whiteText && onClick,
    'text-gray-700': !whiteText,
    'hover:text-indigo-600': !whiteText && onClick
  });

  const pillStyle = {
    background: color?.startsWith('#') ? getHexWithOpacity(color, 0.5) : TAG_COLORS[color || 'default']?.bgColor
  };

  const Component = React.useCallback(
    ({ link, children, ...rest }) => {
      return onClick ? (
        <Tippy content='View all occurrences' placement='top'>
          <div className='truncate'>
            <div
              {...rest}
              aria-label='pill'
              className={whiteText ? 'text-white hover:text-gray-200' : 'text-gray-700 hover:text-indigo-600'}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
            >
              {children}
            </div>
          </div>
        </Tippy>
      ) : (
        <Text h='400' as='span' {...rest}>
          {children}
        </Text>
      );
    },
    [whiteText, onClick]
  );

  return (
    <div className={cn('xx-tag-pill truncate', pillClass, className)} style={pillStyle}>
      <Component className={cn('text-sm flex-shrink truncate', { ['opacity-0']: !color })}>
        {tag}
        {count !== undefined && <span className='ml-1 opacity-50'>{count}</span>}
      </Component>
      {onClickDelete && (
        <XSVG
          className={'w-5 h-5 min-w-5 min-h-5 flex-shrink-0' + (color ? '' : 'opacity-0')}
          role='button'
          aria-label={`Delete ${tag} tag`}
          onClick={onClickDelete}
        />
      )}
    </div>
  );
};

type TagPillProps = Omit<Props, 'tag' | 'color' | 'onClick'> & {
  tag: Tag;
  onPillClick?: (link: string) => void;
};

export const TagPill: React.FC<TagPillProps> = ({ onPillClick, tag, ...props }) => {
  const link = `?filters[]=tag%20includes_any%20${tag.id}`;

  return (
    <BaseTagPill
      onClick={onPillClick ? () => onPillClick(link) : undefined}
      tag={tag.name}
      count={tag.taggings_count}
      color={tag.color}
      {...props}
    />
  );
};
