import { Toast } from '@stores/toaster';

// get
export const failedGet = (): Toast => ({
  heading: 'Failed to load!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
});

// create
export const failedCreate = (): Toast => ({
  heading: 'Failed to create!',
  icon: 'error',
  text: 'Something went wrong creating your segment. Please try again later.'
});

export const successCreate = (title: string): Toast => ({
  heading: 'Segment created',
  icon: 'success',
  text: `“${title}” segment was created.`
});

// delete
export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that segment. Please try again later.'
});

export const successDelete = (title: string): Toast => ({
  heading: 'Segment deleted',
  icon: 'success',
  text: `“${title}” segment was deleted.`
});

// update
export const successUpdate = (title: string): Toast => ({
  heading: 'Segment updated',
  icon: 'success',
  text: `“${title}” segment updates were saved.`
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong saving your segment. Please try again later.'
});
