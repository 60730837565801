import * as React from 'react';

import { Text } from '@components/common';
import { DashboardHeader, DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { ZDSData, ZDSType } from '@components/shared/ZDSPage/constants';
import { HelpCenter } from '@components/shared/ZDSPage/HelpCenter';

interface Props {
  type: ZDSType;
  body: React.FC;
  updateCandidate?: (c: Candidate) => void;
}

const renderZDSBody = (ZDSBody, updateCandidate) => {
  return <ZDSBody updateCandidate={updateCandidate} />;
};

export const ZDSPage: React.FC<Props> = ({ type, body, updateCandidate }) => {
  const { header, description, subheader } = ZDSData[type];

  return (
    <DashboardLayout>
      <DashboardHeader
        h1={header}
        renderHeaderButtons={() => <RestrictedButton action='New study' permission='createStudy' />}
      >
        <Text h='400' as='span'>
          {description}
        </Text>
      </DashboardHeader>

      <DashboardLayoutBody className='flex flex-col flex-grow'>
        <Text h='600' bold className='mb-4'>
          {subheader}
        </Text>
        {renderZDSBody(body, updateCandidate)}
        <HelpCenter />
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
