import React, { SVGProps } from 'react';

export const CalendarSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0)'>
        <path
          d='M1.43866 3.1875H14.5637V14.5625H1.43866V3.1875Z'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.43866 6.6875H14.5637'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.93866 4.5V1.4375'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.0637 4.5V1.4375'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='14' height='14' fill='white' transform='translate(1 1)' />
        </clipPath>
      </defs>
    </svg>
  );
};
