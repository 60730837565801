import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import tinytime from 'tinytime';

import { api } from '@api/reduxApi';
import { Button, Loading, Pill, Select, Tabs, TeamIcon, Text } from '@components/common';
import { RadioGroupOption } from '@components/common/RadioGroup';
import { UploadButton } from '@components/common/UploadButton';
import { track } from '@components/tracking';
import { noop } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { useTeams } from '@hooks/useTeams';
import { useToaster } from '@stores/toaster';
import Tippy from '@tippyjs/react';

import { ConsentFormFieldsModal } from './components/ConsentFormFieldsModal';
import { ConsentPlacementSettings } from './components/ConsentPlacementSettings';
import { DeleteFormButton } from './components/DeleteFormButton';
import * as toasts from './toasts';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

type Props = {
  teamId?: number | null;
};

const EXPIRATION_DAYS_OPTIONS: RadioGroupOption<'90' | '365' | ''>[] = [
  {
    label: 'Non-transferrable: Do not accept same consent form received across studies',
    value: ''
  },
  {
    label: 'For 90 days: Accept same consent form received on another study within the previous 90 days',
    value: '90'
  },
  {
    label: 'For 1 year: Accept same consent form received on another study within the previous 365 days',
    value: '365'
  }
];

export const LegalIndex: React.FC<Props> = ({ teamId }) => {
  const isAdmin = usePermission('isAdmin')();

  const canAddConsentForm = isAdmin;

  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [activeConsentForm, setActiveConsentForm] = useState<ConsentForm>();
  const showToast = useToaster();
  const { findTeam } = useTeams();
  const team = teamId && findTeam(teamId);

  const { data: consentForms = [], isFetching: isLoadingConsentForms, refetch } = api.useGetConsentFormsQuery();
  const [createConsentForm] = api.useCreateConsentFormMutation();
  const [updateConsentForm, { isLoading: isLoadingConsentFormUpdate }] = api.useUpdateConsentFormMutation();

  const handleFileDrop = async ([file]: File[]) => {
    setUploading(true);

    const body = new FormData();
    body.append('consent_form[file]', file);

    if (teamId) {
      body.append('consent_form[team_id]', teamId.toString());
    }

    try {
      await createConsentForm(body).unwrap();

      setOpen(false);
      setUploading(false);
      track('created_consent_form', { has_team: !!teamId });
    } catch (_) {
      showToast(toasts.failedCreate());
    }
  };

  const setDefault = async (id: number) => {
    try {
      updateConsentForm({ id, consent_form: { is_default: true } }).unwrap();
    } catch (_) {
      showToast(toasts.failedUpdate());
    }
  };

  const updateForm = async (id: number, params: Partial<ConsentForm>) => {
    try {
      updateConsentForm({ id, consent_form: params }).unwrap();
      showToast(toasts.successUpdate());
      setActiveConsentForm(undefined);
    } catch (_) {
      showToast(toasts.failedUpdate());
    }
  };

  const displayableConsentForms = consentForms.filter((c) => teamId == c.team_id);
  const loading = isLoadingConsentForms || isLoadingConsentFormUpdate;

  return (
    <>
      <div className='flex items-end justify-between mb-4 monitor:mb-6'>
        <div className='flex items-center space-x-2'>
          {team && <TeamIcon team={team} />}
          <Text as='h1' h='700'>
            Legal
          </Text>
        </div>
      </div>

      <div className='p-6 bg-white border border-gray-200 rounded-lg'>
        {(loading || uploading) && <Loading />}
        <Tabs current='Consent forms' tabs={['Consent forms']} />
        <hr className='-mx-6' />
        <Text h='400' className='pt-4'>
          When you create a study, you can choose which consent form you want to use for participants to agree to.
        </Text>
        <ConsentPlacementSettings />
        {displayableConsentForms && (
          <div className='pt-2'>
            {displayableConsentForms.map((form) => (
              <div key={form.id}>
                <div className='flex py-4 space-x-4'>
                  <div>
                    <img
                      alt='Thumbnail preview of the form'
                      className='w-20 h-16 bg-gray-200'
                      src={form.thumbnail_url}
                    />
                  </div>
                  <div className='flex flex-col justify-center flex-grow'>
                    <div className='flex items-center'>
                      <a className='font-bold' href={form.url} target='_blank'>
                        {form.title}
                      </a>

                      <div className='flex-grow pr-6 text-right'>
                        {form.is_default && <Pill color='gray'>Default</Pill>}
                        {!form.is_default && (
                          <Tippy content='Set as default consent form for all new studies'>
                            <button className='h400' onClick={() => setDefault(form.id)}>
                              Set as default
                            </button>
                          </Tippy>
                        )}
                      </div>
                      <Tippy content='Consent Transfer Settings: Choose if your participants need to sign the same consent form again for each study, or if once is enough.'>
                        <div className='w-56 pr-6'>
                          <Select
                            options={EXPIRATION_DAYS_OPTIONS}
                            name='expirationDays'
                            onChange={(v) => updateForm(form.id, { expiration_days: v ? Number(v) : null })}
                            value={form.expiration_days?.toString() || ''}
                          />
                        </div>
                      </Tippy>
                      <Button onClick={() => setActiveConsentForm(form)} className='mr-6'>
                        {pluralize('field', form.form_fields.length, true)}
                      </Button>

                      <DeleteFormButton formId={form.id} loading={loading} onDelete={refetch} />
                    </div>
                    <Text className='mt-1' h='400' color='gray-500'>
                      Uploaded {template.render(form.created_at)}
                    </Text>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          </div>
        )}
        {activeConsentForm && (
          <ConsentFormFieldsModal
            consentForm={activeConsentForm}
            onSave={(params) => updateForm(activeConsentForm.id, params)}
            onCancel={() => setActiveConsentForm(undefined)}
          />
        )}
        {canAddConsentForm && (
          <div className='pt-6'>
            <UploadButton
              icon='paperClip'
              supportedFileTypes={['pdf']}
              onUploadFiles={handleFileDrop}
              uploadZoneProps={{
                heading: 'Upload consent form',
                fileTypesText: 'We support pdf files of any size.'
              }}
              hasFileUploadZone
            >
              Attach file
            </UploadButton>
          </div>
        )}
      </div>
    </>
  );
};
