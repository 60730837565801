import React, { useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { EditConfirmationModal } from '@components/shared/GridTable/components';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';
import { getStateKey, onSelectValue } from '@components/shared/GridTable/components/utils';
import { SelectDropdown } from '@components/shared/SelectDropdown';
import { useOnEscape } from '@components/utils';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { validateEmail } from '@helpers/emailRegexpValidation';
import { ErrorModal } from '@components/shared/GridTable/components/ErrorModal';

export const SingleChoiceCell: EditableCell = (props) => {
  const { state, setState } = React.useContext(GridContext);

  const ref = useRef<HTMLInputElement>(null);

  const { value: initialValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const [value, setValue] = useState<string>(initialValue);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [currentValue, setCurrentValue] = useState<string>();

  const { allAttrs } = useCandidateAttrs();

  const isCustomAttr = column.id.slice(0, 5) === 'extra';
  const currentAttr = allAttrs.find((attr) => attr.name === (isCustomAttr ? column.id.slice(6) : column.id));

  const { data, isFetching } = api.useGetCandidateAttrsSuggestionsQuery(
    { id: currentAttr?.id, q: query },
    { skip: !currentAttr || !isCustomAttr }
  );

  const cellValue = value !== initialValue ? value : state?.[stateKey]?.[column?.id] || initialValue || '';
  const previousValue = state?.[row?.id]?.[column?.id] || initialValue || '';

  const options = useMemo(() => data?.map((v) => ({ label: v.toString(), value: v })), [data]);
  const selected = useMemo(() => {
    if (cellValue) {
      return { label: cellValue, value: cellValue };
    }

    return null;
  }, [cellValue]);

  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  useOnEscape(() => {
    if (editing) {
      setValue(previousValue);
      setEditing(false);
    }
  }, [editing]);

  const updateValue = async (currentValue) => {
    setValue(currentValue);
    setEditing(false);

    await onSelectValue({
      currentValue,
      initialValue,
      rowId: String(stateKey),
      setState,
      updateCellData,
      columnId: column.id
    });
  };

  const onBlur = (currentValue) => {
    if ((!currentValue && !previousValue) || currentValue === previousValue) {
      setEditing(false);
      return;
    }

    setCurrentValue(currentValue);

    if (column.id === 'email' && !validateEmail(currentValue)) {
      setErrorModal(true);
      return;
    }

    if ((column.id === 'phone_number' || column.id === 'email') && initialValue) {
      setModalOpen(true);
      return;
    }

    updateValue(currentValue);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.target.value === previousValue) return;
      onBlur(e.target.value);
    }
  };

  const onSelect = (item: DropdownItem | null): void => {
    if (item && item.value === previousValue) {
      setEditing(false);
      return;
    }

    onBlur(item ? item.value : null);
  };

  if (isCustomAttr && currentAttr?.attr_type !== 'url') {
    if (!editing) {
      return (
        <CellWrapper
          wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 truncate'
          onClick={() => setEditing(true)}
        >
          {cellValue}
        </CellWrapper>
      );
    }

    return (
      <SelectDropdown
        options={options ?? []}
        value={selected ? [selected] : []}
        loading={isFetching}
        onInputValueChange={(value) => callback(value)}
        onChange={(newValue) => onSelect(newValue[0])}
        onClose={(_, inputValue) => {
          if (inputValue === '') {
            onSelect(null);
          }
          setEditing(false);
        }}
        overrides={{
          Input: {
            props: {
              placeholder: 'Search...',
              autoFocus: true
            }
          }
        }}
        onCreate={(newValue) => {
          onSelect(newValue);
        }}
        defaultIsOpen
        creatable
      />
    );
  }

  if (!editing) {
    if (column.id === 'email' && props.row.original.email_valid === false) {
      return (
        <CellWrapper
          wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 text-red-600'
          onClick={() => setEditing(true)}
        >
          {cellValue}
        </CellWrapper>
      );
    } else {
      return (
        <CellWrapper
          wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600'
          onClick={() => setEditing(true)}
        >
          {cellValue}
        </CellWrapper>
      );
    }
  }

  const onClose = () => {
    setValue(previousValue);
    setModalOpen(false);
  };

  const onConfirm = () => {
    updateValue(cellValue);
    setModalOpen(false);
  };

  return (
    <div>
      <input
        data-testid='single-choice-text-input'
        type='text'
        autoFocus
        ref={ref}
        onKeyDown={onKeyDown}
        className='h400 no-outline focus:border focus:border-indigo-600 w-full h-full px-1 py-3 rounded-md'
        value={cellValue}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => {
          if (cellValue === previousValue) return;
          onBlur(value);
        }}
      />

      {modalOpen && (
        <EditConfirmationModal
          previousValue={previousValue}
          currentValue={currentValue}
          candidateName={row.original.participation?.name || row.original.name || 'Unnamed candidate'}
          attrName={column.headerLabel.toLowerCase()}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
      <ErrorModal
        invalidValue={currentValue || 'empty string'}
        onClose={() => {
          setErrorModal(false);
          ref.current?.focus();
        }}
        open={errorModal}
        attrName={column.id}
      />
    </div>
  );
};
