import * as React from 'react';

const RocketSVG: React.FC<any> = (props) => {
  return (
    <svg {...props} width='165' height='137' viewBox='0 0 165 137' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M104.257 61.6653C104.271 57.3307 103.231 53.0574 101.225 49.2122C100.273 47.4065 99.4044 46.284 98.7284 45.5955C98.4736 45.3258 98.1406 45.1423 97.7761 45.0705C97.4117 44.9988 97.0338 45.0424 96.6953 45.1952C96.4248 45.2904 96.1805 45.4476 95.9819 45.6542C95.0777 46.5882 94.2609 47.8228 93.5476 49.1375C91.4373 53.0725 90.3421 57.4695 90.3606 61.9321V64.6113H88.6378V33.4786C88.6378 26.9532 87.1736 20.5149 84.3058 14.799C82.9468 12.0931 81.7073 10.4119 80.7532 9.37833C80.522 9.11557 80.2373 8.90505 79.9181 8.76082C79.5989 8.6166 79.2524 8.54199 78.902 8.54199C78.5515 8.54199 78.2051 8.6166 77.8859 8.76082C77.5666 8.90505 77.2819 9.11557 77.0508 9.37833C75.7596 10.7784 74.595 12.6286 73.5766 14.5962C70.5608 20.4296 69.0289 27.0493 69.0289 33.7686V64.6113H67.5397V61.8041C67.5582 57.3414 66.4629 52.9444 64.3527 49.0094C63.6393 47.6965 62.8225 46.4618 61.9183 45.5261C61.6581 45.2526 61.3174 45.0688 60.9456 45.001C60.5737 44.9332 60.1899 44.985 59.8495 45.1489L59.912 45.1827C59.6936 45.279 59.4961 45.4168 59.3306 45.5884C58.6618 46.2768 57.7932 47.3994 56.8337 49.2051C54.8279 53.0503 53.7875 57.3236 53.8019 61.6582V108.204H59.1165L58.5369 112.031V123.472H62.8814V112.031L62.3018 108.204H67.5397V65.8157H69.0289V108.014H73.2735L72.6635 112.029V123.472H77.0098V112.031L76.3998 108.016H82.1193L81.5094 112.031V123.472H85.8556V112.031L85.2457 108.016H88.6342V65.8157H90.357V108.268H91.7534V108.204H96.3707L95.7911 112.031V123.472H100.137V112.031L99.5577 108.204H104.257V61.6653Z'
          fill='url(#paint0_linear)'
        />
      </g>
      <path d='M63.7482 109.801H59.6338L60.3686 104.818H63.0134L63.7482 109.801Z' fill='#535461' />
      <g opacity='0.1'>
        <path opacity='0.1' d='M60.1621 106.795H63.2171L63.012 105.402H60.3672L60.1621 106.795Z' fill='black' />
      </g>
      <path d='M99.0158 109.801H94.9014L95.6361 104.818H98.281L99.0158 109.801Z' fill='#535461' />
      <g opacity='0.1'>
        <path opacity='0.1' d='M95.4316 106.795H98.4867L98.2816 105.402H95.6367L95.4316 106.795Z' fill='black' />
      </g>
      <path d='M77.121 109.801H73.0049L73.7397 104.818H76.3863L77.121 109.801Z' fill='#535461' />
      <g opacity='0.1'>
        <path opacity='0.1' d='M73.5342 106.795H76.591L76.3859 105.402H73.7393L73.5342 106.795Z' fill='black' />
      </g>
      <path d='M85.4962 109.801H81.3818L82.1166 104.818H84.7614L85.4962 109.801Z' fill='#535461' />
      <g opacity='0.1'>
        <path opacity='0.1' d='M81.9121 106.795H84.9689L84.7621 105.402H82.1172L81.9121 106.795Z' fill='black' />
      </g>
      <path d='M93.1391 63.624H63.748V64.7964H93.1391V63.624Z' fill='#535461' />
      <path
        d='M97.6823 45.0998C97.527 44.9287 97.3375 44.7921 97.126 44.6985C96.9145 44.605 96.6858 44.5566 96.4544 44.5566C96.2231 44.5566 95.9943 44.605 95.7828 44.6985C95.5713 44.7921 95.3818 44.9287 95.2265 45.0998C94.3705 46.0088 93.5982 47.2115 92.9223 48.4906C90.9221 52.3372 89.8873 56.6104 89.9065 60.9437V106.075H102.913V60.7569C102.93 56.5424 101.945 52.384 100.04 48.6222C99.1376 46.8645 98.3155 45.7705 97.6823 45.0998Z'
        fill='#E0E0E0'
      />
      <path
        d='M60.386 45.0998C60.5413 44.9287 60.7308 44.7921 60.9423 44.6985C61.1537 44.605 61.3825 44.5566 61.6139 44.5566C61.8452 44.5566 62.074 44.605 62.2855 44.6985C62.4969 44.7921 62.6864 44.9287 62.8418 45.0998C63.6978 46.0088 64.47 47.2115 65.146 48.4906C67.1461 52.3372 68.181 56.6104 68.1617 60.9437V106.075H55.1516V60.7569C55.1352 56.5424 56.1198 52.384 58.0247 48.6222C58.9253 46.8645 59.7475 45.7705 60.386 45.0998Z'
        fill='#E0E0E0'
      />
      <path d='M55.2236 61.7197L58.309 61.734V65.5304H55.2236V61.7197Z' fill='#535461' />
      <path d='M65.0713 61.7197L68.1566 61.734V65.5304H65.0713V61.7197Z' fill='#535461' />
      <path d='M89.9053 61.7197L92.9924 61.734V65.5304H89.9053V61.7197Z' fill='#535461' />
      <path d='M99.751 61.7197L102.838 61.734V65.5304H99.751V61.7197Z' fill='#535461' />
      <g opacity='0.1'>
        <path
          opacity='0.1'
          d='M65.14 48.432C64.4659 47.1529 63.6919 45.9503 62.8358 45.0412C62.5939 44.7747 62.2712 44.5944 61.917 44.5279C61.5628 44.4614 61.1965 44.5124 60.874 44.6729C61.0599 44.7654 61.2277 44.8901 61.3698 45.0412C62.2259 45.9503 62.9981 47.1529 63.674 48.432C65.6742 52.2786 66.7091 56.5518 66.6898 60.8851V106.013H68.1594V60.8904C68.1784 56.5551 67.1423 52.2799 65.14 48.432Z'
          fill='black'
        />
      </g>
      <g opacity='0.1'>
        <path
          opacity='0.1'
          d='M94.0934 48.5555C94.7676 47.2764 95.5416 46.0738 96.3976 45.1647C96.5579 44.9927 96.7515 44.8547 96.9665 44.7591C96.6486 44.6202 96.295 44.5847 95.9557 44.6575C95.6164 44.7303 95.3087 44.9077 95.0761 45.1647C94.2201 46.0738 93.4478 47.2764 92.7719 48.5555C90.7717 52.4022 89.7369 56.6754 89.7561 61.0086V106.137H91.0794V61.0157C91.0585 56.6802 92.0928 52.4045 94.0934 48.5555Z'
          fill='black'
        />
      </g>
      <path d='M63.7482 109.801H59.6338V120.941H63.7482V109.801Z' fill='url(#paint1_linear)' />
      <path d='M77.1193 109.801H73.0049V120.941H77.1193V109.801Z' fill='url(#paint2_linear)' />
      <path d='M85.4962 109.801H81.3818V120.941H85.4962V109.801Z' fill='url(#paint3_linear)' />
      <path d='M99.0158 109.801H94.9014V120.941H99.0158V109.801Z' fill='url(#paint4_linear)' />
      <path
        d='M80.6628 9.71451C80.4478 9.45967 80.1796 9.2548 79.8769 9.11425C79.5742 8.9737 79.2444 8.90088 78.9105 8.90088C78.5767 8.90088 78.2468 8.9737 77.9442 9.11425C77.6415 9.2548 77.3733 9.45967 77.1583 9.71451C75.9367 11.0772 74.8399 12.8794 73.8697 14.7972C71.0162 20.4793 69.5645 26.9282 69.5645 33.4679V105.89H88.1282V33.1761C88.1282 26.8215 86.7425 20.5451 84.0263 14.9928C82.7405 12.3581 81.567 10.7214 80.6628 9.71451Z'
        fill='#EEEEEE'
      />
      <path
        d='M81.5991 17.7598H75.8368C74.7533 17.7598 73.875 18.6359 73.875 19.7167V20.2308C73.875 21.3116 74.7533 22.1877 75.8368 22.1877H81.5991C82.6825 22.1877 83.5609 21.3116 83.5609 20.2308V19.7167C83.5609 18.6359 82.6825 17.7598 81.5991 17.7598Z'
        fill='#6C63FF'
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M122.093 119.725C121.483 116.061 119.513 112.761 116.573 110.482C113.633 108.203 109.941 107.112 106.231 107.428C102.521 107.744 99.0674 109.443 96.5573 112.185C94.0472 114.928 92.6654 118.514 92.6873 122.228C92.6873 122.301 92.6873 122.372 92.6873 122.445C92.1398 122.527 91.5979 122.643 91.0644 122.792C90.3812 120.078 88.869 117.643 86.7372 115.824C84.6054 114.005 81.9594 112.893 79.1656 112.64C76.3718 112.388 73.5686 113.008 71.1439 114.416C68.7193 115.823 66.7933 117.947 65.6326 120.495C64.4363 120.035 63.1797 119.748 61.9017 119.645C61.6979 118.49 61.356 117.364 60.8833 116.291C60.6615 112.905 59.168 109.727 56.7008 107.39C54.2336 105.054 50.9741 103.732 47.5725 103.687C44.171 103.642 40.8776 104.878 38.3492 107.148C35.8209 109.418 34.2436 112.556 33.932 115.935C33.2318 117.414 32.7845 119 32.6087 120.627C29.5129 121.911 27.0055 124.297 25.5725 127.321C24.1395 130.345 23.883 133.792 24.8526 136.993H133.211C133.775 135.126 133.928 133.158 133.657 131.226C133.386 129.295 132.699 127.444 131.642 125.803C130.585 124.161 129.184 122.768 127.536 121.719C125.887 120.669 124.03 119.989 122.093 119.725Z'
          fill='url(#paint5_linear)'
        />
      </g>
      <path
        d='M48.6353 130.256C55.8088 130.256 61.624 124.456 61.624 117.3C61.624 110.144 55.8088 104.343 48.6353 104.343C41.4618 104.343 35.6465 110.144 35.6465 117.3C35.6465 124.456 41.4618 130.256 48.6353 130.256Z'
        fill='url(#paint6_linear)'
      />
      <path
        d='M48.5725 136.345C56.4276 136.345 62.7954 129.993 62.7954 122.157C62.7954 114.322 56.4276 107.97 48.5725 107.97C40.7174 107.97 34.3496 114.322 34.3496 122.157C34.3496 129.993 40.7174 136.345 48.5725 136.345Z'
        fill='url(#paint7_linear)'
      />
      <path
        d='M106.373 136.345C114.228 136.345 120.596 129.993 120.596 122.157C120.596 114.322 114.228 107.97 106.373 107.97C98.5182 107.97 92.1504 114.322 92.1504 122.157C92.1504 129.993 98.5182 136.345 106.373 136.345Z'
        fill='url(#paint8_linear)'
      />
      <path
        d='M105.659 136.345C112.832 136.345 118.647 130.544 118.647 123.389C118.647 116.233 112.832 110.432 105.659 110.432C98.4852 110.432 92.6699 116.233 92.6699 123.389C92.6699 130.544 98.4852 136.345 105.659 136.345Z'
        fill='url(#paint9_linear)'
      />
      <path
        d='M90.9803 125.85C90.9824 127.899 90.4965 129.919 89.5625 131.744C88.6286 133.569 87.2734 135.146 85.6086 136.346H70.3727C68.5693 135.046 67.1309 133.306 66.1952 131.292C65.2594 129.279 64.8578 127.059 65.0286 124.846C65.1994 122.633 65.9371 120.501 67.1709 118.654C68.4047 116.807 70.0932 115.307 72.0749 114.297C74.0565 113.288 76.2648 112.803 78.4881 112.889C80.7115 112.975 82.8755 113.629 84.7727 114.789C86.67 115.948 88.237 117.574 89.3236 119.511C90.4102 121.448 90.98 123.631 90.9785 125.85H90.9803Z'
        fill='url(#paint10_linear)'
      />
      <path
        d='M107.089 135.18C107.089 135.567 107.071 135.951 107.037 136.331C107.038 136.336 107.038 136.341 107.037 136.347H81.1629C81.1272 135.962 81.1094 135.573 81.1094 135.181C81.1072 132.081 82.2206 129.083 84.2472 126.732C86.2739 124.381 89.079 122.834 92.1524 122.372C92.7947 122.276 93.4433 122.227 94.0928 122.227C94.4245 122.227 94.7545 122.239 95.0808 122.262C97.4089 122.44 99.646 123.24 101.557 124.577C103.468 125.915 104.983 127.742 105.942 129.866C105.995 129.983 106.047 130.102 106.095 130.221C106.751 131.793 107.089 133.478 107.089 135.18Z'
        fill='url(#paint11_linear)'
      />
      <path
        d='M131.638 132.588C131.639 133.861 131.452 135.127 131.082 136.345H106.221C105.619 134.353 105.505 132.246 105.887 130.2C105.908 130.088 105.93 129.974 105.955 129.864C106.581 126.967 108.183 124.372 110.494 122.51C112.805 120.649 115.686 119.633 118.656 119.633C119.065 119.633 119.466 119.651 119.864 119.69C120.042 119.704 120.22 119.725 120.399 119.748C123.512 120.171 126.366 121.705 128.433 124.065C130.499 126.426 131.638 129.454 131.638 132.588Z'
        fill='url(#paint12_linear)'
      />
      <path
        d='M51.8485 136.346C51.8913 136.211 51.9288 136.076 51.9662 135.939C52.5575 133.733 52.5542 131.41 51.9568 129.206C51.3593 127.002 50.1889 124.994 48.5638 123.386C46.9387 121.777 44.9167 120.626 42.7022 120.047C40.4878 119.469 38.1594 119.484 35.9527 120.091C35.436 120.233 34.9291 120.408 34.435 120.615C31.4604 121.849 29.0512 124.141 27.6745 127.047C26.2978 129.953 26.0516 133.265 26.9838 136.341H51.8485V136.346Z'
        fill='url(#paint13_linear)'
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M126.7 109.699C128.016 109.699 129.083 108.635 129.083 107.323C129.083 106.01 128.016 104.946 126.7 104.946C125.384 104.946 124.317 106.01 124.317 107.323C124.317 108.635 125.384 109.699 126.7 109.699Z'
          fill='url(#paint14_linear)'
        />
      </g>
      <path
        d='M126.7 109.524C127.919 109.524 128.908 108.538 128.908 107.322C128.908 106.106 127.919 105.12 126.7 105.12C125.481 105.12 124.492 106.106 124.492 107.322C124.492 108.538 125.481 109.524 126.7 109.524Z'
        fill='url(#paint15_linear)'
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M137.612 132.848C139.047 132.848 140.211 131.688 140.211 130.256C140.211 128.825 139.047 127.664 137.612 127.664C136.177 127.664 135.014 128.825 135.014 130.256C135.014 131.688 136.177 132.848 137.612 132.848Z'
          fill='url(#paint16_linear)'
        />
      </g>
      <path
        d='M137.612 132.718C138.975 132.718 140.08 131.616 140.08 130.256C140.08 128.896 138.975 127.794 137.612 127.794C136.249 127.794 135.144 128.896 135.144 130.256C135.144 131.616 136.249 132.718 137.612 132.718Z'
        fill='url(#paint17_linear)'
      />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M26.4246 118.332C28.0015 118.332 29.2799 117.057 29.2799 115.484C29.2799 113.911 28.0015 112.636 26.4246 112.636C24.8477 112.636 23.5693 113.911 23.5693 115.484C23.5693 117.057 24.8477 118.332 26.4246 118.332Z'
          fill='url(#paint18_linear)'
        />
      </g>
      <path
        d='M26.4241 118.205C27.9302 118.205 29.151 116.988 29.151 115.485C29.151 113.983 27.9302 112.765 26.4241 112.765C24.9181 112.765 23.6973 113.983 23.6973 115.485C23.6973 116.988 24.9181 118.205 26.4241 118.205Z'
        fill='url(#paint19_linear)'
      />
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M133.993 3.10731C133.36 2.27307 132.567 1.57277 131.66 1.04722C130.62 0.450845 129.456 0.100873 128.259 0.024305C127.061 -0.052263 125.862 0.146621 124.754 0.60561C123.646 1.0646 122.658 1.77142 121.867 2.67154C121.076 3.57165 120.503 4.64099 120.192 5.79705C119.881 6.9531 119.841 8.16496 120.074 9.33911C120.307 10.5133 120.807 11.6183 121.536 12.569C122.265 13.5197 123.204 14.2906 124.279 14.8222C125.354 15.3538 126.537 15.6319 127.737 15.6351C127.903 15.6351 128.069 15.6351 128.233 15.6191C129.644 15.5303 131.006 15.0617 132.172 14.2631C133.338 13.4645 134.265 12.3657 134.856 11.0834C135.446 9.80118 135.678 8.38335 135.525 6.98055C135.372 5.57774 134.842 4.24235 133.99 3.11621L133.993 3.10731Z'
          fill='url(#paint20_linear)'
        />
      </g>
      <path
        opacity='0.2'
        d='M135.143 7.81357C135.143 9.69122 134.426 11.4984 133.138 12.8676C131.85 14.2367 130.087 15.0651 128.209 15.184C128.053 15.1947 127.896 15.2 127.74 15.2C126.604 15.1994 125.484 14.9383 124.467 14.437C123.449 13.9357 122.56 13.2076 121.869 12.3091C121.178 11.4105 120.704 10.3655 120.483 9.25483C120.262 8.14418 120.299 6.99765 120.593 5.90388C120.887 4.81011 121.429 3.79839 122.177 2.94694C122.926 2.0955 123.86 1.42714 124.909 0.993527C125.958 0.559917 127.092 0.372677 128.225 0.446283C129.358 0.519888 130.459 0.852367 131.442 1.41802C132.3 1.91488 133.05 2.57709 133.648 3.36604C134.62 4.64583 135.145 6.20799 135.143 7.81357Z'
        fill='#6C63FF'
      />
      <path
        opacity='0.05'
        d='M125.918 3.13009C125.536 3.29466 125.167 3.48857 124.814 3.71005C124.579 3.84025 124.377 4.02289 124.224 4.24375C124.149 4.35452 124.108 4.48444 124.106 4.61792C124.103 4.75139 124.139 4.88277 124.21 4.99628C124.388 5.24178 124.745 5.28092 125.043 5.29693C125.316 5.35492 125.6 5.33936 125.866 5.25184C126.132 5.16432 126.37 5.00798 126.555 4.7988L127.192 4.22241C127.413 4.02316 127.655 3.74741 127.559 3.46632C127.486 3.25106 127.243 3.1461 127.024 3.11052C126.477 3.00309 125.909 3.10265 125.431 3.38983'
        fill='#F55F44'
      />
      <path
        opacity='0.05'
        d='M123.259 11.1165C123.474 11.1165 123.648 10.9428 123.648 10.7286C123.648 10.5145 123.474 10.3408 123.259 10.3408C123.044 10.3408 122.87 10.5145 122.87 10.7286C122.87 10.9428 123.044 11.1165 123.259 11.1165Z'
        fill='#F55F44'
      />
      <path
        opacity='0.05'
        d='M131.181 9.69129C131.97 9.69129 132.61 9.0533 132.61 8.2663C132.61 7.4793 131.97 6.84131 131.181 6.84131C130.393 6.84131 129.753 7.4793 129.753 8.2663C129.753 9.0533 130.393 9.69129 131.181 9.69129Z'
        fill='#F55F44'
      />
      <path
        opacity='0.05'
        d='M133.651 3.36414C133.646 3.73648 133.533 4.09937 133.327 4.40967C133.12 4.71998 132.829 4.96464 132.487 5.11458C132.145 5.26452 131.768 5.31343 131.399 5.25549C131.03 5.19756 130.686 5.03521 130.407 4.78776C130.128 4.5403 129.926 4.21815 129.825 3.85959C129.725 3.50103 129.729 3.12115 129.838 2.7651C129.948 2.40906 130.157 2.09182 130.442 1.85111C130.727 1.61039 131.075 1.45633 131.445 1.40723C132.304 1.9067 133.054 2.57198 133.651 3.36414Z'
        fill='#F55F44'
      />
      <path
        opacity='0.05'
        d='M133.793 12.0669C133.156 12.9709 132.323 13.7204 131.357 14.2599C130.39 14.7994 129.314 15.1152 128.209 15.1837C128.2 15.0823 128.194 14.9792 128.194 14.876C128.195 14.2278 128.375 13.5924 128.716 13.0406C129.057 12.4889 129.545 12.0424 130.126 11.7512C130.706 11.4599 131.357 11.3351 132.004 11.3909C132.652 11.4467 133.271 11.6807 133.793 12.0669Z'
        fill='#F55F44'
      />
      <path
        opacity='0.25'
        d='M128.908 62.234C129.41 62.234 129.817 61.8278 129.817 61.3267C129.817 60.8256 129.41 60.4194 128.908 60.4194C128.405 60.4194 127.998 60.8256 127.998 61.3267C127.998 61.8278 128.405 62.234 128.908 62.234Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M7.63416 86.0729C8.13649 86.0729 8.54371 85.6667 8.54371 85.1656C8.54371 84.6645 8.13649 84.2583 7.63416 84.2583C7.13183 84.2583 6.72461 84.6645 6.72461 85.1656C6.72461 85.6667 7.13183 86.0729 7.63416 86.0729Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M164.041 91.2321C164.544 91.2321 164.951 90.8259 164.951 90.3248C164.951 89.8237 164.544 89.4175 164.041 89.4175C163.539 89.4175 163.132 89.8237 163.132 90.3248C163.132 90.8259 163.539 91.2321 164.041 91.2321Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M51.4926 48.2389C51.9949 48.2389 52.4021 47.8327 52.4021 47.3316C52.4021 46.8305 51.9949 46.4243 51.4926 46.4243C50.9902 46.4243 50.583 46.8305 50.583 47.3316C50.583 47.8327 50.9902 48.2389 51.4926 48.2389Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M48.5053 27.3795C49.0076 27.3795 49.4148 26.9733 49.4148 26.4722C49.4148 25.9712 49.0076 25.5649 48.5053 25.5649C48.0029 25.5649 47.5957 25.9712 47.5957 26.4722C47.5957 26.9733 48.0029 27.3795 48.5053 27.3795Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M22.7875 68.7116C23.2898 68.7116 23.697 68.3054 23.697 67.8043C23.697 67.3032 23.2898 66.897 22.7875 66.897C22.2852 66.897 21.8779 67.3032 21.8779 67.8043C21.8779 68.3054 22.2852 68.7116 22.7875 68.7116Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M151.445 68.8694H150.636V68.0635H150.306V68.8694H149.496V69.2003H150.306V70.0062H150.636V69.2003H151.445V68.8694Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M165 40.9387H164.19V40.1328H163.86V40.9387H163.051V41.2696H163.86V42.0755H164.19V41.2696H165V40.9387Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M107.283 25.3355H106.474V24.5278H106.144V25.3355H105.334V25.6646H106.144V26.4723H106.474V25.6646H107.283V25.3355Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M142.613 64.3347H141.803V63.5288H141.473V64.3347H140.665V64.6656H141.473V65.4715H141.803V64.6656H142.613V64.3347Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M112.996 91.1555H112.188V90.3496H111.859V91.1555H111.049V91.4864H111.859V92.2923H112.188V91.4864H112.996V91.1555Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M30.128 35.8298H29.3183V35.0239H28.9884V35.8298H28.1787V36.1589H28.9884V36.9666H29.3183V36.1589H30.128V35.8298Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M1.9493 46.3264H1.13962V45.5205H0.80968V46.3264H0V46.6555H0.80968V47.4632H1.13962V46.6555H1.9493V46.3264Z'
        fill='#535461'
      />
      <path
        opacity='0.25'
        d='M41.2971 79.3653H40.4874V78.5576H40.1575V79.3653H39.3496V79.6944H40.1575V80.5021H40.4874V79.6944H41.2971V79.3653Z'
        fill='#535461'
      />
      <path
        d='M137.612 132.718C138.975 132.718 140.08 131.616 140.08 130.256C140.08 128.896 138.975 127.794 137.612 127.794C136.249 127.794 135.144 128.896 135.144 130.256C135.144 131.616 136.249 132.718 137.612 132.718Z'
        fill='url(#paint21_linear)'
      />
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M47.968 61.9658C47.6666 61.5681 47.2889 61.2343 46.8569 60.9837C46.3614 60.696 45.8061 60.5261 45.2341 60.4872C44.6621 60.4482 44.0888 60.5413 43.5587 60.7591C43.0286 60.977 42.556 61.3138 42.1775 61.7433C41.799 62.1729 41.5249 62.6837 41.3764 63.2361C41.2279 63.7885 41.209 64.3676 41.3211 64.9284C41.4333 65.4893 41.6736 66.0168 42.0232 66.47C42.3729 66.9232 42.8225 67.29 43.3373 67.5418C43.8521 67.7936 44.4181 67.9236 44.9914 67.9219C45.0699 67.9219 45.1501 67.9219 45.2268 67.9219C45.8989 67.879 46.5467 67.6555 47.1017 67.275C47.6567 66.8946 48.0982 66.3714 48.3792 65.7609C48.6602 65.1505 48.7704 64.4755 48.698 63.8077C48.6256 63.1398 48.3733 62.5039 47.968 61.9675V61.9658Z'
          fill='url(#paint22_linear)'
        />
      </g>
      <path
        opacity='0.2'
        d='M48.5149 64.2088C48.5149 65.1031 48.1734 65.9638 47.5598 66.6158C46.9462 67.2678 46.1067 67.6622 45.212 67.7188C45.1389 67.7188 45.064 67.7188 44.9891 67.7188C44.4485 67.7182 43.9154 67.5937 43.4307 67.3549C42.9461 67.116 42.5231 66.7692 42.1942 66.3412C41.8654 65.9133 41.6396 65.4157 41.5343 64.8868C41.429 64.3579 41.4471 63.812 41.587 63.2912C41.7269 62.7703 41.985 62.2886 42.3414 61.8831C42.6977 61.4777 43.1427 61.1594 43.642 60.9528C44.1414 60.7463 44.6816 60.657 45.221 60.692C45.7604 60.7269 46.2845 60.885 46.7529 61.1542C47.1611 61.3908 47.5181 61.7058 47.8033 62.0811C48.2682 62.6932 48.5182 63.4409 48.5149 64.2088Z'
        fill='#6C63FF'
      />
      <path
        opacity='0.1'
        d='M44.1208 61.9774C43.9355 62.0552 43.7566 62.1474 43.5858 62.2532C43.4738 62.3142 43.3774 62.4 43.304 62.504C43.27 62.5572 43.252 62.6189 43.252 62.6819C43.252 62.745 43.27 62.8067 43.304 62.8598C43.3558 62.9106 43.418 62.9495 43.4864 62.9741C43.5547 62.9987 43.6275 63.0082 43.6999 63.0021C43.8292 63.0285 43.9631 63.0206 44.0883 62.9791C44.2135 62.9375 44.3256 62.8639 44.4133 62.7655L44.7165 62.4916C44.8217 62.3955 44.9376 62.2656 44.8948 62.1358C44.8709 62.0866 44.8347 62.0445 44.7897 62.0133C44.7448 61.9821 44.6925 61.963 44.638 61.9579C44.3775 61.907 44.1074 61.9546 43.88 62.0913'
        fill='#F55F44'
      />
      <path
        opacity='0.1'
        d='M42.8544 65.7812C42.9568 65.7812 43.0399 65.6983 43.0399 65.5962C43.0399 65.494 42.9568 65.4111 42.8544 65.4111C42.752 65.4111 42.6689 65.494 42.6689 65.5962C42.6689 65.6983 42.752 65.7812 42.8544 65.7812Z'
        fill='#F55F44'
      />
      <path
        opacity='0.1'
        d='M46.6276 65.1023C47.0038 65.1023 47.3088 64.7981 47.3088 64.4227C47.3088 64.0474 47.0038 63.7432 46.6276 63.7432C46.2513 63.7432 45.9463 64.0474 45.9463 64.4227C45.9463 64.7981 46.2513 65.1023 46.6276 65.1023Z'
        fill='#F55F44'
      />
      <path
        opacity='0.1'
        d='M47.803 62.0961C47.8007 62.2733 47.7475 62.4462 47.6496 62.5941C47.5517 62.742 47.4133 62.8587 47.2508 62.9304C47.0883 63.0021 46.9086 63.0257 46.7331 62.9985C46.5575 62.9712 46.3935 62.8942 46.2605 62.7767C46.1276 62.6592 46.0313 62.506 45.983 62.3354C45.9348 62.1648 45.9367 61.984 45.9886 61.8145C46.0404 61.645 46.1399 61.4939 46.2754 61.3792C46.4108 61.2645 46.5764 61.191 46.7525 61.1675C47.1609 61.4046 47.5179 61.7202 47.803 62.0961Z'
        fill='#F55F44'
      />
      <path
        opacity='0.1'
        d='M47.873 66.2349C47.5698 66.6655 47.1734 67.0227 46.7131 67.2798C46.2528 67.537 45.7404 67.6875 45.2139 67.7203C45.2139 67.6723 45.2139 67.6225 45.2139 67.5727C45.2129 67.2625 45.2987 66.9582 45.4616 66.694C45.6245 66.4298 45.8581 66.2162 46.136 66.0772C46.414 65.9382 46.7253 65.8793 47.035 65.9071C47.3447 65.935 47.6405 66.0485 47.889 66.2349H47.873Z'
        fill='#F55F44'
      />
      <g opacity='0.1'>
        <path opacity='0.1' d='M88.0286 35.9443H69.6592V36.6559H88.0286V35.9443Z' fill='black' />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear'
          x1='79.0304'
          y1='123.472'
          x2='79.0304'
          y2='8.54753'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='61.6901'
          y1='109.801'
          x2='61.6901'
          y2='120.943'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E0E0E0' />
          <stop offset='0.31' stopColor='#FCCC63' />
          <stop offset='0.77' stopColor='#F55F44' />
        </linearGradient>
        <linearGradient
          id='paint2_linear'
          x1='1804.71'
          y1='6985.52'
          x2='1804.71'
          y2='7683.23'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E0E0E0' />
          <stop offset='0.31' stopColor='#FCCC63' />
          <stop offset='0.77' stopColor='#F55F44' />
        </linearGradient>
        <linearGradient
          id='paint3_linear'
          x1='2006.34'
          y1='6985.52'
          x2='2006.34'
          y2='7683.23'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E0E0E0' />
          <stop offset='0.31' stopColor='#FCCC63' />
          <stop offset='0.77' stopColor='#F55F44' />
        </linearGradient>
        <linearGradient
          id='paint4_linear'
          x1='2331.77'
          y1='6985.52'
          x2='2331.77'
          y2='7683.23'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E0E0E0' />
          <stop offset='0.31' stopColor='#FCCC63' />
          <stop offset='0.77' stopColor='#F55F44' />
        </linearGradient>
        <linearGradient
          id='paint5_linear'
          x1='63601.9'
          y1='27917.4'
          x2='63601.9'
          y2='21680.2'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint6_linear'
          x1='48.6353'
          y1='104.343'
          x2='48.6353'
          y2='130.256'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint7_linear'
          x1='7781.57'
          y1='17329.2'
          x2='7781.57'
          y2='21855.1'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint8_linear'
          x1='17058.7'
          y1='17329.2'
          x2='17058.7'
          y2='21855.1'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint9_linear'
          x1='15482.8'
          y1='16196'
          x2='15482.8'
          y2='19970.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint10_linear'
          x1='15002'
          y1='16529'
          x2='15002'
          y2='19622.7'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint11_linear'
          x1='17358.1'
          y1='10740.2'
          x2='17358.1'
          y2='11861.2'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint12_linear'
          x1='20953.2'
          y1='12443.3'
          x2='20953.2'
          y2='14013.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint13_linear'
          x1='9337.76'
          y1='12450.5'
          x2='9337.76'
          y2='14021.7'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint14_linear'
          x1='3509.75'
          y1='3036.11'
          x2='3509.75'
          y2='2909.1'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint15_linear'
          x1='3261.6'
          y1='2707.89'
          x2='3261.6'
          y2='2816.95'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint16_linear'
          x1='4145.03'
          y1='3998.85'
          x2='4145.03'
          y2='3847.84'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint17_linear'
          x1='137.612'
          y1='127.794'
          x2='137.612'
          y2='132.718'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E0E0E0' />
          <stop offset='0.77' stopColor='#B3B3B3' />
        </linearGradient>
        <linearGradient
          id='paint18_linear'
          x1='869.703'
          y1='3901.65'
          x2='869.703'
          y2='3719.25'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint19_linear'
          x1='831.78'
          y1='3561.11'
          x2='831.78'
          y2='3727.52'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint20_linear'
          x1='13476.3'
          y1='2387.63'
          x2='13476.3'
          y2='1014.97'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
        <linearGradient
          id='paint21_linear'
          x1='3944.24'
          y1='3665.12'
          x2='3944.24'
          y2='3801.43'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#F2F2F2' />
          <stop offset='0.31' stopColor='#E6E6E6' />
          <stop offset='0.77' stopColor='#CCCCCC' />
        </linearGradient>
        <linearGradient
          id='paint22_linear'
          x1='2952.31'
          y1='3386.11'
          x2='2952.31'
          y2='3074.68'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#808080' stopOpacity='0.25' />
          <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
          <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export { RocketSVG };
