import cn from 'classnames';
import { useOnClickOutside, useOnEscape } from 'components/utils';
import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import React, { useEffect, useRef, useState } from 'react';

import { Button, TeamIcon, Text } from '@components/common';
import { SearchInput, SearchInputProps } from '@components/shared/SearchInput';
import Tippy from '@tippyjs/react';
import { View } from './components';


interface Props {
  sticky?: boolean;
  h1: string;
  team?: Team | null;
  renderFilters?: () => React.ReactNode;
  searchProps?: SearchInputProps;
  renderToggleSection?: () => React.ReactNode;
  renderBulkActions?: () => React.ReactNode;
  filtersApplied?: boolean;
  renderCta?: () => React.ReactNode;
  renderLayout?: () => React.ReactNode;
  renderSortBy?: () => React.ReactNode;
  renderGroupBy?: () => React.ReactNode;
  renderSearch?: () => React.ReactNode;
  renderTabs?: () => React.ReactNode;
  renderToggle?: () => React.ReactNode;
  renderExportButton?: () => React.ReactNode;
  renderPagination?: () => React.ReactNode;
  hideDefaultButtons?: boolean;
  transparent?: boolean;
  shortPage?: boolean;
  hideActions?: boolean;
  className?: string;
  withBorders?: boolean;
  withPaddings?: boolean;
}

export const PageHeader: React.FC<Props> = ({
  renderToggleSection,
  renderBulkActions,
  hideDefaultButtons,
  sticky = false,
  h1,
  team,
  renderFilters,
  searchProps,
  filtersApplied,
  renderCta,
  renderLayout,
  renderSortBy,
  renderGroupBy,
  renderSearch,
  children,
  transparent,
  renderTabs,
  shortPage = false,
  renderToggle,
  hideActions,
  renderExportButton,
  renderPagination,
  className,
  withBorders = true,
  withPaddings = true
}) => {
  const [showFilters, setShowFilter] = useState(false);
  const [search, setSearch] = useState(false);

  const searchRef = useRef(null);
  const filtersRef = useRef(null);

  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const showSearchInput = () => {
    setSearch(true);
    if (!filtersApplied && showFilters) {
      setShowFilter(false);
    }
  };

  const showFilterInput = () => setShowFilter(true);

  const { shortcut: searchShortcut } = useKeyboardShortcut({
    keys: { F: true, meta: true },
    handler: showSearchInput
  });

  const { shortcut: filterShortcut } = useKeyboardShortcut({
    keys: { I: true, meta: true },
    handler: showFilterInput,
    disabled: search
  });

  useEffect(() => {
    if (showFilters && !filtersApplied) {
      setShowFilter(false);
    } else if (!showFilters && filtersApplied) {
      setShowFilter(true);
    }
  }, [filtersApplied]);

  useOnClickOutside(searchRef, () => !searchProps?.value && search && setSearch(false));

  useOnClickOutside(filtersRef, (e) => {
    if (!filtersApplied && !searchButtonRef.current?.contains(e.target as Node)) {
      setShowFilter(false);
    }
  });

  useOnEscape(() => {
    if (!filtersApplied) {
      setShowFilter(false);
    }
  }, [Boolean(filtersApplied)]);

  useOnEscape(() => {
    if (!searchProps?.value && search) {
      setSearch(false);
    }
  }, [search, Boolean(searchProps?.value)]);

  const renderView = renderLayout || renderGroupBy || renderSortBy;

  const renderSearchInput = () =>
    renderSearch
      ? renderSearch?.()
      : searchProps && <SearchInput autoFocus inputClassName='h-8 leading-4' {...searchProps} />;

  return (
    <div className={cn({ 'top-0 z-30': sticky, 'bg-white': !transparent, 'border-b border-gray-200': withBorders })}>
      <div
        className={cn(
          'flex flex-col items-center transition-height transition-padding duration-300 ease-in-out',
          className,
          {
            'pt-4 mb-4': withPaddings,
            'desktop:flex-row': shortPage,
            'desktop:flex-row px-page': !shortPage
          }
        )}
      >
        <div
          className={cn(
            {
              'desktop:mr-4 shortPage:mb-0': shortPage,
              'tablet:mr-4 desktop:mb-0': !shortPage,
              'mb-2': withPaddings
            },
            'flex items-center'
          )}
        >
          {team && <TeamIcon className='mr-2' team={team} />}
          <h1 className='text-center desktop:text-left h700 font-bold'>{h1}</h1>
        </div>
        {renderExportButton?.()}
        {!hideActions && (
          <div
            className={cn(
              {
                'desktop:flex-row desktop:space-y-0 desktop:space-x-3': shortPage,
                'tablet:flex-row tablet:space-y-0 tablet:space-x-3': !shortPage
              },
              'flex flex-col items-center justify-end flex-1 space-y-2'
            )}
          >
            {renderToggleSection?.()}
            {!hideDefaultButtons && (
              <>
                {search ? (
                  <div ref={searchRef}>{renderSearchInput()}</div>
                ) : (
                  <Tippy
                    placement='top'
                    arrow={false}
                    content={
                      <>
                        Search{'  '}
                        <Text h='400' className='inline' color='gray-400'>
                          {searchShortcut}
                        </Text>
                      </>
                    }
                  >
                    <Button
                      aria-label='Search button'
                      ref={searchButtonRef}
                      medium
                      icon='search'
                      onClick={showSearchInput}
                    >
                      Search
                    </Button>
                  </Tippy>
                )}

                {!showFilters && renderFilters && (
                  <Tippy
                    placement='top'
                    arrow={false}
                    content={
                      <>
                        Filter{'  '}
                        <Text h='400' className='inline' color='gray-400'>
                          {filterShortcut}
                        </Text>
                      </>
                    }
                  >
                    <Button medium className='my-0' icon='filter' onClick={showFilterInput}>
                      Filter
                    </Button>
                  </Tippy>
                )}

                {renderView && (
                  <View
                    renderOnBodyRoot={h1 !== 'Add existing candidates'}
                    renderLayout={renderLayout}
                    renderToggle={renderToggle}
                    renderGroupBy={renderGroupBy}
                    renderSortBy={renderSortBy}
                  />
                )}
              </>
            )}

            {renderBulkActions?.()}
            {renderCta?.()}
            {renderPagination?.()}
          </div>
        )}
      </div>
      {children}
      {showFilters && <div ref={filtersRef}>{renderFilters?.()}</div>}
      {renderTabs?.()}
    </div>
  );
};
