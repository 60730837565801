export const noop = (): void => undefined;
export const asyncNoop = async (): Promise<void> => undefined;

export type ChangeFn = <R>(key: keyof R, value: R[keyof R]) => void;

export function getChangeFn<R>(get: R, set: (value: R) => void, block = false): ChangeFn | undefined {
  if (block) return;
  return (key, value) => {
    set({
      ...get,
      [key]: value
    });
  };
}

export const debounce = (func: any, timeout = 300) => {
  let timer: any;

  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const exactlyOnce = (func: () => void) => {
  let called = false;
  return (...args: any) => {
    if (called) return;
    called = true;
    func.apply(this, args);
  };
};
