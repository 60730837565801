import cn from 'classnames';
import { usePermission } from 'hooks/usePermission';
import { useUser } from 'hooks/useUser';
import React, { ElementType, MouseEvent, ReactElement, useState } from 'react';
import { Link } from 'react-router-dom';

import { AvatarFromId, DropdownLink, Loading, Popper, Text } from '@components/common';
import {
  CheckMarkSVG,
  DuplicateSVG,
  EllipsisSVG,
  InterviewTemplateSVG,
  PencilSVG,
  SurveyTemplateSVG,
  TrashSVG
} from '@components/svgs';

import { DEFAULT_TITLES, RESOURCE_LABEL } from '../consts';
import { Params as DuplicateParams, useDuplicateTemplate } from '../hooks/useDuplicateTemplate';
import { DeleteTemplateModal } from './DeleteTemplateModal';

type Props<T extends AbstractTemplate> = {
  allowUpdate?: boolean;
  global?: boolean;
  template: T;
  onEdit?: (id: number) => void;
  kind: TemplateKind;
  hasLink?: boolean;
  isActive?: boolean;
  onClick?: (e: MouseEvent, templateId: number) => void;
};

export const GRAPHICS: Record<TemplateKind, ElementType> = {
  SurveyTemplate: SurveyTemplateSVG,
  EmailTemplate: SurveyTemplateSVG,
  InterviewTemplate: InterviewTemplateSVG
};

const Separator = () => <div className='flex-shrink-0 w-1 h-1 bg-gray-500 rounded-full' />;

export const SurveyCard = <T extends AbstractTemplate>(props: Props<T>): ReactElement => {
  const { allowUpdate = false, template, onEdit, kind, hasLink = true, isActive, onClick } = props;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { duplicate, isLoading } = useDuplicateTemplate({ kind } as DuplicateParams);

  const canUpdate = usePermission<SurveyTemplate>('updateInterviewTemplate')();
  const { gq_admin } = useUser();

  const Graphic = GRAPHICS[kind];
  const surveyUseCase = (template as SurveyTemplate).use_case;

  const handleClickDelete = (closePopper: () => void) => {
    closePopper();
    setDeleteModalOpen(true);
  };

  return (
    <>
      <section
        className={cn(
          'xx-template-card hover:border-indigo-600 relative w-full overflow-hidden bg-white border border-gray-200 rounded',
          { 'border-indigo-600': isActive }
        )}
        onClick={(e) => onClick?.(e, template.id)}
      >
        {isActive && (
          <span className='top-2 left-2 absolute flex items-center justify-center w-4 h-4 bg-indigo-600 rounded-full'>
            <CheckMarkSVG className=' text-white' />
          </span>
        )}
        {allowUpdate && (
          <Popper
            content={({ closePopper }) => (
              <section className='w-36 py-1 bg-white border border-gray-200 rounded shadow-lg'>
                <DropdownLink className='flex items-center' onClick={() => duplicate({ id: template.id })}>
                  <DuplicateSVG className='w-4 h-4 mr-2' />
                  Duplicate
                </DropdownLink>
                {(!template.global || gq_admin) && canUpdate && (
                  <DropdownLink className='flex items-center' onClick={() => onEdit?.(template.id)}>
                    <PencilSVG className='w-4 h-4 mr-2' />
                    Edit
                  </DropdownLink>
                )}
                {canUpdate && (
                  <DropdownLink className='flex items-center' onClick={() => handleClickDelete(closePopper)}>
                    <TrashSVG className='w-4 h-4 mr-2' />
                    Delete
                  </DropdownLink>
                )}
                {isLoading && <Loading />}
              </section>
            )}
            placement='bottom-end'
            offset={[0, 4]}
            closeOnClickOutside
          >
            <div className='right-2 top-2 absolute cursor-pointer'>
              <EllipsisSVG className='w-4 h-4 transform rotate-90' />
            </div>
          </Popper>
        )}
        <Link to={hasLink ? `${template.id}` : ''} className='hover:text-gray-700 focus:text-gray-700 text-gray-700'>
          <div className='bg-gray-50 flex items-center justify-center h-40'>
            {template.illustration_url ? (
              <img alt='' className='max-w-full max-h-full' loading='lazy' src={template.illustration_url} />
            ) : (
              <Graphic />
            )}
          </div>
          <div className='p-4'>
            <Text className='mb-2' truncate bold>
              {template.title || DEFAULT_TITLES[kind]}
            </Text>
            <div className='flex items-center space-x-2 text-xs text-gray-500'>
              <AvatarFromId key={`template_${template.id}_avatar`} userId={template.creator.id} />
              <Separator />
              <span>{RESOURCE_LABEL[kind]}</span>
              {surveyUseCase && (
                <>
                  <Separator />
                  <span>{surveyUseCase}</span>
                </>
              )}
            </div>
          </div>
        </Link>
      </section>
      <DeleteTemplateModal
        id={template.id}
        title={template.title}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        kind={kind}
      />
    </>
  );
};
