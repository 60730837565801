import React, { forwardRef, HTMLAttributes } from 'react';
import { FilterSVG } from '@components/svgs';

export const Filter = FilterSVG;

export const Close = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.4375 14.5619L14.5625 1.43692'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.5625 14.5619L1.4375 1.43692'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
));

export const Check = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M2 8.89091L5.42857 12.8182L14 3'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
));

export const Hide = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.62341 12.25L12.5609 1.75'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.229 11.1131C5.80231 11.2912 6.3997 11.3795 7 11.3751C9.39167 11.4153 11.8172 9.73414 13.314 8.0868C13.4745 7.90864 13.5633 7.67734 13.5633 7.43755C13.5633 7.19776 13.4745 6.96647 13.314 6.7883C12.7717 6.19343 12.171 5.65463 11.5208 5.18005'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.40757 3.66445C7.9473 3.54991 7.47428 3.49463 6.99999 3.49995C4.64857 3.46087 2.21666 5.10528 0.687741 6.78703C0.527256 6.9652 0.438446 7.19649 0.438446 7.43628C0.438446 7.67607 0.527256 7.90737 0.687741 8.08553C1.15261 8.59443 1.66 9.06279 2.20441 9.48553'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.8125 7.4375C4.81242 7.15021 4.86895 6.86572 4.97886 6.60029C5.08876 6.33486 5.24989 6.09368 5.45303 5.89053C5.65618 5.68739 5.89736 5.52626 6.16279 5.41636C6.42822 5.30645 6.71271 5.24992 7 5.25'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.1875 7.43689C9.18758 7.72421 9.13105 8.00872 9.02115 8.27419C8.91126 8.53966 8.75014 8.78087 8.547 8.98406C8.34387 9.18725 8.10269 9.34844 7.83725 9.4584C7.57182 9.56837 7.28732 9.62497 7 9.62497'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
));

export const MoveLeft = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.0625 6.99878H13.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.25 4.81128L3.0625 6.99878L5.25 9.18628'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.4375 0.436279V13.5613'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
));

export const MoveRight = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.9375 6.99878H0.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.75 4.81128L10.9375 6.99878L8.75 9.18628'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5625 0.436279V13.5613'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
));

export const ChevronDown = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.5625 4.26477L7.30917 10.5175C7.26859 10.5582 7.22039 10.5904 7.16734 10.6124C7.11429 10.6344 7.05743 10.6457 7 10.6457C6.94257 10.6457 6.8857 10.6344 6.83266 10.6124C6.77961 10.5904 6.73141 10.5582 6.69083 10.5175L0.4375 4.26477'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
));
