import React, { SVGProps } from 'react';

export const Sort9to0SVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.6799 4.0625C10.8523 4.0625 11.023 4.02855 11.1822 3.96259C11.3414 3.89663 11.4861 3.79995 11.608 3.67808C11.7299 3.5562 11.8266 3.41151 11.8925 3.25227C11.9585 3.09303 11.9924 2.92236 11.9924 2.75C11.9924 2.57764 11.9585 2.40697 11.8925 2.24773C11.8266 2.08849 11.7299 1.9438 11.608 1.82192C11.4861 1.70005 11.3414 1.60337 11.1822 1.53741C11.023 1.47145 10.8523 1.4375 10.6799 1.4375C10.3318 1.4375 9.998 1.57578 9.75185 1.82192C9.50571 2.06806 9.36743 2.4019 9.36743 2.75C9.36743 3.0981 9.50571 3.43194 9.75185 3.67808C9.998 3.92422 10.3318 4.0625 10.6799 4.0625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.2424 6.68633C10.7066 6.68633 11.1517 6.50196 11.4799 6.17377C11.8081 5.84558 11.9924 5.40046 11.9924 4.93633V2.75'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.47705 14.5586H12.7577'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.1172 14.562V9.64097C11.1174 9.57917 11.1 9.51859 11.0672 9.46622C11.0344 9.41386 10.9874 9.37184 10.9317 9.34501C10.8761 9.31818 10.8139 9.30763 10.7525 9.31458C10.6911 9.32153 10.6329 9.3457 10.5847 9.3843L9.47632 10.2692'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.99243 3.625V12.375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.24243 10.625L4.99243 12.375L6.74243 10.625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
