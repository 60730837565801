import * as React from 'react';

import { AppLoader } from '@components/AppLoader';
import { renderComp } from '@components/utils';

import { PlanFeatures } from './components/PlanFeatures';
import { PlansApp } from './PlansApp';

export function mount(): void {
  renderComp('plans-page', () => (
    <AppLoader>
      <PlansApp />
    </AppLoader>
  ));

  renderComp('plan-info-list', ({ plan }) => {
    return <PlanFeatures plan={plan} expanded />;
  });
}
