import { CustomLabel } from 'components/ScreenerResults/ScreenerChart/components/shared';
import * as React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { formatBarLabels } from '@components/ScreenerResults/ScreenerChart/helpers';
import Tippy from '@tippyjs/react';

import { ChartDataPoint } from '../../types';

const HORIZONTAL_AXIS_STYLE = { axis: { stroke: 'none' }, axisLabel: { fontSize: 14, fontFamily: 'inherit' } };

const HORIZONTAL_BAR_STYLE = {
  data: {
    fill: '#E5EDFF',
    width: 32
  },
  labels: {
    fontSize: 12,
    fontFamily: 'inherit'
  }
};

export const HorizontalBarChart: React.FC<{ data: ChartDataPoint[]; total: number }> = ({ data: rawData, total }) => {
  // work out what this formula should be
  const data = rawData.sort(({ y: aY }, { y: bY }) => (aY > bY ? -1 : 1));
  const height = data.length * 32 + (data.length - 1) * 16;

  return (
    <div>
      <VictoryChart domainPadding={{ x: 16 }} height={height} padding={0} style={{ parent: { height } }}>
        <VictoryBar
          animate
          horizontal
          barRatio={0.5}
          barWidth={32}
          cornerRadius={8}
          style={HORIZONTAL_BAR_STYLE}
          data={data}
          labels={({ datum }) => formatBarLabels(datum, total)}
          padding={0}
        />
        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={HORIZONTAL_AXIS_STYLE}
          tickValues={[1, 2, 3]}
          tickLabelComponent={<CustomLabel />}
          tickFormat={data.map(({ x }) => x)}
        />
      </VictoryChart>
    </div>
  );
};
