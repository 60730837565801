import qs from 'qs';
import * as React from 'react';
import { useState } from 'react';

import { BetaFlag, Button, Heading, HelperText, Loading, Select, SelectOption, Text } from '@components/common';
import { ChevronLeftSVG } from '@components/svgs';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { useFeature } from '@hooks/useFeature';
import { useToaster } from '@stores/toaster';

import { MappingTable } from '../components/MappingTable';
import { PageLayout } from '../components/PageLayout';
import { useCustomerImport } from '../hooks/useCustomerImport';
import * as toasts from '../toasts';
import { hasMultipleUniqueIds, isMissingUniqueId, getUnmappedColumns } from '../utils';

const EMAIL_OPTION: SelectOption = { label: 'Email', value: 'email' };
export const uniqueIdOptions: (attrs: Attr_[]) => SelectOption[] = (attrs) => {
  return [EMAIL_OPTION].concat(attrs.filter((a) => a.unique).map((a) => ({ label: a.name, value: `extra:${a.name}` })));
};

export const ClassifyStep: React.FC = () => {
  const hook = useCustomerImport();
  const { allAttrs } = useCandidateAttrs();
  const { id, loading, model, save } = hook;
  const { id_attr = 'email' } = model || {};
  const { study_id } = qs.parse(location.search.slice(1));

  const hasUniqueCustomAttrs = useFeature('unique_custom_attrs');

  const [errors, setErrors] = useState<number[]>([]); // list of indexes that have errors

  const showToast = useToaster();

  const setIdAttr = (id_attr: string) => {
    const newAttrs: Partial<CustomerImport> = { id_attr };
    if (id_attr.startsWith('extra:')) {
      newAttrs.update_existing = true;
    }
    save({ ...(model as CustomerImport), ...newAttrs });
  };

  function handleBeforeNext() {
    if (!model) {
      return false;
    }

    const newErrors = getUnmappedColumns(model);
    setErrors(newErrors);

    if (newErrors.length > 0) {
      showToast(toasts.MISSING_FIELD);
      return false;
    }

    const requiredUniqueId = isMissingUniqueId(model);

    if (requiredUniqueId) {
      showToast(toasts.UNIQUE_ID_REQUIRED(requiredUniqueId));
      return false;
    }
    if (hasMultipleUniqueIds(model)) {
      showToast(toasts.MULTIPLE_UNIQUE_IDS);
      return false;
    }
    return true;
  }

  return (
    <PageLayout
      hook={hook}
      step={2}
      className='flex flex-col flex-1 pb-20'
      prev={
        <Button href={`/customer_imports/new${study_id ? `?study_id=${study_id}` : ''}`}>
          <ChevronLeftSVG className='mr-2' /> Back
        </Button>
      }
      next={`/customer_imports/${id}/manage${study_id ? `?study_id=${study_id}` : ''}`}
      beforeNext={handleBeforeNext}
    >
      {loading && <Loading />}
      {model && (
        <div className='py-gutter flex flex-col flex-1'>
          <div className='max-w-2xl mx-auto'>
            <Heading as='h2' className='mx-4 mb-2'>
              Classify the information you’re importing
            </Heading>
            <Text className='mx-4 mb-4'>
              Assign existing attributes or create new attributes for each column of information. Unselect any columns
              that you don’t need imported.
            </Text>
            {hasUniqueCustomAttrs && (
              <div className='max-w-md mx-4 mb-4'>
                <div className='flex items-center mb-2 space-x-2'>
                  <Text bold>Unique identifier</Text>
                  <BetaFlag />
                </div>

                <HelperText>
                  What attribute should we use to match candidates to your existing database? Only custom attributes set
                  as unique can be used.
                </HelperText>
                <Select
                  className='max-w-sm bg-white'
                  options={uniqueIdOptions(allAttrs)}
                  value={model.id_attr}
                  onChange={setIdAttr}
                />
              </div>
            )}
          </div>
          <MappingTable
            model={model}
            save={async (m) => {
              if (errors.length > 0) {
                const newErrors = getUnmappedColumns(m);
                setErrors(newErrors);
                if (newErrors.length > 0) {
                  showToast(toasts.MISSING_FIELD);
                }
              }
              await save(m);
            }}
            erroredColumnIndexes={errors}
          />
        </div>
      )}
    </PageLayout>
  );
};
