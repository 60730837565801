import TiptapHeading from '@tiptap/extension-heading';

export const HEADING_STYLES = {
  1: 'font-medium leading-11 text-3xl mb-5',
  2: 'font-medium leading-8 text-2xl mb-4',
  3: 'font-medium leading-7 text-xl mb-4',
  4: 'font-medium leading-6 text-base mb-2',
  5: 'font-medium leading-5 text-sm mb-2',
  6: 'font-medium leading-4 text-xs mb-2'
};

export const Heading = TiptapHeading.extend({
  addAttributes() {
    return {
      level: {
        default: 1,
        renderHTML: (attributes) => {
          return {
            class: HEADING_STYLES[attributes.level]
          };
        }
      }
    };
  }
});
