import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { DropdownCombobox } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';

export type RenderItem = (item: DropdownItem, isHighlighted: boolean) => React.ReactElement;

interface Props {
  label?: string;
  inputClassName?: string;
  className?: string;
  options: DropdownItem[];
  onSelect: (val: string) => void | Promise<void>;
  defaultSelected?: string;
  renderItem?: RenderItem;
  placeholder?: string;
}
const CheckedSvg = () => (
  <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600'>
    <svg className='w-5 h-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
      <path
        fillRule='evenodd'
        d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
        clipRule='evenodd'
      />
    </svg>
  </span>
);

const renderDefaultItem: (selected?: string) => RenderItem = (selected) => (item) => {
  const { disabled, value, text, label } = item;

  const optionClassName = cn(
    'xx-study-dropdown-study text-gray-900 hover:text-white hover:bg-indigo-600  select-none relative py-2 pl-3 pr-9',
    { 'opacity-50 cursor-not-allowed': disabled, 'cursor-pointer': !disabled }
  );

  const labelClassName = cn('block truncate', {
    'font-semibold': selected && selected == value,
    'font-normal': !selected && selected == value
  });

  return (
    <div className={optionClassName}>
      <span className={labelClassName}>{label}</span>
      {text && <span className='text-xs text-gray-500'>{text}</span>}
      {selected && selected === value && CheckedSvg}
    </div>
  );
};

const SearchSelector: React.FC<Props> = ({
  placeholder = 'Please select…',
  inputClassName,
  label,
  className,
  options,
  onSelect,
  defaultSelected,
  renderItem
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  const selectedOption = selected ? options.find(({ value }) => selected.toString() === value.toString()) : undefined;

  const handleSelect = (item: DropdownItem | null): void => {
    if (!item?.disabled && item) {
      setSelected(item.value);
      onSelect(item.value);
    }
  };

  return (
    <div className={className}>
      {label && <label className='mb-1'>{label}</label>}
      <DropdownCombobox
        items={options}
        submitOnEnter
        inputClassName={inputClassName}
        selectedItem={selectedOption}
        placeholder={placeholder}
        onSelect={handleSelect}
        renderItem={renderItem ? renderItem : renderDefaultItem(selectedOption?.value)}
      />
    </div>
  );
};

export default SearchSelector;
