import cn from 'classnames';
import * as React from 'react';

import { getBackgroundColor } from '@components/tags/colors';

type Props = {
  className?: string;
  color?: Tag['color'];
};
export const Swatch: React.FC<Props> = ({ className, color = 'default' }) => (
  <div className={cn('w-4 h-4 rounded', className)} style={{ backgroundColor: getBackgroundColor(color, 0.2) }} />
);
