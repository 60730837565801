import { Route, Routes } from 'react-router-dom';
import * as React from 'react';
import { Navigate } from 'react-router';
import { Compliance, DataRetention, Eligibility, Permissions } from '@components/GovernanceApp/components';
import { usePermission } from '@hooks/usePermission';

export const GovernanceApp: React.FC = () => {
  const isAdmin = usePermission('isAdmin')();

  return (
    <div className='w-full h-full flex flex-col'>
      <Routes>
        <Route path='eligibility' element={<Eligibility canEdit={isAdmin} />} />
        {isAdmin && <Route path='data_retention' element={<DataRetention />} />}
        {isAdmin && <Route path='permissions' element={<Permissions />} />}
        <Route path='compliance' element={<Compliance />} />
        <Route path='*' element={<Navigate replace to='eligibility' />} />
      </Routes>
    </div>
  );
};

