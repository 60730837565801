import React, { SVGProps } from 'react';

export const InfoCircleIcon = ({ className = '' }) => {
  return (
    <svg
      className={`stroke-current ${className}`}
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.0625 15.125H12.375C12.0103 15.125 11.6606 14.9801 11.4027 14.7223C11.1449 14.4644 11 14.1147 11 13.75V10.3125C11 10.1302 10.9276 9.9553 10.7986 9.82636C10.6697 9.69743 10.4948 9.625 10.3125 9.625H9.625'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6562 6.1875C10.5883 6.1875 10.5218 6.20766 10.4653 6.24543C10.4087 6.2832 10.3647 6.33689 10.3387 6.3997C10.3126 6.46251 10.3058 6.53163 10.3191 6.59831C10.3324 6.66499 10.3651 6.72624 10.4132 6.77432C10.4613 6.82239 10.5225 6.85513 10.5892 6.86839C10.6559 6.88166 10.725 6.87485 10.7878 6.84883C10.8506 6.82282 10.9043 6.77876 10.9421 6.72223C10.9798 6.6657 11 6.59924 11 6.53125C11 6.44008 10.9638 6.35265 10.8993 6.28818C10.8349 6.22372 10.7474 6.1875 10.6562 6.1875Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.6875 11C0.6875 13.735 1.77399 16.3581 3.70796 18.292C5.64193 20.226 8.26496 21.3125 11 21.3125C13.735 21.3125 16.3581 20.226 18.292 18.292C20.226 16.3581 21.3125 13.735 21.3125 11C21.3125 8.26496 20.226 5.64193 18.292 3.70796C16.3581 1.77399 13.735 0.6875 11 0.6875C8.26496 0.6875 5.64193 1.77399 3.70796 3.70796C1.77399 5.64193 0.6875 8.26496 0.6875 11V11Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
