import React, { VFC } from 'react';

import { PageTitle } from '@components/common/helmets';

import { Header } from '../components/Header';

export const Index: VFC = () => {
  return (
    <section>
      <PageTitle>AI Search</PageTitle>
      <Header />
    </section>
  );
};
