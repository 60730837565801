import qs from 'qs';
import * as React from 'react';

import { api } from '@api/reduxApi';
import { Button, Heading, Loading, Text, Tooltip } from '@components/common';
import { compact } from '@components/utils';
import { useFeature } from '@hooks/useFeature';
import { useTeams } from '@hooks/useTeams';
import { useToaster } from '@stores/toaster';

import { PageLayout } from '../components/PageLayout';
import { useCustomerImport } from '../hooks/useCustomerImport';
import * as toasts from '../toasts';

export const ImportStep: React.FC = () => {
  const showToast = useToaster();
  const hook = useCustomerImport();
  const { id, loading, model } = hook;
  const { study_id } = qs.parse(location.search.slice(1));
  const { id_attr } = model || {};

  const [runCustomerImport, { isLoading }] = api.useRunCustomerImportMutation();

  const displayIdAttr = (id_attr || 'email').replace('extra:', '');

  const enableTeams = useFeature('teams');
  const { findTeam, teams } = useTeams();
  const teamNames =
    teams && model?.contact_access === 'needs_team' && model.team_ids_str
      ? model.team_ids_str
          .split(',')
          .map(Number)
          .map(findTeam)
          .map((t) => t?.name)
          .filter(Boolean)
      : [];
  async function handleSubmit() {
    try {
      const { message } = await runCustomerImport({ id }).unwrap();

      showToast(toasts.SUCCESS(message));
      window.location.href = study_id ? `/studies/${study_id}/participations?status=shortlisted` : '/candidates';
    } catch (_) {
      showToast(toasts.FAILED_RUN);
    }
  }

  return (
    <PageLayout
      hook={hook}
      className='pb-24'
      step={4}
      prev={`/customer_imports/${id}/manage${study_id ? `?study_id=${study_id}` : ''}`}
      next={
        <Button disabled={isLoading} primary onClick={handleSubmit}>
          Confirm &amp; import
        </Button>
      }
    >
      {loading && <Loading />}
      {model && (
        <div className='px-page py-gutter max-w-3xl mx-auto'>
          <Heading className='mb-6'>Review and complete import</Heading>
          <Text h='600' className='mb-6 font-bold'>
            Import preview
          </Text>
          {model.can_create_new && (
            <div className='flex items-center justify-between mb-4'>
              <div className='flex items-center space-x-2'>
                <Text>New candidates</Text>
                <Tooltip
                  content={`Candidates who don't already exist in your database based on their ${displayIdAttr}.`}
                />
              </div>
              <Text>{model.new_candidates_count}</Text>
            </div>
          )}
          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center space-x-2'>
              <Text>Existing candidates</Text>
              <Tooltip content={`Candidates who already exist in your database based on their ${displayIdAttr}.`} />
            </div>
            <Text>{model.existing_candidates_count}</Text>
          </div>
          <div className='flex items-center justify-between mb-4'>
            {model.can_create_new ? (
              <div className='flex items-center space-x-2'>
                <Text>Invalid candidates</Text>
                <Tooltip content='Rows with invalid email addresses.' />
              </div>
            ) : (
              <div className='flex items-center space-x-2'>
                <Text>Unknown candidates</Text>
                <Tooltip content='Rows that cannot be matched will not be imported into Great Question.' />
              </div>
            )}
            <Text>{model.invalid_candidates_count}</Text>
          </div>
          <hr className='my-4'></hr>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center space-x-2'>
              <Text bold>Total candidates</Text>
            </div>
            <Text bold>{model.existing_candidates_count + model.new_candidates_count}</Text>
          </div>
          <hr className='my-4'></hr>
          {model.project_id && model.skip_ineligible && (
            <>
              <div className='flex items-center justify-between mb-4'>
                <div className='flex items-center space-x-2'>
                  <Text>Ineligible candidates</Text>
                  <Tooltip content='Candidates who don’t meet the eligibility requirements defined in your workspace settings.' />
                </div>
                <Text>{model.ineligible_candidates_count}</Text>
              </div>
              <hr className='my-4'></hr>
            </>
          )}

          {model.project_id && (
            <>
              <div className='flex items-center justify-between mb-4'>
                <div className='flex items-center space-x-2'>
                  <Text bold>Total to be shortlisted</Text>
                </div>
              </div>
              <Text>You selected to exclude ineligible candidates from the shortlist.</Text>
              <hr className='my-4'></hr>
            </>
          )}

          <Text h='600' className='mb-6 font-bold'>
            Import settings
          </Text>

          <ul className='space-y-6 list-disc list-inside'>
            {compact([
              {
                label: 'Import method',
                value: model.update_existing
                  ? 'Update existing and add new information'
                  : 'Don’t overwrite any existing candidates'
              },
              model.name && { label: 'Import name', value: model.name },
              model.project_id && {
                label: 'Studies',
                value: `Shortlisted${model.skip_ineligible ? ' (excluding ineligible people)' : ''}`
              },
              enableTeams && {
                label: 'Contact Access',
                value:
                  model.contact_access === 'public'
                    ? 'Anyone on the workspace'
                    : `Only specific teams${teamNames?.length > 0 && ` – ${teamNames.join(', ')}`}`
              }
            ]).map((item, i) => (
              <li key={i} className='h700'>
                {item && (
                  <>
                    <Text className='inline' bold>
                      {item.label}:{' '}
                    </Text>
                    <Text className='inline'>{item.value}</Text>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </PageLayout>
  );
};
