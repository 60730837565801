import { CSSProperties, useMemo } from 'react';

import { useDrawerContext } from './useDrawerContext';

type Styles = {
  styles: CSSProperties;
};

export const useStyles = (): Styles => {
  const {
    state: { headerHeight, contentHeight, isExpanded, isDragging, transformY }
  } = useDrawerContext();

  const styles = useMemo(() => {
    const defaultStyles: CSSProperties = {};

    if (headerHeight && contentHeight) {
      defaultStyles.maxHeight = `${headerHeight + contentHeight}px`;
    }

    if (isDragging) {
      defaultStyles.transition = 'none';
    } else {
      defaultStyles.transition = 'transform 200ms ease';
    }

    if (isExpanded) {
      defaultStyles.transform = `translate3d(0, ${transformY}px, 0)`;
    } else {
      defaultStyles.transform = `translate3d(0, calc(100% - ${headerHeight}px + ${transformY}px), 0)`;
    }

    return defaultStyles;
  }, [headerHeight, contentHeight, isExpanded, isDragging, transformY]);

  return { styles };
};
