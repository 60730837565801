import * as Icons from '../../icons';

export const TABLE_ACTIONS = [
  {
    desc: 'Add table',
    Icon: Icons.AddTable,
    func: (editor) => () => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
  },
  {
    desc: 'Add column before',
    Icon: Icons.AddColumnBefore,
    func: (editor) => () => editor.chain().focus().addColumnBefore().run()
  },
  {
    desc: 'Add column after',
    Icon: Icons.AddColumnAfter,
    func: (editor) => () => editor.chain().focus().addColumnAfter().run()
  },
  {
    desc: 'Delete column',
    Icon: Icons.DeleteColumn,
    func: (editor) => () => editor.chain().focus().deleteColumn().run()
  },
  {
    desc: 'Add row before',
    Icon: Icons.AddRowBefore,
    func: (editor) => () => editor.chain().focus().addRowBefore().run()
  },
  {
    desc: 'Add row after',
    Icon: Icons.AddRowAfter,
    func: (editor) => () => editor.chain().focus().addRowAfter().run()
  },
  { desc: 'Delete row', Icon: Icons.DeleteRow, func: (editor) => () => editor.chain().focus().deleteRow().run() },
  { desc: 'Delete table', Icon: Icons.DeleteTable, func: (editor) => () => editor.chain().focus().deleteTable().run() },
  { desc: 'Merge cells', Icon: Icons.MergeCells, func: (editor) => () => editor.chain().focus().mergeCells().run() },
  { desc: 'Split cell', Icon: Icons.SplitCell, func: (editor) => () => editor.chain().focus().splitCell().run() },
  {
    desc: 'Toggle header column',
    Icon: Icons.ToggleHeaderCol,
    func: (editor) => () => editor.chain().focus().toggleHeaderColumn().run()
  },
  {
    desc: 'Toggle header row',
    Icon: Icons.ToggleHeaderRow,
    func: (editor) => () => editor.chain().focus().toggleHeaderRow().run()
  },
  {
    desc: 'Toggle header cell',
    Icon: Icons.ToggleHeaderCell,
    func: (editor) => () => editor.chain().focus().toggleHeaderCell().run()
  },
  {
    desc: 'Merge or split cell',
    Icon: Icons.MergeOrSplitCell,
    func: (editor) => () => editor.chain().focus().mergeOrSplit().run()
  },
  { desc: 'Fix tables', Icon: Icons.FixTable, func: (editor) => () => editor.chain().focus().fixTables().run() }
];

export const COLUMN_ACTIONS = [
  {
    desc: 'Set 1 columns layout',
    Icon: Icons.Add1Column,
    func: (editor) => () => editor.chain().focus().setColumns(1).run()
  },
  {
    desc: 'Set 2 columns layout',
    Icon: Icons.Add2Columns,
    func: (editor) => () => editor.chain().focus().setColumns(2).run()
  },
  {
    desc: 'Set 3 columns layout',
    Icon: Icons.Add3Columns,
    func: (editor) => () => editor.chain().focus().setColumns(3).run()
  },
  {
    desc: 'Set 4 columns layout',
    Icon: Icons.Add4Columns,
    func: (editor) => () => editor.chain().focus().setColumns(4).run()
  },
  {
    desc: 'Remove columns',
    Icon: Icons.RemoveColumns,
    func: (editor) => () => editor.chain().focus().unsetColumns().run()
  }
];
