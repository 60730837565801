import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '@api/reduxApi';
import { ScreenSharingProvider } from '@components/ScreenSharingProvider';
import { api as surveyBuilderApi } from '@components/SurveyBuilder';
import { Unmoderated } from '@components/Unmoderated';
import { inferPermissionsFromBlocks } from '@components/Unmoderated/utils';
import { useAccount } from '@hooks/useAccount';
import { useBrand } from '@hooks/useBrand';
import { usePlan } from '@hooks/usePlan';
import { useSidebar } from '@hooks/useSidebar';
import { useTeams } from '@hooks/useTeams';

import { Skeleton } from './components/Skeleton';
import { GQSurvey } from '../GQSurvey';

interface Props {
  studyId: number;
  screenerType: ScreenerType;
}

export const GQSurveyPreview: React.FC<Props> = ({ studyId, screenerType }) => {
  const { data: study, isLoading } = api.useGetSimpleStudyQuery(studyId);
  const { data: blocks, isLoading: isLoadingSurveyBlocks } = surveyBuilderApi.useGetSurveyBuilderBlocksQuery(studyId, {
    skip: !study || study.style !== 'unmoderated_test'
  });

  useBrand();

  const { account } = useAccount();
  const { teams } = useTeams();

  const { t } = useTranslation('GQSurvey');

  const currentPlan = usePlan();

  const sidebar = useSidebar();

  useEffect(() => {
    sidebar.setHidden(true);
  }, []);

  const screener = useMemo<Screener | null>(() => {
    if (!study) {
      return null;
    }
    if (screenerType === 'pre') {
      return study.pre_screener;
    }
    if (screenerType === 'survey') {
      return study.survey_screener;
    }
    return null;
  }, [study, screenerType, study?.pre_screener, study?.survey_screener]);

  if (!study) return null;
  if (!screener) return null;

  if (isLoading || isLoadingSurveyBlocks) return <Skeleton />;

  if (study.style === 'unmoderated_test' && screenerType === 'survey' && blocks?.length) {
    return (
      <div className='brand'>
        <ScreenSharingProvider permissions={inferPermissionsFromBlocks(blocks)} device={study.device_type}>
          <Unmoderated blocks={blocks} surveyId={study.id} deviceType={study.device_type} />
        </ScreenSharingProvider>
      </div>
    );
  }

  const hasTeam = !!study.team_id;

  const team = teams?.find(({ id }) => id === study.team_id);

  const logoUrl = hasTeam ? team?.brand?.logo : account.logo;

  return (
    <div className='relative flex flex-col flex-1 w-full h-full pt-16 bg-white'>
      <div className='tablet:absolute tablet:z-10 tablet:flex-row tablet:border-b top-0 flex flex-col items-center justify-center w-full p-4 text-center bg-white border-gray-200'>
        {logoUrl && (
          <div className='tablet:absolute tablet:mb-0 tablet:ml-10 left-0 mb-4'>
            <img alt='Company logo' src={logoUrl} className='block h-8' />
          </div>
        )}
        <span className='text-custom-brand text-xl font-bold'>
          {t('help_us_build')} {account.name}
        </span>
      </div>
      <div className='flex-1'>
        <GQSurvey
          preview
          study={study}
          screener={screener}
          hidden={false}
          account={{
            name: account.name,
            logo: account.logo,
            unique_id_attr: account.unique_id_attr,
            plan_features: currentPlan.features,
            hide_global_opt_out_candidate_ux: account.hide_global_opt_out_candidate_ux
          }}
        />
      </div>
      <div className='flex-0 monitor:px-36 tablet:px-16 desktop:px-20 monitor:px-24 tablet:pb-10 tablet:border-0 tablet:items-end flex items-center justify-between w-full px-10 py-4 text-center bg-white border-t border-gray-200'>
        <p className='text-custom-brand h400 capitalize'>* Required</p>
        <div className='inline-block'>
          <a href='greatquestion.co' className='xx-powered-by flex flex-col justify-center text-center'>
            <span className='text-xs font-light text-gray-500 uppercase'>Powered by</span>
            <img src='/temp-assets/logo.png' className='w-auto h-6' alt='Great Question' />
          </a>
        </div>
      </div>
    </div>
  );
};
