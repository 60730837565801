import * as React from 'react';
import { forwardRef } from 'react';

import { Text, TimeSelect } from '@components/common';
import { XSVG } from '@components/svgs';
import { useTimeSelect } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/AvailabilityPanel/components/hooks/useTimeSelect';
import { RecurringSelect } from '../../RecurringSelect';
import {
  getDateFromString,
  getTime,
  NormalizedSlot
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';

export type BlockMode = 'availability' | 'session';

export interface Props {
  blockMode: BlockMode;
  studyDuration: number;
  slot: NormalizedSlot<SlotInstance>;
  onChange: (params: Partial<SlotInstance>) => void;
  onRemove: React.MouseEventHandler;
  schedulingIncrement: number;
  closePopper: () => void;
  date: string;
}

export const HoveringSlotControls = forwardRef<HTMLDivElement, Props>(
  ({ blockMode, slot, onChange, onRemove, schedulingIncrement, closePopper, date }, ref) => {
    const {
      onChangeStartTime,
      onChangeEndTime,
      error,
      currentStartMin,
      currentStartHour,
      currentEndMin,
      currentEndHour
    } = useTimeSelect({ slot: slot.original, onChange });

    return (
      <div
        ref={ref}
        tabIndex={-1}
        role='menu'
        className='font-normal text-left'
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='p-4 bg-white rounded-md shadow-lg'>
          <div className='flex justify-between mb-2'>
            <span className='text-sm font-bold text-gray-700'>
              {blockMode === 'availability' ? 'Availability block' : 'Session'}
            </span>
            <button
              aria-label='Close popup'
              className='h400 rounded-full hover:bg-gray-50 p-1 transition duration-150 ease-in-out'
              onClick={closePopper}
            >
              <XSVG className='w-4 h-4 text-gray-700' />
            </button>
          </div>
          <div className='flex flex-row space-x-2'>
            <TimeSelect
              minHour={0}
              maxHour={24}
              increment={schedulingIncrement}
              initialValue={{ hour: currentStartHour, min: currentStartMin }}
              onChange={(time) => onChangeStartTime(getTime(time))}
              label='start time'
            />
            <span className='self-center text-sm text-gray-700'>to</span>
            <TimeSelect
              addOneMoreOption
              minHour={0}
              maxHour={24}
              increment={schedulingIncrement}
              initialValue={{ hour: currentEndHour, min: currentEndMin }}
              onChange={(time) => onChangeEndTime(getTime(time))}
              label='end time'
            />
          </div>
          {error && (
            <Text color='red-600' h='200' className='w-full mt-2'>
              Start time must be before end time
            </Text>
          )}

          <div className='flex flex-col items-start mt-4'>
            <RecurringSelect style='select' slot={slot} updateSlot={onChange} date={getDateFromString(date)} />

            <button className='h400 mt-3' onClick={onRemove}>
              Remove
            </button>
          </div>
        </div>
      </div>
    );
  }
);
