import React, { SVGProps } from 'react';

export const MergeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.2188 13.063C10.151 13.063 8.46878 11.3817 8.46878 9.31506V3.79361L10.25 5.57385L10.913 4.91129L8.00005 2L5.08714 4.91129L5.74998 5.57385L7.53131 3.79361V9.31506C7.53131 11.3817 5.84901 13.063 3.78123 13.063H2V14H3.78123C5.63438 14 7.23964 12.9196 8.00005 11.356C8.76036 12.9197 10.3656 14 12.2188 14H14V13.063H12.2188Z'
      fill='currentColor'
    />
  </svg>
);
