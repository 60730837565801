import React from 'react';

/* eslint-disable no-useless-escape */

export const isUrl = (word) => {
  const urlPattern =
    /(([a-z]+:\/\/)?(([a-z0-9\-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-\.~]+)*(\/([a-z0-9_\-\.]*)(\?[a-z0-9+_\-\.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi;
  return word.match(urlPattern);
};

export const LinkifiedText: React.FC<{ text?: string }> = ({ text }) => {
  if (!text) {
    return null;
  }

  const addMarkup = (word) => {
    const link = word.startsWith('http') ? word : `https://${word}`;

    return isUrl(word) && !word.startsWith('javascript:') ? `<a target='_blank' href='${link}'>${word}</a>` : word;
  };

  const words = text.split(' ');
  const formatedWords = words.map(addMarkup);
  const html = formatedWords.join(' ');

  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
