import { useCombobox, UseComboboxReturnValue, useMultipleSelection, UseMultipleSelectionReturnValue } from 'downshift';
import { useEffect, useMemo, useState } from 'react';

import { AI } from '@api/chat-gpt';
import { compact, uniq, without } from '@components/utils';

import {
  buildManageTagsItem,
  buildNameAndCreateLinkItem,
  buildTagGroupItem,
  buildTagItem as baseBuildTagItem,
  buildTagItemId,
  Item,
  ItemWithTagGroup
} from './items';

export type Params = {
  closeOnEsc?: boolean;
  suggest?: AI.UseQueryResult<string>;
  canManage?: boolean;
  hideManagerLinks?: boolean;
  text?: string;
  tags: Tag[];
  tagGroups?: TagGroup[];
  initialSelectedTagIds?: number[];
  studyId?: number | null;
  onAddTag: (tag: Tag) => void;
  onRemoveTag: (tag: Tag) => void;
  onCreateTag?: (name: string, tagGroupId?: number) => Promise<Tag | undefined>;
};
export type Hook = UseMultipleSelectionReturnValue<Item> &
  UseComboboxReturnValue<Item> & {
    inputValue: string;
    visibleItems: Item[];
    showCreateLink: () => boolean;
    toggleGroupOpen: (id: string) => void;
    mode: 'study' | 'global';
    setMode: (mode: 'study' | 'global') => void;
  };
export const useMultiselectTags = (params: Params): Hook => {
  const [mode, setMode] = useState<'study' | 'global'>(params.studyId ? 'study' : 'global');
  const [inputValue, setInputValue] = useState('');
  const [selectedTagGroup, setSelectedTagGroup] = useState<TagGroup>();

  const { canManage = true, hideManagerLinks = false } = params;
  const [openGroups, setOpenGroups] = useState<string[]>([]);
  const toggleGroupOpen = (id: string) => {
    setOpenGroups(openGroups.includes(id) ? without(openGroups, id) : [...openGroups, id]);
  };

  const suggestedTags = useMemo(() => params?.suggest?.map(({ result }) => result) || [], [params?.suggest]);
  const buildTagItem = (tag: Tag) => baseBuildTagItem(tag, suggestedTags.includes(tag.name));

  const multipleSelection = useMultipleSelection<Item>({ initialSelectedItems: [] });
  const { addSelectedItem, removeSelectedItem, selectedItems, setSelectedItems } = multipleSelection;

  const selectedIds = selectedItems.map((s) => s.id);

  const tags = useMemo(() => {
    const studyTags = params.tags.filter((tag) => tag.project_id && tag.project_id === params.studyId);
    const globalTags = params.tags.filter((tag) => !tag.project_id);
    return [...studyTags, ...globalTags];
  }, [params.tags, params.studyId]);

  const showCreateLink = () =>
    canManage &&
    !!params.onCreateTag &&
    inputValue !== '' &&
    inputValue.length >= 3 &&
    !tags
      .filter((t) => t.project_id == params.studyId)
      .some(({ name }) => name.toLowerCase() === inputValue.toLowerCase());

  let searchResults = tags
    .filter(
      (item) =>
        !selectedIds.includes(buildTagItemId(item.project_id, item.name)) &&
        item.name.toLowerCase().includes(inputValue.toLowerCase())
    )
    .sort(params.studyId ? (a, b) => (a.project_id === params?.studyId ? -1 : 1) : () => 0);
  const isActiveSearch = inputValue !== '' && searchResults.length > 0;
  const tagGroupCounts = useMemo<Record<number, number>>(
    () =>
      tags.reduce((acc, tag) => {
        if (tag.tag_group_id && selectedIds.includes(buildTagItemId(tag.project_id, tag.name))) {
          if (!acc[tag.tag_group_id]) {
            acc[tag.tag_group_id] = 0;
          }
          acc[tag.tag_group_id]++;
        }
        return acc;
      }, {}),
    [selectedItems, tags, params.tagGroups]
  );

  const tagGroupItems = compact(
    uniq(searchResults.map((tag) => tag.tag_group_id)).map((tagGroupId) => {
      const tagGroup = params.tagGroups
        ?.filter(({ project_id }) => (mode === 'study' ? project_id === params.studyId : !project_id))
        .find(({ id }) => id === tagGroupId);
      return (
        tagGroup &&
        buildTagGroupItem(tagGroup, openGroups.includes(String(tagGroup.id)), tagGroupCounts[tagGroup.id] || 0)
      );
    })
  ) as ItemWithTagGroup[];
  const visibleItems: Item[] = [];
  visibleItems.push(
    ...selectedItems
      .filter((item) => item.type === 'with_tag' && item.tag.name.toLowerCase().includes(inputValue.toLowerCase()))
      .filter(
        (item) =>
          item.type !== 'with_tag' || (mode === 'study' ? item.tag.project_id === params.studyId : !item.tag.project_id)
      )
  );
  for (const tagGroupItem of tagGroupItems) {
    visibleItems.push(tagGroupItem);
    const tagsInThisGroup = searchResults.filter((t) => t.tag_group_id === tagGroupItem.tagGroup.id);
    if (tagGroupItem.open || isActiveSearch) {
      visibleItems.push(...tagsInThisGroup.map(buildTagItem));
    }
    searchResults = searchResults.filter((t) => !tagsInThisGroup.includes(t));
  }

  const alphaCompare = (a: Tag, b: Tag) => {
    if (params.suggest) {
      if (suggestedTags.includes(a.name) && !suggestedTags.includes(b.name)) {
        return -1;
      }
      if (!suggestedTags.includes(a.name) && suggestedTags.includes(b.name)) {
        return 1;
      }
    }
    return a.name.localeCompare(b.name);
  };

  if (mode === 'study') {
    const studyTags = searchResults.filter((r) => r.project_id === params.studyId);
    visibleItems.push(...studyTags.sort(alphaCompare).map(buildTagItem));
  } else {
    const globalTags = searchResults.filter((r) => !r.project_id);
    visibleItems.push(...globalTags.sort(alphaCompare).map(buildTagItem));
  }

  if (showCreateLink()) {
    visibleItems.push(buildNameAndCreateLinkItem(inputValue));
    // visibleItems.push(buildGroupPickerItem(selectedTagGroup || null));
  }
  if (canManage && !hideManagerLinks) {
    visibleItems.push(buildManageTagsItem());
  }

  useEffect(() => {
    const { initialSelectedTagIds } = params;

    if (!tags.length || !initialSelectedTagIds?.length) return;

    const initialSelectedTags = initialSelectedTagIds.map((id) => tags.find((t) => t.id === id));

    const selectedTags = compact(initialSelectedTags).map(buildTagItem);

    setSelectedItems(selectedTags);
  }, [tags, params.initialSelectedTagIds, params.studyId]);

  const combobox = useCombobox({
    inputValue,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    items: visibleItems,
    stateReducer: (_state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return { ...changes, isOpen: true };
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
          return { ...changes, isOpen: !(changes.highlightedIndex === visibleItems.length - 1) };
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          if (!params.closeOnEsc) {
            return { ...changes, isOpen: true };
          }
          break;
      }
      return changes;
    },
    onStateChange: async ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '');
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem?.type === 'with_tag') {
            setInputValue('');
            if (selectedIds.includes(selectedItem.id)) {
              removeSelectedItem(selectedItem);
              params.onRemoveTag(selectedItem.tag);
            } else {
              addSelectedItem(selectedItem);
              params.onAddTag(selectedItem.tag);
            }
          }
          if (selectedItem?.type === 'with_name_and_create_link') {
            setInputValue('');
            const tag = await params.onCreateTag?.(selectedItem.name.trim(), selectedTagGroup?.id);
            if (tag) {
              addSelectedItem(buildTagItem(tag));
              params.onAddTag(tag);
            }
          }
          if (selectedItem?.type === 'with_tag_group') {
            toggleGroupOpen(String(selectedItem.tagGroup.id));
          }
          break;
        default:
          break;
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen) setInputValue('');
    }
  });

  return {
    ...combobox,
    ...multipleSelection,
    inputValue,
    setInputValue,
    visibleItems,
    showCreateLink,
    toggleGroupOpen,
    mode,
    setMode
  };
};
