import React, { SVGProps } from 'react';

export const EditEmailSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m14.2008 9.67413-4.4508 4.45087-2.1875.4375.4375-2.1875 4.4508-4.45087c.1146-.11472.2506-.20572.4003-.26782.1498-.06209.3103-.09405.4724-.09405s.3226.03196.4724.09405c.1497.0621.2857.1531.4003.26782l.0046.00467c.1147.11453.2058.25056.2679.4003.062.14974.094.31026.094.47236 0 .16211-.032.32262-.094.47236-.0621.14974-.1532.28577-.2679.40031z' />
      <path d='m7.5625 10.1875h-5.25c-.23206 0-.45462-.0922-.61872-.25628-.16409-.1641-.25628-.38666-.25628-.61872v-7c0-.23206.09219-.45462.25628-.61872.1641-.16409.38666-.25628.61872-.25628h10.5c.2321 0 .4546.09219.6187.25628.1641.1641.2563.38666.2563.61872v3.5' />
      <path d='m13.4898 1.7583-4.75071 3.654c-.33733.2595-.75099.4002-1.17659.4002-.42559 0-.83925-.1407-1.17658-.4002l-4.75067-3.654' />
    </g>
  </svg>
);
