export const getCtaText = (
  event?: StudyMessageEvent,
  studyStyle?: Study['style'],
  tremendousIncentives?: boolean
): string => {
  switch (event) {
    case 'screener':
      return 'Apply now';
    case 'invite':
      return {
        screener: 'Apply now',
        video_call: 'Schedule interview',
        online_task: 'Start task',
        unmoderated_test: 'Start test',
        panel: 'Join our panel',
        survey: 'Start survey',
        default: 'Get started'
      }[studyStyle || 'default'];
    case 'booked':
      return 'View interview details';
    case 'thanks':
      return tremendousIncentives ? 'Redeem reward' : 'View';
    case 'welcome':
      return 'Manage Preferences';
    case 'reschedule_request':
      return 'Schedule time';
    case 'booked_reminder':
    case 'started_reminder':
      return 'View details';
    default:
      return 'View';
  }
};
