import React, { useEffect, useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Control } from 'react-hook-form';

import { Button } from '@components/common';
import { Portal } from '@components/shared/Portal';
import { Enums, Models } from '@components/SurveyBuilder';

import { useUnmoderatedContext } from '../../hooks/useUnmoderatedContext';

import { ResponsiveCard } from '../ResponsiveCard';

import * as Types from '../../types';
import { CardList } from './components/CardList';
import { shuffle } from '@components/utils';
import { Categories } from './components/Categories';
import { useCardSortTaskTracking } from '@components/Unmoderated/hooks/useCardSortTaskTracking';

interface Props {
  block: Models.Block<Enums.Kind.cardSort>;
  control: Control<Types.FormData>;
  fullScreenPortalSelector: string;
}

export const CardSortTask: React.VFC<Props> = ({ control, block, fullScreenPortalSelector }) => {
  const { blocks } = useUnmoderatedContext();

  const { events, onCreateCategory, onDeleteCategory, onEditCategory, onMoveCard } = useCardSortTaskTracking();

  const [categories, setCategories] = useState(
    block.blockable.categories.map((name) => ({
      name,
      custom: false,
      cards: [] as string[]
    }))
  );

  const initialCards = useMemo(
    () => (block.blockable.randomise_cards ? shuffle(block.blockable.cards) : block.blockable.cards),
    []
  );

  const [cards, setCards] = useState(initialCards);

  const onCategorySelect = ({
    card,
    newCategory,
    initialCategory
  }: {
    card: string;
    newCategory?: string;
    initialCategory?: string;
  }) => {
    if (!initialCategory) {
      setCards(cards.filter((c) => c !== card));
    }

    if (!newCategory) {
      setCards([...cards, card]);
    }

    const newCategories = categories.map((c) => {
      if (c.name === initialCategory) {
        return {
          ...c,
          cards: c.cards.filter((c) => c !== card)
        };
      }

      if (c.name === newCategory) {
        return {
          ...c,
          cards: [...(c.cards || []), card] as string[]
        };
      }

      return c;
    });
    onMoveCard(card, initialCategory || null, newCategory || null);
    setCategories(newCategories);
  };

  const handleOnDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination || source.droppableId === destination.droppableId) return;

    if (destination.droppableId === 'cards-list') {
      onCategorySelect({
        card: draggableId,
        initialCategory: source.droppableId
      });
    }

    onCategorySelect({
      card: draggableId,
      newCategory: destination.droppableId,
      initialCategory: source.droppableId === 'cards-list' ? undefined : source.droppableId
    });
  };

  const onNameChange = (categoryName: string, value: string) => {
    onEditCategory(categoryName, value);
    setCategories(
      categories.map((c) => {
        if (c.name === categoryName) {
          return {
            ...c,
            name: value
          };
        }

        return c;
      })
    );
  };

  const onCategoryCreate = () => {
    onCreateCategory(`Untitled ${categories.length + 1}`);
    setCategories([
      ...categories,
      {
        name: `Untitled ${categories.length + 1}`,
        custom: true,
        cards: []
      }
    ]);
  };

  useEffect(() => {
    control.register('card_sort_task');
  }, [control]);

  return (
    <ResponsiveCard
      blockKind={block.kind}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={() => (
        <Button aria-label='Continue' className='btn-custom-brand' icon='arrowRight' type='submit' small noStyle>
          Continue
        </Button>
      )}
    >
      <Portal selector={fullScreenPortalSelector}>
        <div className='absolute w-full h-full flex'>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <CardList
              totalCards={block.blockable.cards.length}
              onCategorySelect={onCategorySelect}
              categories={categories}
              cards={cards}
            />
            <Categories
              onCategoryCreate={onCategoryCreate}
              onCategorySelect={onCategorySelect}
              onNameChange={onNameChange}
              categories={categories}
              canCreate={block.blockable.sort_type === 'open'}
            />
          </DragDropContext>
        </div>
      </Portal>
    </ResponsiveCard>
  );
};
