import React, { useState } from 'react';

import { api } from '@api/reduxApi';
import { Input, Loading, PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { ChevronRightSVG, SolidKebabSVG } from '@components/svgs';
import { EditTagModal } from '@components/TagManager/components/GroupColumn/EditTagModal';
import { getBackgroundColor } from '@components/tags/colors';
import { useOnEscape } from '@components/utils';
import { useToaster } from '@stores/toaster';

import { ViewOccurrencesDropdownLink } from '../ViewOccurrencesDropdownLink';

const EnterToSave: React.FC<{ className: string }> = ({ className }) => (
  <Text h='100' color='gray-500' className={className}>
    <span className='indigo-600'>ENTER</span> to save
  </Text>
);

const TagGroupColor: React.FC<{ color: string }> = ({ color }) => {
  const backgroundColor = getBackgroundColor(color || 'default', 0.2);
  return <div className='min-w-4 w-4 h-4 rounded' style={{ backgroundColor }} />;
};

interface Props {
  tag: Tag;
  studyId?: number;
  onDeleteTag: (e: React.MouseEvent<any>) => void;
}

export const TagDropdown: React.FC<Props> = ({ tag, studyId, onDeleteTag }) => {
  const [mode, setMode] = useState<'top' | 'edit'>();

  const [showEditModal, setShowEditModal] = useState(false);
  const [name, setName] = useState<string>(tag.name);

  const { data: tagGroups } = api.useGetTagGroupsQuery();
  const [updateTag, { isLoading: isUpdating }] = api.useUpdateTagMutation();
  const [create] = api.useCreateTagGroupMutation();

  const showToast = useToaster();

  const MoveToGroup: React.FC = () => (
    <div className='flex flex-col'>
      {tagGroups &&
        tagGroups
          .filter(({ id }) => id !== tag.tag_group_id)
          .filter(({ project_id }) => (!tag.project_id && !project_id) || tag.project_id === project_id)
          .map((g) => (
            <PopperDropdownButton
              onClick={(e) => {
                e.stopPropagation();
                saveTag({ tag_group_id: g.id });
              }}
            >
              <span className='flex flex-row items-center space-x-2'>
                <TagGroupColor color={g.color} />
                <span className='truncate'>{g.name || 'Untitled group'}</span>
              </span>
            </PopperDropdownButton>
          ))}
      <PopperDropdownButton
        onClick={async (e) => {
          e.stopPropagation();

          try {
            const tagGroup = await create({ name: '', project_id: studyId }).unwrap();

            saveTag({ tag_group_id: tagGroup.id });
          } catch {
            showToast({
              icon: 'error',
              heading: 'Failed to create a tag group',
              text: 'Something went wrong. Please try again later.'
            });
          }
        }}
      >
        Create a new group
      </PopperDropdownButton>
    </div>
  );

  useOnEscape(() => {
    setName(tag.name);
  });

  function handleNameUpdate(v: string) {
    setName(v);
  }
  function saveTag(params: Partial<Tag>) {
    return updateTag({ key: tag.name, project_id: tag.project_id, ...params });
  }

  const handleEnter: React.KeyboardEventHandler = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      if (studyId) {
        saveTag({ name })
          .unwrap()
          .then(() => {
            setMode('top');
          });
      } else {
        setShowEditModal(true);
      }
    }

    if (e.key === 'Escape') {
      setName(tag.name);
      setMode('top');
    }
  };

  const confirmEdit = () => {
    saveTag({ name })
      .unwrap()
      .then(() => {
        setMode('top');
      });
    setShowEditModal(false);
  };

  const renderMoveToGroup = () => {
    return (
      <div className='flex items-center flex-1 justify-between'>
        <span>Move to</span>
        <ChevronRightSVG />
      </div>
    );
  };

  return (
    <>
      {showEditModal && (
        <EditTagModal
          taggingsCount={tag.taggings_count}
          onClose={() => setShowEditModal(false)}
          onSubmit={confirmEdit}
        />
      )}
      <PopperDropdown
        aria-label='Tag options'
        name='tag options'
        hideCaret
        closeOnDropdownClick={false}
        renderIcon={() => <SolidKebabSVG className='w-4 h-4' />}
        buttonClassName='border-none rounded-full flex-shrink-0 pt-2 pr-2 pb-2 pl-2 mr-2'
        popperProps={{ zIndex: 30, placement: 'bottom', onClose: () => setMode('top') }}
      >
        <div className='w-44'>
          {isUpdating && <Loading absolute />}
          {mode === 'edit' && (
            <Input autoFocus className='w-full' value={name} onKeyDown={handleEnter} onChange={handleNameUpdate} />
          )}
          {mode === 'edit' && <EnterToSave className='px-4 py-2' />}

          {mode !== 'edit' && <ViewOccurrencesDropdownLink tag={tag} />}

          {mode !== 'edit' && (
            <PopperDropdownButton
              onClick={(e) => {
                e.stopPropagation();
                setMode('edit');
              }}
            >
              Edit tag
            </PopperDropdownButton>
          )}
          {mode !== 'edit' && (
            <>
              <PopperDropdown
                popperProps={{ renderOnBodyRoot: false, zIndex: 40, placement: 'right' }}
                text={renderMoveToGroup()}
                hideCaret
                textStyle
                className='w-full tw-ui-dropdown-link flex items-center justify-between'
              >
                <MoveToGroup />
              </PopperDropdown>
              <PopperDropdownButton color='red-600' onClick={onDeleteTag}>
                Delete tag…
              </PopperDropdownButton>
            </>
          )}
        </div>
      </PopperDropdown>
    </>
  );
};
