import React, { SVGProps } from 'react';

export const SynthesisSVG: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.9375 5.25C4.66237 5.25 5.25 4.66237 5.25 3.9375C5.25 3.21263 4.66237 2.625 3.9375 2.625C3.21263 2.625 2.625 3.21263 2.625 3.9375C2.625 4.66237 3.21263 5.25 3.9375 5.25Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0625 11.375C10.7874 11.375 11.375 10.7874 11.375 10.0625C11.375 9.33763 10.7874 8.75 10.0625 8.75C9.33763 8.75 8.75 9.33763 8.75 10.0625C8.75 10.7874 9.33763 11.375 10.0625 11.375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9583 0.875H2.04167C1.39733 0.875 0.875 1.39733 0.875 2.04167V11.9583C0.875 12.6027 1.39733 13.125 2.04167 13.125H11.9583C12.6027 13.125 13.125 12.6027 13.125 11.9583V2.04167C13.125 1.39733 12.6027 0.875 11.9583 0.875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7 0.875V13.125' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M0.875 7H13.125' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
