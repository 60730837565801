import React, { VFC, useMemo } from 'react';
import Tippy from '@tippyjs/react';

import { Text } from '@components/common';
import { Column, Grid } from '@components/common/Grid';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
}

export const Summary: VFC<Props> = ({ answers }) => {
  const completed = answers.filter((answer) => answer.completed);

  const successRate = useMemo(() => {
    const successCount = answers.filter((answer) => answer.completed).length;

    return (successCount / answers.length) * 100;
  }, [answers]);

  const incompleteRate = useMemo(() => {
    const incompleteCount = answers.filter((answer) => !answer.completed).length;

    return (incompleteCount / answers.length) * 100;
  }, [answers]);

  const clicksToGoal = useMemo(() => {
    const totalClicksToGoal = completed.reduce((acc, answer) => acc + (answer.clicks_to_goal ?? 0), 0);

    return totalClicksToGoal / completed.length;
  }, [answers]);

  const missClickRate = useMemo(() => {
    const totalMissClicks = answers.reduce((acc, answer) => acc + (answer.miss_clicks ?? 0), 0);

    return totalMissClicks / answers.length;
  }, [answers]);

  const avgDuration = useMemo(() => {
    const totalDuration = completed.reduce((acc, answer) => acc + (answer.time_in_task ?? 0), 0);

    return totalDuration / completed.length / 1000;
  }, [answers]);

  return (
    <section className='p-4 border border-gray-200 rounded'>
      <Grid desktop={5} tablet={5} mobile={2}>
        <Column className='px-4 border-r border-gray-200'>
          <Tippy content='Percentage of responses that successfully reached the goal screen'>
            <svg className='w-4 h-4'>
              <use href='#prototypeTaskMetrics-success' />
            </svg>
          </Tippy>
          <Text className='my-2 text-xl' bold>
            {successRate.toFixed(1)}%
          </Text>
          <Text className='text-sm leading-4 text-gray-500'>Success</Text>
        </Column>
        <Column className='px-4 border-r border-gray-200'>
          <Tippy content='Percentage of responses that failed to reach the goal screen'>
            <svg className='w-4 h-4'>
              <use href='#prototypeTaskMetrics-incomplete' />
            </svg>
          </Tippy>
          <Text className='my-2 text-xl' bold>
            {incompleteRate.toFixed(1)}%
          </Text>
          <Text className='text-sm leading-4 text-gray-500'>Incomplete</Text>
        </Column>
        <Column className='px-4 border-r border-gray-200'>
          <Tippy content='Average number of clicks it took to reach the goal screen'>
            <svg className='w-4 h-4'>
              <use href='#prototypeTaskMetrics-avgClicksToGoal' />
            </svg>
          </Tippy>
          <Text className='my-2 text-xl' bold>
            {isNaN(clicksToGoal) ? '-' : clicksToGoal.toFixed(1)}
          </Text>
          <Text className='text-sm leading-4 text-gray-500'>Avg. Clicks to goal</Text>
        </Column>
        <Column className='px-4 border-r border-gray-200'>
          <Tippy content='The number of clicks outside click hotspots, as a percentage of total clicks'>
            <svg className='w-4 h-4'>
              <use href='#prototypeTaskMetrics-misClickRate' />
            </svg>
          </Tippy>
          <Text className='my-2 text-xl' bold>
            {missClickRate.toFixed(1)}%
          </Text>
          <Text className='text-sm leading-4 text-gray-500'>Misclick rate</Text>
        </Column>
        <Column className='px-4'>
          <Tippy content='How long it took users to reach the goal screen, on average'>
            <svg className='w-4 h-4'>
              <use href='#prototypeTaskMetrics-avgDuration' />
            </svg>
          </Tippy>
          <Text className='my-2 text-xl' bold>
            {isNaN(avgDuration) ? '-' : `${avgDuration.toFixed(1)}s`}
          </Text>
          <Text className='text-sm leading-4 text-gray-500'>Avg. Duration</Text>
        </Column>
      </Grid>
    </section>
  );
};
