import { Row } from 'react-table';

import { FilterState, GlobalFilter } from '../types';
import { isFilterApplyable } from '../utils/isFilterApplyable';

export const buildRawFilter =
  <D extends object>(filters: FilterState<any>[], op: FilterOp): ((records: D[]) => D[]) =>
  (records: D[]) => {
    const applyableFilters = filters.filter(isFilterApplyable).filter((f) => f.definition.func);

    if (applyableFilters.length < 1) {
      return records;
    }

    return records.filter((record) => {
      switch (op) {
        case 'any':
          return applyableFilters.some((f) => f.definition.func?.(record, f));
        case 'all':
          return applyableFilters.every((f) => f.definition.func?.(record, f));
        default:
          return false;
      }
    });
  };
