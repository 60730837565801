import React, { useState } from 'react';

import { Button, Select, SelectOption, Text, Tooltip } from '@components/common';

import { getDefaultOperator, transformAnswerValue } from '../../utils';
import { NULL_ANSWER } from '../constants';
import { ValueInput } from './ValueInput';

interface Props {
  field: ScreenerField;
  onSave: (answer: IdealAnswer) => void;
  options: SelectOption<IdealAnswerOperator>[];
}

export const Form: React.FC<Props> = ({ field, options, onSave }) => {
  const [answer, setAnswer] = useState<IdealAnswer>({
    value: (field.ideal_answer || {}).value,
    operator: getDefaultOperator(field)
  });

  function handleSave() {
    onSave(answer);
  }
  function handleRemove() {
    setAnswer(NULL_ANSWER);
    onSave(NULL_ANSWER);
  }

  return (
    <div className='min-w-80 max-w-screen p-4 bg-white rounded-md border border-gray-200 shadow-lg'>
      <div className='flex items-center mb-3 space-x-2'>
        <Text bold h='400'>
          Ideal answer
        </Text>
        <Tooltip content='Set the ideal answers for your preferred responses to speed up the screening process.' />
      </div>
      <Select<IdealAnswerOperator>
        value={answer.operator || ''}
        options={options}
        onChange={(operator) =>
          setAnswer({
            operator,
            value: transformAnswerValue({
              prev: answer.operator,
              next: operator,
              fieldType: field.field_type,
              value: answer.value
            })
          })
        }
        className='mb-4'
      />
      <ValueInput field={field} answer={answer} onChange={(value) => setAnswer({ ...answer, value })} />
      <div className='flex items-center mt-6 space-x-4'>
        <Button primary onClick={handleSave}>
          Save
        </Button>
        <Button link onClick={handleRemove}>
          Remove
        </Button>
      </div>
    </div>
  );
};
