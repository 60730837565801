import * as React from 'react';
import { useMemo } from 'react';

import { AvatarFromId, Text } from '@components/common';
import { uniq } from '@components/utils';

export interface UserAvatarsProps {
  user_ids: number[];
  addIcon?: boolean;
  noTippy?: boolean;
  size?: 'md' | 'lg' | 'xl';
  className?: string;
  truncate?: number;
}

export function AddTeamIcon(props) {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.5625 10.0625C6.5625 10.9908 6.93125 11.881 7.58763 12.5374C8.244 13.1938 9.13424 13.5625 10.0625 13.5625C10.9908 13.5625 11.881 13.1938 12.5374 12.5374C13.1938 11.881 13.5625 10.9908 13.5625 10.0625C13.5625 9.13424 13.1938 8.244 12.5374 7.58763C11.881 6.93125 10.9908 6.5625 10.0625 6.5625C9.13424 6.5625 8.244 6.93125 7.58763 7.58763C6.93125 8.244 6.5625 9.13424 6.5625 10.0625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0625 8.3125V11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.3125 10.0625H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 10.0625C0.437943 9.4116 0.599692 8.77091 0.908284 8.19776C1.21688 7.62461 1.66269 7.13686 2.20587 6.77814C2.74905 6.41941 3.37266 6.20087 4.02094 6.14208C4.66923 6.08328 5.32198 6.18606 5.92083 6.44121'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.96875 2.84375C1.96875 3.48193 2.22226 4.09397 2.67352 4.54523C3.12478 4.99648 3.73682 5.25 4.375 5.25C5.01318 5.25 5.62522 4.99648 6.07648 4.54523C6.52774 4.09397 6.78125 3.48193 6.78125 2.84375C6.78125 2.20557 6.52774 1.59353 6.07648 1.14227C5.62522 0.691015 5.01318 0.4375 4.375 0.4375C3.73682 0.4375 3.12478 0.691015 2.67352 1.14227C2.22226 1.59353 1.96875 2.20557 1.96875 2.84375V2.84375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const SPACING = {
  md: '-ml-0.5',
  lg: '-ml-1',
  xl: '-ml-2.5'
};
export const UserAvatars: React.FC<UserAvatarsProps> = ({
  user_ids,
  size = 'md',
  noTippy = false,
  className = '',
  addIcon = false,
  truncate
}) => {
  const spacingClass = SPACING[size];
  const reversed = useMemo(() => uniq([...user_ids]).reverse(), [user_ids]);
  const idsToDisplay = truncate && reversed.length > truncate ? reversed.slice(0, truncate) : reversed;

  return (
    <div className={`flex flex-row flex-row-reverse justify-end ${className}`}>
      {addIcon && (
        <div
          className={`${spacingClass} bg-white text-white flex items-center justify-center rounded-full h-10 w-10 border border-gray-200 hover:border-indigo-600`}
        >
          <AddTeamIcon className='text-gray-700' />
        </div>
      )}
      {truncate && reversed.length > truncate && (
        <Text className='self-center ml-1' h='200' color='gray-500'>
          +{reversed.length - truncate}
        </Text>
      )}
      {idsToDisplay.map((id, i) => (
        <AvatarFromId
          noTippy={noTippy}
          key={`user_avatars_${id}`}
          userId={id}
          size={size}
          className={i === idsToDisplay.length - 1 ? '' : spacingClass}
        />
      ))}
    </div>
  );
};
