import React, { SVGProps } from 'react';

export const RoundArrowDownSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1433_3326)'>
      <path
        d='M5.8125 9.3125L8 11.5L10.1875 9.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 4.9375V11.5' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M1.4375 8C1.4375 9.74048 2.1289 11.4097 3.35961 12.6404C4.59032 13.8711 6.25952 14.5625 8 14.5625C9.74048 14.5625 11.4097 13.8711 12.6404 12.6404C13.8711 11.4097 14.5625 9.74048 14.5625 8C14.5625 6.25952 13.8711 4.59032 12.6404 3.35961C11.4097 2.1289 9.74048 1.4375 8 1.4375C6.25952 1.4375 4.59032 2.1289 3.35961 3.35961C2.1289 4.59032 1.4375 6.25952 1.4375 8V8Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1433_3326'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
