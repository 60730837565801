import React, { SVGProps } from 'react';

export const InterviewTemplateSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='120' height='100' viewBox='0 0 120 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect x='0.5' y='0.5' width='119' height='99' rx='2.5' fill='white' stroke='#E5E7EB' />
    <rect x='5' y='81' width='110' height='4' fill='#E5E7EB' />
    <rect x='5' y='89' width='65' height='4' fill='#E5E7EB' />
    <rect x='5.5' y='5.5' width='109' height='68' rx='2.5' fill='white' stroke='#E5E7EB' />
    <path
      d='M51.6457 53C51.1994 52.9988 50.7607 52.8892 50.3705 52.6817C49.9531 52.4659 49.6049 52.1452 49.3635 51.754C49.122 51.3628 48.9964 50.9159 49.0001 50.4614V29.5333C49.0001 29.0796 49.1272 28.6341 49.3683 28.2435C49.6093 27.853 49.9554 27.5316 50.3704 27.3131C50.7853 27.0946 51.2539 26.9869 51.7272 27.0013C52.2004 27.0157 52.6609 27.1516 53.0605 27.395L73.7742 37.859C74.1498 38.0877 74.4591 38.4036 74.6734 38.7774C74.8876 39.1512 75 39.5708 75 39.9974C75 40.4239 74.8876 40.8435 74.6734 41.2173C74.4591 41.5911 74.1498 41.907 73.7742 42.1357L53.0605 52.5998C52.6384 52.8598 52.1475 52.9986 51.6457 53V53Z'
      fill='#6C63FF'
    />
  </svg>
);
