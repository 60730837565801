import { Toast } from '@stores/toaster';

export const FAILED_UPLOAD: Toast = {
  heading: 'Failed to add a candidate!',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};

export const SUCCESS = (text: string, id: number): Toast => ({
  heading: 'Success!',
  text,
  icon: 'success',
  action: {
    text: 'View profile',
    href: `/candidates/${id}`
  }
});
