import React, { VFC, useEffect, useState } from 'react';

import { Button, Card, CardTitle, Pill, Popper, Select, Text } from '@components/common';
import { MicrophoneSVG, ScreenSVG, VideoCameraSVG, WarningSVG } from '@components/svgs';
import { useParticipantRecording } from '@components/ScreenSharingProvider';
import { Enums, Models, getBlockLabel } from '@components/SurveyBuilder';

import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';

import { ResponsiveCard } from './ResponsiveCard';

type PermissionFieldProps = {
  label: string;
  description: string;
  permission: Enums.DevicePermission;
  isChecked: boolean;
  isErrored: boolean;
  onClick?: () => void;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  renderSelect?: () => React.ReactNode;
  showWarning?: boolean;
};

interface Props {
  block: Models.Block<Enums.Kind.permissions>;
  isPreview: boolean;
  onSubmit: () => Promise<void>;
}

const PermissionField: VFC<PermissionFieldProps> = ({
  icon: Icon,
  isChecked,
  isErrored,
  label,
  description,
  permission,
  showWarning,
  onClick,
  renderSelect
}) => {
  if (permission === 'disabled') {
    return null;
  }

  return (
    <div className='w-full mb-6'>
      <div className='flex items-center justify-between mb-1'>
        <div className='flex items-center'>
          <Icon className='flex-shrink-0 mr-2' />
          <Text bold h='400'>
            {label}
          </Text>

          {showWarning && (
            <Popper
              event='hover'
              offset={[0, 6]}
              closeOnClickOutside
              content={() => (
                <Card className='p-3 shadow-md' noPadding>
                  <Text className='mb-2' bold>
                    This test will open URLs in a new tab
                  </Text>
                  <Text className='text-sm'>
                    Please record the <b>entire screen</b> to capture all interactions.
                  </Text>
                </Card>
              )}
            >
              <button type='button' className='focus:outline-none text-yellow-600' aria-label='Show warning message'>
                <WarningSVG className='w-4 h-4 ml-2' />
              </button>
            </Popper>
          )}
        </div>

        {isChecked && <Pill color='green'>ENABLED</Pill>}
      </div>
      <div className='ml-6'>
        <Text className='text-custom-brand-secondary' h='200' mb='4'>
          {description}
        </Text>
        {!isChecked && !isErrored && onClick && (
          <Button
            className='btn-custom-brand'
            noStyle
            small
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.();
            }}
          >
            Enable
          </Button>
        )}
        {isChecked && renderSelect?.()}
        {!isChecked && !isErrored && !onClick && (
          <Button className='btn-custom-brand' small disabled noStyle>
            Enable
          </Button>
        )}
        {isErrored && (
          <Text color='red-600' h='200'>
            Ensure your device is set up correctly and is accessible to the browser.
          </Text>
        )}
      </div>
    </div>
  );
};

export const Permissions: VFC<Props> = ({ block, isPreview, onSubmit }) => {
  const [waitingForRecording, setWaitingForRecording] = useState(false);

  const {
    camState,
    micState,
    hasCamError,
    hasMicError,
    isSharingScreen,
    readyToJoin,
    isJoined,
    joinCall,
    startScreenShare,
    isRecording,
    cameras,
    microphones,
    setCamera,
    setMicrophone,
    canStartRecording,
    startRecording,
    isDisabled
  } = useParticipantRecording();

  const { blocks, deviceType } = useUnmoderatedContext();

  const { camera, microphone, screen } = block.blockable;

  const websiteTestBlocks = blocks.filter(
    (block) => block.kind === Enums.Kind.websiteTest
  ) as Models.Block<Enums.Kind.websiteTest>[];

  const needsFullWindowMode = websiteTestBlocks.some((websiteTestBlock) => !websiteTestBlock.blockable.is_iframable);

  useEffect(() => {
    if (waitingForRecording && (isPreview || isRecording)) {
      onSubmit();
    }
  }, [isRecording, waitingForRecording, isPreview]);

  useEffect(() => {
    if (isDisabled) {
      onSubmit();
    }
  }, [isDisabled]);

  useEffect(() => {
    if (camera === 'disabled' && microphone === 'disabled' && !isJoined && readyToJoin) {
      joinCall();
    }
  }, [camera, microphone, isJoined, readyToJoin]);

  const micOptions = microphones.map(({ device: { label, deviceId } }) => ({
    label,
    value: deviceId
  }));

  const selectedMic = microphones.find(({ selected }) => selected)?.device.deviceId;

  const camOptions = cameras.map(({ device: { label, deviceId } }) => ({
    label,
    value: deviceId
  }));

  const selectedCam = cameras.find(({ selected }) => selected)?.device.deviceId;

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={() => (
        <Button
          type='button'
          className='btn-custom-brand'
          icon='arrowRight'
          aria-label='Start recording and continue'
          loading={waitingForRecording}
          black
          small
          fullWidth
          noStyle
          disabled={!isPreview && !canStartRecording}
          onClick={() => {
            setWaitingForRecording(true);

            if (!isPreview && canStartRecording) {
              startRecording();
            }
          }}
        >
          Start recording and continue
        </Button>
      )}
    >
      <div className='mt-4'>
        <PermissionField
          icon={MicrophoneSVG}
          renderSelect={() =>
            !!micOptions.length && (
              <Select
                className='truncate'
                h={8}
                onChange={setMicrophone}
                options={micOptions}
                value={selectedMic || micOptions[0].value}
              />
            )
          }
          label='Microphone'
          permission={microphone}
          isChecked={micState === 'granted'}
          isErrored={hasMicError}
          description='This is so we can hear you think out loud.'
          onClick={readyToJoin ? joinCall : undefined}
        />
        <PermissionField
          icon={VideoCameraSVG}
          renderSelect={() =>
            !!camOptions.length && (
              <Select h={8} onChange={setCamera} options={camOptions} value={selectedCam || camOptions[0].value} />
            )
          }
          label='Camera'
          permission={camera}
          isChecked={camState === 'granted'}
          isErrored={hasCamError}
          description='So we can observe any facial expressions.'
          onClick={readyToJoin ? joinCall : undefined}
        />
        {deviceType === 'desktop' && (
          <PermissionField
            icon={ScreenSVG}
            label='Screen recording'
            permission={screen}
            isErrored={false}
            isChecked={isSharingScreen}
            description='So we can observe you clicking through the test.'
            showWarning={needsFullWindowMode}
            onClick={
              isJoined
                ? () => {
                    startScreenShare({
                      displayMediaOptions: {
                        selfBrowserSurface: 'include',
                        surfaceSwitching: 'exclude',
                        systemAudio: 'exclude'
                      }
                    });
                  }
                : undefined
            }
          />
        )}
      </div>
    </ResponsiveCard>
  );
};
