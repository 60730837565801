import { FilterDefinition, Operator } from '../types';

export const getDefaultOperator = (type: FilterDefinition<any>['type']): Operator => {
  switch (type) {
    case 'free_text':
      return 'contains';
    case 'date':
    case 'number':
      return 'is_between';
    case 'text':
    case 'multiple_choice':
      return 'includes_any';
    case 'eligibility':
    case 'boolean':
    default:
      return 'is';
  }
};
