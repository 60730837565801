import { useEffect, useState } from 'react';

import { useWindowSize } from '@components/utils';

import { useDraggableCardContext } from './useDraggableCardContext';

export type BoundaryRect = {
  left: number;
  top: number;
  width: number;
  height: number;
};

export const useBoundaryRect = (): BoundaryRect | null => {
  const [rect, setRect] = useState<BoundaryRect | null>(null);

  const {
    state: { boundaryElement }
  } = useDraggableCardContext();

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  useEffect(() => {
    if (!boundaryElement) {
      return;
    }

    let boundaryWidth = 0;
    let boundaryHeight = 0;
    let paddingLeft = 0;
    let paddingTop = 0;

    if (boundaryElement instanceof HTMLElement) {
      const computedStyle = window.getComputedStyle(boundaryElement);

      paddingLeft = parseFloat(computedStyle.paddingLeft);
      paddingTop = parseFloat(computedStyle.paddingTop);

      boundaryWidth = boundaryElement.clientWidth - paddingLeft;
      boundaryHeight = boundaryElement.clientHeight - paddingTop;
    }

    if (boundaryElement instanceof Window) {
      boundaryWidth = windowWidth ?? 0;
      boundaryHeight = windowHeight ?? 0;
    }

    setRect({
      left: paddingLeft,
      top: paddingTop,
      width: boundaryWidth,
      height: boundaryHeight
    });
  }, [boundaryElement, windowWidth, windowHeight]);

  return rect;
};
