import React, { SVGProps } from 'react';

export const SendSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.83929 10.4193C1.59842 10.4193 1.3641 10.3409 1.17173 10.196C0.979355 10.051 0.839389 9.84739 0.772978 9.61585C0.706567 9.38432 0.71732 9.13746 0.803612 8.91258C0.889905 8.68769 1.04705 8.49701 1.25129 8.36933L13.5733 0.666662C13.7561 0.552269 13.9683 0.493666 14.1839 0.49803C14.3995 0.502393 14.6091 0.569534 14.7871 0.691229C14.9651 0.812924 15.1038 0.983892 15.1861 1.18319C15.2684 1.38249 15.2909 1.60148 15.2506 1.81333L12.8506 14.5953C12.8108 14.8068 12.7103 15.0021 12.5615 15.1575C12.4126 15.3129 12.2217 15.4216 12.0122 15.4704C11.8026 15.5192 11.5834 15.506 11.3811 15.4325C11.1789 15.3589 11.0025 15.2281 10.8733 15.056L7.39996 10.4193H1.83929Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.39994 10.4193H5.73328V13.8667C5.73326 14.0683 5.78826 14.2662 5.89234 14.4389C5.99642 14.6116 6.14564 14.7527 6.32394 14.8469C6.50224 14.9412 6.70286 14.985 6.90421 14.9736C7.10556 14.9623 7.3 14.8963 7.46661 14.7827L9.58528 13.3387L7.39994 10.4193Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.39929 10.4193L14.786 0.692657' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
