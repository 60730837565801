import React, { SVGProps } from 'react';

export const RedoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_17_200)'>
      <path
        d='M15.5 0.498779V5.49878H10.5'
        stroke='#374151'
        strokeWidth='0.666667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00001 15.4988C6.62483 15.4996 5.2759 15.1223 4.1007 14.4081C2.92551 13.694 1.96928 12.6705 1.33657 11.4495C0.703865 10.2285 0.419033 8.85705 0.51322 7.48511C0.607406 6.11316 1.07698 4.79351 1.87061 3.67045C2.66424 2.54739 3.75137 1.66415 5.01314 1.11729C6.27491 0.570438 7.66274 0.38102 9.02491 0.569749C10.3871 0.758479 11.6711 1.31809 12.7367 2.1874C13.8022 3.05671 14.6083 4.20225 15.0667 5.49878'
        stroke='#374151'
        strokeWidth='0.666667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_17_200'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
