import React, { SVGProps } from 'react';

export const PlayerPause = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='32' height='32' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_797:80)'>
      <path
        d='M12.1875 10.3101V19.6851'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.8125 10.3101V19.6851'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.9375 14.9976C0.9375 18.7272 2.41908 22.304 5.05631 24.9412C7.69354 27.5785 11.2704 29.0601 15 29.0601C18.7296 29.0601 22.3065 27.5785 24.9437 24.9412C27.5809 22.304 29.0625 18.7272 29.0625 14.9976C29.0625 11.268 27.5809 7.6911 24.9437 5.05387C22.3065 2.41664 18.7296 0.935059 15 0.935059C11.2704 0.935059 7.69354 2.41664 5.05631 5.05387C2.41908 7.6911 0.9375 11.268 0.9375 14.9976V14.9976Z'
        stroke='currentColor'
        strokeWidth='1.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_797:80'>
        <rect width='30' height='30' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
