import { docReady } from '@src/docReady';
docReady(() => {
  const analytics = window.analytics;
  const gtag = window.gtag;

  document.querySelectorAll('.fire-ga-event').forEach((element) => {
    const action = element.dataset['action'];
    const category = element.dataset['category'];
    gtag &&
      gtag('event', action, {
        event_category: category
      });
  });
  const elements = document.querySelectorAll('[data-event-name]');
  for (const element of elements) {
    element.addEventListener('click', function (e) {
      const name = e.currentTarget.dataset['eventName'];
      const position = e.currentTarget.dataset['eventPosition'];
      const page = e.currentTarget.dataset['eventPage'];
      const copy = e.currentTarget.dataset['eventCopy'];
      const props = { position, page, copy };
      analytics?.track(name, props);
      gtag && gtag('event', name, props);
    });
  }
});
