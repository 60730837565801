import React from 'react';

import { Candidate, Session, Url, User } from './components';
import { parseUrn } from './parseUrn';
import { UrnKey, UrnProps } from './types';

const Links: Record<UrnKey, React.FC<UrnProps>> = {
  user: User,
  candidate: Candidate,
  session: Session
};

export const Link: React.FC<{ href: string }> = ({ children, href }) => {
  const urn = parseUrn(href);
  if (!urn) {
    return <Url href={href}>{children}</Url>;
  }

  const Comp = Links[urn.key];

  if (!Comp) {
    return <>{children}</>;
  }

  return (
    <Comp id={urn.id} params={urn.params}>
      {children}
    </Comp>
  );
};
