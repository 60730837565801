import React, { SVGProps } from 'react';

export const MicrophoneSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M8 12.6895V15.0332' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M7.6875 0.96875H8.3125C8.3125 0.96875 10.8125 0.96875 10.8125 3.46875V8C10.8125 8 10.8125 10.5 8.3125 10.5H7.6875C7.6875 10.5 5.1875 10.5 5.1875 8V3.46875C5.1875 3.46875 5.1875 0.96875 7.6875 0.96875Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3 6.59375V8C3 9.2432 3.49386 10.4355 4.37294 11.3146C5.25201 12.1936 6.4443 12.6875 7.6875 12.6875H8.3125C9.5557 12.6875 10.748 12.1936 11.6271 11.3146C12.5061 10.4355 13 9.2432 13 8V6.59375'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
