import { format as timeagoFormat } from 'timeago.js';

export const taskIcon = (task: BackgroundTask | undefined): string | undefined => {
  if (!task) {
    return undefined;
  }
  if (task.status !== 'failed') {
    return 'cloud';
  } else {
    return 'error';
  }
};

export const isFailed = (task: BackgroundTask | undefined): boolean => {
  if (!task) {
    return false;
  }

  return task.status === 'failed'
};

export const isExpired = (valid: boolean | undefined): boolean => {
  return !valid;
};

export const syncText = (
  label: string,
  task: BackgroundTask | undefined,
  finishedAt: string | undefined
): string | undefined => {
  if (finishedAt) {
    const timeAgo = timeagoFormat(finishedAt);

    return `Last ${label}ed ${timeAgo}`;
  } else if (task) {
    const timeAgo = timeagoFormat(task.created_at);
    return `Started ${label}ing ${timeAgo}`;
  }
  return `No ${label} run`;
};
