import { FormGroup, Label, PanelWrapper } from '../shared';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { InputWithAddons, Select, Alert } from '@components/common';
import { VideoURLInput } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/components';
import {
  DEFAULT_DURATION_VALUES,
  DURATION_OPTIONS
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';
import { useDebouncedCallback } from 'use-debounce';
import * as toasts from '../../../../toasts';
import { useToaster } from '@stores/toaster';
import { useUser } from '@hooks/useUser';

interface Props {
  onClose: () => void;
  study: Study;
  onSave: (study: { id: number } & Partial<Study>) => void;
  readOnly?: boolean;
  calendar?: StudyCalendar;
}

export const EventDetailsPanel: React.FC<Props> = ({ readOnly, onClose, study, onSave, calendar }) => {
  const getDurationValue = (v: number | null) => {
    if (!v) return 'custom';
    if (DEFAULT_DURATION_VALUES.includes(v) && !showDurationInput) return String(v);
    return 'custom';
  };

  const [customDuration, setCustomDuration] = useState<number | undefined>(study.duration_in_minutes || undefined);
  const [showDurationInput, setShowDurationInput] = useState(false);
  const [duration, setDuration] = useState<string>(getDurationValue(study.duration_in_minutes));

  const user = useUser();

  const showToast = useToaster();
  const saveConfig = (study: { id: number } & Partial<Study>) => {
    if (study.duration_in_minutes && study.duration_in_minutes >= 1) {
      onSave(study);
    } else {
      showToast(toasts.failedSetDuration());
    }
  };

  const { callback: debouncedSaveConfig } = useDebouncedCallback(
    saveConfig,
    process.env.NODE_ENV === 'test' ? 10 : 300
  );

  const onDurationChange = (v: string) => {
    setDuration(v);
    // if user select custom option just render an input with empty value
    if (v === 'custom') {
      setCustomDuration(undefined);
      setShowDurationInput(true);
      return;
    }
    // if user select predefined option save value and hide custom input
    setShowDurationInput(false);
    const attrs: Partial<Study> = { duration_in_minutes: parseInt(v) };

    onSave({ id: study.id, ...attrs });
  };

  const handleCustomDuration = (v: string) => {
    const parsedValue = parseInt(v) ? parseInt(v) : 0;

    setCustomDuration(parsedValue);
    debouncedSaveConfig({ id: study.id, duration_in_minutes: parsedValue });
  };

  useEffect(() => {
    setShowDurationInput(getDurationValue(study.duration_in_minutes) === 'custom');
  }, [study.duration_in_minutes]);

  return (
    <PanelWrapper onClose={onClose} title='Event details'>
      {study.state === 'active' && (
        <Alert type='warning' className='mb-4'>
          Edits will only apply to future bookings.
        </Alert>
      )}
      <FormGroup>
        <Label tooltip='How long the interview will be for each participant.'>Duration</Label>
        <Select
          name='duration'
          disabled={readOnly}
          options={DURATION_OPTIONS}
          value={duration}
          onChange={onDurationChange}
        />
        {showDurationInput && (
          <InputWithAddons
            disabled={readOnly}
            type='number'
            name='custom_duration'
            className='xx-custom-duration no_arrows mt-2'
            value={customDuration ? customDuration.toString() : ''}
            onChange={handleCustomDuration}
            prefix=''
            placeholder='Enter duration...'
            suffix='minutes'
          />
        )}
      </FormGroup>

      <FormGroup>
        <Label tooltip='Where the moderator(s) and participant will join the call.'>Location</Label>
        <VideoURLInput
          addDebounce
          calendar={calendar}
          disabled={readOnly}
          user={user}
          study={study}
          onChange={(s) => onSave({ id: study.id, ...s })}
        />
      </FormGroup>
    </PanelWrapper>
  );
};
