import React, { useCallback, useEffect, useState } from 'react';
import * as timeago from 'timeago.js';
import { useDispatch } from 'react-redux';

import { api } from '@api/reduxApi';
import { Alert, AlertHeading, AlertMessage, Button, Loading, Text } from '@components/common';
import consumer from '@helpers/consumer';
import { Toast, useToaster } from '@stores/toaster';

import { useCancelBackgroundTaskMutation, useGetBackgroundTaskQuery } from './api';
import { getMessage } from './constants';

interface Props {
  backgroundTask: BackgroundTask;
  setBackgroundTask: (backgroundTask: BackgroundTask) => void;
  onFinished: () => void;
}

const BackgroundTasksChannel = (background_task_id: number) =>
  consumer.subscriptions.create(
    {
      channel: 'BackgroundTasksChannel',
      background_task_id: background_task_id
    },
    {}
  );

export const BackgroundTaskStatus: React.FC<Props> = ({ backgroundTask, setBackgroundTask, onFinished }) => {
  const [initialStatus] = useState(backgroundTask.status);
  const [isCancelling, setIsCancelling] = useState(false);
  const { data: fetchedBackgroundTask } = useGetBackgroundTaskQuery(
    { id: backgroundTask?.id },
    { skip: !backgroundTask }
  );
  const [cancelBackgroundTask] = useCancelBackgroundTaskMutation();
  const showToast = useToaster();
  const dispatch = useDispatch();

  const onCancel = useCallback(async () => {
    if (isCancelling) {
      return;
    }
    setIsCancelling(true);

    const resp = (await cancelBackgroundTask({ id: backgroundTask?.id })) as { data?: any; error?: any };
    if (resp.data) {
      setBackgroundTask(resp.data);
    } else {
      const toast: Toast = {
        heading: 'Task not canceled',
        text: 'Task could not be canceled, already in progress.',
        icon: 'error'
      };

      showToast(toast);
    }
    setIsCancelling(false);
  }, [isCancelling]);

  useEffect(() => {
    if (fetchedBackgroundTask) {
      setBackgroundTask(fetchedBackgroundTask);
    }
  }, [fetchedBackgroundTask]);

  useEffect(() => {
    BackgroundTasksChannel(backgroundTask.id).received = (data) => {
      setBackgroundTask(data.message);
    };

    return () => {
      BackgroundTasksChannel(backgroundTask.id).unsubscribe();

      dispatch(api.util.invalidateTags([{ type: 'BackgroundTask', id: backgroundTask.id }]));
    };
  }, []);

  useEffect(() => {
    // Note(caio): We only want to call onFinished if we witness a change in status. This is to avoid calling onFinished
    // multiple times when the component is re-rendered.
    if (initialStatus !== backgroundTask.status && backgroundTask.status === 'finished') {
      onFinished();
    }
  }, [backgroundTask.status, initialStatus]);

  if (!backgroundTask) return <></>;

  const alertMessage = getMessage(backgroundTask.action);
  return (
    <>
      {(backgroundTask.status === 'pending' || backgroundTask.status === 'processing') && (
        <Alert
          type={'warning'}
          className='my-4'
          cta={
            backgroundTask.status === 'pending' &&
            !isCancelling &&
            alertMessage.cancelled_action && (
              <Button onClick={onCancel} small danger icon='trash' className='right-0'>
                {alertMessage.cancelled_action}
              </Button>
            )
          }
        >
          <AlertHeading>{alertMessage.heading}</AlertHeading>
          <AlertMessage>{alertMessage.message}</AlertMessage>
          <Text h='200' color='gray-500'>
            Started {timeago.format(new Date(backgroundTask.created_at))}
          </Text>
          {isCancelling && <Loading />}
        </Alert>
      )}
      {backgroundTask.status === 'cancelled' && (
        <Alert type={'error'} className='my-4' dismissKey={`backgroundTask-dismiss-${backgroundTask.id}`}>
          <AlertHeading>Task cancelled</AlertHeading>
          <AlertMessage>{alertMessage.cancelled_heading}</AlertMessage>
        </Alert>
      )}
      {backgroundTask.status === 'failed' && (
        <Alert type={'error'} className='my-4' dismissKey={`backgroundTask-dismiss-${backgroundTask.id}`}>
          <AlertHeading>Something went wrong</AlertHeading>
          <AlertMessage>{alertMessage.failed_heading}</AlertMessage>
          <AlertMessage>{backgroundTask.error_message}</AlertMessage>
        </Alert>
      )}
    </>
  );
};
