import qs from 'qs';
import * as React from 'react';

import { api } from '@api/reduxApi';
import { Alert, AlertLink, Button, Heading, Spinner } from '@components/common';
import { UploadButton } from '@components/common/UploadButton';
import { usePlan } from '@hooks/usePlan';
import { useToaster } from '@stores/toaster';

import { PageLayout } from '../components/PageLayout';
import * as toasts from '../toasts';

const SECURITY_ITEMS = [
  ['GDPR compliant', 'Committed to complying with GDPR and provide the necessary tooling.'],
  ['Certified infrastructure', 'Rely on Heroku data centers which are SOC 1, 2 and 3 certified.'],
  ['Encrypted data', 'Secured using TLS and at-rest with AES 256, blockLevel storage.'],
  ['User roles and permissions', 'You can configure user permissions to ensure security.'],
  ['Constant monitoring', 'Infrastructure and network monitoring to prevent threats.'],
  ['Audit trails and logging', 'All access to user data is logged for historical auditing and tracking.'],
  ['Limited employee access', 'Only select employees are authorized to access your data.']
];

const SuccessIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    role='img'
    className='flex-shrink-0 w-4 h-4 mr-2 text-white'
  >
    <title id='aemsn7eg77qwabf1pjyyf5blx7iyc76k'>Icons/success</title>
    <path
      d='M4.54175 8.95633L6.03897 11.0812C6.09576 11.166 6.17199 11.236 6.26132 11.2855C6.35065 11.3349 6.45049 11.3623 6.55253 11.3653C6.65457 11.3684 6.75587 11.347 6.84799 11.303C6.9401 11.259 7.02038 11.1936 7.08214 11.1123L11.8751 5.04828'
      stroke='#31C48D'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M1.33337 8.20834C1.33337 10.0317 2.0577 11.7804 3.34701 13.0697C4.63633 14.359 6.38501 15.0833 8.20837 15.0833C10.0317 15.0833 11.7804 14.359 13.0697 13.0697C14.359 11.7804 15.0834 10.0317 15.0834 8.20834C15.0834 6.38498 14.359 4.6363 13.0697 3.34698C11.7804 2.05767 10.0317 1.33334 8.20837 1.33334C6.38501 1.33334 4.63633 2.05767 3.34701 3.34698C2.0577 4.6363 1.33337 6.38498 1.33337 8.20834V8.20834Z'
      stroke='#31C48D'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
export const ExternalLinkIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none' role='img' {...props}>
    <title id='ae371hce2aajt3dmz32dewyqpjos0ntt'>Icons/expand</title>
    <path
      d='M13.5631 4.37384V0.43634H9.62561'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M13.5631 0.43634L4.81311 9.18634'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M6.56311 3.06134H1.31311C1.08105 3.06134 0.858486 3.15353 0.694392 3.31762C0.530298 3.48172 0.43811 3.70428 0.43811 3.93634V12.6863C0.43811 12.9184 0.530298 13.141 0.694392 13.3051C0.858486 13.4692 1.08105 13.5613 1.31311 13.5613H10.0631C10.2952 13.5613 10.5177 13.4692 10.6818 13.3051C10.8459 13.141 10.9381 12.9184 10.9381 12.6863V7.43634'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);

const ShieldSVG: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='85'
    height='75'
    viewBox='0 0 85 75'
    fill='none'
    role='img'
    className='flex-shrink-0'
  >
    <title id='a6l0iwimefdocv2utgzrhnxw4qb7t0q5'>Security shield</title>
    <path
      d='M42.6682 1.04996L15.9929 17.2626C15.9929 17.2626 15.9929 50.7011 42.6682 68.9403C69.3434 50.7011 69.3434 17.2626 69.3434 17.2626L42.6682 1.04996Z'
      fill='#31C48D'
    ></path>
    <path
      opacity='0.04'
      d='M42.8273 1.04996L42.3713 1.3271V68.624C42.5228 68.7298 42.6741 68.8355 42.8273 68.9403C69.5026 50.7011 69.5026 17.2626 69.5026 17.2626L42.8273 1.04996Z'
      fill='#6C63FF'
    ></path>
    <g opacity='0.7'>
      <path
        opacity='0.7'
        d='M36.2102 33.9031L41.2389 38.4557L47.4728 29.5739L49.9872 31.6434L41.2389 42.5946L34.1149 34.7309L36.2102 33.9031Z'
        fill='url(#paint0_linear)'
      ></path>
    </g>
    <path
      d='M37.3971 34.1764L41.4763 37.8695L47.2552 29.4761L49.2948 31.1548L41.4763 41.2268L35.6974 34.8479L37.3971 34.1764Z'
      fill='white'
    ></path>
    <path
      opacity='0.5'
      d='M16.3481 4.68472C16.2664 4.64955 16.2005 4.58591 16.1632 4.50592C16.1258 4.42592 16.1196 4.33516 16.1456 4.25091C16.1498 4.23799 16.1525 4.22463 16.1535 4.2111C16.1559 4.1725 16.1456 4.13416 16.1242 4.10178C16.1028 4.06939 16.0714 4.04468 16.0347 4.0313C15.9979 4.01791 15.9578 4.01657 15.9203 4.02747C15.8827 4.03837 15.8497 4.06093 15.8261 4.09181C15.818 4.10276 15.8112 4.11463 15.8059 4.12715C15.7703 4.20791 15.7059 4.27291 15.6249 4.3098C15.5439 4.34668 15.452 4.35285 15.3667 4.32716C15.3536 4.323 15.3401 4.32038 15.3264 4.31935C15.2873 4.317 15.2485 4.32715 15.2157 4.34829C15.1829 4.36942 15.1579 4.40043 15.1443 4.43671C15.1308 4.473 15.1294 4.51263 15.1404 4.54973C15.1515 4.58684 15.1743 4.61945 15.2056 4.64273C15.2167 4.65073 15.2287 4.65742 15.2414 4.66265C15.3231 4.69782 15.389 4.76146 15.4263 4.84145C15.4636 4.92144 15.4699 5.01221 15.4439 5.09646C15.4397 5.10938 15.437 5.12274 15.436 5.13627C15.4336 5.17487 15.4439 5.2132 15.4653 5.24559C15.4867 5.27797 15.5181 5.30269 15.5548 5.31607C15.5916 5.32946 15.6317 5.3308 15.6692 5.3199C15.7068 5.309 15.7398 5.28644 15.7634 5.25556C15.7715 5.2446 15.7783 5.23274 15.7836 5.22022C15.8192 5.13946 15.8836 5.07445 15.9646 5.03757C16.0456 5.00069 16.1375 4.99451 16.2228 5.02021C16.2359 5.02437 16.2494 5.02699 16.2631 5.02802C16.3022 5.03037 16.341 5.02022 16.3738 4.99908C16.4066 4.97794 16.4316 4.94694 16.4452 4.91065C16.4587 4.87437 16.4601 4.83474 16.4491 4.79764C16.438 4.76053 16.4152 4.72792 16.3839 4.70464C16.3728 4.69663 16.3608 4.68995 16.3481 4.68472Z'
      fill='#4D8AF0'
    ></path>
    <path
      opacity='0.5'
      d='M1.90794 36.4316C1.82617 36.3964 1.76035 36.3328 1.723 36.2528C1.68566 36.1728 1.67941 36.082 1.70543 35.9978C1.70964 35.9849 1.71229 35.9715 1.71333 35.958C1.71571 35.9194 1.70544 35.8811 1.68403 35.8487C1.66263 35.8163 1.63123 35.7916 1.5945 35.7782C1.55776 35.7648 1.51763 35.7635 1.48006 35.7744C1.44249 35.7853 1.40948 35.8078 1.38591 35.8387C1.3778 35.8497 1.37103 35.8615 1.36574 35.874C1.33013 35.9548 1.26569 36.0198 1.18469 36.0567C1.1037 36.0936 1.0118 36.0997 0.926495 36.074C0.913416 36.0699 0.899888 36.0673 0.886187 36.0662C0.847102 36.0639 0.80829 36.074 0.7755 36.0952C0.74271 36.1163 0.717686 36.1473 0.704134 36.1836C0.690582 36.2199 0.689223 36.2595 0.700258 36.2966C0.711293 36.3337 0.734137 36.3663 0.765404 36.3896C0.776497 36.3976 0.788508 36.4043 0.801188 36.4095C0.882958 36.4447 0.948778 36.5083 0.98612 36.5883C1.02346 36.6683 1.02972 36.7591 1.0037 36.8433C0.999486 36.8563 0.996834 36.8696 0.995794 36.8832C0.993414 36.9218 1.00369 36.9601 1.02509 36.9925C1.04649 37.0249 1.07789 37.0496 1.11463 37.063C1.15136 37.0763 1.19149 37.0777 1.22906 37.0668C1.26663 37.0559 1.29965 37.0333 1.32322 37.0024C1.33132 36.9915 1.33809 36.9796 1.34339 36.9671C1.379 36.8863 1.44343 36.8213 1.52443 36.7845C1.60542 36.7476 1.69732 36.7414 1.78263 36.7671C1.79571 36.7713 1.80924 36.7739 1.82294 36.7749C1.86202 36.7772 1.90083 36.7671 1.93362 36.746C1.96641 36.7248 1.99144 36.6938 2.00499 36.6575C2.01854 36.6213 2.0199 36.5816 2.00886 36.5445C1.99783 36.5074 1.97499 36.4748 1.94372 36.4515C1.93263 36.4435 1.92061 36.4368 1.90794 36.4316Z'
      fill='#4D8AF0'
    ></path>
    <path
      opacity='0.5'
      d='M74.0213 25.3981C74.3491 25.3981 74.6147 25.1357 74.6147 24.812C74.6147 24.4883 74.3491 24.2259 74.0213 24.2259C73.6936 24.2259 73.4279 24.4883 73.4279 24.812C73.4279 25.1357 73.6936 25.3981 74.0213 25.3981Z'
      fill='#F55F44'
    ></path>
    <path
      opacity='0.5'
      d='M12.0073 67.3039C12.335 67.3039 12.6007 67.0415 12.6007 66.7178C12.6007 66.3941 12.335 66.1317 12.0073 66.1317C11.6795 66.1317 11.4138 66.3941 11.4138 66.7178C11.4138 67.0415 11.6795 67.3039 12.0073 67.3039Z'
      fill='#F55F44'
    ></path>
    <path
      opacity='0.5'
      d='M84.1098 47.9629C84.4375 47.9629 84.7032 47.7004 84.7032 47.3768C84.7032 47.0531 84.4375 46.7906 84.1098 46.7906C83.782 46.7906 83.5164 47.0531 83.5164 47.3768C83.5164 47.7004 83.782 47.9629 84.1098 47.9629Z'
      fill='#F55F44'
    ></path>
    <path
      opacity='0.5'
      d='M69.6587 7.51767C69.5769 7.4825 69.5111 7.41886 69.4737 7.33887C69.4364 7.25887 69.4301 7.16811 69.4562 7.08386C69.4604 7.07094 69.463 7.05758 69.4641 7.04405C69.4664 7.00544 69.4562 6.96711 69.4348 6.93473C69.4134 6.90234 69.382 6.87763 69.3452 6.86424C69.3085 6.85086 69.2684 6.84952 69.2308 6.86041C69.1932 6.87131 69.1602 6.89387 69.1366 6.92476C69.1285 6.93571 69.1218 6.94757 69.1165 6.9601C69.0809 7.04086 69.0164 7.10586 68.9354 7.14274C68.8544 7.17962 68.7625 7.1858 68.6772 7.1601C68.6642 7.15594 68.6506 7.15332 68.6369 7.1523C68.5978 7.14995 68.559 7.16009 68.5262 7.18123C68.4934 7.20237 68.4684 7.23338 68.4549 7.26966C68.4413 7.30595 68.44 7.34557 68.451 7.38268C68.462 7.41979 68.4849 7.45239 68.5161 7.47568C68.5272 7.48368 68.5392 7.49037 68.5519 7.49559C68.6337 7.53077 68.6995 7.59441 68.7368 7.6744C68.7742 7.75439 68.7804 7.84515 68.7544 7.92941C68.7502 7.94232 68.7476 7.95569 68.7465 7.96922C68.7441 8.00782 68.7544 8.04615 68.7758 8.07854C68.7972 8.11092 68.8286 8.13564 68.8654 8.14902C68.9021 8.16241 68.9422 8.16375 68.9798 8.15285C69.0174 8.14195 69.0504 8.11939 69.0739 8.08851C69.0821 8.07755 69.0888 8.06569 69.0941 8.05317C69.1297 7.97241 69.1942 7.9074 69.2752 7.87052C69.3562 7.83364 69.448 7.82746 69.5334 7.85316C69.5464 7.85732 69.56 7.85994 69.5737 7.86096C69.6128 7.86331 69.6516 7.85317 69.6844 7.83203C69.7171 7.81089 69.7422 7.77988 69.7557 7.7436C69.7693 7.70732 69.7706 7.66769 69.7596 7.63058C69.7486 7.59348 69.7257 7.56087 69.6945 7.53759C69.6834 7.52958 69.6713 7.5229 69.6587 7.51767Z'
      fill='#4D8AF0'
    ></path>
    <path
      opacity='0.5'
      d='M79.5493 68.8628C79.4675 68.8276 79.4017 68.764 79.3644 68.684C79.327 68.604 79.3208 68.5132 79.3468 68.429C79.351 68.4161 79.3536 68.4027 79.3547 68.3892C79.3571 68.3506 79.3468 68.3122 79.3254 68.2798C79.304 68.2475 79.2726 68.2228 79.2359 68.2094C79.1991 68.196 79.159 68.1946 79.1214 68.2055C79.0838 68.2164 79.0508 68.239 79.0273 68.2699C79.0192 68.2808 79.0124 68.2927 79.0071 68.3052C78.9715 68.386 78.907 68.451 78.8261 68.4879C78.7451 68.5247 78.6532 68.5309 78.5679 68.5052C78.5548 68.5011 78.5412 68.4984 78.5275 68.4974C78.4885 68.4951 78.4496 68.5052 78.4169 68.5264C78.3841 68.5475 78.359 68.5785 78.3455 68.6148C78.3319 68.6511 78.3306 68.6907 78.3416 68.7278C78.3527 68.7649 78.3755 68.7975 78.4068 68.8208C78.4179 68.8288 78.4299 68.8355 78.4425 68.8407C78.5243 68.8759 78.5901 68.9395 78.6275 69.0195C78.6648 69.0995 78.6711 69.1903 78.6451 69.2745C78.6408 69.2874 78.6382 69.3008 78.6372 69.3143C78.6348 69.3529 78.645 69.3913 78.6664 69.4237C78.6879 69.456 78.7192 69.4808 78.756 69.4941C78.7927 69.5075 78.8328 69.5089 78.8704 69.498C78.908 69.4871 78.941 69.4645 78.9646 69.4336C78.9727 69.4227 78.9794 69.4108 78.9847 69.3983C79.0204 69.3175 79.0848 69.2525 79.1658 69.2156C79.2468 69.1788 79.3387 69.1726 79.424 69.1983C79.4371 69.2024 79.4506 69.2051 79.4643 69.2061C79.5034 69.2084 79.5422 69.1983 79.575 69.1771C79.6078 69.156 79.6328 69.125 79.6463 69.0887C79.6599 69.0524 79.6613 69.0128 79.6502 68.9757C79.6392 68.9386 79.6163 68.906 79.5851 68.8827C79.574 68.8747 79.562 68.868 79.5493 68.8628Z'
      fill='#4D8AF0'
    ></path>
    <path
      opacity='0.5'
      d='M1.21555 17.8717C1.13378 17.8365 1.06796 17.7729 1.03062 17.6929C0.99328 17.6129 0.987024 17.5221 1.01304 17.4379C1.01726 17.425 1.01991 17.4116 1.02095 17.3981C1.02333 17.3595 1.01305 17.3211 0.99165 17.2888C0.970247 17.2564 0.938852 17.2317 0.902115 17.2183C0.865377 17.2049 0.825252 17.2035 0.787682 17.2144C0.750112 17.2253 0.717096 17.2479 0.693523 17.2788C0.685417 17.2897 0.678648 17.3016 0.673356 17.3141C0.637743 17.3949 0.573306 17.4599 0.492312 17.4968C0.411318 17.5337 0.31942 17.5398 0.234112 17.5141C0.221034 17.51 0.207505 17.5074 0.193805 17.5063C0.15472 17.504 0.115908 17.5141 0.0831175 17.5353C0.0503274 17.5564 0.0253028 17.5874 0.0117508 17.6237C-0.00180118 17.66 -0.00316023 17.6996 0.00787523 17.7367C0.0189107 17.7738 0.0417541 17.8064 0.073021 17.8297C0.0841142 17.8377 0.0961255 17.8444 0.108805 17.8496C0.190575 17.8848 0.256395 17.9484 0.293737 18.0284C0.331079 18.1084 0.337334 18.1992 0.311313 18.2834C0.307103 18.2964 0.304451 18.3097 0.303411 18.3233C0.301032 18.3619 0.311304 18.4002 0.332707 18.4326C0.354111 18.465 0.385506 18.4897 0.422243 18.5031C0.458981 18.5164 0.499106 18.5178 0.536676 18.5069C0.574246 18.496 0.607263 18.4734 0.630836 18.4425C0.638942 18.4316 0.645712 18.4197 0.651003 18.4072C0.686616 18.3264 0.751051 18.2614 0.832046 18.2246C0.91304 18.1877 1.00494 18.1815 1.09025 18.2072C1.10332 18.2114 1.11685 18.214 1.13055 18.215C1.16964 18.2173 1.20845 18.2072 1.24124 18.1861C1.27403 18.1649 1.29905 18.1339 1.31261 18.0976C1.32616 18.0614 1.32752 18.0217 1.31648 17.9846C1.30545 17.9475 1.2826 17.9149 1.25134 17.8916C1.24024 17.8836 1.22823 17.8769 1.21555 17.8717Z'
      fill='#4D8AF0'
    ></path>
    <path
      opacity='0.5'
      d='M7.85308 28.3282C8.18083 28.3282 8.44652 28.0658 8.44652 27.7421C8.44652 27.4184 8.18083 27.156 7.85308 27.156C7.52533 27.156 7.25964 27.4184 7.25964 27.7421C7.25964 28.0658 7.52533 28.3282 7.85308 28.3282Z'
      fill='#47E6B1'
    ></path>
    <path
      opacity='0.5'
      d='M84.4065 33.017C84.7343 33.017 85 32.7546 85 32.4309C85 32.1072 84.7343 31.8448 84.4065 31.8448C84.0788 31.8448 83.8131 32.1072 83.8131 32.4309C83.8131 32.7546 84.0788 33.017 84.4065 33.017Z'
      fill='#47E6B1'
    ></path>
    <g opacity='0.5'>
      <path opacity='0.5' d='M57.9985 0H57.7018V1.66062H57.9985V0Z' fill='#47E6B1'></path>
      <path
        opacity='0.5'
        d='M58.6909 0.977133V0.684082L57.0095 0.684082V0.977133L58.6909 0.977133Z'
        fill='#47E6B1'
      ></path>
    </g>
    <g opacity='0.5'>
      <path opacity='0.5' d='M2.90768 68.5737H2.61096V70.2344H2.90768V68.5737Z' fill='#47E6B1'></path>
      <path opacity='0.5' d='M3.6001 69.5509V69.2578H1.91869V69.5509H3.6001Z' fill='#47E6B1'></path>
    </g>
    <g opacity='0.5'>
      <path opacity='0.5' d='M70.4607 38.6829H70.1639V40.3435H70.4607V38.6829Z' fill='#47E6B1'></path>
      <path opacity='0.5' d='M71.1531 39.66V39.367H69.4717V39.66H71.1531Z' fill='#47E6B1'></path>
    </g>
    <path
      opacity='0.5'
      d='M78.6905 12.0168L79.3277 12.5937L80.2306 11.2824L80.5492 11.5447L79.3277 13.1183L78.4249 12.1217L78.6905 12.0168Z'
      fill='#47E6B1'
    ></path>
    <defs>
      <linearGradient
        id='paint0_linear'
        x1='34.1149'
        y1='36.0843'
        x2='49.9872'
        y2='36.0843'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25'></stop>
        <stop offset='0.53514' stopColor='#808080' stopOpacity='0.12'></stop>
        <stop offset='1' stopColor='#808080' stopOpacity='0.1'></stop>
      </linearGradient>
    </defs>
  </svg>
);

export const UploadStep: React.FC = () => {
  const { study_id, team_id } = qs.parse(location.search.slice(1));

  const [createCustomerImport, { isLoading }] = api.useCreateCustomerImportMutation();

  const plan = usePlan();
  const showToast = useToaster();
  async function handleChooseFile(file: File) {
    if (!file.name.toLowerCase().endsWith('.csv')) {
      showToast({
        heading: 'That file type is not supported.',
        text: 'Please upload a valid CSV file with ".csv" extension.',
        icon: 'error'
      });
      return;
    }

    try {
      const model = await createCustomerImport({ file, teamId: Number(team_id), studyId: Number(study_id) }).unwrap();
      location.href = `/customer_imports/${model.id}/edit${study_id ? `?study_id=${study_id}` : ''}`;
    } catch (_) {
      showToast(toasts.FAILED_UPLOAD);
    }
  }

  return (
    <PageLayout
      step={1}
      prev={
        <Button spa href={study_id ? `/studies/${study_id}/participations` : '/candidates'}>
          Cancel
        </Button>
      }
      disabled={isLoading}
    >
      <div className='tablet:space-x-10 tablet:flex px-page py-gutter mx-auto'>
        <div className='tablet:w-7/12 desktop:w-8/12 w-full'>
          <div>
            <Heading className='mb-4'>Upload your list</Heading>
            <p className='mb-2 text-gray-700'>
              The file must be a .CSV file with the email address for each person and column headers indicating any
              other data to be uploaded. Admins only will be contacted when you import them.
            </p>
            <p className='text-gray-700'>
              <a href='/static_templates/GQCustomerImportTemplate.csv' target='_blank'>
                Download a CSV template
              </a>
            </p>
            {plan.limits.candidates && plan.getQuota('candidates') <= 1000 && (
              <Alert className='mt-6 mb-6' type='info'>
                <div className='h400'>
                  You have {plan.getQuota('candidates')} remaining of your {plan.limits.candidates} candidate limit on
                  the <span className='font-bold'>{plan.name}</span> plan.{' '}
                  <AlertLink href='/plans'>View membership plans</AlertLink>
                </div>
              </Alert>
            )}
          </div>
          <div className='flex items-center mt-6'>
            <UploadButton
              primary
              disabled={isLoading}
              supportedFileTypes={['.csv']}
              onUploadFiles={([file]) => handleChooseFile(file)}
            >
              Upload file
            </UploadButton>
            {isLoading && <Spinner className='w-4 h-4 ml-4' />}
          </div>
        </div>
        <div>
          <div className='border border-gray-200 rounded-lg'>
            <div className='flex flex-row items-center px-6 py-4 border-b border-gray-200'>
              <ShieldSVG />
              <h2 className='desktop:text-2xl desktop:ml-10 ml-4 text-xl font-bold text-gray-700'>
                We protect your customers’ data like it's our own
              </h2>
            </div>
            <div className='p-6'>
              <div className='desktop:grid-cols-2 gap-y-6 gap-x-10 grid grid-cols-1 pb-4'>
                {SECURITY_ITEMS.map((item) => (
                  <div className=''>
                    <div className='flex flex-row items-center pb-1'>
                      <SuccessIcon />
                      <div className='text-sm font-medium leading-normal text-gray-700'>{item[0]}</div>
                    </div>
                    <div className='ml-6 text-xs text-gray-500'>{item[1]}</div>
                  </div>
                ))}
              </div>
              <div className='boder-gray-200 flex flex-row items-center justify-between pt-4 border-t'>
                <div className='font-bold text-gray-500'>Learn more about</div>
                <div className='flex flex-row space-x-10'>
                  <a
                    className='flex flex-row items-center text-sm font-medium text-indigo-600'
                    href='https://greatquestion.co/features/security'
                    target='_blank'
                  >
                    Security
                    <ExternalLinkIcon className='ml-2 text-indigo-600' />
                  </a>
                  <a
                    className=' flex flex-row items-center text-sm font-medium text-indigo-600'
                    href='https://greatquestion.co/features/gdpr'
                    target='_blank'
                  >
                    GDPR
                    <ExternalLinkIcon className='ml-2 text-indigo-600' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
