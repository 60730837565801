import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useFeature } from '@hooks/useFeature';

import { ClassifyStep } from './steps/ClassifyStep';
import { ImportStep } from './steps/ImportStep';
import { ManageStep } from './steps/ManageStep';
import { UploadStep } from './steps/UploadStep';
import { EditCustomerImportPage } from './EditCustomerImportPage';
import { NewCustomerImportPage } from './NewCustomerImportPage';

export const CustomerImportApp: React.FC<{ legacy?: boolean }> = ({ legacy = false }) => {
  const hasNewCSVImport = useFeature('new_csv_import');

  if (hasNewCSVImport && !legacy) {
    return (
      <Routes>
        <Route path=':id/edit' element={<EditCustomerImportPage />} />
        <Route path='new' element={<NewCustomerImportPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path=':id/import' element={<ImportStep />} />
      <Route path=':id/manage' element={<ManageStep />} />
      <Route path=':id/edit' element={<ClassifyStep />} />
      <Route path='new' element={<UploadStep />} />
    </Routes>
  );
};
