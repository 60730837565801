import { Option } from '@components/common';

type ArtifactIndexReplicaName = 'created_at' | 'updated_at' | 'owner_name';

export { ArtifactIndexReplicaName as SortOption };

export const SORT_SUFFIXES: Record<ArtifactIndexReplicaName, string> = {
  created_at: '',
  updated_at: '_updated_at',
  owner_name: '_owner_name'
};

export const SORT_OPTIONS: Option<ArtifactIndexReplicaName>[] = [
  {
    value: 'created_at',
    label: 'Recently added'
  },
  {
    value: 'updated_at',
    label: 'Last updated'
  },
  {
    value: 'owner_name',
    label: 'Owner'
  }
];
