import React, { useEffect, useMemo, useState } from 'react';

import { Dropdown, Spinner } from '@components/common';

import { useGetIntegrationStatusQuery } from './api';
import { JobStatus } from './components';
import { Provider } from './types';
import { isExpired, isFailed, syncText, taskIcon } from './utils';

export const IntegrationStatus = () => {
  const { data: status, isLoading, refetch } = useGetIntegrationStatusQuery();
  const [open, setOpen] = useState(false);

  const failed = useMemo(() => isFailed(status?.latest_pull_task), [status]);
  const icon = useMemo(() => taskIcon(status?.latest_pull_task), [status]);
  const expired = useMemo(() => isExpired(status?.valid), [status]);
  const latestPullText = useMemo(() => syncText('import', status?.latest_pull_task, status?.latest_pull), [status]);

  // refetch when opened
  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  if (isLoading) {
    return <Spinner />;
  }

  // Never finished before.
  const noData = isLoading || !status || !status.connected || !status.provider || (status.jobs.length == 0)
  if (noData) {
    return null;
  }

  if (expired) {
    return (
      <>
        <Dropdown
          medium
          danger
          dropdownWidth='auto'
          icon={'error'}
          className='xx-send-menu bg-white'
          text={`Connection to ${status.provider} expired`}
          isOpen={open}
          onClick={() => setOpen(!open)}
          onClose={() => setOpen(false)}
        >
          <div className='w-80 px-4 py-2 space-y-3'>
            {status.jobs.includes('pull') && (
              <JobStatus
                job='pull'
                provider={status.provider as Provider}
                lastFinishedAt={status?.latest_pull}
                task={status?.latest_pull_task}
                expired
              />
            )}
            {status.jobs.includes('push') && (
              <JobStatus
                job='push'
                provider={status.provider as Provider}
                lastFinishedAt={status?.latest_push}
                task={status?.latest_push_task}
                expired
              />
            )}
          </div>
        </Dropdown>
      </>
    );
  }

  return (
    <>
      <Dropdown
        medium
        danger={failed}
        dropdownWidth='auto'
        icon={icon}
        className='xx-send-menu bg-white'
        text={latestPullText}
        isOpen={open}
        onClick={() => setOpen(!open)}
        onClose={() => setOpen(false)}
      >
        <div className='w-80 px-4 py-2 space-y-3'>
          {status.jobs.includes('pull') && (
            <JobStatus
              job='pull'
              provider={status.provider as Provider}
              lastFinishedAt={status?.latest_pull}
              task={status?.latest_pull_task}
            />
          )}
          {status.jobs.includes('push') && (
            <JobStatus
              job='push'
              provider={status.provider as Provider}
              lastFinishedAt={status?.latest_push}
              task={status?.latest_push_task}
            />
          )}
        </div>
      </Dropdown>
    </>
  );
};
