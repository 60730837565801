import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { BASE_ATTRS } from '@components/config';

import { useAccount } from './useAccount';

interface Hook {
  idAttr: Attr_;
  allAttrs: Attr_[];
  candidateAttrs: Attr_[];
  setCandidateAttrs: React.Dispatch<React.SetStateAction<Attr_[]>>;
  create: (attr: Partial<Attr_>) => Promise<Attr_ | undefined>;
  isLoading: boolean;
  isSuccess: boolean;
}
// TODO: change to useAttrs
export const useCandidateAttrs = (): Hook => {
  const [candidateAttrs, setCandidateAttrs] = useState<Attr_[]>([]);
  const { data, isLoading, isSuccess } = api.useGetCandidateAttrsQuery();
  const [createCandidateAttr] = api.useCreateCandidateAttrMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setCandidateAttrs(data);
    }
  }, [isLoading]);

  const {
    account: { unique_id_attr }
  } = useAccount();

  const allAttrs = BASE_ATTRS.concat(candidateAttrs || []).map((a) => ({
    ...a,
    primary: a.name === unique_id_attr,
    lookup: a.core ? a.name : `extra.${a.name}`
  }));

  async function create(attr: Partial<Attr_>): Promise<Attr_> {
    return new Promise<Attr_>((resolve, reject) => {
      createCandidateAttr(attr)
        .unwrap()
        .then((resp) => {
          setCandidateAttrs([resp, ...candidateAttrs]);
          return resolve(resp);
        })
        .catch(reject);
    });
  }

  const idAttr = allAttrs.find((a) => a.name === unique_id_attr);

  return {
    idAttr: idAttr as any,
    allAttrs,
    candidateAttrs,
    setCandidateAttrs,
    create,
    isLoading,
    isSuccess
  };
};
