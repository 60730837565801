import { Toast } from '@stores/toaster';

export const connectedAccount = (providerName: Integration['name']): Toast => ({
  heading: 'Account connected',
  icon: 'success',
  text: `Your ${providerName} account is now connected.`
});

export const disconnectedAccount = (providerName: Integration['name']): Toast => ({
  heading: 'Account disconnected',
  icon: 'success',
  text: `We’ve removed your ${providerName} account.`
});
