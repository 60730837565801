import React, { SVGProps } from 'react';

export const WaitlistedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1234_8120)'>
      <path
        d='M11.0625 14.5625C12.9955 14.5625 14.5625 12.9955 14.5625 11.0625C14.5625 9.12951 12.9955 7.5625 11.0625 7.5625C9.12951 7.5625 7.5625 9.12951 7.5625 11.0625C7.5625 12.9955 9.12951 14.5625 11.0625 14.5625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6095 11.0624H11.0625V9.51538'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.0625 7.13135H7.125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.0625 9.31885H5.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.0625 11.5063H5.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.6875 14.5625H2.3125C2.08044 14.5625 1.85787 14.4703 1.69378 14.3062C1.52969 14.1421 1.4375 13.9195 1.4375 13.6875V4.5C1.4375 4.26794 1.52969 4.04537 1.69378 3.88128C1.85787 3.71719 2.08044 3.625 2.3125 3.625H4.5C4.5 3.04484 4.73047 2.48844 5.1407 2.0782C5.55094 1.66797 6.10734 1.4375 6.6875 1.4375C7.26768 1.4375 7.82407 1.66797 8.23432 2.0782C8.64452 2.48844 8.875 3.04484 8.875 3.625H11.0625C11.2945 3.625 11.5171 3.71719 11.6812 3.88128C11.8453 4.04537 11.9375 4.26794 11.9375 4.5V5.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.6875 3.63135C6.56669 3.63135 6.46875 3.53341 6.46875 3.4126C6.46875 3.29178 6.56669 3.19385 6.6875 3.19385'
        stroke='currentColor'
        strokeWidth='0.875'
      />
      <path
        d='M6.6875 3.63135C6.80831 3.63135 6.90625 3.53341 6.90625 3.4126C6.90625 3.29178 6.80831 3.19385 6.6875 3.19385'
        stroke='currentColor'
        strokeWidth='0.875'
      />
    </g>
    <defs>
      <clipPath id='clip0_1234_8120'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
