import * as React from 'react';
import { createContext, useContext, useReducer } from 'react';

import { TagManagerPage } from './components/TagManagerPage';

export type State = boolean;

const INITIAL_STATE: State = false;

export type Context = { state: State; dispatch: React.Dispatch<Action> };

export const context = createContext<Context>({} as Context);

export type Action = { type: 'OPEN_MANAGER' } | { type: 'CLOSE_MANAGER' };

const reducer: React.Reducer<State, Action> = (state, action) => action.type === 'OPEN_MANAGER';

export const Provider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <context.Provider value={{ state, dispatch }}>
      {children}
      {state && <TagManagerPage onClickBack={() => dispatch({ type: 'CLOSE_MANAGER' })} />}
    </context.Provider>
  );
};

export const useTagManagerState = (): State => useContext(context).state;

type ActionsHook = {
  openManager: () => void;
  closeManager: () => void;
};
export const useTagManagerActions = (): ActionsHook => {
  const { dispatch } = useContext(context);

  return {
    openManager: () => dispatch({ type: 'OPEN_MANAGER' }),
    closeManager: () => dispatch({ type: 'CLOSE_MANAGER' })
  };
};
