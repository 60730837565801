import { Controller } from 'stimulus';

import { slugify } from '@components/utils';

export default class extends Controller {
  static targets = ['companyName', 'workspaceUrl'];

  hasTyped = false;

  connect() {
    this.companyNameTarget.addEventListener('input', this.updateWorkspaceUrl);
    this.workspaceUrlTarget.addEventListener('input', this.setHasTyped);
  }

  updateWorkspaceUrl = () => {
    if (!this.hasTyped) {
      this.workspaceUrlTarget.value = slugify(this.companyNameTarget.value);
    }
  };

  setHasTyped = () => {
    this.hasTyped = true;
  };
}
