import React from 'react';
import { ExternalLinkSVG } from '@components/svgs';

export const HelpCenter: React.FC = () => (
  <p className='pt-11'>
    <span className='font-bold'>Questions?</span> Visit our{' '}
    <a className='inline-flex items-center' href='http://greatquestion.co/support/'>
      Help Center <ExternalLinkSVG className='ml-2' />
    </a>
  </p>
);
