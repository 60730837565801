import React from 'react';

import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import * as dropdowns from '@components/shared/TableFilters/components/dropdowns';

type Statuses = Incentive['status'];

type DropdownOption<T> = { label: string; value: T };

export const INCENTIVE_STATUS_LABELS = {
  pending: 'Not sent',
  sent: 'Sent',
  redeemed: 'Redeemed',
  canceled: 'Canceled'
};

const STATUSES_OPTIONS: DropdownOption<Statuses>[] = [
  { label: INCENTIVE_STATUS_LABELS['pending'], value: 'pending' },
  { label: INCENTIVE_STATUS_LABELS['sent'], value: 'sent' },
  { label: INCENTIVE_STATUS_LABELS['redeemed'], value: 'redeemed' },
  { label: INCENTIVE_STATUS_LABELS['canceled'], value: 'canceled' }
];

export const buildIncentivesFilterDefs = (): FilterDefinition<Incentive>[] => [
  {
    defaultOperator: 'includes_any',
    func: ({ status }, { value }) => {
      if (!value) return true;

      return value.includes(status);
    },
    id: 'status',
    name: 'Status',
    type: 'incentive_status',
    Icon: svgs.status,
    Component: (props) => <dropdowns.Choose {...props} options={STATUSES_OPTIONS} />
  },
  {
    defaultOperator: 'includes_any',
    func: ({ study }, { value }) => {
      if (!value) return true;

      return value.includes(study.owner_id);
    },
    id: 'owner',
    name: 'Owner',
    type: 'team_members',
    Icon: svgs.owner,
    Component: dropdowns.TeamMember
  }
];
