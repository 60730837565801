import cn from 'classnames';
import { Avatar } from 'components/common';
import { useAccount } from 'hooks/useAccount';
import * as React from 'react';

import type { ReactNode } from 'react';
interface UseRenderAvatarsHook {
  renderAvatars: (userIds: number[], max?: number) => ReactNode;
}

export const useRenderAvatars = (): UseRenderAvatarsHook => {
  const {
    account: { team }
  } = useAccount();

  return {
    renderAvatars: (userIds, max = 2) => {
      const users = team.filter(({ id }) => userIds.includes(id));

      if (!users.length) return null;

      return (
        <section className='flex items-center'>
          {users.map((user, i) => (
            <Avatar key={user?.id} user={user} className={cn({ ['-ml-2']: i > 0 })} />
          ))}
          {users.length > max && <Avatar user={{ initials: `+${users.length - max}` }} className='-ml-2' />}
        </section>
      );
    }
  };
};
