import React, { SVGProps } from 'react';

export const JobTitleSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.5 2.5H3.5C3.23478 2.5 2.98043 2.60536 2.79289 2.79289C2.60536 2.98043 2.5 3.23478 2.5 3.5V14.5C2.5 14.7652 2.60536 15.0196 2.79289 15.2071C2.98043 15.3946 3.23478 15.5 3.5 15.5H12.5C12.7652 15.5 13.0196 15.3946 13.2071 15.2071C13.3946 15.0196 13.5 14.7652 13.5 14.5V3.5C13.5 3.23478 13.3946 2.98043 13.2071 2.79289C13.0196 2.60536 12.7652 2.5 12.5 2.5H9.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 2C9.5 1.60218 9.34196 1.22064 9.06066 0.93934C8.77936 0.658035 8.39782 0.5 8 0.5C7.60218 0.5 7.22064 0.658035 6.93934 0.93934C6.65804 1.22064 6.5 1.60218 6.5 2V4C6.5 4.13261 6.55268 4.25979 6.64645 4.35355C6.74022 4.44732 6.86739 4.5 7 4.5H9C9.13261 4.5 9.25979 4.44732 9.35355 4.35355C9.44732 4.25979 9.5 4.13261 9.5 4V2Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.75 8.75C5.75 9.34674 5.98705 9.91903 6.40901 10.341C6.83097 10.7629 7.40326 11 8 11C8.59674 11 9.16903 10.7629 9.59099 10.341C10.0129 9.91903 10.25 9.34674 10.25 8.75C10.25 8.15326 10.0129 7.58097 9.59099 7.15901C9.16903 6.73705 8.59674 6.5 8 6.5C7.40326 6.5 6.83097 6.73705 6.40901 7.15901C5.98705 7.58097 5.75 8.15326 5.75 8.75V8.75Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 15.5001C11.8698 14.5316 11.3925 13.6432 10.6569 12.9999C9.9213 12.3565 8.97725 12.002 8 12.002C7.02275 12.002 6.0787 12.3565 5.34308 12.9999C4.60745 13.6432 4.13023 14.5316 4 15.5001'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
