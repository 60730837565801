import { Text, Tooltip } from 'components/common';
import { useDraftStudyContext } from 'components/StudiesApp/components/StudyDraft/StudyDraft';
import { moneyFormat } from 'components/utils';
import React, { useEffect, useState } from 'react';

interface Props {
  study: Study;
}

export const ContinuousFundingInfo: React.FC<Props> = ({ study }) => {
  const { prefundLimit, setPrefundLimit } = useDraftStudyContext();

  const [limit, setLimit] = useState<number>(prefundLimit);

  useEffect(() => {
    setLimit(prefundLimit);
  }, [prefundLimit]);

  const [error, setError] = useState<boolean>(false);

  const onBlur = () => {
    if (!limit || limit < (study.participant_limit || 0)) {
      setError(true);
      setLimit(prefundLimit);
      return;
    }
    setPrefundLimit(limit);
  };

  const onChange = (v: string) => {
    error && setError(false);
    const limit = Number(v.replace(/[^0-9]/g, ''));
    setLimit(limit);
  };
  return (
    <>
      <div className='relative flex flex-row items-center justify-between mb-4'>
        <div className='flex items-center'>
          <span className='text-sm'># of participants to pre-fund</span>
          <Tooltip className='ml-1.5 text-gray-700'>
            Based on your weekly participant limit multiplied by your maximum scheduling notice. Funding more upfront
            means you won’t have to worry about funding incentives every week.
          </Tooltip>
        </div>

        <input
          data-testid='prefund-limit'
          type='text'
          value={limit}
          onChange={(e) => onChange(e.currentTarget.value)}
          onBlur={onBlur}
          className='form-input no_arrows h400 block w-8 h-8 px-0 text-center'
        />
        {error && (
          <Text h='200' color='red-600' className='-bottom-3 absolute'>
            # of participants must be not less then weekly participant limit
          </Text>
        )}
      </div>

      <div className='flex flex-row items-center justify-between mb-4'>
        <span className='block text-sm'>Incentive amount per participant</span>
        <span className='block text-sm'>
          {study.currencySymbol}
          {moneyFormat(study.incentive || 0)}
        </span>
      </div>
    </>
  );
};
