import React, { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { useBulkUploadActions } from '@components/BulkUploadFlow';
import { Button, Text } from '@components/common';
import { Accordion } from '@components/common/Accordion';
import { ModalBackdrop } from '@components/common/Modal';
import { AddCandidateSlideOut } from '@components/shared/AddCandidateSlideOut';
import { NewStudySlideout } from '@components/StudiesApp/components/StudyNew/components/NewStudySlideout';
import { Card } from '@components/shared/Skeleton';
import { CloseSVG } from '@components/svgs';
import { useAuthProvider } from '@hooks/useAuthProvider';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { Heading } from './components';

export const OnboardingChecklist = () => {
  const [fetch, { data: checklist, isLoading }] = api.useLazyGetOnboardingChecklistQuery();
  const { isError: hasZoomError } = api.useGetZoomRecordingsQuery(undefined, { skip: !checklist?.uploaded_recording });
  const { getButtonProps, isOpen: authPopupOpen } = useAuthProvider('zoom');
  const { openManagerZDS, openZoomImportModal, state } = useBulkUploadActions();
  const [isClosedStored, setIsClosedStored] = useLocalStorage<boolean>('onboarding-checklist-closed');
  const [isClosed, setIsClosed] = useState<boolean | null>(isClosedStored);
  const [showCandidateSlideOut, setShowCandidateSlideOut] = useState<boolean>(false);
  const [showStudySlideOut, setShowStudySlideOut] = useState<boolean>(false);
  const [expandedSections, setExpandedSections] = useState<number[]>([]);

  const getExpandedSections = () => {
    const expandedSections = [
      !checklist?.sent_invites && 0,
      !checklist?.created_study && 1,
      !checklist?.uploaded_recording && 2
    ];
    return [expandedSections.filter(Number.isFinite).shift()] as number[];
  };

  const handleOnClose = () => {
    setIsClosed(true);
    setIsClosedStored(true);
  };

  useEffect(() => {
    if (!state.managerOpen && !isClosed) {
      fetch();
    }
  }, [state.managerOpen, isClosed]);

  useEffect(() => {
    setExpandedSections(getExpandedSections());
  }, [checklist]);

  const onUploadClick = () => {
    openManagerZDS();
  };

  if (isClosed) {
    return null;
  }

  if (!checklist) {
    return isLoading ? <Card height={400} /> : null;
  }

  return (
    <>
      <section className='p-6 bg-white border border-gray-200 rounded mb-12'>
        <header className='flex items-start justify-between'>
          <Text as='h2' h='600' bold className='mb-6'>
            Tips for trying out Great Question
          </Text>
          <button
            aria-label='Close onboarding checklist'
            className='hover:text-indigo-500 text-gray-700'
            onClick={handleOnClose}
          >
            <CloseSVG className='w-4 h-4' />
          </button>
        </header>
        <Accordion expandedSections={expandedSections} isExpandable>
          <Accordion.Section>
            <Accordion.Header>
              <Heading position={1} isComplete={checklist.sent_invites}>
                Start building a research panel
              </Heading>
            </Accordion.Header>
            <Accordion.Panel>
              <section className='pl-12'>
                <Text h='400' as='p'>
                  Once you have people in your candidate database, you can invite them to participate in research
                  studies, track contact history, and build profiles.
                </Text>
                <div className='flex mt-4'>
                  {checklist.sent_invites ? (
                    <Button icon='candidates' href='/candidates' spa>
                      View candidates
                    </Button>
                  ) : (
                    <>
                      <Button icon='import' href='/customer_imports/new' primary spa>
                        Import a list
                      </Button>
                      <Button icon='addPeople' className='ml-4' onClick={() => setShowCandidateSlideOut(true)}>
                        Add someone manually
                      </Button>
                    </>
                  )}
                </div>
              </section>
            </Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Heading position={2} isComplete={checklist.created_study}>
                Kick off a research study
              </Heading>
            </Accordion.Header>
            <Accordion.Panel>
              <section className='pl-12'>
                <Text h='400' as='p'>
                  Create your first study. Invite participants and get all your questions answered.
                </Text>
                <div className='flex mt-4'>
                  {checklist.created_study ? (
                    <Button icon='studies' href='/studies' spa>
                      View studies
                    </Button>
                  ) : (
                    <Button icon='plus' onClick={() => setShowStudySlideOut(true)} primary>
                      Create new study
                    </Button>
                  )}
                </div>
              </section>
            </Accordion.Panel>
          </Accordion.Section>
          <Accordion.Section>
            <Accordion.Header>
              <Heading position={3} isComplete={checklist.uploaded_recording}>
                Upload a customer interview
              </Heading>
            </Accordion.Header>
            <Accordion.Panel>
              <section className='pl-12'>
                <Text h='400' as='p'>
                  Once you upload a recording, we’ll automatically transcribe it from which you can create shareable
                  highlights and tags.
                </Text>
                <div className='flex mt-4'>
                  {checklist.uploaded_recording ? (
                    <Button href='/repository' spa>
                      View data
                    </Button>
                  ) : (
                    <>
                      <Button icon='import' onClick={onUploadClick} primary>
                        Upload
                      </Button>
                      {hasZoomError ? (
                        <Button {...getButtonProps({ callback: openZoomImportModal })} icon='zoom' className='ml-4'>
                          Import from Zoom
                        </Button>
                      ) : (
                        <Button icon='zoom' onClick={() => openZoomImportModal()} className='ml-4'>
                          Import from Zoom
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </section>
            </Accordion.Panel>
          </Accordion.Section>
        </Accordion>
      </section>
      {showCandidateSlideOut && (
        <AddCandidateSlideOut
          onClose={() => setShowCandidateSlideOut(false)}
          style='addCandidate'
          onAdd={() => fetch()}
          team={null}
        />
      )}
      {showStudySlideOut && <NewStudySlideout isVisible onClose={() => setShowStudySlideOut(false)} />}
      {authPopupOpen && <ModalBackdrop />}
    </>
  );
};
