import React from 'react';
import { useContext, useState } from 'react';
import { Placement } from 'tippy.js';

import { Button, Text } from '@components/common';
import { RequestUpgradeModal } from '@components/RoleRequests/RequestUpgradeModal';
import { humanize } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { usePlan } from '@hooks/usePlan';
import { store as permissionsStore } from '@stores/permissions';
import Tippy from '@tippyjs/react';

import { ACTIONS, FEATURES } from './constants';
import { PlanUpgradeModal } from './PlanUpgradeModal';
import { RestrictedLimit, RestrictedPermission } from './types';

type RenderProps = {
  can: boolean;
  may: boolean;
  show?: () => void;
};

export interface Props {
  children: string | React.ReactElement | ((props: RenderProps) => React.ReactElement);
  autoShow?: boolean;
  limit?: RestrictedLimit;
  feature?: PlanFeature;
  tipPlacement?: Placement;
  spanClassName?: string;
  permission?: RestrictedPermission;
  setUpgradeModal?: (v: null | 'request_role_upgrade' | 'plan_upgrade') => void;
}

const LimitedRoleTip: React.FC<{ message: string; onClick: () => void }> = ({ message, onClick }) => (
  <div className='p-2'>
    <Text h='400' className='leading-normal'>
      {message}
    </Text>
    <Button primary className='mt-4' onClick={onClick}>
      Request upgrade
    </Button>
  </div>
);

const DisabledTip: React.FC = () => (
  <div className='p-2'>
    <Text h='400' bold className='mt-2 mb-2'>
      Coming Soon
    </Text>
    <Text h='400' className='leading-normal'>
      This feature is currently in private Beta and will released soon.
    </Text>
  </div>
);

const PlanUpgradeTip: React.FC<{ feature?: PlanFeature; limit?: PlanLimit }> = ({ feature, limit }) => {
  const canUpgrade = usePermission('isAdmin')();
  return (
    <div className='p-2'>
      <Text h='400' bold className='mt-2 mb-2'>
        Upgrade required
      </Text>
      <Text h='400' className='leading-normal'>
        {feature && `In order to ${FEATURES[feature]}, you’d need to upgrade your membership plan.`}
        {limit && `You can no longer ${ACTIONS[limit]}. Please upgrade your account to continue.`}
      </Text>
      {
        <Button
          trackEvent='upgrade_tooltip_clicked_view_plans'
          trackProps={{ feature, limit }}
          primary
          disabled={canUpgrade}
          className='mt-4'
          href='/pricing'
        >
          View plans
        </Button>
      }
    </div>
  );
};

export const RestrictedAction: React.FC<Props> = ({
  limit,
  feature,
  autoShow = true,
  spanClassName,
  permission,
  tipPlacement = 'auto',
  children,
  setUpgradeModal
}) => {
  const [showModal, setShowModal] = useState<null | 'request_role_upgrade' | 'plan_upgrade'>();
  const { hasQuota, hasFeature } = usePlan();
  const { role } = useContext(permissionsStore);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const can = permission ? usePermission(permission)() : true;

  let may = true;
  if (feature) {
    may = hasFeature(feature);
  } else if (limit) {
    may = hasQuota(limit);
  }

  const setModal = (v: null | 'request_role_upgrade' | 'plan_upgrade') => {
    if (setUpgradeModal) {
      setUpgradeModal(v);
    } else {
      setShowModal(v);
    }
  };

  function show() {
    if (!can) {
      setModal('request_role_upgrade');
    }
    if (!may) {
      setModal('plan_upgrade');
    }
  }

  if (can && may) {
    return <>{typeof children === 'function' ? children({ can, may }) : children}</>;
  }

  const actionText = ACTIONS[permission as any];
  let message: string;
  if (!can) {
    message = `You're on an ${humanize(
      role
    )} account, so you can't ${actionText}. In order to ${actionText}, request to upgrade permissions from your account administrator.`;
  } else if (feature) {
    message = `In order to ${FEATURES[feature]}, you’d need to upgrade your membership plan.`;
  } else {
    message = `You can no longer ${actionText}. Please upgrade your account to continue.`;
  }

  if (can && !may) {
    return (
      <>
        <span className={spanClassName} onClick={() => autoShow && show()}>
          {typeof children === 'function' ? children({ can, may, show }) : children}
        </span>
        {showModal === 'plan_upgrade' && (
          <PlanUpgradeModal feature={feature} limit={limit} onClose={() => setModal(null)} />
        )}
      </>
    );
  }

  return (
    <>
      <Tippy
        maxWidth={can ? 800 : 240}
        interactive={true}
        arrow={false}
        content={
          <>
            {!can && <LimitedRoleTip message={message} onClick={() => setModal('request_role_upgrade')} />}
            {can && !may && <PlanUpgradeTip feature={feature} limit={limit} />}
          </>
        }
        placement={tipPlacement}
        className='custom-tippy text-left text-gray-700 bg-white rounded-md shadow'
      >
        <span className={spanClassName}>{typeof children === 'function' ? children({ can, may }) : children}</span>
      </Tippy>
      {showModal === 'request_role_upgrade' && (
        <span className='text-left'>
          <RequestUpgradeModal onCancel={() => setModal(null)} />
        </span>
      )}
    </>
  );
};
