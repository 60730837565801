import React, { SVGProps } from 'react';

export const ContinuousSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1532_19843)'>
      <path
        d='M4.8125 10.0625H1.3125C1.08044 10.0625 0.857876 9.97031 0.693782 9.80622C0.529687 9.64212 0.4375 9.41956 0.4375 9.1875V2.1875C0.4375 1.95544 0.529687 1.73288 0.693782 1.56878C0.857876 1.40469 1.08044 1.3125 1.3125 1.3125H9.1875C9.41956 1.3125 9.64212 1.40469 9.80622 1.56878C9.97031 1.73288 10.0625 1.95544 10.0625 2.1875V4.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 3.9375H10.0625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0625 2.1875V0.4375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.4375 2.1875V0.4375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.75 10.9375H6.5625V13.125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2225 11.3657C12.9904 12.0424 12.5415 12.6236 11.9455 13.0193C11.3495 13.415 10.6396 13.603 9.92582 13.5542C9.21207 13.5055 8.53434 13.2226 7.99768 12.7495C7.46103 12.2764 7.09542 11.6395 6.95752 10.9375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.375 9.1875H13.5625V7'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.90259 8.75954C7.13428 8.08244 7.58297 7.50073 8.17903 7.10467C8.77508 6.70861 9.48519 6.52034 10.1992 6.56907C10.9132 6.6178 11.5911 6.9008 12.1278 7.37418C12.6645 7.84755 13.03 8.48482 13.1676 9.18712'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1532_19843'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
