import React, { SVGProps } from 'react';

export const ClockRefreshSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.75 10.9375H6.5625V13.125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2225 11.3651C12.9908 12.0422 12.5421 12.6239 11.9461 13.02C11.35 13.416 10.6399 13.6043 9.92593 13.5556C9.21194 13.5068 8.534 13.2238 7.99728 12.7504C7.46056 12.2771 7.09507 11.6398 6.95752 10.9375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.375 9.1875H13.5625V7'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.90259 8.75991C7.13428 8.08281 7.58297 7.5011 8.17903 7.10504C8.77508 6.70898 9.48519 6.52071 10.1992 6.56944C10.9132 6.61817 11.5911 6.90117 12.1278 7.37454C12.6645 7.84791 13.03 8.48519 13.1676 9.18749'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.81246 8.3125C4.33063 8.31254 3.85808 8.17996 3.44659 7.92928C3.0351 7.67861 2.70054 7.31951 2.47957 6.89133C2.2586 6.46316 2.15975 5.98242 2.19383 5.5018C2.22792 5.02117 2.39364 4.5592 2.67283 4.16649C2.95202 3.77379 3.33391 3.46551 3.77666 3.27542C4.21941 3.08533 4.70594 3.02076 5.18294 3.08881C5.65995 3.15685 6.10902 3.35487 6.48097 3.66117C6.85291 3.96747 7.13338 4.37023 7.29163 4.82533'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 5.6875H4.8125V4.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.375 11.8125H1.3125C1.08044 11.8125 0.857876 11.7203 0.693782 11.5562C0.529687 11.3921 0.4375 11.1696 0.4375 10.9375V1.3125C0.4375 1.08044 0.529687 0.857876 0.693782 0.693782C0.857876 0.529687 1.08044 0.4375 1.3125 0.4375H7.51275C7.74474 0.437704 7.96715 0.530025 8.13108 0.694167L9.80642 2.36892C9.88766 2.45021 9.95208 2.54671 9.99602 2.6529C10.04 2.7591 10.0626 2.87291 10.0625 2.98783V4.375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
