import React from 'react';

export const ImageSVG = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1101_8202)'>
      <path
        d='M13.9555 0H2.04453C0.917156 0 0 0.917188 0 2.04456V13.9555C0 15.0828 0.917156 16 2.04453 16H13.9555C15.0828 16 16 15.0828 16 13.9554V2.04456C16 0.917188 15.0828 0 13.9555 0ZM15.0576 13.9555C15.0576 14.5632 14.5632 15.0576 13.9555 15.0576H2.04453C1.43681 15.0576 0.942406 14.5632 0.942406 13.9555V12.2365L4.04413 9.59741C4.15731 9.50109 4.32244 9.50019 4.43669 9.59506L6.37962 11.2084C6.56697 11.364 6.84197 11.3512 7.01412 11.1789L11.6307 6.55531C11.7141 6.47172 11.8114 6.46359 11.8622 6.46619C11.9128 6.46878 12.0089 6.48684 12.0833 6.57856L15.0576 10.2408V13.9555H15.0576ZM15.0576 8.74578L12.8149 5.98434C12.5925 5.71047 12.2628 5.54303 11.9104 5.52494C11.5583 5.50713 11.213 5.63969 10.9637 5.88938L6.651 10.2088L5.03881 8.87006C4.57134 8.48188 3.89622 8.48591 3.43341 8.87969L0.942406 10.9991V2.04456C0.942406 1.43684 1.43681 0.942438 2.04453 0.942438H13.9555C14.5632 0.942438 15.0576 1.43684 15.0576 2.04456V8.74578Z'
        fill='#374151'
      />
      <path
        d='M5.03669 1.9686C3.78372 1.9686 2.7644 2.98797 2.7644 4.24088C2.7644 5.49382 3.78375 6.51316 5.03669 6.51316C6.28962 6.51316 7.30897 5.49382 7.30897 4.24088C7.30897 2.98794 6.28965 1.9686 5.03669 1.9686ZM5.03669 5.57075C4.30337 5.57075 3.70681 4.97416 3.70681 4.24088C3.70681 3.50757 4.30337 2.911 5.03669 2.911C5.77 2.911 6.36656 3.5076 6.36656 4.24088C6.36656 4.97416 5.77 5.57075 5.03669 5.57075Z'
        fill='#374151'
      />
    </g>
    <defs>
      <clipPath id='clip0_1101_8202'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
