import { Link } from 'react-router-dom';
import { Text } from '@components/common';
import * as React from 'react';
import { HamburgerSVG } from '@components/svgs';
import { useFixedSidebar } from '@components/layouts/Sidebar/context';
import { capitalize } from '@components/utils';
interface Props {
  artifactTitle: string | undefined;
  study?: Pick<Study, 'id' | 'title'> | null;
  parent_repo_session?: Pick<RepoSession, 'uuid' | 'title'> | null;
  artifactLink?: string;
  page?: string;
  shortened?: boolean;
}

export const ArtifactBreadcrumbs: React.FC<Props> = ({
  shortened,
  artifactLink,
  study,
  parent_repo_session,
  artifactTitle,
  page = 'repository'
}) => {
  const { toggle, open } = useFixedSidebar();

  return (
    <div className='flex items-center h400 mb-2 space-x-4 text-gray-500'>
      <>
        {shortened ? (
          <span>...</span>
        ) : (
          <>
            <button
              name='Toggle sidebar'
              aria-expanded={open}
              aria-label='Toggle sidebar'
              className='hover:text-gray-500 hover:underline text-gray-500'
              onClick={toggle}
            >
              <HamburgerSVG className='text-indigo-600' />
            </button>
            <div className='h-3 mr-3 border-r border-gray-200'></div>
            {study && (
              <>
                <Link className='hover:text-gray-500 hover:underline text-gray-500' to='/studies'>
                  Studies
                </Link>
                <span className='text-gray-400'>/</span>
                <Link
                  className='hover:text-gray-500 hover:underline text-gray-500 truncate'
                  to={`/studies/${study?.id}`}
                >
                  {study?.title || 'Untitled study'}
                </Link>
                <span className='text-gray-400'>/</span>
              </>
            )}
          </>
        )}
        <Link
          className='hover:text-gray-500 hover:underline text-gray-500 truncate'
          to={study?.id ? `/studies/${study?.id}/${page}` : `/${page}`}
        >
          {capitalize(page)}
        </Link>
      </>

      {parent_repo_session && (
        <>
          <span className='text-gray-400'>/</span>
          <Link
            className='hover:text-gray-500 hover:underline text-gray-500 truncate'
            to={`/sessions/${parent_repo_session.uuid}`}
          >
            {parent_repo_session.title || 'Untitled session'}
          </Link>
        </>
      )}

      <span className='text-gray-400'>/</span>

      {artifactLink ? (
        <Link className='truncate hover:text-gray-500 hover:underline text-gray-500' to={artifactLink}>
          {artifactTitle}
        </Link>
      ) : (
        <Text truncate color='gray-500' className='text-sm'>
          {artifactTitle}
        </Text>
      )}
    </div>
  );
};
