import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';

import { Options } from '@popperjs/core';
import { useFeature } from '@hooks/useFeature';

const ANIMATE_CLASSNAME = {
  top: 'translate-y-10',
  bottom: '-translate-y-10',
  'bottom-start': '-translate-y-10',
  left: 'translate-x-10',
  right: '-translate-x-10',
  'right-start': '-translate-x-10'
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  isVisible: boolean;
  placement?: Options['placement'];
}

export const MotionWrapper = forwardRef<HTMLDivElement, Props>(
  ({ isVisible, placement = 'top', children, className, ...rest }, ref) => {
    const nonVideoHlsEnabled = useFeature('non_video_highlights');
    return (
      <div
        ref={ref}
        className={cn(className, {
          [nonVideoHlsEnabled ? 'z-30' : 'z-20']: isVisible,
          'z-0 invisible w-0 h-0': !isVisible
        })}
        {...rest}
      >
        <div
          className={cn(
            'text-sm bg-white border border-gray-200 shadow-custom rounded transform-gpu',
            {
              [ANIMATE_CLASSNAME[placement]]: !isVisible,
              'opacity-0 pointer-events-none': !isVisible,
              'translate-y-0 translate-x-0 opacity-100 transition-all duration-150 ease-in-out pointer-events-auto':
                isVisible
            },
            className
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);
