import * as React from 'react';

export const ConfirmationIcon: React.FC<any> = (props) => (
  <svg {...props} width='116' height='104' viewBox='0 0 116 104' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M114.982 39.7717C114.941 39.7717 114.9 39.759 114.866 39.7353L58.5839 0.587828C58.4126 0.46925 58.2091 0.405879 58.0007 0.406234C57.7923 0.406589 57.589 0.470652 57.4181 0.589813L1.54138 39.7349C1.49718 39.7658 1.44248 39.778 1.38931 39.7687C1.33614 39.7594 1.28885 39.7293 1.25785 39.6852C1.22685 39.641 1.21468 39.5864 1.22401 39.5333C1.23334 39.4802 1.26342 39.433 1.30761 39.402L57.1844 0.256945C57.4237 0.0901441 57.7083 0.000480822 58.0001 1.92839e-06C58.2918 -0.000476965 58.5767 0.0882507 58.8165 0.254265L115.099 39.4016C115.134 39.4264 115.161 39.4618 115.175 39.5028C115.189 39.5437 115.19 39.5881 115.177 39.6294C115.164 39.6707 115.138 39.7067 115.103 39.7323C115.068 39.7579 115.026 39.7717 114.982 39.7717L114.982 39.7717Z'
      fill='#E5E7EB'
    />
    <path
      d='M4.73462 41.1589L58.0564 1.69043L111.783 43.9673L60.8037 74.1505L33.1265 67.8496L4.73462 41.1589Z'
      fill='#E5E7EB'
    />
    <path
      d='M35.4627 92.7942H9.12046C8.95871 92.7944 8.7985 92.7628 8.64901 92.7011C8.49951 92.6395 8.36365 92.5489 8.24921 92.4348C8.13476 92.3206 8.04397 92.1851 7.98202 92.0358C7.92007 91.8866 7.88818 91.7266 7.88818 91.5651C7.88818 91.4035 7.92007 91.2436 7.98202 91.0943C8.04397 90.9451 8.13476 90.8096 8.24921 90.6954C8.36365 90.5812 8.49951 90.4907 8.64901 90.429C8.7985 90.3674 8.95871 90.3357 9.12046 90.3359H35.4627C35.6245 90.3357 35.7847 90.3674 35.9342 90.429C36.0837 90.4907 36.2195 90.5812 36.334 90.6954C36.4484 90.8096 36.5392 90.9451 36.6012 91.0943C36.6631 91.2436 36.695 91.4035 36.695 91.5651C36.695 91.7266 36.6631 91.8866 36.6012 92.0358C36.5392 92.1851 36.4484 92.3206 36.334 92.4348C36.2195 92.5489 36.0837 92.6395 35.9342 92.7011C35.7847 92.7628 35.6245 92.7944 35.4627 92.7942Z'
      fill='#E5E7EB'
    />
    <path
      d='M18.5715 87.5091H9.12046C8.95871 87.5093 8.7985 87.4777 8.64901 87.416C8.49951 87.3543 8.36365 87.2638 8.24921 87.1496C8.13476 87.0355 8.04397 86.8999 7.98202 86.7507C7.92007 86.6014 7.88818 86.4415 7.88818 86.2799C7.88818 86.1184 7.92007 85.9584 7.98202 85.8092C8.04397 85.66 8.13476 85.5244 8.24921 85.4102C8.36365 85.2961 8.49951 85.2056 8.64901 85.1439C8.7985 85.0822 8.95871 85.0506 9.12046 85.0508H18.5715C18.7332 85.0506 18.8934 85.0822 19.0429 85.1439C19.1924 85.2056 19.3283 85.2961 19.4427 85.4102C19.5572 85.5244 19.648 85.66 19.7099 85.8092C19.7719 85.9584 19.8037 86.1184 19.8037 86.2799C19.8037 86.4415 19.7719 86.6014 19.7099 86.7507C19.648 86.8999 19.5572 87.0355 19.4427 87.1496C19.3283 87.2638 19.1924 87.3543 19.0429 87.416C18.8934 87.4777 18.7332 87.5093 18.5715 87.5091Z'
      fill='#E5E7EB'
    />
    <path
      d='M58.806 63.143C58.6057 63.1432 58.4074 63.1044 58.2221 63.0288L25.3369 49.4008V6.74309C25.3374 6.36588 25.4876 6.00425 25.7546 5.73752C26.0217 5.47079 26.3838 5.32075 26.7615 5.32031H89.8492C90.2269 5.32075 90.589 5.47079 90.856 5.73752C91.1231 6.00425 91.2733 6.36588 91.2738 6.74309V49.4306L91.2119 49.457L59.4075 63.0221C59.2171 63.1021 59.0125 63.1432 58.806 63.143Z'
      fill='white'
    />
    <path
      d='M58.8061 63.2446C58.5927 63.2449 58.3812 63.2035 58.1835 63.1229L25.2354 49.4688V6.74316C25.2358 6.339 25.3968 5.95153 25.6829 5.66574C25.969 5.37996 26.357 5.21921 26.7617 5.21875H89.8494C90.2541 5.21921 90.642 5.37996 90.9282 5.66574C91.2143 5.95153 91.3752 6.339 91.3757 6.74316V49.4978L59.4477 63.1156C59.2446 63.2009 59.0265 63.2448 58.8061 63.2446ZM25.6424 49.1973L58.3387 62.7471C58.6437 62.8707 58.9852 62.8686 59.2887 62.7414L90.9687 49.2294V6.74316C90.9683 6.44678 90.8503 6.16264 90.6405 5.95307C90.4306 5.7435 90.1461 5.62561 89.8494 5.62526H26.7617C26.4649 5.62561 26.1804 5.7435 25.9706 5.95307C25.7608 6.16264 25.6427 6.44678 25.6424 6.74316L25.6424 49.1973Z'
      fill='#E5E7EB'
    />
    <path
      d='M114.575 39.3652H114.535L91.1719 49.3288L59.2068 62.961C59.0819 63.0135 58.9479 63.0408 58.8124 63.0415C58.6769 63.0422 58.5426 63.0163 58.4172 62.9651L25.4386 49.3003L1.50187 39.3815L1.4653 39.3652H1.42456C1.04687 39.3657 0.684772 39.5157 0.417706 39.7824C0.15064 40.0491 0.000419076 40.4108 0 40.788V102.577C0.000419613 102.954 0.15064 103.316 0.417706 103.583C0.684772 103.85 1.04687 104 1.42456 104H114.575C114.953 104 115.315 103.85 115.582 103.583C115.849 103.316 116 102.954 116 102.577V40.788C116 40.4108 115.849 40.0491 115.582 39.7824C115.315 39.5157 114.953 39.3657 114.575 39.3652ZM115.593 102.577C115.593 102.847 115.486 103.105 115.295 103.296C115.104 103.486 114.845 103.593 114.575 103.594H1.42456C1.15473 103.593 0.895973 103.486 0.70517 103.296C0.514366 103.105 0.407125 102.847 0.407018 102.577V40.788C0.407387 40.5252 0.509456 40.2727 0.691885 40.0833C0.874314 39.8939 1.12298 39.7823 1.38591 39.7717L25.4386 49.7393L58.2605 63.3411C58.6158 63.4855 59.0139 63.4833 59.3676 63.335L91.1719 49.7698L114.616 39.7717C114.879 39.7831 115.127 39.895 115.309 40.0843C115.491 40.2736 115.592 40.5256 115.593 40.788V102.577Z'
      fill='#E5E7EB'
    />
    <path
      d='M58.4772 51.0463C57.2632 51.0486 56.0814 50.6565 55.11 49.9292L55.0498 49.884L42.3689 40.1873C41.7817 39.7379 41.2888 39.1773 40.9184 38.5377C40.548 37.898 40.3074 37.1918 40.2103 36.4592C40.1133 35.7267 40.1616 34.9823 40.3526 34.2684C40.5435 33.5545 40.8734 32.8852 41.3234 32.2987C41.7734 31.7121 42.3346 31.2198 42.9751 30.8499C43.6155 30.48 44.3227 30.2397 45.0561 30.1428C45.7896 30.0458 46.5349 30.0941 47.2497 30.2848C47.9645 30.4755 48.6346 30.805 49.2219 31.2544L57.4356 37.5447L76.8459 12.2626C77.296 11.6762 77.8574 11.1841 78.498 10.8144C79.1386 10.4447 79.8458 10.2047 80.5793 10.108C81.3128 10.0113 82.0582 10.0598 82.7729 10.2508C83.4876 10.4418 84.1576 10.7716 84.7447 11.2212L84.624 11.3848L84.7478 11.2236C85.9321 12.1326 86.707 13.4738 86.9025 14.9527C87.098 16.4317 86.6981 17.9277 85.7905 19.1126L62.9594 48.8504C62.4314 49.5357 61.7523 50.0902 60.975 50.471C60.1977 50.8519 59.343 51.0487 58.4772 51.0463Z'
      fill='#057A55'
    />
  </svg>
);
