import cn from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { useGetAssumedContext } from '@components/AiChat/utils/useGetAssumedContext';
import { BetaFlag, Button, Text } from '@components/common';
import { FEATURE_DATA, FeatureType } from '@components/shared/FeatureBanner/constants';
import { StudySlideOut } from '@components/StudiesApp/types';
import { CloseSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { datadogRum } from '@datadog/browser-rum';
import { useAccount } from '@hooks/useAccount';
import { useAiChat } from '@stores/aiChat';

interface Props {
  source: 'dashboard' | 'dashboard_zds';
  setSlideOut?: (v: StudySlideOut | null) => void;
  type: FeatureType;
  dismissedFeatures: FeatureType[] | null;
  setDismissedFeatures: (v: FeatureType[]) => void;
  translateY?: number;
}

export const FeatureBanner: React.FC<Props> = ({
  dismissedFeatures,
  setDismissedFeatures,
  type,
  source,
  setSlideOut,
  translateY
}) => {
  const isDismissed = dismissedFeatures?.includes(type);

  const {
    bannerSidesWidth = ['7/12', '5/12'],
    isBeta,
    header,
    cta,
    guide_link,
    subheader,
    options,
    cta_url,
    slideout,
    video_url
  } = FEATURE_DATA[type];

  const {
    account: { ai_enabled: aiEnabled }
  } = useAccount();

  const { setContext, setOpen, openWith, messages } = useAiChat();
  const assumed = useGetAssumedContext();
  const { pathname } = useLocation();

  if (isDismissed) return null;

  const openAiSlideout = () => {
    track('open_ask_ai', { via: 'feature_banner', path: pathname, context: assumed ?? null });

    // const DDRum = (window as any).DD_RUM as typeof datadogRum;
    // if (DDRum != null) {
    //   DDRum.startSessionReplayRecording();
    // }

    if (messages.length > 0) {
      setOpen(true);
    } else if (assumed) {
      openWith(assumed.page, assumed.context);
    } else {
      setContext(null);
      setOpen(true);
    }
  };

  const onClick = () => {
    if (aiEnabled && type === 'ai') {
      openAiSlideout();
    } else if (setSlideOut && slideout) {
      setSlideOut(slideout);
    }
  };

  const handleDismiss = () => {
    track(`clicked_${type}_dismiss_button`, { page: source });
    setDismissedFeatures([...(dismissedFeatures || []), type]);
  };

  const getUrl = () => {
    switch (type) {
      case 'ai':
        return aiEnabled ? undefined : cta_url;
      default:
        return cta_url;
    }
  };

  const getCtaText = () => {
    switch (type) {
      case 'ai':
        return aiEnabled ? cta : 'Enable AI';
      default:
        return cta;
    }
  };

  return (
    <section
      className={cn(
        `transform min-h-full bg-indigo-50 border border-gray-200 rounded-md w-full`,
        translateY && `-translate-y-${translateY}`
      )}
      data-testid={`feature-banner-${type}`}
    >
      <div className='flex items-start'>
        <div className='desktop:flex-row desktop:justify-between desktop:space-y-0 flex flex-col items-center w-full p-6 space-y-4'>
          <div className={`relative w-full desktop:w-${bannerSidesWidth[0]}`}>
            <div className='flex items-center mb-2 mr-6 space-x-2'>
              <h4>{header}</h4>
              {isBeta && <BetaFlag color='indigo-600' />}
            </div>
            <Text className='mb-6'>{subheader}</Text>
            <ul className='mb-6 ml-6 list-disc'>
              {options.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
            {cta && (
              <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-2 desktop:space-x-4 whitespace-nowrap flex flex-col items-center space-y-2'>
                <Button
                  trackEvent={`clicked_${type}_feature_banner_cta`}
                  trackProps={{ page: source, cta: cta }}
                  primary
                  href={getUrl()}
                  className='tablet:w-auto w-full'
                  onClick={onClick}
                >
                  {getCtaText()}
                </Button>
                <Button className='tablet:w-auto w-full' data-testid='dismiss-button' onClick={handleDismiss}>
                  Dismiss
                </Button>
                {guide_link && (
                  <Button
                    className='tablet:w-auto w-full'
                    trackEvent={`clicked_${type}_help_link`}
                    trackProps={{ page: source }}
                    link
                    iconSuffix='externalLink'
                    target='_blank'
                    href={guide_link}
                  >
                    Read help guide
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className={`flex items-center justify-center mx-8 w-full desktop:w-${bannerSidesWidth[1]} rounded-md`}>
            {video_url ? (
              <video
                aria-label='Feature banner video'
                controls
                src={video_url}
                className='h-full max-w-full rounded-md'
              />
            ) : (
              <img
                className='h-full max-w-full'
                alt=''
                src={`/images/feature-banners/${type}.png`}
                srcSet={`/images/feature-banners/${type}@2x.png 2x, /images/feature-banners/${type}@3x.png 3x`}
              />
            )}
          </div>
        </div>
        <button
          aria-label='Dismiss feature banner'
          className='top-6 right-6 hover:text-indigo-500 absolute text-gray-700'
          onClick={handleDismiss}
        >
          <CloseSVG className='w-4 h-4' />
        </button>
      </div>
    </section>
  );
};
