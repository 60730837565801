import React, { useState, VFC } from 'react';
import { Text } from '@components/common';
import cn from 'classnames';
import { ChevronDownSVG } from '@components/svgs';
import { CardItem } from '../../shared';
import { EditableTitle } from '@components/common';
import { ErrorMessage } from '@components/common';

interface Props {
  category: { name: string; custom: boolean; cards: string[] };
  cards: string[];
  onNameChange: (categoryName: string, name: string) => void;
  dropProvided: any;
  categories: { name: string; custom: boolean; cards: string[] }[];
  onCategorySelect: (params: { card: string; newCategory: string; initialCategory?: string }) => void;
  isDraggingOver: boolean;
}

export const CategoryCard: VFC<Props> = ({
  dropProvided,
  onNameChange,
  category,
  categories,
  onCategorySelect,
  cards,
  isDraggingOver
}) => {
  const [expanded, setExpanded] = useState(true);
  const [titleError, setTitleError] = useState<'isEmpty' | 'isTaken'>();

  const onBlur = (value: string) => {
    if (value !== category.name) {
      if (value.trim() === '') {
        setTitleError('isEmpty');
      } else if (categories.some((c) => c.name === value.trim())) {
        setTitleError('isTaken');
      } else {
        onNameChange(category.name, value.trim());
      }
    }
  };

  return (
    <div
      className={cn(
        'bg-white border rounded p-4 overflow-hidden',
        isDraggingOver ? 'border-indigo-600 border-dashed' : 'border-gray-200'
      )}
      ref={dropProvided.innerRef}
      {...dropProvided.droppableProps}
    >
      <div className='flex items-center justify-between space-x-1'>
        <EditableTitle
          size='sm'
          lineClamp='1'
          autofocus={!category.name}
          readOnly={!category.custom}
          initialValue={category.name}
          onBlur={onBlur}
        />
        <button
          type='button'
          onClick={() => setExpanded(!expanded)}
          aria-label={expanded ? 'Collapse' : 'Expand'}
          className='flex-shrink-0 hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'
        >
          <ChevronDownSVG className={cn('w-4 h-4 transform', { ['rotate-180']: expanded })} />
        </button>
      </div>
      {titleError === 'isEmpty' && (
        <ErrorMessage className='mt-1' size='small'>
          The category name is required
        </ErrorMessage>
      )}
      {titleError === 'isTaken' && (
        <ErrorMessage className='mt-1' size='small'>
          The category name has already been taken
        </ErrorMessage>
      )}
      {expanded && (
        <>
          {!cards.length ? (
            <div className='h400 no-branding text-gray-500 border border-gray-200 rounded border-dashed py-2.5 mt-4 text-center w-full'>
              This category is empty
            </div>
          ) : (
            <div className='flex flex-col space-y-1 mt-4'>
              {cards.map((card, i) => (
                <CardItem
                  category={category.name}
                  onCategorySelect={onCategorySelect}
                  key={card}
                  card={card}
                  categories={categories}
                  index={i}
                />
              ))}
            </div>
          )}
          <Text h='200' className='mt-4 text-gray-500 no-branding'>
            {cards.length} cards
          </Text>
        </>
      )}
    </div>
  );
};
