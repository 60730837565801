import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationAttrs, DecorationSet } from 'prosemirror-view';

const DECORATION_ATTRS: DecorationAttrs = {
  nodeName: 'gq-text-selection',
  class: 'py-1 bg-indigo-200'
};

export const TextSelection = () =>
  new Plugin({
    key: new PluginKey('textSelection'),
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply({ selection }, _, __, newState) {
        const { from, to } = selection;

        return DecorationSet.create(newState.doc, [Decoration.inline(from, to, DECORATION_ATTRS)]);
      }
    },
    props: {
      decorations(state) {
        return this.getState(state);
      }
    }
  });
