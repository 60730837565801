import React, { SVGProps } from 'react';

export const MobileSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='phone-mobile-device-samsung-1 1'>
      <path
        id='Vector'
        d='M10.5781 0.96875H5.42188C4.38634 0.96875 3.54688 1.80822 3.54688 2.84375V13.1563C3.54688 14.1918 4.38634 15.0313 5.42188 15.0313H10.5781C11.6137 15.0313 12.4531 14.1918 12.4531 13.1563V2.84375C12.4531 1.80822 11.6137 0.96875 10.5781 0.96875Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M8 2.85938C7.91369 2.85938 7.84375 2.78942 7.84375 2.70313C7.84375 2.61683 7.91369 2.54688 8 2.54688'
        stroke='currentColor'
      />
      <path
        id='Vector_3'
        d='M8 2.85938C8.08631 2.85938 8.15625 2.78942 8.15625 2.70313C8.15625 2.61683 8.08631 2.54688 8 2.54688'
        stroke='currentColor'
      />
    </g>
  </svg>
);
