import * as React from 'react';

export const reporting = () => (
  <svg
    className='group-hover:text-gray-300 w-4 h-4 mr-3 text-white'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_25_559)'>
      <path
        d='M5.8125 12.5208L8.14583 10.1875C8.22786 10.1056 8.33906 10.0596 8.455 10.0596C8.57094 10.0596 8.68214 10.1056 8.76417 10.1875L9.89583 11.3192C9.97786 11.4011 10.0891 11.4471 10.205 11.4471C10.3209 11.4471 10.4321 11.4011 10.5142 11.3192L12.8475 8.98584'
        stroke='white'
        strokeWidth='0.583333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.3125 1.4375H13.6875C13.6875 1.4375 14.5625 1.4375 14.5625 2.3125V13.6875C14.5625 13.6875 14.5625 14.5625 13.6875 14.5625H2.3125C2.3125 14.5625 1.4375 14.5625 1.4375 13.6875V2.3125C1.4375 2.3125 1.4375 1.4375 2.3125 1.4375Z'
        stroke='white'
        strokeWidth='0.583333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.1875 5.95825C3.1875 6.30297 3.2554 6.64432 3.38732 6.9628C3.51923 7.28128 3.71259 7.57065 3.95634 7.81441C4.2001 8.05816 4.48948 8.25152 4.80796 8.38344C5.12644 8.51535 5.46778 8.58325 5.8125 8.58325C6.15722 8.58325 6.49856 8.51535 6.81704 8.38344C7.13552 8.25152 7.4249 8.05816 7.66866 7.81441C7.91241 7.57065 8.10577 7.28128 8.23768 6.9628C8.3696 6.64432 8.4375 6.30297 8.4375 5.95825C8.4375 5.61353 8.3696 5.27219 8.23768 4.95371C8.10577 4.63523 7.91241 4.34585 7.66866 4.1021C7.4249 3.85834 7.13552 3.66499 6.81704 3.53307C6.49856 3.40115 6.15722 3.33325 5.8125 3.33325C5.46778 3.33325 5.12644 3.40115 4.80796 3.53307C4.48948 3.66499 4.2001 3.85834 3.95634 4.1021C3.71259 4.34585 3.51923 4.63523 3.38732 4.95371C3.2554 5.27219 3.1875 5.61353 3.1875 5.95825Z'
        stroke='white'
        strokeWidth='0.583333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.8125 3.33325V5.95825L7.6675 7.81325'
        stroke='white'
        strokeWidth='0.583333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.625 4.20825H12.6667'
        stroke='white'
        strokeWidth='0.583333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.625 6.25H12.6667'
        stroke='white'
        strokeWidth='0.583333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_25_559'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
