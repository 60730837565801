import { sum } from '@components/utils';

export type CandidateMessageTab = 'all' | CandidateMessage['state'];

export const tabs: CandidateMessageTab[] = ['all', 'sent', 'opened', 'clicked', 'canceled', 'pending'];

export const buildLabels = (
  counts?: Record<CandidateMessage['state'], number>
): Record<CandidateMessageTab, string> => {
  if (counts) {
    return {
      all: `All (${sum(Object.values(counts))})`,
      sent: `Sent (${counts.sent || 0})`,
      clicked: `Clicked (${counts.clicked || 0})`,
      opened: `Opened (${counts.opened || 0})`,
      canceled: `Canceled (${counts.canceled || 0})`,
      pending: `Pending (${counts.pending || 0})`
    };
  }
  return {
    all: 'All',
    sent: 'Sent',
    clicked: 'Clicked',
    opened: 'Opened',
    canceled: 'Canceled',
    pending: 'Pending'
  };
};
