import { createContext, useContext } from 'react';

export const SectionContext = createContext<number>(0);

export const useSectionContext = () => {
  const sectionContext = useContext<number>(SectionContext);

  if (sectionContext === undefined) throw new Error();

  return sectionContext;
};
