import React from 'react';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { HighlightDropdown } from '@components/RepoSessionApp/components/HighlightsTab/components/HighlightDropdown';
import { Avatar, InfoLabel, Text } from '@components/common';
import { AskAiSVG } from '@components/svgs';
import { format as timeagoFormat } from 'timeago.js';
import { getHighlightDisplayTitle } from '@components/utils';
import { formatDuration } from '@components/shared/Artifact/components/utils';
import { TextNode } from '@components/RepoSessionApp/ClipPage/hooks/useEditorState';
import { EditorState } from 'prosemirror-state';
import { ClipTranscript } from './ClipTranscript';

const renderContent = (content: HighlightContentElement[]) => {
  const containImages = content.some((c) => c.type === 'image');
  const containText = content.some((c) => c.type === 'text');

  if (!containImages) {
    const text = content.map((c: TextContentElement) => c.text).join(' ');
    return <Text h='400'>{text}</Text>;
  }

  if (!containText) {
    return <img src={(content[0] as ImageContentElement).src} alt='' className='w-auto h-48' />;
  }

  const contentAsText = content.map((c) => (c.type === 'text' ? c.text : '[image]'));

  return (
    <Text h='400' as='span'>
      {contentAsText.join(' ')}
    </Text>
  );
};

interface Props {
  highlight: Highlight;
  creator?: TeamUser | null;
  sessionTitle?: string;
  getTextNodes: (timeRange: [number, number]) => TextNode[];
  state: EditorState | undefined;
}

export const HighlightCard: React.FC<Props> = ({ getTextNodes, state, sessionTitle, highlight, creator }) => {
  const { renderTagsList } = useRenderTagsList();

  return (
    <div className='p-4 border border-gray-200 rounded-md'>
      <div className='flex items-center justify-end'>
        <HighlightDropdown highlight={highlight} />
      </div>
      <Text bold className='pr-4 mt-1'>
        {getHighlightDisplayTitle(highlight)}
      </Text>
      <div className='flex flex-wrap items-center mt-1'>
        <div className='flex-nowrap flex items-center py-px mt-1 mr-3 space-x-1'>
          {highlight.ai ? (
            <>
              <AskAiSVG className='w-4 h-4 mr-1' />
              <Text h='400' className='leading-5'>
                Great Question AI
              </Text>
            </>
          ) : (
            creator && (
              <>
                <Avatar user={creator} size='sm' />
                <Text color='gray-500' h='200'>
                  {creator?.name}
                </Text>
              </>
            )
          )}
        </div>
        {sessionTitle && <InfoLabel h='200' className='mt-1 mr-3 text-gray-500' icon='checklist' text={sessionTitle} />}
        {highlight.created_at && (
          <InfoLabel
            h='200'
            className='mt-1 mr-3 text-gray-500'
            icon='changedAt'
            text={timeagoFormat(highlight.created_at)}
          />
        )}
        {highlight.clip && (
          <InfoLabel
            h='200'
            className='mt-1 mr-3 text-gray-500'
            icon='clock'
            text={formatDuration(Math.round(highlight.clip.to / 1000 - highlight.clip.from / 1000))}
          />
        )}
      </div>

      {!highlight.clip && !!highlight.content?.length && (
        <section className='pl-2 mt-4'>{renderContent(highlight.content)}</section>
      )}
      {highlight.clip && getTextNodes && state && (
        <section className='pl-2 mt-4 border-l-2 border-gray-200'>
          <ClipTranscript
            className='mt-4 mb-2'
            range={[highlight.clip.from, highlight.clip.to]}
            getTextNodes={getTextNodes}
            state={state}
          />
        </section>
      )}

      <div className='mt-3'>{renderTagsList(highlight.tag_ids || [], { max: 6 })}</div>
    </div>
  );
};
