import React from 'react';

import { Input, Text } from '@components/common';

export const NumberRange = ({ value, onChange }) => {
  const [min, max] = value || [];
  const changeMin = (newMin) => onChange([newMin, max]);
  const changeMax = (newMax) => onChange([min, newMax]);
  return (
    <div>
      <Input type='number' placeholder='Enter number' value={min || ''} onChange={changeMin} />
      <Text h='400' color='gray-500' className='py-4'>
        and
      </Text>
      <Input type='number' placeholder='Enter number' value={max || ''} onChange={changeMax} />
    </div>
  );
};
