import algoliasearch from 'algoliasearch';
import cn from 'classnames';
import qs from 'qs';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { dashboardContext } from '@components/DashboardApp/DashboardIndex';
import { algoliaAdapter, AlgoliaConfig, AlgoliaData, Search, useSearch } from '@components/shared/Search';
import configStore from '@stores/config';

import { ArtifactHit } from './types';
import { ArtifactCard } from '@components/RepositoryApp/hits/artifacts/ArtifactCard';
import { HighlightArtifact, PreviewContentSlideout } from '@components/RepositoryApp/components/PreviewContentSlideout';

const RECENT_ARTIFACT = {
  Story: {
    title: 'insights',
    link: 'insights',
    filter: '(kind:"Story")'
  },
  'Repo::Session': {
    title: 'interviews',
    link: 'interviews',
    filter: '(kind:"Repo::Session")'
  },
  HighlightReel: {
    title: 'highlight reels',
    filter: '(kind:"HighlightReel")',
    link: 'reels'
  }
};

const DEFAULT_VISIBLE = 3;

interface Props {
  kind: ArtifactHit['kind'];
}

const Artifacts: FC<{ kind: ArtifactHit['kind'] }> = ({ kind }) => {
  const { setHitsNumber } = useContext(dashboardContext);

  const [previewSlideout, setPreviewSlideout] = useState<HighlightArtifact | null>(null);

  const { data } = useSearch<AlgoliaData>();
  const { hits = [] } = data ?? {};

  useEffect(() => setHitsNumber?.((prev) => prev + hits.length), [hits.length]);

  const query = qs.stringify(
    {
      filters: [`artifact_type includes_any ${kind}`]
    },
    { encode: false, arrayFormat: 'brackets' }
  );

  if (!hits.length) {
    return null;
  }

  return (
    <section>
      <div className='flex items-end justify-between'>
        <h2 className='h600 mt-12 mb-4 font-bold'>Recent {RECENT_ARTIFACT[kind].title}</h2>
        {hits.length >= DEFAULT_VISIBLE && (
          <div className='mb-5 text-sm'>
            <Link to={`/repository/${RECENT_ARTIFACT[kind].link}`} className='cursor-pointer'>
              View all
            </Link>
          </div>
        )}
      </div>

      <section className='flex p-4 overflow-y-auto bg-white border border-gray-200 rounded-md'>
        {hits.map((hit, index) => (
          <div key={hit.objectID} className={cn('w-72 flex-shrink-0', { 'mr-4': index < hits.length - 1 })}>
            <ArtifactCard {...(hit as ArtifactHit)} page='dashboard' setPreviewSlideout={setPreviewSlideout} />
          </div>
        ))}
      </section>
      {previewSlideout && (
        <PreviewContentSlideout closeSlideout={() => setPreviewSlideout(null)} artifact={previewSlideout} />
      )}
    </section>
  );
};
export const RecentArtifacts: React.FC<Props> = ({ kind }) => {
  const {
    state: {
      config: {
        algolia: { index_suffix, app_id, api_key }
      }
    }
  } = useContext(configStore);

  const client = useMemo(() => algoliasearch(app_id, api_key), []);

  const addSuffix = (indexName: string) => `${indexName}${index_suffix}_updated_at`;

  const algoliaConfig = useMemo<AlgoliaConfig>(
    () => ({ index: addSuffix('Artifact'), client, hitsPerPage: DEFAULT_VISIBLE }),
    [client]
  );
  return (
    <Search<AlgoliaData, AlgoliaConfig>
      query=''
      filters={RECENT_ARTIFACT[kind].filter}
      adapter={algoliaAdapter}
      config={algoliaConfig}
      infiniteResults
    >
      <Artifacts kind={kind} />
    </Search>
  );
};
