import React, { SVGProps } from 'react';

export const ImportIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='80' height='81' viewBox='0 0 80 81' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.3553 14.7639C13.8087 28.4599 13.9207 43.6972 14.5873 56.1132C32.534 56.5078 47.0353 55.8838 64.2567 56.1132C63.5313 44.1825 63.3927 32.7319 63.19 21.0572C63.158 18.9772 63.1207 16.8919 63.078 14.7959C48.5607 14.7745 26.5287 14.7479 13.3607 14.7639H13.3553Z'
      fill='#E5E7EB'
    />
    <path
      d='M77.19 57.4839C77.1527 61.7345 77.0193 64.7052 76.822 65.1959C75.3393 68.2039 72.1607 68.2039 69.2807 68.2039C67.942 68.2039 57.9793 68.2039 46.358 68.1665C46.2193 68.1719 46.0753 68.1665 45.9367 68.1665C41.5687 68.1559 36.9767 68.1399 32.534 68.1132H32.2193C19.5153 68.0385 8.09134 67.9105 6.60867 67.6759C3.72334 67.1372 2.36334 65.2012 2.486 62.1932V57.5319C15.6807 57.5319 63.0887 57.5319 77.19 57.4892V57.4839Z'
      fill='#00D4C8'
    />
    <path
      d='M77.51 14.9079L77.6327 14.7852C77.702 14.7159 77.622 14.6945 77.558 14.6945C74.646 14.6945 71.734 14.6892 68.8167 14.6839C63.0193 14.6732 57.222 14.6679 51.4247 14.6625C44.5553 14.6519 37.6807 14.6465 30.8113 14.6465C24.8273 14.6465 18.8487 14.6465 12.8647 14.6465C9.57932 14.6465 6.29399 14.6305 3.00865 14.6892C2.88599 14.6892 2.76865 14.6892 2.64599 14.6999C2.54999 14.6999 2.12332 14.7532 2.13399 14.9025C2.21932 16.1825 2.17665 17.4785 2.18732 18.7585C2.19799 21.4732 2.20332 24.1879 2.20332 26.9025C2.20332 33.8945 2.18732 40.8919 2.16599 47.8839C2.15532 51.1372 2.14465 54.3959 2.13399 57.6492C2.13399 57.6812 2.22999 57.6865 2.24065 57.6865H76.9447C77.046 57.6865 77.4566 57.6332 77.4566 57.4839C77.5527 50.7959 77.494 44.1025 77.446 37.4145C77.4033 31.3079 77.318 25.1959 77.366 19.0892C77.3713 18.1345 77.382 17.1799 77.4193 16.2252C77.4353 15.8092 77.3873 15.2812 77.5313 14.8812C77.5633 14.7905 76.9767 14.8439 76.9233 14.9985C76.8113 15.3132 76.8327 15.7025 76.8167 16.0332C76.7793 16.8065 76.7687 17.5799 76.758 18.3585C76.726 20.9079 76.7367 23.4625 76.7527 26.0119C76.7847 32.9772 76.8593 39.9425 76.8807 46.9132C76.8913 50.4919 76.8913 54.0759 76.838 57.6545L77.35 57.4519C70.2727 57.4519 63.19 57.4519 56.1127 57.4519H3.22199C3.12599 57.4519 2.87532 57.4999 2.79532 57.4519C2.70465 57.3985 2.75265 57.2279 2.75265 57.0572C2.75265 56.6359 2.75265 56.2145 2.75265 55.7932C2.75799 53.8945 2.76332 51.9959 2.76865 50.0972C2.78465 45.5319 2.79532 40.9612 2.80065 36.3959C2.81132 29.3985 2.90199 22.3959 2.76332 15.3985C2.76332 15.1799 2.75799 14.9559 2.74199 14.7372L2.22999 14.9399C5.04599 14.8439 7.87799 14.8972 10.694 14.8919C16.4913 14.8812 22.2833 14.8812 28.0807 14.8865C34.9073 14.8865 41.7287 14.8972 48.5553 14.9025C54.614 14.9079 60.6673 14.9185 66.726 14.9239C70.07 14.9239 73.4193 14.9345 76.7633 14.9399C76.838 14.9399 76.9127 14.9345 76.9873 14.9399C77.19 14.9559 77.0513 14.8652 76.9393 14.9772C76.8273 15.0892 77.3927 15.0199 77.494 14.9185L77.51 14.9079Z'
      fill='#12102F'
    />
    <path
      d='M76.8753 57.5105C76.8593 59.3452 76.8327 61.1852 76.742 63.0199C76.694 64.0066 76.7313 64.9612 76.1553 65.7985C74.55 68.1399 71.43 67.9852 68.9127 67.9852C63.2167 67.9852 57.5153 67.9745 51.8193 67.9639C43.382 67.9425 34.95 67.9159 26.5127 67.8519C20.278 67.8039 14.006 67.8732 7.77666 67.5372C5.53133 67.4145 3.50466 66.3959 2.95533 64.0385C2.742 63.1265 2.80066 62.1825 2.80066 61.2545V57.5052L2.45933 57.7505C6.16066 57.7505 9.862 57.7505 13.5633 57.7505C18.8913 57.7505 24.2193 57.7505 29.542 57.7505C35.4407 57.7505 41.3393 57.7505 47.2433 57.7452C52.7633 57.7452 58.2887 57.7399 63.8087 57.7345C67.8993 57.7345 71.9847 57.7239 76.0753 57.7132C76.438 57.7132 76.8007 57.7132 77.1633 57.7132C77.526 57.7132 77.6433 57.2705 77.222 57.2705C73.3767 57.2812 69.5367 57.2865 65.6913 57.2919C60.2887 57.2972 54.8913 57.3025 49.4887 57.3025C43.5847 57.3025 37.6807 57.3079 31.7767 57.3079C26.3207 57.3079 20.8647 57.3079 15.4087 57.3079C11.4513 57.3079 7.49933 57.3079 3.542 57.3079C3.20066 57.3079 2.85933 57.3079 2.518 57.3079C2.40066 57.3079 2.17666 57.4092 2.17666 57.5532C2.17666 59.1052 2.19266 60.6625 2.17666 62.2145C2.16066 63.5905 2.342 64.9932 3.22733 66.1079C4.02733 67.1105 5.28066 67.7025 6.53399 67.8946C8.27799 68.1612 10.1287 68.0759 11.8887 68.1132C15.4673 68.1879 19.0407 68.2252 22.6193 68.2572C31.4407 68.3372 40.262 68.3639 49.0887 68.3905C55.5473 68.4119 62.006 68.4225 68.4647 68.4225C70.742 68.4225 73.286 68.5879 75.2913 67.3079C76.1927 66.7319 77.0887 65.7719 77.238 64.6839C77.4193 63.3399 77.4247 61.9585 77.4567 60.6092C77.4833 59.5585 77.4993 58.5132 77.51 57.4625C77.51 57.1479 76.886 57.2439 76.886 57.5105H76.8753Z'
      fill='#12102F'
    />
    <path
      d='M77.478 14.9932L77.6006 14.8705C77.7233 14.7479 77.6273 14.6359 77.4726 14.6359C74.5606 14.6359 71.6486 14.6305 68.7313 14.6252C62.934 14.6145 57.1366 14.6092 51.3393 14.6039C44.47 14.5932 37.5953 14.5879 30.726 14.5879C24.742 14.5879 18.7633 14.5879 12.7793 14.5879C9.49398 14.5879 6.20865 14.5719 2.92331 14.6305C2.80065 14.6305 2.68331 14.6305 2.56065 14.6412C2.41665 14.6412 2.11798 14.7052 2.13398 14.9025C2.21931 16.1825 2.17665 17.4839 2.18731 18.7639C2.19798 21.4892 2.20331 24.2092 2.20331 26.9345C2.20331 33.9052 2.18731 40.8705 2.16598 47.8412C2.15531 51.0945 2.14465 54.3532 2.13398 57.6065C2.13398 57.7132 2.22998 57.7505 2.32598 57.7505H77.03C77.1313 57.7505 77.4566 57.6919 77.4566 57.5319C77.5526 50.8599 77.494 44.1825 77.446 37.5052C77.4033 31.3665 77.318 25.2225 77.366 19.0839C77.3713 18.1239 77.382 17.1639 77.4193 16.2092C77.4353 15.8039 77.3926 15.2919 77.5366 14.9025C77.6113 14.6945 76.9926 14.7692 76.918 14.9772C76.8006 15.2972 76.8273 15.7079 76.8113 16.0439C76.774 16.8332 76.7633 17.6172 76.7526 18.4065C76.7206 20.9505 76.7366 23.4945 76.7473 26.0439C76.7793 32.9825 76.854 39.9265 76.8753 46.8652C76.886 50.4439 76.886 54.0279 76.8326 57.6065L77.2593 57.3879H2.56065L2.75265 57.5319C2.77398 51.1745 2.79531 44.8172 2.80598 38.4599C2.81665 32.1079 2.83265 25.7559 2.80598 19.4039C2.80065 17.8519 2.85398 16.2839 2.75265 14.7372L2.32598 14.9985C5.14198 14.9025 7.97398 14.9559 10.79 14.9505C16.5393 14.9399 22.294 14.9399 28.0433 14.9452C34.9126 14.9452 41.782 14.9559 48.6513 14.9612C54.6726 14.9665 60.6993 14.9772 66.7206 14.9825C70.0913 14.9825 73.462 14.9932 76.8326 14.9985C76.966 14.9985 77.0993 14.9985 77.238 14.9985L77.11 14.7639L76.9873 14.8865C76.7313 15.1425 77.334 15.1425 77.4833 14.9932H77.478Z'
      fill='#12102F'
    />
    <path
      d='M76.8807 57.5159C76.8647 59.3505 76.838 61.1905 76.7473 63.0252C76.6993 63.9799 76.7367 64.9399 76.198 65.7612C74.6193 68.1612 71.478 68.0012 68.9393 68.0012C63.1793 68.0012 57.414 67.9905 51.654 67.9799C43.2167 67.9585 34.7793 67.9319 26.342 67.8679C20.1607 67.8199 13.9527 67.8892 7.782 67.5585C5.526 67.4359 3.50466 66.3959 2.95533 64.0385C2.742 63.1265 2.80066 62.1825 2.80066 61.2492V57.4999L2.374 57.7292C6.07533 57.7292 9.77666 57.7292 13.478 57.7292C18.806 57.7292 24.134 57.7292 29.4567 57.7292C35.3553 57.7292 41.254 57.7292 47.158 57.7239C52.678 57.7239 58.2033 57.7185 63.7233 57.7132C67.814 57.7132 71.8993 57.7025 75.99 57.6919C76.3527 57.6919 76.7153 57.6919 77.078 57.6919C77.3393 57.6919 77.7233 57.2972 77.3073 57.2972C73.462 57.3079 69.622 57.3132 65.7767 57.3185C60.374 57.3239 54.9767 57.3292 49.574 57.3292C43.67 57.3292 37.766 57.3345 31.862 57.3345C26.406 57.3345 20.95 57.3345 15.494 57.3345C11.5367 57.3345 7.58466 57.3345 3.62733 57.3345C3.286 57.3345 2.94466 57.3345 2.60333 57.3345C2.49133 57.3345 2.17666 57.3932 2.17666 57.5639C2.17666 59.1159 2.19266 60.6732 2.17666 62.2252C2.16066 63.6012 2.342 64.9932 3.22733 66.1025C4.02733 67.0999 5.28066 67.6919 6.52866 67.8785C8.278 68.1345 10.1233 68.0599 11.8833 68.0972C15.5153 68.1719 19.142 68.2092 22.774 68.2465C31.6007 68.3265 40.4327 68.3532 49.2593 68.3799C55.654 68.3959 62.054 68.4065 68.4487 68.4119C70.726 68.4119 73.2753 68.5719 75.2807 67.2972C76.1767 66.7265 77.0833 65.7719 77.2327 64.6892C77.414 63.3452 77.4193 61.9639 77.4513 60.6145C77.478 59.5639 77.494 58.5185 77.5047 57.4679C77.5047 57.1799 76.886 57.2972 76.8807 57.5319V57.5159Z'
      fill='#12102F'
    />
    <path
      d='M52.6087 78.6945H26.71L26.534 78.9825C28.534 78.5719 31.2007 78.3265 32.1287 76.1665C32.6247 75.0199 32.6087 73.6652 32.7207 72.4385C32.7847 71.7185 32.8167 70.9985 32.8327 70.2785C32.8433 69.8625 32.8487 69.4465 32.8487 69.0305C32.8487 68.7319 32.9393 68.2999 32.8487 68.0225L32.4113 68.2519C36.8807 68.2785 41.3447 68.2945 45.814 68.3052L45.6327 68.2039C45.462 68.6305 45.5847 69.3345 45.5793 69.7985C45.574 70.4119 45.5687 71.0252 45.6007 71.6385C45.6647 72.8919 45.8673 74.1825 46.4913 75.2919C47.686 77.4092 50.1927 77.9799 52.23 78.9719C52.3793 79.0465 52.95 78.8119 52.7313 78.7052C50.6833 77.7132 48.1447 77.1372 47.014 74.9559C46.4913 73.9479 46.2993 72.8012 46.2247 71.6812C46.1873 71.0999 46.182 70.5239 46.1873 69.9425C46.1873 69.6759 46.1927 69.4039 46.198 69.1372C46.198 68.8492 46.1287 68.3852 46.2407 68.1132C46.278 68.0172 46.1073 68.0119 46.0593 68.0119C41.59 68.0012 37.126 67.9852 32.6567 67.9585C32.5767 67.9585 32.1713 68.0385 32.2193 68.1879C32.2833 68.3852 32.2247 68.7372 32.2193 68.9452C32.2193 69.2759 32.2193 69.6119 32.214 69.9425C32.2033 70.6839 32.182 71.4199 32.1287 72.1559C32.054 73.2065 32.006 74.2892 31.7927 75.3239C31.5527 76.4705 30.9447 77.3719 29.8673 77.8839C28.8593 78.3639 27.7127 78.4759 26.6353 78.6945C26.4753 78.7265 26.054 78.9825 26.4593 78.9825H52.358C52.5553 78.9825 53.0247 78.6892 52.614 78.6892L52.6087 78.6945Z'
      fill='#12102F'
    />
    <path
      d='M53.3233 79.9425C52.3153 80.0172 51.286 79.9639 50.278 79.9692C48.294 79.9745 46.3153 79.9799 44.3313 79.9799C39.6753 79.9852 35.0193 79.9745 30.358 79.9479C29.158 79.9425 27.9527 79.9425 26.7527 79.9425C26.486 79.9425 25.8727 80.0599 25.654 79.8625C25.4353 79.6652 25.5687 79.0839 25.638 78.8225L25.2967 79.0679H53.206L52.9287 78.9345C52.9607 79.3559 52.9767 79.7719 52.9873 80.1932C52.998 80.5079 53.622 80.4065 53.6113 80.1399C53.6007 79.6812 53.5793 79.2225 53.5473 78.7639C53.5367 78.6412 53.35 78.6305 53.27 78.6305H25.3607C25.2167 78.6305 25.0567 78.7319 25.0193 78.8759C24.8753 79.4145 24.8273 80.2305 25.5153 80.3745C26.2033 80.5185 26.966 80.3852 27.638 80.3852C30.838 80.3852 34.038 80.4279 37.238 80.4279C41.2433 80.4279 45.254 80.4279 49.2593 80.4119C50.5873 80.4119 51.942 80.4759 53.27 80.3799C53.6113 80.3532 53.75 79.9105 53.3287 79.9425H53.3233Z'
      fill='#12102F'
    />
    <path
      d='M65.9046 18.4118C65.8353 14.0172 65.7553 9.59582 65.622 5.08915C63.5366 4.89715 61.19 5.71315 60.0913 7.49982C60.0593 6.17182 59.4353 1.33448 57.5633 0.22515C43.2646 0.11315 30.134 0.619817 15.574 0.619817C16.5126 16.6625 16.4486 37.4305 17.3073 53.4732C35.254 53.8678 49.7553 53.2438 66.9766 53.4732C66.2513 41.5425 66.1126 30.0918 65.91 18.4172L65.9046 18.4118Z'
      fill='#E5E7EB'
    />
    <path
      d='M65.622 5.08915C63.5366 4.89715 61.19 5.71315 60.0913 7.49982C60.0593 6.17182 59.4353 1.33448 57.5633 0.22515C43.2646 0.11315 30.134 0.619817 15.574 0.619817C15.9046 6.27848 16.1126 12.5291 16.278 18.9451L16.4433 19.2278C16.4433 19.2278 49.19 18.6625 65.91 18.4118C65.8406 14.0171 65.7606 9.59582 65.6273 5.08915H65.622Z'
      fill='white'
    />
    <path
      d='M60.918 29.4358C60.8753 26.0012 60.8486 23.5905 60.8486 23.5905C60.8486 23.5905 53.7126 23.7612 45.2166 23.9318C37.4086 24.0705 28.47 24.2252 22.8167 24.2358C22.8273 27.8732 23.0193 31.8625 23.222 35.8945C23.462 40.6785 23.718 45.5158 23.7233 49.8412L28.8647 49.7825L61.2646 49.3718C61.126 44.6838 60.9926 35.4838 60.9233 29.4412L60.918 29.4358Z'
      fill='#FB5779'
    />
    <path
      d='M65.622 5.08916C63.5366 4.89716 61.19 5.71316 60.0913 7.49983C60.0593 6.17183 59.4353 1.33449 57.5633 0.225159C59.9526 0.417159 64.982 4.15583 65.6273 5.09449L65.622 5.08916Z'
      fill='#E5E7EB'
    />
    <path
      d='M15.2593 0.630541C16.0647 14.5079 16.1073 28.4172 16.534 42.3052C16.6513 46.0332 16.79 49.7612 16.9873 53.4839C17.0033 53.8145 17.6273 53.7239 17.6167 53.4412C16.8807 39.5639 16.8433 25.6545 16.3847 11.7665C16.262 8.03854 16.1073 4.31054 15.894 0.587874C15.8727 0.257208 15.2487 0.347874 15.2647 0.630541H15.2593Z'
      fill='#12102F'
    />
    <path
      d='M65.3127 5.1212C65.6593 17.6812 65.6913 30.2465 66.15 42.8012C66.2833 46.3852 66.4487 49.9692 66.662 53.5479C66.678 53.7825 67.2967 53.6439 67.2807 53.3825C66.5287 40.8759 66.4007 28.3425 66.1713 15.8145C66.1073 12.2252 66.0327 8.6412 65.9313 5.05187C65.926 4.76387 65.302 4.88653 65.3073 5.11587L65.3127 5.1212Z'
      fill='#12102F'
    />
    <path
      d='M15.462 0.806517C26.3686 0.806517 37.27 0.502517 48.1713 0.417184C51.2646 0.39585 54.3526 0.390517 57.446 0.417184C57.7073 0.417184 58.0913 0.0278503 57.6753 0.0278503C46.8166 -0.057483 35.958 0.225184 25.0993 0.358517C21.9633 0.39585 18.8273 0.417184 15.6913 0.417184C15.43 0.417184 15.046 0.806517 15.462 0.806517Z'
      fill='#12102F'
    />
    <path
      d='M17.0993 53.5959C29.9527 53.8785 42.8007 53.5959 55.654 53.5479C59.3607 53.5372 63.062 53.5479 66.7687 53.5959C66.9073 53.5959 67.4727 53.3399 67.1687 53.3345C54.4433 53.1692 41.718 53.4945 28.998 53.4679C25.1633 53.4572 21.334 53.4199 17.4993 53.3345C17.3607 53.3345 16.7953 53.5905 17.0993 53.5959Z'
      fill='#12102F'
    />
    <path
      d='M57.3127 0.385201C58.4487 1.08387 58.902 2.69453 59.2167 3.9052C59.5313 5.11587 59.7447 6.31587 59.7767 7.53187C59.782 7.83053 60.278 7.67587 60.3793 7.5212C61.4567 5.81987 63.5687 5.1212 65.5047 5.29187C65.7713 5.3132 66.15 4.93453 65.734 4.8972C63.5047 4.7052 61.0353 5.53187 59.798 7.48387L60.4007 7.4732C60.3687 6.1932 60.1393 4.89187 59.814 3.65453C59.4887 2.4172 58.982 0.795868 57.8033 0.0705345C57.606 -0.0521322 57.046 0.225201 57.318 0.395868L57.3127 0.385201Z'
      fill='#12102F'
    />
    <path
      d='M57.4513 0.438532C58.2193 0.507865 58.9607 0.838532 59.6487 1.17453C60.4807 1.5852 61.2807 2.0652 62.054 2.57187C62.774 3.04653 63.478 3.5532 64.1447 4.09187C64.5607 4.42787 65.03 4.77987 65.3393 5.22253C65.494 5.44653 66.086 5.21186 65.9047 4.95587C65.5847 4.50253 65.1047 4.13987 64.6833 3.7932C63.9687 3.21187 63.2167 2.6732 62.4433 2.16653C61.6273 1.6332 60.7793 1.13187 59.894 0.710532C59.1953 0.379865 58.4433 0.0705322 57.6647 0.00119884C57.3713 -0.0254678 57.03 0.401199 57.446 0.438532H57.4513Z'
      fill='#12102F'
    />
    <path
      d='M16.406 19.4252C20.454 19.3559 24.5073 19.2865 28.5553 19.2172C36.7633 19.0785 44.9713 18.9399 53.1793 18.8065C57.4513 18.7372 61.7287 18.6679 66.0007 18.6092C66.3367 18.6092 66.4913 18.1932 66.0647 18.1985C58.0433 18.3159 50.022 18.4492 42.0007 18.5825C34.902 18.6999 27.8087 18.8225 20.71 18.9399C19.2967 18.9665 17.878 18.9879 16.4647 19.0145C16.1287 19.0199 15.974 19.4305 16.4007 19.4252H16.406Z'
      fill='#12102F'
    />
    <path
      d='M27.5633 6.09185C27.1473 4.58252 25.4993 3.82519 24.0273 4.11319C22.342 4.44385 21.11 5.84119 20.6673 7.45185C19.846 10.4225 21.0513 15.0625 24.6033 15.5532C26.5393 15.8199 28.4273 14.7425 29.6967 13.3559C29.942 13.0839 29.3447 13.0839 29.2007 13.2439C27.91 14.6572 25.894 15.7879 23.9793 14.9239C22.4327 14.2252 21.574 12.5559 21.2327 10.9719C20.838 9.12119 20.9927 6.84385 22.3793 5.41452C23.0833 4.69452 24.07 4.23585 25.0833 4.48652C25.9793 4.70519 26.71 5.36652 26.9553 6.25719C27.0193 6.48119 27.6327 6.31585 27.574 6.09185H27.5633Z'
      fill='#12102F'
    />
    <path
      d='M36.5393 4.36921C36.4593 3.71321 35.862 3.29188 35.2273 3.23855C34.4433 3.17988 33.718 3.64388 33.1633 4.15055C32.3793 4.87055 30.8593 6.85988 32.0806 7.80921C32.582 8.19855 33.286 8.26255 33.8993 8.31588C34.806 8.39588 35.6806 8.59321 36.4646 9.08388C37.8833 9.97455 38.726 11.8145 37.9366 13.4145C36.9393 15.4305 34.2406 14.8652 32.5073 14.4119C32.23 14.3372 31.7766 14.7425 32.1553 14.8439C33.5473 15.2119 35.0886 15.5585 36.5126 15.1372C37.8246 14.7479 38.758 13.6599 38.854 12.2785C38.9606 10.7852 38.134 9.43588 36.87 8.67321C36.2406 8.29455 35.542 8.04921 34.822 7.93188C34.102 7.81455 33.302 7.91055 32.6833 7.52655C31.7126 6.92921 32.694 5.45721 33.1953 4.85455C33.5473 4.43321 34.0006 4.00655 34.5233 3.80921C35.1366 3.57988 35.8353 3.83055 35.9153 4.53455C35.9526 4.82788 36.5713 4.67855 36.534 4.36921H36.5393Z'
      fill='#12102F'
    />
    <path
      d='M40.2087 4.45986C42.2993 8.19319 44.134 12.0599 45.7073 16.0385C45.782 16.2252 46.2727 16.0545 46.326 15.9105C47.7873 11.7719 49.6327 7.78252 51.8407 3.99052C51.91 3.86786 51.3287 3.91052 51.2273 4.08119C49.0193 7.87319 47.174 11.8625 45.7127 16.0012L46.3313 15.8732C44.758 11.8945 42.9233 8.02786 40.8327 4.29452C40.7313 4.11319 40.1393 4.32652 40.214 4.45986H40.2087Z'
      fill='#12102F'
    />
    <path
      d='M22.502 24.2679C22.5447 32.8119 23.3873 41.3292 23.414 49.8732C23.414 49.9799 23.51 50.0225 23.606 50.0225C27.83 49.9692 32.0593 49.9159 36.2833 49.8625C43.0193 49.7772 49.7607 49.6919 56.4967 49.6065C58.0434 49.5852 59.5953 49.5692 61.142 49.5479C61.3873 49.5479 61.7927 49.1745 61.3767 49.1799C57.1527 49.2332 52.9233 49.2865 48.6993 49.3399C41.9633 49.4252 35.222 49.5105 28.486 49.5959C26.9393 49.6172 25.3873 49.6332 23.8407 49.6545L24.0327 49.8039C24.0113 41.2599 23.1633 32.7425 23.1207 24.1985C23.1207 23.9265 22.502 24.0545 22.502 24.2679Z'
      fill='#12102F'
    />
    <path
      d='M22.79 24.4652C28.79 24.4599 34.79 24.3479 40.7846 24.2412C46.342 24.1399 51.9046 24.0225 57.462 23.8945C58.582 23.8679 59.702 23.8412 60.8273 23.8145L60.5393 23.6065C60.6033 30.1399 60.6833 36.6785 60.806 43.2119C60.8433 45.2705 60.886 47.3292 60.95 49.3879C60.9606 49.7185 61.5846 49.6279 61.5793 49.3452C61.3393 41.5479 61.2806 33.7505 61.19 25.9479C61.1793 25.1532 61.174 24.3585 61.1633 23.5639C61.1633 23.4039 61.014 23.3505 60.8753 23.3559C57.686 23.4359 54.4966 23.5052 51.3126 23.5745C44.966 23.7132 38.6246 23.8359 32.278 23.9265C29.1313 23.9692 25.99 24.0065 22.8433 24.0119C22.486 24.0119 22.374 24.4759 22.79 24.4759V24.4652Z'
      fill='#12102F'
    />
    <path
      d='M28.214 24.3052C28.5393 32.0599 28.534 39.8305 28.5553 47.5905C28.5553 48.3479 28.5553 49.1052 28.5553 49.8572C28.5553 49.9745 29.174 49.8839 29.174 49.6919C29.174 43.3185 29.1313 36.9452 29.0087 30.5719C28.966 28.4279 28.918 26.2839 28.8273 24.1399C28.8273 24.0172 28.2033 24.1185 28.2087 24.3052H28.214Z'
      fill='#12102F'
    />
    <path
      d='M44.9073 24.0065C45.238 31.6812 45.334 39.3719 45.4673 47.0572C45.4833 47.8519 45.494 48.6519 45.5047 49.4465C45.5047 49.5319 46.1233 49.4465 46.1233 49.2812C46.022 42.8279 45.8993 36.3745 45.7233 29.9265C45.67 27.8999 45.6113 25.8679 45.5207 23.8412C45.5207 23.7505 44.8967 23.8412 44.902 24.0065H44.9073Z'
      fill='#12102F'
    />
    <path
      d='M22.9927 30.1612C28.5287 29.9425 34.0753 29.8998 39.6167 29.8252C45.4513 29.7505 51.2807 29.6972 57.1153 29.6492C58.342 29.6385 59.574 29.6279 60.8007 29.6225C61.0567 29.6225 61.446 29.2332 61.03 29.2332C57.5527 29.2545 54.0753 29.2865 50.598 29.3185C44.1553 29.3772 37.7127 29.4465 31.27 29.5692C28.534 29.6172 25.8033 29.6705 23.0673 29.7772C22.742 29.7879 22.5713 30.1772 22.9927 30.1612Z'
      fill='#12102F'
    />
    <path
      d='M23.0833 36.0172C33.062 35.5372 43.0673 35.7452 53.0566 35.5639C55.6326 35.5159 58.2086 35.4465 60.7846 35.3239C60.95 35.3185 61.4566 35.0625 61.0513 35.0785C51.0726 35.5585 41.0673 35.3505 31.078 35.5319C28.502 35.5799 25.926 35.6492 23.35 35.7719C23.1846 35.7772 22.678 36.0332 23.0833 36.0172Z'
      fill='#12102F'
    />
    <path
      d='M23.6113 42.1345C27.8246 42.1612 32.0326 42.1932 36.246 42.2199C42.902 42.2679 49.558 42.3105 56.214 42.3585C57.7446 42.3692 59.2753 42.3799 60.806 42.3905C61.0833 42.3905 61.446 41.9745 61.03 41.9745C56.8167 41.9479 52.6086 41.9159 48.3953 41.8892C41.7393 41.8412 35.0833 41.7985 28.4273 41.7505C26.8966 41.7399 25.366 41.7292 23.8353 41.7185C23.558 41.7185 23.1953 42.1345 23.6113 42.1345Z'
      fill='#12102F'
    />
    <path
      d='M71.6487 43.3452C71.3873 42.8172 71.0673 42.3158 70.6833 41.8518C68.9553 39.7718 66.0487 38.8225 63.382 39.2492C58.7633 39.9905 55.0833 44.5238 56.3687 49.2492C58.022 55.3238 64.886 57.9372 70.2833 54.4118C71.9367 53.3292 72.5607 51.3932 72.6673 49.5318C72.79 47.4252 72.566 45.2065 71.6487 43.3452Z'
      fill='#5850EC'
    />
    <path
      d='M71.958 43.2652C70.166 39.7612 66.0646 38.2998 62.3526 39.3665C59.0566 40.3158 56.2246 43.1105 55.846 46.6145C55.4673 50.1185 57.5366 53.4092 60.47 55.0145C63.2166 56.5185 66.566 56.4652 69.3553 55.0998C71.318 54.1452 72.5126 52.6251 72.87 50.4492C73.254 48.0812 72.998 45.4358 71.958 43.2652C71.878 43.0998 71.2806 43.3025 71.3393 43.4305C72.1766 45.1798 72.438 47.1265 72.3846 49.0518C72.3366 50.8065 71.958 52.6838 70.63 53.9372C68.3366 56.0972 64.518 56.3532 61.734 55.1265C58.95 53.8998 57.046 51.4038 56.534 48.5078C55.9473 45.1958 57.8246 41.9158 60.6993 40.3052C64.47 38.1932 69.366 39.5532 71.3446 43.4252C71.43 43.5905 72.022 43.3825 71.9633 43.2598L71.958 43.2652Z'
      fill='#12102F'
    />
    <path
      d='M63.3393 48.9399L59.6273 49.0252C59.926 49.6492 63.9367 53.2439 65.03 54.2572C65.03 54.2572 68.31 50.3692 69.798 48.8225H65.9953C65.7713 47.0519 65.9633 43.4625 65.8513 43.3879C65.7713 43.3292 63.814 43.3879 63.1793 43.3879C63.318 45.2492 63.334 48.9452 63.334 48.9452L63.3393 48.9399Z'
      fill='white'
    />
    <path
      d='M63.462 48.7692C62.2247 48.7958 60.9873 48.8278 59.75 48.8545C59.6753 48.8545 59.2327 48.9452 59.318 49.1052C59.5047 49.4572 59.83 49.7505 60.102 50.0278C60.6193 50.5558 61.1527 51.0625 61.6913 51.5692C62.6993 52.5132 63.7233 53.4358 64.7367 54.3745C64.8807 54.5078 65.2007 54.3905 65.3127 54.2572C66.854 52.4332 68.39 50.5878 70.0433 48.8652C70.1607 48.7425 70.0753 48.6518 69.9207 48.6518H66.118L66.3047 48.7372C66.102 47.0465 66.2407 45.3452 66.1927 43.6545C66.182 43.2012 66.0487 43.2012 65.606 43.1905C64.838 43.1798 64.07 43.2118 63.2967 43.2172C63.1633 43.2172 62.854 43.2865 62.8647 43.4678C62.998 45.3132 63.014 47.1745 63.0193 49.0252C63.0193 49.2065 63.638 49.0892 63.638 48.8598C63.6273 47.0092 63.6167 45.1478 63.4833 43.3025L63.0513 43.5532C63.7393 43.5532 64.4273 43.5265 65.1153 43.5265C65.2487 43.5265 65.3873 43.5158 65.5153 43.5265C65.6647 43.5425 65.5313 43.4625 65.5527 43.5265C65.6113 43.7025 65.5687 43.9532 65.574 44.1398C65.574 45.7238 65.494 47.3238 65.6807 48.8972C65.6913 48.9718 65.814 48.9825 65.8673 48.9825H69.67L69.5473 48.7692C67.8673 50.5238 66.3047 52.3958 64.7367 54.2465L65.3127 54.1292C64.3313 53.2225 63.334 52.3265 62.358 51.4145C61.8353 50.9238 61.318 50.4332 60.8113 49.9265C60.518 49.6332 60.1233 49.3078 59.926 48.9345L59.494 49.1852C60.7313 49.1585 61.9687 49.1265 63.206 49.0998C63.43 49.0998 63.862 48.7532 63.4513 48.7638L63.462 48.7692Z'
      fill='#12102F'
    />
  </svg>
);
