import { Toast } from '@stores/toaster';

export const FAILED_SUBSET: Toast = {
  heading: 'Failed to select a subset',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};
export const SUCCESS_UPDATE_CONTACT_ACCESS: Toast = {
  heading: 'Contact Access updated',
  text: 'Candidate settings have been updated.',
  icon: 'success'
};
export const FAILED_UPDATE_CONTACT_ACCESS: Toast = {
  heading: 'Failed to update contact access',
  text: 'Settings could not be changed. Please try again later, or contact support.',
  icon: 'error'
};
