import { Tabs } from '@components/common';
import * as React from 'react';
import { useState } from 'react';
import { HighlightsTab } from '@components/RepoSessionApp/components/HighlightsTab';
import { TranscriptTab } from '@components/RepoSessionApp/components/TranscriptTab';
import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { Summary } from '@components/shared/Summary';
import { useGetRecordingQuery } from '@components/shared/Tiptap/api';
import { useTranscriptStatus } from '@components/RepoSessionApp/RecordingWidget/hooks/useTranscriptStatus';
import { api } from '@api/reduxApi';

export const InterviewContent: React.FC<{ session: RepoSession }> = ({ session }) => {
  const [activeTab, setActiveTab] = useState('transcript');

  const { study, recording = null, document_id: documentId = null, uuid: sessionUuid } = session ?? {};

  const { data: recordingData } = useGetRecordingQuery({ id: recording?.id as number }, { skip: !recording?.id });
  const { data: highlights } = api.useGetHighlightsFromDocumentQuery(recording?.transcript?.document_id as number, {
    skip: !recording?.transcript?.document_id
  });

  const { message: transcriptStatus } = useTranscriptStatus(recording?.transcript);

  const tiptapWithTranscript = useTiptapFromDocumentId({
    documentId: recording?.transcript?.document_id as number,
    studyId: study?.id,
    sessionUuid,
    scrollToTop: false,
    readonly: true,
    slideoutView: true,
    config: {
      highlight: { enable: true, menuPlacement: 'left-start' }
    },
    withoutPlayer: true
  });

  const tiptapNotes = useTiptapFromDocumentId({
    documentId: documentId as number,
    studyId: study?.id,
    readonly: true,
    scrollToTop: false,
    config: {
      highlight: { enable: true, menuPlacement: 'bottom-start' },
      link: { enable: true },
      image: { enable: true },
      templates: true,
      artifacts: true,
      placeholder: 'No notes added.'
    }
  });

  let tabContent: React.ReactNode;
  switch (activeTab) {
    case 'highlights':
      tabContent = <HighlightsTab readOnly highlights={highlights} recording={recordingData} />;
      break;
    case 'summary':
      tabContent = session ? (
        <div className='w-full px-4 py-3'>
          <Summary session={session} readOnly />
        </div>
      ) : null;
      break;
    case 'transcript':
      tabContent = (
        <div className='w-full p-3'>
          <TranscriptTab
            readOnly
            transcriptStatus={transcriptStatus}
            tiptap={tiptapWithTranscript}
            sessionUuid={sessionUuid}
            studyId={study?.id}
            recording={recording}
          />
        </div>
      );
      break;
    case 'notes':
      tabContent = (
        <div className='w-full'>
          <Tiptap {...tiptapNotes.getTiptapProps()}>
            <div className='px-6 py-4'>
              <tiptapNotes.Content {...tiptapNotes.getContentProps()} />
            </div>
          </Tiptap>
        </div>
      );
      break;
    default:
      tabContent = null;
  }
  return (
    <>
      <Tabs
        current={activeTab}
        labels={{
          transcript: 'Transcript',
          highlights: 'Highlights',
          notes: 'Notes',
          summary: 'Summary'
        }}
        onSelect={setActiveTab}
        className='flex items-center px-6 border-b border-gray-200'
        tabs={['summary', 'transcript', 'highlights', 'notes']}
      />

      {tabContent}
    </>
  );
};
