import cn from 'classnames';
import { Task as Preview } from 'components/Public/GQSurvey/fields/TaskInput';
import { shuffle } from 'components/utils';
import React, { FC, Fragment, HTMLProps, ReactElement, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvidedDragHandleProps, Droppable, DropResult } from 'react-beautiful-dnd';

import { Button, Input, Text, Textarea } from '@components/common';
import { DragSVG, TrashSVG } from '@components/svgs';
import Tippy from '@tippyjs/react';

interface SubTaskData {
  position: number;
  label: string;
}

interface SubTaskProps {
  task: SubTaskData;
  placeholder?: string;
  onChange: (subTask: SubTaskData) => void;
  onDelete: (subTask: SubTaskData) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  hideControls?: boolean;
}

const PLACEHOLDERS = [
  'Make a dentist appointment for next Tuesday at 10am',
  'Book a hotel for this weekend',
  'Look up the results of your midterm exams',
  'Find a concert to go to tonight',
  "Book a restaurant reservation for your family's trip in August",
  'Hire movers to pack up your apartment',
  'Find a good dog park in your city',
  'Find the average price of houses in your city',
  'Create a playlist of your favorite songs ',
  "Let your colleague know you'll be late to the office",
  "Let your colleague know they're on mute",
  'Remind your customer about an upcoming call'
];

const subTaskBuilder = (subTasks: string[]): SubTaskData[] =>
  subTasks.map((label, i) => ({
    position: i,
    label
  }));

const updateSubTask = (subTasks: string[], { position, label }: SubTaskData) => {
  const newSubTasks = [...subTasks];
  newSubTasks[position] = label;
  return newSubTasks;
};

const deleteSubTask = (subTasks: string[], { position }: SubTaskData) => subTasks.filter((_, i) => i !== position);

const fieldOptionsBuilder = (subTasks: SubTaskData[]) =>
  subTasks.sort(({ position: posA }, { position: posB }) => posA - posB).map(({ label }) => label);

export const MediaInput: FC<{ start?: ReactElement | null; end?: ReactElement | null } & HTMLProps<HTMLInputElement>> =
  ({ className, start, end, ...rest }) => (
    <div
      className={cn(
        'focus-within:border-indigo-500 tablet:text-sm focus:ring-indigo-500 flex items-stretch  border border-gray-200 rounded-md',
        className
      )}
    >
      {start && <div>{start}</div>}
      <div className='flex-1 px-4 py-3'>
        <input className='p0 w-full placeholder-gray-400 border-none outline-none' {...rest} />
      </div>
      {end && <div>{end}</div>}
    </div>
  );

const SubTask: FC<SubTaskProps> = ({
  task: { position, label },
  placeholder,
  onChange,
  onDelete,
  dragHandleProps,
  hideControls
}) => {
  return (
    <MediaInput
      name={`subTask-${position}`}
      className='w-full mb-3'
      value={label}
      placeholder={placeholder ? `e.g. ${placeholder}` : `Task #${position + 1}`}
      onChange={(event) => onChange({ position, label: event.currentTarget.value })}
      end={
        !hideControls ? (
          <div className='flex items-center h-full pr-3 space-x-1'>
            <Tippy maxWidth={240} arrow={false} content='Delete task'>
              <button
                className='hover:bg-gray-100 focus:outline-none flex items-center justify-center w-6 h-6 rounded-full'
                onClick={() => onDelete({ position, label })}
                aria-label={`Delete ${label}`}
              >
                <TrashSVG className='text-gray-700' />
              </button>
            </Tippy>
            <Tippy maxWidth={240} arrow={false} content='Reorder task'>
              <button
                className='hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'
                {...dragHandleProps}
              >
                <DragSVG className='text-gray-700' />
              </button>
            </Tippy>
          </div>
        ) : null
      }
    />
  );
};

export const Task: QuestionCardBodyComponent = ({ question: q, onChange }) => {
  const [preview, setPreview] = useState<boolean>(false);
  const fieldOptions = q.options ?? [];
  const subTasks: SubTaskData[] = subTaskBuilder(fieldOptions);

  const placeholders = useMemo(() => shuffle(PLACEHOLDERS), []);

  const handleSubTaskUpdate = (subTask: SubTaskData) => {
    onChange({ ...q, options: updateSubTask(fieldOptions, subTask) });
  };

  const handleOnTaskDelete = (subTask: SubTaskData) => {
    onChange({ ...q, options: deleteSubTask(fieldOptions, subTask) });
  };

  const handleOnTaskCreate = () => {
    onChange({ ...q, options: [...fieldOptions, ''] });
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const {
      destination: { index: destinationIndex },
      source: { index: sourceIndex }
    } = result;

    const newSubTasks = subTasks.reduce((acc, { position, ...rest }) => {
      if (position === destinationIndex) {
        return [...acc, { ...rest, position: sourceIndex }];
      }

      if (position === sourceIndex) {
        return [...acc, { ...rest, position: destinationIndex }];
      }

      return [...acc, { ...rest, position }];
    }, []);

    onChange({ ...q, options: fieldOptionsBuilder(newSubTasks) });
  };

  useEffect(() => {
    if (!fieldOptions.length) {
      onChange({ ...q, options: [''] });
    }
  }, [fieldOptions]);

  return (
    <div>
      <div className='mb-6'>
        <Text h='400' bold mb='2'>
          Link
        </Text>
        <Text h='400' mb='2'>
          This is what the participants will be asked to click through.
        </Text>
        <Input
          name='link'
          className='w-full'
          value={q.param || undefined}
          placeholder='e.g. Figma, InVision'
          onChange={(v) => onChange({ ...q, param: v })}
        />
      </div>
      <div className='mb-6'>
        <Text h='400' bold mb='2'>
          Tasks
        </Text>
        <Text h='400' mb='2'>
          This is what participants will be asked to complete when viewing the prototype. <br />
          Multiple tasks will be shown one by one.
        </Text>

        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='sub-tasks'>
            {(dropProvided) => (
              <section ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
                {subTasks.map((task) => (
                  <Fragment key={task.position}>
                    <Draggable
                      draggableId={`sub-task_draggable-${task.position}`}
                      index={task.position}
                      isDragDisabled={subTasks.length === 1}
                    >
                      {(dragProvided) => (
                        <div ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                          <SubTask
                            key={task.position}
                            task={task}
                            placeholder={placeholders[task.position]}
                            onChange={handleSubTaskUpdate}
                            onDelete={handleOnTaskDelete}
                            dragHandleProps={dragProvided.dragHandleProps}
                            hideControls={subTasks.length === 1}
                          />
                        </div>
                      )}
                    </Draggable>
                  </Fragment>
                ))}
                {dropProvided.placeholder}
              </section>
            )}
          </Droppable>
        </DragDropContext>

        <Button icon='plus' onClick={handleOnTaskCreate} className='text-indigo-600' link>
          Add task
        </Button>
      </div>
      {q.meta.can_iframe ? (
        <Button primary className='w-auto' onClick={() => setPreview(true)}>
          Preview
        </Button>
      ) : (
        <Button primary className='w-auto' target='_blank' href={q.param || undefined}>
          Preview
        </Button>
      )}
      {preview && <Preview preview url={q.param as any} onClose={() => setPreview(false)} options={q.options} />}
    </div>
  );
};
