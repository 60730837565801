import { useEffect, useState } from 'react';

interface Hook<R> {
  loading: boolean;
  records: R[] | null;
  refresh: () => void;
  setRecords: (value: R[]) => void;
}
export const useRecords = <R>(fetch: () => Promise<R[]>): Hook<R> => {
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState<R[]>([]);

  function fetchRecords() {
    fetch().then((records) => {
      if (records) {
        setRecords(records);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchRecords();
  }, []);

  return { records, setRecords, loading, refresh: fetchRecords };
};
