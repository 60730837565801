import React, { SVGProps } from 'react';

export const HorizontalEllipsisSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.6875 11.0215C0.6875 11.6597 0.941015 12.2717 1.39227 12.723C1.84353 13.1742 2.45557 13.4277 3.09375 13.4277C3.73193 13.4277 4.34397 13.1742 4.79523 12.723C5.24649 12.2717 5.5 11.6597 5.5 11.0215C5.5 10.3833 5.24649 9.77127 4.79523 9.32001C4.34397 8.86875 3.73193 8.61523 3.09375 8.61523C2.45557 8.61523 1.84353 8.86875 1.39227 9.32001C0.941015 9.77127 0.6875 10.3833 0.6875 11.0215Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 11.0215C16.5 11.3375 16.5622 11.6504 16.6832 11.9423C16.8041 12.2343 16.9813 12.4995 17.2048 12.723C17.4282 12.9464 17.6935 13.1236 17.9854 13.2446C18.2774 13.3655 18.5903 13.4277 18.9062 13.4277C19.2222 13.4277 19.5351 13.3655 19.8271 13.2446C20.119 13.1236 20.3843 12.9464 20.6077 12.723C20.8312 12.4995 21.0084 12.2343 21.1293 11.9423C21.2503 11.6504 21.3125 11.3375 21.3125 11.0215C21.3125 10.7055 21.2503 10.3926 21.1293 10.1007C21.0084 9.80871 20.8312 9.54345 20.6077 9.32001C20.3843 9.09657 20.119 8.91933 19.8271 8.7984C19.5351 8.67747 19.2222 8.61523 18.9062 8.61523C18.5903 8.61523 18.2774 8.67747 17.9854 8.7984C17.6935 8.91933 17.4282 9.09657 17.2048 9.32001C16.9813 9.54345 16.8041 9.80871 16.6832 10.1007C16.5622 10.3926 16.5 10.7055 16.5 11.0215Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.59375 11.0215C8.59375 11.6597 8.84726 12.2717 9.29852 12.723C9.74978 13.1742 10.3618 13.4277 11 13.4277C11.6382 13.4277 12.2502 13.1742 12.7015 12.723C13.1527 12.2717 13.4062 11.6597 13.4062 11.0215C13.4062 10.3833 13.1527 9.77127 12.7015 9.32001C12.2502 8.86875 11.6382 8.61523 11 8.61523C10.3618 8.61523 9.74978 8.86875 9.29852 9.32001C8.84726 9.77127 8.59375 10.3833 8.59375 11.0215Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='0.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
