import cn from 'classnames';
import { AvatarInitials } from 'components/common/Avatar';
import { ExternalLinkIcon } from 'components/CustomerImports/steps/UploadStep';
import { useOnClickOutside } from 'components/utils';
import { PLATFORM_SENDER_EMAIL, SUPPORT_EMAIL } from '@constants/emails';
import { useUser } from 'hooks/useUser';
import React, { useRef, useState } from 'react';

import { AvatarFromId, Button, SelectOption as SelectOptionType, Text } from '@components/common';
import { LogoSVG } from '@components/svgs';

export interface SelectOptionGroup {
  label: string;
  value: string;
  position: number;
  options: SelectOptionType[];
}

interface OptionProps {
  option: SelectOptionType;
  selected: boolean;
  onClick: () => void;
}

interface Props {
  groups: SelectOptionGroup[];
  disabled?: boolean;
  selected?: { group: string; option: string };
  onChange: (values: { group: string; option: string; sender: EmailSender }) => void;
  error?: boolean;
}

const Label: React.FC<{ option: SelectOptionType; selected?: boolean }> = ({ option, selected }) => {
  const { id } = useUser();
  return (
    <div className={`flex items-center space-x-4`}>
      {option.data?.level === 'user' && (
        <AvatarFromId userId={option.data?.user_id} size='xl' noTippy className={option.disabled ? 'opacity-50' : ''} />
      )}
      {option.data?.level === 'system' && (
        <div className='flex flex-row items-center justify-center w-10 h-10 bg-white border border-gray-200 rounded-full'>
          <LogoSVG className='w-8 h-8 text-indigo-600 fill-current' />
        </div>
      )}
      {option.data?.level === 'account' && (
        <AvatarInitials size='xl' initials={option.label.slice(0, 2)} colorKey={option.label} />
      )}
      <div className={`flex flex-col flex-grow ${option.disabled ? ' opacity-50' : ''}`}>
        <Text h='400'>{option.data?.name}</Text>
        <Text
          h='400'
          className={'group-hover:font-semibold block ml-3 truncate ' + selected ? 'font-normal' : 'font-semibold'}
        >
          {option.label}
        </Text>
      </div>
      {option.data?.user_id === id &&
        option.data.connected &&
        option.disabled &&
        option.value !== PLATFORM_SENDER_EMAIL && (
          <div className='flex flex-row items-center space-x-2'>
            <Button
              small
              primary
              href='/user/connected_accounts'
              target='_blank'
              trackEvent='clicked_connect_from_email_senders'
            >
              Reconnect
            </Button>
          </div>
        )}
      {option.data?.user_id === id &&
        option.data.connected &&
        option.disabled &&
        option.value === PLATFORM_SENDER_EMAIL && (
          <div className='flex flex-row items-center space-x-2'>
            <Button
              small
              primary
              href={`mailto:${SUPPORT_EMAIL}?subject=Send%20from%20platform`}
              target='_blank'
              trackEvent='clicked_connect_from_email_senders'
            >
              Request access
            </Button>
          </div>
        )}

      {option.data?.level === 'user' && !option.data.connected && (
        <div className='flex flex-row items-center space-x-2'>
          <Button
            small
            primary
            href='/user/connected_accounts'
            target='_blank'
            trackEvent='clicked_connect_from_email_senders'
          >
            Connect account
          </Button>
        </div>
      )}
    </div>
  );
};

const SelectOption: React.FC<OptionProps> = ({ option, selected, onClick }) => {
  // TODO: add tooltip for Sean.
  return (
    <li
      className={
        ' pr-9 hover:bg-indigo-600 group hover:text-white cursor-pointer relative py-2 pl-3 text-gray-900 select-none'
      }
      key={`option_${option.value}_${option.data?.user_id}`}
      role='option'
      aria-selected={selected}
      onClick={() => !option.disabled && onClick()}
    >
      <Label option={option} selected={selected} />
      {selected && (
        <span className='group-hover:text-white absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600'>
          <svg
            className='w-5 h-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      )}
    </li>
  );
};

export const GroupSelect: React.FC<Props> = ({ error, groups, selected, disabled, onChange }) => {
  const [show, setShow] = useState<boolean>(false);
  const selectedOption = selected
    ? groups.find((g) => g.value === selected.group)?.options.find((o) => o.value === selected.option)
    : null;
  const selectRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(selectRef, () => setShow(false));

  return (
    <div className='relative mt-1' ref={selectRef}>
      <button
        type='button'
        className={cn(
          'focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm relative w-full py-2 pl-3 pr-10 text-left rounded-md cursor-default',
          {
            'bg-gray-50': disabled,
            'bg-white border border-gray-300': !disabled && !error,
            'bg-white border border-red-600': error
          }
        )}
        aria-haspopup='listbox'
        aria-expanded='true'
        aria-labelledby='listbox-label'
        aria-label='Select sender'
        onClick={() => !disabled && setShow(!show)}
      >
        {selectedOption ? <Label option={selectedOption} /> : <span>Please select…</span>}
        {!disabled && (
          <span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
            <svg
              className='w-5 h-5 text-gray-400'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
              fill='currentColor'
              aria-hidden='true'
            >
              <path
                fillRule='evenodd'
                d='M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </span>
        )}
      </button>
      {show && (
        <ul
          className='ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm xx-grouped-select xx-grouped-options absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg'
          tabIndex={-1}
          role='listbox'
          aria-labelledby='listbox-label'
          aria-activedescendant='listbox-option-3'
        >
          {groups.map((group) => (
            <li className='pb-4' key={`sender-group-${group.position}`}>
              <div className='p-4 pb-2'>
                <Text h='400' color='gray-500'>
                  {group.label}
                </Text>
              </div>
              <ul>
                {group.options.map((option) => (
                  <SelectOption
                    key={`select_option_${option.value}_${option.data?.user_id}`}
                    option={option}
                    selected={group.value === selected?.group && option.value === selected?.option}
                    onClick={() => {
                      onChange({ group: group.value, option: option.value, sender: option.data });
                      setShow(false);
                    }}
                  />
                ))}
              </ul>
            </li>
          ))}
          <li className='px-4 py-2 text-right border-t border-gray-200'>
            <a
              href='/user/connected_accounts'
              target='_blank'
              className='flex flex-row items-center justify-end space-x-2'
            >
              <Text h='400' color='indigo-600'>
                Manage integrations
              </Text>
              <ExternalLinkIcon />
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};
