import * as React from 'react';

import {
  AddSVG,
  CandidatesRecommendedSVG,
  ChecklistSVG,
  IncentivesRecommendedSVG,
  InvoiceSVG,
  LightBulbSVG,
  OnlineTaskSVG,
  RepositoryRecommendedSVG,
  StudiesRecommendedSVG,
  SurveysSVG,
  UnmoderatedSVG,
  WalletSVG,
  ZoomGlassSVG
} from '@components/svgs';

export type ZDSType = 'studies' | 'candidates' | 'repository' | 'incentives';

export const ZDSData = {
  studies: {
    header: 'Studies',
    description: 'Organize, execute and track your research studies from sending invites through paying incentives.',
    subheader: 'Start your next research study'
  },
  candidates: {
    header: 'Candidates',
    description: 'Build and manage a panel of candidates you can invite to participate in research studies.',
    subheader: 'Start building your candidate panel'
  },
  repository: {
    header: 'Repository',
    description: 'Your single place to store interview recordings, insights, and more.',
    subheader: 'Start building your repository'
  },
  incentives: {
    header: 'Incentives',
    description: 'This is where you can send incentives, deposit funds, and create invoices.',
    subheader: 'Start sending your incentives'
  }
};

export const RMContent = {
  studies: {
    svg: StudiesRecommendedSVG,
    header: 'Interviews',
    subheader: 'Schedule calls to ask questions, share ideas or test designs.',
    button: {
      label: 'New interview study',
      icon: 'plus' as const,
      href: '',
      trackEvent: 'clicked_new_interview',
      trackProps: { source: 'studies_zds' }
    }
  },
  candidates: {
    svg: CandidatesRecommendedSVG,
    header: 'Import a list',
    subheader: 'You can upload a CSV file of existing customers and securely import their information.',
    button: {
      label: 'Import a list',
      icon: 'import' as const,
      href: '/customer_imports/new',
      trackEvent: 'clicked_import',
      trackProps: { source: 'candidates_zds' }
    }
  },
  repository: {
    svg: RepositoryRecommendedSVG,
    header: 'Upload interview recordings',
    subheader: 'Upload your past interview recordings and we’ll automatically transcribe it for you.',
    button: {
      label: 'Upload interviews',
      icon: 'import' as const,
      href: '',
      trackEvent: 'clicked_upload_interviews',
      trackProps: { source: 'repository_zds' }
    }
  },
  incentives: {
    svg: IncentivesRecommendedSVG,
    header: 'Send incentive',
    subheader: 'Say “thank you” with any incentive to any country, for free!',
    button: {
      label: 'Send incentive',
      icon: 'money' as const,
      href: '',
      trackEvent: 'clicked_send_incentives',
      trackProps: { source: 'incentives_zds' }
    }
  }
};

type CandidatesZDSButton = {
  icon: React.FC;
  heading: string;
  subheading: string;
  trackEvent?: string;
  trackProps?: any;
};

export const candidatesZDSButtons: CandidatesZDSButton[] = [
  {
    icon: ZoomGlassSVG,
    heading: 'Recruit people to join',
    subheading: 'Create a landing page and signup form to share online to get panel signups.'
  },
  {
    icon: AddSVG,
    heading: 'Add someone manually',
    subheading: 'You can add people 1 by 1 if you have their name and email address.',
    trackEvent: 'clicked_add_candidate',
    trackProps: { source: 'candidates_zds' }
  }
];

type IncentivesZDSButton = { icon: React.FC; heading: string; subheading: string; link: string };

export const incentivesZDSButtons: IncentivesZDSButton[] = [
  {
    icon: WalletSVG,
    heading: 'Deposit funds',
    subheading: 'Add funds to your account that can be used for future study incentives.',
    link: '/incentives/deposit'
  },
  {
    icon: InvoiceSVG,
    heading: 'Create an invoice',
    subheading: 'Generate an invoice to be paid by your internal team. ',
    link: '/incentives/invoice'
  }
];

type RepositoryZDSButton = { icon: React.FC; heading: string; subheading: string; href: string };

export const repositoryZDSButtons: RepositoryZDSButton[] = [
  {
    icon: LightBulbSVG,
    heading: 'Create an insight',
    subheading: 'Write a document that highlights learnings on a research topic.',
    href: '/insights'
  },
  {
    icon: ChecklistSVG,
    heading: 'Start a new research project',
    subheading: 'Generate new research and artifacts will be stored here.',
    href: '/studies'
  }
];

type StudiesZDSButton = {
  className?: string;
  icon: React.FC;
  heading: string;
  subheading: string;
  style: Study['style'];
};

export const studiesZDSButtons: StudiesZDSButton[] = [
  {
    className: 'xx-new-survey',
    icon: SurveysSVG,
    heading: 'Survey',
    subheading: 'Send a survey to collect information, get roadmap feedback, and more.',
    style: 'survey' as const
  },
  {
    className: 'xx-new-unmoderated-test',
    icon: UnmoderatedSVG,
    heading: 'Unmoderated prototype test',
    subheading:
      'Send customers a link to a prototype and have them record their experience using our Loom integration.',
    style: 'unmoderated_test' as const
  },
  {
    className: 'xx-new-online-task',
    icon: OnlineTaskSVG,
    heading: 'Online task',
    subheading: 'If you use another platform for unmoderated tests, use this study to send the link to your panel.',
    style: 'online_task' as const
  }
];
