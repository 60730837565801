import React, { SVGProps } from 'react';

export const TeamSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M8.31181 11.8125H7.72848C7.64968 11.811 7.5747 11.7782 7.52002 11.7215C7.46533 11.6647 7.43541 11.5886 7.43681 11.5098V2.05217C7.43612 2.01317 7.44311 1.97443 7.4574 1.93814C7.47168 1.90184 7.49297 1.86872 7.52005 1.84066C7.54714 1.8126 7.57949 1.79015 7.61525 1.7746C7.65101 1.75904 7.68949 1.75068 7.72848 1.75H8.31181'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.12451 7H7.43701'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.062 1.75C10.062 1.92236 10.096 2.09303 10.1619 2.25227C10.2279 2.41151 10.3246 2.5562 10.4464 2.67808C10.5683 2.79995 10.713 2.89663 10.8722 2.96259C11.0315 3.02855 11.2022 3.0625 11.3745 3.0625C11.5469 3.0625 11.7175 3.02855 11.8768 2.96259C12.036 2.89663 12.1807 2.79995 12.3026 2.67808C12.4245 2.5562 12.5211 2.41151 12.5871 2.25227C12.6531 2.09303 12.687 1.92236 12.687 1.75C12.687 1.57764 12.6531 1.40697 12.5871 1.24773C12.5211 1.08849 12.4245 0.943799 12.3026 0.821922C12.1807 0.700045 12.036 0.603367 11.8768 0.537408C11.7175 0.471449 11.5469 0.4375 11.3745 0.4375C11.2022 0.4375 11.0315 0.471449 10.8722 0.537408C10.713 0.603367 10.5683 0.700045 10.4464 0.821922C10.3246 0.943799 10.2279 1.08849 10.1619 1.24773C10.096 1.40697 10.062 1.57764 10.062 1.75Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5619 5.25C13.4319 4.76307 13.1495 4.33052 12.756 4.01564C12.3625 3.70075 11.8786 3.52006 11.375 3.5C10.8713 3.51994 10.3872 3.70057 9.99362 4.01547C9.60001 4.33036 9.31752 4.76298 9.1875 5.25H13.5619Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.062 10.0625C10.062 10.2349 10.096 10.4055 10.1619 10.5648C10.2279 10.724 10.3246 10.8687 10.4464 10.9906C10.5683 11.1125 10.713 11.2091 10.8722 11.2751C11.0315 11.3411 11.2022 11.375 11.3745 11.375C11.5469 11.375 11.7175 11.3411 11.8768 11.2751C12.036 11.2091 12.1807 11.1125 12.3026 10.9906C12.4245 10.8687 12.5211 10.724 12.5871 10.5648C12.6531 10.4055 12.687 10.2349 12.687 10.0625C12.687 9.89014 12.6531 9.71947 12.5871 9.56023C12.5211 9.40099 12.4245 9.2563 12.3026 9.13442C12.1807 9.01255 12.036 8.91587 11.8768 8.84991C11.7175 8.78395 11.5469 8.75 11.3745 8.75C11.2022 8.75 11.0315 8.78395 10.8722 8.84991C10.713 8.91587 10.5683 9.01255 10.4464 9.13442C10.3246 9.2563 10.2279 9.40099 10.1619 9.56023C10.096 9.71947 10.062 9.89014 10.062 10.0625Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.562 13.5625C13.432 13.0755 13.1495 12.6429 12.7559 12.328C12.3623 12.0131 11.8782 11.8324 11.3745 11.8125C10.8708 11.8324 10.3868 12.0131 9.99314 12.328C9.59952 12.6429 9.31703 13.0755 9.18701 13.5625H13.562Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.31201 5.25C1.31201 5.71413 1.49639 6.15925 1.82457 6.48744C2.15276 6.81563 2.59788 7 3.06201 7C3.52614 7 3.97126 6.81563 4.29945 6.48744C4.62764 6.15925 4.81201 5.71413 4.81201 5.25C4.81201 4.78587 4.62764 4.34075 4.29945 4.01256C3.97126 3.68437 3.52614 3.5 3.06201 3.5C2.59788 3.5 2.15276 3.68437 1.82457 4.01256C1.49639 4.34075 1.31201 4.78587 1.31201 5.25Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.437012 10.5C0.437012 9.80381 0.713573 9.13613 1.20586 8.64384C1.69814 8.15156 2.36582 7.875 3.06201 7.875C3.7582 7.875 4.42588 8.15156 4.91817 8.64384C5.41045 9.13613 5.68701 9.80381 5.68701 10.5H0.437012Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
