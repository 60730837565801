import React, { SVGProps } from 'react';

export const InterviewTemplateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} fill='none' height='72' viewBox='0 0 80 72' width='80' xmlns='http://www.w3.org/2000/svg'>
      <clipPath id='a'>
        <path d='m0 0h80v71.2386h-80z' />
      </clipPath>
      <g clipPath='url(#a)'>
        <path
          d='m57.5627 63.9506h-39.2438c-1.3308-.0015-2.6067-.5309-3.5477-1.4719-.9411-.941-1.4704-2.2169-1.4719-3.5477v-46.0887c.0015-1.3309.5308-2.6067 1.4719-3.54775.941-.94103 2.2169-1.47036 3.5477-1.47186h36.51l7.7534 6.18001v44.9283c-.0015 1.3308-.5308 2.6067-1.4719 3.5477-.941.941-2.2169 1.4704-3.5477 1.4719z'
          fill='#e6e6e6'
        />
        <path
          d='m18.3187 9.26771c-.9477.00107-1.8563.37802-2.5264 1.04819-.6701.6701-1.0471 1.5787-1.0482 2.5264v46.0887c.0011.9477.3781 1.8563 1.0482 2.5264s1.5787 1.0471 2.5264 1.0481h39.2438c.9477-.001 1.8563-.378 2.5264-1.0481.6702-.6701 1.0471-1.5787 1.0482-2.5264v-44.2322l-6.8139-5.43109z'
          fill='#fff'
        />
        <path
          d='m49.3747 22.6691h-26.3157c-.1099.0001-.2187-.0214-.3202-.0634-.1015-.0419-.1938-.1035-.2715-.1811s-.1393-.1698-.1814-.2713-.0637-.2103-.0637-.3201c0-.1099.0216-.2186.0637-.3201s.1037-.1937.1814-.2713.17-.1392.2715-.1811c.1015-.042.2103-.0635.3202-.0634h26.3157c.2217 0 .4343.0881.5911.2448.1567.1568.2448.3694.2448.5911s-.0881.4343-.2448.5911c-.1568.1567-.3694.2448-.5911.2448z'
          fill='#ccc'
        />
        <path
          d='m52.8225 25.4902h-29.7637c-.1098 0-.2185-.0216-.3199-.0636s-.1936-.1036-.2712-.1812c-.0776-.0777-.1392-.1698-.1812-.2712s-.0636-.2101-.0636-.3199.0216-.2185.0636-.3199.1036-.1935.1812-.2712c.0776-.0776.1698-.1392.2712-.1812s.2101-.0636.3199-.0636h29.7637c.1098 0 .2185.0216.3199.0636s.1936.1036.2712.1812c.0776.0777.1392.1698.1812.2712s.0636.2101.0636.3199-.0216.2185-.0636.3199-.1036.1935-.1812.2712c-.0776.0776-.1698.1392-.2712.1812s-.2101.0636-.3199.0636z'
          fill='#ccc'
        />
        <path
          d='m49.3747 35.312h-26.3157c-.1099.0001-.2187-.0214-.3202-.0634s-.1938-.1035-.2715-.1811c-.0777-.0777-.1393-.1699-.1814-.2713-.0421-.1015-.0637-.2103-.0637-.3201 0-.1099.0216-.2186.0637-.3201s.1037-.1937.1814-.2713.17-.1392.2715-.1811c.1015-.042.2103-.0635.3202-.0634h26.3157c.2217 0 .4343.0881.5911.2448.1567.1568.2448.3694.2448.5911s-.0881.4343-.2448.591c-.1568.1568-.3694.2449-.5911.2449z'
          fill='#5850ec'
        />
        <path
          d='m52.8225 38.1331h-29.7637c-.1098 0-.2185-.0216-.3199-.0636-.1014-.0421-.1936-.1036-.2712-.1812-.0776-.0777-.1392-.1698-.1812-.2712s-.0636-.2101-.0636-.3199.0216-.2185.0636-.3199.1036-.1936.1812-.2712.1698-.1392.2712-.1812.2101-.0636.3199-.0636h29.7637c.1098 0 .2185.0216.3199.0636s.1936.1036.2712.1812.1392.1698.1812.2712.0636.2101.0636.3199-.0216.2185-.0636.3199-.1036.1935-.1812.2712c-.0776.0776-.1698.1391-.2712.1812-.1014.042-.2101.0636-.3199.0636z'
          fill='#ccc'
        />
        <path
          d='m49.3745 47.9548h-26.3157c-.2217 0-.4343-.0881-.5911-.2448-.1567-.1568-.2448-.3694-.2448-.5911s.0881-.4343.2448-.591c.1568-.1568.3694-.2449.5911-.2449h26.3157c.2217 0 .4343.0881.5911.2449.1567.1567.2448.3693.2448.591s-.0881.4343-.2448.5911c-.1568.1567-.3694.2448-.5911.2448z'
          fill='#ccc'
        />
        <path
          d='m52.8227 50.7759h-29.7637c-.1099.0002-.2187-.0214-.3202-.0633-.1015-.042-.1938-.1035-.2715-.1812-.0777-.0776-.1393-.1698-.1814-.2713-.0421-.1014-.0637-.2102-.0637-.3201 0-.1098.0216-.2186.0637-.32.0421-.1015.1037-.1937.1814-.2713.0777-.0777.17-.1392.2715-.1812.1015-.0419.2103-.0635.3202-.0633h29.7637c.2217 0 .4343.088.5911.2448.1568.1567.2448.3694.2448.591 0 .2217-.088.4343-.2448.5911s-.3694.2448-.5911.2448z'
          fill='#ccc'
        />
        <path
          d='m62.4313 14.2016h-6.0735c-.2195 0-.4369-.0433-.6398-.1273-.2028-.084-.3871-.2072-.5423-.3624-.1553-.1552-.2784-.3395-.3624-.5424-.084-.2028-.1273-.4202-.1273-.6397v-4.48584c0-.01964.0056-.03888.016-.05552s.0253-.03.043-.03855.0374-.01194.057-.00979c.0195.00215.038.00976.0534.02196l7.6408 6.05314c.017.0134.0294.0319.0354.0527.0061.0208.0055.043-.0016.0635-.0072.0205-.0205.0383-.0382.0508-.0177.0126-.0388.0194-.0605.0194z'
          fill='#e6e6e6'
        />
        <path
          d='m24.3025 11.7862c0 2.2692-.6729 4.4874-1.9336 6.3742s-3.0526 3.3573-5.149 4.2257c-2.0965.8684-4.4034 1.0956-6.629.6529-2.22559-.4427-4.26993-1.5354-5.87449-3.14-1.60457-1.6046-2.69729-3.6489-3.13999-5.8745s-.21549-4.53249.65289-6.62895c.86839-2.09647 2.33895-3.88835 4.22572-5.14905 1.88677-1.260697 4.10497-1.933593 6.37417-1.933593 3.0426.00096 5.9604 1.210053 8.1118 3.361503 2.1515 2.15145 3.3605 5.06916 3.3615 8.11179z'
          fill='#fff'
        />
        <path
          d='m18.1319 13.7494c-.1253 7.3378-11.04012 7.3366-11.16437-.0002.12531-7.33759 11.04007-7.33644 11.16437.0002z'
          fill='#5850ec'
        />
        <g fill='#2f2e41'>
          <path d='m12.3281 16.0269-.0058-.2086c1.4377.192 1.7474-1.5899.2974-.9237l.331-2.4189.2067.0284-.2816 2.0575c1.5267-.2273.962 1.6738-.5477 1.4653z' />
          <path d='m15.3029 12.6815h-1.1235v.2086h1.1235z' />
          <path d='m11.7568 12.6815h-1.1235v.2086h1.1235z' />
          <path d='m14.3592 21.5541c.2191-1.478-1.5113-1.1859-2.4271-1.2005-.1476.0004-.2933.0338-.4264.0977-.1332.0639-.2504.1567-.3431.2716s-.1586.2491-.193.3927c-.0343.1436-.0361.293-.0054.4375-.7088.2191-1.36516.5811-1.92856 1.0638 2.37426.8279 4.95406.8565 7.34606.0814-.5831-.5236-1.2733-.9141-2.0225-1.1442z' />
          <path d='m9.92539 11.1091c1.03451-.5978 2.28911-.1392 3.35771.1116 2.0274.6233 4.8706-.1572 5.1171-2.57234.2566-2.24614-2.816-3.498-3.7981-1.26244l.2859-.03737c-3.185-4.73347-8.72538-4.55395-8.68894 1.95373-.01181.20104.30113.20036.31284-.00012-.11726-3.83488 2.42072-6.45629 6.0084-3.99183.821.59905 1.4854 1.39247 2.0975 2.19615.3737.41872.4549-1.38455 2.2431-.91223 1.9681.76245 1.3259 3.58055-.4332 4.19915-2.1735 1.1105-4.5274-1.0425-6.66026.0454-.03433.0217-.0589.0559-.06852.0953-.00963.0395-.00356.0811.01692.1162.02049.035.05379.0608.09288.0717.03909.011.08092.0064.11667-.0129z' />
        </g>
        <path
          d='m12.8294 23.5724c-2.96292-.0025-5.81598-1.1218-7.99021-3.1346s-3.50984-4.7712-3.74045-7.7251c-.230621-2.95387.6607-5.88614 2.4962-8.21199s4.48028-3.874341 7.40686-4.336644c2.9266-.462302 5.9199.19556 8.3829 1.842384 2.4631 1.64682 4.2149 4.16157 4.906 7.04272.6911 2.88113.2708 5.91693-1.1772 8.50193-1.4479 2.585-3.817 4.5291-6.6349 5.4448-1.1784.3833-2.41.5778-3.6492.5765zm0-22.946592c-2.2078-.000313-4.36608.654102-6.20189 1.880472-1.83582 1.22637-3.26672 2.96961-4.11174 5.00926-.84501 2.03964-1.06619 4.28406-.63556 6.44946.43064 2.1653 1.49375 4.1543 3.05487 5.7155 1.56112 1.5611 3.55013 2.6242 5.71552 3.0548 2.1653.4307 4.4097.2095 6.4494-.6355 2.0396-.8451 3.7829-2.276 5.0092-4.1118 1.2264-1.8358 1.8808-3.9941 1.8805-6.2018-.0033-2.95891-1.1802-5.79565-3.2725-7.8879-2.0922-2.09225-4.929-3.269146-7.8878-3.272492z'
          fill='#ccc'
        />
        <path
          d='m79.687 35.3585c0 2.422-.7662 4.7819-2.1889 6.7419-1.4227 1.9601-3.429 3.4198-5.7318 4.1702-.0688.022-.1377.0439-.2076.0647-.2983.0918-.6007.17-.9085.2368-.5568.1208-1.122.1993-1.6907.2347-.2471.0167-.4957.025-.7457.025-1.2917.0013-2.5743-.216-3.7935-.6425-.0355-.0125-.0709-.025-.1053-.0376-1.9754-.7134-3.7171-1.9553-5.0353-3.5905-1.3182-1.6351-2.1623-3.6006-2.4404-5.6824s.0206-4.2.8634-6.1237c.8428-1.9238 2.1975-3.5793 3.9164-4.7862s3.7361-1.9189 5.8317-2.0583c2.0957-.1395 4.1894.299 6.0531 1.2675 1.8636.9685 3.4257 2.4299 4.516 4.2251 1.0903 1.7951 1.6669 3.855 1.6671 5.9553z'
          fill='#fff'
        />
        <path
          d='m67.9389 42.9601c3.0829 0 5.5822-2.4993 5.5822-5.5822 0-3.083-2.4993-5.5822-5.5822-5.5822-3.083 0-5.5822 2.4992-5.5822 5.5822 0 3.0829 2.4992 5.5822 5.5822 5.5822z'
          fill='#5850ec'
        />
        <g fill='#2f2e41'>
          <path d='m68.2646 40.3855.0058-.2086c-.3881-.0108-.7302-.0351-.9873-.223-.0729-.0562-.1328-.1276-.1757-.2091-.0428-.0815-.0676-.1712-.0727-.2632-.0045-.0562.004-.1126.0249-.165.0208-.0524.0535-.0992.0954-.1369.1706-.1441.4452-.0975.6454-.0057l.1726.0791-.331-2.4188-.2067.0283.2816 2.0576c-.272-.08-.524-.0456-.6966.1002-.0663.0579-.1183.1303-.1519.2115-.0337.0813-.0481.1693-.0421.257.0061.1236.0391.2443.0967.3537.0577.1094.1386.2049.2371.2798.3303.2414.7596.2534 1.1045.2631z' />
          <path d='m66.4135 37.0401h-1.1235v.2086h1.1235z' />
          <path d='m69.9597 37.0401h-1.1235v.2086h1.1235z' />
          <path d='m69.5248 45.1817c.0148-.0685.0225-.1384.023-.2086-.0004-.2626-.1049-.5144-.2907-.7002-.1857-.1857-.4375-.2902-.7002-.2906h-1.4602c-.2627.0002-.5146.1047-.7004.2904-.1858.1858-.2903.4377-.2905.7004 0 .0706.0076.1409.0229.2097-.5512.1737-1.0723.4316-1.5446.7645-.0919.0636-.1826.1325-.2692.2045.0345.0125.0699.025.1054.0375 1.2192.4266 2.5018.6438 3.7935.6425.2503 0 .4989-.0083.7457-.025.5687-.0354 1.1339-.1139 1.6908-.2347.3077-.0668.6101-.145.9084-.2368-.5901-.522-1.2829-.915-2.0339-1.1536z' />
          <path d='m69.3371 46.6743c-.1354.01-.2657.0557-.3776.1325.5687-.0354 1.1339-.1139 1.6907-.2347z' />
          <path d='m73.0018 36.0518-9.6974-3.5476c-.0515-.0189-.0987-.0476-.139-.0847-.0404-.0371-.073-.0818-.0961-.1315-.0231-.0496-.0361-.1034-.0384-.1581-.0023-.0548.0062-.1094.025-.1609l.0328-.1551c.7574-2.0702 3.2676-3.3731 5.3378-2.6157l2.9841 1.0916c2.0702.7574 2.97 3.2088 2.2126 5.279l-.0863.2345c-.0188.0514-.0476.0987-.0847.139s-.0817.073-.1314.0961c-.0497.023-.1035.0361-.1582.0384s-.1094-.0062-.1608-.025z' />
          <path d='m73.5239 36.2487c-13.5162.4987-14.6973-1.1996-10.579-3.8701l.8737-1.6076 10.013 3.6505z' />
          <path d='m70.8737 30.0353c.0746-.2483-.245-.5638-.7139-.7047-.4689-.141-.9095-.054-.9842.1943-.0746.2482.2451.5637.714.7047.4689.1409.9095.054.9841-.1943z' />
        </g>
        <path
          d='m68.214 47.1447c-1.327.0015-2.6446-.2217-3.897-.6601l-.1089-.0388c-2.6234-.948-4.8255-2.7959-6.2147-5.2148-1.3893-2.4189-1.8755-5.2522-1.3723-7.9959s1.9633-5.22 4.1205-6.9885 4.8717-2.7146 7.6608-2.67 5.472 1.077 7.5716 2.9136c2.0995 1.8366 3.4796 4.3583 3.8948 7.1167s-.1613 5.5747-1.6272 7.9479c-1.4659 2.3733-3.726 4.1498-6.3784 5.0135-.0741.0235-.1439.0457-.2148.0669-.2962.0911-.6105.173-.9316.2427-.5724.124-1.1533.2047-1.7379.2412-.2511.017-.5091.0256-.7649.0256zm0-22.9465c-2.6263.0003-5.1683.9267-7.1789 2.6162-2.0107 1.6894-3.3613 4.0337-3.8142 6.6206-.453 2.5868.0207 5.2505 1.3377 7.5227 1.317 2.2721 3.393 4.007 5.8628 4.8997l.1033.0369c1.1857.4149 2.4331.6261 3.6893.6246.2418 0 .4856-.0082.7246-.0243.5535-.0346 1.1037-.1109 1.6457-.2284.3037-.0658.6007-.1432.8826-.2299.0698-.0209.1361-.042.2024-.0631 2.5262-.8228 4.6761-2.5198 6.0632-4.7858 1.387-2.2661 1.9203-4.9526 1.5039-7.5766s-1.7551-5.0135-3.7755-6.7388c-2.0205-1.7254-4.5901-2.6735-7.2469-2.6738z'
          fill='#ccc'
        />
        <path
          d='m.312754 59.4524c-.002 1.5101.29504 3.0057.873976 4.4004.57894 1.3947 1.42833 2.661 2.49915 3.7258.08974.0886.18151.1773.2743.2639.55917.5224 1.16927.9875 1.82117 1.3882.12513.0772.25031.1513.3786.2233.65927.3715 1.35386.6765 2.07352.9105.12412.0407.25032.0793.37652.1147 2.28771.6561 4.72241.5876 6.96951-.196.0438-.0157.0876-.0313.1304-.047 1.9726-.7176 3.7106-1.9627 5.0247-3.5996s2.154-3.6029 2.4283-5.6841c.2743-2.0811-.0276-4.1976-.8728-6.119-.8452-1.9215-2.2013-3.5743-3.9206-4.7785-1.7194-1.2042-3.7362-1.9137-5.8308-2.0512s-4.18684.3022-6.04889 1.2713-3.42258 2.4304-4.51172 4.2248c-1.089143 1.7945-1.665155 3.8534-1.665336 5.9525z'
          fill='#fff'
        />
        <path
          d='m9.36323 65.076c.00555.8888-.32207 1.7475-.91828 2.4068-.58994.6475-1.46818 1.0768-2.35445.8992-.81607-.1636-1.47923-.8769-1.3965-1.7397.01372-.1869.06805-.3686.15917-.5323.09113-.1638.21688-.3057.36847-.4159.1516-.1101.32539-.1859.50928-.2221.18389-.0361.37344-.0316.55544.013.77524.2012 1.08414 1.3185.43813 1.8429-.15502.1259-.37772-.0942-.22126-.2212.42705-.3467.29512-1.1052-.22034-1.2954-.60012-.2214-1.23596.2549-1.27911.8684-.05117.7274.50609 1.2753 1.18948 1.4042.73929.1396 1.47227-.2388 1.97321-.7611.57411-.6064.8909-1.4118.88385-2.2469.00141-.0405.01851-.0789.0477-.1071.02918-.0282.06818-.044.10876-.044s.07957.0158.10876.044.04629.0666.04769.1071z'
          fill='#2f2e41'
        />
        <path
          d='m12.4186 67.0651c3.083 0 5.5822-2.4992 5.5822-5.5822 0-3.0829-2.4992-5.5821-5.5822-5.5821-3.08295 0-5.58217 2.4992-5.58217 5.5821 0 3.083 2.49922 5.5822 5.58217 5.5822z'
          fill='#5850ec'
        />
        <path
          d='m12.133 63.8648-.0058-.2086c.3881-.0108.7302-.0352.9873-.223.0729-.0563.1328-.1276.1757-.2091.0428-.0815.0676-.1712.0727-.2632.0045-.0562-.004-.1126-.0248-.165-.0209-.0524-.0535-.0992-.0955-.1369-.1706-.1441-.4452-.0975-.6454-.0057l-.1726.0791.331-2.4188.2067.0283-.2816 2.0576c.272-.08.524-.0456.6966.1002.0663.0579.1183.1302.1519.2115.0337.0813.0481.1693.0421.257-.006.1236-.0391.2443-.0967.3537-.0577.1094-.1386.2049-.237.2798-.3304.2414-.7597.2534-1.1046.2631z'
          fill='#2f2e41'
        />
        <path d='m15.1079 60.5194h-1.1235v.2086h1.1235z' fill='#2f2e41' />
        <path d='m11.5617 60.5194h-1.1235v.2086h1.1235z' fill='#2f2e41' />
        <path
          d='m8.61011 70.4793c2.28769.6561 4.72239.5876 6.96949-.1961.0438-.0156.0876-.0313.1304-.0469-.5295-.4085-1.1284-.718-1.7679-.9137.0452-.1604.0445-.3303-.0021-.4903-.0633-.2028-.1904-.3797-.3623-.5045-.172-.1248-.3796-.1907-.5921-.188h-1.3601c-.2121-.0029-.4194.0629-.591.1875-.1717.1246-.2984.3014-.3613.504-.0466.1603-.0473.3305-.002.4913-.5633.1725-1.09567.4333-1.57711.7729-.09702.0678-.19088.1387-.28158.2127-.06887.0553-.13665.1127-.2024.1711z'
          fill='#2f2e41'
        />
        <path
          d='m16.7917 60.8907c0 .9409.4632 1.721 1.065 1.8566.0613.0138.1239.0208.1867.0209.6894 0 1.2516-.8417 1.2516-1.8775 0-.6007-.1888-1.1358-.4819-1.479-.1017-.1231-.2269-.2249-.3682-.2993-.124-.0647-.2617-.0987-.4015-.0991-.1921.0024-.3786.0651-.533.1794-.4245.3004-.7187.9481-.7187 1.698z'
          fill='#3f3d56'
        />
        <path
          d='m5.63135 60.8907c0 .8094.34209 1.4999.82189 1.7638.13134.0736.27919.1127.42974.1137.03347 0 .06691-.0021.10013-.0063.64246-.0761 1.1515-.8855 1.1515-1.8712 0-.7895-.32651-1.4664-.78752-1.7428-.13943-.0866-.29999-.1332-.46411-.1346-.13987.0004-.27759.0344-.40158.0991-.14168.0748-.26717.1769-.36923.3004-.29208.3431-.48082.8782-.48082 1.4779z'
          fill='#3f3d56'
        />
        <path
          d='m6.53126 62.4745c.02484 0 .04931-.0059.0714-.0173.02209-.0113.04117-.0278.05565-.0479.01449-.0202.02397-.0435.02766-.0681.00368-.0246.00148-.0496-.00644-.0732-.29902-.8892-.38897-1.8354-.26286-2.765s.46487-1.8176.98997-2.595 1.22239-1.4233 2.03767-1.8874c.81529-.4641 1.72659-.7339 2.66319-.7886.9365-.0546 1.873.1074 2.7367.4736.8638.3661 1.6314.9265 2.2434 1.6376.6119.711 1.0516 1.5536 1.285 2.4623.2334.9086.2541 1.8588.0605 2.7768-.0043.0201-.0045.0408-.0007.061s.0115.0395.0227.0567.0257.032.0427.0436c.0169.0116.036.0198.0561.024.0201.0043.0408.0045.061.0007.0202-.0037.0395-.0115.0567-.0227s.032-.0257.0437-.0426c.0116-.017.0197-.0361.024-.0562.2036-.9651.182-1.9641-.0633-2.9195s-.7076-1.8413-1.3509-2.589c-.6434-.7476-1.4505-1.3369-2.3586-1.7218-.9081-.385-1.8928-.5554-2.8775-.4979-.9847.0574-1.9428.3412-2.79999.8292-.85717.4881-1.59025 1.1672-2.14226 1.9846-.55202.8174-.90809 1.7511-1.04055 2.7286-.13246.9774-.03773 1.9722.27682 2.907.01047.0311.0304.058.05699.0771s.05851.0294.09125.0294z'
          fill='#3f3d56'
        />
        <path
          d='m15.2229 57.8662c.2211.3017.1121.7083-.1208.9685-.303.3385-.7863.3598-1.2059.297-.4709-.0705-.9703-.2911-1.447-.1424-.4063.1267-.6961.4952-1.1182.5878-.3744.0821-.822-.0266-.9533-.4256-.063-.1917.239-.2739.3018-.0832.0849.2581.4515.253.6614.1815.3366-.1146.596-.3933.9268-.5265.39-.1569.8131-.0861 1.2102.0034.3743.0842.805.2092 1.1779.0518.2759-.1165.4954-.4835.2969-.7544-.1191-.1626.1523-.3189.2702-.1579z'
          fill='#2f2e41'
        />
        <path
          d='m11.7862 71.2386c-1.1037.0009-2.2021-.1535-3.26279-.4587-.12464-.035-.2556-.0749-.38715-.1179-.73906-.2406-1.45243-.5539-2.12965-.9353-.12701-.0713-.25452-.1464-.38934-.2295-.66948-.4119-1.29613-.8896-1.87059-1.4261-.09477-.0884-.18869-.1791-.28051-.2698-1.80659-1.8012-2.979927-4.14-3.343744-6.6651-.363816-2.525.101535-5.0999 1.326144-7.3379 1.22461-2.238 3.14235-4.0182 5.46513-5.0731 2.32277-1.055 4.9251-1.3277 7.4162-.7773 2.491.5504 4.7362 1.8943 6.3982 3.8297s2.6512 4.3579 2.8188 6.9035-.4953 5.0769-1.8891 7.2136c-1.3939 2.1366-3.4435 3.7633-5.8408 4.6356-.043.0158-.0876.0317-.1321.0476-1.253.4387-2.5712.6621-3.8987.6607zm0-22.9465c-2.20885.0001-4.36799.6556-6.20406 1.8835-1.83606 1.2279-3.26648 2.9731-4.11014 5.0145-.843667 2.0413-1.06263 4.2871-.629173 6.453.433463 2.1659 1.499843 4.1545 3.064143 5.714.08658.0855.17621.1721.26684.2567.54407.508 1.13755.9605 1.77161 1.3505.12773.0787.24793.1496.36777.2168.6414.3613 1.31708.658 2.0171.8859.12386.0405.24624.0778.36448.111 2.22583.6388 4.59493.5723 6.78143-.1901l.1264-.0455c2.4658-.8976 4.5365-2.6351 5.8485-4.9076 1.312-2.2724 1.7815-4.9345 1.326-7.5187-.4556-2.5841-1.807-4.9252-3.8169-6.6121-2.01-1.6868-4.55-2.6116-7.174-2.6119z'
          fill='#ccc'
        />
      </g>
    </svg>
  );
};
