import React from 'react';

import { Choose } from '@components/shared/TableFilters/components/dropdowns/Choose';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterDefinition } from '@components/shared/TableFilters/types';
import { text } from '@components/shared/TableFilters/utils/funcs';

import { SearchTeam } from '../components/SearchTeam';

const USE_CASES = [
  'Evaluation',
  'Concept',
  'Testing',
  'Discovery',
  'Satisfaction',
  'Brand/Messaging',
  'Pricing',
  'Feedback'
];
export const buildSurveyTemplateDefinitions = (): FilterDefinition<SurveyTemplate>[] => [
  {
    defaultOperator: 'is',
    func: ({ creator }, { operator, value }) => {
      if (!value) return true;
      return text(creator.name, operator, value);
    },
    id: 'created_by',
    name: 'Created by',
    type: 'free_text',
    Icon: svgs.text,
    Component: SearchTeam
  },
  {
    defaultOperator: 'includes_any',
    func: ({ use_case }, { operator, value }) => {
      if (!value) return true;
      switch (operator) {
        case 'includes_any':
          return value.includes(use_case);
        case 'includes_none':
          return !value.includes(use_case);
      }
    },
    id: 'use_case',
    name: 'Use case',
    type: 'multiple_choice', // technically single choice.
    Icon: svgs.multipleChoice,
    Component: (props) => <Choose {...props} options={USE_CASES.map((c) => ({ label: c, value: c }))} sort='alpha' />
  }
];
