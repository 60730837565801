export type TimestampStatus = Participation['status'] | 'interview' | 'updated';
export const dateStatuses = (style: Study['style'], hasScreener: boolean, onGQ: boolean): TimestampStatus[] => {
  let statuses: TimestampStatus[] = ['updated', 'shortlisted', 'requested', 'completed', 'rejected'];
  if (hasScreener) {
    statuses = statuses.concat(['invited', 'applied']);
  }
  if (style === 'video_call') {
    statuses = statuses.concat(['interview', 'booked', 'canceled']);
  } else {
    statuses = statuses.concat(['started']);
  }
  return statuses;
};
