import cn from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Card, Input, InputWithAddons, Text } from '@components/common';
import { usePlan } from '@hooks/usePlan';
import { useUser } from '@hooks/useUser';

import { StepHelper, StepTitle } from '../../../shared';
import { useDebouncedCallback } from 'use-debounce';
import { toPositiveNumber } from '@components/utils';

const FormGroup: React.FC = ({ children }) => <div className='mb-8'>{children}</div>;
const Label: React.FC = ({ children }) => <label className='mb-2 text-base font-bold text-gray-700'>{children}</label>;
const Helper: React.FC = ({ children }) => <p className='h400 mb-3 -mt-1 text-gray-500'>{children}</p>;
const Select: React.FC<{
  sizeClass?: string;
  options: { label: string; value: string; disabled?: boolean }[];
  value: string;
  onChange: (value: string) => void;
}> = ({ options, onChange, value, sizeClass = 'w-40' }) => {
  return (
    <select
      onChange={(e) => onChange(e.currentTarget.value)}
      value={value}
      className={`${
        value ? '' : 'text-gray-400'
      } ${sizeClass} mt-1 block h-10 pl-3 static pr-10 py-2 text-base border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 h400 rounded-md`}
    >
      {options.map(({ value, label, disabled }, i) => (
        <option key={`option${value}${i}`} value={value} disabled={disabled}>
          {label}
        </option>
      ))}
    </select>
  );
};

function getEventTypeOptions(event_types: CalendlyEventType[]): { value: string; label: string }[] {
  return event_types.map(({ name, duration, uuid }) => ({
    value: uuid,
    label: `${name}  (${duration} mins)`
  }));
}

const CalendlySelectInput: React.FC<{
  calendlyEventTypes: CalendlyEventType[];
  calendlyEventTypeUUID: string;
  isOwner: boolean;
  setMode: (mode: CalendarMode) => void;
  user: User;
  onChange: (study: Partial<Study>) => void;
}> = ({ calendlyEventTypes, calendlyEventTypeUUID, isOwner, setMode, user, onChange }) => {
  const options = [{ label: 'Please select…', value: '' }].concat(getEventTypeOptions(calendlyEventTypes));
  return (
    <>
      <Label>{isOwner || `${user.first_name}'s `}Calendly Event</Label>
      <Select
        value={calendlyEventTypeUUID}
        onChange={(v) => onChange({ calendly_event_type_uuid: v, nylas_calendar_id: null })}
        sizeClass='w-60'
        options={options}
      />
      <div className=' mt-1 text-xs'>
        <a className='cursor-pointer' onClick={() => setMode('manual')}>
          Enter a Calendly URL
        </a>
      </div>
    </>
  );
};

const ManualCalendlyInput: React.FC<{
  isOwner: boolean;
  user: User;
  bookingUrl: string;
  hasCalendly: boolean;
  onChange: (study: Partial<Study>) => void;
  setMode: (mode: CalendarMode) => void;
}> = ({ isOwner, user, bookingUrl: initialUrl, hasCalendly, onChange, setMode }) => {
  const [bookingUrl, setBookingUrl] = useState<string>(initialUrl);

  const { callback: debouncedChange } = useDebouncedCallback(onChange, process.env.NODE_ENV === 'test' ? 5 : 300);

  return (
    <>
      <Label>{isOwner || `${user.first_name}'s `}Booking Calendar</Label>
      <Helper>Please enter a Calendly booking page URL.</Helper>

      <Input
        value={bookingUrl}
        onChange={(v) => {
          setBookingUrl(v);
          debouncedChange({ booking_url: v });
        }}
        placeholder='e.g. calendly.com/'
        className='w-96'
      />

      <div className=' mt-1 text-xs'>
        {hasCalendly && (
          <>
            Connect to{' '}
            <a className='cursor-pointer' onClick={() => setMode('calendly')}>
              Calendly Account
            </a>
          </>
        )}
      </div>
    </>
  );
};

const CalendlySelector: React.FC<{
  bookingUrl: string;
  calendlyEventTypes: CalendlyEventType[];
  calendlyEventTypeUUID: string;
  hasCalendly: boolean;
  isOwner: boolean;
  mode: CalendarMode;
  onChange: (study: Partial<Study>) => void;
  setMode: (mode: CalendarMode) => void;
  user: User;
}> = ({
  bookingUrl,
  calendlyEventTypes,
  calendlyEventTypeUUID,
  hasCalendly,
  isOwner,
  mode,
  onChange,
  setMode,
  user
}) => {
  return (
    <FormGroup>
      {mode === 'calendly' ? (
        <CalendlySelectInput
          calendlyEventTypes={calendlyEventTypes}
          calendlyEventTypeUUID={calendlyEventTypeUUID}
          isOwner={isOwner}
          onChange={onChange}
          setMode={setMode}
          user={user}
        />
      ) : (
        <ManualCalendlyInput
          bookingUrl={bookingUrl}
          hasCalendly={hasCalendly}
          isOwner={isOwner}
          onChange={onChange}
          setMode={setMode}
          user={user}
        />
      )}
    </FormGroup>
  );
};

// Out of the picture, no need for displaying read only data
const ReadOnlyCalendarInfo: React.FC<{ study: Study }> = ({ study }) => {
  return (
    <FormGroup>
      <Label>Calendar</Label>
      <p className='text-gray-700'>
        {study.owner?.name}: {study.calendar?.name} ({study.calendar?.source})
      </p>
    </FormGroup>
  );
};

type CalendarMode = 'calendly' | 'manual' | 'select';

function getMode(study: Study, user: User, isOwner: boolean): CalendarMode {
  if (study.calendly_event_type_uuid) {
    return 'calendly';
  } else if (study.booking_url) {
    return 'manual';
  }
  if (user.calendly) {
    return 'calendly';
  } else if (isOwner) {
    return 'select';
  } else {
    return 'manual';
  }
}
interface CalendarFieldProps {
  study: Study;
  user: User;
  isOwner: boolean;
  changeBulk: (changes: Partial<Study>) => void;
}
const CalendarField: React.FC<CalendarFieldProps> = ({ study, user, isOwner, changeBulk }) => {
  const defaultMode = getMode(study, user, isOwner);
  const [mode, setMode] = useState<CalendarMode>(defaultMode);

  const { calendly_event_type_uuid, booking_url } = study;
  const { event_types: calendly_event_types, calendly: hasCalendly } = user;

  useEffect(() => {
    setMode(getMode(study, user, isOwner));
  }, [user]);

  useEffect(() => {
    if (mode === 'calendly') {
      changeBulk({
        nylas_calendar_id: null,
        // booking_url: null,
        video_url: null
      });
    }

    if (mode === 'manual') {
      changeBulk({
        calendly_event_type_uuid: null,
        nylas_calendar_id: null,
        video_url: null,
        booking_url: study.booking_url || user.default_booking_url
      });
    }
  }, [mode]);

  return (
    <CalendlySelector
      bookingUrl={booking_url || ''}
      calendlyEventTypes={calendly_event_types}
      calendlyEventTypeUUID={calendly_event_type_uuid || ''}
      hasCalendly={hasCalendly}
      isOwner={isOwner}
      mode={mode}
      onChange={changeBulk}
      setMode={setMode}
      user={user}
    />
  );
};

type Props = PageProps & {
  onBack: () => void;
  studyShowTitle?: boolean;
};

export const CalendlyCalendar: React.FC<Props> = ({ study, onSave, onBack, studyShowTitle }) => {
  const user = useUser();
  const plan = usePlan();

  const [duration, setDuration] = useState<string>(
    study.duration_in_minutes ? study.duration_in_minutes.toString() : ''
  );

  const owner = study.owner;
  const isOwner = owner?.id === user.id;
  const isCreator = study.creator_id === user.id;

  const changeBulk = (attrs: Partial<Study>) => onSave({ id: study.id, ...attrs });

  const { callback: debouncedSave } = useDebouncedCallback(onSave, process.env.NODE_ENV === 'test' ? 5 : 300);

  const canEditCal = (isCreator || isOwner) && study.state === 'draft';

  const wrapperClass = cn({ 'py-gutter px-page': studyShowTitle });

  const cardWrapperClass = cn({ 'px-gutter max-w-3xl mx-auto': !studyShowTitle });

  return (
    <div className={wrapperClass}>
      {!studyShowTitle && (
        <div className='text-center'>
          <StepTitle>Set up your calendar</StepTitle>
          <StepHelper>This determines when participants can schedule time with you.</StepHelper>
        </div>
      )}
      {studyShowTitle && <h3 className='mb-4'>Calendar</h3>}

      <div className={cardWrapperClass}>
        <Card>
          <div>
            {canEditCal && owner ? (
              <CalendarField study={study} user={owner} changeBulk={changeBulk} isOwner={isOwner} />
            ) : (
              <ReadOnlyCalendarInfo study={study} />
            )}

            <FormGroup>
              {!study.calendly_event_type_uuid && (
                <>
                  <Label>Interview duration</Label>
                  <Helper>This is the amount of time you'll be speaking to each participant.</Helper>
                  <div className='flex items-baseline space-x-2'>
                    <div className='xx-input-addon-group max-w-xs'>
                      <InputWithAddons
                        type='number'
                        name='duration'
                        className='no_arrows'
                        value={duration}
                        onChange={(v: number) => {
                          setDuration(v.toString());
                          debouncedSave({
                            id: study.id,
                            duration_in_minutes: toPositiveNumber(v)
                          });

                        }}
                        prefix=''
                        placeholder='Enter number…'
                        suffix='minutes'
                      />
                    </div>
                  </div>
                </>
              )}
            </FormGroup>
          </div>
        </Card>

        {study.state === 'draft' && (
          <div className='pt-4'>
            <button className='h400' onClick={onBack}>
              Switch to Google calendar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
