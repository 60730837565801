import { Provider as CollectionView } from 'components/stores/view';
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { GuidesEdit } from './GuidesEdit';
import { GuidesIndex } from './GuidesIndex';
import { GuidesShow } from './GuidesShow';

export const GuidesApp: React.FC = () => {
  return (
    <Routes>
      <Route path=':token/edit' element={<GuidesEdit />} />
      <Route path=':token' element={<GuidesShow />} />
      <Route
        path=''
        element={
          <CollectionView scope='guides'>
            <GuidesIndex />
          </CollectionView>
        }
      />
    </Routes>
  );
};
