export const canMapToAttribute = (field_type: ScreenerField['field_type'], attr_type: Attr_['attr_type']): boolean => {
  if (field_type === 'multiple_choice') {
    return attr_type === 'multiple_choice';
  } else if (field_type === 'date') {
    return attr_type === 'datetime';
  } else if (field_type === 'yes_no') {
    return attr_type === 'boolean';
  } else {
    return attr_type !== 'multiple_choice';
  }
};
