import React from 'react';
import { ActivitiesZDSSVG } from '@components/svgs';

export const ActivitiesZDS: React.FC = () => (
  <div className='p-10 bg-white border border-gray-200 rounded-md'>
    <div className='flex flex-col items-center flex-grow'>
      <ActivitiesZDSSVG />
      <h3 className='h600 mt-4 mb-2 font-bold'>No activity yet</h3>
      <p className='h500 text-gray-500'>We’ll show the activity of you, your teammates and participants.</p>
    </div>
  </div>
);
