import { toggleMark } from 'prosemirror-commands';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { Decoration, DecorationAttrs, DecorationSet } from 'prosemirror-view';

import { getBackgroundColor } from '@components/tags/colors';
import { Editor, getMarkType, NodeWithPos } from '@tiptap/core';
import { MarkRange } from '@tiptap/react';

import { HighlightAttributes } from '../extensions/Highlight';
import * as Commands from '../helpers/commands';
import * as Queries from '../helpers/queries';

const DECORATION_ATTRS: DecorationAttrs = {
  nodeName: 'gq-highlight-widget',
  class: 'py-1 duration-150 transition-colors ease-in-out'
};

const WIDGET_SPEC = {
  ignoreSelection: true
};

const ANCHOR_CLASSNAMES = ['ProseMirror-widget', 'gq-highlight-widget-anchor', 'cursor-ew-resize', 'relative'];

export interface PluginParams {
  editor: Editor;
}

export class HighlightWidgetState {
  editor: Editor;
  decos: Decoration[];
  activeMarkRange: MarkRange | void;
  isMouseDown: boolean;
  anchor1: HTMLSpanElement;
  anchor2: HTMLSpanElement;
  activeAnchor: HTMLSpanElement | null;
  handlers: { name: string; handler: (event: Event) => void }[];

  constructor(editor: Editor) {
    this.editor = editor;
    this.decos = [];
    this.activeMarkRange = undefined;

    this.anchor1 = this.createAnchorElement('anchor1');
    this.anchor2 = this.createAnchorElement('anchor2');

    this.handlers = ['mousemove', 'mouseup'].map((name) => {
      const handler = (e: Event) => {
        this[name](e);
      };
      this.editor.view.dom.addEventListener(name, handler);
      return { name, handler };
    });

    [this.anchor1, this.anchor2].forEach((anchor) => {
      anchor.addEventListener('mousedown', this.mousedown);
    });

    this.editor.on('destroy', () => this.destroy());
  }

  isHex(color: string) {
    return color.startsWith('#');
  }

  destroy() {
    this.handlers.forEach(({ name, handler }) => this.editor.view.dom.removeEventListener(name, handler));
  }

  createAnchorElement(id?: string) {
    const element = document.createElement('span');
    if (id) element.id = id;
    element.classList.add(...ANCHOR_CLASSNAMES);

    return element;
  }

  updateAnchorsClassnames(attributes: HighlightAttributes) {
    this.anchor1.className = ANCHOR_CLASSNAMES.join(' ');
    this.anchor2.className = ANCHOR_CLASSNAMES.join(' ');

    if (!this.isHex(attributes.color)) {
      this.anchor1.classList.add(`color-${attributes.color}`);
      this.anchor2.classList.add(`color-${attributes.color}`);
    }
  }

  init() {
    return DecorationSet.empty;
  }

  getDecorations = (marks: NodeWithPos[], attributes: HighlightAttributes) => {
    const [firstMark] = marks;
    const lastMark = [...marks].pop() as NodeWithPos;

    const from = firstMark.pos;
    const to = lastMark.pos + lastMark.node.nodeSize;

    return [
      Decoration.widget(from, this.anchor1, { ...WIDGET_SPEC, side: 1 }),
      Decoration.widget(to, this.anchor2, { ...WIDGET_SPEC, side: -1 }),

      Decoration.inline(from, to, {
        ...DECORATION_ATTRS,
        ...(!this.isHex(attributes.color) && {
          style: `background-color: ${getBackgroundColor(attributes.color, 0.15)}`
        })
      })
    ];
  };

  apply = (_: Transaction, __: any, ___: EditorState, state: EditorState) => {
    const range = Queries.getActiveHighlightRange(state) ?? this.activeMarkRange;

    if (range) {
      const attributes = range.mark.attrs as HighlightAttributes;
      const marks = Queries.findMarksByHighlightId(state, attributes.highlightId);

      if (marks.length) {
        this.updateAnchorsClassnames(attributes);
        this.decos = this.getDecorations(marks, attributes);
      }
    } else {
      this.decos = [];
    }

    return DecorationSet.create(state.doc, this.decos);
  };

  mousemove(event: MouseEvent) {
    if (this.activeMarkRange && this.isMouseDown) {
      const cursorPos = this.editor.view.posAtCoords({ left: event.clientX, top: event.clientY });
      let { from, to } = this.activeMarkRange;

      if (cursorPos) {
        if (this.activeAnchor === this.anchor1) {
          if (cursorPos.pos >= this.activeMarkRange.to) {
            from = to;
            to = cursorPos.pos;
          } else {
            from = cursorPos.pos;
          }
        }

        if (this.activeAnchor === this.anchor2) {
          if (cursorPos.pos <= this.activeMarkRange.from) {
            from = cursorPos.pos;
            to = from;
          } else {
            to = cursorPos.pos;
          }
        }

        // good enough for now
        if (from === to) return;

        const updatedMark = { mark: this.activeMarkRange.mark, from, to };
        Commands.replaceMark(this.editor, [this.activeMarkRange, updatedMark]);

        this.activeMarkRange = updatedMark;
      }
    }
  }

  mousedown = (event: MouseEvent) => {
    this.isMouseDown = true;
    this.activeMarkRange = Queries.getActiveHighlightRange(this.editor.state);
    this.editor.commands.blur();
    this.activeAnchor = event.target as HTMLSpanElement;
  };

  mouseup = (e: MouseEvent) => {
    this.isMouseDown = false;
    this.activeMarkRange = undefined;
    this.activeAnchor = null;

    if (e.target === this.anchor1 || e.target === this.anchor2) {
      this.editor.commands.focus();
    }
  };
}

export const HighlightWidget = ({ editor }: PluginParams) =>
  new Plugin({
    key: new PluginKey('highlightWidget'),
    state: new HighlightWidgetState(editor),
    props: {
      decorations(state) {
        return this.getState(state);
      }
    }
  });
