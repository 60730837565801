import React, { SVGProps } from 'react';

export const StatsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1093_4192)'>
      <path
        d='M12.6875 0.4375H1.3125C0.829251 0.4375 0.4375 0.829251 0.4375 1.3125V12.6875C0.4375 13.1707 0.829251 13.5625 1.3125 13.5625H12.6875C13.1707 13.5625 13.5625 13.1707 13.5625 12.6875V1.3125C13.5625 0.829251 13.1707 0.4375 12.6875 0.4375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.1875 7.43826H3.9375L5.25583 4.14243C5.28902 4.06026 5.34641 3.99013 5.42038 3.94134C5.49435 3.89255 5.58141 3.8674 5.67 3.86923C5.7586 3.87107 5.84454 3.8998 5.91642 3.95161C5.98831 4.00343 6.04274 4.07588 6.0725 4.15934L7.99283 9.59893C8.02073 9.67791 8.07071 9.74723 8.13684 9.79864C8.20297 9.85005 8.28247 9.8814 8.3659 9.88895C8.44932 9.89651 8.53316 9.87996 8.60745 9.84126C8.68174 9.80257 8.74337 9.74336 8.785 9.67068L10.0625 7.43826H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1093_4192'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
