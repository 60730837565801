import React, { SVGProps } from 'react';
import cn from 'classnames';
import { Text } from '@components/common';
import { Icon } from '@components/shared/Artifact/components/utils';
import { TextProps } from '@components/common/Text';
import {
  ChecklistSVG,
  CheckmarkInCircleSVG,
  ClockSVG,
  EyeSVG,
  FilmSVG,
  HighlightReelSVG,
  HighlightSVG,
  LightBulbSVG,
  PersonSVG,
  SingleUserSVG,
  TagSVG,
  UploadTimeSVG,
  InterviewSVG
} from '@components/svgs';

const ICONS: Record<Icon, React.FC<SVGProps<SVGSVGElement>>> = {
  reel: HighlightReelSVG,
  highlight: HighlightSVG,
  clock: ClockSVG,
  eye: EyeSVG,
  checkmark: CheckmarkInCircleSVG,
  lightBulb: LightBulbSVG,
  checklist: ChecklistSVG,
  tag: TagSVG,
  user: SingleUserSVG,
  interview: InterviewSVG,
  film: FilmSVG,
  person: PersonSVG,
  changedAt: UploadTimeSVG
};

interface Props {
  icon: Icon;
  text: string;
  className?: string;
  pill?: boolean;
  h?: TextProps['h'];
}

export const InfoLabel: React.FC<Props> = ({ h = '400', icon, className, text }) => {
  const Svg: React.FC<SVGProps<SVGSVGElement>> = ICONS[icon];
  
  return (
    <div className={cn('max-w-full overflow-hidden py-px inline-flex items-center space-x-1', className)}>
      <Svg className='flex-shrink-0 w-3.5 h-3.5' />
      <Text truncate h={h}>
        {text}
      </Text>
    </div>
  );
};
