import React, { SVGProps } from 'react';

export const CandidateRefreshSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M0.849609 9.87004C0.850025 9.26001 1.00161 8.65959 1.2908 8.12246C1.58 7.58534 1.99779 7.12826 2.50682 6.79207C3.01586 6.45589 3.60027 6.2511 4.20781 6.196C4.81534 6.1409 5.42706 6.23721 5.98828 6.47633'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.28516 3.1051C2.28516 3.40123 2.34348 3.69446 2.45681 3.96805C2.57013 4.24164 2.73623 4.49023 2.94563 4.69962C3.15503 4.90902 3.40362 5.07512 3.67721 5.18845C3.95079 5.30177 4.24403 5.3601 4.54016 5.3601C4.83629 5.3601 5.12952 5.30177 5.40311 5.18845C5.6767 5.07512 5.92529 4.90902 6.13468 4.69962C6.34408 4.49023 6.51018 4.24164 6.6235 3.96805C6.73683 3.69446 6.79516 3.40123 6.79516 3.1051C6.79516 2.50703 6.55758 1.93347 6.13468 1.51057C5.71179 1.08768 5.13822 0.850098 4.54016 0.850098C3.94209 0.850098 3.36852 1.08768 2.94563 1.51057C2.52274 1.93347 2.28516 2.50703 2.28516 3.1051Z'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.63984 10.6899H6.58984V12.7399'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8312 11.0906C12.614 11.7252 12.1935 12.2703 11.635 12.6415C11.0764 13.0127 10.4109 13.1891 9.74179 13.1434C9.07268 13.0978 8.43735 12.8326 7.93437 12.3889C7.43138 11.9453 7.08887 11.3481 6.95996 10.6899'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.0996 9.05H13.1496V7'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.9082 8.64921C7.12534 8.01467 7.54582 7.46953 8.10441 7.09836C8.663 6.7272 9.32847 6.55076 9.99758 6.59643C10.6667 6.64209 11.302 6.90731 11.805 7.35093C12.308 7.79454 12.6505 8.39176 12.7794 9.04992'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
