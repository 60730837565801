import { createContext, useContext } from 'react';
import * as React from 'react';
import { CalendarSettings } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/GQCalendar';
import { PanelType } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/PanelButton';

interface Context {
  studyUserColors: Record<number, UserColor | undefined>;
  calSettings?: CalendarSettings | null;
  setCalSettings?: (v: CalendarSettings | null) => void;
  activePanel?: PanelType | null;
  setActivePanel?: (v: PanelType | null) => void;
  dailyLimit: number;
  weeklyLimit: number;
  durationInMinutes: number;
  participantsLimit: number;
  minimumSchedulingNotice: number;
  maximumSchedulingNotice: number;
  callBuffer: number;
  startDate: Date;
  endDate: Date | null;
  studyId: number;
}

export const GQCalendarContext = createContext<Context>({} as Context);

export const useGQCalendarContext = () => {
  const context = useContext<Context>(GQCalendarContext);

  if (context === undefined) throw new Error();

  return context;
};

export const GQCalendarProvider: React.FC<{
  value: Context;
}> = ({ children, value }) => {
  return <GQCalendarContext.Provider value={value}>{children}</GQCalendarContext.Provider>;
};
