import { Operator } from '../types';

export const text = (value: string | null, op: Operator, v: string) => {
  // in some cases like external attributes, value can be an array, so we should check it
  const preparedValue = Array.isArray(value) ? value.join(', ') : value || '';
  const haystack = preparedValue.trim().toLowerCase();
  const needle = (v || '').trim().toLowerCase();

  switch (op) {
    case 'is':
      return haystack === needle;
    case 'is_not':
      return haystack !== needle;
    case 'contains':
      return haystack.includes(needle);
    case 'includes_none':
      return !haystack.includes(needle);
    case 'is_present':
      return !!haystack;
    case 'is_blank':
      return !haystack;
    default:
      return false;
  }
};

export const singleChoice = (value: string, op: Operator, v: string | string[] | null) => {
  const haystack = (value || '').toString().trim().toLowerCase();
  const needles = (Array.isArray(v) ? v : [v]).map((s) => (s || '').toString().trim().toLowerCase());
  switch (op) {
    case 'is':
    case 'includes_any':
      return needles.length === 0 || needles.some((needle) => haystack === needle);
    case 'contains':
      return haystack.includes(needles.join(' '));
    case 'includes_none':
      return needles.every((needle) => haystack !== needle);
    case 'is_present':
      return !!haystack;
    case 'is_blank':
      return !haystack;
    default:
      return false;
  }
};

export const number = (
  value: number | undefined,
  range: { min?: number; max?: number } | undefined,
  op: Operator,
  v: number
) => {
  switch (op) {
    case 'is':
      return value === v;
    case 'is_not':
      return value !== v;
    case 'is_greater':
      if (!value) return false;
      return value > v;
    case 'is_lesser':
      if (!value) return false;
      return value < v;
    case 'is_between':
      if (!value || !range) return false;
      if (range.min != null && value < range.min) {
        return false;
      }
      if (range.max != null && value > range.max) {
        return false;
      }
      return true;
    case 'is_present':
      return !!value || value === 0;
    case 'is_blank':
      return !value && value !== 0;
    default:
      return false;
  }
};

export const date = (value: Date, range: { min?: number; max?: number }, op: Operator, v: Date) => {
  return number(Number(new Date(value)?.setHours(0, 0, 0, 0)), range, op, Number(v?.setHours(0, 0, 0, 0)));
};

export const boolean = (value: boolean | null | undefined, op: Operator, v: boolean | null | undefined) => {
  switch (op) {
    case 'is':
      return v === null || v === undefined ? value === null || value === undefined : value === v;
    case 'is_not':
      return v === null || v === undefined ? value !== null && value !== undefined : value !== v;
    case 'is_present':
      return value !== null && value !== undefined;
    case 'is_blank':
      return value === null || value === undefined;
    default:
      return false;
  }
};

export const multipleChoice = (value: string | Array<string | null>, op: Operator, v: string[] | null) => {
  const haystack = (Array.isArray(value) ? value : [value]).map((s) => (s || '').toString().trim().toLowerCase());
  const needles = (Array.isArray(v) ? v : v ? [v] : []).map((s) => (s || '').toString().trim().toLowerCase());

  switch (op) {
    case 'includes_any':
      return needles.length === 0 || needles.some((needle) => haystack.includes(needle));
    case 'includes_all':
      return needles.every((needle) => haystack.includes(needle));
    case 'includes_none':
      return needles.every((needle) => !haystack.includes(needle));
    case 'is_present':
      return haystack.every((v) => !!v);
    case 'is_blank':
      return haystack.every((v) => !v);
    case 'contains':
      return needles.every((needle) => haystack.some((hay) => hay.includes(needle)));
    default:
      return false;
  }
};

export const teamIds = (
  candidate: {
    contact_access: 'public' | 'needs_team';
    team_ids: number[];
  },
  op: Operator,
  v: ('none' | 'all' | number)[]
) => {
  const { team_ids, contact_access } = candidate;

  if (contact_access === 'public') return v.includes('all');
  if (contact_access === 'needs_team' && !team_ids?.length) return v.includes('none');

  switch (op) {
    case 'includes_any':
      return v.some((item) => team_ids.includes(item as number));
    case 'includes_all':
      return v.every((item) => team_ids.includes(item as number));
    case 'includes_none':
      return !v.some((item) => team_ids.includes(item as number));
    default:
      return false;
  }
};
