import React from 'react';

import { PlusSVG } from '@components/svgs';
import Tippy from '@tippyjs/react';

interface Props {
  toggleColumnDropdown: () => void;
}

export const AddColumnButton: React.FC<Props> = ({ toggleColumnDropdown }) => {
  return (
    <Tippy content='Add column'>
      <button
        name='add column'
        data-testid='add column'
        aria-label='Add column'
        type='button'
        className='hover:bg-gray-100 flex items-center justify-center w-10 h-10 text-gray-500 rounded-full'
        onClick={toggleColumnDropdown}
      >
        <PlusSVG className='hover:text-indigo-700 text-indigo-600' />
      </button>
    </Tippy>
  );
};
