import { VictoryBarProps } from 'victory';

import { api } from '@api/reduxApi';

import { MetricData, MetricParams } from './types';

const reportingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMetric: builder.query<MetricData, MetricParams>({
      query: ({ id, starts_at, ends_at, type, period }) => ({
        url: `/reporting/stats/${id}`,
        params: { starts_at, ends_at, type, period }
      }),
      transformResponse: (response: { data: any }) => response.data
    })
  })
});

export const { useGetMetricQuery } = reportingApi;
