import { Avatar, InfoLabel, Text } from '@components/common';
import { format as timeagoFormat } from 'timeago.js';
import { formatDuration } from '@components/shared/Artifact/components/utils';
import React from 'react';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { getHighlightDisplayTitle } from '@components/utils';
import { AskAiSVG } from '@components/svgs';

interface Props {
  clip: Pick<Clip, 'created_at' | 'from' | 'to' | 'tag_ids' | 'title'>;
  renderActions: () => React.ReactElement;
  creator?: TeamUser | null;
  ai?: boolean | null;
  sessionTitle?: string;
  renderTranscript: () => React.ReactElement;
}

export const Highlight: React.FC<Props> = ({ renderTranscript, sessionTitle, clip, renderActions, creator, ai }) => {
  const { renderTagsList } = useRenderTagsList();

  return (
    <div className='p-4 border border-gray-200 rounded-md'>
      {renderActions()}
      <Text bold className='pr-4 mt-1'>
        {getHighlightDisplayTitle({ clip } as Highlight)}
      </Text>
      <div className='flex flex-wrap items-center mt-1'>
        <div className='flex-nowrap flex items-center py-px mt-1 mr-3 space-x-1'>
          {ai ? (
            <>
              <AskAiSVG className='w-4 h-4 mr-1' />
              <Text h='400' className='leading-5'>
                Great Question AI
              </Text>
            </>
          ) : (
            creator && (
              <>
                <Avatar user={creator} size='sm' />
                <Text color='gray-500' h='200'>
                  {creator?.name}
                </Text>
              </>
            )
          )}
        </div>
        {sessionTitle && <InfoLabel h='200' className='mt-1 mr-3 text-gray-500' icon='checklist' text={sessionTitle} />}
        {clip.created_at && (
          <InfoLabel
            h='200'
            className='mt-1 mr-3 text-gray-500'
            icon='changedAt'
            text={timeagoFormat(clip.created_at)}
          />
        )}
        <InfoLabel
          h='200'
          className='mt-1 mr-3 text-gray-500'
          icon='clock'
          text={formatDuration(Math.round(clip.to / 1000 - clip.from / 1000))}
        />
      </div>

      <section className='pl-2 mt-4 border-l-2 border-gray-200'>{renderTranscript()}</section>

      <div className='mt-3'>{renderTagsList(clip.tag_ids || [], { max: 6 })}</div>
    </div>
  );
};
