import { api } from '@api/reduxApi';
import { EligibilityRulesResp } from '@components/GovernanceApp/components/Eligiblity/types';

const eligibilityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEligibilityRules: builder.query<EligibilityRulesResp[], void>({
      query: () => `/eligibility_settings`,
      transformResponse: (resp: any) => resp.rules,
      providesTags: ['EligibilityRules']
    }),
    updateEligibilityRules: builder.mutation<EligibilityRulesResp[], EligibilityRulesResp[]>({
      query: (rules) => ({
        url: `/eligibility_settings`,
        method: 'PUT',
        body: { rules }
      }),
      transformResponse: (resp: any) => resp.rules,
      invalidatesTags: ['EligibilityRules']
    })
  })
});

export const { useGetEligibilityRulesQuery, useUpdateEligibilityRulesMutation } = eligibilityApi;
