import React, { SVGProps } from 'react';

export const NotificationSuccessSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.8125 13.4345L9.05833 16.6218C9.14352 16.749 9.25787 16.8541 9.39186 16.9282C9.52585 17.0023 9.67561 17.0434 9.82867 17.048C9.98174 17.0525 10.1337 17.0205 10.2719 16.9545C10.41 16.8885 10.5304 16.7904 10.6231 16.6685L17.8125 7.57242'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 12.3125C2 15.0475 3.08649 17.6706 5.02046 19.6045C6.95443 21.5385 9.57746 22.625 12.3125 22.625C15.0475 22.625 17.6706 21.5385 19.6045 19.6045C21.5385 17.6706 22.625 15.0475 22.625 12.3125C22.625 9.57746 21.5385 6.95443 19.6045 5.02046C17.6706 3.08649 15.0475 2 12.3125 2C9.57746 2 6.95443 3.08649 5.02046 5.02046C3.08649 6.95443 2 9.57746 2 12.3125V12.3125Z'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
