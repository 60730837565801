import cn from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { Option, Text } from '@components/common';
import { CaretDownSVG, CheckSVG } from '@components/svgs';
import { useOnClickOutside } from '@components/utils';

interface Props<T extends string = string> {
  label: string;
  value: T;
  options: Option<T>[];
  onChange: (value: T) => void;
  multiselect?: boolean;
  rectangularButton?: boolean;
  rightDropdown?: boolean;
}

export const PillDropdown = <T extends string = string>({
  label,
  value,
  options,
  onChange,
  multiselect,
  rectangularButton,
  rightDropdown
}: React.PropsWithChildren<Props<T>>): React.ReactElement => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    if (open) {
      setOpen(false);
    }
  });

  const selectedLabel = options?.find((item) => item.value === value || item.value === value?.[0]);

  const dropdownTitle = value && value.length > 1 ? `${label}: ${value.length}` : selectedLabel?.label || 'All';

  const buttonStyle = cn(
    'focus:outline-none flex items-center rounded-md px-4 py-1.5 space-x-2 bg-indigo-50 border-indigo-600 border ',
    { 'w-full tablet:w-52 justify-between h-full': rectangularButton }
  );

  const dropdownClass = cn('absolute z-50 top-12', { 'right-0': rightDropdown });

  return (
    <div className='relative'>
      <button className={buttonStyle} name={label} onClick={() => setOpen(true)}>
        <Text h='400' medium>
          {multiselect ? dropdownTitle : options.find((o) => o.value === value)?.label}
        </Text>
        <CaretDownSVG />
      </button>

      {open && (
        <div ref={ref} className={dropdownClass}>
          <div className='whitespace-nowrap p-4 bg-white rounded-md shadow-lg'>
            <Text h='300' uppercase className='mb-3'>
              {label}
            </Text>
            <div className='w-40 space-y-3'>
              {options.map((option) => (
                <button
                  key={option.value}
                  name={option.value}
                  className='hover:text-indigo-600 flex items-center w-full p-1 text-left'
                  onClick={() => {
                    onChange(option.value);
                    setOpen(false);
                  }}
                >
                  <Text color={option.value === value ? 'indigo-600' : undefined} className='flex-1 mr-1' h='400'>
                    {option.label}
                  </Text>

                  {Array.isArray(value)
                    ? value.includes(option.value) && <CheckSVG />
                    : option.value === value && <CheckSVG />}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
