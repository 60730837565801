import { buildKey, State } from './context';

export const getCalendarState = (state: State, key: UserAndCalendar): GetCalendarState => ({
  ...key,
  ...(state[buildKey(key)] || { events: null })
});

type GetCalendarState = UserAndCalendar & {
  events: NylasEvent[] | null;
  failed?: boolean;
};

export type GetCalendarStateSet = GetCalendarState[];

export const getCalendarStates = (state: State, calendars: UserAndCalendar[], weekIdx?: number): GetCalendarStateSet =>
  calendars.map(({ userId, calendarId, conflicts }) => ({
    userId,
    calendarId,
    ...(getCalendarState(state, { userId, calendarId, conflicts, weekIdx }) as any)
  }));
