import * as React from 'react';
import { useState } from 'react';

import { Button, Tabs, Text } from '@components/common';
import { TagManager } from '@components/TagManager/components/TagManager';
import { TagMerge } from '@components/TagManager/components/TagMerge';
import { MoveTagDropdown } from '@components/TagManager/components/MoveTagDropdown';
import { usePermission } from '@hooks/usePermission';
import { api } from '@api/reduxApi';
import { DeleteTagModal } from '@components/TagManager/components/GroupColumn/DeleteTagModal';

interface Props {
  study: Study;
}

export const TagsTab: React.FC<Props> = ({ study }) => {
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);
  const [tab, setTab] = useState<'study' | 'global'>('study');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const permission = tab === 'study' ? 'manageStudyTags' : 'manageTags';

  const canManage = usePermission(permission)();

  const { data: tagGroups } = api.useGetTagGroupsQuery();
  const { data: tags } = api.useGetTagsQuery(
    { studyId: tab === 'study' ? study.id : undefined },
    { refetchOnMountOrArgChange: true }
  );

  const [destroyTags, { isLoading: isDeletingTags }] = api.useBulkDestroyTagsMutation();

  const handleDelete = () => {
    const taggingCount = selectedTags.reduce((m, { taggings }) => m + taggings.length, 0);

    if (taggingCount > 0) {
      setShowDeleteModal(true);
    } else {
      destroyTags({ ids: selectedTags.map(({ id }) => id) });
    }
  };

  return (
    <div className='flex flex-col h-full'>
      <div className='px-page tablet:pt-6 monitor:pt-12 pt-4 border-b border-gray-200'>
        <header className='flex justify-between items-center'>
          <Text h='700' mb='2'>
            Tags
          </Text>
          <div className='flex items-center space-x-2 tablet:space-x-4'>
            {selectedTags.length > 0 && canManage && (
              <TagMerge
                medium
                studyId={tab === 'study' ? study.id : undefined}
                selectedTags={selectedTags}
                allTags={tags ?? []}
                onSuccess={() => setSelectedTags([])}
              />
            )}
            {selectedTags.length !== 0 && canManage && (
              <MoveTagDropdown
                medium
                studyId={tab === 'study' ? study.id : undefined}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                tagGroups={tagGroups}
              />
            )}
            {selectedTags.length !== 0 && canManage && (
              <Button medium name='close' onClick={handleDelete} icon='trash' loading={isDeletingTags}>
                Delete
              </Button>
            )}
          </div>
        </header>

        <Tabs<'study' | 'global'>
          tabs={['study', 'global']}
          labels={{ study: 'Study Tags', global: 'Global Tags' }}
          current={tab}
          onSelect={(tab) => {
            setTab(tab);
            setSelectedTags([]);
          }}
        />
      </div>
      <div className='flex-1 overflow-hidden min-h-192'>
        <TagManager
          canManage={canManage}
          key={tab}
          studyId={tab === 'study' ? study.id : undefined}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </div>
      {showDeleteModal && (
        <DeleteTagModal
          tags={selectedTags}
          onClose={() => setShowDeleteModal(false)}
          onSubmit={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};
