import React, { forwardRef, useEffect, useState } from 'react';

import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { parseIframeUrl } from './helpers';
import { FigmaPrototypeTypes } from './types';

import { Spinner } from '../Spinner';

export const FigmaPrototype = forwardRef<HTMLIFrameElement, FigmaPrototypeTypes.IframeProps>(
  (
    {
      hideUi,
      hideHotspots,
      nodeId,
      scaling,
      onLoad,
      onPrototypeMousePress,
      onPrototypePresentedNodeChanged,
      src,
      ...rest
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleOnLoad = () => {
      setIsLoading(false);
    };

    useEffect(() => {
      const handler = (event: MessageEvent<FigmaPrototypeTypes.MessageEvent>) => {
        if (event.origin === 'https://www.figma.com') {
          switch (event.data.type) {
            case 'MOUSE_PRESS_OR_RELEASE':
              onPrototypeMousePress?.(event.data.data);
              break;
            case 'PRESENTED_NODE_CHANGED':
              onPrototypePresentedNodeChanged?.(event.data.data);
              break;
            default:
              break;
          }
        }
      };

      window.addEventListener('message', handler);

      return () => {
        window.removeEventListener('message', handler);
      };
    }, []);

    if (!src) {
      return null;
    }

    return (
      <div className='relative w-full h-full'>
        {isLoading && (
          <div className='absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50'>
            <Spinner className='w-9 h-9' />
          </div>
        )}
        <iframe
          title='Figma prototype test'
          onLoad={composeEventHandlers(handleOnLoad, onLoad)}
          ref={ref}
          src={parseIframeUrl(src, { hideUi, hideHotspots, nodeId, scaling })}
          {...rest}
        />
      </div>
    );
  }
);
