import * as React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';

import { Button } from '@components/common';

import { CreatedBy } from '../../components/cells/CreatedBy';
import { IsDefault } from '../../components/cells/IsDefault';
import { LastUpdated } from '../../components/cells/LastUpdated';
import { DeleteTemplateButton } from '../../components/DeleteTemplateButton';
import { DEFAULT_TITLES } from '../../consts';

type Params = {
  canUpdate: (template: SurveyTemplate) => boolean;
  isGQAdmin?: boolean;
};
export const buildColumns = (params: Params): Column<SurveyTemplate>[] => [
  {
    id: 'name',
    Cell: ({ row }) => <Link to={`${row.original.id}`}>{row.original.title || DEFAULT_TITLES.SurveyTemplate}</Link>
  },
  {
    id: 'created by',
    Cell: CreatedBy
  },
  {
    id: 'last updated',
    Cell: LastUpdated
  },
  {
    id: 'default',
    Header: () => <></>,
    Cell: IsDefault
  },
  {
    id: 'extra',
    Header: () => <></>,
    Cell: ({ row }) => (
      <div className='whitespace-nowrap pr-6 text-right'>
        {params.canUpdate(row.original) && (
          <>
            {(!row.original.global || params.isGQAdmin) && (
              <Button text rounded small href={`${row.original.id}/edit`} spa icon='pencil' />
            )}
            <DeleteTemplateButton template={row.original} kind='SurveyTemplate' />
          </>
        )}
      </div>
    )
  }
];
