import { AI } from '@api/chat-gpt';
import { uid } from '@components/utils';

import { QuestionType, SuggestedQuestion } from './types';

export const toScreenerField = (question: SuggestedQuestion, position: number = 1): ScreenerField => {
  return {
    id: uid(),
    position,
    external_field_id: null,
    field_type: QuestionType[question.type.toLowerCase()],
    helper: '',
    label: question.question,
    options: question.options,
    required: false,
    meta: {},
    next_screener_field_id: null,
    number_range_options: question.range_options
  };
};

export const shouldCreate = (fields: ScreenerField[], suggestion: SuggestedQuestion) => {
  return !fields.find((field) => field.label === suggestion.question);
};
