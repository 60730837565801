import React, { SVGProps } from 'react';

export const VideoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1388_40483)'>
      <path
        d='M31 12V29C31 29.5304 30.7893 30.0391 30.4142 30.4142C30.0391 30.7893 29.5304 31 29 31H3C2.46957 31 1.96086 30.7893 1.58579 30.4142C1.21071 30.0391 1 29.5304 1 29V12'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M31 8H1V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H29C29.5304 1 30.0391 1.21071 30.4142 1.58579C30.7893 1.96086 31 2.46957 31 3V8Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M10 8L12 1' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 8L22 1' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M12.6507 24.88C12.4767 24.9667 12.2834 25.0076 12.0891 24.9987C11.8949 24.9898 11.7062 24.9314 11.5408 24.8291C11.3755 24.7268 11.239 24.584 11.1443 24.4141C11.0497 24.2443 11 24.0531 11 23.8586V16.1413C11 15.9468 11.0497 15.7556 11.1443 15.5858C11.239 15.4159 11.3755 15.2731 11.5408 15.1708C11.7062 15.0685 11.8949 15.0101 12.0891 15.0012C12.2834 14.9923 12.4767 15.0332 12.6507 15.12L20.3693 18.98C20.5589 19.0746 20.7183 19.2201 20.8298 19.4003C20.9412 19.5804 21.0002 19.7881 21.0002 20C21.0002 20.2118 20.9412 20.4195 20.8298 20.5996C20.7183 20.7798 20.5589 20.9253 20.3693 21.02L12.6507 24.88Z'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1388_40483'>
        <rect width='32' height='32' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
