import { Row } from 'react-table';

import { FilterState, GlobalFilter } from '../types';
import { isFilterApplyable } from '../utils/isFilterApplyable';

export const buildGlobalFilter =
  <D extends object>(filters: FilterState<any>[], op: FilterOp): GlobalFilter<D> =>
  (rows: Row<D>[], columnIds: string[]) => {
    const applyableFilters = filters.filter(isFilterApplyable);

    if (!rows) return [];

    if (applyableFilters.length < 1) {
      return rows;
    }

    return rows.filter((row) => {
      switch (op) {
        case 'any':
          return applyableFilters.some((f) => f.definition.func?.(row.original, f));
        case 'all':
          return applyableFilters.every((f) => f.definition.func?.(row.original, f));
        default:
          return false;
      }
    });
  };
