import { Toast } from 'components/stores/toaster';

export const failedAttrCreation = (errorMessage?: string): Toast => ({
  heading: 'Failed to create the new attribute',
  icon: 'error',
  text: errorMessage || 'Something went wrong creating the attribute. Please try again later.'
});

export const successAttrCreation = (): Toast => ({
  heading: 'Attribute created!',
  icon: 'success',
  text: 'The new attribute was created.'
});

export const failedAttrUpdate = (errorMessage?: string): Toast => ({
  heading: 'Failed to update the attribute',
  icon: 'error',
  text: errorMessage || 'Something went wrong updating the attribute. Please try again later.'
});

export const successAttrUpdate = (): Toast => ({
  heading: 'Attribute updated!',
  icon: 'success',
  text: 'The attribute was updated.'
});

export const failedAttrDelete = (errorMessage?: string): Toast => ({
  heading: 'Failed to delete the attribute',
  icon: 'error',
  text: errorMessage || 'Something went wrong deleting the attribute. Please try again later.'
});

export const successAttrDelete = (): Toast => ({
  heading: 'Attribute deleted!',
  icon: 'success',
  text: 'The attribute was deleted.'
});
