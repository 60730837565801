import React, { SVGProps } from 'react';

export const ChevronUpSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.4375 11.0269L7.69083 4.77419C7.73141 4.73355 7.77961 4.70131 7.83266 4.67931C7.8857 4.65732 7.94257 4.646 8 4.646C8.05743 4.646 8.11429 4.65732 8.16734 4.67931C8.22039 4.70131 8.26859 4.73355 8.30917 4.77419L14.5625 11.0269'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
