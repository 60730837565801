import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Editor } from './components/Editor';
import { Show } from './components/Show';

export const HighlightReelsApp: React.FC = () => (
  <Routes>
    <Route path='/new' element={<Editor />} />
    <Route path='/:token/edit' element={<Editor />} />
    <Route path='/:token' element={<Show />} />
  </Routes>
);
