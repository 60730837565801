import tinytime from 'tinytime';
import * as React from 'react';

import { ChevronRightSVG } from '@components/svgs';
import { Text } from '@components/common';

const dateFormat = tinytime('{dddd}, {MM} {DD}');
const timeFormat = tinytime('{h}:{mm}{a}');

export const timeRangeStr = (calendarEvent: CalendarEvent): string =>
  `${timeFormat.render(calendarEvent.start_at)} - ${timeFormat.render(calendarEvent.end_at)}`;

type Props = {
  calendarEvent: CalendarEvent;
  onClick?: () => void;
};

export const CalendarEventLink: React.FC<Props> = ({ calendarEvent, onClick }) => (
  <button
    name={calendarEvent.title}
    aria-label={calendarEvent.title}
    className='hover:text-indigo-600 w-full text-left text-gray-500'
    onClick={onClick}
  >
    <div className='flex items-center flex-1 w-full mb-1 space-x-2'>
      <Text className='flex-1' h='400' color={calendarEvent.discarded_at ? 'gray-400' : undefined}>
        {calendarEvent.discarded_at && 'Replaced: '}
        {calendarEvent.title}
      </Text>
      <ChevronRightSVG />
    </div>
    <Text h='400' color={calendarEvent.discarded_at ? 'gray-400' : 'gray-500'} className='flex items-center space-x-2'>
      <span>Candidate</span>
      <span>•</span>
      <span>{dateFormat.render(calendarEvent.start_at)}</span>
      <span>•</span>
      <span>{timeRangeStr(calendarEvent)}</span>
    </Text>
  </button>
);
