import React from 'react';
import { api } from 'api/reduxApi';
import { CreateCsvExportButton } from 'components/common';

interface Props {
  study?: Study;
  currentTab: string;
}
export const ExportCsvButton: React.FC<Props> = ({ study, currentTab }) => {
  const [createCsvExport, { isSuccess: csvExportSuccess, error: csvExportError, isLoading: csvExportLoading }] =
    api.useCreateStudyScreenerCsvExportMutation();

  const handleCsvExport = async () => {
    if (!study?.survey_screener?.id) return;

    await createCsvExport({ study_id: study.id, screener_id: study.survey_screener.id });
  };

  if (!study?.survey_screener || !['summary_responses', 'individual_responses'].includes(currentTab)) return null;

  return (
    <div className='flex items-center justify-end flex-1'>
      <CreateCsvExportButton
        isLoading={csvExportLoading}
        isSuccess={csvExportSuccess}
        isError={!!csvExportError}
        successMessage='Exported the responses to a CSV file. You will receive an email with the link when it is ready.'
        errorMessage='Something went wrong when exporting the responses as a CSV file. Please try again later.'
        onClick={handleCsvExport}
      />
    </div>
  );
};
