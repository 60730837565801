import { Checkbox } from '@components/common';
import React, { FC } from 'react';
import { Grid } from '@components/common/Grid';
import { Filter, FilterStateLookup } from '../FilterInput';

interface Props {
  object: ExternalCandidatesFilterListResponse;
  filters: FilterStateLookup;
  storeFilterValues: (v: Filter[]) => void;
  setChanged: (changed: boolean) => void;
  disabled?: boolean;
  limit?: number;
}

export const Checkboxes: FC<Props> = ({ limit, disabled, setChanged, storeFilterValues, object, filters }) => {
  const options = Object.keys(object.choices).map((c) => ({
    label: object.choices[c],
    value: c
  }));

  const onSelect = (value: string) => {
    let selected_values: string[];

    if (value === 'all') {
      selected_values = [];
    } else {
      selected_values = [...(filters[object.filter_id]?.selected_values || []), value];
    }

    storeFilterValues([
      {
        filter_id: object.filter_id,
        selected_values
      }
    ]);

    setChanged(true);
  };

  const onDeselect = (value: string) => {
    let selected_values: string[];

    if (value === 'all') {
      selected_values = options.map((o) => o.value);
    } else {
      selected_values = (filters[object.filter_id].selected_values || []).filter(
        (selected_value) => selected_value !== value
      );
    }

    storeFilterValues([
      {
        filter_id: object.filter_id,
        selected_values
      }
    ]);

    setChanged(true);
  };

  const selected = filters[object.filter_id]?.selected_values || [];

  return (
    <div className='mt-6'>
      {object.all_option && (
        <label
          className={`mb-2 space-x-3 flex flex-row ${disabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'}`}
        >
          <Checkbox
            value='all'
            name='All'
            disabled={disabled}
            selected={!selected.length}
            onChange={(v) => (v ? onSelect('all') : onDeselect('all'))}
          />
          <span className='h400 block'>{object.all_option}</span>
        </label>
      )}
      <Grid tablet={1} mobile={1} tiny={1} desktop={2} gap={2}>
        {options.map(({ label, value }) => {
          return (
            <label
              key={value}
              className={`space-x-3 flex flex-row ${disabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer'}`}
            >
              <Checkbox
                value={value}
                name={label}
                disabled={disabled || (selected.length === limit && selected.includes(value))}
                selected={selected.includes(value)}
                onChange={(v) => (v ? onSelect(value) : onDeselect(value))}
              />
              <span className='h400 block'>{label}</span>
            </label>
          );
        })}
      </Grid>
    </div>
  );
};
