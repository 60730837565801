import { useContext } from 'react';

import { DrawerContext } from '../context/drawerContext';

import { Drawer } from './useDrawer';

export const useDrawerContext = () => {
  const drawerContext = useContext<Drawer | undefined>(DrawerContext);

  if (drawerContext === undefined) throw new Error("Don't forget to wrap your component with <DrawerProvider>!");

  return drawerContext;
};
