import * as React from 'react';
import { CellProps } from 'react-table';

import { Pill } from '@components/common';

export const IsDefault: React.FC<CellProps<AbstractTemplate>> = ({ row }) =>
  row.original.default ? (
    <div className='text-right'>
      <Pill color='gray'>Default</Pill>
    </div>
  ) : (
    <></>
  );
