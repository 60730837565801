import * as React from 'react';
import { useRef } from 'react';

import { Button, Text } from '@components/common';

import { useRecorder } from './useRecorder';

interface LoomRecorderProps {
  onCancel?: () => void;
  onStart?: () => void;
  onComplete: (id) => void;
}

export const Recorder: React.FC<LoomRecorderProps> = ({ onStart, onCancel, onComplete, children }) => {
  const { status, ref } = useRecorder({ onComplete, onStart, onCancel });
  const buttonRef = useRef();

  // useEffect(() => {
  //   init();
  // }, []);

  switch (status) {
    case 'new':
    case 'canceled':
      return (
        <Button primary ref={ref}>
          {children || 'Start'}
        </Button>
      );
    case 'started':
      return (
        <Button disabled ref={ref}>
          In Progress…
        </Button>
      );
    case 'completed':
      return <Text h='400'>Recording complete!</Text>;
    default:
      return null;
  }
};
