import { RestrictedButton } from 'components/shared/RestrictedButton';
import * as React from 'react';
// hooks
import { useState } from 'react';

import { Button, Text } from '@components/common';
import { getConnectZoomButtonProps } from '@components/getConnectZoomButtonProps';

// components
import { ErrorSVG, SVG } from './SVGImages';
import { ZoomRecordingsModal } from './ZoomRecordingsModal';
import { useDeviceType } from '@hooks/useDeviceType';
import { useAccount } from '@hooks/useAccount';
import { UploadButton } from '@components/common/UploadButton';

const ZOOM_AUTH_PATH = `/users/auth/zoom`;

interface Props {
  isWaitingForZoom: boolean;
  isWaitingForDaily: boolean;
  error: boolean;
  owner: User;
  isOwner: boolean;
  isZoom: boolean;
  canUpload: boolean;
  onChooseFile: (file: File) => void;
  onChooseZoom: (recording: ZoomRecording) => void;
  participation?: Participation | null;
  hasAiSummary: boolean;
}

export const EmptyState: React.FC<Props> = ({
  isWaitingForZoom,
  isWaitingForDaily,
  error,
  canUpload,
  onChooseFile,
  onChooseZoom,
  owner,
  isOwner,
  isZoom,
  participation,
  hasAiSummary
}) => {
  const {
    account: { team }
  } = useAccount();

  const [modalOpen, setModalOpen] = useState<'upload' | 'zoom' | null>(null);

  const { isDesktopM, isDesktopL } = useDeviceType();
  const onDrop = ([file]) => onChooseFile(file);

  const closeModal = () => setModalOpen(null);

  const openZoomModal = () => setModalOpen('zoom');

  const onZoomSelect = (r: ZoomRecording) => {
    onChooseZoom(r);
    closeModal();
  };

  const moderator = team.find(({ name, id }) => participation?.moderator_id === id);

  return (
    <div>
      <ZoomRecordingsModal open={modalOpen === 'zoom'} onClose={closeModal} onSelect={(rs) => onZoomSelect(rs[0])} />

      <div className='bg-gray-50 p-6 text-center border border-gray-200 border-dashed rounded-lg'>
        {!error && (
          <>
            <SVG className='mx-auto mb-6' />
            <span className='block mb-2 font-bold text-gray-700'>
              {isWaitingForDaily && 'Waiting for participant recording…'}
              {isWaitingForZoom && 'Waiting for Zoom recording…'}
              {!isWaitingForDaily && !isWaitingForZoom && 'Recording'}
            </span>
            <span className='block mb-6 text-sm text-gray-500'>
              {isWaitingForDaily && (
                <span>
                  This typically takes up to a few minutes from when the test ends. Once processed, the recording will
                  be automatically transcribed for you.
                </span>
              )}
              {isZoom && owner.zoom && isOwner && isWaitingForZoom && (
                <span>
                  This typically takes around 10 minutes from when the call ends. If it’s not appearing, you can upload
                  the file directly or find in your Zoom account. Once uploaded, the recording will be automatically
                  transcribed for you.
                </span>
              )}
              {isZoom && owner.zoom && isOwner && !isWaitingForZoom && (
                <span>
                  Your Zoom is all set up to automatically upload recordings here, or you can upload a file directly.
                  Once uploaded, the recording will be automatically transcribed for you.
                </span>
              )}
              {isZoom && !owner.zoom && isOwner && (
                <span>
                  Connect Zoom to automatically upload recordings, or you can upload a file directly. Once uploaded, the
                  recording will be automatically transcribed for you.
                </span>
              )}
              {isZoom && owner.zoom && !isOwner && (
                <span>
                  The Zoom recording from {moderator?.name || owner.name} will be automatically uploaded, or you can
                  upload a file directly. Once uploaded, the recording will be automatically transcribed.
                </span>
              )}
              {isZoom && !owner.zoom && !isOwner && (
                <span>
                  {moderator?.name || owner.name} can connect their Zoom account to automatically upload recordings, or
                  you can upload a file directly. Once uploaded, the recording will be automatically transcribed.
                </span>
              )}
            </span>
          </>
        )}
        {error && (
          <>
            <ErrorSVG className='mx-auto mb-6' />
            <span className='block mb-2 font-bold text-gray-700'>Upload failed</span>
            <span className='block mb-6 text-sm text-gray-500'>
              <span>There was an issue uploading your file. Please try again.</span>
            </span>
          </>
        )}
        <div className='flex items-center self-center justify-center'>
          {!isWaitingForDaily && canUpload && (
            <RestrictedButton
              action='Upload video'
              limit='storage'
              buttonProps={{ link: true, className: 'hover:bg-gray-100 px-2.5 font-bold text-indigo-600' }}
            >
              <UploadButton
                className='hover:bg-gray-100 px-2.5 font-bold text-indigo-600'
                link
                supportedFileTypes={['mp4', 'm4v', 'mov', 'mp3']}
                onUploadFiles={onDrop}
                uploadZoneProps={{
                  fileTypesText: 'We support MP4, M4V, MOV, and MP3 files of any size.'
                }}
                hasFileUploadZone
              >
                Upload recording
              </UploadButton>
            </RestrictedButton>
          )}
          {isZoom && isOwner && !owner.zoom && (
            <>
              <div className='h-5 border-r mx-1.5 border-gray-200' />
              <Button
                className='hover:bg-gray-100 px-2.5 font-bold text-indigo-600'
                target='auth_popup'
                onClick={() => {
                  window.open('about:blank', 'auth_popup', 'width=1000,height=800');
                }}
                link
                {...getConnectZoomButtonProps()}
              >
                Connect Zoom
              </Button>
            </>
          )}
          {!isWaitingForDaily && isOwner && owner.zoom && (
            <>
              <div className='h-5 border-r mx-1.5 border-gray-200' />
              <Button className='hover:bg-gray-100 px-2.5 font-bold text-indigo-600' onClick={openZoomModal} link>
                Find Zoom Recording
              </Button>
            </>
          )}
        </div>
      </div>

      {(isDesktopM || isDesktopL) && (
        <section contentEditable={false} className='px-6 mt-6'>
          <Text bold className='mb-6'>
            Summary
          </Text>
          {hasAiSummary && (
            <Text color='gray-500'>
              Once the recording is uploaded and transcription is provided, we’ll generate an AI summary.
            </Text>
          )}
        </section>
      )}
    </div>
  );
};
