import * as React from 'react';

export const studies: React.FC<any> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.1991 8.67408L8.75 13.1249L6.5625 13.5624L7 11.3749L11.4514 6.92408C11.5659 6.80938 11.7019 6.71839 11.8516 6.6563C12.0013 6.59421 12.1617 6.56226 12.3238 6.56226C12.4858 6.56226 12.6463 6.59421 12.796 6.6563C12.9457 6.71839 13.0817 6.80938 13.1962 6.92408L13.2014 6.92875C13.4325 7.16053 13.5621 7.4746 13.5616 7.8019C13.5612 8.12919 13.4308 8.44292 13.1991 8.67408Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.81006 11.8125H1.31006C1.07799 11.8125 0.855434 11.7203 0.69134 11.5562C0.527246 11.3921 0.435059 11.1696 0.435059 10.9375V1.3125C0.435059 1.08044 0.527246 0.857876 0.69134 0.693782C0.855434 0.529687 1.07799 0.4375 1.31006 0.4375H10.0601C10.2921 0.4375 10.5147 0.529687 10.6788 0.693782C10.8429 0.857876 10.9351 1.08044 10.9351 1.3125V5.25'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.81006 3.0625H8.31006'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.06006 5.6875H8.31006'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.06006 8.3125H7.43506'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
