import { FilterState } from 'components/shared/TableFilters/types';

type FilterByType = (study: Study, filter: FilterState<Study, any, any>) => boolean;

export const filterByType: FilterByType = ({ focus_group, style }, { operator, value }) => {
  if (!value) return true;

  switch (operator) {
    case 'includes_any':
      if (value.includes('focus_groups') && !value.includes('video_call')) {
        return !!focus_group || (value.includes(style) && style !== 'video_call');
      }
      if (value.includes('focus_groups') && value.includes('video_call')) {
        return !!focus_group || value.includes(style);
      }

      return value.includes(style) && !focus_group;
    case 'includes_none':
      if (value.includes('focus_groups') && value.includes('video_call')) {
        return !value.includes(style);
      }
      if (value.includes('focus_groups') && !value.includes('video_call')) {
        return !focus_group && !value.includes(style);
      }
      if (!value.includes('focus_groups') && value.includes('video_call')) {
        return !value.includes(style) || !!focus_group;
      }
      return !value.includes(style);
  }
};
