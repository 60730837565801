import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { updateParticipation } from '@api/public_endpoints';
import { Button } from '@components/common';

import { ConfirmationIcon } from './ConfirmationIcon';

function getConfirmationPageKey(screener: Screener, status: string) {
  let key;

  if (status == 'requested') {
    key = screener.style == 'video_call' ? 'requested_to_schedule' : 'requested_to_get_started';
  } else if (screener.screener_type === 'pre') {
    key = 'screener';
  } else if (screener.style === 'panel') {
    key = 'panel';
  } else if (screener.style === 'unmoderated_test') {
    key = 'unmoderated';
  } else {
    key = 'survey';
  }

  return key;
}

interface Props {
  screener: Screener;
  participation: Participation | null;
  account: PublicGQAccount;
}

export const ConfirmationPage: React.FC<Props> = ({ screener, participation, account }) => {
  const page = getConfirmationPageKey(screener, participation?.status as ParticipationStatus);

  async function handleStartClick() {
    if (!participation) return;

    const resp = await updateParticipation(participation.token, { screener_id: screener.id, transition: 'request' });

    if (resp) {
      window.location.href = `/go/${participation.token}`;
    }
  }

  const { t } = useTranslation('ConfirmationPage');

  useEffect(() => {
    if (!participation) return;

    // hide label rendered by rails layout
    const requiredLabel = document.getElementById('required-label');
    if (requiredLabel) {
      requiredLabel.style.visibility = 'hidden';
    }
  }, []);

  return (
    <div id='step-done' key='done' className='tablet:mb-48 p-10 my-8 text-center border border-gray-200 rounded-sm'>
      <ConfirmationIcon className='mx-auto' />
      <h2 role='alert' area-live='Assertive' className='text-custom-brand py-2 text-2xl font-bold'>
        {t(`${page}.heading`)}
      </h2>
      <div className='text-custom-brand pb-8'>{t(`${page}.text`)}</div>
      {participation?.status === 'requested' && (
        <Button onClick={handleStartClick} noStyle className='btn-custom-brand'>
          {t(`${page}.cta`)}
        </Button>
      )}
      {['booked', 'completed'].includes(participation?.status || '') && participation?.external_completion_url && (
        <div className='pt-6 mt-6 border-t border-gray-200'>
          <div className='pb-8 font-bold text-gray-700'>{t('completion_code.heading')}</div>
          <Button href={`${participation.external_completion_url}`} target='_blank'>
            {t('completion_code.submit')}
          </Button>
        </div>
      )}
      {participation?.consent_token && !account.hide_global_opt_out_candidate_ux && (
        <div className='pt-6 mt-6 border-t border-gray-200'>
          <div className='text-custom-brand pb-8 font-bold'>{t('hearing_from_us')}</div>
          <Button
            href={`/my_preferences/${participation.consent_token}`}
            noStyle
            className='btn-custom-brand-secondary'
          >
            {t('update_contact_preferences')}
          </Button>
        </div>
      )}
    </div>
  );
};
