import { PageHeader } from 'components/shared/PageHeader';
import { useCollectionView } from 'components/stores/view';
import pluralize from 'pluralize';
import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Column as TableColumn } from 'react-table';

import { Alert, Button, Loading, Option, Text } from '@components/common';
import { LayoutToggle } from '@components/common';
import { Column, Grid } from '@components/common/Grid';
import { PageTitle } from '@components/common/helmets';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { SortDropdown } from '@components/shared/SortDropdown';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { buildEmailTemplateDefinitions } from '@components/TemplatesApp/helpers/buildEmailTemplatesDefinitions';
import { useCreateTemplate } from '@components/TemplatesApp/hooks/useCreateTemplate';
import { EditEmailTemplate } from '@components/TemplatesApp/pages/emails/edit';
import { sortTemplates } from '@components/TemplatesApp/utils';
import { track } from '@components/tracking';
import { PermissionCheck, usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';

import { ListViewTable } from '../components/ListViewTable';
import { SurveyCard } from '../components/SurveyCard';
import { TemplateCard } from '../components/TemplateCard';
import { H1, RESOURCE_NAME, TIPS_N_TRICKS } from '../consts';
import { buildSurveyTemplateDefinitions } from '../helpers/buildSurveyTemplateDefinitions';

type Props<T extends AbstractTemplate> = {
  kind: TemplateKind;
  buildColumns: (params: {
    canUpdate: PermissionCheck<T>;
    isGQAdmin?: boolean;
    onEdit: (template: T, createNew?: boolean) => void;
  }) => TableColumn<T>[];
  getQuery: () => { data: T[]; isLoading: boolean };
  createMutation: () => [
    (data?: Partial<EmailTemplate>) => EmailTemplate,
    { data: T; isLoading: boolean; isSuccess: boolean; isError: boolean }
  ];
};

const options: Option[] = [
  { label: 'Last updated', value: 'updated_at' },
  { label: 'Title', value: 'title' },
  { label: 'Use case', value: 'event' },
  { label: 'Created by', value: 'owner' }
];

const EMAILS = {
  InterviewTemplate: 'interiew',
  EmailTemplate: 'email',
  SurveyTemplate: 'survey'
};

export type TemplateForm = Pick<EmailTemplate, 'title' | 'default' | 'event' | 'subject'> & {
  text: string;
  cta: string;
};

export const TemplatesIndexPage = <T extends AbstractTemplate>(props: Props<T>) => {
  const canUpdate = usePermission<T>('updateInterviewTemplate');
  const { kind, createMutation, getQuery, buildColumns } = props;
  const { data, isLoading } = getQuery();
  const { gq_admin: isGQAdmin } = useUser();
  const [templates, setTemplates] = useState<T[]>(data || []);
  const [preparedTemplates, setPreparedTemlates] = useState<T[]>(templates || []);
  const [search, setSearch] = useState('');

  const [createNew, setCreateNew] = useState(false);

  const { search: searchUrl } = useLocation();

  const storageRef = useRef(false);

  const viewHook = useCollectionView();
  const { view, setView } = viewHook;
  const { sort, layout } = view;

  function setSort(sort) {
    setView({ sort });
  }
  function setLayout(layout) {
    setView({ layout });
  }

  const {
    create,
    creating,
    handleClickEditTemplate,
    emailTemplate,
    setEmailTemplate,
    showSlideOut,
    setShowSlideOut,
    methods
  } = useCreateTemplate({ createMutation, kind });

  const onEdit = (template, createNew) => {
    if (kind === 'EmailTemplate') {
      setCreateNew(createNew);
      setEmailTemplate(template);
      setShowSlideOut(true);
    } else {
      handleClickEditTemplate(template.id);
    }
  };

  const columns = buildColumns({ canUpdate, onEdit, isGQAdmin: !!isGQAdmin });

  const definitions = useMemo(() => {
    switch (kind) {
      case 'EmailTemplate':
        return buildEmailTemplateDefinitions();
      case 'SurveyTemplate':
        return buildSurveyTemplateDefinitions();
      default:
        return buildEmailTemplateDefinitions();
    }
  }, [kind]);

  const filtersHook = useTableFilters<SurveyTemplate | EmailTemplate>({
    trackKey: 'templates',
    definitions,
    syncWithURL: true
  });

  const handleSearch = (query: string) => {
    if (query) {
      setTemplates(data.filter(({ title }) => title.toLowerCase().includes(query.toLowerCase())));
    } else {
      setTemplates(data);
    }
    setSearch(query);
  };

  const onSortChange = ({ value }) => {
    setSort({ value, desc: false });
  };

  const onCreate = () => {
    kind === 'EmailTemplate' ? setShowSlideOut(true) : create();
  };

  useEffect(() => {
    if (!templates.length) return setPreparedTemlates([]);

    const filteredTemplates = filtersHook.rawFilter(templates as SurveyTemplate[]) as T[];
    const sortedTemplates = sortTemplates<T>(filteredTemplates, sort.value);

    setPreparedTemlates(sortedTemplates);
  }, [templates, filtersHook.filters, filtersHook.op, sort]);

  useEffect(() => {
    if (!isLoading) {
      setTemplates(data);
    }
  }, [isLoading, data]);

  useEffect(() => {
    track('viewed_templates', { page: kind });
  }, [kind]);

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex items-center justify-between flex-1'>
        <TableFilters<SurveyTemplate | EmailTemplate>
          hook={filtersHook}
          defaultShowInput={!filtersHook.filters.length}
        />
        <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
          <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
          <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
        </div>
      </div>
    </div>
  );
  return (
    <DashboardLayout>
      <PageTitle>{H1[kind]}</PageTitle>
      <PageHeader
        h1={H1[kind]}
        filtersApplied={!!filtersHook.filters.length}
        renderCta={() => (
          <RestrictedButton action={`Create ${RESOURCE_NAME[kind]}`} permission='createTemplate'>
            <Button
              primary
              medium
              className='whitespace-nowrap'
              disabled={creating}
              trackEvent={'create_template'}
              trackProps={{ kind }}
              onClick={onCreate}
            >
              Create {RESOURCE_NAME[kind]}
            </Button>
          </RestrictedButton>
        )}
        renderFilters={renderFilters}
        searchProps={{
          onSearch: handleSearch,
          value: search,
          addDebounce: true
        }}
        renderSortBy={
          kind === 'EmailTemplate'
            ? () => <SortDropdown wrapperClass='w-full' options={options} value={sort.value} onChange={onSortChange} />
            : undefined
        }
        renderLayout={() => <LayoutToggle value={layout} onChange={setLayout} />}
      />

      <DashboardLayoutBody>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {kind !== 'InterviewTemplate' && (
              <Alert type='promo' className='mb-4'>
                <Text bold>Tips and tricks</Text>
                {TIPS_N_TRICKS[kind]}
              </Alert>
            )}
            <div className='mobile:flex-row mobile:justify-between flex flex-col items-center justify-center mb-4'>
              <div className='flex-grow'>
                {preparedTemplates && (
                  <Text color='gray-500' h='400'>
                    {preparedTemplates && (
                      <div className='flex items-center mb-4'>
                        <div className='flex-grow'>
                          <Text color='gray-500' className='whitespace-nowrap' h='400'>
                            {pluralize('templates', preparedTemplates.length, true)}
                          </Text>
                        </div>
                      </div>
                    )}
                  </Text>
                )}
              </div>
            </div>
            {layout === 'grid' && (
              <Grid gap={2} desktop={4} tablet={3} mobile={2} tiny={1} className='pb-4'>
                {preparedTemplates.map(
                  (t) =>
                    t && (
                      <Column key={t.id} className='flex w-full'>
                        {['SurveyTemplate', 'InterviewTemplate'].includes(kind) ? (
                          <SurveyCard<T>
                            allowUpdate={canUpdate(t)}
                            template={t}
                            onEdit={handleClickEditTemplate}
                            kind={kind}
                          />
                        ) : (
                          <TemplateCard<T>
                            allowUpdate={canUpdate(t)}
                            className='m-3'
                            template={t}
                            kind={kind}
                            onEdit={onEdit}
                          />
                        )}
                      </Column>
                    )
                )}
              </Grid>
            )}
            {layout === 'list' && templates && <ListViewTable<T> columns={columns} templates={preparedTemplates} />}
          </>
        )}
      </DashboardLayoutBody>
      {showSlideOut && (
        <FormProvider {...methods}>
          <EditEmailTemplate
            createNew={createNew}
            emailTemplate={emailTemplate}
            onClose={() => {
              setEmailTemplate(null);
              setShowSlideOut(false);
            }}
          />
        </FormProvider>
      )}
    </DashboardLayout>
  );
};
