export const EVENT_TITLES: Record<StudyMessageEvent, string> = {
  booked: 'Booked confirmations',
  booked_reminder: 'Booked reminders',
  cancel_interview: 'Interviews canceled',
  cancel_task: 'Tasks canceled',
  invite: 'Participation invites',
  invited_reminder: 'Participation invite reminders',
  reschedule_request: 'Booking reschedule requests',
  screener: 'Screener invites',
  started_reminder: 'Started reminders',
  thanks: 'Thanks messages',
  welcome: 'Welcome messages',
  ad_hoc: 'Ad hoc messages'
};
export const EVENT_DEFAULT_HEADERS = ['Sent', 'Opened', 'Clicked', 'Completed'];
export const EVENT_HEADERS: Partial<Record<StudyMessageEvent, typeof EVENT_DEFAULT_HEADERS>> = {
  invite: ['Sent', 'Opened', 'Clicked', 'Scheduled']
};

export const DEFAULT_CANDIDATE_MESSAGES_VIEW: CollectionView = {
  sort: {
    value: 'name',
    desc: false
  },
  filters: '',
  layout: 'list',
  columns: ['name', 'sender', 'state'],
  tab: 'all'
};

export const DEFAULT_TOOLTIPS: Record<keyof StudyStats, string> = {
  opened: 'Open rate equals the number of people who opened the email divided by the total recipients.',
  clicked:
    'Click rate equals the number of people who clicked through the email divided by the total number who opened.',
  sent: 'Sent rate equals the number of people you tried sending the email to.',
  converted: 'Completion rate equals the number of people who completed the desired action.'
};
export const TOOLTIPS: Partial<Record<StudyMessageEvent, Partial<typeof DEFAULT_TOOLTIPS>>> = {
  invite: {
    converted: 'Scheduled rate is the number of recipients who booked an interview.'
  }
};

export const CANCELATION_REASONS: Record<CandidateMessage['cancelation_reason'], { short: string; tooltip: string }> = {
  external_candidates_request_error: {
    short: 'External request error',
    tooltip: 'The external request to the candidate failed.'
  },
  nylas_request_error: {
    short: 'Nylas request error',
    tooltip: 'The Nylas request to the candidate failed.'
  },
  candidate_unsubscribed: {
    short: 'Unsubscribed',
    tooltip: 'The candidate unsubscribed from the study.'
  },
  unsupported_sender: {
    short: 'Unsupported sender',
    tooltip: 'This system sender is not supported.'
  },
  candidate_deleted: {
    short: 'Candidate deleted',
    tooltip: 'The candidate was deleted.'
  },
  incentive_error: {
    short: 'Incentive error',
    tooltip: 'Internal error generating the incentive.'
  },
  participant_already_started: {
    short: 'No longer needed',
    tooltip: 'The participant already started, so an invite is not needed'
  },
  batch_canceled: {
    short: 'Batch canceled',
    tooltip: 'The message batch was canceled.'
  },
  double_send_prevented: {
    short: 'Duplicate',
    tooltip: 'The message was accidentally duplicated.'
  },
  invalid_message_template: {
    short: 'Invalid template',
    tooltip: 'The message template is invalid.'
  }
};
