import React, { SVGProps } from 'react';

export const ActivitiesZDSSVG = () => (
  <svg width='128' height='125' viewBox='0 0 128 125' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M81.2589 28.0591H46.7684C45.9825 28.06 45.229 28.3722 44.6732 28.9272C44.1174 29.4821 43.8048 30.2345 43.8038 31.0193V107.561L43.4085 107.682L34.9475 110.269C34.5465 110.391 34.1134 110.349 33.7432 110.153C33.373 109.957 33.0961 109.621 32.9731 109.221L7.80532 27.1335C7.68284 26.7331 7.72459 26.3005 7.92138 25.9308C8.11817 25.5611 8.45391 25.2846 8.85481 25.162L21.8933 21.1755L59.6923 9.62263L72.7307 5.63616C72.9291 5.57518 73.1376 5.55387 73.3443 5.57346C73.551 5.59304 73.7517 5.65312 73.9351 5.75027C74.1185 5.84743 74.2809 5.97974 74.4131 6.13963C74.5452 6.29953 74.6444 6.48387 74.7051 6.68212L81.1383 27.6644L81.2589 28.0591Z'
      fill='#F2F2F2'
    />
    <path
      d='M88.7867 27.6649L81.0332 2.37648C80.9042 1.95513 80.6933 1.56329 80.4126 1.22335C80.1319 0.883407 79.7868 0.602032 79.3972 0.3953C79.0075 0.188568 78.5808 0.0605277 78.1416 0.0185023C77.7024 -0.0235232 77.2591 0.0212892 76.8372 0.150374L58.506 5.75314L20.709 17.308L2.37773 22.9127C1.52616 23.1738 0.813123 23.7616 0.395139 24.5472C-0.0228463 25.3327 -0.111632 26.2517 0.148273 27.1025L26.6481 113.528C26.8592 114.215 27.2853 114.816 27.8636 115.243C28.442 115.67 29.1423 115.901 29.8618 115.902C30.1948 115.902 30.5259 115.852 30.844 115.754L43.4101 111.914L43.8054 111.791V111.379L43.4101 111.499L30.7275 115.377C29.9758 115.606 29.1639 115.528 28.4699 115.16C27.776 114.791 27.2566 114.164 27.0256 113.413L0.527827 26.986C0.41341 26.6144 0.373533 26.2239 0.410477 25.8369C0.447421 25.4498 0.560458 25.0739 0.743123 24.7305C0.925787 24.3872 1.17449 24.0832 1.47498 23.8359C1.77548 23.5887 2.12186 23.403 2.4943 23.2897L20.8256 17.6849L58.6226 6.13206L76.9539 0.527316C77.2364 0.441232 77.5301 0.397339 77.8255 0.397065C78.4593 0.398486 79.0761 0.602537 79.5854 0.979331C80.0947 1.35612 80.4698 1.88586 80.6557 2.49095L88.3736 27.6649L88.4962 28.0596H88.9073L88.7867 27.6649Z'
      fill='#374151'
    />
    <path
      d='M24.2475 25.2233C23.8666 25.223 23.4958 25.1009 23.1894 24.8747C22.8831 24.6486 22.6575 24.3304 22.5455 23.9668L19.9998 15.664C19.9314 15.4409 19.9077 15.2066 19.93 14.9744C19.9523 14.7423 20.0202 14.5167 20.1297 14.3107C20.2393 14.1047 20.3885 13.9223 20.5687 13.7739C20.7489 13.6255 20.9566 13.514 21.18 13.4457L55.9529 2.81569C56.404 2.67824 56.8913 2.72512 57.3078 2.94603C57.7243 3.16695 58.036 3.54385 58.1745 3.99401L60.7202 12.297C60.8578 12.7474 60.8108 13.2339 60.5896 13.6498C60.3684 14.0657 59.991 14.3769 59.5402 14.5153L24.7672 25.1453C24.5988 25.1969 24.4236 25.2232 24.2475 25.2233Z'
      fill='#9FA6B2'
    />
    <path
      d='M37.5817 8.87146C39.7648 8.87146 41.5346 7.10433 41.5346 4.92446C41.5346 2.74459 39.7648 0.977463 37.5817 0.977463C35.3987 0.977463 33.6289 2.74459 33.6289 4.92446C33.6289 7.10433 35.3987 8.87146 37.5817 8.87146Z'
      fill='#9FA6B2'
    />
    <path
      d='M37.5812 7.42485C38.9636 7.42485 40.0842 6.30585 40.0842 4.92549C40.0842 3.54513 38.9636 2.42612 37.5812 2.42612C36.1988 2.42612 35.0781 3.54513 35.0781 4.92549C35.0781 6.30585 36.1988 7.42485 37.5812 7.42485Z'
      fill='white'
    />
    <path
      d='M119.106 114.892H52.303C51.8576 114.891 51.4306 114.714 51.1157 114.4C50.8007 114.086 50.6236 113.659 50.623 113.214V33.2877C50.6235 32.8429 50.8007 32.4165 51.1156 32.102C51.4306 31.7876 51.8576 31.6107 52.303 31.6102H119.106C119.551 31.6107 119.978 31.7876 120.293 32.1021C120.608 32.4165 120.785 32.8429 120.786 33.2877V113.214C120.785 113.659 120.608 114.086 120.293 114.4C119.978 114.714 119.551 114.891 119.106 114.892Z'
      fill='#E6E6E6'
    />
    <path
      d='M88.3717 27.6634H46.7681C45.8774 27.6646 45.0235 28.0185 44.3937 28.6474C43.7639 29.2763 43.4095 30.1289 43.4082 31.0183V111.498L43.8035 111.377V31.0183C43.8044 30.2335 44.1171 29.4811 44.6729 28.9262C45.2286 28.3712 45.9821 28.059 46.7681 28.0581H88.4943L88.3717 27.6634ZM124.639 27.6634H46.7681C45.8774 27.6646 45.0235 28.0185 44.3937 28.6474C43.7639 29.2763 43.4095 30.1289 43.4082 31.0183V121.405C43.4095 122.294 43.7639 123.147 44.3937 123.776C45.0235 124.404 45.8774 124.758 46.7681 124.76H124.639C125.53 124.758 126.384 124.404 127.013 123.776C127.643 123.147 127.998 122.294 127.999 121.405V31.0183C127.998 30.1289 127.643 29.2763 127.013 28.6474C126.384 28.0185 125.53 27.6646 124.639 27.6634ZM127.604 121.405C127.603 122.189 127.29 122.942 126.734 123.497C126.178 124.052 125.425 124.364 124.639 124.365H46.7681C45.9821 124.364 45.2286 124.052 44.6729 123.497C44.1171 122.942 43.8044 122.189 43.8035 121.405V31.0183C43.8044 30.2335 44.1171 29.4811 44.6729 28.9262C45.2286 28.3712 45.9821 28.059 46.7681 28.0581H124.639C125.425 28.059 126.178 28.3712 126.734 28.9262C127.29 29.4811 127.603 30.2335 127.604 31.0183V121.405Z'
      fill='#374151'
    />
    <path
      d='M103.887 36.3451H67.521C67.0494 36.3445 66.5972 36.1572 66.2638 35.8243C65.9303 35.4913 65.7427 35.0398 65.7422 34.5689V25.8855C65.7427 25.4146 65.9303 24.9632 66.2638 24.6302C66.5972 24.2972 67.0494 24.1099 67.521 24.1094H103.887C104.359 24.1099 104.811 24.2972 105.144 24.6302C105.478 24.9632 105.665 25.4146 105.666 25.8855V34.5689C105.665 35.0398 105.478 35.4913 105.144 35.8243C104.811 36.1572 104.359 36.3445 103.887 36.3451Z'
      fill='#9FA6B2'
    />
    <path
      d='M85.7048 24.7032C87.8879 24.7032 89.6577 22.936 89.6577 20.7562C89.6577 18.5763 87.8879 16.8092 85.7048 16.8092C83.5217 16.8092 81.752 18.5763 81.752 20.7562C81.752 22.936 83.5217 24.7032 85.7048 24.7032Z'
      fill='#9FA6B2'
    />
    <path
      d='M85.7045 23.1601C87.0342 23.1601 88.1122 22.0838 88.1122 20.756C88.1122 19.4283 87.0342 18.352 85.7045 18.352C84.3748 18.352 83.2969 19.4283 83.2969 20.756C83.2969 22.0838 84.3748 23.1601 85.7045 23.1601Z'
      fill='white'
    />
  </svg>
);
