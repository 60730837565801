import { uniq } from '@components/utils';

export function getUnmappedColumns({ headers, hidden_column_ids, mapping }: CustomerImport): number[] {
  const errors: number[] = [];
  for (let i = 0; i < headers.length; i++) {
    if (!mapping[i] && !hidden_column_ids.includes(i)) {
      errors.push(i);
    }
  }
  return errors;
}

export const isMissingUniqueId = ({ hidden_column_ids, id_attr, mapping }: CustomerImport): string | undefined => {
  const uniqueIdIndicies: number[] = new Array(mapping.length)
    .fill(0)
    .map((_, i) => i)
    .filter((i) => mapping[i] === id_attr);
  const uniqueIdsHidden: boolean =
    hidden_column_ids.length > 0 && uniqueIdIndicies.every((i) => hidden_column_ids.includes(i));

  if (!mapping.includes(id_attr) || uniqueIdsHidden) {
    return id_attr.replace('extra:', '');
  }
};

export const hasMultipleUniqueIds = ({ id_attr, mapping }: CustomerImport) => {
  return id_attr.startsWith('extra:') && mapping.includes('email');
};

export const getDuplicateMappings = ({ hidden_column_ids, mapping }: CustomerImport): string[] => {
  const mappingWithoutHidden = mapping.map((m, i) => (hidden_column_ids.includes(i) ? null : m));

  return uniq(mapping.filter((m, i) => !!m && mappingWithoutHidden.indexOf(m) !== mappingWithoutHidden.lastIndexOf(m)));
};
