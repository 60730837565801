import * as React from 'react';

export const CustomerInterviewsSVG: React.FC<any> = (props) => (
  <div
    {...props}
    className='flex items-center justify-center w-10 h-10 rounded-md'
    style={{ backgroundColor: '#3F83F8' }}
  >
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.75 6.01012C12.75 6.60685 12.9871 7.17915 13.409 7.60111C13.831 8.02306 14.4033 8.26012 15 8.26012C15.5967 8.26012 16.169 8.02306 16.591 7.60111C17.0129 7.17915 17.25 6.60685 17.25 6.01012C17.25 5.41338 17.0129 4.84108 16.591 4.41913C16.169 3.99717 15.5967 3.76012 15 3.76012C14.4033 3.76012 13.831 3.99717 13.409 4.41913C12.9871 4.84108 12.75 5.41338 12.75 6.01012V6.01012Z'
        stroke='#F9FAFB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 9.01012C15.9946 9.01012 16.9484 9.4052 17.6517 10.1085C18.3549 10.8117 18.75 11.7656 18.75 12.7601'
        stroke='#F9FAFB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 4.51012V2.26012C2.25 1.86229 2.40804 1.48076 2.68934 1.19946C2.97064 0.918152 3.35218 0.760117 3.75 0.760117H21.75C22.1478 0.760117 22.5294 0.918152 22.8107 1.19946C23.092 1.48076 23.25 1.86229 23.25 2.26012V14.2601C23.25 14.6579 23.092 15.0395 22.8107 15.3208C22.5294 15.6021 22.1478 15.7601 21.75 15.7601H14.25'
        stroke='#F9FAFB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 15.7601V18.7601'
        stroke='#F9FAFB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M15.75 18.7601H19.5' stroke='#F9FAFB' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M0.75 23.2601C0.75 21.4699 1.46116 19.753 2.72703 18.4871C3.9929 17.2213 5.70979 16.5101 7.5 16.5101C9.29021 16.5101 11.0071 17.2213 12.273 18.4871C13.5388 19.753 14.25 21.4699 14.25 23.2601'
        stroke='#F9FAFB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.375 10.8851C3.375 11.9791 3.8096 13.0283 4.58318 13.8019C5.35677 14.5755 6.40598 15.0101 7.5 15.0101C8.59402 15.0101 9.64323 14.5755 10.4168 13.8019C11.1904 13.0283 11.625 11.9791 11.625 10.8851C11.625 9.7911 11.1904 8.74189 10.4168 7.9683C9.64323 7.19471 8.59402 6.76012 7.5 6.76012C6.40598 6.76012 5.35677 7.19471 4.58318 7.9683C3.8096 8.74189 3.375 9.7911 3.375 10.8851V10.8851Z'
        stroke='#F9FAFB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </div>
);

export const SurveysSVG: React.FC<any> = (props) => (
  <div
    {...props}
    className='flex items-center justify-center w-10 h-10 rounded-md'
    style={{ backgroundColor: '#FF5A1F' }}
  >
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.0453 18.636L9.33333 19.166L9.86333 15.454L19.4093 5.90798C19.8313 5.48602 20.4036 5.24897 21.0003 5.24897C21.5971 5.24897 22.1694 5.48602 22.5913 5.90798C23.0133 6.32994 23.2503 6.90224 23.2503 7.49898C23.2503 8.09572 23.0133 8.66802 22.5913 9.08998L13.0453 18.636Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 0.748989H12.75V3.74899H5.25V0.748989Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 2.24899H15.75C16.1478 2.24899 16.5294 2.40702 16.8107 2.68833C17.092 2.96963 17.25 3.35116 17.25 3.74899'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.25 18.749V21.749C17.25 22.1468 17.092 22.5283 16.8107 22.8096C16.5294 23.091 16.1478 23.249 15.75 23.249H2.25C1.85218 23.249 1.47064 23.091 1.18934 22.8096C0.908035 22.5283 0.75 22.1468 0.75 21.749V3.74899C0.75 3.35116 0.908035 2.96963 1.18934 2.68833C1.47064 2.40702 1.85218 2.24899 2.25 2.24899H5.25'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.25 8.24899H12.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.25 12.749H8.25' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </div>
);

export const OnlineTasksSVG: React.FC<any> = (props) => (
  <div
    {...props}
    className='flex items-center justify-center w-10 h-10 rounded-md'
    style={{ backgroundColor: '#0694A2' }}
  >
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='24' height='24' fill='#0694A2' />
      <path d='M8.25 23.25H15.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12 20.25V23.25' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M0.75 16.5H23.25' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M21.75 3C22.1478 3 22.5294 3.15804 22.8107 3.43934C23.092 3.72064 23.25 4.10218 23.25 4.5V18.75C23.25 19.1478 23.092 19.5294 22.8107 19.8107C22.5294 20.092 22.1478 20.25 21.75 20.25H2.25C1.85218 20.25 1.47064 20.092 1.18934 19.8107C0.908035 19.5294 0.75 19.1478 0.75 18.75V4.5C0.75 4.10218 0.908035 3.72064 1.18934 3.43934C1.47064 3.15804 1.85218 3 2.25 3'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 13.5V2.25C5.25 1.85218 5.40804 1.47064 5.68934 1.18934C5.97064 0.908035 6.35218 0.75 6.75 0.75H17.25C17.6478 0.75 18.0294 0.908035 18.3107 1.18934C18.592 1.47064 18.75 1.85218 18.75 2.25V13.5'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 6.75H11.25V9.75H8.25V6.75Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M5.25 3.75H18.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.25 6.75H15.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M14.25 9H15.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.25 12.75H15.75' stroke='white' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </div>
);

export const UnmoderatedSVG: React.FC<any> = (props) => (
  <div
    {...props}
    className='flex items-center justify-center w-10 h-10 rounded-md'
    style={{ backgroundColor: '#12102F' }}
  >
    <svg width='24' height='27' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 12.0938V3.65625C1.5 3.35788 1.60536 3.07173 1.79289 2.86075C1.98043 2.64978 2.23478 2.53125 2.5 2.53125H13.5C13.7652 2.53125 14.0196 2.64978 14.2071 2.86075C14.3946 3.07173 14.5 3.35788 14.5 3.65625V12.0938'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.72267 12.0938C9.54915 12.4354 9.29841 12.7194 8.99589 12.9169C8.69336 13.1145 8.34979 13.2185 8 13.2185C7.65021 13.2185 7.30664 13.1145 7.00411 12.9169C6.70159 12.7194 6.45085 12.4354 6.27733 12.0938H0.5V13.2188C0.5 13.8155 0.710714 14.3878 1.08579 14.8097C1.46086 15.2317 1.96957 15.4687 2.5 15.4688H13.5C14.0304 15.4687 14.5391 15.2317 14.9142 14.8097C15.2893 14.3878 15.5 13.8155 15.5 13.2188V12.0938H9.72267Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.28267 5.40454C7.19971 5.36066 7.10855 5.34 7.01697 5.34431C6.92538 5.34862 6.83608 5.37777 6.75667 5.42929C6.67958 5.47839 6.6153 5.54939 6.57026 5.63521C6.52522 5.72102 6.50099 5.81863 6.5 5.91829V8.70679C6.50106 8.80642 6.52531 8.904 6.57035 8.98981C6.61538 9.07561 6.67962 9.14663 6.75667 9.19579C6.83608 9.2473 6.92538 9.27645 7.01697 9.28076C7.10855 9.28507 7.19971 9.26441 7.28267 9.22054L9.60867 7.98529C9.72495 7.92808 9.82389 7.83398 9.89337 7.71449C9.96285 7.595 9.99986 7.45532 9.99986 7.31254C9.99986 7.16975 9.96285 7.03007 9.89337 6.91058C9.82389 6.79109 9.72495 6.69699 9.60867 6.63979L7.28267 5.40454Z'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </div>
);

export const PanelSVG: React.FC<any> = (props) => (
  <div {...props} className='flex items-center justify-center w-10 h-10 bg-indigo-600 rounded-md'>
    <svg className='w-6 h-6' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.5 2.67592C10.5095 1.87454 9.27406 1.43732 8 1.43732C6.72594 1.43732 5.49046 1.87454 4.5 2.67592'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.95837 4.78467C6.53622 4.31736 7.25688 4.06242 8.00004 4.06242C8.7432 4.06242 9.46386 4.31736 10.0417 4.78467'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.4375 9.09375C1.4375 9.49986 1.59883 9.88934 1.88599 10.1765C2.17316 10.4637 2.56264 10.625 2.96875 10.625C3.37486 10.625 3.76434 10.4637 4.05151 10.1765C4.33867 9.88934 4.5 9.49986 4.5 9.09375C4.5 8.68764 4.33867 8.29816 4.05151 8.01099C3.76434 7.72383 3.37486 7.5625 2.96875 7.5625C2.56264 7.5625 2.17316 7.72383 1.88599 8.01099C1.59883 8.29816 1.4375 8.68764 1.4375 9.09375V9.09375Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.38317 12.6568C5.22847 12.2953 4.99457 11.9732 4.69872 11.7143C4.40288 11.4554 4.05265 11.2662 3.6739 11.1608C3.29515 11.0553 2.89755 11.0363 2.51047 11.1051C2.12339 11.1739 1.75671 11.3288 1.4375 11.5583'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 9.09375C11.5 9.29484 11.5396 9.49395 11.6166 9.67973C11.6935 9.86551 11.8063 10.0343 11.9485 10.1765C12.0907 10.3187 12.2595 10.4315 12.4453 10.5084C12.631 10.5854 12.8302 10.625 13.0312 10.625C13.2323 10.625 13.4315 10.5854 13.6172 10.5084C13.803 10.4315 13.9718 10.3187 14.114 10.1765C14.2562 10.0343 14.369 9.86551 14.4459 9.67973C14.5229 9.49395 14.5625 9.29484 14.5625 9.09375C14.5625 8.89266 14.5229 8.69355 14.4459 8.50777C14.369 8.32199 14.2562 8.15318 14.114 8.01099C13.9718 7.8688 13.803 7.75601 13.6172 7.67906C13.4315 7.60211 13.2323 7.5625 13.0312 7.5625C12.8302 7.5625 12.631 7.60211 12.4453 7.67906C12.2595 7.75601 12.0907 7.8688 11.9485 8.01099C11.8063 8.15318 11.6935 8.32199 11.6166 8.50777C11.5396 8.69355 11.5 8.89266 11.5 9.09375V9.09375Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6168 12.6568C10.7715 12.2953 11.0054 11.9732 11.3013 11.7143C11.5971 11.4554 11.9473 11.2662 12.3261 11.1608C12.7048 11.0553 13.1024 11.0363 13.4895 11.1051C13.8766 11.1739 14.2433 11.3288 14.5625 11.5583'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.03125 8.65625C6.03125 9.17839 6.23867 9.67915 6.60788 10.0484C6.9771 10.4176 7.47785 10.625 8 10.625C8.52214 10.625 9.0229 10.4176 9.39212 10.0484C9.76133 9.67915 9.96875 9.17839 9.96875 8.65625C9.96875 8.1341 9.76133 7.63335 9.39212 7.26413C9.0229 6.89492 8.52214 6.6875 8 6.6875C7.47785 6.6875 6.9771 6.89492 6.60788 7.26413C6.23867 7.63335 6.03125 8.1341 6.03125 8.65625V8.65625Z'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.5 14.5625C11.386 13.715 10.9685 12.9377 10.3248 12.3748C9.68114 11.8119 8.85509 11.5016 8 11.5016C7.14491 11.5016 6.31886 11.8119 5.67519 12.3748C5.03152 12.9377 4.61395 13.715 4.5 14.5625'
        stroke='white'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  </div>
);

export const FocusGroupSVG: React.FC<any> = (props) => (
  <div
    {...props}
    className='flex items-center justify-center w-10 h-10 rounded-md'
    style={{ backgroundColor: '#9F46F4' }}
  >
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='4' fill='#9F46F4' />
      <g clipPath='url(#clip0_1422_13668)'>
        <path
          d='M6.71777 21.1563C6.71777 21.7944 6.97129 22.4065 7.42255 22.8577C7.87381 23.309 8.48585 23.5625 9.12402 23.5625C9.7622 23.5625 10.3742 23.309 10.8255 22.8577C11.2768 22.4065 11.5303 21.7944 11.5303 21.1563C11.5303 20.5181 11.2768 19.906 10.8255 19.4548C10.3742 19.0035 9.7622 18.75 9.12402 18.75C8.48585 18.75 7.87381 19.0035 7.42255 19.4548C6.97129 19.906 6.71777 20.5181 6.71777 21.1563Z'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5615 26.3125C12.2292 25.6895 11.7339 25.1685 11.1284 24.8052C10.5229 24.4419 9.83012 24.25 9.12402 24.25C8.41793 24.25 7.72511 24.4419 7.11963 24.8052C6.51416 25.1685 6.0188 25.6895 5.68652 26.3125'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.4678 21.1563C20.4678 21.4722 20.53 21.7851 20.6509 22.0771C20.7719 22.369 20.9491 22.6343 21.1725 22.8577C21.396 23.0812 21.6613 23.2584 21.9532 23.3793C22.2451 23.5003 22.558 23.5625 22.874 23.5625C23.19 23.5625 23.5029 23.5003 23.7949 23.3793C24.0868 23.2584 24.3521 23.0812 24.5755 22.8577C24.7989 22.6343 24.9762 22.369 25.0971 22.0771C25.218 21.7851 25.2803 21.4722 25.2803 21.1563C25.2803 20.8403 25.218 20.5274 25.0971 20.2354C24.9762 19.9435 24.7989 19.6782 24.5755 19.4548C24.3521 19.2313 24.0868 19.0541 23.7949 18.9332C23.5029 18.8122 23.19 18.75 22.874 18.75C22.558 18.75 22.2451 18.8122 21.9532 18.9332C21.6613 19.0541 21.396 19.2313 21.1725 19.4548C20.9491 19.6782 20.7719 19.9435 20.6509 20.2354C20.53 20.5274 20.4678 20.8403 20.4678 21.1563Z'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.3115 26.3125C25.9792 25.6895 25.4839 25.1685 24.8784 24.8052C24.2729 24.4419 23.5801 24.25 22.874 24.25C22.1679 24.25 21.4751 24.4419 20.8696 24.8052C20.2642 25.1685 19.7688 25.6895 19.4365 26.3125'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.5928 8.09375C13.5928 8.40974 13.655 8.72264 13.7759 9.01458C13.8969 9.30652 14.0741 9.57178 14.2975 9.79523C14.521 10.0187 14.7863 10.1959 15.0782 10.3168C15.3701 10.4378 15.683 10.5 15.999 10.5C16.315 10.5 16.6279 10.4378 16.9199 10.3168C17.2118 10.1959 17.4771 10.0187 17.7005 9.79523C17.9239 9.57178 18.1012 9.30652 18.2221 9.01458C18.343 8.72264 18.4053 8.40974 18.4053 8.09375C18.4053 7.77776 18.343 7.46486 18.2221 7.17292C18.1012 6.88098 17.9239 6.61572 17.7005 6.39227C17.4771 6.16883 17.2118 5.99159 16.9199 5.87066C16.6279 5.74974 16.315 5.6875 15.999 5.6875C15.683 5.6875 15.3701 5.74974 15.0782 5.87066C14.7863 5.99159 14.521 6.16883 14.2975 6.39227C14.0741 6.61572 13.8969 6.88098 13.7759 7.17292C13.655 7.46486 13.5928 7.77776 13.5928 8.09375Z'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.9774 12.5625C18.61 12.1312 18.1534 11.7847 17.639 11.5472C17.1246 11.3096 16.5648 11.1866 15.9982 11.1866C15.4316 11.1866 14.8718 11.3096 14.3574 11.5472C13.8431 11.7847 13.3864 12.1312 13.019 12.5625'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.2958 23.0647C15.0514 23.7341 16.9929 23.7282 18.7444 23.0482'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.819 10.5C10.0665 11.2048 9.46662 12.0566 9.05666 13.0027C8.6467 13.9488 8.43534 14.9689 8.43567 16C8.43567 16.2319 8.44942 16.4583 8.4705 16.6875'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M23.5267 16.6875C23.5469 16.4602 23.5616 16.2292 23.5616 16C23.5621 14.9689 23.3508 13.9487 22.9408 13.0026C22.5308 12.0565 21.9309 11.2047 21.1782 10.5'
          stroke='white'
          strokeWidth='1.375'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1422_13668'>
          <rect width='22' height='22' fill='white' transform='translate(5 5)' />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export const ContinuousSVG: React.FC<any> = (props) => (
  <div
    {...props}
    className='flex items-center justify-center w-10 h-10 rounded-md'
    style={{ backgroundColor: '#16bdca' }}
  >
    <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'>
      <clipPath id='a'>
        <path d='m4 4h24v24h-24z' />
      </clipPath>
      <rect fill='#16bdca' height='32' rx='4' width='32' />
      <g clipPath='url(#a)' stroke='#fff' strokeLinejoin='round' strokeWidth='1.5'>
        <path d='m25.75 7.75h-19.5c-.82843 0-1.5.67157-1.5 1.5v16.5c0 .8284.67157 1.5 1.5 1.5h19.5c.8284 0 1.5-.6716 1.5-1.5v-16.5c0-.82843-.6716-1.5-1.5-1.5z' />
        <path d='m4.75 13.75h22.5' />
        <g strokeLinecap='round'>
          <path d='m10.75 10v-5.25' />
          <path d='m21.25 10v-5.25' />
          <path d='m15.344 19.75h-2.344v-3' />
          <path d='m13.118 19.75c.1993-.8257.661-1.5645 1.3159-2.1055.6549-.5409 1.4676-.8548 2.3161-.8945.6345 0 1.2586.1609 1.8139.4678.5553.3068 1.0236.7496 1.3613 1.2867.3376.5372.5334 1.1513.569 1.7847.0357.6335-.09 1.2656-.3651 1.8373-.2752.5716-.6909 1.0642-1.2083 1.4314s-1.1194.5972-1.7499.6684c-.6304.0712-1.2686-.0187-1.8549-.2614-.5862-.2426-1.1013-.63-1.497-1.1259' />
        </g>
      </g>
    </svg>
  </div>
);
