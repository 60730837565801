import React from 'react';

import { Button, Modal, ModalHeading, ModalSubheading } from '@components/common';

interface Props {
  action: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const SimpleConfirmationModal = ({ action, onCancel, onConfirm }: Props) => {
  return (
    <Modal size='sm' onClose={onCancel}>
      <ModalHeading className='mb-6'>Are you sure?</ModalHeading>
      <ModalSubheading className='mb-6'>Click Yes to {action}</ModalSubheading>
      <div className='flex justify-end space-x-4'>
        <Button onClick={onCancel}>No</Button>
        <Button onClick={onConfirm} primary>
          Yes
        </Button>
      </div>
    </Modal>
  );
};
