import React, { SVGProps } from 'react';

export const HideTabsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1.5' y='1.5' width='13' height='13' rx='3.5' fill='white' stroke='currentColor' />
    <line x1='6' y1='14.021' x2='6' y2='1.9794' stroke='currentColor' />
    <g clipPath='url(#clip0_1093_4818)'>
      <path
        d='M8.74978 8.00003C8.74978 7.88336 8.79978 7.78336 8.88311 7.71669L10.7498 6.06669C10.8831 5.95003 11.0831 5.96669 11.1831 6.10003C11.2831 6.23336 11.2831 6.41669 11.1498 6.53336L9.51645 7.96669C9.49978 7.98336 9.49978 8.00003 9.51645 8.03336L11.1498 9.46669C11.2831 9.58336 11.2831 9.78336 11.1664 9.90003C11.0498 10.0167 10.8664 10.0334 10.7331 9.93336L8.86645 8.30003C8.79978 8.21669 8.74978 8.11669 8.74978 8.00003Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1093_4818'>
        <rect width='4' height='4' fill='white' transform='translate(12 10) rotate(-180)' />
      </clipPath>
    </defs>
  </svg>
);
