import cn from 'classnames';
import { Spinner, Text } from 'components/common';
import { useCanvasPlayer } from 'components/HighlightReelsApp/hooks/useCanvasPlayer';
import { noop } from 'components/utils';
import React, { forwardRef, HTMLAttributes, useEffect, useRef, useState } from 'react';

import { api } from '@api/reduxApi';
import { ErrorSvg, VideoSVG } from '@components/svgs';
import { Controls } from '@components/VideoPlayer/components';

import { useHighlightReelsContext } from '../../../hooks/useHighlightReelsContext';
import { Thumbnail } from './Thumbnail';
import { useLocalStorage } from '@hooks/useLocalStorage';

interface Props extends HTMLAttributes<HTMLDivElement> {}

export const Player = forwardRef<HTMLDivElement, Props>(({ className, ...rest }, ref) => {
  const [showRecompileAlert, setShowRecompileAlert] = useState<boolean>(false);
  const compiledRef = useRef<boolean>(false);

  const { artifactIds, setPlayerIsLoading } = useHighlightReelsContext();

  const [playbackSpeed, setPlaybackSpeed] = useLocalStorage('gq-default-playback-speed');

  const { data: artifacts = [], isLoading, isSuccess } = api.useGetArtifactsQuery(artifactIds);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [volume, setVolume] = useState<number>(1);

  const { setRef, state, player, compile } = useCanvasPlayer({
    currentTime,
    volume,
    playbackRate: playbackSpeed || 1,
    artifacts
  });

  const handleOnProgressBarChange = (value: number) => {
    if (player) {
      setCurrentTime((value * player.duration) / 100);
    }
  };

  const handleOnRecompile = async () => {
    await compile();

    setShowRecompileAlert(false);
  };

  useEffect(() => {
    if (artifacts && artifacts.length > 0) {
      if (!compiledRef.current) {
        compiledRef.current = true;
        compile();
      } else {
        setShowRecompileAlert(true);
      }
    }
  }, [artifacts]);

  return (
    <>
      {showRecompileAlert && (
        <div className='bg-yellow-50 p-2 mb-4 text-sm border border-yellow-600 rounded'>
          Changes made. Please{' '}
          <button
            className={cn('focus:outline-none text-indigo-600 underline', { 'cursor-wait': state.isLoading })}
            onClick={handleOnRecompile}
            disabled={state.isLoading}
          >
            re-compile
          </button>{' '}
          to see the updated video.
        </div>
      )}

      <section ref={ref} className={cn('relative bg-black group', className)} {...rest}>
        <div ref={setRef} />
        {player && (
          <div
            className={cn(
              'group-hover:opacity-100 absolute inset-0 transition-opacity duration-150 bg-gray-700 bg-opacity-50',
              { 'opacity-0': !state.isLoading && !state.errors.length && artifacts.length }
            )}
          >
            {state.isLoading && (
              <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
                <Spinner className='w-7 h-7 mb-2' />
                <span className='text-xs'>Updating reel...</span>
              </div>
            )}
            {state.errors.length > 0 && (
              <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
                <ErrorSvg className='w-7 h-7 mb-2' />
                <span className='text-xs'>Something went wrong</span>
              </div>
            )}
            {isSuccess && artifacts.length === 0 && (
              <div className='flex flex-col items-center justify-center w-full h-full bg-gray-500'>
                <VideoSVG />
                <Text className='mt-6 text-sm text-center' color='white'>
                  Video highlight reel will appear once highlights are added.
                </Text>
              </div>
            )}
            {!state.isLoading && !state.errors.length && artifacts.length && (
              <>
                {player.layers.length > 0 && (
                  <div className='top-4 right-4 absolute text-sm text-white'>
                    {player.activeLayerIndex + 1} of {player.layers.length}: {player.activeLayer?.name}
                  </div>
                )}
                <Controls
                  isPlaying={state.isPlaying}
                  duration={state.duration}
                  currentTime={state.currentTime}
                  buffer={0}
                  volume={volume}
                  isFullscreen={false}
                  playbackSpeed={playbackSpeed || 1}
                  player={undefined}
                  show={true}
                  play={player.play.bind(player)}
                  pause={player.pause.bind(player)}
                  setVolume={setVolume}
                  setPlaybackSpeed={setPlaybackSpeed}
                  rewind={() => player.back(5000)}
                  fastForward={() => player.forward(5000)}
                  hasMiniPlayer={false}
                  handleOnProgressBarChange={handleOnProgressBarChange}
                  renderThumbnail={(value) => {
                    return <Thumbnail player={player} timestamp={(value * player.duration) / 100} />;
                  }}
                />
              </>
            )}
          </div>
        )}
      </section>
    </>
  );
});
