import * as React from 'react';

export const Available: React.VFC = () => (
  <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='20' cy='20' r='20' fill='url(#paint0_linear_604_40771)' />
    <g clipPath='url(#clip0_604_40771)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.321 17.8951C21.1654 18.0445 21.874 18.7083 22.0705 19.6211L22.1033 19.7731C22.8402 23.1962 27.7103 23.2271 28.4787 19.8007L28.5185 19.6235C28.7177 18.7352 29.3972 18.0843 30.2142 17.9112V27.0698C30.2142 27.4865 30.0486 27.8863 29.7539 28.1809C29.4593 28.4756 29.0595 28.6412 28.6428 28.6412H16.0714L9.78564 30.2126L11.3571 25.4983V12.9269C11.3571 12.5101 11.5226 12.1104 11.8173 11.8157C12.112 11.521 12.5117 11.3555 12.9285 11.3555H20.7848C20.6368 11.4191 20.4814 11.4661 20.321 11.4944C16.75 12.1261 16.7501 17.2634 20.321 17.8951Z'
        fill='url(#paint1_linear_604_40771)'
      />
      <path
        d='M30.2142 18.4269V27.0698C30.2142 27.4865 30.0486 27.8863 29.7539 28.1809C29.4593 28.4756 29.0595 28.6412 28.6428 28.6412H16.0714L9.78564 30.2126L11.3571 25.4983V12.9269C11.3571 12.5101 11.5226 12.1104 11.8173 11.8157C12.112 11.521 12.5117 11.3555 12.9285 11.3555H19.9999'
        fill='url(#paint2_linear_604_40771)'
      />
      <path
        d='M30.2142 18.4269V27.0698C30.2142 27.4865 30.0486 27.8863 29.7539 28.1809C29.4593 28.4756 29.0595 28.6412 28.6428 28.6412H16.0714L9.78564 30.2126L11.3571 25.4983V12.9269C11.3571 12.5101 11.5226 12.1104 11.8173 11.8157C12.112 11.521 12.5117 11.3555 12.9285 11.3555H19.9999'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.7998 15.1883C20.2568 15.0922 20.2568 14.2995 20.7998 14.2035C22.7671 13.8555 24.3317 12.3318 24.7588 10.3483L24.7915 10.1963C24.909 9.65056 25.6731 9.64717 25.7953 10.1918L25.8351 10.369C26.2777 12.3431 27.8429 13.8539 29.8047 14.201C30.3504 14.2975 30.3504 15.0942 29.8047 15.1908C27.8429 15.5378 26.2777 17.0486 25.8351 19.0227L25.7953 19.1999C25.6731 19.7446 24.909 19.7412 24.7915 19.1955L24.7588 19.0434C24.3317 17.0599 22.7671 15.5363 20.7998 15.1883Z'
        fill='url(#paint3_linear_604_40771)'
      />
      <path
        d='M20.7998 15.1883C20.2568 15.0922 20.2568 14.2995 20.7998 14.2035C22.7671 13.8555 24.3317 12.3318 24.7588 10.3483L24.7915 10.1963C24.909 9.65056 25.6731 9.64717 25.7953 10.1918L25.8351 10.369C26.2777 12.3431 27.8429 13.8539 29.8047 14.201C30.3504 14.2975 30.3504 15.0942 29.8047 15.1908C27.8429 15.5378 26.2777 17.0486 25.8351 19.0227L25.7953 19.1999C25.6731 19.7446 24.909 19.7412 24.7915 19.1955L24.7588 19.0434C24.3317 17.0599 22.7671 15.5363 20.7998 15.1883Z'
        fill='url(#paint4_linear_604_40771)'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_604_40771'
        x1='9.16667'
        y1='1.66667'
        x2='31.6667'
        y2='36.6667'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#12102F' />
        <stop offset='0.348343' stopColor='#EB00FF' />
        <stop offset='0.712271' stopColor='#FF7A00' />
        <stop offset='0.99' stopColor='#16BDCA' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_604_40771'
        x1='14.4672'
        y1='12.1412'
        x2='24.7011'
        y2='29.3872'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#12102F' />
        <stop offset='0.348343' stopColor='#EB00FF' />
        <stop offset='0.712271' stopColor='#FF7A00' />
        <stop offset='0.99' stopColor='#16BDCA' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_604_40771'
        x1='14.4672'
        y1='12.1412'
        x2='24.7011'
        y2='29.3872'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#12102F' />
        <stop offset='0.348343' stopColor='#EB00FF' />
        <stop offset='0.712271' stopColor='#FF7A00' />
        <stop offset='0.99' stopColor='#16BDCA' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_604_40771'
        x1='22.6433'
        y1='10.1944'
        x2='28.1679'
        y2='18.7881'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#12102F' />
        <stop offset='0.348343' stopColor='#EB00FF' />
        <stop offset='0.712271' stopColor='#FF7A00' />
        <stop offset='0.99' stopColor='#16BDCA' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_604_40771'
        x1='22.6433'
        y1='10.1944'
        x2='28.1679'
        y2='18.7881'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#12102F' />
        <stop offset='0.348343' stopColor='#EB00FF' />
        <stop offset='0.712271' stopColor='#FF7A00' />
        <stop offset='0.99' stopColor='#16BDCA' />
      </linearGradient>
      <clipPath id='clip0_604_40771'>
        <rect width='22' height='22' fill='white' transform='translate(9 9)' />
      </clipPath>
    </defs>
  </svg>
);
