import React, { SVGProps } from 'react';

export const ScreenerSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1093_4151)'>
      <path
        d='M3.9375 3.28125C3.9375 3.06493 4.00165 2.85346 4.12183 2.6736C4.24201 2.49373 4.41284 2.35354 4.61269 2.27076C4.81255 2.18797 5.03246 2.16631 5.24463 2.20852C5.4568 2.25072 5.65168 2.35489 5.80465 2.50786C5.95758 2.66082 6.06177 2.8557 6.104 3.06787C6.14617 3.28004 6.12453 3.49995 6.04176 3.69981C5.95898 3.89967 5.81877 4.07049 5.6389 4.19067C5.45904 4.31085 5.24757 4.375 5.03125 4.375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.03125 6.125C4.91044 6.125 4.8125 6.02706 4.8125 5.90625C4.8125 5.78544 4.91044 5.6875 5.03125 5.6875'
        stroke='currentColor'
        strokeWidth='0.875'
      />
      <path
        d='M5.03125 6.125C5.15206 6.125 5.25 6.02706 5.25 5.90625C5.25 5.78544 5.15206 5.6875 5.03125 5.6875'
        stroke='currentColor'
        strokeWidth='0.875'
      />
      <path
        d='M10.0625 1.3125V3.9375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 0.4375C9.41955 0.4375 9.64215 0.529687 9.80624 0.693782C9.97033 0.857873 10.0625 1.08044 10.0625 1.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.3125 0.4375H9.1875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 1.3125C0.4375 1.08044 0.529687 0.857873 0.693782 0.693782C0.857873 0.529687 1.08044 0.4375 1.3125 0.4375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 7.4375V1.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.3125 8.3125C1.08044 8.3125 0.857873 8.22033 0.693782 8.05624C0.529687 7.89215 0.4375 7.66955 0.4375 7.4375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0625 8.3125H1.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0625 10.9375V8.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.8125 9.625L3.0625 10.9375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5625 10.9375H11.8125V13.5625L9.1875 10.9375H6.5625V5.6875H13.5625V10.9375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0625 7.4375V9.1875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1093_4151'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
