import { useCallback, useMemo } from 'react';

import { EXTERNAL_ATTRS } from '@components/config';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { COLUMN_LABELS } from '../constants';

type UseColumnLabelsReturnType = (key: string) => string;

interface UseColumnLabelsArgs {
  study?: Study;
}

export const useColumnLabels = ({ study }: UseColumnLabelsArgs): UseColumnLabelsReturnType => {
  const { candidateAttrs } = useCandidateAttrs();

  const attributes = useMemo(() => candidateAttrs.concat(EXTERNAL_ATTRS), [candidateAttrs]);

  const preScreenerFieldLabels = useMemo<Record<string, string> | undefined>(
    () =>
      study?.pre_screener?.fields.reduce(
        (acc, { id, label }) => ({
          ...acc,
          [`preScreenerField.${id}`]: label
        }),
        {}
      ),
    [study]
  );

  const surveyScreenerFieldLabels = useMemo<Record<string, string> | undefined>(
    () =>
      study?.survey_screener?.fields.reduce(
        (acc, { id, label }) => ({
          ...acc,
          [`surveyScreenerField.${id}`]: label
        }),
        {}
      ),
    [study]
  );

  const candidateAttrsLabels = useMemo<Record<string, string>>(
    () => attributes.reduce((acc, { name, label }) => ({ ...acc, [`extra.${name}`]: label }), {}),
    [attributes]
  );

  const getColumnLabel = useCallback<UseColumnLabelsReturnType>(
    (key: string) => {
      return (
        { ...COLUMN_LABELS, ...candidateAttrsLabels, ...preScreenerFieldLabels, ...surveyScreenerFieldLabels }[key] ??
        key
      );
    },
    [candidateAttrsLabels, preScreenerFieldLabels, surveyScreenerFieldLabels]
  );

  return getColumnLabel;
};
