import { useState } from 'react';

interface TrackDuration {
  duration: number | null;
  isTracking: boolean;
  startAt?: Date;
  endAt?: Date;
  start: () => void;
  stop: () => Partial<{ duration: number; endAt: Date }>;
}

type UseTaskDuration = () => TrackDuration;

export const useTaskTimings: UseTaskDuration = () => {
  const [startAt, setStartAt] = useState<Date>(new Date());
  const [endAt, setEndAt] = useState<Date>(new Date());
  const [duration, setDuration] = useState<number>(0);

  const isDevelopment = process.env.NODE_ENV === 'development';

  const start = () => {
    const startAt = new Date();

    if (isDevelopment) {
      console.info(`Task started at ${startAt}`);
    }

    setStartAt(startAt);
    setDuration(0);
  };

  const stop = () => {
    if (startAt) {
      const endAt = new Date();
      const duration = endAt.getTime() - startAt.getTime();

      if (isDevelopment) {
        console.info(`Task ended at ${endAt} with duration of ${duration}`);
      }

      setEndAt(endAt);
      setDuration(duration);

      return { duration, endAt };
    }

    return {};
  };

  return { duration, isTracking: !!startAt, startAt, endAt, start, stop };
};
