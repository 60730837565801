import { getHost } from '@components/utils';

export const getCtaUrl = (
  workspace: string,
  study: Study,
  message?: StudyMessage,
  accountPublicDomain?: string
): string | null => {
  const event = message?.event || '';
  const host = accountPublicDomain || getHost();

  if (event === 'screener') {
    if (study.screener_invite_cta === 'landing_page') {
      return `${host}/${workspace}/${study.slug}`;
    } else {
      return `${host}/${workspace}/${study.slug}/apply`;
    }
  }
  if (['invite', 'invited_reminder', 'reschedule_request'].includes(event)) {
    return `${host}/${workspace}/${study.slug}/direct`;
  }
  return null;
};
