import pluralize from 'pluralize';
import * as React from 'react';

import { Alert, AlertHeading, AlertMessage } from '@components/common';

import { AlertProps } from '../types';
import { CalendarSVG } from './svgs';

export const GoodCal: React.FC<AlertProps> = ({ slots }) => {
  const { remaining } = slots;
  return (
    <Alert className='my-4' Icon={CalendarSVG} type='success'>
      <>
        <AlertHeading>Calendar is looking good</AlertHeading>
        <AlertMessage>
          <>
            You have more slots than your <b>{remaining ? remaining : ''}</b> required.
          </>
        </AlertMessage>
      </>
    </Alert>
  );
};
