import * as React from 'react';
import { useParams } from 'react-router-dom';

import { Provider as CollectionView } from '@stores/view';

import CandidatesListPage from '../CandidatesIndex/CandidatesListPage';
import { DEFAULT_CANDIDATES_VIEW } from '../CandidatesIndex';

export const TeamCandidatesIndex: React.FC = () => {
  const { teamId: teamIdStr } = useParams<{ teamId: string }>();
  const teamId = teamIdStr ? parseInt(teamIdStr) : null;

  return (
    <CollectionView scope='candidates' page={`team-${teamId}`} defaultable default={DEFAULT_CANDIDATES_VIEW}>
      <CandidatesListPage key={teamId} teamId={teamId} />
    </CollectionView>
  );
};
