import { compact } from '@components/utils';

export const getEvents = (study: Study): StudyMessageEvent[] => {
  return compact([
    study.has_screener && 'screener',
    'invite',
    'invited_reminder',
    study.style === 'video_call' && 'booked',
    study.style === 'video_call' && 'booked_reminder',
    study.style === 'video_call' && 'cancel_interview',
    ['survey', 'online_task'].includes(study.style) && 'started_reminder',
    study.style === 'panel' && 'welcome',
    study.style === 'video_call' && 'reschedule_request',
    study.style === 'online_task' && 'cancel_task',
    'thanks',
    'ad_hoc'
  ]);
};
