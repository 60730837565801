import { api } from 'api/reduxApi';
import { DropdownItem } from 'components/common/DropdownCombobox';
import { useToaster } from 'components/stores/toaster';
import * as React from 'react';
import { useMemo, useState } from 'react';
// hooks
import { useForm } from 'react-hook-form';

// components
import { Button, Loading, SlideOut } from '@components/common';
import { AddAttribute } from '@components/shared/AddAttribute';
import { STYLES } from '@components/shared/AddCandidateSlideOut';
import { AttributesTable } from '@components/shared/AddCandidateSlideOut/components/AttributesTable';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';

import { FilterQuery } from './types';

interface Props {
  ids?: number[];
  onClose: () => void;
  onFinished: (backgroundTask: BackgroundTask) => void;
  allSelected?: boolean;
  selectedCount?: number;
  query?: FilterQuery;
}

export const BulkEditSlideOut: React.FC<Props> = ({ onClose, ids, onFinished, allSelected, selectedCount, query }) => {
  const [loading, setLoading] = useState(false);
  const [newAttr, setNewAttr] = useState<Attr_[]>([]);
  const [bulkCandidateEdit] = api.useBulkCandidateEditMutation();

  const { create } = useCandidateAttrs();
  const showToast = useToaster();

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const resp = await bulkCandidateEdit({
        customer: data,
        ...(allSelected ? { query } : { ids })
      }).unwrap();

      if (resp) {
        onFinished(resp);
        onClose();
        showToast({
          heading: 'Profile updated!',
          icon: 'success',
          text: 'The profile was updated.'
        });
      }
    } catch {
      showToast({
        heading: 'Failed to update the profile!',
        icon: 'error',
        text: 'Something went wrong updating the profile. Please try again later.'
      });
    }

    setLoading(false);
  };

  const { handleSubmit, control, getValues } = useForm();

  const addNewAttrToList = (attr: Attr_): void => {
    setNewAttr([...newAttr, attr]);
  };

  const { candidateAttrs } = useCandidateAttrs();
  const options: DropdownItem[] = useMemo(
    () => candidateAttrs.map((attr) => ({ value: attr.name, label: attr.label, data: attr })),
    [candidateAttrs]
  );

  return (
    <SlideOut
      size='2xl'
      title='Add or update attributes'
      onClose={onClose}
      renderFooter={() => (
        <div className=' flex flex-row justify-end w-full'>
          <div className='space-x-4'>
            <Button onClick={onClose}>Cancel</Button>
            <Button disabled={!newAttr.length} primary type='submit' form='form2' onClick={handleSubmit(onSubmit)}>
              Update
            </Button>
          </div>
        </div>
      )}
    >
      {loading && <Loading absolute />}
      <div className='relative p-6'>
        <div className='mb-6'>
          {ids && (
            <p>
              We'll apply the update to all the {selectedCount} selected candidates and will override any existing data.
            </p>
          )}
        </div>

        <form id='form2'>
          <table className='w-full'>
            <tbody>
              <AttributesTable
                newAttr={newAttr}
                control={control}
                getValues={getValues}
                styles={STYLES['addCandidate']}
              />
            </tbody>
          </table>
        </form>

        <AddAttribute
          createAttr={create}
          addNewAttrToList={addNewAttrToList}
          showInput
          visibleAttrs={newAttr}
          options={options}
        />
      </div>
    </SlideOut>
  );
};
