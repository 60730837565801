import { useReducer } from 'react';

enum Actions {
  SET_IS_EXPANDED = 'SET_IS_EXPANDED',
  SET_IS_DRAGGING = 'SET_IS_DRAGGING',
  SET_TRANSFORM_Y = 'SET_TRANSFORM_Y',
  SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT',
  SET_CONTENT_HEIGHT = 'SET_CONTENT_HEIGHT'
}

type Action =
  | GenericAction<Actions.SET_IS_EXPANDED, boolean>
  | GenericAction<Actions.SET_IS_DRAGGING, boolean>
  | GenericAction<Actions.SET_TRANSFORM_Y, number>
  | GenericAction<Actions.SET_HEADER_HEIGHT, number>
  | GenericAction<Actions.SET_CONTENT_HEIGHT, number>;

type State = {
  isExpanded: boolean;
  isDragging: boolean;
  transformY: number;
  headerHeight: number | null;
  contentHeight: number | null;
};

export type Drawer = {
  state: State;
  setIsExpanded: (isExpanded: boolean) => void;
  setIsDragging: (isDragging: boolean) => void;
  setTransformY: (transformY: number) => void;
  setHeaderHeight: (headerHeight: number) => void;
  setContentHeight: (contentHeight: number) => void;
};

export const INITIAL_STATE: State = {
  isExpanded: false,
  isDragging: false,
  transformY: 0,
  headerHeight: null,
  contentHeight: null
};

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Actions.SET_IS_EXPANDED:
      return { ...state, isExpanded: action.payload };
    case Actions.SET_IS_DRAGGING:
      return { ...state, isDragging: action.payload };
    case Actions.SET_TRANSFORM_Y:
      return { ...state, transformY: action.payload };
    case Actions.SET_HEADER_HEIGHT:
      return { ...state, headerHeight: action.payload };
    case Actions.SET_CONTENT_HEIGHT:
      return { ...state, contentHeight: action.payload };
    default:
      return state;
  }
};

export const useDrawer = (): Drawer => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const setIsExpanded = (isExpanded: boolean) => {
    dispatch({ type: Actions.SET_IS_EXPANDED, payload: isExpanded });
  };

  const setIsDragging = (isDragging: boolean) => {
    dispatch({ type: Actions.SET_IS_DRAGGING, payload: isDragging });
  };

  const setTransformY = (transformY: number) => {
    dispatch({ type: Actions.SET_TRANSFORM_Y, payload: transformY });
  };

  const setHeaderHeight = (headerHeight: number) => {
    dispatch({ type: Actions.SET_HEADER_HEIGHT, payload: headerHeight });
  };

  const setContentHeight = (contentHeight: number) => {
    dispatch({ type: Actions.SET_CONTENT_HEIGHT, payload: contentHeight });
  };

  return { state, setIsExpanded, setIsDragging, setTransformY, setHeaderHeight, setContentHeight };
};
