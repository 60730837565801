export const getErrorMessage = (recording: Recording): string => {
  switch (recording.error_code) {
    case 'invalid_zoom':
      return 'The Zoom account this cloud recording belongs to needs to be reconnected';
    case 'timeout':
      return `We weren’t able to find a cloud recording for this Zoom meeting (ID: ${
        recording.zoom_meeting?.meeting_id || 'unknown'
      }).`;
    case 'not_ready':
      return 'We couldn’t download this cloud recording from Zoom.';
    case 'mux_error':
      return 'We encountered an error while processing this video.';
    default:
      return 'Please try uploading your video again.';
  }
};
