import { Extension, JSONContent } from '@tiptap/react';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    loaddoc: {
      loadContent: (content: JSONContent) => ReturnType;
    };
  }
}

export const LoadDoc = Extension.create({
  name: 'loaddoc',

  addCommands() {
    return {
      loadContent:
        (content) =>
        ({ tr, dispatch, commands }) => {
          commands.setContent(content);

          if (dispatch) {
            tr.setMeta('addToHistory', false);
          }

          return true;
        }
    };
  }
});
