export const objectAssign = <D extends Record<string, any>>(obj: D, path: string, value: any) => {
  const paths = path.split('.');
  const inputObj = obj === null ? {} : { ...obj };

  if (paths.length === 0) {
    return inputObj;
  }

  if (paths.length === 1) {
    const path = paths[0];
    inputObj[path] = value;
    return { ...inputObj, [path]: value };
  }

  const [key, ...rest] = paths;
  const currentNode = inputObj[key];

  const childNode = objectAssign(currentNode, rest.join('.'), value);

  return { ...inputObj, [key]: childNode };
};
