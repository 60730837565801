import { humanize } from 'components/utils';
import * as React from 'react';
import tinytime from 'tinytime';

import { CopyText } from '@components/shared/CopyText';

const dateTemplate = tinytime('{dddd}, {MM} {DD}, {YYYY}');
const timeTemplate = tinytime('{h}:{mm} {a}');

interface Props {
  participation: Participation;
}
export const InterviewDetails: React.FC<Props> = ({
  participation: { duration, interview_at, interview_path, incentive_in_cents, join_url, event_details }
}) => (
  <>
    <div className='divide-y-gray-200 divide-y'>
      {event_details?.location?.type && (
        <div className='py-4'>
          <div className='inline-block w-40 font-medium'>Type</div>
          <div className='inline-block'>{humanize(event_details.location.type)}</div>
        </div>
      )}
      {interview_at && (
        <>
          <div className='py-4'>
            <div className='inline-block w-40 font-medium'>Date</div>
            <div className='inline-block'>{dateTemplate.render(new Date(interview_at))}</div>
          </div>
          <div className='py-4'>
            <div className='inline-block w-40 font-medium'>Time</div>
            <div className='inline-block'>{timeTemplate.render(new Date(interview_at))}</div>
          </div>
        </>
      )}
      <div className='py-4'>
        <div className='inline-block w-40 font-medium'>Duration</div>
        <div className='inline-block'>{duration}</div>
      </div>
      {incentive_in_cents && incentive_in_cents > 0 && (
        <div className='py-4'>
          <div className='inline-block w-40 font-medium'>Incentive</div>
          <div className='inline-block'>${(incentive_in_cents / 100).toLocaleString()}</div>
        </div>
      )}
      <div className='py-4'>
        <div className='inline-block w-40 font-medium'>Join URL</div>
        <div className='inline-block'>
          {join_url && (
            <a className='underline truncate' href={join_url} target='_blank'>
              {join_url}
            </a>
          )}
        </div>
      </div>
    </div>
  </>
);
