import * as React from 'react';

import { ProgressBar } from '@components/common';
import { pct } from 'components/utils';

interface Hook {
  show?: boolean;
  text?: string;
  renderProgressBar?: () => React.ReactNode;
}
export const getStudyProgress = (study: Study): Hook => {
  const isBookable = ['video_call'].includes(study.style);
  const isStartable = ['survey', 'online_task', 'unmoderated_test'].includes(study.style);
  const total = study.maximum_slots;
  const completed = study.completed_participations_count;
  const inProgress = isStartable ? study.started_participations_count : study.booked_participations_count;

  if (!(isBookable || isStartable) || study.participant_limit === null || !['active', 'closed'].includes(study.state)) {
    return { show: false };
  }

  const tooltipText = study.style === 'video_call' ? 'Scheduled' : 'In Progress';

  const bars = [
    { color: 'indigo-600', progress: pct(completed || 0, total || 0), tooltip: 'Completed' },
    { color: 'indigo-400', progress: pct(inProgress || 0, total || 0), tooltip: tooltipText }
  ];

  return {
    show: true,
    text: `${completed}/${total}`,
    renderProgressBar: () => <ProgressBar size='sm' bars={bars} />
  };
};
