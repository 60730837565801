import React, { ElementType, ReactElement, Ref, ElementRef, PropsWithChildren, ComponentPropsWithoutRef } from 'react';

type AsProp<E extends React.ElementType> = {
  as?: E;
  innerRef?: Ref<ElementRef<E>>;
};

type PolymorphicProps<E extends ElementType> = PropsWithChildren<ComponentPropsWithoutRef<E> & AsProp<E>>;

type ComponentProps<P, E extends ElementType> = PolymorphicProps<E> & P;

export const createPolymorphicComponent = <P, E extends ElementType = 'div'>(
  render: (props: ComponentProps<P, E>) => ReactElement
) => {
  return <C extends ElementType = E>(props: ComponentProps<P, C>) => {
    const { as: Component = 'div', ...rest } = props;
    return render({ as: Component, ...rest } as ComponentProps<P, E>);
  };
};
