function screenerItemAction(study: Study): string {
  if (study.style === 'panel') {
    return study.has_screener ? 'submit a screener or join the panel' : 'join the panel';
  } else if (study.style === 'survey' && study.on_gq) {
    return study.has_screener ? 'submit a screener or complete the survey' : 'complete the survey';
  } else if (study.style === 'unmoderated_test') {
    return study.has_screener ? 'submit a screener or complete the test' : 'complete the test';
  } else {
    return 'submit a screener';
  }
}
export const SETTING_LABELS: { [key in SettingsKey]?: string | ((study: Study) => string) } = {
  'screener_response.create': (study) => `Participants ${screenerItemAction(study)}`,
  'participation.booked': 'Participants schedule a call',
  'participation.upcoming_reminder': '30 minutes before an upcoming interview',
  'incentive.redeemed': 'Participants redeem their incentive',
  'project.sent_requests': 'Account members or I send new invitations',
  // 'screener.sent_requests': 'Account members or I send new screeners',
  'project.funded': 'Account members or I fund incentives',
  'project.closed': 'Account members or I close the study',
  'transcript.ready': 'Recordings and transcripts are ready',
  invited_reminder: 'Send invitation reminders to candidates who do not respond after 24 hours.',
  started_reminder: 'Send candidates a reminder to complete the task after 24 hours',
  booked_reminder: 'Send interview reminders to candidates 24 hours before their scheduled interview.',
  auto_complete: (study) =>
    `Mark participations as completed ${
      study.has_incentive ? 'and pay incentives' : ''
    } 24 hours after their interview`,
  has_team_event: 'Create an internal calendar event for new bookings'
};

export const EMAIL_KEYS: SettingsKey[] = [
  'screener_response.create',
  'project.sent_requests',
  'participation.booked',
  'participation.upcoming_reminder',
  'project.funded',
  'incentive.redeemed',
  'project.closed',
  'transcript.ready'
];
export const NON_OWNER_EMAIL_KEYS: SettingsKey[] = [
  'participation.booked',
  'participation.upcoming_reminder',
  'transcript.ready'
];
export const SLACK_KEYS: SettingsKey[] = [
  'screener_response.create',
  'participation.booked',
  'participation.upcoming_reminder',
  'project.funded',
  'project.closed',
  'transcript.ready'
];
