import * as React from 'react';
import { useEffect, useState } from 'react';

import { Avatar, Checkbox, InfoLabel, Text } from '@components/common';
import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { ExternalLinkSVG, PlaySVG } from '@components/svgs';
import { track } from '@components/tracking';
import { getHighlightDisplayTitle } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';
import Tippy from '@tippyjs/react';
import * as toasts from '../toasts';
import { useMiniPlayerContext } from './MiniPlayer';
import { formatDuration } from '@components/shared/Artifact/components/utils';
import { format as timeagoFormat } from 'timeago.js';
import { HighlightArtifact } from '@components/RepositoryApp/components/PreviewContentSlideout';
import { EditableTitle } from '@components/common/EditableTitle';
import { api } from '@api/reduxApi';

type Props = {
  isSelected: boolean;
  onToggleSelected: (e) => void;
  highlight: Highlight;
  setPreviewSlideout: (v: HighlightArtifact) => void;
};

export const HighlightCard: React.FC<Props> = ({ setPreviewSlideout, highlight, isSelected, onToggleSelected }) => {
  const initialTitle = getHighlightDisplayTitle(highlight);

  const [title, setTitle] = useState(highlight.title || highlight.clip?.title || 'Untitled highlight');

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  const canEdit = usePermission('canCreate')();

  const [updateHighlight, { isError }] = api.useUpdateHighlightMutation();

  const { getUserById } = useAccount();
  const creator = getUserById(highlight.creator_id);

  const { renderTagsList } = useRenderTagsList();

  const showToast = useToaster();

  const miniPlayer = useMiniPlayerContext();

  const playbackId = highlight.mux_video?.playback_id;

  const totalViews = highlight.clip?.views.map((view) => view.count).reduce((a, b) => a + b, 0);

  const duration = Math.round((highlight.clip?.to || 0) / 1000 - (highlight.clip?.from || 0) / 1000);

  const artifact: Partial<HighlightArtifact> = {
    objectID: highlight.objectID,
    owner_id: highlight.creator_id,
    title: initialTitle,
    subtitle: highlight.description || '',
    body: highlight.text,
    kind: 'Highlight',
    model_id: highlight.uuid,
    created_at: highlight.created_at,
    updated_at: highlight.updated_at,
    study_ids: [highlight.study?.id] as number[],
    href: `/h/${highlight.uuid}`,
    from: highlight.clip?.from,
    to: highlight.clip?.from,
    duration,
    study_title: highlight.study?.title || null,
    stream_url: highlight.clip?.stream_url,
    mux_status: highlight.clip?.mux_video?.status,
    documentId: highlight.document_id,
    highlightId: highlight.id
  };

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdateClip());
    }
  }, [isError]);

  return (
    <div
      onClick={() => setPreviewSlideout(artifact as HighlightArtifact)}
      aria-label='Card wrapper'
      className='overflow-hidden bg-white border border-gray-200 rounded-md'
    >
      <div className='bg-gray-50 flex items-center p-4 pb-3 space-x-4'>
        <div onClick={(e) => e.stopPropagation()}>
          <Tippy content='Select'>
            <Checkbox name={`highlight-${highlight.id}`} selected={isSelected} onChange={onToggleSelected} />
          </Tippy>
        </div>
        <div className='flex-1' />
        {playbackId && (
          <Tippy content='Play video clip'>
            <button
              className='hover:text-indigo-600 text-gray-700'
              onClick={(e) => {
                e.stopPropagation();
                miniPlayer.setPlaybackId(playbackId);
              }}
            >
              <PlaySVG />
            </button>
          </Tippy>
        )}
        <Tippy content='Open in new tab'>
          <a
            onClick={(e) => e.stopPropagation()}
            className='hover:text-indigo-600 text-gray-700'
            href={`/h/${highlight.uuid}`}
            title={title}
            target='_blank'
          >
            <ExternalLinkSVG />
          </a>
        </Tippy>
      </div>
      <section className='bg-gray-50 relative flex items-center w-full h-32 px-4 pt-2 overflow-hidden whitespace-pre-wrap border-b border-gray-200'>
        <span className='line-clamp-5 inline'>“{highlight.text}”</span>
      </section>
      <section className='flex flex-col flex-1 px-4 py-2'>
        <div className='h-20'>
          <EditableTitle
            size='sm'
            lineClamp='3'
            readOnly={!canEdit}
            initialValue={title || 'Untitled'}
            onBlur={(value) => {
              if (value !== initialTitle) {
                updateHighlight({ uuid: highlight.uuid, title: value });
                track('board_updated_highlight_title');
              }
            }}
          />
        </div>
        <div className='pt-1 flex flex-wrap items-center mb-1.5'>
          {creator && (
            <div className='flex items-center py-px mr-3 space-x-1'>
              <Avatar user={creator} size='sm' />
              <Text color='gray-500' h='200'>
                {creator?.name}
              </Text>
            </div>
          )}
          {highlight.study?.title && (
            <InfoLabel h='200' className='mr-3 text-gray-500' icon='checklist' text={highlight.study?.title} />
          )}
          {highlight.clip?.created_at && (
            <InfoLabel
              h='200'
              className='mr-3 text-gray-500'
              icon='changedAt'
              text={timeagoFormat(highlight.clip?.created_at)}
            />
          )}
          <InfoLabel h='200' className='mr-3 text-gray-500' icon='clock' text={formatDuration(Math.round(duration))} />
          {typeof totalViews === 'number' && totalViews > 0 && (
            <InfoLabel className='mr-3 text-gray-500' icon='eye' text={totalViews.toLocaleString()} />
          )}
        </div>
      </section>
      <div className='p-4 pt-0'>{renderTagsList(highlight.tag_ids, { max: 2 })}</div>
    </div>
  );
};
