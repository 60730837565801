import React, { SVGProps } from 'react';

export const VideoCameraSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.66699 6.66669H3.33366C3.15685 6.66669 2.98728 6.59645 2.86225 6.47143C2.73723 6.3464 2.66699 6.17683 2.66699 6.00002C2.66699 5.82321 2.73723 5.65364 2.86225 5.52862C2.98728 5.4036 3.15685 5.33336 3.33366 5.33336H4.66699C4.8438 5.33336 5.01337 5.4036 5.13839 5.52862C5.26341 5.65364 5.33365 5.82321 5.33365 6.00002C5.33365 6.17683 5.26341 6.3464 5.13839 6.47143C5.01337 6.59645 4.8438 6.66669 4.66699 6.66669Z'
      fill='currentColor'
    />
    <path
      d='M14.7136 3.91266C14.5263 3.81259 14.3154 3.76515 14.1033 3.77536C13.8912 3.78557 13.6858 3.85306 13.509 3.97066L11.3336 5.42066V4.67933C11.3331 4.2341 11.156 3.80726 10.8412 3.49244C10.5264 3.17762 10.0995 3.00052 9.65429 2.99999H2.3463C1.90107 3.00052 1.47423 3.17762 1.15941 3.49244C0.84459 3.80726 0.667491 4.2341 0.666962 4.67933V11.3207C0.667491 11.7659 0.84459 12.1927 1.15941 12.5075C1.47423 12.8224 1.90107 12.9995 2.3463 13H9.65429C10.0995 12.9995 10.5264 12.8224 10.8412 12.5075C11.156 12.1927 11.3331 11.7659 11.3336 11.3207V10.5793L13.509 12.0293C13.6858 12.147 13.8912 12.2146 14.1033 12.2248C14.3155 12.235 14.5264 12.1874 14.7136 12.0872C14.9009 11.987 15.0575 11.8378 15.1666 11.6556C15.2758 11.4734 15.3335 11.2651 15.3336 11.0527V4.94733C15.3335 4.73492 15.2758 4.52653 15.1666 4.34434C15.0574 4.16214 14.9009 4.01295 14.7136 3.91266ZM10.0003 11.3207C10.0003 11.4124 9.96384 11.5004 9.89895 11.5653C9.83407 11.6302 9.74606 11.6667 9.65429 11.6667H2.3463C2.25453 11.6667 2.16652 11.6302 2.10164 11.5653C2.03675 11.5004 2.0003 11.4124 2.0003 11.3207V4.67933C2.0003 4.58756 2.03675 4.49955 2.10164 4.43467C2.16652 4.36978 2.25453 4.33333 2.3463 4.33333H9.65429C9.74606 4.33333 9.83407 4.36978 9.89895 4.43467C9.96384 4.49955 10.0003 4.58756 10.0003 4.67933V11.3207ZM14.0003 10.7547L11.3336 8.97666V7.02333L14.0003 5.24533V10.7547Z'
      fill='currentColor'
    />
  </svg>
);
