import classNames from 'classnames';
import * as React from 'react';

import Tippy from '@tippyjs/react';

const SIZES = {
  xs: 'h-1 rounded-sm',
  sm: 'h-2 rounded-sm',
  md: 'h-4 rounded-md'
};
const STYLES = {
  default: 'bg-gray-200',
  withBorder: 'bg-white border border-gray-200'
};
interface Props {
  showLabel?: boolean;
  progress?: number;
  endless?: boolean;
  bars?: { progress: number; color: string; tooltip: string }[];
  size?: 'xs' | 'sm' | 'md';
  style?: 'default' | 'withBorder';
}

export const ProgressBar: React.FC<Props> = ({
  showLabel,
  progress,
  endless,
  bars,
  size = 'md',
  style = 'default'
}) => (
  <div className='relative'>
    {showLabel && (
      <span className='block mb-2 text-sm text-gray-700'>{progress != null && progress.toFixed(0)}% uploaded</span>
    )}
    <div className={classNames('flex overflow-hidden', STYLES[style], SIZES[size], { 'animate-pulse': endless })}>
      {!bars && (
        <div
          style={{ width: `${endless ? 100 : progress}%`, transition: 'width 200ms' }}
          className='whitespace-nowrap flex flex-col justify-center text-center text-white bg-indigo-600 shadow-none'
        ></div>
      )}
      {bars &&
        bars.map((bar, i) => (
          <Tippy key={i} content={bar.tooltip}>
            <div
              style={{ width: `${bar.progress}%`, transition: 'width 200ms' }}
              className={
                'whitespace-nowrap flex flex-col justify-center text-center text-white shadow-none ' + `bg-${bar.color}`
              }
            ></div>
          </Tippy>
        ))}
    </div>
  </div>
);
