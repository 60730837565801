import React from 'react';

export const UploadBoxSVG = () => {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1315_7051)'>
        <path
          d='M40.0066 65.0034V35.0034'
          stroke='#374151'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.0066 45.0034L40.0066 35.0034L50.0066 45.0034'
          stroke='#374151'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M72.5066 22.5034H7.50659C4.74517 22.5034 2.50659 24.742 2.50659 27.5034V72.5034C2.50659 75.2648 4.74517 77.5034 7.50659 77.5034H72.5066C75.268 77.5034 77.5066 75.2648 77.5066 72.5034V27.5034C77.5066 24.742 75.268 22.5034 72.5066 22.5034Z'
          stroke='#374151'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.17993 25.0001L11.1799 5.47009C11.5737 4.58558 12.2157 3.83441 13.0281 3.30778C13.8406 2.78115 14.7884 2.50173 15.7566 2.50343H64.2566C65.224 2.5033 66.1707 2.78383 66.9818 3.311C67.793 3.83817 68.4338 4.58933 68.8266 5.47343L76.6366 24.6901'
          stroke='#374151'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M40.0066 22.5034V2.50342'
          stroke='#374151'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1315_7051'>
          <rect width='80' height='80' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
