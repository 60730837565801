import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Text } from '@components/common';
import { useToaster } from '@stores/toaster';
import { usePermission } from '@hooks/usePermission';
import { useTeams } from '@hooks/useTeams';

import * as toasts from '../../toasts';
import { CreateTeamModal } from '../../modals/CreateTeamModal';
import { ZDS } from './ZDS';
import { TeamsTable } from './TeamsTable';
import { Skeleton } from './Skeleton';

export const TeamsIndex: React.FC = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const { teams = [], isLoading, isError } = useTeams();

  const canManageTeams = usePermission('manageTeams')();

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGetTeams());
    }
  }, [isError]);

  return (
    <>
      <div className='flex items-center mb-4 monitor:mb-6'>
        <div className='flex-1'>
          <Text as='h1' h='700' bold>
            Teams
          </Text>
        </div>
        <div>
          {canManageTeams && (
            <Button medium primary onClick={() => setCreateModalOpen(true)}>
              Create team
            </Button>
          )}
        </div>
      </div>

      <CreateTeamModal trackKey='table' open={createModalOpen} onClose={() => setCreateModalOpen(false)} />

      <div className='p-6 bg-white border border-gray-200 rounded-md'>
        {(() => {
          if (isLoading) {
            return <Skeleton />;
          }
          if (teams.length === 0) {
            return <ZDS />;
          }
          return <TeamsTable teams={teams} />;
        })()}
      </div>
    </>
  );
};
