import { useContext, useMemo } from 'react';

import { store } from './context';
import { getCalendarStates, GetCalendarStateSet } from './getters';

export const useCalendarStates = (ids: UserAndCalendar[], weekIdx?: number): GetCalendarStateSet => {
  const { state } = useContext(store);

  return useMemo(() => getCalendarStates(state, ids, weekIdx), [state, ids, weekIdx]);
};
