import * as React from 'react';

export const upgrade = (props) => (
  <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.125 19.3672C4.125 19.823 4.30608 20.2602 4.62841 20.5825C4.95074 20.9049 5.38791 21.0859 5.84375 21.0859C6.29959 21.0859 6.73676 20.9049 7.05909 20.5825C7.38142 20.2602 7.5625 19.823 7.5625 19.3672C7.5625 18.9113 7.38142 18.4742 7.05909 18.1518C6.73676 17.8295 6.29959 17.6484 5.84375 17.6484C5.38791 17.6484 4.95074 17.8295 4.62841 18.1518C4.30608 18.4742 4.125 18.9113 4.125 19.3672V19.3672Z'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 19.3672C11 19.823 11.1811 20.2602 11.5034 20.5825C11.8257 20.9049 12.2629 21.0859 12.7188 21.0859C13.1746 21.0859 13.6118 20.9049 13.9341 20.5825C14.2564 20.2602 14.4375 19.823 14.4375 19.3672C14.4375 18.9113 14.2564 18.4742 13.9341 18.1518C13.6118 17.8295 13.1746 17.6484 12.7188 17.6484C12.2629 17.6484 11.8257 17.8295 11.5034 18.1518C11.1811 18.4742 11 18.9113 11 19.3672V19.3672Z'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.9375 10.0846V0.917969'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.9375 0.917969L12.375 4.35547'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.9375 0.917969L5.5 4.35547'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.6875 6.64629L2.82242 13.8751C2.90042 14.168 3.07304 14.427 3.31341 14.6117C3.55379 14.7963 3.84846 14.8964 4.15158 14.8963H14.0681C14.3712 14.8964 14.6659 14.7963 14.9063 14.6117C15.1466 14.427 15.3192 14.168 15.3973 13.8751L18.3682 2.73488C18.485 2.29557 18.7437 1.9072 19.1042 1.63017C19.4646 1.35315 19.9064 1.20302 20.361 1.20313H21.3125'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
