import React, { SVGProps } from 'react';

export const StepBack = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.375 21.1666C15.1427 21.1666 16.8706 20.6425 18.3404 19.6604C19.8102 18.6783 20.9557 17.2825 21.6322 15.6494C22.3086 14.0162 22.4856 12.2192 22.1408 10.4855C21.7959 8.7518 20.9447 7.1593 19.6948 5.90936C18.4448 4.65943 16.8523 3.80822 15.1186 3.46336C13.3849 3.11851 11.5879 3.2955 9.95477 3.97196C8.32165 4.64841 6.92581 5.79396 5.94374 7.26372C4.96168 8.73349 4.4375 10.4615 4.4375 12.2291V12.9166'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.6875 10.1639L4.4375 12.9139L7.1875 10.1639'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.0256 16.0994C14.4105 16.0994 15.4261 15.0767 15.4261 13.6847C15.4261 12.2749 14.446 11.2415 13.1108 11.2415C12.6207 11.2415 12.1449 11.4155 11.8466 11.6534H11.804L12.0597 9.50852H15.0994V8.72727H11.321L10.8807 12.3068L11.7045 12.4062C12.0064 12.1896 12.5213 12.0334 12.9688 12.0369C13.8956 12.044 14.5739 12.7472 14.5739 13.6989C14.5739 14.6328 13.9205 15.3182 13.0256 15.3182C12.2798 15.3182 11.6868 14.8388 11.6193 14.1818H10.767C10.8203 15.2898 11.7756 16.0994 13.0256 16.0994Z'
      fill='white'
    />
  </svg>
);
