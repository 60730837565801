import * as React from 'react';
import { useRef } from 'react';

import { Text } from '@components/common';
import { Portal } from '@components/shared/Portal';
import { Tiptap, TiptapHook } from '@components/shared/Tiptap';
import { isTranscriptDocumentEmpty } from '@components/shared/Tiptap/helpers/queries';

interface Props {
  recording: Recording | null;
  sessionUuid?: string;
  studyId?: number;
  tiptap: TiptapHook;
  transcriptStatus?: Transcript['state'];
  readOnly?: boolean;
}

export const TranscriptTab = React.forwardRef<HTMLDivElement, Props>(
  ({ transcriptStatus, tiptap, recording, sessionUuid, studyId }, ref) => {
    const rootRef = useRef<HTMLDivElement>(null);

    const videoStatus = recording?.mux_video?.status;

    const hasTranscript = videoStatus === 'ready' && transcriptStatus === 'ready';

    const noTranscript =
      videoStatus === 'ready' && (!transcriptStatus || ['unstarted', 'processing'].includes(transcriptStatus));

    if (noTranscript) {
      return (
        <>
          <div className='px-6 mt-6 font-bold text-center text-gray-700'>We’re preparing the transcript</div>
          <div className='px-6 text-sm text-center text-gray-500'>
            This may take a few minutes for longer videos. Feel free to leave the page while it finishes.
          </div>
        </>
      );
    }

    const isEmpty = tiptap.editor?.state && isTranscriptDocumentEmpty(tiptap.editor.state);

    return (
      <>
        {hasTranscript ? (
          <>
            <Tiptap {...tiptap.getTiptapProps()}>
              <tiptap.Content {...tiptap.getContentProps({ className: 'z-10' })} />
              {isEmpty && (
                <Text className='px-6 text-center' h='400' color='gray-500'>
                  There is no audio in this recording, so the transcript is empty.
                </Text>
              )}
            </Tiptap>
            <Portal selector='#gq-editor'>
              <div ref={rootRef} className='absolute inset-0 pointer-events-none'>
                <div ref={ref} className='bg-opacity-10 absolute bg-indigo-600 rounded' />
              </div>
            </Portal>
          </>
        ) : (
          <Text className='px-6 mt-6' color='gray-500'>
            Once the video is uploaded, we’ll show the transcript here.
          </Text>
        )}
      </>
    );
  }
);
