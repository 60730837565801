import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'form'];

  showForm() {
    this.buttonTarget.hidden = true;
    this.formTarget.hidden = false;
  }
}
