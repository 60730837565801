import * as React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Text } from '@components/common';

export interface ContactPreferencesForm {
  frequency?: string;
}
interface Props {
  accountName: string;
  hideGlobalOputOut: boolean;
  customUnsubscribeUrl: string | null;
  initialFrequency: string;
  saving?: boolean;
  handleSave: SubmitHandler<ContactPreferencesForm>;
}
export const UpdateMyPreferencesForm: React.FC<Props> = ({
  accountName,
  hideGlobalOputOut,
  customUnsubscribeUrl,
  initialFrequency,
  saving,
  handleSave
}) => {
  const { t, i18n } = useTranslation('UpdateMyPreferencesForm');
  const { control, handleSubmit, setValue } = useForm<ContactPreferencesForm>({
    defaultValues: {
      frequency: initialFrequency
    }
  });

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <div className='tablet:border p-6 border-gray-200 rounded-md'>
        <div className='text-center'>
          <h1 className='mb-2 text-xl font-bold text-gray-700'>{t('page_title')}</h1>
          <Text h='400'>{t('page_desc', { accountName: accountName })}</Text>
        </div>

        <div className='py-6'>
          <div className='w-full border-b border-gray-200' />
        </div>

        <div className='pb-8'>
          <label className='block mb-2 font-bold text-gray-700'>{t('frequency_section_title')}</label>
          <Text h='400' color='gray-500' className='mb-2'>
            {t('frequency_section_desc', { accountName: accountName })}
          </Text>

          <Controller
            control={control}
            name='frequency'
            render={({ onChange, value: selectedValue, name }) => (
              <>
                {['every_1mo', 'every_3mo', 'every_6mo', 'every_12mo', 'anytime'].map((key) => (
                  <div key={`freq_${key}`} className='flex items-center my-1 space-x-2'>
                    <input
                      id={key}
                      type='radio'
                      name={name}
                      value={key}
                      checked={selectedValue === key}
                      disabled={saving}
                      onChange={() => onChange(key)}
                    />
                    <label htmlFor={key} className='text-sm font-normal leading-relaxed'>
                      {t(`freq_${key}`)}
                    </label>
                  </div>
                ))}
              </>
            )}
          />
        </div>

        <div className='pt-6 text-center'>
          <Button disabled={saving} primary type='submit'>
            {t('save_cta')}
          </Button>
        </div>
      </div>
      {!hideGlobalOputOut && (
        <div className='pt-6 text-center'>
          {customUnsubscribeUrl ? (
            <Button href={customUnsubscribeUrl} target='_blank'>
              {t('optout_cta')}
            </Button>
          ) : (
            <Button
              disabled={saving}
              onClick={() => {
                setValue('frequency', 'never'); // TODO - DOES THiS UNSUBSCRIBE!!!!
              }}
            >
              {t('optout_cta')}
            </Button>
          )}
        </div>
      )}
    </form>
  );
};
