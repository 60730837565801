import React, { SVGProps } from 'react';

export const RecurringIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M5.5 11.5H1.5C1.23478 11.5 0.98043 11.3946 0.792893 11.2071C0.605357 11.0196 0.5 10.7652 0.5 10.5V2.5C0.5 2.23478 0.605357 1.98043 0.792893 1.79289C0.98043 1.60536 1.23478 1.5 1.5 1.5H10.5C10.7652 1.5 11.0196 1.60536 11.2071 1.79289C11.3946 1.98043 11.5 2.23478 11.5 2.5V5.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.5 4.5H11.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.5 2.5V0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8.5 2.5V0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M10 12.5H7.5V15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M15.1112 12.9893C14.8459 13.7627 14.3329 14.427 13.6517 14.8792C12.9705 15.3314 12.1592 15.5463 11.3435 15.4906C10.5278 15.4348 9.75325 15.1115 9.13993 14.5709C8.52661 14.0302 8.10877 13.3023 7.95117 12.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M13 10.5H15.5V8' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M7.88867 10.0113C8.15347 9.2375 8.66625 8.57269 9.34746 8.12005C10.0287 7.66741 10.8402 7.45225 11.6562 7.50794C12.4722 7.56363 13.247 7.88706 13.8604 8.42806C14.4738 8.96906 14.8915 9.69737 15.0487 10.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
