import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ArtifactsView, PublicArtifactsView } from '../components';

export interface ArtifactsAttributes {
  artifacts: string[];
  uuid: string;
}

export const Artifacts = Node.create<ArtifactsAttributes>({
  name: 'artifacts',

  group: 'block',

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      artifacts: {
        default: []
      },
      uuid: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'artifact-component'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['artifact-component', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    if ((this.editor.options?.editorProps as any)?.api === 'public') {
      return ReactNodeViewRenderer(PublicArtifactsView);
    }
    return ReactNodeViewRenderer(ArtifactsView);
  }
});
