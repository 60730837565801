export enum QuestionType {
  'yes/no' = 'yes_no',
  'multiple choice' = 'multiple_choice',
  'single choice' = 'single_choice',
  'short answer' = 'short_text',
  'long answer' = 'long_text',
  'linear scale' = 'number_range'
}

export interface SuggestedQuestion {
  position: number;
  question: string;
  type: keyof typeof QuestionType;
  options: string[];
  range_options?: {
    min: number;
    max: number;
    labels: {
      [num: number]: string;
    };
  };
}
