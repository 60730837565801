import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tinytime from 'tinytime';

import { AvatarFromId, Button, DropdownLink, Text, TippyOrNot } from '@components/common';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import * as toasts from '@components/HighlightReelsApp/toasts';
import { Views } from '@components/shared/Views';
import { DownloadSVG } from '@components/svgs';
import { TagList } from '@components/tags';
import { HighlightReelShareAccessDropdown } from '@components/shared/ShareAccess/HighlightReelShareAccessDropdown';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useMuxVideoDownloadUrl } from '@hooks/useMuxVideoDownloadUrl';
import { useToaster } from '@stores/toaster';
import Tippy from '@tippyjs/react';

import { OptionsDropdown } from './OptionsDropdown';
import { ArtifactBreadcrumbs } from '@components/RepositoryApp/components/ArtifactBreadcrumbs';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const DOWNLOAD_BUTTON_TOOLTIP = "Reel is being processed. The download option will be available when it's finished.";

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  highlightReel?: HighlightReel;
}

export const Header = forwardRef<HTMLDivElement, Props>(({ className, highlightReel, ...rest }, ref) => {
  const navigate = useNavigate();

  const {
    account: { team }
  } = useAccount();

  const creator = team.find((u) => u.id === highlightReel?.creator_id);

  const showToast = useToaster();

  const canEdit = usePermission<HighlightReel>('updateReel')(highlightReel);

  const downloadReady = highlightReel?.mux_video?.download_ready;

  const { downloadUrl: downloadLink } = useMuxVideoDownloadUrl(highlightReel?.mux_video?.id, !downloadReady);

  const handleRemoveHighlightReel = () => {
    navigate('/repository');
  };

  const handleOnDownloadBtnClick = (e: MouseEvent<Element>) => {
    e.preventDefault();

    if (!downloadLink) {
      showToast(toasts.failedDownload());
    } else {
      window.open(downloadLink, '_blank', 'rel=noopener noreferrer');
    }
  };

  return (
    <header ref={ref} className={cn('w-full', className)} {...rest}>
      <section className='tablet:flex-row tablet:justify-between flex flex-col'>
        {highlightReel && (
          <div className='overflow-hidden'>
            <ArtifactBreadcrumbs artifactTitle={highlightReel.title || ''} study={highlightReel.study} />
            <Text h='600' bold mb='2'>
              {highlightReel.title || 'Untitled highlight reel'}
            </Text>
            {highlightReel.subtitle && (
              <Text h='400' mb='2'>
                {highlightReel.subtitle}
              </Text>
            )}
            <div className='tablet:flex-row tablet:space-x-2 flex flex-col items-center mt-2'>
              <AvatarFromId userId={creator?.id} />
              <Text h='200' color='gray-500' data-testid='creator-name' className='flex-shrink-0'>
                {creator?.name}
              </Text>
              <Text h='200' color='gray-200'>
                &bull;
              </Text>
              <Text h='200' color='gray-500' data-testid='created-at' className='flex-shrink-0'>
                {dateTemplate.render(new Date(highlightReel.created_at))}
              </Text>
              {highlightReel.tag_ids.length > 0 && (
                <>
                  <Text h='200' color='gray-200'>
                    &bull;
                  </Text>

                  <div className='max-w-sm'>
                    <TagList
                      readOnly
                      tagIds={highlightReel.tag_ids}
                      studyId={highlightReel.project_id}
                      className='focus:outline-none flex items-center w-full h-10 space-x-2 rounded cursor-default'
                      placement='bottom'
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {highlightReel && (
          <div className='flex h-10 space-x-2'>
            <CopyURLButton name='Copy link' icon='link' text={window.location.href} />
            <Views views={highlightReel.views} />
            <Tippy content='Edit highlight reel'>
              <Button
                icon='pencil'
                name='edit'
                aria-label='edit'
                href={`/highlight_reels/${highlightReel.token}/edit`}
                spa
                className='ml-1'
              />
            </Tippy>
            <HighlightReelShareAccessDropdown
              token={highlightReel.token}
              value={highlightReel.access}
              readOnly={!canEdit}
            />
            <OptionsDropdown highlightReel={highlightReel} onRemoveHighlightReel={handleRemoveHighlightReel}>
              <DropdownLink
                onClick={handleOnDownloadBtnClick}
                className='flex items-center'
                disabled={!downloadReady}
                aria-label='Download highlight reel'
              >
                <TippyOrNot content={DOWNLOAD_BUTTON_TOOLTIP} show={!downloadReady}>
                  <div className='flex items-center'>
                    <DownloadSVG className='hover:text-indigo-600 mr-2' /> Download
                  </div>
                </TippyOrNot>
              </DropdownLink>
            </OptionsDropdown>
          </div>
        )}
      </section>
    </header>
  );
});
