import React, { SVGProps } from 'react';

export const ShoppingCartSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1335_8623)'>
      <path
        d='M2.625 12.3242C2.625 12.6143 2.74023 12.8925 2.94535 13.0976C3.15047 13.3027 3.42867 13.418 3.71875 13.418C4.00883 13.418 4.28703 13.3027 4.49215 13.0976C4.69727 12.8925 4.8125 12.6143 4.8125 12.3242C4.8125 12.0341 4.69727 11.7559 4.49215 11.5508C4.28703 11.3457 4.00883 11.2305 3.71875 11.2305C3.42867 11.2305 3.15047 11.3457 2.94535 11.5508C2.74023 11.7559 2.625 12.0341 2.625 12.3242Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 12.3242C7 12.6143 7.11523 12.8925 7.32035 13.0976C7.52547 13.3027 7.80367 13.418 8.09375 13.418C8.38383 13.418 8.66203 13.3027 8.86715 13.0976C9.07227 12.8925 9.1875 12.6143 9.1875 12.3242C9.1875 12.0341 9.07227 11.7559 8.86715 11.5508C8.66203 11.3457 8.38383 11.2305 8.09375 11.2305C7.80367 11.2305 7.52547 11.3457 7.32035 11.5508C7.11523 11.7559 7 12.0341 7 12.3242Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 6.41797L3.5 4.23047'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 0.582031V6.41536'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.6875 6.41797L7.875 4.23047'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 4.22946L1.79608 8.82962C1.84572 9.01603 1.95557 9.1808 2.10854 9.29832C2.2615 9.41584 2.44902 9.47952 2.64192 9.47946H8.95242C9.14532 9.47952 9.33283 9.41584 9.4858 9.29832C9.63876 9.1808 9.74861 9.01603 9.79825 8.82962L11.6888 1.73979C11.7633 1.46034 11.928 1.21333 12.1573 1.03715C12.3867 0.860978 12.6678 0.765521 12.957 0.765625H13.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1335_8623'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
