import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateCandidateConsent } from '@api/public_endpoints';
import { Loading } from '@components/common';
import { useToaster } from '@stores/toaster';

import { OptedOut } from './OptedOut';
import * as toasts from './toasts';
import { ContactPreferencesForm, UpdateMyPreferencesForm } from './UpdateMyPreferencesForm';

type PublicAccount = {
  name: string;
  hide_global_opt_out_candidate_ux: boolean;
  custom_unsubscribe_url: string | null;
};
interface Props {
  consent_token: string;
  contact_preferences: {
    frequency: string;
  };
  account: PublicAccount;
  language: 'en' | 'de' | 'es' | 'fr' | 'it' | 'pt-BR';
}

export const CandidateConsent: React.FC<Props> = ({ contact_preferences, consent_token, account, language }) => {
  const [frequency, setFrequency] = useState(contact_preferences.frequency);
  const [saving, setSaving] = useState(false);

  const showToast = useToaster();
  const { t, i18n } = useTranslation('UpdateMyPreferencesForm');

  async function handleSave(data: ContactPreferencesForm) {
    setSaving(true);

    const ok = await updateCandidateConsent(consent_token, data);
    if (ok && data.frequency) {
      showToast(toasts.successUpdate(t));
      setFrequency(data.frequency);
    } else {
      showToast(toasts.failedUpdate(t));
    }
    setSaving(false);
  }

  useEffect(() => {
    i18n.changeLanguage(language);
  }, []);

  return (
    <div className='w-full max-w-2xl'>
      {saving && <Loading />}

      {frequency === 'never' && (
        <OptedOut accountName={account.name} saving={saving} handleOptIn={() => handleSave({ frequency: 'anytime' })} />
      )}

      {frequency !== 'never' && (
        <UpdateMyPreferencesForm
          accountName={account.name}
          hideGlobalOputOut={account.hide_global_opt_out_candidate_ux}
          customUnsubscribeUrl={account.custom_unsubscribe_url}
          initialFrequency={frequency}
          saving={saving}
          handleSave={handleSave}
        />
      )}
    </div>
  );
};
