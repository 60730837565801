import React, { SVGProps } from 'react';

export const HighlightReelSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='7' cy='7' r='6.5' stroke='currentColor' />
    <circle cx='7' cy='4' r='1' fill='currentColor' />
    <circle cx='4' cy='7' r='1' fill='currentColor' />
    <circle cx='10' cy='7' r='1' fill='currentColor' />
    <circle cx='7' cy='10' r='1' fill='currentColor' />
    <path d='M7 13.5H14' stroke='currentColor' strokeLinecap='round' />
  </svg>
);
