export enum Region {
  Collapse = 'collapse',
  Expand = 'expand'
}

export const POINTER_SENSOR_ACTIVATION = {
  delay: 25,
  tolerance: 40
};

export const TOUCH_SENSOR_ACTIVATION = {
  delay: 25,
  tolerance: 40
};
