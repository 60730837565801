import React, { SVGProps } from 'react';

export const SurveyTemplateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='92' height='82' viewBox='0 0 92 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M75.3532 35.707H4.94488C4.4352 35.7065 3.94633 35.5048 3.58445 35.1459C3.22257 34.787 3.01692 34.2998 3.01217 33.7902L1.90765 3.95426C1.90823 3.44183 2.11204 2.95056 2.47439 2.58822C2.83673 2.22588 3.328 2.02206 3.84043 2.02148H74.2488C74.7584 2.02203 75.2473 2.22367 75.6092 2.58258C75.9711 2.94149 76.1767 3.42869 76.1815 3.93835L77.286 33.7743C77.2854 34.2867 77.0816 34.778 76.7193 35.1403C76.3569 35.5026 75.8656 35.7065 75.3532 35.707ZM2.45994 3.9397L3.56432 33.7743C3.56474 34.1403 3.71033 34.4912 3.96914 34.75C4.22796 35.0088 4.57886 35.1544 4.94488 35.1548H75.3532C75.7167 35.1544 76.0654 35.0108 76.3238 34.7551C76.5822 34.4994 76.7294 34.1523 76.7337 33.7888L75.6293 3.95426C75.6289 3.58824 75.4833 3.23734 75.2245 2.97853C74.9657 2.71971 74.6148 2.57413 74.2488 2.57371H3.84043C3.47693 2.57413 3.12823 2.71773 2.86985 2.9734C2.61146 3.22907 2.4642 3.57623 2.45994 3.9397Z'
        fill='#3F3D56'
      />
      <path
        d='M88.6065 79.6094H18.1982C17.6885 79.6088 17.1997 79.4072 16.8378 79.0483C16.4759 78.6894 16.2702 78.2022 16.2655 77.6925L15.161 47.8566C15.1616 47.3442 15.3654 46.8529 15.7277 46.4906C16.0901 46.1282 16.5813 45.9244 17.0938 45.9238H87.5021C88.0118 45.9244 88.5007 46.126 88.8625 46.4849C89.2244 46.8438 89.4301 47.331 89.4348 47.8407L90.5393 77.6766C90.5387 78.189 90.3349 78.6803 89.9726 79.0426C89.6102 79.405 89.119 79.6088 88.6065 79.6094ZM15.7133 47.842L16.8176 77.6766C16.8181 78.0426 16.9637 78.3935 17.2225 78.6523C17.4813 78.9111 17.8322 79.0567 18.1982 79.0572H88.6065C88.97 79.0567 89.3187 78.9131 89.5771 78.6575C89.8355 78.4018 89.9828 78.0546 89.987 77.6912L88.8826 47.8566C88.8822 47.4906 88.7366 47.1397 88.4778 46.8809C88.219 46.6221 87.8681 46.4765 87.5021 46.4761H17.0938C16.7303 46.4765 16.3816 46.6201 16.1232 46.8757C15.8648 47.1314 15.7175 47.4786 15.7133 47.842Z'
        fill='#3F3D56'
      />
      <path
        d='M34.9029 52.4121C34.4269 52.4121 33.9705 52.6012 33.6339 52.9378C33.2973 53.2743 33.1082 53.7308 33.1082 54.2068C33.1082 54.6828 33.2973 55.1393 33.6339 55.4759C33.9705 55.8125 34.4269 56.0016 34.9029 56.0016H70.7974C71.2734 56.0016 71.7299 55.8125 72.0664 55.4759C72.403 55.1393 72.5921 54.6828 72.5921 54.2068C72.5921 53.7308 72.403 53.2743 72.0664 52.9378C71.7299 52.6012 71.2734 52.4121 70.7974 52.4121H34.9029Z'
        fill='#6C63FF'
      />
      <path
        d='M34.9029 60.9707C34.4269 60.9707 33.9705 61.1598 33.6339 61.4964C33.2973 61.8329 33.1082 62.2894 33.1082 62.7654C33.1082 63.2414 33.2973 63.6979 33.6339 64.0345C33.9705 64.3711 34.4269 64.5601 34.9029 64.5601H70.7974C71.2734 64.5601 71.7299 64.3711 72.0664 64.0345C72.403 63.6979 72.5921 63.2414 72.5921 62.7654C72.5921 62.2894 72.403 61.8329 72.0664 61.4964C71.7299 61.1598 71.2734 60.9707 70.7974 60.9707H34.9029Z'
        fill='#6C63FF'
      />
      <path
        d='M34.9029 69.5312C34.4269 69.5312 33.9705 69.7203 33.6339 70.0569C33.2973 70.3935 33.1082 70.85 33.1082 71.326C33.1082 71.802 33.2973 72.2585 33.6339 72.595C33.9705 72.9316 34.4269 73.1207 34.9029 73.1207H70.7974C71.2734 73.1207 71.7299 72.9316 72.0664 72.595C72.403 72.2585 72.5921 71.802 72.5921 71.326C72.5921 70.85 72.403 70.3935 72.0664 70.0569C71.7299 69.7203 71.2734 69.5312 70.7974 69.5312H34.9029Z'
        fill='#6C63FF'
      />
      <path
        d='M21.6496 8.50977C21.1736 8.50977 20.7171 8.69885 20.3805 9.03543C20.0439 9.372 19.8549 9.8285 19.8549 10.3045C19.8549 10.7805 20.0439 11.237 20.3805 11.5735C20.7171 11.9101 21.1736 12.0992 21.6496 12.0992H57.544C58.02 12.0992 58.4765 11.9101 58.8131 11.5735C59.1497 11.237 59.3387 10.7805 59.3387 10.3045C59.3387 9.8285 59.1497 9.372 58.8131 9.03543C58.4765 8.69885 58.02 8.50977 57.544 8.50977H21.6496Z'
        fill='#6C63FF'
      />
      <path
        d='M21.6496 17.0703C21.1736 17.0703 20.7171 17.2594 20.3805 17.596C20.0439 17.9326 19.8549 18.389 19.8549 18.865C19.8549 19.341 20.0439 19.7975 20.3805 20.1341C20.7171 20.4707 21.1736 20.6598 21.6496 20.6598H57.544C58.02 20.6598 58.4765 20.4707 58.8131 20.1341C59.1497 19.7975 59.3387 19.341 59.3387 18.865C59.3387 18.389 59.1497 17.9326 58.8131 17.596C58.4765 17.2594 58.02 17.0703 57.544 17.0703H21.6496Z'
        fill='#6C63FF'
      />
      <path
        d='M21.6496 25.6289C21.1736 25.6289 20.7171 25.818 20.3805 26.1546C20.0439 26.4911 19.8549 26.9476 19.8549 27.4236C19.8549 27.8996 20.0439 28.3561 20.3805 28.6927C20.7171 29.0293 21.1736 29.2183 21.6496 29.2183H57.544C58.02 29.2183 58.4765 29.0293 58.8131 28.6927C59.1497 28.3561 59.3387 27.8996 59.3387 27.4236C59.3387 26.9476 59.1497 26.4911 58.8131 26.1546C58.4765 25.818 58.02 25.6289 57.544 25.6289H21.6496Z'
        fill='#6C63FF'
      />
    </svg>
  );
};
