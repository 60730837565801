import * as React from 'react';

export default () => (
  <svg className='mx-auto' width='76' height='61' viewBox='0 0 76 61' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.7363 37.7773L7.37103 42.683C10.9975 44.2252 14.6684 42.0843 16.1731 41.0109C21.032 43.6877 26.7256 44.408 32.0955 43.0253C37.4654 41.6427 42.1081 38.261 45.079 33.5682C48.05 28.8754 49.1259 23.2242 48.0879 17.7643C47.05 12.3045 43.9761 7.44616 39.4917 4.17788C35.0073 0.909599 29.4493 -0.523091 23.9486 0.171306C18.4479 0.865703 13.4177 3.63502 9.88145 7.91579C6.34525 12.1966 4.5687 17.6672 4.91327 23.2146C5.25785 28.762 7.69766 33.9694 11.7363 37.7773V37.7773Z'
      fill='#E5E7EB'
    />
    <path
      d='M14.905 18.2722C19.4084 18.2252 23.9113 18.1445 28.4145 18.0831C29.9157 18.0627 31.417 18.0447 32.9184 18.0293C34.212 18.0158 34.2153 15.9992 32.9184 16.0128C28.415 16.0598 23.9121 16.1405 19.4089 16.2018C17.9076 16.2223 16.4064 16.2402 14.905 16.2556C13.6114 16.2691 13.6081 18.2857 14.905 18.2722Z'
      fill='white'
    />
    <path
      d='M14.905 22.9777L33.106 22.7887L38.2824 22.7349C39.576 22.7215 39.5793 20.7049 38.2824 20.7183L20.0815 20.9074L14.905 20.9612C13.6114 20.9746 13.6081 22.9912 14.905 22.9777Z'
      fill='white'
    />
    <path
      d='M14.905 27.6828L33.106 27.4938L38.2824 27.44C39.576 27.4266 39.5793 25.4099 38.2824 25.4234L20.0815 25.6125L14.905 25.6662C13.6114 25.6797 13.6081 27.6963 14.905 27.6828Z'
      fill='white'
    />
    <path
      d='M1.5858 46.9259C2.46161 46.9259 3.1716 46.2141 3.1716 45.3361C3.1716 44.4581 2.46161 43.7463 1.5858 43.7463C0.709986 43.7463 0 44.4581 0 45.3361C0 46.2141 0.709986 46.9259 1.5858 46.9259Z'
      fill='#E5E7EB'
    />
    <path
      d='M76.0003 38.605C75.9984 33.824 74.4299 29.176 71.5359 25.3759C68.6419 21.5757 64.5829 18.8339 59.9829 17.5722C55.383 16.3105 50.497 16.5986 46.0763 18.3924C41.6557 20.1862 37.9453 23.3863 35.5158 27.5004C33.0863 31.6145 32.0723 36.4148 32.6296 41.1631C33.187 45.9114 35.2849 50.3446 38.6006 53.7809C41.9164 57.2173 46.2664 59.4664 50.9817 60.1824C55.6969 60.8984 60.5161 60.0416 64.6983 57.7439C66.203 58.8172 69.8739 60.9581 73.5004 59.4159L69.1351 54.5102C71.3051 52.4719 73.0342 50.0083 74.2151 47.2723C75.3961 44.5363 76.0037 41.5862 76.0003 38.605Z'
      fill='#FACA15'
    />
    <path
      d='M43.1045 35.0054C47.6079 34.9584 52.1107 34.8777 56.614 34.8164C58.1152 34.7959 59.6165 34.778 61.1178 34.7626C62.4114 34.7491 62.4147 32.7325 61.1178 32.746C56.6144 32.793 52.1116 32.8738 47.6084 32.9351C46.1071 32.9555 44.6058 32.9735 43.1045 32.9889C41.8109 33.0024 41.8076 35.019 43.1045 35.0054Z'
      fill='white'
    />
    <path
      d='M43.1045 39.7114L61.3054 39.5224L66.4819 39.4686C67.7755 39.4552 67.7788 37.4385 66.4819 37.452L48.2809 37.6411L43.1045 37.6948C41.8109 37.7083 41.8076 39.7249 43.1045 39.7114V39.7114Z'
      fill='white'
    />
    <path
      d='M43.1045 44.4164L61.3054 44.2274L66.4819 44.1736C67.7755 44.1602 67.7788 42.1435 66.4819 42.157L48.2809 42.3461L43.1045 42.3998C41.8109 42.4133 41.8076 44.4299 43.1045 44.4164Z'
      fill='white'
    />
  </svg>
);
