import * as React from 'react';
import { useEffect, useState } from 'react';

import { createWalletInvoice } from '@api/queries';
import { Button, Input, Loading, Modal, Text, Textarea } from '@components/common';
import { Toast, useToaster } from '@components/stores/toaster';
import { moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}
export const errorToast: Toast = {
  heading: 'Failed to create invoice!',
  icon: 'error',
  text: 'Something went wrong while trying to create your invoice. Please try again later.'
};
export const successToast: Toast = {
  heading: 'Invoice successfully created!',
  icon: 'success',
  text: 'Invoice will be sent to account admin via email.'
};

// TODO: explicit add permissions here.
export const WalletInvoiceModal: React.FC<Props> = ({ onCancel, onSuccess }) => {
  const {
    refresh: refreshAccount,
    account: { wallet }
  } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [memo, setMemo] = useState<string>();
  const showToast = useToaster();

  async function handleSubmit() {
    setLoading(true);
    const [err, resp] = await createWalletInvoice({ amount_in_cents: amount * 100, memo: memo as any });
    if (err) {
      showToast(errorToast);
    } else {
      showToast(successToast);
      await refreshAccount();
      onSuccess();
    }
    setLoading(false);
  }
  const balanceAfter = wallet.available + amount * 100;

  return (
    <Modal
      size='md'
      title='Create invoice'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={handleSubmit} primary disabled={!amount || amount === 0}>
            Create invoice
          </Button>
        </>
      )}
    >
      {loading && <Loading absolute />}
      <div className='py-6 space-y-4'>
        <div className='flex flex-row items-center justify-between'>
          <Text bold>Current balance</Text>
          <Text>${moneyFormat(wallet.available / 100)}</Text>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <Text bold>Amount to fund</Text>
          <Input
            type='number'
            name='amount'
            value={amount === 0 ? undefined : amount.toString()}
            onChange={(v) => setAmount(parseInt(v))}
          />
        </div>

        <div className='flex flex-row items-center justify-between'>
          <div>
            <Text bold>Balance after invoice</Text>
            <Text h='400'>Balance will only update after invoice is processed.</Text>
          </div>
          <Text>${moneyFormat(balanceAfter / 100)}</Text>
        </div>
        <div>
          <Text bold mb='1'>
            Memo or PO
          </Text>
          <Textarea name='memo' value={memo as any} onChange={setMemo} />
        </div>
      </div>
    </Modal>
  );
};
