import { createContext, MutableRefObject, useContext, useRef } from 'react';

import { UseVideoPlayer } from '@components/VideoPlayer';
import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';

export interface Context {
  documentId?: number;
  studyId?: number | null;
  sessionUuid?: string;
  slideoutView?: boolean;
  highlightReelToken?: string;
  artifactIds?: string[];
  ctaProps?: CtaProps;
  recordingId?: number;
}

export const TiptapContext = createContext<Context | undefined>(undefined);

export const useTiptapContext = () => {
  const tiptapContext = useContext<Context | undefined>(TiptapContext);
  const ref = useRef<UseVideoPlayer | null>(null);

  if (tiptapContext === undefined) {
    return {
      documentId: undefined,
      studyId: undefined,
      highlightReelToken: undefined,
      artifactIds: undefined,
      ctaProps: undefined,
      recordingId: undefined
    };
  }

  return tiptapContext;
};
