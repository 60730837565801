import qs from 'qs';
import React, { useMemo } from 'react';

import { api } from '@api/reduxApi';
import { pick } from '@components/utils';

import { Invite } from './Invite';

export const NewInvite: React.FC = () => {
  const [createInvitation] = api.useCreateInvitationMutation();

  const newInvite = useMemo(() => {
    const params = qs.parse(location.search.slice(1));
    return params.name || params.email ? pick(params, 'name', 'email', 'role') : null;
  }, []);

  return (
    <Invite
      type='Invitation'
      method='create'
      onUpdate={(params) => createInvitation(params).unwrap()}
      {...(newInvite ? { record: newInvite } : null)}
    />
  );
};
