import { ArtifactHit } from '@components/RepositoryApp/types';

type GetClipDataFromHit = (hit: ArtifactHit) => Pick<Clip, 'from' | 'to' | 'tag_ids' | 'created_at' | 'title'>;

export const getClipDataFromHit: GetClipDataFromHit = (hit) => ({
  tag_ids: hit.tag_ids,
  from: hit.from as number,
  to: hit.to as number,
  created_at: hit.created_at,
  title: hit.title
});
