import { Card } from '@components/shared/Skeleton';
import { NoParticipantsZDS } from '@components/StudiesApp/components/StudyPublished/components/NoParticipantsZDS';
import { Alerts } from '@components/StudiesApp/components/StudyPublished/components/Alerts';
import { BackgroundTasks } from '@components/shared/BackgroundTasks';
import { useGetStudyBackgroundTasksQuery } from '@components/StudiesApp/components/StudyPublished/api';
import { ZDS } from '@components/StudiesApp/components/StudyPublished/components/ZDS';
import { MessageRequests } from '@components/StudiesApp/components/StudyPublished/components/MessageRequests';
import { PageHeader } from '@components/shared/PageHeader';
import { ParticipationStatusTabs } from '@components/StudiesApp/components/StudyPublished/components/ParticipationStatusTabs';
import { TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { BulkActions } from '@components/StudiesApp/components/StudyPublished/components/BulkActions';
import { Modal, Notification, Option, Spinner } from '@components/common';
import pluralize from 'pluralize';
import { SelectSubsetDropdown } from '@components/shared/GridTable/components/SelectSubsetDropdown';
import { getHiddenColumnNames, selectSubset, updateCellData } from '@components/shared/GridTable/components/utils';
import { SortDropdown } from '@components/shared/SortDropdown';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ADD_EXISTING,
  ADD_INTERVIEW,
  ADD_MANUAL,
  CANCEL_INTERVIEW_SLIDEOUT,
  EDIT_CUSTOM_ATTRIBUTES,
  FUND_STUDY,
  INVITE_VIA_LINK,
  REVIEW_TIME_PROPOSAL,
  SEND_INVITES,
  SEND_REMINDERS,
  SEND_SCREENER,
  SEND_THANKS,
  StudyAction,
  UPDATE_MODERATOR
} from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { NotificationProps } from '@components/common/Notification';
import { useFeature } from '@hooks/useFeature';
import { useCollectionView } from '@stores/view';
import { useTeams } from '@hooks/useTeams';
import { compact, removeHash, setHash, uniq, without } from '@components/utils';
import { api } from '@api/reduxApi';
import { useSearchParams } from 'react-router-dom';
import { useParticipations } from '@hooks/useParticipations';
import { useToaster } from '@stores/toaster';
import { useUser } from '@hooks/useUser';
import { usePermission } from '@hooks/usePermission';
import { CORE_ATTRS, EXTERNAL_ATTRS } from '@components/config';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { buildParticipationsFilterDefs } from '@components/StudiesApp/components/StudyPublished/components/buildParticipationsFilterDefs';
import {
  buildParticipantsColumns,
  ParticipationTableItem
} from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import { PARTICIPATION_STATUSES } from '@components/StudiesApp/constants';
import { getInitialStatus } from '@components/StudiesApp/components/StudyPublished/helpers/getInitialStatus';
import * as toasts from '@components/StudiesApp/components/StudyDraft/toasts';
import { filterPartiesById } from '@components/StudyMessages/utils/filters';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { ELocalStorageKeys } from '@constants/localStorageKeys';
import { UseRowSelect } from 'components/shared/GridTable/hooks/UseRowSelect';
import { OnToggleProps } from '@components/shared/GridTable/types';
import {
  Row,
  TableOptions,
  useAbsoluteLayout,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table';
import {
  getDateAttrs,
  getStatusAttrs
} from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers';
import { calculateNumber } from '@components/GQSurveyBuilder/utils';
import { UpdateCellData } from '@components/shared/GridTable/components/inputs/types';
import { useColumnResizeObserver } from '@components/shared/GridTable/hooks/useColumnResizeObserver';
import { buildColumnTabs } from '@components/StudiesApp/components/StudyPublished/utils';
import { TableStateProvider } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { GridTable } from '@components/shared/GridTable';
import { AddCandidates } from '@components/StudiesApp/components/StudyPublished/components/AddCandidates';
import { InviteLink } from '@components/StudiesApp/components/StudyPublished/components/InviteLink';
import { AddCandidateSlideOut } from '@components/shared/AddCandidateSlideOut';
import { NewInterview } from '@components/NewInterview';
import { ProfileContextProvider } from '@hooks/useProfileContext';
import { StudyAttrsSlideout } from '@components/StudiesApp/components/StudyPublished/components/StudyAttrsSlideout';
import { ProfileSlideout } from '@components/RepoSessionApp/ProfileSlideout';
import { EmailSlideOuts } from '@components/StudiesApp/components/StudyPublished/components/EmailSlideouts';
import { ReviewTimeProposalSlideout } from '@components/StudiesApp/components/StudyPublished/components/ReviewTimeProposalSlideout';
import { Paginator } from '@components/shared/Paginator';
import {
  getProfileSlideOutSections,
  statusFilter
} from '@components/StudiesApp/components/StudyPublished/components/ParticipantsTab/utils';
import { ExportCsvButton } from 'components/common/ExportCsvButton';
import { DefaultActions } from '../DefaultActions';
import { failedAttrUpdate } from '@components/CandidateProfile';
import { ParticipationsTable } from '@components/shared/ParticipationsTable';
import cn from 'classnames';

interface Props {
  study: Study;
  backgroundTasks: BackgroundTask[];
  setBackgroundTasks: (tasks: BackgroundTask[]) => void;
  refetch: () => void;
}

export const ParticipantsTab: FC<Props> = ({ study, backgroundTasks, setBackgroundTasks, refetch: refetchStudy }) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [page, setPage] = useState<number>(1);
  const [activeParticipation, setActiveParticipation] = useState<Participation | null>();
  const [profileSlideoutOpen, setProfileSlideoutOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<StudyAction | null>(null);
  const [addCandidatesDefaultStudyLimit, setAddCandidatesDefaultStudyLimit] = useState<StudyLimit | null>(null);
  const [notification, setNotification] = useState<Omit<NotificationProps, 'onClose'> | null>();
  const [query, setQuery] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>(query);

  const { style, currencySymbol, focus_group, on_gq, pre_screener, survey_screener } = study;

  const enableTeams = useFeature('teams');
  const newParticipantsTable = useFeature('new_participants_table');

  const canUpdate = usePermission<Study>('updateStudy')(study);

  const participantLevelIncentives = useFeature('participant_level_incentives');
  const hasParticipantLevelIncentives = participantLevelIncentives && canUpdate;

  const { view, setView } = useCollectionView();

  const teamId = enableTeams ? study.team_id : null;
  const { teams, findTeam } = useTeams({ skip: !enableTeams });
  const team = (teamId && findTeam(teamId)) || null;

  const screeningAttrs = [
    'preScreenerField.ideal_response',
    'preScreenerField.ideal_answers_count',
    ...(pre_screener?.fields || []).map((q) => `preScreenerField.${q.id}`),
    ...(study.has_external_candidates_requests ? EXTERNAL_ATTRS.map((a) => `extra.${a.name}`) : [])
  ];

  const initialAttrs = (study.visible_attrs || []).map((attr) =>
    ['company', 'title', 'location'].includes(attr) ? `extra.${attr}` : attr
  );

  const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>(
    uniq(['name', 'rating', 'moderator', 'interview', 'interview_at', ...screeningAttrs, ...initialAttrs])
  );
  const { data: segments, isSuccess: segmentsLoaded, isLoading: isLoadingSegments } = api.useGetSegmentsQuery();
  const { data: slots, refetch: refetchSlots } = api.useGetStudySlotsBalanceQuery(study.id, { skip: !study.id });
  const { data: messageRequests, refetch: refetchMessageRequests } = api.useGetMessageRequestsQuery(study.id, {
    skip: !study.id
  });

  const { data: studyLimits } = api.useGetStudyLimitsQuery(study.id);
  const {
    data: studyLimitsProgress,
    isSuccess: isSuccessStudyLimitsProgress,
    refetch: refetchStudyLimitsProgress
  } = api.useGetStudyLimitsProgressQuery(study.id, {
    skip: !studyLimits || studyLimits.length < 1
  });

  useEffect(() => {
    if (studyLimits && studyLimits.length > 0 && !visibleColumnNames.includes('segment')) {
      setVisibleColumnNames(['segment', ...visibleColumnNames]);
    }
  }, [studyLimits, visibleColumnNames]);

  const [updateStudy, { isLoading: updateLoading, isError: updateError }] = api.useUpdateStudyMutation();
  const [updateParticipation] = api.useUpdateParticipationMutation();

  const skip = !!activeParticipation || !!mode || profileSlideoutOpen || query !== '';

  const {
    participations = [],
    isFetching: isFetchingParticipations,
    isError: isErrorParticipations,
    initialFetchDone: initialFetchDoneParticipations,
    refetch: refetchParties,
    updateParticipation: updateEntireParticipation
  } = useParticipations({
    studyId: study.id,
    preScreenerId: pre_screener?.id,
    surveyScreenerId: survey_screener?.id,
    skip
  });

  const showToast = useToaster();
  const user = useUser();
  const canExportCsv = usePermission('exportCsv')();
  const canPII = usePermission('showPII')();

  const hiddenCore = ['first_name', 'last_name', ...(canPII ? [] : ['email', 'phone_number'])];

  const coreAttrs = CORE_ATTRS.filter((c) => !hiddenCore.includes(c.name));

  const { candidateAttrs, setCandidateAttrs, create } = useCandidateAttrs();

  useEffect(() => {
    if (isErrorParticipations) {
      showToast({
        heading: 'An error occurred',
        icon: 'error',
        text: 'We couldn’t fetch participations for this study. Please try again later.'
      });
    }
  }, [isErrorParticipations]);

  const selectionCallback = useCallback((ids) => setSelectedIds(ids), [setSelectedIds]);

  // Filter based on candidate search
  const {
    data: searchData,
    isFetching: isFetchingSearch,
    isUninitialized,
    refetch: refetchSearch
  } = api.useSearchCandidatesQuery({ study_id: study.id, q: query, size: 50 }, { skip: query === '' });

  const [currentStatus, setCurrentStatus] = useState<ParticipationStatus>(
    (view.tableTab as ParticipationStatus) || 'shortlisted'
  );

  const setStatus = (status: ParticipationStatus) => {
    setCurrentStatus(status);
    setView({ tableTab: status });
  };

  const [searchParams, setSearchParams] = useSearchParams();

  // automatically open review time proposal slideout if arriving with a query apram
  const reviewTimeProposalParam = searchParams.get('review_id');
  const reviewTimeProposalSlideoutInitialOpenRef = useRef(false);

  useEffect(() => {
    if (
      participations.length > 0 &&
      mode === null &&
      !reviewTimeProposalSlideoutInitialOpenRef.current &&
      reviewTimeProposalParam
    ) {
      const partyId = parseInt(reviewTimeProposalParam);
      const party = participations.find((p) => p.id === partyId);

      if (!party?.time_proposal?.times.length) return;

      setActiveParticipation(party);
      reviewTimeProposalSlideoutInitialOpenRef.current = true;
      setMode(REVIEW_TIME_PROPOSAL);
    }
  }, [participations, reviewTimeProposalParam]);

  const searchParticipations = useMemo(
    () =>
      participations.filter((participation) =>
        (searchData || []).some((resp) => resp.id === participation.customer_id)
      ),
    [searchData, participations]
  );

  const currentParticipations = useMemo(
    () => (query !== '' ? searchParticipations : participations),
    [searchParticipations, query, participations]
  );

  const definitions = useMemo(
    () =>
      buildParticipationsFilterDefs({
        hasExternalCandidates: study.has_external_candidates_requests,
        preScreenerQuestions: pre_screener?.fields || [],
        surveyScreenerQuestions: survey_screener?.fields || [],
        customAttrs: candidateAttrs,
        segments: segments || [],
        enableTeams,
        teams,
        preScreenerHasIdealAnswers: pre_screener?.has_ideal_answers || false,
        style: study.style,
        hasScreener: study.has_screener,
        onGQ: study.on_gq
      }),
    [
      candidateAttrs,
      teams,
      segments,
      study.on_gq,
      study.has_screener,
      pre_screener,
      survey_screener?.fields,
      study.has_external_candidates_requests
    ]
  );

  const filtersHook = useTableFilters<Participation>({
    trackKey: 'candidates',
    definitions,
    query: searchQuery,
    syncWithURL: !searchParams.get('review_id')
  });

  const filteredParticipations = useMemo(
    () => filtersHook.rawFilter(currentParticipations),
    [currentParticipations, filtersHook.filters]
  );

  const onAddCandidates = () => {
    setAddCandidatesDefaultStudyLimit(null);
    setMode(ADD_EXISTING);
  };

  const filteredByStatus = useMemo(
    () => filteredParticipations.filter(statusFilter(currentStatus)),
    [filteredParticipations, currentStatus]
  );

  const data = useMemo<ParticipationTableItem[]>(() => {
    // this places a limit onto every participation object before its passed to the table
    const limits = studyLimitsProgress || {};
    const partyIdToLimit = (partyId: number) => {
      if (!studyLimits) return null;
      const limitId = Object.keys(limits).find((id) => limits[id].participation_ids.includes(partyId));
      const limit = studyLimits.find((l) => String(l.id) === limitId);
      return limit ? { order: limit.order, name: limit.name } : null;
    };
    return filteredByStatus?.map((p) => ({
      participation: { ...p, name: p.customer?.name },
      limit: partyIdToLimit(p.id)
    }));
  }, [filteredByStatus, studyLimits, studyLimitsProgress]);

  const counts = useMemo(
    () =>
      PARTICIPATION_STATUSES.reduce(
        (acc, status) => ({
          ...acc,
          [status]: filteredParticipations.filter((participation) => participation.status === status).length || 0
        }),
        {}
      ),
    [filteredParticipations]
  );

  const defaultStatus = searchParams.get('status');

  useEffect(() => {
    if (initialFetchDoneParticipations && !view.tableTab) {
      let initialStatus: ParticipationStatus;

      if (defaultStatus) {
        initialStatus = defaultStatus?.toString() as ParticipationStatus;
        searchParams.delete('status');
        setSearchParams(searchParams);
      } else {
        initialStatus = getInitialStatus(counts);
      }
      if (initialStatus !== currentStatus) {
        setStatus(initialStatus);
      }
    }
  }, [initialFetchDoneParticipations, counts]);

  const handleHash = (participations: Participation[]) => {
    const hash = window.location.hash;

    if (hash) {
      const [path, id] = hash.substring(1).split('/');

      if (path === 'candidates') {
        const p = participations.find(({ customer_id }) => id.toString() === customer_id.toString());
        if (p) {
          setActiveParticipation(p);
          setProfileSlideoutOpen(true);
          setStatus(p.status);
        } else {
          removeHash();
        }
      } else if (path === 'fund') {
        setMode(FUND_STUDY);
      } else if (path === 'invite') {
        setMode(INVITE_VIA_LINK);
      } else {
        // Unhandled
      }
    }
  };

  useEffect(() => {
    if (participations.length) {
      handleHash(participations);
    }
  }, [participations]);

  useEffect(() => {
    selectionCallback([]);
  }, [currentStatus]);

  const handleAddCustomer = async (backgroundTask: BackgroundTask) => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
      refreshStudy();
    }
    setMode(null);
    setStatus('shortlisted');
  };

  const handleClickRow = useCallback(
    async (participation: Participation) => {
      if (participation.id === activeParticipation?.id) {
        unsetActiveParticipation();
      } else {
        setActiveParticipation(participation);
        setProfileSlideoutOpen(true);
        setHash(`candidates/${participation.customer_id}`);
      }
    },
    [activeParticipation?.id]
  );

  const handleClickRowModerator = (participation: Participation) => {
    setActiveParticipation(participation);
    setMode(UPDATE_MODERATOR);
  };

  const handleClickReviewTimeProposal = (participation: Participation) => {
    setActiveParticipation(participation);
    setMode(REVIEW_TIME_PROPOSAL);
  };

  const handleSelectIncentive = async (participation: Participation, value: number) => {
    await updateParticipation({ id: participation.id, incentive_in_cents: value * 100 });
    refetchStudy();
    refetchParties();
  };

  const handleClickRateParticipation = async (participation: Participation, rating: number) => {
    await updateParticipation({ id: participation.id, rating: rating });
    refetchParties();
  };

  useEffect(() => {
    selectionCallback([]);
  }, [isFetchingParticipations]);

  const refreshStudy = async (fetchParties = true, resetMode = true) => {
    setLoading(true);
    removeHash();

    if (query && !isUninitialized) {
      await refetchSearch();
    }
    if (fetchParties) {
      refetchParties();
      if (isSuccessStudyLimitsProgress) {
        refetchStudyLimitsProgress();
      }
    }
    setLoading(false);
    if (resetMode) {
      setMode(null);
    }
  };

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  const slideOutParties: Participation[] = useMemo(() => {
    if (activeParticipation) {
      return [activeParticipation];
    } else {
      return filterPartiesById(participations, selectedIds);
    }
  }, [activeParticipation, selectedIds, participations]);

  const onChangeCandidate = (customer: Candidate) => {
    if (activeParticipation) {
      const newParticipation = { ...activeParticipation, customer };
      setActiveParticipation(newParticipation);
      refetchParties();
    }
  };

  const onCloseProfileSlideout = () => {
    if (mode !== SEND_THANKS && mode !== SEND_INVITES && mode !== SEND_REMINDERS) {
      setActiveParticipation(null);
      setProfileSlideoutOpen(false);
      removeHash();
    }
  };

  const renderProfileSlideoutHeader = () => (
    <div className='flex items-center space-x-2'>
      <span className='h600 block'>{study.title}</span>
      {activeParticipation && <ParticipationPill participation={activeParticipation} />}
    </div>
  );

  const renderProfileSlideoutActions = (activeParticipation: Participation) => () =>
    canUpdate && (
      <BulkActions
        onSlideoutActions
        canSchedule={!!slots?.remaining && slots.remaining >= 1}
        participations={participations}
        closeAll={closeAll}
        selectedIds={[activeParticipation.id]}
        study={study}
        currentStatus={currentStatus}
        onSuccess={handleParticipationsUpdate}
        setLoading={setLoading}
        mode={mode}
        closeProfileSlideout={() => setProfileSlideoutOpen(false)}
        candidateId={activeParticipation.customer_id}
        setMode={setMode}
      />
    );

  const [storedSort, setStoredSort] = useLocalStorage<{ value: string; desc: boolean }>('participations-sort-value');
  const [columnsWidth, setColumnsWidth] = useLocalStorage<Record<string, number>>(
    ELocalStorageKeys.PARTICIPANTS_TABLE_COLUMNS_WIDTH
  );

  const [sort, setSort] = useState<{ value: string; desc: boolean }>({
    value: storedSort?.value || 'name',
    desc: !!storedSort?.desc
  });
  const { handleMouseOver, handleToggleCheckBox, resetOnClick, hoveredRows } = UseRowSelect(setSelectedIds);

  const toggleColVisibility = (name: string): void => {
    const newAttrs = visibleColumnNames.includes(name)
      ? compact(without(visibleColumnNames, name))
      : [...visibleColumnNames, name];

    setVisibleColumnNames(newAttrs);

    updateStudy({ id: study.id, visible_attrs: newAttrs });
  };

  const isVisible = (name: string) => visibleColumnNames.includes(name);

  const setSortValue = (value: string, desc: boolean): void => {
    setSort({ value, desc });
    setStoredSort({ value, desc });
  };

  const onSortChange = ({ value, desc = false }: { value: string; desc: boolean }): void => {
    setSortBy([{ id: value, desc }]);
    setStoredSort({ value, desc });
    setSortValue(value, desc);
  };

  const onGQ = ['video_call', 'unmoderated_test'].includes(style) || on_gq;

  const addFilter = useCallback(
    (filter: string) => {
      const def = definitions.find((d) => d.id === filter);

      if (def) {
        filtersHook.addFilter(def);
      }
    },
    [filtersHook.addFilter]
  );

  const onToggleCheckbox = (props: OnToggleProps) => {
    handleToggleCheckBox(props);
    setSelectedIds((prev) => {
      const currentId = (props.row as Row<ParticipationTableItem>).original.participation.id;
      let ids = prev;

      if (props.row.isSelected) {
        ids = prev.filter((id) => id !== currentId);
      }

      if (!props.row.isSelected && !prev.includes(currentId)) {
        ids = [...prev, currentId];
      }

      return ids;
    });
  };

  const setAllSelected = () => {
    setSelectedIds((prev) => {
      if (prev.length === filteredByStatus.length) {
        return [];
      } else {
        return filteredByStatus.map(({ id }) => id);
      }
    });
  };

  const allColumns = useMemo(() => {
    const builtColumns = buildParticipantsColumns({
      definitions,
      columnsWidth,
      addFilter,
      preScreenerHasIdealAnswers: pre_screener?.has_ideal_answers || false,
      preScreenerQuestions: pre_screener?.fields || [],
      surveyScreenerQuestions: survey_screener?.fields || [],
      customAttrs: candidateAttrs,
      canPII,
      onClickParticipation: (row) => handleClickRow(row.participation),
      onClickModerator: (row) => handleClickRowModerator(row.participation),
      onClickReviewTimeProposal: (row) => handleClickReviewTimeProposal(row.participation),
      onSelectIncentive: (row, value) => handleSelectIncentive(row.participation, value),
      onClickRateParticipation: (row, rating) => handleClickRateParticipation(row.participation, rating),
      status: currentStatus,
      style,
      currencySymbol,
      focus_group,
      hasScreener: study.has_screener,
      hasExternalCandidates: study.has_external_candidates_requests,
      onGQ,
      isVisible,
      setSortValue,
      handleMouseOver,
      handleToggleCheckBox: onToggleCheckbox,
      hasParticipantLevelIncentives,
      resetOnClick: () => {
        resetOnClick();
        setAllSelected?.();
      },
      hoveredRows,
      enableTeams,
      teams
    });

    return builtColumns.map((c) => ({
      ...c,
      toggleColVisibility
    }));
  }, [
    definitions,
    filtersHook.filters,
    visibleColumnNames,
    candidateAttrs,
    handleMouseOver,
    handleToggleCheckBox,
    hoveredRows,
    pre_screener,
    survey_screener,
    currentStatus,
    study.has_external_candidates_requests,
    filteredByStatus,
    teams
  ]);

  const defaultColumn = useMemo(
    () => ({
      disableFilters: true,
      Filter: () => ''
    }),
    []
  );

  const columnOptions: Option[] = useMemo(
    () =>
      [
        {
          name: 'contact_access',
          label: 'Contact access'
        },
        ...coreAttrs.filter((c) => !c.hide),
        ...(canPII ? [{ name: 'email', label: 'Email' }] : []),
        ...(hasParticipantLevelIncentives ? [{ name: 'incentive_in_whole_currency', label: 'Incentive' }] : []),
        ...(study.has_external_candidates_requests ? [{ name: 'recruiting_source', label: 'Source' }] : []),
        { name: 'participations_count', label: '# of studies' },
        { name: 'token', label: 'Token' },
        ...getDateAttrs(style, study.has_screener, onGQ),
        ...getStatusAttrs(currentStatus, style, onGQ),
        ...candidateAttrs.map((a) => ({ name: `extra.${a.name}`, label: a.label })),
        ...(study.has_external_candidates_requests
          ? EXTERNAL_ATTRS.map((a) => ({ name: `extra.${a.name}`, label: a.label }))
          : []),
        ...(pre_screener?.fields || []).map((question, i) => ({
          name: `preScreenerField.${question.id}`,
          label: question.label || `Screener Question #${calculateNumber(pre_screener?.fields || [], i)}`
        })),
        ...(survey_screener?.fields || []).map((question, i) => ({
          name: `surveyScreenerField.${question.id}`,
          label: question.label || `Survey Question #${calculateNumber(survey_screener?.fields || [], i)}`
        }))
      ].map(({ name, label }) => ({ label, value: name })),
    [candidateAttrs, currentStatus, pre_screener, survey_screener]
  );

  const hiddenColumns = useMemo(() => {
    return getHiddenColumnNames(columnOptions, visibleColumnNames);
  }, [columnOptions, visibleColumnNames]);

  const [updateCandidate] = api.useUpdateCandidateMutation();

  const updateParticipationData: UpdateCellData = useCallback(
    async (rowIndex, columnId, value) => {
      try {
        const res = await updateCellData({
          rowIndex,
          columnId,
          value,
          data,
          customAttrs: candidateAttrs,
          setCustomAttrs: setCandidateAttrs,
          updateCandidate
        });
        const participation = data.find((p) => p.participation.customer_id === rowIndex);
        const customer = compact(res)[0];

        if (customer && participation?.participation) {
          updateEntireParticipation({ ...participation?.participation, customer: customer });
        }

        showToast(toasts.successUpdateProfile());
      } catch (e) {
        showToast(failedAttrUpdate(e));
      }
    },
    [data]
  );

  const tableOptions = useMemo<TableOptions<ParticipationTableItem>>(
    () => ({
      columns: allColumns,
      data,
      updateCellData: updateParticipationData,
      defaultColumn,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetPage: false,
      initialState: {
        pageSize: 100,
        pageIndex: page - 1,
        filters: [],
        hiddenColumns,
        sortBy: [{ id: sort.value, desc: sort.desc }]
      }
    }),
    [page, allColumns, data, updateCellData, hiddenColumns]
  );

  const tableInstance = useTable<ParticipationTableItem>(
    tableOptions,
    useAbsoluteLayout,
    useResizeColumns,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder
  );

  const {
    setSortBy,
    rows,
    visibleColumns,
    gotoPage,
    pageOptions,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { selectedRowIds, pageIndex },
    toggleRowSelected,
    toggleAllRowsSelected,
    isAllRowsSelected,
    totalColumnsWidth
  } = tableInstance;

  const onResize = (columnId: string, columnSize: number) => {
    setColumnsWidth({ ...columnsWidth, [columnId]: columnSize });
  };

  useColumnResizeObserver(tableInstance.state, onResize);

  useEffect(() => {
    setPage(1);
    gotoPage(0);
  }, [filtersHook.filters, query, currentStatus]);

  useEffect(() => setPage(pageIndex + 1), [pageIndex]);

  useEffect(() => {
    if (selectedIds.length === 0 && isAllRowsSelected) {
      toggleAllRowsSelected(false);
    } else if (selectedIds.length === filteredByStatus.length && !isAllRowsSelected) {
      toggleAllRowsSelected(true);
    } else {
      rows.forEach((row) => {
        if (selectedIds.includes(row.original.participation.id) !== selectedRowIds[row.id]) {
          toggleRowSelected(row.id, selectedIds.includes(row.original.participation.id));
        }
      });
    }
  }, [rows.length, selectedIds.length]);

  const switchCandidate = (shift: number) => {
    if (!activeParticipation) return;

    const currentIndex = rows.findIndex((p) => p.original.participation.id === activeParticipation.id);

    if (rows.length === currentIndex + shift) {
      setActiveParticipation(rows[0].original.participation);
    } else if (currentIndex + shift < 0) {
      setActiveParticipation(rows[rows.length - 1].original.participation);
    } else {
      setActiveParticipation(rows[currentIndex + shift].original.participation);
    }
  };

  const unsetActiveParticipation = () => {
    setSelectedIds([]);
    setActiveParticipation(null);
    toggleAllRowsSelected(false);
    removeHash();
  };

  const handleParticipationsUpdate = (backgroundTask?: BackgroundTask): void => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
      refreshStudy(true, false);
    }

    unsetActiveParticipation();
  };

  const handleAddManual: (params: { candidate: Candidate; background_task?: BackgroundTask }) => void = ({
    background_task
  }) => {
    setStatus('shortlisted');
    if (background_task) {
      setBackgroundTasks([...backgroundTasks, background_task]);
      refreshStudy(true, false);
    }
  };

  const csvHeaders = useMemo(
    () =>
      columnOptions
        .filter(({ value }) => visibleColumnNames.includes(value))
        .map(({ label, value }) => ({ label, key: value })),
    [columnOptions, visibleColumnNames]
  );

  const selectedCsvData = useMemo(
    () =>
      selectedIds.map((id) => {
        const participation = participations.find((p) => p.id === id);
        const values = visibleColumns.reduce((prev, { id }) => {
          const accessor = visibleColumns.find((d) => d.id === id)?.accessor;
          let value;
          if (typeof accessor === 'function') {
            value = accessor({ participation });
          } else {
            value = participation?.[id];
          }
          return { ...prev, [id]: value };
        }, {});
        return values;
      }),
    [selectedIds, participations, csvHeaders, visibleColumns]
  );

  const csvData = useMemo(() => rows.map((row) => row.values), [rows]);

  const closeAll = () => {
    setMode(null);
    setActiveParticipation(null);
    removeHash();
  };

  const handleEmailSuccess = (backgroundTask?: BackgroundTask, mode?: StudyAction) => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
    }
    setMode(null);
    unsetActiveParticipation();
    refreshStudy();

    mode === SEND_SCREENER && setStatus('invited');
    mode && ![CANCEL_INTERVIEW_SLIDEOUT, SEND_INVITES].includes(mode) && refetchMessageRequests();
  };

  const showColumns = async (cols: string[]) => {
    setVisibleColumnNames(cols);

    await updateStudy({ id: study.id, visible_attrs: cols });
  };

  const gridTableProps = React.useMemo(
    () => ({
      tableInstance,
      tableColumnsOrder: ELocalStorageKeys.PARTICIPANTS_TABLE_ORDER,
      columnOptions,
      setVisibleColumnNames: showColumns,
      visibleColumnNames,
      tabs: buildColumnTabs(study),
      pagination: true
    }),
    [totalColumnsWidth, columnOptions, visibleColumnNames, selectedRowIds, rows, pageIndex]
  );

  const subsetButtonIsHidden = Object.keys(selectedRowIds).length > 0;

  const Table = React.useMemo(() => {
    return (
      <TableStateProvider>
        <GridTable {...gridTableProps} />
      </TableStateProvider>
    );
  }, [gridTableProps]);

  if (!initialFetchDoneParticipations) {
    return (
      <div className='px-page py-gutter h-full overflow-hidden rounded'>
        <Card height='100%' />
      </div>
    );
  }

  if (!isFetchingParticipations && participations.length === 0) {
    if (study.state === 'closed') return <NoParticipantsZDS />;

    return (
      <div className='px-page py-gutter'>
        <Alerts
          slots={slots}
          user={user}
          study={study}
          openFundModal={() => setMode(FUND_STUDY)}
          canAction={canUpdate}
          keys={['must_fund', 'pending_funding', 'funding_required']}
        />

        <BackgroundTasks
          onFinished={() => refreshStudy(true, false)}
          setBackgroundTasks={setBackgroundTasks}
          backgroundTasks={backgroundTasks}
          params={{ objectId: study.id }}
          backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
        />

        <ZDS
          study={study}
          setMode={setMode}
          setAddCandidatesDefaultStudyLimit={setAddCandidatesDefaultStudyLimit}
          studyLimits={studyLimits}
        />
        {mode === ADD_EXISTING && (
          <Modal fullScreen onClose={closeAll} closeOnEsc={false}>
            <div className='h-full mt-10 overflow-y-auto'>
              <div className='mb-10'>
                <AddCandidates
                  study={study}
                  teamId={teamId}
                  onAdd={handleAddCustomer}
                  refetchParties={refetchParties}
                  studySegment={addCandidatesDefaultStudyLimit}
                />
              </div>
            </div>
          </Modal>
        )}
        <EmailSlideOuts
          mode={mode}
          study={study}
          participations={[]}
          onClose={closeAll}
          onSuccess={handleEmailSuccess}
        />
        {mode === INVITE_VIA_LINK && (
          <Modal onClose={closeAll} size='md'>
            <InviteLink study={study} />
          </Modal>
        )}
        {mode === ADD_MANUAL && (
          <AddCandidateSlideOut
            style='addCandidate'
            onClose={closeAll}
            studyId={study.id}
            onAdd={handleAddManual}
            team={team}
          />
        )}
      </div>
    );
  }

  const isLoading = loading || isFetchingSearch || isFetchingParticipations;

  const canSchedule = !!slots?.remaining && slots.remaining >= selectedIds.length;

  return (
    <div className={cn('w-full', newParticipantsTable && 'flex flex-col flex-1')}>
      <MessageRequests
        className='desktop:px-8 px-4 mt-6'
        study={study}
        messageRequests={messageRequests}
        canAction={canUpdate}
      />
      <Alerts
        className='desktop:px-8 px-4 mt-6'
        slots={slots}
        user={user}
        study={study}
        openFundModal={() => setMode(FUND_STUDY)}
        canAction={canUpdate}
        keys={['must_fund', 'batching_on', 'pending_funding', 'funding_required']}
      />
      <div className='desktop:px-8 w-full px-4'>
        <BackgroundTasks
          onFinished={() => refreshStudy(true, false)}
          setBackgroundTasks={setBackgroundTasks}
          backgroundTasks={backgroundTasks}
          params={{ objectId: study.id }}
          backgroundTasksQuery={useGetStudyBackgroundTasksQuery}
        />
      </div>
      <div className={cn(newParticipantsTable && 'flex flex-col flex-1')}>
        <PageHeader
          className='desktop:px-8 px-4 pt-6'
          transparent
          filtersApplied={!!filtersHook.filters.length}
          renderTabs={() => (
            <div className='desktop:px-8 px-4'>
              <ParticipationStatusTabs
                current={currentStatus}
                counts={counts}
                study={study}
                onClick={unsetActiveParticipation}
                onSelect={setStatus}
              />
            </div>
          )}
          searchProps={{
            inputClassName: 'h-8 leading-4',
            addDebounce: true,
            onEnd: setQuery,
            onSearch: setSearchQuery,
            value: searchQuery
          }}
          renderFilters={() => (
            <div className='desktop:px-8 flex-1 px-4'>
              <TableFilters<Participation> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
            </div>
          )}
          renderBulkActions={() => (
            <>
              {!['closed', 'archived'].includes(study.state) &&
                (selectedIds.length > 0 && canUpdate ? (
                  <>
                    <BulkActions
                      medium
                      selectedIds={selectedIds}
                      study={study}
                      canSchedule={canSchedule}
                      currentStatus={currentStatus}
                      mode={mode}
                      participations={participations}
                      onSuccess={handleParticipationsUpdate}
                      closeAll={closeAll}
                      setLoading={setLoading}
                      setMode={setMode}
                      renderExportCSV={() => (
                        <ExportCsvButton
                          className='desktop:mx-0 mx-2 mb-4'
                          data={selectedCsvData}
                          disabled={!canExportCsv}
                          disabledTooltip='Available only for admins'
                          medium
                          type='link'
                          filename='participations-export.csv'
                          headers={csvHeaders}
                        />
                      )}
                    />
                  </>
                ) : (
                  <DefaultActions
                    renderExportCSV={() => (
                      <ExportCsvButton
                        className='desktop:mx-0 mx-2 mb-4'
                        data={csvData}
                        disabled={!canExportCsv}
                        disabledTooltip='Available only for admins'
                        medium
                        type='link'
                        filename='participations-export.csv'
                        headers={csvHeaders}
                      />
                    )}
                    canUpdate={canUpdate}
                    setMode={setMode}
                    mode={mode}
                    study={study}
                    closeAll={closeAll}
                    studyLimits={studyLimits || []}
                    setStudyLimit={setAddCandidatesDefaultStudyLimit}
                  />
                ))}
            </>
          )}
          h1='Participants'
          team={team}
        />

        <div className={cn('relative', newParticipantsTable && 'flex flex-col flex-grow')}>
          <div
            className={cn(
              'desktop:px-8 flex flex-col px-4 overflow-x-auto',
              newParticipantsTable && 'flex-grow pb-3 desktop:pb-4'
            )}
          >
            <div className='flex items-center py-2'>
              {isLoading && <Spinner className='w-4 h-4 mr-2' />}
              <span className='h400 text-gray-500'>
                {counts[currentStatus].toLocaleString()} {pluralize('participant', counts[currentStatus])}
                {selectedIds.length > 0 && ` (${selectedIds.length} selected)`}
              </span>
              <SelectSubsetDropdown
                onSelectSubset={selectSubset}
                toggleRowSelected={toggleRowSelected}
                subsetButtonIsHidden={subsetButtonIsHidden}
                rows={rows as any}
                setIds={(id) => setSelectedIds((prev) => [...prev, id])}
              />
              <div className='flex justify-end flex-1'>
                <Paginator
                  onClickNext={nextPage}
                  onClickPrevious={previousPage}
                  canClickNext={canNextPage}
                  canClickPrevious={canPreviousPage}
                  current={pageIndex + 1}
                  total={pageOptions.length || 1}
                />
                <SortDropdown wrapperClass='ml-2' options={columnOptions} value={sort.value} onChange={onSortChange} />
              </div>
            </div>
            {newParticipantsTable ? (
              <ParticipationsTable
                addFilter={addFilter}
                participations={filteredByStatus}
                onParticipationClick={handleClickRow}
                study={study}
                participationStatus={currentStatus}
                currentPage={pageIndex}
                sortBy={sort}
                selectedRowIds={selectedIds}
                onSelectRowIds={(rows) => setSelectedIds(rows)}
                updateEntireParticipation={updateEntireParticipation}
                setBackgroundTask={(task) => setBackgroundTasks([...backgroundTasks, task])}
                onTimeProposalClick={handleClickReviewTimeProposal}
              />
            ) : (
              Table
            )}
          </div>
        </div>
      </div>
      {notification && <Notification {...notification} onClose={() => setNotification(null)} />}
      {mode === ADD_EXISTING && (
        <Modal fullScreen onClose={closeAll} closeOnEsc={false}>
          <div className='h-full mt-10 overflow-y-auto'>
            <div className='mb-10'>
              <AddCandidates
                study={study}
                teamId={teamId}
                onAdd={handleAddCustomer}
                refetchParties={refetchParties}
                studySegment={addCandidatesDefaultStudyLimit}
              />
            </div>
          </div>
        </Modal>
      )}

      <EmailSlideOuts
        mode={mode}
        study={study}
        participations={slideOutParties}
        onClose={closeAll}
        onSuccess={handleEmailSuccess}
      />
      {mode === REVIEW_TIME_PROPOSAL && activeParticipation && (
        <ReviewTimeProposalSlideout
          participation={activeParticipation}
          onClose={closeAll}
          onSubmit={(backgroundTask) => {
            if (backgroundTask) {
              setBackgroundTasks([...backgroundTasks, backgroundTask]);
              refreshStudy();
            }
            closeAll();
          }}
        />
      )}

      {mode === INVITE_VIA_LINK && (
        <Modal onClose={closeAll} size='md'>
          <InviteLink study={study} />
        </Modal>
      )}

      {mode === ADD_MANUAL && (
        <AddCandidateSlideOut
          style='addCandidate'
          onClose={closeAll}
          studyId={study.id}
          onAdd={handleAddManual}
          team={team}
        />
      )}
      {mode === ADD_INTERVIEW && <NewInterview study={study} onClose={closeAll} />}
      {mode === EDIT_CUSTOM_ATTRIBUTES && (
        <ProfileContextProvider>
          <StudyAttrsSlideout study={study} setLoading={setLoading} onClose={closeAll} />
        </ProfileContextProvider>
      )}

      {activeParticipation?.customer && profileSlideoutOpen && (
        <ProfileContextProvider>
          <ProfileSlideout
            canUpdate={canUpdate}
            switchCandidate={switchCandidate}
            createAttr={create}
            key={`party-${activeParticipation.id}`}
            header={renderProfileSlideoutHeader()}
            candidate={activeParticipation.customer}
            activeParticipation={activeParticipation}
            onChangeCandidate={onChangeCandidate}
            open={profileSlideoutOpen}
            setLoading={setLoading}
            onClose={onCloseProfileSlideout}
            renderActions={renderProfileSlideoutActions(activeParticipation)}
            showSections={getProfileSlideOutSections({ study, activeParticipation })}
          />
        </ProfileContextProvider>
      )}
    </div>
  );
};
