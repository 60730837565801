import cn from 'classnames';
import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

import { LocationSVG } from '@components/svgs';

import { Input } from './Input';
import { useForwardedRef } from '@hooks/useForwardedRef';

type LocationInputValue = string;
type LocationInputDetailValue = {
  name?: string;
  notes?: string;
  address?: string;
  coordinates?: { latitude?: number; longitude?: number; }
};

type LocationInputHandler<T> = (
  value: T extends LocationInputDetailValue ? LocationInputDetailValue : LocationInputValue
) => void;
export type LocationInputBasicHandler = LocationInputHandler<LocationInputValue>;
export type LocationInputDetailHandler = LocationInputHandler<LocationInputDetailValue>;

export type LocationInputProps = {
  id: number | string;
  inputValue?: string;
  notesValue?: string;
  placeholder?: string;
  requestTypes?: string[];
  withDetails?: boolean;
  onFocus?: React.FormEventHandler;
  setValue?: LocationInputHandler<LocationInputValue | LocationInputDetailValue>;
  onChange?: (v: string) => void;
  className?: string;
  inputClassName?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
};

export const LocationInput = React.forwardRef<HTMLInputElement, LocationInputProps>(
  (
    {
      id,
      onChange,
      inputValue,
      notesValue,
      placeholder,
      requestTypes,
      withDetails,
      onFocus,
      setValue,
      className,
      inputClassName,
      readOnly,
      autoFocus
    },
    ref
  ) => {
    const {
      ready,
      suggestions: { status, data },
      value: autocompleteValue,
      setValue: setAutocompleteValue,
      clearSuggestions
    } = usePlacesAutocomplete({
      callbackName: 'initGoogleMap',
      defaultValue: inputValue,
      requestOptions: {
        types: requestTypes ?? []
      },
      debounce: 300
    });

    const [notes, setNotes] = React.useState<string | undefined>(notesValue);
    const inputRef = useForwardedRef<HTMLInputElement>(ref);

    const handleInput = (value: string) => {
      setAutocompleteValue(value);
    };

    const handleSelect = (
      suggestion: google.maps.places.AutocompletePrediction,
      withDetails: boolean | undefined
    ): void => {
      withDetails ? handleSelectDetails(suggestion) : handleSelectBasic(suggestion);
    };

    const handleSelectBasic = ({ structured_formatting }): void => {
      const secondaryValue = structured_formatting.secondary_text ? `, ${structured_formatting.secondary_text}` : '';
      const newValue = structured_formatting.main_text + secondaryValue;
      setAutocompleteValue(newValue, false);
      onChange?.(newValue);
      if (setValue) {
        setValue(newValue);
      }
      clearSuggestions();
    };

    const handleSelectDetails = ({ place_id }): void => {
      getDetails({ placeId: place_id, fields: ['name', 'formatted_address', 'geometry'] }).then((detail) => {
        if (typeof detail === 'string') {
          setAutocompleteValue(detail, false);
          onChange?.(detail);
          if (setValue) {
            setValue(detail);
          }
        } else {
          setAutocompleteValue(`${detail.name}, ${detail.formatted_address}`, false);
          onChange?.(`${detail.name}, ${detail.formatted_address}`);
          if (setValue) {
            setValue({
              name: detail.name,
              address: detail.formatted_address,
              coordinates: {
                latitude: detail.geometry?.location?.lat(),
                longitude: detail.geometry?.location?.lng()
              },
              notes: notes
            });
          }
          clearSuggestions();
        }
      });
    };

    const renderSuggestions = () =>
      data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <li
          key={place_id}
          role='option'
          aria-selected='false'
          onClick={() => handleSelect(suggestion, withDetails)}
          className='hover:bg-indigo-50 px-4 py-2 mt-1 rounded-md cursor-pointer'
        >
          <strong>{main_text}</strong>
          <small className='ml-2'>{secondary_text}</small>
        </li>
      );
    });

    useEffect(() => {
      if (ready && autoFocus) {
        inputRef.current?.focus();
      }
    }, [ready, autoFocus]);

    const notesOnBlur = () => {
      if (setValue) {
        setValue({
          notes: notes
        });
      }
    };

    return (
      <div className={className}>
        <div className='relative flex items-center'>
          <Input
            ref={inputRef}
            className={cn(
              'focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm block w-full border-gray-200 rounded-md',
              inputClassName
            )}
            autoFocus={autoFocus}
            wrapperClass='w-full'
            placeholder={placeholder}
            type='text'
            name={String(id)}
            onFocus={onFocus}
            value={autocompleteValue}
            onChange={handleInput}
            disabled={!ready}
            icon={LocationSVG}
            readOnly={readOnly}
          />
        </div>
        {status === 'OK' && <ul>{renderSuggestions()}</ul>}
        {withDetails && (<div className='relative flex items-center mt-2'>
          <Input
            className={cn(
              'focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm block w-full border-gray-200 rounded-md',
              inputClassName
            )}
            wrapperClass='w-full'
            placeholder='Instructions'
            type='text'
            onChange={(v) => setNotes(v)}
            onBlur={notesOnBlur}
            value={notes}
          />
        </div>)}
      </div>
    );
  }
);
