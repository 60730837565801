import * as UpChunk from '@mux/upchunk';

type Handlers = {
  onError: () => void;
  onProgress: (p: number) => void;
  onSuccess: () => void;
};
export const startMuxUpload = async (
  item: RecordingItem,
  endpoint: string,
  handlers: Handlers
): Promise<() => void> => {
  const upload = UpChunk.createUpload({
    endpoint,
    file: item.file,
    chunkSize: 5120 // Uploads the file in ~5mb chunks
  });
  upload.on('error', handlers.onError);
  upload.on('progress', (e) => handlers.onProgress((e as any).detail));
  upload.on('success', () => {
    handlers.onSuccess();
  });

  return () => upload.abort();
};
