export const CANDIDATE_MESSAGE_EVENTS = {
  booked: 'a booking confirmation',
  booked_reminder: 'a booking reminder',
  cancel_interview: 'an interview canceled',
  cancel_task: 'a task canceled',
  invite: 'a participation invite',
  invited_reminder: 'an invite reminder',
  reschedule_request: 'a reschedule request',
  screener: 'a screener invite',
  started_reminder: 'a started reminder',
  thanks: 'a thanks',
  welcome: 'a welcome',
  ad_hoc: 'an ad hoc'
};
