import cn from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { Popper } from '@components/common';
import { Tooltip } from '@components/shared/Tooltip';
import {
  MiniPlayerSVG,
  PlayerFullScreen,
  PlayerMute,
  PlayerPause,
  PlayerPlay,
  PlayerSpeaker,
  StepBack,
  StepForward
} from '@components/svgs';

import { PLAYBACK_RATES } from '../constants';
import { ControlsProps } from '../types';
import { formatSeconds } from '../utils';
import { PlaybackRates } from './PlaybackRates';
import { RangeBar } from './RangeBar';
import { Thumbnail } from './Thumbnail';

import type { FC } from 'react';

export const Controls: FC<ControlsProps> = ({
  isPlaying,
  duration,
  currentTime,
  buffer,
  volume,
  isFullscreen,
  playbackSpeed,
  className,
  show,
  hasMiniPlayer,
  play,
  pause,
  setVolume,
  setPlaybackSpeed,
  enterFullscreen,
  exitFullscreen,
  setMiniPlayerEnabled,
  rewind,
  fastForward,
  handleOnProgressBarChange,
  renderThumbnail
}) => {
  const [playbackRate, setPlaybackRate] = useState<number>(playbackSpeed);
  const [popperIsOpen, setPopperIsOpen] = useState<boolean>(false);
  const [rangeIsHover, setRangeIsHover] = useState<boolean>(false);
  const [rangeValue, setRangeValue] = useState<number>(0);
  const [thumbPos, setThumbPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const formatPlaybackRate = (rate: number): string => `${rate.toString()}x`;

  const handleOnVolumeBarChange = (value: number) => {
    setVolume((value * 1) / 100);
  };

  const handleOnProgressBarHover = (e: MouseEvent, value: number) => {
    const target = e.target as HTMLElement;
    const x = e.pageX;
    const y = target.getBoundingClientRect().top + 10;
    setThumbPos({ x, y });
    setRangeValue(value);
  };

  const handleOnPlaybackRateChange = (rate: number, closePopper: () => void) => {
    setPlaybackSpeed(rate);
    setPlaybackRate(rate);
    closePopper();
  };

  return (
    <section
      aria-label='Video controls'
      ref={wrapperRef}
      className={cn(
        'video-controls',
        'absolute',
        'bottom-0',
        'w-full',
        'pb-4',
        'text-sm',
        'text-white',
        {
          ['opacity-100']: show || popperIsOpen,
          ['opacity-0']: !show || !popperIsOpen
        },
        className
      )}
    >
      <div className='mb-2'>
        <RangeBar
          value={currentTime}
          max={duration}
          onChange={handleOnProgressBarChange}
          onHover={handleOnProgressBarHover}
          className='w-full'
          buffer={buffer}
          barClassName='bg-indigo-600'
          onMouseEnter={() => setRangeIsHover(true)}
          onMouseLeave={() => setRangeIsHover(false)}
          hideThumb
        />
        {rangeIsHover && (
          <Thumbnail position={thumbPos} className='w-36 h-28 relative flex flex-col items-center justify-center'>
            {renderThumbnail?.(rangeValue)}
          </Thumbnail>
        )}
      </div>
      <div className='flex items-center px-4'>
        <div className='flex items-center flex-1 space-x-3'>
          {isPlaying ? (
            <Tooltip content='Pause'>
              <button type='button' aria-label='Pause' className='focus:outline-none' onClick={pause}>
                <PlayerPause className='w-5 h-5' />
              </button>
            </Tooltip>
          ) : (
            <Tooltip content='Play'>
              <button type='button' aria-label='Play' className='focus:outline-none' onClick={play}>
                <PlayerPlay className='w-5 h-5' />
              </button>
            </Tooltip>
          )}
          {volume === 0 ? (
            <Tooltip content='Unmute'>
              <button type='button' className='focus:outline-none' onClick={() => setVolume(1)} aria-label='Unmute'>
                <PlayerMute className='w-5 h-5' />
              </button>
            </Tooltip>
          ) : (
            <Tooltip content='Mute'>
              <button type='button' className='focus:outline-none' onClick={() => setVolume(0)} aria-label='Mute'>
                <PlayerSpeaker className='w-5 h-5' />
              </button>
            </Tooltip>
          )}
          <RangeBar
            className='w-14'
            max={1}
            value={volume}
            onChange={handleOnVolumeBarChange}
            barClassName='bg-white'
          />
        </div>
        <div className='flex items-center justify-center flex-1'>
          <span className='flex-shrink-0 leading-none select-none'>
            {formatSeconds(currentTime)} / {formatSeconds(duration)}
          </span>
        </div>
        <div className='flex items-center justify-end flex-1 ml-auto space-x-3'>
          <Tooltip content='Step back 5 seconds'>
            <button type='button' className='focus:outline-none flex-shrink-0' onClick={() => rewind(5)}>
              <StepBack className='w-5 h-5' />
            </button>
          </Tooltip>
          <Tooltip content='Step forward 5 seconds'>
            <button type='button' className='focus:outline-none flex-shrink-0' onClick={() => fastForward(5)}>
              <StepForward className='w-5 h-5' />
            </button>
          </Tooltip>
          <Popper
            content={({ closePopper }) => (
              <PlaybackRates
                rates={PLAYBACK_RATES.concat([]).reverse()}
                activeRate={playbackRate}
                orientation='vertical'
                onChange={(rate) => handleOnPlaybackRateChange(rate, closePopper)}
              />
            )}
            offset={[0, 10]}
            zIndex={40}
            boundary={isFullscreen && wrapperRef.current ? wrapperRef.current : undefined}
            renderOnBodyRoot={!isFullscreen}
            onOpen={() => setPopperIsOpen(true)}
            onClose={() => setPopperIsOpen(false)}
            closeOnClickOutside
          >
            <div className='inline-flex'>
              <Tooltip content='Playback speed'>
                <button
                  data-testid='current-speed'
                  className='focus:outline-none flex-shrink-0 text-sm leading-none cursor-pointer'
                >
                  {formatPlaybackRate(playbackRate)}
                </button>
              </Tooltip>
            </div>
          </Popper>
          {hasMiniPlayer && (
            <Tooltip content='Mini player'>
              <button
                type='button'
                className='focus:outline-none flex-shrink-0 leading-none'
                onClick={() => setMiniPlayerEnabled?.(true)}
              >
                <MiniPlayerSVG className='w-5 h-5' />
              </button>
            </Tooltip>
          )}
          {isFullscreen && (
            <Tooltip content='Exit full screen'>
              <button
                data-testid='exit-fullscreen'
                type='button'
                className='focus:outline-none flex-shrink-0'
                onClick={exitFullscreen}
              >
                <PlayerFullScreen className='w-5 h-5' />
              </button>
            </Tooltip>
          )}
          {enterFullscreen && (
            <Tooltip content='Full screen'>
              <button
                data-testid='enter-fullscreen'
                type='button'
                className='focus:outline-none flex-shrink-0'
                onClick={enterFullscreen}
              >
                <PlayerFullScreen className='w-5 h-5' />
              </button>
            </Tooltip>
          )}
        </div>
      </div>
    </section>
  );
};
