import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';

type UseScreenerResults = {
  isLoading: boolean;
  count?: number;
  total?: number;
  results: ScreenerResponseResults;
};

type ResultsStore = {
  individual: ScreenerResponseResults['individual'];
  summary: Record<number, ScreenerResponseResultsSummary>;
};
export const useScreenerResults = (studyId?: number, screenerId?: number): UseScreenerResults => {
  const [count, setCount] = useState<number>();
  const [total, setTotal] = useState<number>();

  const [results, setResults] = useState<ResultsStore>({
    individual: [],
    summary: {}
  });
  const addResults = (r: ScreenerResponseResults) => {
    const mapped = r.summary.reduce(
      (m, result) => {
        const _ref = m[result.field.id] || { field: result.field, answers: [] };
        _ref.answers = _ref.answers.concat(result.answers);
        _ref.blockable_id = result.blockable_id;
        m[result.field.id] = _ref;
        return m;
      },
      { ...results.summary }
    );

    setResults({
      individual: [...results.individual, ...r.individual],
      summary: mapped
      // summary: [...results.summary, ...r.summary] how to merge?
    });
  };

  const [isLoading, setIsLoading] = useState(!!studyId && !!screenerId);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { isSuccess, data } = api.useGetStudyScreenerResultsPagedQuery(
    { study_id: studyId as number, screener_id: screenerId as number, page: currentPage },
    { skip: !isLoading || !studyId || !screenerId }
  );

  useEffect(() => {
    if (isSuccess && data?.data && data?.meta?.page === currentPage) {
      addResults(data.data.results);
      setTotal(data.meta.count);

      if (data.meta.page === data.meta.pages) {
        setIsLoading(false);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setCount(results.individual.length);
  }, [results]);

  const sortedSummary = Object.values(results.summary).sort((a, b) => a.field.position - b.field.position);

  const sortedIndividual = [...results.individual].map((res) => {
    const answers_json = [...res.answers_json].sort((a, b) => {
      return (
        sortedSummary.findIndex((i) => i.field.id === a.field_id) -
        sortedSummary.findIndex((i) => i.field.id === b.field_id)
      );
    });
    return { ...res, answers_json };
  });

  return {
    isLoading,
    count,
    total,
    results: {
      individual: sortedIndividual,
      summary: sortedSummary
    }
  };
};
