import * as React from 'react';
import cn from 'classnames';
import ReactSkeleton from 'react-loading-skeleton';

export const Circle: React.FC<{
  h?: string;
  mb?: string;
  width?: number | string | undefined;
  inline?: boolean;
}> = ({ h = '10', mb, width = 500, inline }) => (
  <ReactSkeleton
    inline={inline}
    duration={1}
    width={width}
    circle
    className={cn(`h-${h}`, 'rounded-md leading-none', { [`mb-${mb}`]: !!mb, block: !inline })}
  />
);
