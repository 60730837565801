import { Cell, Header, Table } from '@tanstack/react-table';
import { format } from 'date-fns';

import { isValidDate } from '@components/utils';
import { objectAssign } from '@helpers/objectAssign';

import { CellType, OnUpdateCellParams } from '../types';

export const updateCellData = <D extends Record<string, any>>(
  data: D[],
  { row, columnId, value }: OnUpdateCellParams<D>
): D[] => data.map((item, index) => (index === row.index ? objectAssign<D>(data[row.index], columnId, value) : item));

export const isEmpty = (value: any) => value == null || value === '';

export const getPreviousCellSize = <D extends Record<string, any>>(
  cells: Cell<D, unknown>[] | Header<D, unknown>[],
  cell: Cell<D, unknown> | Header<D, unknown>
) => {
  const index = cells.findIndex(({ id }) => id === cell.id);

  if (index === 0) return 0;

  return cells[index - 1].column.getSize();
};

export const columnSorting = <D extends Record<string, any>>(sortBy: CollectionView['sort'], table: Table<D>) => {
  const header = table.getFlatHeaders().find((header) => header.id === sortBy.value);

  if (header) {
    header.column.toggleSorting(sortBy.desc);
  }
};

export const parseCellValue = (initialValue: any, type: CellType) => {
  const value = sanitizeValue(initialValue, type);

  if (value === null || value === '' || value === undefined) return '-';

  switch (type) {
    case 'datetime':
      return format(value, 'PPPpp');
    case 'multiple_choice':
      return value.join(', ');
    case 'number':
      return value.toString();
    case 'boolean':
      return value ? 'Yes' : 'No';
    default:
      return value;
  }
};

export const sanitizeValue = (value: any, type: CellType) => {
  if (type === 'multiple_choice') {
    return Array.isArray(value) ? value : [];
  }

  if (type === 'datetime') {
    return isValidDate(value) ? new Date(value) : null;
  }

  return value;
};
