import * as React from 'react';

import { api } from '@api/reduxApi';
import { RadioGroup } from '@components/common';

export const FocusGroupSessionInput: React.FC<{
  studyId: number;
  interviewAt?: string;
  setInterviewAt: (date: string) => void;
}> = ({ studyId, interviewAt, setInterviewAt }) => {
  const { data = [], isError: fetchError, isSuccess } = api.useGetRepoSessionsQuery({ studyId });
  return (
    <div>
      <RadioGroup
        options={data.map((session) => ({
          label: session.session_at?.toString() || 'Session time',
          value: session.session_at?.toString() || ''
        }))}
        name='interview_at'
        selected={interviewAt || ''}
        onChange={setInterviewAt}
        // className='space-y-2'
      />
    </div>
  );
};
