import * as React from 'react';

import { api } from '@api/reduxApi';
import { useToaster } from '@stores/toaster';

import { BulkAddDropdown } from '../../shared/BulkAddDropdown';
import { Placement } from '@popperjs/core';
import { Offset } from '@components/common/Popper';
import { HTMLAttributes, ReactElement } from 'react';

type Props = {
  disabled?: boolean;
  selectedArtifacts: string[];
  medium?: boolean;
  primary?: boolean;
  studyId?: number | null;
  renderButton?: (props: HTMLAttributes<HTMLButtonElement>) => ReactElement;
  popperPlacement?: Placement;
  popperOffset?: Offset;
  renderOnBodyRoot?: boolean;
  onlyContent?: boolean;
  hide?: () => void;
  trackCreateClick?: (count: number) => void;
  trackAddClick?: (count: number) => void;
};

export const BulkAddToInsightDropdown: React.FC<Props> = ({
  disabled: propDisabled,
  selectedArtifacts,
  studyId,
  onlyContent,
  trackCreateClick,
  trackAddClick,
  ...rest
}) => {
  const showToast = useToaster();

  const [insertArtifacts, { isLoading: isInserting }] = api.useInsertArtifactsIntoDocumentMutation();

  const [create, { isLoading: isCreating }] = api.useCreateInsightMutation();

  const handleClickCreate = (title: string) => {
    trackCreateClick?.(selectedArtifacts.length);
    create({ title, project_id: studyId })
      .unwrap()
      .then((artifact) => {
        handleAddToInsight(artifact);
      });
  };

  const disabled = propDisabled || isInserting;

  const handleAddToInsight = (insight: Insight) => {
    trackAddClick?.(selectedArtifacts.length);
    insertArtifacts({
      documentId: insight.document_id,
      artifactIds: selectedArtifacts
    })
      .unwrap()
      .then(() => {
        showToast({
          heading: 'Artifacts added',
          text: 'Artifacts were added to the selected insight',
          icon: 'success',
          action: {
            text: 'View insight',
            href: `/insights/${insight.slug}`,
            target: '_blank'
          }
        });
      })
      .catch(() => {
        showToast({
          heading: 'Something went wrong',
          text: 'Artifacts could not be added to the selected insight',
          icon: 'error'
        });
      });
  };

  return (
    <BulkAddDropdown<Insight>
      disabled={disabled}
      icon='addToInsight'
      onlyContent={onlyContent}
      selectedCount={selectedArtifacts.length}
      studyId={studyId}
      getQuery={api.useGetInsightsQuery}
      onCreate={handleClickCreate}
      artifactType='insight'
      hasCreateMode
      isCreating={isCreating}
      handleAdd={handleAddToInsight}
      {...rest}
    />
  );
};
