import { DependencyList } from 'react';

import { Config } from './extensions';

// tiptap useEditor can get a dependency list to re-render the editor
// since we're passing dynamic info on our config object, we need to make sure the editor re-renders when that changes
// we should aim to use only primitives when creating those dependencies
export const getDependencyList = (config: Partial<Config>): DependencyList => {
  // using any here because DependencyList is readonly
  // we're still asserting the same type which is any
  const dependencyList: any[] = [];

  if (config.cta?.as) {
    dependencyList.push(config.cta.as);
  }

  if (config.transcript) {
    const speakers = config.transcript?.defaultSpeakers;

    if (speakers && speakers.length) {
      dependencyList.push(speakers.map((speaker) => speaker.email).join('_'));
    }
  }

  return dependencyList;
};
