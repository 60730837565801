import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { format as timeagoFormat } from 'timeago.js';

import { AvatarFromId, Button, Text } from '@components/common';
import { InterviewTemplateSVG } from '@components/svgs';
import { DuplicateEmailButton } from '@components/TemplatesApp/components/DuplicateEmailButton';
import Tippy from '@tippyjs/react';

import { DEFAULT_TITLES } from '../consts';
import { DeleteTemplateModal } from './DeleteTemplateModal';

export type Props<T extends AbstractTemplate> = {
  allowUpdate?: boolean;
  template: T;
  onEdit?: (template: T, createNew?: boolean) => void;
  className?: string;
  useTemplateButton?: boolean;
  onTemplateUse?: (template: T) => void;
  kind: TemplateKind;
};

const ConditionalLink = ({ link, to, children, ...rest }) =>
  link ? (
    <Link {...rest} to={to}>
      {children}
    </Link>
  ) : (
    <div {...rest}>{children}</div>
  );

export const TemplateCard = <T extends AbstractTemplate>(props: Props<T>): React.ReactElement => {
  const { allowUpdate = false, template, onEdit, className, useTemplateButton = false, onTemplateUse, kind } = props;
  const [mouseOver, setMouseOver] = useState(false);

  const onUpdate: React.MouseEventHandler = (e) => {
    e.preventDefault();
    onEdit?.(template);
  };

  const onDuplicate: React.MouseEventHandler = (e) => {
    e.preventDefault();
    onEdit?.(template, true);
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const openModal = (e?: React.MouseEvent) => {
    e?.preventDefault();
    setDeleteModalOpen(true);
  };

  const modalClose = (e?: React.MouseEvent) => {
    e?.preventDefault();
    setDeleteModalOpen(false);
  };

  return (
    <div
      data-testid='template-card'
      className='xx-template-card relative w-full'
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <ConditionalLink link={!useTemplateButton} to={template.id.toString()} title={template.title}>
        <div className='desktop:w-auto hover:bg-gray-50 hover:border-indigo-600 w-full text-gray-700 bg-white border border-gray-200 rounded-md'>
          <section className='px-2'>
            <div className='bg-gray-50 flex items-center justify-center h-40'>
              {template.illustration_url ? (
                <img alt='' className='max-w-full max-h-full' loading='lazy' src={template.illustration_url} />
              ) : (
                <InterviewTemplateSVG />
              )}
            </div>
          </section>
          <div className='p-4'>
            <Text bold truncate className='mb-1'>
              {template.title || DEFAULT_TITLES[kind]}
            </Text>
            {mouseOver && useTemplateButton && (
              <Button onClick={() => onTemplateUse?.(template)} className='w-full mt-5' primary>
                Use template
              </Button>
            )}
            {(!mouseOver || !useTemplateButton) && (
              <>
                <div className='flex items-center h-6'>
                  <Text h='200' color='gray-500'>
                    Updated {timeagoFormat(template.updated_at)}
                  </Text>
                </div>
                <div className='flex items-center mt-1'>
                  <div className='flex flex-row flex-row-reverse justify-end flex-1'>
                    <AvatarFromId key={`template_${template.id}_avatar`} userId={template.creator.id} />
                  </div>
                  <div>
                    {kind === 'EmailTemplate' && <DuplicateEmailButton onClick={onDuplicate} />}
                    {onEdit && allowUpdate && (
                      <Tippy className='bg-gray-500 rounded-lg' content='Edit template' arrow={false}>
                        <Button small rounded text className='border-none' onClick={onUpdate} icon='pencil' />
                      </Tippy>
                    )}
                    {allowUpdate && (
                      <Tippy className='bg-gray-500 rounded-lg' content='Delete template' arrow={false}>
                        <Button small text className='border-none' rounded onClick={openModal} icon='trash' />
                      </Tippy>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ConditionalLink>
      <DeleteTemplateModal
        id={template.id}
        title={template.title}
        open={deleteModalOpen}
        onClose={modalClose}
        kind={kind}
      />
    </div>
  );
};
