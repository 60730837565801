import * as React from 'react';
import { useUser } from '@hooks/useUser';
import { usePermission } from '@hooks/usePermission';
import { Avatar, Button } from '@components/common';
import { AdminDropdowns, CanEditDropdowns, CanViewDropdowns } from './components';

interface TeamUserRowProps {
  study: Study;
  updateStudy: (study: { id: number } & Partial<Study>) => void;
  user: TeamUser;
  onClickAdd?: () => void;
  onClickRemove?: () => void;
  isMember?: boolean;
  role?: AccountRole;
}

export type Role = 'Owner' | 'Can edit' | 'Can view';

export const TeamUserRow: React.FC<TeamUserRowProps> = ({
  study,
  updateStudy,
  user,
  onClickAdd,
  onClickRemove,
  isMember = true,
  role
}) => {
  const { id: currentUserId } = useUser();
  // TODO: move to use Permission('changeStudyOwner')
  const isAdmin = usePermission('isAdmin')();
  const currentUserIsOwner = study.owner?.id === currentUserId;
  const currentUserIsCreator = study.creator_id === currentUserId;

  const isStudyOwner = study.owner?.id === user.id;
  const isStudyCreator = study.creator_id === user.id;
  const isAnObserver = user?.role === 'observer';
  const isPhantom = user?.is_phantom;

  const userWithEditRights = ['admin', 'creator'].includes(role as any);

  const canSetOwner = (isAdmin || currentUserIsOwner) && !isAnObserver && !isPhantom;
  const cantSetOwnerMessage = () => {
    if (!isAdmin) {
      return 'Only account admins can reassign study owner.';
    }
    if (isAnObserver) {
      return 'Observer may not own a study.';
    }
    if (isPhantom) {
      return 'This user must sign up to join the account first.';
    }
  };

  const studyRole: Role = isStudyOwner ? 'Owner' : userWithEditRights ? 'Can edit' : 'Can view';

  const renderTeamMembersRow = () => {
    if (currentUserIsOwner || isAdmin) {
      return (
        <AdminDropdowns
          onClickRemove={onClickRemove}
          setOwner={() =>
            updateStudy({
              id: study.id,
              owner_id: user.id,
              ...(user.default_nylas_calendar_id ? { nylas_calendar_id: user.default_nylas_calendar_id } : {})
            })
          }
          cantSetOwnerMessage={cantSetOwnerMessage}
          studyRole={studyRole}
          isStudyOwner={isStudyOwner}
          canSetOwner={canSetOwner}
        />
      );
    } else if (currentUserIsCreator) {
      return (
        <CanEditDropdowns
          setOwner={() => updateStudy({ id: study.id, owner_id: user.id })}
          isCurrentUser={currentUserId === user.id}
          studyRole={studyRole}
          isStudyOwner={isStudyOwner}
          onRemove={onClickRemove}
          isStudyCreator={isStudyCreator}
          isAdmin={isAdmin}
        />
      );
    } else {
      return (
        <CanViewDropdowns
          isCurrentUser={currentUserId === user.id}
          onRemove={onClickRemove}
          isOwnerOrCreator={isStudyOwner || isStudyCreator}
          studyRole={studyRole}
        />
      );
    }
  };

  return (
    <li className='group flex items-center justify-between'>
      <Avatar user={user} size='xl' />
      <div className='flex flex-col flex-1 ml-2'>
        <p className='font-medium text-gray-700'>
          {user.name}
          {currentUserId == user.id ? ' (You)' : ''}
        </p>
        <p className='text-xs text-gray-500'>{user.job_title}</p>
      </div>
      {isMember ? (
        renderTeamMembersRow()
      ) : (
        <Button className='w-32 m-1' primary onClick={() => onClickAdd?.()}>
          {currentUserId == user.id ? 'Add yourself' : 'Add'}
        </Button>
      )}
    </li>
  );
};
