import React, {
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
  VFC
} from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';

import { Spinner, Text } from '@components/common';
import { getHighlightDisplayTitle } from '@components/utils';
import { Tooltip } from '@components/shared/Tooltip';
import { AskAiSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';

interface Props {
  highlight: Highlight;
  onSave?: (title: string) => void;
}

export const HighlightMenuTitle: VFC<Props> = ({ highlight, onSave }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [title, setTitle] = useState<string>(highlight.title ?? '');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { account } = useAccount();

  const handleOnShowTextArea: MouseEventHandler<HTMLDivElement> = (_) => {
    setShowTextArea(true);

    requestAnimationFrame(() => {
      textAreaRef.current?.focus();
    });
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      setShowTextArea(false);
      setIsLoading(false);
      onSave?.(title);
    }
  };

  const handleOnBlur: FocusEventHandler<HTMLTextAreaElement> = () => {
    setShowTextArea(false);
    setIsFocused(false);

    if (highlight.title) {
      setIsLoading(false);
    }

    if (title) {
      onSave?.(title);
    }
  };

  useEffect(() => {
    if (account.ai_enabled && !isFocused && !highlight.title) {
      setIsLoading(true);
    }

    if ((highlight.title && !isFocused) || !account.ai_enabled) {
      setTitle(getHighlightDisplayTitle(highlight));
      setIsLoading(false);
    }
  }, [account.ai_enabled, isFocused, highlight.title]);

  return (
    <Tooltip content='Edit title' placement='top-start' isDisabled={showTextArea}>
      <div role='button' tabIndex={0} className='flex items-center px-2 py-1' onClick={handleOnShowTextArea}>
        {showTextArea ? (
          <ReactTextareaAutosize
            ref={textAreaRef}
            className='h400 focus:outline-none w-full p-0 px-2 py-1 font-bold border-0 resize-none'
            minRows={1}
            maxRows={2}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={handleOnBlur}
            onKeyDown={handleOnKeyDown}
            value={title}
          />
        ) : (
          <>
            {isLoading ? (
              <div
                role='button'
                tabIndex={0}
                className='hover:bg-gray-50 hover:border-gray-200 flex items-center justify-between w-full px-2 py-1 border border-transparent'
              >
                <AskAiSVG className='w-4 h-4 mr-2 text-indigo-600' />
                <Text className='text-sm' bold>
                  AI generating title...
                </Text>
                <Spinner className='w-4 h-4 ml-auto text-indigo-600' />
              </div>
            ) : (
              <div className='hover:bg-gray-50 hover:border-gray-200 w-full px-2 py-1 border border-transparent'>
                <Text as='h2' h='400' className='multi-line-truncate w-full font-bold'>
                  {title}
                </Text>
              </div>
            )}
          </>
        )}
      </div>
    </Tooltip>
  );
};
