import * as React from 'react';
import { useState } from 'react';

import { Button, DropdownLink, Popper } from '@components/common';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { Views } from '@components/shared/Views';
import { DownloadSVG, FullPageViewSVG, SolidKebabSVG, TrashSVG } from '@components/svgs';
import { DeleteModal } from '@components/RepositoryApp/hits/artifacts/DeleteModal';
import { ShareAccessDropdown } from '@components/shared/ShareAccess/components/ShareAccessDropdown';
import { useToaster } from '@stores/toaster';
import * as toasts from '@components/RepositoryApp/hits/artifacts/toasts';
import { Attrs } from '../hooks/useContentRenderer';
import { useDestroyDocumentHighlightMutation } from '@components/shared/Tiptap/api';
import { HighlightArtifact } from '../PreviewContentSlideout';
import { api } from '@api/reduxApi';

interface Props {
  pauseVideo?: () => void;
  downloadUrl?: string;
  canUpdate: boolean;
  canDelete: boolean;
  artifact: HighlightArtifact;
  href: string;
  views?: ViewCount[];
  updateModel?: (attrs: Partial<Attrs>) => void;
  access: ShareAccess;
  onClose: () => void;
  isLoading?: boolean;
  reelPreviewProps?: { onAddToHighlightReel: () => void; onRemove: () => void };
  model?: Highlight | Clip;
}

export const Footer: React.FC<Props> = ({
  artifact,
  model,
  href,
  views,
  downloadUrl,
  canUpdate,
  canDelete,
  pauseVideo,
  onClose,
  access,
  updateModel,
  reelPreviewProps
}) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [destroyDocumentHighlight] = useDestroyDocumentHighlightMutation();
  const [destroyClip] = api.useDestroyClipMutation();

  const type = artifact.objectID.split('_')[0];

  const documentId = type === 'Clip' ? (model as Clip)?.highlight?.document_id : (model as Highlight)?.document_id;

  const [value, setValue] = useState<ShareAccess>(access);

  const showToast = useToaster();

  const handleOnClipDelete = async () => {
    try {
      if (type === 'Highlight' && documentId && model) {
        await destroyDocumentHighlight({
          documentId,
          highlightId: (model as Highlight).id
        }).unwrap();
      }

      if (type === 'Clip') {
        await destroyClip({ highlightUuid: String(artifact.model_id) }).unwrap();
      }

      showToast(toasts.successDeleteClip(artifact.title || 'Untitled highlight'));
    } catch {
      showToast(toasts.failedDeleteClip());
    }
  };

  const handleChange = (access: ShareAccess) => {
    setValue(access);
    updateModel?.({ access });
  };

  const { onAddToHighlightReel, onRemove } = reelPreviewProps ?? {};

  return (
    <>
      <div className='flex-nowrap flex items-center justify;-end space-x-3'>
        {reelPreviewProps && artifact.isActive && (
          <Button className='whitespace-nowrap' icon='trash' target='_blank' onClick={onRemove}>
            Remove
          </Button>
        )}
        {reelPreviewProps && !artifact.isActive && (
          <Button className='whitespace-nowrap' primary icon='plus' target='_blank' onClick={onAddToHighlightReel}>
            Add to reel
          </Button>
        )}

        {artifact.kind !== 'Story' && (
          <ShareAccessDropdown
            readOnly={!canUpdate}
            aboveButton
            primary={!reelPreviewProps}
            value={value}
            onChange={handleChange}
            linkUrl={window.location.origin + href}
          />
        )}
        {views && <Views maxCount={1} aboveButton views={views} />}
        {!reelPreviewProps && (
          <Button
            spa
            outline
            data-testid='view-in-full-page'
            icon='fullPageView'
            target='_blank'
            href={href}
            onClick={pauseVideo}
          >
            View on full page
          </Button>
        )}
        <CopyURLButton data-testid='copy-url' outline className='p-6' text={`${location.origin}${href}`} icon='link' />

        {(downloadUrl || canDelete) && (
          <Popper
            closeOnClickOutside
            closeOnEscape
            renderOnBodyRoot={false}
            placement='top-end'
            offset={[0, 24]}
            content={() => (
              <div className='whitespace-nowrap text-left ring-1 ring-black ring-opacity-5 py-1 bg-white rounded-md shadow-lg'>
                {reelPreviewProps && (
                  <DropdownLink
                    className='flex items-center'
                    data-testid='view-in-full-page'
                    target='_blank'
                    href={href}
                    onClick={pauseVideo}
                  >
                    <FullPageViewSVG className='mr-2' />
                    View on full page
                  </DropdownLink>
                )}
                {downloadUrl && (
                  <DropdownLink
                    data-testid='download'
                    className='flex items-center'
                    onClick={() => window.open(downloadUrl)}
                  >
                    <DownloadSVG className='mr-2' />
                    Download
                  </DropdownLink>
                )}

                {canDelete && (
                  <DropdownLink
                    className='flex items-center'
                    color='red-600'
                    hoverColor='red-700'
                    data-testid='delete'
                    onClick={() => setDeleteModal(true)}
                  >
                    <TrashSVG className='mr-2' />
                    Delete
                  </DropdownLink>
                )}
              </div>
            )}
          >
            <button data-testid='more' className='focus:outline-none' name='more'>
              <SolidKebabSVG className='w-4 h-4' />
            </button>
          </Popper>
        )}
      </div>
      <DeleteModal
        onConfirm={async () => {
          if (['Clip', 'Highlight'].includes(artifact.kind)) {
            await handleOnClipDelete();
          }
          onClose();
          setDeleteModal(false);
        }}
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
        selected={artifact}
      />
    </>
  );
};
