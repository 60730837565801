import { createContext, useContext } from 'react';
import { HighlightArtifact } from '@components/RepositoryApp/components/PreviewContentSlideout';

export interface Context {
  reload: () => void;
  selectedArtifacts: string[];
  setSelectedArtifacts: (artifacts: string[]) => void;
  previewSlideout: HighlightArtifact | null;
  setPreviewSlideout: (artifact: HighlightArtifact | null) => void;
  study?: Study;
  setBackgroundTasks?: (setBackgroundTasks: BackgroundTask) => void;
}

export const RepositoryContext = createContext<Context | undefined>(undefined);

export const useRepositoryContext = () => {
  const repositoryContext = useContext<Context | undefined>(RepositoryContext);

  if (repositoryContext === undefined) throw new Error();

  return repositoryContext;
};
