import React, { SVGProps } from 'react';

export const CompleteSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m4.5 8.71479 1.42917 2.02821c.05421.081.12697.1479.21224.1951.08527.0471.18057.0733.27797.0762.09741.0029.1941-.0175.28203-.0595s.16456-.1044.22351-.182l4.57508-5.78842' />
      <path d='m1.4375 8c0 1.74048.6914 3.4097 1.92211 4.6404s2.89991 1.9221 4.64039 1.9221 3.4097-.6914 4.6404-1.9221 1.9221-2.89992 1.9221-4.6404-.6914-3.40968-1.9221-4.64039-2.89992-1.92211-4.6404-1.92211-3.40968.6914-4.64039 1.92211-1.92211 2.89991-1.92211 4.64039z' />
    </g>
  </svg>
);
