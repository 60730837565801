import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { UploadCSVModal } from '../UploadCSVModal';

export const NewCustomerImportPage: React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const teamIdStr = searchParams.get('team_id');
  const teamId = teamIdStr ? parseInt(teamIdStr) : null;

  const studyIdStr = searchParams.get('study_id');
  const studyId = studyIdStr ? parseInt(studyIdStr) : null;

  return <UploadCSVModal teamId={teamId} studyId={studyId} />;
};
