import { useEffect } from 'react';

const snapTo = (num: number, snap: number) => Math.floor(num / snap) * snap;

interface UseDragToResizeParams {
  id: string;
  snapHeight: number;
  onDragEnd: (pxDiff: number) => void;
  dragResizeState: UseDragToResizeHookState;
  setDragResizeState: (value: UseDragToResizeHookState) => void;
}
export interface UseDragToResizeHookState {
  handle: string | null;
  mousey: number | null;
  dragstarty: number | null;
}
interface UseDragToResizeHook {
  onMouseDown: React.MouseEventHandler;
  isDragging: boolean;
  pxDiff: number;
}
export const useDragToResize = (params: UseDragToResizeParams): UseDragToResizeHook => {
  const SNAP_HEIGHT = params.snapHeight;
  const state = params.dragResizeState;
  const { handle, mousey, dragstarty } = state;
  const isDragging = handle === params.id;

  const pxDiff = isDragging && mousey && dragstarty ? snapTo(mousey - dragstarty, SNAP_HEIGHT) : 0;

  useEffect(() => {
    if (isDragging) {
      startDragging(state);
    }
  }, []);

  function startDragging(state: UseDragToResizeHookState) {
    const onmousemove: EventListener = (e: MouseEvent) => params.setDragResizeState({ ...state, mousey: e.pageY });
    const onmouseup: EventListener = (e: MouseEvent) => {
      document.getElementsByTagName('html')[0].classList.remove('cursor-move');
      document.removeEventListener('mouseup', onmouseup);
      document.removeEventListener('mousemove', onmousemove);
      params.setDragResizeState({
        handle: null,
        dragstarty: null,
        mousey: null
      });
      params.onDragEnd(snapTo(e.pageY - (state.dragstarty as any), SNAP_HEIGHT));
    };

    document.getElementsByTagName('html')[0].classList.add('cursor-move');
    document.addEventListener('mouseup', onmouseup);
    document.addEventListener('mousemove', onmousemove);
  }

  const onMouseDown: React.MouseEventHandler = (e) => {
    e.preventDefault();
    const state: UseDragToResizeHookState = {
      mousey: e.pageY,
      dragstarty: e.pageY,
      handle: params.id
    };
    params.setDragResizeState(state);
    startDragging(state);
  };

  return {
    onMouseDown,
    isDragging,
    pxDiff
  };
};
