import React, { SVGProps } from 'react';

export const BankIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M1 15.5H15' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M15 5.49997H1L7.45933 1.65864C7.62061 1.55499 7.80829 1.49988 8 1.49988C8.19172 1.49988 8.37939 1.55499 8.54067 1.65864L15 5.49997Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M14 7.5V13.5H12V7.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M9 7.5V13.5H7V7.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4 7.5V13.5H2V7.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
