import React, { SVGProps } from 'react';

export const ProtectionSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.7049 4.11971C14.6967 3.68773 14.6889 3.27965 14.6889 2.88486C14.6889 2.57556 14.4382 2.32481 14.1288 2.32481C11.7337 2.32481 9.91017 1.63649 8.39014 0.158576C8.1727 -0.0528959 7.82667 -0.0528212 7.6093 0.158576C6.08942 1.63649 4.26615 2.32481 1.87119 2.32481C1.5619 2.32481 1.31115 2.57556 1.31115 2.88486C1.31115 3.27972 1.30338 3.68795 1.29509 4.12001C1.21833 8.14006 1.11319 13.6457 7.81629 15.9691C7.87573 15.9897 7.9377 16 7.99968 16C8.06166 16 8.12371 15.9897 8.18308 15.9691C14.8867 13.6456 14.7817 8.13983 14.7049 4.11971ZM7.99976 14.8457C2.25097 12.7573 2.33789 8.18255 2.41503 4.14136C2.41966 3.89883 2.42414 3.66376 2.42727 3.43332C4.67042 3.33864 6.4642 2.6589 7.99976 1.32122C9.53546 2.6589 11.3295 3.33871 13.5728 3.43332C13.576 3.66369 13.5805 3.8986 13.5851 4.14099C13.6621 8.18232 13.749 12.7573 7.99976 14.8457Z'
        fill='currentColor'
      />
      <path
        d='M9.92825 6.05462L7.22519 8.75754L6.07173 7.60407C5.85302 7.38543 5.4984 7.38543 5.27976 7.60407C5.06104 7.82286 5.06104 8.17741 5.27976 8.39612L6.82921 9.94557C6.93853 10.0549 7.0819 10.1096 7.22519 10.1096C7.36849 10.1096 7.51186 10.0549 7.62118 9.94557L10.7201 6.84667C10.9389 6.62796 10.9389 6.27334 10.7202 6.0547C10.5016 5.83598 10.147 5.83591 9.92825 6.05462Z'
        fill='currentColor'
      />
    </svg>
  );
};
