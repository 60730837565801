import React, { SVGProps } from 'react';

export const EmployerSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.5 2.5V15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 2.5H13.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 15.5V2.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M13.5 15.5H2.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M13.5 2.5H2.5L3.5 0.5H12.5L13.5 2.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.5 15.5H15.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M9.5 14C9.5 13.6022 9.34196 13.2206 9.06066 12.9393C8.77936 12.658 8.39782 12.5 8 12.5C7.60218 12.5 7.22064 12.658 6.93934 12.9393C6.65804 13.2206 6.5 13.6022 6.5 14V15.5H9.5V14Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 4.25C5.0663 4.25 5.12989 4.27634 5.17678 4.32322C5.22366 4.37011 5.25 4.4337 5.25 4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.75 4.5C4.75 4.4337 4.77634 4.37011 4.82322 4.32322C4.87011 4.27634 4.9337 4.25 5 4.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 4.75C4.9337 4.75 4.87011 4.72366 4.82322 4.67678C4.77634 4.62989 4.75 4.5663 4.75 4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 4.5C5.25 4.5663 5.22366 4.62989 5.17678 4.67678C5.12989 4.72366 5.0663 4.75 5 4.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 7.25C5.0663 7.25 5.12989 7.27634 5.17678 7.32322C5.22366 7.37011 5.25 7.4337 5.25 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.75 7.5C4.75 7.4337 4.77634 7.37011 4.82322 7.32322C4.87011 7.27634 4.9337 7.25 5 7.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 7.75C4.9337 7.75 4.87011 7.72366 4.82322 7.67678C4.77634 7.62989 4.75 7.5663 4.75 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 7.5C5.25 7.5663 5.22366 7.62989 5.17678 7.67678C5.12989 7.72366 5.0663 7.75 5 7.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 10.25C5.0663 10.25 5.12989 10.2763 5.17678 10.3232C5.22366 10.3701 5.25 10.4337 5.25 10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.75 10.5C4.75 10.4337 4.77634 10.3701 4.82322 10.3232C4.87011 10.2763 4.9337 10.25 5 10.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 10.75C4.9337 10.75 4.87011 10.7237 4.82322 10.6768C4.77634 10.6299 4.75 10.5663 4.75 10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 10.5C5.25 10.5663 5.22366 10.6299 5.17678 10.6768C5.12989 10.7237 5.0663 10.75 5 10.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 4.25C8.0663 4.25 8.12989 4.27634 8.17678 4.32322C8.22366 4.37011 8.25 4.4337 8.25 4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.75 4.5C7.75 4.4337 7.77634 4.37011 7.82322 4.32322C7.87011 4.27634 7.9337 4.25 8 4.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 4.75C7.9337 4.75 7.87011 4.72366 7.82322 4.67678C7.77634 4.62989 7.75 4.5663 7.75 4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 4.5C8.25 4.5663 8.22366 4.62989 8.17678 4.67678C8.12989 4.72366 8.0663 4.75 8 4.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 7.25C8.0663 7.25 8.12989 7.27634 8.17678 7.32322C8.22366 7.37011 8.25 7.4337 8.25 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.75 7.5C7.75 7.4337 7.77634 7.37011 7.82322 7.32322C7.87011 7.27634 7.9337 7.25 8 7.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 7.75C7.9337 7.75 7.87011 7.72366 7.82322 7.67678C7.77634 7.62989 7.75 7.5663 7.75 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 7.5C8.25 7.5663 8.22366 7.62989 8.17678 7.67678C8.12989 7.72366 8.0663 7.75 8 7.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 10.25C8.0663 10.25 8.12989 10.2763 8.17678 10.3232C8.22366 10.3701 8.25 10.4337 8.25 10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.75 10.5C7.75 10.4337 7.77634 10.3701 7.82322 10.3232C7.87011 10.2763 7.9337 10.25 8 10.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8 10.75C7.9337 10.75 7.87011 10.7237 7.82322 10.6768C7.77634 10.6299 7.75 10.5663 7.75 10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.25 10.5C8.25 10.5663 8.22366 10.6299 8.17678 10.6768C8.12989 10.7237 8.0663 10.75 8 10.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 4.25C11.0663 4.25 11.1299 4.27634 11.1768 4.32322C11.2237 4.37011 11.25 4.4337 11.25 4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.75 4.5C10.75 4.4337 10.7763 4.37011 10.8232 4.32322C10.8701 4.27634 10.9337 4.25 11 4.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 4.75C10.9337 4.75 10.8701 4.72366 10.8232 4.67678C10.7763 4.62989 10.75 4.5663 10.75 4.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 4.5C11.25 4.5663 11.2237 4.62989 11.1768 4.67678C11.1299 4.72366 11.0663 4.75 11 4.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7.25C11.0663 7.25 11.1299 7.27634 11.1768 7.32322C11.2237 7.37011 11.25 7.4337 11.25 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.75 7.5C10.75 7.4337 10.7763 7.37011 10.8232 7.32322C10.8701 7.27634 10.9337 7.25 11 7.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 7.75C10.9337 7.75 10.8701 7.72366 10.8232 7.67678C10.7763 7.62989 10.75 7.5663 10.75 7.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 7.5C11.25 7.5663 11.2237 7.62989 11.1768 7.67678C11.1299 7.72366 11.0663 7.75 11 7.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 10.25C11.0663 10.25 11.1299 10.2763 11.1768 10.3232C11.2237 10.3701 11.25 10.4337 11.25 10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.75 10.5C10.75 10.4337 10.7763 10.3701 10.8232 10.3232C10.8701 10.2763 10.9337 10.25 11 10.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 10.75C10.9337 10.75 10.8701 10.7237 10.8232 10.6768C10.7763 10.6299 10.75 10.5663 10.75 10.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.25 10.5C11.25 10.5663 11.2237 10.6299 11.1768 10.6768C11.1299 10.7237 11.0663 10.75 11 10.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
