import React, { Fragment, VFC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { PopperDropdown, PopperDropdownButton } from '@components/common';
import { DragSVG, PlusSVG } from '@components/svgs';

interface Props {
  card: string;
  category?: string;
  categories: { name: string; custom: boolean; cards: string[] }[];
  onCategorySelect: (params: { card: string; newCategory: string; initialCategory?: string }) => void;
  index: number;
}

export const CardItem: VFC<Props> = ({ card, categories, category: initialCategory, onCategorySelect, index }) => {
  const categoriesList = initialCategory ? categories.filter(({ name }) => name !== initialCategory) : categories;

  return (
    <Draggable key={card} draggableId={card} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
        <Fragment key={card}>
          <div
            key={card}
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            className='bg-white h-10 tablet:text-sm flex items-center border border-gray-200 rounded-md'
          >
            <div className='flex-1 px-4'>{card}</div>
            <div className='flex items-center h-full pr-3 space-x-1'>
              <PopperDropdown
                popperProps={{ placement: 'right-start' }}
                className='hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'
                hideCaret
                textStyle
                tooltip='Move to category'
                small
                aria-label='Move to category'
                renderIcon={() => <PlusSVG />}
              >
                {categoriesList.map((category) => (
                  <PopperDropdownButton
                    key={category.name}
                    onClick={() => onCategorySelect({ card, newCategory: category.name, initialCategory })}
                  >
                    {category.name}
                  </PopperDropdownButton>
                ))}
              </PopperDropdown>
              <div className='w-6 h-6'>
                <DragSVG className='text-gray-700' />
              </div>
            </div>
          </div>
          {snapshot.isDragging && <div className='h-9 w-full' />}
        </Fragment>
      )}
    </Draggable>
  );
};
