import { CopyURLButton } from 'components/shared/CopyURLButton';
import { useDeviceType } from 'hooks/useDeviceType';
import * as React from 'react';

import { ScreenerChartWithTitle as ScreenerChart } from '@components/ScreenerResults/ScreenerChart';
import { ScreenerResponsesZDS } from '@components/ScreenerResults/components/ScreenerResponsesZDS';
import { Portal } from '@components/shared/Portal';
import { Card } from '@components/shared/Skeleton';
import { PrototypeTaskMetricsSpriteSVG } from '@components/svgs';

interface Props {
  results: ScreenerResponseResults['summary'];
  isLoading?: boolean;
  withBorders?: boolean;
}

export const ScreenerResultsSummary: React.FC<Props> = ({ withBorders, isLoading, results }) => {
  const { isMobile } = useDeviceType();

  const noResults = results.map((result) => result.answers.length).every((answers) => answers === 0);

  if (isLoading)
    return (
      <div className='h-full'>
        <Card height='100%' />
      </div>
    );

  if (!results.length || noResults) {
    return <ScreenerResponsesZDS tab='summary' withBorders={withBorders} />;
  }

  return (
    <div className='relative p-6 bg-white rounded-md'>
      {results.map((result) => (
        <ScreenerChart
          id={result.field.id}
          key={result.field.id}
          title={result.field.label}
          type={result.field.field_type}
          answers={result.answers}
          blockableId={result.blockable_id}
        />
      ))}
      <div className='top-6 right-6 absolute rounded-md'>
        <CopyURLButton icon='link' noTippy text={window.location.href} trackEvent='copied_summary_link'>
          {isMobile ? '' : 'Copy link'}
        </CopyURLButton>
      </div>

      <Portal>
        {/* Prototype task metrics icons sprite */}
        <PrototypeTaskMetricsSpriteSVG style={{ display: 'none' }} />
      </Portal>
    </div>
  );
};
