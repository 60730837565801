import React, { SVGProps } from 'react';

export const PagesSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1093_4148)'>
      <path
        d='M12.2559 1.31445H1.75586C1.27261 1.31445 0.880859 1.7062 0.880859 2.18945V11.8145C0.880859 12.2977 1.27261 12.6895 1.75586 12.6895H12.2559C12.7391 12.6895 13.1309 12.2977 13.1309 11.8145V2.18945C13.1309 1.7062 12.7391 1.31445 12.2559 1.31445Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.880859 3.93945H13.1309'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1093_4148'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
