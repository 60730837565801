import * as React from 'react';
import ReactDOM from 'react-dom';

export function renderComp<T = any>(id: string, Component: React.FC<T>): void {
  return renderComponent(Component, id);
}

export function renderComponent<T extends Record<any, any>>(
  Component: React.FC<T>,
  id: string,
  parserFn?: (data: any) => any
): void {
  const el = document.getElementById(id);
  if (!el) {
    return;
  }
  const data = JSON.parse(el.dataset.props || '{}');
  const props: T = parserFn ? parserFn(data) : data;
  ReactDOM.render(<Component {...props} />, el);
}
