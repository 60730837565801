import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { page } from '@components/tracking';

export const TrackRouteChange: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    page();
  }, [pathname]);

  return null;
};
