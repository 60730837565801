import React from 'react';

interface Props {
  show: boolean;
  top?: boolean;
  bottom?: boolean;
  className?: string;
}
export const RecordingZone: React.FC<Props> = ({ show, top, bottom, className = '', children }) => {
  if (!show) {
    return <>{children}</>;
  }
  let klasses = className + ' px-3 -mx-3 border-l border-r border-red-600 border-dashed';
  if (top) {
    klasses += ' border-t rounded-t';
  }
  if (bottom) {
    klasses += ' border-b rounded-b';
  }
  return <div className={klasses}>{children}</div>;
};
