import React, { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { Loading } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { useAccount } from '@hooks/useAccount';
import { usePlan } from '@hooks/usePlan';

import { useCreateTrialMutation } from './api';
import { PricingTierCardsList } from './components/PricingTierCardsList';

export const PlansIndex: React.FC = () => {
  const currentPlan = usePlan();
  const {
    params: { sched: urlSched }
  } = useMatch('/plans/:sched') as any;
  const navigate = useNavigate();
  const sched: Sched = urlSched || 'monthly';
  const {
    account: { trial_ends_at, status }
  } = useAccount();

  return (
    <div className='bg-white'>
      <PageTitle>Plans</PageTitle>
      <PricingTierCardsList
        currentPlan={{
          id: currentPlan.id,
          name: currentPlan.name,
          interval: currentPlan.interval,
          key: currentPlan.key
        }}
        trialEndsAt={trial_ends_at ? new Date(trial_ends_at) : undefined}
        isTrial={status === 'trial'}
        isSignedIn
        sched={sched}
        setSched={(sched) => navigate(`/plans/${sched}`)}
      />
    </div>
  );
};
