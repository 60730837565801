import { List, Text } from 'components/common';
import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../toasts';
import { StudyActionModal, StudyActionModalProps } from '../StudyActionModal/StudyActionModal';

export const ApproveStudyModal: React.FC<StudyActionModalProps> = (props) => {
  const showToast = useToaster();
  const [approveStudy, { isSuccess, isError, isLoading }] = api.useApproveStudyMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successApproved(props.title));
      props.onAfterSubmit?.();
      props.onClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedApproved());
    }
  }, [isError]);

  return (
    <StudyActionModal
      {...props}
      heading='Approve study'
      loading={isLoading}
      primaryButtonProps={{
        primary: true,
        children: 'Confirm & approve'
      }}
      onSubmit={async () => {
        approveStudy({ id: props.id });
      }}
    >
      <div className='my-1'>
        <Text h='400' mb='6'>
          Once approved, users will be able to invite participants to participate.
        </Text>
        <Text h='400' mb='4'>
          They will also be able to make further changes to the study that don’t require your approval.
        </Text>
      </div>
    </StudyActionModal>
  );
};
