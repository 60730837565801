import React, { SVGProps } from 'react';

export const PublicAssetLogoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='140' height='18' viewBox='0 0 140 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.60078 8.77861V9.14438C8.45175 9.20214 8.72252 9.31765 8.72252 9.85668V11.2235C8.72252 12.7829 8.10363 14.1882 6.49837 14.1882C4.54499 14.1882 3.34589 12.3786 3.34589 7.77754C3.34589 3.50374 4.50631 1.55936 6.69178 1.55936C8.79988 1.55936 10.0957 3.34973 10.8886 5.96791H11.2368C11.1401 4.37005 11.1401 2.75294 11.1401 1.15508H10.8306L10.1924 2.04064C9.38009 1.44385 8.18099 1.03957 6.63376 1.03957C2.59161 1.03957 0 3.83102 0 7.85455C0 11.9166 2.63029 14.708 6.34365 14.708C8.16165 14.708 9.45745 14.0727 10.3084 13.1872L11.1788 14.6503H11.4689V9.85668C11.4689 9.31765 11.7203 9.20214 12.5713 9.14438V8.77861H7.60078Z'
      fill='#5850EC'
    />
    <path
      d='M20.2837 4.50481C18.8718 4.50481 17.8855 5.56364 17.4987 7.10374L17.5954 4.50481L13.6113 4.79358V5.15936C14.3655 5.21711 14.6363 5.33262 14.6363 5.83316V13.3412C14.6363 13.8802 14.4042 13.9957 13.6113 14.0535V14.4193H19.4907V14.0535C18.0789 13.9957 17.6534 13.8802 17.6534 13.3412V9.22139C17.6534 6.98824 18.253 6.16043 18.9492 6.16043C19.6068 6.16043 19.9356 6.79572 19.9356 8.33583C20.8832 8.33583 22.2371 7.85455 22.2371 6.33369C22.2371 5.15936 21.4248 4.50481 20.2837 4.50481Z'
      fill='#5850EC'
    />
    <path
      d='M28.9177 13.861C27.1577 13.861 26.3647 12.4941 26.2874 9.24064H31.7414C31.8961 8.89412 31.9735 8.47059 31.9735 7.98931C31.9735 5.71765 30.1941 4.50481 28.028 4.50481C25.185 4.50481 23.0962 6.58396 23.0962 9.68342C23.0962 12.7636 25.2043 14.708 28.0087 14.708C29.7686 14.708 31.1998 13.938 31.9541 12.5711L31.6447 12.3209C30.9291 13.3797 29.9621 13.861 28.9177 13.861ZM27.97 5.04385C29.0724 5.04385 29.4205 6.27594 29.4205 7.43102C29.4205 8.43209 29.2078 8.74011 28.5115 8.74011H26.2874C26.2874 6.64171 26.6935 5.04385 27.97 5.04385Z'
      fill='#5850EC'
    />
    <path
      d='M43.1345 12.6866C42.9411 13.2449 42.709 13.6107 42.3416 13.6107C41.9741 13.6107 41.8387 13.2642 41.8387 12.7444V8.23957C41.8387 5.64064 40.4269 4.50481 37.8739 4.50481C35.6691 4.50481 33.9865 5.35187 33.9865 6.69946C33.9865 7.70053 34.9342 8.16257 36.4041 8.16257C36.4041 5.8139 36.8876 5.04385 37.6999 5.04385C38.4928 5.04385 38.841 5.83316 38.841 7.06524V9.2984C35.9399 9.35615 33.4063 10.0492 33.4063 12.2631C33.4063 13.6492 34.354 14.708 36.0366 14.708C37.4871 14.708 38.4928 13.9187 38.9957 12.6481C39.1697 13.8417 39.8467 14.708 41.1425 14.708C42.2062 14.708 43.2312 14.1305 43.444 12.7829L43.1345 12.6866ZM37.3131 13.2834C36.6748 13.2834 36.2107 12.7829 36.2107 11.8396C36.2107 10.6075 37.081 9.91444 38.841 9.81818V11.3583C38.841 12.4364 38.1447 13.2834 37.3131 13.2834Z'
      fill='#5850EC'
    />
    <path
      d='M50.8853 12.8791C50.3438 13.4759 49.7829 13.8032 49.2221 13.8032C48.3711 13.8032 47.9649 13.1102 47.9649 11.8588V5.33262H50.8273V4.79358H47.9649V1.98289C46.9592 2.27166 45.9535 2.48342 44.9478 2.61818V4.79358H43.8454V5.33262H44.9478V11.3583C44.9478 13.8802 46.3984 14.708 48.0616 14.708C49.3961 14.708 50.4985 14.1305 51.1948 13.1872L50.8853 12.8791Z'
      fill='#5850EC'
    />
    <path
      d='M70.3975 14.477C69.8753 15.3818 69.1017 15.7476 68.4248 15.7476C67.3804 15.7476 66.6842 14.939 66.0459 13.9957C68.0767 12.9369 69.3725 10.723 69.3725 7.81604C69.3725 3.85027 66.8389 1.03957 62.9321 1.03957C58.9867 1.03957 56.4724 3.88877 56.4724 7.89305C56.4724 11.9166 58.948 14.708 63.0288 14.708C63.5317 14.708 64.0152 14.6503 64.46 14.554C65.0209 16.2289 65.6978 18 67.5738 18C69.4885 18 70.4169 16.0749 70.5909 14.5733L70.3975 14.477ZM62.9321 1.55936C64.8468 1.55936 66.0266 3.46524 66.0266 7.81604C66.0266 10.0492 65.7172 11.6471 65.1756 12.6866C64.5374 11.8011 63.8411 11.0695 62.7774 11.0695C61.7524 11.0695 60.9014 11.7818 60.9014 12.9176C60.9014 12.9947 60.9014 13.0524 60.9207 13.1102C60.2051 12.1283 59.8183 10.415 59.8183 7.93155C59.8183 3.61925 60.9401 1.55936 62.9321 1.55936ZM62.9902 14.1882C61.9651 14.1882 61.4429 13.4952 61.4429 12.8021C61.4429 12.1091 61.9651 11.6471 62.5647 11.6471C63.4156 11.6471 63.8218 12.6289 64.2279 13.8225C63.8605 14.0727 63.435 14.1882 62.9902 14.1882Z'
      fill='#5850EC'
    />
    <path
      d='M81.9977 12.6674C81.8236 13.2449 81.6109 13.6107 81.2434 13.6107C80.8566 13.6107 80.7406 13.2642 80.7406 12.7444V4.50481L76.6984 4.79358V5.15936C77.4914 5.21711 77.7235 5.33262 77.7235 5.83316V11.0888C77.7235 12.6674 76.8145 13.3604 76.0602 13.3604C75.4026 13.3604 74.7644 12.8021 74.7644 11.3198V4.50481L70.7222 4.79358V5.15936C71.5152 5.21711 71.7473 5.33262 71.7473 5.83316V11.7626C71.7473 13.9957 73.2752 14.708 74.687 14.708C76.1956 14.708 77.3947 13.8802 77.8782 12.6096C78.0329 13.784 78.7292 14.708 80.0443 14.708C81.108 14.708 82.1137 14.1305 82.3458 12.7829L81.9977 12.6674Z'
      fill='#5850EC'
    />
    <path
      d='M89.0731 13.861C87.3131 13.861 86.5202 12.4941 86.4428 9.24064H91.8968C92.0515 8.89412 92.1289 8.47059 92.1289 7.98931C92.1289 5.71765 90.3496 4.50481 88.1834 4.50481C85.3404 4.50481 83.2516 6.58396 83.2516 9.68342C83.2516 12.7636 85.3597 14.708 88.1641 14.708C89.9241 14.708 91.3553 13.938 92.1095 12.5711L91.8001 12.3209C91.0845 13.3797 90.1175 13.861 89.0731 13.861ZM88.1254 5.04385C89.2278 5.04385 89.576 6.27594 89.576 7.43102C89.576 8.43209 89.3632 8.74011 88.667 8.74011H86.4428C86.4428 6.64171 86.849 5.04385 88.1254 5.04385Z'
      fill='#5850EC'
    />
    <path
      d='M98.0681 4.50481C95.3411 4.50481 93.9292 5.7754 93.9292 7.41176C93.9292 9.14439 95.4764 9.83743 96.8109 10.4727C98.01 11.031 99.2091 11.6471 99.2091 12.8406C99.2091 13.7455 98.5129 14.1497 97.7779 14.1497C97.0043 14.1497 96.134 13.6492 95.9793 11.493C95.5538 11.416 95.0896 11.4353 94.7415 11.5508C94.1613 11.6663 93.5811 12.0321 93.5811 12.7444C93.5811 13.9187 95.0509 14.708 97.3525 14.708C100.312 14.708 101.762 13.3797 101.762 11.7241C101.762 10.0877 100.389 9.35615 98.9964 8.72086C97.7006 8.12406 96.4821 7.56578 96.4821 6.37219C96.4821 5.54438 97.0817 5.0631 97.836 5.0631C98.861 5.0631 99.4025 5.98717 99.4025 7.48877C99.7893 7.58503 100.215 7.58503 100.582 7.45027C101.337 7.37326 101.569 6.79572 101.569 6.35294C101.569 5.19786 100.099 4.50481 98.0681 4.50481Z'
      fill='#5850EC'
    />
    <path
      d='M110.002 12.8791C109.46 13.4759 108.9 13.8032 108.339 13.8032C107.488 13.8032 107.082 13.1102 107.082 11.8588V5.33262H109.944V4.79358H107.082V1.98289C106.076 2.27166 105.07 2.48342 104.064 2.61818V4.79358H102.962V5.33262H104.064V11.3583C104.064 13.8802 105.515 14.708 107.178 14.708C108.513 14.708 109.615 14.1305 110.311 13.1872L110.002 12.8791Z'
      fill='#5850EC'
    />
    <path
      d='M113.26 3.11872C114.382 3.11872 115.097 2.48342 115.097 1.55936C115.097 0.616043 114.382 0 113.26 0C112.138 0 111.403 0.616043 111.403 1.55936C111.403 2.48342 112.138 3.11872 113.26 3.11872ZM111.848 13.3412C111.848 13.8802 111.616 13.9957 110.823 14.0535V14.4193H115.89V14.0535C115.097 13.9957 114.865 13.8802 114.865 13.3412V4.50481L110.823 4.79358V5.15936C111.577 5.21711 111.848 5.33262 111.848 5.83316V13.3412Z'
      fill='#5850EC'
    />
    <path
      d='M122.337 14.708C125.296 14.708 127.52 12.7444 127.52 9.58717C127.52 6.42995 125.296 4.50481 122.337 4.50481C119.378 4.50481 117.154 6.42995 117.154 9.58717C117.154 12.7444 119.378 14.708 122.337 14.708ZM122.337 14.169C120.848 14.169 120.326 12.5904 120.326 9.58717C120.326 6.58396 120.848 5.04385 122.337 5.04385C123.826 5.04385 124.349 6.58396 124.349 9.58717C124.349 12.5904 123.826 14.169 122.337 14.169Z'
      fill='#5850EC'
    />
    <path
      d='M138.975 13.3412V7.35401C138.975 5.21711 137.466 4.50481 136.035 4.50481C134.527 4.50481 133.27 5.35187 132.786 6.62246L132.883 4.50481L128.879 4.79358V5.15936C129.634 5.21711 129.904 5.33262 129.904 5.83316V13.3412C129.904 13.8802 129.672 13.9957 128.879 14.0535V14.4193H133.869V14.0535C133.134 13.9957 132.921 13.8802 132.921 13.3412V8.04706C132.921 6.62246 133.792 5.85241 134.623 5.85241C135.32 5.85241 135.958 6.37219 135.958 7.73904V13.3412C135.958 13.8802 135.726 13.9957 135.01 14.0535V14.4193H140V14.0535C139.207 13.9957 138.975 13.8802 138.975 13.3412Z'
      fill='#5850EC'
    />
  </svg>
);
