import * as React from 'react';
import { Button } from 'components/common';
import { AuthButton } from 'components/StudiesApp/components/StudyDraft/pages/Calendar/components/AuthButton';
import { useUserConnectedAccounts } from 'hooks/useUserConnectedAccounts';
import { WarningSVG } from 'components/svgs';

export const InvalidConnectedAccount: React.FC = () => {
  const { connectedAccounts } = useUserConnectedAccounts();

  const invalidConnectedAccounts = connectedAccounts?.filter((account: ConnectedAccount) => account.is_invalid);

  if (!invalidConnectedAccounts || invalidConnectedAccounts.length === 0) {
    return null;
  }

  const button = (
    <Button href='/user/connected_accounts' small className='ml-3'>
      {invalidConnectedAccounts.length > 1
        ? 'Go to Integrations'
        : `Reconnect ${invalidConnectedAccounts[0].provider_name}`}
    </Button>
  );

  const message =
    invalidConnectedAccounts.length > 1
      ? 'There are multiple issues with your integrations'
      : `Your ${invalidConnectedAccounts[0].provider_name} connection has expired. Please reconnect`;

  return (
    <div className='px-page bg-red-700 text-white flex py-3 border-b border-gray-200'>
      <div className='flex items-center mx-auto'>
        <WarningSVG className='mr-3' />
        <span>{message}</span>
        {button}
      </div>
    </div>
  );
};
