import React from 'react';

import { Provider as CollectionView } from '@components/stores/view';

import { AccountAttrsIndex } from './components/AccountAttrsIndex';

export const AccountAttrsApp: React.FC = () => {
  return (
    <CollectionView scope='custom_attributes' defaultable>
      <AccountAttrsIndex />
    </CollectionView>
  );
};
