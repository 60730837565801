import { Button, Modal, ModalHeading, Text } from '@components/common';
import React from 'react';

export const SettingsMovedModal = ({ onClose }) => {
  return (
    <Modal
      onClose={onClose}
      renderFooter={() => (
        <div className='space-x-6'>
          <Button onClick={onClose}>Go to back</Button>
          <Button primary href='/governance/permissions' onClick={onClose}>
            Go to Permissions
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-6'>Settings have moved</ModalHeading>
      <Text h='400' className='mb-12'>
        We’ve moved these settings from Members {'>'} Settings into Governance {'>'} Permissions. Please navigate there
        to review or make changes.
      </Text>
    </Modal>
  );
};
