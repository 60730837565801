import { compact } from 'components/utils';
import convert from 'convert';
import pluralize, { plural } from 'pluralize';
import React, { useEffect, useRef, useState } from 'react';

import { Input, Select, SelectOption } from '@components/common';
import { useDebouncedCallback } from 'use-debounce';

enum Units {
  minute = 'minute',
  hour = 'hour',
  day = 'day',
  week = 'week',
  month = 'month'
}

type Unit = keyof typeof Units;

export interface Props {
  placeholder?: string;
  value: number;
  values: number[];
  activeUnit: Unit;
  units: Unit[];
  conversionUnit: Unit;
  onChange: (value: number) => void;
  noMaximumLimit: boolean;
  disabled?: boolean;
}

export const SchedulingDropdown = ({
  placeholder = 'Select...',
  value: defaultValue = 0,
  values = [],
  activeUnit = Units.minute,
  conversionUnit = Units.minute,
  units = [Units.minute],
  onChange,
  noMaximumLimit = true,
  disabled
}: Props) => {
  const valueConverted = convert(defaultValue, conversionUnit).to(activeUnit);
  const fixedValues = [0].concat(values);

  const getDefaultValue = () => (fixedValues.includes(valueConverted) ? String(valueConverted) : 'custom');

  const getDefaultCustomValue = () => (fixedValues.includes(valueConverted) ? '1' : String(defaultValue));

  const getDefaultUnit = () => (fixedValues.includes(valueConverted) ? units[0] : conversionUnit);

  const [value, setValue] = useState<string>(getDefaultValue());
  const [customValue, setCustomValue] = useState<string>(getDefaultCustomValue());
  const [selectedUnit, setSelectedUnit] = useState<Unit>(getDefaultUnit());
  const isInitialMountRef = useRef(true);

  useEffect(() => {
    if (isInitialMountRef.current) return;

    setValue(getDefaultValue());
    setCustomValue(getDefaultCustomValue());
    setSelectedUnit(getDefaultUnit());
  }, [defaultValue]);

  const options: SelectOption[] = compact([noMaximumLimit && { label: placeholder, value: '0' }])
    .concat(
      values.map((value) => ({
        label: `${value} ${pluralize(activeUnit, value)}`,
        value: String(value)
      }))
    )
    .concat([{ label: 'Custom', value: 'custom' }]);

  const unitOptions: SelectOption<Unit>[] = units.map((unit) => ({
    label: plural(unit),
    value: unit
  }));

  const { callback: debouncedChange } = useDebouncedCallback(onChange, process.env.NODE_ENV === 'test' ? 5 : 300);

  useEffect(() => {
    if (isInitialMountRef.current) return;

    let result = 0;

    if (value === 'custom') {
      const v = isNaN(+customValue) ? 1 : +customValue;
      result = convert(v, selectedUnit).to(conversionUnit);
    } else {
      const v = isNaN(+value) ? 1 : +value;
      result = convert(v, activeUnit).to(conversionUnit);
    }

    debouncedChange(result);
  }, [value, customValue, selectedUnit]);

  useEffect(() => {
    isInitialMountRef.current = false;
  }, []);

  return (
    <section>
      <Select disabled={disabled} onChange={(v) => setValue(v)} value={value ?? ''} options={options} />
      {value === 'custom' && (
        <div className='flex flex-wrap items-center justify-between mt-2'>
          <Input
            onChange={(v) => setCustomValue(v)}
            type='number'
            min='1'
            value={customValue}
            disabled={disabled}
            className='flex-1 min-w-0 mr-3'
          />
          <Select<Unit>
            disabled={disabled}
            className='h-full'
            onChange={(v) => setSelectedUnit(v)}
            value={selectedUnit}
            options={unitOptions}
          />
        </div>
      )}
    </section>
  );
};
