import cn from 'classnames';
import { Popper, Text } from 'components/common';
import { useToaster } from 'components/stores/toaster';
import { EllipsisSVG, SurveyBuilderSpriteSVG, TrashSVG } from 'components/svgs';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { getRecording, updateParticipationZoomMeeting } from '@api/queries';
import { api } from '@api/reduxApi';
import { PlaceholderPlayer } from '@components/RepoSessionApp/RecordingWidget/PlaceholderPlayer';
import { Portal } from '@components/shared/Portal';
import { useCreateMuxPlaybackUrlsMutation } from '@components/shared/Tiptap/api';
import Player from '@components/VideoPlayer';
import { useDeviceType } from '@hooks/useDeviceType';
import * as UpChunk from '@mux/upchunk';

import { answersToChapters } from '../helpers';
import { useSessionContext } from '../SessionPage';
import * as toasts from '../toasts';
import { DeleteRecordingModal } from './DeleteRecordingModal';
import { EmptyState } from './EmptyState';
import { getErrorMessage } from './getErrorMessage';
import { PollRecording } from './PollRecording';
import { UploadingState } from './UploadingState';
import { parseImageFragmentStyle } from '@components/common/VideoSeekBar';
import { BlockIcon, getBlockLabel, Models } from '@components/SurveyBuilder';

interface Props {
  participation?: Participation | null;
  initialRecording: Recording | null;
  onCreateRecording?: (recording: Recording) => void;
  canUpload: boolean;
  isOwner: boolean;
  owner?: User;
  track?: (eventName: string, properties: Record<string, any>) => void;
  renderSummary: () => React.ReactElement | null;
  restrictWidth?: boolean;
  update: (target: number) => void;
  transcriptStatus?: Transcript['state'];
  initialTime?: number;
  study?: Study | null;
}

export const RecordingWidget: React.FC<Props> = ({
  participation,
  initialRecording,
  onCreateRecording,
  canUpload,
  track,
  owner,
  isOwner,
  renderSummary,
  restrictWidth,
  update,
  transcriptStatus,
  initialTime,
  study
}) => {
  const [recording, setRecording] = useState<Recording | null>(initialRecording);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);
  const [upchunker, setUpchunker] = useState<UpChunk.UpChunk | null>(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false);
  const [muxUrls, setMuxUrls] = useState<MuxPlaybackUrls>();

  const { videoPlayerRef } = useSessionContext();

  const { isDesktopL, isDesktopM } = useDeviceType();

  const [createMuxUrls] = useCreateMuxPlaybackUrlsMutation();

  const { data: screenerResponse } = api.useGetScreenerResponseQuery(
    {
      screenerId: study?.survey_screener?.id,
      studyId: study?.id,
      participationId: participation?.id
    },
    {
      skip: !participation?.id || !study?.id || !study?.survey_screener?.id
    }
  );

  const [getMuxDirectUploadUrl] = api.useGetMuxDirectUploadUrlMutation();
  const [createRecording] = api.useCreateRecordingMutation();
  const [deleteRecording] = api.useDestroyRecordingMutation();

  const chapters = answersToChapters(screenerResponse?.answers_json);

  const showToast = useToaster();

  const startUpload = async (file: File) => {
    setUploading(true);

    const { url: endpoint, passthrough } = await getMuxDirectUploadUrl().unwrap();

    const upload = UpChunk.createUpload({
      endpoint,
      file,
      chunkSize: 5120 // Uploads the file in ~5mb chunks
    });
    upload.on('error', (err) => {
      setUploading(false);
      setUploadError(err.detail);
    });
    upload.on('progress', (progress) => {
      setProgress(progress.detail);
    });
    upload.on('success', async () => {
      setUploadError(null);
      const recording = await createRecording({
        participation_id: participation?.id,
        mux_passthrough: passthrough,
        filename: file.name,
        byte_size: file.size
      }).unwrap();
      setRecording(recording);
      setUploading(false);
      setProgress(null);
      setUploadError(null);
      setUpchunker(null);
      onCreateRecording?.(recording);

      track?.('added_recording', { recording_id: recording.id, method: 'manual' });
    });
    setUpchunker(upload);
  };
  const cancelUpload = async () => {
    setUploading(false);
    setProgress(null);
    if (upchunker) {
      upchunker.abort();
    }
    setUploadError(null);
    setUpchunker(null);
  };

  const linkZoomRecording = async (cloudRecording: ZoomRecording) => {
    setUploading(true);

    if (participation) {
      const resp = await updateParticipationZoomMeeting(participation.id, {
        meeting_id: cloudRecording.id,
        uuid: cloudRecording.uuid
      });
      if (resp.recording_id) {
        const [err, recording] = await getRecording(resp.recording_id);

        if (err) {
          setUploading(false);
          showToast(toasts.failedLinkingZoom());
        } else {
          setRecording(recording);
          setUploading(false);
          onCreateRecording?.(recording);
        }
      }
    } else {
      try {
        const resp = await createRecording({ zoom_meeting_id: String(cloudRecording.id) }).unwrap();
        setRecording(resp);
        onCreateRecording?.(resp);
      } catch {
        showToast(toasts.failedLinkingZoom());
      } finally {
        setUploading(false);
      }
    }
  };

  const handleClickDelete = async () => {
    if (!recording) return;

    const resp = await deleteRecording({ id: recording.id });

    try {
      showToast(toasts.successDelete());
      setRecording(null);
    } catch (_) {
      showToast(toasts.failedDelete());
    }

    track?.('deleted_recording', { recording_id: recording.id });
  };

  const handlePoll = (recording: Recording) => {
    if (recording.transcript?.state === 'ready') {
      setRecording(recording);
    }
  };

  const muxPlaybackId = recording?.mux_video?.playback_id;

  useEffect(() => {
    if (muxPlaybackId) {
      (async () => {
        try {
          const urls = await createMuxUrls({ playbackId: muxPlaybackId || '' }).unwrap();
          setMuxUrls(urls);
        } catch {
          console.error('createMuxUrls request failed');
        }
      })();
    }
  }, [muxPlaybackId]);

  const videoStatus = recording?.mux_video?.status;

  const isPastInterview = !!participation?.interview_at && new Date() > participation.interview_at;
  const shouldHaveDailyRecording = participation?.style === 'unmoderated_test';
  const shouldHaveZoomMeeting = participation?.event_details?.location?.type === 'zoom';
  const hasZoomMeeting = !!participation?.zoom_meeting?.meeting_id;
  const hasTranscript = videoStatus === 'ready' && transcriptStatus === 'ready';
  const noTranscript =
    videoStatus === 'ready' && (!transcriptStatus || ['unstarted', 'processing'].includes(transcriptStatus));
  const erroredTranscript = videoStatus === 'ready' && transcriptStatus === 'errored';
  const preparing = videoStatus === 'preparing';
  const errored = videoStatus === 'errored';

  const placeholderState = videoStatus === 'errored' ? 'errored' : 'processing';

  if (uploading && progress) {
    return <UploadingState progress={progress} onClickCancel={cancelUpload} />;
  }

  if (!recording && owner) {
    return (
      <EmptyState
        canUpload={canUpload}
        error={!!uploadError}
        onChooseFile={startUpload}
        onChooseZoom={linkZoomRecording}
        owner={owner}
        participation={participation}
        isOwner={isOwner}
        isZoom={shouldHaveZoomMeeting}
        isWaitingForZoom={hasZoomMeeting && isPastInterview}
        isWaitingForDaily={shouldHaveDailyRecording && participation.status === 'completed'}
        hasAiSummary={study?.style === 'video_call'}
      />
    );
  }
  return (
    <div
      className={cn('flex flex-col flex-1 border w-full border-gray-200', {
        'max-w-2xl': restrictWidth
      })}
    >
      {!hasTranscript && (
        <div className='relative'>
          <Popper
            content={() => (
              <div className='shadow-custom px-5 py-3 bg-white rounded'>
                <button
                  className='focus:outline-none xx-delete-video flex items-center text-sm text-red-600'
                  onClick={() => setDeleteModalIsOpen(true)}
                >
                  <TrashSVG className='w-4 h-4 mr-2' />
                  Delete recording
                </button>
              </div>
            )}
            offset={[0, 6]}
            placement='bottom-end'
            closeOnClickOutside
          >
            <button
              className='focus:outline-none top-4 right-4 absolute z-10 text-white'
              aria-label='Recording options'
            >
              <EllipsisSVG className='w-4 h-4' />
            </button>
          </Popper>
        </div>
      )}
      {!recording?.mux_video?.playback_id && !muxUrls && <PlaceholderPlayer state={placeholderState} />}
      {muxUrls && (
        <Player
          testId='recording-widget-player'
          ref={videoPlayerRef}
          src={muxUrls.stream_url}
          className='w-full bg-gray-900'
          poster={muxUrls.thumbnail_url}
          onPlayerTimeUpdate={(n) => update(n * 1000)}
          currentTime={initialTime}
          autoPlay={!!initialTime}
          storyboardUrl={muxUrls.storyboard_url}
          chapters={chapters}
          renderPreview={(metadata, time, imageFragment) => {
            if (study?.style === 'unmoderated_test' && chapters.length > 0) {
              return (
                <div className='max-w-full overflow-hidden text-gray-700 bg-white border border-gray-200 rounded shadow-md'>
                  <div style={imageFragment ? parseImageFragmentStyle(imageFragment) : {}}></div>

                  <div className='p-2 border-t border-gray-200'>
                    <div className='flex items-start justify-start space-x-2'>
                      <BlockIcon kind={metadata.kind as Models.BlockKind} className='flex-shrink-0' />

                      <div className='overflow-hidden'>
                        <Text className='max-w-full text-sm truncate'>{metadata.title}</Text>
                        <Text className='text-xs text-gray-500'>
                          {getBlockLabel(metadata.kind as Models.BlockKind)}
                        </Text>
                      </div>
                    </div>

                    <Text className='mt-1 text-xs'>
                      {metadata.start} - {metadata.end}
                    </Text>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          }}
        />
      )}
      <section className='flex flex-col h-full'>
        {(preparing || errored || noTranscript || erroredTranscript) && recording && (
          <div className='py-6 text-center'>
            {preparing && (
              <>
                <PollRecording recordingId={recording.id} onPoll={handlePoll} />
                <div className='font-bold text-gray-700'>We’re preparing the video</div>
                <div className='text-sm text-gray-500'>
                  This may take a few minutes for longer videos. Feel free to leave the page while it finishes.
                </div>
              </>
            )}

            {errored && (
              <>
                <div className='font-bold text-gray-700'>An error occurred</div>
                <div className='text-sm text-gray-500'>{getErrorMessage(recording)}</div>
              </>
            )}

            {erroredTranscript && (
              <>
                <PollRecording recordingId={recording.id} onPoll={handlePoll} />
                <div className='font-bold text-gray-700'>We couldn’t get the transcript</div>
                <div className='text-sm text-gray-500'>
                  There may be something wrong with the video. Please try again, or contact support.
                </div>
              </>
            )}
          </div>
        )}
        {(isDesktopL || isDesktopM) && renderSummary()}
        <DeleteRecordingModal
          isOpen={deleteModalIsOpen}
          onClose={() => setDeleteModalIsOpen(false)}
          onDelete={handleClickDelete}
        />
      </section>
      <Portal>
        <SurveyBuilderSpriteSVG style={{ display: 'none' }} />
      </Portal>
    </div>
  );
};
