import * as React from 'react';

export const ScreenerZDSSVG: React.FC<any> = (props) => {
  return (
    <svg {...props} width='220' viewBox='0 0 187 121' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M163.867 110.922C169.881 104.909 169.881 95.1587 163.867 89.145C157.853 83.1314 148.103 83.1314 142.09 89.145C136.076 95.1587 136.076 104.909 142.09 110.922C148.103 116.936 157.853 116.936 163.867 110.922Z'
        fill='#2F2E41'
      />
      <path
        d='M152.893 101.451C155.731 100.823 157.522 98.0129 156.894 95.175C156.266 92.3371 153.456 90.5457 150.618 91.1739C147.78 91.8021 145.989 94.6119 146.617 97.4499C147.245 100.288 150.055 102.079 152.893 101.451Z'
        fill='white'
      />
      <path
        d='M150.002 96.6656C150.948 96.4562 151.546 95.5196 151.336 94.5736C151.127 93.6276 150.19 93.0305 149.244 93.2399C148.298 93.4493 147.701 94.3859 147.91 95.3319C148.12 96.2778 149.056 96.875 150.002 96.6656Z'
        fill='#3F3D56'
      />
      <path
        d='M152.37 89.6232C146.073 89.6116 141.652 88.4867 141.662 82.7812C141.673 77.0756 146.786 72.4597 153.084 72.4714C159.382 72.483 164.479 77.1177 164.468 82.8233C164.457 88.5289 158.668 89.6348 152.37 89.6232Z'
        fill='#5850EC'
      />
      <path
        d='M177.114 102.608C175.308 108.874 170.033 113.261 164.532 116.987C163.763 117.508 162.995 118.016 162.226 118.513C162.221 118.515 162.216 118.52 162.21 118.522C162.173 118.546 162.136 118.57 162.102 118.594C161.943 118.696 161.784 118.798 161.627 118.899L161.714 118.938C161.714 118.938 161.798 118.983 161.706 118.945C161.678 118.934 161.649 118.924 161.621 118.912C158.425 117.623 155.172 116.239 152.723 113.824C150.182 111.316 148.697 107.383 150.073 104.088C150.253 103.657 150.482 103.247 150.754 102.866C150.865 102.706 150.986 102.553 151.111 102.403C151.87 101.477 152.862 100.77 153.985 100.354C155.108 99.9379 156.321 99.8287 157.5 100.037C158.679 100.246 159.782 100.765 160.694 101.541C161.606 102.317 162.295 103.322 162.69 104.452C163.094 100.689 159.991 97.536 157.005 95.2099C154.017 92.885 150.622 90.3531 150.044 86.6105C149.722 84.5282 150.446 82.5102 151.765 80.8902C151.806 80.841 151.847 80.7918 151.889 80.744C153.494 78.8572 155.753 77.6456 158.213 77.3512C162.794 76.8751 167.244 79.3001 170.572 82.4855C175.929 87.6105 179.168 95.4825 177.114 102.608Z'
        fill='#E6E6E6'
      />
      <path
        d='M168.503 94.4111C169.382 95.7791 170.057 97.268 170.507 98.8308C170.887 100.228 171.056 101.673 171.009 103.12C170.886 106.098 170.051 109.002 168.572 111.589C167.458 113.551 166.1 115.365 164.532 116.988C163.763 117.508 162.995 118.016 162.226 118.513C162.221 118.515 162.216 118.52 162.21 118.522C162.173 118.546 162.136 118.57 162.102 118.594C161.943 118.696 161.785 118.798 161.627 118.9C161.627 118.9 161.799 118.983 161.706 118.946C161.678 118.934 161.649 118.924 161.622 118.912C161.908 116.563 161.627 114.18 160.803 111.962C159.979 109.745 158.635 107.757 156.884 106.165C155.129 104.598 153.029 103.467 150.754 102.866C150.866 102.706 150.986 102.553 151.111 102.403C151.989 102.65 152.845 102.973 153.666 103.37C156.603 104.781 159.008 107.099 160.525 109.983C161.886 112.601 162.471 115.553 162.213 118.493C162.345 118.374 162.476 118.252 162.604 118.132C165.032 115.868 167.19 113.268 168.65 110.269C169.916 107.728 170.539 104.916 170.462 102.078C170.343 98.9841 169.11 96.1182 167.326 93.6234C165.369 90.9617 163.058 88.5799 160.457 86.5444C157.835 84.4445 154.966 82.6733 151.914 81.2703C151.845 81.2382 151.791 81.1808 151.764 81.1101C151.736 81.0394 151.736 80.9607 151.765 80.8903C151.785 80.8272 151.83 80.7746 151.889 80.7441C151.919 80.731 151.952 80.725 151.985 80.7264C152.018 80.7278 152.05 80.7366 152.079 80.7522C152.456 80.9256 152.83 81.1015 153.201 81.285C156.293 82.8131 159.183 84.7195 161.805 86.9603C164.337 89.1194 166.722 91.5861 168.503 94.4111Z'
        fill='white'
      />
      <path
        d='M26.3565 119.976C28.5095 119.976 30.2549 119.322 30.2549 118.514C30.2549 117.707 28.5095 117.053 26.3565 117.053C24.2034 117.053 22.458 117.707 22.458 118.514C22.458 119.322 24.2034 119.976 26.3565 119.976Z'
        fill='#2F2E41'
      />
      <path
        d='M35.7129 119.781C37.866 119.781 39.6113 119.126 39.6113 118.319C39.6113 117.512 37.866 116.857 35.7129 116.857C33.5598 116.857 31.8145 117.512 31.8145 118.319C31.8145 119.126 33.5598 119.781 35.7129 119.781Z'
        fill='#2F2E41'
      />
      <path
        d='M48.1336 95.2088C51.8175 93.0851 54.2635 90.4259 53.5968 89.2694C52.9301 88.1129 49.4032 88.897 45.7192 91.0207C42.0353 93.1445 39.5893 95.8036 40.256 96.9601C40.9227 98.1166 44.4496 97.3325 48.1336 95.2088Z'
        fill='#2F2E41'
      />
      <path
        d='M44.6752 100.417C46.0375 92.0226 40.3366 84.113 31.9419 82.7507C23.5471 81.3884 15.6375 87.0894 14.2752 95.4841C12.9129 103.879 18.6139 111.788 27.0086 113.151C35.4033 114.513 43.313 108.812 44.6752 100.417Z'
        fill='#2F2E41'
      />
      <path d='M27.1361 110.036H22.458V118.417H27.1361V110.036Z' fill='#2F2E41' />
      <path d='M36.4926 110.036H31.8145V118.417H36.4926V110.036Z' fill='#2F2E41' />
      <path
        d='M29.8654 99.3145C32.7721 99.3145 35.1283 96.9582 35.1283 94.0516C35.1283 91.1449 32.7721 88.7887 29.8654 88.7887C26.9588 88.7887 24.6025 91.1449 24.6025 94.0516C24.6025 96.9582 26.9588 99.3145 29.8654 99.3145Z'
        fill='white'
      />
      <path
        d='M29.8656 95.8058C30.8345 95.8058 31.6199 95.0204 31.6199 94.0515C31.6199 93.0827 30.8345 92.2972 29.8656 92.2972C28.8968 92.2972 28.1113 93.0827 28.1113 94.0515C28.1113 95.0204 28.8968 95.8058 29.8656 95.8058Z'
        fill='#3F3D56'
      />
      <path
        d='M14.5382 83.6301C13.2948 78.0617 17.2694 72.435 23.4157 71.0626C29.562 69.6901 35.5526 73.0917 36.796 78.6602C38.0393 84.2286 33.9659 86.2823 27.8196 87.6547C21.6733 89.0271 15.7816 89.1986 14.5382 83.6301Z'
        fill='#E6E6E6'
      />
      <path
        d='M19.0877 94.104C19.6598 92.8978 17.0089 90.4429 13.1669 88.6206C9.32483 86.7984 5.74651 86.299 5.17446 87.5051C4.60241 88.7113 7.25325 91.1662 11.0953 92.9885C14.9373 94.8107 18.5157 95.3101 19.0877 94.104Z'
        fill='#2F2E41'
      />
      <path
        d='M21.9111 103.505C21.9111 105.012 25.7917 107.988 30.0978 107.988C34.4039 107.988 38.4414 103.745 38.4414 102.238C38.4414 100.731 34.4039 102.531 30.0978 102.531C25.7918 102.531 21.9111 101.998 21.9111 103.505Z'
        fill='white'
      />
      <path
        d='M83.1699 119.976C85.323 119.976 87.0684 119.322 87.0684 118.514C87.0684 117.707 85.323 117.053 83.1699 117.053C81.0169 117.053 79.2715 117.707 79.2715 118.514C79.2715 119.322 81.0169 119.976 83.1699 119.976Z'
        fill='#2F2E41'
      />
      <path
        d='M73.8135 119.781C75.9665 119.781 77.7119 119.126 77.7119 118.319C77.7119 117.512 75.9665 116.857 73.8135 116.857C71.6604 116.857 69.915 117.512 69.915 118.319C69.915 119.126 71.6604 119.781 73.8135 119.781Z'
        fill='#2F2E41'
      />
      <path
        d='M69.2707 96.9603C69.9374 95.8038 67.4914 93.1446 63.8074 91.0209C60.1234 88.8971 56.5965 88.113 55.9298 89.2696C55.2631 90.4261 57.7091 93.0852 61.3931 95.2089C65.077 97.3327 68.6039 98.1168 69.2707 96.9603Z'
        fill='#2F2E41'
      />
      <path
        d='M80.0512 113.349C88.5557 113.349 95.45 106.455 95.45 97.9505C95.45 89.4459 88.5557 82.5516 80.0512 82.5516C71.5466 82.5516 64.6523 89.4459 64.6523 97.9505C64.6523 106.455 71.5466 113.349 80.0512 113.349Z'
        fill='#2F2E41'
      />
      <path d='M87.0688 110.036H82.3906V118.417H87.0688V110.036Z' fill='#2F2E41' />
      <path d='M77.7123 110.036H73.0342V118.417H77.7123V110.036Z' fill='#2F2E41' />
      <path
        d='M76.4436 99.3145C79.3502 99.3145 81.7065 96.9582 81.7065 94.0516C81.7065 91.1449 79.3502 88.7887 76.4436 88.7887C73.5369 88.7887 71.1807 91.1449 71.1807 94.0516C71.1807 96.9582 73.5369 99.3145 76.4436 99.3145Z'
        fill='white'
      />
      <path
        d='M75.175 94.5715C75.8624 93.8847 75.8668 92.7747 75.1849 92.0921C74.503 91.4096 73.3929 91.413 72.7055 92.0998C72.0182 92.7865 72.0138 93.8966 72.6957 94.5791C73.3776 95.2617 74.4877 95.2582 75.175 94.5715Z'
        fill='#3F3D56'
      />
      <path
        d='M81.7067 87.6547C75.5604 86.2823 71.487 84.2285 72.7304 78.6601C73.9738 73.0916 79.9643 69.6901 86.1106 71.0625C92.2569 72.4349 96.2316 78.0616 94.9882 83.6301C93.7448 89.1985 87.853 89.0271 81.7067 87.6547Z'
        fill='#5850EC'
      />
      <path
        d='M96.1543 109.688C97.4445 109.345 97.6055 105.736 96.5139 101.626C95.4223 97.5163 93.4915 94.4625 92.2013 94.8052C90.9111 95.1478 90.7501 98.7573 91.8417 102.867C92.9333 106.977 94.8641 110.031 96.1543 109.688Z'
        fill='#2F2E41'
      />
      <path
        d='M76.5627 102.531C73.7356 102.531 71.085 100.732 71.085 102.239C71.085 103.746 73.7356 107.989 76.5627 107.989C79.3898 107.989 81.9375 105.013 81.9375 103.506C81.9375 101.999 79.3897 102.531 76.5627 102.531Z'
        fill='white'
      />
      <path
        d='M56.6621 72.4179H55.2946C54.0696 72.4165 52.8951 71.9292 52.0289 71.063C51.1626 70.1968 50.6754 69.0223 50.674 67.7973V66.6492C50.674 63.8055 51.2128 61.4245 52.2755 59.5723C53.5633 57.4178 55.0658 55.3991 56.7599 53.547C57.8846 52.416 58.9051 51.186 59.809 49.8718C60.4576 48.8508 60.7924 47.6621 60.7721 46.4527C60.7721 43.6001 58.9136 42.2134 55.0903 42.2134C52.9695 42.2374 50.8621 42.5537 48.8277 43.1534C48.0432 43.3773 47.2173 43.4161 46.4152 43.2667C45.6131 43.1173 44.8566 42.7838 44.2053 42.2924C43.554 41.801 43.0256 41.1651 42.6617 40.4349C42.2979 39.7046 42.1085 38.8998 42.1085 38.0839V35.7598C42.104 34.7593 42.3898 33.779 42.9313 32.9377C43.4728 32.0964 44.2467 31.4303 45.1592 31.02C48.7521 29.4866 52.6269 28.7244 56.5329 28.7825C60.6249 28.7825 64.3188 29.4698 67.5123 30.8251C70.7116 32.1836 73.2392 34.0992 75.0249 36.5187C76.8052 38.9212 77.7515 41.8397 77.7195 44.8297C77.7195 48.0406 76.8429 50.8371 75.1141 53.1416C73.4042 55.4228 70.953 57.92 67.8286 60.5645C66.1824 61.9335 64.6225 63.4031 63.1578 64.9648C62.2117 65.9838 61.543 67.2286 61.2158 68.5801C61.0335 69.6551 60.476 70.6307 59.6422 71.3334C58.8084 72.0361 57.7525 72.4203 56.6621 72.4179Z'
        fill='#5850EC'
      />
      <path
        d='M55.9017 94.1483H55.7214C53.4511 94.1469 51.2742 93.2439 49.6694 91.6381C48.0646 90.0322 47.1631 87.8548 47.1631 85.5845C47.1631 83.3142 48.0646 81.1368 49.6694 79.531C51.2742 77.9251 53.4511 77.0222 55.7214 77.0207H55.9017C57.0268 77.02 58.141 77.241 59.1806 77.671C60.2203 78.1011 61.165 78.7318 61.9608 79.5271C62.7566 80.3223 63.3879 81.2666 63.8186 82.306C64.2493 83.3454 64.4711 84.4594 64.4711 85.5845C64.4711 86.7096 64.2493 87.8237 63.8186 88.863C63.3879 89.9024 62.7566 90.8467 61.9608 91.642C61.165 92.4373 60.2203 93.068 59.1806 93.498C58.141 93.9281 57.0268 94.1491 55.9017 94.1483Z'
        fill='#5850EC'
      />
      <path
        d='M111.556 120.138H0.357553C0.262724 120.138 0.171784 120.1 0.10473 120.033C0.0376758 119.966 0 119.875 0 119.78C0 119.685 0.0376758 119.594 0.10473 119.527C0.171784 119.46 0.262724 119.423 0.357553 119.423H111.556C111.651 119.423 111.742 119.46 111.809 119.527C111.876 119.594 111.914 119.685 111.914 119.78C111.914 119.875 111.876 119.966 111.809 120.033C111.742 120.1 111.651 120.138 111.556 120.138Z'
        fill='#CCCCCC'
      />
      <path
        d='M186.643 120.138H127.289C127.194 120.138 127.103 120.1 127.036 120.033C126.969 119.966 126.932 119.875 126.932 119.78C126.932 119.685 126.969 119.594 127.036 119.527C127.103 119.46 127.194 119.423 127.289 119.423H186.643C186.738 119.423 186.829 119.46 186.896 119.527C186.963 119.594 187 119.685 187 119.78C187 119.875 186.963 119.966 186.896 120.033C186.829 120.1 186.738 120.138 186.643 120.138Z'
        fill='#CCCCCC'
      />
      <path
        d='M149.804 109.693C154.056 109.61 157.481 108.46 157.455 107.126C157.429 105.791 153.961 104.777 149.71 104.86C145.458 104.943 142.033 106.092 142.059 107.427C142.085 108.762 145.553 109.776 149.804 109.693Z'
        fill='#2F2E41'
      />
      <path
        d='M145.94 53.2755H136.643C136.169 53.275 135.715 53.0864 135.38 52.7513C135.045 52.4161 134.856 51.9617 134.855 51.4878V42.1914C134.856 41.7174 135.045 41.263 135.38 40.9279C135.715 40.5927 136.169 40.4042 136.643 40.4036H145.94C146.414 40.4042 146.868 40.5927 147.203 40.9279C147.538 41.263 147.727 41.7174 147.727 42.1914V51.4878C147.727 51.9617 147.538 52.4161 147.203 52.7513C146.868 53.0864 146.414 53.275 145.94 53.2755ZM136.643 41.1187C136.359 41.119 136.086 41.2322 135.885 41.4332C135.684 41.6343 135.571 41.907 135.571 42.1914V51.4878C135.571 51.7721 135.684 52.0448 135.885 52.2459C136.086 52.447 136.359 52.5601 136.643 52.5604H145.94C146.224 52.5601 146.497 52.447 146.698 52.2459C146.899 52.0448 147.012 51.7721 147.012 51.4878V42.1914C147.012 41.907 146.899 41.6343 146.698 41.4332C146.497 41.2322 146.224 41.119 145.94 41.1187H136.643Z'
        fill='#F2F2F2'
      />
      <path
        d='M150.23 47.1971H140.933C140.459 47.1966 140.005 47.008 139.67 46.6729C139.335 46.3377 139.146 45.8833 139.146 45.4094V36.113C139.146 35.639 139.335 35.1846 139.67 34.8495C140.005 34.5143 140.459 34.3258 140.933 34.3252H150.23C150.704 34.3258 151.158 34.5143 151.493 34.8495C151.828 35.1846 152.017 35.639 152.017 36.113V45.4094C152.017 45.8833 151.828 46.3377 151.493 46.6729C151.158 47.008 150.704 47.1966 150.23 47.1971ZM140.933 35.0403C140.649 35.0406 140.376 35.1538 140.175 35.3548C139.974 35.5559 139.861 35.8286 139.861 36.113V45.4094C139.861 45.6938 139.974 45.9664 140.175 46.1675C140.376 46.3686 140.649 46.4817 140.933 46.482H150.23C150.514 46.4817 150.787 46.3686 150.988 46.1675C151.189 45.9664 151.302 45.6938 151.302 45.4094V36.113C151.302 35.8286 151.189 35.5559 150.988 35.3548C150.787 35.1538 150.514 35.0406 150.23 35.0403H140.933Z'
        fill='#F2F2F2'
      />
      <path
        d='M76.9318 17.5199H67.6354C67.1614 17.5193 66.707 17.3308 66.3719 16.9956C66.0367 16.6605 65.8482 16.2061 65.8477 15.7321V6.43574C65.8482 5.96176 66.0367 5.50736 66.3719 5.17221C66.707 4.83705 67.1614 4.64852 67.6354 4.64798H76.9318C77.4057 4.64852 77.8601 4.83705 78.1953 5.17221C78.5304 5.50736 78.719 5.96176 78.7195 6.43574V15.7321C78.719 16.2061 78.5304 16.6605 78.1953 16.9956C77.8601 17.3308 77.4057 17.5193 76.9318 17.5199ZM67.6354 5.36308C67.351 5.3634 67.0784 5.47651 66.8773 5.6776C66.6762 5.8787 66.5631 6.15135 66.5628 6.43574V15.7321C66.5631 16.0165 66.6762 16.2892 66.8773 16.4902C67.0784 16.6913 67.351 16.8045 67.6354 16.8048H76.9318C77.2162 16.8045 77.4888 16.6913 77.6899 16.4902C77.891 16.2892 78.0041 16.0165 78.0044 15.7321V6.43574C78.0041 6.15135 77.891 5.8787 77.6899 5.6776C77.4888 5.47651 77.2162 5.3634 76.9318 5.36308H67.6354Z'
        fill='#F2F2F2'
      />
      <path
        d='M71.2111 12.8719H61.9147C61.4407 12.8714 60.9863 12.6828 60.6512 12.3477C60.316 12.0125 60.1275 11.5581 60.127 11.0841V1.78776C60.1275 1.31379 60.316 0.859381 60.6512 0.524228C60.9863 0.189075 61.4407 0.000542247 61.9147 0H71.2111C71.6851 0.000542247 72.1395 0.189075 72.4746 0.524228C72.8098 0.859381 72.9983 1.31379 72.9989 1.78776V11.0841C72.9983 11.5581 72.8098 12.0125 72.4746 12.3477C72.1395 12.6828 71.6851 12.8714 71.2111 12.8719ZM61.9147 0.715105C61.6303 0.715416 61.3577 0.828527 61.1566 1.02962C60.9555 1.23072 60.8424 1.50337 60.8421 1.78776V11.0841C60.8424 11.3685 60.9555 11.6412 61.1566 11.8423C61.3577 12.0434 61.6303 12.1565 61.9147 12.1568H71.2111C71.4955 12.1565 71.7681 12.0434 71.9692 11.8423C72.1703 11.6412 72.2834 11.3685 72.2838 11.0841V1.78776C72.2834 1.50337 72.1703 1.23072 71.9692 1.02962C71.7681 0.828527 71.4955 0.715416 71.2111 0.715105H61.9147Z'
        fill='#F2F2F2'
      />
      <path
        d='M114.853 67.8053C116.13 67.8053 117.166 66.7701 117.166 65.493C117.166 64.216 116.13 63.1808 114.853 63.1808C113.576 63.1808 112.541 64.216 112.541 65.493C112.541 66.7701 113.576 67.8053 114.853 67.8053Z'
        fill='#F2F2F2'
      />
      <path
        d='M21.8894 59.5816C23.1664 59.5816 24.2017 58.5464 24.2017 57.2693C24.2017 55.9923 23.1664 54.9571 21.8894 54.9571C20.6124 54.9571 19.5771 55.9923 19.5771 57.2693C19.5771 58.5464 20.6124 59.5816 21.8894 59.5816Z'
        fill='#F2F2F2'
      />
      <path
        d='M177.782 79.6047C179.059 79.6047 180.094 78.5695 180.094 77.2925C180.094 76.0155 179.059 74.9802 177.782 74.9802C176.505 74.9802 175.47 76.0155 175.47 77.2925C175.47 78.5695 176.505 79.6047 177.782 79.6047Z'
        fill='#F2F2F2'
      />
    </svg>
  );
};
