import * as React from 'react';
import { HeaderProps } from 'react-table';

import { Text } from '@components/common';
import { humanize } from '@components/utils';

export const Header: React.FC<HeaderProps<AbstractTemplate>> = ({ column }) => (
  <Text h='400' className='text-left' bold>
    {humanize(column.id)}
  </Text>
);
