import React, { SVGProps } from 'react';

export const ViewSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.4375 11.0835H8.02083'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5618 11.0835H11.2285'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.4375 4.0835H8.02083'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5618 4.0835H11.2285'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.4375 7.5835H2.77083' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' />
    <path d='M5.97852 7.5835H13.5618' stroke='currentColor' strokeWidth='0.875' strokeLinecap='round' />
  </svg>
);
