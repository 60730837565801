import { Button, CreateCsvExportButton, Modal } from '@components/common';
import React from 'react';
import { ServerFilterQuery } from '@components/CandidatesApp/CandidatesIndex/types';

interface QueryParams extends ServerFilterQuery {
  columns: string[];
  selectedIds?: number[];
}

type Query = () => [
  (v: QueryParams) => { unwrap: () => Promise<void> },
  {
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
  }
];

interface Props {
  setCsvExportModalOpen: (open: boolean) => void;
  pluralizedCount: string;
  query: Query;
  queryParams: QueryParams;
}

export const ExportCsvModal: React.FC<Props> = ({ setCsvExportModalOpen, pluralizedCount, query, queryParams }) => {
  const [createCsvExport, { isSuccess, isError, isLoading }] = query();

  const handleCsvExport = async () => {
    await createCsvExport(queryParams).unwrap();

    setCsvExportModalOpen(false);
  };

  return (
    <Modal
      size='md'
      onClose={() => setCsvExportModalOpen(false)}
      className='xx-csv-export-modal'
      renderFooter={() => (
        <div className='flex flex-row justify-end space-x-4'>
          <Button aria-label='Cancel' onClick={() => setCsvExportModalOpen(false)}>
            Cancel
          </Button>
          <CreateCsvExportButton
            primary
            isSuccess={isSuccess}
            aria-label='Export to CSV'
            isError={isError}
            isLoading={isLoading}
            successMessage={`Exported ${pluralizedCount} to a CSV file. You will receive an email with the link when it is ready.`}
            errorMessage='Something went wrong when exporting the candidates as a CSV file. Please try again later.'
            onClick={handleCsvExport}
          />
        </div>
      )}
    >
      <div>
        <h4 className='mb-3 text-xl font-semibold tracking-tight text-gray-800'>Export {pluralizedCount} to CSV</h4>
        <p className='mb-2'>You are about to export {pluralizedCount} to a CSV file.</p>
        <p className='mb-2'>You will receive a link to download it when it has been processed.</p>
      </div>
    </Modal>
  );
};
