import React, { SVGProps } from 'react';

export const DownloadSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1058_24723)'>
      <path
        d='M8 11.3334C7.724 11.3334 7.5 11.1094 7.5 10.8334V0.5C7.5 0.223999 7.724 0 8 0C8.276 0 8.5 0.223999 8.5 0.5V10.8334C8.5 11.1094 8.276 11.3334 8 11.3334Z'
        fill='currentColor'
      />
      <path
        d='M7.99951 11.3333C7.87146 11.3333 7.74353 11.2845 7.64612 11.1865L4.97949 8.5199C4.78418 8.32458 4.78418 8.00793 4.97949 7.8125C5.1748 7.61719 5.49146 7.61719 5.68689 7.8125L8.00012 10.1259L10.3135 7.8125C10.5088 7.61719 10.8256 7.61719 11.0209 7.8125C11.2162 8.00793 11.2162 8.32458 11.0209 8.5199L8.35413 11.1865C8.25549 11.2845 8.12756 11.3333 7.99951 11.3333Z'
        fill='currentColor'
      />
      <path
        d='M14.1666 16.0004H1.83337C0.822021 16.0004 0 15.1783 0 14.167V11.167C0 10.891 0.223999 10.667 0.5 10.667C0.776001 10.667 1 10.891 1 11.167V14.167C1 14.6263 1.37402 15.0004 1.83337 15.0004H14.1666C14.626 15.0004 15 14.6263 15 14.167V11.167C15 10.891 15.224 10.667 15.5 10.667C15.776 10.667 16 10.891 16 11.167V14.167C16 15.1783 15.178 16.0004 14.1666 16.0004Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1058_24723'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
