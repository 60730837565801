import React, { SVGProps } from 'react';

export const StepForward = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.625 21.1666C8.85733 21.1666 7.12936 20.6425 5.65959 19.6604C4.18983 18.6783 3.04428 17.2825 2.36783 15.6494C1.69137 14.0162 1.51438 12.2192 1.85923 10.4855C2.20409 8.7518 3.0553 7.15929 4.30523 5.90936C5.55516 4.65943 7.14767 3.80822 8.88138 3.46336C10.6151 3.11851 12.4121 3.2955 14.0452 3.97196C15.6783 4.64841 17.0742 5.79396 18.0563 7.26372C19.0383 8.73348 19.5625 10.4615 19.5625 12.2291V12.9166'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M22.3125 10.1638L19.5625 12.9138L16.8125 10.1638'
      stroke='white'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.0256 16.0994C12.4105 16.0994 13.4261 15.0767 13.4261 13.6847C13.4261 12.2749 12.446 11.2415 11.1108 11.2415C10.6207 11.2415 10.1449 11.4155 9.84659 11.6534H9.80398L10.0597 9.50852H13.0994V8.72727H9.32102L8.88068 12.3068L9.70455 12.4062C10.0064 12.1896 10.5213 12.0334 10.9688 12.0369C11.8956 12.044 12.5739 12.7472 12.5739 13.6989C12.5739 14.6328 11.9205 15.3182 11.0256 15.3182C10.2798 15.3182 9.68679 14.8388 9.61932 14.1818H8.76705C8.82031 15.2898 9.77557 16.0994 11.0256 16.0994Z'
      fill='white'
    />
  </svg>
);
