import React from 'react';

export const NoResponsesIndividualSVG = () => (
  <svg width='120' height='120' viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_40_2454)'>
      <path d='M120.094 12.2178H32.0756V33.6066H120.094V12.2178Z' fill='#E5E7EB' />
      <path d='M34.3836 31.2976H117.783V14.5278H34.3836V31.2976Z' fill='white' />
      <path
        d='M50.2686 29.3017C53.7971 29.3017 56.6575 26.4413 56.6575 22.9128C56.6575 19.3843 53.7971 16.5239 50.2686 16.5239C46.7402 16.5239 43.8798 19.3843 43.8798 22.9128C43.8798 26.4413 46.7402 29.3017 50.2686 29.3017Z'
        fill='#5850EC'
      />
      <path d='M108.287 20.4131H65.5455V21.802H108.287V20.4131Z' fill='#5850EC' />
      <path d='M108.287 24.0239H65.5455V25.4128H108.287V24.0239Z' fill='#5850EC' />
      <path d='M0 44.7266L74.4744 50.1588V69.7129L0 68.33V44.7266Z' fill='#E5E7EB' />
      <path d='M2.34573 65.8381L72.8481 67.5625V52.1601L2.34573 47.4316V65.8381Z' fill='white' />
      <path
        d='M17.8208 50.5825C18.6348 50.635 19.4275 50.8646 20.1436 51.255C20.8618 51.6476 21.4989 52.1731 22.0212 52.8034C22.5638 53.4555 22.9889 54.197 23.2774 54.9949C23.5817 55.8343 23.737 56.7204 23.7362 57.6133C23.7394 58.4927 23.584 59.3654 23.2774 60.1896C22.993 60.955 22.5668 61.6599 22.0212 62.2674C21.4972 62.8512 20.8579 63.3199 20.1436 63.6439C19.4142 63.9715 18.6199 64.1287 17.8208 64.1038C17.003 64.0778 16.201 63.8718 15.4719 63.5003C14.7334 63.1226 14.0753 62.6052 13.534 61.9768C12.966 61.3199 12.5202 60.5667 12.2174 59.7527C11.8962 58.8931 11.732 57.9827 11.7327 57.065C11.7291 56.161 11.8935 55.2641 12.2174 54.4201C12.5163 53.6407 12.9635 52.9266 13.5343 52.3174C14.078 51.7369 14.7385 51.2782 15.4723 50.9715C16.2152 50.6642 17.0186 50.5312 17.8208 50.5825Z'
        fill='#E5E7EB'
      />
      <path d='M31.6903 55.4224L66.004 57.2105V58.5102L31.6903 56.843V55.4224Z' fill='#E5E7EB' />
      <path d='M31.6903 59.1147L66.004 60.5878V61.8874L31.6903 60.5354V59.1147Z' fill='#E5E7EB' />
      <path d='M0 84.4491L74.4744 83.0664V102.621L0 108.053V84.4491Z' fill='#E5E7EB' />
      <path d='M2.34573 105.347L72.8481 100.619V85.2178L2.34573 86.9421V105.347Z' fill='white' />
      <path
        d='M11.7358 95.4045C11.772 94.4875 11.9729 93.5845 12.3288 92.7386C12.6631 91.9414 13.1383 91.2111 13.7316 90.5825C14.2963 89.982 14.9734 89.4981 15.7245 89.1584C16.4673 88.8239 17.2752 88.6588 18.0897 88.6749C18.889 88.6913 19.6741 88.889 20.3859 89.2532C21.0857 89.6143 21.7039 90.1154 22.2022 90.7252C22.7218 91.3605 23.1185 92.087 23.3718 92.8676C23.6447 93.7064 23.765 94.5874 23.7272 95.4687C23.693 96.3607 23.503 97.2398 23.1659 98.0664C22.847 98.85 22.3931 99.5715 21.8247 100.198C21.2789 100.803 20.6219 101.297 19.8897 101.653C19.1584 102.008 18.3579 102.197 17.5452 102.208C16.7404 102.218 15.9441 102.043 15.2174 101.697C14.4972 101.351 13.8575 100.859 13.3398 100.25C12.7953 99.6109 12.3785 98.8732 12.112 98.0769C11.8234 97.2168 11.6959 96.3109 11.7358 95.4045Z'
        fill='#E5E7EB'
      />
      <path d='M31.6903 92.2441L66.004 90.8921V92.1917L31.6903 93.6647V92.2441Z' fill='#E5E7EB' />
      <path d='M31.6903 95.9378L66.004 94.271V95.5706L31.6903 97.3588V95.9378Z' fill='#E5E7EB' />
    </g>
    <defs>
      <clipPath id='clip0_40_2454'>
        <rect width='120' height='120' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
