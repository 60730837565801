import { api } from '@api/reduxApi';
import { uid } from '@components/utils';

type CreateRecordingItems = {
  createItem: (file: File) => Promise<UploadManagerItem>;
  createItems: (files: File[]) => Promise<UploadManagerItem[]>;
};
type Params = {
  studyId?: number;
};
export const useCreateRecordingItems = (params?: Params): CreateRecordingItems => {
  const [createRepoSession] = api.useCreateRepoSessionMutation();

  async function createItem(file: File): Promise<UploadManagerItem> {
    return createRepoSession({ title: file.name, kind: 'recording', project_id: params?.studyId })
      .unwrap()
      .then((session) => ({
        session,
        file,
        id: uid(),
        status: 'queued',
        type: 'recording',
        progress: 0,
        tagIds: []
      }));
  }

  async function createItems(files: File[]): Promise<UploadManagerItem[]> {
    return Promise.all(files.map(createItem));
  }

  return {
    createItem,
    createItems
  };
};
