import React from 'react';
import cn from 'classnames';
import Input from 'components/fields/Input';

interface Props {
  labelFor?: string;
  className?: string;
}
const Label: React.FC<Props> = ({ labelFor, className, children }) => (
  <label htmlFor={labelFor} className={cn('block text-sm font-bold text-gray-700', className)}>
    {children}
  </label>
);

export default Label;
