import { format, isDate } from 'date-fns';
import qs from 'qs';

import { FilterState } from '../types';
import { bigIntToString } from '@components/shared/TableFilters/utils/bigIntToString';

export type JoinOp = FilterOp;

export const DATE_FORMAT = 'yyyy-MM-dd';

export const OP_NO_VALUE = ['is_blank', 'is_present'];

const isEmpty = (value: any) => value == null || value === '' || value.length === 0;

const encodeString = (value: string) => value.replace(/,/g, '\\,').replace(/ /g, '\\ ');

export const parseValue = (value: string | number | Date | (string | number)[] | undefined | null) => {
  if (value === undefined || value === null) {
    return '';
  }

  if (Array.isArray(value)) {
    return value.map((v) => (typeof v === 'string' ? encodeString(v) : v)).join(',');
  }

  if (isDate(value)) {
    return format(new Date(value), DATE_FORMAT);
  }

  return typeof value === 'string' ? encodeString(bigIntToString(value)) : value;
};

export const parse = <D extends Record<string, any>>(filter: FilterState<D>) => {
  const escapedId = encodeString(filter.definition.id);

  const period = filter.period && filter.period !== 'all' ? ` period:${filter.period}` : '';

  if (OP_NO_VALUE.includes(filter.operator)) {
    return `${escapedId} ${filter.operator}${period}`;
  }

  if (filter.definition.type === 'segment') {
    return `${filter.definition.type} ${filter.operator} ${escapedId}${period}`;
  }
  
  if (isEmpty(filter.value) && isEmpty(filter.range)) {
    return filter.allowNotSet ? `${escapedId} ${filter.operator == 'is' ? 'is_blank' : 'is_present'}${period}` : '';
  }

  if (filter.operator === 'is_between' && filter.range) {
    const { min, max } = filter.range;

    if (min && max) {
      return `${escapedId} ${filter.operator} ${parseValue(min)}~${parseValue(max)}${period}`;
    }
  }

  return `${escapedId} ${filter.operator} ${parseValue(filter.value)}${period}`;
};

export const encode = <D extends Record<string, any>>(
  joinOp: JoinOp,
  filters: FilterState<D>[],
  query?: string
): string =>
  qs.stringify(
    {
      ...(query && { q: query }),
      ...(joinOp === 'any' && { op: joinOp }),
      filters: filters.map((filter) => parse<D>(filter)).filter(Boolean)
    },
    { encode: true, arrayFormat: 'brackets', encodeValuesOnly: true }
  );
