import classNames from 'classnames';
import * as React from 'react';

export const COLORS = {
  red: ['red-100', 'red-600'],
  pink: ['pink-100', 'pink-600'],
  indigo: ['indigo-100', 'indigo-600'],
  yellow: ['yellow-100', 'yellow-600'],
  green: ['green-100', 'green-600'],
  blue: ['blue-100', 'blue-600'],
  white: ['white', 'gray-700', 'gray-200'],
  gray: ['gray-50', 'gray-700', 'gray-200'],
  orange: ['orange-50', 'orange-600']
};
export interface PillProps {
  className?: string;
  color: keyof typeof COLORS;
  onClick?: () => void;
  children: React.ReactNode;
}
export const Pill: React.FC<PillProps> = ({ className = '', children, color, onClick }) => {
  const [bg, text, border] = COLORS[color];

  return (
    <div
      onClick={() => onClick?.()}
      className={classNames(
        'px-1 inline-block text-xs font-bold leading-relaxed uppercase rounded-md',
        `bg-${bg}`,
        `text-${text}`,
        className,
        border && `border border-${border}`
      )}
    >
      {children}
    </div>
  );
};
