import * as React from 'react';
import { getConnectTeamsButtonProps } from '@components/getConnectTeamsButtonProps';
import { getConnectZoomButtonProps } from '@components/getConnectZoomButtonProps';
import { getConnectWebexButtonProps } from '@components/getConnectWebexButtonProps';
import { DropdownLink, Text, TippyOrNot } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { MeetingProviderIcon } from './MeetingProviderIcon';

interface MeetingProviderProps {
  option: MeetingProvider;
  onClick: (e: any) => void;
  isOwner: boolean;
}

export const MeetingProviderOption: React.FC<MeetingProviderProps> = ({ option, onClick, isOwner }) => {
  const getAdditionalProps = () => {
    if (option.name === 'Microsoft Teams' && option.connected_account_id) {
      if(!option.connected) {
        return getConnectTeamsButtonProps(option.connected_account_id);
      }
    } else if (option.name === 'Zoom' && !option.connected && isOwner) {
      return getConnectZoomButtonProps();
    } else if (option.name === 'Webex' && !option.connected) {
      return getConnectWebexButtonProps();
    }

  };

  const content = (disabled: boolean) => (
    <DropdownLink
      disabled={disabled}
      key={option.video_url_type}
      aria-label={option.video_url_type}
      data-testid={option.video_url_type}
      onClick={onClick}
      className='flex items-center'
      {...getAdditionalProps()}
    >
      <MeetingProviderIcon option={option} />

      <Text h='400' className='flex-1'>
        {option.name}
      </Text>
      {!option.connected && (
        <Text h='400' color='indigo-600'>
          Connect
        </Text>
      )}
    </DropdownLink>
  );

  if (option.name === 'In Person') {
    return (
      <RestrictedAction feature='in_person' tipPlacement='right'>
        {({ may }) => (
          content(!may)
        )}
      </RestrictedAction>
    )
  } else if (option.name !== 'In Person') {
    return (
      <TippyOrNot show={option.tooltip !== ''} content={option.tooltip}>
        {content(option.tooltip !== '' && !option.connected)}
      </TippyOrNot>
    )
  } else {
    return null;
  }
};
