import React, { SVGProps } from 'react';

export const PaperClipSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.9375 10.1869V5.375C11.9375 4.33071 11.5227 3.32919 10.7842 2.59077C10.0458 1.85234 9.04429 1.4375 8 1.4375C6.95571 1.4375 5.95419 1.85234 5.21577 2.59077C4.47734 3.32919 4.0625 4.33071 4.0625 5.375V11.9375C4.0625 12.6337 4.33906 13.3014 4.83134 13.7937C5.32363 14.2859 5.99131 14.5625 6.6875 14.5625C7.38369 14.5625 8.05137 14.2859 8.54365 13.7937C9.03594 13.3014 9.3125 12.6337 9.3125 11.9375V5.375C9.3125 5.0269 9.17422 4.69306 8.92808 4.44692C8.68194 4.20078 8.3481 4.0625 8 4.0625C7.6519 4.0625 7.31806 4.20078 7.07192 4.44692C6.82578 4.69306 6.6875 5.0269 6.6875 5.375V11.0625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
