import { addDaysToDate } from 'components/utils';

export const DURATION_OPTIONS = [
  { label: '15 minutes', value: 15 },
  { label: '30 minutes', value: 30 },
  { label: '45 minutes', value: 45 },
  { label: '60 minutes', value: 60 },
  { label: '75 minutes', value: 75 },
  { label: '90 minutes', value: 90 },
  { label: 'Custom', value: 'custom' }
].map((o) => ({ ...o, value: String(o.value) }));

export const DEFAULT_DURATION_VALUES = [15, 30, 45, 60, 75, 90];

export const TODAY = new Date();
export const TOMORROW = addDaysToDate(TODAY, 1);

export const SCHEDULING_INCREMENT = 15;

export const CALENDLY_AUTH_PATH = `/users/auth/calendly`;

export const MAX_WEEKS_TO_RENDER = 52;

export const VIEW_SETTINGS_OPTIONS = [
  { label: 'Full week (Mon - Sun)', value: '0' },
  { label: 'Weekdays only (Mon - Fri)', value: '1' }
];


export const MODERATION_STYLES = {
  single: 'Standard 1:1',
  collective: 'Collective',
  round_robin: 'Round robin'
};

export const LOCATION: Record<Study['video_url_type'], string> = {
  in_person: 'In person',
  zoom: 'Zoom',
  manual: 'Custom link',
  google_meet: 'Google Meet',
  microsoft_teams: 'Microsoft Teams',
  webex: 'Webex'
};

export const SUFFIXES = [
  {
    multiplier: 24 * 60,
    label: 'day'
  },
  {
    multiplier: 60,
    label: 'hour'
  },
  {
    multiplier: 1,
    label: 'minute'
  }
];
