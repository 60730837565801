import React, { VFC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Control } from 'react-hook-form';

import { Button } from '@components/common';
import { FigmaPrototype } from '@components/common/FigmaPrototype';
import { Portal } from '@components/shared/Portal';
import { Models, Enums, getBlockLabel } from '@components/SurveyBuilder';
import { useDeviceType } from '@hooks/useDeviceType';

import { usePrototypeTaskTracking } from '../hooks/usePrototypeTaskTracking';
import { usePrototypeTaskValidation } from '../hooks/usePrototypeTaskValidation';
import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';

import { ResponsiveCard } from './ResponsiveCard';

import * as Types from '../types';

interface Props {
  block: Models.Block<Enums.Kind.prototypeTest>;
  control: Control<Types.FormData>;
  iframePortalSelector: string;
  onComplete?: () => void;
  onPrototypeLoaded?: () => void;
}

export const PrototypeTestTask: VFC<Props> = ({
  block,
  control,
  iframePortalSelector,
  onComplete,
  onPrototypeLoaded
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isComplete, onIframePrototypeNodeChange } = usePrototypeTaskValidation({ block });
  const { events, onMousePress, onNodeChange } = usePrototypeTaskTracking();
  const { blocks } = useUnmoderatedContext();
  const { isMobile } = useDeviceType();

  const startingNodeId = block.blockable.paths?.[0]?.steps?.find((step) => !step.last)?.external_element_id;

  const handleOnLoad = () => {
    setIsLoaded(true);
    onPrototypeLoaded?.();
  };

  useEffect(() => {
    control.register('prototype_test_task');
  }, [control]);

  useEffect(() => {
    control.setValue('prototype_test_task', { isComplete, events });

    if (isComplete) {
      onComplete?.();
    }
  }, [control, events, isComplete]);

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      isComplete={isComplete}
      totalBlocks={blocks.length}
      renderFooter={() =>
        isComplete ? (
          <Button aria-label='Continue' className='btn-custom-brand' type='submit' icon='arrowRight' small noStyle>
            Continue
          </Button>
        ) : (
          <Button aria-label='End task' className='btn-custom-brand' type='submit' icon='checkInCircle' small noStyle>
            End task
          </Button>
        )
      }
    >
      <Portal selector={iframePortalSelector}>
        <div
          data-testid='prototype-test-task-wrapper'
          className={cn('h-full w-full', { 'bg-black pb-16': isLoaded && isMobile })}
        >
          <FigmaPrototype
            className='w-full h-full'
            hideUi
            hideHotspots
            nodeId={startingNodeId}
            data-testid='prototype-test-task-iframe'
            onLoad={handleOnLoad}
            onPrototypePresentedNodeChanged={(data) => {
              onIframePrototypeNodeChange(data);
              onNodeChange?.(data);
            }}
            onPrototypeMousePress={(data) => {
              onMousePress?.(data);
            }}
            src={block.blockable.url ?? ''}
          />
        </div>
      </Portal>
    </ResponsiveCard>
  );
};
