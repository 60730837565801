import React, { SVGProps } from 'react';

export const ShareSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.47274 3.79354C6.27014 3.90755 4.21082 4.82445 2.63085 6.40445C0.939361 8.09591 0.0078125 10.337 0.0078125 12.7149V16L1.19784 13.2567C2.5878 10.487 5.40653 8.65072 8.47274 8.47989V12.2657L15.9923 6.12358L8.47274 0V3.79354ZM9.40982 4.7185V1.97161L14.5096 6.12467L9.40982 10.2903V7.52973H8.94128C7.16465 7.52973 5.42 8.02928 3.896 8.97436C2.74189 9.69006 1.75859 10.6336 1.00377 11.742C1.48505 7.78973 4.86119 4.7185 8.94128 4.7185H9.40982Z'
      fill='currentColor'
    />
  </svg>
);
