import React from 'react';

export const SubjectPreview: React.FC<{ children: string; renderMergeTag?: any }> = ({ children, renderMergeTag }) => {
  const strs = children.split(/(\{\{.*\}\})/).map((text) => {
    if (text.match(/(\{\{.*\}\})/)) {
      const tag = text.replace('{{', '').replace('}}', '');
      return renderMergeTag(tag);
    } else {
      return text;
    }
  });
  return <span>{strs}</span>;
};
