import { api } from 'api/reduxApi';
import { DropdownInputProps, SelectDropdown } from 'components/shared/SelectDropdown';
import React, { forwardRef, HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass } from '@components/CandidateAttrs/index';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { validateEmail } from '@helpers/emailRegexpValidation';
import { ErrorModal } from '@components/shared/GridTable/components/ErrorModal';

const Input = forwardRef<HTMLInputElement, HTMLAttributes<HTMLInputElement> & DropdownInputProps>(
  ({ __dropdownInputProps: _, ...rest }, ref) => (
    <input ref={ref} {...rest} placeholder='Enter…' className='w-full outline-none border-0 py-2.5 px-4 shadow-none' />
  )
);

export const SingleChoiceInput: React.FC<CandidateAttrInput<string>> = ({
  attr,
  value,
  onSave,
  setValue,
  styles,
  label,
  autoFocus,
  onFocus,
  onBlur,
  isStudyAttr
}) => {
  const { handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);
  const [query, setQuery] = useState<string>('');
  const [errorModal, setErrorModal] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const { data, isFetching } = api.useGetCandidateAttrsSuggestionsQuery(
    { id: attr.id, q: query },
    { skip: attr.lookup === 'timezone' || isStudyAttr }
  );

  const items = useMemo(() => {
    if (data) {
      return data.map((v) => ({ label: v.toString(), value: v }));
    }

    if (attr.lookup === 'timezone' || isStudyAttr) {
      return attr.values?.map((v) => ({ label: v.toString(), value: v }));
    }

    return [];
  }, [data, attr]);

  const selected = useMemo(() => {
    if (value) {
      return { label: value, value: value };
    }

    return null;
  }, [value]);

  const { callback } = useDebouncedCallback((value) => {
    setQuery(value);
  }, 500);

  const onSelect = (item: DropdownItem) => {
    setValue(item.value);

    if (attr.name === 'email' && !validateEmail(item.value)) {
      setErrorModal(true);
      return;
    }

    onSave?.(item.value);
  };

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles?.label}>{label}</td>
      <td className={styles?.value}>
        <SelectDropdown
          inputRef={ref}
          options={items ?? []}
          value={selected ? [selected] : []}
          loading={isFetching}
          onInputValueChange={(value) => callback(value)}
          onChange={(newValue) => onSelect(newValue[0])}
          overrides={{
            Input: {
              component: Input,
              props: {
                autoFocus,
                onFocus: handleFocus
              }
            },
            Item: {
              props: {
                className: 'xx-combo-option'
              }
            }
          }}
          onCreate={onSelect}
          creatable
          defaultIsOpen
        />
        <ErrorModal
          attrName={attr.label.toLowerCase()}
          onClose={() => {
            setErrorModal(false);
            ref.current?.focus();
          }}
          open={errorModal}
          invalidValue={value || 'empty string'}
        />
      </td>
    </tr>
  );
};
