import * as React from 'react';

import { ProgressBar } from '@components/common';
import { Pill, PillProps } from '@components/common/Pill';

import { CellFC } from './types';

const COLORS: Record<UploadManagerItem['status'], PillProps['color']> = {
  uploading: 'gray',
  canceled: 'gray',
  queued: 'gray',
  done: 'green',
  errored: 'red'
};

export const Status: CellFC = ({ row: { original: item } }) => {
  if (item.status === 'uploading') {
    return item.type === 'recording' ? <ProgressBar progress={item.progress} /> : <ProgressBar endless />;
  }

  return <Pill color={COLORS[item.status]}>{item.status}</Pill>;
};
