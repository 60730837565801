// error messages
export const ERROR_NAME = 'Name is required';
export const ERROR_ROLE = 'Role is required';

// integrations modal messages
export const INTEGRATION_MODAL_MSGS = {
  google: 'Are you sure?',
  microsoft: 'Are you sure?',
  zoom: 'Are you sure?',
  calendly: 'Are you sure?',
  salesforce: 'Are you sure?',
  slack: 'Are you sure?'
};
