import React, { SVGProps } from 'react';

export const ExternalLinkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      d='M13.5631 4.37384V0.43634H9.62561'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M13.5631 0.43634L4.81311 9.18634'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M6.56311 3.06134H1.31311C1.08105 3.06134 0.858486 3.15353 0.694392 3.31762C0.530298 3.48172 0.43811 3.70428 0.43811 3.93634V12.6863C0.43811 12.9184 0.530298 13.141 0.694392 13.3051C0.858486 13.4692 1.08105 13.5613 1.31311 13.5613H10.0631C10.2952 13.5613 10.5177 13.4692 10.6818 13.3051C10.8459 13.141 10.9381 12.9184 10.9381 12.6863V7.43634'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
