import React, { SVGProps } from 'react';

export const BubblePictureSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_2403_356)'>
        <path
          d='M14.5 12.5H7.5L3.5 15.5V12.5H1.5C1.23479 12.5 0.980427 12.3947 0.792893 12.2071C0.605357 12.0196 0.5 11.7652 0.5 11.5V1.5C0.5 1.23479 0.605357 0.980427 0.792893 0.792893C0.980427 0.605357 1.23479 0.5 1.5 0.5H14.5C14.7652 0.5 15.0196 0.605357 15.2071 0.792893C15.3947 0.980427 15.5 1.23479 15.5 1.5V11.5C15.5 11.7652 15.3947 12.0196 15.2071 12.2071C15.0196 12.3947 14.7652 12.5 14.5 12.5Z'
          stroke='currentColor'
          strokeWidth='0.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.80664 3.5C4.66857 3.5 4.55664 3.38807 4.55664 3.25C4.55664 3.11193 4.66857 3 4.80664 3'
          stroke='currentColor'
          strokeWidth='0.88'
        />
        <path
          d='M4.80664 3.5C4.94471 3.5 5.05664 3.38807 5.05664 3.25C5.05664 3.11193 4.94471 3 4.80664 3'
          stroke='currentColor'
          strokeWidth='0.88'
        />
        <path
          d='M12.4997 8.50061L9.91967 4.6093C9.87461 4.54157 9.81361 4.4859 9.74207 4.44711C9.67054 4.40833 9.59067 4.38761 9.50927 4.38675C9.42794 4.38589 9.34761 4.40493 9.27527 4.44221C9.20294 4.47949 9.14087 4.53387 9.09434 4.60063L7.76101 6.5013L6.93967 5.84129C6.88547 5.79806 6.82294 5.76654 6.75594 5.74875C6.68894 5.73095 6.61895 5.72727 6.55045 5.73792C6.48195 5.74857 6.41641 5.77334 6.35797 5.81064C6.29955 5.84795 6.2495 5.89697 6.21101 5.95463L4.51367 8.50061'
          stroke='currentColor'
          strokeWidth='0.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M3.5 8.5H12.5' stroke='currentColor' strokeWidth='0.88' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_2403_356'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
