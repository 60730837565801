import * as React from 'react';

import Activity from './Activity';

interface Props {
  activities: PublicActivity[];
  scope?: any;
}

const Activities: React.FC<Props> = ({ activities, scope }) => (
  <ul className='divide-y divide-gray-200'>
    {activities.length == 0 && (
      <div className='flex items-center justify-center py-6 text-gray-500'>There is no activity to show</div>
    )}
    {activities.length > 0 && activities.map((a) => <Activity key={a.id} scope={scope} activity={a} />)}
  </ul>
);

export default Activities;
