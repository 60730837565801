import React, { SVGProps } from 'react';

export const FocusGroupsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <rect width='32' height='32' rx='4' fill='none' />
    <g clipPath='url(#clip0_1422_13668)'>
      <path
        d='M6.71777 21.1563C6.71777 21.7944 6.97129 22.4065 7.42255 22.8577C7.87381 23.309 8.48585 23.5625 9.12402 23.5625C9.7622 23.5625 10.3742 23.309 10.8255 22.8577C11.2768 22.4065 11.5303 21.7944 11.5303 21.1563C11.5303 20.5181 11.2768 19.906 10.8255 19.4548C10.3742 19.0035 9.7622 18.75 9.12402 18.75C8.48585 18.75 7.87381 19.0035 7.42255 19.4548C6.97129 19.906 6.71777 20.5181 6.71777 21.1563Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5615 26.3125C12.2292 25.6895 11.7339 25.1685 11.1284 24.8052C10.5229 24.4419 9.83012 24.25 9.12402 24.25C8.41793 24.25 7.72511 24.4419 7.11963 24.8052C6.51416 25.1685 6.0188 25.6895 5.68652 26.3125'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.4678 21.1563C20.4678 21.4722 20.53 21.7851 20.6509 22.0771C20.7719 22.369 20.9491 22.6343 21.1725 22.8577C21.396 23.0812 21.6613 23.2584 21.9532 23.3793C22.2451 23.5003 22.558 23.5625 22.874 23.5625C23.19 23.5625 23.5029 23.5003 23.7949 23.3793C24.0868 23.2584 24.3521 23.0812 24.5755 22.8577C24.7989 22.6343 24.9762 22.369 25.0971 22.0771C25.218 21.7851 25.2803 21.4722 25.2803 21.1563C25.2803 20.8403 25.218 20.5274 25.0971 20.2354C24.9762 19.9435 24.7989 19.6782 24.5755 19.4548C24.3521 19.2313 24.0868 19.0541 23.7949 18.9332C23.5029 18.8122 23.19 18.75 22.874 18.75C22.558 18.75 22.2451 18.8122 21.9532 18.9332C21.6613 19.0541 21.396 19.2313 21.1725 19.4548C20.9491 19.6782 20.7719 19.9435 20.6509 20.2354C20.53 20.5274 20.4678 20.8403 20.4678 21.1563Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.3115 26.3125C25.9792 25.6895 25.4839 25.1685 24.8784 24.8052C24.2729 24.4419 23.5801 24.25 22.874 24.25C22.1679 24.25 21.4751 24.4419 20.8696 24.8052C20.2642 25.1685 19.7688 25.6895 19.4365 26.3125'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5928 8.09375C13.5928 8.40974 13.655 8.72264 13.7759 9.01458C13.8969 9.30652 14.0741 9.57178 14.2975 9.79523C14.521 10.0187 14.7863 10.1959 15.0782 10.3168C15.3701 10.4378 15.683 10.5 15.999 10.5C16.315 10.5 16.6279 10.4378 16.9199 10.3168C17.2118 10.1959 17.4771 10.0187 17.7005 9.79523C17.9239 9.57178 18.1012 9.30652 18.2221 9.01458C18.343 8.72264 18.4053 8.40974 18.4053 8.09375C18.4053 7.77776 18.343 7.46486 18.2221 7.17292C18.1012 6.88098 17.9239 6.61572 17.7005 6.39227C17.4771 6.16883 17.2118 5.99159 16.9199 5.87066C16.6279 5.74974 16.315 5.6875 15.999 5.6875C15.683 5.6875 15.3701 5.74974 15.0782 5.87066C14.7863 5.99159 14.521 6.16883 14.2975 6.39227C14.0741 6.61572 13.8969 6.88098 13.7759 7.17292C13.655 7.46486 13.5928 7.77776 13.5928 8.09375Z'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.9774 12.5625C18.61 12.1312 18.1534 11.7847 17.639 11.5472C17.1246 11.3096 16.5648 11.1866 15.9982 11.1866C15.4316 11.1866 14.8718 11.3096 14.3574 11.5472C13.8431 11.7847 13.3864 12.1312 13.019 12.5625'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path stroke='currentColor' strokeWidth='1.375' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M10.819 10.5C10.0665 11.2048 9.46662 12.0566 9.05666 13.0027C8.6467 13.9488 8.43534 14.9689 8.43567 16C8.43567 16.2319 8.44942 16.4583 8.4705 16.6875'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.5267 16.6875C23.5469 16.4602 23.5616 16.2292 23.5616 16C23.5621 14.9689 23.3508 13.9487 22.9408 13.0026C22.5308 12.0565 21.9309 11.2047 21.1782 10.5'
        stroke='currentColor'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1422_13668'>
        <rect width='22' height='22' fill='white' transform='translate(5 5)' />
      </clipPath>
    </defs>
  </svg>
);
