import * as React from 'react';

import { api } from '@api/reduxApi';
import { Avatar, Text } from '@components/common';
import { AvatarInitials } from '@components/common/Avatar';
import { useAccount } from '@hooks/useAccount';
import { capitalize } from 'components/utils';

type Props = {
  participation: Participation;
  calendarEvent: CalendarEvent;
};

export const Guests: React.FC<Props> = ({ participation, calendarEvent }) => {
  const {
    account: { team }
  } = useAccount();

  const { data: studyUsers } = api.useGetStudyUsersQuery(participation.project_id);

  const findRole = (id: number) => studyUsers?.find((u) => u.user_id === id)?.role || 'no role';

  return (
    <div className='flex flex-col space-y-3'>
      {calendarEvent.guests.map((guest) => {
        const user = team.find((u) => u.email.toLowerCase() === guest.email.toLowerCase());
        const candidate =
          participation.email?.toLowerCase() === guest.email.toLowerCase() ? participation.customer : null;

        const role = user ? findRole(user.id) : candidate ? 'candidate' : 'no role';

        return (
          <div key={guest.email} className='flex'>
            {user && <Avatar className='mr-2' user={user} size='md' />}
            {!user && candidate && <AvatarInitials className='mr-2' size='md' initials={candidate.initials} />}

            <div>
              <Text h='400'>{user?.name || guest.email}</Text>
              <Text h='200' color='gray-500'>
                {capitalize(role)}
              </Text>
            </div>
          </div>
        );
      })}
    </div>
  );
};
