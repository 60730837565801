import Tippy, { TippyProps } from '@tippyjs/react';
import * as React from 'react';

interface Props extends TippyProps {
  show?: boolean;
}
export const TippyOrNot: React.FC<Props> = ({ show, children, ...props }) => {
  if (show) {
    return (
      <Tippy {...props}>
        <div>{children}</div>
      </Tippy>
    );
  }
  return children ? children : null;
};
