import {
  getHourMin,
  getHours as getHoursNumber,
  getTime
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { getHours, getMinutes, set, sub } from 'date-fns';
import { track } from '@components/tracking';

export const getBlockDate = (eventDate: string) => {
  const [yy, mm, dd] = eventDate.split('-').map(Number);
  const year = new Date(yy, mm - 1, dd).getFullYear();
  const mmString = (mm < 10 ? '0' : '') + mm;
  const ddString = (dd < 10 ? '0' : '') + dd;
  const dateString = `${year}-${mmString}-${ddString}`;
  const date = new Date(`${dateString}T00:00:00`);

  return date;
};

const RUNG_HEIGHT_IN_PIXELS = 12;
const MINUTES_IN_ONE_RUNG = 15;

type OnDragTop = (args: {
  pxDiff: number;
  event: SlotInstance;
  startHour: number;
  schedulingIncrement: number;
  rungStyles: RungStyles;
  onUpdate: (args: { time: string; duration: number }, debounce?: boolean) => void;
}) => void;

export const onDragTop: OnDragTop = ({ pxDiff, event, startHour, schedulingIncrement, rungStyles, onUpdate }) => {
  const { hour, min } = getHourMin(event.time);

  const maxHeight = (getHoursNumber(event.time) - startHour) * 48 + (event.duration / 60) * 48;
  const diffInMinutes = pxDiff * (schedulingIncrement / rungStyles.height);
  const date = sub(set(new Date(), { hours: hour, minutes: min }), { minutes: diffInMinutes * -1 });

  const startOfDay = set(new Date(), { hours: 0, minutes: 0 });
  // If the date is before the start of the day, we should use the start of the day
  // to prevent jumping to the previous day, because
  // in this case we can get previous day evening and time would calculate wrong
  const minDate = Math.max(startOfDay.getTime(), date.getTime());

  const minDateHours = new Date(minDate).getHours() + new Date(minDate).getMinutes() / 60;

  const duration = Math.min(
    (maxHeight * MINUTES_IN_ONE_RUNG) / RUNG_HEIGHT_IN_PIXELS,
    event.duration + diffInMinutes * -1
  );

  const time = getTime({
    hour: minDateHours <= startHour ? startHour : getHours(date),
    min: minDateHours <= startHour ? 0 : getMinutes(date)
  });

  track('availability_slot_updated', { place: 'availability_block_resize' });

  onUpdate({ time, duration }, true);
};
