import * as React from 'react';

import { getFormKey } from '../helpers/getFormKey';
import { InputProps } from '../types';
import { useTranslation } from 'react-i18next';

export const MultipleChoiceInput: React.FC<InputProps> = ({ autofocus, field, watch, onFocus, register, error }) => {
  const { id, required, options, other } = field;
  const { t } = useTranslation('SurveyQuestion');
  const name = id.toString();
  const labelName = getFormKey(field);
  const selected = watch(labelName);
  return (
    <fieldset>
      <legend className='sr-only'>
        {field.label},{field.helper}
      </legend>
      {options
        ?.filter((e) => e != '')
        .map((o, i) => (
          <div key={o} className='flex items-center flex-nowrap'>
            <input
              id={`${String(id)}${o}`}
              type='checkbox'
              className='focus-ring-custom-brand mt-0.5 w-4 h-4 mr-2 input-custom-brand border-gray-400'
              name={labelName}
              value={o}
              autoFocus={i === 0 && autofocus}
              ref={register({ required })}
              aria-invalid={error ? 'true' : 'false'}
              aria-required={required ? 'true' : 'false'}
              aria-labelledby={`${String(id)}${o}`}
            />
            <label
              id={`${String(id)}${o}`}
              htmlFor={`${String(id)}${o}`}
              onFocus={onFocus}
              className='flex py-2 text-base font-normal normal-case cursor-pointer'
            >
              {o}
            </label>
          </div>
        ))}
      {other && (
        <div className='flex items-center flex-nowrap'>
          <input
            type='checkbox'
            className=' focus-ring-custom-brand mt-0.5 w-4 h-4 mr-2 input-custom-brand border-gray-400'
            name={labelName}
            ref={register({ required })}
            value='other'
            id={`${String(id)}${name}-other`}
            aria-invalid={error ? 'true' : 'false'}
          />
          <label
            htmlFor={`${String(id)}${name}-other`}
            id={`${String(id)}${name}-other`}
            onFocus={onFocus}
            className='flex py-2 text-base font-normal normal-case cursor-pointer'
          >
            {t('other')}
          </label>
        </div>
      )}
      {selected && Array.isArray(selected) && selected.includes('other') && (
        <input
          type='text'
          className='rounded-d block w-full px-10 py-3 mt-2 border border-gray-200'
          placeholder={t('please_specify')}
          onFocus={onFocus}
          autoFocus
          name={`${name}.other`}
          aria-invalid={error ? 'true' : 'false'}
          ref={register({ required: required || selected?.includes('other') })}
        />
      )}
    </fieldset>
  );
};
