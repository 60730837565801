import React, { SVGProps } from 'react';

export const ChatPromptSubmitSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='Group 5653'>
      <path
        id='Vector'
        d='M5.60932 14.1169C5.39856 14.1169 5.19353 14.0483 5.0252 13.9214C4.85687 13.7946 4.7344 13.6164 4.67629 13.4138C4.61819 13.2112 4.62759 12.9952 4.7031 12.7985C4.77861 12.6017 4.9161 12.4348 5.09482 12.3231L15.8766 5.5833C16.0365 5.48321 16.2222 5.43193 16.4108 5.43575C16.5995 5.43956 16.7829 5.49831 16.9387 5.60479C17.0944 5.71128 17.2158 5.86087 17.2878 6.03526C17.3598 6.20965 17.3794 6.40126 17.3442 6.58663L15.2442 17.7709C15.2094 17.9559 15.1215 18.1268 14.9912 18.2628C14.861 18.3987 14.694 18.4938 14.5106 18.5366C14.3272 18.5793 14.1354 18.5678 13.9584 18.5034C13.7815 18.439 13.6271 18.3245 13.5141 18.174L10.4749 14.1169H5.60932Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_2'
        d='M10.4749 14.1172H9.0166V17.1336C9.01659 17.3101 9.06471 17.4832 9.15578 17.6343C9.24685 17.7855 9.37742 17.9089 9.53343 17.9913C9.68944 18.0738 9.86499 18.1121 10.0412 18.1022C10.2173 18.0923 10.3875 18.0345 10.5333 17.9351L12.3871 16.6716L10.4749 14.1172Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Vector_3'
        d='M10.4744 14.1163L16.9377 5.60547'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
);
