import React, { SVGProps } from 'react';

export const ImportSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.00058 10.1875V3.1875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.6256 5.8125L8.00058 3.1875L5.37558 5.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5631 10.1875V11.0625C14.5631 11.5266 14.3787 11.9717 14.0505 12.2999C13.7223 12.6281 13.2772 12.8125 12.8131 12.8125H3.18808C2.72395 12.8125 2.27884 12.6281 1.95065 12.2999C1.62246 11.9717 1.43808 11.5266 1.43808 11.0625V10.1875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
