import React, { SVGProps } from 'react';

export const FailedBubbleSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.17328 12.1743C0.862221 11.8629 0.6875 11.4407 0.6875 11.0006C0.6875 10.5604 0.862221 10.1382 1.17328 9.82676L9.82662 1.17435C9.98057 1.02002 10.1634 0.897583 10.3648 0.814042C10.5661 0.730502 10.782 0.6875 11 0.6875C11.2179 0.6875 11.4338 0.730502 11.6351 0.814042C11.8365 0.897583 12.0193 1.02002 12.1733 1.17435L20.8266 9.82676C21.1377 10.1382 21.3124 10.5604 21.3124 11.0006C21.3124 11.4407 21.1377 11.8629 20.8266 12.1743L12.1733 20.8268C11.862 21.1378 11.44 21.3125 11 21.3125C10.5599 21.3125 10.1379 21.1378 9.82662 20.8268L1.17328 12.1743Z'
        fill='#E02424'
        stroke='#E02424'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11 15.125C10.932 15.125 10.8656 15.1452 10.809 15.1829C10.7525 15.2207 10.7084 15.2744 10.6824 15.3372C10.6564 15.4 10.6496 15.4691 10.6629 15.5358C10.6761 15.6025 10.7089 15.6637 10.7569 15.7118C10.805 15.7599 10.8663 15.7926 10.9329 15.8059C10.9996 15.8192 11.0687 15.8124 11.1315 15.7863C11.1944 15.7603 11.248 15.7163 11.2858 15.6597C11.3236 15.6032 11.3437 15.5367 11.3437 15.4688C11.3437 15.3776 11.3075 15.2901 11.2431 15.2257C11.1786 15.1612 11.0912 15.125 11 15.125Z'
        fill='white'
        stroke='white'
        strokeWidth='1.375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11 12.375V5.5' stroke='white' strokeWidth='1.375' strokeMiterlimit='10' strokeLinecap='round' />
    </svg>
  );
};
