import * as React from 'react';

import { Button } from '@components/common';
import { moneyFormat } from '@components/utils';

interface Props {
  platformName: string;
  marketType: string;
  b2bFee: number;
  b2cFee: number;
  candidates: number;
}
export const CostInfo: React.FC<Props> = ({ platformName, marketType, b2bFee, b2cFee, candidates }) => {
  const feePerParticipant = marketType === 'b2b' ? b2bFee : b2cFee;
  const totalCost = moneyFormat(candidates * feePerParticipant, 0);

  return (
    <>
      <div className='p-4 border border-gray-200 rounded'>
        <div className='h500 font-bold text-gray-700'>Estimated cost</div>
        <div className='flex flex-row items-center justify-between mt-4'>
          <div className='h400 text-gray-700'>Fee per participant</div>
          <div className='h400 font-bold text-gray-700'>${feePerParticipant}</div>
        </div>
        <div className='flex flex-row items-center justify-between pt-4 mt-4 border-t border-gray-200'>
          <div className='h400 text-gray-700'>Participant limit</div>
          <div className='h400 font-bold text-gray-700'>{candidates}</div>
        </div>
        <div className='flex flex-row items-center justify-between pt-4 mt-4 border-t border-gray-200'>
          <div className='h400 font-bold text-gray-700'>Total estimated cost</div>
          <div className='h400 font-bold text-gray-700'>${totalCost}</div>
        </div>
        <div className='h400 pt-4 mt-4 leading-snug text-gray-700 border-t border-gray-200'>
          You'll receive a refund of the unused fees if the full limit of participants don't complete the study.
        </div>
      </div>
      {platformName === 'Respondent' && (
        <div className='p-4 mt-4 border border-gray-200 rounded'>
          <div className='flex justify-center'>
            <img src='/temp-assets/candidates/respondent.png' alt='respondent-logo' />
          </div>
          <div className='mt-4'>
            <span className='h400 leading-snug text-gray-700'>
              Leading B2B and B2C research participant recruitment platform.
            </span>
            <span className='h400 text-gray-700'>
              <ul className='pl-4 list-disc'>
                <li>3M+ participants</li>
                <li>30 minute median time to first match</li>
                <li>Less than 5% no-show rate</li>
              </ul>
            </span>
          </div>
          <div className='mt-4'>
            <Button icon='newTab' className='w-full' href='https://www.respondent.io/' target='_blank'>
              Visit website
            </Button>
          </div>
        </div>
      )}
      {platformName === 'Prolific' && (
        <div className='p-4 mt-4 border border-gray-200 rounded'>
          <div className='flex justify-center'>
            <img src='/temp-assets/candidates/prolific.png' alt='prolific-logo' />
          </div>
          <div className='mt-4'>
            <span className='h400 leading-snug text-gray-700'>
              Leading B2C research participant recruitment platform.
            </span>
            <span className='h400 text-gray-700'>
              <ul className='pl-4 list-disc'>
                <li>120k active, vetted participants</li>
                <li>Participants in 38 countries</li>
              </ul>
            </span>
          </div>
          <div className='mt-4'>
            <Button icon='newTab' className='w-full' href='https://www.prolific.com/' target='_blank'>
              Visit website
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
