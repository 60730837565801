import { SelectOption } from '@components/common';

export const getLayoutOptions = ({
  default_email_layout,
  delivery_method
}: {
  default_email_layout: StudyMessage['layout'];
  delivery_method: EmailSender['delivery_method'];
}): SelectOption<StudyMessage['layout']>[] => {
  if (default_email_layout !== 'default') {
    const isNylas = delivery_method === 'nylas';
    return [
      { label: 'Custom layout', value: default_email_layout, disabled: !isNylas },
      { label: 'Branded HTML layout', value: 'default' },
      { label: 'Rich text', value: 'rich_text' }
    ];
  }
  return [
    { label: 'Branded HTML layout', value: 'default' },
    { label: 'Rich text', value: 'rich_text' }
  ];
};
