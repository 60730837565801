import React, { SVGProps } from 'react';

export const AttendeesSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <g clipPath='url(#clip0_1677_8864)'>
      <path
        d='M14.8125 11.3086H9.1875C9.1875 10.5627 9.48382 9.8473 10.0113 9.31986C10.5387 8.79241 11.2541 8.49609 12 8.49609C12.7459 8.49609 13.4613 8.79241 13.9887 9.31986C14.5162 9.8473 14.8125 10.5627 14.8125 11.3086Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.89062 6.38672C9.89062 6.94616 10.1129 7.48269 10.5084 7.87827C10.904 8.27386 11.4406 8.49609 12 8.49609C12.5594 8.49609 13.096 8.27386 13.4916 7.87827C13.8871 7.48269 14.1094 6.94616 14.1094 6.38672C14.1094 5.82728 13.8871 5.29075 13.4916 4.89517C13.096 4.49958 12.5594 4.27734 12 4.27734C11.4406 4.27734 10.904 4.49958 10.5084 4.89517C10.1129 5.29075 9.89062 5.82728 9.89062 6.38672Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.07812 1.46484H16.9219C16.9219 1.46484 19.7344 1.46484 19.7344 4.27734V8.49609C19.7344 8.49609 19.7344 11.3086 16.9219 11.3086H7.07812C7.07812 11.3086 4.26562 11.3086 4.26562 8.49609V4.27734C4.26562 4.27734 4.26562 1.46484 7.07812 1.46484Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.85938 17.6367C2.85938 18.1962 3.08161 18.7327 3.4772 19.1283C3.87278 19.5239 4.40931 19.7461 4.96875 19.7461C5.52819 19.7461 6.06472 19.5239 6.4603 19.1283C6.85589 18.7327 7.07812 18.1962 7.07812 17.6367C7.07812 17.0773 6.85589 16.5407 6.4603 16.1452C6.06472 15.7496 5.52819 15.5273 4.96875 15.5273C4.40931 15.5273 3.87278 15.7496 3.4772 16.1452C3.08161 16.5407 2.85938 17.0773 2.85938 17.6367Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.48438 22.5586C8.14454 21.9214 7.63793 21.3886 7.01869 21.017C6.39946 20.6455 5.69089 20.4492 4.96875 20.4492C4.24661 20.4492 3.53804 20.6455 2.91881 21.017C2.29957 21.3886 1.79296 21.9214 1.45312 22.5586'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.9219 17.6367C16.9219 18.1962 17.1441 18.7327 17.5397 19.1283C17.9353 19.5239 18.4718 19.7461 19.0312 19.7461C19.5907 19.7461 20.1272 19.5239 20.5228 19.1283C20.9184 18.7327 21.1406 18.1962 21.1406 17.6367C21.1406 17.0773 20.9184 16.5407 20.5228 16.1452C20.1272 15.7496 19.5907 15.5273 19.0312 15.5273C18.4718 15.5273 17.9353 15.7496 17.5397 16.1452C17.1441 16.5407 16.9219 17.0773 16.9219 17.6367Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5469 22.5586C22.207 21.9214 21.7004 21.3886 21.0812 21.017C20.462 20.6455 19.7534 20.4492 19.0312 20.4492C18.3091 20.4492 17.6005 20.6455 16.9813 21.017C16.3621 21.3886 15.8555 21.9214 15.5156 22.5586'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.89062 17.6367C9.89062 18.1962 10.1129 18.7327 10.5084 19.1283C10.904 19.5239 11.4406 19.7461 12 19.7461C12.5594 19.7461 13.096 19.5239 13.4916 19.1283C13.8871 18.7327 14.1094 18.1962 14.1094 17.6367C14.1094 17.0773 13.8871 16.5407 13.4916 16.1452C13.096 15.7496 12.5594 15.5273 12 15.5273C11.4406 15.5273 10.904 15.7496 10.5084 16.1452C10.1129 16.5407 9.89062 17.0773 9.89062 17.6367Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.5156 22.5586C15.1758 21.9214 14.6692 21.3886 14.0499 21.017C13.4307 20.6455 12.7221 20.4492 12 20.4492C11.2779 20.4492 10.5693 20.6455 9.95006 21.017C9.33082 21.3886 8.82421 21.9214 8.48438 22.5586'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1677_8864'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
