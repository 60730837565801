import React, { forwardRef, HTMLAttributes } from 'react';

export const Clip = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='6' cy='6' r='5.5' stroke='#6B7280' />
    <circle cx='6.00021' cy='3.42794' r='0.857143' fill='#6B7280' />
    <circle cx='3.42794' cy='6.00021' r='0.857143' fill='#6B7280' />
    <circle cx='8.57101' cy='6.00021' r='0.857143' fill='#6B7280' />
    <circle cx='6.00021' cy='8.57101' r='0.857143' fill='#6B7280' />
    <path d='M6 11.5708H12' stroke='#6B7280' strokeLinecap='round' />
  </svg>
));

export const Insight = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1062_7755)'>
      <path d='M8 2.31308V1.43808' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M11.7123 3.85132L12.3312 3.23241'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.25 7.56308H14.125'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7123 11.2754L12.3312 11.8943'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.28762 3.85132L3.6687 3.23241'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M2.75 7.56308H1.875' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M4.28762 11.2754L3.6687 11.8943'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.25 10.5952V11.9369C6.25 12.401 6.43437 12.8462 6.76256 13.1743C7.09075 13.5025 7.53587 13.6869 8 13.6869C8.46413 13.6869 8.90925 13.5025 9.23744 13.1743C9.56563 12.8462 9.75 12.401 9.75 11.9369V10.5952'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 7.56308C4.5 8.49134 4.86875 9.38158 5.52513 10.038C6.1815 10.6943 7.07174 11.0631 8 11.0631C8.92826 11.0631 9.8185 10.6943 10.4749 10.038C11.1313 9.38158 11.5 8.49134 11.5 7.56308C11.5 6.63482 11.1313 5.74458 10.4749 5.08821C9.8185 4.43183 8.92826 4.06308 8 4.06308C7.07174 4.06308 6.1815 4.43183 5.52513 5.08821C4.86875 5.74458 4.5 6.63482 4.5 7.56308V7.56308Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M8 13.6881V14.5631' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_1062_7755'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
));

export const Interview = forwardRef<SVGSVGElement, HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1062_7773)'>
      <path
        d='M8 2.3125C7.95674 2.3125 7.91444 2.32533 7.87847 2.34937C7.8425 2.3734 7.81446 2.40757 7.7979 2.44754C7.78134 2.48751 7.77701 2.53149 7.78545 2.57393C7.79389 2.61636 7.81473 2.65534 7.84532 2.68593C7.87591 2.71652 7.91489 2.73736 7.95732 2.7458C7.99976 2.75424 8.04374 2.74991 8.08371 2.73335C8.12368 2.71679 8.15785 2.68875 8.18188 2.65278C8.20592 2.61681 8.21875 2.57451 8.21875 2.53125C8.21875 2.47323 8.1957 2.41759 8.15468 2.37657C8.11366 2.33555 8.05802 2.3125 8 2.3125Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.6875 6.6875C6.6875 7.0356 6.82578 7.36944 7.07192 7.61558C7.31806 7.86172 7.6519 8 8 8C8.3481 8 8.68194 7.86172 8.92808 7.61558C9.17422 7.36944 9.3125 7.0356 9.3125 6.6875C9.3125 6.3394 9.17422 6.00556 8.92808 5.75942C8.68194 5.51328 8.3481 5.375 8 5.375C7.6519 5.375 7.31806 5.51328 7.07192 5.75942C6.82578 6.00556 6.6875 6.3394 6.6875 6.6875V6.6875Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1875 9.75C9.97605 9.35353 9.66082 9.02198 9.27552 8.7908C8.89022 8.55962 8.44933 8.4375 8 8.4375C7.55067 8.4375 7.10978 8.55962 6.72448 8.7908C6.33918 9.02198 6.02395 9.35353 5.8125 9.75'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1875 2.75H13.6875C13.9196 2.75 14.1421 2.84219 14.3062 3.00628C14.4703 3.17038 14.5625 3.39294 14.5625 3.625V10.625C14.5625 10.8571 14.4703 11.0796 14.3062 11.2437C14.1421 11.4078 13.9196 11.5 13.6875 11.5H2.3125C2.08044 11.5 1.85788 11.4078 1.69378 11.2437C1.52969 11.0796 1.4375 10.8571 1.4375 10.625V3.625C1.4375 3.39294 1.52969 3.17038 1.69378 3.00628C1.85788 2.84219 2.08044 2.75 2.3125 2.75H5.8125'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.1875 14.125H5.8125L6.25 11.5H9.75L10.1875 14.125Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4.5 14.125H11.5' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_1062_7773'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
));
