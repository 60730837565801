import { EditorView } from 'prosemirror-view';

import { Chapter } from '@components/common/VideoSeekBar';
import { parseScreenerFieldType } from '@components/SurveyBuilder';
import { secondsToTimestamp } from '@components/utils';

export const eventUrl = (participation: Participation): string | null => {
  return participation.event_details?.location?.join_url || null;
};

export const scrollToHighlight = (editorDOM: EditorView['dom'] | undefined, timestamp: number) => {
  const element = editorDOM?.querySelector(`[data-timestamp="${timestamp}"]`);
  if (element) {
    element.scrollIntoView({ block: 'start' });
  }
};

export const answersToChapters = (answers: ScreenerResponseAnswer[] = []): Chapter<Record<string, unknown>>[] => {
  const sorted = [...answers].sort((a, b) => {
    if (a.timings && b.timings) {
      return new Date(a.timings.start_at).getTime() - new Date(b.timings.start_at).getTime();
    }

    return 0;
  });

  // recording only starts after the permissions
  const validAnswers = sorted.filter(
    (answer) => answer.blockable_type !== 'Questions::Welcome' && answer.blockable_type !== 'Permissions'
  );

  if (validAnswers.some((answer) => !answer.timings)) {
    return [];
  }

  let currentTime = 0;

  return validAnswers.map((answer, i) => {
    const timings = answer.timings;
    const duration = timings?.duration ?? 0;

    const from = i === 0 ? 0 : currentTime;
    const kind = parseScreenerFieldType(answer.field_type);
    const start = secondsToTimestamp(Math.floor(from / 1000), 4);
    const end = secondsToTimestamp(Math.floor((from + duration) / 1000), 4);

    currentTime += duration;

    return {
      from,
      metadata: { title: answer.label, start, end, kind }
    };
  });
};
