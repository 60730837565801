import { useReducer } from 'react';

enum Actions {
  SET_IS_MINIMIZED = 'SET_IS_MINIMIZED',
  SET_COORDINATES = 'SET_COORDINATES',
  SET_HEIGHT = 'SET_HEIGHT',
  SET_WIDTH = 'SET_WIDTH',
  SET_START_POSITION = 'SET_START_POSITION',
  SET_BOUNDARY_ELEMENT = 'SET_BOUNDARY_ELEMENT',
  SET_IS_TOUCHED = 'SET_IS_TOUCHED'
}

type Coordinates = {
  x: number;
  y: number;
};

type StartPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'center';

type Action =
  | GenericAction<Actions.SET_IS_MINIMIZED, boolean>
  | GenericAction<Actions.SET_COORDINATES, Coordinates>
  | GenericAction<Actions.SET_HEIGHT, number>
  | GenericAction<Actions.SET_WIDTH, number>
  | GenericAction<Actions.SET_START_POSITION, StartPosition>
  | GenericAction<Actions.SET_BOUNDARY_ELEMENT, HTMLElement | Window>
  | GenericAction<Actions.SET_IS_TOUCHED, boolean>;

export type State = {
  isMinimized: boolean;
  coordinates: Coordinates;
  height: number;
  width: number;
  startPosition: StartPosition;
  boundaryElement?: HTMLElement | Window;
  isTouched?: boolean;
};

export type DraggableCard = {
  state: State;
  setIsMinimized: (isMinimized: boolean) => void;
  setCoordinates: (coordinates: Coordinates) => void;
  setHeight: (height: number) => void;
  setWidth: (width: number) => void;
  setStartPosition: (startPosition: StartPosition) => void;
  setBoundaryElement: (boundaryElement: HTMLElement | Window) => void;
  setIsTouched: (isTouched: boolean) => void;
};

export const INITIAL_STATE: State = {
  isMinimized: false,
  coordinates: { x: 0, y: 0 },
  height: 0,
  width: 0,
  startPosition: 'top-left',
  boundaryElement: window,
  isTouched: false
};

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Actions.SET_IS_MINIMIZED:
      return { ...state, isMinimized: action.payload };
    case Actions.SET_COORDINATES:
      return { ...state, coordinates: action.payload };
    case Actions.SET_HEIGHT:
      return { ...state, height: action.payload };
    case Actions.SET_WIDTH:
      return { ...state, width: action.payload };
    case Actions.SET_START_POSITION:
      return { ...state, startPosition: action.payload };
    case Actions.SET_BOUNDARY_ELEMENT:
      return { ...state, boundaryElement: action.payload };
    case Actions.SET_IS_TOUCHED:
      return { ...state, isTouched: action.payload };
    default:
      return state;
  }
};

export const useDraggableCard = (initialState?: Partial<State>): DraggableCard => {
  const [state, dispatch] = useReducer(reducer, { ...INITIAL_STATE, ...initialState });

  const setIsMinimized = (isMinimized: boolean) => {
    dispatch({ type: Actions.SET_IS_MINIMIZED, payload: isMinimized });
  };

  const setCoordinates = (coordinates: Coordinates) => {
    dispatch({ type: Actions.SET_COORDINATES, payload: coordinates });
  };

  const setHeight = (height: number) => {
    dispatch({ type: Actions.SET_HEIGHT, payload: height });
  };

  const setWidth = (width: number) => {
    dispatch({ type: Actions.SET_WIDTH, payload: width });
  };

  const setStartPosition = (startPosition: StartPosition) => {
    dispatch({ type: Actions.SET_START_POSITION, payload: startPosition });
  };

  const setBoundaryElement = (boundaryElement: HTMLElement | Window) => {
    dispatch({ type: Actions.SET_BOUNDARY_ELEMENT, payload: boundaryElement });
  };

  const setIsTouched = (isTouched: boolean) => {
    dispatch({ type: Actions.SET_IS_TOUCHED, payload: isTouched });
  };

  return {
    state,
    setBoundaryElement,
    setIsMinimized,
    setCoordinates,
    setHeight,
    setStartPosition,
    setWidth,
    setIsTouched
  };
};
