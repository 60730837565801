import { ChevronDownSVG } from 'components/svgs';
import * as React from 'react';
import { forwardRef, useRef } from 'react';

import { ICONS } from '@components/common/Button';
import Tippy from '@tippyjs/react';
import { Popper } from '../Popper';
import mergeRefs from 'react-merge-refs';
import { Props as PopperProps } from './../Popper';
import { useStyles } from '@components/common/Dropdown/hooks/useStyles';
import { useTrapFocus } from '@hooks/useTrapFocus';

export interface PopperDropdownProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: React.ReactNode;
  icon?: keyof typeof ICONS;
  dropdownClass?: string;
  hideCaret?: boolean;
  disabled?: boolean;
  loading?: boolean;
  isOpen?: boolean;
  onClick?: React.MouseEventHandler;
  small?: boolean;
  primary?: boolean;
  textStyle?: boolean;
  buttonClassName?: string;
  ellipsisButton?: boolean;
  error?: boolean;
  danger?: boolean;
  aboveButton?: boolean;
  medium?: boolean;
  renderIcon?: () => React.ReactNode;
  renderHiddenChildren?: boolean;
  tooltip?: React.ReactNode;
  popperProps?: Partial<PopperProps>;
  closeOnDropdownClick?: boolean;
}

export const PopperDropdown = forwardRef<HTMLButtonElement, PopperDropdownProps>((props, ref) => {
  const {
    text,
    icon,
    hideCaret,
    disabled,
    children,
    isOpen,
    onClick,
    medium,
    renderIcon,
    renderHiddenChildren = true,
    tooltip,
    popperProps,
    dropdownClass,
    buttonClassName,
    danger,
    aboveButton,
    error,
    primary,
    small,
    textStyle,
    closeOnDropdownClick = true,
    ...rest
  } = props;

  const { dropdownWrapperClass, mainButtonClass, buttonIconClass, svgClass } = useStyles({
    buttonClassName,
    primary,
    textStyle,
    aboveButton,
    medium,
    small,
    danger,
    disabled,
    error,
    dropdownClass
  });

  const dropdownRef = useRef<HTMLDivElement>(null);

  const { ref: trapRef } = useTrapFocus();

  const ItemsList = ({ children, closePopper }) => {
    if (!isOpen && !renderHiddenChildren) {
      return <></>;
    }

    return (
      <div
        ref={mergeRefs([dropdownRef, trapRef])}
        className={dropdownWrapperClass}
        role='menu'
        tabIndex={-1}
        onClick={(e) => {
          e.stopPropagation();
          closePopper?.();
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            closePopper?.();
          }
        }}
        aria-orientation='vertical'
        aria-labelledby='options-menu'
      >
        {children}
      </div>
    );
  };

  const ButtonIcon: any = icon && ICONS[icon];

  return (
    <Popper
      placement={popperProps?.placement || 'bottom-end'}
      closeOnClickOutside
      closeOnEscape
      autofocus
      offset={[0, 8]}
      content={({ closePopper }) => (
        <ItemsList closePopper={closeOnDropdownClick ? closePopper : undefined}>{children}</ItemsList>
      )}
      {...popperProps}
    >
      <button type='button' className={mainButtonClass} aria-haspopup='true' disabled={disabled} {...rest}>
        <Tippy content={tooltip} disabled={!tooltip}>
          <div className='flex items-center w-full h-full'>
            {icon && <ButtonIcon className={buttonIconClass} />}
            {renderIcon?.()}
            {text && text}
            {!hideCaret && <ChevronDownSVG className={svgClass} />}
          </div>
        </Tippy>
      </button>
    </Popper>
  );
});
