import * as React from 'react';

import { RatingInput } from '@components/shared/RatingInput';

const RATING_OPTIONS: { value: number; label: string }[] = [
  { value: 1, label: '1-star (poor)' },
  { value: 2, label: '2-stars (below average)' },
  { value: 3, label: '3-stars (average)' },
  { value: 4, label: '4-stars (above average)' },
  { value: 5, label: '5-stars (excellent)' }
];

export const RatingCell: React.FC<{ value: number | null; onClick: (rating: number) => void }> = ({
  value,
  onClick
}) => <RatingInput options={RATING_OPTIONS} value={value} onClick={onClick} />;
