import cn from 'classnames';
import { getBackgroundColor, getHexWithOpacity } from 'components/tags/colors';
import React, { forwardRef, HTMLAttributes } from 'react';

import { PADDING } from './Interview';
import { useFeature } from '@hooks/useFeature';

interface Props extends HTMLAttributes<HTMLDivElement> {
  color?: string;
}

export const HighlightedParagraph = forwardRef<HTMLDivElement, Props>(
  ({ children, color, className, ...rest }, ref) => {
    const nonVideoHLEnabled = useFeature('non_video_highlights');
    let finalColor = '';

    if (color) {
      finalColor = color.startsWith('#') ? getHexWithOpacity(color, 0.15) : getBackgroundColor(color, 0.2);
    }

    return (
      <section
        ref={ref}
        style={{ backgroundColor: finalColor }}
        className={cn('bg-gray-50 h-full w-full pt-9 px-3', className)}
        {...rest}
      >
        {children}
      </section>
    );
  }
);
