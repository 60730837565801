import * as React from 'react';

export const FirstIcon: React.FC = () => (
  <svg
    className='fill-current'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M11.689 23.25 0.97 12.53a0.749 0.749 0 0 1 0 -1.06L11.689 0.75' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' />
    <path d='M22.939 23.25 12.22 12.53a0.749 0.749 0 0 1 0 -1.06L22.939 0.75' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' />
  </svg>
);

export const PrevIcon: React.FC = () => (
  <svg
    className='fill-current'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.25 23.25 5.53 12.53a0.749 0.749 0 0 1 0 -1.06L16.25 0.75' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' />
  </svg>
);

export const NextIcon: React.FC = () => (
  <svg
    className='fill-current'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='m5.5 0.75 10.72 10.72a0.749 0.749 0 0 1 0 1.06L5.5 23.25' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' />
  </svg>
);

export const LastIcon: React.FC = () => (
  <svg
    className='fill-current'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M12.311 0.75 23.03 11.47a0.749 0.749 0 0 1 0 1.06L12.311 23.25' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' />
    <path d='M1.061 0.75 11.78 11.47a0.749 0.749 0 0 1 0 1.06L1.061 23.25' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2.5' />
  </svg>
);
