export type ZdsType =
  | 'data'
  | 'studies'
  | 'candidates'
  | 'external_recruitment'
  | 'insights'
  | 'global_insights'
  | 'global_reels'
  | 'global_highlights'
  | 'global_interviews'
  | 'global_recordings'
  | 'global_survey_results'
  | 'global_all';
type ZdsValues = {
  header: string;
  subheader: string;
  options: string[];
  features?: string[];
};

type ZdsData = Record<ZdsType, ZdsValues>;

export const ZDS_DATA: ZdsData = {
  studies: {
    header: 'Launch a new research study',
    subheader: 'Pick a method or template and launch a study in minutes.',
    features: [
      'Manage invites, reminders, meeting links and incentives in one place.',
      'Send branded email communications with batching options.',
      'Create templates to accelerate your studies.',
      'Integrate with your calendar and email providers to use fewer tools.',
      'Send globally accepted incentives automatically.',
      'See all research activities in one place.',
      'Track progress of research, from recruitment to insights.'
    ],
    options: [
      'Run customer interviews, surveys, unmoderated tests, panel recruitment, or online task studies.',
      'Manage the whole study from planning, recruitment, execution, analysis / synthesis, insights and incentives.'
    ]
  },
  candidates: {
    header: 'Build your research panel',
    subheader: 'Manage a panel of participants to make study recruitment a breeze.',
    options: [
      'Control & connect with your own list of research participants',
      'Build robust candidate profiles',
      'Quickly recruit folks to participate in your research'
    ]
  },
  external_recruitment: {
    header: 'Recruit external candidates',
    subheader: 'Recruit from Respondent’s panel of 3M+ B2B and B2C participants',
    options: [
      'Define your target audience of participants to recruit',
      'Submit a recruitment request form to Respondent',
      'Screen participants and conduct research'
    ]
  },
  data: {
    header: 'Store and analyze research data',
    subheader: 'Upload recordings, tag highlights, and create video reels.',
    features: [
      'Ability to import recordings automatically or manually upload in bulk.',
      'Automatic transcription of every recording.',
      'Ability to take notes in the same space.',
      'Ability to highlight and tag transcript moments to create shareable clips.',
      'Ability to manage who can see the session, share it, and track views.',
      'Ability to combine clips together into a single shareable highlight reel.'
    ],
    options: [
      'Every recording will get automatically transcribed.',
      'Highlight and tag transcripts to create shareable clips.',
      'Share interviews and stay up to date on who viewed it.'
    ]
  },
  insights: {
    header: 'Create and communicate research insights',
    subheader: 'Crystallize your work into insights.',
    features: [
      'Ability to create as many or as little insights as you have for a study.',
      'Ability to embed interview data, highlights or reels into an insight.',
      'Ability to manage who can see / access the insight.',
      'Ability to see who’s viewed the insight.',
      'Ability to decide when the insight gets published to the global repository.',
      'Ability to add cover photos to the insight.'
    ],
    options: [
      'Communicate the key learnings from the study.',
      'Embed evidence into each insight.',
      'Share with your team.'
    ]
  },
  global_insights: {
    header: 'Communicate what you learn from research',
    subheader: 'Crystallize your work into insights.',
    options: [
      'Communicate the key learnings from the study.',
      'Embed evidence into each insight.',
      'Share with your team.'
    ]
  },
  global_reels: {
    header: 'Create highlight reels',
    subheader: 'Stitch highlights together into a single video reel.',
    options: [
      'Create reels.',
      'Share with your stakeholders to easily communicate something.',
      'Embed in your insights to give more context.'
    ]
  },
  global_highlights: {
    header: 'Use Highlights to synthesize and find themes',
    subheader: 'Group the highlighted moments from interviews to identify themes.',
    options: [
      'Highlights you created on each interview will automatically.',
      'Group highlights automatically, or create your own themes.',
      'Re-watch clips and dive deeper into the data.'
    ]
  },
  global_interviews: {
    header: 'Store your interview sessions',
    subheader: 'This is where your interview recordings will be stored.',
    options: [
      'Every recording will get automatically transcribed.',
      'Highlight and tag transcripts to create shareable clips.',
      'Share interviews and stay up to date on who viewed it.'
    ]
  },
  global_recordings: {
    header: 'Store your recordings',
    subheader: 'This is where your test recordings will be stored.',
    options: [
      'Every recording will get automatically transcribed.',
      'Highlight and tag transcripts to create shareable clips.',
      'Share recordings and stay up to date on who viewed it.'
    ]
  },
  global_survey_results: {
    header: 'Store your survey responses',
    subheader: 'When people respond to your survey, we’ll display the results here.',
    options: [
      'View responses by individual or summary charts.',
      'Analyze responses to identify themes.',
      'Share survey responses with your team.'
    ]
  },
  global_all: {
    header: 'View all your data in one place',
    subheader: 'This gives you a view of all research data being generated.',
    options: [
      'Search across insights, reels and more.',
      'Filter by multiple different artifacts at the same time.',
      'Identify patterns across different data types.'
    ]
  }
};
