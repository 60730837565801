import React, { useMemo } from 'react';

import { useAccount } from './useAccount';

export type WithUser<T> = T & {
  user: TeamUser;
};

const NullUser: TeamUser = {
  name: 'Unknown',
  id: -1,
  email: '',
  first_name: 'Unknown',
  job_title: 'Unknown',
  initials: 'UNK',
  deactivated: true,
  default_nylas_calendar_id: null,
  is_phantom: true,
  nylas_invalid: true,
  valid_email_integration: true
};

export const useRecordsWithUser: <R extends { user_id: number }>(records: R[]) => WithUser<R>[] = (records) => {
  const { getUserById } = useAccount();
  return useMemo(() => {
    return records.map((chat) => {
      const user: TeamUser = getUserById(chat.user_id) || NullUser;
      return {
        ...chat,
        user: user
      };
    });
  }, [records]);
};
