import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { api } from '@api/reduxApi';

interface UseTransitionStudyProps {
  id: number;
  transition: StudyTransition;
  onSuccess: (data: Study | undefined) => void;
  onError: () => void;
  onFinished?: () => void;
}

interface UseTransitionStudyHook {
  transition: () => void;
  isLoading: boolean;
}
export const useTransitionStudy: (props: UseTransitionStudyProps) => UseTransitionStudyHook = ({
  id,
  transition,
  onSuccess,
  onError,
  onFinished
}) => {
  const [transitionStudy, { data, isSuccess, isError, isLoading }] = api.useTransitionStudyMutation();

  useEffect(() => {
    if (isSuccess) {
      onSuccess(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onError();
    }
  }, [isError]);

  useEffect(() => {
    if (isError || isSuccess) {
      onFinished?.();
    }
  }, [isError, isSuccess]);

  return {
    transition: () => {
      transitionStudy({ id: id, transition });
    },
    isLoading
  };
};
