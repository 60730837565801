import * as React from 'react';

import { CopyURLButton } from '@components/shared/CopyURLButton';
import { useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { TextEditorWrapper } from '@components/shared/TextEditorWrapper';
import { usePermission } from '@hooks/usePermission';

export const Overview: React.FC<{ study: Study }> = ({ study }) => {
  const canUpdate = usePermission<Study>('updateStudy')(study);
  const tiptap = useTiptapFromDocumentId({
    readonly: !canUpdate,
    documentId: study?.overview_document_id as number,
    config: {
      artifacts: true,
      tables: true,
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      placeholder: 'This is the study overview, a place to describe your study and provide an overview for new members.'
    }
  });

  return (
    <TextEditorWrapper
      renderButtons={() => (
        <CopyURLButton
          text={window.location.toString()}
          icon='link'
          medium
          trackEvent='copied_overview_link'
          trackProps={{ source: 'edit_study' }}
        >
          Copy link
        </CopyURLButton>
      )}
      title='Overview'
      tiptap={tiptap}
      isLoading={!study.overview_document_id}
    />
  );
};
