import pluralize from 'pluralize';
import * as React from 'react';

import { Text } from '@components/common';
import { AlgoliaData, useSearch } from '@components/shared/Search';

type Props = { className?: string; render?: (count: number) => React.ReactElement };

export const Count: React.FC<Props> = ({ render, className }) => {
  const { data } = useSearch<AlgoliaData>();

  if (!data) {
    return null;
  }

  return (
    <Text className={className} color='gray-500' h='400'>
      {render ? (
        render(data.nbHits)
      ) : (
        <>
          {data.nbHits.toLocaleString()} {pluralize('results', data.nbHits)}
        </>
      )}
    </Text>
  );
};
