import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button, SlideOut, Text, Textarea } from '@components/common';
import { Label } from '@components/fields';
import { TableFilters } from '@components/shared/TableFilters';
import { UseTableFilters } from '@components/shared/TableFilters/hook/useTableFilters';
import { EligibilityRule } from '@components/GovernanceApp/components/Eligiblity/types';
import { FilterState } from 'components/shared/TableFilters/types';
import { Error } from '@components/shared/AI';

interface Props {
  hook: UseTableFilters<any>;
  open: boolean;
  onClose: () => void;
  onSave: (rule: FilterState<EligibilityRule>[], data: Form) => void;
  rule: EligibilityRule | null;
  id?: string;
  onDelete?: () => void;
  editable?: boolean;
}

type Form = { title: string; label: string; description?: string; criteria?: string };

export const RuleSlideOut: React.FC<Props> = ({ editable, hook, rule, open, onClose, onSave }) => {
  const submitRef = useRef<HTMLButtonElement>(null);

  const defaultValues = {
    title: rule ? rule.title : '',
    label: rule ? rule.name : '',
    description: rule ? rule.description : ''
  };

  const { handleSubmit, register, errors, setError, reset, clearErrors } = useForm<Form>({
    defaultValues,
    mode: 'onSubmit'
  });

  const hasCriteriaError = () => {
    const filtersAreEmpty = hook.filters.every((f) => f.value === null && !f.range);
    return hook.filters.length === 0 || filtersAreEmpty;
  };

  const onSubmit = (data: Form) => {
    if (hasCriteriaError()) {
      setError('criteria', { type: 'required' });
      return;
    }
    onSave(hook.filters, data);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [rule]);

  useEffect(() => {
    if (hook.filters.length > 0 && errors.criteria) {
      clearErrors('criteria');
    }
  }, [hook.filters]);

  if (!open) {
    return null;
  }

  return (
    <SlideOut
      onClose={onClose}
      closeOnEsc
      title={`${rule ? 'Edit' : 'Create'} eligibility rule`}
      subtitle='This will determine who’s eligible to be contacted.'
      renderFooter={() => (
        <div className='flex justify-between w-full'>
          <div className='space-x-4'>
            <Button primary onClick={() => submitRef.current && submitRef.current.click()}>
              {rule ? 'Save changes' : 'Create'}
            </Button>

            <Button onClick={onClose}>{rule ? 'Cancel changes' : 'Cancel'}</Button>
          </div>
        </div>
      )}
    >
      <div className='h-full p-6 text-left'>
        <form
          className='flex flex-col justify-between h-full'
          onSubmit={handleSubmit(onSubmit, () => {
            if (hasCriteriaError()) {
              setError('criteria', { type: 'required' });
            }
          })}
        >
          <div className='flex-1'>
            <div className='mb-8'>
              <Label className='mb-1' labelFor='title'>
                Title
              </Label>
              <Text h='400' color='gray-500' mb='1'>
                Make it clear why this rule exists.
              </Text>

              <div className='mt-1.5'>
                <input
                  type='text'
                  name='title'
                  ref={register({ required: true, validate: (value) => value.trim().length > 0 })}
                  className={cn(
                    'focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm block w-full text-gray-700 placeholder-gray-400 border-gray-200 rounded-md',
                    { 'border-red-600': errors.title }
                  )}
                  aria-required={true}
                  aria-label='Rule title'
                  placeholder='Write text...'
                  autoComplete='off'
                />
                {errors.title && (
                  <Error aria-label='Error message' className='mt-1'>
                    This field is required.
                  </Error>
                )}
              </div>
            </div>
            <div className='mb-8'>
              <Label className='mb-1' labelFor='description'>
                Description
              </Label>
              <Text h='400' color='gray-500' mb='1'>
                Communicate what this rule to make it understandable.
              </Text>

              <div className='mt-1.5'>
                <Textarea
                  name='description'
                  id='description'
                  ref={register}
                  className='focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm block w-full text-gray-700 placeholder-gray-400 border-gray-200 rounded-md'
                  aria-label='Rule description'
                  placeholder='Write text...'
                />
              </div>
            </div>

            <div className='mb-8'>
              <Label className='mb-1'>Criteria</Label>
              <Text h='400' color='gray-500' mb='2'>
                Determines which candidates are affected by this rule.
              </Text>
              <TableFilters<Candidate> hook={hook} editable={editable} advancedLimits isError={!!errors.criteria} />
              {errors.criteria && (
                <Error aria-label='Error message' className='mt-1'>
                  You need to set up at least one criteria.
                </Error>
              )}
            </div>

            <Label className='mb-1' labelFor='label'>
              Label
            </Label>
            <Text h='400' color='gray-500' mb='1'>
              Eligibility status on the participant when the criteria applies.
            </Text>
            <div className='mt-1.5'>
              <input
                type='text'
                name='label'
                aria-required={true}
                aria-label='Label'
                ref={register({ required: true, validate: (value) => value.trim().length > 0 })}
                className={cn(
                  'focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm block w-full text-gray-700 placeholder-gray-400 border-gray-200 rounded-md',
                  { 'border-red-600': errors.label }
                )}
                placeholder='Write text...'
                autoComplete='off'
              />
              {errors.label && (
                <Error aria-label='Error message' className='mt-1'>
                  This field is required.
                </Error>
              )}
            </div>
          </div>
          <button type='submit' className='hidden' ref={submitRef} />
        </form>
      </div>
    </SlideOut>
  );
};
