import * as React from 'react';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { api } from '@api/reduxApi';
import { useBulkUploadActions } from '@components/BulkUploadFlow';
import { Text } from '@components/common';
import { AddCandidateSlideOut } from '@components/shared/AddCandidateSlideOut';
import { AddPeopleSVG, CheckSVG } from '@components/svgs';
import { Basic } from '@components/shared/Skeleton';

import { CellFC } from '../types';
import { SelectDropdown } from '@components/shared/SelectDropdown';
import cn from 'classnames';
import { ItemProps } from '@components/shared/SelectDropdown/components/Item';

export const Item = forwardRef<HTMLLIElement, ItemProps>(
  ({ __dropdownItemProps: { item, active, highlighted }, ...rest }, ref) => {
    return (
      <li
        ref={ref}
        {...rest}
        data-testid='create-item'
        role='option'
        aria-selected={active}
        className={cn('xx-combo-option flex flex-row items-center justify-between px-3 py-2 cursor-pointer', {
          'bg-indigo-600 text-white': highlighted
        })}
      >
        {item.created ? (
          <div className='flex items-center space-x-2'>
            <AddPeopleSVG className='flex-shrink-0' />
            <Text h='400' bold={active} truncate>
              {`Create "${item.label}"`}
            </Text>
          </div>
        ) : (
          <Text h='400' bold={active} truncate>
            {item.label}
          </Text>
        )}

        {active && (
          <CheckSVG className={cn('w-3 h-3', { 'text-indigo-600': !highlighted, 'text-white': highlighted })} />
        )}
      </li>
    );
  }
);

const DEBOUNCE_RATE = 300;

export const Candidate: CellFC = ({ row, tabIndex }) => {
  const [value, setValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [updateRepoSession, { isSuccess, data, isLoading }] = api.useUpdateRepoSessionMutation();
  const { data: fetchedData, isLoading: isFetching } = api.useGetServerSideCandidatesQuery({
    items: 30,
    page: 1,
    searchQuery
  });

  const { callback: debouncedSetSearchQuery } = useDebouncedCallback(setSearchQuery, DEBOUNCE_RATE);

  useEffect(() => {
    if (value === '') {
      setSearchQuery(value);
    } else {
      debouncedSetSearchQuery(value);
    }
  }, [value]);

  const shouldShowSpinner = (searchQuery && searchQuery !== value) || isFetching;

  const candidates = useMemo(() => (fetchedData ? fetchedData.data : []), [fetchedData]);
  const { setItem } = useBulkUploadActions();
  const { session, id } = row.original;
  const candidate = session.candidate;

  const [newCandidate, setNewCandidate] = useState<string>();
  const [creating, setCreating] = useState(false);

  const [edit, setEdit] = useState(!candidate);

  useEffect(() => {
    if (isSuccess) {
      setItem({ id, session: data });
    }
  }, [isSuccess]);

  const items = useMemo(
    () => candidates?.map(({ name, id }) => ({ label: name || 'Unnamed candidate', value: id.toString() })),
    [candidates]
  );
  const selected = useMemo(
    () => (candidate ? { value: candidate.toString(), label: candidate.name || 'Unnamed candidate' } : undefined),
    [candidate]
  );

  function handleSelect(selected) {
    if (selected) {
      updateRepoSession({
        uuid: session.uuid,
        candidate_id: parseInt(selected.value)
      });
    }
    setEdit(false);
  }

  const onAdd = (candidate_id) => {
    updateRepoSession({
      uuid: session.uuid,
      candidate_id
    });
    setEdit(false);
  };

  const onSelect = (item) => {
    if (item?._isNew) {
      setNewCandidate(item.value);
      setCreating(true);
    } else {
      handleSelect(item);
    }
  };

  const onCreate = (item) => {
    setNewCandidate(item.value);
    setCreating(true);
  };

  if (isLoading) {
    return (
      <Text className='w-56 h-10'>
        <Basic width='56' h='10' />
      </Text>
    );
  }

  if (!edit && candidate) {
    return (
      <Text h='400' className='w-56 h-10 px-4 py-3'>
        {candidate.name || 'Unnamed candidate'}
      </Text>
    );
  }

  if (!edit && !candidate) {
    return (
      <Text h='400' className='w-56 h-10 px-4 py-3' color='gray-500' onClick={() => setEdit(true)}>
        Enter name…
      </Text>
    );
  }

  return (
    <div className='w-56'>
      <SelectDropdown<{ value: string; label: string }>
        loading={shouldShowSpinner}
        creatable
        onInputValueChange={setValue}
        onChange={(v) => handleSelect(v[0])}
        options={items ?? []}
        onCreate={onCreate}
        value={selected ? [selected] : []}
        overrides={{
          Input: {
            props: {
              placeholder: 'Enter name…',
              className: 'xx-combo-input'
            }
          },
          Item: {
            component: Item
          }
        }}
      />
      {creating && (
        <AddCandidateSlideOut
          defaultValues={{ name: newCandidate }}
          onAdd={({ candidate }) => onAdd(candidate.id)}
          style='addCandidate'
          onClose={() => setCreating(false)}
          canAddMore={false}
          team={null}
        />
      )}
    </div>
  );
};
