import * as React from 'react';
import { useState } from 'react';
import Tippy from '@tippyjs/react';

import { StarSVG, FullStarSVG } from '@components/svgs';

type Props = {
  value: number | null;
  onClick: (rating: number) => void;
  options: { value: number; label: string }[];
};

export const RatingInput: React.FC<Props> = ({ options, value: initialValue, onClick }) => {
  const [currentValue, setCurrentValue] = useState(initialValue);

  const handleOnClick = (value: number) => {
    if (value !== currentValue) {
      setCurrentValue(value);
      onClick(value);
    }
  };

  return (
    <>
      {options.map(({ value, label }) => (
        <div
          key={value}
          className='flex items-center w-full h-full overflow-hidden'
        >
          <Tippy content={label}>
            <button
              className='hover:bg-indigo-50 hover:text-indigo-600 h400 p-2 rounded-full'
              name={label}
              aria-label={label}
              onClick={() => handleOnClick(value)}
            >
              {currentValue && currentValue >= value ? <FullStarSVG /> : <StarSVG />}
            </button>
          </Tippy>
        </div>
      ))}
    </>
  );
};
