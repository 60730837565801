import React from 'react';

import { FullStarSVG, StarSVG } from '@components/svgs';

export const IdealAnswerStar: React.FC<{ mt?: string; ideal: boolean; onClick: () => void }> = ({
  mt,
  ideal,
  onClick
}) => (
  <span className={`-left-8 absolute ${mt ? `mt-${mt}` : ''}`} onClick={() => onClick()}>
    {ideal ? <FullStarSVG width='16' height='16' /> : <StarSVG width='16' height='16' className='text-gray-400' />}
  </span>
);
