import React from 'react';

export const NoResponsesSummarySVG = () => (
  <svg width='147' height='101' viewBox='0 0 147 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_40_2215)'>
      <path
        d='M144.92 -0.000488281H2.02801C1.48347 0.00011593 0.961406 0.216704 0.576358 0.601753C0.191309 0.986802 -0.0252747 1.50886 -0.0258789 2.0534V98.8798C-0.0252739 99.4243 0.191318 99.9464 0.576367 100.331C0.961415 100.716 1.48347 100.933 2.02801 100.934H144.92C145.465 100.933 145.987 100.716 146.372 100.331C146.757 99.9464 146.974 99.4243 146.974 98.8798V2.0534C146.974 1.50886 146.757 0.986802 146.372 0.601753C145.987 0.216704 145.465 0.00011593 144.92 -0.000488281ZM146.387 98.8798C146.387 99.2688 146.233 99.6419 145.957 99.9169C145.682 100.192 145.309 100.347 144.92 100.347H2.02801C1.63897 100.347 1.26591 100.192 0.990815 99.9169C0.71572 99.6419 0.561103 99.2688 0.560947 98.8798V2.0534C0.561103 1.66436 0.71572 1.2913 0.990815 1.01621C1.26591 0.741111 1.63897 0.586494 2.02801 0.586338H144.92C145.309 0.586494 145.682 0.741111 145.957 1.01621C146.233 1.2913 146.387 1.66436 146.387 2.0534V98.8798Z'
        fill='#9FA6B2'
      />
      <path d='M146.68 8.22607H0.267212V8.8129H146.68V8.22607Z' fill='#9FA6B2' />
      <path
        d='M5.2545 6.1611C6.22678 6.1611 7.01498 5.3729 7.01498 4.40062C7.01498 3.42833 6.22678 2.64014 5.2545 2.64014C4.28221 2.64014 3.49402 3.42833 3.49402 4.40062C3.49402 5.3729 4.28221 6.1611 5.2545 6.1611Z'
        fill='#9FA6B2'
      />
      <path
        d='M10.3165 6.1611C11.2888 6.1611 12.077 5.3729 12.077 4.40062C12.077 3.42833 11.2888 2.64014 10.3165 2.64014C9.34422 2.64014 8.55603 3.42833 8.55603 4.40062C8.55603 5.3729 9.34422 6.1611 10.3165 6.1611Z'
        fill='#9FA6B2'
      />
      <path
        d='M15.3772 6.1611C16.3495 6.1611 17.1377 5.3729 17.1377 4.40062C17.1377 3.42833 16.3495 2.64014 15.3772 2.64014C14.4049 2.64014 13.6167 3.42833 13.6167 4.40062C13.6167 5.3729 14.4049 6.1611 15.3772 6.1611Z'
        fill='#9FA6B2'
      />
      <path
        d='M36.0245 21.8588H11.3777C10.833 21.8588 10.3106 21.6424 9.92544 21.2572C9.54026 20.872 9.32385 20.3496 9.32385 19.8049C9.32385 19.2601 9.54026 18.7377 9.92544 18.3526C10.3106 17.9674 10.833 17.751 11.3777 17.751H36.0245C36.5692 17.751 37.0916 17.9674 37.4768 18.3526C37.862 18.7377 38.0783 19.2601 38.0783 19.8049C38.0783 20.3496 37.862 20.872 37.4768 21.2572C37.0916 21.6424 36.5692 21.8588 36.0245 21.8588Z'
        fill='#E5E7EB'
      />
      <path
        d='M69.767 31.8348H11.3777C10.833 31.8348 10.3106 31.6184 9.92544 31.2333C9.54026 30.8481 9.32385 30.3257 9.32385 29.7809C9.32385 29.2362 9.54026 28.7138 9.92544 28.3286C10.3106 27.9434 10.833 27.7271 11.3777 27.7271H69.767C70.3117 27.7271 70.8341 27.9434 71.2193 28.3286C71.6045 28.7138 71.8209 29.2362 71.8209 29.7809C71.8209 30.3257 71.6045 30.8481 71.2193 31.2333C70.8341 31.6184 70.3117 31.8348 69.767 31.8348Z'
        fill='#E5E7EB'
      />
      <path
        d='M88.6102 35.8032C85.8597 39.8134 84.2565 44.4985 83.9737 49.3531C83.6908 54.2076 84.739 59.0472 87.0052 63.3497C89.2713 67.6521 92.6694 71.2541 96.8326 73.7668C100.996 76.2795 105.766 77.6077 110.629 77.6078V54.2991L88.6102 35.8032Z'
        fill='#5850EC'
      />
      <path
        d='M110.629 24.2056C106.293 24.2028 102.021 25.2575 98.1844 27.2782C94.3476 29.2989 91.0614 32.2247 88.6105 35.8021L110.629 54.298V24.2056Z'
        fill='#E5E7EB'
      />
      <path
        d='M110.629 77.9006H110.336V23.9126H110.629C117.788 23.9126 124.654 26.7566 129.717 31.819C134.779 36.8813 137.623 43.7474 137.623 50.9066C137.623 58.0659 134.779 64.9319 129.717 69.9943C124.654 75.0566 117.788 77.9006 110.629 77.9006ZM110.923 24.501V77.3122C117.875 77.2349 124.516 74.4189 129.405 69.4753C134.294 64.5317 137.036 57.8594 137.036 50.9066C137.036 43.9538 134.294 37.2816 129.405 32.3379C124.516 27.3943 117.875 24.5783 110.923 24.501Z'
        fill='#E5E7EB'
      />
      <path
        d='M28.3955 88.9037H23.4075C23.0575 88.9033 22.7219 88.7641 22.4743 88.5165C22.2268 88.269 22.0876 87.9334 22.0872 87.5833V73.2061C22.0876 72.856 22.2268 72.5204 22.4743 72.2729C22.7219 72.0254 23.0575 71.8861 23.4075 71.8857H28.3955C28.7456 71.8861 29.0812 72.0254 29.3288 72.2729C29.5763 72.5204 29.7155 72.856 29.7159 73.2061V87.5833C29.7155 87.9334 29.5763 88.269 29.3287 88.5165C29.0812 88.7641 28.7456 88.9033 28.3955 88.9037Z'
        fill='#E5E7EB'
      />
      <path
        d='M43.0667 88.9042H38.0787C37.7286 88.9038 37.393 88.7645 37.1455 88.517C36.8979 88.2695 36.7587 87.9339 36.7583 87.5838V66.4581C36.7587 66.108 36.8979 65.7724 37.1455 65.5249C37.393 65.2773 37.7286 65.1381 38.0787 65.1377H43.0667C43.4167 65.1381 43.7524 65.2773 43.9999 65.5249C44.2474 65.7724 44.3867 66.108 44.387 66.4581V87.5838C44.3866 87.9339 44.2474 88.2695 43.9999 88.517C43.7523 88.7645 43.4167 88.9038 43.0667 88.9042Z'
        fill='#9FA6B2'
      />
      <path
        d='M57.7365 88.9038H52.7485C52.3984 88.9034 52.0628 88.7641 51.8153 88.5166C51.5677 88.2691 51.4285 87.9335 51.4281 87.5834V60.5894C51.4285 60.2393 51.5677 59.9037 51.8153 59.6562C52.0628 59.4087 52.3984 59.2694 52.7485 59.269H57.7365C58.0865 59.2694 58.4222 59.4087 58.6697 59.6562C58.9172 59.9037 59.0564 60.2393 59.0568 60.5894V87.5834C59.0564 87.9335 58.9172 88.2691 58.6697 88.5166C58.4221 88.7641 58.0865 88.9034 57.7365 88.9038Z'
        fill='#5850EC'
      />
      <path
        d='M28.3956 89.0509H23.4076C23.0187 89.0504 22.6458 88.8957 22.3708 88.6207C22.0957 88.3457 21.941 87.9728 21.9406 87.5838V55.3084C21.941 54.9194 22.0957 54.5465 22.3708 54.2715C22.6458 53.9965 23.0187 53.8418 23.4076 53.8413H28.3956C28.7846 53.8418 29.1575 53.9965 29.4325 54.2715C29.7076 54.5465 29.8623 54.9194 29.8627 55.3084V87.5838C29.8623 87.9728 29.7076 88.3457 29.4325 88.6207C29.1575 88.8957 28.7846 89.0504 28.3956 89.0509ZM23.4076 54.4281C23.1742 54.4284 22.9505 54.5212 22.7855 54.6862C22.6205 54.8513 22.5276 55.075 22.5274 55.3084V87.5838C22.5276 87.8172 22.6205 88.0409 22.7855 88.206C22.9505 88.371 23.1742 88.4638 23.4076 88.4641H28.3956C28.629 88.4638 28.8528 88.371 29.0178 88.206C29.1828 88.0409 29.2756 87.8172 29.2759 87.5838V55.3084C29.2756 55.075 29.1828 54.8513 29.0178 54.6862C28.8528 54.5212 28.629 54.4284 28.3956 54.4281H23.4076Z'
        fill='#E5E7EB'
      />
      <path
        d='M43.0668 89.0509H38.0788C37.6898 89.0504 37.3169 88.8957 37.0419 88.6207C36.7669 88.3457 36.6121 87.9728 36.6117 87.5838V55.3084C36.6121 54.9194 36.7669 54.5465 37.0419 54.2715C37.3169 53.9965 37.6898 53.8418 38.0788 53.8413H43.0668C43.4557 53.8418 43.8286 53.9965 44.1037 54.2715C44.3787 54.5465 44.5334 54.9194 44.5339 55.3084V87.5838C44.5334 87.9728 44.3787 88.3457 44.1037 88.6207C43.8286 88.8957 43.4557 89.0504 43.0668 89.0509ZM38.0788 54.4281C37.8454 54.4284 37.6216 54.5212 37.4566 54.6862C37.2916 54.8513 37.1988 55.075 37.1985 55.3084V87.5838C37.1988 87.8172 37.2916 88.0409 37.4566 88.206C37.6216 88.371 37.8454 88.4638 38.0788 88.4641H43.0668C43.3002 88.4638 43.5239 88.371 43.6889 88.206C43.8539 88.0409 43.9468 87.8172 43.947 87.5838V55.3084C43.9468 55.075 43.8539 54.8513 43.6889 54.6862C43.5239 54.5212 43.3002 54.4284 43.0668 54.4281H38.0788Z'
        fill='#E5E7EB'
      />
      <path
        d='M57.7366 89.0509H52.7486C52.3596 89.0504 51.9867 88.8957 51.7117 88.6207C51.4367 88.3457 51.2819 87.9728 51.2815 87.5838V55.3084C51.2819 54.9194 51.4367 54.5465 51.7117 54.2715C51.9867 53.9965 52.3596 53.8418 52.7486 53.8413H57.7366C58.1255 53.8418 58.4984 53.9965 58.7735 54.2715C59.0485 54.5465 59.2032 54.9194 59.2037 55.3084V87.5838C59.2032 87.9728 59.0485 88.3457 58.7735 88.6207C58.4984 88.8957 58.1255 89.0504 57.7366 89.0509ZM52.7486 54.4281C52.5152 54.4284 52.2914 54.5212 52.1264 54.6862C51.9614 54.8513 51.8686 55.075 51.8683 55.3084V87.5838C51.8686 87.8172 51.9614 88.0409 52.1264 88.206C52.2914 88.371 52.5152 88.4638 52.7486 88.4641H57.7366C57.97 88.4638 58.1937 88.371 58.3587 88.206C58.5237 88.0409 58.6166 87.8172 58.6168 87.5838V55.3084C58.6166 55.075 58.5237 54.8513 58.3587 54.6862C58.1937 54.5212 57.97 54.4284 57.7366 54.4281H52.7486Z'
        fill='#E5E7EB'
      />
    </g>
    <defs>
      <clipPath id='clip0_40_2215'>
        <rect width='147' height='100.934' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
