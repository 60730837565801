import React, { SVGProps } from 'react';

export const SolidKebabSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='4' cy='12' r='2' fill='currentColor' />
      <circle cx='12' cy='12' r='2' fill='currentColor' />
      <circle cx='20' cy='12' r='2' fill='currentColor' />
    </svg>
  );
};
