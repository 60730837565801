import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Loading, Modal, Text } from '@components/common';
import { moneyFormat } from '@components/utils';
import { FunderMethodSelect, StudyFunder } from '@components/Wallet';
import { useAccount } from '@hooks/useAccount';
import { useToaster } from '@stores/toaster';

interface ModalProps {
  study: Study;
  onCancel: () => void;
  onConfirm: () => void;
  funder: StudyFunder;
  limitCount: number;
}

export const ChangeIncentiveModal: React.FC<ModalProps> = ({ study, onCancel, funder, onConfirm, limitCount }) => {
  const [loading, setLoading] = useState<boolean>();

  const showToast = useToaster();

  const { allocation, selectedMethod } = funder;
  const isRequest = funder.mode === 'request';

  const { incentive, incentive_method } = study;
  const {
    account: { wallet },
    refresh: refreshAccount
  } = useAccount();

  const currentBalanceInDollars: number = study.funding.usd_total / 100;
  const allocationInDollars: number = (allocation?.usd_amount_in_cents || 0) / 100;
  const recruitmentFeesInDollars: number = study.funding.recruitment_fees / 100;
  const defaultIncentiveInDollars: number = study.incentive || 0;

  const pendingRecruitmentFees: boolean =
    study.potentially_active_external_candidates_requests_count >
    study.currently_active_external_candidates_requests_count;

  const evenAllocation: boolean = (allocationInDollars - recruitmentFeesInDollars) % limitCount === 0;
  const evenResult: boolean =
    currentBalanceInDollars + allocationInDollars - recruitmentFeesInDollars === defaultIncentiveInDollars * limitCount;
  const incentiveAdjustment: boolean = !evenAllocation || !evenResult;

  useEffect(() => {
    (async () => {
      await funder.prepare({ participant_limit: limitCount, incentive, incentive_method });
    })();
  }, []);

  async function handleConfirm() {
    setLoading(true);
    const resp = await funder.process({ participant_limit: limitCount, incentive, incentive_method });
    if (resp && resp !== 'error') {
      if (isRequest) {
        showToast({
          icon: 'success',
          heading: 'Request sent',
          text: 'A request to fund your study has been sent'
        });
      } else {
        showToast({
          icon: 'success',
          heading: 'Transactions processed!',
          text: 'Your transaction was successfully processed.'
        });
      }
      setLoading(false);
      refreshAccount();
      onConfirm();
    } else {
      showToast({
        icon: 'error',
        heading: 'An error occurred!',
        text: 'Please try again later.'
      });
      setLoading(false);
    }
  }

  if (!funder.allocation || !funder.mode) {
    return <Loading />;
  }

  if (allocationInDollars === 0) {
    return null;
  }

  const titleAction = currentBalanceInDollars === 0 ? 'funding' : 'change';
  const title = isRequest ? `Request ${titleAction}` : `Confirm ${titleAction}`;

  return (
    <Modal
      title={title}
      onClose={onCancel}
      size='md'
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary disabled={!isRequest && !selectedMethod} onClick={handleConfirm}>
            {isRequest ? 'Request' : 'Confirm'}
          </Button>
        </>
      )}
    >
      {loading && <Loading absolute />}
      {currentBalanceInDollars !== 0 && allocation && allocation.is_credit && (
        <>
          <Text className='mb-4'>
            {incentive_method != 'tremendous' ? (
              'You are removing funded incentives.'
            ) : (
              <>
                You are decreasing the incentive to{' '}
                <b>
                  {study.currencySymbol}
                  {study.incentive && moneyFormat(study.incentive)}
                </b>{' '}
                for <b>{limitCount} participants</b>.
              </>
            )}
          </Text>
          <div className='mb-4'>
            <Text bold>Current balance</Text>
            <Text>${moneyFormat(currentBalanceInDollars)}</Text>
          </div>
          <div className='mb-4'>
            <Text bold>Desired balance</Text>
            <Text>${moneyFormat(currentBalanceInDollars - allocationInDollars)}</Text>
          </div>
          <div className='mb-4'>
            <Text bold>Amount to return</Text>
            <Text>${moneyFormat(allocationInDollars)}</Text>
          </div>
          <div className='mb-4'>
            <Text bold mb='2'>
              Return funds to
            </Text>
            <FunderMethodSelect funder={funder} />
          </div>
        </>
      )}
      {currentBalanceInDollars !== 0 && allocation && !allocation.is_credit && (
        <>
          <Text className='mb-4'>
            {study.incentive && allocationInDollars !== recruitmentFeesInDollars && (
              <>
                {incentiveAdjustment && (
                  <>You are {isRequest ? 'requesting to fund' : 'funding'} incentive adjustments</>
                )}
                {!incentiveAdjustment && (
                  <>
                    You are {isRequest ? 'requesting to increase' : 'increasing'} the incentive to{' '}
                    {study.currencySymbol}
                    {moneyFormat(study.incentive)} for {limitCount} participants
                  </>
                )}
                {recruitmentFeesInDollars > 0
                  ? `, including ${study.currencySymbol}${moneyFormat(
                      recruitmentFeesInDollars
                    )} for external recruitment fees`
                  : null}
                .
              </>
            )}
            {study.incentive && allocationInDollars === recruitmentFeesInDollars && (
              <>
                You are {isRequest ? 'requesting to increase' : 'increasing'} the funding by {study.currencySymbol}
                {moneyFormat(recruitmentFeesInDollars)} for external recruitment fees.
              </>
            )}
          </Text>
          {isRequest && (
            <Text className='mb-4'>
              Your study will be moved to pending state until this is processed by your account administrator.
            </Text>
          )}
          <div className='mb-4'>
            <Text bold>Current balance</Text>
            <Text>${moneyFormat(currentBalanceInDollars)}</Text>
          </div>
          <div className='mb-4'>
            <Text bold>Desired balance</Text>
            <Text>${moneyFormat(currentBalanceInDollars + allocationInDollars)}</Text>
          </div>
          <div className='mb-4'>
            <Text bold>Amount to {isRequest ? 'request' : 'fund'}</Text>
            <Text h='600' bold mt='2'>
              ${moneyFormat(allocationInDollars)}
            </Text>
          </div>
          {!isRequest && (
            <div className='mb-4'>
              <Text bold>Funding source</Text>
              <Text h='400' mb='2'>
                Please select how you would like to fund these incentives.
              </Text>
              <FunderMethodSelect
                funder={funder}
                allowExternalIncentive={!pendingRecruitmentFees}
                allowExternalCredit={pendingRecruitmentFees}
              />
            </div>
          )}
        </>
      )}
      {currentBalanceInDollars === 0 && (
        <>
          <Text className='mb-4'>
            {incentiveAdjustment && <>You are {isRequest ? 'requesting to fund' : 'funding'} incentives</>}
            {!incentiveAdjustment && (
              <>
                You are {isRequest ? 'requesting to fund' : 'funding'} an incentive of {study.currencySymbol}
                {moneyFormat(study.incentive || 0)} for {limitCount} participants
              </>
            )}
            {recruitmentFeesInDollars > 0
              ? `, including ${study.currencySymbol}${moneyFormat(
                  recruitmentFeesInDollars
                )} for external recruitment fees`
              : null}
            .
          </Text>
          {isRequest && (
            <Text className='mb-4'>
              Your study will be moved to pending state until this is processed by your account administrator.
            </Text>
          )}
          <div className='mb-4'>
            <Text bold>Amount to {isRequest ? 'request' : 'fund'}</Text>
            <Text h='600' bold mt='2'>
              ${moneyFormat(allocationInDollars)}
            </Text>
          </div>
          {!isRequest && (
            <div className='mb-4'>
              <Text bold>Funding source</Text>
              <Text h='400' mb='2'>
                Please select how you would like to fund these incentives.
              </Text>
              <FunderMethodSelect
                funder={funder}
                allowExternalIncentive={!pendingRecruitmentFees}
                allowExternalCredit={pendingRecruitmentFees}
              />
            </div>
          )}
        </>
      )}
    </Modal>
  );
};
