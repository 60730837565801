import { api } from '@api/reduxApi';
import classNames from 'classnames';
import * as React from 'react';

interface Props {
  className?: string;
  interviewTemplate: InterviewTemplate;
  setInterviewTemplate: (interviewTemplate: InterviewTemplate) => void;
  placeholder?: string;
}
export const EditableTitle: React.FC<Props> = ({ className, interviewTemplate, setInterviewTemplate, placeholder }) => {
  const [update] = api.useUpdateInterviewTemplateMutation();
  const { id, title } = interviewTemplate;

  async function handleBlur() {
    await update({ id, title });
  }

  return (
    <input
      name='title'
      className={classNames(className, 'h800 focus:outline-none h700 flex-grow placeholder-gray-400 rounded-md')}
      value={title}
      onChange={(e) => setInterviewTemplate({ ...interviewTemplate, title: e.currentTarget.value })}
      onBlur={handleBlur}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      placeholder={placeholder || 'Template title...'}
    />
  );
};
