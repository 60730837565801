import cn from 'classnames';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useScrollDirection } from 'react-use-scroll-direction';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

import { api } from '@api/reduxApi';
import Activities from '@components/Activities';
import { CandidateProfile } from '@components/CandidateProfile';
import { Card, CardTitle, Loading } from '@components/common';
import { track } from '@components/tracking';
import { useDeviceType } from '@hooks/useDeviceType';

import { Assets } from './Assets';
import { Highlights } from './Highlights';
import { Interviews } from './Interviews';
import Participations from './Participations';
import ScreenerResponses from './ScreenerResponses';
import { Settings } from './Settings';

type OnChange = (isVisible: boolean, id: number) => void;

const NAV_LINKS = [
  'About',
  'Activity',
  'Interviews',
  'Screener Q&A',
  'Studies',
  'Assets',
  'Highlights',
  // 'Files',
  'Settings'
];

interface Props {
  candidate: Candidate;
  participations?: Participation[];
  onUpdate: () => void;
}

export const CandidateInfo: React.FC<Props> = ({ candidate, participations, onUpdate }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { device } = useDeviceType();

  const refs = useRef<HTMLDivElement[] | any[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const { isScrollingUp, isScrollingDown } = useScrollDirection(ref.current as any);

  const [offset, setOffset] = useState({});

  useEffect(() => {
    if (isScrollingUp) {
      setOffset({ top: -10 });
    }
    if (isScrollingDown) {
      setOffset({ top: 25 });
    }
  }, [isScrollingUp, isScrollingDown]);

  const { data: activities } = api.useGetCandidateActivitiesQuery({ id: candidate.id });
  const { data: answers } = api.useGetCandidateAnswersQuery({ id: candidate.id });
  useEffect(() => {
    track('viewed_candidate', {});
  }, []);

  const scrollToCard = (id: number): void => {
    refs.current[id].scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const onChange: OnChange = (isVisible, id) => {
    if (isVisible && activeTab !== id) {
      setActiveTab(id);
    }

    if (!isVisible && activeTab === id - 1) {
      setActiveTab(id);
    }
  };

  useEffect(() => {
    setActiveTab(0);
  }, []);

  const anchorNavClass = cn(
    'absolute space-y-2 top-0 left-0 px-page py-gutter flex flex-col items-start',
    device.select({ MOBILE: 'hidden', TABLET: 'hidden', DESKTOP_M: '', DESKTOP_L: '' })
  );

  const AnchorNav = () => (
    <ul className={anchorNavClass}>
      {NAV_LINKS.map((link, idx) => {
        const liClass = cn('text-gray-500 text-sm cursor-pointer', { 'text-indigo-600 font-bold': idx === activeTab });

        return (
          <li key={link}>
            <div className={liClass} onClick={() => scrollToCard(idx)}>
              {link}
            </div>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className='h-user-profile relative flex flex-col overflow-hidden'>
      <AnchorNav />
      <div ref={ref} className='px-page desktop:px-0 flex-1 flex-shrink overflow-y-auto'>
        <div className='py-gutter desktop:w-1/2 w-full mx-auto space-y-6'>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 0)}
            containment={ref.current}
          >
            <Card ref={(el) => (refs.current[0] = el)}>
              <CardTitle size='2xl'>About</CardTitle>
              <CandidateProfile
                setLoading={setLoading}
                customer={candidate}
                onUpdate={onUpdate}
                style='candidateDetail'
              />
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 1)}
            containment={ref.current}
          >
            <Card ref={(el) => (refs.current[1] = el)}>
              <CardTitle size='2xl'>Activity</CardTitle>
              {activities ? <Activities activities={activities} scope='candidate' /> : <Loading absolute />}
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 2)}
            containment={ref.current}
          >
            <Card ref={(el) => (refs.current[2] = el)}>
              <CardTitle size='2xl'>Interviews</CardTitle>
              <Interviews candidateId={candidate.id} firstName={candidate.first_name} />
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 4)}
            containment={ref.current}
          >
            <Card ref={(el) => (refs.current[4] = el)}>
              <CardTitle size='2xl'> Screener Q&amp;A</CardTitle>
              {answers ? (
                <ScreenerResponses candidate={candidate} answers={answers} hidden_answers={[]} showInfo />
              ) : (
                <Loading absolute />
              )}
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 5)}
            containment={ref.current}
          >
            <Card ref={(el) => (refs.current[5] = el)}>
              <CardTitle size='2xl'>Studies</CardTitle>
              {participations ? (
                <Participations candidate={candidate} participations={participations} />
              ) : (
                <Loading absolute />
              )}
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 6)}
            containment={ref.current}
          >
            <Card noPadding ref={(el) => (refs.current[6] = el)}>
              <div className='px-6 pt-6'>
                <CardTitle size='2xl'> Assets </CardTitle>
              </div>
              <Assets customerId={candidate.id} fileAssets={candidate.file_assets || []} onUpdate={onUpdate} />
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 7)}
            containment={ref.current}
          ></VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 8)}
            containment={ref.current}
          >
            <Card noPadding ref={(el) => (refs.current[8] = el)}>
              <div className='px-6 pt-6'>
                <CardTitle size='2xl'>Highlights</CardTitle>
                <Highlights firstName={candidate.first_name} id={candidate.id} />
              </div>
            </Card>
          </VisibilitySensor>
          <VisibilitySensor
            partialVisibility='top'
            offset={offset}
            onChange={(isVisible) => onChange(isVisible, 9)}
            containment={ref.current}
          >
            <Card noPadding ref={(el) => (refs.current[9] = el)}>
              <div className='px-6 pt-6'>
                <CardTitle size='2xl'>Settings</CardTitle>
                <Settings candidate={candidate} onUpdate={onUpdate} />
              </div>
            </Card>
          </VisibilitySensor>
        </div>
      </div>
      {loading && <Loading absolute />}
    </div>
  );
};
