import Zoom from 'react-medium-image-zoom';
import * as React from 'react';
import { ImgHTMLAttributes } from 'react';
import { UncontrolledProps } from 'react-medium-image-zoom/dist/cjs/Uncontrolled';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  zoomProps?: UncontrolledProps;
}

export const ZoomableImage: React.FC<Props> = ({ zoomProps, alt, ...imageProps }) => (
  // bg-gray-700 opacity-50
  <Zoom overlayBgColorEnd='rgba(55, 65, 81, 0.5)' {...zoomProps}>
    <img {...imageProps} alt={alt} />
  </Zoom>
);
