import React, { HTMLAttributes, forwardRef, useLayoutEffect, useRef } from 'react';
import cn from 'classnames';
import mergeRefs from 'react-merge-refs';

import { GrabberSVG } from '@components/svgs';
import { useMutationObserver } from '@hooks/useMutationObserver';

import { Drawer } from '../hooks/useDrawer';
import { useDrawerContext } from '../hooks/useDrawerContext';

type Props = PropsWithChildrenFn<HTMLAttributes<HTMLDivElement>, Pick<Drawer['state'], 'isExpanded'>>;

export const Header = forwardRef<HTMLDivElement, Props>(({ className, children, ...rest }, ref) => {
  const innerRef = useRef<HTMLDivElement>(null);

  const { setHeaderHeight } = useDrawerContext();

  useLayoutEffect(() => {
    if (innerRef.current) {
      setHeaderHeight(innerRef.current.offsetHeight);
    }
  }, []);

  useMutationObserver(
    innerRef,
    (mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && innerRef.current) {
          setHeaderHeight(innerRef.current.offsetHeight);
        }
      }
    },
    { subtree: true, childList: true }
  );

  return (
    <header
      data-testid='drawer-header'
      ref={mergeRefs([innerRef, ref])}
      className={cn(
        'jutify-center relative flex flex-col items-center flex-shrink-0 w-full bg-white overflow-hidden z-10',
        className
      )}
      {...rest}
    >
      <GrabberSVG className='top-2 left-1/2 absolute right-0 transform -translate-x-1/2 -translate-y-1/2' />
      {children}
    </header>
  );
});
