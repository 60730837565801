import * as React from 'react';
import { useState } from 'react';
import { sortOptions } from 'components/CandidatesApp/CandidatesIndex/utils';

export type GetFilteredOptions<T> = (option: T, searchValue?: string) => boolean;

interface UseSearchWithinProps<T> {
  options: T[];
  getFilteredOptions?: GetFilteredOptions<T>;
  sort?: 'alpha';
}

type UseSearchWithinFilter<T> = {
  filteredOptions: T[];
  searchValue: string;
  setSearchValue: (value: string) => void;
};

export const useSearchWithinFilter = <T,>(props: UseSearchWithinProps<T>): UseSearchWithinFilter<T> => {
  const { options, getFilteredOptions } = props;
  const [searchValue, setSearchValue] = useState('');

  const filterOptions = (option) => {
    const labelToLowerCase = option?.toLowerCase();
    const searchValueToLC = searchValue.toLowerCase();

    return labelToLowerCase?.includes(searchValueToLC);
  };

  const filterOptionsFunc = (option) =>
    getFilteredOptions ? getFilteredOptions(option, searchValue) : filterOptions(option);

  const preSortedOptions = options?.filter(filterOptionsFunc);

  const filteredOptions = props.sort === 'alpha' ? sortOptions(preSortedOptions) : preSortedOptions;

  return { filteredOptions, searchValue, setSearchValue };
};
