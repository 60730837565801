import React, { SVGProps } from 'react';

export const LightningSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.4112 6.59692C10.4669 6.50651 10.4973 6.4029 10.4996 6.29678C10.5018 6.19066 10.4757 6.08586 10.4239 5.99319C10.3721 5.90053 10.2966 5.82335 10.2051 5.76962C10.1135 5.71588 10.0093 5.68754 9.90315 5.6875H7.8749V0.4375L3.58857 7.40367C3.53291 7.49403 3.50238 7.59761 3.50013 7.70372C3.49789 7.80983 3.52401 7.91461 3.5758 8.00725C3.62759 8.09988 3.70317 8.17702 3.79473 8.23068C3.88629 8.28434 3.99052 8.31259 4.09665 8.3125H6.1249V13.5625L10.4112 6.59692Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
