import * as React from 'react';
import { useEffect, useRef } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';

import { AppLoader } from '@components/AppLoader';
import { NewStudyShortcut } from '@components/AppRouter';
import { NavShortcuts } from '@components/NavShortcuts';
import { Container } from '@components/layouts/Container';
import { NewStudyAction } from '@components/StudiesApp/components/StudyNew';
import { TrackRouteChange } from '@components/TrackRouteChange';
import { exactlyOnce, renderComponent } from '@components/utils';

export const Unrouter: React.FC = () => {
  const { pathname } = useLocation();
  const initialPathname = useRef(pathname);

  useEffect(() => {
    if (pathname !== initialPathname.current) {
      (window as any).location = pathname;
    }
  }, [pathname]);

  return null;
};

const SidebarShim: React.FC = () => (
  <Router>
    <Unrouter />
    <TrackRouteChange />
    <NavShortcuts />
    <AppLoader hideLoader>
      <NewStudyAction>{({ openSlideout }) => <NewStudyShortcut openSlideout={openSlideout} />}</NewStudyAction>
      <Container sidebar />
    </AppLoader>
  </Router>
);

export function mount(): void {
  const sidebarId = window.window.innerWidth >= 768 ? 'sidebar-portal' : 'sidebar-mobile-portal';
  const shimMount = document.getElementById('sidebar-shim-mount');
  const sidebarPortal = document.getElementById(sidebarId);
  const skeleton = document.getElementById('sidebar-shim-skeleton');
  if (!shimMount || !sidebarPortal || !skeleton) {
    return;
  }

  // watch sidebar-portal for when component is mounted
  // remove the skeleton then to prevent flash of layout change
  const onSidebarMounted = exactlyOnce(() => {
    skeleton.remove();
    sidebarPortal.removeEventListener('DOMNodeInserted', onSidebarMounted);
  });

  const observer = new MutationObserver((mutationList) =>
    mutationList
      .filter((m) => m.type === 'childList')
      .forEach((m) => {
        m.addedNodes.forEach(onSidebarMounted);
      })
  );
  
  observer.observe(sidebarPortal, { childList: true, subtree: true });

  // mount
  renderComponent(SidebarShim, 'sidebar-shim-mount');
}
