type Filterable = { phone_number: string | null; email: string | null; email_valid?: boolean };

export const filterContactable = <R extends Filterable>(method: Study['comms_medium'], records: R[]): R[] => {
  if (method === 'sms') {
    return records.filter((r) => !!r.phone_number);
  } else {
    return records.filter((r) => !!r.email && !(r.email_valid === false));
  }
};

export const filterCompleteable = (parties: Participation[]): Participation[] =>
  parties.filter(({ status }) => status != 'completed');

function partiesByCustomerId(participations: Participation[]): Record<number, Participation[]> {
  return participations.reduce((m, party) => {
    m[party.customer_id] ||= [];
    m[party.customer_id].push(party);
    return m;
  }, {});
}

export const filterPartiesById = (participations: Participation[], ids: number[]): Participation[] =>
  participations.filter(({ id }) => ids.includes(id));
