import React from 'react';

import { UrnKey } from './types';

type Urn = {
  key: UrnKey;
  id: string;
  params: Record<string, string>;
};

export function parseUrn(urn: string): Urn | null {
  const parts = urn.split(':');

  if (parts.length < 3) {
    return null;
  }

  const key = parts[1] as UrnKey;
  const id = parts[2];

  const params = {};

  if (parts.length > 3) {
    parts.slice(3).forEach((pair) => {
      const [paramKey, paramValue] = pair.split('=');
      if (!paramKey || !paramValue) {
        return;
      }
      params[paramKey] = paramValue;
    });
  }

  return {
    key,
    id,
    params
  };
}
