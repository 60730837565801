import { StudyScreenerBuilder } from 'components/StudiesApp/components/StudyDraft/components/StudyScreenerBuilder';
import * as React from 'react';

import { Button, Card, Text } from '@components/common';

const SURVEY_HEADINGS: Omit<Record<Study['style'] & 'focus_group', string>, 'repo_only' | 'phantom'> = {
  survey: 'Survey',
  unmoderated_test: 'Test',
  panel: 'Signup form'
};

interface Props {
  study: Study;
  pathType: string;
  screenerType: ScreenerType;
  updateStudy: (study: { id: number } & Partial<Study>) => void;
}

export const ScreenerTab: React.FC<Props> = ({ study, pathType, screenerType, updateStudy }) => {
  const isPreScreener: boolean = screenerType === 'pre';

  const screener = isPreScreener ? study.pre_screener : study.survey_screener;

  if (!screener) return null;

  return (
    <div className='h-screen-with-fixed-header flex-1 overflow-auto'>
      <section className='border-b border-gray-200'>
        <header className='px-page flex items-center justify-between h-20'>
          <Text as='h2' className='text-2xl' bold>
            {isPreScreener ? 'Screener' : SURVEY_HEADINGS[study.style]}
          </Text>

          <div id='post-publish-survey-actions'>
            {study.style === 'unmoderated_test' && pathType === 'test' && (
              <Button icon='eye' href={`/studies/${study.id}/preview/survey`} target='_blank'>
                Preview
              </Button>
            )}
          </div>
        </header>
      </section>

      <section className='px-page py-gutter'>
        <Card>
          <StudyScreenerBuilder
            page='study'
            study={study}
            screener={screener as Screener}
            preScreener={isPreScreener}
            onSave={updateStudy}
          />
        </Card>
      </section>
    </div>
  );
};
