import React, { SVGProps } from 'react';

export const EmailSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 1H15V11H1V1Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M14.7741 1.36664L9.34474 5.54264C8.95922 5.83921 8.48647 6.00001 8.00007 6.00001C7.51368 6.00001 7.04093 5.83921 6.65541 5.54264L1.22607 1.36664'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
