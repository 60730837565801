import * as React from 'react';

import { buildProject, buildScreener } from '@api/builders';
import { renderComponent } from '@components/utils';

import { GQSurvey } from './GQSurvey';

export function mount(): void {
  renderComponent(GQSurvey, 'screener-form', ({ study, screener, participation, hidden, prefill, account }) => ({
    study: buildProject(study.data),
    screener: buildScreener(screener.data),
    participation: participation ? participation.data.attributes : undefined,
    hidden,
    prefill,
    account
  }));
}
