import * as React from 'react';

export const eligibility: React.FC<any> = (props) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5625 5.79658C13.5628 7.65779 12.9038 9.45893 11.7025 10.8805C10.5012 12.3021 8.83517 13.2523 7 13.5625C5.16483 13.2523 3.49883 12.3021 2.29753 10.8805C1.09622 9.45893 0.437249 7.65779 0.4375 5.79658V1.3125C0.4375 1.08044 0.529687 0.857876 0.693782 0.693782C0.857876 0.529687 1.08044 0.4375 1.3125 0.4375H12.6875C12.9196 0.4375 13.1421 0.529687 13.3062 0.693782C13.4703 0.857876 13.5625 1.08044 13.5625 1.3125V5.79658Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.03125 5.03125C5.03125 5.55339 5.23867 6.05415 5.60788 6.42337C5.9771 6.79258 6.47785 7 7 7C7.52214 7 8.0229 6.79258 8.39212 6.42337C8.76133 6.05415 8.96875 5.55339 8.96875 5.03125C8.96875 4.5091 8.76133 4.00835 8.39212 3.63913C8.0229 3.26992 7.52214 3.0625 7 3.0625C6.47785 3.0625 5.9771 3.26992 5.60788 3.63913C5.23867 4.00835 5.03125 4.5091 5.03125 5.03125V5.03125Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.1425 9.77101C9.84931 9.18904 9.40027 8.69992 8.84543 8.35816C8.29058 8.01641 7.65173 7.83545 7.00008 7.83545C6.34843 7.83545 5.70958 8.01641 5.15474 8.35816C4.5999 8.69992 4.15085 9.18904 3.85767 9.77101'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
