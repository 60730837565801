import React, { SVGProps } from 'react';

export const FolderSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='m13.6875 5.8125c0-.23206-.0922-.45462-.2563-.61872-.1641-.16409-.3866-.25628-.6187-.25628h-5.6875l-1.05-1.4c-.0815-.10867-.18719-.19687-.30869-.25762s-.25547-.09238-.39131-.09238h-2.1875c-.23206 0-.45462.09219-.61872.25628-.16409.1641-.25628.38666-.25628.61872v7.875c0 .2321.09219.4546.25628.6187.1641.1641.38666.2563.61872.2563h9.625c.2321 0 .4546-.0922.6187-.2563s.2563-.3866.2563-.6187z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='.875'
    />
  </svg>
);
