import { useState } from 'react';

import { FigmaPrototypeTypes } from '@components/common/FigmaPrototype';
import { Enums, Models } from '@components/SurveyBuilder';

interface Args {
  block?: Models.Block<Enums.Kind.prototypeTest>;
}

interface Result {
  isComplete: boolean;
  onIframePrototypeNodeChange: (event: FigmaPrototypeTypes.MessageEvent['data']) => void;
}

export const usePrototypeTaskValidation = ({ block }: Args): Result => {
  const [isComplete, setIsComplete] = useState<boolean>(false);

  // goal specific
  const onIframePrototypeNodeChange: Result['onIframePrototypeNodeChange'] = (data) => {
    if (!block || block.kind !== Enums.Kind.prototypeTest) return;

    const [path] = block.blockable.paths;

    const goal = path.steps.find(({ last }) => last);
    const { presentedNodeId } = data;

    if (goal && presentedNodeId === goal.external_element_id) {
      setIsComplete(true);
    }
  };

  return { isComplete, onIframePrototypeNodeChange };
};
