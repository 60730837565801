import cn from 'classnames';
import { Calendar } from 'components/Public/Scheduler/components/Calendar';
import React, { useRef } from 'react';

import { AvatarFromId } from '@components/common';
import { ClockSVG, GlobeSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';
import { useUser } from '@hooks/useUser';

export const SchedulingPagePreview: React.FC<{ brand: Brand; accountName: string }> = ({ brand, accountName }) => {
  const { text_color, logo } = brand;

  const wrapperRef = useRef(null);

  const { name: userName, id, job_title, first_name } = useUser();

  return (
    <div className='relative flex flex-col items-center justify-center min-h-screen bg-white'>
      {logo && (
        <div
          className={`flex-0 desktop:justify-start desktop:pt-8 desktop:px-10 block logo-align-brand w-full py-4 bg-white`}
        >
          <div className='inline-block'>
            <img alt={`${accountName} logo`} className='desktop:max-h-32 max-h-24 block w-auto' src={logo} />
          </div>
        </div>
      )}
      <div className='desktop:pt-8 desktop:px-10 desktop:max-w-none flex flex-col flex-1 max-w-sm pt-4'>
        <h2 className='desktop:mb-6 desktop:pb-0 desktop:pt-0 px-6 pt-4 pb-2 text-2xl font-semibold leading-normal text-left text-center'>
          Let's chat
        </h2>
        <div className='desktop:flex-row desktop:border flex flex-col flex-1 w-full mx-auto border-gray-200'>
          <div className='desktop:w-5/12 desktop:border-r desktop:border-gray-200 desktop:pb-4 w-full p-6 pb-2'>
            <div className=''>
              <div className='flex'>
                <AvatarFromId size='2xl' userId={id} />
                <div className='ml-6 overflow-hidden'>
                  <div className='h600 bold truncate'>{userName}</div>
                  <div className='h500 text-custom-brand-secondary truncate'>{accountName}</div>
                  <div className='h400 truncate'>{job_title}</div>
                </div>
              </div>
              <div className='my-4'>
                <ul className='h400 space-y-3'>
                  <li className='flex items-center'>
                    <div className='flex justify-center w-10'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='18'
                        viewBox='0 0 24 18'
                        fill='none'
                        role='img'
                        aria-labelledby='aadfx2euj4k6hgue9vtjglq24skmkb4j'
                        className='mr-4'
                      >
                        <title id='aadfx2euj4k6hgue9vtjglq24skmkb4j'>Icons/contact phone</title>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M2 0H22C23.1 0 24 0.9 24 2L23.99 16C23.99 17.1 23.1 18 22 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM11 6C11 4.34 9.66 3 8 3C6.34 3 5 4.34 5 6C5 7.66 6.34 9 8 9C9.66 9 11 7.66 11 6ZM2 15V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15H2ZM19.49 11H17.85C17.63 10.37 17.5 9.7 17.5 9C17.5 8.3 17.63 7.63 17.85 7H19.49L21 5L19.01 3.01C17.7 3.99 16.73 5.38 16.28 7C16.1 7.64 16 8.31 16 9C16 9.69 16.1 10.36 16.28 11C16.73 12.61 17.7 14.01 19.01 14.99L21 13L19.49 11Z'
                          fill='#9FA6B2'
                        ></path>
                      </svg>
                    </div>
                    Interviews
                  </li>
                  <li className='flex items-center'>
                    <div className='flex justify-center w-10'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='22'
                        viewBox='0 0 20 22'
                        fill='none'
                        role='img'
                        aria-labelledby='a38c6do036rryfuqm4kqeritnuuarzbq'
                        className='mr-4'
                      >
                        <title id='a38c6do036rryfuqm4kqeritnuuarzbq'>Icons/alarm</title>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M5.94456 2.44107L4.66336 0.905273L0.0560608 4.74887L1.33716 6.28467L5.94456 2.44107ZM15.3366 0.905873L19.944 4.74947L18.6628 6.28527L14.0554 2.44167L15.3366 0.905873ZM10.5001 7.09487H9.00006V13.0949L13.7501 15.9449L14.5001 14.7149L10.5001 12.3449V7.09487ZM10.0001 3.09487C5.03006 3.09487 1.00006 7.12487 1.00006 12.0949C1.00006 17.0649 5.03006 21.0949 10.0001 21.0949C14.9701 21.0949 19.0001 17.0649 19.0001 12.0949C19.0001 7.12487 14.9701 3.09487 10.0001 3.09487ZM3.00006 12.0949C3.00006 15.9549 6.14006 19.0949 10.0001 19.0949C13.8601 19.0949 17.0001 15.9549 17.0001 12.0949C17.0001 8.23487 13.8601 5.09487 10.0001 5.09487C6.14006 5.09487 3.00006 8.23487 3.00006 12.0949Z'
                          fill='#9FA6B2'
                        ></path>
                      </svg>
                    </div>
                    30 minutes
                  </li>
                  <ul>
                    <ul className='h400 space-y-3'>
                      <li className='flex items-center'>
                        <div className='flex justify-center w-10'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            role='img'
                            aria-labelledby='aizkbni4dqqzwcgjnuyx8nticjpu9il7'
                            className='mr-4'
                          >
                            <title id='aizkbni4dqqzwcgjnuyx8nticjpu9il7'>Icons/check box</title>
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM13.58 4.58L14.99 6L6.99 14L2.99 10.01L4.41 8.6L6.99 11.17L13.58 4.58Z'
                              fill='#9FA6B2'
                            ></path>
                          </svg>
                        </div>
                        Laptop with working webcam
                      </li>
                      <li className='flex items-center'>
                        <div className='flex justify-center w-10'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 18 18'
                            fill='none'
                            role='img'
                            aria-labelledby='afsbeq2cetucpzmnjqu231u4g2faa7ch'
                            className='mr-4'
                          >
                            <title id='afsbeq2cetucpzmnjqu231u4g2faa7ch'>Icons/check box</title>
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16ZM13.58 4.58L14.99 6L6.99 14L2.99 10.01L4.41 8.6L6.99 11.17L13.58 4.58Z'
                              fill='#9FA6B2'
                            ></path>
                          </svg>
                        </div>
                        Reliable internet connection
                      </li>
                      <ul></ul>
                    </ul>
                  </ul>
                </ul>
              </div>
              <div
                style={{ color: text_color }}
                className='tablet:block bg-gray-50 p-4 text-sm text-gray-800 rounded-lg'
              >
                <p className='mb-3'>Hey there! 👋</p>

                <p className='mb-3'>
                  I'd love to have you involved in our next research project to help us build a better {name}. It would
                  be great if you'd consider signing up to participate.
                </p>

                <p className='mb-3'>- {first_name}</p>
              </div>
            </div>
          </div>
          <div className='desktop:w-7/12 w-full'>
            <div className='h-full'>
              <div
                id='BookingPage'
                data-props='{"study":{"data":{"id":"2","type":"study","attributes":{"id":2,"language":"en","style":"video_call","public_path":"dsfsdfdf/jgnjl7c1","comms_medium":"email","workspace":"dsfsdfdf","slug":"jgnjl7c1","schedulable_beginning":"2023-08-17T12:39:53.570+03:00","schedulable_end":"2023-08-21T23:59:59.999+03:00","duration_in_minutes":30,"time_proposals_enabled":true,"waitlist_enabled":false,"company_name":"gq","external_url":null,"focus_group":false,"continuous":false}}},"participation":{"data":{"id":null,"type":"participation","attributes":{"id":null,"name":"","first_name":null,"token":null,"status":"requested","phone_number":null,"consent_info":{},"consent":{},"opted_in":false,"consent_token":null,"email":null,"external":false,"external_completion_url":null}}}}'
                className='relative flex-1 max-w-full p-6'
              >
                <div className='h-full max-w-full'>
                  <div className={cn('desktop:grid desktop:grid-cols-2')}>
                    <div>
                      <Calendar
                        wrapperRef={wrapperRef}
                        handleDateSelected={() => null}
                        handleOffsetChanged={() => null}
                        selectedDate={new Date()}
                        calendarOffset={0}
                        setWrapperHeight={() => null}
                        nextMonthCount={0}
                        timeslots={{
                          '2023-08-28': ['09:00'],
                          '2023-08-29': ['09:00'],
                          '2023-08-30': ['09:00']
                        }}
                      />
                      <div className='flex-row truncate space-x-2 mb-5 h-10 flex items-center text-left text-sm rounded-md w-full py-2.5 px-4 focus:outline-none  text-gray-700 hover:bg-gray-50  border border-gray-200  items-center'>
                        <GlobeSVG className='flex-shrink-0' />
                        <span>America / Sao Paulo (BRT -03:00)</span>
                      </div>
                      <div className='flex-row truncate space-x-2 h-10 flex items-center text-left text-sm rounded-md w-full py-2.5 px-4 focus:outline-none  text-gray-700 hover:bg-gray-50  border border-gray-200  items-center'>
                        <ClockSVG /> <span>24-hour clock</span>
                      </div>
                    </div>
                    <section className='desktop:pl-14 desktop:pr-4 overflow-y-auto'>
                      <ol className='desktop:mt-0 text-custom-brand mt-6 space-y-6 text-sm leading-6'>
                        <li>
                          <div className='text-custom-brand mb-4 text-base font-semibold text-left'>
                            <time data-timeslot='2023-08-28' dateTime='Monday, August 28th, 2023'>
                              Monday, August 28th, 2023
                            </time>
                          </div>
                          <ol className='space-y-1'>
                            <li className='group flex items-center w-full space-x-4 text-center'>
                              <button className='border-custom-brand hover-bg-custom-brand-button hover-text-custom-brand-button focus:outline-none w-full h-full px-4 py-2 truncate transition-all duration-150 border rounded-md'>
                                9:00 AM EET
                              </button>
                            </li>
                            <li className='group flex items-center w-full space-x-4 text-center'>
                              <button className='border-custom-brand hover-bg-custom-brand-button hover-text-custom-brand-button focus:outline-none w-1/2 h-full px-4 py-2 truncate transition-all duration-150 border rounded-md'>
                                10:00 AM EET
                              </button>
                              <a className='focus:text-white border-custom-brand bg-custom-brand-button text-custom-brand-button hover-text-custom-brand-button hover:font-bold w-1/2 h-full px-4 py-2 overflow-hidden transition-all duration-150 border rounded-md'>
                                Confirm
                              </a>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </section>
                  </div>
                </div>
                <div className='border-custom-brand-secondary pt-4 mt-4 text-center border-t'>
                  <div className='h500 mb-4 font-bold'>Don’t see a time that works for you?</div>
                  <a
                    role='link'
                    style={{ borderWidth: '1px' }}
                    className='block disabled:opacity-50 cursor-pointer inline-flex items-center justify-center relative focus:ring focus:ring-blue focus:outline-none font-medium text-xs leading-5 px-3 py-1.5 space-x-2 rounded-md btn-custom-brand-secondary'
                  >
                    <svg
                      className='my-0.5 text-gray-700 hover:text-indigo-600'
                      width='16'
                      height='16'
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M3 15.5H13C13.6628 15.4993 14.2983 15.2357 14.767 14.767C15.2357 14.2983 15.4993 13.6628 15.5 13V4C15.4993 3.33717 15.2357 2.70168 14.767 2.23299C14.2983 1.7643 13.6628 1.50069 13 1.5H12.5V1C12.5 0.867392 12.4473 0.740215 12.3536 0.646447C12.2598 0.552678 12.1326 0.5 12 0.5C11.8674 0.5 11.7402 0.552678 11.6464 0.646447C11.5527 0.740215 11.5 0.867392 11.5 1V1.5H4.5V1C4.5 0.867392 4.44732 0.740215 4.35355 0.646447C4.25979 0.552678 4.13261 0.5 4 0.5C3.86739 0.5 3.74021 0.552678 3.64645 0.646447C3.55268 0.740215 3.5 0.867392 3.5 1V1.5H3C2.33717 1.50069 1.70169 1.7643 1.23299 2.23299C0.764301 2.70168 0.500688 3.33717 0.5 4V13C0.500688 13.6628 0.764301 14.2983 1.23299 14.767C1.70169 15.2357 2.33717 15.4993 3 15.5ZM13 14.5H3C2.60231 14.4995 2.22104 14.3414 1.93984 14.0602C1.65863 13.779 1.50045 13.3977 1.5 13V5.5H14.5V13C14.4995 13.3977 14.3414 13.779 14.0602 14.0602C13.779 14.3414 13.3977 14.4995 13 14.5ZM3 2.5H3.5V3C3.5 3.13261 3.55268 3.25979 3.64645 3.35355C3.74021 3.44732 3.86739 3.5 4 3.5C4.13261 3.5 4.25979 3.44732 4.35355 3.35355C4.44732 3.25979 4.5 3.13261 4.5 3V2.5H11.5V3C11.5 3.13261 11.5527 3.25979 11.6464 3.35355C11.7402 3.44732 11.8674 3.5 12 3.5C12.1326 3.5 12.2598 3.44732 12.3536 3.35355C12.4473 3.25979 12.5 3.13261 12.5 3V2.5H13C13.3977 2.50045 13.779 2.65863 14.0602 2.93984C14.3414 3.22104 14.4995 3.60231 14.5 4V4.5H1.5V4C1.50045 3.60231 1.65863 3.22104 1.93984 2.93984C2.22104 2.65863 2.60231 2.50045 3 2.5Z'
                        fill='currentColor'
                      ></path>
                      <path
                        d='M10.8529 8.14586L9.8535 7.14646C9.7592 7.05538 9.6329 7.00498 9.5018 7.00612C9.3707 7.00726 9.24529 7.05985 9.15259 7.15255C9.05989 7.24525 9.0073 7.37066 9.00616 7.50176C9.00502 7.63286 9.05542 7.75916 9.1465 7.85346L9.293 7.99996H5.5C5.36739 7.99996 5.24021 8.05264 5.14645 8.14641C5.05268 8.24017 5 8.36735 5 8.49996C5 8.63257 5.05268 8.75974 5.14645 8.85351C5.24021 8.94728 5.36739 8.99996 5.5 8.99996H9.293L9.1465 9.14646C9.09874 9.19258 9.06065 9.24776 9.03445 9.30876C9.00824 9.36976 8.99445 9.43537 8.99387 9.50176C8.9933 9.56815 9.00595 9.63399 9.03109 9.69544C9.05623 9.75688 9.09336 9.81271 9.1403 9.85966C9.18725 9.9066 9.24308 9.94373 9.30452 9.96887C9.36597 9.99401 9.43181 10.0067 9.4982 10.0061C9.56459 10.0055 9.6302 9.99171 9.6912 9.96551C9.7522 9.93931 9.80738 9.90121 9.8535 9.85346L10.8529 8.85406C10.9466 8.76004 10.9992 8.63271 10.9992 8.49996C10.9992 8.36721 10.9466 8.23988 10.8529 8.14586Z'
                        fill='currentColor'
                      ></path>
                      <path
                        d='M10.5 11H6.707L6.8535 10.8535C6.94458 10.7592 6.99498 10.6329 6.99384 10.5018C6.9927 10.3707 6.94012 10.2453 6.84741 10.1526C6.75471 10.0598 6.6293 10.0073 6.4982 10.0061C6.3671 10.005 6.2408 10.0554 6.1465 10.1465L5.1471 11.1459C5.05339 11.2399 5.00077 11.3672 5.00077 11.5C5.00077 11.6327 5.05339 11.76 5.1471 11.8541L6.1465 12.8535C6.2408 12.9445 6.3671 12.9949 6.4982 12.9938C6.6293 12.9927 6.75471 12.9401 6.84741 12.8474C6.94012 12.7547 6.9927 12.6293 6.99384 12.4982C6.99498 12.3671 6.94458 12.2408 6.8535 12.1465L6.707 12H10.5C10.6326 12 10.7598 11.9473 10.8536 11.8535C10.9473 11.7597 11 11.6326 11 11.5C11 11.3674 10.9473 11.2402 10.8536 11.1464C10.7598 11.0526 10.6326 11 10.5 11Z'
                        fill='currentColor'
                      ></path>
                    </svg>
                    <span>Propose new time</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex-0 desktop:py-4 desktop:border-0 flex justify-center w-full px-10 py-10 text-center bg-white border-t border-gray-200'>
        <div className='inline-block'>
          <a
            href='/?utm_campaign=booking_footer&utm_source=powered_by&utm_medium=gq.fyi'
            className='flex flex-col justify-center text-center'
          >
            <span className='text-xs font-light text-gray-500 uppercase'>Powered by</span>
            <img
              className='w-auto h-6'
              alt='Great Question'
              src='/assets/logo-6ab7960ffc021e5e7be2ef08a23455e84957c18becac88029a7f18e4640bc361.png'
            />
          </a>
          <div></div>
        </div>
        <span className='hidden'></span>
      </div>
    </div>
  );
};
