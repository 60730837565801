import React from 'react';

import { Button, Text } from '@components/common';

export const StudiesZDSAlert: React.FC = () => {
  return (
    <div className='mt-4 px-6 py-12 text-center border border-gray-200 rounded-md'>
      <Text bold mb='2'>
        No existing studies
      </Text>
      <Text h='400' color='gray-500' mb='4'>
        You can create a new study where you can invite candidates.
      </Text>
      <Button primary icon='pencil' spa href='/studies'>
        Create new study
      </Button>
    </div>
  );
};
