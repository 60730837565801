import { Toast } from '@stores/toaster';

export const failedGet = (): Toast => ({
  heading: 'Failed to load!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
});

export const failedCreate = (): Toast => ({
  heading: 'Failed to create!',
  icon: 'error',
  text: 'Something went wrong creating your template. Please try again later.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong saving your template. Please try again later.'
});

export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that template. Please try again later.'
});

export const successCreate = (): Toast => ({
  heading: 'Template was created',
  icon: 'success',
  text: "We've created your template."
});

export const successDelete = (): Toast => ({
  heading: 'Template deleted',
  icon: 'success',
  text: `Your template was deleted`
});

export const successUpdate = (): Toast => ({
  heading: 'Template changed',
  icon: 'success',
  text: `Your template was updated`
});
