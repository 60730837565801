import * as React from 'react';
import { Link } from 'react-router-dom';
import { format as timeagoFormat } from 'timeago.js';

import { Text } from '@components/common';
import { UserAvatars } from '@components/shared/UserAvatars';
import { StudyStatus } from '@components/StudiesApp/components/StudyPublished/components/StudyStatus';
import { AskAiButton } from '@components/AiChat/components/AskAiButton';

import { getStudyProgress } from '../hooks/getStudyProgress';
import { OptionsDropdown } from './OptionsDropdown';

type Props = {
  study: Study;
};

export const StudyCard: React.FC<Props> = ({ study }) => {
  const progress = getStudyProgress(study);
  const url = study.state === 'draft' ? `/studies/${study.id}/edit` : `/studies/${study.id}`;

  const aiChatContext = {
    context_name: study.title,
    context_type: 'study',
    id: String(study.id)
  };

  return (
    <div className='xx-study-card relative'>
      <div className='top-4 right-4 justify-between absolute flex'>
        {aiChatContext && <AskAiButton pageContext={aiChatContext} style='small' showWhenUnavailable={false} />}
        <OptionsDropdown study={study} />
      </div>

      <Link to={url} title={study.title}>
        <div className='desktop:w-auto hover:bg-gray-50 hover:border-indigo-600 w-full h-full text-gray-700 bg-white border border-gray-200 rounded-md'>
          <div className='p-4'>
            <div className='flex items-center'>
              <Text h='400' className='flex-1 mb-6'>
                <StudyStatus status={study.state} />
              </Text>
            </div>

            <Text bold truncate className='xx-study-title mb-1'>
              {study.title}
            </Text>
            <Text h='400' color='gray-500' className='mb-5'>
              {study.style === 'video_call' && study.focus_group && 'Focus groups'}
              {study.style === 'video_call' && !study.focus_group && !study.is_phantom && 'Interviews'}
              {study.style === 'video_call' && !study.focus_group && study.is_phantom && 'Recordings'}
              {study.style === 'survey' && 'Survey'}
              {study.style === 'online_task' && 'Online task'}
              {study.style === 'unmoderated_test' && 'Unmoderated test'}
              {study.style === 'panel' && 'Panel'}
            </Text>

            {!progress.show && <div className='h-8' />}

            <div className='flex items-center h-6 mt-4'>
              <UserAvatars truncate={5} noTippy user_ids={study.user_ids} className='flex-grow flex-shrink-0' />
              <Text h='200' color='gray-500' className='ml-2 truncate'>
                Updated {timeagoFormat(study.updated_at as any)}
              </Text>
            </div>
          </div>

          {progress.show && <hr />}

          {progress.show && (
            <div className='flex items-center p-4'>
              <div className='w-32'>{progress.renderProgressBar?.()}</div>
              <Text h='200' color='gray-500' uppercase className='flex-1 text-right'>
                {progress.text} completed
              </Text>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};
