import React, { SVGProps } from 'react';

export const DesktopSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='monitor 1' clipPath='url(#clip0_4612_24733)'>
      <g id='Group'>
        <path
          id='Vector'
          d='M10.3438 15.0313H5.65625L6.125 12.2188H9.875L10.3438 15.0313Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M4.25 15.0312H11.75'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M0.96875 9.71875H15.0313'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M2.84375 0.96875H13.1563C13.1563 0.96875 15.0313 0.96875 15.0313 2.84375V10.3438C15.0313 10.3438 15.0313 12.2188 13.1563 12.2188H2.84375C2.84375 12.2188 0.96875 12.2188 0.96875 10.3438V2.84375C0.96875 2.84375 0.96875 0.96875 2.84375 0.96875Z'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_4612_24733'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
