import React, { SVGProps } from 'react';

export const ThumbsDownSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1213_3305)'>
      <path
        d='M14.5126 9.01985H13.7018C12.4781 9.01968 11.3033 9.06261 10.4305 9.92023C9.5576 10.7778 9.05652 11.9439 9.03511 13.1674C9.03511 15.1419 6.53963 14.2174 6.53963 12.4557V10.1865C6.53963 9.54217 6.0173 9.01985 5.37296 9.01985H2.6264C2.01628 9.01985 1.50338 8.54928 1.49132 7.93929C1.45286 5.99308 1.77736 4.57745 2.56434 3.07077C3.0181 2.20203 3.96039 1.71706 4.94025 1.73899C11.751 1.89146 10.87 3.04069 14.5126 3.04069'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1213_3305'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
