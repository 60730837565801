// the filter is not applyable if you haven't yet picked a value,
// or it's a range and you haven't put one of the values in yet.

import { FilterState } from '../types';

export const isFilterApplyable = (state: FilterState<any>): boolean => {
  if (state.definition.type === 'segment') {
    return true;
  }
  if (['is_blank', 'is_present'].includes(state.operator)) {
    return true;
  } else if (state.operator === 'is_between') {
    if (state?.range?.min == null) {
      return false;
    }
    if (state?.range?.max == null) {
      return false;
    }
  } else if (state.allowNotSet) {
    return true;
  } else if (state.value == null) {
    return false;
  }
  return true;
};
