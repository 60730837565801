import React, { SVGProps } from 'react';

export const ShowTabsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='1.5' y='1.5' width='13' height='13' rx='3.5' fill='white' stroke='currentColor' />
    <line x1='6' y1='14.021' x2='6' y2='1.9794' stroke='#374151' />
    <g clipPath='url(#clip0_1093_4606)'>
      <path
        d='M11.2502 7.99997C11.2502 8.11664 11.2002 8.21664 11.1169 8.28331L9.25022 9.93331C9.11689 10.05 8.91689 10.0333 8.81689 9.89997C8.71689 9.76664 8.71689 9.58331 8.85022 9.46664L10.4836 8.03331C10.5002 8.01664 10.5002 7.99997 10.4836 7.96664L8.85022 6.53331C8.71689 6.41664 8.71689 6.21664 8.83355 6.09997C8.95022 5.98331 9.13355 5.96664 9.26689 6.06664L11.1336 7.69997C11.2002 7.78331 11.2502 7.88331 11.2502 7.99997Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1093_4606'>
        <rect width='4' height='4' fill='white' transform='translate(8 6)' />
      </clipPath>
    </defs>
  </svg>
);
