import * as React from 'react';

import { HashtagSVG } from '@components/svgs';

export const Number: QuestionCardBodyComponent = () => {
  return (
    <div className='max-w-md px-2 py-2 text-gray-400 border border-gray-200 rounded-md'>
      <div className='flex items-center'>
        <HashtagSVG className='mx-2 text-gray-700' />
        <span>Number</span>
      </div>
    </div>
  );
};
