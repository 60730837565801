import { Toast } from '@stores/toaster';

export const successRemoveUser = (name: string): Toast => ({
  heading: 'Account member removed',
  icon: 'success',
  text: `${name} was removed from your account.`
});

export const failedRemoveUser = (): Toast => ({
  heading: 'Something went wrong!',
  icon: 'error',
  text: `We couldn’t remove that user. Please try again later.`
});
