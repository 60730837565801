import React, { SVGProps } from 'react';

export const SwitchAccountsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1972_70441)'>
      <path
        d='M7.00021 6.95335C7.19336 6.95722 7.38535 6.92252 7.56493 6.85128C7.74451 6.78004 7.90807 6.67369 8.04604 6.53846C8.18401 6.40322 8.29362 6.24182 8.36845 6.06371C8.44327 5.88559 8.48181 5.69434 8.48181 5.50114C8.48181 5.30795 8.44327 5.1167 8.36845 4.93858C8.29362 4.76047 8.18401 4.59907 8.04604 4.46383C7.90807 4.3286 7.74451 4.22225 7.56493 4.15101C7.38535 4.07977 7.19336 4.04507 7.00021 4.04894C6.62226 4.05965 6.26339 4.21732 5.99984 4.48844C5.7363 4.75956 5.58887 5.12275 5.58887 5.50085C5.58887 5.87895 5.7363 6.24215 5.99984 6.51327C6.26339 6.78439 6.62226 6.94206 7.00021 6.95277V6.95335Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.43316 9.21082C9.11524 8.16957 8.13116 7.38849 7.00007 7.38849C5.86899 7.38849 4.88432 8.16899 4.56641 9.21082'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.9004 2.85599C11.2986 2.14281 10.5482 1.56977 9.70167 1.177C8.85517 0.78423 7.93305 0.581212 6.99986 0.582157C3.45553 0.582157 0.582031 3.45566 0.582031 6.99999C0.582031 7.74432 0.708615 8.45949 0.941948 9.12391M2.1127 11.1597C2.71457 11.8683 3.4635 12.4373 4.30746 12.8273C5.15143 13.2172 6.07017 13.4188 6.99986 13.4178C10.5442 13.4178 13.4177 10.5443 13.4177 6.99999C13.4177 6.22124 13.2789 5.47457 13.0251 4.78391'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0557 3.04444H12.067V1.03369'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.94434 10.9556H1.93359V12.9663'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1972_70441'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
