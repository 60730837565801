import cn from 'classnames';
import React from 'react';

import { api } from '@api/reduxApi';
import { Basic } from '@components/shared/Skeleton';
import { isDisplayable, ScreenerAnswer } from '@components/ScreenerResults';

type Props = {
  className?: string;
  screenerId?: number;
  studyId?: number;
  participation?: Participation | null;
};

export const ResponsesTab: React.FC<Props> = ({ className, screenerId, studyId, participation }) => {
  const { data: screenerResponse } = api.useGetScreenerResponseQuery(
    {
      screenerId,
      studyId,
      participationId: participation?.id
    },
    {
      skip: !studyId || !participation?.id || !screenerId
    }
  );

  const answers = screenerResponse?.answers_json || [];

  return (
    <div className={cn('p-8', className)}>
      <div className='flex flex-col space-y-8'>
        {!screenerResponse && (
          <>
            <Basic dataTestid='skeleton' className='h-4' width='full' />
            <Basic className='h-4' width='full' />
            <Basic className='h-4' width='full' />
          </>
        )}
        {answers
          .filter(({ field_type }) => isDisplayable(field_type))
          .map((answer, idx) => (
            <ScreenerAnswer key={idx} answer={answer} />
          ))}
      </div>
    </div>
  );
};
