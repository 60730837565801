import { Toast } from '@stores/toaster';

export const SUCCESS_UPLOAD: Toast = {
  heading: 'Logo updated',
  text: 'The image file was successfully uploaded!',
  icon: 'success'
};

export const FAILED_UPLOAD: Toast = {
  heading: 'Failed to upload image file!',
  text: 'Something went wrong. Please try again later.',
  icon: 'error'
};

export const SUCCESS_UPDATE: Toast = {
  heading: 'Brand updated',
  icon: 'success',
  text: 'The brand settings was successfully updated.'
};

export const FAILED_UPDATE: Toast = {
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating that settings. Please try again later.'
};

export const SUCCESS_RESET: Toast = {
  heading: 'Brand reset',
  icon: 'success',
  text: 'The brand settings was successfully reset.'
};

export const FAILED_RESET: Toast = {
  heading: 'Failed to reset the brand settings',
  text: 'Something went wrong resetting the brand settings. Please try again later.',
  icon: 'error'
};
