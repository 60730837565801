import * as React from 'react';
import { useCallback, useMemo, useRef } from 'react';

import { AI } from '@api/chat-gpt';
import { api } from '@api/reduxApi';
import { compact, uid, uniq, without } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { useTags } from '@stores/tags';
import { useToaster } from '@stores/toaster';

import { randomTagColor } from '../colors';
import { Hook as UseMultiselectTags, useMultiselectTags } from '../useMultiselectTags';

type UseTagList = {
  canManage: boolean;
  multiselectTags: UseMultiselectTags;
  inputRef: React.RefObject<HTMLInputElement>;
  refocusInput: () => void;
};

interface Props {
  tagIds: number[];
  studyId?: number | null;
  readOnly?: boolean;
  onChange?: (tags: number[], tag?: Tag) => void;
  onTagCreate?: (tag: Tag) => void;
  suggest?: AI.UseQueryResult<string>;
  defaultOpen?: boolean;
  closeOnEsc?: boolean;
  hideManagerLinks?: boolean;
}
export const useTagList = ({
  tagIds,
  studyId,
  onChange,
  onTagCreate,
  suggest,
  closeOnEsc,
  hideManagerLinks
}: Props): UseTagList => {
  const { tags: data, tagGroups, getTag } = useTags();
  const [create] = api.useCreateTagMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  const permissionKey: Permission = studyId ? 'manageStudyTags' : 'manageTags';
  const canManage = usePermission(permissionKey)();

  const showToast = useToaster();

  const initialSelectedTagIds = useMemo(() => compact(tagIds.map(getTag)).map((t) => t.id), [tagIds, getTag]);

  const multiselectTags = useMultiselectTags({
    canManage,
    tags: data || [],
    tagGroups: tagGroups || [],
    initialSelectedTagIds,
    studyId,
    suggest,
    closeOnEsc,
    hideManagerLinks,
    onAddTag: (tag) => onChange?.(uniq([...tagIds, tag.id]), tag),
    onRemoveTag: (tag) => onChange?.(without(tagIds, tag.id)),
    onCreateTag: async (name, tagGroupId): Promise<Tag | undefined> => {
      try {
        const resp = await create({
          id: uid(),
          name,
          color: tagGroupId ? 'default' : randomTagColor(),
          taggings: [],
          tag_group_id: tagGroupId || null,
          project_id: (mode === 'study' ? studyId : null) || null
        }).unwrap();

        onTagCreate?.(resp);
        return resp;
      } catch {
        showToast({
          heading: 'Failed to create!',
          icon: 'error',
          text: 'Something went wrong creating the tag. Please try again later.'
        });
      }
    }
  });

  const { mode } = multiselectTags;

  const refocusInput = useCallback(
    () =>
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150),
    [inputRef]
  );

  return {
    canManage,
    multiselectTags,
    inputRef,
    refocusInput
  };
};
