import React, { SVGProps } from 'react';

export const IrregularCheckmarkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M46.185 17.4447C45.8025 15.6275 45.2286 13.906 44.4635 12.28C41.7855 6.15898 35.0906 2.33331 28.4913 1.1856C22.1789 0.0379029 15.0058 3.48101 10.0324 7.11539C3.91137 11.7062 -0.966373 21.1747 1.7116 28.7304C4.48521 36.4774 11.1801 44.0331 19.1184 47.1893C24.57 49.2934 32.9865 44.7982 37.2903 41.8334C42.5506 38.199 46.6632 32.843 46.9501 26.435C47.1414 23.4701 46.8545 20.314 46.185 17.4447Z'
      fill='#374151'
    />
    <path
      d='M14.72 27.2972C16.5372 29.8795 18.8325 31.888 21.3192 33.7052C21.6061 33.8965 22.18 33.8008 22.3713 33.4183C26.0057 26.532 31.266 20.124 36.1437 14.0986C36.6219 13.5248 35.4742 13.2378 35.1873 13.716C30.3095 19.8371 25.0492 26.3408 21.3192 33.227C21.7018 33.1313 21.9887 33.0357 22.3713 32.94C19.8846 31.1228 17.6848 29.21 15.8676 26.7233C15.4851 26.2451 14.3374 26.7233 14.72 27.2972Z'
      fill='white'
    />
  </svg>
);
