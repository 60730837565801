import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AIBots } from '@api/chat-gpt';
import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { MAX_CLIPS } from '@components/HighlightReelsApp/consts';
import { Title } from '@components/shared/AI';
import { useToaster } from '@components/stores/toaster';
import { TagList } from '@components/tags';
import { track } from '@components/tracking';
import { blurOnEnter } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useFixedSidebar } from '@hooks/useSidebar';

import { useHighlightReelsContext } from '../../../hooks/useHighlightReelsContext';
import * as toasts from '../../../toasts';
import { DiscardModal } from './DiscardModal';
import { ArtifactBreadcrumbs } from '@components/RepositoryApp/components/ArtifactBreadcrumbs';

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  highlightReel?: HighlightReel;
  studyId?: number | null;
}

export const Header = forwardRef<HTMLDivElement, Props>(({ className, highlightReel, studyId, ...rest }, ref) => {
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [tagIds, setTagIds] = useState<number[]>([]);
  const [clips, setClips] = useState<HighlightReel['clips']>([]);
  const [discardModalOpen, setDiscardModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { artifactIds } = useHighlightReelsContext();
  const { highlightReelToken = '', isDirty, setIsDirty, setArtifactIds, save } = useHighlightReelsContext();
  const { data: study } = api.useGetSimpleStudyQuery(studyId || 0, { skip: !studyId });
  const navigate = useNavigate();
  const showToast = useToaster();

  const { account } = useAccount();

  useFixedSidebar();

  const onSave = async () => {
    if (!save) {
      return;
    }
    setIsLoading(true);
    setIsDirty?.(false);
    try {
      const hr = await save({ ...highlightReel, title, subtitle, tag_ids: tagIds, clips });
      if (highlightReelToken) {
        showToast(toasts.successUpdateReel());
        track('updated_highlight_reel', { title, clips_count: clips.length });
      } else {
        showToast(toasts.successCreateReel());
        track('created_highlight_reel', { title, clips_count: clips.length });
      }
      navigate(`/highlight_reels/${hr.token}`);
    } catch (e) {
      showToast(toasts.failedUpdateReel());
    } finally {
      setIsLoading(false);
    }
  };

  const navigateBack = () => {
    if (!highlightReelToken && studyId) {
      navigate(`/studies/${studyId}/repository`);
      return;
    }
    if (!highlightReelToken) {
      showToast(toasts.successDiscardReel());
    }
    const url = highlightReelToken ? `/highlight_reels/${highlightReelToken}` : '/repository';
    navigate(url);
  };

  const onDiscard = () => {
    if (isDirty) {
      setDiscardModalOpen(true);
    } else {
      navigateBack();
    }
  };

  useEffect(() => {
    if (highlightReel) {
      setTitle(highlightReel.title || '');
      setSubtitle(highlightReel.subtitle || '');
      setTagIds(highlightReel.tag_ids);
      setArtifactIds(highlightReel.clips.map((c) => `Clip_${c.id}`));
    }
  }, [highlightReel]);

  useEffect(() => {
    setClips(
      artifactIds
        .filter((a) => a.startsWith('Clip_'))
        .map((a, i) => ({
          id: Number(a.slice(5)),
          order: i + 1
        }))
    );
  }, [artifactIds]);

  const tooManyClips = artifactIds.length > MAX_CLIPS;

  const clipIds = highlightReel?.clips.map((c) => c.id) ?? [];
  const highlightReelClipIds =
    highlightReel?.clips.map((c) => c.id) ||
    artifactIds.filter((a) => a.startsWith('Clip_')).map((a) => Number(a.slice(5)));

  return (
    <>
      <header ref={ref} className={cn('w-full', className)} {...rest}>
        <section className='tablet:flex-row tablet:justify-between flex flex-col'>
          <div className='flex-1 overflow-hidden'>
            <ArtifactBreadcrumbs artifactTitle={title || 'Unnamed highlight reel'} study={study} />
            <Title<{ account_id: number; highlight_reel_clip_ids: number[] }>
              className='mb-1'
              size='lg'
              bot={AIBots.HighlightReelTitle}
              context={{ account_id: account.id, highlight_reel_clip_ids: highlightReelClipIds ?? [] }}
              placeholder='Add title...'
              onSave={(value) => setTitle(value)}
              value={highlightReel?.title || undefined}
              disabled={clips.length === 0}
              tooltip='Add highlights to your reel to generate a title'
              fetchOnMount={!highlightReel?.title && clipIds.length > 0}
            />
            <input
              className='focus:outline-none h400 block w-full placeholder-gray-400'
              placeholder='Add description...'
              onChange={(e) => setSubtitle(e.target.value)}
              value={subtitle}
              {...blurOnEnter()}
            />
            <div className='max-w-sm'>
              <TagList
                studyId={studyId}
                tagIds={tagIds}
                onChange={setTagIds}
                className='focus:outline-none hover:bg-gray-50 flex items-center w-full h-10 px-4 mt-2 space-x-2 rounded'
                placement='bottom'
              />
            </div>
          </div>
          <div className='tablet:block tablet:mt-0 flex justify-around mt-4'>
            <Button onClick={onDiscard} link>
              {highlightReelToken ? 'Cancel' : 'Discard'}
            </Button>
            {(!highlightReelToken || !!highlightReel) && (
              <Button
                disabled={tooManyClips || !clips.length}
                onClick={onSave}
                className='whitespace-nowrap ml-1'
                loading={isLoading}
                primary
              >
                Save highlight reel
              </Button>
            )}
          </div>
        </section>
      </header>
      <DiscardModal isOpen={discardModalOpen} onConfirm={navigateBack} onClose={() => setDiscardModalOpen(false)} />
    </>
  );
});
