import React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, Loading, Modal, Text } from '@components/common';
import { useToaster } from '@components/stores/toaster';

import { failedUpdate, successUpdate } from './toasts';

export const ConfirmUnsubscribeModal: React.FC<{
  id: number;
  onCancel: () => void;
  onSuccess: () => void;
}> = ({ id, onCancel, onSuccess }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const showToast = useToaster();

  const [updateCandidate, { isSuccess, isError }] = api.useUpdateCandidateMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(successUpdate());
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(failedUpdate());
    }
  }, [isError]);

  function handleConfirm() {
    setLoading(true);
    updateCandidate({ id, opted_in: false });
  }
  return (
    <Modal
      title='Unsubscribe candidate'
      size='md'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button danger onClick={handleConfirm}>
            Confirm
          </Button>
        </>
      )}
    >
      {loading && <Loading absolute bg='white' />}
      <Text>Are you sure you want to mark this candidate as unsubscribed?</Text>
    </Modal>
  );
};
