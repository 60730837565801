import React, { SVGProps } from 'react';

export const CouponIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M13.5625 8.75V10.5C13.5625 10.7321 13.4703 10.9546 13.3062 11.1187C13.1421 11.2828 12.9196 11.375 12.6875 11.375H1.3125C1.08044 11.375 0.857876 11.2828 0.693782 11.1187C0.529687 10.9546 0.4375 10.7321 0.4375 10.5V8.75C0.901629 8.75 1.34675 8.56563 1.67494 8.23744C2.00313 7.90925 2.1875 7.46413 2.1875 7C2.1875 6.53587 2.00313 6.09075 1.67494 5.76256C1.34675 5.43437 0.901629 5.25 0.4375 5.25V3.5C0.4375 3.26794 0.529687 3.04538 0.693782 2.88128C0.857876 2.71719 1.08044 2.625 1.3125 2.625H12.6875C12.9196 2.625 13.1421 2.71719 13.3062 2.88128C13.4703 3.04538 13.5625 3.26794 13.5625 3.5V5.25C13.0984 5.25 12.6533 5.43437 12.3251 5.76256C11.9969 6.09075 11.8125 6.53587 11.8125 7C11.8125 7.46413 11.9969 7.90925 12.3251 8.23744C12.6533 8.56563 13.0984 8.75 13.5625 8.75Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.9375 5.25C3.9375 5.48206 4.02969 5.70462 4.19378 5.86872C4.35788 6.03281 4.58044 6.125 4.8125 6.125C5.04456 6.125 5.26712 6.03281 5.43122 5.86872C5.59531 5.70462 5.6875 5.48206 5.6875 5.25C5.6875 5.01794 5.59531 4.79538 5.43122 4.63128C5.26712 4.46719 5.04456 4.375 4.8125 4.375C4.58044 4.375 4.35788 4.46719 4.19378 4.63128C4.02969 4.79538 3.9375 5.01794 3.9375 5.25Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.75 8.75C8.75 8.98206 8.84219 9.20462 9.00628 9.36872C9.17038 9.53281 9.39294 9.625 9.625 9.625C9.85706 9.625 10.0796 9.53281 10.2437 9.36872C10.4078 9.20462 10.5 8.98206 10.5 8.75C10.5 8.51794 10.4078 8.29538 10.2437 8.13128C10.0796 7.96719 9.85706 7.875 9.625 7.875C9.39294 7.875 9.17038 7.96719 9.00628 8.13128C8.84219 8.29538 8.75 8.51794 8.75 8.75V8.75Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.1875 4.8125L4.8125 9.1875'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
