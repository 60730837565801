import cn from 'classnames';
import React, {
  ChangeEventHandler,
  FormEventHandler,
  forwardRef,
  HTMLAttributes,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState
} from 'react';
import mergeRefs from 'react-merge-refs';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import { AskAiSVG, ChatPromptSubmitSVG } from '@components/svgs';

interface Props extends Omit<HTMLAttributes<HTMLTextAreaElement>, 'onSubmit' | 'onChange'> {
  value?: string;
  isInvalid?: boolean;
  isLoading?: boolean;
  fillWithPlaceholder?: boolean;
  onSubmit?: (value: string) => void;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
}

export const ChatPrompt = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      onSubmit,
      onChange,
      value: initialValue,
      isInvalid,
      isLoading,
      fillWithPlaceholder,
      className,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const [value, setValue] = useState<string>(initialValue ?? '');
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleOnFormSubmit: FormEventHandler = (e) => {
      e.preventDefault();
      handleOnSubmit();
    };

    const handleOnSubmit = () => {
      if (value && !isLoading) {
        onSubmit?.(value);
      }
    };

    const handleOnKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleOnSubmit();
      } else if (e.key === 'Tab' && fillWithPlaceholder && placeholder && value === '') {
        e.preventDefault();
        setValue(placeholder);
        onChange?.(placeholder);
      }
    };

    const handleOnChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      const newValue = e.currentTarget.value;
      setValue(newValue);
      onChange?.(newValue);
    };

    useEffect(() => {
      setValue(initialValue ?? '');
    }, [initialValue]);

    return (
      <form className='w-full' onSubmit={handleOnFormSubmit}>
        <div className='relative w-full'>
          <div className='top-3 left-3 absolute p-1 text-indigo-600 rounded-full cursor-pointer'>
            <AskAiSVG className='w-4 h-4' />
          </div>

          {isLoading ? (
            <div
              aria-label='loading-bot-response'
              className='loading-ellipsis right-3 bottom-8 absolute w-6 h-6 text-2xl text-indigo-600'
            />
          ) : (
            <button
              type='submit'
              className={cn('right-3 absolute w-6 h-6 text-white rounded-full', {
                'bg-gray-400 cursor-not-allowed': !value,
                'bg-indigo-600': value
              })}
              style={{ bottom: '18px' }}
              disabled={!value || isLoading}
              aria-label='Submit prompt'
            >
              <ChatPromptSubmitSVG />
            </button>
          )}

          <TextareaAutosize
            ref={mergeRefs([textAreaRef, ref])}
            placeholder={placeholder}
            className={cn(
              'chat-prompt',
              'px-11 focus:ring-0 bg-white focus:border-gray-200 focus-visible:ring-0 w-full py-3 text-sm bg-transparent border border-gray-200 rounded-md shadow-md resize-none',
              {
                'border-red-600': isInvalid
              },
              className
            )}
            minRows={1}
            maxRows={6}
            value={value}
            onKeyDown={handleOnKeyDown}
            onChange={handleOnChange}
            {...rest}
          />
        </div>
      </form>
    );
  }
);
