import React from 'react';

import { Radio, Text } from '@components/common';

export const Radios: React.FC<AnswerInputProps<string>> = ({ options, value, onChange }) => {
  return (
    <div className='flex flex-col space-y-1'>
      {(options || []).map((option, i) => (
        <label className='flex items-center space-x-2' key={`radio-${i}`}>
          <Radio onChange={() => onChange(option)} value={option} checked={value === option} />

          <Text>{option || <i>none</i>}</Text>
        </label>
      ))}
    </div>
  );
};
