import { useEffect, useState } from 'react';
import { api } from '@api/reduxApi';
import { useDebouncedCallback } from 'use-debounce';
import { useToaster } from '@stores/toaster';

type UseRecruitingRequest = (params: {
  studyId: number;
  requestId?: number;
  skip?: boolean;
  handleUpdatedRequest?: (v: ExternalCandidatesRequest) => void;
}) => {
  request: ExternalCandidatesRequest | undefined;
  handleChange: (payload: ExternalCandidatesRequest) => void;
  requestInitialized: boolean;
  isUpdating: boolean;
  isLoading: boolean;
};

export const useRecruitingRequest: UseRecruitingRequest = ({ handleUpdatedRequest, requestId, studyId, skip }) => {
  const [requestInitialized, setRequestInitialized] = useState<boolean>(false);
  const [request, setRequest] = useState<ExternalCandidatesRequest>();

  const showToast = useToaster();

  const { data: externalCandidatesRequests, isLoading: requestsLoading } = api.useGetExternalCandidatesRequestsQuery(
    studyId,
    { skip: skip || !!requestId }
  );

  const { data: externalCandidatesRequest, isLoading: requestLoading } = api.useGetExternalCandidatesRequestQuery(
    {
      studyId: studyId,
      externalCandidatesRequestId: requestId as number
    },
    { skip: skip || !requestId }
  );

  const [
    createExternalCandidatesRequest,
    { data: createdRequest, isLoading: createLoading, isSuccess: createSuccess, isError: createError }
  ] = api.useCreateExternalCandidatesRequestMutation();

  const [
    updateExternalCandidatesRequest,
    { data: updatedRequest, isLoading: updateLoading, isSuccess: updateSuccess, isError: updateError }
  ] = api.useUpdateExternalCandidatesRequestMutation();

  useEffect(() => {
    if (externalCandidatesRequests) {
      setRequest(externalCandidatesRequests[0]);
      setRequestInitialized(true);
    }
  }, [externalCandidatesRequests]);

  useEffect(() => {
    if (externalCandidatesRequest) {
      setRequest(externalCandidatesRequest);
      setRequestInitialized(true);
    }
  }, [externalCandidatesRequest]);

  useEffect(() => {
    if (createSuccess) {
      setRequest(createdRequest);
      handleUpdatedRequest?.(createdRequest as ExternalCandidatesRequest);
    }
  }, [createSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      setRequest(updatedRequest);
      handleUpdatedRequest?.(updatedRequest as ExternalCandidatesRequest);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (createError || updateError) {
      showToast({
        heading: 'Error submitting request.',
        text: 'Please try again.',
        icon: 'error'
      });
    }
  }, [createError, updateError]);

  const handleChange = (payload: ExternalCandidatesRequest) => {
    if (payload.id) {
      updateExternalCandidatesRequest(payload);
    } else {
      createExternalCandidatesRequest(payload);
    }
  };

  return {
    request,
    handleChange,
    requestInitialized,
    isLoading: requestsLoading || requestLoading,
    isUpdating: createLoading || updateLoading
  };
};
