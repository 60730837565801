import * as React from 'react';

export const artifactType: React.FC<any> = (props) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.99326 5.91189C10.046 5.99765 10.1393 6.05 10.24 6.05H12.76C12.8607 6.05 12.954 5.99765 13.0067 5.91189C13.0595 5.82617 13.0643 5.71916 13.0195 5.62912L11.7594 3.10013C11.7084 2.99764 11.6012 2.95 11.5 2.95C11.3988 2.95 11.2916 2.99764 11.2406 3.10013L9.98054 5.62912C9.93568 5.71916 9.94051 5.82617 9.99326 5.91189ZM10.7091 5.46829L11.5 3.88091L12.2909 5.46829H10.7091Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.1'
    />
    <path
      d='M5.55496 10.035L4.5 11.0899L3.44504 10.035C3.33177 9.92168 3.14823 9.92168 3.03496 10.035C2.92168 10.1482 2.92168 10.3318 3.03496 10.445L4.08991 11.5L3.03496 12.555C2.92168 12.6682 2.92168 12.8518 3.03496 12.965C3.09158 13.0217 3.16586 13.05 3.24 13.05C3.31414 13.05 3.38842 13.0217 3.44504 12.965L4.5 11.9101L5.55496 12.965C5.61158 13.0217 5.68586 13.05 5.76 13.05C5.83414 13.05 5.90842 13.0217 5.96504 12.965C6.07832 12.8518 6.07832 12.6682 5.96504 12.555L4.91009 11.5L5.96504 10.445C6.07832 10.3318 6.07832 10.1482 5.96504 10.035C5.85177 9.92168 5.66823 9.92168 5.55496 10.035Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.1'
    />
    <path
      d='M11.5 9.95C10.6453 9.95 9.95 10.6453 9.95 11.5C9.95 12.3547 10.6453 13.05 11.5 13.05C12.3547 13.05 13.05 12.3547 13.05 11.5C13.05 10.6453 12.3547 9.95 11.5 9.95ZM11.5 12.47C10.9652 12.47 10.53 12.0349 10.53 11.5C10.53 10.9651 10.9652 10.53 11.5 10.53C12.0348 10.53 12.47 10.9651 12.47 11.5C12.47 12.0349 12.0348 12.47 11.5 12.47Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.1'
    />
    <path
      d='M3.24 6.05H5.76C5.92014 6.05 6.05 5.92029 6.05 5.76V3.24C6.05 3.07971 5.92014 2.95 5.76 2.95H3.24C3.07986 2.95 2.95 3.07971 2.95 3.24V5.76C2.95 5.92029 3.07986 6.05 3.24 6.05ZM3.53 3.53H5.47V5.47H3.53V3.53Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.1'
    />
    <path
      d='M2.74084 15.05H13.259C14.2467 15.05 15.05 14.2467 15.05 13.259V2.74098C15.05 1.75332 14.2467 0.95 13.259 0.95H2.74084C1.75332 0.95 0.95 1.75333 0.95 2.74098V13.259C0.95 14.2467 1.75332 15.05 2.74084 15.05ZM1.61 13.259V8.33H7.67V14.39H2.74084C2.11741 14.39 1.61 13.8825 1.61 13.259ZM13.259 14.39H8.33V8.33H14.39V13.259C14.39 13.8825 13.8826 14.39 13.259 14.39ZM14.39 2.74098V7.67H8.33V1.61H13.259C13.8826 1.61 14.39 2.11753 14.39 2.74098ZM2.74084 1.61H7.67V7.67H1.61V2.74098C1.61 2.11753 2.11741 1.61 2.74084 1.61Z'
      fill='currentColor'
      stroke='currentColor'
      strokeWidth='0.1'
    />
  </svg>
);
