import React, { SVGProps } from 'react';

export const PeopleGroupSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1183_351)'>
      <path
        d='M10.5 1.6761C9.50954 0.874715 8.27406 0.4375 7 0.4375C5.72594 0.4375 4.49046 0.874715 3.5 1.6761'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.95825 3.78475C5.5361 3.31744 6.25676 3.0625 6.99992 3.0625C7.74308 3.0625 8.46374 3.31744 9.04159 3.78475'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 8.09375C0.4375 8.49986 0.598828 8.88934 0.885993 9.17651C1.17316 9.46367 1.56264 9.625 1.96875 9.625C2.37486 9.625 2.76434 9.46367 3.05151 9.17651C3.33867 8.88934 3.5 8.49986 3.5 8.09375C3.5 7.68764 3.33867 7.29816 3.05151 7.01099C2.76434 6.72383 2.37486 6.5625 1.96875 6.5625C1.56264 6.5625 1.17316 6.72383 0.885993 7.01099C0.598828 7.29816 0.4375 7.68764 0.4375 8.09375V8.09375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.38317 11.6571C4.22847 11.2956 3.99457 10.9736 3.69872 10.7146C3.40288 10.4557 3.05265 10.2665 2.6739 10.1611C2.29515 10.0557 1.89755 10.0366 1.51047 10.1055C1.12339 10.1743 0.756706 10.3292 0.4375 10.5587'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 8.09375C10.5 8.29484 10.5396 8.49395 10.6166 8.67973C10.6935 8.86551 10.8063 9.03432 10.9485 9.17651C11.0907 9.3187 11.2595 9.43149 11.4453 9.50844C11.631 9.58539 11.8302 9.625 12.0312 9.625C12.2323 9.625 12.4315 9.58539 12.6172 9.50844C12.803 9.43149 12.9718 9.3187 13.114 9.17651C13.2562 9.03432 13.369 8.86551 13.4459 8.67973C13.5229 8.49395 13.5625 8.29484 13.5625 8.09375C13.5625 7.89266 13.5229 7.69355 13.4459 7.50777C13.369 7.32199 13.2562 7.15318 13.114 7.01099C12.9718 6.8688 12.803 6.75601 12.6172 6.67906C12.4315 6.60211 12.2323 6.5625 12.0312 6.5625C11.8302 6.5625 11.631 6.60211 11.4453 6.67906C11.2595 6.75601 11.0907 6.8688 10.9485 7.01099C10.8063 7.15318 10.6935 7.32199 10.6166 7.50777C10.5396 7.69355 10.5 7.89266 10.5 8.09375V8.09375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.61694 11.6571C9.77164 11.2956 10.0055 10.9736 10.3014 10.7146C10.5972 10.4557 10.9475 10.2665 11.3262 10.1611C11.705 10.0557 12.1026 10.0366 12.4896 10.1055C12.8767 10.1743 13.2434 10.3292 13.5626 10.5587'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.03125 7.65625C5.03125 8.17839 5.23867 8.67915 5.60788 9.04837C5.9771 9.41758 6.47785 9.625 7 9.625C7.52214 9.625 8.0229 9.41758 8.39212 9.04837C8.76133 8.67915 8.96875 8.17839 8.96875 7.65625C8.96875 7.1341 8.76133 6.63335 8.39212 6.26413C8.0229 5.89492 7.52214 5.6875 7 5.6875C6.47785 5.6875 5.9771 5.89492 5.60788 6.26413C5.23867 6.63335 5.03125 7.1341 5.03125 7.65625V7.65625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.5 13.5628C10.386 12.7154 9.96848 11.938 9.32481 11.3751C8.68114 10.8122 7.85509 10.502 7 10.502C6.14491 10.502 5.31886 10.8122 4.67519 11.3751C4.03152 11.938 3.61395 12.7154 3.5 13.5628'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1183_351'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
