import React, { SVGProps } from 'react';

export const UploadTimeSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1406_44471)'>
      <path
        d='M10.0625 13.5625C11.9955 13.5625 13.5625 11.9955 13.5625 10.0625C13.5625 8.1295 11.9955 6.5625 10.0625 6.5625C8.1295 6.5625 6.5625 8.1295 6.5625 10.0625C6.5625 11.9955 8.1295 13.5625 10.0625 13.5625Z'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.6095 10.0626H10.0625V8.51562'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.8125 11.8125H1.3125C1.08044 11.8125 0.857876 11.7203 0.693782 11.5562C0.529687 11.3921 0.4375 11.1696 0.4375 10.9375V1.3125C0.4375 1.08044 0.529687 0.857876 0.693782 0.693782C0.857876 0.529687 1.08044 0.4375 1.3125 0.4375H7.51275C7.74465 0.43755 7.96705 0.529656 8.13108 0.693583L9.80642 2.36892C9.97034 2.53295 10.0625 2.75535 10.0625 2.98725V4.8125'
        stroke='#6B7280'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1406_44471'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
