import React from 'react';

import { UrnProps } from '../types';

export const Candidate: React.FC<UrnProps> = ({ children, id }) => {
  const href = `/candidates/${id}`;

  return (
    <a className='hover:underline text-blue-500' href={href} target='_blank'>
      {children}
    </a>
  );
};
