import { AlertKey } from './types';

export const getAlerts = (params: {
  study: Study;
  slots: StudySlotsBalance;
  canFund: boolean;
}): Record<AlertKey, boolean> => {
  const { study, slots, canFund } = params;
  const active = study.state === 'active';
  const tremendous = study.incentive_method === 'tremendous';
  const { target, limit_reached, completed, enough_slots } = slots;
  const filled = active && target > 0 && limit_reached && completed;
  const has_calendar = study.is_nylas && !study.focus_group;

  return {
    batching_on: !filled && study.batch_on && !!study.next_batch_at,
    must_fund: active && tremendous && study.funding.usd_outstanding > 0,
    filled,
    cal_slots: Boolean(has_calendar && active && !limit_reached && !enough_slots),
    good_cal: Boolean(has_calendar && active && !limit_reached && enough_slots),
    pending_funding: tremendous && study.state === 'pending' && !canFund,
    funding_required: tremendous && study.state === 'pending' && canFund && study.funding.usd_outstanding > 0,
    sample: !!study.sample
  };
};
