import React, { SVGProps } from 'react';

export const SurveyIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.69683 12.4242L6.22217 12.7775L6.5755 10.3029L12.9395 3.93885C13.2208 3.65755 13.6023 3.49951 14.0002 3.49951C14.398 3.49951 14.7795 3.65755 15.0608 3.93885C15.3421 4.22016 15.5002 4.60169 15.5002 4.99952C15.5002 5.39735 15.3421 5.77888 15.0608 6.06019L8.69683 12.4242Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.5 0.499512H8.5V2.49951H3.5V0.499512Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.5 1.49951H10.5C10.7652 1.49951 11.0196 1.60487 11.2071 1.7924C11.3946 1.97994 11.5 2.2343 11.5 2.49951'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.5 12.4995V14.4995C11.5 14.7647 11.3946 15.0191 11.2071 15.2066C11.0196 15.3942 10.7652 15.4995 10.5 15.4995H1.5C1.23478 15.4995 0.98043 15.3942 0.792893 15.2066C0.605357 15.0191 0.5 14.7647 0.5 14.4995V2.49951C0.5 2.2343 0.605357 1.97994 0.792893 1.7924C0.98043 1.60487 1.23478 1.49951 1.5 1.49951H3.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M3.5 5.49951H8.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.5 8.49951H5.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
