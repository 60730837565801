import * as React from 'react';
import { useEffect } from 'react';

import { Button, Modal, ModalHeading, Text } from '@components/common';
import { track } from '@components/tracking';
import { useToaster } from '@stores/toaster';

import { useDeleteTeamMutation } from '../api';
import * as toasts from '../toasts';

type Props = {
  team: Team;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteTeamModal: React.FC<Props> = ({ team, open, onClose, onSuccess }) => {
  const showToast = useToaster();

  const [deleteTeam, { isLoading, isSuccess, isError }] = useDeleteTeamMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDeleteTeam());
      track('deleted_team');
      onSuccess();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedDeleteTeam());
    }
  }, [isError]);

  useEffect(() => {
    if (open) {
      track('viewed_delete_team_modal');
    }
  }, [open]);

  if (!open) {
    return null;
  }

  const handleSubmit = () => {
    deleteTeam({ id: team.id });
  };

  return (
    <Modal
      onClose={onClose}
      size='md'
      renderFooter={() => (
        <div className='flex justify-end space-x-4'>
          <Button disabled={isLoading} onClick={onClose}>
            Cancel & don’t delete
          </Button>
          <Button loading={isLoading} disabled={isLoading} danger onClick={handleSubmit}>
            Delete team
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-6'>Delete {team.name}?</ModalHeading>

      <Text h='400' mb='2'>
        By deleting {team.name}, you understand that:
      </Text>
      <ul className='pl-4 list-disc'>
        <li>
          <Text h='400'>Any studies in the team will be removed from the team, but remain in the workspace.</Text>
        </li>
        <li>
          <Text h='400'>
            Any candidates who’s contact access was restricted to the team, will no longer be restricted.
          </Text>
        </li>
      </ul>
    </Modal>
  );
};
