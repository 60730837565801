import React, { useState } from 'react';
import Linkify from 'react-linkify';

import { InputProps } from '../types';
import { FIGMA_MATCHER, Task } from './TaskInput';

const FigmaLink: React.FC<{ url: string }> = ({ url }) => {
  const [open, setOpen] = useState<boolean | null>();
  return (
    <>
      <a className='cursor-pointer' onClick={() => setOpen(true)}>
        Figma Prototype
      </a>
      {open && <Task url={url} onClose={() => setOpen(null)} />}
    </>
  );
};

function componentDecorator(href: string, text: string, i: number) {
  const figmaMatcher = href.match(FIGMA_MATCHER);
  if (figmaMatcher) {
    return <FigmaLink url={href} />;
  } else {
    return (
      <a href={href} target='_blank'>
        {text}
      </a>
    );
  }
}

export const InfoInput: React.FC<InputProps> = (props) => {
  const {
    field: { id, helper }
  } = props;

  return (
    <div className='text-custom-brand mt-2 mb-6 text-sm'>
      <Linkify componentDecorator={componentDecorator}>
        {helper.split('\n').map((p, i) => (
          <p className='min-h-5' key={i}>
            {p}
          </p>
        ))}
      </Linkify>
    </div>
  );
};
