import { Toast } from '@stores/toaster';

export const failedDeleteClip = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that highlight. Please try again later.'
});

export const successDeleteClip = (title: string): Toast => ({
  heading: 'Highlight deleted',
  icon: 'success',
  text: `The highlight “${title}” was deleted`
});
