import React from 'react';
import ReactDOM from 'react-dom';

interface Args<P extends unknown, RP extends unknown> {
  component: React.ComponentType<P>;
  id: string;
  props: (serverProps: RP) => P;
}

export const render = <P extends unknown, RP extends unknown>({ component: Component, id, props }: Args<P, RP>) => {
  const container = document.getElementById(id);

  if (!container) {
    return;
  }

  let componentProps: P | null = null;

  if (container.dataset.props) {
    componentProps = props(JSON.parse(container.dataset.props));
  }

  if (componentProps) {
    ReactDOM.render(<Component {...componentProps} />, container);
  }
};
