import { api } from 'api/reduxApi';
import {
  BetaFlag,
  Button,
  Input,
  Modal,
  ModalHeading,
  RadioGroup,
  Select,
  SlideOut,
  Text,
  Tooltip
} from 'components/common';
import { ATTR_TYPES } from 'components/config';
import { CancelEditModal } from 'components/shared/CancelEditModal/CancelEditModal';
import { RestrictedAction } from 'components/shared/RestrictedAction';
import { useToaster } from 'components/stores/toaster';
import { noop } from 'components/utils';
import React, { useState } from 'react';

import { useGetEnabledIntegrationsQuery } from '@components/CandidatesApp/CandidatesIndex/api';
import { humanize } from '@components/utils';
import { useFeature } from '@hooks/useFeature';

import * as toasts from '../toasts';
import { AttrScope } from './AccountAttrsIndex';

interface Props {
  mode: 'edit' | 'create';
  onClose: () => void;
  attr?: Attr_;
  scope: AttrScope;
}

// What is the difference between this and Attr_ ?
type Attribute = {
  name: string;
  label: string;
  attr_type: Attr_['attr_type'] | undefined;
  is_pii: boolean;
  unique: boolean;
  is_health: boolean;
  set_by_url_param: boolean;
  description?: string;
  integration_provider?: string;
  integration_provider_object?: string;
  integration_field_name?: string;
  custom_opt_out: boolean;
};

const UNIQUEABLE_TYPES: Attr_['attr_type'][] = ['text', 'number', 'url'] as Attr_['attr_type'][];

const updateMutations = {
  study: api.useUpdateStudyAttrMutation,
  candidate: api.useUpdateCandidateAttrMutation
};

const deleteMutations = {
  study: api.useDeleteStudyAttrMutation,
  candidate: api.useDeleteCandidateAttrMutation
};

const createMutations = {
  study: api.useCreateStudyAttrMutation,
  candidate: api.useCreateCandidateAttrMutation
};

export const AttrSlideout: React.FC<Props> = ({ mode, onClose, attr, scope }) => {
  const [updateAttr, { isLoading: updating }] = updateMutations[scope]();
  const [deleteAttr, { isLoading: deleting }] = deleteMutations[scope]();
  const [createAttr, { isLoading: creating }] = createMutations[scope]();

  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [attribute, setAttribute] = useState<Attribute>({
    label: attr?.label || '',
    name: attr?.name || '',
    unique: attr?.unique || false,
    description: attr?.description || '',
    attr_type: attr?.attr_type || ATTR_TYPES[0].value,
    is_pii: attr?.is_pii || false,
    is_health: attr?.is_health || false,
    set_by_url_param: attr?.set_by_url_param || false,
    integration_provider: attr?.integration_provider || '',
    integration_provider_object: attr?.integration_provider_object || '',
    integration_field_name: attr?.integration_field_name || '',
    custom_opt_out: attr?.custom_opt_out || false
  });

  const showToast = useToaster();

  const isLoading = updating || deleting || creating;

  const isUniqueable: boolean = !!attribute.attr_type && UNIQUEABLE_TYPES.includes(attribute.attr_type);

  const { data: integrations = [] } = useGetEnabledIntegrationsQuery();

  const hasCustomOptOutSalesforce = useFeature('custom_opt_out_salesforce');
  const hasUniqueCustomAttrs = useFeature('unique_custom_attrs');

  const onConfirm = async () => {
    try {
      if (mode === 'edit') {
        await updateAttr({ id: attr?.id, ...attribute }).unwrap();
      } else {
        await createAttr(attribute).unwrap();
      }

      showToast(mode === 'edit' ? toasts.successAttrUpdate() : toasts.successAttrCreation());
      setIsDirty(false);
      onClose();
    } catch {
      showToast(mode === 'edit' ? toasts.failedAttrUpdate() : toasts.failedAttrCreation());
    }
  };

  const onChange = (field, value) => {
    setIsDirty(true);
    setAttribute((prev) => ({ ...prev, [field]: value }));
  };

  const onCancel = () => {
    if (isDirty) {
      setCancelModal(true);
    } else {
      onClose();
    }
  };

  const onDelete = async () => {
    try {
      await deleteAttr({ id: attr?.id }).unwrap();

      showToast(toasts.successAttrDelete());
      setIsDirty(false);
      setDeleteModal(false);
      onClose();
    } catch (error) {
      showToast(toasts.failedAttrDelete());
    }
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <>
      <SlideOut
        onClose={onCancel}
        title={`${mode === 'edit' ? 'Edit' : 'Create'} ${scope} attribute`}
        renderFooter={() => (
          <div className='flex justify-between w-full'>
            <div className='flex space-x-4'>
              <Button className='mr-2' onClick={onCancel}>
                Cancel
              </Button>
              <Button disabled={isLoading} onClick={onConfirm} primary>
                Confirm
              </Button>
            </div>
            {mode === 'edit' && (
              <Button disabled={isLoading} icon='trash' danger className='mr-2' onClick={() => setDeleteModal(true)}>
                Delete
              </Button>
            )}
          </div>
        )}
      >
        <div className='px-6 py-4'>
          <Text className='mb-1' h='400' bold>
            Name
          </Text>
          <Input
            data-testid='attr-label-input'
            name='label'
            disabled={isLoading}
            value={attribute.label}
            onChange={(v) => {
              onChange('label', v);
            }}
            className='w-full mb-6'
          />
          {mode === 'edit' && (
            <>
              <Text className='mb-1' h='400' bold>
                Key
              </Text>
              <Input name='name' disabled value={attribute.name} onChange={noop} className='w-full mb-6' />
            </>
          )}
          <Text className='mb-1' h='400' bold>
            Attribute type
          </Text>
          <Select<Attr_['attr_type']>
            disabled={isLoading || mode === 'edit'}
            options={ATTR_TYPES}
            name='attr_type'
            className='mb-6'
            value={attribute.attr_type || ''}
            onChange={(v) => onChange('attr_type', v)}
          />
          <Text className='mb-1' h='400' bold>
            Description
          </Text>
          <Input
            name='description'
            data-testid='attr-description-input'
            disabled={isLoading}
            value={attribute.description}
            onChange={(v) => onChange('description', v)}
            className='w-full mb-6'
          />

          {integrations.length > 0 && (
            <>
              <Text className='mb-1' h='400' bold data-testid='attr-integration-provider-text'>
                Integration Provider
              </Text>
              <Select
                disabled={isLoading}
                options={integrations.map((i) => ({ value: i, label: humanize(i) }))}
                name='integration_provider'
                className='mb-6'
                value={attribute.integration_provider || ''}
                onChange={(v) => onChange('integration_provider', v)}
              />
            </>
          )}
          {attribute.integration_provider == 'salesforce' && (
            <div className='mb-5'>
              <Text className='mb-1' h='400' bold>
                Integration Field Name
              </Text>
              <Input
                name='integration_field_name'
                data-testid='attr-integration-field-name-input'
                disabled={isLoading}
                value={attribute.integration_field_name}
                onChange={(v) => onChange('integration_field_name', v)}
                className='w-full mb-6'
              />
            </div>
          )}
          {attribute.integration_provider == 'salesforce' && (
            <div className='mb-6'>
              <RadioGroup<string>
                selected={attribute.integration_provider_object || ''}
                onChange={(v) => onChange('integration_provider_object', v)}
                options={[
                  {
                    value: 'contact',
                    label: 'Contact'
                  },
                  {
                    value: 'account',
                    label: 'Account'
                  }
                ]}
              />
            </div>
          )}
          <div className='flex flex-col items-start space-y-3'>
            {attribute.integration_provider == 'salesforce' && hasCustomOptOutSalesforce && (
              <div className='flex items-start'>
                <Input
                  name='custom_opt_out'
                  data-testid='attr-custom-opt-out-input'
                  className='mt-1 mr-2'
                  disabled={isLoading}
                  type='checkbox'
                  checked={attribute.custom_opt_out}
                  onChange={() => onChange('custom_opt_out', !attribute.custom_opt_out)}
                />
                <label
                  onClick={() => onChange('custom_opt_out', !attribute.custom_opt_out)}
                  className='h500 cursor-pointer'
                  htmlFor='custom_opt_out'
                >
                  Is Custom Opt Out ({attribute.integration_provider})
                </label>
              </div>
            )}
            {hasUniqueCustomAttrs && (
              <div className='flex items-start'>
                <Input
                  name='unique'
                  data-testid='attr-unique-input'
                  className='mt-1 mr-2'
                  disabled={isLoading || !isUniqueable}
                  type='checkbox'
                  checked={attribute.unique && isUniqueable}
                  onChange={() => onChange('unique', !attribute.unique)}
                />
                <div className='flex items-center space-x-2'>
                  <label
                    onClick={() => onChange('unique', !attribute.unique)}
                    className={`h500 cursor-pointer ${isUniqueable ? '' : 'text-gray-500'}`}
                    htmlFor='unique'
                  >
                    Unique
                  </label>
                  <Tooltip>
                    Unique attributes can be used as identifier for matching candidates on import. Uniqueness is not
                    currently enforced.
                  </Tooltip>
                  <BetaFlag />
                </div>
              </div>
            )}
            <div className='flex items-start'>
              <Input
                name='is_pii'
                data-testid='attr-is-pii-input'
                className='mt-1 mr-2'
                disabled={isLoading}
                type='checkbox'
                checked={attribute.is_pii}
                onChange={() => onChange('is_pii', !attribute.is_pii)}
              />
              <div className='flex items-center space-x-2'>
                <label
                  onClick={() => onChange('is_pii', !attribute.is_pii)}
                  className='h500 cursor-pointer'
                  htmlFor='is_pii'
                >
                  Personally identifiable information (PII)
                </label>
                <Tooltip>
                  Personally identifiable information (PII) is information that can be used on its own or with other
                  information to identify, contact, or locate a single person, or to identify an individual in context.
                </Tooltip>
              </div>
            </div>
            <RestrictedAction feature='hipaa' autoShow={false}>
              {({ may, show }) => (
                <div className='flex items-start'>
                  <Input
                    name='is_health'
                    data-testid='attr-is-health-input'
                    className='mt-1 mr-2'
                    disabled={isLoading}
                    type='checkbox'
                    checked={attribute.is_health}
                    onClick={() => !may && show?.()}
                    onChange={() => may && onChange('is_health', !attribute.is_health)}
                  />
                  <div className='flex items-center space-x-2'>
                    <label
                      onClick={() => (may ? onChange('is_health', !attribute.is_health) : show?.())}
                      className={'h500 cursor-pointer ' + (may ? '' : 'text-gray-500')}
                      htmlFor={may ? 'is_health' : undefined}
                    >
                      Health Information
                      {!may && <span className='h400 pl-2 italic'>(requires HIPAA add-on)</span>}
                    </label>
                    <Tooltip>
                      Health information is any information that relates to the past, present, or future physical or
                      mental health or condition of an individual.
                    </Tooltip>
                  </div>
                </div>
              )}
            </RestrictedAction>
            <div className='flex items-start'>
              <Input
                name='set_by_url_param'
                data-testid='attr-set-by-url-param-input'
                className='mt-1 mr-2'
                disabled={isLoading}
                type='checkbox'
                checked={attribute.set_by_url_param}
                onChange={() => onChange('set_by_url_param', !attribute.set_by_url_param)}
              />
              <div className='flex items-center space-x-2'>
                <label
                  onClick={() => onChange('set_by_url_param', !attribute.set_by_url_param)}
                  className='h500 cursor-pointer'
                  htmlFor='set_by_url_param'
                >
                  Can be set via a url parameter
                </label>
                <Tooltip>
                  This attribute can be set via a url parameter on study invite links. For example, if the attribute is
                  called <code>source</code>, the url parameter could be appended with <code>?source=facebook</code>.
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </SlideOut>
      <CancelEditModal
        cancelModal={cancelModal}
        onClose={() => setCancelModal(false)}
        setEdit={onClose}
        discardMessage="Attribute wasn't updated"
      />

      {deleteModal && (
        <Modal
          icon='danger'
          onClose={isLoading ? undefined : () => setDeleteModal(false)}
          renderFooter={() => (
            <div className='space-x-6'>
              <Button disabled={isLoading} onClick={onDelete} primary>
                Confirm
              </Button>
              <Button disabled={isLoading} onClick={onClose}>
                Cancel
              </Button>
            </div>
          )}
        >
          <ModalHeading className='mb-2'>Delete attribute</ModalHeading>
          <Text>Are you sure you want to permanently delete the attribute?</Text>
        </Modal>
      )}
    </>
  );
};
