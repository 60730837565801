import { FilterDefinition, Operator } from '../types';

export const getRelevantOperators = (def: FilterDefinition<any>): Operator[] => {
  if (def.operators) {
    return def.operators;
  } else {
    return getDefaultOperators(def);
  }
};
export const getDefaultOperators = (def: FilterDefinition<any>): Operator[] => {
  switch (def.type) {
    case 'tag':
      return ['includes_all', 'includes_any'];
    case 'participated_in_studies':
      return ['includes_all', 'includes_any', 'includes_none'];
    case 'team_members':
    case 'guide_status':
    case 'incentive_status':
    case 'artifact_type':
    case 'studies':
      return ['includes_any'];
    case 'eligibility':
    case 'boolean':
      return ['is', 'is_not', 'is_blank'];
    case 'text':
      return ['includes_any', 'includes_none', 'is_blank', 'is_present', 'contains'];
    case 'free_text':
      return ['is', 'is_not', 'contains', 'is_blank', 'is_present'];
    case 'date':
      return ['is', 'is_not', 'is_greater', 'is_lesser', 'is_between', 'is_present', 'is_blank'];
    case 'number':
      return ['is', 'is_not', 'is_greater', 'is_lesser', 'is_between'];
    case 'multiple_choice':
      return ['includes_any', 'includes_none', 'includes_all', 'is_blank', 'is_present'];
    default:
      return ['is'];
  }
};
