import React, { FC, SVGProps } from 'react';

export const PromoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2.827 8.46652L3.08833 7.78111L2.47816 6.18044C2.43436 6.06498 2.43129 5.93803 2.46948 5.82059C2.50767 5.70316 2.58483 5.60229 2.68816 5.53469L4.122 4.59844L4.56941 2.94527C4.60174 2.82605 4.67387 2.72147 4.77382 2.64889C4.87377 2.57631 4.99554 2.5401 5.11891 2.54627L6.82925 2.63319L8.16275 1.55694C8.25899 1.47933 8.3789 1.43701 8.50254 1.43701C8.62618 1.43701 8.74609 1.47933 8.84233 1.55694L10.1758 2.63319L11.8862 2.54802C12.0095 2.54185 12.1313 2.57806 12.2313 2.65064C12.3312 2.72322 12.4033 2.8278 12.4357 2.94702L12.8831 4.60019L14.3169 5.53644C14.4203 5.60397 14.4976 5.70483 14.5358 5.82229C14.574 5.93975 14.5708 6.06674 14.5269 6.18219L13.9173 7.78286L14.5269 9.38294C14.5709 9.49847 14.574 9.62555 14.5358 9.74311C14.4976 9.86067 14.4204 9.96164 14.3169 10.0293L12.8831 10.9626L12.4333 12.6164C12.4012 12.7357 12.3292 12.8405 12.2292 12.9132C12.1292 12.9859 12.0073 13.0222 11.8838 13.0159L10.1735 12.9308L8.84 14.0047'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.4375 12.5317L2.4035 14.0537'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.77966 5.75244L10.4512 11.5368'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2581 11.232L2.21042 13.7496L1.63058 12.8361L6.97275 6.05664L10.2581 11.232Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.94933 13.2057L4.46033 13.9908C4.54483 14.1293 4.65674 14.2491 4.7892 14.3428C4.92166 14.4365 5.07188 14.5022 5.23063 14.5357C5.38938 14.5692 5.55331 14.57 5.71235 14.5379C5.87139 14.5058 6.0222 14.4415 6.1555 14.349C6.42802 14.1529 6.61528 13.86 6.67893 13.5303C6.74259 13.2007 6.67783 12.8591 6.49792 12.5757L6.40283 12.438'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
