import * as React from 'react';

import { DropdownLink, Text } from '@components/common';
import { SortBySVG } from '@components/svgs';
import { PopUp, usePopUp } from '@hooks/usePopUp';

type Props = {
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
};
export const SortBy: React.FC<Props> = ({ options, value, onChange }) => {
  const { open, togglePopUp, ref } = usePopUp();

  const displayLabel = value && options.find((option) => option.value === value)?.label;

  return (
    <div className='relative' ref={ref}>
      <button
        name='sort'
        aria-label='sort'
        className='flex items-center rounded-md border-gray-200 border bg-white py-1.5 px-2 hover:border-indigo-600 hover:text-indigo-600'
        onClick={togglePopUp}
      >
        <SortBySVG className='mr-2' />
        <Text h='400'>{displayLabel || 'Sort by'}</Text>
      </button>
      <PopUp open={open} className='right-0'>
        <div className='px-4 py-2 mt-2 bg-white rounded shadow-lg'>
          <Text h='200' uppercase mb='2'>
            Sort by
          </Text>
          {options.map((option) => (
            <DropdownLink
              key={option.value}
              className='h400'
              onClick={() => {
                onChange(option.value);
                togglePopUp();
              }}
            >
              {option.label}
            </DropdownLink>
          ))}
        </div>
      </PopUp>
    </div>
  );
};
