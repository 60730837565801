import * as React from 'react';

import { Input, Text, AvatarFromId } from '@components/common';
import { CloseIcon } from '@components/common/Modal';
import { UserSelect } from '@components/shared/UserSelect';
import { uniq, without } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePopUp } from '@hooks/usePopUp';
import { TEAM_COLORS } from '../constants';
import * as icons from './icons';
import { Grid } from '@components/common/Grid';
import { CheckMarkSVG } from '@components/svgs';

export type TeamFormErrors = {
  name?: 'required';
};

type Props = {
  errors: TeamFormErrors;
  name: string;
  onChangeName: (name: string) => void;
  userIds: number[];
  onChangeUserIds: (userIds: number[]) => void;
  icon: string | null;
  setIcon: (icon: string | null) => void;
  setColor: (color: string) => void;
  color: string;
};

export const TeamForm: React.FC<Props> = ({
  errors,
  icon,
  setIcon,
  setColor,
  color,
  name,
  onChangeName,
  userIds,
  onChangeUserIds
}) => {
  const SelectedIcon = icons[icon || 'AATeamMembers'];

  const {
    account: { team }
  } = useAccount();

  const { PopUp, togglePopUp, open, ref } = usePopUp();

  return (
    <div>
      <fieldset className='mb-6'>
        <Text bold mb='1'>
          Name
        </Text>
        <Text h='400' mb='1' color='gray-500'>
          We suggest using how teams are organized at your company.
        </Text>
        <div className='flex items-center'>
          <div className='relative flex items-center' ref={ref}>
            <div
              data-testid='icon-picker'
              className='flex items-center justify-center w-10 h-10 mr-2 bg-white border border-gray-200 rounded-md cursor-pointer'
              onClick={togglePopUp}
            >
              <div className='relative w-5 h-5 overflow-hidden rounded-sm'>
                <div className='opacity-10 w-full h-full rounded-sm' style={{ backgroundColor: color }} />
                <SelectedIcon className='absolute inset-0 z-10 top-0.5 left-0.5 flex-shrink-0' style={{ color }} />
              </div>
            </div>
            <PopUp open={open} className='top-12'>
              <div className='flex flex-col p-4 bg-white rounded-md shadow-lg'>
                <div className='flex items-center pb-3 space-x-6 border-b border-gray-200'>
                  {TEAM_COLORS.map((current) => (
                    <div
                      key={current}
                      data-testid={`color-${current}`}
                      style={{ backgroundColor: current }}
                      onClick={() => setColor(current)}
                      className='flex items-center justify-center w-6 h-6 rounded-full cursor-pointer'
                    >
                      {color === current && <CheckMarkSVG width={16} height={16} className='text-white' />}
                    </div>
                  ))}
                </div>

                <Grid gap={2} columns={15} className='mt-3'>
                  {Object.values(icons).map((Icon, i) => (
                    <Icon
                      onClick={() => setIcon(Object.keys(icons)[i])}
                      key={i}
                      data-testid={`icon-${Object.keys(icons)[i]}`}
                      name={Object.keys(icons)[i]}
                      className='hover:opacity-50 cursor-pointer'
                      style={{ color }}
                    />
                  ))}
                </Grid>
              </div>
            </PopUp>
          </div>
          <Input
            error={!!errors.name}
            data-testid='team-name'
            className='w-full h-10'
            name='name'
            ariaLabel='name'
            value={name}
            onChange={onChangeName}
            placeholder='Enter name…'
          />
        </div>
        {errors.name && (
          <Text color='red-600' h='200' className='mt-1'>
            Please enter a Team name. Must be at least 1 character long.
          </Text>
        )}
      </fieldset>

      <fieldset className='mb-6'>
        <Text bold mb='1'>
          Members
        </Text>
        <Text h='400' mb='1' color='gray-500'>
          Add members who do research for this team.
        </Text>
        <UserSelect users={team} resetOnSelect onSelect={(id) => onChangeUserIds(uniq([...userIds, id]))} />
        <div className='overflow-auto max-h-72'>
          {userIds.map((id) => (
            <div key={id} className='flex items-center py-2 my-1 space-x-4'>
              <AvatarFromId userId={id} />
              <Text h='400' className='flex-1'>
                {team.find((u) => u.id === id)?.name}
              </Text>
              <button
                className='hover:bg-gray-100 hover:text-indigo-600 p-1 rounded-full'
                name='close'
                aria-label='close'
                onClick={() => onChangeUserIds(without(userIds, id))}
              >
                <CloseIcon />
              </button>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};
