import Masonry from 'react-masonry-css';
import React from 'react';
import cn from 'classnames';

const DEFAULT_BREAKPOINTS = {
  480: 1,
  768: 2,
  1024: 3,
  default: 4
};

export const GridGalleryBlock: React.FC<{ className?: string }> = ({ children, className }) => {
  return <div className={cn('mb-4 w-full', className)}>{children}</div>;
};

interface Props {
  className?: string;
  breakpointCols?: number | { default: number; [key: number]: number } | { [key: number]: number };
  columnClassName?: string;
}

export const GridGallery: React.FC<Props> = ({
  children,
  breakpointCols = DEFAULT_BREAKPOINTS,
  columnClassName,
  className
}) => {
  return (
    <Masonry
      breakpointCols={breakpointCols}
      className={cn('-pl-4 flex w-auto', className)}
      columnClassName={cn('bg-clip-padding pl-4', columnClassName)}
    >
      {children}
    </Masonry>
  );
};
