import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Input, Loading, Text } from '@components/common';
import { Avatar, AvatarFromId, AvatarInitials } from '@components/common/Avatar';
import { Item, Menu } from '@components/common/Menu';
import { BlockIcon, getBlockLabel, parseScreenerFieldType } from '@components/SurveyBuilder';
import { Tooltip } from '@components/shared/Tooltip';
import { secondsToTimestamp, toNormalForm } from '@components/utils';
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from '@tiptap/react';

import { useSearchCandidatesQuery } from '../api';
import { TranscriptOptions } from '../extensions';
import * as Icons from '../icons';
import { getTimestamp } from '../utils';
import { useSessionContext } from '@components/RepoSessionApp/SessionPage';
import { getAllNodesOfType } from '../helpers/queries';

type Speaker = TeamUser | Candidate;

const getSpeakerIdentifier = (speaker: TeamUser | Candidate) => `
  ${'role' in speaker ? 'user' : 'candidate'}-${speaker.id}
`;

export const TranscriptView = ({ editor, node, extension, getPos }: NodeViewProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const startTimestamp = getTimestamp(node, editor.schema.marks.transcript_word);
  const speakerAttrs = node.attrs.speaker;
  const blockAttrs = node.attrs.block;
  const extensionOptions = extension.options as TranscriptOptions;

  const [query, setQuery] = useState<string>('');
  const [value, setValue] = useState('');

  const { data: candidates, isLoading } = useSearchCandidatesQuery({ q: query, size: 30 }, { skip: !query });
  const { videoPlayerRef } = useSessionContext();
  const { callback } = useDebouncedCallback((v) => setQuery(v), 300);

  const previousNode = getAllNodesOfType(editor.state, 'transcript', ({ pos }) => pos < getPos()).pop();

  const speakers: Speaker[] = useMemo(() => {
    let list: Speaker[] = [];

    if (extensionOptions.defaultSpeakers && extensionOptions.defaultSpeakers.length) {
      list.push(...extensionOptions.defaultSpeakers);
    }

    list = list.filter(
      (speaker) =>
        toNormalForm(speaker.name || '')
          .toLowerCase()
          .includes(query.toLowerCase()) || (speaker.email || '').toLowerCase().includes(query.toLowerCase())
    );

    const listIDs = list.map((u) => u.id);

    if (candidates && candidates.length) {
      list.push(...candidates.filter(({ id }) => !listIDs.includes(id)));
    }

    return list;
  }, [candidates, extensionOptions.defaultSpeakers]);

  const onTimestampClick = () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.setCurrentTime(startTimestamp);
      videoPlayerRef.current.play();
    }
  };

  const onInputChange = (value: string) => {
    callback(value);
    setValue(value);
  };

  const onSpeakerSelect = (id: string) => {
    if (id === 'source') {
      extensionOptions.onSpeakerChange?.({
        id: null,
        name: `Speaker ${speakerAttrs.source.toUpperCase()}`,
        img: null,
        source: speakerAttrs.source
      });

      return;
    }

    const user = speakers.find((user) => getSpeakerIdentifier(user) === id);
    const newSpeakerId = id.substring(id.indexOf('-') + 1);

    if (user) {
      extensionOptions.onSpeakerChange?.({
        id: +newSpeakerId,
        name: user.name || '',
        img: 'img' in user ? user.img : null,
        source: speakerAttrs.source
      });
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <NodeViewWrapper>
      {blockAttrs?.field_id !== previousNode?.node?.attrs?.block?.field_id && (
        <div className={cn('flex items-start mb-3 mt-6 space-x-2 pl-3')}>
          <BlockIcon kind={parseScreenerFieldType(blockAttrs.field_type)} />

          <div>
            <Text bold>{blockAttrs.title}</Text>
            <Text className='text-sm text-gray-500'>
              {getBlockLabel(parseScreenerFieldType(blockAttrs.field_type))}
            </Text>
          </div>
        </div>
      )}

      <div className={cn('px-3', { 'pl-14': blockAttrs })}>
        <section contentEditable={false} className='flex items-center justify-between'>
          <div className='flex my-2 space-x-2'>
            {speakerAttrs.type === 'user' ? (
              <AvatarFromId userId={speakerAttrs.id} size='lg' />
            ) : (
              <Avatar user={speakerAttrs} size='lg' />
            )}
            <Menu
              className='max-h-52 relative w-64 py-2 overflow-auto text-sm bg-white border border-gray-200 rounded-md shadow-lg'
              popperProps={{
                placement: 'bottom',
                onOpen: () => editor.commands.blur(),
                onClose: () => {
                  setQuery('');
                  setValue('');
                },
                zIndex: 10
              }}
              onItemSelect={onSpeakerSelect}
              renderTrigger={({ isOpen }) => (
                <div>
                  <Tooltip content={`Change ${speakerAttrs.name}`}>
                    <div
                      role='button'
                      className={cn(
                        'group focus:outline-none hover:bg-gray-100 flex items-center h-full px-2 text-base font-bold rounded-md',
                        { 'bg-gray-100': isOpen }
                      )}
                    >
                      {speakerAttrs.name || 'Unnamed candidate'}
                      <Icons.Pencil className={cn('group-hover:block ml-2', { hidden: !isOpen })} />
                    </div>
                  </Tooltip>
                </div>
              )}
            >
              <Item className='px-2 mb-2' isDisabled>
                <Input
                  ref={ref}
                  placeholder="Type speaker's name"
                  size='sm'
                  className='xx-speaker-name'
                  onChange={onInputChange}
                  value={value}
                />
              </Item>
              {speakerAttrs.source && (
                <Item value='source' className='hover:bg-gray-100 flex items-center px-4 py-2 truncate'>
                  <AvatarInitials initials={speakerAttrs.source} size='lg' className='mr-2' />
                  <div className='flex flex-col flex-grow truncate'>
                    <div className='w-full truncate'>Speaker {speakerAttrs.source.toUpperCase()}</div>
                  </div>
                </Item>
              )}
              {speakers.length ? (
                speakers.map((user) => (
                  <Item
                    key={getSpeakerIdentifier(user)}
                    value={getSpeakerIdentifier(user)}
                    className='hover:bg-gray-100 flex items-center px-4 py-2 truncate'
                  >
                    <Avatar size='lg' user={user as TeamUser} className='mr-2' />
                    <div className='flex flex-col flex-grow truncate'>
                      <div className='w-full truncate'>{user.name}</div>
                      <div className='w-full text-xs text-gray-500 truncate'>{user.email}</div>
                    </div>
                  </Item>
                ))
              ) : (
                <Item className='px-4 py-2 text-gray-400' isDisabled>
                  No results
                </Item>
              )}
              {isLoading && (
                <Item isDisabled>
                  <Loading absolute />
                </Item>
              )}
            </Menu>
          </div>

          <div
            role='button'
            tabIndex={0}
            className='focus:outline-none hover:underline flex items-center text-sm text-indigo-600'
            onClick={onTimestampClick}
          >
            {secondsToTimestamp(startTimestamp, 4)}
            <Icons.Play className='transcript-play-icon ml-1' />
          </div>
        </section>

        <section className='pl-12'>
          <NodeViewContent as='div' className='leading-7' />
        </section>
      </div>
    </NodeViewWrapper>
  );
};
