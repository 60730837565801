import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal, ModalHeading } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';
import { useDeleteGuideMutation } from '@components/GuidesApp/api';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  token: string;
  title: string;
}
export const DeleteGuideModal: React.FC<Props> = ({ open, onClose, onSubmit, token, title }) => {
  const [deleteGuide, { isLoading: deleting }] = useDeleteGuideMutation();

  const showToast = useToaster();

  const navigate = useNavigate();

  if (!open) {
    return null;
  }

  async function handleClick() {
    try {
      await deleteGuide(token).unwrap();

      showToast(toasts.successDelete());

      onClose();

      if (onSubmit) {
        onSubmit();
      } else {
        navigate('/guides');
      }
    } catch {
      showToast(toasts.failedDelete());
    }
  }

  return (
    <Modal icon='danger' onClose={deleting ? undefined : onClose}>
      <ModalHeading className='mb-2'>Delete guide</ModalHeading>
      <p className='pb-12 text-gray-700'>
        All text, data and sharing links for <span className='font-bold'>{title || 'Untitled guide'}</span> will be
        permanently deleted.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={deleting} onClick={onClose}>
          Keep guide
        </Button>
        <Button disabled={deleting} onClick={handleClick} danger>
          Delete guide
        </Button>
      </div>
    </Modal>
  );
};
