import React, { SVGProps } from 'react';

export const UploadVideoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_4_11946)'>
      <path
        d='M6.5625 10.0625C6.5625 10.9908 6.93125 11.881 7.58763 12.5374C8.244 13.1938 9.13424 13.5625 10.0625 13.5625C10.9908 13.5625 11.881 13.1938 12.5374 12.5374C13.1938 11.881 13.5625 10.9908 13.5625 10.0625C13.5625 9.13424 13.1938 8.244 12.5374 7.58763C11.881 6.93125 10.9908 6.5625 10.0625 6.5625C9.13424 6.5625 8.244 6.93125 7.58763 7.58763C6.93125 8.244 6.5625 9.13424 6.5625 10.0625Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0625 11.8125V8.3125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0625 8.3125L8.75 9.625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.0625 8.3125L11.375 9.625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.8125 11.8125H1.3125C1.08044 11.8125 0.857876 11.7203 0.693782 11.5562C0.529687 11.3921 0.4375 11.1696 0.4375 10.9375V1.3125C0.4375 1.08044 0.529687 0.857876 0.693782 0.693782C0.857876 0.529687 1.08044 0.4375 1.3125 0.4375H7.51275C7.74465 0.43755 7.96705 0.529656 8.13108 0.693583L9.80642 2.36892C9.97034 2.53295 10.0625 2.75535 10.0625 2.98725V4.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.64058 7.39559C3.57966 7.42601 3.51196 7.44035 3.44393 7.43726C3.3759 7.43417 3.30979 7.41374 3.25188 7.37791C3.19396 7.34209 3.14616 7.29206 3.11302 7.23257C3.07988 7.17308 3.06249 7.1061 3.0625 7.038V4.33717C3.06249 4.26907 3.07988 4.2021 3.11302 4.14261C3.14616 4.08312 3.19396 4.03308 3.25188 3.99726C3.30979 3.96143 3.3759 3.94101 3.44393 3.93791C3.51196 3.93482 3.57966 3.94916 3.64058 3.97959L6.342 5.33059C6.40834 5.3637 6.46414 5.41464 6.50315 5.4777C6.54215 5.54076 6.56281 5.61344 6.56281 5.68759C6.56281 5.76173 6.54215 5.83441 6.50315 5.89747C6.46414 5.96053 6.40834 6.01147 6.342 6.04459L3.64058 7.39559Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4_11946'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
