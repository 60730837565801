import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

interface Props {
  id?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  register: UseFormMethods['register'];
  minRows?: number;
  errors?: UseFormMethods['errors'];
  disabled?: boolean;
}

const Textarea: React.FC<Props> = ({ disabled, errors, id, placeholder, name, required, register, minRows = 3 }) => (
  <>
    <TextareaAutosize
      disabled={disabled}
      id={id}
      name={name}
      minRows={minRows}
      placeholder={placeholder}
      ref={register({ required })}
      className='mt-1.5  block w-full focus:ring-indigo-500 focus:border-indigo-500 tablet:text-sm border-gray-200 rounded-md text-gray-700 placeholder-h400'
    />
    {errors && errors[name] && (
      <p role='alert' aria-live='assertive' className='mt-1 text-xs text-red-600'>
        {' '}
        {name} is required{' '}
      </p>
    )}
  </>
);

export default Textarea;
