import * as React from 'react';

export default () => (
  <svg className='mx-auto' width='76' height='78' viewBox='0 0 76 78' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.7'>
      <path
        opacity='0.7'
        d='M63.0038 37.3862L37.9331 31.437V77.9993L63.0038 71.5328V37.3862Z'
        fill='url(#paint0_linear)'
      />
      <g opacity='0.1'>
        <path
          opacity='0.1'
          d='M63.0038 37.3862L37.9331 31.437V77.9993L63.0038 71.5328V37.3862Z'
          fill='url(#paint1_linear)'
        />
      </g>
      <path
        opacity='0.7'
        d='M12.8633 37.3864L37.935 31.4371V77.9994L12.8633 71.6615V37.3864Z'
        fill='url(#paint2_linear)'
      />
      <g opacity='0.17'>
        <path
          opacity='0.17'
          d='M12.8633 37.3864L37.935 31.4371V77.9994L12.8633 71.6615V37.3864Z'
          fill='url(#paint3_linear)'
        />
      </g>
      <path
        opacity='0.7'
        d='M37.9331 31.4372L50.9277 20.8315L75.9983 27.1694L63.0038 37.3864L37.9331 31.4372Z'
        fill='url(#paint4_linear)'
      />
      <path
        opacity='0.7'
        d='M0 27.298L12.8631 37.3864L37.9348 31.4372L25.2022 20.8315L0 27.298Z'
        fill='url(#paint5_linear)'
      />
      <g opacity='0.05'>
        <path
          opacity='0.05'
          d='M0 27.298L12.8631 37.3864L37.9348 31.4372L25.2022 20.8315L0 27.298Z'
          fill='url(#paint6_linear)'
        />
      </g>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M37.4835 26.0553H19.8068C19.5494 26.0553 19.3408 26.2609 19.3408 26.5144V42.5144C19.3408 42.7679 19.5494 42.9735 19.8068 42.9735H37.4835C37.7408 42.9735 37.9494 42.7679 37.9494 42.5144V26.5144C37.9494 26.2609 37.7408 26.0553 37.4835 26.0553Z'
          fill='url(#paint7_linear)'
        />
      </g>
      <path
        opacity='0.7'
        d='M37.2838 26.1856H20.0358C19.7784 26.1856 19.5698 26.3912 19.5698 26.6448V42.2346C19.5698 42.4881 19.7784 42.6937 20.0358 42.6937H37.2838C37.5412 42.6937 37.7498 42.4881 37.7498 42.2346V26.6448C37.7498 26.3912 37.5412 26.1856 37.2838 26.1856Z'
        fill='url(#paint8_linear)'
      />
      <path
        opacity='0.7'
        d='M23.5648 28.9054H21.4308C21.1735 28.9054 20.9648 29.111 20.9648 29.3645V31.1653C20.9648 31.4189 21.1735 31.6244 21.4308 31.6244H23.5648C23.8221 31.6244 24.0307 31.4189 24.0307 31.1653V29.3645C24.0307 29.111 23.8221 28.9054 23.5648 28.9054Z'
        fill='url(#paint9_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0582 28.9054H25.036C24.9902 28.9054 24.9531 28.942 24.9531 28.987V29.1258C24.9531 29.1709 24.9902 29.2074 25.036 29.2074H37.0582C37.104 29.2074 37.1411 29.1709 37.1411 29.1258V28.987C37.1411 28.942 37.104 28.9054 37.0582 28.9054Z'
        fill='url(#paint10_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0582 29.8112H25.036C24.9902 29.8112 24.9531 29.8477 24.9531 29.8928V30.0316C24.9531 30.0766 24.9902 30.1132 25.036 30.1132H37.0582C37.104 30.1132 37.1411 30.0766 37.1411 30.0316V29.8928C37.1411 29.8477 37.104 29.8112 37.0582 29.8112Z'
        fill='url(#paint11_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0571 33.1012H20.743C20.6972 33.1012 20.6602 33.1378 20.6602 33.1828V33.3216C20.6602 33.3667 20.6972 33.4032 20.743 33.4032H37.0571C37.1028 33.4032 37.1399 33.3667 37.1399 33.3216V33.1828C37.1399 33.1378 37.1028 33.1012 37.0571 33.1012Z'
        fill='url(#paint12_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0571 34.0075H20.743C20.6972 34.0075 20.6602 34.044 20.6602 34.0891V34.2278C20.6602 34.2729 20.6972 34.3095 20.743 34.3095H37.0571C37.1028 34.3095 37.1399 34.2729 37.1399 34.2278V34.0891C37.1399 34.044 37.1028 34.0075 37.0571 34.0075Z'
        fill='url(#paint13_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0571 35.2155H20.743C20.6972 35.2155 20.6602 35.252 20.6602 35.2971V35.4358C20.6602 35.4809 20.6972 35.5175 20.743 35.5175H37.0571C37.1028 35.5175 37.1399 35.4809 37.1399 35.4358V35.2971C37.1399 35.252 37.1028 35.2155 37.0571 35.2155Z'
        fill='url(#paint14_linear)'
      />
      <path
        opacity='0.7'
        d='M30.0815 36.1227H20.743C20.6972 36.1227 20.6602 36.1592 20.6602 36.2043V36.3431C20.6602 36.3882 20.6972 36.4247 20.743 36.4247H30.0815C30.1272 36.4247 30.1643 36.3882 30.1643 36.3431V36.2043C30.1643 36.1592 30.1272 36.1227 30.0815 36.1227Z'
        fill='url(#paint15_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0571 37.3273H20.743C20.6972 37.3273 20.6602 37.3638 20.6602 37.4089V37.5477C20.6602 37.5927 20.6972 37.6293 20.743 37.6293H37.0571C37.1028 37.6293 37.1399 37.5927 37.1399 37.5477V37.4089C37.1399 37.3638 37.1028 37.3273 37.0571 37.3273Z'
        fill='url(#paint16_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0571 38.2335H20.743C20.6972 38.2335 20.6602 38.2701 20.6602 38.3152V38.4539C20.6602 38.499 20.6972 38.5355 20.743 38.5355H37.0571C37.1028 38.5355 37.1399 38.499 37.1399 38.4539V38.3152C37.1399 38.2701 37.1028 38.2335 37.0571 38.2335Z'
        fill='url(#paint17_linear)'
      />
      <path
        opacity='0.7'
        d='M37.0571 39.4425H20.743C20.6972 39.4425 20.6602 39.4791 20.6602 39.5241V39.6629C20.6602 39.708 20.6972 39.7445 20.743 39.7445H37.0571C37.1028 39.7445 37.1399 39.708 37.1399 39.6629V39.5241C37.1399 39.4791 37.1028 39.4425 37.0571 39.4425Z'
        fill='url(#paint18_linear)'
      />
      <path
        opacity='0.7'
        d='M30.0815 40.3488H20.743C20.6972 40.3488 20.6602 40.3853 20.6602 40.4304V40.5692C20.6602 40.6142 20.6972 40.6508 20.743 40.6508H30.0815C30.1272 40.6508 30.1643 40.6142 30.1643 40.5692V40.4304C30.1643 40.3853 30.1272 40.3488 30.0815 40.3488Z'
        fill='url(#paint19_linear)'
      />
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M40.2911 29.9635H22.6144C22.357 29.9635 22.1484 30.1691 22.1484 30.4226V46.4226C22.1484 46.6762 22.357 46.8817 22.6144 46.8817H40.2911C40.5484 46.8817 40.757 46.6762 40.757 46.4226V30.4226C40.757 30.1691 40.5484 29.9635 40.2911 29.9635Z'
          fill='url(#paint20_linear)'
        />
      </g>
      <path
        opacity='0.7'
        d='M40.0897 30.0956H22.8417C22.5843 30.0956 22.3757 30.3012 22.3757 30.5547V46.1445C22.3757 46.3981 22.5843 46.6037 22.8417 46.6037H40.0897C40.3471 46.6037 40.5557 46.3981 40.5557 46.1445V30.5547C40.5557 30.3012 40.3471 30.0956 40.0897 30.0956Z'
        fill='url(#paint21_linear)'
      />
      <path
        opacity='0.7'
        d='M26.378 32.8145H24.244C23.9867 32.8145 23.7781 33.0201 23.7781 33.2736V35.0744C23.7781 35.328 23.9867 35.5336 24.244 35.5336H26.378C26.6354 35.5336 26.844 35.328 26.844 35.0744V33.2736C26.844 33.0201 26.6354 32.8145 26.378 32.8145Z'
        fill='url(#paint22_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8629 32.8146H27.8406C27.7949 32.8146 27.7578 32.8511 27.7578 32.8962V33.035C27.7578 33.0801 27.7949 33.1166 27.8406 33.1166H39.8629C39.9087 33.1166 39.9458 33.0801 39.9458 33.035V32.8962C39.9458 32.8511 39.9087 32.8146 39.8629 32.8146Z'
        fill='url(#paint23_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8629 33.7208H27.8406C27.7949 33.7208 27.7578 33.7574 27.7578 33.8025V33.9412C27.7578 33.9863 27.7949 34.0228 27.8406 34.0228H39.8629C39.9087 34.0228 39.9458 33.9863 39.9458 33.9412V33.8025C39.9458 33.7574 39.9087 33.7208 39.8629 33.7208Z'
        fill='url(#paint24_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8657 37.0104H23.5516C23.5058 37.0104 23.4688 37.0469 23.4688 37.092V37.2308C23.4688 37.2759 23.5058 37.3124 23.5516 37.3124H39.8657C39.9114 37.3124 39.9485 37.2759 39.9485 37.2308V37.092C39.9485 37.0469 39.9114 37.0104 39.8657 37.0104Z'
        fill='url(#paint25_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8657 37.9171H23.5516C23.5058 37.9171 23.4688 37.9537 23.4688 37.9988V38.1375C23.4688 38.1826 23.5058 38.2191 23.5516 38.2191H39.8657C39.9114 38.2191 39.9485 38.1826 39.9485 38.1375V37.9988C39.9485 37.9537 39.9114 37.9171 39.8657 37.9171Z'
        fill='url(#paint26_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8657 39.1246H23.5516C23.5058 39.1246 23.4688 39.1612 23.4688 39.2063V39.345C23.4688 39.3901 23.5058 39.4266 23.5516 39.4266H39.8657C39.9114 39.4266 39.9485 39.3901 39.9485 39.345V39.2063C39.9485 39.1612 39.9114 39.1246 39.8657 39.1246Z'
        fill='url(#paint27_linear)'
      />
      <path
        opacity='0.7'
        d='M32.89 40.0309H23.5516C23.5058 40.0309 23.4688 40.0674 23.4688 40.1125V40.2513C23.4688 40.2963 23.5058 40.3329 23.5516 40.3329H32.89C32.9358 40.3329 32.9729 40.2963 32.9729 40.2513V40.1125C32.9729 40.0674 32.9358 40.0309 32.89 40.0309Z'
        fill='url(#paint28_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8657 41.237H23.5516C23.5058 41.237 23.4688 41.2735 23.4688 41.3186V41.4573C23.4688 41.5024 23.5058 41.539 23.5516 41.539H39.8657C39.9114 41.539 39.9485 41.5024 39.9485 41.4573V41.3186C39.9485 41.2735 39.9114 41.237 39.8657 41.237Z'
        fill='url(#paint29_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8657 42.1432H23.5516C23.5058 42.1432 23.4688 42.1797 23.4688 42.2248V42.3636C23.4688 42.4087 23.5058 42.4452 23.5516 42.4452H39.8657C39.9114 42.4452 39.9485 42.4087 39.9485 42.3636V42.2248C39.9485 42.1797 39.9114 42.1432 39.8657 42.1432Z'
        fill='url(#paint30_linear)'
      />
      <path
        opacity='0.7'
        d='M39.8657 43.3522H23.5516C23.5058 43.3522 23.4688 43.3887 23.4688 43.4338V43.5726C23.4688 43.6176 23.5058 43.6542 23.5516 43.6542H39.8657C39.9114 43.6542 39.9485 43.6176 39.9485 43.5726V43.4338C39.9485 43.3887 39.9114 43.3522 39.8657 43.3522Z'
        fill='url(#paint31_linear)'
      />
      <path
        opacity='0.7'
        d='M32.89 44.2579H23.5516C23.5058 44.2579 23.4688 44.2945 23.4688 44.3396V44.4783C23.4688 44.5234 23.5058 44.5599 23.5516 44.5599H32.89C32.9358 44.5599 32.9729 44.5234 32.9729 44.4783V44.3396C32.9729 44.2945 32.9358 44.2579 32.89 44.2579Z'
        fill='url(#paint32_linear)'
      />
      <g opacity='0.5'>
        <path opacity='0.5' d='M49.0924 32.1539H32.9688V50.8209H49.0924V32.1539Z' fill='url(#paint33_linear)' />
      </g>
      <path opacity='0.7' d='M48.7492 32.4542H33.4644V50.4079H48.7492V32.4542Z' fill='url(#paint34_linear)' />
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M41.9441 35.309V34.858H40.2823V35.309H39.1133V37.524H43.11V35.309H41.9441ZM40.4894 35.0203H41.7319V35.307H40.4894V35.0203Z'
          fill='url(#paint35_linear)'
        />
      </g>
      <path opacity='0.7' d='M42.9358 35.3837H39.2766V37.412H42.9358V35.3837Z' fill='url(#paint36_linear)' />
      <path
        opacity='0.7'
        d='M40.3477 34.9704V35.4968H40.5382V35.1214H41.6813V35.4968H41.8718V34.9704H40.3477Z'
        fill='url(#paint37_linear)'
      />
      <path opacity='0.7' d='M46.4987 40.3312H35.75V40.7821H46.4987V40.3312Z' fill='url(#paint38_linear)' />
      <path opacity='0.7' d='M46.4987 43.298H35.75V43.749H46.4987V43.298Z' fill='url(#paint39_linear)' />
      <path opacity='0.7' d='M46.4987 44.0866H35.75V44.5375H46.4987V44.0866Z' fill='url(#paint40_linear)' />
      <path opacity='0.7' d='M40.2862 42.5094H35.75V42.9604H40.2862V42.5094Z' fill='url(#paint41_linear)' />
      <g opacity='0.6'>
        <path opacity='0.6' d='M46.5014 42.5094H43.1094V42.9604H46.5014V42.5094Z' fill='url(#paint42_linear)' />
      </g>
      <path opacity='0.7' d='M42.9152 45.9271H39.5232V46.3781H42.9152V45.9271Z' fill='url(#paint43_linear)' />
      <path
        opacity='0.7'
        d='M12.8633 37.3864V71.6615L37.935 77.9994V43.9835L12.8633 37.3864Z'
        fill='url(#paint44_linear)'
      />
      <path
        opacity='0.7'
        d='M63.0038 71.533L37.9331 77.9995V43.9835L63.0038 37.3864V71.533Z'
        fill='url(#paint45_linear)'
      />
      <g opacity='0.07'>
        <path
          opacity='0.07'
          d='M63.0038 71.533L37.9331 77.9995V43.9835L63.0038 37.3864V71.533Z'
          fill='url(#paint46_linear)'
        />
      </g>
      <g opacity='0.07'>
        <path
          opacity='0.07'
          d='M53.0273 60.1507V71.4033L63.0036 68.6873V57.4347L53.0273 60.1507Z'
          fill='url(#paint47_linear)'
        />
      </g>
      <path opacity='0.7' d='M57.276 34.5276H49.8821V41.5277H57.276V34.5276Z' fill='url(#paint48_linear)' />
      <path opacity='0.7' d='M57.1625 34.699H50.0005V41.3849H57.1625V34.699Z' fill='url(#paint49_linear)' />
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M54.2169 35.8714V35.5306H52.9506V35.8734H52.0632V37.5589H55.1043V35.8714H54.2169ZM53.109 35.6531H54.0595V35.8714H53.109V35.6531Z'
          fill='url(#paint50_linear)'
        />
      </g>
      <g opacity='0.6'>
        <path opacity='0.6' d='M54.9746 35.9284H52.1914V37.471H54.9746V35.9284Z' fill='url(#paint51_linear)' />
      </g>
      <g opacity='0.6'>
        <path
          opacity='0.6'
          d='M53.0039 35.6134V36.0144H53.1499V35.7287H54.0196V36.0144H54.1646V35.6134H53.0039Z'
          fill='url(#paint52_linear)'
        />
      </g>
      <g opacity='0.6'>
        <path opacity='0.6' d='M54.9328 38.6712H52.3525V39.014H54.9328V38.6712Z' fill='url(#paint53_linear)' />
      </g>
      <path opacity='0.7' d='M55.7867 39.2995H51.3789V39.6423H55.7867V39.2995Z' fill='url(#paint54_linear)' />
      <g opacity='0.5'>
        <path opacity='0.5' d='M41.9391 13.1295H32.3594V20.8836H41.9391V13.1295Z' fill='url(#paint55_linear)' />
      </g>
      <path opacity='0.7' d='M41.8031 13.2764H32.4646V20.755H41.8031V13.2764Z' fill='url(#paint56_linear)' />
      <path opacity='0.7' d='M41.8031 13.2764H32.4646V14.9445H41.8031V13.2764Z' fill='url(#paint57_linear)' />
      <g opacity='0.1'>
        <path opacity='0.1' d='M41.8031 13.2764H32.4646V14.9445H41.8031V13.2764Z' fill='url(#paint58_linear)' />
      </g>
      <path opacity='0.7' d='M41.0615 15.5495H33.2109V16.5208H41.0615V15.5495Z' fill='url(#paint59_linear)' />
      <path opacity='0.7' d='M38.941 17.2545H33.2109V17.5841H38.941V17.2545Z' fill='url(#paint60_linear)' />
      <path opacity='0.7' d='M36.9312 17.8229H33.2109V18.1524H36.9312V17.8229Z' fill='url(#paint61_linear)' />
      <path opacity='0.7' d='M38.1219 18.3908H33.2109V18.7203H38.1219V18.3908Z' fill='url(#paint62_linear)' />
      <path opacity='0.7' d='M38.941 18.9586H33.2109V19.2882H38.941V18.9586Z' fill='url(#paint63_linear)' />
      <path opacity='0.7' d='M35.1265 19.527H33.2109V19.8565H35.1265V19.527Z' fill='url(#paint64_linear)' />
      <g opacity='0.5'>
        <path opacity='0.5' d='M58.3156 18.9523H46.4414V28.5623H58.3156V18.9523Z' fill='url(#paint65_linear)' />
      </g>
      <path opacity='0.7' d='M58.1531 19.1339H46.5781V28.4031H58.1531V19.1339Z' fill='url(#paint66_linear)' />
      <path opacity='0.7' d='M58.1531 19.1339H46.5781V21.201H58.1531V19.1339Z' fill='url(#paint67_linear)' />
      <path opacity='0.7' d='M57.2299 21.9508H47.5V23.1548H57.2299V21.9508Z' fill='url(#paint68_linear)' />
      <path opacity='0.7' d='M54.6019 24.0641H47.5V24.4732H54.6019V24.0641Z' fill='url(#paint69_linear)' />
      <path opacity='0.7' d='M52.1118 24.7677H47.5V25.1768H52.1118V24.7677Z' fill='url(#paint70_linear)' />
      <path opacity='0.7' d='M53.5872 25.4733H47.5V25.8824H53.5872V25.4733Z' fill='url(#paint71_linear)' />
      <path opacity='0.7' d='M54.6019 26.1769H47.5V26.586H54.6019V26.1769Z' fill='url(#paint72_linear)' />
      <path opacity='0.7' d='M49.8753 26.8805H47.5V27.2896H49.8753V26.8805Z' fill='url(#paint73_linear)' />
      <path opacity='0.7' d='M50.7784 22.3595H48.0117V22.7227H50.7784V22.3595Z' fill='url(#paint74_linear)' />
      <g opacity='0.1'>
        <path
          opacity='0.1'
          d='M12.5352 55.1714L26.5796 58.6638L37.8678 49.6099V44.0483L12.7971 37.84L12.5352 55.1714Z'
          fill='url(#paint75_linear)'
        />
      </g>
      <g opacity='0.1'>
        <path
          opacity='0.1'
          d='M63.2004 55.1713L49.1549 58.6637L37.8667 49.6098V44.0483L62.9374 37.8399L63.2004 55.1713Z'
          fill='url(#paint76_linear)'
        />
      </g>
      <path
        opacity='0.7'
        d='M12.8631 37.3864L0 47.8636L25.0707 54.072L37.9348 43.9835L12.8631 37.3864Z'
        fill='url(#paint77_linear)'
      />
      <path
        opacity='0.7'
        d='M63.0038 37.3864L75.9983 47.8636L51.0592 54.072L37.9331 43.9835L63.0038 37.3864Z'
        fill='url(#paint78_linear)'
      />
      <g opacity='0.07'>
        <path
          opacity='0.07'
          d='M63.0038 37.3864L75.9983 47.8636L51.0592 54.072L37.9331 43.9835L63.0038 37.3864Z'
          fill='url(#paint79_linear)'
        />
      </g>
    </g>
    <path d='M63.0517 37.0731L38.1953 31.1738V77.3382L63.0517 70.9268V37.0731Z' fill='#FACA15' />
    <path opacity='0.1' d='M63.0517 37.0731L38.1953 31.1738V77.3382L63.0517 70.9268V37.0731Z' fill='#374151' />
    <path d='M13.3389 37.0728L38.1942 31.1736V77.338L13.3389 71.0541V37.0728Z' fill='#FACA15' />
    <path opacity='0.17' d='M13.3389 36.8686L38.1942 30.9694V77.3378L13.3389 71.0539V36.8686Z' fill='#374151' />
    <path d='M37.9844 31.3772L51.0752 20.6582L75.9316 26.9421L62.8407 37.2764L37.9844 31.3772Z' fill='#FACA15' />
    <path d='M0.793457 27.274L13.5468 37.2768L38.4021 31.3776L25.7792 20.8626L0.793457 27.274Z' fill='#FACA15' />
    <path
      opacity='0.05'
      d='M0.586182 27.0698L13.6501 37.2766L38.5054 31.3774L25.572 20.6584L0.586182 27.0698Z'
      fill='#374151'
    />
    <g opacity='0.5'>
      <path
        opacity='0.5'
        d='M37.742 25.8373H20.2247C19.9674 25.8373 19.7588 26.0429 19.7588 26.2964V42.1515C19.7588 42.4051 19.9674 42.6106 20.2247 42.6106H37.742C37.9993 42.6106 38.2079 42.4051 38.2079 42.1515V26.2964C38.2079 26.0429 37.9993 25.8373 37.742 25.8373Z'
        fill='url(#paint80_linear)'
      />
    </g>
    <path
      d='M37.5386 25.9681H20.4469C20.1896 25.9681 19.981 26.1736 19.981 26.4272V41.8752C19.981 42.1288 20.1896 42.3343 20.4469 42.3343H37.5386C37.7959 42.3343 38.0046 42.1288 38.0046 41.8752V26.4272C38.0046 26.1736 37.7959 25.9681 37.5386 25.9681Z'
      fill='#F5F5F5'
    />
    <path
      d='M23.9491 28.6636H21.8409C21.5836 28.6636 21.375 28.8691 21.375 29.1227V30.9C21.375 31.1536 21.5836 31.3591 21.8409 31.3591H23.9491C24.2064 31.3591 24.415 31.1536 24.415 30.9V29.1227C24.415 28.8691 24.2064 28.6636 23.9491 28.6636Z'
      fill='#5850EC'
    />
    <path
      d='M37.3248 28.6637H25.4071C25.3613 28.6637 25.3242 28.7003 25.3242 28.7453V28.8821C25.3242 28.9271 25.3613 28.9637 25.4071 28.9637H37.3248C37.3705 28.9637 37.4076 28.9271 37.4076 28.8821V28.7453C37.4076 28.7003 37.3705 28.6637 37.3248 28.6637Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3248 29.5613H25.4071C25.3613 29.5613 25.3242 29.5978 25.3242 29.6429V29.7796C25.3242 29.8247 25.3613 29.8612 25.4071 29.8612H37.3248C37.3705 29.8612 37.4076 29.8247 37.4076 29.7796V29.6429C37.4076 29.5978 37.3705 29.5613 37.3248 29.5613Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3267 32.8235H21.1534C21.1076 32.8235 21.0706 32.86 21.0706 32.9051V33.0418C21.0706 33.0869 21.1076 33.1234 21.1534 33.1234H37.3267C37.3724 33.1234 37.4095 33.0869 37.4095 33.0418V32.9051C37.4095 32.86 37.3724 32.8235 37.3267 32.8235Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3267 33.722H21.1534C21.1076 33.722 21.0706 33.7585 21.0706 33.8036V33.9403C21.0706 33.9854 21.1076 34.0219 21.1534 34.0219H37.3267C37.3724 34.0219 37.4095 33.9854 37.4095 33.9403V33.8036C37.4095 33.7585 37.3724 33.722 37.3267 33.722Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3267 34.92H21.1534C21.1076 34.92 21.0706 34.9566 21.0706 35.0016V35.1383C21.0706 35.1834 21.1076 35.22 21.1534 35.22H37.3267C37.3724 35.22 37.4095 35.1834 37.4095 35.1383V35.0016C37.4095 34.9566 37.3724 34.92 37.3267 34.92Z'
      fill='#E0E0E0'
    />
    <path
      d='M30.4111 35.8191H21.1534C21.1076 35.8191 21.0706 35.8557 21.0706 35.9007V36.0375C21.0706 36.0825 21.1076 36.1191 21.1534 36.1191H30.4111C30.4569 36.1191 30.4939 36.0825 30.4939 36.0375V35.9007C30.4939 35.8557 30.4569 35.8191 30.4111 35.8191Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3267 37.0139H21.1534C21.1076 37.0139 21.0706 37.0504 21.0706 37.0955V37.2322C21.0706 37.2773 21.1076 37.3138 21.1534 37.3138H37.3267C37.3724 37.3138 37.4095 37.2773 37.4095 37.2322V37.0955C37.4095 37.0504 37.3724 37.0139 37.3267 37.0139Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3267 37.9124H21.1534C21.1076 37.9124 21.0706 37.9489 21.0706 37.994V38.1307C21.0706 38.1758 21.1076 38.2123 21.1534 38.2123H37.3267C37.3724 38.2123 37.4095 38.1758 37.4095 38.1307V37.994C37.4095 37.9489 37.3724 37.9124 37.3267 37.9124Z'
      fill='#E0E0E0'
    />
    <path
      d='M37.3267 39.1098H21.1534C21.1076 39.1098 21.0706 39.1464 21.0706 39.1915V39.3282C21.0706 39.3733 21.1076 39.4098 21.1534 39.4098H37.3267C37.3724 39.4098 37.4095 39.3733 37.4095 39.3282V39.1915C37.4095 39.1464 37.3724 39.1098 37.3267 39.1098Z'
      fill='#E0E0E0'
    />
    <path
      d='M30.4111 40.0089H21.1534C21.1076 40.0089 21.0706 40.0454 21.0706 40.0905V40.2272C21.0706 40.2723 21.1076 40.3088 21.1534 40.3088H30.4111C30.4569 40.3088 30.4939 40.2723 30.4939 40.2272V40.0905C30.4939 40.0454 30.4569 40.0089 30.4111 40.0089Z'
      fill='#E0E0E0'
    />
    <g opacity='0.5'>
      <path
        opacity='0.5'
        d='M40.5301 29.7134H23.0128C22.7555 29.7134 22.5469 29.9189 22.5469 30.1725V46.0276C22.5469 46.2811 22.7555 46.4867 23.0128 46.4867H40.5301C40.7874 46.4867 40.996 46.2811 40.996 46.0276V30.1725C40.996 29.9189 40.7874 29.7134 40.5301 29.7134Z'
        fill='url(#paint81_linear)'
      />
    </g>
    <path
      d='M40.3272 29.8444H23.2355C22.9781 29.8444 22.7695 30.0499 22.7695 30.3035V45.7515C22.7695 46.005 22.9781 46.2106 23.2355 46.2106H40.3272C40.5845 46.2106 40.7931 46.005 40.7931 45.7515V30.3035C40.7931 30.0499 40.5845 29.8444 40.3272 29.8444Z'
      fill='white'
    />
    <path
      d='M26.7342 32.5396H24.6261C24.3688 32.5396 24.1602 32.7451 24.1602 32.9987V34.776C24.1602 35.0296 24.3688 35.2351 24.6261 35.2351H26.7342C26.9916 35.2351 27.2002 35.0296 27.2002 34.776V32.9987C27.2002 32.7451 26.9916 32.5396 26.7342 32.5396Z'
      fill='#5850EC'
    />
    <path
      d='M40.1131 32.5393H28.1954C28.1496 32.5393 28.1125 32.5759 28.1125 32.621V32.7577C28.1125 32.8028 28.1496 32.8393 28.1954 32.8393H40.1131C40.1588 32.8393 40.1959 32.8028 40.1959 32.7577V32.621C40.1959 32.5759 40.1588 32.5393 40.1131 32.5393Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1131 33.4376H28.1954C28.1496 33.4376 28.1125 33.4741 28.1125 33.5192V33.6559C28.1125 33.701 28.1496 33.7375 28.1954 33.7375H40.1131C40.1588 33.7375 40.1959 33.701 40.1959 33.6559V33.5192C40.1959 33.4741 40.1588 33.4376 40.1131 33.4376Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1114 36.6992H23.9381C23.8923 36.6992 23.8552 36.7357 23.8552 36.7808V36.9175C23.8552 36.9626 23.8923 36.9991 23.9381 36.9991H40.1114C40.1571 36.9991 40.1942 36.9626 40.1942 36.9175V36.7808C40.1942 36.7357 40.1571 36.6992 40.1114 36.6992Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1114 37.5983H23.9381C23.8923 37.5983 23.8552 37.6348 23.8552 37.6799V37.8166C23.8552 37.8617 23.8923 37.8982 23.9381 37.8982H40.1114C40.1571 37.8982 40.1942 37.8617 40.1942 37.8166V37.6799C40.1942 37.6348 40.1571 37.5983 40.1114 37.5983Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1114 38.7958H23.9381C23.8923 38.7958 23.8552 38.8323 23.8552 38.8774V39.0141C23.8552 39.0592 23.8923 39.0957 23.9381 39.0957H40.1114C40.1571 39.0957 40.1942 39.0592 40.1942 39.0141V38.8774C40.1942 38.8323 40.1571 38.7958 40.1114 38.7958Z'
      fill='#E0E0E0'
    />
    <path
      d='M33.1958 39.6949H23.9381C23.8923 39.6949 23.8552 39.7314 23.8552 39.7765V39.9132C23.8552 39.9583 23.8923 39.9949 23.9381 39.9949H33.1958C33.2415 39.9949 33.2786 39.9583 33.2786 39.9132V39.7765C33.2786 39.7314 33.2415 39.6949 33.1958 39.6949Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1114 40.8896H23.9381C23.8923 40.8896 23.8552 40.9262 23.8552 40.9713V41.108C23.8552 41.153 23.8923 41.1896 23.9381 41.1896H40.1114C40.1571 41.1896 40.1942 41.153 40.1942 41.108V40.9713C40.1942 40.9262 40.1571 40.8896 40.1114 40.8896Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1114 41.7886H23.9381C23.8923 41.7886 23.8552 41.8251 23.8552 41.8702V42.0069C23.8552 42.052 23.8923 42.0886 23.9381 42.0886H40.1114C40.1571 42.0886 40.1942 42.052 40.1942 42.0069V41.8702C40.1942 41.8251 40.1571 41.7886 40.1114 41.7886Z'
      fill='#E0E0E0'
    />
    <path
      d='M40.1114 42.9862H23.9381C23.8923 42.9862 23.8552 43.0227 23.8552 43.0678V43.2045C23.8552 43.2496 23.8923 43.2861 23.9381 43.2861H40.1114C40.1571 43.2861 40.1942 43.2496 40.1942 43.2045V43.0678C40.1942 43.0227 40.1571 42.9862 40.1114 42.9862Z'
      fill='#E0E0E0'
    />
    <path
      d='M33.1958 43.8852H23.9381C23.8923 43.8852 23.8552 43.9218 23.8552 43.9669V44.1036C23.8552 44.1487 23.8923 44.1852 23.9381 44.1852H33.1958C33.2415 44.1852 33.2786 44.1487 33.2786 44.1036V43.9669C33.2786 43.9218 33.2415 43.8852 33.1958 43.8852Z'
      fill='#E0E0E0'
    />
    <g opacity='0.5'>
      <path opacity='0.5' d='M49.2593 31.8843H33.2734V50.391H49.2593V31.8843Z' fill='url(#paint82_linear)' />
    </g>
    <path d='M48.9172 32.1826H33.7627V49.9823H48.9172V32.1826Z' fill='white' />
    <g opacity='0.5'>
      <path
        opacity='0.5'
        d='M42.1669 35.013V34.5651H40.5154V35.013H39.3599V37.2096H43.3224V35.013H42.1669ZM40.7173 34.7283H41.9598V35.013H40.7173V34.7283Z'
        fill='url(#paint83_linear)'
      />
    </g>
    <path d='M43.1564 35.0873H39.5283V37.0982H43.1564V35.0873Z' fill='#5850EC' />
    <path d='M40.5898 34.6772V35.1986H40.7783V34.8262H41.9121V35.1986H42.1016V34.6772H40.5898Z' fill='#5850EC' />
    <path d='M46.6878 39.9917H36.0312V40.4386H46.6878V39.9917Z' fill='#E0E0E0' />
    <path d='M46.6878 42.9331H36.0312V43.38H46.6878V42.9331Z' fill='#E0E0E0' />
    <path d='M46.6878 43.7153H36.0312V44.1622H46.6878V43.7153Z' fill='#E0E0E0' />
    <path d='M40.5281 42.1519H36.0312V42.5987H40.5281V42.1519Z' fill='#E0E0E0' />
    <path opacity='0.6' d='M46.6873 42.1519H43.3242V42.5987H46.6873V42.1519Z' fill='#3AD29F' />
    <path d='M43.1365 45.5398H39.7734V45.9867H43.1365V45.5398Z' fill='#5850EC' />
    <path d='M13.3389 37.0727V71.0539L38.1942 77.3378L38.5297 43.6126L13.3389 37.0727Z' fill='#FACA15' />
    <path d='M63.0517 70.9265L38.1953 77.3379V43.6127L63.0517 37.0728V70.9265Z' fill='#FACA15' />
    <path opacity='0.07' d='M63.0517 70.9265L38.1953 77.3379V43.6127L63.0517 37.0728V70.9265Z' fill='#374151' />
    <path opacity='0.07' d='M53.1611 59.6422V70.7979L63.0515 68.1054V56.9486L53.1611 59.6422Z' fill='#374151' />
    <path d='M57.3738 34.2383H50.043V41.1782H57.3738V34.2383Z' fill='url(#paint84_linear)' />
    <path d='M57.2572 34.4077H50.1562V41.0364H57.2572V34.4077Z' fill='#F5F5F5' />
    <g opacity='0.5'>
      <path
        opacity='0.5'
        d='M54.3391 35.5696V35.2247H53.0832V35.5655H52.2041V37.2367H55.2182V35.5696H54.3391ZM53.2406 35.3533H54.1828V35.5696H53.2406V35.3533Z'
        fill='url(#paint85_linear)'
      />
    </g>
    <path opacity='0.6' d='M55.0905 35.6254H52.3311V37.1548H55.0905V35.6254Z' fill='#5850EC' />
    <path
      opacity='0.6'
      d='M53.1335 35.3148V35.7117H53.2775V35.428H54.14V35.7117H54.2829V35.3148H53.1335Z'
      fill='#5850EC'
    />
    <g opacity='0.6'>
      <path opacity='0.6' d='M55.0468 38.3457H52.4883V38.6854H55.0468V38.3457Z' fill='#5850EC' />
    </g>
    <path d='M55.8934 38.9693H51.5239V39.309H55.8934V38.9693Z' fill='#BDBDBD' />
    <path d='M50.3464 0H43.0156V6.93991H50.3464V0Z' fill='url(#paint86_linear)' />
    <path d='M50.2338 0.170898H43.1328V6.79962H50.2338V0.170898Z' fill='#F5F5F5' />
    <g opacity='0.5'>
      <path
        opacity='0.5'
        d='M47.3106 1.33164V0.99189H46.0546V1.33164H45.1755V3.00285H48.1896V1.33164H47.3106ZM46.212 1.11534H47.1542V1.33164H46.212V1.11534Z'
        fill='url(#paint87_linear)'
      />
    </g>
    <path opacity='0.6' d='M48.0607 1.38762H45.3013V2.91701H48.0607V1.38762Z' fill='#5850EC' />
    <path
      opacity='0.6'
      d='M46.1052 1.0765V1.47339H46.2491V1.18975H47.1116V1.47339H47.2545V1.0765H46.1052Z'
      fill='#5850EC'
    />
    <g opacity='0.6'>
      <path opacity='0.6' d='M48.0185 4.1078H45.46V4.44756H48.0185V4.1078Z' fill='#5850EC' />
    </g>
    <path d='M48.8648 4.73081H44.4954V5.07057H48.8648V4.73081Z' fill='#BDBDBD' />
    <g opacity='0.5'>
      <path opacity='0.5' d='M42.1627 13.0226H32.6648V20.7104H42.1627V13.0226Z' fill='url(#paint88_linear)' />
    </g>
    <path d='M42.0327 13.1687H32.7739V20.583H42.0327V13.1687Z' fill='#F2F2F2' />
    <path d='M42.0327 13.1687H32.7739V14.8225H42.0327V13.1687Z' fill='#5850EC' />
    <path opacity='0.1' d='M42.0327 13.1687H32.7739V14.8225H42.0327V13.1687Z' fill='#374151' />
    <path d='M41.2943 15.4228H33.511V16.3859H41.2943V15.4228Z' fill='#E0E0E0' />
    <path d='M39.1913 17.1118H33.511V17.4393H39.1913V17.1118Z' fill='#E0E0E0' />
    <path d='M37.1992 17.6755H33.511V18.003H37.1992V17.6755Z' fill='#E0E0E0' />
    <path d='M38.3795 18.2384H33.511V18.5659H38.3795V18.2384Z' fill='#E0E0E0' />
    <path d='M39.1913 18.8025H33.511V19.13H39.1913V18.8025Z' fill='#E0E0E0' />
    <path d='M35.411 19.3655H33.511V19.693H35.411V19.3655Z' fill='#E0E0E0' />
    <g opacity='0.5'>
      <path opacity='0.5' d='M58.3977 18.7954H46.625V28.3238H58.3977V18.7954Z' fill='url(#paint89_linear)' />
    </g>
    <path d='M58.2412 18.9751H46.7656V28.1657H58.2412V18.9751Z' fill='white' />
    <path d='M58.2412 18.9751H46.7656V21.0248H58.2412V18.9751Z' fill='#5850EC' />
    <path d='M57.3306 21.7684H47.6836V22.9622H57.3306V21.7684Z' fill='#F5F5F5' />
    <path d='M54.7245 23.863H47.6836V24.2681H54.7245V23.863Z' fill='#E0E0E0' />
    <path d='M52.256 24.5618H47.6836V24.9669H52.256V24.5618Z' fill='#E0E0E0' />
    <path d='M53.718 25.2595H47.6836V25.6646H53.718V25.2595Z' fill='#E0E0E0' />
    <path d='M54.7245 25.9588H47.6836V26.3638H54.7245V25.9588Z' fill='#E0E0E0' />
    <path d='M50.0382 26.6565H47.6836V27.0615H50.0382V26.6565Z' fill='#E0E0E0' />
    <path d='M50.9257 22.1734H48.1829V22.5336H50.9257V22.1734Z' fill='#69F0AE' />
    <path
      opacity='0.1'
      d='M13.0117 54.7054L26.9361 58.1672L38.128 49.1908V43.6772L13.2716 37.5219L13.0117 54.7054Z'
      fill='#374151'
    />
    <path
      opacity='0.1'
      d='M63.2481 54.7054L49.3237 58.1672L38.1318 49.1908V43.6772L62.9882 37.5219L63.2481 54.7054Z'
      fill='#374151'
    />
    <path d='M13.3395 37.0727L0.586182 47.4591L25.4415 53.6144L38.1948 43.6126L13.3395 37.0727Z' fill='#FACA15' />
    <path d='M63.0517 37.0728L75.9354 47.4591L51.2095 53.6145L38.1953 43.6127L63.0517 37.0728Z' fill='#FACA15' />
    <path
      opacity='0.07'
      d='M63.0517 37.0728L75.9354 47.4591L51.2095 53.6145L38.1953 43.6127L63.0517 37.0728Z'
      fill='#374151'
    />
    <defs>
      <linearGradient
        id='paint0_linear'
        x1='37.9331'
        y1='54.7186'
        x2='63.0038'
        y2='54.7186'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint1_linear'
        x1='37.9331'
        y1='54.7186'
        x2='63.0038'
        y2='54.7186'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint2_linear'
        x1='3127.52'
        y1='25003.7'
        x2='9198.39'
        y2='25003.7'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint3_linear'
        x1='3127.52'
        y1='25003.7'
        x2='9198.39'
        y2='25003.7'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint4_linear'
        x1='13983.9'
        y1='4744.13'
        x2='27977.8'
        y2='4744.13'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient id='paint5_linear' x1='0' y1='4744.13' x2='13898.2' y2='4744.13' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient id='paint6_linear' x1='0' y1='4744.13' x2='13898.2' y2='4744.13' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint7_linear'
        x1='5167.23'
        y1='7151.83'
        x2='5167.23'
        y2='4346.62'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint8_linear'
        x1='3455.03'
        y1='5598.64'
        x2='6647.07'
        y2='5598.64'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint9_linear'
        x1='641.806'
        y1='835.451'
        x2='732.587'
        y2='835.451'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint10_linear'
        x1='2962.13'
        y1='114.913'
        x2='4396.77'
        y2='114.913'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint11_linear'
        x1='2962.13'
        y1='118.499'
        x2='4396.77'
        y2='118.499'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint12_linear'
        x1='3309.03'
        y1='131.527'
        x2='5931.95'
        y2='131.527'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint13_linear'
        x1='3309.03'
        y1='135.118'
        x2='5931.95'
        y2='135.118'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint14_linear'
        x1='3309.03'
        y1='139.9'
        x2='5931.95'
        y2='139.9'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint15_linear'
        x1='1917.11'
        y1='143.493'
        x2='2789.5'
        y2='143.493'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint16_linear'
        x1='3309.03'
        y1='148.265'
        x2='5931.95'
        y2='148.265'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint17_linear'
        x1='3309.03'
        y1='151.854'
        x2='5931.95'
        y2='151.854'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint18_linear'
        x1='3309.03'
        y1='156.637'
        x2='5931.95'
        y2='156.637'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint19_linear'
        x1='1917.11'
        y1='160.228'
        x2='2789.5'
        y2='160.228'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint20_linear'
        x1='5674.7'
        y1='7804.05'
        x2='5674.7'
        y2='4998.67'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint21_linear'
        x1='3950.88'
        y1='6235.14'
        x2='7142.92'
        y2='6235.14'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint22_linear'
        x1='727.767'
        y1='943.554'
        x2='818.548'
        y2='943.554'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint23_linear'
        x1='3295.59'
        y1='130.394'
        x2='4730.23'
        y2='130.394'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint24_linear'
        x1='3295.59'
        y1='133.982'
        x2='4730.23'
        y2='133.982'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint25_linear'
        x1='3758.77'
        y1='147.009'
        x2='6381.86'
        y2='147.009'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint26_linear'
        x1='3758.77'
        y1='150.6'
        x2='6381.86'
        y2='150.6'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint27_linear'
        x1='3758.77'
        y1='155.38'
        x2='6381.86'
        y2='155.38'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint28_linear'
        x1='2177.68'
        y1='158.968'
        x2='3050.06'
        y2='158.968'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint29_linear'
        x1='3758.77'
        y1='163.755'
        x2='6381.86'
        y2='163.755'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint30_linear'
        x1='3758.77'
        y1='167.334'
        x2='6381.86'
        y2='167.334'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint31_linear'
        x1='3758.77'
        y1='172.121'
        x2='6381.86'
        y2='172.121'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint32_linear'
        x1='2177.68'
        y1='175.705'
        x2='3050.06'
        y2='175.705'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint33_linear'
        x1='6422.27'
        y1='9330.35'
        x2='6422.27'
        y2='5915.04'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint34_linear'
        x1='4973.54'
        y1='7323.11'
        x2='7229.9'
        y2='7323.11'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint35_linear'
        x1='2557.1'
        y1='1195.99'
        x2='2557.1'
        y2='1126.33'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint36_linear'
        x1='1427.35'
        y1='758.98'
        x2='1556.67'
        y2='758.98'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint37_linear'
        x1='634.235'
        y1='216.779'
        x2='656.67'
        y2='216.779'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint38_linear'
        x1='3747.17'
        y1='219.587'
        x2='4863'
        y2='219.587'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint39_linear'
        x1='3747.17'
        y1='235.679'
        x2='4863'
        y2='235.679'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint40_linear'
        x1='3747.17'
        y1='239.945'
        x2='4863'
        y2='239.945'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint41_linear'
        x1='1602.05'
        y1='231.396'
        x2='1800.78'
        y2='231.396'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint42_linear'
        x1='1455.36'
        y1='231.396'
        x2='1566.48'
        y2='231.396'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint43_linear'
        x1='1334.37'
        y1='249.93'
        x2='1445.52'
        y2='249.93'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint44_linear'
        x1='3127.52'
        y1='23002.8'
        x2='9198.39'
        y2='23002.8'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint45_linear'
        x1='9223.08'
        y1='23002.8'
        x2='15293.4'
        y2='23002.8'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint46_linear'
        x1='9223.08'
        y1='23002.8'
        x2='15293.4'
        y2='23002.8'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint47_linear'
        x1='5162.38'
        y1='8877.06'
        x2='6123.6'
        y2='8877.06'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint48_linear'
        x1='3876.17'
        y1='2883.78'
        x2='3876.17'
        y2='2403.5'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint49_linear'
        x1='3508.54'
        y1='2527.66'
        x2='4003.94'
        y2='2527.66'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint50_linear'
        x1='2334.32'
        y1='919.589'
        x2='2334.32'
        y2='879.246'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint51_linear'
        x1='1455.06'
        y1='590.837'
        x2='1529.9'
        y2='590.837'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint52_linear'
        x1='647.156'
        y1='176.365'
        x2='660.155'
        y2='176.365'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint53_linear'
        x1='1356.89'
        y1='169.182'
        x2='1421.22'
        y2='169.182'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint54_linear'
        x1='2238.53'
        y1='171.927'
        x2='2426.17'
        y2='171.927'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint55_linear'
        x1='3469.18'
        y1='1600.31'
        x2='3469.18'
        y2='1011'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint56_linear'
        x1='2960.74'
        y1='1260.56'
        x2='3802.98'
        y2='1260.56'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint57_linear'
        x1='2960.74'
        y1='243.981'
        x2='3802.98'
        y2='243.981'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint58_linear'
        x1='2960.74'
        y1='243.981'
        x2='3802.98'
        y2='243.981'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint59_linear'
        x1='2551.36'
        y1='168.209'
        x2='3146.52'
        y2='168.209'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint60_linear'
        x1='1871.17'
        y1='73.5182'
        x2='2188.22'
        y2='73.5182'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint61_linear'
        x1='1226.53'
        y1='75.9229'
        x2='1360.2'
        y2='75.9229'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint62_linear'
        x1='1608.46'
        y1='78.3263'
        x2='1841.39'
        y2='78.3263'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint63_linear'
        x1='1871.17'
        y1='80.7291'
        x2='2188.22'
        y2='80.7291'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint64_linear'
        x1='647.638'
        y1='83.1334'
        x2='683.075'
        y2='83.1334'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint65_linear'
        x1='6053.13'
        y1='2709.26'
        x2='6053.13'
        y2='1804.1'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint66_linear'
        x1='5253.7'
        y1='2178.49'
        x2='6547.56'
        y2='2178.49'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint67_linear'
        x1='5253.7'
        y1='427.735'
        x2='6547.56'
        y2='427.735'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint68_linear'
        x1='4511.17'
        y1='288.08'
        x2='5425.48'
        y2='288.08'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint69_linear'
        x1='3305.59'
        y1='121.381'
        x2='3792.71'
        y2='121.381'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint70_linear'
        x1='2163.19'
        y1='124.908'
        x2='2368.6'
        y2='124.908'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint71_linear'
        x1='2840.08'
        y1='128.44'
        x2='3197.95'
        y2='128.44'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint72_linear'
        x1='3305.59'
        y1='131.964'
        x2='3792.71'
        y2='131.964'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint73_linear'
        x1='1137.18'
        y1='135.497'
        x2='1191.67'
        y2='135.497'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint74_linear'
        x1='1330.8'
        y1='102.609'
        x2='1404.73'
        y2='102.609'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint75_linear'
        x1='3079.56'
        y1='9886.04'
        x2='9277.44'
        y2='9886.04'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint76_linear'
        x1='9303.15'
        y1='9886.04'
        x2='15501.5'
        y2='9886.04'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient id='paint77_linear' x1='0' y1='7516.02' x2='13898.2' y2='7516.02' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint78_linear'
        x1='13983.9'
        y1='7516.02'
        x2='27977.8'
        y2='7516.02'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint79_linear'
        x1='13983.9'
        y1='7516.02'
        x2='27977.8'
        y2='7516.02'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint80_linear'
        x1='5184.04'
        y1='7031.05'
        x2='5184.04'
        y2='4273.51'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint81_linear'
        x1='5683.12'
        y1='7672.14'
        x2='5683.12'
        y2='4914.61'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint82_linear'
        x1='6404.29'
        y1='9172.37'
        x2='6404.29'
        y2='5815.43'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint83_linear'
        x1='2544.77'
        y1='1178.2'
        x2='2544.77'
        y2='1109.65'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint84_linear'
        x1='3852.6'
        y1='2835.19'
        x2='3852.6'
        y2='2363.13'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint85_linear'
        x1='2317.94'
        y1='905.932'
        x2='2317.94'
        y2='866.275'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient id='paint86_linear' x1='3348.03' y1='472.053' x2='3348.03' y2='0' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint87_linear'
        x1='2106.34'
        y1='196.417'
        x2='2106.34'
        y2='156.781'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint88_linear'
        x1='3464.65'
        y1='1573.56'
        x2='3464.65'
        y2='994.29'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
      <linearGradient
        id='paint89_linear'
        x1='6017.53'
        y1='2663.96'
        x2='6017.53'
        y2='1774.11'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#808080' stopOpacity='0.25' />
        <stop offset='0.54' stopColor='#808080' stopOpacity='0.12' />
        <stop offset='1' stopColor='#808080' stopOpacity='0.1' />
      </linearGradient>
    </defs>
  </svg>
);
