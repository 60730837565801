import { useCallback, useEffect, useRef } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { unRef } from '@helpers/unRef';

type Result = {
  stop: () => void;
};

export const useMutationObserver = (
  target: MaybeRef<HTMLElement | null>,
  callback: MutationCallback,
  options: MutationObserverInit = {}
): Result => {
  const observer = useRef<MutationObserver | null>(null);

  const stop = useCallback(() => {
    if (!observer.current) return;

    observer.current.disconnect();
    observer.current = null;
  }, []);

  useDeepCompareEffect(() => {
    const el = unRef(target);

    if (!el) return;

    observer.current = new MutationObserver(callback);
    observer.current.observe(el, options);

    return stop;
  }, [stop, options, target]);

  useEffect(
    () => () => {
      stop();
    },
    [stop]
  );

  return { stop };
};
