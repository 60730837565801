import React, { SVGProps } from 'react';

export const PauseSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='16' viewBox='0 0 16 16' width='16' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m6.6875 5.8125v4.375' />
      <path d='m9.3125 5.8125v4.375' />
      <path d='m1.4375 8c0 1.74048.6914 3.4097 1.92211 4.6404s2.89991 1.9221 4.64039 1.9221 3.4097-.6914 4.6404-1.9221 1.9221-2.89992 1.9221-4.6404-.6914-3.40968-1.9221-4.64039-2.89992-1.92211-4.6404-1.92211-3.40968.6914-4.64039 1.92211-1.92211 2.89991-1.92211 4.64039z' />
    </g>
  </svg>
);
