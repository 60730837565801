import * as React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import { Button, Spinner, Text } from '@components/common';

import { SVG } from './SVG';

type Props = {
  loading?: boolean;
  text: string;
  onClickCreate: () => void;
};
export const PlaceholderColumn: React.FC<Props> = ({ loading, text, onClickCreate }) => (
  <div className='relative flex flex-col flex-shrink-0 w-full h-full max-w-sm'>
    <Button disabled={loading} className='mb-2' icon='plus' fullWidth onClick={onClickCreate}>
      Create a new group
    </Button>
    <Droppable droppableId='new_group'>
      {(provided) => (
        <div
          className='bg-gray-50 flex flex-col items-center justify-center flex-1 p-4 border border-gray-400 border-dashed rounded-sm'
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {loading && <Spinner className='w-8' />}
          {!loading && (
            <>
              <SVG className='mb-6' />
              <Text h='400'>{text}</Text>
            </>
          )}
        </div>
      )}
    </Droppable>
  </div>
);
