import { api } from '@api/reduxApi';
import { buildStudyLocation } from '@api/builders';

const studyLocationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStudyLocation: builder.query<StudyLocation, number>({
      query: (id) => `/studies/${id}/location`,
      providesTags: (q, i, id) => [{ type: 'StudyLocation', id }]
    }),
    updateStudyLocation: builder.mutation<StudyLocation, StudyLocation>({
      query: ({ study_id, ...location }) => ({
        url: `/studies/${study_id}/location`,
        method: 'PUT',
        body: { location }
      }),
      transformResponse: (resp: any) => buildStudyLocation(resp.data),
      invalidatesTags: (r, e, location) => [{ type: 'Study', id: location?.study_id }, { type: 'StudyLocation', id: location?.study_id }]
    })
  })
});

export const { useGetStudyLocationQuery, useUpdateStudyLocationMutation } = studyLocationApi;
