import React, { SVGProps } from 'react';

export const ExportSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M7.5 11.5C7.5 12.5609 7.92143 13.5783 8.67157 14.3284C9.42172 15.0786 10.4391 15.5 11.5 15.5C12.5609 15.5 13.5783 15.0786 14.3284 14.3284C15.0786 13.5783 15.5 12.5609 15.5 11.5C15.5 10.4391 15.0786 9.42172 14.3284 8.67157C13.5783 7.92143 12.5609 7.5 11.5 7.5C10.4391 7.5 9.42172 7.92143 8.67157 8.67157C7.92143 9.42172 7.5 10.4391 7.5 11.5Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M11.5 9.5V13.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5 13.5L10 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M11.5 13.5L13 12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M2.5 9.5H5.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M5.5 9.5V4.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7.5 6.5V5.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M3.5 9.5V6.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M5 13.5H1.5C1.23478 13.5 0.98043 13.3946 0.792893 13.2071C0.605357 13.0196 0.5 12.7652 0.5 12.5V1.5C0.5 1.23478 0.605357 0.98043 0.792893 0.792893C0.98043 0.605357 1.23478 0.5 1.5 0.5H8.586C8.85103 0.500057 9.1052 0.605321 9.29267 0.792667L11.2073 2.70733C11.3947 2.8948 11.4999 3.14897 11.5 3.414V5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
