import React from 'react';
import Markdown, { Options, Components } from 'react-markdown';

const defaultComponents: Components = {
  h1: ({ children }) => <h1 className='mt-4 mb-2 text-xl font-bold'>{children}</h1>,
  h2: ({ children }) => <h2 className='mt-4 mb-2 text-xl font-bold'>{children}</h2>,
  h3: ({ children }) => <h3 className='mt-4 mb-2 text-lg font-bold'>{children}</h3>,
  h4: ({ children }) => <h4 className='mt-4 mb-2 text-base font-bold'>{children}</h4>,
  h5: ({ children }) => <h5 className='mt-4 mb-2 text-base font-bold'>{children}</h5>,
  h6: ({ children }) => <h6 className='mt-4 mb-2 text-sm font-bold'>{children}</h6>,
  strong: ({ children }) => <strong className='font-bold'>{children}</strong>,
  p: ({ children }) => <p className='mb-2'>{children}</p>,
  ul: ({ children }) => <ul className='pt-2 pl-8 mb-1 list-disc'>{children}</ul>,
  ol: ({ children }) => <ol className='pt-2 pl-8 mb-1 list-decimal'>{children}</ol>,
  li: ({ children }) => <li className='mb-2'>{children}</li>,
  em: ({ children }) => <em className='italic'>{children}</em>,
  hr: () => <hr className='my-4 border border-gray-200' />
};

export const Preview: typeof Markdown = ({ children, components, ...rest }: Options) => {
  return (
    <Markdown {...rest} components={{ ...defaultComponents, ...components }}>
      {children}
    </Markdown>
  );
};
