import { Toast } from '@stores/toaster';

export const templateApplied = (title: string): Toast => ({
  heading: 'Your template was applied!',
  icon: 'success',
  text: `This interview is now using the "${title}" template.`
});

export const failedDelete = (): Toast => ({
  heading: 'Something went wrong!',
  icon: 'error',
  text: `We couldn’t delete this session. Please try again later.`
});

export const successDelete = (): Toast => ({
  heading: 'Recording deleted.',
  text: 'The recording was successfully deleted',
  icon: 'success'
});

export const failedLinkingZoom = (): Toast => ({
  heading: 'Error linking zoom recording',
  text: 'There was an error. Please try again later',
  icon: 'error'
});

export const failedFetching = (): Toast => ({
  heading: 'Error fetching recordings',
  text: 'There was an error fetching your Zoom recordings, please try again later',
  icon: 'error'
});

export const failedFetchingSession = (): Toast => ({
  heading: 'Error fetching interview',
  text: 'There was an error fetching this session, please try again later',
  icon: 'error'
});

export const successCopyClipLink = (): Toast => ({
  heading: 'Link was copied.',
  icon: 'success',
  text: 'Paste it wherever you like.'
});

export const successDeleteHighlight = (): Toast => ({
  heading: 'Highlight deleted.',
  icon: 'success',
  text: 'The highlight and related data have been deleted.'
});

export const failedDeleteHighlight = (): Toast => ({
  heading: 'Something went wrong!',
  icon: 'error',
  text: `We couldn’t delete this highlight. Please try again later.`
});

export const failedUpdate = (): Toast => ({
  heading: 'Something went wrong!',
  icon: 'error',
  text: `We couldn’t update this interview. Please try again later.`
});
