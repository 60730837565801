import { Enums, Models } from '@components/SurveyBuilder';
import { pick } from '@components/utils';

export enum UnmoderatedStorageKeys {
  ACTIVE_BLOCK = 'unmoderated_active_block',
  ANSWERS = 'unmoderated_answers'
}

export const parseStorageKey = ({
  key,
  participation
}: {
  key: UnmoderatedStorageKeys;
  participation?: Participation;
}): string => {
  if (!participation) {
    return key;
  }

  return `${key}_${participation.token}`;
};

export const clearStorage = (participation: Participation) => {
  localStorage.removeItem(parseStorageKey({ key: UnmoderatedStorageKeys.ACTIVE_BLOCK, participation }));
  localStorage.removeItem(parseStorageKey({ key: UnmoderatedStorageKeys.ANSWERS, participation }));
};

export const inferPermissionsFromBlocks = (
  blocks: Models.Block[]
): { [Device in keyof typeof Enums.UnmoderatedDevice]: Enums.DevicePermission } => {
  const permissionsBlock = blocks.find((b) => b.kind === Enums.Kind.permissions);

  if (permissionsBlock) {
    const blockable = permissionsBlock.blockable as unknown as Models.Blockable<Enums.Kind.permissions>;

    return pick(
      blockable,
      Enums.UnmoderatedDevice.camera,
      Enums.UnmoderatedDevice.microphone,
      Enums.UnmoderatedDevice.screen
    );
  }
  return {
    camera: Enums.DevicePermission.required,
    microphone: Enums.DevicePermission.required,
    screen: Enums.DevicePermission.required
  };
};
