import { useRef, useState } from 'react';

import { datadogRum } from '@datadog/browser-rum';

interface Params {
  action: string;
}

interface TrackDuration {
  isTracking: boolean;
  duration: number | null;
  start: () => void;
  stop: (params?: Record<string, any>) => void;
}

const NAME = 'user_activity';

export const useTrackDuration = ({ action }: Params): TrackDuration => {
  const DDRum = (window as any).DD_RUM as typeof datadogRum;
  const [isTracking, setIsTracking] = useState<boolean>(false);

  const nowRef = useRef<number | null>(null);
  const durationRef = useRef<number | null>(null);

  const start = () => {
    setIsTracking(true);
    nowRef.current = performance.now();
    durationRef.current = null;
  };

  const stop = (params) => {
    if (nowRef.current) {
      if (DDRum != null) {
        DDRum.addAction(NAME, {
          action,
          duration: (durationRef.current = performance.now() - nowRef.current),
          ...params
        });
      } else {
        console.error("Can't load DDRum -- addAction not sent", action, params);
      }
    }
    nowRef.current = null;
    setIsTracking(false);
  };

  return { isTracking, duration: durationRef.current, start, stop };
};
