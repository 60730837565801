import { api } from 'api/reduxApi';
import * as React from 'react';
import tinytime from 'tinytime';

import { Text } from '@components/common';

import { useState } from 'react';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');

const EVENT_TITLES: Record<StudyMessageEvent, string> = {
  booked: 'Booked confirmation',
  booked_reminder: 'Booked reminder',
  cancel_interview: 'Interview canceled',
  cancel_task: 'Task canceled',
  invite: 'Participation invite',
  invited_reminder: 'Participation invite reminder',
  reschedule_request: 'Booking reschedule request',
  screener: 'Screener invite',
  started_reminder: 'Started reminder',
  thanks: 'Thanks message',
  welcome: 'Welcome message',
  ad_hoc: 'Ad hoc message'
};

const Message: React.FC<{ message: ParticipationMessage }> = ({ message }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <li className='py-4'>
      <div className='flex flex-row items-center justify-between cursor-pointer' onClick={() => setExpanded(!expanded)}>
        <Text color='gray-500' h='400'>
          {message.event ? EVENT_TITLES[message.event] : message.title}
        </Text>
        <Text color='gray-500' h='400'>
          {template.render(new Date(message.sent_at))}
        </Text>
      </div>
      {expanded && (
        <div className='mt-2'>
          {message.subject && (
            <Text h='400' bold className='mt-2'>
              Subject: {message.subject}
            </Text>
          )}
          <Text h='400' className='mt-2 whitespace-pre-line'>
            {message.body || '(content not available)'}
          </Text>
        </div>
      )}
    </li>
  );
};

export const Messages: React.FC<{ participation: Participation }> = ({ participation }) => {
  const {
    data: messages,
    isLoading,
    isError,
    isSuccess
  } = api.useGetParticipationMessagesQuery({ id: participation.id });

  if (isLoading) {
    return (
      <Text mb='2' color='gray-500'>
        Loading...
      </Text>
    );
  }
  if (isError) {
    return (
      <Text mb='2' color='gray-500'>
        Unable to load messages
      </Text>
    );
  }
  if (isSuccess && !messages?.length) {
    return (
      <Text mb='2' color='gray-500'>
        No messages
      </Text>
    );
  }
  return (
    <ul className='divide-y divide-gray-200'>
      {messages?.map((message) => (
        <Message message={message} />
      ))}
    </ul>
  );
};
