import cn from 'classnames';
import React, { HTMLAttributes } from 'react';

export const BasicHeader: React.FC<{ renderActions?: () => JSX.Element }> = ({ renderActions, children }) => {
  return (
    <header className='z-10 px-12 py-4 bg-white border-b border-gray-200'>
      <section className='flex justify-between'>
        <div className='flex flex-row items-center flex-grow space-x-4'>{children}</div>
        <div className='flex flex-row items-center space-x-4'>{renderActions?.()}</div>
      </section>
    </header>
  );
};

export const PageLayout: React.FC = ({ children }) => (
  <div className='min-h-screen desktop:h-screen flex flex-col flex-1 desktop:mx-auto desktop:overflow-hidden'>
    {children}
  </div>
);
export const PageBody: React.FC = ({ children }) => (
  <section className='relative flex flex-col desktop:flex-row desktop:h-screen overflow-hidden w-full bg-white'>
    {children}
  </section>
);
export const PageLeft = React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...rest }, ref) => (
    <section
      ref={ref}
      className={cn('flex relative flex-col desktop:flex-1 desktop:overflow-y-auto pt-6 pb-6 pl-12 pr-6', className)}
      {...rest}
    >
      {children}
    </section>
  )
);
export const PageRight: React.FC<{ className?: string }> = ({ className, children }) => (
  <section
    data-testid='page-right'
    className={cn('flex flex-col desktop:h-full overflow-hidden desktop:border-l border-gray-200', className)}
  >
    {children}
  </section>
);
