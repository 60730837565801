import { api } from 'api/reduxApi';
import { FormGroup, Label } from 'components/StudiesApp/components/StudyDraft/pages/Plan/components';
import * as React from 'react';
import { useState, useEffect } from 'react';

import { Alert, Button, Card, Text } from '@components/common';
import {
  INCENTIVE_FIELDS,
  INCENTIVE_METHOD_OPTIONS,
  INCENTIVE_OPTIONS
} from '@components/StudiesApp/components/StudyDraft/pages/Plan/constants';
import { useStudyFunder } from '@components/Wallet';
import { ChangeIncentiveModal } from '@components/Wallet/ChangeIncentiveModal';

import { IncentiveForm } from '../../StudyDraft/pages/Plan/components/IncventivesOptions/components/IncentivesForm';
import { StepTitle } from './shared';
import { usePermission } from '@hooks/usePermission';

const IncentivesInfo: React.FC<{ study: Study }> = ({ study }) => {
  const method = study.incentive_method;
  const options = INCENTIVE_OPTIONS;
  const fields = method ? INCENTIVE_FIELDS[method] : [];
  const isMoney = ['tremendous', 'manual'].includes(method as any);
  const incentiveValue = isMoney ? 'money' : method || '';
  const currentOption = options.find((i) => i.value === incentiveValue);

  const incentiveMethodLabel = INCENTIVE_METHOD_OPTIONS.find((v) => v.value === method)?.label || 'No incentives';

  return (
    <>
      <FormGroup>
        <Label>Incentive type</Label>
        {currentOption && (
          <Text h='400' className='w-60 flex flex-col'>
            {currentOption.label}
          </Text>
        )}
      </FormGroup>

      {fields.includes('amount') && (
        <FormGroup>
          <Label>Incentive amount</Label>
          {study.incentive && (
            <Text h='400' className='w-60'>{`${study.currencySymbol}${study.incentive.toFixed(2)} ${
              study.currency
            }`}</Text>
          )}
        </FormGroup>
      )}

      {fields.includes('title') && (
        <FormGroup>
          <Label htmlFor='incentive_title'>Incentive title</Label>
          <Text h='400'>{study.incentive_title}</Text>
        </FormGroup>
      )}
      {fields.includes('coupons') && (
        <FormGroup>
          <Label htmlFor='incentive_coupons'>Coupon codes</Label>
          <Text h='400'>{study.incentive_coupons}</Text>
        </FormGroup>
      )}
      {fields.includes('instructions') && (
        <FormGroup>
          <Label htmlFor='incentive_instructions'>Redemption instructions</Label>
          <Text h='400'>{study.incentive_instructions}</Text>
        </FormGroup>
      )}

      {fields.includes('on_gq') && (
        <FormGroup>
          <Label>Payment method</Label>

          <Text h='400' className='pb-2'>
            {incentiveMethodLabel}
          </Text>
          {method === 'tremendous' && (
            <Text h='400' color='gray-500'>
              The participant can choose their incentive type via{' '}
              <a href='https://www.tremendous.com/' target='_blank' className='text-gray-500 underline'>
                Tremendous
              </a>
              .
            </Text>
          )}
        </FormGroup>
      )}
    </>
  );
};

export const Incentives: React.FC<PageProps> = ({ study: initialStudy, onSave }) => {
  const canUpdate = usePermission<Study>('updateStudy')(initialStudy);

  const canEdit = canUpdate && !['closed', 'archived'].includes(initialStudy.state) && !initialStudy.deleted_at;

  const [editing, setEditing] = useState<boolean>();
  const [fundModal, setFundModal] = useState<boolean>();
  const [study, setStudy] = useState<Study>(initialStudy);
  const [saved, setSaved] = useState<boolean>(false);

  const funder = useStudyFunder({ study });

  function handleCancel() {
    setStudy(initialStudy);
    setEditing(false);
    setFundModal(null as any);
  }

  async function handleSuccessfulSave() {
    const wallet = await funder.prepare({
      participant_limit: study.participant_limit,
      incentive: study.incentive,
      incentive_method: study.incentive_method
    });

    if (wallet) {
      setFundModal(true);
    } else {
      setEditing(false);
    }
  }

  useEffect(() => {
    if (saved) {
      handleSuccessfulSave();
      setSaved(false);
    }
  }, [initialStudy.participant_limit, initialStudy.incentive, initialStudy.incentive_method]);

  function handleSave() {
    setSaved(true);
    onSave(study);
  }

  function handleChange() {
    setFundModal(null as any);
    setEditing(false);
  }

  useEffect(() => {
    setStudy(initialStudy);
  }, [initialStudy]);

  return (
    <div className='px-page py-gutter'>
      <Card className='max-full px-12 py-8 mx-auto'>
        <div className='flex flex-row items-center justify-between'>
          <StepTitle>Incentives</StepTitle>
          {!editing && (
            <Button primary onClick={() => canEdit && setEditing(true)} disabled={!canEdit}>
              Edit
            </Button>
          )}
          {editing && (
            <div className='space-x-3'>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button primary onClick={handleSave}>
                Save
              </Button>
            </div>
          )}
        </div>
        {!study.can_change_incentives && (
          <Alert type={canEdit ? 'info' : 'warning'} bg='white' border className='my-4'>
            With active participants or recruitment requests, incentives cannot be removed.
          </Alert>
        )}
        {editing ? (
          <div className='max-w-xl'>
            <IncentiveForm study={study} onSave={setStudy} />
          </div>
        ) : (
          <IncentivesInfo study={initialStudy} />
        )}
      </Card>
      {fundModal && (
        <ChangeIncentiveModal
          limitCount={study.maximum_slots}
          funder={funder}
          study={study}
          onCancel={handleCancel}
          onConfirm={handleChange}
        />
      )}
    </div>
  );
};
