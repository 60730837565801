import * as React from 'react';
import { CellProps } from 'react-table';
import { format as timeagoFormat } from 'timeago.js';

import { Text } from '@components/common';

export const LastUpdated: React.FC<CellProps<AbstractTemplate>> = ({ row }) => (
  <Text h='400' className='whitespace-nowrap'>
    {timeagoFormat(row.original.updated_at)}
  </Text>
);
