import { useEffect } from 'react';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';
import { useGetGuideQuery } from '@components/GuidesApp/api';

interface Hook {
  loading: boolean;
  guide: Guide | null;
}

export const useGuide = (token: string): Hook => {
  const { data: guide = null, isError, isLoading } = useGetGuideQuery(token);
  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedGet());
    }
  }, [isError]);

  return { guide, loading: isLoading };
};
