import React from 'react';

// components
import { Input } from '@components/common';

interface Props {
  onChange: (value: string) => void;
  value: string;
}

export const SearchInput: React.FC<Props> = ({ onChange, value }) => (
  <div className='mx-4 mb-6'>
    <Input
      iconClassName='text-indigo-600'
      icon='search'
      placeholder='Search...'
      value={value}
      size='lg'
      onChange={onChange}
    />
  </div>
);
