import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Column,
  TableOptions,
  useAbsoluteLayout,
  useColumnOrder,
  useFilters,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useTable
} from 'react-table';
import { Tabs } from '@components/common';
import { Paginator } from '@components/shared/Paginator';
import { SortDropdown } from '@components/shared/SortDropdown';
import { GridTable, GridTableProps } from '@components/shared/GridTable';
import { ELocalStorageKeys } from '@constants/localStorageKeys';
import { useAccount } from '@hooks/useAccount';
import { useLocalStorage } from '@hooks/useLocalStorage';

import { CandidateMessageTab, buildLabels, tabs } from './tabs';
import { PageHeader } from '@components/shared/PageHeader';
import { AnyAllToggle, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { compact, without } from '@components/utils';
import { api } from '@api/reduxApi';
import { useCollectionView } from '@stores/view';
import ReactSkeleton from 'react-loading-skeleton';
import { useColumnResizeObserver } from '@components/shared/GridTable/hooks/useColumnResizeObserver';
import { buildMessagesTableColumns } from '@components/StudiesApp/components/StudyPublished/components/Stats/buildMessagesTableColumns';
import { ProfileContextProvider } from '@hooks/useProfileContext';
import { ProfileSlideout } from '@components/RepoSessionApp/ProfileSlideout';
import { usePermission } from '@hooks/usePermission';
import { getProfileSlideOutSections } from '@components/StudiesApp/components/StudyPublished/components/ParticipantsTab/utils';
import { BulkActions } from '@components/StudiesApp/components/StudyPublished/components/BulkActions';
import { StudyAction } from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { EmailSlideOuts } from '@components/StudiesApp/components/StudyPublished/components/EmailSlideouts';
import { parse } from '@components/shared/TableFilters/utils/encode';
import { buildMessagesAttrs } from '@components/StudiesApp/components/StudyPublished/components/Stats/buildMessagesAttrs';

interface Props {
  study: Study;
  event: string;
  setBackgroundTasks: (tasks: BackgroundTask[]) => void;
  backgroundTasks: BackgroundTask[];
}

export const MessagesTable: React.FC<Props> = ({ study, event, setBackgroundTasks, backgroundTasks }) => {
  const [page, setPage] = useState(0);
  const [mode, setMode] = useState<StudyAction | null>(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const [participationId, setParticipationId] = useState<number | null>(null);
  const { data: slots, refetch: refetchSlots } = api.useGetStudySlotsBalanceQuery(study.id, { skip: !study.id });
  const [query, setQuery] = useState<string>('');

  const { data: participation } = api.useGetParticipationQuery(
    { id: participationId as number },
    { skip: !participationId }
  );

  const {
    account: { team }
  } = useAccount();
  const viewHook = useCollectionView();

  const canUpdate = usePermission<Study>('updateStudy')(study);

  const {
    view: { sort, tab, columns: visibleColumns },
    setView
  } = viewHook;

  const [currentTab, setCurrentTab] = useState<CandidateMessageTab>(tab as CandidateMessageTab);

  const attrs = useMemo(() => buildMessagesAttrs(currentTab === 'canceled'), [currentTab]);

  const filtersHook = useTableFilters<CandidateMessage>({
    definitions: attrs.filters,
    trackKey: 'stats-filter',
    syncWithURL: true,
    query
  });

  const filters: string[] = [
    ...filtersHook.filters.map((filter) => parse<CandidateMessage>(filter) || ''),
    `event is ${event}`
  ];

  const { data, isLoading } = api.useGetStudyCandidateMessagesQuery(
    {
      id: study.id,
      page: page + 1,
      q: query,
      sort: sort.value,
      sortDesc: sort.desc,
      filters: compact([...filters, currentTab !== 'all' && `state is ${currentTab}`])
    },
    {
      refetchOnMountOrArgChange: true
    }
  );

  const { data: messageCounts } = api.useGetStudyCandidateMessageCountsQuery({
    id: study.id,
    q: query,
    filters
  });

  const [columnsWidth, setColumnsWidth] = useLocalStorage<Record<string, number>>(
    ELocalStorageKeys.MESSAGES_TABLE_COLUMNS_WIDTH
  );

  const [search, setSearch] = useState<string>('');

  const addFilter = useCallback(
    (filter: string) => {
      const def = filtersHook.definitions.find((d) => d.id === filter);

      if (def) {
        filtersHook.addFilter(def);
      }
    },
    [filtersHook.definitions, setView]
  );

  const setSortValue = useCallback(
    (value: string, desc: boolean): void => {
      setView({
        sort: { value: value, desc: desc }
      });
    },
    [setView]
  );

  const onSortChange = ({ value }: { value: string }): void => {
    setSortValue(value, sort.desc);
  };

  const setVisibleColumns = useCallback((columns) => setView({ columns }), [setView]);

  const toggleColVisibility = React.useCallback(
    (name: string) => {
      const cols = visibleColumns?.includes(name)
        ? compact(without(visibleColumns, name))
        : [...(visibleColumns || []), name];

      setVisibleColumns(cols);
    },
    [visibleColumns]
  );

  const onClickCandidate = (id: number) => {
    setParticipationId(id);
    setProfileOpen(true);
  };

  const columns = useMemo<Column<CandidateMessage>[]>(
    () =>
      buildMessagesTableColumns({
        storageKey: ELocalStorageKeys.MESSAGES_TABLE_ORDER,
        addFilter,
        setSortValue,
        columnsWidth,
        onClickCandidate,
        team,
        showCancelationReason: currentTab === 'canceled'
      }).map((c) => ({ ...c, toggleColVisibility })),
    [setSortValue, columnsWidth, addFilter, toggleColVisibility, currentTab]
  );

  const hiddenColumns = useMemo(() => {
    return compact(columns.filter(({ id }) => !visibleColumns?.includes(id as string)).map((c) => c.id));
  }, [columns, visibleColumns]);

  const tableOptions = useMemo<TableOptions<CandidateMessage>>(
    () => ({
      columns,
      data: data?.data || [],
      autoResetFilters: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      manualPagination: true,
      manualSortBy: true,
      pageCount: data?.meta.pages,
      initialState: {
        hiddenColumns,
        pageIndex: 0,
        sortBy: [{ id: sort.value, desc: sort.desc }]
      }
    }),
    [columns, data?.data, sort.value, sort.desc, data?.meta.pages]
  );

  const tableInstance = useTable<CandidateMessage>(
    tableOptions,
    useAbsoluteLayout,
    useResizeColumns,
    useFilters,
    usePagination,
    useRowSelect,
    useColumnOrder
  );

  const {
    rows,
    state: { selectedRowIds, pageIndex },
    setHiddenColumns,
    totalColumnsWidth,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage
  } = tableInstance;

  const onResize = (columnId: string, columnSize: number) => {
    setColumnsWidth({ ...columnsWidth, [columnId]: columnSize });
  };

  useColumnResizeObserver(tableInstance.state, onResize);

  useEffect(() => {
    setPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns]);

  const handleParticipationsUpdate = (backgroundTask?: BackgroundTask): void => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
    }

    setMode(null);
    setParticipationId(null);
  };

  const handleEmailSuccess = (backgroundTask?: BackgroundTask, mode?: StudyAction) => {
    if (backgroundTask) {
      setBackgroundTasks([...backgroundTasks, backgroundTask]);
    }
    setMode(null);
    setParticipationId(null);
  };

  const gridTableProps = useMemo<GridTableProps>(
    () => ({
      tableInstance,
      tableColumnsOrder: ELocalStorageKeys.MESSAGES_TABLE_ORDER,
      columnOptions: attrs.columnOptions,
      visibleColumnNames: visibleColumns || [],
      setVisibleColumnNames: setVisibleColumns
    }),
    [totalColumnsWidth, attrs.columnOptions, visibleColumns, selectedRowIds, rows]
  );

  const renderProfileSlideoutActions = (activeParticipation: Participation) => () =>
    canUpdate &&
    activeParticipation && (
      <BulkActions
        onSlideoutActions
        canSchedule={!!slots?.remaining && slots.remaining >= 1}
        participations={[activeParticipation]}
        closeAll={() => setParticipationId(null)}
        selectedIds={[activeParticipation.id]}
        study={study}
        currentStatus={activeParticipation.status}
        onSuccess={handleParticipationsUpdate}
        mode={mode}
        closeProfileSlideout={() => setProfileOpen(false)}
        setMode={setMode}
        candidateId={activeParticipation.customer_id}
      />
    );

  if (isLoading) {
    return (
      <div data-testid='candidates-messages-table-skeleton' className='mt-44 w-full min-h-screen'>
        <ReactSkeleton duration={1} className='bg-gray-50 block w-full h-8 rounded-md' count={15} />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <div>
      <PageHeader
        h1='Email logs'
        transparent
        shortPage
        withBorders={false}
        filtersApplied={filtersHook.filters.length > 0}
        searchProps={{ onSearch: setSearch, value: search, onEnd: setQuery, addDebounce: true }}
        renderPagination={() => (
          <Paginator
            canClickNext={canNextPage}
            canClickPrevious={canPreviousPage}
            onClickNext={nextPage}
            onClickPrevious={previousPage}
            current={data.meta?.page}
            total={data.meta?.pages}
          />
        )}
        renderFilters={() => (
          <div className='flex items-center justify-between flex-1'>
            <TableFilters<CandidateMessage> hook={filtersHook} defaultShowInput={!filtersHook.filters.length} />
            <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
              <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
            </div>
          </div>
        )}
        renderSortBy={() => (
          <SortDropdown
            wrapperClass='w-full'
            options={attrs.sortOptions}
            value={sort.value}
            onChange={onSortChange}
          />
        )}
      />

      <div className='mb-2'>
        <Tabs<CandidateMessageTab>
          tabs={tabs}
          labels={buildLabels(messageCounts)}
          current={currentTab}
          onSelect={setCurrentTab}
        />
      </div>
      <GridTable {...gridTableProps} />

      {participation?.customer && profileOpen && (
        <ProfileContextProvider>
          <ProfileSlideout
            canUpdate={canUpdate}
            candidate={participation.customer}
            renderActions={renderProfileSlideoutActions(participation)}
            open={profileOpen}
            onClose={() => setProfileOpen(false)}
            showSections={getProfileSlideOutSections({ study, activeParticipation: participation })}
          />
        </ProfileContextProvider>
      )}
      {participation && (
        <EmailSlideOuts
          mode={mode}
          study={study}
          participations={[participation]}
          onClose={() => {
            setProfileOpen(false);
            setMode(null);
            setParticipationId(null);
          }}
          onSuccess={handleEmailSuccess}
        />
      )}
    </div>
  );
};

export { Props as MessagesTableProps };
