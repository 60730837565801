import { DropdownLink } from '@components/common';
import * as React from 'react';
import { MemberDropdown } from './MemberDropdown';
import { Role } from '../TeamUserRow';
import cn from 'classnames';

interface Props {
  studyRole: Role;
  isOwnerOrCreator: boolean;
  onRemove?: () => void;
  isCurrentUser?: boolean;
}

export const CanViewDropdowns: React.FC<Props> = ({ isCurrentUser, studyRole, isOwnerOrCreator, onRemove }) => {
  return isOwnerOrCreator ? (
    <div className='px-4 w-32 text-sm text-gray-700'>{studyRole}</div>
  ) : (
    <MemberDropdown studyRole={studyRole}>
      <div className={cn('px-4', isCurrentUser && 'border-b')}>
        <p className='py-2 text-sm text-gray-700'>
          To give creator or owner access, <a>upgrade role permissions</a>
        </p>

        {isCurrentUser && (
          <DropdownLink color='red-600' onClick={onRemove}>
            Leave
          </DropdownLink>
        )}
      </div>
    </MemberDropdown>
  );
};
