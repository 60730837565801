import React from 'react';
import { MasterCardSVG, VisaSVG } from '@components/svgs';

export const CardDetails: React.FC<{ card: CardPaymentMethod }> = ({ card }) => (
  <div className='flex flex-row items-center space-x-2'>
    {card.brand.toLowerCase() === 'visa' && <VisaSVG className='h-5 w-5 inline mr-2' />}
    {card.brand.toLowerCase() === 'mastercard' && <MasterCardSVG className='h-5 w-5 inline mr-2' />}
    <span>
      {card.brand} ending in {card.last4}
    </span>
  </div>
);
