import cn from 'classnames';
import { useCombobox } from 'downshift';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import { Input, TeamIcon, Text } from '@components/common';
import { TeamSVG } from '@components/svgs';
import { usePermission } from '@hooks/usePermission';
import { usePopUp } from '@hooks/usePopUp';
import { useSearch } from '@hooks/useSearch';
import { useTeams } from '@hooks/useTeams';
import { useUser } from '@hooks/useUser';

const NO_TEAM = 'NO_TEAM';

type Props = {
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  label?: string;
  value?: number | null;
  onChange: (teamId: number | null) => void;
  placement?: 'right' | 'left';
};
export const TeamPicker: React.FC<Props> = ({
  disabled,
  className,
  label = 'Change team',
  value,
  placement = 'right',
  onChange
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { teams: allTeams, findTeam } = useTeams();
  const user = useUser();

  const teams = allTeams?.filter((team) => user.teams?.map((t) => t.id).includes(team.id));

  const team = value ? findTeam(value) : null;

  const { PopUp, ref, open, togglePopUp, closePopUp } = usePopUp();

  useEffect(() => {
    if (open) {
      inputRef?.current?.focus();
    }
  }, [open, inputRef]);

  const {
    highlightedIndex,
    getComboboxProps,
    getLabelProps,
    getItemProps,
    getInputProps,
    getMenuProps,
    reset,
    inputValue,
    setInputValue
  } = useCombobox<Team | typeof NO_TEAM>({
    items: [NO_TEAM, ...(teams || [])],
    itemToString: (item) => (item === NO_TEAM ? 'No team' : item?.name || ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        reset();
        closePopUp();
        onChange(selectedItem === NO_TEAM ? null : selectedItem?.id);
      }
    }
  });

  const search = useSearch(teams || [], ['name'], inputValue, []);

  const results: Array<Team | typeof NO_TEAM> = [...search.results];

  if (!inputValue) {
    results.unshift(NO_TEAM);
  }

  return (
    <div className='relative' ref={ref}>
      <button
        disabled={disabled}
        className={cn('max-w-64 truncate group py-2.5 px-3 border-gray-200 border rounded-md', className, {
          'hover:text-indigo-600 hover:bg-gray-50': !disabled
        })}
        name={label}
        aria-label={label}
        data-testid={label}
        onClick={togglePopUp}
      >
        {team && (
          <div className='flex items-center space-x-2'>
            <TeamIcon team={team} />
            <Text h='400'>{team.name || 'Untitled team'}</Text>
          </div>
        )}
        {!team && (
          <div className='flex items-center'>
            <TeamSVG className='mr-2' />
            <Text className='group-hover:text-indigo-600 text-gray-500' h='400'>
              No team
            </Text>
          </div>
        )}
      </button>
      <PopUp
        zIndex={30}
        renderOffScreen
        className={cn(
          { 'right-0': placement === 'right', 'left-0': placement === 'left' },
          'mt-2 bg-white border border-gray-200 rounded-md shadow-sm'
        )}
        open={open}
      >
        <div {...getComboboxProps()}>
          <div className='px-4 pt-4'>
            <Text {...getLabelProps()} h='200' color='gray-500' uppercase bold mb='1'>
              {label}
            </Text>
          </div>

          <div className='px-4 py-3'>
            <Input
              {...getInputProps({ ref: inputRef })}
              className='h400 w-80'
              value={inputValue}
              onChange={setInputValue}
              placeholder='Search…'
            />
          </div>

          {results.length === 0 && (
            <div className='px-4 py-4 text-center'>
              <Text h='400' color='gray-500'>
                No results
              </Text>
            </div>
          )}

          <ul {...getMenuProps()} className='max-h-80 w-full max-w-sm mb-2 overflow-auto'>
            {results.map((team, index) => (
              <li
                {...getItemProps({ item: team, index })}
                key={team === NO_TEAM ? 'no-team' : team.id}
                aria-label={team === NO_TEAM ? 'No team' : team.name}
                className={cn('hover:bg-gray-50 cursor-pointer w-full p-4 px-4', {
                  'bg-gray-50': highlightedIndex === index
                })}
              >
                <div className='flex items-center'>
                  <TeamIcon className='mr-2' team={team === NO_TEAM ? null : team} />
                  <Text truncate h='400'>
                    {team === NO_TEAM ? 'No team' : team.name}
                  </Text>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </PopUp>
    </div>
  );
};
