import Tippy from '@tippyjs/react';
import { DropdownLink } from '@components/common';
import { noop } from '@components/utils';
import * as React from 'react';
import { MemberDropdown } from './MemberDropdown';

export const AdminDropdowns = ({
  setOwner,
  studyRole,
  isStudyOwner,
  canSetOwner,
  cantSetOwnerMessage,
  onClickRemove
}) => {
  return isStudyOwner ? (
    <Tippy content={'To change role or remove, you must first assign another study owner.'}>
      <div>
        <MemberDropdown studyRole={studyRole} disabled={isStudyOwner}>
          <></>
        </MemberDropdown>
      </div>
    </Tippy>
  ) : (
    <MemberDropdown studyRole={studyRole}>
      <div>
        {canSetOwner && <DropdownLink onClick={setOwner}>Set as owner</DropdownLink>}
        {!canSetOwner && (
          <Tippy content={cantSetOwnerMessage()}>
            <div>
              <DropdownLink onClick={noop} disabled>
                Set as owner
              </DropdownLink>
            </div>
          </Tippy>
        )}
        <DropdownLink color='red-600' onClick={onClickRemove}>
          Remove from study
        </DropdownLink>
      </div>
    </MemberDropdown>
  );
};
