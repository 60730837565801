import * as React from 'react';

export const AppErroredSVG: React.FC<any> = (props) => (
  <svg {...props} width='300' height='187' viewBox='0 0 300 187' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M78.2802 113.966C109.751 113.966 135.263 88.4536 135.263 56.9828C135.263 25.5121 109.751 0 78.2802 0C46.8094 0 21.2974 25.5121 21.2974 56.9828C21.2974 88.4536 46.8094 113.966 78.2802 113.966Z'
      fill='#F9FAFB'
    />
    <path d='M0 13.6819C0 34.4145 13.0066 51.1911 29.0802 51.1911L0 13.6819Z' fill='#374151' />
    <path d='M29.0801 51.1912C29.0801 30.2256 43.5947 13.2605 61.5319 13.2605L29.0801 51.1912Z' fill='#5850EC' />
    <path d='M10.5364 15.5613C10.5364 35.2551 18.8304 51.191 29.0803 51.191L10.5364 15.5613Z' fill='#5850EC' />
    <path d='M29.0801 51.1911C29.0801 24.4017 45.8567 2.72412 66.5893 2.72412L29.0801 51.1911Z' fill='#374151' />
    <path
      d='M22.9624 51.4555C22.9624 51.4555 27.0864 51.3285 28.3293 50.4434C29.5722 49.5584 34.6731 48.5016 34.9815 49.921C35.2898 51.3404 41.1792 56.9805 36.5231 57.0181C31.8671 57.0556 25.7046 56.2928 24.4641 55.5372C23.2236 54.7816 22.9624 51.4555 22.9624 51.4555Z'
      fill='#9FA6B2'
    />
    <path
      opacity='0.2'
      d='M36.6062 56.5241C31.9502 56.5616 25.7877 55.7988 24.5472 55.0432C23.6025 54.4677 23.226 52.4028 23.1001 51.4502C23.0128 51.4539 22.9624 51.4556 22.9624 51.4556C22.9624 51.4556 23.2236 54.7816 24.4641 55.5373C25.7046 56.2929 31.8671 57.0557 36.5231 57.0181C37.8671 57.0073 38.3314 56.5291 38.3059 55.8209C38.1192 56.2488 37.6066 56.516 36.6062 56.5241Z'
      fill='#374151'
    />
    <path
      d='M53.1329 120.37C80.7621 120.37 103.16 117.323 103.16 113.564C103.16 109.806 80.7621 106.759 53.1329 106.759C25.5036 106.759 3.10571 109.806 3.10571 113.564C3.10571 117.323 25.5036 120.37 53.1329 120.37Z'
      fill='#9FA6B2'
    />
    <path
      opacity='0.1'
      d='M53.133 119.278C76.3298 119.278 95.1344 116.72 95.1344 113.564C95.1344 110.409 76.3298 107.85 53.133 107.85C29.9363 107.85 11.1316 110.409 11.1316 113.564C11.1316 116.72 29.9363 119.278 53.133 119.278Z'
      fill='#374151'
    />
    <path
      d='M223.814 187C265.627 187 299.523 182.389 299.523 176.7C299.523 171.012 265.627 166.401 223.814 166.401C182.001 166.401 148.104 171.012 148.104 176.7C148.104 182.389 182.001 187 223.814 187Z'
      fill='#9FA6B2'
    />
    <path
      d='M83.0957 178.874C108.213 178.874 128.575 176.104 128.575 172.687C128.575 169.27 108.213 166.5 83.0957 166.5C57.9782 166.5 37.6165 169.27 37.6165 172.687C37.6165 176.104 57.9782 178.874 83.0957 178.874Z'
      fill='#9FA6B2'
    />
    <path
      d='M123.76 167.471C147.837 173.624 194.119 159.445 199.202 143.393'
      stroke='#374151'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M82.8281 69.2891C82.8281 69.2891 117.606 59.6582 119.747 90.6911C121.887 121.724 91.1214 130.552 115.199 136.705'
      stroke='#374151'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M49.2278 143.737C49.2278 143.737 59.6806 140.842 60.3239 150.169C60.9671 159.496 51.7204 162.15 58.9569 163.999'
      stroke='#374151'
      strokeWidth='2'
      strokeMiterlimit='10'
    />
    <path
      d='M208.323 150.681L206.216 164.136C206.216 164.136 195.84 169.648 203.135 169.81C210.43 169.972 244.798 169.81 244.798 169.81C244.798 169.81 251.444 169.81 240.907 163.974L238.8 149.87L208.323 150.681Z'
      fill='#374151'
    />
    <path
      opacity='0.1'
      d='M201.668 169.664C203.325 168.19 206.216 166.654 206.216 166.654L208.323 153.198L238.8 153.227L240.907 166.491C243.364 167.852 244.887 168.896 245.777 169.696C247.131 169.387 248.61 168.24 240.907 163.974L238.8 149.87L208.323 150.681L206.216 164.136C206.216 164.136 197.497 168.768 201.668 169.664Z'
      fill='#374151'
    />
    <path
      d='M287.226 56.8994H159.573C156.907 56.8994 154.745 59.0608 154.745 61.7271V147.717C154.745 150.384 156.907 152.545 159.573 152.545H287.226C289.892 152.545 292.053 150.384 292.053 147.717V61.7271C292.053 59.0608 289.892 56.8994 287.226 56.8994Z'
      fill='#374151'
    />
    <path d='M287.433 62.0061H159.365V133.659H287.433V62.0061Z' fill='#9FA6B2' />
    <path
      d='M223.399 60.5471C223.847 60.5471 224.21 60.1842 224.21 59.7366C224.21 59.2889 223.847 58.926 223.399 58.926C222.952 58.926 222.589 59.2889 222.589 59.7366C222.589 60.1842 222.952 60.5471 223.399 60.5471Z'
      fill='#F9FAFB'
    />
    <path
      d='M292.053 139.333V147.717C292.053 148.351 291.929 148.979 291.686 149.565C291.444 150.151 291.088 150.683 290.64 151.131C290.191 151.58 289.659 151.935 289.073 152.178C288.488 152.42 287.86 152.545 287.226 152.545H159.573C158.939 152.545 158.311 152.42 157.725 152.178C157.14 151.935 156.607 151.58 156.159 151.131C155.711 150.683 155.355 150.151 155.113 149.565C154.87 148.979 154.745 148.351 154.745 147.717V139.333H292.053Z'
      fill='#374151'
    />
    <path
      d='M259.226 178.564V180.185H172.01V178.888L172.13 178.564L174.28 172.728H257.443L259.226 178.564Z'
      fill='#374151'
    />
    <path
      d='M292.789 176.977C292.631 177.656 292.031 178.373 290.676 179.05C285.812 181.482 275.923 178.402 275.923 178.402C275.923 178.402 268.304 177.105 268.304 173.701C268.518 173.554 268.741 173.422 268.972 173.304C271.017 172.222 277.796 169.552 289.817 173.417C290.703 173.695 291.494 174.213 292.102 174.914C292.589 175.484 292.971 176.207 292.789 176.977Z'
      fill='#374151'
    />
    <path
      opacity='0.1'
      d='M292.789 176.977C286.837 179.258 281.531 179.428 276.085 175.646C273.339 173.739 270.844 173.268 268.972 173.303C271.017 172.222 277.796 169.552 289.817 173.416C290.702 173.694 291.493 174.213 292.102 174.914C292.589 175.484 292.971 176.207 292.789 176.977Z'
      fill='#374151'
    />
    <path
      d='M285.326 175.646C286.49 175.646 287.433 175.355 287.433 174.997C287.433 174.639 286.49 174.349 285.326 174.349C284.162 174.349 283.218 174.639 283.218 174.997C283.218 175.355 284.162 175.646 285.326 175.646Z'
      fill='#F9FAFB'
    />
    <path
      d='M223.399 149.06C225.101 149.06 226.48 147.681 226.48 145.98C226.48 144.278 225.101 142.899 223.399 142.899C221.698 142.899 220.319 144.278 220.319 145.98C220.319 147.681 221.698 149.06 223.399 149.06Z'
      fill='#F9FAFB'
    />
    <path opacity='0.1' d='M259.226 178.564V180.185H172.01V178.888L172.13 178.564H259.226Z' fill='#374151' />
    <path d='M84.7009 42.5364H29.0557V107.277H84.7009V42.5364Z' fill='#374151' />
    <path d='M90.319 36.116H23.4377V59.1231H90.319V36.116Z' fill='#9FA6B2' />
    <path d='M90.319 63.4033H23.4377V86.4105H90.319V63.4033Z' fill='#9FA6B2' />
    <path d='M90.319 90.6909H23.4377V113.698H90.319V90.6909Z' fill='#9FA6B2' />
    <path opacity='0.4' d='M76.9425 40.1287H72.6621V44.4091H76.9425V40.1287Z' fill='#5850EC' />
    <path opacity='0.8' d='M83.0958 40.1287H78.8154V44.4091H83.0958V40.1287Z' fill='#5850EC' />
    <path d='M89.2487 40.1287H84.9683V44.4091H89.2487V40.1287Z' fill='#5850EC' />
    <path opacity='0.4' d='M76.9425 67.1487H72.6621V71.4291H76.9425V67.1487Z' fill='#5850EC' />
    <path opacity='0.8' d='M83.0958 67.1487H78.8154V71.4291H83.0958V67.1487Z' fill='#5850EC' />
    <path d='M89.2487 67.1487H84.9683V71.4291H89.2487V67.1487Z' fill='#5850EC' />
    <path opacity='0.4' d='M76.9425 94.1687H72.6621V98.4491H76.9425V94.1687Z' fill='#5850EC' />
    <path opacity='0.8' d='M83.0958 94.1687H78.8154V98.4491H83.0958V94.1687Z' fill='#5850EC' />
    <path d='M89.2487 94.1687H84.9683V98.4491H89.2487V94.1687Z' fill='#5850EC' />
    <path
      d='M84.7009 165.063C96.3731 165.063 105.835 155.601 105.835 143.928C105.835 132.256 96.3731 122.794 84.7009 122.794C73.0286 122.794 63.5664 132.256 63.5664 143.928C63.5664 155.601 73.0286 165.063 84.7009 165.063Z'
      fill='#374151'
    />
    <path d='M81.4907 160.515H75.0701V172.019H81.4907V160.515Z' fill='#374151' />
    <path d='M94.3317 160.515H87.9111V172.019H94.3317V160.515Z' fill='#374151' />
    <path
      d='M80.4206 174.159C83.3756 174.159 85.7711 173.261 85.7711 172.152C85.7711 171.044 83.3756 170.146 80.4206 170.146C77.4656 170.146 75.0701 171.044 75.0701 172.152C75.0701 173.261 77.4656 174.159 80.4206 174.159Z'
      fill='#374151'
    />
    <path
      d='M93.2616 173.891C96.2166 173.891 98.6121 172.993 98.6121 171.885C98.6121 170.777 96.2166 169.878 93.2616 169.878C90.3066 169.878 87.9111 170.777 87.9111 171.885C87.9111 172.993 90.3066 173.891 93.2616 173.891Z'
      fill='#374151'
    />
    <path
      d='M85.2359 145.801C89.2251 145.801 92.459 142.567 92.459 138.578C92.459 134.589 89.2251 131.355 85.2359 131.355C81.2466 131.355 78.0127 134.589 78.0127 138.578C78.0127 142.567 81.2466 145.801 85.2359 145.801Z'
      fill='white'
    />
    <path
      d='M85.2358 140.986C86.5656 140.986 87.6435 139.908 87.6435 138.578C87.6435 137.248 86.5656 136.17 85.2358 136.17C83.9061 136.17 82.8281 137.248 82.8281 138.578C82.8281 139.908 83.9061 140.986 85.2358 140.986Z'
      fill='#9FA6B2'
    />
    <path
      d='M64.1997 124.274C62.4931 116.631 67.9482 108.909 76.3838 107.025C84.8195 105.142 93.0413 109.81 94.7478 117.453C96.4543 125.095 90.8637 127.914 82.4281 129.798C73.9924 131.681 65.9062 131.917 64.1997 124.274Z'
      fill='#5850EC'
    />
    <path
      d='M104.523 139.713C109.888 137.417 113.653 134.19 112.932 132.506C112.212 130.821 107.278 131.317 101.912 133.614C96.547 135.91 92.7819 139.137 93.5028 140.822C94.2237 142.506 99.1576 142.01 104.523 139.713Z'
      fill='#374151'
    />
    <path
      d='M64.9292 153.892C70.2946 151.596 74.0596 148.369 73.3387 146.684C72.6178 145 67.6839 145.496 62.3186 147.792C56.9532 150.089 53.1882 153.316 53.9091 155C54.63 156.685 59.5639 156.188 64.9292 153.892Z'
      fill='#374151'
    />
    <path
      d='M97.007 150.082C97.007 152.15 91.6809 156.235 85.7709 156.235C79.8609 156.235 74.2673 152.418 74.2673 150.349C74.2673 148.281 79.8609 148.744 85.7709 148.744C91.6809 148.744 97.007 148.013 97.007 150.082Z'
      fill='white'
    />
  </svg>
);
