import { WaitlistedSVG } from 'components/svgs';
import * as React from 'react';
import { Row } from 'react-table';

// components
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { CalendarWithClockSVG, ClockRefreshSVG } from '@components/svgs';
// libs
import Tippy from '@tippyjs/react';

import { ParticipationTableItem } from '../../helpers/buildParticipantsColumns';

interface Props {
  row: Row<ParticipationTableItem>;
  onClickReviewTimeProposal: () => void;
}

export const StatusCell: React.FC<Props> = ({ row, onClickReviewTimeProposal }) => {
  return (
    <div className='inline-flex items-center pl-2 space-x-2'>
      <ParticipationPill participation={row.original.participation} />

      {row.original.participation.waitlisted_at && (
        <Tippy content='On waitlist'>
          <span>
            <WaitlistedSVG className='text-yellow-600' aria-label='On waitlist' />
          </span>
        </Tippy>
      )}

      {row.original.participation.reschedule_requested_at && (
        <Tippy content='Sent reschedule request'>
          <span>
            <ClockRefreshSVG className='text-gray-500' />
          </span>
        </Tippy>
      )}

      {row.original.participation.time_proposal && (
        <Tippy content='Proposed alternative times'>
          <button
            className='hover:bg-gray-100 flex items-center justify-center w-6 h-6 rounded-full'
            aria-label='Review time proposal'
            name='Review time proposal'
            onClick={onClickReviewTimeProposal}
          >
            <CalendarWithClockSVG className='text-red-600' />
          </button>
        </Tippy>
      )}
    </div>
  );
};
