import * as React from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';

import { getFormKey } from '../helpers/getFormKey';
import { InputProps, QuestionProps } from '../types';

type PillProps = Pick<UseFormMethods, 'register'> & {
  name?: string;
  label?: string;
  id?: string;
  value?: string;
  selected?: boolean;
  required?: boolean;
  onFocus?: React.FormEventHandler;
  inputType: 'checkbox' | 'radio';
  error?: FieldError;
  autoFocus?: boolean;
};
const Pill: React.FC<PillProps> = ({
  id,
  selected,
  value,
  label,
  inputType,
  name,
  autoFocus,
  error,
  required,
  onFocus,
  register
}) => {
  return (
    <label
      onFocus={onFocus}
      className={`${
        selected ? 'border-indigo-600 text-indigo-600' : ''
      } px-4 py-5 border border-gray-200 hover:bg-gray-50 rounded-md inline-block normal-case text-base font-normal mb-2 cursor-pointer w-full text-center tablet:w-auto`}
      key={value}
    >
      <input
        id={id}
        autoFocus={autoFocus}
        type={inputType}
        className='form-checkbox hidden mr-2'
        name={name}
        ref={register({ required })}
        value={value}
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={`description-for-${id}`}
        aria-required={required ? 'true' : 'false'}
      />
      {label || value}
    </label>
  );
};
export const MultipleChoicePillInput: React.FC<InputProps> = ({ field, watch, onFocus, register, error }) => {
  const { id, required, options, other } = field;
  const name = id.toString();
  const labelName = getFormKey(field);

  const selected = watch(labelName);

  const visibleOptions = options?.filter((e) => e != '');

  return (
    <>
      <div className='tablet:space-x-2 flex flex-wrap justify-center'>
        {visibleOptions?.map((o, i) => (
          <Pill
            id={String(id)}
            name={labelName}
            value={o}
            autoFocus={i === 0}
            inputType='checkbox'
            onFocus={onFocus}
            register={register}
            selected={selected?.includes(o)}
          />
        ))}
        {other && (
          <Pill
            id={String(id)}
            name={labelName}
            value={'other'}
            label={'Other'}
            inputType='checkbox'
            register={register}
            selected={selected?.includes('other')}
          />
        )}
      </div>
      {selected?.includes('other') && (
        <div className='relative w-full rounded-md shadow-sm'>
          <input
            id={String(id)}
            type='text'
            autoFocus
            className='form-input block w-full py-3'
            placeholder='Please specify'
            name={`${name}.other`}
            onFocus={onFocus}
            ref={register({ required: required || selected?.includes('other') })}
          />
        </div>
      )}
    </>
  );
};
export const SingleChoicePillInput: React.FC<InputProps> = ({ field, watch, onFocus, register, error }) => {
  const { id, required, options, other } = field;
  const name = id.toString();
  const labelName = getFormKey(field);

  const selected = watch(labelName);

  const visibleOptions = options?.filter((e) => e != '');

  return (
    <>
      <div className='tablet:space-x-2 flex flex-wrap justify-center'>
        {visibleOptions?.map((o, i) => (
          <Pill
            id={String(id)}
            name={labelName}
            value={o}
            inputType='radio'
            autoFocus={i === 0}
            register={register}
            selected={selected?.includes(o)}
            error={error}
          />
        ))}
        {other && (
          <Pill
            error={error}
            id={String(id)}
            name={labelName}
            value={'other'}
            inputType='radio'
            label={'Other'}
            autoFocus
            onFocus={onFocus}
            register={register}
            selected={selected?.includes('other')}
          />
        )}
      </div>
      {selected?.includes('other') && (
        <div className='relative w-full rounded-md shadow-sm'>
          <input
            autoFocus
            id={String(id)}
            type='text'
            className='form-input block w-full py-3'
            placeholder='Please specify'
            name={`${name}.other`}
            aria-invalid={error ? 'true' : 'false'}
            ref={register({ required: required || selected?.includes('other') })}
          />
        </div>
      )}
    </>
  );
};
