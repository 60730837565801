import { Option } from 'components/common';
import { ParticipationTableItem } from 'components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/buildParticipantsColumns';
import * as React from 'react';
import { MutableRefObject } from 'react';
import { Row } from 'react-table';
import tinytime from 'tinytime';

import { UpdateCellData } from '@components/shared/GridTable/components/inputs/types';
import { SelectSubset } from '@components/shared/GridTable/components/SelectSubsetDropdown';
import { compact, uniq } from '@components/utils';

import { OnToggleProps } from '../types';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

export const getFormattedDate = (value) => {
  return value ? dateTemplate.render(new Date(value)) : undefined;
};

export const getFormattedMoney = (value) => {
  return `${value / 100} USD`;
};

export const getHiddenColumnNames = (all: Option[], visible: string[]): string[] => {
  return all.filter(({ value }) => !visible.includes(value)).map(({ value }) => value);
};

interface OnSelectValueProps<T> {
  currentValue: T;
  initialValue: T;
  setState: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  rowId: string;
  columnId: string;
  updateCellData: UpdateCellData;
}

type OnSelectValue = <T>(props: OnSelectValueProps<T>) => void;

export const onSelectValue: OnSelectValue = async ({
  currentValue,
  initialValue,
  setState,
  rowId,
  columnId,
  updateCellData
}) => {
  if (currentValue === initialValue) return;

  setState?.((prev) => {
    return {
      ...prev,
      [rowId]: { ...prev[rowId], [columnId]: currentValue }
    };
  });

  const attr = columnId.slice(0, 5) === 'extra' ? columnId.slice(6) : columnId;
  await updateCellData(+rowId, attr, currentValue);
};

export const updateAttrValues = (customAttrs, columnId, value, setCustomAttrs) => {
  const newCustomAttrs = customAttrs.map((attr) => {
    if (attr.name !== columnId) {
      return attr;
    }

    const currentAttr = { ...attr };

    if (currentAttr?.attr_type === 'multiple_choice') {
      currentAttr.values = uniq([...currentAttr.values, ...(value as any)]);
    }

    if (currentAttr?.attr_type === 'text') {
      currentAttr.values = uniq([...currentAttr.values, value]);
    }
    return currentAttr;
  });
  setCustomAttrs(newCustomAttrs);
};

interface UpdateCellDataParams {
  rowIndex: number;
  columnId: string;
  value: any;
  data: (ParticipationTableItem | Candidate)[];
  customAttrs: any;
  setCustomAttrs: React.Dispatch<React.SetStateAction<any>>;
  updateLocalCandidate?: (c: Candidate) => void;
  updateCandidate: (candidate: { id: number } & Partial<Candidate>) => any;
}

export const updateCellData = async ({
  rowIndex,
  columnId,
  value,
  data,
  customAttrs,
  setCustomAttrs,
  updateLocalCandidate,
  updateCandidate
}: UpdateCellDataParams) => {
  const res = await Promise.all(
    data.map(async (row, index) => {
      if (getStateKey({ original: row } as any) === rowIndex) {
        const candidateId =
          (data[index] as ParticipationTableItem)?.participation?.customer_id || (data[index] as Candidate).id;
        const updateResult = await updateCandidate({ id: candidateId, [columnId]: value }).unwrap();

        updateLocalCandidate?.(updateResult);

        if (value || value === 0) {
          updateAttrValues(customAttrs, columnId, value, setCustomAttrs);
        }

        return updateResult;
      }
    })
  );

  return res;
};

export const selectSubset: SelectSubset = ({ number, type }, rows, toggleRowSelected, setIds) => {
  const candidatesNumber = parseInt(number);

  if (!candidatesNumber || candidatesNumber < 1) return;

  const availableToSelect = rows.length > candidatesNumber ? candidatesNumber : rows.length;

  const notSelectedRows = compact(
    rows.map((item) => {
      if (!item.isSelected) return item;
    })
  );

  // when the user selects more candidates than are available, we set the maximum number of available candidates as the user's choice
  const availableToRandom = notSelectedRows.length > candidatesNumber ? candidatesNumber : notSelectedRows.length;
  // select number of candidates from the start of the table
  if (type === 'top') {
    for (let i = 0; i < availableToSelect; i++) {
      toggleRowSelected(rows[i].id, true);
      setIds?.((rows[i] as Row<ParticipationTableItem>).original.participation.id);
    }
  } else {
    // select number of random candidates to check from the available pull
    const nums = new Set();

    while (nums.size < availableToRandom) {
      const number = Math.floor(Math.random() * rows.length - 1) + 1;

      if (!rows[number].isSelected) {
        nums.add(number);
      }
    }
    nums.forEach((item: number) => {
      toggleRowSelected(rows[item].id, true);
      setIds?.((rows[item] as Row<ParticipationTableItem>).original.participation.id);
    });
  }
};

interface HandleToggleProps extends OnToggleProps {
  clicked: MutableRefObject<any>;
  setIds?: React.Dispatch<React.SetStateAction<number[]>>;
}

export const handleToggle = ({
  index,
  e,
  setSelectedIds: setCandidateIds,
  toggleRowSelected,
  setIds,
  rows,
  row,
  clicked
}: HandleToggleProps): void => {
  const setSelectedIds = (currentId: number) => {
    setIds?.((prev) => {
      let ids = prev;
      if (row.isSelected) {
        ids = prev.filter((id) => id !== currentId);
      }

      if (!row.isSelected && !prev.includes(currentId)) {
        ids = [...prev, currentId];
      }

      return ids;
    });
  };

  // if clicked first time or w/o shift key
  if (!e.shiftKey || !clicked?.current) {
    clicked.current = index + 1;
    // if selected subset from top to bottom
  } else if (e.shiftKey && clicked?.current && clicked.current < index + 1) {
    setCandidateIds?.({ startIndex: clicked.current - 1, endIndex: index, row, rows });

    for (let i = clicked.current; i < index + 1; i++) {
      toggleRowSelected?.(rows[i].id, !row.isSelected);
      setSelectedIds((rows[i] as Row<ParticipationTableItem>).original.participation.id);
    }
    // if selected subset from bottom to top
  } else if (e.shiftKey && clicked?.current && clicked.current > index + 1) {
    setCandidateIds?.({ startIndex: index, endIndex: clicked.current - 1, row, rows });

    for (let i = clicked.current - 1; i > index; i--) {
      toggleRowSelected?.(rows[i].id, !row.isSelected);
      setSelectedIds((rows[i] as Row<ParticipationTableItem>).original.participation.id);
    }
  }
};

interface HandleHover extends Omit<OnToggleProps, 'toggleRowSelected' | 'setSelectedIds' | 'selectedIds'> {
  clicked: MutableRefObject<any>;
  setHoveredRows: React.Dispatch<React.SetStateAction<number[]>>;
}

export const handleHover = ({ index, e, rows, row, clicked, setHoveredRows }: HandleHover): void => {
  // logic is similar to the handleToggleCheckBox func
  if (e.shiftKey && clicked?.current && !row.isSelected) {
    const newRows: number[] = [];

    if (clicked.current < index + 1) {
      for (let i = clicked.current; i <= index; i++) {
        newRows.push(rows.indexOf(rows[i]));
      }
    } else {
      for (let i = clicked.current - 1; i >= index; i--) {
        newRows.push(rows.indexOf(rows[i]));
      }
    }

    setHoveredRows(newRows);
  }
};

export const getStateKey = (row: Row<ParticipationTableItem | Candidate>) =>
  (row as Row<ParticipationTableItem>).original.participation?.customer_id || (row as Row<Candidate>).original.id;

export const getSelectedString = (allSelected, selectedLength) => {
  if (allSelected) {
    return ' (all selected) ';
  } else if (selectedLength > 0) {
    return ` (${selectedLength.toLocaleString()} selected) `;
  } else {
    return '';
  }
};
