type NavShortcutDefinition = {
  meta?: boolean;
  shift?: boolean;
  keys: string;
  href: string;
  spa: boolean;
};

export const NAV_SHORTCUT_DEFINITIONS: Record<string, NavShortcutDefinition> = {
  dashboard: {
    keys: 'G then D',
    href: '/',
    spa: true
  },
  candidates: {
    keys: 'G then C',
    href: '/candidates',
    spa: true
  },
  studies: {
    keys: 'G then S',
    href: '/studies',
    spa: true
  },
  repository: {
    keys: 'G then R',
    href: '/repository',
    spa: true
  },
  incentives: {
    keys: 'G then I',
    href: '/incentives',
    spa: false
  },
  invite_teammate: {
    keys: 'I',
    meta: true,
    shift: true,
    href: '/invitations/new',
    spa: false
  },
  changelog: {
    keys: 'G then L',
    href: 'https://greatquestion.co/support/releases-updates/changelog',
    spa: false
  },
  templates: {
    keys: 'G then T',
    href: '/study_templates',
    spa: false
  },
  help_center: {
    keys: 'G then H',
    href: 'http://docs.greatquestion.co/en/',
    spa: false
  },
  guides: {
    keys: 'G then U',
    href: '/guides',
    spa: true
  },
  getting_started: {
    keys: 'G then E',
    href: '/getting_started',
    spa: false
  },
  new_csv_import: {
    keys: 'N then I',
    href: '/customer_imports/new',
    spa: false
  }
};

export const getKeysObject = ({ keys, meta = false, shift = false }: NavShortcutDefinition): Record<string, boolean> =>
  keys.split(' then ').reduce(
    (obj, key) => {
      obj[key] = true;
      return obj;
    },
    { meta, shift }
  );
