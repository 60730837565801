import React, { SVGProps } from 'react';

export const RepositoryRecommendedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='256' height='100' viewBox='0 0 256 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='78.5' y='0.5' width='99' height='99' rx='3.5' fill='white' stroke='#9FA6B2' />
    <path
      d='M115.663 68C115.045 67.9983 114.438 67.8466 113.898 67.5592C113.32 67.2605 112.838 66.8164 112.503 66.2747C112.169 65.7331 111.995 65.1143 112 64.485V35.5077C112 34.8794 112.176 34.2626 112.51 33.7218C112.844 33.181 113.323 32.7361 113.897 32.4335C114.472 32.1309 115.121 31.9818 115.776 32.0018C116.431 32.0217 117.069 32.21 117.622 32.5469L146.303 47.0355C146.823 47.3522 147.251 47.7896 147.548 48.3072C147.844 48.8248 148 49.4058 148 49.9963C148 50.5869 147.844 51.1679 147.548 51.6855C147.251 52.2031 146.823 52.6405 146.303 52.9572L117.622 67.4458C117.038 67.8058 116.358 67.9981 115.663 68Z'
      fill='#6C63FF'
    />
    <rect x='0.5' y='15.5' width='69' height='69' rx='3.5' fill='white' stroke='#E5E7EB' />
    <path
      d='M30.6803 45.6804L33.9091 42.4518V55.8181C33.9091 56.4205 34.3975 56.909 35 56.909C35.6025 56.909 36.0909 56.4205 36.0909 55.8181V42.4519L39.3194 45.6804C39.5324 45.8934 39.8116 45.9999 40.0908 45.9999C40.37 45.9999 40.6492 45.8935 40.8622 45.6804C41.2882 45.2543 41.2882 44.5637 40.8622 44.1376L35.7716 39.047C35.746 39.0215 35.7192 38.9973 35.6913 38.9744C35.6793 38.9645 35.6665 38.9559 35.6543 38.9467C35.6381 38.9346 35.6223 38.9222 35.6055 38.9109C35.5905 38.9008 35.5748 38.8921 35.5593 38.8829C35.5443 38.8738 35.5295 38.8644 35.514 38.856C35.4982 38.8476 35.482 38.8405 35.4659 38.8329C35.4497 38.8253 35.4338 38.8172 35.4172 38.8103C35.4016 38.8039 35.3857 38.7986 35.37 38.793C35.3522 38.7866 35.3347 38.7797 35.3164 38.7742C35.3007 38.7695 35.2848 38.7661 35.269 38.7621C35.2504 38.7573 35.2319 38.7521 35.213 38.7483C35.1948 38.7447 35.1764 38.7426 35.1581 38.7399C35.1413 38.7375 35.1247 38.7343 35.1076 38.7327C35.074 38.7294 35.0403 38.7278 35.0065 38.7276C35.0044 38.7276 35.0023 38.7273 35.0001 38.7273C34.998 38.7273 34.9958 38.7276 34.9937 38.7276C34.9599 38.7277 34.9262 38.7294 34.8925 38.7327C34.8759 38.7343 34.8596 38.7374 34.8431 38.7398C34.8243 38.7425 34.8055 38.7447 34.787 38.7484C34.7685 38.752 34.7506 38.7571 34.7325 38.7617C34.7161 38.7658 34.6996 38.7695 34.6833 38.7743C34.6658 38.7797 34.6487 38.7863 34.6316 38.7925C34.6152 38.7983 34.5987 38.8037 34.5825 38.8104C34.5665 38.817 34.5513 38.8248 34.5357 38.8322C34.519 38.84 34.5021 38.8475 34.4857 38.8562C34.4708 38.8642 34.4569 38.8732 34.4424 38.8818C34.4262 38.8914 34.4099 38.9005 34.3941 38.9111C34.3783 38.9216 34.3636 38.9334 34.3484 38.9447C34.335 38.9546 34.3214 38.9639 34.3084 38.9746C34.2819 38.9963 34.2565 39.0194 34.2321 39.0434C34.231 39.0446 34.2297 39.0455 34.2285 39.0467L29.1376 44.1375C28.7116 44.5636 28.7116 45.2543 29.1376 45.6803C29.5636 46.1064 30.2543 46.1063 30.6803 45.6804Z'
      fill='#E5E7EB'
    />
    <path
      d='M45.9091 49.6364C45.3066 49.6364 44.8182 50.1248 44.8182 50.7273V59.0909H25.1818V50.7273C25.1818 50.1248 24.6934 49.6364 24.0909 49.6364C23.4884 49.6364 23 50.1248 23 50.7273V60.1818C23 60.7843 23.4884 61.2727 24.0909 61.2727H45.9091C46.5116 61.2727 47 60.7843 47 60.1818V50.7273C47 50.1248 46.5116 49.6364 45.9091 49.6364Z'
      fill='#E5E7EB'
    />
    <rect x='186.5' y='15.5' width='69' height='69' rx='3.5' fill='white' stroke='#E5E7EB' />
    <path
      d='M216.68 45.6804L219.909 42.4518V55.8181C219.909 56.4205 220.398 56.909 221 56.909C221.602 56.909 222.091 56.4205 222.091 55.8181V42.4519L225.319 45.6804C225.532 45.8934 225.812 45.9999 226.091 45.9999C226.37 45.9999 226.649 45.8935 226.862 45.6804C227.288 45.2543 227.288 44.5637 226.862 44.1376L221.772 39.047C221.746 39.0215 221.719 38.9973 221.691 38.9744C221.679 38.9645 221.667 38.9559 221.654 38.9467C221.638 38.9346 221.622 38.9222 221.606 38.9109C221.59 38.9008 221.575 38.8921 221.559 38.8829C221.544 38.8738 221.53 38.8644 221.514 38.856C221.498 38.8476 221.482 38.8405 221.466 38.8329C221.45 38.8253 221.434 38.8172 221.417 38.8103C221.402 38.8039 221.386 38.7986 221.37 38.793C221.352 38.7866 221.335 38.7797 221.316 38.7742C221.301 38.7695 221.285 38.7661 221.269 38.7621C221.25 38.7573 221.232 38.7521 221.213 38.7483C221.195 38.7447 221.176 38.7426 221.158 38.7399C221.141 38.7375 221.125 38.7343 221.108 38.7327C221.074 38.7294 221.04 38.7278 221.007 38.7276C221.004 38.7276 221.002 38.7273 221 38.7273C220.998 38.7273 220.996 38.7276 220.994 38.7276C220.96 38.7277 220.926 38.7294 220.893 38.7327C220.876 38.7343 220.86 38.7374 220.843 38.7398C220.824 38.7425 220.806 38.7447 220.787 38.7484C220.769 38.752 220.751 38.7571 220.733 38.7617C220.716 38.7658 220.7 38.7695 220.683 38.7743C220.666 38.7797 220.649 38.7863 220.632 38.7925C220.615 38.7983 220.599 38.8037 220.583 38.8104C220.567 38.817 220.551 38.8248 220.536 38.8322C220.519 38.84 220.502 38.8475 220.486 38.8562C220.471 38.8642 220.457 38.8732 220.442 38.8818C220.426 38.8914 220.41 38.9005 220.394 38.9111C220.378 38.9216 220.364 38.9334 220.348 38.9447C220.335 38.9546 220.321 38.9639 220.308 38.9746C220.282 38.9963 220.257 39.0194 220.232 39.0434C220.231 39.0446 220.23 39.0455 220.229 39.0467L215.138 44.1375C214.712 44.5636 214.712 45.2543 215.138 45.6803C215.564 46.1064 216.254 46.1063 216.68 45.6804Z'
      fill='#E5E7EB'
    />
    <path
      d='M231.909 49.6364C231.307 49.6364 230.818 50.1248 230.818 50.7273V59.0909H211.182V50.7273C211.182 50.1248 210.693 49.6364 210.091 49.6364C209.488 49.6364 209 50.1248 209 50.7273V60.1818C209 60.7843 209.488 61.2727 210.091 61.2727H231.909C232.512 61.2727 233 60.7843 233 60.1818V50.7273C233 50.1248 232.512 49.6364 231.909 49.6364Z'
      fill='#E5E7EB'
    />
  </svg>
);
