import * as React from 'react';
import ZDSIcon from './ScreenerResponsesZDS';

import tinytime from 'tinytime';
const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const ZDS: React.FC<{ first_name?: string | null }> = ({ first_name }) => (
  <div className='py-6 text-center'>
    <span className='mx-auto'>
      <ZDSIcon />
    </span>
    <p className='mt-4 text-gray-500'>We'll show {first_name ? `${first_name}'s` : 'their'} screener responses here.</p>
  </div>
);

interface AnswerProps {
  label: string;
  value: string | string[] | Record<string, any>;
  submitted_at: Date;
  title: string;
  field_type: string;
  showInfo?: boolean;
}
const Answer: React.FC<AnswerProps> = ({ label, value, submitted_at, title, field_type, showInfo }) => {
  const renderer = () => {
    if (field_type == 'multiple_choice') {
      return (Array.isArray(value) ? value : [value]).map((v) => (
        <span key={v} className='block'>
          {v}
        </span>
      ));
    }

    if (field_type === 'prototype_test' && typeof value === 'object' && 'completed' in value) {
      return <span>{value.completed ? 'Success' : 'Failed'}</span>;
    }

    return value;
  };

  return (
    <li className='py-4 border-b border-gray-100'>
      <div className=''>
        <h3 className='flex-1 mb-2 text-sm font-medium text-gray-700'>{label}</h3>
        <h3 className=' flex-1 text-sm font-normal text-gray-700'>{renderer()}</h3>
      </div>
      {showInfo && (
        <div className='flex flex-row items-center mt-2 text-xs leading-normal text-gray-500'>
          {submitted_at && <span className='mr-2'>{dateTemplate.render(new Date(submitted_at))}</span>}
          <svg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='2' cy='2' r='2' fill='#E5E7EB' />
          </svg>
          <span className='ml-2'>{title}</span>
        </div>
      )}
    </li>
  );
};

interface Props {
  candidate: Candidate;
  answers: any[];
  hidden_answers: any[];
  showInfo?: boolean;
}
const ScreenerResponses: React.FC<Props> = ({ candidate, answers, hidden_answers, showInfo = true }) => {
  const hiddenKeys = Object.keys(hidden_answers || {});
  if (answers.length === 0) {
    return <ZDS first_name={candidate.first_name} />;
  }

  return (
    <ul>
      {answers.map((answer) => (
        <Answer key={answer.label} {...answer} showInfo={showInfo} />
      ))}
      {hiddenKeys.length > 0 && (
        <li className='py-4 border-b border-gray-100'>
          <h3 className='flex-1 mb-2 text-sm font-medium text-gray-700'>Hidden fields</h3>
          {hiddenKeys.map((key) => (
            <h3 key={key} className=' flex-1 text-sm font-normal text-gray-700'>
              {key}: {hidden_answers[key]}
            </h3>
          ))}
        </li>
      )}
    </ul>
  );
};
export default ScreenerResponses;
