import * as React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@components/common';

import { CellFC } from './types';

export const Extra: CellFC = ({ row }) => (
  <div>
    {row.original.type === 'recording' && row.original.status === 'uploading' && row.original.cancelUpload && (
      <Button link onClick={row.original.cancelUpload}>
        Cancel
      </Button>
    )}
    {row.original.status === 'done' && row.original.session && (
      <Link to={`/sessions/${row.original.session.uuid}`} target='_blank'>
        View
      </Link>
    )}
  </div>
);
