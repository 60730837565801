import cn from 'classnames';
import { secondsToTimestamp } from 'components/utils';
import React, { forwardRef, HTMLAttributes, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Spinner } from '@components/common';
import { Player } from '@lib/canvas-player/Player';

interface Props extends HTMLAttributes<HTMLDivElement> {
  player: Player;
  timestamp: number;
}

export const Thumbnail = forwardRef<HTMLDivElement, Props>(({ player, timestamp, className, ...rest }, ref) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [img, setImg] = useState<string>();
  const seconds = Math.floor(timestamp / 1000);

  const { callback } = useDebouncedCallback(async () => {
    const i = await player.getFrame(timestamp);
    setImg(i);
    setIsLoading(false);
  }, 100);

  useEffect(() => {
    setIsLoading(true);
    callback();
  }, [player, seconds]);

  return (
    <div ref={ref} className={cn('text-center', className)} {...rest}>
      {isLoading ? (
        <div className='flex items-center justify-center w-full h-20'>
          <Spinner className='w-4 h-4' />
        </div>
      ) : (
        <img alt='Thumbnail preview of the video clip' src={img} className='w-full h-20 border border-gray-500 rounded' />
      )}

      <span className='mt-1 text-xs'>{secondsToTimestamp(seconds, 4)}</span>
    </div>
  );
});
