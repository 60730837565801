import cn from 'classnames';
import { useCombobox } from 'downshift';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { compact, noop } from '@components/utils';
import * as PopperJS from '@popperjs/core';
import { Spinner } from '../Spinner';
import { Portal } from '../Portal';

type DropdownItem = {
  label: string;
  value: string;
  search?: string;
  _isNew?: boolean;
  disabled?: boolean;
  data?: any;
  text?: string;
};

export type RenderDropdownItem = (item: DropdownItem, isHighlighted?: boolean) => React.ReactNode;

interface Props {
  disabled?: boolean;
  error?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  allowCreate?: boolean;
  defaultIsOpen?: boolean;
  items: DropdownItem[];
  tight?: boolean;
  selectedItem?: DropdownItem;
  placeholder: string | React.ReactNode;
  inputClassName?: string;
  submitOnEnter?: boolean; // whether hitting enter should just pick the first item
  // submitOnBlur?: boolean; // whether hitting click out should do it
  resetOnBlur?: boolean;
  onClickOutside?: () => void;
  resetOnSelect?: boolean;
  blurOnSelect?: boolean;
  onSelect: (item: DropdownItem | null) => void;
  renderItem?: RenderDropdownItem;
  renderInput?: (inputProps: any) => React.ReactNode;
  renderEmptyState?: (value: string) => React.ReactNode;
  fullWidth?: boolean;
  noBorder?: boolean;
  onFocus?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  inputStyle?: React.CSSProperties;
  submitLastValue?: boolean;
  limitedWidthDropdown?: boolean;
  showItemsByDefault?: boolean;
  tabIndex?: number;
  openOnTop?: boolean;
  maxHeight?: number;
  maxItems?: number;
  dropdownWidth?: number | string;
  absolute?: boolean;
  unlimitedItems?: boolean;
  onInputChange?: (value: string) => void;
  wrapperWidth?: string;
  popperOptions?: Omit<Partial<PopperJS.Options>, 'modifiers'>;
  adjustablePopper?: boolean;
  isLoading?: boolean;
  syncWidth?: boolean;
  renderOnBodyRoot?: boolean;
}

const LIST_STYLES = {
  default: 'divide-y divide-y-gray-200',
  tight: ''
};

const ITEM_STYLES = {
  default: 'py-2',
  tight: 'py-1'
};

const shallowCompareObjects = (object1: any, object2: any): boolean =>
  Object.keys(object1).length === Object.keys(object2).length &&
  Object.keys(object1).every((key) => object1[key] === object2[key]);

const shallowCompareArrays = (array1: any[], array2: any[]): boolean =>
  array1.length === array2.length && array1.every((value, index) => shallowCompareObjects(value, array2[index]));

const useShallowCompareArray = (array: any[]): boolean => {
  const instanceValueRef = useRef(array);

  if (shallowCompareArrays(instanceValueRef.current, array)) {
    return true;
  }

  instanceValueRef.current = array;
  return false;
};

const DEFAULT_POPPER_OPTIONS: Omit<Partial<PopperJS.Options>, 'modifiers'> = {
  placement: 'bottom-start'
};

export const DropdownCombobox: React.FC<Props> = ({
  disabled,
  error,
  required,
  allowCreate,
  defaultIsOpen,
  autoFocus,
  items,
  selectedItem,
  placeholder,
  tight = false,
  submitOnEnter,
  resetOnBlur = false,
  resetOnSelect = false,
  blurOnSelect = false,
  onClickOutside = noop,
  onSelect,
  inputClassName: propsInputClassName,
  renderItem,
  inputStyle,
  renderInput,
  onFocus,
  onBlur,
  renderEmptyState,
  fullWidth,
  wrapperWidth,
  noBorder,
  submitLastValue,
  limitedWidthDropdown,
  showItemsByDefault,
  tabIndex,
  openOnTop = false,
  maxHeight = 250,
  maxItems = 30,
  dropdownWidth,
  absolute = true,
  unlimitedItems,
  onInputChange,
  popperOptions = DEFAULT_POPPER_OPTIONS,
  renderOnBodyRoot,
  isLoading,
  adjustablePopper
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [defaultItems, setDefaultItems] = useState<DropdownItem[]>(items);
  const [inputItems, setInputItems] = useState<DropdownItem[]>([]);
  const style = tight ? 'tight' : 'default';
  const inputRef = useRef<HTMLInputElement>(null);
  const itemsShallowEqual = useShallowCompareArray(items);
  const [triggerRef, setTriggerRef] = React.useState<HTMLElement | null>(null);
  const [menuRef, setMenuRef] = React.useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(triggerRef, menuRef, {
    ...popperOptions
  });

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    setInputValue,
    closeMenu,
    openMenu
  } = useCombobox({
    selectedItem,
    defaultIsOpen,
    items: inputItems,
    onIsOpenChange: ({ isOpen }) => {
      if (!isOpen) {
        onClickOutside();
        if (resetOnBlur) {
          setInputValue('');
        }
      }
    },
    itemToString: (item) => (item ? item.label : ''),
    onSelectedItemChange: ({ selectedItem }) => {
      if (resetOnSelect) setInputValue('');
      if (blurOnSelect) inputRef.current?.blur();
      if (selectedItem) {
        onSelect(selectedItem);
      }
    },
    onInputValueChange: ({ inputValue, type }) => {
      if (onInputChange) {
        onInputChange(inputValue || '');
      }
      const filteredItems = items.filter((item) =>
        (item.search || item.label).toLowerCase().includes((inputValue || '').toLowerCase())
      );

      // TODO: this needs more smarts... as the label may not actually match but we have one selected.
      const notExactMatch = inputItems.every(({ label, value }) => label !== inputValue && value !== inputValue);

      setInputItems(
        compact([
          ...filteredItems,
          allowCreate &&
            inputValue &&
            inputValue.length >= 3 &&
            notExactMatch && { label: `Create ${inputValue}`, value: inputValue, search: '', _isNew: true }
        ]) as any
      );
    }
  });

  const inputProps = getInputProps({
    disabled,
    ref: inputRef,
    onFocus: (e) => {
      onFocus?.(e);
      if (!isOpen) {
        openMenu();
      }
    },
    style: inputStyle,
    onBlur,
    onKeyDown: (e) => {
      if (e.key === 'Enter' && submitOnEnter) {
        if (highlightedIndex > -1) {
          onSelect(inputItems[highlightedIndex]);
        } else {
          // preventing of creation the new attr if the one with exact label is already exists
          const exactMatch = inputItems.some(({ label }) => label === inputValue);

          if (exactMatch) {
            const valueToSelect = inputItems.find(({ label }) => label === inputValue);
            if (valueToSelect) {
              onSelect(valueToSelect);
            }
          } else {
            const valueToSelect = submitLastValue ? inputItems[inputItems.length - 1] : inputItems[0];
            onSelect(inputValue === '' ? null : valueToSelect);
          }
          setInputValue('');
          closeMenu();
        }
      }
    },
    tabIndex
  });

  const showEmptyState = renderEmptyState && inputValue.length > 0 && inputItems.length === 0;

  const wrapperClass = cn('relative', { 'w-full': fullWidth, [`w-${wrapperWidth}`]: wrapperWidth });

  const dropdownVisible = (isOpen && inputItems.length > 0) || showEmptyState;

  const inputClassName = cn(
    'xx-combo-input border border-gray-200 h400 rounded-md w-full py-2.5 px-4 text-gray-700 placeholder-gray-400 focus:outline-none',
    error ? 'focus:border-red-600' : 'focus:border-indigo-600',
    error && 'border-red-600',
    propsInputClassName
  );

  const dropdownClass = cn(
    'z-40 overflow-y-auto',
    absolute && 'absolute',
    dropdownWidth && `w-${dropdownWidth}`,
    dropdownVisible && LIST_STYLES[style],
    {
      'border border-gray-200 shadow-lg': !noBorder && dropdownVisible,
      'w-full': !limitedWidthDropdown && !dropdownWidth,
      'w-56': limitedWidthDropdown && !dropdownWidth,
      'bottom-full': openOnTop,
      'rounded-t-md': openOnTop,
      'rounded-b-md': !openOnTop,
      'bg-white pt-2 pb-2': dropdownVisible
    }
  );

  useEffect(
    () => {
      setDefaultItems(items);

      if (showItemsByDefault) {
        setInputItems(items);

        // we should revisit this... not being able to focus input
        // using autoFocus or on mount (only when mounted on RichEditor)
        inputRef.current?.focus();
      }
    },
    // avoid to many state updates
    [itemsShallowEqual]
  );

  useEffect(() => {
    if (defaultItems) {
      setInputItems(defaultItems);
    }
  }, [defaultItems]);

  const inputPlaceholder = typeof placeholder === 'string' ? placeholder : '';

  const width = limitedWidthDropdown || dropdownWidth ? undefined : triggerRef?.getBoundingClientRect().width;

  return (
    <div ref={ref} className={wrapperClass}>
      <div
        {...getComboboxProps({
          ref: setTriggerRef
        })}
      >
        {!renderInput && (
          <div className='relative'>
            <input
              {...inputProps}
              className={inputClassName}
              placeholder={inputPlaceholder}
              autoComplete='off'
              name='dropdown_combobox'
              aria-label='dropdown_combobox'
              autoFocus={autoFocus}
              required={required}
              aria-invalid={error}
            />
            {!!placeholder && typeof placeholder !== 'string' && !isOpen && (
              <div className='absolute inset-0 pointer-events-none'>{placeholder}</div>
            )}
            {isLoading && <Spinner className='right-2 absolute top-0 bottom-0 w-4 h-4 m-auto' />}
          </div>
        )}
        {renderInput && renderInput(inputProps)}
      </div>
      <Portal id={renderOnBodyRoot ? 'gq-popper-root' : undefined}>
        <ul
          {...getMenuProps({
            style: {
              maxHeight,
              width,
              ...(adjustablePopper ? styles.popper : {})
            },
            ref: setMenuRef
          })}
          {...(adjustablePopper ? attributes.popper : {})}
          className={dropdownClass}
        >
          {showEmptyState && renderEmptyState(inputValue)}
          {(isOpen || showItemsByDefault) &&
            (unlimitedItems ? inputItems : inputItems.slice(0, maxItems)).map((item, index) => (
              <li
                className={`${
                  highlightedIndex === index
                    ? 'bg-indigo-600 text-white'
                    : item._isNew
                    ? 'text-indigo-600'
                    : 'text-gray-700'
                } group block w-full px-4 hover:bg-indigo-600 hover:text-white cursor-pointer xx-combo-option ${
                  ITEM_STYLES[style]
                }`}
                key={`${item}${index}`}
                {...getItemProps({
                  item,
                  index,
                  onClick: () => {
                    if (resetOnSelect) setTimeout(() => setInputValue(''), 0);
                  }
                })}
              >
                {renderItem ? renderItem(item, highlightedIndex === index) : item.label}
              </li>
            ))}
        </ul>
      </Portal>
    </div>
  );
};

export { Props as DropdownComboboxProps, DropdownItem };
