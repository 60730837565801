import React, { SVGProps } from 'react';

export const CheckmarkInCircleSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3.5 7.71357L4.92917 9.74182C4.98338 9.8228 5.05614 9.88966 5.14141 9.93683C5.22668 9.98401 5.32198 10.0101 5.41938 10.0131C5.51679 10.016 5.61348 9.99557 5.70141 9.95356C5.78934 9.91156 5.86597 9.84917 5.92492 9.77157L10.5 3.98315'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.4375 6.99951C0.4375 8.73999 1.1289 10.4092 2.35961 11.6399C3.59032 12.8706 5.25952 13.562 7 13.562C8.74048 13.562 10.4097 12.8706 11.6404 11.6399C12.8711 10.4092 13.5625 8.73999 13.5625 6.99951C13.5625 5.25903 12.8711 3.58983 11.6404 2.35912C10.4097 1.12842 8.74048 0.437012 7 0.437012C5.25952 0.437012 3.59032 1.12842 2.35961 2.35912C1.1289 3.58983 0.4375 5.25903 0.4375 6.99951V6.99951Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
