export const capitalize = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeAllWords = (s: string): string => {
  if (typeof s !== 'string') return '';
  return s
    .split(' ')
    .map((word) => capitalize(word))
    .join(' ');
};

// remove any possible list decorator but only from the start of the string and trim spaces
export const removeListDecorators = (text: string): string =>
  text
    .replace(/\d\.\s+|[a-z]\)\s+|[a-z]\.\s+|[A-Z]\)\s+|\d\)\s+|[•●⁃✓▪]\s+|[A-Z]\.\s+|[IVX]+\.\s+|[ivx]+\.\s+/, '')
    .trim();

export const slugify = (s: string): string => {
  return s
    .toLowerCase()
    .replace(/(\s+)/g, '-')
    .replace(/[^\w-]+/g, '');
};

export const pascalize = (text: string) =>
  text
    .split(/\s|_/)
    .map((t) => t[0].toUpperCase() + t.slice(1).toLowerCase())
    .join('');

export const truncate: (str: string, length?: number) => string = (str, length = 18) => {
  return str.length > length ? str.slice(0, length) + '…' : str;
};

export const humanize = (s: string): string => capitalize(s.replace(/_/g, ' '));

export const getHost = (): string => window.location.toString().replace(/(https?:\/\/[^/]*)(.*)/, '$1');

export const cleanUrl = (url: string): string => url.replace(/https?:\/\//, '');

export const toNormalForm = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;
export function makeRandomId(length: number): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
