import React from 'react';

import { Loading } from '@components/common';
import { DashboardHeaderSkeleton, DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';

export const PageSkeleton: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeaderSkeleton />
      <DashboardLayoutBody className='h-full'>
        <Loading absolute bg='white' />
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
