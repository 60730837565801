import { useEffect, useState } from 'react';
import { useUser } from './useUser';
import consumer from '@helpers/consumer';
import { api } from 'api/reduxApi';

const ConnectedAccountsChannel = (user_id: number) =>
  consumer.subscriptions.create(
    {
      channel: 'Users::ConnectedAccountsChannel',
      user_id: user_id
    },
    {}
  );

const ConnectedAccountCreatedChannel = (user_id: number) =>
  consumer.subscriptions.create(
    {
      channel: 'Users::ConnectedAccountCreatedChannel',
      user_id: user_id
    },
    {}
  );

export const useUserConnectedAccounts = () => {
  const { data, refetch } = api.useGetConnectedAccountsQuery();
  const [connectedAccounts, setConnectedAccounts] = useState<ConnectedAccount[]>([]);
  const user = useUser();

  useEffect(() => data && setConnectedAccounts(data), [data]);

  useEffect(() => {
    ConnectedAccountCreatedChannel(user.id).received = (received) => refetch();
    ConnectedAccountsChannel(user.id).received = (received) => refetch();
  }, []);

  return { connectedAccounts };
};
