import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATHS } from '@components/TemplatesApp/consts';
import * as toasts from '@components/TemplatesApp/toasts';
import { useToaster } from '@stores/toaster';
import { useForm, UseFormMethods } from 'react-hook-form';
import { TemplateForm } from '@components/TemplatesApp/pages/TemplatesIndexPage';

interface UseCreateTemplateProps<T extends AbstractTemplate> {
  createMutation: () => [
    (params?: T) => EmailTemplate,
    { data: T; isLoading: boolean; isSuccess: boolean; isError: boolean }
  ];
  kind: TemplateKind;
}

type UseCreateTemplate = <T extends AbstractTemplate>(
  props: UseCreateTemplateProps<T>
) => {
  create: (data?: Partial<EmailTemplate>) => Promise<EmailTemplate>;
  creating: boolean;
  handleClickEditTemplate: (id: number) => void;
  template: T | undefined;
  createSuccess: boolean;
  createError: boolean;
  emailTemplate: EmailTemplate | null;
  setEmailTemplate: (template: EmailTemplate | null) => void;
  showSlideOut: boolean;
  setShowSlideOut: (v: boolean) => void;
  methods: UseFormMethods<TemplateForm>;
};

export const useCreateTemplate: UseCreateTemplate = ({ createMutation, kind }) => {
  const [create, { data: template, isLoading: creating, isSuccess, isError }] = createMutation();

  const methods = useForm<TemplateForm>();

  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate | null>(null);

  const [showSlideOut, setShowSlideOut] = useState(false);

  const showToast = useToaster();
  const navigate = useNavigate();

  const handleClickEditTemplate = (id: number) => navigate(`${PATHS[kind]}/${id}/edit`);

  const handleCreate = async (params) => await (create(params) as any).unwrap();

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedCreate());
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess && ['InterviewTemplate', 'SurveyTemplate'].includes(kind)) {
      navigate(`${PATHS[kind]}/${template?.id}/edit`);
    }

    if (isSuccess && kind === 'EmailTemplate') {
      showToast(toasts.successCreate());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (emailTemplate) {
      methods.reset({
        title: emailTemplate.title,
        event: emailTemplate.event,
        subject: emailTemplate.subject,
        default: emailTemplate.default
      });
    } else {
      methods.reset({
        title: '',
        event: '' as any,
        subject: '',
        default: false
      });
    }
  }, [emailTemplate]);

  return {
    create: handleCreate,
    creating,
    handleClickEditTemplate,
    template,
    createSuccess: isSuccess,
    createError: isError,
    emailTemplate,
    setEmailTemplate,
    showSlideOut,
    setShowSlideOut,
    methods
  };
};
