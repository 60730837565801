import cn from 'classnames';
import Mustache from 'mustache';
import { findChildrenByType } from 'prosemirror-utils';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import TextareaAutosize from 'react-textarea-autosize';

import { api } from '@api/reduxApi';
import { Alert, Card, Input, Select, SelectOption, Text, Toggle } from '@components/common';
import { Error } from '@components/shared/AI';
import { Tiptap, useTiptapFromDocumentId } from '@components/shared/Tiptap';
import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';
import { EditorProps } from '@components/StudyMessages/hooks/useMessageEditor';
import { MessagePreview } from '@components/StudyMessages/hooks/useMessageEditor/components/MessagePreview';
import { UpdateModal } from '@components/StudyMessages/hooks/useMessageEditor/components/UpdateModal';
import { getErrorMessage } from '@components/StudyMessages/utils';
import { EMAIL_TEMPLATE_PURPOSES } from '@components/TemplatesApp/consts';
import { track } from '@components/tracking';
import { getChangeFn, humanize } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';
import Tippy from '@tippyjs/react';

// Note this is now study dep.
import { useCreateStudyMessageMutation, useUpdateStudyMessageMutation } from './api';
import { SubjectPreview } from './components/SubjectPreview';
import * as toasts from './toasts';
import { getCtaText } from './utils/getCtaText';
import { getLayoutOptions } from './utils/getLayoutOptions';
import { SenderSelect } from './SenderSelect';
import { useFeature } from 'hooks/useFeature';
import { GroupSelect, SelectOptionGroup } from './components/GroupSelect';
import { getSendOptionGroups } from './utils/getSendOptionGroups';
import { useUser } from 'hooks/useUser';
import { Editor } from '@tiptap/core';

interface Props extends EditorProps {
  style?: Study['style'] | 'screener';
  message: StudyMessage;
  editSlideOut?: boolean;
  editDisabled?: boolean;
  type: 'sender' | 'template';
  updateOnlyTemplate?: boolean;
  ctaProps?: CtaProps;
}

const Label: React.FC<{ className?: string }> = ({ className = '', children }) => (
  <label className={`mb-2 h500-bold ${className}`}>{children}</label>
);

function showEmailTemplates(event: StudyMessageEvent) {
  return Object.keys(EMAIL_TEMPLATE_PURPOSES).includes(event);
}

const MERGE_TAGS = [
  'candidate.first_name',
  'candidate.name',
  'study.duration',
  'study.incentive',
  'study.style',
  'study.title',
  'sender.first_name',
  'sender.name',
  'sender.message',
  'account.company_name'
];

const MergeInfoTip = () => (
  <div className='p-2'>
    <div>
      <ul>
        {MERGE_TAGS.map((t) => (
          <li key={t} className='flex flex-row justify-between'>
            <span>
              {`{{${t.split('.')[0]}.`}
              <b>{t.split('.')[1]}</b>
              {'}}'}
            </span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);
const MergeTagInfo = () => (
  <>
    <Tippy
      maxWidth={240}
      interactive={true}
      arrow={false}
      content={<MergeInfoTip />}
      className='z-50 text-gray-900 bg-white shadow'
      placement='bottom'
    >
      <span className='hover:text-gray-500 text-xs font-bold text-gray-400 cursor-pointer'>{'{{ merge_tags }}'}</span>
    </Tippy>
  </>
);

export type EditorRef = {
  save(): Promise<StudyMessage>;
};

const DEFAULT_PREVIEW_CANDIDATE: Partial<Candidate> = {
  first_name: 'Joe',
  name: 'Joe smith',
  id: 0
};

const senderToOption = (sender: EmailSender): SelectOption => {
  return {
    label: sender.email,
    value: sender.email,
    disabled: sender.disabled,
    data: sender
  };
};

export const MessageEditor = forwardRef<EditorRef, Props>((props, ref) => {
  const {
    study,
    message: initialMessage,
    hideSender,
    sender,
    setSender,
    senders,
    event,
    edit,
    save: saveTemplate,
    customizable,
    context = 'send',
    previewCandidate = DEFAULT_PREVIEW_CANDIDATE,
    previewParticipation,
    setIsDirty,
    updateModal,
    setUpdateModal,
    setMessage: setInitialMessage,
    formMethods,
    editSlideOut,
    type,
    editDisabled,
    setSenderChanged,
    updateOnlyTemplate,
    ctaProps
  } = props;

  const hasNewSenderSelect = useFeature('new_sender_select');
  const [createStudyMessage] = useCreateStudyMessageMutation();
  const [updateStudyMessage] = useUpdateStudyMessageMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [nodeCtaCount, setNodeCtaCount] = useState(0);
  const [emptyText, setEmptyText] = useState(true);

  const showTemplates = showEmailTemplates(event);

  const showToast = useToaster();

  const {
    formState: { errors },
    setError,
    clearErrors
  } = formMethods;

  const {
    account: { name: accountName, team, default_email_layout }
  } = useAccount();

  const { id: userId } = useUser();

  const isAdminOrCreator = usePermission('canCreate')();

  const messageRef = useRef(initialMessage);

  const [message, setMessage] = useState<StudyMessage | null>(initialMessage);
  const [updateCurrentTemplate, setUpdateCurrentTemplate] = useState<boolean>(false);
  const [localDoc, setLocalDoc] = useState<ApiDocument['doc']>();

  const { data: templates, isSuccess: hasLoadedTemplates } = api.useGetEmailTemplatesQuery(
    { event },
    { skip: !showTemplates }
  );

  const change = getChangeFn<StudyMessage>(message as any, (message) => {
    setIsDirty?.(true);
    setMessage(message);
  });

  const validateDocument = (editor: Editor) => {
    const count = findChildrenByType(editor.state.doc, editor.schema.nodes.cta).length ?? 0;

    setNodeCtaCount(count);
    setEmptyText(!editor.getText());
  };

  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null); // document_id
  const [selectedId, setSelectedId] = useState<number>(); // template id

  const tiptap = useTiptapFromDocumentId({
    documentId: (selectedTemplateId ?? message?.document_id) as number,
    localDoc,
    autoSave: false,
    ctaProps,
    config: {
      placeholder: 'Write your email content…',
      headings: false,
      link: { enable: true },
      highlight: { enable: false },
      artifacts: false,
      cta: {
        enable: true,
        as: message?.layout === 'rich_text' ? 'link' : 'button',
        default: getCtaText(message?.event, study.style, !!study.incentive && study.incentive_method === 'tremendous')
      },
      mergeTags: {
        tags: [
          'candidate.first_name',
          'candidate.name',
          'study.style',
          'study.duration',
          'study.incentive',
          'study.incentive_instructions',
          'sender.first_name',
          'sender.name',
          'account.company_name',
          ...(message?.extra_merge_tags ?? [])
        ]
      }
    },
    scrollToTop: false,
    onEditorUpdate: ({ editor }) => {
      setIsDirty?.(true);
      validateDocument(editor);
    },
    onReady: validateDocument
  });

  const needsCTA = nodeCtaCount === 0 && message?.cta_required && tiptap.ready;

  useImperativeHandle(ref, () => ({
    save
  }));

  useEffect(() => {
    if (initialMessage) {
      setMessage(initialMessage);
    }
  }, [initialMessage]);

  useEffect(() => {
    setMessage(initialMessage);
    const content = tiptap.getTiptapProps().data?.doc;

    if (edit && content) {
      tiptap.editor?.commands.setContent(content as any);
    }
  }, [edit]);

  useEffect(() => {
    if (needsCTA) {
      setError('cta', { type: 'manual' });
    } else {
      clearErrors('cta');
    }
  }, [needsCTA]);

  useEffect(() => {
    if (emptyText) {
      setError('text', { type: 'manual' });
    } else {
      clearErrors('text');
    }
  }, [emptyText]);

  useEffect(() => {
    if (!message?.subject) {
      setError('subject', { type: 'manual' });
    } else {
      clearErrors('subject');
    }
  }, [message?.subject]);

  const save = async (): Promise<StudyMessage> => {
    let resp;
    const data = { ...message } as any;
    if (tiptap.editor) {
      if (updateCurrentTemplate || updateOnlyTemplate) {
        tiptap.saveContent(messageRef.current?.document_id);
      }

      data.document = tiptap.editor.state.doc.toJSON();
    }

    if (isAdminOrCreator) {
      data.default_sender = sender?.email;
    }
    setIsLoading(true);
    try {
      if (updateCurrentTemplate || updateOnlyTemplate) {
        resp = await updateStudyMessage({
          studyId: study.id,
          id: messageRef.current?.id as any,
          study_message: data
        }).unwrap();
      } else {
        resp = await createStudyMessage({ id: study.id, study_message: data }).unwrap();
      }
    } catch {
      showToast(toasts.failedUpdate());
    }
    setIsLoading(false);

    if (resp) {
      setInitialMessage(resp);
      if (tiptap.editor && !(updateCurrentTemplate || editSlideOut)) {
        tiptap.saveContent(resp.document_id);
      }
    }
    if (updateCurrentTemplate && editSlideOut) {
      setIsLoading(true);
      try {
        await updateStudyMessage({
          studyId: study.id,
          id: 'all',
          study_message: { default_sender: sender?.email }
        }).unwrap();
      } catch {
        showToast(toasts.failedUpdate());
      }
      setIsLoading(false);

      setSenderChanged(false);
    }

    return resp;
  };
  const templateOptions = useMemo(() => {
    return [{ label: 'Select a template…', value: '' }].concat(
      templates?.map(({ id, title }) => ({ label: title, value: String(id) })) ?? []
    );
  }, [templates]);

  const handleTemplateChange = (id: number) => {
    const template = id && templates?.find((t) => t.id === id);
    if (template) {
      setSelectedTemplateId(template.document_id);
      setSelectedId(id);
      change?.('subject', template.subject || '');

      track('set_email_template', { event, study_title: study.title, template_title: template.title });
      return;
    }
    setIsDirty?.(true);
    setSelectedTemplateId(null);
  };

  const partyIncentive = previewParticipation ? previewParticipation.incentive_in_whole_currency : null;
  const incentiveAmount = partyIncentive === null ? study.incentive : partyIncentive;

  const previewContext = {
    candidate: previewCandidate,
    study: {
      style: humanize(study.style_label),
      duration: study.duration_in_minutes,
      incentive: study.has_incentive ? study.incentive_title || `${study.currencySymbol}${incentiveAmount}` : '',
      incentive_instructions: study.incentive_instructions,
      live_stream_disclaimer: `This session will be recorded and may be viewed by other researchers from ${accountName}. Please notify your researcher if you no longer wish for this session to be recorded.`
    },
    sender,
    account: {
      company_name: accountName
      // website:
    },
    interview: {
      date_and_time: 'Interview date and time',
      join_url: 'video call url'
    }
  };

  const renderMergeTag = (str: string) => {
    const tag = `{{${str}}}`;
    return (
      <Tippy content={tag}>
        {
          <span className='border-b-2 border-indigo-600 border-dashed'>
            {Mustache.render(tag, previewContext) || str}
          </span>
        }
      </Tippy>
    );
  };

  const evalIfCondition = (key: string): boolean => {
    switch (key) {
      case 'study.duration':
        return !!study.duration_in_minutes;
      case 'study.live_stream':
        return study.live_stream_enabled;
      case 'study.incentive':
        return study.has_incentive;
      default:
        return true;
    }
  };

  const groupedSenders = (senders || [])
    .filter((s) => (edit ? s.user_id === study.owner_id || s.level === 'account' : true))
    .map(senderToOption)
    .reduce((a, b) => {
      if ((b as any).data.level === 'account') {
        (a['0'] = a['0'] || []).push(b);
      } else {
        (a[b.data?.user_id] = a[b.data?.user_id] || []).push(b);
      }
      return a;
    }, {});

  const sendOptionGroups: SelectOptionGroup[] = useMemo(
    () => getSendOptionGroups(groupedSenders, userId),
    [groupedSenders, userId]
  );

  const selectedSender = (senders || []).find((s) => s.email === sender?.email && s.user_id === sender?.user_id);

  const richFormat = message && message.medium === 'email';

  const noSubject = message && (!message.subject || message.subject.trim() === '');

  const onUpdate = async () => {
    await saveTemplate();
    setUpdateCurrentTemplate(false);
    setUpdateModal(false);
    setSenderChanged(false);
  };

  const onSenderChange = (_: EmailSender) => {
    setSenderChanged(true);
    setIsDirty(true);
  };

  if (editDisabled) {
    return (
      <section>
        {message?.subject && (
          <div className='p-4 border-b border-gray-200'>
            <SubjectPreview renderMergeTag={renderMergeTag}>{message.subject}</SubjectPreview>
          </div>
        )}
        <MessagePreview
          message={message}
          study={study}
          renderMergeTag={renderMergeTag}
          evalIfCondition={evalIfCondition}
          edit={edit}
          previewContext={previewContext}
        />
      </section>
    );
  }

  return (
    <div key={`${study.id}-${event}-message`}>
      {!hideSender && (
        <div className='form-group mb-6'>
          <Label>Sender</Label>
          {message?.medium === 'sms' && <span className='block mb-1 text-gray-700'>SMS</span>}
          {message?.medium === 'email' && hasNewSenderSelect && (
            <SenderSelect
              studyMessage={message}
              study={study}
              sender={sender}
              setSender={setSender}
              onSelect={onSenderChange}
            />
          )}
          {message?.medium === 'email' && !hasNewSenderSelect && (
            <GroupSelect
              groups={sendOptionGroups}
              disabled={context === 'editor' && !edit}
              error={!selectedSender}
              selected={
                selectedSender
                  ? {
                      group: selectedSender.level === 'account' ? '0' : selectedSender?.user_id?.toString() || '0',
                      option: selectedSender.email
                    }
                  : undefined
              }
              onChange={({ group, sender }) => {
                setSenderChanged(true);
                setIsDirty(true);
                setSender(sender);
              }}
            />
          )}
        </div>
      )}
      {message?.has_subject && (
        <>
          {showTemplates && message && edit && (
            <div className='form-group mb-6'>
              <Label>Email template</Label>
              {hasLoadedTemplates && templates && templates?.length > 0 && (
                <Select
                  options={templateOptions}
                  value={String(selectedId)}
                  onChange={(v) => handleTemplateChange(+v)}
                  placeholder='Select a template…'
                  renderLabel={({ label, value }) => (
                    <span className={cn({ 'text-gray-400': value === '' })}>{label}</span>
                  )}
                />
              )}
              {hasLoadedTemplates && templates && templates?.length == 0 && (
                <Text color='gray-500' h='400'>
                  No available templates
                </Text>
              )}
              {!hasLoadedTemplates && <Skeleton duration={1} className='bg-gray-50 block w-full h-10 rounded-md' />}
            </div>
          )}

          <div className='form-group mb-6'>
            <Label>{edit ? 'Email subject' : 'Email preview'}</Label>
            {!message && <Skeleton duration={1} className='bg-gray-50 block w-full h-10 rounded-md' />}
            {message && !edit && (
              <section className='border border-gray-200 rounded-md'>
                {message?.subject && (
                  <div className='p-4 border-b border-gray-200'>
                    {edit ? (
                      message.subject
                    ) : (
                      <>
                        <SubjectPreview renderMergeTag={renderMergeTag}>{message.subject}</SubjectPreview>
                      </>
                    )}
                  </div>
                )}
                <MessagePreview
                  message={message}
                  study={study}
                  renderMergeTag={renderMergeTag}
                  evalIfCondition={evalIfCondition}
                  edit={edit}
                  previewContext={previewContext}
                />
              </section>
            )}
            {message && edit && (
              <Input
                error={!!errors.subject}
                type='text'
                value={message.subject}
                onChange={(v) => change?.('subject', v)}
                className='w-full text-gray-700'
                textSize='base'
                name='subject'
                id='subject'
              />
            )}

            {edit && errors && errors['subject'] && (
              <Error className='col-span-3 mt-3 truncate'>Please enter a subject line for your email.</Error>
            )}
            {message && !edit && noSubject && (
              <Alert type='error' className='mb-2'>
                Please set a subject in order to send this email
              </Alert>
            )}
          </div>
        </>
      )}
      {message?.medium === 'sms' && (
        <div className='form-group mb-6'>
          <Label>SMS Message</Label>
          {message && !edit && (
            <div className='bg-gray-50 rounded-md'>
              <div className='px-6 py-4'>
                {message.text?.split('\n').map((p, i) => (
                  <p className=' h500 my-1' key={i}>
                    {Mustache.render(p, previewContext)}
                  </p>
                ))}
              </div>
            </div>
          )}
          {edit && (
            <TextareaAutosize
              value={message.text || ''}
              minRows={6}
              onChange={(e) => change?.('text', e.currentTarget.value)}
              className='focus:border-indigo-500 focus:ring-indigo-500 block w-full p-3 text-gray-700 placeholder-gray-400 transition duration-150 ease-in-out border-gray-200 rounded-md'
            />
          )}
          {message.medium === 'sms' && (
            <div className='flex flex-row'>
              <Toggle on={message.short_url} disabled={!edit} onToggle={(v) => change?.('short_url', v)} />
              <div className='flex flex-col space-y-2'>
                <Text bold>Use short links</Text>
                <Text h='400'>
                  Any urls will be shortened to <b>https://gq.fyi/token</b>.
                </Text>
              </div>
            </div>
          )}
          {study.style === 'video_call' && event === 'invite' && (
            <Alert type='info' className='mt-2'>
              Note: Although candidates will be invited via SMS, email will still be required to make a booking.
            </Alert>
          )}
        </div>
      )}
      {message?.medium === 'email' && (
        <div className='form-group mb-6'>
          {!richFormat && (
            <>
              <div className='items- center flex flex-row justify-between'>
                <Label>Email body</Label>
                {message && edit && <MergeTagInfo />}
              </div>
              {!message && <Skeleton duration={1} className='bg-gray-50 block w-full h-48 rounded-md' />}
              {message && edit && (
                <TextareaAutosize
                  value={message.text || ''}
                  minRows={6}
                  onChange={(e) => change?.('text', e.currentTarget.value)}
                  className='focus:border-indigo-500 focus:ring-indigo-500 block w-full p-3 text-gray-700 placeholder-gray-400 transition duration-150 ease-in-out border-gray-200 rounded-md'
                />
              )}
            </>
          )}
          {message && edit && richFormat && (
            <>
              <div className='mb-6'>
                <Label>Email Design</Label>
                <Select<StudyMessage['layout']>
                  name='layout'
                  options={getLayoutOptions({
                    default_email_layout,
                    delivery_method: sender?.delivery_method
                  })}
                  value={message.layout}
                  onChange={(v) => {
                    setLocalDoc(tiptap?.editor?.getJSON());
                    change?.('layout', v);
                  }}
                />
              </div>
              <Label>Email body</Label>
              <div key={message.document_id}>
                <Tiptap {...tiptap.getTiptapProps()}>
                  <>
                    <tiptap.Menu {...tiptap.getMenuProps()} />
                    <Card errors={errors['cta'] || errors['text']} className='rounded-lg- w-full' paddingClass='p-2'>
                      <tiptap.Content {...tiptap.getContentProps()} />
                    </Card>
                  </>
                </Tiptap>
              </div>
            </>
          )}
          {edit && errors?.['cta'] && (
            <Error className='col-span-3 mt-3'>
              {getErrorMessage(study.style, message.event) || 'Please insert a call-to-action in your email.'}
            </Error>
          )}
          {edit && errors?.['text'] && <Error className='col-span-3 mt-3'>Please enter an email body.</Error>}
        </div>
      )}
      {needsCTA && !edit && (
        <Alert type='warning' heading='Email content missing a call to action'>
          This email is missing the button for the candidate(s). Please edit the email to include one.
        </Alert>
      )}
      {(customizable || editSlideOut) && updateModal && message && (
        <UpdateModal
          type={type}
          onClose={() => {
            setUpdateCurrentTemplate(false);
            setUpdateModal(false);
          }}
          updateCurrentTemplate={updateCurrentTemplate}
          setUpdateCurrentTemplate={setUpdateCurrentTemplate}
          onConfirm={onUpdate}
          isLoading={isLoading}
        />
      )}
    </div>
  );
});
