import * as React from 'react';

export const LoginSVG: React.FC<any> = (props) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M11.8125 10.9375V11.8125C11.8125 12.0446 11.7203 12.2671 11.5562 12.4312C11.3921 12.5953 11.1696 12.6875 10.9375 12.6875H8.3125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 2.1875H10.9375C11.1696 2.1875 11.3921 2.27969 11.5562 2.44378C11.7203 2.60788 11.8125 2.83044 11.8125 3.0625V3.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.06086 13.5567L0.810859 12.8334C0.706269 12.8179 0.610832 12.765 0.5422 12.6846C0.473567 12.6041 0.436383 12.5016 0.437526 12.3959V2.17002C0.439202 2.07148 0.4741 1.97639 0.536565 1.90016C0.599031 1.82393 0.685404 1.77103 0.781692 1.75002L6.03169 0.455018C6.09543 0.441099 6.16148 0.441613 6.225 0.456522C6.28852 0.47143 6.3479 0.500357 6.39879 0.541182C6.44968 0.582008 6.4908 0.6337 6.51913 0.692472C6.54746 0.751244 6.56229 0.815608 6.56253 0.880852V13.1309C6.56251 13.1935 6.54888 13.2553 6.52257 13.3121C6.49627 13.3689 6.45793 13.4193 6.4102 13.4598C6.36248 13.5003 6.30652 13.53 6.24619 13.5467C6.18587 13.5634 6.12263 13.5668 6.06086 13.5567Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5 5.6875L8.75 7.4375H13.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5 9.1875L8.75 7.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.302 7.22229C4.24399 7.22229 4.18835 7.19924 4.14732 7.15822C4.1063 7.1172 4.08325 7.06156 4.08325 7.00354C4.08325 6.94552 4.1063 6.88988 4.14732 6.84886C4.18835 6.80784 4.24399 6.78479 4.302 6.78479'
      stroke='currentColor'
      strokeWidth='0.875'
    />
    <path
      d='M4.302 7.22229C4.36002 7.22229 4.41566 7.19924 4.45668 7.15822C4.49771 7.1172 4.52075 7.06156 4.52075 7.00354C4.52075 6.94552 4.49771 6.88988 4.45668 6.84886C4.41566 6.80784 4.36002 6.78479 4.302 6.78479'
      stroke='currentColor'
      strokeWidth='0.875'
    />
  </svg>
);
