import React, { SVGProps } from 'react';

export const CopySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 0.666664H2.99999C2.26666 0.666664 1.66666 1.26666 1.66666 2V11.3333H2.99999V2H11V0.666664ZM13 3.33333H5.66666C4.93332 3.33333 4.33332 3.93333 4.33332 4.66666V14C4.33332 14.7333 4.93332 15.3333 5.66666 15.3333H13C13.7333 15.3333 14.3333 14.7333 14.3333 14V4.66666C14.3333 3.93333 13.7333 3.33333 13 3.33333ZM5.66666 14H13V4.66666H5.66666V14Z'
      fill='currentColor'
    />
  </svg>
);
