import cn from 'classnames';
import { Spinner, Text } from 'components/common';
import { useCanvasPlayer } from 'components/HighlightReelsApp/hooks/useCanvasPlayer';
import React, { forwardRef, HTMLAttributes, useEffect, useMemo, useState } from 'react';

import { ErrorSvg, VideoSVG } from '@components/svgs';
import { ControlsWithTimecodes } from '@components/VideoPlayer/components';

import { useLocalStorage } from '@hooks/useLocalStorage';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { Thumbnail } from '@components/HighlightReelsApp/components/Editor/components';
import { api } from '@api/reduxApi';
import { Chapter } from 'components/common/VideoSeekBar';

const reorderArtifacts = (artifacts: ArtifactHit[], hlrClips?: HighlightReel['clips']) => {
  if (!hlrClips) return artifacts;

  const reorderedArtifacts: ArtifactHit[] = [];

  hlrClips.forEach((clip) => {
    const current = artifacts.find(({ objectID }) => objectID.replace('Clip_', '') === clip.id.toString());

    if (current) {
      reorderedArtifacts.push(current);
    }
  });

  return reorderedArtifacts;
};
interface Props extends HTMLAttributes<HTMLDivElement> {
  onPlayerTimeUpdate?: (t: number) => void;
  chapters?: Chapter<Record<string, unknown>>[];
  artifactIds?: string[];
  highlightReelToken?: string;
}

export const UnstitchedReelPlayer = forwardRef<HTMLDivElement, Props>(
  ({ highlightReelToken, artifactIds, onPlayerTimeUpdate, chapters, className, ...rest }, ref) => {
    const [playbackSpeed, setPlaybackSpeed] = useLocalStorage('gq-default-playback-speed');

    const { data: highlightReel } = api.useGetHighlightReelQuery(highlightReelToken as string, {
      skip: !highlightReelToken
    });

    const { data: artifacts = [], isSuccess } = api.useGetArtifactsQuery(artifactIds as string[], {
      skip: !artifactIds
    });

    const reorderedArtifacts = useMemo(
      () => reorderArtifacts(artifacts, highlightReel?.clips),
      [artifacts, highlightReel?.clips]
    );

    const [volume, setVolume] = useState<number>(1);
    const [currentTime, setCurrentTime] = useState<number>(0);

    const { setRef, state, player } = useCanvasPlayer({
      currentTime,
      volume,
      playbackRate: playbackSpeed || 1,
      artifacts: reorderedArtifacts
    });

    const handleOnProgressBarChange = (value: number) => {
      if (player) {
        setCurrentTime((value * player.duration) / 100);
      }
    };

    useEffect(() => {
      return () => {
        player?.stop();
      };
    }, [player]);

    useEffect(() => {
      onPlayerTimeUpdate?.(state.currentTime);
    }, [state.currentTime]);

    return (
      <section data-testid='reel-player' ref={ref} className={cn('relative bg-black group', className)} {...rest}>
        <div ref={setRef} />
        <div
          className={cn(
            'group-hover:opacity-100 absolute inset-0 transition-opacity duration-150 bg-gray-700 bg-opacity-50',
            { 'opacity-0': !state.isLoading && !state.errors.length && artifacts.length }
          )}
        >
          {(state.isLoading || !player) && (
            <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
              <Spinner className='w-7 h-7 mb-2' />
              <span className='text-xs'>Updating reel...</span>
            </div>
          )}
          {state.errors.length > 0 && (
            <div className='absolute inset-0 flex flex-col items-center justify-center text-white'>
              <ErrorSvg className='w-7 h-7 mb-2' />
              <span className='text-xs'>Something went wrong</span>
            </div>
          )}
          {isSuccess && artifacts.length === 0 && (
            <div className='flex flex-col items-center justify-center w-full h-full bg-gray-500'>
              <VideoSVG />
              <Text className='mt-6 text-sm text-center' color='white'>
                Video highlight reel will appear once highlights are added.
              </Text>
            </div>
          )}
          {player && !state.isLoading && !state.errors.length && !!artifacts.length && (
            <ControlsWithTimecodes
              chapters={chapters}
              isPlaying={state.isPlaying}
              duration={state.duration}
              currentTime={state.currentTime}
              buffer={0}
              volume={volume}
              isFullscreen={false}
              playbackSpeed={playbackSpeed || 1}
              player={undefined}
              show
              play={player.play.bind(player)}
              pause={player.pause.bind(player)}
              setVolume={setVolume}
              setPlaybackSpeed={setPlaybackSpeed}
              rewind={() => player.back(5000)}
              fastForward={() => player.forward(5000)}
              hasMiniPlayer={false}
              handleOnProgressBarChange={handleOnProgressBarChange}
              renderThumbnail={(value) => {
                return <Thumbnail player={player} timestamp={(value * player.duration) / 100} />;
              }}
            />
          )}
        </div>
      </section>
    );
  }
);
