import React, { SVGProps } from 'react';

export const SurveysSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.7414 13.6308L13.75 20.625L10.3125 21.3125L11 17.875L17.9951 10.8808C18.175 10.7006 18.3887 10.5576 18.6239 10.46C18.8591 10.3625 19.1113 10.3123 19.366 10.3123C19.6206 10.3123 19.8728 10.3625 20.108 10.46C20.3432 10.5576 20.5569 10.7006 20.7368 10.8808L20.7451 10.8882C21.1082 11.2524 21.3118 11.7459 21.3111 12.2603C21.3105 12.7746 21.1055 13.2676 20.7414 13.6308Z'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.55884 18.5625H2.05884C1.69417 18.5625 1.34443 18.4176 1.08657 18.1598C0.828704 17.9019 0.683838 17.5522 0.683838 17.1875V2.0625C0.683838 1.69783 0.828704 1.34809 1.08657 1.09023C1.34443 0.832366 1.69417 0.6875 2.05884 0.6875H15.8088C16.1735 0.6875 16.5232 0.832366 16.7811 1.09023C17.039 1.34809 17.1838 1.69783 17.1838 2.0625V8.25'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.55884 4.8125H13.0588'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.80884 8.9375H13.0588'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.80884 13.0625H11.6838'
      stroke='currentColor'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
