import React, { SVGProps } from 'react';

export const CalendarWithClockSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 1H7.5V0.5C7.5 0.224 7.276 0 7 0C6.724 0 6.5 0.224 6.5 0.5V1H3.5V0.5C3.5 0.224 3.276 0 3 0C2.724 0 2.5 0.224 2.5 0.5V1H1.5C0.672 1 0 1.672 0 2.5V12.5C0 13.328 0.672 14 1.5 14H6C6.276 14 6.5 13.776 6.5 13.5C6.5 13.224 6.276 13 6 13H1.5C1.224 13 1 12.776 1 12.5V5H13V6C13 6.276 13.224 6.5 13.5 6.5C13.776 6.5 14 6.276 14 6V2.5C14 1.672 13.328 1 12.5 1H11.5V0.5C11.5 0.224 11.276 0 11 0C10.724 0 10.5 0.224 10.5 0.5V1ZM10 6C7.7925 6 6 7.7925 6 10C6 12.2075 7.7925 14 10 14C12.2075 14 14 12.2075 14 10C14 7.7925 12.2075 6 10 6ZM10 7C11.6555 7 13 8.3445 13 10C13 11.6555 11.6555 13 10 13C8.3445 13 7 11.6555 7 10C7 8.3445 8.3445 7 10 7ZM9.5 9V10C9.5 10.276 9.724 10.5 10 10.5H11C11.276 10.5 11.5 10.276 11.5 10C11.5 9.724 11.276 9.5 11 9.5H10.5V9C10.5 8.724 10.276 8.5 10 8.5C9.724 8.5 9.5 8.724 9.5 9ZM2.5 2H1.5C1.224 2 1 2.224 1 2.5V4H13V2.5C13 2.224 12.776 2 12.5 2H11.5V2.5C11.5 2.776 11.276 3 11 3C10.724 3 10.5 2.776 10.5 2.5V2H7.5V2.5C7.5 2.776 7.276 3 7 3C6.724 3 6.5 2.776 6.5 2.5V2H3.5V2.5C3.5 2.776 3.276 3 3 3C2.724 3 2.5 2.776 2.5 2.5V2Z'
        fill='currentColor'
      />
    </svg>
  );
};
