import consumer from 'helpers/consumer';
import React, { useEffect, useRef, useState } from 'react';
import MuxPlayer from '@mux/mux-player-react';
import { SVG } from '../RecordingWidget/SVGImages';
import { LiveDocument } from './LiveDocument';
import type MuxPlayerElement from '@mux/mux-player';
import { InfoCircleIcon } from '@components/svgs';
import { liveStreamDefaultMessages, liveStreamMessageMap, liveStreamTitles } from './constants';

const RepoSessionsLiveStreamChannel = (live_stream_id) =>
  consumer.subscriptions.create(
    {
      channel: 'Repo::Sessions::LiveStreamsChannel',
      live_stream_id: live_stream_id
    },
    {}
  );

interface Props {
  liveStream: RepoSessionsLiveStream;
  setLiveStreamLocked: React.Dispatch<React.SetStateAction<boolean>>;
  setLiveStreamStatus: React.Dispatch<React.SetStateAction<RepoSessionsLiveStream['status']>>;
}

const statusTitleMap = (status: RepoSessionsLiveStreamStatus, message: string | null) => {
  return {
    title: liveStreamTitles[status],
    message: liveStreamDefaultMessages[status] || (message !== null ? liveStreamMessageMap[message] : null) || message
  };
};

const StatusPresentation: React.FC<{ title: string; message: string | null }> = ({ title, message }) => (
  <div className='bg-gray-50 p-6 text-center border border-gray-200 border-dashed rounded-lg'>
    <SVG className='mx-auto mb-6' />
    <span className='block mb-2 font-bold text-gray-700'>{title}</span>
    {message && (
      <span className='block mb-6 text-sm text-gray-500'>
        <span>{liveStreamMessageMap[message] || message}</span>
      </span>
    )}
  </div>
);

export const LiveStreamWrapper: React.FC<Props> = ({
  liveStream: initialLiveStream,
  setLiveStreamLocked,
  setLiveStreamStatus
}) => {
  const [liveStream, setLiveStream] = useState<RepoSessionsLiveStream>(initialLiveStream);
  const videoRef = useRef<MuxPlayerElement>(null);

  const onError = (_) => {
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.src = 'dummy'; // This is here just to force a refresh of the player
        videoRef.current.src = liveStream.playback_url;
      }
    }, 1000);
  };

  useEffect(() => {
    if (liveStream) {
      RepoSessionsLiveStreamChannel(liveStream.id).received = (data) => {
        setLiveStream(data.message);
        setLiveStreamLocked(data.message.status === 'live');
        setLiveStreamStatus(data.message.status);
      };
    }
  }, []);

  if (!liveStream) {
    return null;
  }

  const getLiveStreamComponent = () => {
    switch (liveStream.status) {
      case 'scheduled':
      case 'joined_waiting_room':
      case 'ended':
      case 'failed':
        return <StatusPresentation {...statusTitleMap(liveStream.status, liveStream.message)} />;
      case 'live':
        return (
          <MuxPlayer
            ref={videoRef}
            onError={onError}
            className='object-cover w-full bg-gray-900'
            src={liveStream.playback_url}
            streamType='live'
            autoPlay
            muted
          />
        );
      default: {
        const exhaustiveCheck: never = liveStream.status;
        return exhaustiveCheck;
      }
    }
  };

  return (
    <div className='flex flex-col h-full'>
      {getLiveStreamComponent()}
      {liveStream.status !== 'ended' && (
        <div className='bg-gray-50 rounded md px-4 py-2.5 mt-2 flex items-center space-x-4'>
          <InfoCircleIcon className='w-3.5 h-3.5 flex-shrink-0' />
          <span className='text-sm'>
            A 10-second delay is applied to the live stream to maintain quality and smoothness.
          </span>
        </div>
      )}
      {liveStream.temp_transcript_document_id && <LiveDocument documentId={liveStream.temp_transcript_document_id} />}
    </div>
  );
};
