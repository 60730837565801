import * as React from 'react';

import { PlayerPlayNoCircle } from 'components/svgs';
import { PLAYBACK_RATES } from '../constants';
import { PlaybackRates } from './PlaybackRates';

import type { ControlsProps } from '../types';
import { useLocalStorage } from '@hooks/useLocalStorage';

export const PrePlayControls: React.FC<
  Partial<Pick<ControlsProps, 'play' | 'setPlaybackSpeed'>> & { showPlaybackRates?: boolean }
> = ({ play, setPlaybackSpeed, showPlaybackRates = true }) => {
  const [defaultPlaybackSpeed] = useLocalStorage('gq-default-playback-speed');
  const [playbackRate, setPlaybackRate] = React.useState<number>(defaultPlaybackSpeed || PLAYBACK_RATES[0]);

  const handleOnPlaybackRateChange = (rate: number) => {
    setPlaybackSpeed?.(rate);
    setPlaybackRate(rate);
  };

  return (
    <section className='absolute inset-0 flex flex-col items-center justify-center bg-gray-700 bg-opacity-50'>
      <button
        className='flex items-center justify-center w-12 h-12 tablet:w-20 tablet:h-20 mb-4 tablet:mb-6 text-indigo-600 bg-white rounded-full'
        aria-label='Play'
        onClick={play}
      >
        <PlayerPlayNoCircle className='w-6 h-6 tablet:w-8 tablet:h-8 ml-1 tablet:ml-1.5' />
      </button>
      {showPlaybackRates && (
        <PlaybackRates
          rates={PLAYBACK_RATES}
          activeRate={playbackRate}
          onChange={(rate) => handleOnPlaybackRateChange(rate)}
        />
      )}
    </section>
  );
};
