import { FileAsset } from 'components/CandidatesApp/CandidatesShow/components/Assets';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getCandidate } from '@api/queries';

interface Props {
  candidateId: number;
}
export const Assets: React.FC<Props> = ({ candidateId }) => {
  const [loading, setLoading] = useState(true);
  const [candidate, setCandidate] = useState<Candidate | null>(null);

  useEffect(() => {
    getCandidate(candidateId).then((candidate) => {
      setCandidate(candidate);
      setLoading(false);
    });
  }, []);

  return (
    <div className='divide-y-gray-200 divide-y'>
      {loading && <Skeleton className='bg-gray-50 h-12' />}
      {!loading && candidate?.file_assets?.length === 0 && (
        <span className='mt-2 mb-6 text-gray-500'>This candidate has no attached file assets</span>
      )}
      {!loading && candidate?.file_assets && candidate?.file_assets.length > 0 && (
        <div>
          {candidate?.file_assets?.map((asset) => (
            <FileAsset key={'f' + asset.id} asset={asset} />
          ))}
        </div>
      )}
    </div>
  );
};
