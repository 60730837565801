import React, { FocusEventHandler, useEffect, useState } from 'react';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';
import { useDebouncedCallback } from 'use-debounce';

import { AI } from '@api/chat-gpt';
import { api } from '@api/reduxApi';
import { Preview as MarkdownPreview } from '@components/common/MarkdownEditor';
import { Basic } from '@components/shared/Skeleton';
import { SAVE_DEBOUNCE } from '@components/shared/Tiptap/constants';
import { failedUpdateSummary } from '@components/shared/Tiptap/toasts';
import { useToaster } from '@stores/toaster';
import { AskAiSVG } from '@components/svgs';
import { truncate } from '@components/utils';

interface Props {
  session: RepoSession;
  skip?: boolean;
  aiSummary?: AI.UseQueryResult<string>;
  onChange?: () => void;
  preview?: boolean;
  readOnly?: boolean;
  loading?: boolean;
}

const TRUNCATED_LENGTH = 250;

export const Summary = ({ preview, session, loading, aiSummary, onChange: initialOnChange, readOnly }: Props) => {
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [bodyExpanded, setBodyExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const showToast = useToaster();

  const [updateRepoSession, { isLoading, isError }] = api.useUpdateRepoSessionMutation();

  const hasSuggestion = aiSummary && aiSummary.length > 0;
  const suggestion = hasSuggestion ? aiSummary[0] : undefined;

  const isAiSummaryValue = suggestion && session && suggestion.result === session.summary;

  const handleOnUpdateSession = async (summary: string) => {
    if (session.uuid) {
      await updateRepoSession({ uuid: session.uuid, summary });
    }
  };

  const { callback: debouncedUpdate } = useDebouncedCallback(handleOnUpdateSession, SAVE_DEBOUNCE);

  const handleOnBlur: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    setIsEditing(false);
    if (!suggestion && e.target.value !== session.summary) {
      handleOnUpdateSession(e.target.value);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.target.value);
    debouncedUpdate(e.target.value);
    initialOnChange?.();
  };

  useEffect(() => {
    if (!preview && session.summary && textareaValue !== session.summary) {
      setTextareaValue(session.summary);
    }
  }, []);

  useEffect(() => {
    if (preview && session.summary) {
      const summary = bodyExpanded || !preview ? session.summary : truncate(session.summary || '', TRUNCATED_LENGTH);

      setTextareaValue(summary);
    }
  }, [bodyExpanded, session.summary]);

  useEffect(() => {
    if (isError) {
      showToast(failedUpdateSummary());
    }
  }, [isError]);

  const toggleEdit = () => setIsEditing(!isEditing);
  const sectionClasses = cn('overflow-hidden', 'rounded-md', 'border-transparent', 'border-2', {
    'ring-indigo-500 border-indigo-500 bg-gray-50': isEditing
  });
  const textareaClasses = cn('focus:ring-0 h500 w-full p-0 overflow-y-hidden border-none resize-none', {
    'bg-gray-50': isEditing
  });
  return (
    <>
      {isAiSummaryValue && (
        <div className='flex items-center text-sm text-gray-500'>
          <AskAiSVG className='mr-2' /> Generated by Great Question AI
        </div>
      )}
      {preview && !textareaValue ? (
        <p>No summary created.</p>
      ) : (
        <section className={sectionClasses} onClick={() => !isEditing && toggleEdit()}>
          <div className={preview ? 'flex flex-col items-end' : 'px-2 pb-2 mt-2'}>
            {!isEditing ? (
              <MarkdownPreview>{textareaValue}</MarkdownPreview>
            ) : (
              <TextareaAutosize
                autoFocus
                placeholder='Enter a summary'
                className={textareaClasses}
                value={textareaValue}
                disabled={readOnly}
                onChange={onChange}
                onBlur={handleOnBlur}
                minRows={preview ? 2 : 6}
                readOnly={isLoading || preview}
              />
            )}
            {session.summary && session.summary?.length > TRUNCATED_LENGTH && preview && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setBodyExpanded(!bodyExpanded);
                }}
                className='focus:outline-none h400 ml-2 text-indigo-600'
              >
                {bodyExpanded ? 'Show less' : 'Show more'}
              </button>
            )}
          </div>
        </section>
      )}
      {loading && preview && <Basic containerTestId='transcript-skeleton' h='28' width='100%' />}
      {isEditing && (
        <div className='flex items-center mb-3 text-sm text-gray-500'>
          You are currently editing the summary. Use markdown to format text.
        </div>
      )}
    </>
  );
};
