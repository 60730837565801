import { CandidateSlideOut } from '@components/CandidatesApp/CandidatesIndex/types';
import * as React from 'react';
import { PopperDropdown, PopperDropdownButton, Text } from '@components/common';

interface Props {
  ids: number[];
  panelStudy?: boolean;
  setSlideOut: (slideOut: CandidateSlideOut) => void;
}

export const SendMenu: React.FC<Props> = ({ ids, setSlideOut, panelStudy }) => {
  const showSlideOut = (slideOut) => {
    setSlideOut(slideOut);
  };

  const showInviteSlideOut = () => showSlideOut('InviteSlideOut');

  const showScreenerSlideOut = () => showSlideOut('ScreenerSlideOut');

  const showPanelInviteSlideOut = () => showSlideOut('PanelInviteSlideOut');

  return (
    <PopperDropdown medium buttonClassName='xx-send-menu' primary text='Compose' icon='composeEmail'>
      <PopperDropdownButton className='xx-bulk-invite border-b' onClick={showInviteSlideOut}>
        Participation invite
      </PopperDropdownButton>
      <PopperDropdownButton className='xx-bulk-screener border-b' onClick={showScreenerSlideOut}>
        Screener invite
      </PopperDropdownButton>
      {panelStudy && (
        <PopperDropdownButton className='xx-bulk-panel border-b' onClick={showPanelInviteSlideOut}>
          Panel opt-in form
        </PopperDropdownButton>
      )}
      <Text className='h400 px-4 py-2 opacity-50 whitespace-nowrap w-full text-left text-gray-700'>
        Emails won't be sent yet
      </Text>
    </PopperDropdown>
  );
};
