import * as React from 'react';
import { useCallback } from 'react';

import { Text } from '@components/common';
import { TagPill } from '@components/tags';
import { compact } from '@components/utils';
import { GetTag, useTags } from '@stores/tags';

type Options = {
  max?: number;
  clickable?: boolean;
};
interface Hook {
  getTag: GetTag;
  renderTagsList: (tagIds: number[], options?: Options, onPillClick?: (link: string) => void) => React.ReactNode;
}

export const useRenderTagsList = (): Hook => {
  const { tags: allTags, getTag } = useTags();

  const renderTagsList = useCallback<Hook['renderTagsList']>(
    (tagIds, options = {}, onPillClick) => {
      const { max = 2, clickable = false } = options;

      if (!allTags) return null;

      const tagsToRender = compact(tagIds.map(getTag).slice(0, max));

      if (tagsToRender.length < 1) return null;

      return (
        <section className='flex items-center space-x-2 truncate'>
          {tagsToRender.map((tag) => (
            <TagPill onPillClick={onPillClick} key={tag.name} tag={tag} size='sm' />
          ))}
          {tagIds.length > max && (
            <Text as='span' h='200' className='flex-shrink-0 ml-1 text-gray-500'>
              +{tagIds.length - max}
            </Text>
          )}
        </section>
      );
    },
    [getTag]
  );

  return {
    getTag,
    renderTagsList
  };
};

export { Hook as RenderTagsList };
