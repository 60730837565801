import { useMemo } from 'react';

import { api as reduxApi } from '@api/reduxApi';
import { convertStringsToDates } from '@hooks/useCachedPagedQuery';
import { useScreenerResults } from '@hooks/useScreenerResults';

import { useAccount } from './useAccount';
import { useFeature } from '@hooks/useFeature';

export type UseServerSideParticipations = {
  initialFetchDone: boolean;
  isFetching: boolean;
  isError: boolean;
  participations?: Participation[];
  totalPages: number;
  refetch: () => void;
  counts?: ParticipationCounts;
  updateParticipation?: (participation: Participation) => void;
};

// TODO: Get rid of this and just let the backend decide.
export const FIELDS = [
  'id',
  'project_id',
  'customer_id',
  'status',
  'external_status',
  'extra',
  'name',
  'token',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'opted_in',
  'created_at',
  'consented',
  'updated_at',
  'booked_at',
  'completed_at',
  'interview_at',
  'moderator_id',
  'interview_path',
  'public_path',
  'loom_recording_id',
  'requested_at',
  'applied_at',
  'shortlisted_at',
  'rejected_at',
  'no_show_at',
  'opted_out_at',
  'consented_at',
  'started_at',
  'canceled_at',
  'deleted_at',
  'invited_at',
  'thanked_at',
  'reschedule_requested_at',
  'customer',
  'can_request_reschedule',
  'cancelation_reason',
  'time_proposal',
  'rsvp',
  'waitlisted_at',
  'recruiting_source',
  'ideal_pre_screener_response',
  'pre_screener_response_match_score',
  'rating',
  'incentive_in_whole_currency'
];

interface Props extends ParticipantsFilterQuery {
  studyId: number;
  preScreenerId?: number;
  surveyScreenerId?: number;
  skip?: boolean;
  pollingEnabled?: boolean;
  page: number;
  currentStatus?: Participation['status'];
}

export const useServerSideParticipations = ({
  studyId,
  pollingEnabled = true,
  preScreenerId,
  surveyScreenerId,
  page,
  sort,
  sort_desc,
  skip,
  op,
  filters,
  currentStatus,
  searchQuery
}: Props): UseServerSideParticipations => {

  const {
    account: { team }
  } = useAccount();


  const {
    data,
    isLoading,
    isUninitialized,
    isFetching,
    isError,
    refetch: refetchParticipants
  } = reduxApi.useGetParticipationsPagedQuery({
    study_id: studyId,
    fields: FIELDS,
    page,
    query: {
      sort,
      sort_desc,
      op,
      filters: [`status is ${currentStatus}`, ...(filters || [])],
      searchQuery
    },
    items: 100
  });

  const { data: counts, refetch: refetchCounts } = reduxApi.useGetParticipationsStatusesCountsQuery(
    {
      study_id: studyId,
      filters,
      searchQuery
    },
    // turn off counts polling for now
    { refetchOnMountOrArgChange: true }
  );

  const refetch = () => {
    refetchParticipants();
    refetchCounts();
  };

  const records: Participation[] = useMemo(() => (data?.data || []).map(convertStringsToDates), [data]);

  const { results: preScreenerResults, isLoading: loadingPreScreenerResults } = useScreenerResults(
    studyId,
    preScreenerId
  );
  const { results: surveyScreenerResults, isLoading: loadingSurveyScreenerResults } = useScreenerResults(
    studyId,
    surveyScreenerId
  );

  const resultsMap = useMemo(() => {
    const map = new Map();
    if (loadingPreScreenerResults || loadingSurveyScreenerResults) {
      return map;
    }

    const screenerResults: ScreenerResponseResults[] = [];
    screenerResults.push(preScreenerResults);
    screenerResults.push(surveyScreenerResults);

    screenerResults.forEach((results) => {
      if (results.individual) {
        results.individual.forEach((result) => {
          const mapped = result.answers_json.reduce((m, { field_id, value, ideal }) => {
            m[field_id] = { value, ideal };
            return m;
          }, {});
          map.set(result.participation.id, { ...map.get(result.participation.id), ...mapped });
        });
      }
    });

    return map;
  }, [loadingPreScreenerResults, loadingSurveyScreenerResults]);

  const participations: Participation[] = useMemo(() => {
    return records
      .filter(({ deleted_at }) => !deleted_at)
      .map((participation) => ({
        ...participation,
        screenerResults: resultsMap.get(participation.id),
        moderator: team.find(({ name, id }) => (participation.moderator_id === id ? { name, id } : null))
      }));
  }, [records, resultsMap, team]);

  return {
    refetch,
    isError,
    totalPages: data?.meta?.pages || 0,
    initialFetchDone: !isUninitialized && !isLoading,
    isFetching,
    participations,
    counts
  };
};
