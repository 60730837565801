import React, { VFC } from 'react';

import { api } from '@api/reduxApi';
import { Text, Toggle } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { Tooltip } from '@components/shared/Tooltip';
import { useToaster } from '@components/stores/toaster';
import { InfoCircleIcon } from '@components/svgs';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';

enum Features {
  unmoderated_test = 'Unmoderated test',
  survey = 'Survey',
  online_task = 'Online task',
  livestream = 'Livestream',
  incentives = 'Incentives',
  external_recruitment = 'External Recruitment'
}

type ValidFeatures = keyof typeof Features;

const TOOLTIPS: Record<ValidFeatures, string> = {
  external_recruitment:
    'The ability to submit and pay for external recruitment requests\nthrough our integration with Respondent.',
  incentives: 'The ability to fund and pay incentives\nthrough our integration with Tremendous.',
  livestream:
    'The ability to have observers watch a live stream video\nof interviews conducted through Great Question.',
  online_task: 'The ability to create and run\nonline task studies through Great Question.',
  survey: 'The ability to create and run survey studies\nthrough Great Question.',
  unmoderated_test: 'The ability to create and run unmoderated testing\nstudies through Great Question.'
};

export const DisabledFeatures: VFC = () => {
  const disabledFeatures = useDisabledFeatures();
  const [updateFeatures] = api.useUpdateAccountDisabledFeaturesMutation();

  const showToast = useToaster();

  const handleOnToggle = async (feature: ValidFeatures) => {
    let newFeatures: ValidFeatures[] = [];

    Object.keys(disabledFeatures).forEach((key: ValidFeatures) => {
      if (disabledFeatures[key]) {
        newFeatures.push(key);
      }
    });

    if (newFeatures.includes(feature)) {
      newFeatures = newFeatures.filter((f) => f !== feature);
    } else {
      newFeatures = [...newFeatures, feature];
    }

    try {
      await updateFeatures({ disabled_features: newFeatures }).unwrap();

      showToast({
        heading: 'Features updated',
        icon: 'success',
        text: 'Disabled features have been updated.'
      });
    } catch (error) {
      showToast({
        heading: 'Failed to update',
        icon: 'error',
        text: 'Something went wrong updating disabled features. Please try again later.'
      });
    }
  };

  return (
    <section>
      <Text h='600' mb='1' bold>
        Feature access
      </Text>
      <Text h='400' color='gray-500' mb='4'>
        Decide which features users on your account have access to. When toggled off, users won't see these capabilities
        in Great Question.
      </Text>

      <div>
        {Object.keys(Features).map((feature: ValidFeatures) => (
          <RestrictedAction key={feature} permission='disableFeatures'>
            {({ can, may }) => (
              <div className='flex flex-row items-center space-x-2'>
                <Toggle
                  on={!disabledFeatures[feature]}
                  disabled={!can || !may}
                  onToggle={() => handleOnToggle(feature)}
                  name={Features[feature]}
                />
                <div className='flex items-center space-x-2'>
                  <Text h='400' color={can && may ? 'gray-700' : 'gray-500'}>
                    {Features[feature]}
                  </Text>
                  <Tooltip content={TOOLTIPS[feature]} placement='right'>
                    <div className='cursor-pointer'>
                      <InfoCircleIcon className='w-3 h-3' />
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </RestrictedAction>
        ))}
      </div>
    </section>
  );
};
