import pluralize from 'pluralize';
import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../toasts';

interface Props {
  tags: Tag[];
  onClose: () => void;
  onSubmit?: () => void;
}
export const DeleteTagModal: React.FC<Props> = ({ onClose, onSubmit, tags }) => {
  const showToast = useToaster();

  const [destroyTags, { isLoading, isError, isSuccess }] = api.useBulkDestroyTagsMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successDeleteTags());
      onSubmit?.();
    }
  }, [isSuccess]);

  const handleClick = () => destroyTags({ ids: tags.map(({ id }) => id) });

  const taggingCount = tags.reduce((m, { taggings }) => m + taggings.length, 0);
  const count = tags.length;

  return (
    <Modal icon='danger' size='md' onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2'>Deleting {pluralize('tags', count)}</ModalHeading>
      <p className='pb-12 text-gray-700'>
        You want to delete {pluralize('tags', count, true)} with the {pluralize('instances', taggingCount, true)}.
        Actual data (e.g. the transcript quote where this highlight and tag were used) will not be deleted.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={handleClick} danger>
          Delete
        </Button>
      </div>
    </Modal>
  );
};
