import React, { SVGProps } from 'react';

export const RoundCheckMarkSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1421_6398)'>
      <path
        d='M4.5 8.71479L5.92917 10.743C5.98338 10.824 6.05614 10.8909 6.14141 10.9381C6.22668 10.9852 6.32198 11.0114 6.41938 11.0143C6.51679 11.0172 6.61348 10.9968 6.70141 10.9548C6.78934 10.9128 6.86597 10.8504 6.92492 10.7728L11.5 4.98438'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.4375 8C1.4375 9.74048 2.1289 11.4097 3.35961 12.6404C4.59032 13.8711 6.25952 14.5625 8 14.5625C9.74048 14.5625 11.4097 13.8711 12.6404 12.6404C13.8711 11.4097 14.5625 9.74048 14.5625 8C14.5625 6.25952 13.8711 4.59032 12.6404 3.35961C11.4097 2.1289 9.74048 1.4375 8 1.4375C6.25952 1.4375 4.59032 2.1289 3.35961 3.35961C2.1289 4.59032 1.4375 6.25952 1.4375 8Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1421_6398'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
