import React, { SVGProps } from 'react';

export const PlanSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.8125 1.75H13.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.8125 7H13.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.8125 12.25H13.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.625 0.4375H0.875C0.633375 0.4375 0.4375 0.633375 0.4375 0.875V2.625C0.4375 2.86662 0.633375 3.0625 0.875 3.0625H2.625C2.86662 3.0625 3.0625 2.86662 3.0625 2.625V0.875C3.0625 0.633375 2.86662 0.4375 2.625 0.4375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.625 5.6875H0.875C0.633375 5.6875 0.4375 5.88338 0.4375 6.125V7.875C0.4375 8.11662 0.633375 8.3125 0.875 8.3125H2.625C2.86662 8.3125 3.0625 8.11662 3.0625 7.875V6.125C3.0625 5.88338 2.86662 5.6875 2.625 5.6875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.625 10.9375H0.875C0.633375 10.9375 0.4375 11.1334 0.4375 11.375V13.125C0.4375 13.3666 0.633375 13.5625 0.875 13.5625H2.625C2.86662 13.5625 3.0625 13.3666 3.0625 13.125V11.375C3.0625 11.1334 2.86662 10.9375 2.625 10.9375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
