// libs
import cn from 'classnames';
import { usePermission } from 'hooks/usePermission';
import * as React from 'react';
// hooks
import { useState } from 'react';

import { DropdownCombobox, DropdownItem, RenderDropdownItem } from '@components/common/DropdownCombobox';
import { NewAttrModal } from '@components/shared/NewAttrModal';
// components
import { CaretDownSVG } from '@components/svgs';

interface Props {
  className?: string;
  disabled?: boolean;
  defaultLabel: string;
  attrs: Attr_[];
  value: string;
  onChange: (value: string) => Promise<void>;
  errored: boolean;
}

const ComboboxItem: RenderDropdownItem = (item, isHighlighted) => {
  const newAttrClass = cn('xx-create-new-attr', { 'text-white': isHighlighted, 'text-indigo-600': !isHighlighted });

  return (
    <div className='flex flex-col space-y-1'>
      {item._isNew ? (
        <>
          <span className={newAttrClass}>{`”${item.value}”`}</span>
          <span className='text-xs'>Save this as a new attribute</span>
        </>
      ) : (
        <span className='text-sm'>{item.label}</span>
      )}
    </div>
  );
};

const renderInput = (inputProps: React.HTMLProps<HTMLInputElement>): React.ReactElement => {
  return (
    <input
      {...inputProps}
      className='w-full text-sm min-w-48 py-2.5 px-4 text-gray-700 placeholder-gray-400 xx-combo-input'
      type='text'
      placeholder='Find or create an attribute…'
      autoFocus
      autoComplete='off'
      name='dropdown_combobox'
      style={{ outline: 'none', outlineOffset: 'none', boxShadow: 'none', border: 'none', borderWidth: 0 }}
    />
  );
};

export const TdAttributePicker: React.FC<Props> = ({
  className = '',
  defaultLabel: initialDefaultLabel,
  disabled,
  attrs,
  value,
  onChange,
  errored
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [defaultLabel, setDefaultLabel] = useState(initialDefaultLabel);

  const canCreate = usePermission('manageAttrs')();

  const options = attrs
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    .map(({ name, label }) => ({ label, value: name }));

  const selectedItem = options.find((o) => o.value === value);

  const onAttrCreate = async (attr: Attr_) => {
    await onChange(`extra:${attr.name}`);
  };

  const onSelect = (attr: DropdownItem) => {
    if (attr._isNew) {
      setDefaultLabel(attr.value);
      setModalOpen(true);
    } else {
      onChange(attr.value);
    }
    setSelecting(false);
  };

  const tdClass = cn(className, {
    'bg-gray-50': disabled,
    'border-indigo-600 border': !disabled && !value && !errored,
    'border-red-600 border': !disabled && errored
  });

  const buttonClass = cn(
    'flex w-full min-w-48 justify-between items-center px-4 py-3 border-0 outline-none hover:bg-grey-50',
    {
      'text-gray-400 cursor-default': disabled
    }
  );

  return (
    <td className={tdClass}>
      {selecting ? (
        <DropdownCombobox
          allowCreate={canCreate}
          unlimitedItems
          items={options}
          selectedItem={selectedItem}
          placeholder='Find or create an attribute…'
          onClickOutside={() => setSelecting(false)}
          renderInput={renderInput}
          onSelect={onSelect}
          renderItem={ComboboxItem}
        />
      ) : (
        <button disabled={disabled} className={buttonClass} onClick={() => setSelecting(true)}>
          <span className='mr-2 text-sm'>{selectedItem?.label || 'Select attribute…'}</span>
          <CaretDownSVG />
        </button>
      )}

      <NewAttrModal
        defaultLabel={defaultLabel}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={onAttrCreate}
      />
    </td>
  );
};
