import React, { CSSProperties, HTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

import { useVideoSeekBarContext } from '../hooks/useVideoSeekBarContext';

export const Thumb = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ children, ...rest }, ref) => {
  const {
    state: { currentTime, isHovering, max, trackWidth }
  } = useVideoSeekBarContext();

  const style: CSSProperties = {
    left: (currentTime / max) * trackWidth
  };

  return (
    <div
      ref={ref}
      data-testid='video-seek-bar-thumb'
      style={style}
      className={cn(
        'transition-opacity rounded-full bg-indigo-600 w-3 h-3 top-1/2 -ml-1.5 absolute pointer-events-none transform -translate-y-1/2',
        {
          'opacity-0': !isHovering,
          'scale-x-150': isHovering
        }
      )}
      {...rest}
    >
      {children}
    </div>
  );
});
