import classNames from 'classnames';
import type { FC, ReactElement, ReactNode, SyntheticEvent } from 'react';
import * as React from 'react';
import { cloneElement, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { useOnEscape } from '@components/utils';
import type { Instance as PopperInstance, Placement, PositioningStrategy } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import { NO_OUTLINE } from '@components/CandidateAttrs';

export enum Events {
  click = 'click',
  hover = 'hover'
}

export type Offset = [number, number];

export interface ContentParams {
  closePopper: () => void;
  updatePopper: () => void;
}

export interface Props {
  children: ReactElement;
  content: (args: ContentParams) => ReactNode;
  placement?: Placement;
  strategy?: PositioningStrategy;
  offset?: Offset;
  closeOnClickOutside?: boolean;
  closeOnEscape?: boolean;
  className?: string;
  onOpen?: (popper: PopperInstance | undefined) => void;
  onClose?: (popper: PopperInstance | undefined) => void;
  event?: keyof typeof Events;
  boundary?: HTMLDivElement;
  zIndex?: number;
  renderOnBodyRoot?: boolean;
  isDisabled?: boolean;
  hideBeforeInit?: boolean;
  syncWidth?: boolean;
  closeOnButtonClick?: boolean;
  autofocus?: boolean;
  closeOnDropdownClick?: boolean;
}

const ROOT_ID = 'gq-popper-root';
const DEFAULT_PLACEMENT = 'top';
const DEFAULT_STRATEGY = 'absolute';

const buildOffsetModifier = (offset: [number, number]) => {
  return {
    name: 'offset',
    options: { offset }
  };
};

const buildPreventOverflowModifier = (element: Props['boundary']) => {
  return {
    name: 'preventOverflow',
    options: {
      boundary: element,
      padding: 1
    }
  };
};

const buildSameWidthModifier = () => ({
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite' as const,
  requires: ['computeStyles'],
  fn({ state }) {
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect({ state }) {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  }
});

export const Popper: FC<Props> = ({
  content,
  placement = DEFAULT_PLACEMENT,
  strategy = DEFAULT_STRATEGY,
  offset,
  boundary,
  closeOnClickOutside,
  closeOnEscape = false,
  event = Events.click,
  zIndex,
  className,
  children,
  onOpen,
  onClose,
  renderOnBodyRoot = true,
  isDisabled = false,
  hideBeforeInit = true,
  closeOnButtonClick = true,
  autofocus = false,
  syncWidth
}) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const triggerRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>(null);
  const popperInstanceRef = useRef<PopperInstance>();
  const firstMount = useRef(true);

  const showContent = () => setIsMounted(true);

  const hideContent = () => {
    setIsMounted(false);
  };

  const updatePopper = () => {
    if (popperInstanceRef.current) popperInstanceRef.current.forceUpdate();
  };

  const appendRoot = (): void => {
    if (!document.getElementById(ROOT_ID)) {
      const root = document.createElement('div');
      root.id = ROOT_ID;

      document.body.appendChild(root);
    }
  };

  const getEventHandlers = () => {
    if (isDisabled) {
      return {};
    }

    if (event === Events.click) {
      return {
        onClick: (e: SyntheticEvent) => {
          e.stopPropagation();
          (isMounted && closeOnButtonClick ? hideContent : showContent)();
          if (children.props.onClick) children.props.onClick(e, hideContent);
        }
      };
    }

    if (event === Events.hover) {
      return {
        onMouseEnter: (e: SyntheticEvent) => {
          showContent();
          if (children.props.onMouseEnter) children.props.onMouseEnter(e);
        },
        onMouseLeave: (e: SyntheticEvent) => {
          hideContent();
          if (children.props.onMouseEnter) children.props.onMouseLeave(e);
        }
      };
    }
  };

  const showOnMouseEnter = () => {
    if (isDisabled) {
      return;
    }

    if (event === Events.hover) {
      showContent();
    }
  };

  const hideOnMouseLeave = () => {
    if (isDisabled) {
      return;
    }

    if (event === Events.hover) {
      hideContent();
    }
  };

  const initPopper = () => {
    if (!contentRef.current) return;

    const modifiers: any[] = [];

    if (offset) modifiers.push(buildOffsetModifier(offset));
    if (boundary) modifiers.push(buildPreventOverflowModifier(boundary));
    if (syncWidth) modifiers.push(buildSameWidthModifier());

    popperInstanceRef.current = createPopper(triggerRef.current as any, contentRef.current, {
      placement,
      modifiers,
      strategy
    });

    if (zIndex) contentRef.current.className = classNames(className, `z-${zIndex}`);
    // avoid flickers
    if (hideBeforeInit) contentRef.current.style.display = 'block';
  };

  const clickOutSideHandler = (event: MouseEvent) => {
    if (!closeOnClickOutside) return;

    const { current: triggerEl } = triggerRef;
    const { current: contentEl } = contentRef;
    const target = event.target as Node;

    if (contentEl && triggerEl && !(contentEl.contains(target) || triggerEl.contains(target))) {
      hideContent();

      if (autofocus) {
        requestAnimationFrame(() => triggerRef.current?.focus());
      }
    }
  };

  useEffect(() => {
    appendRoot();

    return () => {
      if (popperInstanceRef.current) popperInstanceRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    let raf: number;

    if (isMounted) {
      raf = requestAnimationFrame(initPopper);
    } else if (popperInstanceRef.current) {
      popperInstanceRef.current.destroy();
    }

    return () => {
      if (raf) cancelAnimationFrame(raf);
    };
  }, [isMounted]);

  useEffect(() => {
    if (isMounted) {
      if (autofocus) {
        requestAnimationFrame(() => contentRef.current?.focus());
      }
      onOpen?.(popperInstanceRef.current);
    } else if (!firstMount.current) {
      onClose?.(popperInstanceRef.current);
    }

    firstMount.current = false;
  }, [isMounted]);

  useEffect(() => {
    document.addEventListener('click', clickOutSideHandler, { capture: true });

    return () => {
      document.removeEventListener('click', clickOutSideHandler);
    };
  }, [contentRef, triggerRef, isMounted]);

  useEffect(() => {
    if (isDisabled) {
      setIsMounted(false);
    }
  }, [isDisabled]);

  useOnEscape(() => {
    if (closeOnEscape && isMounted) {
      setIsMounted(false);
      if (autofocus) {
        requestAnimationFrame(() => triggerRef.current?.focus());
      }
    }
  }, [isMounted]);

  const Content = (
    <div
      onMouseEnter={showOnMouseEnter}
      onMouseLeave={hideOnMouseLeave}
      className={className}
      ref={contentRef}
      tabIndex={-1}
      style={{ ...NO_OUTLINE, display: hideBeforeInit ? 'none' : 'block' }}
    >
      {typeof content === 'string'
        ? content
        : content({
            closePopper: hideContent,
            updatePopper
          })}
    </div>
  );

  // Caio and Pedro spent _hours_ trying to get this thing below to be on a separate component, but
  // for some reason when it is on a separate content, and _any_ state change happens, Popper bugs
  // out and the content disappears.
  return (
    <>
      {cloneElement(children, {
        ref: triggerRef,
        ['aria-expanded']: isMounted,
        ...getEventHandlers()
      })}
      {isMounted && renderOnBodyRoot && ReactDOM.createPortal(Content, document.getElementById(ROOT_ID) as any)}
      {isMounted && !renderOnBodyRoot && Content}
    </>
  );
};
