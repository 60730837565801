import React, { HTMLAttributes, forwardRef, useEffect } from 'react';
import { useDraggable } from '@dnd-kit/core';

import { useDrawerContext } from '../hooks/useDrawerContext';
import { useStyles } from '../hooks/useStyles';

import { Header } from './Header';
import { Content } from './Content';

interface Props extends HTMLAttributes<HTMLDivElement> {
  header: (isExpanded: boolean) => JSX.Element;
}

export const Sheet = forwardRef<HTMLDivElement, Props>(({ children, header, ...rest }, ref) => {
  const {
    state: { isExpanded },
    setIsDragging,
    setTransformY
  } = useDrawerContext();

  const { attributes, isDragging, listeners, transform, setNodeRef } = useDraggable({ id: 'header' });

  const { styles } = useStyles();

  useEffect(() => {
    setIsDragging(isDragging);
  }, [isDragging]);

  useEffect(() => {
    setTransformY(transform?.y ?? 0);
  }, [transform?.y]);

  return (
    <div ref={ref} style={styles} {...rest}>
      <Header ref={setNodeRef} style={{ touchAction: 'none', pointerEvents: 'all' }} {...attributes} {...listeners}>
        {header(isExpanded)}
      </Header>

      <Content>{children}</Content>
    </div>
  );
});
