import * as React from 'react';

export const StickyNoteSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
    <path
      d='M7.95025 13.5625H1.3125C1.08044 13.5625 0.857876 13.4703 0.693782 13.3062C0.529687 13.1421 0.4375 12.9196 0.4375 12.6875V1.3125C0.4375 1.08044 0.529687 0.857876 0.693782 0.693782C0.857876 0.529687 1.08044 0.4375 1.3125 0.4375H12.6875C12.9196 0.4375 13.1421 0.529687 13.3062 0.693782C13.4703 0.857876 13.5625 1.08044 13.5625 1.3125V7.95025C13.5625 8.18215 13.4703 8.40455 13.3064 8.56858L8.56858 13.3064C8.40455 13.4703 8.18215 13.5625 7.95025 13.5625Z'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.3125 13.4838V9.1875C8.3125 8.95544 8.40469 8.73288 8.56878 8.56878C8.73288 8.40469 8.95544 8.3125 9.1875 8.3125H13.4838'
      stroke='currentColor'
      strokeWidth='0.88'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
