import cn from 'classnames';
import * as React from 'react';
import { useMemo } from 'react';
import { useTable } from 'react-table';

import { useTabIndexer } from '@hooks/useTabIndexer';

import { buildColumns } from './buildColumns';
import { Default as Cell } from './cells/Default';
import { Default as Header } from './headers/Default';

type Props = {
  items: UploadManagerItem[];
  studyId?: number;
};
export const UploadsTable: React.FC<Props> = ({ items, studyId }) => {
  const anyZoom = items.some((i) => i.type === 'zoom');

  const { tabIndex } = useTabIndexer();

  const columns = useMemo(() => buildColumns({ showDate: anyZoom, studyId }), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<UploadManagerItem>({
    columns,
    defaultColumn: { Header, Cell },
    data: items
  });

  return (
    <div className='w-full h-full overflow-x-auto overflow-y-visible'>
      <table className='w-full' {...getTableProps()}>
        <thead className='border-b border-gray-200'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className='pb-2' {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className='divide-y divide-gray-200' {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  const className = cn('py-4', { 'pr-4': i !== row.cells.length - 1 });
                  return (
                    <td className={className} {...cell.getCellProps()}>
                      {cell.render('Cell', { tabIndex: tabIndex() })}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
