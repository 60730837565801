import * as toasts from 'components/StudiesApp/components/StudyDraft/toasts';
import { ProfileContextProvider } from 'hooks/useProfileContext';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Attribute } from '@components/CandidateProfile/components/index';
import { buildVisibleAttrs } from '@components/CandidateProfile/helpers';
import { AddAttribute } from '@components/shared/AddAttribute';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

interface Props {
  study: Study;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  smallButton?: boolean;
}

export const StudyAttrsTable: React.FC<Props> = ({ study, setLoading, smallButton }) => {
  const [expanded, setExpanded] = useState(false);
  const { data: studyAttrs } = api.useGetStudyAttrsQuery();
  const [newAttr, setNewAttr] = useState<Attr_[]>([]);
  const { visibleAttrs, totalAttrs } = buildVisibleAttrs({ attrs: studyAttrs || [], newAttr, entity: study, expanded });
  const canUpdate = usePermission<Study>('updateStudy')(study);
  const showToast = useToaster();
  const [updateStudy, { isSuccess: updateSuccess, isError: updateError }] = api.useUpdateStudyMutation();
  const [createStudyAttr] = api.useCreateStudyAttrMutation();

  async function create(attr: Partial<Attr_>): Promise<Attr_> {
    return new Promise<Attr_>((resolve, reject) => {
      createStudyAttr(attr)
        .unwrap()
        .then((resp) => {
          return resolve(resp);
        })
        .catch(reject);
    });
  }

  const addNewAttrToList = (attr: Attr_): void => {
    setNewAttr([...newAttr, attr]);
  };

  useEffect(() => {
    if (totalAttrs < 6) {
      setExpanded(true);
    }
  }, []);

  useEffect(() => {
    if (updateError) {
      showToast(toasts.failedUpdate());
    }
  }, [updateError]);

  useEffect(() => {
    if (updateSuccess) {
      showToast(toasts.successUpdate());
    }
  }, [updateSuccess]);

  const updateValue = async (name: string, value: unknown) => {
    const attrs = { ...study.custom_attributes, [name]: value };
    setLoading?.(true);
    return updateStudy({ id: study.id, custom_attributes: attrs }).finally(() => {
      setLoading?.(false);
    });
  };

  const options = useMemo(
    () =>
      studyAttrs
        ?.filter((attr) => {
          // Filter out attrs that are already visible
          return !visibleAttrs.find((visibleAttr) => visibleAttr.name === attr.name);
        })
        .map((attr) => ({ label: attr.label, value: attr.name, data: attr })) || [],
    [studyAttrs, visibleAttrs]
  );

  return (
    <ProfileContextProvider>
      <table className='w-full'>
        <tbody>
          {visibleAttrs.map((attr, i) => (
            <Attribute
              key={`${attr.label}${i}`}
              attr={attr}
              index={i}
              canEdit={canUpdate}
              entity={study}
              onUpdate={(value) => updateValue(attr.name, value)}
              style='slideOut'
              onClickDelete={() => console.log('onClickDelete')}
              isStudyAttr
            />
          ))}
        </tbody>
      </table>
      {canUpdate && (
        <AddAttribute
          smallButton={smallButton}
          addNewAttrToList={addNewAttrToList}
          onAdd={() => setExpanded(true)}
          defaultButton
          visibleAttrs={visibleAttrs}
          createAttr={create}
          options={options}
        />
      )}
    </ProfileContextProvider>
  );
};
