import { AIBots } from 'api/chat-gpt';
import { Title } from 'components/shared/AI';
import { format } from 'date-fns';
import { useUser } from 'hooks/useUser';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getTags, updateHighlightTags } from '@api/queries';
import { api } from '@api/reduxApi';
import { AvatarFromId, DropdownLink, Spinner, Text, TippyOrNot } from '@components/common';
import { Column, Grid } from '@components/common/Grid';
import { Skeleton } from '@components/RepoSessionApp/ClipPage/components/Skeleton';
import { ArtifactBreadcrumbs } from '@components/RepositoryApp/components/ArtifactBreadcrumbs';
import { BulkAddToHighlightReel } from '@components/RepositoryApp/components/BulkAddToHighlightReel';
import { BulkAddToInsightDropdown } from '@components/RepositoryApp/components/BulkAddToInsightDropdown';
import { ClipTranscript } from '@components/shared/ClipTranscript';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { EditableHeader } from '@components/shared/EditableHeader';
import { HighlightShareAccessDropdown } from '@components/shared/ShareAccess';
import { AskAiSVG, ClockSVG, PersonSVG, TrashSVG } from '@components/svgs';
import { TagList } from '@components/tags';
import { track } from '@components/tracking';
import { compact, getHighlightDisplayTitle } from '@components/utils';
import VideoPlayer from '@components/VideoPlayer';
import { useAccount } from '@hooks/useAccount';
import { useDeviceType } from '@hooks/useDeviceType';
import { useMuxVideoDownloadUrl } from '@hooks/useMuxVideoDownloadUrl';
import { usePermission } from '@hooks/usePermission';
import { useTrackDuration } from '@hooks/useTrackDuration';
import { useTags } from '@stores/tags';
import { useToaster } from '@stores/toaster';

import { Views } from '../../shared/Views';
import { DeleteClipModal, OptionsDropdown } from './components';
import { useEditorState } from './hooks/useEditorState';
import * as toasts from './toasts';
import { renderHighlightContent } from '@components/RepositoryApp/components/PreviewContentSlideout/components/HighlightContent';

export interface RouteParams {
  from: string;
  to: string;
  id: string;
  highlightUuid: string;
  uuid: string;
}

export const ClipPage: FC = () => {
  const { highlightUuid } = useParams() as { highlightUuid: string };

  const [allTags, setAllTags] = useState<Tag[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const trackAddTag = useTrackDuration({ action: 'add_tag_on_clip_page' });

  const { isMobile } = useDeviceType();

  const currentUser = useUser();
  const { account } = useAccount();

  const canCreate = usePermission('canCreate')();
  const canUpdate = usePermission('updateClip')();

  const { data: highlight, isLoading: highlightLoading } = api.useGetHighlightByIdQuery(
    { id: highlightUuid },
    { refetchOnMountOrArgChange: true }
  );

  const { data: session } = api.useGetRepoSessionQuery(
    { uuid: highlight?.clip?.session_uuid },
    { skip: !highlight?.clip }
  );

  const [destroyHighlight] = api.useDestroyHighlightMutation();
  const [updateHighlight] = api.useUpdateHighlightMutation();

  const { recording, session_at: date, title, participation } = session ?? {};
  const { document_id: documentId } = recording?.transcript ?? {};
  const names = compact([participation?.moderator?.name, participation?.name]);
  const owner = session?.creator || session?.study?.owner;

  const { getTextNodes, updateMark, state } = useEditorState({ documentId });

  const { getTag } = useTags();

  const textNodes = useMemo(
    () => getTextNodes([highlight?.clip?.from || 0, highlight?.clip?.to || 0]),
    [highlight?.clip, state]
  );

  const sessionPath = `/sessions/${highlight?.clip ? highlight?.clip?.session_uuid : highlight?.session_uuid}`;

  const { downloadUrl } = useMuxVideoDownloadUrl(highlight?.clip?.mux_video?.id);

  const isClipReady = highlight?.clip?.mux_video?.status === 'ready';

  const showToast = useToaster();

  const goToInterview = () => {
    window.location.assign(sessionPath);
  };

  const handleOnTagSelect = async (newTagIds: number[]) => {
    trackAddTag.start();
    setTags(allTags.filter(({ id }) => newTagIds.includes(id)));

    if (highlight) {
      setIsLoading(true);
      const updatedHighlight = await updateHighlightTags(
        {
          documentId: highlight.document_id,
          highlightId: highlight.id
        },
        newTagIds
      );

      updateMark(updatedHighlight);
      setIsLoading(false);
    }
    trackAddTag.stop();
  };

  const handleOnHighlightDelete = async () => {
    try {
      await destroyHighlight({ highlightUuid }).unwrap();

      setTags([]);
      goToInterview();
    } catch {
      showToast(toasts.failedDelete);
    }
  };

  // tracking methods
  useEffect(() => {
    if (highlight?.clip) {
      track('viewed_clip', {
        layout: 'page', // or modal
        artifact_type: 'clip',
        session_uuid: highlight?.clip?.session_uuid,
        has_recording: true,
        highlight_uuid: highlightUuid,
        is_creator: highlight && highlight?.creator_id === currentUser?.id,
        is_guest: false
      });
    }
  }, [highlight?.uuid]);

  const trackViewsModal = () => {
    track('page_views', { page: 'clip' });
  };

  useEffect(() => {
    if (highlight) {
      setTags(compact(highlight.tag_ids.map(getTag)));
    }
  }, [highlight, getTag]);

  useEffect(() => {
    const getAllTags = async () => {
      setAllTags(await getTags());
    };

    if (recording || !highlight?.clip_id) {
      getAllTags();
      setIsLoading(false);
    }
  }, [recording, highlight?.clip_id]);

  if (!highlightUuid) {
    goToInterview();
    return null;
  }

  const handleUpdateTitleOrDescription = async (mode: 'title' | 'description', value: string) => {
    try {
      await updateHighlight({ uuid: highlightUuid, [mode]: value }).unwrap();

      showToast(mode === 'title' ? toasts.updatedTitle : toasts.updatedDescription);
    } catch {
      showToast(toasts.failedUpdate);
    }
  };

  const updateDate = highlight?.updated_at ? new Date(highlight.updated_at) : null;

  const dropdownOptions: string[] = compact([highlight?.clip && 'download', canUpdate && 'delete']);

  return (
    <section className='flex flex-col h-full pb-8 bg-white'>
      <DeleteClipModal
        isOpen={showDeleteModal}
        highlightUuid={highlightUuid}
        onConfirm={handleOnHighlightDelete}
        onClose={() => setShowDeleteModal(false)}
      />
      {isLoading && <Skeleton />}
      {!isLoading && highlight && (
        <>
          <header className='desktop:px-12 z-30 p-4 bg-white border-b border-gray-200'>
            <ArtifactBreadcrumbs
              study={session?.study || highlight.study}
              parent_repo_session={session}
              artifactTitle={highlight?.clip?.title ?? highlight?.title ?? 'Untitled highlight'}
            />
            <div className='flex items-start justify-between'>
              <div className='flex flex-col'>
                <Title<{ account_id: number; context_type: string; id: number | null }>
                  bot={AIBots.HighlightTitle}
                  context={{ account_id: account.id, context_type: 'highlight', id: highlight.id }}
                  value={getHighlightDisplayTitle(highlight)}
                  onSave={(value) => handleUpdateTitleOrDescription('title', value)}
                  aria-label='title'
                  readOnly={!canCreate}
                  disabled={!canCreate}
                  name='title'
                  fetchOnMount={!highlight?.title && !highlight?.clip?.title && canCreate}
                />

                <EditableHeader
                  readOnly={!canCreate}
                  mode='description'
                  className='w-full mt-4 truncate'
                  initialValue={highlight?.description || highlight?.clip?.description || ''}
                  placeholder='Add description'
                  onBlur={(value) => handleUpdateTitleOrDescription('description', value)}
                />
              </div>

              <div className='flex-nowrap justify-end flex items-center space-x-1.5 tablet:space-x-3'>
                <CopyURLButton icon='link' text={window.location.href} />
                {!isMobile && highlight?.views && <Views views={highlight.views} onOpen={trackViewsModal} />}
                {canCreate && (
                  <>
                    <TippyOrNot show={!highlight?.clip} content='Only highlights with videos can be added to reels'>
                      <BulkAddToHighlightReel
                        disabled={!highlight?.clip}
                        medium={false}
                        studyId={session?.project_id}
                        selectedArtifacts={[`Clip_${highlight?.clip?.id}`]}
                      />
                    </TippyOrNot>
                    <BulkAddToInsightDropdown
                      medium={false}
                      primary={false}
                      studyId={session ? session?.project_id : highlight?.study?.id}
                      selectedArtifacts={
                        highlight?.clip ? [`Clip_${highlight?.clip.id}`] : [`Highlight_${highlight.id}`]
                      }
                    />
                  </>
                )}
                {highlight && (
                  <HighlightShareAccessDropdown readOnly={!canUpdate} uuid={highlightUuid} highlight={highlight} />
                )}
                {!isMobile && dropdownOptions.length > 0 && (
                  <OptionsDropdown dropdownClassName='top-9' aria-label='More options'>
                    {dropdownOptions.includes('download') && (
                      <DropdownLink
                        className='flex items-center'
                        disabled={!downloadUrl}
                        onClick={() => window.open(downloadUrl)}
                      >
                        {!downloadUrl && <Spinner className='w-4 h-4 mr-2' />}
                        Download highlight
                      </DropdownLink>
                    )}
                    {dropdownOptions.includes('delete') && (
                      <DropdownLink
                        className='flex items-center'
                        color='red-600'
                        hoverColor='red-700'
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <TrashSVG className='mr-2' />
                        Delete highlight…
                      </DropdownLink>
                    )}
                  </OptionsDropdown>
                )}
              </div>
            </div>
            <section className='flex mt-4 space-x-6'>
              {highlight?.clip && (
                <div className='xx-session-moderator flex items-center'>
                  {highlight?.ai ? (
                    <>
                      <AskAiSVG className='w-4 h-4 mr-2' />
                      <Text h='400' className='leading-5'>
                        Great Question AI
                      </Text>
                    </>
                  ) : (
                    <>
                      <AvatarFromId size='sm' userId={owner?.id} />
                      <Text h='400' className='ml-1 leading-5'>
                        {owner?.name}
                      </Text>
                    </>
                  )}
                </div>
              )}
              {updateDate && (
                <Text h='400' className='flex items-center text-gray-500'>
                  <ClockSVG className='mr-1' />
                  {format(updateDate, 'PP')}
                </Text>
              )}
              {names.length > 0 && (
                <Text h='400' className='flex items-center text-gray-500'>
                  <PersonSVG className='mr-1' /> {names.join(', ')}
                </Text>
              )}
              <TagList
                studyId={highlight?.study?.id}
                readOnly={!canCreate}
                placement='bottom-start'
                tagIds={tags.map(({ id }) => id)}
                previewShowLimit={isMobile ? 2 : undefined}
                onChange={handleOnTagSelect}
                className='focus:bg-gray-50 focus:outline-none hover:bg-gray-50 flex-nowrap flex items-center w-full space-x-2 overflow-hidden'
              />
            </section>
          </header>

          <section className='desktop:px-12 tablet:px-6 px-3'>
            <Grid tiny={12} mobile={12} tablet={12} desktop={12}>
              <Column tiny={0} mobile={0} tablet={0} desktop={3} />
              <Column tiny={12} mobile={12} tablet={12} desktop={6}>
                <div className='w-full mt-6 mb-6'>
                  {highlight?.clip && (
                    <VideoPlayer
                      clip={!isClipReady ? [highlight?.clip.from / 1000, highlight?.clip.to / 1000] : undefined}
                      src={highlight?.clip.stream_url}
                      poster={highlight?.clip.mux_video?.thumbnail_url || undefined}
                      onPlay={() => track('played_video', { page: 'highlight', artifact_type: 'highlight' })}
                    />
                  )}
                  {!highlight?.clip && !!highlight?.content?.length && renderHighlightContent(highlight.content)}
                </div>

                {highlight?.clip && (
                  <section className='pb-4 mb-4'>
                    <ClipTranscript className='mt-4 mb-2' textNodes={textNodes} />
                  </section>
                )}
              </Column>
              <Column tiny={0} mobile={0} tablet={0} desktop={3} />
            </Grid>
          </section>
        </>
      )}
    </section>
  );
};
