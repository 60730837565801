import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { useToaster } from '@components/stores/toaster';

import * as toasts from '../toasts';

export interface Params {
  kind: Extract<TemplateKind, 'EmailTemplate' | 'SurveyTemplate'>;
}

interface Hook {
  duplicate: (params: { id: number }) => void;
  isLoading: boolean;
}

export const useDuplicateTemplate = ({ kind }: Params): Hook => {
  const useDuplicateMutation = {
    InterviewTemplate: api.useDuplicateStudyTemplateMutation,
    EmailTemplate: api.useDuplicateEmailTemplateMutation,
    SurveyTemplate: api.useDuplicateStudyTemplateMutation
  }[kind];

  const [duplicate, { isSuccess, data, isLoading }] = useDuplicateMutation();
  const showToast = useToaster();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      navigate(`${data?.id}`);
      showToast(toasts.successCreate());
    }
  }, [isSuccess]);

  return { duplicate, isLoading };
};
