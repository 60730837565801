import cn from 'classnames';
import * as React from 'react';
import { forwardRef, useState } from 'react';

import { Button, Text } from '@components/common';
import { MultiselectDropdownItem } from '@components/shared/MultiselectCombobox';
import { SearchSVG } from '@components/svgs';
import { compact } from '@components/utils';

const STATUS_COLORS = {
  draft: 'bg-yellow-400',
  pending: 'bg-orange-400',
  active: 'bg-green-600',
  closed: 'bg-gray-400',
  paused: 'bg-gray-400',
  archived: 'bg-gray-400',
  cancelled: 'bg-red-100'
};

interface Props<T extends string | number> {
  error?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  dropdownClass?: string;
  className?: string;
  label?: string;
  onSearch?: (value: string) => void;
  items: MultiselectDropdownItem<T>[];
  single?: boolean;
  selectedValues?: T[];
  onSave: (value: T[]) => void;
  autoSave?: boolean;
  onSaveSingle?: (value: T) => void;
  canCreate?: boolean;
  styles?: { [key: string]: React.CSSProperties };
  attributes?: { [key: string]: { [key: string]: string } | undefined };
}

const MultiselectDropdown = <T extends string | number = string>(props: Props<T>, ref): React.ReactElement => {
  const {
    error,
    required,
    children,
    dropdownClass,
    single,
    className,
    label,
    onSearch,
    items,
    selectedValues,
    onSave,
    autoSave,
    onSaveSingle,
    canCreate,
    attributes,
    styles
  } = props;

  const [selected, setSelected] = useState<T[]>(selectedValues || []);

  const onChange = (value) => {
    let newSelected;
    if (selected?.includes(value)) {
      newSelected = selected.filter((item) => item !== value);
    } else {
      newSelected = [...selected, value];
    }
    setSelected(newSelected);
    if (autoSave) onSave(newSelected);
  };

  const inputClass = compact([
    'focus:ring-indigo-500 w-4 h-4 text-indigo-600 border-gray-700 cursor-pointer',
    error && 'border-red-600'
  ]).join(' ');

  return (
    <div ref={ref} {...attributes?.popper} style={{ ...styles?.popper }} className={dropdownClass}>
      <div className={cn('p-2 shadow-lg rounded-md bg-white w-full max-w-xs min-w-48 overflow-hidden', className)}>
        {children}
        {label && (
          <Text h='300' uppercase className='mb-3'>
            {label}
          </Text>
        )}
        {!!onSearch && (
          <div className='relative flex items-center mb-2'>
            <SearchSVG className='left-5 absolute' />
            <input
              className='w-full pb-2 pl-12 border-gray-200 rounded-md'
              type='search'
              autoFocus
              placeholder='Search'
              onChange={(event) => onSearch(event.target.value)}
              required={required}
              aria-invalid={error}
            />
          </div>
        )}

        <ul className='max-h-48 mb-2 -space-y-px overflow-x-hidden'>
          {items?.map(({ label, value, status }, index) => (
            <li key={`${value} ${index}`} className='relative px-2 py-2'>
              <div onClick={single ? () => onSaveSingle?.(value) : undefined}>
                <label className='flex items-center justify-between space-x-3 cursor-pointer'>
                  {!single && (
                    <div className='flex items-center h-5'>
                      <input
                        name={label}
                        type='checkbox'
                        value={value || ''}
                        onChange={() => onChange(value)}
                        checked={selected?.includes(value)}
                        className={inputClass}
                      />
                    </div>
                  )}

                  <Text truncate h='400' className='flex-grow block text-left'>
                    {label}
                  </Text>
                  {status && <div className={cn('min-w-2 h-2 w-2 ml-2 rounded-lg', STATUS_COLORS[status])} />}
                </label>
              </div>
            </li>
          ))}
          {!items.length && canCreate && (
            <li className='text-sm'>
              Press <span className='font-bold'>Enter</span> to create new
            </li>
          )}
        </ul>
        {!single && !autoSave && (
          <div className='flex items-center justify-between pt-2 border-t border-gray-200'>
            <span>
              <a onClick={() => setSelected([])} className='text-sm text-indigo-600 cursor-pointer'>
                Clear
              </a>
            </span>
            <Button primary onClick={() => onSave(selected)}>
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const MultiselectDropdownWithRef = forwardRef(MultiselectDropdown) as <T extends string | number>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof MultiselectDropdown>;

export { MultiselectDropdownWithRef as MultiselectDropdown };
