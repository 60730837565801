import React from 'react';
import { Text } from 'components/common';

export const BlockHeader = ({ children }) => (
  <Text h='600' medium className='mt-6 inline-block'>
    {children}
  </Text>
);
export const Label = ({ children }) => (
  <Text h='400' medium className='mt-6'>
    {children}
  </Text>
);
export const Description = ({ children }) => (
  <Text h='400' color='gray-500' className='mt-1'>
    {children}
  </Text>
);
