import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import { api } from '@api/reduxApi';
import { useAccount } from '@hooks/useAccount';
import React from 'react';
import { format as timeagoFormat } from 'timeago.js';
import { Avatar, InfoLabel, Text } from '@components/common';
import pluralize from 'pluralize';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { formatDuration } from '@components/shared/Artifact/components/utils';
import { EditableTitle } from '@components/common/EditableTitle';
import { getArtifactInfoIcon, getArtifactInfoLabel } from '@components/shared/Artifact/components/utils';

interface Props {
  artifact: ArtifactHit;
  studyId?: number;
  updateData?: (v: Partial<ArtifactHit>) => void;
}

export const Header: React.FC<Props> = ({ updateData, artifact, studyId }) => {
  const { study_ids, owner_id, tag_ids, clips, created_at, updated_at, title, subtitle, duration, kind, views } =
    artifact;

  const { renderTagsList } = useRenderTagsList();

  const { data: study } = api.useGetSimpleStudyQuery(study_ids?.[0], { skip: !study_ids?.[0] });

  const { getUserById } = useAccount();

  const owner = getUserById(owner_id);

  return (
    <>
      <div className='w-full pb-4 pt-6 border-b border-gray-200'>
        <div className='px-4'>
          <EditableTitle
            readOnly={!updateData}
            initialValue={title || 'Untitled'}
            onBlur={(title) => updateData?.({ title })}
          />
        </div>

        {subtitle && artifact.kind !== 'Repo::Session' && (
          <Text as='p' h='400' className='px-6 mt-3 mb-2'>
            {subtitle}
          </Text>
        )}

        <div className='flex flex-wrap px-6'>
          {owner && (
            <div className='flex items-center mt-3 mr-2 space-x-3'>
              <Avatar size='sm' user={owner} />
              <Text h='400' className='leading-5'>
                {owner.name}
              </Text>
            </div>
          )}
          {study && <InfoLabel className='mt-3 mr-4' pill={false} icon='checklist' text={study.title} />}
          <InfoLabel
            className='mt-3 mr-4'
            icon={getArtifactInfoIcon(artifact.kind)}
            text={getArtifactInfoLabel(artifact.kind)}
          />
          {!!clips?.length && (
            <InfoLabel
              className='mt-3 mr-4'
              pill={false}
              icon='film'
              text={`${pluralize('highlight', clips.length, true)}`}
            />
          )}

          <InfoLabel
            className='mt-3 mr-4'
            pill={false}
            icon='checkmark'
            text={`Created ${timeagoFormat(created_at)}`}
          />

          <InfoLabel
            className='mt-3 mr-4'
            pill={false}
            icon='checkmark'
            text={`Updated ${timeagoFormat(updated_at)}`}
          />

          {typeof duration === 'number' && kind !== 'Story' && (
            <InfoLabel className='mt-3 mr-4' pill={false} icon='clock' text={formatDuration(duration)} />
          )}

          {typeof views === 'number' && views > 0 && (
            <InfoLabel className='mt-3 mr-4' pill={false} icon='eye' text={views.toLocaleString()} />
          )}
          {!!tag_ids?.length && <div className='w-full mt-3'>{renderTagsList(tag_ids, { max: 5 })}</div>}
        </div>
      </div>
    </>
  );
};
