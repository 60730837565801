import React, { VFC, useEffect, useState } from 'react';
import pluralize from 'pluralize';

import { Modal, Pill, ProgressBar, Spinner, Text } from '@components/common';
import { Tooltip } from '@components/shared/Tooltip';
import { CheckMark2SVG, CloseSVG } from '@components/svgs';
import { IPath, PrototypePathsProcessor } from '@lib/prototype-paths-processor/PrototypePathsProcessor';

import { ScreenThumbnail } from './ScreenThumbnail';
import { ScreensPreview } from './ScreensPreview';

interface Props {
  answers: NonNullable<ScreenerResponseAnswerValue<'prototype_test'>>[];
  prototypeTestId: number;
}

const processor = new PrototypePathsProcessor();

export const Paths: VFC<Props> = ({ answers, prototypeTestId }) => {
  const [pathData, setPathData] = useState<IPath[]>([]);
  const [avgTimeChartRange, setAvgTimeChartRange] = useState<[number, number]>([0, 0]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activePath, setActivePath] = useState<IPath>();

  useEffect(() => {
    (async () => {
      processor.responses = answers;
      await processor.call();

      setIsLoading(false);

      setPathData(processor.paths);
      setAvgTimeChartRange(processor.avgTimeChartRange);
    })();
  }, [answers]);

  const pathClicks = (pathIndex: number, responses: IPath['responses']) => {
    return responses.reduce<PrototypeClick[]>((acc, response) => {
      if (response) {
        return acc.concat(response.clicks.filter((click) => click.response_path_index === pathIndex));
      }

      return acc;
    }, []);
  };

  const activePathClicks = () => {
    if (activePath) {
      return activePath.responses.reduce<PrototypeClick[]>((acc, response) => {
        if (response) {
          return acc.concat(...response.clicks);
        }

        return acc;
      }, []);
    }
    return [];
  };

  if (isLoading) {
    return (
      <div className='flex items-center justify-center w-full py-6'>
        <Spinner className='w-10 h-10' />
      </div>
    );
  }

  return (
    <>
      {pathData.map((path, index) => (
        <section
          key={path.path.join(',')}
          className='hover:bg-indigo-50 hover:border-indigo-600 px-4 py-3 mt-3 border border-gray-200 rounded cursor-pointer'
          role='button'
          tabIndex={0}
          onClick={() => setActivePath(path)}
        >
          <header className='flex items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <Text bold>Path {index + 1}</Text>

              <Tooltip content={`${path.successful ? 'Successful' : 'Failed'} path`}>
                <div aria-label={`${path.successful ? 'Successful' : 'Failed'} path`}>
                  {path.successful ? (
                    <CheckMark2SVG className='w-4 h-4 text-green-500' />
                  ) : (
                    <CloseSVG className='w-3 h-3 text-red-500' />
                  )}
                </div>
              </Tooltip>

              {path.shortestPath && (
                <Pill color='blue' className='uppercase'>
                  Shortest path
                </Pill>
              )}
            </div>

            <div className='flex items-center space-x-6'>
              <Text className='text-sm'>
                {path.count} {pluralize('response', path.count)} ({(path.countAsPercentage * 100).toFixed(1)}%)
              </Text>

              <div className='flex items-center space-x-2'>
                <div className='flex items-center space-x-1'>
                  <svg className='w-4 h-4'>
                    <use href='#prototypeTaskMetrics-misClickRate' />
                  </svg>

                  <Text className='text-sm'>{path.avgMissClickRate.toFixed(1)}%</Text>
                </div>

                <div className='w-14'>
                  <ProgressBar progress={path.avgMissClickRate} size='sm' />
                </div>
              </div>

              <div className='flex items-center space-x-2'>
                <div className='flex items-center space-x-1'>
                  <svg className='w-4 h-4'>
                    <use href='#prototypeTaskMetrics-avgDuration' />
                  </svg>

                  <Text className='text-sm'>{(path.avgTime / 1000).toFixed(1)}s</Text>
                </div>

                <div className='w-14'>
                  <ProgressBar progress={(path.avgTime / avgTimeChartRange[1]) * 100} size='sm' />
                </div>
              </div>
            </div>
          </header>

          <div className='flex items-center max-w-full mt-4 space-x-4 overflow-x-auto'>
            {path.path.map((step, index) => (
              <div key={index}>
                <div className='relative flex items-center justify-center w-48 h-32 text-xs bg-gray-200 rounded'>
                  <ScreenThumbnail
                    prototypeTestId={prototypeTestId}
                    className='absolute flex items-center justify-center w-full h-full'
                    clicks={pathClicks(index, path.responses)}
                    screenExternalElementId={step}
                    thumbnailSize={400}
                  />
                </div>

                <Text className='mt-1 text-xs text-gray-500'>Frame {index + 1}</Text>
              </div>
            ))}
          </div>
        </section>
      ))}

      {prototypeTestId && activePath && (
        <Modal onClose={() => setActivePath(undefined)} size='xl'>
          <ScreensPreview prototypeTestId={prototypeTestId} path={activePath.path} clicks={activePathClicks()} />
        </Modal>
      )}
    </>
  );
};
