import { EMAIL_TEMPLATE_PURPOSES } from 'components/TemplatesApp/consts';

export const sortTemplates = <T extends AbstractTemplate>(templates: T[], sortBy: string) => {
  if (!templates.length) return [];

  const presortedTemplates = [...templates];

  switch (sortBy) {
    case 'updated_at':
      return presortedTemplates.sort((a, b) => +b[sortBy] - +a[sortBy]);
    case 'owner':
      return presortedTemplates.sort((a, b) =>
        a.creator.name.toLowerCase().localeCompare(b.creator.name.toLowerCase())
      );
    case 'title':
      return presortedTemplates.sort((a, b) => {
        if (!a[sortBy] && b[sortBy]) return 1;
        if (a[sortBy] && !b[sortBy]) return -1;
        if (!a[sortBy] && !b[sortBy]) return 0;
        return a[sortBy].toString().localeCompare(b[sortBy].toString());
      });
    case 'event':
      return presortedTemplates.sort((a, b) => {
        if (!EMAIL_TEMPLATE_PURPOSES[a[sortBy]] && EMAIL_TEMPLATE_PURPOSES[b[sortBy]]) return 1;
        if (EMAIL_TEMPLATE_PURPOSES[a[sortBy]] && !EMAIL_TEMPLATE_PURPOSES[b[sortBy]]) return -1;
        if (!EMAIL_TEMPLATE_PURPOSES[a[sortBy]] && !EMAIL_TEMPLATE_PURPOSES[b[sortBy]]) return 0;

        return EMAIL_TEMPLATE_PURPOSES[a[sortBy]]
          .toLowerCase()
          .localeCompare(EMAIL_TEMPLATE_PURPOSES[b[sortBy]].toLowerCase());
      });
    default:
      return presortedTemplates;
  }
};
