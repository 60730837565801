import * as React from 'react';
import { useContext, useState } from 'react';

import { CandidateProfile } from '@components/CandidateProfile';
import { without } from '@components/utils';
import configStore from '@stores/config';
import { api } from 'api/reduxApi';

interface Props {
  candidate: Candidate;
  onUpdate?: (candidate: Candidate) => void;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  createAttr?: (attr: Partial<Attr_>) => Promise<Attr_ | undefined>;
}
export const ProfileAttributes: React.FC<Props> = ({ candidate, onUpdate, setLoading, createAttr }) => {
  const {
    state: {
      config: { interviewProfileAttrs: initialInterviewProfileAttrs }
    }
  } = useContext<ConfigStore>(configStore);

  const [updateConfig] = api.useUpdateUserConfigMutation();

  const [interviewProfileAttrs, setInterviewProfileAttrs] = useState(initialInterviewProfileAttrs);

  const onChangeInterviewProfileAttrs = async (newAttrs: string[]) => {
    await updateConfig({ interview_profile_attrs: newAttrs });
    setInterviewProfileAttrs(newAttrs);
  };

  return (
    <div>
      <div className='w-full mb-4'>
        <CandidateProfile
          customer={candidate}
          createAttr={createAttr}
          onClickDeleteAttr={(attr) => {
            onChangeInterviewProfileAttrs(without(interviewProfileAttrs as any, attr));
          }}
          onUpdate={onUpdate}
          setLoading={setLoading}
          style='slideOut'
        />
      </div>
    </div>
  );
};
