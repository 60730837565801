import * as React from 'react';

export const dashboard: React.FC = () => (
  <svg
    className='w-4 h-4 mr-3'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.1875 7.82495L4.25675 6.75512C4.36307 6.64889 4.50721 6.58923 4.6575 6.58923C4.80779 6.58923 4.95193 6.64889 5.05825 6.75512L5.725 7.42362C5.83132 7.52984 5.97546 7.58951 6.12575 7.58951C6.27604 7.58951 6.42018 7.52984 6.5265 7.42362L8.11667 5.83637C8.22298 5.73014 8.36713 5.67048 8.51742 5.67048C8.66771 5.67048 8.81185 5.73014 8.91817 5.83637L10.1379 7.05612C10.2442 7.16234 10.3884 7.22201 10.5387 7.22201C10.689 7.22201 10.8331 7.16234 10.9394 7.05612L12.8125 5.19995'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M7.888 1.4375V2.27633'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M1.4375 2.27637H14.5625V11.4639H1.4375V2.27637Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path d='M8 11.4639V13.2139' stroke='white' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round'></path>
    <path
      d='M6.20392 14.5608L8 13.2139L9.79434 14.5596'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
