import { api } from '@api/reduxApi';

interface Checkout {
  payment_method_id?: string;
  plan_id?: number;
  study_id: number;
  incentive: number;
  participant_limit: number;
  amount: number;
}
const billingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSetupIntent: builder.query<{ client_secret: string }, void>({
      query: () => '/billing/setup_intent',
      transformResponse: (response: any) => response.data
    })
  })
});

export const { useGetSetupIntentQuery } = billingApi;
