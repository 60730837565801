import React, { VFC } from 'react';
import { useParams } from 'react-router-dom';

import { Spinner } from '@components/common';
import { PageTitle } from '@components/common/helmets';

import { useGetSearchQuery } from '../api';
import { Header } from '../components/Header';
import { Artifacts } from '../components/Artifacts';

export const Show: VFC = () => {
  const { id } = useParams<{ id: string }>();

  const { data: search, isLoading } = useGetSearchQuery({ id }, { skip: !id });

  return (
    <section>
      <PageTitle>{`AI Search: ${search?.query}`}</PageTitle>
      <Header />
      <section className='px-12'>
        <div className='mt-6'>{isLoading ? <Spinner className='center w-5 mx-auto my-0' /> : <Artifacts />}</div>
      </section>
    </section>
  );
};
