export const QUESTION_TYPES = [
  'short_text',
  'long_text',
  'number',
  'multiple_choice',
  'single_choice',
  'yes_no',
  'email',
  'website',
  'number_range',
  'location',
  'date',
  'prototype_test'
];

export const isDisplayable = (fieldType: ScreenerFieldType) => QUESTION_TYPES.includes(fieldType);
