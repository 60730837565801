import React, { SVGProps } from 'react';

export const ZoomGlassSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_463_1090)'>
      <path
        d='M14.8594 0C9.81924 0 5.71876 4.10048 5.71876 9.14062C5.71876 11.4082 6.54901 13.4852 7.92113 15.0845L6.33183 16.6738C5.5479 16.3004 4.5796 16.4373 3.93141 17.0855L0.616834 20.4C-0.205635 21.2225 -0.205588 22.5607 0.616834 23.3832C1.43926 24.2056 2.77749 24.2056 3.59996 23.3832L6.91454 20.0686C7.56268 19.4205 7.69965 18.4521 7.32619 17.6682L8.91549 16.0789C10.5148 17.451 12.5918 18.2812 14.8594 18.2812C19.8995 18.2812 24 14.1808 24 9.14062C24 4.10048 19.8995 0 14.8594 0V0ZM5.92013 19.0742L2.60555 22.3888C2.33138 22.6629 1.88532 22.6629 1.61119 22.3888C1.33702 22.1146 1.33702 21.6686 1.61119 21.3944L4.92577 18.0798C5.19938 17.8062 5.64418 17.8058 5.91844 18.0783C5.91901 18.0788 5.91943 18.0795 5.91994 18.08C5.92051 18.0806 5.92107 18.081 5.92163 18.0815C6.19421 18.3558 6.19379 18.8006 5.92013 19.0742ZM14.8594 16.875C10.5946 16.875 7.12501 13.4054 7.12501 9.14062C7.12501 4.87589 10.5946 1.40625 14.8594 1.40625C19.1241 1.40625 22.5938 4.87589 22.5938 9.14062C22.5938 13.4054 19.1241 16.875 14.8594 16.875Z'
        fill='currentColor'
      />
      <path
        d='M17.4127 8.74102C18.0085 8.11088 18.375 7.26173 18.375 6.32812C18.375 4.38961 16.7979 2.8125 14.8594 2.8125C12.9209 2.8125 11.3437 4.38961 11.3437 6.32812C11.3437 7.26173 11.7103 8.11088 12.3061 8.74102C10.6471 9.38925 9.46875 11.0048 9.46875 12.8906V13.3594C9.46875 13.7477 9.78356 14.0625 10.1719 14.0625H19.5469C19.9352 14.0625 20.25 13.7477 20.25 13.3594V12.8906C20.25 11.0048 19.0717 9.38925 17.4127 8.74102ZM14.8594 4.21875C16.0225 4.21875 16.9687 5.16502 16.9687 6.32812C16.9687 7.49123 16.0225 8.4375 14.8594 8.4375C13.6963 8.4375 12.75 7.49123 12.75 6.32812C12.75 5.16502 13.6963 4.21875 14.8594 4.21875ZM10.8839 12.6562C11.004 11.0853 12.3207 9.84375 13.9219 9.84375H15.7969C17.3981 9.84375 18.7147 11.0853 18.8348 12.6562H10.8839Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_463_1090'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
