// yoinked: https://codesandbox.io/s/strange-feistel-kwsed?file=/grid-with-sticky-cells.jsx
import * as React from 'react';
import { CSSProperties, forwardRef, useMemo } from 'react';
import { VariableSizeGrid as Grid, VariableSizeGridProps as GridProps } from 'react-window';

const getCellIndicies = (child) => ({ row: child.props.rowIndex, column: child.props.columnIndex });

const getShownIndicies = (children) => {
  let minRow = Infinity;
  let maxRow = -Infinity;
  let minColumn = Infinity;
  let maxColumn = -Infinity;

  for (const child of children) {
    const { row, column } = getCellIndicies(child);
    minRow = Math.min(minRow, row);
    maxRow = Math.max(maxRow, row);
    minColumn = Math.min(minColumn, column);
    maxColumn = Math.max(maxColumn, column);
  }

  return {
    from: {
      row: minRow,
      column: minColumn
    },
    to: {
      row: maxRow,
      column: maxColumn
    }
  };
};

const useInnerElementType = (Cell, columnWidth, rowHeight) =>
  useMemo(
    () =>
      forwardRef<HTMLDivElement, { children: React.ReactNode[]; style: CSSProperties }>((props, ref) => {
        function sumRowsHeights(index) {
          let sum = 0;

          while (index > 1) {
            sum += rowHeight(index - 1);
            index -= 1;
          }

          return sum;
        }

        function sumColumnWidths(index) {
          let sum = 0;

          while (index > 1) {
            sum += columnWidth(index - 1);
            index -= 1;
          }

          return sum;
        }

        const shownIndecies = getShownIndicies(props.children);

        const children = props.children.map((child) => {
          const { column, row } = getCellIndicies(child);

          // do not show non-sticky cell
          if (column === 0 || row === 0) {
            return null;
          }

          return child;
        });

        children.push(
          React.createElement(Cell, {
            key: '0:0',
            rowIndex: 0,
            columnIndex: 0,
            style: {
              display: 'inline-flex',
              width: columnWidth(0),
              height: rowHeight(0),
              position: 'sticky',
              top: 0,
              left: 0,
              zIndex: 4,
              overflow: ' visible'
            }
          })
        );

        const shownColumnsCount = shownIndecies.to.column - shownIndecies.from.column;

        for (let i = 1; i <= shownColumnsCount; i += 1) {
          const columnIndex = i + shownIndecies.from.column;
          const rowIndex = 0;
          const width = columnWidth(columnIndex);
          const height = rowHeight(rowIndex);

          const marginLeft = i === 1 ? sumColumnWidths(columnIndex) : undefined;

          children.push(
            React.createElement(Cell, {
              key: `${rowIndex}:${columnIndex}`,
              rowIndex,
              columnIndex,
              style: {
                marginLeft,
                display: 'inline-flex',
                width,
                height,
                overflow: 'visible',
                position: 'sticky',
                top: 0,
                zIndex: 3
              }
            })
          );
        }

        const shownRowsCount = shownIndecies.to.row - shownIndecies.from.row;

        for (let i = 1; i <= shownRowsCount; i += 1) {
          const columnIndex = 0;
          const rowIndex = i + shownIndecies.from.row;
          const width = columnWidth(columnIndex);
          const height = rowHeight(rowIndex);

          const marginTop = i === 1 ? sumRowsHeights(rowIndex) : undefined;

          children.push(
            React.createElement(Cell, {
              key: `${rowIndex}:${columnIndex}`,
              rowIndex,
              columnIndex,
              style: {
                marginTop,
                width,
                height,
                position: 'sticky',
                left: 0,
                zIndex: 2
              }
            })
          );
        }

        return (
          <div ref={ref} {...props}>
            {children}
          </div>
        );
      }),
    [Cell, columnWidth, rowHeight]
  );

export const GridWithStickyCells = React.forwardRef<HTMLDivElement, GridProps>((props, ref) => (
  <Grid
    outerRef={ref}
    {...props}
    innerElementType={useInnerElementType(props.children, props.columnWidth, props.rowHeight)}
  />
));
