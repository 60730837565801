import React, { SVGProps } from 'react';

export const SplitPageSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_179_11289)'>
      <path
        d='M13.25 1.87326H2.75C2.26675 1.87326 1.875 2.26501 1.875 2.74826V13.2483C1.875 13.7315 2.26675 14.1233 2.75 14.1233H13.25C13.7332 14.1233 14.125 13.7315 14.125 13.2483V2.74826C14.125 2.26501 13.7332 1.87326 13.25 1.87326Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.625 1.87326V14.1233'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_179_11289'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
