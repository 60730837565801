import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useToaster } from '@stores/toaster';
import { api } from 'api/reduxApi';
import { noop } from 'components/utils';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';

export type UseCustomerImport = {
  id: number;
  loading: boolean;
  saving: boolean;
  model: CustomerImport | null;
  setModel: (model: CustomerImport) => void;
  save: (value: CustomerImport) => Promise<boolean>;
};

export const useCustomerImport = (): UseCustomerImport => {
  const showToast = useToaster();

  const params = useParams() as { id: string };
  const id = parseInt(params.id);

  const { data: customerImport, isLoading, isError } = api.useGetCustomerImportQuery(id);
  const [updateCustomerImport, { isLoading: isLoadingUpdate }] = api.useUpdateCustomerImportMutation();

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  const setModel = (model: CustomerImport) => {
    const patch = api.util.updateQueryData('getCustomerImport', id, (draft) => {
      Object.assign(draft, model);
    });

    dispatch(patch);
  };

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'An error occurred!',
        text: 'Something went wrong. Please try again later.',
        icon: 'error'
      });
    }
  }, [isError]);

  return {
    id,
    loading: isLoading,
    saving: isLoadingUpdate,
    model: customerImport ?? null,
    setModel,
    save: async (value: CustomerImport) => {
      if (!customerImport) return false;

      setModel(value);

      try {
        await updateCustomerImport(value).unwrap();

        return true;
      } catch (_) {
        showToast({
          heading: 'An error occurred!',
          text: 'Something went wrong. Please try again later.',
          icon: 'error'
        });

        return false;
      }
    }
  };
};
