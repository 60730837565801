import * as React from 'react';

import { Basic, Grid } from '@components/shared/Skeleton';

export const RepositorySkeletonBody: React.FC = () => {
  return (
    <>
      <Basic width={140} height={18} mb='4' />
      <Grid cardsNumber={3} monitor={5} desktop={3} tablet={2} mobile={2} tiny={1} height={384} />
    </>
  );
};
