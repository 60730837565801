import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { api } from '@api/reduxApi';
import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { useWindowFocus } from './useWindowFocus';

interface ButtonProps extends HTMLAttributes<HTMLAnchorElement> {
  auth_path?: string;
  callback?: () => void;
}

interface Hook {
  getButtonProps: (props: ButtonProps) => HTMLAttributes<HTMLAnchorElement>;
  isOpen: boolean;
  isLoading?: boolean;
  isConnected?: boolean;
  isInvalid?: boolean;
}

const POPUP_KEY = 'auth_popup';
const POP_UP_WIDTH = 463;
const POP_UP_HEIGHT = 558;

function openPopup() {
  const top = window.outerHeight / 2 + window.screenY - POP_UP_HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - POP_UP_WIDTH / 2;
  const config = Object.entries({ width: POP_UP_WIDTH, height: POP_UP_HEIGHT, top, left })
    .map(([k, v]) => `${k}=${v}`)
    .join(',');

  window.open('about:blank', POPUP_KEY, config);
}

export const useAuthProvider = (defaultProvider?: IntegrationProvider): Hook => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const focused = useWindowFocus();
  const mountRef = useRef<boolean>(false);
  const callbackRef = useRef<undefined | (() => void)>();

  const { data, isLoading } = api.useGetConnectedAccountsQuery();

  const dispatch = useDispatch();

  const connectedAccount = data?.find((account) => account.provider === defaultProvider);

  function handleOnClick(callback: ButtonProps['callback']) {
    if (callback) callbackRef.current = callback;
    openPopup();
    setIsOpen(true);
  }

  useEffect(() => {
    if (focused && mountRef.current && isOpen) {
      callbackRef.current?.();
      setIsOpen(false);
      dispatch(api.util.invalidateTags(['ConnectedAccounts']));
    }

    mountRef.current = true;
  }, [focused]);

  return {
    isOpen,
    isLoading,
    isConnected: !!connectedAccount,
    isInvalid: connectedAccount?.is_invalid,
    getButtonProps: ({ auth_path, callback, onClick, ...rest }) => ({
      'data-method': 'post',
      target: POPUP_KEY,
      href: auth_path || `/users/auth/${defaultProvider}`,
      onClick: composeEventHandlers(() => handleOnClick(callback), onClick),
      ...rest
    })
  };
};
