import React, { FC } from 'react';
import { DropdownProps } from '@components/shared/TableFilters/types';
import { useAccount } from '@hooks/useAccount';
import { Choose } from '@components/shared/TableFilters/components/dropdowns';

type Props<T = AbstractTemplate> = DropdownProps<T, any>;

export const SearchTeam: FC<Props> = (props) => {
  const {
    account: { team }
  } = useAccount();
  const items = team.map(({ name }) => ({
    label: name,
    value: name
  }));

  return <Choose {...props} options={items} />;
};
