import { CopyURLButton } from 'components/shared/CopyURLButton';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetWhoamiQuery } from '@components/stores/whoami';

import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { Checkbox, FormGroup, Input, Label, Textarea } from '@components/fields';
import { compact, getHost, pick } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../../toasts';
import { BrandLogo } from './BrandLogo';
import { PreviewSignupPage } from './PreviewSignupPage/PreviewSignupPage';

export const LANDING_PAGE_KEYS = [
  'title',
  'description',
  'requirements',
  'personal_note',
  'button_text',
  'booking_page_title',
  'slug',
  'sidebar_title',
  'sidebar_subtitle',
  'sidebar_items'
];

export const SIDEBAR_ITEM_LABELS = ['Study type', 'Duration', 'Incentive'];

export const getDefaultValues = (s: Partial<LandingPage>): Partial<LandingPage> =>
  pick<Partial<LandingPage>, any>(s, ...LANDING_PAGE_KEYS);

export const checkAnyValuesChanged = (s1: Partial<LandingPage>, s2: Partial<LandingPage>): boolean =>
  LANDING_PAGE_KEYS.some((key) => s1[key] !== s2[key]);

export const EditSignupPage: React.FC<PageProps> = ({ landing_page, onSave, study, setSaving }) => {
  const [edit, setEdit] = useState(false);
  const canUpdate = usePermission('updateStudy')();

  const showToast = useToaster();

  const { register, handleSubmit, errors, reset } = useForm<Partial<LandingPage>>({
    defaultValues: getDefaultValues(landing_page as any)
  });

  const [updateStudyLandingPage] = api.useUpdateStudyLandingPageMutation();

  const onSubmit = async (data: Partial<LandingPage>) => {
    const dataNotChanged = !checkAnyValuesChanged(data, landing_page as any) || Object.keys(data).length === 0;

    if (dataNotChanged) {
      setEdit(false);
      return;
    }

    setSaving(true);

    try {
      const resp = await updateStudyLandingPage({ study_id: study.id, ...data }).unwrap();

      setEdit(false);
      onSave({ id: study.id, slug: resp.slug }); // is there another way to refresh the study?
      reset(getDefaultValues(resp)); // why this? To set new values as default
      // https://react-hook-form.com/kr/v6/api/
      // Important: defaultValues is cached at the first render within the custom hook.
      // If you want to reset the defaultValues, you should use the reset api.
    } catch (_) {
      showToast(toasts.failedUpdate());
    }

    setSaving(false);
  };

  const { data: whoami } = useGetWhoamiQuery();
  const host = whoami?.account_public_domain.base_url;

  const publicUrl = host + '/' + study.public_path;

  return (
    <div
      className={compact([
        'w-full bg-white max-w-4xl p-8 relative mx-auto border border-gray-200',
        edit && 'rounded-md',
        !edit && 'shadow-lg'
      ]).join(' ')}
    >
      <div className='flex pb-8 space-x-3'>
        <div className='flex-grow'>
          <BrandLogo study={study} className='h-8' edit={edit} />
        </div>

        {!edit && (
          <>
            {study.state !== 'pending' && (
              <CopyURLButton disabled={!study.slug} icon='link' className='mr-2' text={publicUrl} />
            )}
            <Button iconSuffix='externalLink' disabled={!study.slug} href={publicUrl} target='_blank'>
              View
            </Button>
            <Button iconSuffix='pencil' onClick={() => setEdit(true)} disabled={!canUpdate}>
              Edit
            </Button>
          </>
        )}

        {edit && (
          <>
            <Button onClick={() => setEdit(false)}>Cancel</Button>
            <Button type='submit' primary onClick={handleSubmit(onSubmit)}>
              Done
            </Button>
          </>
        )}
      </div>

      {!edit && <PreviewSignupPage landing_page={landing_page as any} study={study} />}

      {edit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h4 className='mb-4 font-bold text-gray-700'>Page</h4>
          <FormGroup>
            <Label>Share URL</Label>
            <div className='flex'>
              <div className='flex flex-col justify-center mr-1 text-gray-700'>
                {host}/{study.public_path?.split('/')[0]}/
              </div>
              <div className='flex-grow'>
                <Input name='slug' placeholder='' register={register} />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <Label labelFor='title'>Headline</Label>
            <Input name='title' id='title' errors={errors} register={register} />
          </FormGroup>
          <FormGroup>
            <Label>Description</Label>
            <Textarea name='description' id='description' errors={errors} register={register} minRows={1} />
          </FormGroup>

          <FormGroup>
            <Label>Button text</Label>
            <Input name='button_text' placeholder='' register={register} />
          </FormGroup>

          <FormGroup>
            <Label className='mb-4'>{landing_page?.sidebar_title}</Label>
            {landing_page?._default_sidebar_items.map(
              (item, i) =>
                !(!study.has_incentive && i === 2) && (
                  <Checkbox
                    key={item}
                    className='mb-3 text-gray-700'
                    name='sidebar_items'
                    value={i.toString()}
                    label={`${SIDEBAR_ITEM_LABELS[i]} (${item})`}
                    register={register}
                  />
                )
            )}
          </FormGroup>

          <FormGroup>
            <Label className='mb-4'>{landing_page?.sidebar_subtitle}</Label>
            {landing_page?._default_requirements.map((item, i) => (
              <Checkbox
                key={item}
                className='mb-3 text-gray-700'
                name='requirements'
                value={item}
                label={item}
                register={register}
              />
            ))}
          </FormGroup>

          <FormGroup>
            <Label>Personal Note</Label>
            <Textarea name='personal_note' placeholder='' register={register} />
          </FormGroup>
        </form>
      )}
    </div>
  );
};
