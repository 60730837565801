import { ArtifactHit } from '@components/RepositoryApp/types';

import { configureStore } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({ baseUrl: '/api/v1/public/' });

export const api = createApi({
  reducerPath: 'publicRepoSessionApi',
  baseQuery,
  endpoints: (builder) => ({
    getRepoSession: builder.query<PublicRepoSession, { uuid: string }>({
      query: ({ uuid }) => `repo/sessions/${uuid}`,
      transformResponse: (resp: any) => resp.data.attributes
    }),
    getClip: builder.query<PublicClip, { token: string }>({
      query: ({ token }) => `repo/clips/${token}`,
      transformResponse: (resp: any) => resp.data.attributes
    }),
    getHighlight: builder.query<PublicHighlight, { uuid: string }>({
      query: ({ uuid }) => `repo/highlights/${uuid}`,
      transformResponse: (resp: any) => resp.data.attributes
    }),
    getInsight: builder.query<PublicInsight, { slug: string }>({
      query: ({ slug }) => `stories/${slug}`,
      transformResponse: (resp: any) => resp.data.attributes
    }),
    getHighlightReel: builder.query<PublicHighlightReel, { token: string }>({
      query: ({ token }) => `repo/highlight_reels/${token}`,
      transformResponse: (resp: any) => resp.data.attributes
    }),
    getArtifacts: builder.query<ArtifactHit[], { accountId: number; objectIds: string[] }>({
      query: ({ accountId, objectIds }) => `/artifacts?account_id=${accountId}&object_ids=${objectIds.join(',')}`,
      transformResponse: (resp: { data: ArtifactHit[] }) => resp.data
    })
  })
});

export const store = configureStore({
  reducer: { publicRepoSessionApi: api.reducer },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), api.middleware]
});
