import { useTransitionStudy } from 'components/StudiesApp/hooks/useTransitionStudy';
import * as React from 'react';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../../toasts';
import { StudyActionModal, StudyActionModalProps } from '../StudyActionModal/StudyActionModal';

export const ReactivateStudyModal: React.FC<StudyActionModalProps> = (props) => {
  const showToast = useToaster();
  const { transition, isLoading } = useTransitionStudy({
    id: props.id,
    transition: 'reactivate',
    onSuccess: () => {
      showToast(toasts.successReactivate());
      props.onAfterSubmit?.();
      props.onClose();
    },
    onError: () => {
      showToast(toasts.failedReactivate());
      props.onClose();
    }
  });

  return (
    <StudyActionModal
      {...props}
      heading='Reactivate study'
      loading={isLoading}
      primaryButtonProps={{
        primary: true,
        children: 'Reactivate'
      }}
      onSubmit={async () => {
        transition();
      }}
    >
      Are you sure you want to reactivate study “{props.title}“?
    </StudyActionModal>
  );
};
