// const BASE_URL = `${process.env.API_HOST}/api/v1`;
const BASE_URL = `/api/v1`;
const TOKEN_KEY = 'token';
export function getToken(): string | undefined {
  const tokenEl = document.getElementById('gq-api-token');
  if (tokenEl && tokenEl instanceof HTMLMetaElement) {
    return tokenEl.content;
  }
}

function buildHeaders(isJson = true): Headers {
  const headers = new Headers();
  if (isJson) headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json');
  const token = getToken();

  if (token) {
    headers.append('Authorization', token);
  }
  return headers;
}

class Api {
  get = async (path: string, urlParams: any = null): Promise<Response> => {
    const options = {
      method: 'GET',
      headers: buildHeaders()
    };
    let url = BASE_URL + path;
    if (urlParams) {
      url = `${url}?${urlParams}`;
    }

    const request = new Request(url, options);
    const response = await fetch(request);
    // Intercept response headers here.
    return response;
  };

  post = async (path: string, model: any): Promise<Response> => {
    const isJson = !(model instanceof FormData);
    const options = {
      method: 'POST',
      headers: buildHeaders(isJson),
      body: isJson ? JSON.stringify(model) : model
    };
    const request = new Request(BASE_URL + path, options);
    const response = await fetch(request);
    return response;
  };

  put = async (path: string, model: any): Promise<Response> => {
    const isJson = !(model instanceof FormData);
    const options = {
      method: 'PUT',
      headers: buildHeaders(isJson),
      body: isJson ? JSON.stringify(model) : model
    };
    const request = new Request(BASE_URL + path, options);
    const response = await fetch(request);
    return response;
  };

  delete = async (path: string): Promise<Response> => {
    const options = {
      method: 'DELETE',
      headers: buildHeaders()
    };
    const request = new Request(BASE_URL + path, options);
    const response = await fetch(request);
    return response;
  };
}
const client = new Api();

export default client;
