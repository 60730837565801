import * as svgs from '@components/shared/TableFilters/components/svgs';
import {
  buildCollectionAttributes,
  CollectionAttributes
} from '@components/shared/CollectionAttributes/buildCollectionAttributes';
import { compact } from '@components/utils';

export const buildMessagesAttrs = (showCancelationReason: boolean): CollectionAttributes<StudyStatsEvents> => {
  return buildCollectionAttributes<StudyStatsEvents>(
    compact([
      {
        id: 'name',
        name: 'Name',
        type: 'free_text',
        Icon: svgs.text,
        filterable: false
      },
      {
        id: 'email',
        name: 'Email',
        type: 'free_text',
        Icon: svgs.text
      },
      {
        id: 'sender_name',
        name: 'Sender name',
        type: 'free_text',
        Icon: svgs.text,
        filterable: false
      },
      {
        id: 'sender',
        name: 'Sender email',
        type: 'free_text',
        Icon: svgs.text
      },
      {
        id: 'state',
        name: 'Event',
        type: 'text',
        Icon: svgs.multipleChoice,
        filter: {
          values: ['sent', 'opened', 'clicked', 'canceled', 'pending']
        }
      },
      showCancelationReason && {
        id: 'cancelation_reason',
        name: 'Cancelation reason',
        type: 'free_text',
        Icon: svgs.text,
        filterable: false,
        sortable: false
      },
      {
        id: 'state_timestamp',
        name: 'Event date & time',
        type: 'date',
        Icon: svgs.date,
        filterable: false,
        sortable: false
      }
    ])
  );
};
