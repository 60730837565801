export const move = <E extends unknown>(array: E[], from: number, to: number) => {
  if (from === to) return array;

  const newArray = [...array];
  const target = newArray[from];
  const inc = to < from ? -1 : 1;

  for (let i = from; i !== to; i += inc) {
    newArray[i] = newArray[i + inc];
  }

  newArray[to] = target;

  return newArray;
};
