import classNames from 'classnames';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import tinytime from 'tinytime';

import { Text } from '@components/common';
import { compact, uniqueId } from '@components/utils';

import { AvailabilityBlock } from './AvailabilityBlock';
import { Event } from './Event';
import {
  getAllTimesBetween,
  getDateFromString,
  getHourMin,
  getRungStyles,
  isFreeAt,
  keyFromSlot,
  normalizeSlots,
  rawNylasEventToSlotInstance,
  slotFromKey
} from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { UseDragToResizeHookState } from '../../NylasCalendar/useDragToResize';
import { useCalendarStates } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/store/useCalendarStates';
import { conflictChecker } from '../../NylasCalendar/utils/conflictChecker';
import mergeRefs from 'react-merge-refs';
import { track } from '@components/tracking';
import { useGQCalendarContext } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/hooks/useCalendarContext';
import { SCHEDULING_INCREMENT } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';

const dateTemplate = tinytime('{MM} {DD}');

interface Props {
  disabled?: boolean;
  frozen?: boolean;
  events: SlotInstance[];
  calendars: UserAndCalendar[];
  date: string;
  startHour: number;
  endHour: number;
  studyDuration: number;
  timezone: Timezone;
  onCreateEvent: (slot: Slot, id: string) => void;
  onUpdateEvent: (slot: SlotInstance, debounce?: boolean) => void;
  onDeleteEvent: (id: string) => void;
  schedulingIncrement: number;
  showConflicts: boolean;
  userIdsWithHiddenEvents: number[];
  weekIdx: number;
}

export const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const AVAILABILITY_BLOCK_WIDTH = 32;

export const DayColumn: React.FC<Props> = ({
  disabled,
  frozen,
  calendars,
  events,
  date,
  startHour,
  endHour,
  studyDuration,
  timezone,
  onCreateEvent,
  onUpdateEvent,
  onDeleteEvent,
  schedulingIncrement,
  showConflicts,
  userIdsWithHiddenEvents,
  weekIdx
}) => {
  const { studyUserColors, calSettings } = useGQCalendarContext();
  const ref = useRef<any>(null);

  const calendarStates = useCalendarStates(calendars, weekIdx);

  const rungs = useMemo(
    () => getAllTimesBetween(startHour, endHour, schedulingIncrement),
    [startHour, endHour, schedulingIncrement]
  );

  const canClick = !frozen && !disabled;

  const day = getDateFromString(date).getDay() as Slot['day'];

  const rungStyles: RungStyles = getRungStyles(schedulingIncrement);

  const [dragResizeState, setDragResizeState] = useState<UseDragToResizeHookState>(() => ({
    dragstarty: null,
    mousey: null,
    handle: null
  }));

  const handleClickRung: React.MouseEventHandler = (e) => {
    const dataset = (e.currentTarget as HTMLDivElement).dataset;

    if (dataset && dataset.rbdDroppableId) {
      const slot = slotFromKey(dataset.rbdDroppableId);

      const availabilityBlock = getAvailabilityBlocksAt(slot.time)[0];

      const { hour, min } = getHourMin(slot.time);
      const isOverlapped = !isFreeAt(events, hour, min);

      if ((availabilityBlock && availabilityBlock.single == undefined) || isOverlapped) {
        return;
      }

      const id = uniqueId();

      setDragResizeState({
        handle: `${id}-bottom`,
        mousey: e.pageY,
        dragstarty: e.pageY
      });

      track('availability_slot_created', { place: 'calendar_body_click' });

      onCreateEvent(slot, id);
    }
  };

  const normalizedSlots = useMemo(
    () => normalizeSlots(events, startHour, endHour, schedulingIncrement),
    [events, startHour, endHour, schedulingIncrement]
  );

  const getAvailabilityBlocksAt = useCallback(
    (time: string) => {
      return normalizedSlots.filter((e) => e.time === time);
    },
    [normalizedSlots]
  );

  const availabilityBlockTimes = useMemo(() => normalizedSlots.map((e) => e.time), [events]);

  const getEventsAsSlots = ({ events: rawNylasEvents, userId }) =>
    normalizeSlots<NylasSlotInstance>(
      compact((rawNylasEvents || []).map((e) => rawNylasEventToSlotInstance(userId, e))),
      startHour,
      endHour,
      SCHEDULING_INCREMENT
    ).filter(
      (e) =>
        !userIdsWithHiddenEvents.includes(e.user_id) && !calSettings?.hiddenEvents?.includes(e.busy ? 'busy' : 'free')
    );
  // returns the events array for the current day.
  const currentDayEvents = useMemo(
    () => calendarStates.map(getEventsAsSlots).reduce((acc, curr) => [...acc, ...curr], []),
    [calendarStates, startHour, endHour, timezone, userIdsWithHiddenEvents, calSettings?.hiddenEvents]
  );

  const currentDayEventsTimes = useMemo(() => currentDayEvents.map((e) => e.time), [currentDayEvents]);

  const hasEvents = currentDayEvents.some((e) => e.date === date);

  const eventsForUsersAt = useCallback(
    (time: string): { userId: number; events: NylasSlotInstance[] }[] => {
      return calendarStates.map(({ userId, events }, i) => {
        const eventsAsSlots = getEventsAsSlots({ userId, events }).filter((e) => e.date === date && e.time === time);

        return { userId, events: eventsAsSlots };
      });
    },
    [calendarStates, startHour, endHour, timezone, userIdsWithHiddenEvents, calSettings?.hiddenEvents]
  );

  const check = conflictChecker({
    calendarStates,
    availabilityBlocks: events,
    date,
    startHour,
    endHour,
  });

  return (
    <div className={classNames('xx-day-column flex-1', { 'bg-gray-50 text-gray-500': disabled })}>
      <div className='text-center'>
        <Text h='400' uppercase color={disabled ? 'gray-400' : 'gray-500'} className='block pt-5 mb-2 text-center'>
          {DAYS[day]}
        </Text>
        <div className='pb-5'>
          <Text medium color={disabled ? 'gray-400' : undefined} className='text-center'>
            {dateTemplate.render(getDateFromString(date))}
          </Text>
        </div>
      </div>

      <div
        className={classNames('border-gray-200 divide-gray-200 w-full', { 'border-t border-b divide-y': !disabled })}
      >
        {!disabled &&
          rungs.map((time: string) => {
            const availabilityBlocks = availabilityBlockTimes.includes(time) ? getAvailabilityBlocksAt(time) : [];

            const eventsForUsers = currentDayEventsTimes.includes(time) ? eventsForUsersAt(time) : [];

            return (
              <Droppable droppableId={keyFromSlot({ date, time })} key={`${time}${canClick}`}>
                {(provided) => (
                  <div
                    id={provided.droppableProps['data-rbd-droppable-id']}
                    ref={mergeRefs([ref, provided.innerRef])}
                    className={classNames('relative px-1', rungStyles.className, {
                      'cursor-pointer': canClick && !frozen
                    })}
                  >
                    <div
                      {...provided.droppableProps}
                      onMouseDown={canClick && !frozen ? handleClickRung : undefined}
                      className={classNames('absolute w-full', rungStyles.className)}
                    >
                      <div className='hidden'>{provided.placeholder}</div>
                    </div>
                    {availabilityBlocks.map((e, idx) => (
                      <AvailabilityBlock
                        date={date}
                        calendarStates={calendarStates}
                        dragResizeState={dragResizeState}
                        setDragResizeState={setDragResizeState}
                        showConflicts={showConflicts}
                        hasEvents={hasEvents}
                        key={e.id}
                        event={e}
                        disallowEdit={Boolean(frozen)}
                        studyDuration={studyDuration}
                        startHour={startHour}
                        endHour={endHour}
                        idx={idx}
                        onUpdate={(slot, debounce) => {
                          const newSlot: SlotInstance = { ...e.original, ...slot };
                          onUpdateEvent(newSlot, debounce);
                        }}
                        onClickDelete={() => onDeleteEvent(e.id)}
                        schedulingIncrement={schedulingIncrement}
                        rungTime={time}
                        conflictChecker={check}
                      />
                    ))}
                    <div
                      className='flex flex-1'
                      style={{
                        marginLeft: AVAILABILITY_BLOCK_WIDTH,
                        width: `calc(100% - ${AVAILABILITY_BLOCK_WIDTH}px)`
                      }}
                    >
                      {eventsForUsers.map(({ userId, events }, colorIndex) => {
                        if (events.length === 0) {
                          return null;
                        }
                        return (
                          <div key={userId} className='flex flex-1'>
                            {events.map((e) => {
                              const { hour, min } = getHourMin(e.time);
                              const maxHeight = (endHour - (hour + min / 60)) * getRungStyles(30).height * 2;

                              return (
                                <Event
                                  key={e.id}
                                  event={e}
                                  timezone={timezone}
                                  schedulingIncrement={schedulingIncrement}
                                  maxHeight={maxHeight}
                                  color={studyUserColors[userId]}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Droppable>
            );
          })}
      </div>
    </div>
  );
};
