import qs from 'qs';
import React, { HTMLAttributes, MouseEventHandler, ReactElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading, Text } from '@components/common';
import { Offset } from '@components/common/Popper';
import { MAX_CLIPS } from '@components/HighlightReelsApp/consts';
import { HighlightsLimitModal } from '@components/shared/HighlightsLimitModal';
import { useToaster } from '@stores/toaster';
import { Placement } from '@popperjs/core';

import { BulkAddDropdown } from '../../shared/BulkAddDropdown';
import { getIDFromObjectID, getTypeFromObjectID } from './utils';
import * as toasts from './toasts';
import { track } from '@components/tracking';

interface Props {
  disabled?: boolean;
  selectedArtifacts: string[];
  studyId?: number | null;
  medium?: boolean;
  renderButton?: (props: HTMLAttributes<HTMLButtonElement>) => ReactElement;
  popperPlacement?: Placement;
  popperOffset?: Offset;
  renderOnBodyRoot?: boolean;
  onlyContent?: boolean;
  hide?: () => void;
  trackCreateClick?: (count: number) => void;
  trackAddClick?: (count: number) => void;
}

export const BulkAddToHighlightReel = ({
  trackCreateClick,
  trackAddClick,
  studyId,
  selectedArtifacts = [],
  disabled: propDisabled,
  ...rest
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [clipsLimitModalOpen, setClipLimitsModalOpen] = useState(false);
  const [selectedHlr, setSelectedHlr] = useState<HighlightReel>();
  const anyClips = selectedArtifacts.some((objectID) => getTypeFromObjectID(objectID) === 'Clip');
  const allClips = selectedArtifacts.every((objectID) => getTypeFromObjectID(objectID) === 'Clip');
  const onlyClips = selectedArtifacts.filter((objectID) => getTypeFromObjectID(objectID) === 'Clip');

  const clipIds = useMemo(() => onlyClips.map(getIDFromObjectID), [onlyClips]);

  const onClick = (e, hideContent) => {
    if (allClips) return;
    hideContent();
    setModalOpen(true);
  };

  const navigate = useNavigate();

  const showToast = useToaster();

  const [insertClips, { isLoading: isInserting }] = api.useAddClipsToHighlightReelMutation();

  const disabled = propDisabled || isInserting;

  const handleClickCreate = () => {
    trackCreateClick?.(clipIds.length);

    const params: any = { artifacts: onlyClips.join(',') };
    if (studyId) {
      params.project_id = studyId;
    }
    navigate(`/highlight_reels/new${qs.stringify(params, { encode: false, addQueryPrefix: true })}`);
  };

  const handleAddToHighlightReel = (highlightReel: HighlightReel) => {
    setSelectedHlr(highlightReel);

    const clipsToAdd = clipIds.map(Number).map((id) => ({ id }));

    if (highlightReel.clips.length + clipsToAdd.length > MAX_CLIPS) {
      setClipLimitsModalOpen(true);
      return;
    }

    trackAddClick?.(clipIds.length);

    insertClips({
      token: highlightReel.token,
      clips: clipsToAdd
    })
      .unwrap()
      .then(() => {
        showToast(toasts.successAddedHighlights(highlightReel));
      })
      .catch(() => {
        showToast(toasts.failedAddedHighlights());
      });
  };

  const renderModal = (onContinue) => {
    if (!modalOpen) {
      return null;
    }

    if (anyClips) {
      return (
        <Modal
          onClose={() => setModalOpen(false)}
          renderFooter={() => (
            <>
              <Button onClick={() => setModalOpen(false)}>Go back</Button>
              <Button
                primary
                onClick={() => {
                  onContinue();
                  setModalOpen(false);
                }}
              >
                Continue
              </Button>
            </>
          )}
        >
          <ModalHeading className='mb-4'>
            Heads up
            <Text h='400' className='mt-1 text-gray-500'>
              Not all your selections will be added to reel.
            </Text>
          </ModalHeading>
          <section className='mt-4 mb-6'>
            <Text h='400'>
              You've selected some items that aren't video highlights. Only video highlights will be used in the
              highlight reel.
            </Text>
          </section>
        </Modal>
      );
    }

    return (
      <Modal
        icon='danger'
        onClose={() => setModalOpen(false)}
        renderFooter={() => (
          <Button onClick={() => setModalOpen(false)} primary>
            Got it
          </Button>
        )}
      >
        <ModalHeading className='mb-4'>No video highlights selected</ModalHeading>
        <section className='mt-2 mb-6'>
          <Text h='400'>You need to select video highlights in order to create a highlight reel.</Text>
        </section>
      </Modal>
    );
  };

  return (
    <>
      <BulkAddDropdown<HighlightReel>
        disabled={disabled}
        onClick={allClips ? undefined : (onClick as MouseEventHandler<HTMLButtonElement>)}
        icon='highlightReel'
        selectedCount={onlyClips.length}
        studyId={studyId}
        getQuery={api.useGetHighlightReelsQuery}
        hasCreateMode={false}
        onClickCreate={handleClickCreate}
        artifactType='reel'
        handleAdd={handleAddToHighlightReel}
        renderModal={renderModal}
        {...rest}
      />
      {clipsLimitModalOpen && (
        <HighlightsLimitModal
          existingClips={selectedHlr?.clips.length}
          clipsToAdd={clipIds.length}
          onClose={() => setClipLimitsModalOpen(false)}
        />
      )}
    </>
  );
};
