import React, { VFC } from 'react';

import { AvatarFromId, Card, Text } from '@components/common';
import { MeetingWebcamSVG } from '@components/svgs';

import { Pill } from './Pill';

interface Props {
  text: string;
  title: string;
  moderatorId: number;
  studyTitle: string | null;
  onClick?: () => void;
}

export const TranscriptSection: VFC<Props> = ({ moderatorId, studyTitle, text, title, onClick }) => {
  return (
    <Card noPadding>
      <div className='h-80 flex flex-col cursor-pointer' onClick={onClick}>
        <div className='bg-indigo-50 h-36 flex-shrink-0 p-4'>
          <span className='line-clamp-5'>{text}</span>
        </div>
        <section className='flex flex-col h-full p-4 border-t border-gray-200'>
          <Text as='h2' h='500' className='mb-2' bold truncate>
            {title}
          </Text>

          <div className='gap-y-2 gap-x-2 flex flex-wrap'>
            {studyTitle && <Pill icon={<MeetingWebcamSVG className='w-3 h-3' />} text={studyTitle} />}
            <Pill icon={<MeetingWebcamSVG className='w-3 h-3' />} text='Interview' />
          </div>

          <AvatarFromId className='mt-auto' userId={moderatorId} />
        </section>
      </div>
    </Card>
  );
};
