export const getScrollableParent = (node: Element | null): Element | null => {
  if (node == null) return null;

  if ((node as any)?.scrollTopMax !== undefined) {
    return (node as any)?.scrollTopMax > 0 ? node : getScrollableParent(node.parentNode as Element);
  }
  
  if (node == document.scrollingElement && node.scrollHeight > node.clientHeight) {
    return node;
  } else if (
    node.scrollHeight > node.clientHeight &&
    ['scroll', 'auto'].indexOf(getComputedStyle(node).overflowY) >= 0
  ) {
    return node;
  } else {
    return getScrollableParent(node.parentNode as Element);
  }
};
