import { useCreateGuideMutation, useGetGuidesQuery } from '@components/GuidesApp/api';

interface Hook {
  loading: boolean;
  guides: Guide[] | null;
  fetch: ReturnType<typeof useGetGuidesQuery>['refetch'];
  creating?: boolean;
  createGuide: ReturnType<typeof useCreateGuideMutation>[0];
}
export const useGuides = (): Hook => {
  const [createGuide, { isLoading: creating }] = useCreateGuideMutation();

  const { data: guides = null, refetch, isLoading, isFetching } = useGetGuidesQuery();

  return { guides, loading: isLoading || isFetching, fetch: refetch, creating, createGuide };
};
