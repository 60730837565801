import React, { SVGProps } from 'react';

export const ErrorRecordingSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity='0.3' clipPath='url(#clip0_506_3677)'>
      <path
        d='M6.75955 6.92261H6.75956H37.1842C40.6401 6.92261 43.4437 9.72745 43.4437 13.1825V19.9437C43.4437 20.4144 43.0622 20.7959 42.5916 20.7959C42.1209 20.7959 41.7394 20.4143 41.7394 19.9437V13.1825C41.7394 10.6686 39.6988 8.62683 37.1841 8.62683H6.75956C4.24054 8.62683 2.20422 10.6645 2.20422 13.1823V30.7613C2.20422 33.2775 4.24434 35.3169 6.76106 35.3169H26.3662C26.8368 35.3169 27.2183 35.6984 27.2183 36.169C27.2183 36.6397 26.8368 37.0212 26.3662 37.0212H6.76106C3.30313 37.0212 0.5 34.2187 0.5 30.7614V13.1823C0.5 9.72339 3.29912 6.92252 6.75955 6.92261Z'
        fill='currentColor'
        stroke='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M38.1972 41.5774C43.6111 41.5774 48 37.1885 48 31.7746C48 26.3607 43.6111 21.9718 38.1972 21.9718C32.7833 21.9718 28.3943 26.3607 28.3943 31.7746C28.3943 37.1886 32.7832 41.5774 38.1972 41.5774ZM38.1972 38.8732C34.2768 38.8732 31.0986 35.6951 31.0986 31.7746C31.0986 27.8542 34.2768 24.6761 38.1972 24.6761C42.1176 24.6761 45.2958 27.8542 45.2958 31.7746C45.2958 35.6951 42.1176 38.8732 38.1972 38.8732ZM19.6056 24.7809V18.8957L24.2422 21.833L19.6056 24.7809ZM27.4831 22.9715C28.3185 22.4403 28.3185 21.221 27.4832 20.6896L18.9791 15.2804C18.0789 14.7079 16.9013 15.3545 16.9013 16.4213V27.2382C16.9013 28.3049 18.0788 28.9515 18.979 28.3791L27.4831 22.9715Z'
        fill='currentColor'
      />
      <rect x='36.9375' y='26.3438' width='2.71875' height='7.21875' rx='1.35938' fill='white' />
      <rect x='36.9375' y='34.5938' width='2.71875' height='2.71875' rx='1.35938' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0_506_3677'>
        <rect width='48' height='48' fill='currentColor' />
      </clipPath>
    </defs>
  </svg>
);
