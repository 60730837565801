import { createContext, useContext } from 'react';

import { ArtifactHit } from '@components/RepositoryApp/types';

export interface ArtifactWithDetails extends ArtifactHit {
  users: string;
  studyTitles?: string;
  isActive?: boolean;
}

export interface Context {
  highlightReelToken?: HighlightReel['token'];
  artifactIds: string[];
  isDirty?: boolean;
  save?: (highlightReel: Partial<HighlightReel>) => Promise<HighlightReel>;
  setArtifactIds: (clips: string[]) => void;
  setIsDirty?: (isDirty: boolean) => void;
  setShowClips?: (show: boolean) => void;
  clipForDetails?: ArtifactWithDetails | null;
  setClipForDetails?: (show: ArtifactWithDetails | null) => void;
  clips?: Clip[];
  playerIsLoading?: boolean;
  setPlayerIsLoading?: (isLoading: boolean) => void;
  slideoutView?: true;
  reelPreviewProps?: { onAddToHighlightReel: () => void; onRemove: () => void };
}

export const HighlightReelsContext = createContext<Context | undefined>(undefined);

export const useHighlightReelsContext = () => {
  const highlightReelsContext = useContext<Context | undefined>(HighlightReelsContext);

  if (highlightReelsContext === undefined) throw new Error();

  return highlightReelsContext;
};
