import { api } from 'api/reduxApi';
import { useToaster } from 'components/stores/toaster';
import { useEffect } from 'react';

import * as toasts from '../../../toasts';

type Hook = {
  loading: boolean;
  settings: StudySettings;
  updateSettings: any;
  handleToggleSetting: (medium: 'email' | 'slack' | 'automation', key: string) => void;
};

type UseStudySettings = (study: Study) => Hook;

export const useStudySettings: UseStudySettings = (study) => {
  const {
    data: settings = { email: {}, slack: {}, automation: {} },
    isLoading,
    isSuccess,
    isError
  } = api.useGetStudySettingsQuery(study.id, { skip: !study.id });

  const [updateSettings, { isLoading: isUpdating, isError: isUpdatingError, isSuccess: isUpdatingSuccess }] =
    api.useUpdateStudySettingsMutation();

  const loading = isLoading || !isSuccess;

  const showToast = useToaster();

  useEffect(() => {
    if (isError) {
      showToast({
        heading: 'Something went wrong!',
        text: 'An error occurred while getting study settings. Please try again later.',
        icon: 'error'
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isUpdatingError) {
      showToast(toasts.failedUpdate());
    }
  }, [isUpdatingError]);

  const handleToggleSetting = (medium: 'email' | 'slack' | 'automation', key: string) => {
    const newSettings = { ...settings, [medium]: { ...settings[medium], [key]: !settings[medium][key] } };
    updateSettings({ id: study.id, settings: newSettings });
  };

  return {
    loading,
    updateSettings,
    handleToggleSetting,
    settings
  };
};
