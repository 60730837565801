import { getDocument, updateDocument } from 'api/queries';
import { Mark, Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { useEffect, useState } from 'react';

import { Extension, useEditor } from '@tiptap/react';
import { buildExtensions } from '@components/shared/Tiptap/helpers/extensions';
import { BASE_CONFIG } from '@components/shared/Tiptap/constants';
import { Recording, Transcript } from '@components/shared/Tiptap/extensions';

interface Args {
  documentId: number | undefined;
}

export type TextNode = {
  speaker: TranscriptSpeaker;
  text: string;
  timestamp: number;
};
interface UseEditorState {
  getTextNodes: (timeRange: [number, number]) => TextNode[];
  updateMark: (highlight: Highlight) => Promise<unknown>;
  state: EditorState | undefined;
}

export const useEditorState = ({ documentId }: Args): UseEditorState => {
  const [document, setDocument] = useState<ApiDocument['doc']>();

  const editor = useEditor(
    {
      extensions: buildExtensions({ ...BASE_CONFIG, ...{ highlight: { enable: true } } }, [
        Transcript,
        Recording
      ] as Extension[]),
      content: document
    },
    [document]
  );

  const schema = editor?.schema;

  const getTextNodes = ([from, to]: [number, number]): TextNode[] => {
    const nodes: TextNode[] = [];
    let currentSpeaker: TranscriptSpeaker | null = null;
    let currentMark: Mark | null = null;
    let text = '';

    editor?.state.doc.forEach((node) => {
      const isTranscript = node.type === schema?.nodes.transcript;

      if (isTranscript) {
        node.content.forEach((wordNode, pos) => {
          const wordMark = wordNode.marks.find(({ type }) => type === schema?.marks.transcript_word);

          if (!wordMark) return;

          if (wordMark.attrs.start_ts >= from && wordMark.attrs.end_ts <= to) {
            if (!currentSpeaker) {
              currentSpeaker = node.attrs.speaker;
            }

            if (currentSpeaker && node.attrs.speaker.name !== currentSpeaker.name) {
              nodes.push({
                speaker: currentSpeaker,
                text,
                timestamp: wordMark.attrs.start_ts
              });

              currentSpeaker = node.attrs.speaker;
              text = wordNode.textContent;
            } else {
              text += wordNode.textContent;
            }

            currentMark = wordMark;
          }
        });
      }
    });

    // leftover text
    if (text && currentSpeaker && currentMark) {
      nodes.push({
        speaker: currentSpeaker,
        text,
        timestamp: (currentMark as Mark).attrs.start_ts
      });
    }

    return nodes;
  };

  const saveDocument = async (newDocument: Node) => {
    await updateDocument(documentId, newDocument.toJSON());
  };

  const updateMark = async (highlight: Highlight) => {
    if (!editor) return;

    editor.commands.toggleHighlight({ highlightId: highlight.id, color: highlight.color });
    saveDocument(editor.state.doc);
  };

  useEffect(() => {
    const getTranscriptDocument = async () => {
      const [, { doc }] = await getDocument(documentId);
      setDocument(doc);
    };

    if (documentId) {
      getTranscriptDocument();
    }
  }, [documentId]);

  return {
    getTextNodes,
    updateMark,
    state: editor?.state
  };
};
