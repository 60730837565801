import React, { SVGProps } from 'react';

export const TagSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.707031 1.70667V5.878C0.706968 6.40829 0.91751 6.9169 1.29236 7.292L8.9997 15C9.18723 15.1875 9.44153 15.2928 9.7067 15.2928C9.97186 15.2928 10.2262 15.1875 10.4137 15L14.9997 10.4133C15.1872 10.2258 15.2925 9.9715 15.2925 9.70633C15.2925 9.44117 15.1872 9.18686 14.9997 8.99933L7.29236 1.292C6.91727 0.917144 6.40866 0.706602 5.87836 0.706665H1.70703C1.44181 0.706665 1.18746 0.812022 0.999925 0.999558C0.812388 1.18709 0.707031 1.44145 0.707031 1.70667V1.70667Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.20703 4.20667C3.20703 4.33799 3.2329 4.46802 3.28315 4.58935C3.33341 4.71067 3.40707 4.82091 3.49992 4.91377C3.59278 5.00663 3.70302 5.08029 3.82435 5.13054C3.94567 5.1808 4.07571 5.20667 4.20703 5.20667C4.33835 5.20667 4.46839 5.1808 4.58971 5.13054C4.71104 5.08029 4.82128 5.00663 4.91414 4.91377C5.007 4.82091 5.08066 4.71067 5.13091 4.58935C5.18117 4.46802 5.20703 4.33799 5.20703 4.20667C5.20703 4.07534 5.18117 3.94531 5.13091 3.82398C5.08066 3.70266 5.007 3.59242 4.91414 3.49956C4.82128 3.4067 4.71104 3.33304 4.58971 3.28279C4.46839 3.23253 4.33835 3.20667 4.20703 3.20667C4.07571 3.20667 3.94567 3.23253 3.82435 3.28279C3.70302 3.33304 3.59278 3.4067 3.49992 3.49956C3.40707 3.59242 3.33341 3.70266 3.28315 3.82398C3.2329 3.94531 3.20703 4.07534 3.20703 4.20667Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
