import { ACTIVITY_TIMESTAMPS_MAPPING } from '../components';
import { dateStatuses } from './dateStatuses';

// TODO: add all dates.
export const getDateAttrs = (style, hasScreener, onGQ) => {
  const statuses = dateStatuses(style, hasScreener, onGQ);

  return [
    { name: 'last_contacted_at', label: 'Last invited' },
    ...statuses.map((s) => {
      const [header, accessor] = ACTIVITY_TIMESTAMPS_MAPPING[s];
      return { name: accessor, label: header };
    })
  ];
};
