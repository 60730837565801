import { useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import consumer from '@helpers/consumer';

const CHANNEL = 'ConnectedAccountSyncChannel';

export const useConnectedAccountSync = ({ provider }: HookArgs): HookResult => {
  const [message, setMessage] = useState<SyncChannelMessage>(null);
  const [connectedAccount, setConnectedAccount] = useState<ConnectedAccount>();
  const { data: connectedAccounts } = api.useGetConnectedAccountsQuery();

  const channel = useMemo(
    () =>
      connectedAccount
        ? consumer.subscriptions.create({
            channel: CHANNEL,
            connected_account_id: connectedAccount.id
          })
        : null,
    [consumer, connectedAccount]
  );

  const getAccount = (acc: ConnectedAccount) => acc.provider === provider;

  useEffect(() => {
    if (channel) {
      channel.received = (data) => {
        setMessage(data.message);
      };
    }
  }, [channel]);

  useEffect(() => {
    if (connectedAccounts && connectedAccounts.length) {
      setConnectedAccount(connectedAccounts.find(getAccount));
    }
  }, [connectedAccounts]);

  useEffect(() => {
    if (
      connectedAccount &&
      connectedAccount.sync_status &&
      ['pending', 'failed'].includes(connectedAccount.sync_status)
    ) {
      setMessage(connectedAccount.sync_status);
    }
  }, [connectedAccount]);

  return { message, account: connectedAccount };
};
