import { TODAY, TOMORROW } from '../../constants';
import { startOfWeek } from '@components/utils';
import { findCurrentTimeZoneName } from '@components/utils/tz';

export const bookingLimitOptions = () => {
  const options = [{ label: 'None', value: '-1' }];
  const otherOptions = [...Array.from(Array(21).keys())].map((key) => ({ label: `${key}`, value: `${key}` }));

  options.push(...otherOptions);

  return options;
};

export const getInitialTimeSlot = (
  time_slot_settings: Partial<Study['time_slot_settings']>,
  time_zone?: string | null
) => ({
  start_time: '07:00',
  end_time: '19:00',
  days: [1, 2, 3, 4, 5],
  timezone: time_zone || findCurrentTimeZoneName(),
  future_limit: 2,
  call_buffer: 0,
  start_date: TOMORROW.toISOString(),
  ...time_slot_settings
});

export const getViewSettings = (cal_view_settings: Partial<Study['cal_view_settings']>) => ({
  start_date: +startOfWeek(TODAY),
  start_time: '07:00',
  end_time: '19:00',
  week_days_only: false,
  ...cal_view_settings
});
