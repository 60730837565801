import { Toast } from '@stores/toaster';

export const successAddedHighlights = (highlightReel: HighlightReel): Toast => ({
  heading: 'Highlights added',
  text: 'Highlights were added to the selected highlight reel',
  icon: 'success',
  action: {
    text: 'View highlight reel',
    href: `/highlight_reels/${highlightReel.token}`
  }
});

export const failedAddedHighlights = (): Toast => ({
  heading: 'Something went wrong',
  text: 'Highlights could not be added to the selected highlight reel',
  icon: 'error'
});
