import { sample, without } from '@components/utils';

export type TagColorOptions = {
  bgColor: string;
  whiteText?: boolean;
};

type Color = {
  r: number;
  g: number;
  b: number;
  a: number;
};
// https://www.figma.com/file/ogost4NOL4qXlyJhZGJLj3/Repo?node-id=358%3A12808
export const TAG_COLORS: Record<string, TagColorOptions> = {
  red: { bgColor: '#FB5779', whiteText: true },
  orange: { bgColor: '#FF7511', whiteText: true },
  tangerine: { bgColor: '#FFA800', whiteText: false },
  yellow: { bgColor: '#FFD100', whiteText: false },
  lime: { bgColor: '#ACE60F', whiteText: false },
  seagreen: { bgColor: '#19DB7E', whiteText: true },
  aqua: { bgColor: '#00D4C8', whiteText: false },
  lightblue: { bgColor: '#48DAFD', whiteText: false },
  blue: { bgColor: '#0064FB', whiteText: true },
  violet: { bgColor: '#6457F9', whiteText: true },
  purple: { bgColor: '#9F46F4', whiteText: true },
  pink: { bgColor: '#FF78FF', whiteText: true },
  hotpink: { bgColor: '#FF4BA6', whiteText: true },
  salmon: { bgColor: '#FF93AF', whiteText: false },
  gray: { bgColor: '#5A7896', whiteText: true },
  default: { bgColor: '#E5E7EB', whiteText: false }
};

export const getBackgroundColor = (tagColor = 'default', a = 1, mergeWithBG?: string): string => {
  const hex = TAG_COLORS[tagColor]?.bgColor || TAG_COLORS.default.bgColor;
  const color = getColorFromHex(hex);
  color.a = a;

  return toRGBA(color);
};

const getColorFromHex = (hex: string): Color => {
  return { r: parseInt(hex.slice(1, 3), 16), g: parseInt(hex.slice(3, 5), 16), b: parseInt(hex.slice(5, 7), 16), a: 1 };
};

const toRGBA = (color: Color): string => `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

export const randomTagColor = (): string => sample(without(Object.keys(TAG_COLORS), 'default'));

export const getHexWithOpacity = (hex: string, opacity = 1) => {
  const color = getColorFromHex(hex);
  color.a = opacity;
  return toRGBA(color);
};
