import tinytime from 'tinytime';

export function xTickFormat(t: Date, period: 'week' | 'month'): string {
  if (period === 'month') {
    return `${['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][t.getMonth()]}`;
  } else {
    const dateTemplate = tinytime('{DD} {MMMM}');
    return dateTemplate.render(t);
  }
}

export function daysToWholeUnits(days: number | null): [number, number, number] | null {
  if (days === null) {
    return null;
  }
  const wholeDays = Math.floor(days);
  const hours = Math.round((days - wholeDays) * 24);
  const minutes = Math.round(((days - wholeDays) * 24 - hours) * 60);
  return [wholeDays, hours, minutes];
}
