import React, { SVGProps } from 'react';

export const PlayerPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 30.0625C23.7665 30.0625 30.0625 23.7665 30.0625 16C30.0625 8.2335 23.7665 1.9375 16 1.9375C8.2335 1.9375 1.9375 8.2335 1.9375 16C1.9375 23.7665 8.2335 30.0625 16 30.0625Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.25 20.5124C12.2478 20.9057 12.3595 21.2913 12.5716 21.6226C12.7837 21.9538 13.0872 22.2166 13.4453 22.3792C13.8035 22.5418 14.2011 22.5972 14.5901 22.5387C14.9791 22.4803 15.3429 22.3105 15.6375 22.0499L21.4875 16.9374C21.6205 16.8201 21.727 16.6758 21.8 16.5142C21.8729 16.3526 21.9107 16.1772 21.9107 15.9999C21.9107 15.8226 21.8729 15.6472 21.8 15.4856C21.727 15.324 21.6205 15.1797 21.4875 15.0624L15.6375 9.9374C15.3411 9.68223 14.9779 9.51716 14.5908 9.46166C14.2037 9.40617 13.8088 9.46256 13.4527 9.6242C13.0965 9.78583 12.7941 10.046 12.581 10.3739C12.3679 10.7018 12.253 11.0838 12.25 11.4749V20.5124Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
