import * as React from 'react';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import Skeleton from 'react-loading-skeleton';

import { PlaceholderColumn } from './PlaceholderColumn';

type Props = {
  onDragStart: (start: DragStart) => void;
  onDragEnd: (result: DropResult) => void;
  showSkeleton?: boolean;
  showPlaceholder?: boolean;
  placeholderColumnText?: string;
  onCreateColumn: () => void;
};

export const ColumnBoard: React.FC<Props> = ({
  children,
  onDragStart,
  onDragEnd,
  showSkeleton,
  showPlaceholder,
  placeholderColumnText = '',
  onCreateColumn
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div className='flex w-full h-full px-page mt-4 space-x-6 overflow-x-auto'>
        {showSkeleton && (
          <div className='relative flex flex-col flex-shrink-0 w-full h-full max-w-sm'>
            <Skeleton className='bg-gray-50 h-12' />
          </div>
        )}

        {children}

        {showPlaceholder && <PlaceholderColumn text={placeholderColumnText} onClickCreate={onCreateColumn} />}
      </div>
    </DragDropContext>
  );
};
