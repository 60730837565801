import * as React from 'react';
import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Card, CardTitle } from '@components/common';
import { Basic } from '@components/shared/Skeleton';

import { EventStats } from './EventStats';

interface Props {
  study: Study;
}

const EmptyStats: React.FC<any> = () => (
  <Card>
    <CardTitle>No stats available</CardTitle>
  </Card>
);

const Skeleton = () => {
  return (
    <div className='relative p-6 mb-4 bg-white border border-gray-200 rounded'>
      <Basic h='10' width={250} />
      <div className='flex flex-row mt-6 -ml-6 divide-x divide-gray-200'>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={136} />
          <Basic h='8' width={110} />
        </div>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={110} />
          <Basic h='8' width={60} />
        </div>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={120} />
          <Basic h='8' width={50} />
        </div>
        <div className='flex flex-col flex-grow px-6 space-y-4'>
          <Basic h='8' width={100} />
          <Basic h='8' width={50} />
        </div>
      </div>
    </div>
  );
};

export const StatsIndex: React.FC<Props> = ({ study }) => {
  const { data: studyStats, isLoading, isSuccess } = api.useGetStudyStatsQuery(study.id);
  const [eventStats, setEventStats] = useState<Omit<StudyStatsEvents, 'id'> | undefined>(undefined);
  const [hasEvents, setHasEvents] = useState<boolean>(true);

  useEffect(() => {
    const { id, ...events } = studyStats || {};
    setEventStats(events);
    setHasEvents(Object.entries(events).filter(([_, stats]) => stats).length > 0);
  }, [isLoading]);

  return (
    <div className='px-page py-gutter relative'>
      <h3>Stats</h3>
      <p>
        <small>Performance of emails by type. Participants may be counted multiple times.</small>
      </p>
      <br />
      {isLoading ? (
        <Skeleton />
      ) : (
        hasEvents &&
        eventStats &&
        Object.entries(eventStats).map(([evt, stats], i) => (
          <EventStats key={`event-stats-${evt}`} event={evt} stats={stats} showTitle studyId={study.id} />
        ))
      )}
      {!hasEvents && isSuccess && <EmptyStats />}
    </div>
  );
};
