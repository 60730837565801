import consumer from 'helpers/consumer';

export const createChannel = (id, opts = {}) =>
  consumer.subscriptions.create(
    {
      channel: 'Ai::ChatsChannel',
      chat_id: id
    },
    opts
  );
