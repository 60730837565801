import { compact } from 'components/utils';

const LABELS = {
  'Repo::Session': 'Interview',
  Clip: 'Highlight',
  Highlight: 'Note',
  HighlightReel: 'Highlight Reel',
  Story: 'Insight',
  ScreenerResponse: 'Survey Response'
};

type Option = { label: string; value: string };

export const artifactTypeOptions = () =>
  compact([
    {
      label: LABELS['Repo::Session'],
      value: 'Repo::Session'
    },
    {
      label: LABELS['Clip'],
      value: 'Clip'
    },
    {
      label: LABELS['Highlight'],
      value: 'Highlight'
    },
    {
      label: LABELS['HighlightReel'],
      value: 'HighlightReel'
    },
    {
      label: LABELS['Story'],
      value: 'Story'
    },
    {
      label: LABELS['ScreenerResponse'],
      value: 'ScreenerResponse'
    }
  ]) as Option[];

export { LABELS as ARTIFACT_TYPE_LABELS };
