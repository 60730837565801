import * as React from 'react';

import { track } from '@components/tracking';
import { ArtifactHit } from '@components/RepositoryApp/types';
import VideoPlayer, { UseVideoPlayer } from '@components/VideoPlayer';
import { forwardRef } from 'react';
import { LightBulbSVG } from '@components/svgs';
import { getArtifactInfoLabel } from 'components/shared/Artifact/components/utils';

type Props = {
  artifact: ArtifactHit;
  page: 'reels_editor' | 'repository';
};

export const VideoPreview = forwardRef<UseVideoPlayer, Props>(({ page, artifact }, ref) => {
  const { stream_url, mux_status, objectID, cover_image_url, from, to } = artifact;
  const type = objectID.split('_')[0];
  const showPlayer = ['Repo::Session', 'Clip', 'HighlightReel'].includes(type);

  const clip: [number, number] | undefined =
    mux_status !== 'ready' ? [(from || 0) / 1000, (to || 0) / 1000] : undefined;

  return (
    <>
      {type === 'Story' && (
        <div className='flex flex-col flex-1'>
          <div style={{ paddingBottom: '35.7%' }} className='relative w-full'>
            {cover_image_url ? (
              <img alt='Cover' className='absolute object-cover w-full h-full' src={cover_image_url} />
            ) : (
              <div className='absolute flex items-center justify-center w-full h-full bg-gray-200'>
                <LightBulbSVG className='w-8 h-8 text-gray-400' />
              </div>
            )}
          </div>
        </div>
      )}
      {showPlayer && (
        <VideoPlayer
          ref={ref}
          src={stream_url}
          autoPlay
          miniPlayerEnabled={false}
          clip={clip}
          onPlay={() => track('played_video', { page, artifact_type: getArtifactInfoLabel(artifact.kind) })}
        />
      )}
    </>
  );
});
