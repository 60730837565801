import * as React from 'react';
import { HeaderProps } from 'react-table';

import { Text } from '@components/common';
import { humanize } from '@components/utils';

export const Default: React.FC<HeaderProps<UploadManagerItem>> = ({ column }) => (
  <Text className='text-left' bold>
    {humanize(column.id)}
  </Text>
);
