import { COMPLIANCE_REGULATORS } from '@components/GovernanceApp/components/constants';
import { Button, Text } from '@components/common';
import React from 'react';
import { useDeviceType } from '@hooks/useDeviceType';

export const Compliance: React.FC = () => {
  const { isMobile, isTablet } = useDeviceType();

  return (
    <>
      <Text as='h1' h='700' className='mb-1'>
        Compliance
      </Text>
      <Text h='500' color='gray-500' className='mb-4 monitor:mb-6'>
        Our security and privacy certifications to keep your data safe.
      </Text>
      <div className='flex-1 flex flex-col bg-white border border-gray-200 rounded-md px-6'>
        {COMPLIANCE_REGULATORS.map((regulator) => {
          return (
            <div key={regulator} className='flex flex-nowrap space-x-4 items-center py-6 border-b border-gray-200'>
              <img
                alt={`${regulator} logo`}
                src={`/images/compliance/${regulator.replace(/\s+/g, '')}.png`}
                srcSet={`/images/compliance/${regulator.replace(
                  /\s+/g,
                  ''
                )}@2x.png 2x, /images/compliance/${regulator.replace(/\s+/g, '')}@3x.png 3x`}
              />
              <div className='flex flex-col space-y-2 flex-grow'>
                <Text bold>{regulator}</Text>
                {/*<Text h='400'>Received .</Text>*/}
              </div>
              <Button small icon='roundArrowDown' href='https://trust.greatquestion.co' target='_blank'>
                {isMobile || isTablet ? '' : 'View in Trust Center'}
              </Button>
            </div>
          );
        })}
      </div>
    </>
  );
};
