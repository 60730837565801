import { Toast } from '@stores/toaster';

export const successUpdate = (t): Toast => ({
  heading: t('success_heading'),
  text: t('success_desc'),
  icon: 'success'
});

export const failedUpdate = (t): Toast => ({
  heading: t('failed_heading'),
  text: t('failed_desc'),
  icon: 'error'
});
