import * as React from 'react';
import {
    DomainTuple, VictoryAxis, VictoryBar, VictoryChart, VictoryTooltip, VictoryVoronoiContainer
} from 'victory';

import { ChartDataPoint } from '@components/ScreenerResults/ScreenerChart/types';

import { xTickFormat } from './utils';

function daysToDaysAndHours(days: number | null): string {
  if (days === null) {
    return 'no data';
  }
  const wholeDays = Math.floor(days);
  const hours = Math.round((days - wholeDays) * 24);
  const minutes = Math.round(((days - wholeDays) * 24 - hours) * 60);
  if (wholeDays === 0 && hours === 0) {
    return `${minutes}min`;
  } else if (wholeDays === 0) {
    return `${hours}h ${minutes}min`;
  }
  return `${wholeDays}d ${hours}h`;
}

interface Props {
  data: ChartDataPoint[];
  size?: { width: number; height: number };
  unit: string;
  period: 'week' | 'month';
  domain: { x?: DomainTuple };
}

export const BarChart: React.FC<Props> = ({ data, size, unit, period, domain }) => {
  return (
    <VictoryChart
      width={size?.width || 600}
      height={size?.height || 300}
      scale={{ x: 'time', y: 'linear' }}
      domainPadding={{ x: [30, 0] }}
      domain={domain}
      containerComponent={
        <VictoryVoronoiContainer
          voronoiDimension='x'
          labels={({ datum }) => {
            if (datum.y === null) {
              return 'no data';
            }
            if (unit == 'days') {
              return `${xTickFormat(datum.x, period)}: ${daysToDaysAndHours(datum.y)}`;
            } else {
              return `${xTickFormat(datum.x, period)}: ${datum.y}`;
            }
          }}
          labelComponent={
            <VictoryTooltip
              style={{
                fontSize: 12,
                fontFamily: 'inherit'
              }}
              flyoutStyle={{ stroke: '#e5e7eb', fill: 'white', fontFamily: 'inherit' }}
            />
          }
        />
      }
    >
      <VictoryAxis
        style={{
          axis: { stroke: '#EDEDED' },
          ticks: { stroke: 'grey', size: 5 },
          tickLabels: {
            fontSize: 12,
            padding: 5,
            fill: '#6B6B6B',
            fontFamily: 'inherit'
          }
        }}
        tickFormat={(t) => xTickFormat(t, period)}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: 'transparent' },
          grid: { stroke: '#EDEDED' },
          tickLabels: { fontSize: 12, padding: 5, fill: '#6B6B6B', fontFamily: 'inherit' }
        }}
        tickFormat={(x) => `${x}`}
        label={unit}
      />
      <VictoryBar
        data={data}
        style={{
          data: { stroke: '#5850ec', strokeWidth: 2, fill: '#5850ec' },
          labels: { fontFamily: 'inherit' }
        }}
      />
    </VictoryChart>
  );
};
