import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { baseColumnDefinitions } from '../helpers/baseColumnDefinitions';
import { piiColumnDefinitions } from '../helpers/piiColumnDefinitions';
import { studyColumnDefinitions } from '../helpers/studyColumnDefinitions';
import { customerColumnDefinitions } from '../helpers/customerColumnDefinitions';
import { activityColumnDefinitions } from '../helpers/activityColumnDefinitions';
import { extraColumnDefinitions } from '../helpers/extraColumnDefinitions';
import { screenerFieldColumnDefinitions } from '../helpers/screenerFieldColumnDefinitions';

import { useColumnLabels } from './useColumnLabels';
import { usePermission } from '@hooks/usePermission';
import { useFeature } from '@hooks/useFeature';
import { hasInterviewRoom as hasRoom } from '@components/StudiesApp/components/StudyPublished/utils';

interface UseColumnDefinitionsProps {
  candidateAttrs: Attr_[];
  study: Study;
  onParticipationClick?: (participation: Participation) => void;
  setBackgroundTask?: (task: BackgroundTask) => void;
  participationStatus: ParticipationStatus;
  limits?: boolean;
  addFilter: (filter: string) => void;
  onTimeProposalClick?: (participation: Participation) => void;
  partyIdToLimit: (id: number) => string;
}

export const useColumnDefinitions = ({
  candidateAttrs,
  study,
  onParticipationClick,
  setBackgroundTask,
  participationStatus,
  limits,
  addFilter,
  onTimeProposalClick,
  partyIdToLimit
}: UseColumnDefinitionsProps): ColumnDef<Participation>[] => {
  const getColumnLabel = useColumnLabels({ study });

  const canPII = usePermission('showPII')();
  const enableTeams = useFeature('teams');

  const participantLevelIncentives = useFeature('participant_level_incentives');
  const canUpdate = usePermission<Study>('updateStudy')(study);
  const hasParticipantLevelIncentives = participantLevelIncentives && canUpdate;
  const hasInterviewRoom = hasRoom({ style: study.style, status: participationStatus });

  const definitions = useMemo<ColumnDef<Participation>[]>(() => {
    return [
      ...baseColumnDefinitions({ getColumnLabel, onParticipationClick, addFilter }),
      ...piiColumnDefinitions({ getColumnLabel, canPII, addFilter }),
      ...customerColumnDefinitions({ getColumnLabel, enableTeams, addFilter, participationStatus }),
      ...studyColumnDefinitions({
        study,
        getColumnLabel,
        setBackgroundTask,
        hasInterviewRoom,
        hasParticipantLevelIncentives,
        participationStatus,
        limits,
        addFilter,
        onTimeProposalClick,
        partyIdToLimit
      }),
      ...activityColumnDefinitions({ getColumnLabel, study, addFilter }),
      ...extraColumnDefinitions({ candidateAttrs, addFilter, study }),
      ...screenerFieldColumnDefinitions({ study, addFilter })
    ];
  }, [
    candidateAttrs,
    canPII,
    enableTeams,
    participationStatus,
    study,
    activityColumnDefinitions,
    baseColumnDefinitions,
    customerColumnDefinitions,
    extraColumnDefinitions,
    getColumnLabel,
    piiColumnDefinitions,
    setBackgroundTask,
    studyColumnDefinitions,
    addFilter,
    limits,
    partyIdToLimit
  ]);

  return definitions;
};
