import * as React from 'react';

import { useAccount } from '@hooks/useAccount';

import { DropdownProps } from '../../types';
import { MultiSelectWithSearch, Option } from './MultiSelectWithSearch';

type Props<D = any> = DropdownProps<D, number[]>;

export const TeamMember: React.FC<Props> = (props) => {
  const {
    account: { team }
  } = useAccount();

  const options: Option[] = team.map((t) => ({
    label: t.name,
    value: t.id
  }));

  return <MultiSelectWithSearch {...props} options={options} />;
};
