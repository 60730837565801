import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading, ModalSubheading } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';

interface Props {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  accountUserId: number;
  name: string;
  newQuota: number;
}
export const RemoveUserModal: React.FC<Props> = ({ open, onClose, onComplete, accountUserId, name, newQuota }) => {
  const showToast = useToaster();

  const [updateAccountUser, { isLoading, isError, isSuccess }] = api.useUpdateAccountUserMutation();

  const deactivate = (id: number) => updateAccountUser({ id, role: 'deactivated' });

  useEffect(() => {
    if (isSuccess) {
      showToast(toasts.successRemoveUser(name));
      onComplete();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedRemoveUser());
    }
  }, [isError]);

  if (!open) {
    return null;
  }

  const handleClick = () => deactivate(accountUserId);

  return (
    <Modal size='md' icon='danger' onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2'>Remove {name}?</ModalHeading>
      <ModalSubheading>Review what it means to update their permissions.</ModalSubheading>
      <p className='h500 mt-6 mb-4 font-bold'>You understand that:</p>
      <ul className='ml-6 space-y-1 list-disc'>
        <li>{name} will no longer be able to access your account.</li>
        <li>Any draft studies will be reassigned to you.</li>
        <li>Any active studies will be paused.</li>
        <li>Your billing will be reduced in the next cycle.</li>
        <li>After this change, you will have {newQuota === 0 ? 'no' : newQuota} creator seats remaining</li>
      </ul>
      <div className='flex flex-wrap items-center justify-end mt-6 space-x-4'></div>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Don’t remove
        </Button>
        <Button disabled={isLoading} onClick={handleClick} danger>
          Confirm & remove
        </Button>
      </div>
    </Modal>
  );
};
