import { differenceInMinutes, format, formatDistanceToNow } from 'date-fns';
import React from 'react';

import { Text } from '@components/common';
import { CalendarSVG, ClockSVG } from '@components/svgs';

interface Props {
  participation: Participation;
}

export const EventDate: React.FC<Props> = ({ participation }) => {
  const { start_time } = participation.event_details ?? {};

  if (!start_time) return null;

  return (
    <div className='flex flex-row items-center space-x-4 py-1.5'>
      <Text h='400' className='flex items-start whitespace-nowrap'>
        <CalendarSVG className='mr-2' />
        {format(new Date(start_time), 'E, MMM d, R')}
      </Text>
      <Text h='400' className='flex items-center whitespace-nowrap'>
        <ClockSVG className='mr-2' />
        {format(new Date(start_time), 'h:mm a O')}
      </Text>
    </div>
  );
};
