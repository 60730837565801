import { api } from 'api/reduxApi';
import { useToaster } from 'components/stores/toaster';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { changeParticipationStatus, createParticipation, getParticipations } from '@api/queries';
import {
    Alert, AlertHeading, AlertLink, AlertMessage, Button, InputWithAddons, Loading, Select,
    SlideOut, Text
} from '@components/common';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { ThanksSlideOut } from '@components/StudyMessages';
import { compact, moneyFormat, noop } from '@components/utils';
import { usePermission } from '@hooks/usePermission';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { CandidateSelector } from './components/CandidateSelector';
import { FundStudyModal } from './components/FundStudyModal';
import { NewCandidate } from './components/NewCandidate';
import { NewStudy } from './components/NewStudy';
import { FormGroup, Label, STUDY_STYLE_OPTIONS, SubLabel } from './components/shared';
import { StudySelector } from './components/StudySelector';

const ExpandSVG: React.FC = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.5631 4.37402V0.436523H9.62561'
      stroke='#5850EC'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5631 0.436523L4.81311 9.18652'
      stroke='#5850EC'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.56311 3.06152H1.31311C1.08105 3.06152 0.858486 3.15371 0.694392 3.3178C0.530298 3.4819 0.43811 3.70446 0.43811 3.93652V12.6865C0.43811 12.9186 0.530298 13.1411 0.694392 13.3052C0.858486 13.4693 1.08105 13.5615 1.31311 13.5615H10.0631C10.2952 13.5615 10.5177 13.4693 10.6818 13.3052C10.8459 13.1411 10.9381 12.9186 10.9381 12.6865V7.43652'
      stroke='#5850EC'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
const ValidStudyInfo: React.FC<{ study: Study }> = ({ study }) => (
  <>
    <div className='flex flex-row space-x-4'>
      <div className='tablet:w-1/2 w-full'>
        <label htmlFor='new_incentive_amount'>Incentive ($)</label>
        <InputWithAddons
          id='new_incentive_amount'
          value={study?.incentive}
          onChange={noop}
          className='w-full bg-gray-200'
          suffix={study.currency}
        />
      </div>
      <div className='tablet:w-1/2 w-full'>
        <label htmlFor='style_for_new_incentives_study'>Style</label>
        <Select
          id='style_for_new_incentives_study'
          value={study.style}
          options={STUDY_STYLE_OPTIONS}
          onChange={noop}
          disabled
          className='bg-gray-200'
        />
      </div>
    </div>
    <Text h='400' color='gray-500' className='mt-2'>
      Values cannot be changed for existing studies.
      <Link className='ml-1 text-xs' target='_blank' to={`/studies/${study.id}`}>
        View study
      </Link>
    </Text>
  </>
);

export const NewIncentive: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const navigate = useNavigate();
  const [party, setParty] = useState<Participation>();

  const showToast = useToaster();

  const { data: study, isError: studyError } = api.useGetStudyQuery(party?.project_id ?? skipToken, {
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    if (studyError) {
      showToast({
        heading: 'Something went wrong!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }, [studyError]);

  function onSend() {
    onSuccess();
    navigate('/incentives');
  }

  function handleClose() {
    navigate('/incentives');
  }

  return party && study ? (
    <ThanksSlideOut
      messageOnly={party && party.status === 'completed'}
      study={study}
      participations={[party]}
      onClose={handleClose}
      onSuccess={onSend}
    />
  ) : (
    <SelectPartySlideout onSuccess={setParty} />
  );
};

const INVALID_STUDY_STATE_TEXTS: { [key in Study['state']]?: string } = {
  draft: 'still in draft',
  pending: 'pending funding',
  closed: 'closed'
};

const INCENTIVIZED_STATUSES: Participation['status'][] = ['booked', 'started', 'completed'];

const SelectPartySlideout: React.FC<{ onSuccess: (particiation: Participation) => void }> = ({ onSuccess }) => {
  const [selectedStudyId, setSelectedStudyId] = useState<number | undefined>();

  const { data: selectedStudy } = api.useGetStudyQuery(selectedStudyId ?? skipToken);

  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [study, setStudy] = useState<Study>();
  const [fundingModal, setFundingModal] = useState<boolean>(false);
  const [candidate, setCandidate] = useState<Candidate>();
  const [parties, setParties] = useState<Participation[]>([]);

  useEffect(() => {
    if (selectedStudy) {
      setStudy(selectedStudy);
    }
  }, [selectedStudy]);

  const canFund = usePermission('fundStudy')();

  const partiesByCustomerId = useMemo(
    () =>
      parties.reduce((m, party) => {
        m[party.customer_id] ||= [];
        m[party.customer_id].push(party);
        return m;
      }, {}),
    [parties]
  );

  const activeParties: Record<number, Participation> = useMemo(() => {
    return !candidate?.id
      ? {}
      : [candidate].reduce((m, { id }) => {
          const parties = partiesByCustomerId[id] || [];

          const validParty = parties.find(({ status }) => INCENTIVIZED_STATUSES.includes(status));
          m[id] = validParty || parties[0];
          return m;
        }, {});
  }, [candidate?.id, partiesByCustomerId]);

  useEffect(() => {
    if (study?.id) {
      getParticipations(study.id).then((parties) => {
        setParties(parties);
      });
    } else {
      setParties([]);
    }
  }, [study?.id]);

  async function handleNewCandidate({ text }) {
    if (text && text.match(/@/)) {
      setCandidate({ email: text } as Candidate);
    } else {
      setCandidate({ name: text } as Candidate);
    }
  }

  async function handleNewStudy({ title }) {
    setStudy({ title, style: 'video_call' } as Study);
  }

  async function fetchAndSetStudy({ id }) {
    setSelectedStudyId(id);
  }

  function handleClose() {
    navigate('/incentives');
  }

  function createParties() {
    const parties: Participation[] = [];
    Promise.all(
      [candidate as any].map(async ({ id }) => {
        const party = activeParties[id];
        if (!party) {
          const resp = await createParticipation({
            projectId: study?.id.toString() as any,
            customerId: id.toString(),
            transition: 'complete',
            source: 'incentive'
          });
          parties.push(resp);
        } else if (INCENTIVIZED_STATUSES.includes(party.status)) {
          await parties.push(party);
        } else {
          await changeParticipationStatus(party.id, 'complete');
          parties.push(party);
        }
      })
    ).then(() => {
      setLoading(false);
      onSuccess(parties[0]);
    });
  }
  function handleFundingClose() {
    setFundingModal(false);
    setLoading(false);
  }

  async function handleFundingSuccess() {
    setFundingModal(false);
    setSelectedStudyId(study?.id);
    createParties();
  }

  async function handleSubmit() {
    setLoading(true);

    if (!hasFunds && canFund) {
      setFundingModal(true);
      return;
    }
    createParties();
  }

  const unallocated = Object.values(activeParties).reduce((m, p) => {
    if (p && INCENTIVIZED_STATUSES.includes(p.status)) {
      return m;
    } else {
      return m + (p?.incentive?.local_amount_in_cents as any) || (study?.incentive || 0) * 100;
    }
  }, 0);

  const allComplete = Object.values(activeParties).every((p) => p && p.status === 'completed');
  const isActive = study && study.state == 'active';
  const isPending = study && study.state == 'pending';
  const hasIncentives = study && study.incentive_method === 'tremendous';
  //
  const spareFunds = study && hasIncentives && study.funding.local_unallocated - unallocated;
  const hasFunds = study && hasIncentives ? (spareFunds as any) >= 0 : false;

  const validStudy = (isActive && hasFunds) || (canFund && (isActive || isPending));

  const canSubmit = candidate?.id && study?.id && validStudy;

  return (
    <>
      {fundingModal && (
        <FundStudyModal
          study={study as any}
          amountInCents={Math.abs(spareFunds as any)}
          onSuccess={handleFundingSuccess}
          onCancel={handleFundingClose}
        />
      )}
      <SlideOut
        size='xl'
        closeOnEsc={false}
        title='New Incentive'
        subtitle='We’ll email recipients a link to redeem their incentive.'
        onClose={fundingModal ? undefined : handleClose}
        renderFooter={() => (
          <div className='flex flex-row w-full space-x-6'>
            <Button primary onClick={handleSubmit} disabled={!canSubmit}>
              Next
            </Button>
          </div>
        )}
      >
        {loading && <Loading absolute />}
        <div className='p-6'>
          <FormGroup>
            <Label>To</Label>
            <CandidateSelector candidate={candidate as any} setCandidate={setCandidate} onNew={handleNewCandidate} />
          </FormGroup>
          {candidate && !candidate.id && (
            <Alert type='warning' bg='transparent' position='top' className='relative mb-4' border>
              <>
                <Text h='500' bold className='mb-2'>
                  Add new candidate
                </Text>
                <Text h='400' color='gray-500' className='mb-4'>
                  We require it so there’s a record of who the incentive was paid to and their name.
                </Text>
                <NewCandidate candidate={candidate} setCandidate={setCandidate as any} />
              </>
            </Alert>
          )}
          <FormGroup>
            <Label>Study</Label>
            <SubLabel>We’ll pre-populate the incentive information, or create a new study for easy tracking.</SubLabel>
            <StudySelector study={study as any} setStudy={fetchAndSetStudy} onNew={handleNewStudy} canFund={canFund} />
            {study && study.id && validStudy && (
              <div className='mt-4 mb-6'>
                <ValidStudyInfo study={study} />
                {candidate?.id && hasFunds && (
                  <Text h='400' bold className='mt-4'>
                    After sending this incentive the remaining balance will be {study.currencySymbol}
                    {moneyFormat((spareFunds as any) / 100)}.
                  </Text>
                )}
                {candidate?.id && !hasFunds && (
                  <Text h='400' bold className='mt-4'>
                    Before sending this incentive you will need to fund the study.
                  </Text>
                )}
              </div>
            )}
            {study && study.id && !validStudy && (
              <Alert type='error' className='mt-4'>
                <AlertHeading>Cannot send incentives for this study</AlertHeading>
                {!isActive && (
                  <AlertMessage>
                    This study is {INVALID_STUDY_STATE_TEXTS[study.state]}.{' '}
                    <AlertLink href={`/studies/${study.id}`}>View study</AlertLink>
                  </AlertMessage>
                )}
                {isActive && !hasIncentives && (
                  <AlertMessage>This study does not have incentives set up on Great Question.</AlertMessage>
                )}
                {isActive && hasIncentives && !hasFunds && !canFund && (
                  <AlertMessage>
                    This study does not have any available funds and you do not have permissions to fund it.{' '}
                    <AlertLink href={`/studies/${study.id}`}>View study</AlertLink>
                  </AlertMessage>
                )}
              </Alert>
            )}
          </FormGroup>
          {study && !study.id && (
            <Alert type='warning' bg='transparent' position='top' className='relative mb-4' border>
              <>
                <Text h='500' bold className='mb-2'>
                  Create new study
                </Text>
                <Text h='400' color='gray-500' className='mb-4'>
                  We require it so there’s a record of what the incentive is for.
                </Text>
                <NewStudy study={study} setStudy={setStudy} />
              </>
            </Alert>
          )}
          {canSubmit && Object.values(activeParties).length > 0 && !allComplete && (
            <Alert type='info' position='top' bg='transparent' border>
              <Text h='500' bold className='mb-2'>
                Our records indicate this participant hasn't completed
              </Text>
              <Text h='400' color='gray-500' className='mb-2'>
                We will automatically create completed participations or change their status to completed in order to
                pay them.
              </Text>
              <ul className='w-full mt-2 divide-y divide-gray-200'>
                {compact(Object.values(activeParties)).map((party) => (
                  <li key={`incentive_party_${party.id}`} className='flex flex-row items-center w-full space-x-4'>
                    <Text h='400' className='flex-grow'>
                      {party.name}({party.email})
                    </Text>
                    <ParticipationPill participation={party}></ParticipationPill>
                    <a target='_blank' href={`/studies/${study.id}#candidates/${party.customer_id}`}>
                      <ExpandSVG />
                    </a>
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </div>
      </SlideOut>
    </>
  );
};
