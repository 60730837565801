import * as React from 'react';
import { useContext, useState } from 'react';
import { GridContext } from '@components/CandidatesApp/CandidatesIndex/CandidatesListPage';
import { Button, Modal, Portal, Textarea } from '@components/common';
import { CellWrapper } from '@components/shared/GridTable/components/shared';
import { EditableCell } from '@components/shared/GridTable/components/inputs/types';
import { getStateKey, onSelectValue } from '@components/shared/GridTable/components/utils';
import { useOnEscape } from '@components/utils';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import Tippy from '@tippyjs/react';

export const FreeTextCell: EditableCell = (props) => {
  const { state, setState } = useContext(GridContext);

  const { value: initialValue, row, column, updateCellData } = props;

  const stateKey = getStateKey(row);

  const [value, setValue] = useState<string>(initialValue);
  const [editing, setEditing] = useState(false);
  const { allAttrs } = useCandidateAttrs();
  const currentAttr = allAttrs.find((attr) => attr.name === column.id.split('.')[1]);

  const cellValue = value !== initialValue ? value : state?.[stateKey]?.[column?.id] || initialValue || '';
  const previousValue = state?.[stateKey]?.[column?.id] || initialValue || '';

  const cancel = () => {
    setValue(previousValue);
    setEditing(false);
  };

  useOnEscape(() => {
    if (editing) {
      cancel();
    }
  }, [editing]);

  const save = async () => {
    setValue(value);
    setEditing(false);

    await onSelectValue({
      currentValue: value,
      initialValue,
      rowId: String(stateKey),
      setState,
      updateCellData,
      columnId: column.id
    });
  };

  const tippyContent = cellValue.split('\n').map((v, i) => <p key={v + i}>{v}</p>);

  if (!editing) {
    return (
      <CellWrapper
        wrapperClass='cursor-pointer hover:bg-indigo-50 hover:text-indigo-600 overflow-hidden'
        position='start'
        onClick={() => setEditing(true)}
      >
        <Tippy content={tippyContent}>
          <span>{cellValue}</span>
        </Tippy>
      </CellWrapper>
    );
  }

  return (
    <>
      <Portal id='app-main'>
        <Modal
          title={`Edit ${currentAttr?.name} for ${row.original.participation?.customer?.name || row.original.name}`}
          size='md'
          onClose={cancel}
          renderFooter={() => (
            <>
              <Button onClick={cancel}>Cancel</Button>
              <Button primary onClick={save}>
                Save
              </Button>
            </>
          )}
        >
          <Textarea value={cellValue} onChange={setValue} />
        </Modal>
      </Portal>
    </>
  );
};
