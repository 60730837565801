import pluralize from 'pluralize';
import React from 'react';

import { Text } from '@components/common';

import { CheckedSVG, GuestsSVG, UserProfileSVG } from '../eventPopupSvgs';

interface Props {
  guests: {
    name?: string;
    email: string;
    status: 'yes' | 'maybe' | 'no' | 'noreply';
  }[];
}

export const NylasEventGuests: React.FC<Props> = ({ guests }) => {
  return (
    <>
      <div className='flex items-center h-6'>
        <GuestsSVG />
        <Text h='400' className='ml-4'>
          {pluralize('guests', guests.length, true)}
        </Text>
      </div>

      <div className='ml-8 pb-6'>
        <div className='overflow-auto max-h-52 relative w-full mt-4 space-y-3'>
          {guests.map((guest) => (
            <div key={guest.email} className='flex items-center'>
              <div className='relative flex items-center justify-center w-4 h-4 rounded-full'>
                <UserProfileSVG />
                {guest.status === 'yes' && (
                  <div className='-right-1 absolute bottom-0 z-10 flex items-center justify-center w-2 h-2 bg-green-600 rounded-full'>
                    <CheckedSVG />
                  </div>
                )}
              </div>

              <Text h='400' truncate className='ml-4' key={guest.email}>
                {guest.email}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
