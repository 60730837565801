import { Select, SelectOption, Text } from '@components/common';
import React from 'react';
import { Label } from '@components/BrandingApp/components/shared';
import { CenterAlignSVG, RightAlignSVG, LeftAlignSVG } from '@components/svgs';

const options: SelectOption[] = [
  { label: 'Left-aligned', value: 'left', data: { icon: LeftAlignSVG } },
  { label: 'Center-aligned', value: 'center', data: { icon: CenterAlignSVG } },
  { label: 'Right-aligned', value: 'right', data: { icon: RightAlignSVG } }
];

const renderOption = ({ data, label }: SelectOption) => (
  <div className='flex flex-row items-center space-x-2'>
    {data && <data.icon />}
    <Text h='400'>{label}</Text>
  </div>
);

interface Props {
  edit: boolean;
  alignment: 'left' | 'center' | 'right';
  setAlignment: (v: 'left' | 'center' | 'right') => void;
}

export const AlignmentDropdown: React.FC<Props> = ({ edit, alignment, setAlignment }) => {
  const selected = options.find(({ value }) => value === alignment);

  return edit ? (
    <>
      {edit && <Label>Alignment</Label>}

      <Select
        options={options}
        className='w-full max-w-sm mt-2'
        ulClassName='divide-y divide-gray-200'
        overflowClass='visible'
        value={alignment}
        renderLabel={renderOption}
        renderSelected={renderOption}
        onChange={setAlignment}
      />
    </>
  ) : (
    <div className='flex space-x-3 items-center mt-6'>
      {selected && (
        <>
          <selected.data.icon />
          <div>{selected.label}</div>
        </>
      )}
    </div>
  );
};
