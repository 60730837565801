import { Skeleton as EditSkeleton } from 'components/StudiesApp/components/StudyDraft/components/Skeleton';
import * as React from 'react';

import { api } from '@api/reduxApi';
import { Skeleton as ShowSkeleton } from '@components/StudiesApp/components/StudyPublished/components/Skeleton';

interface Props {
  id: number;
  children: (study: Study, refetch: () => void) => React.ReactElement;
  type: 'show' | 'edit';
}

export const StudyLoader: React.FC<Props> = ({ id, children, type }) => {
  const { data, refetch } = api.useGetSimpleStudyQuery(id);

  if (data) {
    return children(data, refetch);
  }

  return type === 'show' ? <ShowSkeleton /> : <EditSkeleton />;
};
