import React from 'react';
import { Link } from 'react-router-dom';

import { UrnProps } from '../types';

export const Session: React.FC<UrnProps> = ({ children, id, params }) => {
  let href = `/sessions/${id}`;
  if (params?.start_ts) {
    href = href.concat(`?ts=${params.start_ts}`);
  }
  return (
    <Link className='hover:underline text-blue-500' to={href}>
      {children}
    </Link>
  );
};
