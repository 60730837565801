import React, { FC } from 'react';

import { Text } from '@components/common';
import { DraggableCard } from '@components/common/DraggableCard';
import { Preview as MarkdownPreview } from '@components/common/MarkdownEditor';

import * as Types from '../types';

import { CompletedStatus } from './CompletedStatus';

export const Card: FC<Types.ResponsiveCardProps> = ({
  blockKind,
  blockPosition,
  children,
  description,
  renderFooter,
  title,
  totalBlocks,
  isComplete
}) => {
  return (
    <DraggableCard
      boundary='parent'
      startPosition='bottom-right'
      width={467}
      prepend={() => isComplete && <CompletedStatus className='border-b border-gray-200'>Completed</CompletedStatus>}
      header={(isMinimized) => (
        <>
          {isMinimized ? (
            <Text className='text-custom-brand-secondary text-base' bold>
              {title}
            </Text>
          ) : (
            <div className='flex items-center space-x-4'>
              <Text className='text-custom-brand-secondary text-xs' uppercase bold>
                {blockKind}
              </Text>

              <Text className='text-custom-brand-secondary text-xs'>
                {blockPosition} / {totalBlocks}
              </Text>
            </div>
          )}
        </>
      )}
    >
      <section>
        <Text className='mb-2 text-base' bold>
          {title}
        </Text>

        {description && (
          <MarkdownPreview
            className='max-h-64 overflow-y-auto text-sm'
            components={{
              a: ({ children, href }) => (
                <a href={href} target='_blank'>
                  {children}
                </a>
              )
            }}
          >
            {description}
          </MarkdownPreview>
        )}
      </section>

      <div data-testid='question-card-content'>{children}</div>

      {renderFooter && <footer className='flex items-center justify-end mt-4'>{renderFooter()}</footer>}
    </DraggableCard>
  );
};
