import { Option } from '@components/common';
import { compact } from '@components/utils';

import { STYLES } from '../AddCandidateSlideOut';
import { FilterDefinition } from '../TableFilters/types';
import { buildCollectionAttribute } from './buildCollectionAttribute';
import { CollectionAttribute, GroupByOption, PartialCollection } from './types';

export type CollectionAttributes<D> = {
  all: CollectionAttribute<D>[];
  filters: FilterDefinition<D>[];
  groups: GroupByOption<D>[];
  sortOptions: Option[];
  groupOptions: Option[];
  columnOptions: Option[];
  find: (v: any) => CollectionAttribute<D> | undefined;
};

export function buildCollectionAttributes<D>(partials: PartialCollection<D>[]): CollectionAttributes<D> {
  const attrs = partials.map((a) => buildCollectionAttribute<D>(a));
  const find = attrs.find.bind(attrs);
  const groupable = attrs.filter(({ groupable }) => groupable);
  const filters = compact(attrs.filter(({ filterable }) => filterable).map(({ filter }) => filter));
  const groups = compact(groupable.map(({ group }) => group));
  return {
    all: attrs, //
    filters,
    groups,
    sortOptions: attrs
      .filter(({ sortable }) => sortable)
      .map((attr) => ({ label: attr.name, value: Object.keys(STYLES).includes(attr.id) ? STYLES[attr.id] : attr.id })),
    columnOptions: attrs.filter(({ viewable }) => viewable).map((attr) => ({ label: attr.name, value: attr.id })),
    groupOptions: attrs
      .filter(({ groupable }) => groupable)
      .map((attr) => ({ label: attr.name, value: attr.id }))
      .concat([
        {
          value: '',
          label: 'None'
        }
      ]),
    find
  };
}
