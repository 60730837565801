import React, { useState } from 'react';

import { api } from '@api/reduxApi';
import { Text, Toggle } from '@components/common';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { track } from '@components/tracking';
import { useAccount } from '@hooks/useAccount';

export const DataRetentionIndex: React.FC = () => {
  const { account } = useAccount();
  const { delete_data_after_six_months } = account;
  const [updateDataRetention, {}] = api.useUpdateDataRetentionMutation();

  const change = async (on) => {
    track('toggled_data_retention', { on });

    await updateDataRetention(on);
  };

  return (
    <div className='p-6 bg-white border border-gray-200 rounded-lg'>
      <div className='flex items-end justify-between mb-2'>
        <div className='flex items-center'>
          <Text h='700'>Data Retention</Text>
        </div>
      </div>
      <hr className='-mx-6' />
      <Text h='400' className='py-4'>
        Automatically delete all Recordings and Transcripts 6 months after they were uploaded to Great Question
      </Text>
      <RestrictedAction feature='data_retention'>
        {({ can, may }) => (
          <div className='flex flex-row items-center space-x-2'>
            <Toggle on={delete_data_after_six_months} onToggle={(on) => change(on)} disabled={!can || !may} />
            <Text h='400'>Automatically delete all Recordings and Transcripts after 6 months.</Text>
          </div>
        )}
      </RestrictedAction>
    </div>
  );
};
