import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut';
import React, { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Input, Text } from '@components/common';
import Tippy from '@tippyjs/react';
import cn from 'classnames';

const DEBOUNCE_RATE = 500;

export interface SearchInputProps {
  value?: string;
  onSearch: (value: string) => void;
  onEnd?: (value: string) => void;
  addDebounce?: boolean;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  autoFocus?: boolean;
}
export const SearchInput: React.FC<SearchInputProps> = ({
  className,
  value,
  onSearch,
  onEnd,
  addDebounce,
  placeholder = 'Search...',
  inputClassName,
  autoFocus
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const debouncedSearch = useDebouncedCallback((val) => {
    onEnd?.(val);
  }, DEBOUNCE_RATE);

  const handleKeyDown = async (e) => {
    const val = e.currentTarget.value;
    if (e.key === 'Enter') {
      e.currentTarget.blur();
      debouncedSearch.cancel();
      onSearch(val);
      onEnd?.(val);
    }
  };

  const handleChange = (val) => {
    onSearch(val);
    if (addDebounce) {
      debouncedSearch.callback(val);
    } else {
      onEnd?.(val);
    }
  };

  const { shortcut } = useKeyboardShortcut({
    keys: { F: true, meta: true },
    handler: () => {
      ref.current?.focus();
    }
  });

  return (
    <div className={cn(`flex flex-row`, className)}>
      <Tippy
        placement='top'
        arrow={false}
        content={
          <>
            Search{'  '}
            <Text h='400' className='inline' color='gray-400'>
              {shortcut}
            </Text>
          </>
        }
      >
        <div className='w-full max-w-lg'>
          <Input
            className={cn('xx-search', inputClassName)}
            autoFocus={autoFocus}
            ref={ref}
            type='search'
            name='search'
            aria-label='Search'
            onKeyDown={addDebounce ? handleKeyDown : undefined}
            value={value}
            onChange={handleChange}
            size='lg'
            icon='search'
            placeholder={placeholder}
            wrapperClass='max-w-lg w-full'
          />
        </div>
      </Tippy>
    </div>
  );
};
