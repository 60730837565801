import pluralize from 'pluralize';
import * as React from 'react';

import { Alert, AlertHeading, AlertMessage, Button } from '@components/common';

import { AlertProps } from '../types';
import { CalendarAlertSVG } from './svgs';

export const CalSlots: React.FC<AlertProps> = ({ slots, study, canAction }) => {
  const { no_slots, remaining } = slots;

  return (
    <Alert
      className='my-4'
      Icon={CalendarAlertSVG}
      type={no_slots ? 'error' : 'warning'}
      cta={
        canAction && (
          <Button spa href={`/studies/${study.id}/calendar`}>
            {' '}
            Edit Calendar
          </Button>
        )
      }
    >
      <>
        <AlertHeading>Open up more calendar slots</AlertHeading>
        <AlertMessage>
          <>
            Based on your current calendar availability, you don't have enough slots for the{' '}
            <b>{remaining ? remaining : ''} participants</b> you still want to schedule.
          </>
        </AlertMessage>
      </>
    </Alert>
  );
};
