import tinytime from 'tinytime';

import { GridTableTabs } from '@components/shared/GridTable/components/ColumnsDropdown';
import { browserTimezoneDesc } from '@components/shared/TimezonePicker';
import { StudyTabKey } from '@components/StudiesApp/components/StudyPublished/helpers/buildTabs';
import { CORE_ATTRS } from '@components/config';

import { PANEL_TAB_LABELS, TAB_LABELS } from './constants';

const dateFormat = tinytime('{dddd}, {MM} {DD}');
const timeFormat = tinytime('{h}:{mm} {a}');

type TimesArrayOption = {
  value: string;
  label: string;
  isPast: boolean;
};

export const hasInterviewRoom = (participation: Pick<Participation, 'style' | 'status'>): boolean => {
  const { style, status } = participation;

  if (style === 'unmoderated_test' && ['completed', 'no_show'].includes(status)) {
    return true;
  }

  return style === 'video_call' && ['booked', 'completed', 'no_show'].includes(status);
};

export const buildColumnTabs = (study: Study): GridTableTabs => {
  const withPreScreener = !!study.pre_screener?.fields?.length;
  const withSurveyScreener = !!study.survey_screener?.fields?.length;
  const testStudy = study.style === 'unmoderated_test';

  const coreAttrLabels = CORE_ATTRS.map(({ name }) => name);

  return {
    labels: {
      ...(withPreScreener && { preScreenerQuestions: 'Screener questions' }),
      ...(withSurveyScreener && { surveyScreenerQuestions: 'Survey questions' }),
      ...(withSurveyScreener && testStudy && { surveyScreenerQuestions: 'Test questions' }),
      studyFields: 'Study fields',
      candidateAttributes: 'Candidate attributes'
    },
    filterFunc: (value, tab) => {
      switch (tab) {
        case 'preScreenerQuestions':
          return value.startsWith('preScreenerField.');
        case 'surveyScreenerQuestions':
          return value.startsWith('surveyScreenerField.');
        case 'candidateAttributes':
          return value.startsWith('extra.') || coreAttrLabels.includes(value) || value === 'contact_access';
        case 'studyFields':
          return (
            !value.startsWith('extra.') &&
            !value.startsWith('preScreenerField.') &&
            !value.startsWith('surveyScreenerField.') &&
            !coreAttrLabels.includes(value) &&
            value !== 'contact_access'
          );
        default:
          return true;
      }
    }
  };
};

export const buildTabLabels: (style: Study['style']) => Record<StudyTabKey, string> = (style) => {
  if (style === 'panel') {
    return { ...TAB_LABELS, ...PANEL_TAB_LABELS };
  } else {
    return TAB_LABELS;
  }
};

export const getTimesGroupedByDay = (times: string[]): Record<string, TimesArrayOption[]> =>
  times.reduce((acc, time) => {
    const date = new Date(time);
    const currentDate = new Date();
    const day = dateFormat.render(date);
    const currentTz = browserTimezoneDesc().abbreviation;
    const option: TimesArrayOption = {
      value: time,
      label: `${timeFormat.render(date)} ${currentTz}`,
      isPast: date < currentDate
    };

    return { ...acc, [day]: [...(acc[day] || []), option] };
  }, {});
