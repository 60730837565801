import { Toast } from '@stores/toaster';

// get
export const failedGet = (): Toast => ({
  heading: 'Failed to load!',
  icon: 'error',
  text: 'Something went wrong. Please try again later.'
});

// delete
export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that eligibility rule. Please try again later.'
});

export const successDelete = (title: string): Toast => ({
  heading: 'Rule deleted',
  icon: 'success',
  text: `“${title}” rule has been deleted.`
});

// update
export const successUpdate = (title: string): Toast => ({
  heading: 'Rule updated',
  icon: 'success',
  text: `“${title}” rule has been updated.`
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating that eligibility rule. Please try again later.'
});

// create
export const successCreate = (title: string): Toast => ({
  heading: 'Rule created',
  icon: 'success',
  text: `“${title}” rule has been created.`
});

export const failedCreate = (): Toast => ({
  heading: 'Failed to create!',
  icon: 'error',
  text: 'Something went wrong creating that eligibility rule. Please try again later.'
});
