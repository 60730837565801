import qs from 'qs';

import { FigmaPrototypeTypes } from './types';

type Options = Pick<FigmaPrototypeTypes.IframeProps, 'hideHotspots' | 'hideUi' | 'nodeId' | 'scaling'>;

export const parseIframeUrl = (url: string, options: Options): string => {
  const prefix = 'https://www.figma.com/embed';

  const params = {
    embed_host: 'share',
    url: parsePrototypeUrl(url, options)
  };

  return `${prefix}?${qs.stringify(params)}`;
};

const parsePrototypeUrl = (url: string, options: Options): string => {
  const { hideUi, nodeId, scaling, hideHotspots } = options;

  const parsedUrl = new URL(url);
  const params = parsedUrl.searchParams;

  if (hideUi) {
    params.set('hide-ui', '1');
  }

  if (nodeId) {
    params.set('node-id', nodeId);
  }

  if (scaling) {
    params.set('scaling', scaling);
  }

  if (hideHotspots) {
    params.set('hotspot-hints', '0');
  }

  return `${parsedUrl.origin}${parsedUrl.pathname}?${params.toString()}`;
};
