import React from 'react';
import { Placement } from 'tippy.js';
import { Input, Text } from '@components/common';

interface Props {
  'data-placement': Placement;
  'data-reference-hidden'?: string;
  'data-escaped'?: string;
  info?: string;
  renderDestination?: () => JSX.Element;
  setValue?: (value: string) => void;
  value?: string;
  onBlur?: () => void;
  editable?: boolean;
}

export const CtaTooltip: React.FC<Props> = ({ editable, onBlur, setValue, value, renderDestination, info, ...rest }) => (
  <div contentEditable={false} className='pb-3 pt-4 bg-white rounded-md shadow-lg' {...rest} tabIndex={-1}>
    {editable && (
      <div className='px-4 mb-4'>
        <Text h='200' color='gray-500' bold className='mb-1'>
          LABEL
        </Text>
        <Input
          autoFocus
          onFocus={(e) => e.currentTarget.select()}
          className='w-full'
          type='text'
          value={value}
          onBlur={onBlur}
          onChange={setValue}
        />
      </div>
    )}
    <Text h='200' color='gray-500' bold className='px-4 mb-1'>
      DESTINATION
    </Text>
    {renderDestination?.()}
    {info && (
      <Text color='gray-500' className='py-3 px-4 pb-0 border-t border-gray-200' h='200'>
        {info}
      </Text>
    )}
  </div>
);
