import * as React from 'react';

import { Provider as ToastProvider } from '@components/stores/toaster';
import { renderComp } from '@components/utils';

import { AccountSwitcher } from './AccountSwitcher';

export const Wrapper = (props) => {
  return (
    <ToastProvider>
      <AccountSwitcher {...props} />
    </ToastProvider>
  );
};

export function mount(): void {
  renderComp('AccountSwitcherApp', Wrapper);
}
