import React from 'react';
import { Text } from 'components/common';
import { NoResponsesIndividualSVG } from './NoResponsesIndividualSVG';
import { NoResponsesSummarySVG } from './NoResponsesSummarySVG';
import cn from 'classnames';

interface Props {
  withBorders?: boolean;
  tab: 'individual' | 'summary';
}

export const ScreenerResponsesZDS: React.FC<Props> = ({ tab, withBorders }) => {
  return (
    <div
      className={cn(
        'relative bg-white p-6 flex flex-col items-center justify-center h-full',
        withBorders && 'border-gray-200 border rounded-md'
      )}
    >
      {tab === 'individual' ? <NoResponsesIndividualSVG /> : <NoResponsesSummarySVG />}
      <Text bold h='700' className='mt-6 mb-2'>
        No responses
      </Text>
      <Text color='gray-500'>None of the participants have added a response yet</Text>
    </div>
  );
};
