const getClosestTimestamp = (timestamps: number[] = [], goal: number) =>
  timestamps.reduce((prev, current) => (Math.abs(current - goal) < Math.abs(prev - goal) ? current : prev), 0);

const buildStyles = (rect: DOMRect, root: Element | undefined): Partial<CSSStyleDeclaration> => {
  if (!root) return {};

  const { top, left, width, height } = rect;
  const { top: rootTop, left: rootLeft } = root.getBoundingClientRect();

  return {
    width: `${width}px`,
    height: `${height + 4}px`,
    left: `${left - rootLeft - 2}px`,
    top: `${top - rootTop - 2}px`,
    zIndex: '1'
  };
};

type JumpToTs = (args: {
  ts: number;
  timestamps: number[];
  highlight: HTMLDivElement;
  root: Element | undefined;
  enableAutoScroll?: boolean;
  behavior?: ScrollBehavior;
}) => void;

export const jumpToTs: JumpToTs = ({ ts, timestamps, highlight, root, enableAutoScroll, behavior = 'smooth' }) => {
  const closest = getClosestTimestamp(timestamps, Math.floor(ts));

  const mark = document.querySelector(`[data-start-time='${closest}']`);

  if (mark) {
    Object.assign(highlight.style, buildStyles(mark.getBoundingClientRect(), root));

    if (enableAutoScroll) {
      highlight.scrollIntoView({ behavior, block: 'center', inline: 'start' });
    }
  }
};
