import { StepperProps } from '@components/shared/Stepper';
import { compact } from '@components/utils';

type Step = StepperProps['steps'][0];

interface BuildSteps {
  steps: Step[];
  currentStep: Step | undefined;
  nextHref: string;
}
export const buildSteps = (study: Study, planOptions: string[], currentPath: string): BuildSteps => {
  const steps: StepperProps['steps'] = compact([
    { href: '/plan', name: 'Plan' },
    planOptions.includes('screener') && { href: '/screener', name: 'Screener' },
    planOptions.includes('external_candidates') && { href: '/recruiting', name: 'Recruiting' },
    study.style === 'online_task' && { href: '/task', name: 'Task' },
    study.style === 'unmoderated_test' && { href: '/test', name: 'Test' },
    study.style === 'video_call' && { href: '/calendar', name: 'Calendar' },
    study.style === 'survey' && { href: '/survey', name: 'Survey' },
    study.style === 'panel' && { href: '/survey', name: 'Signup form' },
    study.state === 'draft' && { href: '/review', name: 'Review' }
  ]).map((s, i) => ({
    ...s,
    id: `${i + 1}`,
    status: 'upcoming',
    href: `/studies/${study.id}/edit${s.href}`
  }));

  const currentStep = steps.find((step) => currentPath.includes(step.href));
  // this loop does two things: figure out what the link to the next step is,
  // and color all the finished steps bold up until the current
  // one (like in the designs)
  let nextHref;
  for (let i = 0; i < steps.length; i++) {
    const crumb = steps[i];
    if (crumb === currentStep) {
      crumb.status = 'current';
      nextHref = steps[i + 1]?.href;
      break;
    }
    crumb.status = 'complete';
  }

  return {
    steps,
    currentStep,
    nextHref
  };
};
