import React from 'react';

import { BasicHeader, PageBody, PageLayout, PageLeft, PageRight } from './Layout';
import { Basic } from '@components/shared/Skeleton';
import { Circle } from '@components/shared/Skeleton/Circle';

export const PageSkeleton: React.FC = () => (
  <PageLayout>
    <BasicHeader>
      <div className='w-full'>
        <div className='flex flex-col tablet:flex-row tablet:items-center pb-2 mt-2 tablet:space-x-4'>
          <Basic width='368' height={40} containerClassName='flex-grow leading-0' />
          <div className='flex flex-grow space-x-4 tablet:justify-end'>
            <Circle inline width={40} />
            <Basic inline width={90} height={40} />
            <Basic inline width={90} height={40} />
          </div>
        </div>
        <Basic width='50%' height={16} containerClassName='flex-grow' />
      </div>
    </BasicHeader>
    <PageBody>
      <PageLeft className='w-1/2'>
        <Basic width='100%' height='100%' className='min-h-96' containerClassName='w-full min-h-96' />
        <div className='flex items-center space-x-4 my-3'>
          <Circle inline width={40} />
          <Basic inline width={120} height={24} />
        </div>
        <Basic width='100%' h='6' mb='3' />
        <Basic width='50%' h='6' mb='3' />
        <Basic width='30%' h='6' mb='3' />
      </PageLeft>
      <PageRight className='p-6'>
        <Basic width='100%' height='100%' containerClassName='w-full flex-grow' />
      </PageRight>
    </PageBody>
  </PageLayout>
);
