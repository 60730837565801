import { loadStripe } from '@stripe/stripe-js';

const stripeMeta = document.querySelector('meta[name="stripe-key"]');

const stripeKey = stripeMeta?.getAttribute('content');

if (!stripeKey) {
  throw new Error('Stripe key not found');
}
export const stripePromise = loadStripe(stripeKey);
