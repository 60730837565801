import React, { SVGProps } from 'react';

export const LockSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 14 14' fill='none'>
    <path
      d='M3.9375 5.6875V3.5C3.9375 2.68777 4.26016 1.90882 4.83449 1.33449C5.40882 0.760155 6.18777 0.4375 7 0.4375C7.81223 0.4375 8.59118 0.760155 9.16551 1.33449C9.73984 1.90882 10.0625 2.68777 10.0625 3.5V5.6875M2.1875 5.6875H11.8125V13.5625H2.1875V5.6875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 9.1875C6.95674 9.1875 6.91444 9.20033 6.87847 9.22437C6.8425 9.2484 6.81446 9.28257 6.7979 9.32254C6.78134 9.36251 6.77701 9.40649 6.78545 9.44893C6.79389 9.49136 6.81473 9.53034 6.84532 9.56093C6.87591 9.59152 6.91489 9.61236 6.95732 9.6208C6.99976 9.62924 7.04374 9.62491 7.08371 9.60835C7.12368 9.59179 7.15785 9.56376 7.18188 9.52778C7.20592 9.49181 7.21875 9.44952 7.21875 9.40625C7.21883 9.3775 7.21322 9.34902 7.20226 9.32245C7.19129 9.29587 7.17518 9.27173 7.15485 9.2514C7.13452 9.23107 7.11038 9.21496 7.0838 9.204C7.05723 9.19303 7.02875 9.18742 7 9.1875V9.1875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
