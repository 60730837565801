import React, { SVGProps } from 'react';

export const AskAiRainbowSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <circle cx='16' cy='16' r='16' fill='url(#paint0_linear_14_298)' />
    <g clipPath='url(#clip0_14_298)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2568 14.3161C16.9324 14.4356 17.4993 14.9666 17.6565 15.6968L17.6826 15.8185C18.2722 18.5569 22.1683 18.5816 22.783 15.8405L22.8148 15.6988C22.9742 14.9881 23.5178 14.4674 24.1714 14.3289V21.6558C24.1714 21.9892 24.0389 22.309 23.8032 22.5447C23.5675 22.7804 23.2477 22.9129 22.9143 22.9129H12.8571L7.82855 24.1701L9.0857 20.3986V10.3415C9.0857 10.0081 9.21815 9.68832 9.4539 9.45256C9.68966 9.2168 10.0094 9.08435 10.3428 9.08435H16.6279C16.5095 9.13528 16.3851 9.17282 16.2568 9.19551C13.4 9.70089 13.4001 13.8107 16.2568 14.3161Z'
        fill='white'
      />
      <path
        d='M24.1714 14.7415V21.6558C24.1714 21.9892 24.0389 22.309 23.8032 22.5447C23.5675 22.7804 23.2477 22.9129 22.9143 22.9129H12.8571L7.82855 24.1701L9.0857 20.3986V10.3415C9.0857 10.0081 9.21815 9.68832 9.4539 9.45256C9.68966 9.2168 10.0094 9.08435 10.3428 9.08435H16'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6401 12.1506C16.2057 12.0738 16.2057 11.4396 16.6401 11.3628C18.2139 11.0844 19.4656 9.86544 19.8072 8.27866L19.8334 8.15703C19.9274 7.72045 20.5386 7.71773 20.6365 8.15346L20.6683 8.29521C21.0224 9.8745 22.2745 11.0831 23.8439 11.3608C24.2805 11.438 24.2805 12.0754 23.8439 12.1526C22.2745 12.4303 21.0224 13.6389 20.6683 15.2182L20.6365 15.3599C20.5386 15.7957 19.9274 15.7929 19.8334 15.3564L19.8072 15.2347C19.4656 13.648 18.2139 12.429 16.6401 12.1506Z'
        fill='white'
      />
      <path
        d='M16.6401 12.1506C16.2057 12.0738 16.2057 11.4396 16.6401 11.3628C18.2139 11.0844 19.4656 9.86544 19.8072 8.27866L19.8334 8.15703C19.9274 7.72045 20.5386 7.71773 20.6365 8.15346L20.6683 8.29521C21.0224 9.8745 22.2745 11.0831 23.8439 11.3608C24.2805 11.438 24.2805 12.0754 23.8439 12.1526C22.2745 12.4303 21.0224 13.6389 20.6683 15.2182L20.6365 15.3599C20.5386 15.7957 19.9274 15.7929 19.8334 15.3564L19.8072 15.2347C19.4656 13.648 18.2139 12.429 16.6401 12.1506Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <linearGradient
        id='paint0_linear_14_298'
        x1='7.33333'
        y1='1.33333'
        x2='25.3333'
        y2='29.3333'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#12102F' />
        <stop offset='0.348343' stopColor='#EB00FF' />
        <stop offset='0.712271' stopColor='#FF7A00' />
        <stop offset='0.99' stopColor='#16BDCA' />
      </linearGradient>
      <clipPath id='clip0_14_298'>
        <rect width='17.6' height='17.6' fill='white' transform='translate(7.20001 7.20001)' />
      </clipPath>
    </defs>
  </svg>
);
