import React, { VFC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, SlideOut } from '@components/common';
import { Grid } from '@components/common/Grid';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import VideoPlayer from '@components/VideoPlayer';

import { useGetSearchQuery } from '../api';
import { TranscriptSection } from './TranscriptSection';

export const Artifacts: VFC = () => {
  const [activeArtifact, setActiveArtifact] = useState<AiSearchResult['data'] | null>(null);

  const { id } = useParams<{ id: string }>();

  const { data: search } = useGetSearchQuery({ id }, { skip: !id });

  if (!search) {
    return null;
  }

  return (
    <section>
      <Grid desktop={4} tablet={3} gap={6}>
        {search.results.map((result) => (
          <TranscriptSection
            key={result.data.id}
            text={result.data.text}
            title={result.data.title}
            studyTitle={result.data.study_title}
            moderatorId={result.data.moderator_id}
            onClick={() => setActiveArtifact(result.data)}
          />
        ))}
      </Grid>

      {activeArtifact && (
        <SlideOut
          size='xl'
          title={activeArtifact.title}
          onClose={() => setActiveArtifact(null)}
          renderFooter={() => (
            <div className='flex space-x-6'>
              <Button outline icon='fullPageView' target='_blank' href={activeArtifact.session_url}>
                View on full page
              </Button>
              <CopyURLButton outline className='p-6 ml-2' text={activeArtifact.session_url} icon='link' />
            </div>
          )}
        >
          <section className='p-4'>
            <VideoPlayer src={activeArtifact.stream_url} autoPlay />
          </section>
        </SlideOut>
      )}
    </section>
  );
};
