import { ButtonProps } from 'components/common/Button';

import * as React from 'react';
import { HTMLAttributes, MouseEventHandler, ReactElement, useRef } from 'react';

import { Button, Popper } from '@components/common';
import { useDeviceType } from '@hooks/useDeviceType';
import { Placement } from '@popperjs/core';
import { Offset } from '@components/common/Popper';
import { BulkAddDropdownContent } from '@components/shared/BulkAddDropdownContent';


export type HasTitle = { id: any; title: string | null; study?: Partial<Study> };

type Props<T extends HasTitle> = {
  disabled?: boolean;
  icon: ButtonProps['icon'];
  selectedCount: number;
  studyId?: number | null;
  onClose?: () => void;
  getQuery: () => {
    isLoading?: boolean;
    data?: T[];
  };
  handleAdd: (target: T) => void;
  hasCreateMode: boolean;
  onClickCreate?: () => void;
  onCreate?: (title: string) => void;
  isCreating?: boolean;
  artifactType: 'insight' | 'reel';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  renderModal?: (togglePopUp?: () => void) => React.ReactNode;
  medium?: boolean;
  primary?: boolean;
  renderButton?: (props: HTMLAttributes<HTMLButtonElement>) => ReactElement;
  popperPlacement?: Placement;
  popperOffset?: Offset;
  renderOnBodyRoot?: boolean;
  onlyContent?: boolean;
  hide?: () => void;
};
export const BulkAddDropdown = <T extends HasTitle>(props: Props<T>): React.ReactElement => {
  const {
    artifactType,
    getQuery,
    onClickCreate,
    onCreate,
    isCreating,
    handleAdd,
    hasCreateMode,
    disabled: propDisabled,
    icon,
    selectedCount,
    onClick,
    studyId,
    renderModal,
    medium = true,
    primary,
    renderButton,
    popperPlacement = 'bottom-start',
    popperOffset = [0, 8],
    renderOnBodyRoot,
    onlyContent,
    hide
  } = props;

  const { isLoading: isLoadingQuery, data } = getQuery();

  const { isMobile } = useDeviceType();

  const studyData = data?.filter((i) => i.study?.id === studyId);

  const inputRef = useRef<HTMLInputElement>(null);

  const disabled = propDisabled || isCreating;

  const renderContent = (closePopper?: () => void) => {
    return (
      <BulkAddDropdownContent<T>
        ref={inputRef}
        disabled={disabled}
        onCreate={onCreate}
        hasCreateMode={hasCreateMode}
        onClickCreate={onClickCreate}
        selectedCount={selectedCount}
        isLoading={isLoadingQuery}
        artifactType={artifactType}
        closePopper={closePopper}
        data={studyId ? studyData : data}
        handleAdd={(v) => {
          handleAdd(v);
          closePopper?.();
        }}
      />
    );
  };

  return (
    <>
      {onlyContent ? (
        renderContent(hide)
      ) : (
        <div className='relative inline text-left'>
          <Popper
            closeOnEscape
            closeOnClickOutside
            event='click'
            onOpen={() =>
              setTimeout(() => {
                inputRef.current?.focus();
              }, 100)
            }
            placement={popperPlacement}
            offset={popperOffset}
            strategy='absolute'
            renderOnBodyRoot={renderOnBodyRoot}
            zIndex={50}
            content={({ closePopper }) => (
              <div className='w-80 bg-white border border-gray-200 rounded-md shadow-lg'>
                {renderContent(closePopper)}
              </div>
            )}
          >
            {renderButton?.({ onClick }) || (
              <Button
                medium={medium}
                weight='normal'
                disabled={disabled}
                icon={icon}
                className='whitespace-nowrap'
                primary={primary}
                onClick={onClick}
              >
                {!isMobile ? `Add to ${artifactType}` : ''}
              </Button>
            )}
          </Popper>
        </div>
      )}
      {renderModal?.()}
    </>
  );
};
