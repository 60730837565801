import { Helper, Label } from 'components/StudiesApp/components/StudyDraft/pages/Plan/components/IncventivesOptions';
import * as React from 'react';
import { useState } from 'react';

// components
import { Card, CardTitle, FormGroup, Input, Loading } from '@components/common';
import { getChangeFn } from '@components/utils';

export const Task: React.FC<PageProps> = ({ study, onSave, saving }) => {
  const [link, setLink] = useState(study.external_url || '');
  const change = getChangeFn<Study>(study, onSave, saving);

  const onChange = (value) => {
    setLink(value);
    change?.('external_url', value);
  };

  const onKeyDown = (e) => e.key === 'Enter' && e.currentTarget.blur();

  return (
    <div className='px-page py-gutter'>
      <Card className='rounded'>
        <CardTitle className='pb-6'>Task</CardTitle>
        <FormGroup>
          <Label>Task link</Label>
          <Helper>This will be shared with candidates you invite to the study.</Helper>
          <Input
            type='text'
            className='w-96 px-4 py-2 border border-gray-200'
            value={link}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder='e.g. UsabilityHub.com/xyz'
          />
        </FormGroup>
      </Card>
      {saving && <Loading absolute />}
    </div>
  );
};
