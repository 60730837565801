import React, { SVGProps } from 'react';

export const BlogSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.88199 7H3.21533C2.93933 7 2.71533 6.776 2.71533 6.5C2.71533 6.224 2.93933 6 3.21533 6H4.88199C5.15799 6 5.38199 6.224 5.38199 6.5C5.38199 6.776 5.15799 7 4.88199 7Z'
      fill='currentColor'
    />
    <path
      d='M12.8818 7H7.88184C7.60584 7 7.38184 6.776 7.38184 6.5C7.38184 6.224 7.60584 6 7.88184 6H12.8818C13.1578 6 13.3818 6.224 13.3818 6.5C13.3818 6.776 13.1578 7 12.8818 7Z'
      fill='currentColor'
    />
    <path
      d='M4.88199 9.66699H3.21533C2.93933 9.66699 2.71533 9.44299 2.71533 9.16699C2.71533 8.89099 2.93933 8.66699 3.21533 8.66699H4.88199C5.15799 8.66699 5.38199 8.89099 5.38199 9.16699C5.38199 9.44299 5.15799 9.66699 4.88199 9.66699Z'
      fill='currentColor'
    />
    <path
      d='M12.8818 9.66699H7.88184C7.60584 9.66699 7.38184 9.44299 7.38184 9.16699C7.38184 8.89099 7.60584 8.66699 7.88184 8.66699H12.8818C13.1578 8.66699 13.3818 8.89099 13.3818 9.16699C13.3818 9.44299 13.1578 9.66699 12.8818 9.66699Z'
      fill='currentColor'
    />
    <path
      d='M4.88199 12.3335H3.21533C2.93933 12.3335 2.71533 12.1095 2.71533 11.8335C2.71533 11.5575 2.93933 11.3335 3.21533 11.3335H4.88199C5.15799 11.3335 5.38199 11.5575 5.38199 11.8335C5.38199 12.1095 5.15799 12.3335 4.88199 12.3335Z'
      fill='currentColor'
    />
    <path
      d='M12.8818 12.3335H7.88184C7.60584 12.3335 7.38184 12.1095 7.38184 11.8335C7.38184 11.5575 7.60584 11.3335 7.88184 11.3335H12.8818C13.1578 11.3335 13.3818 11.5575 13.3818 11.8335C13.3818 12.1095 13.1578 12.3335 12.8818 12.3335Z'
      fill='currentColor'
    />
    <path
      d='M14.1667 15.3337H1.83333C0.822 15.3337 0 14.5117 0 13.5003V2.50033C0 1.48899 0.822 0.666992 1.83333 0.666992H14.1667C15.178 0.666992 16 1.48899 16 2.50033V13.5003C16 14.5117 15.178 15.3337 14.1667 15.3337ZM1.83333 1.66699C1.374 1.66699 1 2.04099 1 2.50033V13.5003C1 13.9597 1.374 14.3337 1.83333 14.3337H14.1667C14.626 14.3337 15 13.9597 15 13.5003V2.50033C15 2.04099 14.626 1.66699 14.1667 1.66699H1.83333Z'
      fill='currentColor'
    />
    <path
      d='M15.5 4H0.5C0.224 4 0 3.776 0 3.5C0 3.224 0.224 3 0.5 3H15.5C15.776 3 16 3.224 16 3.5C16 3.776 15.776 4 15.5 4Z'
      fill='currentColor'
    />
  </svg>
);
