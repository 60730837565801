import * as React from 'react';

import { Button, Modal } from '@components/common';
import { humanize } from '@components/utils';
import { usePlan } from '@hooks/usePlan';

import { PERMISSIONS } from './constants';
import { ModalProps } from './types';

export const ConfirmModal: React.FC<ModalProps> = ({
  type,
  role,
  method,
  onSubmit,
  onCancel,
  newQuota,
  title = 'Confirm invitation',
  cta = 'Confirm & Send'
}) => {
  return (
    <Modal
      size='md'
      title={title}
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button link onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button primary onClick={() => onSubmit()}>
            {cta}
          </Button>
        </>
      )}
    >
      <p className='h500 mb-4 font-bold'>You understand that:</p>
      <ul className='ml-6 space-y-1 list-disc'>
        <li>
          They will be {type === 'Invitation' ? 'invited as an' : 'changed to an'} {humanize(role)}
        </li>
        {role === 'observer' && type == 'User' && method !== 'reactivate' && (
          <li>Any draft studies will be reassigned to you.</li>
        )}
        <li>{PERMISSIONS[role]}</li>
        {role === 'observer' && method === 'update' && (
          <li>As you are downgrading to observer, your billing will be reduced in the next cycle.</li>
        )}
        {role === 'observer' && (method === 'create' || method === 'reactivate') && (
          <li>You have unlimited observer seats under your current plan, so there will be no change to your billing</li>
        )}
        {role !== 'observer' && (
          <li>You have available seats under your current plan, so there will be no change to your billing</li>
        )}
        <li>After this change, you will have {newQuota === 0 ? 'no' : newQuota} creator seats remaining</li>
      </ul>
      <div className='flex flex-wrap items-center justify-end mt-6 space-x-4'></div>
    </Modal>
  );
};
