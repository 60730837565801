import React, { FC, useEffect, useState } from 'react';
import { UploadFileModal } from '@components/shared/UploadFileModal';
import { useDirectUpload } from '../hooks';
import { useBeforeunload } from 'react-beforeunload';
import { UPLOAD_ALERT } from '../constants';

interface Props {
  isOpen: boolean;
  onUpload: (signedId: string) => void;
  onClose: () => void;
}

export const ImportDocumentModal = ({ isOpen, onUpload, onClose }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const { signedId, isError, isLoading } = useDirectUpload({ file, skip: !file });

  const handleOnclose = () => {
    setFile(null);
    onClose();
  };

  const handleOnDrop = (files: File[]) => {
    const [file] = files;

    if (!file) return;

    setFile(file);
  };

  useBeforeunload(() => isLoading && UPLOAD_ALERT);

  useEffect(() => {
    if (signedId) {
      onUpload?.(signedId);
    }
  }, [signedId]);

  return (
    <UploadFileModal
      heading='Import document'
      uploading={isLoading}
      subheading={'Note: Font formatting won’t be imported. Only images bigger than 50x50 pixels will be imported. The import sometimes results in elements being out of place or used differently.'}
      open={isOpen}
      onClose={isLoading ? undefined : handleOnclose}
      onDrop={handleOnDrop}
      supportedFileTypes={['pdf']}
    />
  );
}
