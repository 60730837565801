import { Basic } from '@components/shared/Skeleton';
import * as React from 'react';
import { Circle } from '@components/shared/Skeleton/Circle';

export const ClipSkeleton = () => {
  return (
    <div className='px-6 py-3'>
      <div className='flex items-center space-x-2 mb-2'>
        <Circle width={20} h='5' />
        <Basic h='6' width='115px' />
      </div>
      <Basic h='28' width='full' />
    </div>
  );
};
