import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';

type UseMuxVideoDownloadUrl = {
  downloadUrl: string | undefined;
};
export const useMuxVideoDownloadUrl = (muxVideoId?: number | null, skip = false): UseMuxVideoDownloadUrl => {
  const [createDownloadUrl] = api.useCreateMuxDownloadUrlMutation();

  const [downloadUrl, setDownloadUrl] = useState<string>();

  useEffect(() => {
    if (muxVideoId && !skip) {
      createDownloadUrl({ muxVideoId })
        .unwrap()
        .then(({ download_url }) => setDownloadUrl(download_url))
        .catch();
    }
  }, [muxVideoId, skip]);

  return { downloadUrl };
};
