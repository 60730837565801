import React, { SVGProps } from 'react';

export const LeftAlignSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1491_1792)'>
      <path
        d='M12.2292 12.823H2.02083C2.02083 12.823 1.4375 12.823 1.4375 12.2397V9.89583C1.4375 9.89583 1.4375 9.3125 2.02083 9.3125H12.2292C12.2292 9.3125 12.8125 9.3125 12.8125 9.89583V12.2397C12.8125 12.2397 12.8125 12.823 12.2292 12.823Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.2292 6.6875H6.83333C6.83333 6.6875 6.25 6.6875 6.25 6.10417V3.76034C6.25 3.76034 6.25 3.177 6.83333 3.177H12.2292C12.2292 3.177 12.8125 3.177 12.8125 3.76034V6.10417C12.8125 6.10417 12.8125 6.6875 12.2292 6.6875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.5625 1.4375V14.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1491_1792'>
        <rect width='14' height='14' fill='white' transform='translate(1 1)' />
      </clipPath>
    </defs>
  </svg>
);
