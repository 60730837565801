import React, { SVGProps } from 'react';

export const EmailTemplateIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} fill='none' height='71' viewBox='0 0 80 71' width='80' xmlns='http://www.w3.org/2000/svg'>
      <clipPath id='a'>
        <path d='m0 0h80v70.303h-80z' />
      </clipPath>
      <g clipPath='url(#a)'>
        <path
          d='m79.3625 69.2977c-.1398.1953-.2809.3893-.4207.5818-.4017.2768-.8783.4245-1.3661.4235h-75.15152c-.46608.0004-.92229-.1343-1.31337-.3878-.129791-.1712-.258119-.3437-.38791-.5163l.129791-.0713 38.044909-21.088c.3376-.1867.7169-.2849 1.1027-.2854s.7654.0967 1.1034.2825l38.1304 20.9897z'
          fill='#5850ec'
        />
        <path
          d='m77.5758 24.9554-32.9724-19.04172c-1.3868-.8009-2.9598-1.22292-4.5613-1.22371s-3.175.41968-4.5626 1.21921l-33.05526 19.04622c-.64267.001-1.25875.2567-1.71319.7111-.454437.4544-.71014085 1.0705-.71105 1.7132v40.4991c.00074878.6427.2564 1.2589.710871 1.7134.454469.4544 1.070649.7101 1.713369.7108h75.15156c.6426-.0011 1.2586-.2568 1.7129-.7112.4544-.4544.7102-1.0704.7113-1.713v-40.4991c-.0009-.6427-.2566-1.2588-.7111-1.7132-.4544-.4545-1.0705-.7102-1.7131-.7111zm2.139 42.9234c-.0014.5669-.2272 1.1101-.628 1.511-.4009.4008-.9442.6266-1.511.628h-75.15156c-.56699-.001-1.11047-.2267-1.511398-.6276-.400926-.4009-.626617-.9444-.627637-1.5114v-40.4991c.00069-.5671.226274-1.1108.627272-1.5118.400993-.401.944673-.6266 1.511763-.6273l33.05526-19.04621c1.3876-.79953 2.9611-1.22 4.5626-1.21921s3.1745.42281 4.5613 1.2237l32.9724 19.04172c.5671.0007 1.1107.2263 1.5117.6273s.6266.9447.6273 1.5118z'
          fill='#6b7280'
        />
        <path
          d='m40.0428 47.8688c-.2484.0009-.4951-.0405-.7296-.1224l-.0431-.0152-.0088-.0959c-.0268.0132-.054.0259-.0811.0379l-.0287.0126-.0287-.0127c-.0601-.0264-.1198-.0563-.1774-.0887l-26.2537-14.7527v-30.12226c.0007-.69944.2789-1.37.7735-1.864582.4946-.494578 1.1652-.7727772 1.8646-.7735623h49.3405c.6994.0007851 1.37.2789843 1.8645.7735623.4946.494582.7728 1.165142.7736 1.864582v30.16076l-.0363.0205-26.1319 14.6937c-.3355.1871-.7133.285-1.0974.2844z'
          fill='#fff'
        />
        <path
          d='m40.0428 47.9401c-.2564.0009-.5111-.0419-.7532-.1264l-.0864-.0304-.0037-.0408-.048.0211-.0574-.0252c-.0622-.0273-.124-.0583-.1836-.0919l-26.2901-14.773v-30.16405c.0008-.71834.2865-1.40703.7944-1.914972.508-.507944 1.1967-.79366501 1.915-.794478h49.3405c.7183.000816 1.407.286537 1.9149.79448.508.50794.7937 1.19663.7945 1.91497v30.20245l-26.2046 14.7347c-.3461.193-.736.2941-1.1323.2935zm-.5723-.3674c.5242.1505 1.0868.0877 1.5649-.1748l26.0591-14.6528v-30.03565c-.0007-.64273-.2564-1.25892-.7108-1.713402-.4545-.454477-1.0707-.710119-1.7134-.710843h-49.3405c-.6427.000721-1.2589.256363-1.7134.710841-.4545.454474-.7101 1.070674-.7108 1.713404v29.99705l1.7097.9602 24.435 13.7313c.0333.0187.067.0363.1013.0528.0342-.0165.0679-.0341.1011-.0527l.192-.1082z'
          fill='#6b7280'
        />
        <path
          d='m79.3684 26.0078-10.3486 5.8182-1.7826 1.0025-26.1319 14.6938c-.3248.1811-.6906.2759-1.0624.2752-.2403.0007-.4789-.0393-.7058-.1184-.0632-.0201-.1251-.0439-.1855-.0713-.0584-.0256-.1154-.0541-.171-.0855l-24.435-13.7312-1.7825-1.0011-12.109823-6.805c-.032928-.0189-.057082-.05-.067224-.0865-.010141-.0366-.005453-.0757.013052-.1088.018554-.0329.049397-.0571.085754-.0672.036356-.0102.075257-.0055.108166.013l11.970075 6.7265 1.7825 1.0011 24.5747 13.8096c.0815.0458.1664.0854.2538.1184.0371.0143.0756.0271.1141.0385.2441.0749.5008.0996.7547.0727.2539-.027.4998-.1051.7227-.2296l26.2716-14.7707 1.7826-1.0025 10.2088-5.7398c.0163-.0092.0343-.015.0528-.0173.0186-.0022.0374-.0008.0554.0042.018.0051.0349.0136.0496.0251.0147.0116.027.0259.0362.0422s.015.0342.0173.0528c.0022.0186.0008.0374-.0042.0554-.0051.018-.0136.0348-.0251.0496-.0116.0147-.0259.027-.0422.0361z'
          fill='#6b7280'
        />
        <path
          d='m23.3976 9.41177h-3.9929c-.3025 0-.5927-.12019-.8066-.33413-.214-.21395-.3342-.50412-.3342-.80669 0-.30256.1202-.59273.3342-.80668.2139-.21394.5041-.33414.8066-.33414h3.9929c.3026 0 .5927.1202.8067.33414.2139.21395.3341.50412.3341.80668 0 .30257-.1202.59274-.3341.80669-.214.21394-.5041.33413-.8067.33413z'
          fill='#5850ec'
        />
        <path
          d='m28.1035 13.262h-8.6988c-.3025 0-.5927-.1202-.8066-.3341-.214-.2139-.3342-.5041-.3342-.8067 0-.3025.1202-.5927.3342-.8067.2139-.2139.5041-.3341.8066-.3341h8.6988c.3026 0 .5927.1202.8067.3341.2139.214.3341.5042.3341.8067 0 .3026-.1202.5928-.3341.8067-.214.2139-.5041.3341-.8067.3341z'
          fill='#e6e6e6'
        />
        <path
          d='m52.4065 23.5294h-24.6702c-.3026 0-.5928-.1202-.8067-.3341-.2139-.214-.3341-.5041-.3341-.8067s.1202-.5927.3341-.8067c.2139-.2139.5041-.3341.8067-.3341h24.6702c.3026 0 .5927.1202.8067.3341.2139.214.3341.5041.3341.8067s-.1202.5927-.3341.8067c-.214.2139-.5041.3341-.8067.3341z'
          fill='#5850ec'
        />
        <g fill='#e6e6e6'>
          <path d='m54.6881 27.9501h-29.3761c-.3026 0-.5928-.1202-.8067-.3341-.214-.214-.3342-.5042-.3342-.8067 0-.3026.1202-.5928.3342-.8067.2139-.214.5041-.3341.8067-.3341h29.3761c.3025 0 .5927.1201.8067.3341.2139.2139.3341.5041.3341.8067 0 .3025-.1202.5927-.3341.8067-.214.2139-.5042.3341-.8067.3341z' />
          <path d='m54.6881 32.2282h-29.3761c-.3026 0-.5928-.1202-.8067-.3342-.214-.2139-.3342-.5041-.3342-.8067 0-.3025.1202-.5927.3342-.8066.2139-.214.5041-.3342.8067-.3342h29.3761c.3025 0 .5927.1202.8067.3342.2139.2139.3341.5041.3341.8066 0 .3026-.1202.5928-.3341.8067-.214.214-.5042.3342-.8067.3342z' />
        </g>
      </g>
    </svg>
  );
};
