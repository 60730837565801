import { Button, Modal, ModalHeading, Text } from 'components/common';
import * as React from 'react';

import { Toast, useToaster } from '@stores/toaster';

const discardedChanges = (text): Toast => ({
  heading: 'Changes discarded',
  icon: 'success',
  text
});

interface Props {
  setEdit: (v: boolean) => void;
  cancelModal: boolean;
  onClose: () => void;
  setIsDirty?: (v: unknown) => void;
  discardMessage: string;
  onDiscard?: () => void;
}

export const CancelEditModal: React.FC<Props> = ({
  setEdit,
  cancelModal,
  onClose,
  setIsDirty,
  discardMessage,
  onDiscard: parentOnDiscard
}) => {
  const showToast = useToaster();

  const onDiscard = () => {
    setEdit(false);
    setIsDirty?.(false);
    showToast(discardedChanges(discardMessage));
    onClose();
    if (parentOnDiscard) parentOnDiscard();
  };

  if (!cancelModal) return null;

  return (
    <Modal
      icon='danger'
      onClose={onClose}
      renderFooter={() => (
        <div className='flex flex-row justify-end w-full space-x-4'>
          <Button onClick={onClose}>Back to editing</Button>
          <Button danger onClick={onDiscard}>
            Discard changes
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-4'>Unsaved changes</ModalHeading>
      <section className='mt-4 mb-6'>
        <Text h='400'>You have unsaved changes. Are you sure?</Text>
      </section>
    </Modal>
  );
};
