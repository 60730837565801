import classNames from 'classnames';
import { ChevronDownSVG, ChevronUpSVG } from 'components/svgs';
import { useDeviceType } from 'hooks/useDeviceType';
import pluralize from 'pluralize';
import * as React from 'react';
import { useState } from 'react';
import { followCursor } from 'tippy.js';

import HeadlessTippy from '@tippyjs/react/headless';

import { PLAN_FEATURES, PLAN_LIMITS, PLAN_SUPPORT_FEATURES } from '../constants';

type RenderFeature = (feature: string | Record<'title' | 'popupText' | 'popupImg', string>) => React.ReactElement;

const renderFeature: RenderFeature = (feature) => {
  const title = typeof feature === 'string' ? feature : feature.title;
  const popupText = typeof feature === 'string' ? null : feature?.popupText;

  return popupText ? (
    <HeadlessTippy
      followCursor
      placement='bottom-start'
      plugins={[followCursor]}
      render={(attrs) => (
        <div {...attrs} className='w-44 p-2 m-4 text-xs bg-white rounded-md shadow-lg'>
          {popupText}
        </div>
      )}
    >
      <li
        key={title}
        style={{ textDecoration: 'underline dotted', textUnderlineOffset: '4px' }}
        className='text-xs cursor-pointer'
      >
        {title}
      </li>
    </HeadlessTippy>
  ) : (
    <li key={title} className='text-xs'>
      {title}
    </li>
  );
};

const ListItem = ({ size = 'xs', children }) => {
  return <li className={`text-${size}`}>{children}</li>;
};

const renderLimit = (plan, limit, size = 'xs') => {
  // limit would never be 0 right? number > 0 or null
  const renderLimitTitle = () => {
    if (limit === 'storage' && !limit) {
      return PLAN_LIMITS[limit].slice(3);
    } else {
      return PLAN_LIMITS[limit];
    }
  };

  return (
    <ListItem key={limit} size={size}>
      {plan.limits[limit]
        ? `${plan.limits[limit].toLocaleString()} ${PLAN_LIMITS[limit]}`
        : `Unlimited  ${renderLimitTitle()}`}
    </ListItem>
  );
};

const renderSupportFeature = (feature) => {
  return <ListItem key={feature}>{PLAN_SUPPORT_FEATURES[feature]}</ListItem>;
};

interface Props {
  recommended?: boolean;
  plan: Plan;
  expanded?: boolean;
  previousTierName?: string | null;
}
export const PlanUsage: React.FC<Props & { ulClass?: string; size?: 'xs' | 'sm' | 'md' }> = ({
  plan,
  size = 'xs',
  ulClass = '',
  expanded = false
}) => {
  const viewers = plan.limits.observer_seats;

  return (
    <ul className={'space-y-2 ' + ulClass}>
      {expanded && (
        <ListItem size={size} key='observer_seats'>
          {viewers ? pluralize('viewer', viewers, true) : 'Unlimited viewers'}
        </ListItem>
      )}

      {plan.self_serve && Object.keys(PLAN_LIMITS).map((limit) => renderLimit(plan, limit, size))}
      {!plan.self_serve && (
        <>
          <ListItem key='interviews' size={size}>
            Custom interview hours / month
          </ListItem>
          <ListItem key='candidates' size={size}>
            Custom research participant panel
          </ListItem>
        </>
      )}
    </ul>
  );
};

export const PlanFeatures: React.FC<Props> = ({ plan, recommended, previousTierName, expanded = false }) => {
  const { isMobile } = useDeviceType();

  const [open, setOpen] = useState(false);

  return (
    <>
      {plan.display_features.length > 0 && (
        <div className='p-4 pb-6'>
          <p className='mb-2 text-xs font-bold'>
            {expanded || !previousTierName ? 'Features' : `Everything in ${previousTierName}, plus:`}
          </p>
          <ul className='space-y-2'>
            {plan.display_features.map((feature) => {
              if (PLAN_FEATURES[feature]) {
                return renderFeature(PLAN_FEATURES[feature]);
              }
              return null;
            })}
          </ul>
        </div>
      )}
      <div className='p-4 pb-6'>
        <p className='mb-2 text-xs font-bold'>Usage</p>
        <PlanUsage plan={plan} recommended={recommended} previousTierName={previousTierName} expanded={expanded} />
      </div>
      {(open || !isMobile) && (
        <div className='p-4 pb-6'>
          <p className='mb-2 text-xs font-bold'>Support and security</p>
          <ul className='space-y-2'>{plan.support_features.map((feature) => renderSupportFeature(feature))}</ul>
        </div>
      )}

      {isMobile && (
        <div
          className={classNames(
            'w-full cursor-pointer flex items-center justify-center font-medium text-indigo-600 text-sm leading-5 p-4 border-t space-x-2',
            recommended ? 'border-indigo-600' : 'border-gray-200'
          )}
          onClick={() => setOpen(!open)}
        >
          <span>SHOW {open ? 'LESS' : 'MORE'} DETAILS</span>
          {open ? <ChevronUpSVG /> : <ChevronDownSVG />}
        </div>
      )}
    </>
  );
};
