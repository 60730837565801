import { api } from '@api/reduxApi';

const onboardingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    onboardingInfo: builder.mutation<void, Record<string, any>>({
      query: (body) => ({
        url: '/users/onboarding_info',
        method: 'PUT',
        body: { onboarding_info: body }
      })
    })
  })
});

const integrationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrations: builder.query<Integration[], void>({
      query: () => `users/integrations`,
      transformResponse: (resp: any) => resp.data.map(({ attributes }) => attributes as Integration)
    })
  })
});

const connectedAccountsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateConnectedAccount: builder.mutation<void, Partial<ConnectedAccount>>({
      query: (account) => ({
        url: `users/connected_accounts/${account.id}`,
        method: 'PUT',
        body: { connected_account: account }
      })
    }),
    destroyConnectedAccount: builder.mutation<void, number>({
      query: (id) => ({
        url: `users/connected_accounts/${id}`,
        method: 'DELETE'
      })
    })
  })
});

export const { useOnboardingInfoMutation } = onboardingApi;
export const { useGetIntegrationsQuery } = integrationsApi;
export const { useUpdateConnectedAccountMutation, useDestroyConnectedAccountMutation } = connectedAccountsApi;
