import { useContext } from 'react';

import { VideoSeekBarContext } from '../context/videoSeekBarContext';

import { VideoSeekBar } from './useVideoSeekBar';

export const useVideoSeekBarContext = () => {
  const videoSeekBarContext = useContext<VideoSeekBar | undefined>(VideoSeekBarContext);

  if (videoSeekBarContext === undefined)
    throw new Error("Don't forget to wrap your component with <VideoSeekBarProvider>!");

  return videoSeekBarContext;
};
