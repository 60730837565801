export const HEADINGS: Partial<Record<StudyMessageEvent, string>> = {
  ad_hoc: 'Ad hoc',
  invite: 'Participation invite',
  screener: 'Screener',
  invited_reminder: 'Invitation reminder',
  booked_reminder: 'Interview reminder',
  booked: 'Scheduled confirmation',
  cancel_interview: 'Cancel',
  reschedule_request: 'Reschedule'
};

export const SUBHEADINGS: Record<StudyMessageEvent, string> = {
  ad_hoc: 'When you want to send an ad hoc message to participants.',
  invite: 'When you send the invite to someone this is the email template you’ll start from.',
  screener: 'When you send the screener to someone this is the email template you’ll start from.',
  invited_reminder: 'When you want to remind someone who didn’t respond to your initial invite.',
  booked: 'When a candidate schedules an interview.',
  booked_reminder: 'When you want to remind someone about an upcoming scheduled interview.',
  started_reminder: 'When you want to remind someone they started the task but did not finish.',
  reschedule_request: 'When you want to ask someone to reschedule a call.',
  cancel_task: 'When you want to send a cancellation notice to participants.',
  cancel_interview: 'When you want to send a cancellation notice to participants.',
  welcome: 'When you want to welcome someone to your panel.',
  thanks: 'When you send a thank you note with an incentive.'
};
