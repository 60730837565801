import React, { SVGProps } from 'react';

export const CandidatesRecommendedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='120' height='100' viewBox='0 0 120 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='119' height='25' rx='2.5' fill='white' stroke='#9FA6B2' />
    <g clipPath='url(#clip0_553_1324)'>
      <path
        d='M18.6569 7.34314C17.1459 5.83215 15.1368 5 13 5C10.863 5 8.85413 5.83215 7.34314 7.34314C5.83215 8.85413 5 10.863 5 13C5 15.1368 5.83215 17.1459 7.34314 18.6569C8.85413 20.1678 10.863 21 13 21C15.1368 21 17.1459 20.1678 18.6569 18.6569C20.1678 17.1459 21 15.1368 21 13C21 10.863 20.1678 8.85413 18.6569 7.34314ZM9.01062 18.8246C9.34521 16.8981 11.0146 15.4771 13 15.4771C14.9855 15.4771 16.6548 16.8981 16.9894 18.8246C15.8535 19.605 14.4792 20.0625 13 20.0625C11.5208 20.0625 10.1465 19.605 9.01062 18.8246ZM10.4563 11.9958C10.4563 10.5931 11.5974 9.45215 13 9.45215C14.4026 9.45215 15.5437 10.5933 15.5437 11.9958C15.5437 13.3984 14.4026 14.5396 13 14.5396C11.5974 14.5396 10.4563 13.3984 10.4563 11.9958ZM17.8014 18.1743C17.5491 17.2775 17.048 16.4637 16.3456 15.8281C15.9147 15.4381 15.4242 15.1304 14.8969 14.9135C15.85 14.2917 16.4813 13.2162 16.4813 11.9958C16.4813 10.0763 14.9196 8.51465 13 8.51465C11.0804 8.51465 9.5188 10.0763 9.5188 11.9958C9.5188 13.2162 10.1501 14.2917 11.1031 14.9135C10.5759 15.1304 10.0853 15.438 9.65442 15.828C8.95215 16.4636 8.45093 17.2773 8.19861 18.1742C6.80884 16.8835 5.9375 15.0417 5.9375 13C5.9375 9.10571 9.10571 5.9375 13 5.9375C16.8943 5.9375 20.0625 9.10571 20.0625 13C20.0625 15.0419 19.1912 16.8837 17.8014 18.1743Z'
        fill='currentColor'
      />
    </g>
    <rect x='26' y='7' width='85' height='4' fill='#9FA6B2' />
    <rect x='26' y='15' width='50' height='4' fill='#9FA6B2' />
    <rect x='0.5' y='37.5' width='119' height='25' rx='2.5' fill='white' stroke='#E5E7EB' />
    <g clipPath='url(#clip1_553_1324)'>
      <path
        d='M18.6569 44.3431C17.1459 42.8322 15.1368 42 13 42C10.863 42 8.85413 42.8322 7.34314 44.3431C5.83215 45.8541 5 47.863 5 50C5 52.1368 5.83215 54.1459 7.34314 55.6569C8.85413 57.1678 10.863 58 13 58C15.1368 58 17.1459 57.1678 18.6569 55.6569C20.1678 54.1459 21 52.1368 21 50C21 47.863 20.1678 45.8541 18.6569 44.3431ZM9.01062 55.8246C9.34521 53.8981 11.0146 52.4771 13 52.4771C14.9855 52.4771 16.6548 53.8981 16.9894 55.8246C15.8535 56.605 14.4792 57.0625 13 57.0625C11.5208 57.0625 10.1465 56.605 9.01062 55.8246ZM10.4563 48.9958C10.4563 47.5931 11.5974 46.4521 13 46.4521C14.4026 46.4521 15.5437 47.5933 15.5437 48.9958C15.5437 50.3984 14.4026 51.5396 13 51.5396C11.5974 51.5396 10.4563 50.3984 10.4563 48.9958ZM17.8014 55.1743C17.5491 54.2775 17.048 53.4637 16.3456 52.8281C15.9147 52.4381 15.4242 52.1304 14.8969 51.9135C15.85 51.2917 16.4813 50.2162 16.4813 48.9958C16.4813 47.0763 14.9196 45.5146 13 45.5146C11.0804 45.5146 9.5188 47.0763 9.5188 48.9958C9.5188 50.2162 10.1501 51.2917 11.1031 51.9135C10.5759 52.1304 10.0853 52.438 9.65442 52.828C8.95215 53.4636 8.45093 54.2773 8.19861 55.1742C6.80884 53.8835 5.9375 52.0417 5.9375 50C5.9375 46.1057 9.10571 42.9375 13 42.9375C16.8943 42.9375 20.0625 46.1057 20.0625 50C20.0625 52.0419 19.1912 53.8837 17.8014 55.1743Z'
        fill='#E5E7EB'
      />
    </g>
    <rect x='26' y='44' width='85' height='4' fill='#E5E7EB' />
    <rect x='26' y='52' width='50' height='4' fill='#E5E7EB' />
    <rect x='0.5' y='74.5' width='119' height='25' rx='2.5' fill='white' stroke='#E5E7EB' />
    <g clipPath='url(#clip2_553_1324)'>
      <path
        d='M18.6569 81.3431C17.1459 79.8322 15.1368 79 13 79C10.863 79 8.85413 79.8322 7.34314 81.3431C5.83215 82.8541 5 84.863 5 87C5 89.1368 5.83215 91.1459 7.34314 92.6569C8.85413 94.1678 10.863 95 13 95C15.1368 95 17.1459 94.1678 18.6569 92.6569C20.1678 91.1459 21 89.1368 21 87C21 84.863 20.1678 82.8541 18.6569 81.3431ZM9.01062 92.8246C9.34521 90.8981 11.0146 89.4771 13 89.4771C14.9855 89.4771 16.6548 90.8981 16.9894 92.8246C15.8535 93.605 14.4792 94.0625 13 94.0625C11.5208 94.0625 10.1465 93.605 9.01062 92.8246ZM10.4563 85.9958C10.4563 84.5931 11.5974 83.4521 13 83.4521C14.4026 83.4521 15.5437 84.5933 15.5437 85.9958C15.5437 87.3984 14.4026 88.5396 13 88.5396C11.5974 88.5396 10.4563 87.3984 10.4563 85.9958ZM17.8014 92.1743C17.5491 91.2775 17.048 90.4637 16.3456 89.8281C15.9147 89.4381 15.4242 89.1304 14.8969 88.9135C15.85 88.2917 16.4813 87.2162 16.4813 85.9958C16.4813 84.0763 14.9196 82.5146 13 82.5146C11.0804 82.5146 9.5188 84.0763 9.5188 85.9958C9.5188 87.2162 10.1501 88.2917 11.1031 88.9135C10.5759 89.1304 10.0853 89.438 9.65442 89.828C8.95215 90.4636 8.45093 91.2773 8.19861 92.1742C6.80884 90.8835 5.9375 89.0417 5.9375 87C5.9375 83.1057 9.10571 79.9375 13 79.9375C16.8943 79.9375 20.0625 83.1057 20.0625 87C20.0625 89.0419 19.1912 90.8837 17.8014 92.1743Z'
        fill='#E5E7EB'
      />
    </g>
    <rect x='26' y='81' width='85' height='4' fill='#E5E7EB' />
    <rect x='26' y='89' width='50' height='4' fill='#E5E7EB' />
    <defs>
      <clipPath id='clip0_553_1324'>
        <rect width='16' height='16' fill='white' transform='translate(5 5)' />
      </clipPath>
      <clipPath id='clip1_553_1324'>
        <rect width='16' height='16' fill='white' transform='translate(5 42)' />
      </clipPath>
      <clipPath id='clip2_553_1324'>
        <rect width='16' height='16' fill='white' transform='translate(5 79)' />
      </clipPath>
    </defs>
  </svg>
);
