import { configureStore } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({ baseUrl: '/api/v1/public/' });

export const api = createApi({
  reducerPath: 'public',
  baseQuery,
  endpoints: (builder) => ({
    createDailyRoom: builder.mutation<{ name: string }, { token: string }>({
      query: ({ token }) => ({
        url: `participations/${token}/daily/rooms`,
        method: 'POST'
      })
    })
  })
});

export const store = configureStore({
  reducer: { public: api.reducer },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), api.middleware]
});
