import React, { CSSProperties } from 'react';
import cn from 'classnames';
import Skeleton from 'react-loading-skeleton';

import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';

interface Props {
  rows?: number;
  rowClassName?: CSSProperties['className'];
}

export const HighlightMenuSkeleton = createPolymorphicComponent<Props, 'div'>(
  ({ as: Component = 'div', innerRef, rowClassName, rows = 1 }) => {
    return (
      <Component ref={innerRef} className='highlight-menu-skeleton'>
        {Array.from({ length: rows }).map((_, index) => (
          <div key={index} className={cn('w-full', rowClassName)}>
            <Skeleton className='bg-gray-50 h-4 rounded-full' />
          </div>
        ))}
      </Component>
    );
  }
);
