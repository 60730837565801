import * as React from 'react';

import { Basic } from '@components/shared/Skeleton';

export const Skeleton: React.FC = () => {
  return (
    <div className='relative flex flex-col flex-1 w-full h-full pt-16 bg-white'>
      <div className='tablet:absolute tablet:z-10 tablet:flex-row tablet:border-b top-0 flex flex-col items-center justify-center w-full p-4 text-center bg-white border-gray-200'>
        <div className='tablet:absolute tablet:mb-0 tablet:ml-10 left-0 mb-4'>
          <Basic h='8' width={32} />
        </div>
        <span className='text-xl font-bold text-gray-700'>
          <Basic h='8' width={620} />
        </span>
      </div>
      <div className='flex-1'>
        <div className='tablet:space-y-6 py-gutter flex flex-col items-center flex-1 space-y-2'>
          <Basic h='52' width={830} />
          <Basic h='72' width={830} />
          <Basic h='48' width={830} />
        </div>
      </div>
      <div className='flex-0 monitor:px-36 tablet:px-16 desktop:px-20 monitor:px-24 tablet:pb-10 tablet:border-0 tablet:items-end flex items-center justify-between w-full px-10 py-4 text-center bg-white border-t border-gray-200'>
        <p className='h400 capitalize'>* Required</p>
        <div className='inline-block'>
          <a href='greatquestion.co' className='xx-powered-by flex flex-col justify-center text-center'>
            <span className='text-xs font-light text-gray-500 uppercase'>Powered by</span>
            <img src='/temp-assets/logo.png' className='w-auto h-6' alt='Great Question' />
          </a>
        </div>
      </div>
    </div>
  );
};
