import { FormGroup, Label, PanelWrapper } from '../shared';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Select, Text, Toggle, Tooltip } from '@components/common';
import * as toasts from '../../../../toasts';
import { useToaster } from '@stores/toaster';
import { useUpdateStudyCalendarMutation } from '../../components/NylasCalendar/api';
import { CALL_BUFFER_OPTIONS } from '../../options';
import { DurationInput } from '@components/common/DurationInput';
import { SchedulingDropdown } from '../../components/NylasCalendar/components';
import { useDebouncedCallback } from 'use-debounce';
import { track } from '@components/tracking';
import { bookingLimitOptions } from './utils';

interface Props {
  onClose: () => void;
  study: Study;
  onSave: (study: { id: number } & Partial<Study>) => void;
  readOnly?: boolean;
  timeSlot: RecurringTimeSlot | OneOffTimeSlot;
  calendar: StudyCalendar | undefined;
  changeTimeSlot: (attr: string, val: string | number | number[]) => void;
}

export const AdditionalOptionsPanel: React.FC<Props> = ({
  readOnly,
  onClose,
  study,
  onSave,
  timeSlot,
  calendar,
  changeTimeSlot
}) => {
  const [minBookingNotice, setMinBookingNotice] = useState(study.minimum_booking_notice_in_minutes);
  const [dailyLimit, setDailyLimit] = useState<string>('');
  const [weeklyLimit, setWeeklyLimit] = useState<string>('');

  const [updateStudyCalendar, { isError }] = useUpdateStudyCalendarMutation();

  const showToast = useToaster();

  const options = bookingLimitOptions();

  const { callback: debouncedSave } = useDebouncedCallback(onSave, 300);

  const updateLimits = ({ dailyLimit, weeklyLimit }) => {
    if (calendar) {
      if (dailyLimit != String(calendar.booking_limit_daily) || weeklyLimit != String(calendar.booking_limit_weekly)) {
        updateStudyCalendar({
          studyId: study.id,
          calendar: { booking_limit_daily: parseInt(dailyLimit), booking_limit_weekly: parseInt(weeklyLimit) }
        });
      }
    }
  };

  const handleMinBookingNoticeChange = (v: number) => {
    const parsedValue = v || 0;
    setMinBookingNotice(v);
    debouncedSave({ id: study.id, minimum_booking_notice_in_minutes: Math.max(0, parsedValue) });
  };

  useEffect(() => {
    if (calendar) {
      setDailyLimit(String(calendar.booking_limit_daily));
      setWeeklyLimit(String(calendar.booking_limit_weekly));
    }
  }, [calendar]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedUpdate());
    }
  }, [isError]);

  if (!calendar) {
    return null;
  }

  return (
    <PanelWrapper onClose={onClose} title='Additional options'>
      <FormGroup>
        <Label tooltip='Limits how many participants can schedule calls in the same day or week.'>Booking Limits</Label>
        <div className='flex items-center justify-between space-x-2'>
          <label htmlFor='daily-limit'>Daily:</label>
          <div className='w-3/4'>
            <Select
              id='daily-limit'
              disabled={readOnly}
              name='daily'
              options={options}
              value={dailyLimit}
              maxH={60}
              onChange={(value) => {
                updateLimits({ dailyLimit: value, weeklyLimit });
                setDailyLimit(value);
              }}
            />
          </div>
        </div>

        <div className='flex items-center justify-between pt-2 space-x-2'>
          <label htmlFor='weekly-limit'>Weekly:</label>
          <div className='w-3/4'>
            <Select
              id='weekly-limit'
              disabled={readOnly}
              name='weekly'
              options={options}
              value={weeklyLimit}
              maxH={60}
              onChange={(value) => {
                updateLimits({ dailyLimit, weeklyLimit: value });
                setWeeklyLimit(value);
              }}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label tooltip='Applied before and after all moderator calendar events which impacts the available slots shown to participants.'>
          Buffer between events
        </Label>
        <Select
          name='buffer'
          disabled={readOnly}
          maxH={60}
          options={CALL_BUFFER_OPTIONS.map((o) => ({ ...o, value: String(o.value) }))}
          value={String(timeSlot.call_buffer)}
          onChange={(v) => changeTimeSlot('call_buffer', v === 'null' ? null : (parseInt(v) as any))}
        />
      </FormGroup>
      <FormGroup>
        <Label tooltip="Ensures participants don't schedule without enough advanced notice.">
          Minimum scheduling notice
        </Label>
        <DurationInput
          disabled={readOnly}
          name='min_booking_notice'
          value={minBookingNotice || 0}
          onChange={handleMinBookingNoticeChange}
          placeholder='Enter number…'
        />
      </FormGroup>
      <FormGroup>
        <Label tooltip="Ensures participants won't schedule time too far in advance even if your scheduling window allows for it.">
          Maximum scheduling notice
        </Label>
        <SchedulingDropdown
          disabled={readOnly}
          noMaximumLimit={!study.continuous}
          placeholder='No maximum'
          value={calendar.maximum_scheduling_notice || 0}
          values={[1, 2, 3, 4]}
          activeUnit='week'
          conversionUnit='day'
          units={['day', 'week', 'month']}
          onChange={(v) => {
            updateStudyCalendar({
              studyId: study.id,
              calendar: { maximum_scheduling_notice: v }
            });
          }}
        />
      </FormGroup>
      <FormGroup>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <Text bold as='label'>
              Allow waitlists
            </Text>
            <Tooltip>
              If no more slots are available, participants can join a waitlist to be invited if any new slots open up on
              the study.
            </Tooltip>
          </div>
          <Toggle
            name='Allow waitlists'
            disabled={readOnly}
            on={study.waitlist_enabled}
            onToggle={(v) => {
              track('toggled_allow_waitlist', { value: v });
              onSave({ id: study.id, waitlist_enabled: v });
            }}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <Text bold as='label'>
              Allow time proposals
            </Text>
            <Tooltip>
              If no times shown work for the participant, they can propose alternative times for your review.
            </Tooltip>
          </div>
          <Toggle
            name='Allow time proposals'
            disabled={readOnly}
            on={study.time_proposals_enabled}
            onToggle={(v) => {
              track('toggled_allow_time_proposals', { value: v });
              onSave({ id: study.id, time_proposals_enabled: v });
            }}
          />
        </div>
      </FormGroup>
    </PanelWrapper>
  );
};
