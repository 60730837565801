import pluralize from 'pluralize';
import * as React from 'react';

import { Alert } from '@components/common';

export const UncontactableAlert: React.FC<{ diff: number; medium: Study['comms_medium']; total: number }> = ({
  diff,
  medium,
  total
}) => {
  if (diff === 0) {
    return null;
  }
  const isAll = total === diff;

  let prefix = `${pluralize('candidates', diff, true)} ${diff > 1 ? 'do not have' : 'does not have'}`;
  if (total === diff) {
    prefix = total > 1 ? 'No candidate has' : 'Candidate does not have';
  }
  return (
    <Alert type={isAll ? 'error' : 'warning'} className='mb-4'>
      {prefix} an {medium} to contact.
    </Alert>
  );
};
