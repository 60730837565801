import { RadioGroup, Text } from '@components/common';
import * as React from 'react';
import { RadioGroupOption } from '@components/common/RadioGroup';
import { useState } from 'react';

const OPTIONS: RadioGroupOption<Study['screener_review']>[] = [
  {
    label: 'Manual review',
    helper: 'Handpick participants based on their profiles and screener responses.',
    value: 'manual'
  },
  {
    label: 'Automatic review',
    helper: 'Participants who 100% match your ideal answers can participate right after they apply.',
    value: 'auto'
  }
];

interface Props {
  study: Study;
  onSave?: (study: { id: number } & Partial<Study>) => void;
  wrapperClassName?: string;
}

export const ScreenerReview: React.FC<Props> = ({ study, onSave, wrapperClassName }) => {
  const [screenerReview, setScreenerReview] = useState<Study['screener_review']>(study.screener_review);

  return (
    <div className={wrapperClassName}>
      <Text bold className='mb-2'>
        Review process
      </Text>
      <RadioGroup<Study['screener_review']>
        options={OPTIONS}
        name='screener_review'
        selected={screenerReview}
        onChange={(v) => {
          setScreenerReview(v);
          onSave?.({ id: study.id, screener_review: v });
        }}
      />
    </div>
  );
};
