import React, { SVGProps } from 'react';

export const AddToHighlightReelSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.4375 4.0625H4.0625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9375 4.0625H9.3125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.0625 14.5625V9.3125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.3125 5.8125V1.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.0625 6.6875V1.4375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.4375 11.9375H4.0625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.4375 9.3125H6.25'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.9375 5.8125V2.3125C11.9375 2.08044 11.8453 1.85788 11.6812 1.69378C11.5171 1.52969 11.2946 1.4375 11.0625 1.4375H2.3125C2.08044 1.4375 1.85788 1.52969 1.69378 1.69378C1.52969 1.85788 1.4375 2.08044 1.4375 2.3125V13.6875C1.4375 13.9196 1.52969 14.1421 1.69378 14.3062C1.85788 14.4703 2.08044 14.5625 2.3125 14.5625H6.6875'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.4375 6.6875H7.5625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.5625 11.0625C7.5625 11.9908 7.93125 12.881 8.58763 13.5374C9.244 14.1938 10.1342 14.5625 11.0625 14.5625C11.9908 14.5625 12.881 14.1938 13.5374 13.5374C14.1938 12.881 14.5625 11.9908 14.5625 11.0625C14.5625 10.1342 14.1938 9.244 13.5374 8.58763C12.881 7.93125 11.9908 7.5625 11.0625 7.5625C10.1342 7.5625 9.244 7.93125 8.58763 8.58763C7.93125 9.244 7.5625 10.1342 7.5625 11.0625Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.0625 9.3125V12.8125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.3125 11.0625H12.8125'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
