import cn from 'classnames';
import React, { FC, MouseEvent, useEffect, useState } from 'react';

import { Button, Heading, Loading, Pill, Text, Toggle } from '@components/common';
import { ModalBackdrop } from '@components/common/Modal';
import { useToaster } from '@components/stores/toaster';
import { ChevronDownSVG, ChevronUpSVG, PlusSVG, TrashSVG } from '@components/svgs';
import { track } from '@components/tracking';
import { useAuthProvider } from '@hooks/useAuthProvider';
import { useGetWhoamiQuery } from '@components/stores/whoami';

import {
  useDestroyConnectedAccountMutation,
  useGetIntegrationsQuery,
  useUpdateConnectedAccountMutation
} from '../../../api';
import * as toasts from '../../../toasts';

interface Props {
  onNext: () => void;
}

export const Integrations: FC<Props> = ({ onNext }) => {
  const { data: whoami } = useGetWhoamiQuery();

  const { data: integrations = [], refetch, isLoading } = useGetIntegrationsQuery();
  const [destroyConnectedAccount] = useDestroyConnectedAccountMutation();
  const [updateConnectedAccount] = useUpdateConnectedAccountMutation();
  const showToast = useToaster();
  const { getButtonProps, isOpen: popupOpen } = useAuthProvider();
  const [showAll, setShowAll] = useState<boolean>(false);

  const filterIntegrations = (visible: boolean) =>
    integrations.filter(({ recommended, connected_accounts, replaced_by }) => {
      const deprecated = replaced_by && whoami?.config?.features?.[`${replaced_by}_auth`];
      return (recommended === visible && !deprecated) || connected_accounts.length;
    });

  const visibleIntegrations = filterIntegrations(true);
  const hiddenIntegrations = filterIntegrations(false);

  async function disconnect(
    event: MouseEvent<HTMLButtonElement>,
    accountId: ConnectedAccount['id'],
    providerName: Integration['name']
  ) {
    event.preventDefault();
    await destroyConnectedAccount(accountId);
    refetch();
    showToast(toasts.disconnectedAccount(providerName));
  }

  async function makeAvailable(available: boolean, accountId: ConnectedAccount['id']) {
    updateConnectedAccount({ id: accountId, level: available ? 'account' : 'user' });
  }

  function onConnect(provider: Integration['provider'], providerName: Integration['name']) {
    refetch();
    track(`connected_${provider}`, { providerName });
  }

  useEffect(() => {
    track('viewed_onboarding_step', { step: 'integrations' });
    track('viewed_integrations', {});
  }, []);

  const connected = integrations.some(({ connected_accounts }) => connected_accounts.length > 0);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <section className='tablet:mt-24 desktop:mt-36 w-full max-w-3xl px-4 mx-auto mt-8'>
      <Heading as='h1' h='700' className='mb-2'>
        Connect your scheduling tools
      </Heading>
      <Text as='p' h='500' color='gray-500' className='mb-6'>
        This ensures you can easily set up or moderate interview studies.
      </Text>
      <section className='p-6 mb-10 border border-gray-200 rounded'>
        <Text h='400' className='mb-6 text-gray-500' bold>
          Recommended
        </Text>
        {visibleIntegrations.map(({ provider, name, icon, description, connected_accounts, multiple, auth_path }) => (
          <section key={provider + name} className='pb-4 mb-4 border-b border-gray-200'>
            <div className='flex-column flex items-center'>
              <img src={icon} alt={`${provider} icon`} className='w-10 h-10 mr-6' />
              <div className='pr-4'>
                <Text h='500' className='mb-1' bold>
                  {name}
                </Text>
                <Text h='400'>{description}</Text>
              </div>
              {connected_accounts.length ? (
                <Pill color='green' className='ml-auto'>
                  Connected
                </Pill>
              ) : (
                <Button
                  {...getButtonProps({ auth_path, callback: () => onConnect(provider, name) })}
                  type='button'
                  iconSuffix='plus'
                  className='ml-auto'
                >
                  Connect
                </Button>
              )}
            </div>
            {connected_accounts.length > 0 && (
              <section className='p-4 mt-4 ml-16 border border-gray-200 rounded'>
                {connected_accounts.map(({ id, email, level }) => (
                  <div key={id} className={cn({ 'border-b border-gray-200 pb-3 mb-3': multiple })}>
                    <div className='flex items-center justify-between'>
                      <Text>{email}</Text>
                      <button
                        className='focus:ring focus:outline-none flex items-center text-sm text-red-600 rounded'
                        type='button'
                        onClick={(event) => disconnect(event, id, name)}
                      >
                        <TrashSVG className='w-4 h-4 mr-2' /> Disconnect
                      </button>
                    </div>
                    {multiple && (
                      <div className='flex items-center mt-4'>
                        <Toggle
                          className='mr-2'
                          on={level === 'account'}
                          onToggle={(toggled) => makeAvailable(toggled, id)}
                        />
                        <Text h='400' className='text-gray-500'>
                          Make available to anyone on account
                        </Text>
                      </div>
                    )}
                  </div>
                ))}
                {multiple && (
                  <a
                    {...getButtonProps({ auth_path })}
                    className='focus:ring focus:outline-none flex items-center mt-2 text-sm text-indigo-600 rounded'
                    type='button'
                  >
                    <PlusSVG className='w-4 h-4 mr-2' /> Connect another account
                  </a>
                )}
              </section>
            )}
          </section>
        ))}
        {showAll && (
          <>
            <Text h='400' className='mb-6 text-gray-500' bold>
              All
            </Text>
            {hiddenIntegrations.map(({ provider, name, icon, description, auth_path }) => (
              <div key={provider} className='flex-column flex items-center pb-4 mb-4 border-b border-gray-200'>
                <img src={icon} alt={`${provider} icon`} className='w-10 h-10 mr-6' />
                <div className='pr-4'>
                  <Text h='500' className='mb-1' bold>
                    {name}
                  </Text>
                  <Text h='400'>{description}</Text>
                </div>
                <Button {...getButtonProps({ auth_path })} type='button' iconSuffix='plus' className='ml-auto'>
                  Connect
                </Button>
              </div>
            ))}
          </>
        )}
        <button
          className='focus:ring focus:outline-none flex items-center mt-2 text-sm text-indigo-600 rounded'
          type='button'
          onClick={() => setShowAll(!showAll)}
        >
          View {showAll ? 'less' : 'all'}{' '}
          {showAll ? <ChevronUpSVG className='w-4 h-4 ml-2' /> : <ChevronDownSVG className='w-4 h-4 ml-2' />}
        </button>
      </section>
      <div className='w-full text-center'>
        <Button aria-label='Continue' primary={!!connected} onClick={onNext} iconSuffix='arrowRight'>
          {connected ? 'Continue' : 'Skip for now'}
        </Button>
      </div>
      {popupOpen && <ModalBackdrop />}
    </section>
  );
};
