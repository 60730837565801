// Custom TailwindCSS modals for confirm dialogs

const Rails = require('@rails/ujs');

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm;

// Elements we want to listen to for data-confirm
const elements = ['a[data-confirm]', 'button[data-confirm]', 'input[type=submit][data-confirm]'];

const createConfirmModal = (element) => {
  var id = 'confirm-modal-' + String(Math.random()).slice(2, -1);
  var confirm = element.dataset.confirm;
  var message = element.dataset.message;
  var btn_class = element.dataset.btnclass;

  var content = `
    <div id="${id}" class="fixed top-0 left-0 z-50 table w-full h-full animated fadeIn" style="background-color: rgba(0, 0, 0, 0.8);">
      <div class="table-cell align-middle">

        <div class="max-w-sm p-8 mx-auto bg-white rounded shadow">
          <h4>${confirm}</h4>

          ${message ? `<p class="pt-6 whitespace-normal">${message}</p>` : ''}

          <div class="flex flex-wrap items-center justify-end mt-6">
            <button data-behavior="cancel" class="mr-2 btn btn-white primary">Cancel</button>
            <button data-behavior="commit" class="btn btn-primary ${
              btn_class || 'bg-indigo-600 hover:bg-indigo-700'
            } transition-none focus:outline-none">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  `;

  element.insertAdjacentHTML('afterend', content);

  var modal = element.nextElementSibling;
  element.dataset.confirmModal = `#${id}`;

  modal.addEventListener('keyup', (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      element.removeAttribute('data-confirm-modal');
      modal.remove();
    }
  });

  modal.querySelector("[data-behavior='cancel']").addEventListener('click', (event) => {
    event.preventDefault();
    element.removeAttribute('data-confirm-modal');
    modal.remove();
  });
  modal.querySelector("[data-behavior='commit']").addEventListener('click', (event) => {
    event.preventDefault();

    // Allow the confirm to go through
    Rails.confirm = () => {
      return true;
    };

    // Click the link again
    element.click();

    // Remove the confirm attribute and modal
    element.removeAttribute('data-confirm-modal');
    Rails.confirm = old_confirm;

    modal.remove();
  });

  modal.querySelector("[data-behavior='commit']").focus();
  return modal;
};

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal;
};

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true;

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target);
    return false;
  }
};

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(', '), 'confirm', handleConfirm);
