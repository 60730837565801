import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { api } from '@api/reduxApi';
import { Alert, Avatar, Loading, SlideOut } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { useCancelBackgroundTaskMutation } from '@components/shared/BackgroundTaskStatus/api';
import SearchSelector from '@components/shared/SearchSelector';
import { SendMessageParams, StudyMessageSlideOut } from '@components/StudyMessages';
import {
  AlreadyParticipatedAlert,
  NoCandidateAccessAlert,
  StudiesZDSAlert,
  UncontactableAlert
} from '@components/StudyMessages/components/alerts';
import { usePlan } from '@hooks/usePlan';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useToasterWithTimeout } from '@stores/toaster';

import { ServerFilterQuery } from '../types';
import { errorToast, successToast } from './toasts';
import { getBatchingSettings } from './utils';
import { useStudyLimits } from 'components/StudiesApp/components/StudyPublished/hooks/useStudyLimits';

interface Props {
  teamId?: number | null;
  study?: Study;
  studies?: Study[];
  ids?: number[];
  onClose: () => void;
  onSuccess: () => void;
  query?: ServerFilterQuery;
  allSelected?: boolean;
  selectedCount: number;
  previewCandidate?: Candidate;
}

const ZDS = () => (
  <Alert className='mt-6' type='warning'>
    No candidate are available for this study.
  </Alert>
);
const STYLE = {
  video_call: 'Interview',
  survey: 'Survey',
  online_task: 'Task',
  unmoderated_test: 'Test'
};

const renderItem =
  (studies: Study[]) =>
  (item: DropdownItem, isHighlighted: boolean): React.ReactElement => {
    const study = studies.find(({ id }) => item.value === id.toString());

    return (
      <div className='xx-study-dropdown-study flex flex-row items-center space-x-4'>
        {study && (
          <>
            {study.owner && <Avatar user={study.owner} />}
            <span className='flex-grow'>
              <span>{item.label}</span>
            </span>
            <span className={'flex-0 text-xs font-semibold' + (isHighlighted ? 'text-white' : 'text-gray-500')}>
              {STYLE[study.style]}
            </span>
          </>
        )}
      </div>
    );
  };

export const CandidatesInviteSlideOut: React.FC<Props> = ({
  teamId,
  studies,
  ids,
  query,
  allSelected,
  selectedCount,
  onClose,
  onSuccess,
  previewCandidate,
  study: initialStudy
}) => {
  const [selectedStudyId, setSelectedStudyId] = useState<number | undefined>();

  const {
    data: study = initialStudy,
    isLoading: studyLoading,
    isError: studyError
  } = api.useGetStudyQuery(selectedStudyId ?? skipToken);

  const candidateParams = allSelected ? { query } : { ids };

  const { studyLimits, studyLimitMatches } = useStudyLimits(study, candidateParams);

  const { hasQuota, getQuota } = usePlan();
  const [showToast, clearToastTimeout] = useToasterWithTimeout();
  const [invite, { isLoading: inviteLoading }] = api.useBulkParticipationInviteMutation();
  const [getCounts, { data, isSuccess }] = api.useGetInviteSlideOutCountsMutation();
  const [cancelBackgroundTask, {}] = useCancelBackgroundTaskMutation();
  const { data: canAccess } = api.useCanAccessCandidatesQuery(
    { candidates: candidateParams, team_id: study?.team_id },
    {
      skip: !study || !ids
    }
  );

  useEffect(() => {
    if (initialStudy) {
      handleStudySelect(initialStudy.id);
    }
  }, [initialStudy]);

  useEffect(() => {
    if (studyError) {
      showToast({
        heading: 'Something went wrong!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }, [studyError]);

  async function handleStudySelect(id: number) {
    setSelectedStudyId(id);
    await getCounts({
      studyId: id,
      ...(allSelected ? { query } : { candidateIds: ids })
    });
  }

  const inviteableCount = isSuccess && data ? data.inviteable_count : 0;
  const reInviteableCount = isSuccess && data ? data.reinviteable_count : 0;
  const resendCount = isSuccess && data ? data.reinviteable_count : 0;
  const contactableCount = isSuccess && data ? data.contactable_count : 0;
  const ineligibleCount = isSuccess && data ? data.ineligible_count : 0;
  const possibleCount = inviteableCount + resendCount;
  const canSend = hasQuota('invites', inviteableCount);

  const batchingSettings = getBatchingSettings(inviteableCount, study);
  const studyOptions: DropdownItem[] = useMemo(() => {
    return (studies || [])
      .filter((s) => s.state === 'active')
      .filter((s) => !teamId || s.team_id === teamId)
      .map(({ id, title }) => ({ label: title, value: id.toString() }));
  }, [studies]);

  const handleSend: (params: SendMessageParams) => Promise<void> = async ({
    batch,
    addToBatch,
    sender,
    excludeIneligible
  }) => {
    const sendNow = study?.batch_on && !addToBatch;

    if (!ids && !query) return;
    if (!study) return;

    const invite_params = {
      batch_on: batch.on,
      batch_size: batch.size,
      batch_wait_hours: batch.wait_hours,
      batch_auto_restart: batch.auto_restart
    };
    try {
      const backgroundTask = await invite({
        studyId: study.id,
        sender: sender,
        exclude_ineligible: excludeIneligible,
        invite: invite_params,
        send_now: sendNow,
        ...(allSelected ? { query } : { customer_ids: ids })
      }).unwrap();

      showToast(successToast(!!initialStudy, study, backgroundTask, handleCancel));
      onSuccess();
    } catch (e) {
      showToast(errorToast(e.data.error));
    }
  };
  const handleCancel: (task: BackgroundTask) => Promise<void> = async (task) => {
    clearToastTimeout();

    try {
      const result = await cancelBackgroundTask(task).unwrap();
      showToast({
        heading: 'Emails unsent',
        text: "They won't be delivered",
        icon: 'success'
      });
    } catch (e) {
      showToast(errorToast(e.data.error));
    }
  };

  if (!canSend) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <Alert type='warning'>
            Cannot send all {inviteableCount} messages as this exceeds your remaining quota of {getQuota('invites')}
          </Alert>
        </div>
      </SlideOut>
    );
  }

  if (canAccess === false) {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='p-6'>
          <NoCandidateAccessAlert />
        </div>
      </SlideOut>
    );
  }

  const selectedItem: DropdownItem | null = study ? { label: study.title, value: study.id.toString() } : null;

  const loading = studyLoading || inviteLoading;

  if (study && possibleCount > 0) {
    return (
      <StudyMessageSlideOut
        batchable
        defaultBatching={batchingSettings}
        title='Participation invite'
        batchingTitle='study invites'
        study={study}
        event='invite'
        customizable={!study.batch_on || !study.next_batch_at}
        inviteableCount={inviteableCount}
        ineligibleCount={ineligibleCount}
        resendCount={resendCount}
        onClose={onClose}
        onSend={handleSend}
        previewCandidate={previewCandidate}
        studyLimits={studyLimits}
        studyLimitMatches={studyLimitMatches}
      >
        <>
          {loading && <Loading absolute />}
          <SearchSelector
            inputClassName='xx-study-dropdown'
            onSelect={(v) => handleStudySelect(parseInt(v))}
            options={studyOptions}
            defaultSelected={selectedItem?.value}
            label='Select Study'
            placeholder='Enter study title…'
            renderItem={studies && renderItem(studies)}
          />
          {(ids || query) && (
            <AlreadyParticipatedAlert
              diff={contactableCount - inviteableCount - reInviteableCount}
              total={selectedCount}
            />
          )}
          {(ids || query) && selectedCount != 0 && contactableCount && (
            <UncontactableAlert
              diff={selectedCount - contactableCount}
              medium={study.comms_medium}
              total={selectedCount}
            />
          )}
        </>
      </StudyMessageSlideOut>
    );
  } else {
    return (
      <SlideOut title='Participation invite' onClose={onClose} size='xl'>
        <div className='px-4'>
          {loading && <Loading absolute />}

          <SearchSelector
            inputClassName='xx-study-dropdown'
            onSelect={(v) => handleStudySelect(parseInt(v))}
            options={studyOptions}
            defaultSelected={selectedItem?.value}
            label='Select Study'
            placeholder='Enter study title…'
            renderItem={studies && renderItem(studies)}
          />
          {studyOptions.length === 0 && !loading && <StudiesZDSAlert />}
          {study && inviteableCount === 0 && ids && !loading && <ZDS />}
          {study && ids && (
            <AlreadyParticipatedAlert
              diff={contactableCount - inviteableCount - reInviteableCount}
              total={selectedCount}
            />
          )}
          {study && contactableCount !== 0 && selectedCount !== 0 && (
            <UncontactableAlert
              diff={selectedCount - contactableCount}
              medium={study.comms_medium}
              total={selectedCount}
            />
          )}
        </div>
      </SlideOut>
    );
  }
};
