import * as React from 'react';

import { FilterSearch } from 'components/CandidatesApp/CandidatesIndex/FilterSearch';
import { useSearchWithinFilter } from 'components/CandidatesApp/CandidatesIndex/hooks';
import { Text } from '@components/common';
import { useAccount } from '@hooks/useAccount';

import { DropdownProps } from '../../types';

export type Option = { label: string; value: any };

type Props<D = any> = DropdownProps<D, number[]> & { options: Option[] };

export const MultiSelectWithSearch: React.FC<Props> = ({ options, value, onChange }) => {
  const {
    account: { team }
  } = useAccount();

  const { searchValue, setSearchValue, filteredOptions } = useSearchWithinFilter<Option>({
    options,
    getFilteredOptions: (option, search) =>
      option.label.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || ''),
    sort: 'alpha'
  });

  const toggleFilter = (option: number) => {
    const newValue = value?.includes(option) ? value.filter((opt) => opt !== option) : (value || []).concat(option);
    onChange({ value: newValue });
  };

  return (
    <div>
      <FilterSearch value={searchValue} setValue={setSearchValue} />
      <div className='space-y-1.5 max-h-32 overflow-auto py-1'>
        {filteredOptions.map((option, i) => (
          <label
            key={'filterOption' + `${option}${i}`}
            className='max-w-md hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 flex items-center text-sm leading-5 text-gray-700 normal-case'
          >
            <input
              type='checkbox'
              className='form-checkbox ml-1 mr-2'
              onChange={() => toggleFilter(option.value)}
              value={option.value}
              checked={value?.includes(option.value) || false}
            />
            <Text h='400' truncate>
              {option.label}
            </Text>
          </label>
        ))}
      </div>
    </div>
  );
};
