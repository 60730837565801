import React, { ButtonHTMLAttributes, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import cn from 'classnames';

interface Props {
  initialValue?: string;
  onValueChange?: (v: string) => void;
  onBlur?: (v: string) => void;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  size?: 'md' | 'sm';
  width?: string;
  readOnly?: boolean;
  lineClamp?: string;
  autofocus?: boolean;
}

const SIZES = {
  md: 'font-bold text-lg leading-normal rounded-md px-2',
  sm: 'font-bold text-md leading-normal rounded-md px-1'
};

export const EditableTitle: React.FC<Props> = ({
  size = 'md',
  inputProps,
  buttonProps,
  initialValue = '',
  onValueChange,
  onBlur,
  width = 'full',
  readOnly,
  lineClamp,
  autofocus
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [focused, setFocused] = useState(autofocus);

  const touchedRef = useRef(false);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const onChange = (v: string) => {
    setValue(v);
    onValueChange?.(v);
  };

  const handleBlur = (v: string) => {
    onBlur?.(v);

    if (v) {
      setFocused(false);
    }
  };

  useEffect(() => {
    if (!focused && touchedRef.current) {
      requestAnimationFrame(() => buttonRef.current?.focus());
    }
  }, [focused]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return focused ? (
    <input
      autoFocus
      onFocus={(e) => e.target.select()}
      data-testid='title'
      onClick={(e) => e.stopPropagation()}
      className={cn(
        'outline-none border focus:border-indigo-600 focus-visible:ring-indigo-600 focus:ring-indigo-600',
        SIZES[size],
        `w-${width}`
      )}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => handleBlur(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      value={value}
      placeholder='Enter title...'
      {...inputProps}
    />
  ) : (
    <Tippy placement='bottom' arrow={false} disabled={readOnly} content='Edit title'>
      <div className={cn(!value && 'w-full')} onClick={(e) => e.stopPropagation()}>
        <button
          onClick={() => {
            if (!readOnly) {
              setFocused(true);
              touchedRef.current = true;
            }
          }}
          ref={buttonRef}
          type='button'
          className={cn(
            'border border-transparent',
            SIZES[size],
            lineClamp && `line-clamp-${lineClamp}`,
            readOnly && 'cursor-default',
            !readOnly && 'text-left cursor-pointer hover:bg-gray-50 hover:border-gray-400'
          )}
          {...buttonProps}
        >
          {value}
        </button>
      </div>
    </Tippy>
  );
};
