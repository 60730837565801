import React, { SVGProps } from 'react';

export const ScreenSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M1.9043 1.9082H14.0918C14.0918 1.9082 15.0293 1.9082 15.0293 2.8457V10.3457C15.0293 10.3457 15.0293 11.2832 14.0918 11.2832H1.9043C1.9043 11.2832 0.966797 11.2832 0.966797 10.3457V2.8457C0.966797 2.8457 0.966797 1.9082 1.9043 1.9082Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3418 14.0957H5.6543L6.12305 11.2832H9.87305L10.3418 14.0957Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M4.24805 14.0918H11.748' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
