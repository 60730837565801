import { api } from '@api/reduxApi';

const sampleGeneratorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateSampleCandidates: builder.mutation<void, Record<string, any>>({
      query: (body) => ({
        url: '/sample/candidates',
        method: 'POST',
        body: { config: body }
      })
    })
  })
});

export const { useGenerateSampleCandidatesMutation } = sampleGeneratorsApi;
