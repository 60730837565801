import * as React from 'react';
import { useCallback } from 'react';

import {
  ModeratorsNeedCalendarAlert,
  ModeratorsNeedZoomSettingChangeAlert,
  ModeratorsNeedConferencingAlert,
  NeedsSlotsAlert,
  UnderParticipantLimitAlert,
  InvalidCallLocationAlert,
  NoScreenerQuestionsAlert,
  NoSurveyQuestionsAlert
} from './components';

type Props = {
  study: Study;
  bookability: Bookability;
  onStartPolling?: () => void;
};

const COMPONENTS: Record<BookabilityRule, BookabilityRuleAlertComponent> = {
  moderators_need_calendar: ModeratorsNeedCalendarAlert,
  moderators_need_conferencing: ModeratorsNeedConferencingAlert,
  moderators_need_zoom_setting_change: ModeratorsNeedZoomSettingChangeAlert,
  needs_slots: NeedsSlotsAlert,
  under_participant_limit: UnderParticipantLimitAlert,
  invalid_call_location: InvalidCallLocationAlert,
  no_screener_questions: NoScreenerQuestionsAlert,
  no_survey_questions: NoSurveyQuestionsAlert
};

export const BookabilityAlerts: React.FC<Props> = ({ study, bookability, onStartPolling }) => {
  const rules = Object.keys(bookability);

  const renderRule = useCallback(
    (rule: BookabilityRule) => {
      const Component = COMPONENTS[rule];
      const level = bookability[rule]?.[0];
      const extra = bookability[rule]?.[1];
      return (
        level &&
        Component && (
          <Component key={rule} study={study} rule={rule} level={level} extra={extra} onClickCta={onStartPolling} />
        )
      );
    },
    [bookability]
  );

  const displayableRules = rules.filter((rule) => bookability[rule][0]);

  if (!displayableRules?.length) {
    return null;
  }

  return <div className='flex flex-col mb-4 space-y-4'>{displayableRules.map(renderRule)}</div>;
};
