import { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';

export const useBulkZoomImport = (items: ZoomItem[], dispatchSetItem: (item: Patch<ZoomItem>) => void): void => {
  const [create, { isSuccess, data }] = api.useCreateZoomBulkImportMutation();
  const [updateRepoSession, {}] = api.useUpdateRepoSessionMutation();

  const [uuids, setUuids] = useState<string[]>([]);
  const zoomMeetingUuids = items.filter((i) => i.status === 'queued').map((item) => item.cloudRecording.uuid);
  const uuidsToBeCreated = zoomMeetingUuids.filter((uuid) => !uuids.includes(uuid));

  useEffect(() => {
    if (uuidsToBeCreated.length > 0) {
      const zoom_meetings = items
        .map((item) => ({
          meeting_id: item.cloudRecording.id,
          uuid: item.cloudRecording.uuid
        }))
        .filter(({ uuid }) => uuidsToBeCreated.includes(uuid));

      create({ zoom_meetings });
      setUuids(uuids.concat(uuidsToBeCreated));
    }
  }, [uuidsToBeCreated]);

  useEffect(() => {
    if (isSuccess) {
      const { recordings } = data as any;
      recordings.forEach((recording) => {
        const item = items.find((item) => String(item.cloudRecording.uuid) === recording.zoom_meeting?.uuid);
        if (item) {
          updateRepoSession({ uuid: item.session.uuid, recording_id: recording.id })
            .unwrap()
            .then((session) => {
              dispatchSetItem({ id: item.id, status: 'uploading', session });
            });
        }
      });
    }
  }, [isSuccess]);
};
