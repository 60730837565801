import * as React from 'react';

import { PageTitle } from '@components/common/helmets';
import { DashboardHeader, DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { Basic, Grid } from '@components/shared/Skeleton';

export const Skeleton: React.FC = () => {
  return (
    <DashboardLayout>
      <PageTitle>Guides</PageTitle>
      <div className='px-page py-4 flex flex-col desktop:flex-row items-center border-b border-gray-200'>
        <h1 className='h700 font-bold mb-2 desktop:mb-0'>Guides</h1>
        <div className='flex flex-col tablet:flex-row space-y-2 tablet:space-y-0 items-center justify-end flex-1 tablet:space-x-3'>
          <Basic h='8' width={90} />
          <Basic h='8' width={95} />
          <Basic h='8' width={120} />
        </div>
      </div>

      <DashboardLayoutBody className='h-full'>
        <div className='flex mb-4'>
          <Basic h='6' width={100} />
        </div>
        <Grid />
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
