import * as React from 'react';

import { NodeViewProps } from '@tiptap/react';
import { api } from '@components/PublicAssetApp/api';
import { useAccount } from '@hooks/useAccount';

import { ArtifactsAttributes } from '../../extensions';
import { Cards } from './Cards';

export const PublicArtifactsView = (props: NodeViewProps): React.ReactElement => {
  const { account } = useAccount();
  const { artifacts } = props.node.attrs as ArtifactsAttributes;
  const { data, isLoading } = api.useGetArtifactsQuery({
    accountId: account.id,
    objectIds: artifacts
  });

  return <Cards isPublic isLoading={isLoading} data={data} {...props} />;
};
