import cn from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Pill } from '@components/common/Pill';
// import { Link as V6Link } from 'react-router-dom';
import { Spinner } from '@components/common/Spinner';
import { ExternalLinkIcon } from '@components/CustomerImports/steps/UploadStep';
import {
  AccountingDocumentSVG,
  AddPeopleSVG,
  AddToHighlightReelSVG,
  AddToInsightSVG,
  AskAiSVG,
  BlogSVG,
  CandidatesSVG,
  CaretDownSVG,
  CashPaymentSVG,
  ChevronLeftSVG,
  ChevronRightSVG,
  ClipSVG,
  CloudSVG,
  ComposeEmailSVG,
  CopySVG,
  TransferVerticalSVG,
  DeselectSVG,
  EditEmailSVG,
  EmailSearchSVG,
  ErrorSvg,
  ExportSVG,
  ExternalCandidatesSVG,
  EyeSVG,
  FilterSVG,
  FullscreenSVG,
  HighlightReelSVG,
  HighlightSVG,
  ImportSVG,
  LinkSVG,
  LockSVG,
  WorldSVG,
  MergeSVG,
  MoneySVG,
  NewTabSVG,
  NoCircleSVG,
  PaperClipSVG,
  PauseSVG,
  PictureSVG,
  PencilSVG,
  PlaySVG,
  PlusSVG,
  PromoSVG,
  ProposeTimeSVG,
  ReloadIcon,
  RepositorySVG,
  ResetSVG,
  SearchSVG,
  SendSVG,
  ShareSVG,
  StarSVG,
  StudiesSVG,
  TagSVG,
  TemplatesSVG,
  TickSVG,
  TrashSVG,
  UnlinkSVG,
  UploadVideoSVG,
  UploadZoomSVG,
  ViewSVG,
  ZoomSVG,
  FullPageViewSVG,
  QuestionBankSVG,
  CheckmarkInCircleSVG,
  ThinArrowRightSVG,
  RoundArrowDownSVG,
  DollarBadgeSVG,
  ChevronUpSVG,
  SettingsGearSVG,
  CheckSVG
} from '@components/svgs';
import { track } from '@components/tracking';

export const ICONS: Record<string, (props: React.SVGProps<SVGSVGElement>) => JSX.Element> = {
  send: SendSVG,
  editEmail: EditEmailSVG,
  emailSearch: EmailSearchSVG,
  tick: TickSVG,
  plus: PlusSVG,
  trash: TrashSVG,
  tag: TagSVG,
  export: ExportSVG,
  eye: EyeSVG,
  pencil: PencilSVG,
  import: ImportSVG,
  externalLink: ExternalLinkIcon,
  blog: BlogSVG,
  paperClip: PaperClipSVG,
  unlink: UnlinkSVG,
  link: LinkSVG,
  reload: ReloadIcon,
  clip: ClipSVG,
  caretDown: CaretDownSVG,
  share: ShareSVG,
  merge: MergeSVG,
  cashPayment: CashPaymentSVG,
  accountingDocument: AccountingDocumentSVG,
  transferVertical: TransferVerticalSVG,
  chevronLeft: ChevronLeftSVG,
  chevronRight: ChevronRightSVG,
  chevronUp: ChevronUpSVG,
  money: MoneySVG,
  promo: PromoSVG,
  candidates: CandidatesSVG,
  addPeople: AddPeopleSVG,
  studies: StudiesSVG,
  zoom: ZoomSVG,
  star: StarSVG,
  addToInsight: AddToInsightSVG,
  deselect: DeselectSVG,
  addToHighlightReel: AddToHighlightReelSVG,
  highlightReel: HighlightReelSVG,
  highlight: HighlightSVG,
  composeEmail: ComposeEmailSVG,
  repository: RepositorySVG,
  filter: FilterSVG,
  view: ViewSVG,
  lock: LockSVG,
  world: WorldSVG,
  search: SearchSVG,
  reset: ResetSVG,
  proposeTime: ProposeTimeSVG,
  copy: CopySVG,
  uploadVideo: UploadVideoSVG,
  uploadZoom: UploadZoomSVG,
  ai: AskAiSVG,
  cloud: CloudSVG,
  error: ErrorSvg,
  template: TemplatesSVG,
  externalCandidates: ExternalCandidatesSVG,
  noCircle: NoCircleSVG,
  fullscreen: FullscreenSVG,
  newTab: NewTabSVG,
  fullPageView: FullPageViewSVG,
  pause: PauseSVG,
  play: PlaySVG,
  questionBank: QuestionBankSVG,
  checkInCircle: CheckmarkInCircleSVG,
  arrowRight: ThinArrowRightSVG,
  roundArrowDown: RoundArrowDownSVG,
  picture: PictureSVG,
  dollarBadge: DollarBadgeSVG,
  settings: SettingsGearSVG,
  check: CheckSVG
};

export interface Props {
  beta?: boolean;
  black?: boolean;
  children?: React.ReactNode;
  className?: string;
  danger?: boolean;
  disabled?: boolean;
  form?: string;
  fullWidth?: boolean;
  href?: string;
  icon?: keyof typeof ICONS;
  iconSuffix?: keyof typeof ICONS;
  id?: string;
  inactive?: boolean;
  link?: boolean;
  loading?: boolean;
  name?: string;
  onClick?: React.MouseEventHandler<any>;
  outline?: boolean;
  primary?: boolean;
  ref?: UseFormMethods['register'];
  rounded?: boolean;
  secondary?: boolean;
  small?: boolean;
  spa?: boolean;
  target?: string;
  text?: boolean;
  trackEvent?: string;
  trackProps?: Record<string, any>;
  type?: 'button' | 'submit';
  underline?: boolean;
  value?: string;
  medium?: boolean;
  noStyle?: boolean;
  weight?: 'normal' | 'medium';
  noWrap?: boolean;
}

const DEFAULT_STYLES =
  'block disabled:opacity-50 cursor-pointer inline-flex items-center justify-center relative focus:ring focus:ring-blue focus:outline-none';

const STYLES: Record<string, string> = {
  outline:
    'border border-gray-200 bg-white text-gray-700 hover:bg-gray-50 active:bg-gray-50 active:text-gray-800 focus:border-blue-300',
  primary:
    'bg-indigo-600 text-white hover:text-white hover:bg-indigo-500 active:text-white active:bg-indigo-500 focus:text-white focus:ring-indigo',
  primary_outline: 'bg-indigo-50 text-indigo-600 hover:text-indigo-600 focus:ring-indigo border border-indigo-600',
  secondary:
    'bg-gray-700 text-white hover:text-white hover:bg-gray-500 active:text-white active:bg-indigo-500 focus:text-white focus:ring-indigo',
  danger:
    'bg-red-600 text-white hover:text-white hover:bg-red-500 active:text-white active:bg-red-500 focus:text-white focus:ring-red',
  danger_outline:
    'bg-white text-red-600 border-red-600 border hover:bg-gray-50 active:bg-gray-50 focus:bg-gray-50 focus:ring-red',
  black:
    'hover:bg-gray-900 text-white hover:text-white bg-gray-800  active:text-white active:bg-indigo-500 focus:text-white focus:ring-indigo',
  link: 'text-indigo-600 hover:bg-gray-50 hover:text-indigo-700 active:text-indigo-700 focus:ring-indigo',
  text: 'text-gray-700 hover:bg-gray-50 hover:text-indigo-700 active:text-indigo-700 focus:ring-indigo rounded-md',
  inactive: 'text-gray-700 bg-gray-200',
  icon: 'leading-none p-1',
  small: 'text-xs leading-5 px-3 py-1.5',
  medium: 'text-sm leading-5 px-3 py-1.5 h-8',
  big: 'text-sm leading-5 px-4 py-2.5 '
};

const ICON_STYLES: Record<string, string> = {
  link: 'text-indigo-600',
  primary: 'text-white hover:text-white',
  primary_outline: 'text-indigo-600',
  danger_outline: 'text-red-600',
  secondary: 'text-white hover:text-white',
  black: 'text-white hover:text-white',
  danger: 'text-white hover:text-white',
  default: 'text-gray-700 hover:text-indigo-600'
};

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    children,
    className = '',
    id,
    disabled,
    trackEvent,
    trackProps,
    onClick,
    href,
    name,
    target,
    type,
    small,
    medium,
    icon,
    iconSuffix,
    form,
    beta = false,
    value,
    link,
    inactive,
    text,
    danger,
    secondary,
    black,
    fullWidth,
    primary,
    outline,
    noStyle,
    loading,
    underline,
    rounded,
    weight = 'medium',
    noWrap = false,
    ...rest
  } = props;

  let style = noStyle ? null : 'outline';
  if (primary) {
    style = outline ? 'primary_outline' : 'primary';
  } else if (secondary) {
    style = 'secondary';
  } else if (black) {
    style = 'black';
  } else if (danger) {
    style = outline ? 'danger_outline' : 'danger';
  } else if (text) {
    style = 'text';
  } else if (link) {
    style = 'link';
  } else if (props.inactive) {
    style = 'inactive';
  }

  let Element: React.FC<any> | string = props.href ? 'a' : 'button';

  const iconStyles = 'my-0.5 ' + ((style && ICON_STYLES[style]) || ICON_STYLES.default);

  const iconSuffixStyles = cn(iconStyles, fullWidth && 'absolute right-5');

  let child = children;
  if (icon || iconSuffix) {
    const Icon = icon ? ICONS[icon] : undefined;
    const IconSuffix = iconSuffix ? ICONS[iconSuffix] : undefined;

    child = (
      <>
        {Icon && <Icon className={iconStyles} />}
        {children && <span>{children}</span>}
        {IconSuffix && <IconSuffix className={iconSuffixStyles} />}
      </>
    );
  }
  if (beta) {
    child = (
      <>
        {child}
        <Pill color='blue'>Beta</Pill>
      </>
    );
  }

  if (loading) {
    child = (
      <>
        <Spinner className='w-5 h-5' />
        {children && <span>{children}</span>}
      </>
    );
  }

  const theProps: any = {
    children: child,
    disabled,
    role: props.href ? 'link' : 'button',
    ref,
    id,
    href,
    target,
    name,
    type,
    form,
    value,
    className: cn(
      DEFAULT_STYLES,
      `font-${weight}`,
      style && STYLES[style],
      STYLES[small ? 'small' : medium ? 'medium' : noStyle && icon ? 'icon' : 'big'],
      {
        'w-full': fullWidth,
        underline: underline,
        'rounded-full': rounded,
        'space-x-2': !!icon || !!iconSuffix || loading,
        'rounded-md': !text,
        'whitespace-nowrap': noWrap
      },
      className
    ),
    ...rest
  };

  if (onClick || trackEvent) {
    theProps.onClick = ((e: React.MouseEvent): void => {
      if (trackEvent) {
        track(trackEvent, trackProps);
      }
      if (onClick) {
        onClick(e);
      }
    }) as React.MouseEventHandler;
  }

  if (props.spa) {
    theProps.to = theProps.href;
    delete theProps.href;
    delete theProps.type;
    delete theProps.spa;
    Element = Link;
  }

  return React.createElement(Element, theProps);
});

export { Props as ButtonProps, STYLES as buttonStyleClasses };
