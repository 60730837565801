import * as React from 'react';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass } from '@components/CandidateAttrs/index';

export const BooleanInput: React.FC<CandidateAttrInput<boolean>> = ({
  value,
  styles,
  label,
  setValue,
  onSave,
  onFocus,
  onBlur
}) => {
  const { handleBlur, handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);

  const handleChange = (value) => {
    setValue(value);
    onSave?.(value);
  };

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles?.label}>{label}</td>
      <td className={styles?.value}>
        <div className='flex flex-row items-center space-x-3 px-4 py-2.5'>
          <label>
            <input
              className='mr-1'
              type='radio'
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={'1'}
              checked={value === true}
              onChange={() => handleChange(true)}
            />
            Yes
          </label>
          <label>
            <input
              className='mr-1'
              type='radio'
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={'0'}
              checked={value === false}
              onChange={() => handleChange(false)}
            />
            No
          </label>
          <a className='pl-2 text-xs cursor-pointer' onClick={() => handleChange(null)}>
            Clear
          </a>
        </div>
      </td>
    </tr>
  );
};
