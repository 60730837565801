import React, { SVGProps } from 'react';

export const AddToInsightSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_506_3465)'>
      <path
        d='M12.2083 15C10.1177 15 8.41667 13.299 8.41667 11.2084C8.41667 9.11769 10.1177 7.41669 12.2083 7.41669C14.299 7.41669 16 9.11769 16 11.2084C16 13.299 14.299 15 12.2083 15ZM12.2083 8.29169C10.6001 8.29169 9.29167 9.60011 9.29167 11.2084C9.29167 12.8166 10.6001 14.125 12.2083 14.125C13.8166 14.125 15.125 12.8166 15.125 11.2084C15.125 9.60011 13.8166 8.29169 12.2083 8.29169Z'
        fill='currentColor'
      />
      <path
        d='M12.2083 13.2501C11.9668 13.2501 11.7708 13.0541 11.7708 12.8126V9.60425C11.7708 9.36275 11.9668 9.16675 12.2083 9.16675C12.4498 9.16675 12.6458 9.36275 12.6458 9.60425V12.8126C12.6458 13.0541 12.4498 13.2501 12.2083 13.2501Z'
        fill='currentColor'
      />
      <path
        d='M13.8125 11.6458H10.6042C10.3627 11.6458 10.1667 11.4498 10.1667 11.2083C10.1667 10.9668 10.3627 10.7708 10.6042 10.7708H13.8125C14.054 10.7708 14.25 10.9668 14.25 11.2083C14.25 11.4498 14.054 11.6458 13.8125 11.6458Z'
        fill='currentColor'
      />
      <path
        d='M7.36083 13.25H3.60417C2.71925 13.25 2 12.5308 2 11.6458V2.60417C2 1.71925 2.71925 1 3.60417 1H10.3125C11.1974 1 11.9167 1.71925 11.9167 2.60417V6.15667C11.9167 6.39817 11.7207 6.59417 11.4792 6.59417C11.2377 6.59417 11.0417 6.39817 11.0417 6.15667V2.60417C11.0417 2.20225 10.7144 1.875 10.3125 1.875H3.60417C3.20225 1.875 2.875 2.20225 2.875 2.60417V11.6458C2.875 12.0478 3.20225 12.375 3.60417 12.375H7.36083C7.60233 12.375 7.79833 12.571 7.79833 12.8125C7.79833 13.054 7.60233 13.25 7.36083 13.25V13.25Z'
        fill='currentColor'
      />
      <path
        d='M9.72917 6.54169H4.1875C3.946 6.54169 3.75 6.34569 3.75 6.10419C3.75 5.86269 3.946 5.66669 4.1875 5.66669H9.72917C9.97067 5.66669 10.1667 5.86269 10.1667 6.10419C10.1667 6.34569 9.97067 6.54169 9.72917 6.54169Z'
        fill='currentColor'
      />
      <path
        d='M7.39583 8.875H4.1875C3.946 8.875 3.75 8.679 3.75 8.4375C3.75 8.196 3.946 8 4.1875 8H7.39583C7.63733 8 7.83333 8.196 7.83333 8.4375C7.83333 8.679 7.63733 8.875 7.39583 8.875Z'
        fill='currentColor'
      />
      <path
        d='M6.8125 4.20831H4.1875C3.946 4.20831 3.75 4.01231 3.75 3.77081C3.75 3.52931 3.946 3.33331 4.1875 3.33331H6.8125C7.05399 3.33331 7.24999 3.52931 7.24999 3.77081C7.24999 4.01231 7.05399 4.20831 6.8125 4.20831Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_506_3465'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
