import { api } from '@api/reduxApi';
import { AI } from './types';

export const aiBotApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // unfortunately we need to use `any` here, but that's ok since our useQuery hooks are fully typed
    getBotSuggestions: builder.query<AI.UseQueryResult<any>, AI.UseQueryArg<any>>({
      query: ({ id, context }) => ({
        url: '/ai/suggestions',
        method: 'POST',
        body: { bot_id: id, context }
      }),
      transformResponse: (response: { data: any }) => response.data
    }),
    acceptBotSuggestion: builder.mutation<void, number>({
      query: (id) => ({
        url: `/ai/suggestions/${id}/accept`,
        method: 'POST'
      })
    }),
    rejectBotSuggestion: builder.mutation<void, number>({
      query: (id) => ({
        url: `/ai/suggestions/${id}/reject`,
        method: 'POST'
      })
    })
  })
});

export const useGetBotSuggestionsQuery = <
  TData extends AI.GenericData = AI.GenericData,
  TContext extends Record<string, any> = Record<string, any>
>(
  arg: AI.UseQueryArg<TContext>,
  options: AI.UseQueryOptions<TContext> = {}
): AI.UseQuery<TData, TContext> => aiBotApi.useGetBotSuggestionsQuery(arg, options);

export const useLazyGetBotSuggestionsQuery = <
  TData extends AI.GenericData = AI.GenericData,
  TContext extends Record<string, any> = Record<string, any>
>(
  options?: AI.UseQueryOptions<TContext>
): AI.UseLazyQuery<TData, TContext> => aiBotApi.useLazyGetBotSuggestionsQuery(options);

export const { useAcceptBotSuggestionMutation, useRejectBotSuggestionMutation } = aiBotApi;
