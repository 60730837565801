import cn from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';
import { usePopper } from 'react-popper';

import { AI } from '@api/chat-gpt';
import { Portal } from '@components/shared/Portal';
import { Placement } from '@popperjs/core';

import { TagListButton } from './components/TagListButton';
import { TagListItems } from './components/TagListItems';
import { useTagList } from './useTagList';

interface Props {
  tagIds: number[];
  studyId?: number | null;
  readOnly?: boolean;
  onChange?: (tagIds: number[], tag?: Tag) => void;
  onTagCreate?: (tag: Tag) => void;
  inputClassName?: string;
  placeholder?: string;
  className?: string;
  previewShowLimit?: number;
  pillClassName?: string;
  wrapperClassName?: string;
  tabIndex?: number;
  placement?: Placement;
  suggest?: AI.UseQueryResult<string>;
  defaultOpen?: boolean;
  dropdownClassName?: string;
  hideManagerLinks?: boolean;
  usePortal?: boolean;
}
export const TagList: React.FC<Props> = ({
  tagIds,
  studyId,
  onChange,
  onTagCreate,
  readOnly,
  className,
  previewShowLimit,
  pillClassName,
  wrapperClassName,
  tabIndex = -1,
  placement = 'right-start',
  suggest,
  defaultOpen,
  dropdownClassName,
  hideManagerLinks,
  usePortal = true
}) => {
  const { canManage, multiselectTags, inputRef } = useTagList({
    tagIds,
    defaultOpen,
    readOnly,
    onChange,
    onTagCreate,
    suggest,
    studyId,
    closeOnEsc: true,
    hideManagerLinks
  });

  const { isOpen } = multiselectTags;

  const menuRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLDivElement>(null);
  const { styles, attributes, update } = usePopper(triggerRef.current, menuRef.current, {
    placement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          padding: 8,
          altBoundary: true
        }
      }
    ]
  });

  useEffect(() => {
    if (isOpen && update) {
      update();
    }
  }, [isOpen, update]);

  const items = (
    <TagListItems
      className='shadow-custom bg-white border border-t-0 border-gray-200 rounded'
      canManage={canManage}
      studyId={studyId}
      multiselectTags={multiselectTags}
    />
  );

  return (
    <div className={cn('relative', wrapperClassName)}>
      <TagListButton
        tabIndex={tabIndex}
        className={className}
        pillClassName={pillClassName}
        readOnly={readOnly}
        previewShowLimit={previewShowLimit}
        tagIds={tagIds}
        multiselectTags={multiselectTags}
        inputRef={inputRef}
        triggerRef={triggerRef}
        studyId={studyId}
      />

      {usePortal && (
        <Portal>
          <div
            ref={menuRef}
            className={cn('w-72 z-40', dropdownClassName)}
            {...attributes}
            style={{ minHeight: 8, ...styles.popper }}
          >
            {items}
          </div>
        </Portal>
      )}

      {!usePortal && (
        <div ref={menuRef} className={dropdownClassName} {...attributes} style={{ minHeight: 8, ...styles.popper }}>
          {items}
        </div>
      )}
    </div>
  );
};
