import React from 'react';

import { Checkbox } from '@components/common';

export const StandardConsentForm: React.FC<{ name?: string }> = ({ name = '{{Your company}}' }) => (
  <div className='flex-1 p-4 mx-10 my-6 border border-gray-200 rounded-md'>
    <div className='flex items-start space-x-2'>
      <Checkbox disabled selected={false} className='mt-1' />
      <span>I understand that my data is stored for research purposes for {name}</span>
    </div>
    <div className='flex items-start space-x-2'>
      <Checkbox disabled selected={false} className='mt-1' />
      <span>I understand that I can request for my research participation data to be deleted at any time</span>
    </div>
    <div className='flex items-start space-x-2'>
      <Checkbox disabled selected={false} className='mt-1' />
      <span>I opt-in to being contacted for future studies</span>
    </div>
  </div>
);
