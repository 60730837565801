export type Options = { label: string; value: any }[];

export const sortOptions = (options) => {
  return options.sort((a, b) => {
    if (!a.label) return 1;
    if (!b.label) return -1;
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};
