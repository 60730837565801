import * as React from 'react';

import { api } from '@api/reduxApi';

import { AssetShareAccessDropdown } from './AssetShareAccessDropdown';

type Props = {
  readOnly?: boolean;
  insight: Insight;
};
export const InsightShareAccessDropdown: React.FC<Props> = ({ insight: { slug, access: initialAccess }, readOnly }) => {
  const [updateInsight] = api.useUpdateInsightMutation();

  return (
    <AssetShareAccessDropdown initialValue={initialAccess} onUpdate={(access) => updateInsight({ slug, access })} readOnly={readOnly} />
  );
};
