import { CancelEditModal } from 'components/shared/CancelEditModal/CancelEditModal';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import { EditorProps } from '@components/StudyMessages/hooks/useMessageEditor';
import { EditorRef, MessageEditor } from '@components/StudyMessages/MessageEditor';
import { CtaProps } from '@components/StudyMessages/components/DocumentPreview/CtaTooltip/helpers';

export interface Props extends EditorProps {
  editSlideOut?: boolean;
  sendPreview: () => Promise<void>;
  senders: EmailSender[];
  studyAssetsPage?: boolean;
  editDisabled?: boolean;
  ctaProps?: CtaProps;
}

export const EditorComponent = React.forwardRef<EditorRef, Props>((params, ref) => {
  const {
    message,
    ctaProps,
    edit,
    cancelModal,
    setCancelModal,
    setEdit,
    setIsDirty,
    editDisabled,
    discardChanges,
    senders
  } = params;

  if (!message) return <Skeleton duration={1} className='bg-gray-50 block w-full h-64 rounded-md' />;

  return (
    <>
      <MessageEditor
        ref={ref}
        {...params}
        message={message}
        senders={senders}
        editDisabled={editDisabled}
        editSlideOut={edit}
        edit={edit && !editDisabled}
        ctaProps={ctaProps}
        type='template'
      />
      <CancelEditModal
        discardMessage="Your email wasn't updated."
        setEdit={setEdit}
        setIsDirty={setIsDirty}
        onClose={() => setCancelModal(false)}
        cancelModal={cancelModal}
        onDiscard={discardChanges}
      />
    </>
  );
});
