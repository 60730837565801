import React, { useCallback, useEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { getScrollableParent } from '@helpers/getScrollableParent';

import { jumpToTs } from './utils/jumpToTs';

interface Params {
  disable?: boolean;
  track?: boolean;
  root?: Element;
  initialTs?: number | null;
}

interface UseTranscriptHook {
  update: (target: number) => void;
  ref: React.RefObject<HTMLDivElement>;
}

const SCROLL_STOP_DEBOUNCE = 500;

export const useTranscript = ({ disable, track, root, initialTs }: Params): UseTranscriptHook => {
  const timestampsRef = useRef<number[]>([]);
  const highlightRef = useRef<HTMLDivElement | null>(null);
  const scrollingRef = useRef<boolean>(false);

  const { callback: onStopScroll } = useDebouncedCallback(() => {
    scrollingRef.current = false;
  }, SCROLL_STOP_DEBOUNCE);

  const collect = useCallback(() => {
    if (disable) return;

    requestAnimationFrame(() => {
      const collected: number[] = [];
      const marks = document.querySelectorAll('[data-start-time]');

      if (marks) {
        marks.forEach((element) => {
          const startTime = element.getAttribute('data-start-time');
          if (startTime) collected.push(+startTime);
        });

        timestampsRef.current = collected;
      }
    });
  }, [disable]);

  useEffect(() => {
    if (!highlightRef.current || !initialTs) return;

    jumpToTs({
      ts: initialTs,
      timestamps: timestampsRef.current,
      highlight: highlightRef.current,
      root,
      enableAutoScroll: true,
      behavior: 'auto'
    });
  }, [highlightRef.current, root]);

  const update = useCallback(
    (target: number) => {
      if (!target || !highlightRef.current) return;

      jumpToTs({
        ts: target,
        timestamps: timestampsRef.current,
        highlight: highlightRef.current,
        root,
        enableAutoScroll: track && !scrollingRef.current,
        behavior: 'auto'
      });
    },
    [track, timestampsRef.current, root]
  );

  const onScroll = () => {
    scrollingRef.current = true;
    onStopScroll();
  };

  const eventListeners = useCallback(() => {
    const root = getScrollableParent(highlightRef.current);

    root?.addEventListener('scroll', onScroll);

    return () => {
      root?.removeEventListener('scroll', onScroll);
    };
  }, [highlightRef.current]);

  useEffect(collect, [collect, timestampsRef.current]);

  useEffect(eventListeners, [eventListeners]);

  return {
    update,
    ref: highlightRef
  };
};
