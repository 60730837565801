import React, { useMemo, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useBeforeunload } from 'react-beforeunload';
import * as portals from 'react-reverse-portal';
import { useParams, useSearchParams } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Button } from '@components/common';
import { Column, Grid } from '@components/common/Grid';
import { WindowLayout, WindowLayoutBody } from '@components/layouts/WindowLayout';
import { move } from '@helpers/move';
import { usePrompt } from '@hooks/usePrompt';

import { ArtifactWithDetails, HighlightReelsContext } from '../../hooks/useHighlightReelsContext';
import { Clips, Header, Preview } from './components';
import { HighlightsLimitModal } from '@components/shared/HighlightsLimitModal';
import { MAX_CLIPS } from '@components/HighlightReelsApp/consts';

export const Editor = () => {
  const { token = '' } = useParams<{ token: string }>();
  const [search] = useSearchParams();
  const artifacts = search.get('artifacts');
  const studyIdParam = search.get('project_id');
  const initialArtifacts = artifacts ? artifacts.split(',') : [];

  const [showClips, setShowClips] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [artifactIds, setArtifactIds] = useState<string[]>(initialArtifacts);
  const [clipForDetails, setClipForDetails] = useState<ArtifactWithDetails | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [playerIsLoading, setPlayerIsLoading] = useState<boolean>(false);

  const { data: highlightReel } = api.useGetHighlightReelQuery(token, {
    skip: !token
  });
  const [createHighlightReel] = api.useCreateHighlightReelMutation();
  const [updateHighlightReel] = api.useUpdateHighlightReelMutation();

  const studyId = highlightReel ? highlightReel.project_id : studyIdParam ? parseInt(studyIdParam) : null;

  const portalNode = useMemo(
    () =>
      portals.createHtmlPortalNode({
        attributes: {
          class: 'contents'
        }
      }),
    []
  );

  const onAddArtifacts = (artifactIds: string[]) => {
    if (artifactIds.length > MAX_CLIPS) {
      setModalOpen(true);
    } else {
      setArtifactIds(artifactIds);
    }
  };

  const save = (hr: Partial<HighlightReel>) =>
    token ? updateHighlightReel(hr).unwrap() : createHighlightReel({ ...hr, project_id: studyId }).unwrap();

  const onDragEnd = (result: DropResult) => {
    if (playerIsLoading) return;

    const { destination, source } = result;

    if (destination) {
      setArtifactIds(move<string>(artifactIds, source.index, destination.index));
    }
  };

  useBeforeunload((e) => {
    if (isDirty) {
      e.preventDefault();
    }
  });

  usePrompt('Are you sure you want to discard this highlight reel?', isDirty);

  const onAddToHighlightReel = () => {
    if (!clipForDetails) return;
    setArtifactIds([...artifactIds, clipForDetails.objectID]);
    setIsDirty?.(true);
    setClipForDetails(null);
  };

  const onRemove = () => {
    if (!clipForDetails) return;
    setArtifactIds(artifactIds.filter((ArtObjectID) => ArtObjectID !== clipForDetails.objectID));
    setIsDirty?.(true);
    setClipForDetails(null);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <HighlightReelsContext.Provider
        value={{
          highlightReelToken: token,
          artifactIds,
          isDirty,
          save,
          setArtifactIds: onAddArtifacts,
          setIsDirty,
          setShowClips,
          clipForDetails,
          setClipForDetails,
          playerIsLoading,
          setPlayerIsLoading,
          reelPreviewProps: { onAddToHighlightReel, onRemove }
        }}
      >
        <WindowLayout>
          <section className='desktop:h-screen desktop:overflow-hidden flex flex-col'>
            <Header
              highlightReel={highlightReel}
              studyId={studyId}
              className='desktop:px-12 px-4 pt-4 pb-2 bg-white border-b border-gray-200'
            />
            <WindowLayoutBody className='desktop:overflow-hidden flex-grow'>
              <Grid desktop={12} className='h-full'>
                {showClips ? (
                  <>
                    <Column desktop={6} className='bg-white'>
                      <portals.OutPortal node={portalNode} />
                    </Column>
                    <Column desktop={6} className='bg-white border-l border-gray-200'>
                      <Clips studyId={studyId} className='desktop:overflow-hidden flex flex-col h-full' />
                    </Column>
                  </>
                ) : (
                  <>
                    <Column desktop={3} className='bg-white' />
                    <Column desktop={6} className='bg-white'>
                      <portals.OutPortal node={portalNode} />
                    </Column>
                    <Column desktop={3} className='bg-white'>
                      <Button className='mt-6 ml-6' icon='plus' onClick={() => setShowClips(true)} small>
                        Add highlights
                      </Button>
                    </Column>
                  </>
                )}
              </Grid>
              {modalOpen && <HighlightsLimitModal onClose={() => setModalOpen(false)} />}
            </WindowLayoutBody>
          </section>
        </WindowLayout>
        <portals.InPortal node={portalNode}>
          <Preview className='flex flex-col max-h-full min-h-full px-3 py-6 overflow-auto' />
        </portals.InPortal>
      </HighlightReelsContext.Provider>
    </DragDropContext>
  );
};
