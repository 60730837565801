// the time slot strings that come out of buildTimeProposalSlots
// are missing seconds and timezone offset, they look like 2020-01-01T00:00
// this function turns it into a full ISO string

// getTimezoneOffset returns a number of minutes like +480
// this needs to be inverted and turned into an offset like -08:00

export const addTz = (timeStr: string): string => {
  const offsetInHours = Math.round(new Date().getTimezoneOffset() / 60);
  const sign = offsetInHours > 0 ? '-' : '+';
  const num = Math.abs(offsetInHours).toString().padStart(2, '0').slice(0, 2);

  return new Date(`${timeStr}:00${sign}${num}:00`).toISOString();
};
