import React, { VFC, useState } from 'react';
import { Controller, ControllerProps, Control } from 'react-hook-form';

import { Button } from '@components/common';
import {
  BlockableComponent,
  BlockableComponents,
  Enums,
  Models,
  getBlockLabel,
  isBlockOfKind
} from '@components/SurveyBuilder';

import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';
import * as Types from '../types';

import { ResponsiveCard } from './ResponsiveCard';

interface Props {
  block: Models.Block;
  control: Control<Types.FormData>;
  isValid: boolean;
}

const EMPTY_BLOCKABLE_BLOCKS: `${Enums.Kind}`[] = [Enums.Kind.info, Enums.Kind.welcome, Enums.Kind.thankYou];

export const Question: VFC<Props> = ({ block, control, isValid }) => {
  const { blocks } = useUnmoderatedContext();

  const renderer: ControllerProps<typeof BlockableComponent>['render'] = ({ value, onChange }) => {
    if (!block) {
      return <></>;
    }

    const { kind } = block;

    if (EMPTY_BLOCKABLE_BLOCKS.includes(kind)) {
      return <></>;
    }

    if (isBlockOfKind(block, Enums.Kind.linearScale)) {
      return (
        <BlockableComponent<BlockableComponents.LinearScale>
          kind={kind}
          componentProps={{ onChange, options: block.blockable.options }}
        />
      );
    }

    if (isBlockOfKind(block, Enums.Kind.multiSelect) || isBlockOfKind(block, Enums.Kind.singleSelect)) {
      return (
        <BlockableComponent<BlockableComponents.MultiSelect | BlockableComponents.SingleSelect>
          kind={kind}
          componentProps={{ onChange, options: block.blockable.options }}
        />
      );
    }

    if (isBlockOfKind(block, Enums.Kind.yesNo)) {
      return (
        <BlockableComponent<BlockableComponents.YesNo>
          kind={kind}
          componentProps={{ onChange, options: ['Yes', 'No'] }}
        />
      );
    }

    if (isBlockOfKind(block, Enums.Kind.date)) {
      return (
        <BlockableComponent<BlockableComponents.Date>
          kind={kind}
          componentProps={{
            onChange: (date) => onChange(date?.toString()),
            selected: value && typeof value === 'string' ? new Date(value) : null
          }}
        />
      );
    }

    return <BlockableComponent kind={kind} componentProps={{ onChange }} />;
  };

  if (!block) {
    return null;
  }

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={() => (
        <Button
          aria-label='Continue'
          className='btn-custom-brand'
          icon='arrowRight'
          type='submit'
          disabled={!isValid}
          small
          noStyle
        >
          Continue
        </Button>
      )}
    >
      <div className='my-4'>
        <Controller
          control={control}
          key={block.id}
          name='value'
          render={renderer}
          rules={{ required: block.required }}
        />
      </div>
    </ResponsiveCard>
  );
};
