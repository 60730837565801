import * as React from 'react';

import { Button } from '@components/common';
import { useCollectionView } from '@components/stores/view';
import { SUPPORT_EMAIL } from '@constants/emails';

import { NoResultsSVG } from '../NoResultsSVG';

export const NoSearchResultsZDS: React.FC<{ query: string }> = ({ query }) => {
  const { setView, view } = useCollectionView();
  return (
    <div className='max-w-xl py-6 mx-auto text-center'>
      <NoResultsSVG className='mx-auto mb-4' />
      <span className='block mb-2 text-2xl font-bold text-gray-700'>Sorry, we couldn’t find a match for “{query}”</span>
      <span className='block mb-6 text-gray-700'>
        It may be hiding somewhere. Double check your search for any typos or spelling errors - or try a different
        search term. If you need help finding something, you can always drop us a line at {SUPPORT_EMAIL}
      </span>
      {view?.tab !== 'all' && (
        <Button medium onClick={() => setView({ tab: 'all' })}>
          View all repository results
        </Button>
      )}
    </div>
  );
};
