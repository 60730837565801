import cn from 'classnames';
import * as React from 'react';
import { useState } from 'react';

import { DropdownCombobox, Text } from '@components/common';
import { DropdownItem } from '@components/common/DropdownCombobox';
import { PlusSVG } from '@components/svgs';
import { useOnEscape } from '@components/utils';
import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut';
import Tippy from '@tippyjs/react';

import { UseTableFilters } from '../hook/useTableFilters';

type Props = {
  className?: string;
  advancedLimits?: boolean;
  hook: UseTableFilters<any>;
  defaultShowInput?: boolean;
};

const renderDropdownItem = (item: DropdownItem, icons: Record<string, React.FC>): React.ReactElement => (
  <div className='flex flex-row items-center justify-between'>
    <div className='flex items-center justify-center w-4 h-4 mr-3'>
      {icons[item.value] && React.createElement(icons[item.value])}
    </div>
    <span className='h400 flex-1'>{item.label}</span>
    {item.value.match(/segment/) && <span className='h200 group-hover:text-white text-gray-500'>segment</span>}
  </div>
);

const renderDropdownInput: React.FC<{ className?: string; inputProps: any }> = ({ className, inputProps }) => (
  <input
    {...inputProps}
    className={cn(
      className,
      'border border-gray-200 h400 focus:border-indigo-600 rounded-md py-1.5 px-4 text-gray-700 placeholder-gray-400 focus:outline-none'
    )}
    placeholder='Add filter'
    autoComplete='off'
    autoFocus
  />
);

const renderFilterButton = (
  advancedLimits: boolean | undefined,
  className = '',
  handleAddFilter: any,
  highlightShortcut: string[],
  filters: any
): React.ReactElement =>
  advancedLimits ? (
    <button
      className={cn(
        'focus:ring focus:ring-blue focus:outline-none hover:text-indigo-600 flex items-center h-8 px-2 space-x-2 border rounded-md',
        className
      )}
      onClick={handleAddFilter}
    >
      <PlusSVG />
      {!filters.length && (
        <Text h='400' medium>
          Add criteria
        </Text>
      )}
    </button>
  ) : (
    <Tippy
      placement='top'
      arrow={false}
      content={
        <>
          Filter{'  '}
          <Text h='400' className='inline' color='gray-400'>
            {highlightShortcut}
          </Text>
        </>
      }
    >
      <button
        className={cn(
          className,
          'focus:ring focus:ring-blue focus:outline-none hover:text-indigo-600 flex items-center rounded-md bg-white px-4 py-1.5 space-x-2 border'
        )}
        onClick={handleAddFilter}
      >
        <PlusSVG />
        <Text h='400' medium>
          Filter
        </Text>
      </button>
    </Tippy>
  );

export const AddFilterButton: React.FC<Props> = ({
  className,
  advancedLimits,
  hook: { definitions, addFilter, filters },
  defaultShowInput = false
}) => {
  const [showFilterInput, setShowFilterInput] = useState(defaultShowInput);
  const currentlyAppliedIds = filters.filter((f) => f.definition.onlyAllowOne).map((f) => f.definition.id);
  const items = definitions
    .filter(({ id }) => !currentlyAppliedIds.includes(id))
    .map(({ id, name }) => ({ label: name, value: id }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const icons: Record<string, React.FC> = definitions.reduce((acc, d) => {
    acc[d.id] = d.Icon;
    return acc;
  }, {});

  const handleAddFilter = () => setShowFilterInput(true);

  const onSelect = (item: DropdownItem) => {
    const def = definitions.find((d) => d.id === item?.value);

    if (def) {
      addFilter(def);
    }

    setShowFilterInput(false);
  };
  useOnEscape(() => {
    setShowFilterInput(false);
  });
  const { shortcut: highlightShortcut } = useKeyboardShortcut({
    keys: { I: true, meta: true },
    handler: handleAddFilter,
    disabled: showFilterInput
  });

  return showFilterInput ? (
    <DropdownCombobox
      unlimitedItems
      inputClassName={className}
      tight
      autoFocus
      defaultIsOpen
      items={items}
      onSelect={onSelect}
      onClickOutside={() => setShowFilterInput(false)}
      placeholder='Add filter'
      renderInput={(inputProps) => renderDropdownInput({ className, inputProps })}
      renderItem={(item) => renderDropdownItem(item, icons)}
      submitOnEnter
      limitedWidthDropdown
    />
  ) : (
    renderFilterButton(advancedLimits, className, handleAddFilter, highlightShortcut, filters)
  );
};
