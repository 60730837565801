import { usePlan } from '@hooks/usePlan';
import React, { useState } from 'react';

import { Button, Text } from '@components/common';
import { skipToken } from '@reduxjs/toolkit/query/react';

import { useGetProviderQuery } from './api';
import { SamlFormModal } from './SamlFormModal';

export const SamlProvider: React.FC = () => {
  const { hasFeature } = usePlan();
  const hasSaml = hasFeature('saml');
  const { data, isLoading } = useGetProviderQuery(hasSaml ? undefined : skipToken);
  const [showModal, setShowModal] = useState<boolean>(false);

  if (!hasSaml) {
    return null;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (data) {
    return <Text>Configured - {data.idp_sso_target_url}</Text>;
  }

  return (
    <>
      <div className='flex flex-row items-center space-x-4'>
        <p>Not configured</p>
        <Button onClick={() => setShowModal(true)}>Setup</Button>
      </div>
      {showModal && <SamlFormModal onSuccess={() => setShowModal(false)} onCancel={() => setShowModal(false)} />}
    </>
  );
};
