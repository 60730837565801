import classNames from 'classnames';
import * as React from 'react';

import { InfoCircleIcon } from '@components/svgs';
import Tippy, { TippyProps } from '@tippyjs/react';

// TODO: handle other icons

interface Props extends Omit<TippyProps, 'children'> {
  type?: 'info';
}

const ICONS: Record<'info', React.FC<{ className?: string }>> = {
  info: InfoCircleIcon
};

export const Tooltip: React.FC<Props> = ({ children, type = 'info', className, ...rest }) => {
  const Icon = ICONS[type];

  return (
    <Tippy
      aria={{ content: 'labelledby', expanded: 'auto' }}
      appendTo={() => document.body}
      interactive
      content={children}
      {...rest}
    >
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <span tabIndex={0} role='tooltip' className={classNames('w-4 h-4', className)}>
        <Icon className='inline-block w-4 h-4 align-top cursor-pointer' />
      </span>
    </Tippy>
  );
};
