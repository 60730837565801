import * as React from 'react';

export const BuildingSvg = () => (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.733337 21.2667H21.2667M3.48334 3.4834H18.5167H3.48334ZM3.48334 3.4834H18.6083V21.3584H3.48334V3.4834ZM18.5167 21.2667H3.48334H18.5167ZM18.5167 3.4834H3.48334L4.76667 0.733398H17.2333L18.5167 3.4834Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.875 6.50837C6.69166 6.50837 6.50833 6.4167 6.50833 6.23337M13.0167 19.25C13.0167 18.15 12.1 17.2334 11 17.2334C9.9 17.2334 8.98333 18.15 8.98333 19.25V21.2667H13.1083V19.25H13.0167ZM6.875 5.8667C7.05833 5.8667 7.24166 6.05003 7.24166 6.23337L6.875 5.8667ZM6.50833 6.23337C6.50833 6.05003 6.69166 5.8667 6.875 5.8667L6.50833 6.23337Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.875 10.6334C6.69166 10.6334 6.50833 10.4501 6.50833 10.2667M7.24166 6.2334C7.24166 6.41673 7.05833 6.60006 6.875 6.60006L7.24166 6.2334ZM6.875 9.99173C7.05833 9.99173 7.24166 10.1751 7.24166 10.3584L6.875 9.99173ZM6.50833 10.2667C6.50833 10.0834 6.69166 9.90006 6.875 9.90006L6.50833 10.2667Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.50833 14.4833C6.50833 14.2999 6.69166 14.1166 6.875 14.1166M7.24166 10.2666C7.24166 10.4499 7.05833 10.6333 6.875 10.6333L7.24166 10.2666ZM6.875 14.1166C7.05833 14.1166 7.24166 14.2999 7.24166 14.4833L6.875 14.1166Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6333 6.23337C10.6333 6.05003 10.8167 5.8667 11 5.8667M6.875 14.7584C6.69166 14.7584 6.50833 14.575 6.50833 14.3917L6.875 14.7584ZM7.24166 14.4834C7.24166 14.6667 7.05833 14.85 6.875 14.85L7.24166 14.4834ZM11 5.8667C11.1833 5.8667 11.3667 6.05003 11.3667 6.23337L11 5.8667Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11 10.6333C10.8167 10.6333 10.6333 10.4499 10.6333 10.2666M11 6.50827C10.8167 6.50827 10.6333 6.32493 10.6333 6.1416L11 6.50827ZM11.3667 6.23327C11.3667 6.4166 11.1833 6.59993 11 6.59993L11.3667 6.23327ZM11 9.9916C11.1833 9.9916 11.3667 10.1749 11.3667 10.3583L11 9.9916ZM10.6333 10.2666C10.6333 10.0833 10.8167 9.89994 11 9.89994L10.6333 10.2666Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.6333 14.4833C10.6333 14.2999 10.8167 14.1166 11 14.1166M11.3667 10.2666C11.3667 10.4499 11.1833 10.6333 11 10.6333L11.3667 10.2666ZM11 14.1166C11.1833 14.1166 11.3667 14.2999 11.3667 14.4833L11 14.1166Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.7583 6.23337C14.7583 6.05003 14.9417 5.8667 15.125 5.8667M11 14.7584C10.8167 14.7584 10.6333 14.575 10.6333 14.3917L11 14.7584ZM11.3667 14.4834C11.3667 14.6667 11.1833 14.85 11 14.85L11.3667 14.4834ZM15.125 5.8667C15.3083 5.8667 15.4917 6.05003 15.4917 6.23337L15.125 5.8667Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.125 10.6333C14.9417 10.6333 14.7583 10.4499 14.7583 10.2666M15.125 6.50827C14.9417 6.50827 14.7583 6.32493 14.7583 6.1416L15.125 6.50827ZM15.4917 6.23327C15.4917 6.4166 15.3083 6.59993 15.125 6.59993L15.4917 6.23327ZM15.125 9.9916C15.3083 9.9916 15.4917 10.1749 15.4917 10.3583L15.125 9.9916ZM14.7583 10.2666C14.7583 10.0833 14.9417 9.89994 15.125 9.89994L14.7583 10.2666Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.7583 14.4833C14.7583 14.2999 14.9417 14.1166 15.125 14.1166M15.4917 10.2666C15.4917 10.4499 15.3083 10.6333 15.125 10.6333L15.4917 10.2666ZM15.125 14.1166C15.3083 14.1166 15.4917 14.2999 15.4917 14.4833L15.125 14.1166Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.4917 14.4833C15.4917 14.6666 15.3083 14.8499 15.125 14.8499M15.125 14.7583C14.9417 14.7583 14.7583 14.5749 14.7583 14.3916L15.125 14.7583Z'
      stroke='#E5E7EB'
      strokeWidth='1.375'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
