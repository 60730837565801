import React, { SVGProps } from 'react';

export const ChevronLeftSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} fill='none' height='14' viewBox='0 0 8 14' width='8' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m7.47918 13.5625-6.25333-6.25333c-.04064-.04058-.07288-.08878-.09488-.14183-.02199-.05305-.03331-.10991-.03331-.16734s.01132-.1143.03331-.16734c.022-.05305.05424-.10125.09488-.14183l6.25333-6.25333'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='.875'
    />
  </svg>
);
