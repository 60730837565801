import * as React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, IconButton } from '@components/common';
import { PageTitle } from '@components/common/helmets';
import { WindowLayout } from '@components/layouts/WindowLayout';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { useTiptapFromGuideToken } from '@components/shared/Tiptap';
import { BackArrowSVG } from '@components/svgs';
import { useAccount } from '@hooks/useAccount';
import { useToaster } from '@stores/toaster';

import * as toasts from '../toasts';
import { BackgroundSlideout } from './BackgroundSlideout';
import { EditableTitle } from './EditableTitle';
import { PublishedTitle } from './PublishedTitle';
import { TextEditorWrapper } from '@components/shared/TextEditorWrapper';
import { usePublishGuideMutation, useUpdateGuideMutation } from '@components/GuidesApp/api';

interface Props {
  editable?: boolean;
  guide: Guide;
}

export const GuidePage: React.FC<Props> = ({ editable, guide }) => {
  const [publishGuide] = usePublishGuideMutation();
  const [updateGuide] = useUpdateGuideMutation();

  const showToast = useToaster();

  const navigate = useNavigate();

  const canUpdate = true; // usePermission

  const {
    account: { id: accountId }
  } = useAccount();

  const isFromThisAccount = accountId === guide.account_id;

  const [publishing, setPublishing] = useState(false);
  async function handleClickPublish() {
    setPublishing(true);

    try {
      await publishGuide(guide.token).unwrap();

      showToast(toasts.successPublish());
    } catch {
      showToast(toasts.failedUpdate());
    }

    setPublishing(false);
  }
  async function handleClickSave() {
    setPublishing(true);
    navigate(`/guides/${guide.token}`);
  }

  const [bgSlideoutOpen, setBgSlideoutOpen] = useState(false);

  async function handleChangeColor(bg_color: string) {
    try {
      await updateGuide({ token: guide.token, bg_color }).unwrap();
    } catch {
      showToast(toasts.failedUpdate());
    }
  }

  const tiptap = useTiptapFromGuideToken({
    documentId: guide.document_id,
    guideToken: guide.token,
    config: {
      artifacts: true,
      image: { enable: true },
      link: { enable: true },
      highlight: { enable: false },
      placeholder: 'Write learnings, embed interviews, and so much more…'
    },
    readonly: !editable
  });

  return (
    <WindowLayout noSidebar>
      <PageTitle>{guide.title || ''}</PageTitle>
      <div className='px-page flex items-center justify-between w-full py-4 bg-white border-b border-gray-200'>
        {editable && guide.published ? (
          <Link aria-label='back' className='inline-block p-2' to={`/guides/${guide.token}`}>
            <BackArrowSVG className='text-indigo-600' />
          </Link>
        ) : (
          <Link className='inline-block p-2' to='/guides'>
            <BackArrowSVG className='text-indigo-600' />
          </Link>
        )}

        <div className='flex items-center space-x-6'>
          {!guide.published && (
            <Button disabled={publishing} onClick={handleClickPublish} primary>
              Publish
            </Button>
          )}
          {guide.published && editable && (
            <Button disabled={publishing} onClick={handleClickSave} primary>
              Save
            </Button>
          )}
          {guide.published && isFromThisAccount && !editable && (
            <IconButton as='Link' name='edit' icon='pencil' href={`/guides/${guide.token}/edit`}>
              Edit
            </IconButton>
          )}
          {guide.published && !editable && (
            <CopyURLButton text={location.href} primary>
              Copy link
            </CopyURLButton>
          )}
        </div>
      </div>
      <TextEditorWrapper
        saving={publishing}
        showMenu={canUpdate && editable}
        tiptap={tiptap}
        renderCover={() => (
          <div
            id='xx-guide-header'
            className='h-80 flex flex-col p-6 pb-4 rounded-md'
            style={{ backgroundColor: `#${guide.bg_color || '374151'}` }}
          >
            <div className='flex-grow'>
              {editable && canUpdate && (
                <div className='flex justify-end'>
                  <Button onClick={() => setBgSlideoutOpen(true)}>Customize background</Button>
                </div>
              )}
            </div>
            {editable && canUpdate ? (
              <EditableTitle className='h700 px-4 py-3 rounded-md' guide={guide} />
            ) : (
              <PublishedTitle guide={guide} />
            )}
          </div>
        )}
      />

      {bgSlideoutOpen && (
        <BackgroundSlideout guide={guide} onChangeColor={handleChangeColor} onClose={() => setBgSlideoutOpen(false)} />
      )}
    </WindowLayout>
  );
};
