import { api } from '@api/reduxApi';

interface AiMessageParams {
  chat_id: string;
  content: string;
}

interface AiChatFeedbackParams {
  chat_id: string;
  message_id: string;
  rating: number;
}

const chatApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createAiChat: builder.mutation<AiChat, Pick<AiChat, 'context'>>({
      query: (chat) => ({
        url: '/ai/chats',
        method: 'POST',
        body: { chat },
        transformResponse: (response: any) => response.data
      })
    }),
    createAiMessage: builder.mutation<AiMessage, AiMessageParams>({
      query: ({ chat_id, content }) => ({
        url: `/ai/chats/${chat_id}/messages`,
        method: 'POST',
        body: { message: { content } },
        transformResponse: (response: any) => response.data
      })
    }),
    submitMessageFeedback: builder.mutation<any, AiChatFeedbackParams>({
      query: ({ chat_id, message_id, rating }) => ({
        url: `/ai/chats/${chat_id}/messages/${message_id}/feedback`,
        method: 'POST',
        body: { feedback: { rating } }
      })
    })
  })
});

export const { useCreateAiChatMutation, useCreateAiMessageMutation, useSubmitMessageFeedbackMutation } = chatApi;
