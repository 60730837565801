import * as React from 'react';

import { Button, Text } from '@components/common';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { usePopUp } from '@hooks/usePopUp';

import { Option } from './Option';
import { ACCESS_DESCRIPTION, ACCESS_LABELS } from '../consts';

type Props = {
  readOnly?: boolean;
  value: ShareAccess;
  onChange: (value: ShareAccess) => void;
  linkUrl: string;
  aboveButton?: boolean;
  primary?: boolean;
};

export const ShareAccessDropdown: React.FC<Props> = ({
  readOnly,
  primary = true,
  aboveButton,
  value = 'public',
  onChange,
  linkUrl
}) => {
  const { PopUp, togglePopUp, open, ref } = usePopUp();

  return (
    <div className='relative' ref={ref}>
      <Button data-testid='share' icon={value === 'public' ? 'world' : 'lock'} primary={primary} onClick={togglePopUp}>
        Share
      </Button>
      <PopUp zIndex={50} className={aboveButton ? 'bottom-12 left-0' : 'mt-2 right-0'} open={open}>
        <div className='overflow-hidden bg-white border border-gray-200 rounded-md shadow-md'>
          {!readOnly && (
            <>
              <Option
                value='public'
                checked={value === 'public'}
                onClick={value === 'private' ? () => onChange('public') : undefined}
              />
              <Option
                value='private'
                checked={value === 'private'}
                onClick={value === 'public' ? () => onChange('private') : undefined}
              />
            </>
          )}
          {readOnly && (
            <div className='p-4'>
              <Text bold>{ACCESS_LABELS[value]}</Text>
              <Text h='400'>{ACCESS_DESCRIPTION[value]}</Text>
            </div>
          )}
          <div className='border-t border-gray-200'>
            <div className='p-4 text-right'>
              <CopyURLButton icon='link' small text={linkUrl}>
                Copy link
              </CopyURLButton>
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  );
};
