import { track } from 'components/tracking';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';
import { useRenderAvatars } from '@components/RepositoryApp/hooks/useRenderAvatars';
import { InterviewCard } from '@components/shared/InterviewCard';

const PanelHeader: React.FC<{ study: Study }> = ({ study }) => {
  const publicUrl = window.location.protocol + '//' + window.location.host + '/' + study.public_path;
  return (
    <>
      <div className='md:flex md:items-start md:justify-between mt-2 mb-2'>
        <div className='flex-1 min-w-0'>
          <h2 className='sm:text-3xl sm:leading-9 sm:truncate text-2xl font-bold text-gray-700'>{study.title}</h2>
        </div>
        <div className='flex-0 space-x-2'></div>
      </div>
    </>
  );
};

type StudyTab = 'landing_page' | 'welcome_email' | 'settings' | 'survey' | 'screener' | 'activities';
interface StudyTabProps {
  study: Study;
  setStudy: (study: Study) => void;
  saving: boolean;
  setSaving: (saving: boolean) => void;
}

// const TAB_LABELS: {[key in StudyTab]?: string}  = {
//   landing_page: 'Landing Page',
//   survey: 'Signup form',
//   welcome_email: 'Welcome email',
//   activities: 'Activity log',
//   settings: 'Settings',
// }

export const Phantom: React.FC<{ study: Study }> = ({ study }) => {
  const { renderAvatars } = useRenderAvatars();
  const { data: participations = [], isLoading: fetching, refetch } = api.useGetParticipationsQuery({ id: study.id });

  useEffect(() => {
    track('viewed_study', { style: study.style });
  }, []);

  return (
    <div>
      <div className='px-6 pt-6 bg-white border-b border-gray-200'>
        <PanelHeader study={study} />
      </div>

      <div className='bg-gray-50 md:p-6 relative p-4'>
        {fetching && (
          <div className='relative flex items-center justify-center px-24 py-24'>
            <Loading bg='transparent' />
          </div>
        )}
        {!fetching &&
          participations.length > 0 &&
          participations.map((interview) => (
            <InterviewCard
              className='inline-block my-1 cursor-pointer'
              loading={false}
              href={`/interviews/${interview.token}`}
              interviewAt={interview.interview_at}
              recordingId={interview.recording_id as any}
              studyTitle={study.title}
              userIds={[interview.moderator_id]}
              token={interview.token}
              candidateName={interview.name || ''}
              showLink={!!interview.token}
              renderAvatars={renderAvatars}
            />
          ))}
        {!fetching && participations.length === 0 && (
          <div className='relative flex items-center justify-center px-24 py-24'>
            <div className='text-center text-gray-500'>
              <div className='mb-4 text-4xl font-bold'>No Participants Yet</div>
              <div className='text-xl'>
                This study was created for incentive purposes.
                <br /> You can send incentives to candidates through this study by going to{' '}
                <Link to='/incentives'>Incentives</Link>.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
