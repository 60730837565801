import React, { useEffect, useState } from 'react';

import { Button, FormGroup, Input, Loading, Modal, Text, Textarea } from '@components/common';

import { useSetProviderMutation } from './api';
import { SamlAuthProvider } from './types';

export const SamlFormModal = ({ onSuccess, onCancel }) => {
  const [provider, setProvider] = useState<SamlAuthProvider>({
    idp_sso_target_url: '',
    idp_entity_id: '',
    idp_cert: ''
  });
  const [update, { isSuccess, isLoading }] = useSetProviderMutation();

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const handleSubmit = () => {
    update(provider);
  };

  return (
    <Modal
      title='SAML Provider Configuration'
      size='lg'
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={handleSubmit}>
            Save
          </Button>
        </>
      )}
      onClose={onCancel}
    >
      {isLoading && <Loading absolute />}
      <FormGroup>
        <Text bold mb='2'>
          IDP SSO target URL
        </Text>
        <Input
          className='w-full'
          value={provider.idp_sso_target_url}
          onChange={(v) => setProvider({ ...provider, idp_sso_target_url: v })}
        />
      </FormGroup>
      <FormGroup>
        <Text bold mb='2'>
          IDP Entity
        </Text>
        <Input
          className='w-full'
          value={provider.idp_entity_id}
          onChange={(v) => setProvider({ ...provider, idp_entity_id: v })}
        />
      </FormGroup>
      <FormGroup>
        <Text bold mb='2'>
          IDP Certificate
        </Text>
        <Textarea
          className='max-h-72 max-w-60'
          style={{ fontFamily: 'monospace' }}
          value={provider.idp_cert}
          onChange={(v) => setProvider({ ...provider, idp_cert: v })}
        />
      </FormGroup>
    </Modal>
  );
};
