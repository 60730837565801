import React, { useMemo, useState } from 'react';
import cn from 'classnames';

import { Popper, Tabs } from '@components/common';
import { MultiselectDropdown } from '@components/common/MultiselectDropdown';
import { MultiselectDropdownItem } from '@components/shared/MultiselectCombobox';
import { Tooltip } from '@components/shared/Tooltip';
import { PlusSVG } from '@components/svgs';
import { composeEventHandlers } from '@helpers/composeEventHandlers';
import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';
import { useSearch } from '@hooks/useSearch';
import { GridTableTabs } from '@components/shared/GridTable/components';

interface Props {
  onVisibileColumnsChange: (columnIds: string[]) => void;
  items: MultiselectDropdownItem<string>[];
  tabs?: GridTableTabs;
  selectedValues: string[];
}

export const TableSidebar = createPolymorphicComponent<Props, 'section'>(
  ({
    as: Component = 'section',
    innerRef,
    selectedValues,
    className,
    tabs,
    onVisibileColumnsChange,
    items,
    ...rest
  }) => {
    const initialTab = tabs ? Object.keys(tabs.labels)[0] : null;

    const [search, setSearch] = useState('');
    const [tab, setTab] = useState<string | null>(initialTab);

    const activeItems = useMemo<MultiselectDropdownItem<string>[]>(
      () => items.filter(({ value }) => (tabs ? tabs.filterFunc(value, tab ?? tabs.labels[0]) : true)),
      [items, tab, tabs]
    );

    const sortedItems = useMemo<MultiselectDropdownItem<string>[]>(
      () =>
        [...activeItems].sort((a, b) => {
          const selectedItemsA = selectedValues.includes(a.value) ? -1 : 1;
          const selectedItemsB = selectedValues.includes(b.value) ? -1 : 1;

          if (selectedItemsA !== selectedItemsB) {
            return selectedItemsA - selectedItemsB;
          }

          return a.label.localeCompare(b.label);
        }),
      [activeItems, selectedValues]
    );

    const { results } = useSearch(sortedItems, ['label'], search, [search, sortedItems]);

    const handleOnSave = (values: string[], closeDropdown: () => void) => {
      onVisibileColumnsChange(values);
    };

    return (
      <Component ref={innerRef} className={cn('items-start flex justify-center', className)} {...rest}>
        <Popper
          content={({ closePopper }) => (
            <MultiselectDropdown
              className='max-w-md border border-gray-200 shadow-md'
              onSearch={setSearch}
              dropdownClass='absolute top-8 right-8 w-112 xx-multi-dropdown'
              selectedValues={selectedValues}
              onSave={composeEventHandlers((values: string[]) => handleOnSave(values, closePopper), closePopper)}
              items={results}
            >
              {tabs && (
                <div className='w-auto mb-2'>
                  <Tabs tabs={Object.keys(tabs.labels)} labels={tabs.labels} current={tab || ''} onSelect={setTab} />
                </div>
              )}
            </MultiselectDropdown>
          )}
          closeOnClickOutside
          closeOnEscape
          onClose={() => setSearch('')}
        >
          <div>
            <Tooltip content='Add column'>
              <button
                aria-label='Add column'
                name='add column'
                data-testid='add column'
                className='hover:bg-gray-50 flex items-center justify-center w-10 h-10 mt-1 rounded-full'
              >
                <PlusSVG />
              </button>
            </Tooltip>
          </div>
        </Popper>
      </Component>
    );
  }
);
