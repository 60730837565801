import React from 'react';

import { Table } from '@components/shared/Table';

import * as Types from '../types';

export const baseColumnDefinitions: Types.DefinitionFn = ({ getColumnLabel, onParticipationClick, addFilter }) => {
  return [
    {
      accessorKey: 'id',
      id: 'id',
      size: 48,
      cell: ({ row }) => (
        <Table.Checkbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
      header: ({ table }) => (
        <Table.Checkbox
          title='Toggle All Rows Selected'
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      )
    },
    {
      id: 'name',
      accessorKey: 'name',
      cell: (props) => (
        <Table.Cell<Participation>
          {...props}
          render={({ row }) => (
            <button
              aria-label={row.original.name ?? 'Unnamed candidate'}
              className='xx-candidate-link focus:outline-none hover:underline max-w-full px-2 font-bold text-indigo-600 truncate'
              onClick={() => onParticipationClick?.(row.original)}
            >
              {row.original.name ?? 'Unnamed candidate'}
            </button>
          )}
        />
      ),
      header: (props) => (
        <Table.ColumnHeader {...props} addFilter={addFilter} disableHide>
          {getColumnLabel?.('name')}
        </Table.ColumnHeader>
      ),
      size: 250
    }
  ];
};
