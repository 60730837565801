import * as React from 'react';

export enum EScreenSizes {
  DESKTOP_L = 'DESKTOP_L',
  DESKTOP_M = 'DESKTOP_M',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE'
}

export const DEVICE_WIDTH = {
  [EScreenSizes.MOBILE]: 768,
  [EScreenSizes.TABLET]: 1024,
  [EScreenSizes.DESKTOP_M]: 1400
};

type SelectProps = { [k in keyof typeof EScreenSizes]?: any } & { DEFAULT?: any };

export interface Hook {
  isMobile: boolean;
  isTablet: boolean;
  isDesktopL: boolean;
  isDesktopM: boolean;
  device: {
    select: (obj: SelectProps) => any;
  };
}

const getDeviceType = (): EScreenSizes => {
  const wind: Window = window;
  const docu: HTMLElement = document.documentElement;
  const body: HTMLElement = document.getElementsByTagName('body')[0] || ({ clientWidth: 0 } as HTMLElement);

  const width = wind.innerWidth || docu.clientWidth || body.clientWidth;
  if (width < DEVICE_WIDTH[EScreenSizes.MOBILE]) return EScreenSizes.MOBILE;
  if (width >= DEVICE_WIDTH[EScreenSizes.MOBILE] && width < DEVICE_WIDTH[EScreenSizes.TABLET]) {
    return EScreenSizes.TABLET;
  }
  if (width >= DEVICE_WIDTH[EScreenSizes.TABLET] && width < DEVICE_WIDTH[EScreenSizes.DESKTOP_M]) {
    return EScreenSizes.DESKTOP_M;
  }

  return EScreenSizes.DESKTOP_L;
};

export const useDeviceType = (): Hook => {
  const [deviceType, setDeviceType] = React.useState<EScreenSizes>(getDeviceType());

  const handleResize = () => {
    const type = getDeviceType();

    if (deviceType !== type) {
      setDeviceType(type);
    }
  };

  // effects
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [deviceType]);

  const isMobile = deviceType === EScreenSizes.MOBILE;
  const isTablet = deviceType === EScreenSizes.TABLET;
  const isDesktopM = deviceType === EScreenSizes.DESKTOP_M;
  const isDesktopL = deviceType === EScreenSizes.DESKTOP_L;

  const select: Hook['device']['select'] = (obj) => {
    return obj[deviceType] || obj['DEFAULT'];
  };

  return {
    isMobile,
    isTablet,
    isDesktopL,
    isDesktopM,
    device: {
      select
    }
  };
};
