import * as React from 'react';

import { Portal } from '@components/common/Portal';
import { useDeviceType } from '@hooks/useDeviceType';
import { useAiChat } from '@stores/aiChat';

import { Provider as SidebarProvider, Sidebar } from './Sidebar';
import { AiChatSlideOut, FloatingChatButton } from '@components/AiChat';
import { InvalidConnectedAccount } from 'components/shared/InvalidConnectedAccount';
import { emptyFallback, ErrorBoundary } from 'components/ErrorBoundary';

type Props = {
  sidebar?: boolean;
  aiChat?: boolean;
};

export const Container: React.FC<Props> = ({ children, sidebar, aiChat }) => {
  const { isMobile } = useDeviceType();
  const { enabled, mode } = useAiChat();

  return (
    <div id='Container' className='flex h-full'>
      <SidebarProvider>
        {sidebar && (
          <Portal id={isMobile ? 'sidebar-mobile-portal' : 'sidebar-portal'}>
            <Sidebar />
          </Portal>
        )}
        <div className='relative flex flex-1 max-w-full'>
          <div className='flex-1 max-w-full'>
            {sidebar && (
              <ErrorBoundary fallback={emptyFallback}>
                <Portal id='invalid-account-portal'>
                  <InvalidConnectedAccount />
                </Portal>
              </ErrorBoundary>
            )}
            {children}
          </div>
        </div>
        {aiChat && enabled && (
          <Portal id={'ask-ai-slideout-portal'}>{mode === 'slideout' && <AiChatSlideOut />}</Portal>
        )}
      </SidebarProvider>
    </div>
  );
};
