import React, { SVGProps } from 'react';

export const TickSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 8.89091L5.42857 12.8182L14 3'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
