import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { track } from '@components/tracking';

import { startMuxUpload } from '../utils/startMuxUpload';

const DEFAULT_NUM_UPLOADS_IN_PROGRESS_LIMIT = 6;

type Options = {
  numUploadsInProgressLimit?: number;
};

export const useUploadFiles = (
  items: RecordingItem[],
  dispatchSetItem: (item: Patch<RecordingItem>) => void,
  opts?: Options
): void => {
  const [updateRepoSession, {}] = api.useUpdateRepoSessionMutation();
  const [getMuxDirectUploadUrl] = api.useGetMuxDirectUploadUrlMutation();
  const [createRecording] = api.useCreateRecordingMutation();

  const begin = async (item: RecordingItem) => {
    const { url: endpoint, passthrough: mux_passthrough } = await getMuxDirectUploadUrl().unwrap();

    const abort = await startMuxUpload(item, endpoint, {
      onProgress: (progress) => {
        dispatchSetItem({ id: item.id, progress });
      },
      onError: () => {
        dispatchSetItem({ id: item.id, status: 'errored' });
      },
      onSuccess: async () => {
        const { name: filename, size: byte_size } = item.file;
        const recording = await createRecording({ mux_passthrough, filename, byte_size }).unwrap();

        updateRepoSession({ uuid: item.session.uuid, recording_id: recording.id })
          .unwrap()
          .then((data) => {
            dispatchSetItem({ id: item.id, status: 'done', session: data });
          });
        track('added_recording', { recording: recording, method: 'manual' });
      }
    });

    const cancelUpload = () => {
      dispatchSetItem({ id: item.id, status: 'canceled' });
      abort();
    };
    dispatchSetItem({ id: item.id, status: 'uploading', progress: 0, cancelUpload });
  };

  const inProgressItems = items.filter((item) => item.status === 'uploading');
  const queuedItems = items.filter((item) => item.status === 'queued');
  const numUploadsInProgressLimit = opts?.numUploadsInProgressLimit || DEFAULT_NUM_UPLOADS_IN_PROGRESS_LIMIT;
  useEffect(() => {
    if (inProgressItems.length < numUploadsInProgressLimit && queuedItems.length > 0) {
      begin(queuedItems[0]);
    }
  }, [inProgressItems.length, queuedItems.length, queuedItems[0]?.id]);
};
