import React, { SVGProps } from 'react';

export const BubbleKebabSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.75 12.0229C0.75 12.7191 1.02656 13.3868 1.51884 13.8791C2.01113 14.3714 2.67881 14.6479 3.375 14.6479C4.07119 14.6479 4.73887 14.3714 5.23116 13.8791C5.72344 13.3868 6 12.7191 6 12.0229C6 11.3268 5.72344 10.6591 5.23116 10.1668C4.73887 9.67451 4.07119 9.39795 3.375 9.39795C2.67881 9.39795 2.01113 9.67451 1.51884 10.1668C1.02656 10.6591 0.75 11.3268 0.75 12.0229V12.0229Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18 12.0229C18 12.3677 18.0679 12.709 18.1998 13.0275C18.3317 13.346 18.5251 13.6354 18.7688 13.8791C19.0126 14.1229 19.302 14.3162 19.6205 14.4481C19.9389 14.5801 20.2803 14.6479 20.625 14.6479C20.9697 14.6479 21.3111 14.5801 21.6295 14.4481C21.948 14.3162 22.2374 14.1229 22.4812 13.8791C22.7249 13.6354 22.9183 13.346 23.0502 13.0275C23.1821 12.709 23.25 12.3677 23.25 12.0229C23.25 11.6782 23.1821 11.3369 23.0502 11.0184C22.9183 10.6999 22.7249 10.4105 22.4812 10.1668C22.2374 9.92304 21.948 9.72968 21.6295 9.59777C21.3111 9.46585 20.9697 9.39795 20.625 9.39795C20.2803 9.39795 19.9389 9.46585 19.6205 9.59777C19.302 9.72968 19.0126 9.92304 18.7688 10.1668C18.5251 10.4105 18.3317 10.6999 18.1998 11.0184C18.0679 11.3369 18 11.6782 18 12.0229V12.0229Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.375 12.0229C9.375 12.7191 9.65156 13.3868 10.1438 13.8791C10.6361 14.3714 11.3038 14.6479 12 14.6479C12.6962 14.6479 13.3639 14.3714 13.8562 13.8791C14.3484 13.3868 14.625 12.7191 14.625 12.0229C14.625 11.3268 14.3484 10.6591 13.8562 10.1668C13.3639 9.67451 12.6962 9.39795 12 9.39795C11.3038 9.39795 10.6361 9.67451 10.1438 10.1668C9.65156 10.6591 9.375 11.3268 9.375 12.0229V12.0229Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
