import TiptapTable from '@tiptap/extension-table';
import { columnResizing, tableEditing } from 'prosemirror-tables';

// taken from https://github.com/sjdemartini/mui-tiptap/tree/main

export const Table = TiptapTable.extend({
  addProseMirrorPlugins() {
    return [
      ...(this.options.resizable
        ? [
            columnResizing({
              handleWidth: this.options.handleWidth,
              cellMinWidth: this.options.cellMinWidth,
              View: this.options.View,
              lastColumnResizable: this.options.lastColumnResizable
            })
          ]
        : []),

      tableEditing({
        allowTableNodeSelection: this.options.allowTableNodeSelection
      })
    ];
  }
});
