import { getHighlightedParts } from './utils';
import { AlgoliaData, AlgoliaProxyConfig } from './types';

export const algoliaProxyAdapter = async (
  { filters, query, page, infiniteResults, prevData }: AdapterProps<AlgoliaData>,
  { fetch, sortBy, hitsPerPage }: AlgoliaProxyConfig
): Promise<AlgoliaData> => {
  const { hits, nbPages, nbHits } = await fetch({
    sortBy,
    filters,
    page,
    hitsPerPage,
    query
  }).unwrap();

  return {
    hits: page > 0 && infiniteResults ? (prevData?.hits ?? []).concat(hits) : hits,
    hasMore: nbPages - 1 > page,
    nbHits,
    getHighlightedParts
  };
};
