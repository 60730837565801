import * as React from 'react';
import {
    DomainTuple, VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryTooltip
} from 'victory';

import { COLORS, LABELS } from '@components/ReportingApp/constants';

import { xTickFormat } from './utils';

interface Props {
  data: Record<string, any[]>;
  size?: { width: number; height: number };
  unit: string;
  period: 'week' | 'month';
  domain: { x?: DomainTuple };
}

export const StackedBarChart: React.FC<Props> = ({ data, period, size, domain }) => {
  return (
    <VictoryChart
      width={size?.width || 600}
      height={size?.height || 300}
      scale={{ x: 'time', y: 'linear' }}
      domainPadding={{ x: [30, 0] }}
      domain={domain}
      // domain={
    >
      <VictoryAxis
        style={{
          axis: { stroke: '#EDEDED' },
          ticks: { stroke: 'grey', size: 5 },
          tickLabels: { fontSize: 12, padding: 5, fill: '#6B6B6B', fontFamily: 'inherit' }
        }}
        tickFormat={(t) => xTickFormat(t, period)}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: 'transparent' },
          grid: { stroke: '#EDEDED' },
          tickLabels: { fontSize: 12, padding: 5, fill: '#6B6B6B', fontFamily: 'inherit' }
        }}
      />
      <VictoryStack colorScale={COLORS}>
        {Object.keys(data).map((key) => (
          <VictoryBar
            key={key}
            data={data[key]}
            labels={({ datum }) =>
              `${xTickFormat(datum.x, period)}: ${datum.y > 0 ? `${LABELS[key]} (${datum.y})` : null}`
            } // Add labels for each data point
            labelComponent={
              <VictoryTooltip
                constrainToVisibleArea
                style={{ fontSize: 12, fontFamily: 'inherit' }}
                flyoutStyle={{ stroke: '#e5e7eb', fill: 'white' }}
              />
            }
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  );
};
