import { FilterStateLookup } from '@components/StudiesApp/components/StudyPublished/pages/ExternalCandidatesRequest/components/FilterInput';

export const mapToExternalCandidatesFilters = (filtersToMap: FilterStateLookup) =>
  Object.keys(filtersToMap).map((filter_id) => {
    return {
      filter_id: filter_id,
      ...(filtersToMap[filter_id].selected_values && { selected_values: filtersToMap[filter_id].selected_values }),
      ...(filtersToMap[filter_id].selected_range && { selected_range: filtersToMap[filter_id].selected_range })
    };
  });

export const requestAttrsToFilters = (request?: ExternalCandidatesRequest): FilterStateLookup => {
  if (request?.platform_name === 'Prolific') {
    return {
      marketType: {
        type: 'select',
        selected_values: [request?.attrs.market_type || 'b2b']
      },
      ...request?.attrs.prolific?.filters?.reduce((acc, filter) => {
        return { ...acc, [filter.filter_id]: filter };
      }, {})
    } as FilterStateLookup;
  }

  return {
    ...request?.attrs.respondent?.filters?.reduce((acc, filter) => {
      return { ...acc, [filter.filter_id]: filter };
    }, {}),
    marketType: {
      type: 'select',
      selected_values: [request?.attrs.market_type || 'b2b']
    },
    targetTopics: {
      type: 'select',
      selected_values: request?.attrs.respondent?.topics?.map(({ id }) => id) || []
    },
    targetJobTitles: {
      type: 'select',
      selected_values: request?.attrs.respondent?.job_titles?.map(({ id }) => id) || []
    },
    targetIndustries: {
      type: 'select',
      selected_values: request?.attrs.respondent?.industries?.map(({ id }) => id) || []
    },
    targetSkills: {
      type: 'select',
      selected_values: request?.attrs.respondent?.skills?.map(({ id }) => id) || []
    }
  } as FilterStateLookup;
};
