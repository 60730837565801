import { fullStatusText } from 'components/StudiesApp/components/StudyPublished/components/ParticipationActions';
import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import tinytime from 'tinytime';

import ZDSIcon from './ParticipationsZDSIcon';

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');
const dateTimeTemplate = tinytime('{MM} {DD}, {YYYY} at {H}:{mm}', { padHours: true });

function ZDS({ first_name }) {
  return (
    <div className='py-6 text-center'>
      <span className='mx-auto'>
        <ZDSIcon />
      </span>
      <p className='mt-4 text-gray-500'>We'll show {first_name ? `${first_name}'s` : 'their'} studies here.</p>
    </div>
  );
}
interface Props {
  id: number;
  project_id: number;
  customer_id: number;
  study: Study;
  updated_at: Date;
  last_activity_at: Date;
  status: Participation['status'];
  style: Study['style'];
}
const Participation: React.FC<Props> = ({ id, project_id, customer_id, study, last_activity_at, status, style }) => {
  return (
    <li className='xx-participation py-4 border-b border-gray-100' key={`participation_${id}`}>
      <Link to={`/studies/${project_id}#candidates/${customer_id}`}>
        <div className='flex'>
          <h3 className='flex-1 mb-2 text-sm font-normal text-gray-700'>{study.title}</h3>
          <svg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1.70492 0L0.294922 1.41L4.87492 6L0.294922 10.59L1.70492 12L7.70492 6L1.70492 0Z'
              fill='#5850EC'
            />
          </svg>
        </div>
        <div className='flex flex-row items-center text-xs leading-normal text-gray-500'>
          <span className='mr-2'>
            <time dateTime={last_activity_at.toISOString()} title={dateTimeTemplate.render(last_activity_at)}>
              {dateTemplate.render(last_activity_at)}
            </time>
          </span>
          <svg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='2' cy='2' r='2' fill='#E5E7EB' />
          </svg>
          <span className='ml-2'>{fullStatusText(status, style)}</span>
        </div>
      </Link>
    </li>
  );
};
const DEFAULT_VISIBLE = 3;
export default function Participations({ title = 'Studies', candidate, participations }) {
  const [collapsed, setCollapsed] = useState(true);

  const visibleParticipations = collapsed ? participations.slice(0, DEFAULT_VISIBLE) : participations;
  const any = participations.length > 0;
  return (
    <div>
      {any ? (
        <ul>
          {visibleParticipations.map((participation) => (
            <Participation key={participation.id} {...participation} />
          ))}
        </ul>
      ) : (
        <ZDS first_name={candidate.first_name} />
      )}
      {any && participations.length > DEFAULT_VISIBLE && (
        <div className='pt-2 text-sm'>
          <a onClick={() => setCollapsed(!collapsed)} className='xx-participations-view-all cursor-pointer'>
            {collapsed ? `View all (${participations.length})` : 'Hide'}
          </a>
        </div>
      )}
    </div>
  );
}
