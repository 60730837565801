import { ZDSPage } from 'components/shared/ZDS/components/ZDSPage';
import * as React from 'react';

import { AlgoliaData, useSearch } from '@components/shared/Search';
import { ZdsType } from '@components/shared/ZDS/constants';

import { useRepositoryContext } from '../hooks/useRepositoryContext';
import { NoFilterResultsZDS } from './NoFilterResultsZDS';
import { NoSearchResultsZDS } from './NoSearchResultsZDS';
import { RepositorySkeletonBody } from './RepositorySkeleton';

type Props = {
  anyFilters: boolean;
  studyId?: number;
  screenerResults?: boolean;
  tab: string;
};

export const StateResults: React.FC<Props> = ({ tab, screenerResults, anyFilters, children, studyId }) => {
  const { study } = useRepositoryContext();
  const { isFetching, data, loading, query } = useSearch<AlgoliaData>();

  if (screenerResults) return <>{children}</>;

  if (loading || isFetching) {
    return (
      <div className='px-page py-gutter'>
        <RepositorySkeletonBody />
      </div>
    );
  }
  if (data && data.nbHits > 0) {
    return <>{children}</>;
  }

  if (query) {
    return (
      <div className='px-page py-gutter'>
        <NoSearchResultsZDS query={query} />
      </div>
    );
  }

  if (anyFilters) {
    return (
      <div className='px-page py-gutter'>
        <NoFilterResultsZDS />
      </div>
    );
  }
  if (['individual_responses', 'summary_responses'].includes(tab)) {
    return <ZDSPage withHeader={false} types={['global_survey_results']} studyId={study?.id} />;
  }

  const zdsType = tab === 'interviews' && study?.style === 'unmoderated_test' ? 'recordings' : tab;

  return <ZDSPage withHeader={false} types={[`global_${zdsType}` as ZdsType]} studyId={study?.id} />;
};
