import cn from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';

import { Text } from '@components/common';
import { MultiselectDropdown } from '@components/common/MultiselectDropdown';
import { CaretDownSVG } from '@components/svgs';
import { compact, uniq, useOnClickOutside } from '@components/utils';
import { useSearch } from '@hooks/useSearch';
import * as PopperJS from '@popperjs/core';
import { usePopper } from 'react-popper';

export const Check: React.FC = () => (
  <svg width='16' height='13' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M1 7.3L5 11.5L15 1' stroke='#5850EC' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

const STATUS_COLORS = {
  draft: 'bg-yellow-400',
  pending: 'bg-orange-400',
  active: 'bg-green-600',
  closed: 'bg-gray-400',
  paused: 'bg-gray-400',
  archived: 'bg-gray-400',
  cancelled: 'bg-red-100'
};

export type MultiselectDropdownItem<T extends string | number> = {
  label: string;
  value: T;
  status?: keyof typeof STATUS_COLORS;
};

interface Props<T extends string> {
  label?: string;
  value: T[];
  options: MultiselectDropdownItem<T>[];
  onSave: (value: T[]) => void;
  single?: boolean;
  withSearch?: boolean;
  rectangularButton?: boolean;
  rightDropdown?: boolean;
  className?: string;
  wrapperClass?: string;
  onBlur?: () => void;
  withInput?: boolean;
  canCreate?: boolean;
  popperOptions?: Omit<Partial<PopperJS.Options>, 'modifiers'>;
}

const DEFAULT_POPPER_OPTIONS: Omit<Partial<PopperJS.Options>, 'modifiers'> = {
  placement: 'bottom'
};

export const MultiselectCombobox = <T extends string>({
  label,
  value,
  options: items,
  onSave,
  onBlur,
  single,
  withSearch,
  rectangularButton,
  rightDropdown,
  className,
  wrapperClass,
  withInput,
  canCreate,
  popperOptions = DEFAULT_POPPER_OPTIONS
}: React.PropsWithChildren<Props<T>>): React.ReactElement => {
  const [open, setOpen] = useState(false);

  const [search, setSearch] = useState<string>('');

  const ref = useRef<HTMLDivElement>(null);

  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
  const [menuRef, setMenuRef] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(triggerRef, menuRef, {
    ...popperOptions
  });

  const { results: visibleItems } = useSearch(items, ['label'], search, [search, items]);

  useOnClickOutside(ref, () => {
    if (open) {
      setOpen(false);
      onBlur?.();
    }
  });

  const handleSave = (value) => {
    onSave(compact(uniq([search, ...value])));
    setOpen(false);
  };

  const selectedLabel = items?.find((item) => (value?.length === 1 ? item.value === value?.[0] : null));

  const dropdownTitle =
    value?.length > 1 ? `${label}: ${value.length}` : selectedLabel?.label || `All ${label?.toLowerCase()}`;

  const buttonStyle = cn('focus:outline-none flex items-center px-4 py-1.5 space-x-2 ', {
    'w-full tablet:w-52 justify-between h-full': rectangularButton,
    'rounded-md bg-indigo-50 border-indigo-600 border': !rectangularButton
  });

  const dropdownClass = cn('absolute z-50 top-12', { 'right-0': rightDropdown });

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && search) {
      handleSave(value);
    }
  };

  return (
    <div className={cn('relative', wrapperClass)} ref={ref}>
      {withInput ? (
        <input
          ref={setTriggerRef}
          className='xx-combo-input border border-gray-200 h400 focus:border-indigo-600 rounded-md w-full py-2.5 px-4 text-gray-700 placeholder-gray-400 focus:outline-none'
          autoComplete='off'
          name='dropdown_combobox'
          value={search}
          onFocus={() => setOpen(true)}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          onKeyDown={onKeyDown}
          autoFocus
        />
      ) : (
        <button ref={setTriggerRef} className={buttonStyle} onClick={() => setOpen(true)}>
          <Text truncate h='400' medium>
            {dropdownTitle}
          </Text>
          <CaretDownSVG className='min-w-4' />
        </button>
      )}
      {open && (
        <MultiselectDropdown
          canCreate={canCreate}
          dropdownClass={dropdownClass}
          onSave={handleSave}
          items={visibleItems}
          ref={setMenuRef}
          single={single}
          selectedValues={value}
          onSearch={withSearch ? setSearch : undefined}
          label={label}
          className={className}
          styles={styles}
          attributes={attributes}
        />
      )}
    </div>
  );
};
