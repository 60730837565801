import React, { useMemo } from 'react';

import { ColumnDef, Table } from '@components/shared/Table';
import { WithUser } from '@hooks/useRecordsWithUser';

import { tableDefition } from './tableDefinition';

export const ChatsTable: React.FC<{ chats: WithUser<AiChat>[] }> = ({ chats }) => {
  const columns = useMemo<ColumnDef<WithUser<AiChat>>[]>(() => tableDefition, []);

  return (
    <div className={`relative max-w-full overflow-auto border-l border-r border-gray-200`}>
      <Table data={chats} columns={columns} className={`w-full bg-white border-t border-gray-200 table-fixed`} />
    </div>
  );
};
