const safeAreas = new Set<Element>();

export const registerSafeArea = (element: Element): void => {
  safeAreas.add(element);
};

export const unregisterSafeArea = (element: Element): void => {
  safeAreas.delete(element);
};

export const isSafeArea = (element: Element): boolean => {
  for (const safeArea of Array.from(safeAreas)) {
    if (safeArea.contains(element)) {
      return true;
    }
  }
  return false;
};
