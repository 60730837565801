import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { MaybeDrafted } from '@reduxjs/toolkit/dist/query/core/buildThunks';

import { surveyBuilderApi as api } from '../api';

import * as Enums from '../types/enums';
import * as Models from '../types/models';

type Result = [
  (from: number, to: number) => void,
  {
    blocks?: Models.Block[];
  }
];

export const usePatchBlockPosition = (surveyId: number): Result => {
  const { data: blocks } = api.useGetSurveyBuilderBlocksQuery(surveyId);

  const dispatch = useDispatch<ThunkDispatch<unknown, unknown, AnyAction>>();

  const canReorder = (position: number, draft: MaybeDrafted<Models.Block[]>) => {
    const permissionsBlock = draft.find(({ kind }) => kind === Enums.Kind.permissions);
    const thankYouBlock = draft.find(({ kind }) => kind === Enums.Kind.thankYou);

    if (permissionsBlock && thankYouBlock) {
      return position > permissionsBlock.position && position < thankYouBlock.position;
    } else {
      return true;
    }
  };

  const reorder = (from: number, to: number) => {
    const patch = api.util.updateQueryData('getSurveyBuilderBlocks', +surveyId, (draft) => {
      if (canReorder(to, draft)) {
        const block = draft.find(({ position }) => position === from);

        if (block) {
          block.position = to;

          draft.forEach((b) => {
            if (b.id !== block.id && b.position === to) {
              if (from > to) {
                b.position += 1;
              } else {
                b.position -= 1;
              }
            }
          });
        }

        draft.sort((a, b) => a.position - b.position);
      }
    });

    dispatch(patch);
  };

  return [reorder, { blocks }];
};
