import cn from 'classnames';
import * as React from 'react';
import { forwardRef } from 'react';

interface TitleProps {
  size?: 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
  className?: string;
}
interface Props {
  noPadding?: boolean;
  className?: string;
  paddingClass?: string;
  style?: Record<string, any>;
  children?: React.ReactNode;
  errors?: boolean;
}
export const CardTitle: React.FC<TitleProps> = ({ children, size = 'xl', className = '' }) => {
  return <h3 className={`text-${size} ${className} font-bold text-gray-700`}>{children}</h3>;
};

export const Card = forwardRef<HTMLDivElement, Props>(
  ({ className = '', errors, noPadding, paddingClass = 'p-6', style = {}, children }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('bg-white rounded relative border', className, noPadding ? '' : paddingClass, {
          'border-gray-200': !errors,
          'focus:ring-red-600 focus:border-red-600 border-red-600': errors
        })}
        style={style}
      >
        {children}
      </div>
    );
  }
);
