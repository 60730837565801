import { Toast } from '@stores/toaster';

export const savedChanges = (): Toast => ({
  heading: 'Email changes saved',
  icon: 'success',
  text: 'Your email has been updated.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating that email. Please try again later.'
});

const DEFAULT_ERROR = 'Something went wrong sending the message. Please try again later.';
export const failedSend = (error: string): Toast => {
  const message = error || DEFAULT_ERROR;

  const toast: Toast = {
    heading: `Failed to send!`,
    icon: 'error',
    text: message
  };

  return toast;
};
export const canceledSend = (): Toast => ({
  heading: 'Emails unsent',
  text: "They won't be delivered",
  icon: 'success'
});

export const successSend = (
  onStudy: boolean,
  study: Study,
  backgroundTask: BackgroundTask,
  onCancel?: (task: BackgroundTask) => Promise<void>
): Toast => {
  const toast: Toast = {
    heading: 'Invites sent!',
    text: 'The invites will be sent shortly',
    icon: 'success',
    actions: [],
    timeoutSeconds: 10
  };

  if (backgroundTask && ['pending', 'processing'].includes(backgroundTask.status) && toast.actions) {
    toast.actions.push({
      text: 'Undo',
      href: '#',
      onClick: () => {
        if (onCancel) {
          onCancel(backgroundTask);
        }
      }
    });
  }

  if (!onStudy && study && toast.actions) {
    toast.actions.push({
      text: 'View study',
      href: `/studies/${study.id}`
    });
  }
  return toast;
};

export const failedSendScreener = (error?: string): Toast => {
  const message = error || 'Something went wrong. Please try again later.'

  return {
    heading: 'Failed to send screener!',
    icon: 'error',
    text: message
  }
};

export const successSendScreener = (): Toast => ({
  heading: 'Screener invites will be sent!',
  text: 'We will send the invites shortly.',
  icon: 'success'
});

export const genericFailure = (): Toast => ({
  heading: 'Something went wrong!',
  text: 'Please try again later.',
  icon: 'error'
});
