import React from 'react';

import { api } from '@api/reduxApi';
import { Loading } from '@components/common';

interface Props {
  studyId: string;
  children: ({ requests }: { requests: any }) => React.ReactElement;
}

export const ExternalCandidatesRequestsProvider = ({ studyId, children }) => {
  const { data: requests, isLoading } = api.useGetExternalCandidatesRequestsQuery(studyId);
  if (isLoading) {
    return <Loading absolute />;
  }
  return children({ requests });
};
