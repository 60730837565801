import React, { SVGProps } from 'react';

export const StudiesRecommendedSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='120' height='100' viewBox='0 0 120 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='119' height='99' rx='2.5' fill='white' stroke='#9FA6B2' />
    <g clipPath='url(#clip0_553_1331)'>
      <path
        d='M18.6569 81.3431C17.1459 79.8322 15.1368 79 13 79C10.863 79 8.85413 79.8322 7.34314 81.3431C5.83215 82.8541 5 84.863 5 87C5 89.1368 5.83215 91.1459 7.34314 92.6569C8.85413 94.1678 10.863 95 13 95C15.1368 95 17.1459 94.1678 18.6569 92.6569C20.1678 91.1459 21 89.1368 21 87C21 84.863 20.1678 82.8541 18.6569 81.3431V81.3431ZM9.01062 92.8246C9.34521 90.8981 11.0146 89.4771 13 89.4771C14.9855 89.4771 16.6548 90.8981 16.9894 92.8246C15.8535 93.605 14.4792 94.0625 13 94.0625C11.5208 94.0625 10.1465 93.605 9.01062 92.8246ZM10.4563 85.9958C10.4563 84.5931 11.5974 83.4521 13 83.4521C14.4026 83.4521 15.5437 84.5933 15.5437 85.9958C15.5437 87.3984 14.4026 88.5396 13 88.5396C11.5974 88.5396 10.4563 87.3984 10.4563 85.9958V85.9958ZM17.8014 92.1743C17.5491 91.2775 17.048 90.4637 16.3456 89.8281C15.9147 89.4381 15.4242 89.1304 14.8969 88.9135C15.85 88.2917 16.4813 87.2162 16.4813 85.9958C16.4813 84.0763 14.9196 82.5146 13 82.5146C11.0804 82.5146 9.5188 84.0763 9.5188 85.9958C9.5188 87.2162 10.1501 88.2917 11.1031 88.9135C10.5759 89.1304 10.0853 89.438 9.65442 89.828C8.95215 90.4636 8.45093 91.2773 8.19861 92.1742C6.80884 90.8835 5.9375 89.0417 5.9375 87C5.9375 83.1057 9.10571 79.9375 13 79.9375C16.8943 79.9375 20.0625 83.1057 20.0625 87C20.0625 89.0419 19.1912 90.8837 17.8014 92.1743V92.1743Z'
        fill='currentColor'
      />
    </g>
    <rect x='26' y='81' width='85' height='4' fill='#9FA6B2' />
    <rect x='26' y='89' width='50' height='4' fill='#9FA6B2' />
    <rect x='5.5' y='5.5' width='109' height='68' rx='2.5' fill='white' stroke='#E5E7EB' />
    <path
      d='M51.6457 53C51.1994 52.9988 50.7607 52.8892 50.3705 52.6817C49.9531 52.4659 49.6049 52.1452 49.3635 51.754C49.122 51.3628 48.9964 50.9159 49.0001 50.4614V29.5333C49.0001 29.0796 49.1272 28.6341 49.3683 28.2435C49.6093 27.853 49.9554 27.5316 50.3704 27.3131C50.7853 27.0946 51.2539 26.9869 51.7272 27.0013C52.2004 27.0157 52.6609 27.1516 53.0605 27.395L73.7742 37.859C74.1498 38.0877 74.4591 38.4036 74.6734 38.7774C74.8876 39.1512 75 39.5708 75 39.9974C75 40.4239 74.8876 40.8435 74.6734 41.2173C74.4591 41.5911 74.1498 41.907 73.7742 42.1357L53.0605 52.5998C52.6384 52.8598 52.1475 52.9986 51.6457 53V53Z'
      fill='#6C63FF'
    />
    <defs>
      <clipPath id='clip0_553_1331'>
        <rect width='16' height='16' fill='white' transform='translate(5 79)' />
      </clipPath>
    </defs>
  </svg>
);
