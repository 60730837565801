import React, { SVGProps } from 'react';

export const UndoSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_17_201)'>
      <path
        d='M0.5 0.498779V5.49878H5.5'
        stroke='#374151'
        strokeWidth='0.666667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99977 15.4988C9.37495 15.4996 10.7239 15.1223 11.8991 14.4081C13.0743 13.694 14.0305 12.6705 14.6632 11.4495C15.2959 10.2285 15.5807 8.85705 15.4866 7.48511C15.3924 6.11316 14.9228 4.79351 14.1292 3.67045C13.3355 2.54739 12.2484 1.66415 10.9866 1.11729C9.72487 0.570438 8.33704 0.38102 6.97487 0.569749C5.61271 0.758479 4.32865 1.31809 3.26309 2.1874C2.19754 3.05671 1.3915 4.20225 0.933105 5.49878'
        stroke='#374151'
        strokeWidth='0.666667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_17_201'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
