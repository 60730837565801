import React, { SVGProps } from 'react';

export const LiveSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.125 6.97198C6.125 7.20405 6.21719 7.42661 6.38128 7.5907C6.54538 7.7548 6.76794 7.84698 7 7.84698C7.23206 7.84698 7.45462 7.7548 7.61872 7.5907C7.78281 7.42661 7.875 7.20405 7.875 6.97198C7.875 6.73992 7.78281 6.51736 7.61872 6.35327C7.45462 6.18917 7.23206 6.09698 7 6.09698C6.76794 6.09698 6.54538 6.18917 6.38128 6.35327C6.21719 6.51736 6.125 6.73992 6.125 6.97198Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.16534 4.80667C9.44983 5.09102 9.6755 5.42862 9.82947 5.80021C9.98343 6.1718 10.0627 6.57008 10.0627 6.9723C10.0627 7.37452 9.98343 7.7728 9.82947 8.14438C9.6755 8.51597 9.44983 8.85358 9.16534 9.13792'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.83465 9.13792C4.55017 8.85358 4.3245 8.51597 4.17053 8.14438C4.01656 7.7728 3.93732 7.37452 3.93732 6.9723C3.93732 6.57008 4.01656 6.1718 4.17053 5.80021C4.3245 5.42862 4.55017 5.09102 4.83465 4.80667'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.4031 3.56943C11.3055 4.47202 11.8124 5.69603 11.8124 6.9723C11.8124 8.24857 11.3055 9.47259 10.4031 10.3752'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.59685 10.3752C3.14992 9.92834 2.79539 9.39783 2.55351 8.81396C2.31163 8.23009 2.18713 7.60429 2.18713 6.9723C2.18713 6.34031 2.31163 5.71451 2.55351 5.13064C2.79539 4.54677 3.14992 4.01627 3.59685 3.56943'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.6404 2.33334C12.871 3.56413 13.5623 5.23331 13.5623 6.97376C13.5623 8.71421 12.871 10.3834 11.6404 11.6142'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.35956 11.6124C1.12951 10.3817 0.438538 8.71291 0.438538 6.97289C0.438538 5.23286 1.12951 3.56406 2.35956 2.33334'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
