import * as React from 'react';
import { useEffect, useState } from 'react';
import pluralize from 'pluralize';

import { api } from '@api/reduxApi';
import { DropdownLink, OptionsDropdown, TeamIcon, Text, TippyOrNot } from '@components/common';
import { WrappedUserAvatars } from '@components/shared/WrappedUserAvatars';
import * as svgs from '@components/svgs';
import { usePermission } from '@hooks/usePermission';
import { useUser } from '@hooks/useUser';
import { useToaster } from '@stores/toaster';

import { DeleteTeamModal } from '../../modals/DeleteTeamModal';
import { EditTeamModal } from '../../modals/EditTeamModal';

type Props = {
  teams: Team[];
};
export const TeamsTable: React.FC<Props> = ({ teams }) => {
  const user = useUser();

  const [editModalTeam, setEditModalTeam] = useState<Team | null>(null);
  const [deleteModalTeam, setDeleteModalTeam] = useState<Team | null>(null);
  const canManageTeams = usePermission('manageTeams')();
  const canJoin = usePermission<Team>('canJoin');
  const showToast = useToaster();

  const isCurrentUserInTeam = (teamId: number) =>
    !!teams?.find((team) => team.id === teamId)?.users?.find((u) => u.id === user?.id);

  const [joinOrLeaveTeam, { isSuccess }] = api.useJoinOrLeaveTeamMutation();

  useEffect(() => {
    if (isSuccess) {
      showToast({
        heading: 'Success!',
        text: 'We have updated that team.',
        icon: 'success'
      });
    }
  }, [isSuccess]);

  const handleClickJoinTeam = (teamId: number) => joinOrLeaveTeam({ teamId });
  const handleClickLeaveTeam = (teamId: number) => joinOrLeaveTeam({ teamId, leave: true });

  return (
    <>
      <div>
        <table className='w-full'>
          <thead>
            <tr>
              <th className='pb-3 text-left'>Name</th>
              <th className='pb-3 text-left'>Members</th>
              <th className='pb-3'></th>
              <th className='pb-3'></th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {teams.map((team) => (
              <tr key={team.id}>
                <td>
                  <div className='flex items-center space-x-2'>
                    <TeamIcon team={team} />
                    <Text as='span' h='400' truncate className='max-w-sm'>
                      {team.name}
                    </Text>
                  </div>
                </td>
                <td>
                  <Text h='400' className='mr-2'>
                    {pluralize('member', team.users.length, true)}
                  </Text>
                </td>
                <td>
                  <div className='py-1'>
                    <WrappedUserAvatars maxPerRow={12} size='md' user_ids={team.users.map((u) => u.id)} />
                  </div>
                </td>
                <td className='items-center text-right'>
                  <OptionsDropdown aria-label='more'>
                    {canManageTeams && (
                      <DropdownLink
                        onClick={() => setEditModalTeam(team)}
                        className='flex items-center space-x-2'
                        trackEvent='clicked_edit_team'
                      >
                        <svgs.PencilSVG />
                        <Text h='400'>Edit Team</Text>
                      </DropdownLink>
                    )}
                    {!isCurrentUserInTeam(team.id) && (
                      <TippyOrNot
                        show={!canJoin(team)}
                        content='Your account settings restrict joining this team yourself.'
                      >
                        <DropdownLink
                          aria-disabled={!canJoin(team)}
                          disabled={!canJoin(team)}
                          className='flex items-center space-x-2'
                          onClick={() => handleClickJoinTeam(team.id)}
                          trackEvent='clicked_join_team'
                        >
                          <svgs.LoginSVG />
                          <Text h='400'>Join Team</Text>
                        </DropdownLink>
                      </TippyOrNot>
                    )}
                    {isCurrentUserInTeam(team.id) && (
                      <DropdownLink
                        className='flex items-center space-x-2'
                        onClick={() => handleClickLeaveTeam(team.id)}
                        trackEvent='clicked_leave_team'
                      >
                        <svgs.LoginSVG />
                        <Text h='400'>Leave Team</Text>
                      </DropdownLink>
                    )}
                    {canManageTeams && (
                      <>
                        <hr />
                        <DropdownLink onClick={() => setDeleteModalTeam(team)} className='flex items-center space-x-2'>
                          <svgs.TrashSVG className='text-red-600' />
                          <Text color='red-600' h='400'>
                            Delete Team
                          </Text>
                        </DropdownLink>
                      </>
                    )}
                  </OptionsDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editModalTeam && <EditTeamModal team={editModalTeam} open onClose={() => setEditModalTeam(null)} />}

      {deleteModalTeam && (
        <DeleteTeamModal
          team={deleteModalTeam}
          open
          onClose={() => setDeleteModalTeam(null)}
          onSuccess={() => setDeleteModalTeam(null)}
        />
      )}
    </>
  );
};
