import { useEffect, useState } from 'react';

import { UseTableFilters } from '@components/shared/TableFilters/hook/useTableFilters';

import { ArtifactHit } from '../types';

type UseAlgoliaFiltersOptions = {
  extraFilter?: string;
};

export const useAlgoliaFilters = (
  { filters, op }: UseTableFilters<ArtifactHit>,
  opts: UseAlgoliaFiltersOptions = {}
) => {
  const [algoliaFilters, setAlgoliaFilters] = useState<string>('');

  useEffect(() => {
    const joinOp = op === 'all' ? ') AND (' : ') OR (';

    const result = filters
      .map((filterState) => {
        const { toAlgoliaFilter } = filterState.definition;

        if (toAlgoliaFilter) {
          return toAlgoliaFilter(filterState);
        }
      })
      .filter(Boolean)
      .join(joinOp);

    if (result && opts.extraFilter) {
      setAlgoliaFilters(`${opts.extraFilter} AND (${result})`);
    } else if (result) {
      setAlgoliaFilters(`(${result})`);
    } else {
      setAlgoliaFilters(opts.extraFilter || '');
    }
  }, [filters, op, opts]);

  return algoliaFilters;
};
