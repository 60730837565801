import * as React from 'react';
import { useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { createRoleRequest } from '@api/queries';
import { Button, FormGroup, Loading, Modal, Option, Select, Text } from '@components/common';
import { useToaster } from '@stores/toaster';

interface Props {
  onSuccess?: () => void;
  onCancel: () => void;
}
const DEFAULT_MESSAGE =
  'Hey!\nI’d like to have more permissions on Great Question so I can create, edit and collaborate on research projects. \n\nThanks!';
const ROLE_OPTIONS: Option<AccountRole>[] = [
  { label: 'Creator: I need to create or edit content.', value: 'creator' },
  { label: 'Admin: I need access to do everything including managing billing.', value: 'admin' }
];

// TODO: add subtitle
export const RequestUpgradeModal: React.FC<Props> = ({ onCancel, onSuccess }) => {
  const [role, setRole] = useState<AccountRole>('creator');
  const [message, setMessage] = useState<string>(DEFAULT_MESSAGE);
  const [loading, setLoading] = useState<boolean>();

  const showToast = useToaster();

  async function handleSend() {
    setLoading(true);
    const [err, resp] = await createRoleRequest({ role, message });
    if (resp) {
      setLoading(false);
      onSuccess?.();
      showToast({
        icon: 'success',
        heading: 'Request sent',
        text: 'We’ll let you know when they respond.'
      });
    }
    // TODO: Send request
  }

  return (
    <Modal
      title='Request to upgrade permissions'
      size='md'
      onClose={onCancel}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={handleSend}>
            Send request
          </Button>
        </>
      )}
    >
      {loading && <Loading absolute />}
      <FormGroup>
        <Text bold>To</Text>
        <Text>Your account administator</Text>
      </FormGroup>
      <FormGroup>
        <Text bold className='mb-2'>
          Permission requested
        </Text>
        <Select<AccountRole> value={role} onChange={setRole} options={ROLE_OPTIONS} />
      </FormGroup>
      <FormGroup>
        <Text bold className='mb-2'>
          Message
        </Text>
        <TextareaAutosize
          minRows={4}
          onChange={(e) => setMessage(e.currentTarget.value)}
          value={message}
          className='focus:border-indigo-500 focus:ring-indigo-500 block w-full p-3 text-gray-700 placeholder-gray-400 transition duration-150 ease-in-out border-gray-200 rounded-md'
        />
      </FormGroup>
    </Modal>
  );
};
