import { Button } from 'components/common';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';

interface Props {
  onClose: () => void;
  loading: boolean;
  error: boolean;
  handleSave: SubmitHandler<any>;
  handleSaveAndAddMore: SubmitHandler<any>;
  canAddMore?: boolean;
}

export const SlideOutFooter: React.FC<Props> = ({
  canAddMore,
  onClose,
  loading,
  error,
  handleSave,
  handleSaveAndAddMore
}) => {
  return (
    <div>
      <Button onClick={handleSave} primary disabled={loading || error} className='mr-4' form='form1'>
        Save
      </Button>

      {canAddMore && (
        <Button onClick={handleSaveAndAddMore} disabled={loading || error} className='mr-4' form='form1'>
          Save & add another
        </Button>
      )}

      <Button disabled={loading} link onClick={onClose}>
        Cancel
      </Button>
    </div>
  );
};
