import React, { useEffect } from 'react';
import { Consumer, Mixin, Subscription } from '@rails/actioncable';

import consumer from '@helpers/consumer';

interface Args<Type> {
  channel: string;
  record: Type;
  skip?: boolean;
  onReceived?: (record: Type) => void;
}

export interface RecordWithId {
  id: any;
}

export const useWebsocket = <Type extends RecordWithId>({ channel, record, onReceived, skip = false }: Args<Type>) => {
  const ref = React.useRef<(Subscription<Consumer> & Mixin) | null>(null);

  useEffect(() => {
    if (!record.id || ref.current) return;

    ref.current = consumer.subscriptions.create(
      { channel: channel, id: record.id },
      {
        received: ({ message }: { message: Type }) => {
          if (!skip) {
            onReceived?.(message);
          }
        }
      }
    );

    return () => {
      if (ref.current) {
        ref.current.unsubscribe();
        ref.current = null;
      }
    };
  }, [record.id, skip, ref]);
}
