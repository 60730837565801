import * as React from 'react';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

export const NoSurveyQuestionsAlert: BookabilityRuleAlertComponent = ({ study, level, onClickCta }) => (
  <BookabilityRuleAlert
    level={level}
    heading='Survey has no questions'
    message='Please add survey questions for participants to complete.'
    ctas={[
      {
        children: 'Edit survey',
        href: `/studies/${study.id}/survey`,
        onClick: onClickCta
      }
    ]}
  />
);
