import React from 'react';

export const UnsupportedSvg = () => (
  <svg width='144' height='128' viewBox='0 0 144 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M134.051 69.7379L31.52 113.602C30.8198 113.901 30.0297 113.91 29.323 113.626C28.6164 113.343 28.0509 112.791 27.7507 112.092L0.703011 48.8693C0.484213 48.3562 0.477839 47.7774 0.685289 47.2597C0.89274 46.742 1.29707 46.3277 1.80958 46.1078L105.719 1.65366C106.241 1.43109 106.83 1.42463 107.356 1.63568C107.883 1.84674 108.304 2.25808 108.528 2.77946L135.561 65.9686C135.86 66.6689 135.869 67.459 135.586 68.1656C135.302 68.8722 134.75 69.4377 134.051 69.7379Z'
      fill='#F2F2F2'
    />
    <path
      d='M129.31 67.8229L33.7543 108.703C32.3535 109.303 30.7574 108.723 30.1963 107.411L6.64279 52.3563C6.22662 51.3835 6.73247 50.2308 7.77045 49.7867L104.611 8.35667C105.666 7.90523 106.869 8.34248 107.292 9.33128L130.833 64.3572C131.394 65.6689 130.711 67.2236 129.31 67.8229Z'
      fill='white'
    />
    <path
      d='M108.617 3.09043L0.806323 49.2138L0.231329 47.8698C-0.0680843 47.1671 -0.0765484 46.3743 0.207792 45.6654C0.492132 44.9565 1.04606 44.3894 1.74804 44.0884L104.26 0.232034C104.962 -0.0678179 105.755 -0.0767756 106.464 0.207126C107.173 0.491027 107.741 1.0446 108.042 1.74641L108.617 3.09043Z'
      fill='#6C63FF'
    />
    <path
      d='M4.76982 45.9073C5.1815 45.9073 5.51523 45.5736 5.51523 45.1619C5.51523 44.7502 5.1815 44.4165 4.76982 44.4165C4.35814 44.4165 4.02441 44.7502 4.02441 45.1619C4.02441 45.5736 4.35814 45.9073 4.76982 45.9073Z'
      fill='white'
    />
    <path
      d='M7.37138 44.795C7.78306 44.795 8.11679 44.4613 8.11679 44.0496C8.11679 43.6379 7.78306 43.3042 7.37138 43.3042C6.95971 43.3042 6.62598 43.6379 6.62598 44.0496C6.62598 44.4613 6.95971 44.795 7.37138 44.795Z'
      fill='white'
    />
    <path
      d='M9.97246 43.6822C10.3841 43.6822 10.7179 43.3485 10.7179 42.9368C10.7179 42.5251 10.3841 42.1914 9.97246 42.1914C9.56078 42.1914 9.22705 42.5251 9.22705 42.9368C9.22705 43.3485 9.56078 43.6822 9.97246 43.6822Z'
      fill='white'
    />
    <path
      d='M52.8832 63.3052L40.1397 68.757C39.4395 69.0556 38.6494 69.0643 37.9428 68.7811C37.2361 68.498 36.6707 67.9461 36.3704 67.2465L29.3359 50.8036C29.0373 50.1034 29.0286 49.3133 29.3118 48.6066C29.5949 47.9 30.1468 47.3345 30.8464 47.0343L43.5898 41.5825C44.29 41.2838 45.0802 41.2752 45.7868 41.5583C46.4934 41.8415 47.0589 42.3934 47.3591 43.093L54.3937 59.5359C54.6923 60.2361 54.701 61.0262 54.4178 61.7328C54.1346 62.4395 53.5827 63.0049 52.8832 63.3052Z'
      fill='#F2F2F2'
    />
    <path
      d='M74.4456 54.0806L61.7022 59.5324C61.002 59.831 60.2119 59.8397 59.5052 59.5565C58.7986 59.2734 58.2332 58.7215 57.9329 58.0219L50.8984 41.579C50.5998 40.8788 50.5911 40.0887 50.8743 39.382C51.1574 38.6754 51.7093 38.1099 52.4089 37.8097L65.1523 32.3579C65.8525 32.0592 66.6426 32.0506 67.3493 32.3337C68.0559 32.6169 68.6214 33.1688 68.9216 33.8684L75.9561 50.3113C76.2547 51.0115 76.2634 51.8016 75.9802 52.5083C75.6971 53.2149 75.1452 53.7803 74.4456 54.0806Z'
      fill='#F2F2F2'
    />
    <path
      d='M96.0086 44.8555L83.2652 50.3073C82.565 50.606 81.7749 50.6146 81.0682 50.3315C80.3616 50.0483 79.7961 49.4964 79.4959 48.7968L72.4614 32.3539C72.1627 31.6537 72.1541 30.8636 72.4372 30.1569C72.7204 29.4503 73.2723 28.8849 73.9719 28.5846L86.7153 23.1328C87.4156 22.8341 88.2057 22.8255 88.9123 23.1086C89.6189 23.3918 90.1844 23.9437 90.4846 24.6433L97.5191 41.0862C97.8178 41.7864 97.8265 42.5765 97.5433 43.2832C97.2601 43.9898 96.7082 44.5552 96.0086 44.8555Z'
      fill='#F2F2F2'
    />
    <path
      d='M63.7665 88.7442L51.0231 94.196C50.3228 94.4947 49.5327 94.5033 48.8261 94.2202C48.1194 93.937 47.554 93.3851 47.2537 92.6855L40.2192 76.2426C39.9206 75.5424 39.9119 74.7522 40.1951 74.0456C40.4782 73.339 41.0301 72.7735 41.7297 72.4733L54.4731 67.0214C55.1734 66.7228 55.9635 66.7141 56.6701 66.9973C57.3767 67.2805 57.9422 67.8324 58.2424 68.5319L65.277 84.9749C65.5756 85.6751 65.5843 86.4652 65.3011 87.1719C65.0179 87.8785 64.466 88.4439 63.7665 88.7442Z'
      fill='#F2F2F2'
    />
    <path
      d='M85.3294 79.5195L72.586 84.9714C71.8858 85.27 71.0957 85.2787 70.389 84.9955C69.6824 84.7123 69.117 84.1604 68.8167 83.4609L61.7822 67.018C61.4835 66.3177 61.4749 65.5276 61.758 64.821C62.0412 64.1144 62.5931 63.5489 63.2927 63.2487L76.0361 57.7968C76.7363 57.4982 77.5264 57.4895 78.2331 57.7727C78.9397 58.0559 79.5052 58.6078 79.8054 59.3073L86.8399 75.7502C87.1386 76.4505 87.1472 77.2406 86.8641 77.9472C86.5809 78.6538 86.029 79.2193 85.3294 79.5195Z'
      fill='#F2F2F2'
    />
    <path
      d='M106.892 70.2945L94.1486 75.7463C93.4483 76.045 92.6582 76.0536 91.9516 75.7705C91.2449 75.4873 90.6795 74.9354 90.3792 74.2358L83.3447 57.7929C83.046 57.0927 83.0374 56.3026 83.3205 55.5959C83.6037 54.8893 84.1556 54.3238 84.8552 54.0236L97.5987 48.5717C98.2989 48.2731 99.089 48.2644 99.7957 48.5476C100.502 48.8308 101.068 49.3827 101.368 50.0822L108.403 66.5252C108.701 67.2254 108.71 68.0155 108.427 68.7221C108.144 69.4288 107.592 69.9942 106.892 70.2945Z'
      fill='#F2F2F2'
    />
    <path
      d='M140.39 128H28.8704C28.1091 127.999 27.3793 127.696 26.841 127.158C26.3027 126.619 25.9999 125.89 25.999 125.128V56.3629C25.9996 55.8052 26.2215 55.2705 26.6158 54.8761C27.0102 54.4818 27.5449 54.2599 28.1026 54.2593H141.122C141.689 54.2599 142.233 54.4856 142.634 54.8868C143.035 55.288 143.261 55.8319 143.262 56.3993V125.128C143.261 125.89 142.958 126.619 142.42 127.158C141.881 127.696 141.151 127.999 140.39 128Z'
      fill='#E6E6E6'
    />
    <path
      d='M136.785 124.375H32.8509C31.3274 124.375 30.0879 123.214 30.0879 121.788V61.9057C30.0879 60.8477 31.0064 59.9868 32.1354 59.9868H137.466C138.614 59.9868 139.548 60.8619 139.548 61.9373V121.788C139.548 123.214 138.308 124.375 136.785 124.375Z'
      fill='white'
    />
    <path
      d='M143.222 56.7208H25.959V55.2589C25.9601 54.4951 26.2641 53.763 26.8044 53.223C27.3446 52.6831 28.077 52.3795 28.8408 52.3789H140.34C141.104 52.3795 141.836 52.6831 142.376 53.223C142.917 53.763 143.221 54.4951 143.222 55.2589V56.7208Z'
      fill='#6C63FF'
    />
    <path
      d='M31.1961 55.3004C31.6078 55.3004 31.9415 54.9667 31.9415 54.555C31.9415 54.1433 31.6078 53.8096 31.1961 53.8096C30.7844 53.8096 30.4507 54.1433 30.4507 54.555C30.4507 54.9667 30.7844 55.3004 31.1961 55.3004Z'
      fill='white'
    />
    <path
      d='M34.0252 55.3004C34.4369 55.3004 34.7706 54.9667 34.7706 54.555C34.7706 54.1433 34.4369 53.8096 34.0252 53.8096C33.6135 53.8096 33.2798 54.1433 33.2798 54.555C33.2798 54.9667 33.6135 55.3004 34.0252 55.3004Z'
      fill='white'
    />
    <path
      d='M36.8548 55.3004C37.2665 55.3004 37.6002 54.9667 37.6002 54.555C37.6002 54.1433 37.2665 53.8096 36.8548 53.8096C36.4431 53.8096 36.1094 54.1433 36.1094 54.555C36.1094 54.9667 36.4431 55.3004 36.8548 55.3004Z'
      fill='white'
    />
    <path
      d='M131.158 76.7969H81.9151C79.9435 76.7969 78.3452 78.3952 78.3452 80.3667V80.3667C78.3452 82.3383 79.9435 83.9366 81.9151 83.9366H131.158C133.13 83.9366 134.728 82.3383 134.728 80.3667C134.728 78.3952 133.13 76.7969 131.158 76.7969Z'
      fill='#E6E6E6'
    />
    <path
      d='M131.158 88.6108H81.9151C79.9435 88.6108 78.3452 90.2091 78.3452 92.1807V92.1807C78.3452 94.1523 79.9435 95.7505 81.9151 95.7505H131.158C133.13 95.7505 134.728 94.1523 134.728 92.1807C134.728 90.2091 133.13 88.6108 131.158 88.6108Z'
      fill='#E6E6E6'
    />
    <path
      d='M131.158 100.425H81.9151C79.9435 100.425 78.3452 102.024 78.3452 103.995V103.995C78.3452 105.967 79.9435 107.565 81.9151 107.565H131.158C133.13 107.565 134.728 105.967 134.728 103.995C134.728 102.024 133.13 100.425 131.158 100.425Z'
      fill='#E6E6E6'
    />
    <path
      d='M73.7144 92.181C73.7144 95.967 72.5918 99.6679 70.4884 102.816C68.385 105.964 65.3954 108.417 61.8975 109.866C58.3997 111.315 54.5508 111.694 50.8376 110.956C47.1243 110.217 43.7135 108.394 41.0364 105.717C38.3593 103.04 36.5361 99.6287 35.7975 95.9154C35.0589 92.2022 35.438 88.3533 36.8868 84.8555C38.3357 81.3577 40.7892 78.368 43.9371 76.2646C47.0851 74.1613 50.7861 73.0386 54.5721 73.0386C59.6485 73.0402 64.5165 75.0575 68.106 78.647C71.6955 82.2366 73.7128 87.1046 73.7144 92.181Z'
      fill='white'
    />
    <path
      d='M63.419 95.4562C63.2099 107.699 44.9993 107.697 44.792 95.4558C45.0011 83.2136 63.2117 83.2155 63.419 95.4562Z'
      fill='#6C63FF'
    />
    <path
      d='M53.7358 99.256L53.7261 98.9079C56.1249 99.2282 56.6415 96.2552 54.2223 97.3667L54.7745 93.3311L55.1194 93.3783L54.6496 96.8112C57.1968 96.432 56.2547 99.6038 53.7358 99.256Z'
      fill='#2F2E41'
    />
    <path d='M58.6992 93.6743H56.8247V94.0224H58.6992V93.6743Z' fill='#2F2E41' />
    <path d='M52.7827 93.6743H50.9082V94.0224H52.7827V93.6743Z' fill='#2F2E41' />
    <path
      d='M57.1247 108.478C57.4902 106.012 54.6032 106.499 53.0753 106.475C52.8289 106.475 52.5858 106.531 52.3637 106.638C52.1416 106.744 51.9461 106.899 51.7914 107.091C51.6367 107.283 51.5267 107.506 51.4694 107.746C51.4122 107.986 51.4091 108.235 51.4604 108.476C50.2778 108.841 49.1827 109.446 48.2427 110.251C52.2039 111.632 56.5082 111.68 60.4991 110.387C59.5262 109.513 58.3747 108.862 57.1247 108.478Z'
      fill='#2F2E41'
    />
    <path
      d='M49.727 91.0512C51.453 90.0537 53.5461 90.819 55.3291 91.2374C58.7117 92.2773 63.4553 90.9751 63.8667 86.9456C64.2948 83.1981 59.1683 81.1094 57.5297 84.8393L58.0068 84.7769C52.6927 76.8795 43.449 77.179 43.5098 88.0366C43.4901 88.372 44.0123 88.3709 44.0318 88.0364C43.8361 81.6382 48.0706 77.2646 54.0563 81.3763C55.4262 82.3758 56.5346 83.6996 57.556 85.0404C58.1794 85.739 58.3149 82.7304 61.2985 83.5185C64.5821 84.7906 63.5107 89.4923 60.5757 90.5245C56.9494 92.3772 53.0219 88.7851 49.4634 90.6002C49.4062 90.6364 49.3652 90.6934 49.3491 90.7592C49.333 90.825 49.3432 90.8945 49.3773 90.953C49.4115 91.0115 49.4671 91.0544 49.5323 91.0728C49.5975 91.0911 49.6673 91.0834 49.727 91.0512V91.0512Z'
      fill='#2F2E41'
    />
    <path
      d='M54.5718 111.845C49.6284 111.841 44.8683 109.974 41.2408 106.615C37.6132 103.257 35.3848 98.655 35.0001 93.7266C34.6153 88.7982 36.1024 83.9059 39.1648 80.0254C42.2272 76.1449 46.6399 73.5613 51.5227 72.79C56.4055 72.0187 61.3996 73.1163 65.509 75.8639C69.6184 78.6115 72.5412 82.8072 73.6942 87.6142C74.8473 92.4212 74.1461 97.4862 71.7303 101.799C69.3145 106.112 65.3617 109.356 60.6604 110.883C58.6942 111.523 56.6393 111.847 54.5718 111.845ZM54.5718 73.5604C50.8883 73.5599 47.2874 74.6517 44.2244 76.6978C41.1615 78.744 38.7741 81.6524 37.3643 85.0555C35.9544 88.4585 35.5854 92.2031 36.3039 95.8159C37.0224 99.4286 38.7961 102.747 41.4007 105.352C44.0054 107.956 47.3239 109.73 50.9366 110.449C54.5494 111.167 58.2941 110.798 61.6971 109.388C65.1001 107.978 68.0086 105.591 70.0547 102.528C72.1008 99.4651 73.1926 95.8642 73.1921 92.1807C73.1865 87.244 71.2229 82.5111 67.7322 79.0203C64.2414 75.5296 59.5085 73.566 54.5718 73.5604Z'
      fill='#3F3D56'
    />
  </svg>
);
