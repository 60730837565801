import React, { forwardRef, HTMLAttributes, useRef } from 'react';

import { useAccordionContext } from '../hooks/useAccordionContext';
import { SectionContext } from '../hooks/useSectionContext';

export const Section = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ children, ...rest }, ref) => {
  const { currentIndexRef } = useAccordionContext();
  const sectionIndexRef = useRef(currentIndexRef.current++);
  const sectionIndex = sectionIndexRef.current;

  return (
    <SectionContext.Provider value={sectionIndex}>
      <section ref={ref} data-index={sectionIndex} {...rest}>
        {children}
      </section>
    </SectionContext.Provider>
  );
});
