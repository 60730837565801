import { CalendarSVG, CheckSVG, RecurringIconSVG } from '@components/svgs';
import { PopperDropdown, PopperDropdownButton, Text } from '@components/common';
import { DAYS_LONG_TEXT } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/constants';
import { getStringFromDate } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import cn from 'classnames';
import * as React from 'react';

import { format } from 'date-fns';

interface Props {
  slot: SlotInstance;
  updateSlot: (slot: Partial<SlotInstance>) => void;
  date: Date;
  disabled?: boolean;
  style?: 'select' | 'button';
}

export const RecurringSelect: React.FC<Props> = ({ style = 'button', slot, updateSlot, date, disabled }) => {
  const recurringText = `Apply for every ${DAYS_LONG_TEXT[date.getDay()]}`;
  const singleText = `Apply only for ${format(date, 'MMM d')}`;

  const renderSelected = () => {
    switch (style) {
      case 'button':
        return slot.single ? <CalendarSVG /> : <RecurringIconSVG />;
      case 'select':
        return (
          <div className='flex items-center justify-start w-full'>
            {slot.single ? <CalendarSVG className='mr-3' /> : <RecurringIconSVG className='mr-3' />}
            <Text h='400' className='flex-grow whitespace-nowrap text-left'>
              {slot.single ? singleText : recurringText}
            </Text>
          </div>
        );
    }
  };

  return (
    <PopperDropdown
      disabled={disabled}
      popperProps={{ renderOnBodyRoot: false, placement: 'bottom-start' }}
      buttonClassName={cn('group py-2 px-3 border-gray-200 border rounded-md', {
        'hover:text-indigo-600 hover:bg-gray-50': !disabled,
        'w-72': style === 'select',
        'mr-2 h-8': style === 'button'
      })}
      hideCaret={style === 'button'}
      text={renderSelected()}
      aria-label='Change recurring'
      name='Change recurring'
    >
      <div className='w-72'>
        <PopperDropdownButton
          aria-label='Reccuring'
          onClick={() => {
            updateSlot({ ...slot, single: false });
          }}
          className='group px-4 py-2 flex items-center cursor-pointer'
        >
          <RecurringIconSVG className='mr-3' />
          <Text h='400' className='flex-grow whitespace-nowrap'>
            {recurringText}
          </Text>
          {!slot.single && <CheckSVG />}
        </PopperDropdownButton>
        <PopperDropdownButton
          aria-label='Single'
          onClick={() => {
            updateSlot({ ...slot, single: true, date: getStringFromDate(date) });
          }}
          className='group px-4 py-2 flex items-center cursor-pointer justify-between'
        >
          <CalendarSVG className='mr-3' />
          <Text h='400' className='flex-grow whitespace-nowrap'>
            {singleText}
          </Text>
          {slot.single && <CheckSVG />}
        </PopperDropdownButton>
      </div>
    </PopperDropdown>
  );
};
