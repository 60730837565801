import cn from 'classnames';
import { InfoLabel, Text } from 'components/common';
import { formatDistance } from 'date-fns';
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { useRenderTagsList } from '@components/RepositoryApp/hooks/useRenderTagsList';
import Tippy from '@tippyjs/react';
import {
  formatDuration,
  getArtifactInfoIcon,
  getArtifactInfoLabel
} from '@components/shared/Artifact/components/utils';
import { Link } from 'react-router-dom';

export enum ArtifactType {
  'Participation' = 'Interview',
  'Highlight' = 'Highlight',
  'HighlightReel' = 'Reel',
  'Repo::Session' = 'Interview',
  'Story' = 'Insight',
  'Clip' = 'Highlight',
  'ScreenerResponse' = 'Survey Response',
  'Feedbacks::Feedback' = 'Feedbacks'
}

export type ArtifactKind = keyof typeof ArtifactType;

interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'type'> {
  title: ReactNode;
  subtitle?: string;
  type: keyof typeof ArtifactType;
  createdAt: Date | null;
  updatedAt: Date | null;
  displayDate?: 'createdAt' | 'updatedAt';
  avatar: ReactNode;
  duration?: number | null;
  tag_ids?: number[];
  studyId?: number | null;
  studyTitle?: string | null;
  studyStyle?: Study['style'];
  views?: number | null;
  candidateName: string | null;
  hasLinks: boolean;
  onPillClick?: (params: string) => void;
}

export const Info = forwardRef<HTMLDivElement, Props>(
  (
    {
      views,
      tag_ids,
      studyId,
      studyTitle,
      studyStyle,
      duration,
      className,
      title,
      subtitle,
      type,
      createdAt,
      updatedAt,
      displayDate = 'createdAt',
      avatar,
      candidateName,
      hasLinks,
      onPillClick,
      ...rest
    },
    ref
  ) => {
    const timestamp = displayDate === 'updatedAt' ? updatedAt : createdAt;

    const { renderTagsList } = useRenderTagsList();

    return (
      <section className={cn('px-3 py-2.5 flex flex-col h-1/2', className)} ref={ref} {...rest}>
        <Tippy content={title} disabled={!title}>
          <h2 className='h500 line-clamp-2 font-bold'>{title || 'Untitled'}</h2>
        </Tippy>
        <div className='flex flex-wrap items-center mt-2 mb-1.5 line-clamp-2'>
          {studyTitle &&
            (hasLinks ? (
              <Link to={`/studies/${studyId}`} className='truncate mr-1.5 mb-1.5'>
                <InfoLabel className='hover:text-indigo-600 text-gray-500' icon='checklist' text={studyTitle} />
              </Link>
            ) : (
              <InfoLabel className='truncate mr-1.5 mb-1.5 text-gray-500' icon='checklist' text={studyTitle} />
            ))}
          {candidateName && <InfoLabel className='mr-3 text-gray-500 mb-1.5' icon='person' text={candidateName} />}
          <InfoLabel
            className='mr-3 text-gray-500 mb-1.5'
            icon={getArtifactInfoIcon(type)}
            text={getArtifactInfoLabel(type, studyStyle)}
          />
          {typeof duration === 'number' && type !== 'Story' && (
            <InfoLabel className='mr-3 text-gray-500 mb-1.5' icon='clock' text={formatDuration(duration)} />
          )}
          {typeof views === 'number' && views > 0 && (
            <InfoLabel className='mr-3 text-gray-500 mb-1.5' icon='eye' text={views.toLocaleString()} />
          )}
        </div>

        {tag_ids &&
          tag_ids.length > 0 &&
          renderTagsList(
            tag_ids,
            {
              max: 3,
              clickable: true
            },
            onPillClick
          )}
        <div className='flex-1' />
        <div className='flex whitespace-nowrap items-center mt-3 text-gray-500'>
          {avatar}
          <div className='flex-1' />
          {timestamp && (
            <Text h='200' className='ml-1 truncate'>
              {formatDistance(timestamp, new Date(), { addSuffix: true })}
            </Text>
          )}
        </div>
      </section>
    );
  }
);
