import copy from 'copy-to-clipboard';
import * as React from 'react';

import { Button, ButtonProps } from '@components/common/Button';
import { useToaster } from '@stores/toaster';
import Tippy from '@tippyjs/react';

interface Props extends Omit<ButtonProps, 'text'> {
  text: string;
  onCopy?: (text: string) => void;
  noTippy?: boolean;
}
export const CopyURLButton: React.FC<Props> = ({ text, noTippy, children, onCopy, ...props }) => {
  const showToast = useToaster();

  function onClick() {
    showToast({
      heading: 'URL copied to the clipboard',
      text: 'Paste it wherever you’d like.',
      icon: 'success'
    });
    copy(text);
    if (onCopy) {
      onCopy(text);
    }
  }

  if (noTippy) {
    return (
      <Button aria-label='Copy link' {...props} onClick={onClick}>
        {children}
      </Button>
    );
  }

  return (
    <Tippy content='Copy link'>
      <Button aria-label='Copy link' {...props} onClick={onClick}>
        {children}
      </Button>
    </Tippy>
  );
};
