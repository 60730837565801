import React, { SVGProps } from 'react';

export const FilmSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1062_24826)'>
      <path
        d='M15.6803 0.563721H0.319761C0.143138 0.563721 0 0.706859 0 0.883446V15.1164C0 15.2929 0.143138 15.4361 0.319761 15.4361H15.6803C15.8569 15.4361 16 15.293 16 15.1164V0.883446C16 0.706859 15.8569 0.563721 15.6803 0.563721ZM10.8799 1.20321H12.8004V2.5266H10.8799V1.20321ZM8.31974 1.20321H10.2403V2.5266H8.31974V1.20321ZM5.75967 1.20321H7.68026V2.5266H5.75967V1.20321ZM3.19956 1.20321H5.12015V2.5266H3.19956V1.20321ZM0.639486 1.20321H2.56007V2.5266H0.639486V1.20321ZM2.56007 14.7966H0.639486V13.4732H2.56007V14.7966ZM5.12018 14.7966H3.19956V13.4732H5.12015V14.7966H5.12018ZM7.68026 14.7966H5.75967V13.4732H7.68026V14.7966ZM10.2404 14.7966H8.31978V13.4732H10.2404V14.7966ZM12.8004 14.7966H10.8799V13.4732H12.8004V14.7966ZM15.3605 14.7966H13.4399V13.4732H15.3605V14.7966ZM15.3605 12.8337H0.639486V3.16608H15.3605V12.8337ZM15.3605 2.52696H13.4399V1.20321H15.3605V2.52696Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1062_24826'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
