import tinytime from 'tinytime';
import * as React from 'react';

import { SlideOut, Text } from '@components/common';
import { compact } from '@components/utils';
import { timeRangeStr } from '@components/RepoSessionApp/ProfileSlideout/components/CalendarEvents/CalendarEventLink';
import { TimezoneIndicator } from '../TimezoneIndicator';

import { Guests } from './Guests';

const dateFormat = tinytime('{dddd}, {MMMM} {DD}');

export const buildDataRows = (
  participation: Participation,
  calendarEvent: CalendarEvent
): [string, React.ReactNode][] =>
  compact([
    ['Date', dateFormat.render(calendarEvent.start_at)],
    [
      'Time',
      <div className='flex flex-col space-y-1'>
        <Text h='400'>{timeRangeStr(calendarEvent)}</Text>
        <TimezoneIndicator className='text-gray-500' />
      </div>
    ],
    ['Duration', Math.floor(+calendarEvent.end_at - +calendarEvent.start_at) / 1000 / 60 + ' minutes'],
    !!calendarEvent.conference_provider && ['Call location', calendarEvent.conference_provider],
    [
      'Join URL',
      calendarEvent.conference_url || (
        <Text h='400' color='gray-500'>
          None
        </Text>
      )
    ],
    ['Attendees', <Guests participation={participation} calendarEvent={calendarEvent} />],
    ['Description', calendarEvent.description]
  ]);

type Props = {
  participation: Participation;
  calendarEvent: CalendarEvent;
  onClose?: () => void;
};

export const CalendarEventSlideOut: React.FC<Props> = ({ participation, calendarEvent, onClose }) => (
  <SlideOut size='2xl' onClose={onClose}>
    <div className='px-4'>
      <Text h='700' truncate mb='4'>
        {calendarEvent.title}
      </Text>
      <Text bold mb='4'>
        Event details
      </Text>
      <table className='w-full'>
        <tbody>
          {buildDataRows(participation, calendarEvent).map(([label, value]) => (
            <tr key={label}>
              <td className='py-2 pr-6 align-top'>
                <Text h='400' medium className='whitespace-nowrap'>
                  {label}
                </Text>
              </td>
              <td className='py-2'>{typeof value === 'string' ? <Text h='400'>{value}</Text> : value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </SlideOut>
);
