import { RadioGroup, RadioGroupOption } from 'components/common/RadioGroup';
import React, { useMemo } from 'react';

import { humanize } from '@components/utils';

const ROLES: AccountRole[] = ['admin', 'creator', 'observer'];
const DESCRIPTIONS = {
  admin: 'Can do everything on the account, including managing billing. ',
  creator: "Can create or edit content, but can't manage billing.",
  observer: "Can observe and view research, but can't create or edit content."
};

interface Props {
  selected: AccountRole;
  onChange: (role: AccountRole) => void;
  error?: boolean;
  isDisabled: (role: AccountRole) => boolean;
}

export const RoleOptions: React.FC<Props> = ({ selected, onChange, error = false, isDisabled }) => {
  const ROLE_OPTIONS: RadioGroupOption[] = useMemo(
    () =>
      ROLES.map((r) => ({
        label: humanize(r),
        helper: DESCRIPTIONS[r],
        value: r,
        disabled: isDisabled(r)
      })),
    [isDisabled]
  );

  return (
    <RadioGroup
      options={ROLE_OPTIONS}
      name='role'
      onChange={(v) => onChange(v as AccountRole)}
      selected={selected}
      borderColor={error ? 'red-600' : 'gray-200'}
    />
  );
};
