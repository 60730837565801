import React, { useState } from 'react';

import { api } from '@api/reduxApi';
import { useFeature } from '@hooks/useFeature';
import { SelectOption, Text } from '@components/common';
import { ModerationStyleDropdown } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/ModerationStyleDropdown';

import { FormGroup, Label, PanelWrapper } from '../shared';
import { CalendarTypeToggle } from '../NylasCalendar/CalendarTypeToggle';
import { CallModerators } from '../NylasCalendar/CallModerators';
import { UseStudyUsersHook } from '../NylasCalendar/hooks/useStudyUsers';
import { useGQCalendarContext } from '../../hooks/useCalendarContext';
import { ConfirmChangeModerationStyleModal } from './ConfirmChangeModerationStyleModal';

type Data = {
  icon: React.ReactElement;
  description: string;
};

type RenderSelected = <T extends string>(option: SelectOption<T, Data>) => React.ReactNode;

const renderSelected: RenderSelected = (option) => (
  <div className='flex items-center space-x-2'>
    {option.data?.icon} <span>{option.label}</span>
  </div>
);
interface Props {
  study: Study;
  onBack: () => void;
  readOnly?: boolean;
  onSave: (study: { id: number } & Partial<Study>) => void;
  onClose: () => void;
  studyUsersHook: UseStudyUsersHook;
}

export const AttendeesPanel: React.FC<Props> = ({ study, onSave, onBack, readOnly, onClose, studyUsersHook }) => {
  const { studyUserColors } = useGQCalendarContext();

  const [confirmModerationStyleModalOpen, setConfirmModerationStyleModalOpen] = useState(false);

  const [updateStudy, { isLoading }] = api.useUpdateStudyMutation();

  const anyPhantoms = studyUsersHook.allStudyUsers?.map((m) => m.teamUser)?.some((u) => u?.is_phantom);

  const handleChangeModerationStyle = async (v: ModerationStyle) => {
    await updateStudy({ id: study.id, moderation_style: v });
    studyUsersHook.fetchStudyUsers();
  };

  return (
    <PanelWrapper title='Attendees' onClose={onClose}>
      {study.style === 'video_call' && (
        <FormGroup>
          <Label tooltip='Determines how calls will be moderated and the resulting availability for calls.'>
            Scheduling style
          </Label>
          <ModerationStyleDropdown
            renderSelected={renderSelected}
            study={study}
            onChangeRoundRobinStyle={async (v) => {
              await updateStudy({ id: study.id, round_robin_style: v });
              studyUsersHook.fetchStudyUsers();
            }}
            onChangeModerationStyle={async (v) => {
              const numModerators =
                studyUsersHook.allStudyUsers?.filter((s) => s.studyUser.role === 'moderator')?.length || 0;

              if (v === 'single' && numModerators > 1) {
                setConfirmModerationStyleModalOpen(true);
              } else {
                await handleChangeModerationStyle(v);
              }
            }}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Label tooltip='Who will be moderating the call with the participant. Moderators will be on calendar invites with participants that schedule.'>
          {study.moderation_style !== 'single' ? 'Moderators' : 'Moderator'}
        </Label>
        <div className='pb-4'>
          <CallModerators
            studyUserColors={studyUserColors}
            studyUsersHook={studyUsersHook}
            study={study}
            type='moderator'
            requireCalendar
            onBack={onBack}
            onClickSwitchToCalendly={study.state === 'draft' ? onBack : undefined}
          />
        </div>
        <CalendarTypeToggle
          readOnly={readOnly}
          studyId={study.id}
          type={study.nylas_calendar_type}
          conflictScope={study.conflict_scope}
          onChange={onSave}
        />
      </FormGroup>

      <FormGroup>
        <Label tooltip='Who will be invited to listen in.  Observers will receive internal calendar invites separate from the moderator and participant calendar invites.'>
          Observers
        </Label>

        <CallModerators
          studyUserColors={studyUserColors}
          disabled={readOnly}
          type='observer'
          requireCalendar={false}
          studyUsersHook={studyUsersHook}
          study={study}
        />
        {anyPhantoms && (
          <Text color='gray-500' h='200'>
            *Some calendars can not be shown.
          </Text>
        )}
      </FormGroup>
      {confirmModerationStyleModalOpen && (
        <ConfirmChangeModerationStyleModal
          ownerName={study.owner?.first_name || 'the study owner'}
          isLoading={isLoading}
          onClose={() => setConfirmModerationStyleModalOpen(false)}
          onSubmit={async () => {
            await updateStudy({ id: study.id, moderation_style: 'single' });
            setConfirmModerationStyleModalOpen(false);
            studyUsersHook.fetchStudyUsers();
          }}
        />
      )}
    </PanelWrapper>
  );
};
