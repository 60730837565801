import * as React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useSidebar } from '@hooks/useSidebar';

interface Props {
  noSidebar?: boolean;
  keepSidebarDisabled?: boolean;
}

const FIXED_HEADER_CLASSES = ['sticky', 'top-0', 'z-20', 'w-full'];

function toggleFixedHeader(show: boolean): void {
  const header = document.getElementById('fixed-layout-header');
  if (!header) {
    return;
  }

  if (show) {
    header.classList.add(...FIXED_HEADER_CLASSES);
  } else {
    header.classList.remove(...FIXED_HEADER_CLASSES);
  }
}
export const WindowLayout: React.FC<Props> = ({ noSidebar = false, children }) => {
  const sidebar = useSidebar();
  useEffect(() => {
    if (noSidebar) {
      sidebar.setHidden(true);
      return () => sidebar.setHidden(false);
    }
  }, [noSidebar]);

  return <div className='flex flex-col h-full mx-auto'>{children}</div>;
};

export const WindowStickyContainer: React.FC = ({ children }) => {
  const root = document.getElementById('fixed-layout-header-portal');
  if (!root) return <>{children}</>;
  return ReactDOM.createPortal(<div className='top-0 w-full'>{children}</div>, root as any);
};
export const WindowHeader: React.FC = ({ children }) => (
  <div className='px-page top-0 flex items-center w-full py-4 bg-white border-b border-gray-200'>{children}</div>
);

export const WindowStickyHeader: React.FC = ({ children }) => (
  <WindowStickyContainer>
    <WindowHeader>{children}</WindowHeader>
  </WindowStickyContainer>
);

export const WindowFooter: React.FC = ({ children }) => (
  <div className='px-page fixed bottom-0 z-20 flex items-center w-full py-4 bg-white border-0 border-t border-gray-200'>
    {children}
  </div>
);

interface WindowLayoutBodyProps {
  fixedHeader?: boolean;
  children?: React.ReactNode;
  className?: string;
}

export const WindowLayoutBody: React.FC<WindowLayoutBodyProps> = ({
  children,
  fixedHeader = false,
  className = ''
}) => {
  useEffect(() => {
    toggleFixedHeader(fixedHeader);

    return () => {
      toggleFixedHeader(false);
    };
  }, [fixedHeader]);

  return <div className={className}>{children}</div>;
};
