import { InputProps } from 'components/Public/GQSurvey/types';
import * as React from 'react';
import { Controller } from 'react-hook-form';

import { LocationInput } from '@components/common/LocationInput';

export const LocationSearchInput: React.FC<InputProps> = ({
  field: { id, required },
  placeholder,
  onFocus,
  watch,
  control,
  error,
  autofocus
}) => {
  return (
    <Controller
      control={control}
      name={String(id)}
      id={String(id)}
      rules={{ required }}
      defaultValue={watch(String(id))}
      render={({ onChange }) => (
        <LocationInput
          inputClassName='focus-ring-custom-brand focus-border-custom-brand'
          inputValue={watch(String(id))}
          onChange={onChange}
          id={id}
          autoFocus={autofocus}
          aria-describedby={`description-for-${id}`}
          aria-required={required ? 'true' : 'false'}
          placeholder={placeholder}
          onFocus={onFocus}
          aria-invalid={error ? 'true' : 'false'}
        />
      )}
    />
  );
};
