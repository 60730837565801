import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['collapsed', 'expanded'];

  connect() {
    this.expandClass = this.element.dataset['expandClass'];
    this.collapseClass = this.element.dataset['collapseClass'];
    if (window.localStorage.getItem('navCollapsed')) {
      this.collapse();
    }
  }

  collapse(event) {
    if (event) {
      event.preventDefault();
    }

    this.collapsedTargets.forEach((target) => {
      target.classList.remove('hidden');
    });
    this.expandedTargets.forEach((target) => {
      target.classList.add('hidden');
    });
    this.element.classList.remove(this.expandClass);
    this.element.classList.add(this.collapseClass);
    window.localStorage.setItem('navCollapsed', true);
    // console.log(this.expandTargets)
  }

  expand(event) {
    event.preventDefault();

    this.collapsedTargets.forEach((target) => {
      target.classList.add('hidden');
    });
    this.expandedTargets.forEach((target) => {
      target.classList.remove('hidden');
    });
    this.element.classList.add(this.expandClass);
    this.element.classList.remove(this.collapseClass);
    window.localStorage.removeItem('navCollapsed');
    // console.log(this.expandTargets)
  }
}
