export const without = <T>(list: T[], ...vals: T[]): T[] => [...list.filter((v) => !vals.includes(v))];

export const take = <T>(list: T[], n = 1): T[] => list.slice(0, n);

export const compact = <T>(list: (T | '' | false | undefined)[]): T[] => list.filter(Boolean) as T[];

export const find = <T>(list: T[], fn: (t: T) => any): T => list.filter(fn)[0];

export const last = <T>(list: T[]): T => list[list.length - 1];

export const uniq = <T>(list: T[]): T[] => {
  const result: T[] = [];
  for (let i = 0; i < list.length; i++) {
    if (result.indexOf(list[i]) === -1) {
      result.push(list[i]);
    }
  }
  return result;
};

export const listsEq = <T>(a: T[], b: T[]): boolean => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

// What's important to note - this takes the LAST not the first.
export const uniqBy = <T, U = any>(list: T[], iteree: (val: T) => U): T[] => {
  const seen: U[] = [];
  const ret: T[] = [];
  for (const v of list) {
    const id = iteree(v);
    if (seen.includes(id)) {
      const idx = ret.findIndex((w) => iteree(w) === id);
      ret[idx] = v;
    } else {
      ret.push(v);
      seen.push(id);
    }
  }
  return ret;
};

export const sample = <T>(list: T[]): T => list[Math.floor(Math.random() * list.length)];

export const shuffle = <T>(list: T[]): T[] => {
  for (let i = list.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [list[i], list[j]] = [list[j], list[i]];
  }
  return list;
};

export const rangeMap = <T>(lo: number, hi: number, each: (i: number) => T): T[] => {
  const returns: T[] = [];
  if (lo <= hi) {
    for (let i = lo; i <= hi; i++) {
      returns.push(each(i));
    }
  }
  return returns;
};

export const closest = (target: number, list: number[]): number =>
  list.reduce((acc, n) => (Math.abs(n - target) < Math.abs(acc - target) ? n : acc));

export const pick = <T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> => {
  const ret: any = {};
  keys.forEach((key) => {
    if (obj[key] !== undefined) {
      ret[key] = obj[key];
    }
  });
  return ret;
};
