import { Basic } from '@components/shared/Skeleton';
import * as React from 'react';
import { Circle } from '@components/shared/Skeleton/Circle';

export const InterviewSkeleton = () => {
  return (
    <>
      <div className='desktop:space-x-5 relative flex items-center pb-1 space-x-3 overflow-x-auto overflow-y-visible flex px-6 items-center border-b border-gray-200'>
        <Basic h='6' width='75px' />
        <Basic h='6' width='85px' />
        <Basic h='6' width='70px' />
        <Basic h='6' width='90px' />
      </div>
      <div className='px-6 py-3'>
        <div className='flex items-center space-x-2 mb-2'>
          <Circle width={20} h='5' />
          <Basic h='6' width='115px' />
        </div>
        <Basic h='24' width='full' />
        <div className='flex items-center space-x-2 my-2'>
          <Circle width={20} h='5' />
          <Basic h='6' width='115px' />
        </div>
        <Basic h='28' width='full' />
        <div className='flex items-center space-x-2 my-2'>
          <Circle width={20} h='5' />
          <Basic h='6' width='115px' />
        </div>
        <Basic h='20' width='full' />
      </div>
    </>
  );
};
