import * as React from 'react';
import { Button, Modal, ModalHeading } from '@components/common';

interface Props {
  taggingsCount: number | undefined;
  onClose: () => void;
  onSubmit: () => void;
}
export const EditTagModal: React.FC<Props> = ({ onClose, onSubmit, taggingsCount }) => {
  return (
    <Modal size='md' onClose={onClose}>
      <ModalHeading className='mb-2'>Editing Global Tag</ModalHeading>
      <p className='pb-12 text-gray-700'>
        You are just about to edit the name of the Global Tag. The changes will be applied to all ({taggingsCount || 0})
        instances where the tag was used.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} primary>
          I understand
        </Button>
      </div>
    </Modal>
  );
};
