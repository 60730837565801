import React, { SVGProps } from 'react';

export const IncentivesSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1171_1829)'>
      <path
        d='M5.6875 7.875C5.6875 8.13459 5.76448 8.38835 5.9087 8.60419C6.05292 8.82003 6.2579 8.98825 6.49773 9.08759C6.73756 9.18693 7.00146 9.21292 7.25606 9.16228C7.51066 9.11164 7.74452 8.98663 7.92808 8.80308C8.11163 8.61952 8.23664 8.38566 8.28728 8.13106C8.33792 7.87646 8.31193 7.61256 8.21259 7.37273C8.11325 7.1329 7.94503 6.92792 7.72919 6.7837C7.51335 6.63948 7.25959 6.5625 7 6.5625C6.74041 6.5625 6.48665 6.48552 6.27081 6.3413C6.05497 6.19709 5.88675 5.9921 5.78741 5.75227C5.68807 5.51244 5.66208 5.24854 5.71272 4.99394C5.76336 4.73934 5.88837 4.50548 6.07192 4.32192C6.25548 4.13837 6.48934 4.01336 6.74394 3.96272C6.99854 3.91208 7.26244 3.93807 7.50227 4.03741C7.7421 4.13675 7.94709 4.30498 8.0913 4.52081C8.23552 4.73665 8.3125 4.99041 8.3125 5.25'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 3.0625V3.9375'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7 9.1875V10.0625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.125 5.46875C13.1284 7.25162 12.5232 8.98225 11.4095 10.3744C10.2957 11.7666 8.74015 12.737 7.00001 13.125C5.25988 12.737 3.70431 11.7666 2.59056 10.3744C1.47681 8.98225 0.871598 7.25162 0.875014 5.46875V2.40625C0.875014 2.00014 1.03634 1.61066 1.32351 1.32349C1.61067 1.03633 2.00015 0.875 2.40626 0.875H11.5938C11.9999 0.875 12.3894 1.03633 12.6765 1.32349C12.9637 1.61066 13.125 2.00014 13.125 2.40625V5.46875Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1171_1829'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
