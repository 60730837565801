import React, { HTMLAttributes, forwardRef } from 'react';
import cn from 'classnames';

import { Text } from '@components/common';
import { CheckmarkInCircleSVG } from '@components/svgs';

export const CompletedStatus = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex-nowrap bg-green-50 flex items-center justify-center w-full h-8 space-x-1', className)}
        {...rest}
      >
        <CheckmarkInCircleSVG className='text-green-500' />
        <Text h='200' className='no-branding text-green-500' bold uppercase>
          {children}
        </Text>
      </div>
    );
  }
);
