import { createInMemoryCache } from '@algolia/cache-in-memory';
import { ArtifactAttributes } from '@components/RepositoryApp/types';

import { HIGHLIGHT_POST_TAG, HIGHLIGHT_PRE_TAG, getHighlightedParts } from './utils';
import { AlgoliaConfig, AlgoliaData } from './types';

export const algoliaAdapter = async (
  { filters, query, page, infiniteResults, prevData }: AdapterProps<AlgoliaData>,
  { client, index, hitsPerPage }: AlgoliaConfig
): Promise<AlgoliaData> => {
  client.initPersonalization({
    responsesCache: createInMemoryCache(),
    requestsCache: createInMemoryCache({ serializable: false })
  });

  const clientIndex = client.initIndex(index);

  const { hits, nbPages, nbHits } = await clientIndex.search<ArtifactAttributes>(query, {
    filters,
    page,
    hitsPerPage,
    highlightPreTag: HIGHLIGHT_PRE_TAG,
    highlightPostTag: HIGHLIGHT_POST_TAG
  });

  return {
    hits: page > 0 && infiniteResults ? (prevData?.hits ?? []).concat(hits) : hits,
    hasMore: nbPages - 1 > page,
    nbHits,
    getHighlightedParts
  };
};
