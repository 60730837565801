import React, { useState, useRef } from 'react';
import cn from 'classnames';
import copy from 'copy-html-to-clipboard';
import Tippy from '@tippyjs/react';

import { Avatar, AvatarInitials } from '@components/common/Avatar';
import { Preview as MarkdownPreview } from '@components/common/MarkdownEditor';
import { ThumbsUpSVG, ThumbsDownSVG, CopySVG, CheckMarkSVG, AskAiRainbowSVG } from 'components/svgs';

import { Link } from './Link';

type MessageProps = AiMessage & {
  chat_id?: string;
  handleSubmit?: (message_id: string, rating: number) => void;
  user?: Partial<TeamUser>;
};

export const Message: React.FC<MessageProps> = ({
  chat_id,
  id,
  rich_content,
  status,
  content,
  role,
  user,
  rating,
  handleSubmit
}) => {
  const userName = user?.name || 'You';

  const viewFeedbackOnly = handleSubmit == null || chat_id == null;
  const isFeedbackUp = rating === 5;
  const isFeedbackDown = rating === 0;

  const isAssistantMsg = role === 'assistant';
  const hasChatId = chat_id != null;
  const hasRating = rating != null;
  const isCompleted = status === 'completed';
  const showFeedback = hasRating || (hasChatId && isCompleted && isAssistantMsg);
  const showCopyButton = hasChatId && isCompleted && isAssistantMsg;

  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopyResponse = () => {
    if (messageContainerRef.current) {
      const htmlContent = messageContainerRef.current.innerHTML;
      const wasCopied = copy(htmlContent, {
        asHtml: true,
        message: 'Press #{key} to copy'
      });

      if (wasCopied) {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      }
    }
  };

  const messageActionButtonBaseClasses = [
    'hover:bg-indigo-100',
    'disabled:opacity-50',
    'transition-all',
    'items-center',
    'justify-center',
    'rounded-md',
    'px-1',
    'py-1',
    'mr-1'
  ];

  const messageContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className='px-4 py-2 text-sm'>
      <div className='flex items-center space-x-2'>
        {role === 'assistant' && <AskAiRainbowSVG className='w-6 h-6' />}
        {role === 'user' && (user != null ? <Avatar user={user} /> : <AvatarInitials initials='U' colorKey={''} />)}
        <span className='font-bold'>{role === 'assistant' ? 'Assistant' : userName}</span>
      </div>
      <div className='pl-8' ref={messageContainerRef}>
        <MarkdownPreview
          urlTransform={(url) => {
            return url;
          }}
          components={{ a: Link }}
        >
          {rich_content || content}
        </MarkdownPreview>
        <div className='flex items-center justify-between'>
          {showFeedback && (
            <div className='flex items-center space-x-1'>
              <Tippy content='Good response' placement='bottom'>
                <button
                  className={cn(messageActionButtonBaseClasses, {
                    'bg-indigo-600 text-white hover:bg-indigo-600': isFeedbackUp
                  })}
                  name='thumbs_up'
                  onClick={() => !viewFeedbackOnly && handleSubmit(id, 5)}
                  disabled={isFeedbackUp || viewFeedbackOnly}
                  aria-label='Thumbs up'
                >
                  <ThumbsUpSVG className='w-4 h-4' />
                </button>
              </Tippy>
              <Tippy content='Bad response' placement='bottom'>
                <button
                  className={cn(messageActionButtonBaseClasses, {
                    'bg-indigo-600 text-white hover:bg-indigo-600': isFeedbackDown
                  })}
                  name='thumbs_down'
                  onClick={() => !viewFeedbackOnly && handleSubmit(id, 0)}
                  disabled={isFeedbackDown || viewFeedbackOnly}
                  aria-label='Thumbs down'
                >
                  <ThumbsDownSVG className='w-4 h-4' />
                </button>
              </Tippy>
            </div>
          )}
          <div className='flex items-center space-x-1'>
            {showCopyButton &&
              (copySuccess ? (
                <Tippy content='Copied!' placement='bottom'>
                  <button
                    className={cn(messageActionButtonBaseClasses.filter((c) => !c.startsWith('hover')))}
                    name='Copied'
                  >
                    <CheckMarkSVG className='w-4 h-4' />
                  </button>
                </Tippy>
              ) : (
                <Tippy content='Copy response to clipboard' placement='bottom'>
                  <button
                    className={cn(messageActionButtonBaseClasses)}
                    name='Copy link'
                    onClick={() => handleCopyResponse()}
                  >
                    <CopySVG className='w-4 h-4' />
                  </button>
                </Tippy>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
