import React, { forwardRef, HTMLAttributes, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Item, Menu } from '@components/common/Menu';
import { ArtifactHit } from '@components/RepositoryApp/types';
import { track } from '@components/tracking';
import { rangeMap } from '@components/utils';
import { getOSShortcut } from '@helpers/getOSShortcut';
import { useDeviceType } from '@hooks/useDeviceType';
import { useKeyboardShortcut } from '@hooks/useKeyboardShortcut';
import { Editor } from '@tiptap/react';

import { useCreateDocumentImageMutation, useCreateDocumentAssetMutation } from '../../api';
import { ArtifactsAttributes, MergeTagAttributes } from '../../extensions';
import { HEADING_STYLES } from '../../extensions/Heading';
import * as Queries from '../../helpers/queries';
import { ModalManager } from '../../hooks/useModalManager';
import * as Icons from '../../icons';
import type { ModalIds } from '../../Tiptap';
import type { Props as TiptapProps } from '../../useTiptap';
import { EmbedArtifactModal } from '../EmbedArtifactModal';
import { LinkModal } from '../LinkModal';
import { MenuBarButton } from '../MenuBarButton';
import { TemplatesModal } from '../TemplatesModal';
import { UploadImageModal } from '../UploadImageModal';
import { ImportDocumentModal } from '../ImportDocumentModal';
import { Grid } from '@components/common/Grid';
import { COLUMN_ACTIONS, TABLE_ACTIONS } from '@components/shared/Tiptap/components/MenuBar/constants';
import { CircleArrowsSVG, RedoSVG, UndoSVG } from 'components/svgs';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  editor: Editor | null;
  documentId: number;
  activeModal: ModalManager<ModalIds>['activeModal'];
  config: TiptapProps['config'];
  readonly?: boolean;
  setActiveModal: ModalManager<ModalIds>['setActiveModal'];
  closeModal: ModalManager<ModalIds>['closeModal'];
  onTemplatePick: TiptapProps['onTemplatePick'];
  saving?: boolean;
}

type Level = 1 | 2 | 3 | 4 | 5 | 6;

export const MenuBar = forwardRef<HTMLDivElement, Props>(
  (
    {
      editor,
      saving,
      activeModal,
      config = {},
      readonly,
      setActiveModal,
      closeModal,
      onTemplatePick,
      documentId,
      ...rest
    },
    ref
  ) => {
    const { isDesktopL } = useDeviceType();

    const { shortcut: imageShortcut } = useKeyboardShortcut({
      keys: { meta: true, shift: true, I: true },
      handler: () => setActiveModal('image')
    });

    const { shortcut: linkShortcut } = useKeyboardShortcut({
      keys: { meta: true, shift: true, U: true },
      handler: () => setActiveModal('link')
    });

    const { shortcut: embedShortcut } = useKeyboardShortcut({
      keys: { meta: true, alt: true, E: true },
      handler: () => setActiveModal('embed')
    });

    const { shortcut: templatesShortcut } = useKeyboardShortcut({
      keys: { meta: true, alt: true, T: true },
      handler: () => setActiveModal('templates')
    });

    const [selectedHits, setSelectedHits] = useState<ArtifactsAttributes['artifacts']>([]);
    const [createDocumentImage] = useCreateDocumentImageMutation();
    const [createDocumentAsset] = useCreateDocumentAssetMutation();

    if (!editor) {
      return null;
    }

    const getActiveTextExtension = () => {
      if (editor.isActive('heading')) {
        return `Heading ${editor.getAttributes('heading').level}`;
      }

      return 'Normal text';
    };

    const getActiveMergeTag = () => {
      const mergeTagAttrs = editor.getAttributes('merge_tag') as MergeTagAttributes;

      if (mergeTagAttrs.attr) {
        return `{{${mergeTagAttrs.attr}}}`;
      }

      return 'Add attribute…';
    };

    const handleTextExtensionChange = (value: string) => {
      if (!value) return;

      if (value === 'p') {
        editor.chain().focus().setParagraph().run();
        return;
      }

      editor
        .chain()
        .focus()
        .toggleHeading({ level: +value.replace('h', '') as Level })
        .run();
    };

    const handleOnEmbedBtnClick = () => {
      const { node } = Queries.findActiveNode(editor, 'artifacts') ?? {};

      if (node) {
        setSelectedHits(node.attrs.artifacts as ArtifactsAttributes['artifacts']);
      } else {
        setSelectedHits([]);
      }

      setActiveModal('embed');
    };

    const handleImageUpload = async (signedId: string) => {
      setActiveModal('image');

      const { url } = await createDocumentImage({ documentId, signedId }).unwrap();
      editor.chain().focus().setImage({ src: url }).createParagraphNear().run();

      closeModal();

      track('added_image_to_document', { documentId });
    };

    const handleImportDocument = async (signedId: string) => {
      setActiveModal('import_document');

      await createDocumentAsset({ documentId, signedId });

      closeModal();

      track('imported_image_to_document', { documentId });
    };

    const handleOnEmbedSelect = (artifacts: ArtifactHit[]) => {
      const { node } = Queries.findActiveNode(editor, 'artifacts') ?? {};
      const artifactIds = artifacts.map(({ objectID }) => objectID);

      if (node) {
        editor
          .chain()
          .focus()
          .updateAttributes('artifacts', { ...node.attrs, artifacts: artifactIds } as ArtifactsAttributes)
          .run();

        return;
      }

      editor
        .chain()
        .focus()
        .insertContent({
          type: 'artifacts',
          attrs: { uuid: uuid(), artifacts: artifactIds } as ArtifactsAttributes
        })
        .createParagraphNear()
        .run();

      track('added_artifacts_to_document', { documentId });
    };

    const handleMergeTagChange = (value: string) => {
      editor
        .chain()
        .focus()
        .insertContent({ type: 'merge_tag', attrs: { attr: value } })
        .run();
    };

    return (
      <section ref={ref} {...rest}>
        <div className='flex justify-between w-full'>
          <div className='flex flex-wrap items-center'>
            {config.headings && (
              <>
                <Menu
                  className='overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg'
                  onItemSelect={handleTextExtensionChange}
                  popperProps={{ placement: 'bottom-start', zIndex: 30 }}
                  renderTrigger={({ isOpen }) => (
                    <MenuBarButton className='h-10 px-4 text-sm' isActive={isOpen} isDisabled={readonly}>
                      {getActiveTextExtension()}
                      <Icons.ChevronDown className='ml-2' />
                    </MenuBarButton>
                  )}
                >
                  <Item value='p' className='hover:bg-indigo-600 hover:text-white px-4 py-2'>
                    Normal text
                  </Item>

                  {rangeMap(1, 6, (n) => (
                    <Item
                      key={n}
                      value={`h${n}`}
                      className='hover:bg-indigo-600 hover:text-white flex items-center justify-between px-4 py-2'
                    >
                      <span className={HEADING_STYLES[n]}>Heading {n}</span>
                      <span className='px-1 ml-3 text-xs text-gray-700 bg-gray-200 rounded'>
                        {getOSShortcut({ alt: true, meta: true, [n]: true }).join('')}
                      </span>
                    </Item>
                  ))}
                </Menu>
                <div className='h-6 mr-2 border-r border-gray-200' />
              </>
            )}
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().toggleBold().run()}
              aria-selected={editor.isActive('strong')}
              isActive={editor.isActive('strong')}
              isDisabled={readonly}
              tooltip={`Bold (${getOSShortcut({ meta: true, B: true }).join('')})`}
            >
              <Icons.B />
            </MenuBarButton>
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().toggleItalic().run()}
              aria-selected={editor.isActive('em')}
              isActive={editor.isActive('em')}
              isDisabled={readonly}
              tooltip={`Italic (${getOSShortcut({ meta: true, I: true }).join('')})`}
            >
              <Icons.I />
            </MenuBarButton>
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              aria-selected={editor.isActive('underline')}
              isActive={editor.isActive('underline')}
              isDisabled={readonly}
              tooltip={`Underline (${getOSShortcut({ meta: true, U: true }).join('')})`}
            >
              <Icons.U />
            </MenuBarButton>
            <div className='h-6 mr-2 border-r border-gray-200' />
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              aria-selected={editor.isActive({ textAlign: 'left' })}
              isActive={editor.isActive({ textAlign: 'left' })}
              isDisabled={readonly}
              tooltip={`Align left (${getOSShortcut({ meta: true, shift: true, L: true }).join('')})`}
            >
              <Icons.AL />
            </MenuBarButton>
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              aria-selected={editor.isActive({ textAlign: 'center' })}
              isActive={editor.isActive({ textAlign: 'center' })}
              isDisabled={readonly}
              tooltip={`Align center (${getOSShortcut({ meta: true, shift: true, E: true }).join('')})`}
            >
              <Icons.AC />
            </MenuBarButton>
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              aria-selected={editor.isActive({ textAlign: 'right' })}
              isActive={editor.isActive({ textAlign: 'right' })}
              isDisabled={readonly}
              tooltip={`Align right (${getOSShortcut({ meta: true, shift: true, R: true }).join('')})`}
            >
              <Icons.AR />
            </MenuBarButton>
            <div className='h-6 mr-2 border-r border-gray-200' />
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              aria-selected={editor.isActive('orderedList')}
              isActive={editor.isActive('orderedList')}
              isDisabled={readonly}
              tooltip={`Ordered list (${getOSShortcut({ meta: true, shift: true, 7: true }).join('')})`}
            >
              <Icons.OL />
            </MenuBarButton>
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              aria-selected={editor.isActive('bulletList')}
              isActive={editor.isActive('bulletList')}
              isDisabled={readonly}
              tooltip={`Bullet list (${getOSShortcut({ meta: true, shift: true, 8: true }).join('')})`}
            >
              <Icons.UL />
            </MenuBarButton>

            <div className='h-6 mr-2 border-r border-gray-200' />
            {config.link?.enable && (
              <MenuBarButton
                className='w-6 h-6 my-3 mr-2'
                onClick={() => setActiveModal('link')}
                tooltip={`Insert link (${linkShortcut.join('')})`}
                isActive={editor.isActive('link')}
                isDisabled={readonly || editor.isActive('cta')}
              >
                <Icons.Link />
              </MenuBarButton>
            )}
            {config.image?.enable && (
              <MenuBarButton
                className='w-6 h-6 my-3 mr-2'
                aria-label='Upload image'
                onClick={() => setActiveModal('image')}
                tooltip={`Upload image (${imageShortcut.join('')})`}
                isDisabled={readonly}
              >
                <Icons.Image />
              </MenuBarButton>
            )}
            {config.artifacts && (
              <>
                <div className='h-6 mr-2 border-r border-gray-200' />
                <MenuBarButton
                  aria-label='Embed'
                  className='w-6 h-6 my-3 mr-2'
                  onClick={handleOnEmbedBtnClick}
                  tooltip={`Embed (${embedShortcut.join('')})`}
                  isDisabled={readonly}
                >
                  <Icons.Embed />
                </MenuBarButton>
              </>
            )}
            {config.templates && (
              <MenuBarButton
                className='w-6 h-6 my-3 mr-2'
                aria-label='Use a template'
                onClick={() => setActiveModal('templates')}
                tooltip={`Use a template (${templatesShortcut.join('')})`}
                isDisabled={readonly}
              >
                <Icons.Templates />
              </MenuBarButton>
            )}
            {config.cta?.enable && (
              <>
                <div className='h-6 mr-2 border-r border-gray-200' />
                <MenuBarButton
                  className='whitespace-nowrap h-10 px-4 text-sm text-indigo-600'
                  isDisabled={Queries.getAllNodesOfType(editor.state, 'cta').length > 0 || readonly}
                  onClick={() =>
                    editor
                      .chain()
                      .focus()
                      .insertContent({ type: 'cta', content: [{ type: 'text', text: config.cta?.default }] })
                      .run()
                  }
                  tooltip='Add call to action button'
                >
                  Add CTA Button
                </MenuBarButton>
              </>
            )}
            {config.import_document && (
              <MenuBarButton
                className='w-6 h-6 my-3 mr-2'
                aria-label='Import Document'
                onClick={() => setActiveModal('import_document')}
                tooltip='Import Document'
                isDisabled={readonly}
              >
                <Icons.ImportDocument />
              </MenuBarButton>
            )}
            {config.mergeTags && config.mergeTags.tags.length > 0 && (
              <>
                <div className='h-6 mr-2 border-r border-gray-200' />
                <Menu
                  className='max-h-72 overflow-hidden overflow-y-auto text-sm bg-white border border-gray-200 rounded-md shadow-lg'
                  onItemSelect={handleMergeTagChange}
                  popperProps={{ placement: 'bottom-start', zIndex: 50 }}
                  renderTrigger={({ isOpen }) => (
                    <MenuBarButton
                      className='whitespace-nowrap h-10 px-4 text-sm'
                      isActive={isOpen}
                      isDisabled={readonly}
                    >
                      {getActiveMergeTag()}
                      <Icons.ChevronDown className='ml-2' />
                    </MenuBarButton>
                  )}
                >
                  {config.mergeTags.tags.map((attribute) => (
                    <Item key={attribute} value={attribute} className='hover:bg-indigo-600 hover:text-white px-4 py-2'>
                      {`{{${attribute}}}`}
                    </Item>
                  ))}
                </Menu>
              </>
            )}
            {config.columns && (
              <Menu
                className='overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg'
                onItemSelect={handleTextExtensionChange}
                popperProps={{ placement: 'bottom-start', zIndex: 30 }}
                renderTrigger={({ isOpen }) => (
                  <MenuBarButton className='w-6 h-6 my-3 mr-2' isActive={isOpen} isDisabled={readonly}>
                    <Icons.AddColumns />
                  </MenuBarButton>
                )}
              >
                <Grid className='p-3' columns={5} gap={3}>
                  {COLUMN_ACTIONS.map(({ desc, Icon, func }, i) => (
                    <MenuBarButton
                      key={i}
                      className='flex-shrink-0'
                      aria-label={desc}
                      onClick={func(editor)}
                      tooltip={desc}
                      isDisabled={readonly}
                    >
                      <div className='flex-shrink-0 p-1'>
                        <Icon />
                      </div>
                    </MenuBarButton>
                  ))}
                </Grid>
              </Menu>
            )}
            {config?.tables && (
              <Menu
                className='overflow-hidden text-sm bg-white border border-gray-200 rounded-md shadow-lg'
                onItemSelect={handleTextExtensionChange}
                popperProps={{ placement: 'bottom-start', zIndex: 30 }}
                renderTrigger={({ isOpen }) => (
                  <MenuBarButton className='w-6 h-6 my-3 mr-2' isActive={isOpen} isDisabled={readonly}>
                    <Icons.Table />
                  </MenuBarButton>
                )}
              >
                <Grid className='p-3' columns={5} gap={3}>
                  {TABLE_ACTIONS.map(({ desc, Icon, func }, i) => (
                    <MenuBarButton
                      key={i}
                      className='flex-shrink-0'
                      aria-label={desc}
                      onClick={func(editor)}
                      tooltip={desc}
                      isDisabled={readonly}
                    >
                      <div className='flex-shrink-0 p-1'>
                        <Icon />
                      </div>
                    </MenuBarButton>
                  ))}
                </Grid>
              </Menu>
            )}
            <div className='h-6 mr-2 border-r border-gray-200' />
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().undo().run()}
              isDisabled={readonly || !editor.can().undo()}
              tooltip={`Undo (${getOSShortcut({ meta: true, Z: true }).join('')})`}
            >
              <UndoSVG />
            </MenuBarButton>
            <MenuBarButton
              className='w-6 h-6 my-3 mr-2'
              onClick={() => editor.chain().focus().redo().run()}
              isDisabled={readonly || !editor.can().redo()}
              tooltip={`Redo (${getOSShortcut({ meta: true, Y: true }).join('')})`}
            >
              <RedoSVG />
            </MenuBarButton>
          </div>
          {saving && (
            <div className='flex items-center space-x-2'>
              <CircleArrowsSVG className='animate-spin' />
              {isDesktopL && <span className='text-sm text-gray-400'>The document was automatically saved</span>}
            </div>
          )}
        </div>
        {!readonly && (
          <>
            <LinkModal editor={editor} isOpen={activeModal === 'link'} onClose={closeModal} />
            <UploadImageModal isOpen={activeModal === 'image'} onUpload={handleImageUpload} onClose={closeModal} />
            <ImportDocumentModal isOpen={activeModal === 'import_document'} onUpload={handleImportDocument} onClose={closeModal} />
            <EmbedArtifactModal
              isOpen={activeModal === 'embed'}
              onClose={closeModal}
              onSelect={handleOnEmbedSelect}
              selectedHits={selectedHits}
            />
            <TemplatesModal
              editor={editor}
              isOpen={activeModal === 'templates'}
              onClose={closeModal}
              onTemplatePick={onTemplatePick}
            />
          </>
        )}
      </section>
    );
  }
);
