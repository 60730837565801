import { api } from 'api/reduxApi';
import { useToaster } from 'components/stores/toaster';
import { Provider as CollectionView, useCollectionView } from 'components/stores/view';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { Alert, Button, PopperDropdown, PopperDropdownButton, PopperDropdownLink } from '@components/common';
import { IncentivesZDSPage } from '@components/IncentivesApp/components/IncentivesZDSPage';
import { buildIncentivesFilterDefs } from '@components/IncentivesApp/filters/buildIncentivesFilterDefs';
import { sortIncentives } from '@components/IncentivesApp/utils';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { RequestUpgradeModal } from '@components/RoleRequests/RequestUpgradeModal';
import { PageHeader } from '@components/shared/PageHeader';
import { RestrictedAction } from '@components/shared/RestrictedAction';
import { PlanUpgradeModal } from '@components/shared/RestrictedAction/PlanUpgradeModal';
import { AnyAllToggle, SegmentActions, TableFilters, useTableFilters } from '@components/shared/TableFilters';
import { ThanksSlideOut } from '@components/StudyMessages';
import { moneyFormat } from '@components/utils';
import { useAccount } from '@hooks/useAccount';
import { useRecords } from '@hooks/useRecords';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import { ChangeStatusModal } from './components/ChangeStatusModal';
import { CsvExport as CsvExportButton } from './components/CsvExport';
import { IncentivesTable } from './components/IncentivesTable';
import { Skeleton } from './components/Skeleton';
import { WalletDepositModal } from './components/WalletDepositModal';
import { WalletInvoiceModal } from './components/WalletInvoiceModal';
import { WalletStats } from './components/WalletStats';
import { NewIncentive } from './NewIncentive';
import { IncentiveAction } from './types';

const SendIncentive: React.FC<{ study_id: number; party_id: number; onSend: () => void; onCancel: () => void }> = ({
  study_id,
  party_id,
  onSend,
  onCancel
}) => {
  const { data: study, isLoading: loading, isError: studyError } = api.useGetStudyQuery(study_id ?? skipToken);

  const showToast = useToaster();

  useEffect(() => {
    if (studyError) {
      showToast({
        heading: 'Something went wrong!',
        text: 'Please try again later.',
        icon: 'error'
      });
    }
  }, [studyError]);

  if (loading) {
    return <div>Loading</div>;
  } else {
    return <ThanksSlideOut study={study as any} partyIds={[party_id]} onClose={onCancel} onSuccess={onSend} />;
  }
};

export const IncentivesIndex: React.FC = () => {
  return (
    <CollectionView scope='incentives'>
      <IncentivesIndexInner />
    </CollectionView>
  );
};
const IncentivesIndexInner: React.FC = () => {
  const [getIncentives] = api.useLazyGetIncentivesQuery();
  const { records: incentives, loading, refresh } = useRecords<Incentive>(() => getIncentives().unwrap());

  const [selected, setSelected] = useState<Incentive | null>();
  const [upgradeModal, setUpgradeModal] = useState<null | 'request_role_upgrade' | 'plan_upgrade'>();

  const viewHook = useCollectionView();
  const { view, setView } = viewHook;
  const { sort } = view;

  const [value, setValue] = useState<string>('');
  const [mode, setMode] = useState<IncentiveAction | null>();

  const {
    account: { wallet }
  } = useAccount();

  const navigate = useNavigate();

  const searchedIncentives = useMemo(
    () =>
      value
        ? incentives?.filter(
            (g) =>
              g.study.title.toLowerCase().includes(value.toLowerCase()) ||
              (g.candidate.name || '').toLowerCase().includes(value.toLowerCase())
          )
        : incentives,
    [incentives, value]
  );

  const definitions = useMemo(() => buildIncentivesFilterDefs(), []);

  const filtersHook = useTableFilters<Incentive>({
    trackKey: 'incentives',
    definitions
  });

  const incentivesToDisplay = searchedIncentives
    ? filtersHook.rawFilter(sortIncentives([...searchedIncentives], sort))
    : [];

  const walletEmpty = wallet.available + wallet.spent + wallet.allocated === 0;
  const showZDS = (!incentives || incentives?.length === 0) && walletEmpty;

  function handleSelected(incentive: Incentive, mode?: IncentiveAction) {
    setSelected(incentive);
    setMode(mode);
  }

  function handleCancel() {
    setSelected(null);
    setMode(null);
  }

  function handleSuccess() {
    setSelected(null);
    setMode(null);
    refresh();
  }

  const renderCta = () => (
    <>
      <CsvExportButton incentives={incentives || []} />
      <PopperDropdown medium text='Manage wallet'>
        <PopperDropdownLink to='/incentives/deposit'>Deposit funds…</PopperDropdownLink>
        <RestrictedAction setUpgradeModal={setUpgradeModal} feature='fund_with_invoice'>
          {({ can, may }) => (
            <PopperDropdownLink disabled={!can || !may} to='/incentives/invoice'>
              Create invoice…
            </PopperDropdownLink>
          )}
        </RestrictedAction>
      </PopperDropdown>
      <Button className='whitespace-nowrap' primary medium onClick={() => navigate(`/incentives/send`)}>
        New incentive
      </Button>
      {upgradeModal === 'plan_upgrade' && (
        <PlanUpgradeModal feature='fund_with_invoice' onClose={() => setUpgradeModal(null)} />
      )}
      {upgradeModal === 'request_role_upgrade' && (
        <span className='text-left'>
          <RequestUpgradeModal onCancel={() => setUpgradeModal(null)} />
        </span>
      )}
    </>
  );

  const renderFilters = () => (
    <div className='px-page flex items-center border-t border-gray-200'>
      <div className='flex-1'>
        <TableFilters<Incentive> hook={filtersHook} defaultShowInput />
      </div>
      <div className='flex py-3 pl-3 space-x-3 border-l border-gray-200'>
        <AnyAllToggle value={filtersHook.op} onChange={filtersHook.setOp} />
        <SegmentActions onClear={filtersHook.clearFilters} anyFilters={filtersHook.filters.length > 0} />
      </div>
    </div>
  );

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      {showZDS ? (
        <IncentivesZDSPage />
      ) : (
        <DashboardLayout>
          <PageHeader
            filtersApplied={!!filtersHook.filters.length}
            searchProps={{
              onSearch: setValue,
              value
            }}
            renderCta={renderCta}
            renderFilters={renderFilters}
            h1='Incentives'
          >
            <WalletStats />
          </PageHeader>

          <DashboardLayoutBody className='min-h-full'>
            {wallet.outstanding > 0 && (
              <Alert type='warning' className='mb-4'>
                <span>
                  Does not include recently created invoice of <b>${moneyFormat(wallet.outstanding / 100)} USD</b>
                </span>
              </Alert>
            )}

            <IncentivesTable handleSelect={handleSelected} incentives={incentivesToDisplay} />
          </DashboardLayoutBody>
          {selected && mode === 'send' && (
            <SendIncentive
              party_id={selected.participation.id}
              study_id={selected.study.id}
              // candidate_id={selected.candidate.id}
              onSend={handleSuccess}
              onCancel={handleCancel}
            />
          )}
          {selected && mode && ['cancel', 'no_show'].includes(mode) && (
            <ChangeStatusModal
              action={mode}
              incentives={[selected]}
              onSuccess={handleSuccess}
              onCancel={handleCancel}
            />
          )}
        </DashboardLayout>
      )}
      <Routes>
        <Route path='send' element={<NewIncentive onSuccess={refresh} />} />
        <Route
          path='invoice'
          element={
            <WalletInvoiceModal onSuccess={() => navigate('/incentives')} onCancel={() => navigate('/incentives')} />
          }
        />
        <Route
          path='deposit'
          element={
            <WalletDepositModal onSuccess={() => navigate('/incentives')} onCancel={() => navigate('/incentives')} />
          }
        />
      </Routes>
    </>
  );
};
