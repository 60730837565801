import tinytime from 'tinytime';
import * as React from 'react';

import { Select, Text } from '@components/common';
import { TimezoneDesc, timezoneFromName, TimezonePicker } from '@components/shared/TimezonePicker';
import { addDaysToDate } from '@components/utils';

import { FormGroup, Label, PanelWrapper } from '../shared';
import { CONTINUOUS_OPTIONS } from '../../components/NylasCalendar/constants';
import { InterviewDates, InterviewDatesDropdown } from './components/InterviewDatesDropdown';
import { fixSlots, getHourMin, getStringFromDate } from '../../components/NylasCalendar/utils';
import { AvailabilityRow, NextWeekButton, PreviousWeekButton } from './components';
import { DAYS_SHORT_TEXT } from './constants';
import { getInitialDuration } from './utils';

const shortDateTemplate = tinytime('{MM} {DD}');

interface Props {
  onClose: () => void;
  study: Study;
  onSave: (study: { id: number } & Partial<Study>) => void;
  readOnly?: boolean;
  addDebounce?: boolean;
  setWeekIdx: (weekIdx: number) => void;
  weekIdx: number;
  calAvailableSlots: CalenderAvailableSlots;
  disablePrevWeekButton?: boolean;
  disableNextWeekButton?: boolean;
  startDate: Date;
  endDate: Date | null;
  viewSettings: CalenderViewSettings;
  dates: Date[];
  timeSlot: RecurringTimeSlot | OneOffTimeSlot;
  changeTimeSlot: (attr: string, val: string | number | number[]) => void;
  setCalAvailableSlots: (v: CalenderAvailableSlots) => void;
}

const renderLabel = (option) => (
  <div className='flex items-center space-x-2 overflow-hidden'>
    {option.data?.icon}
    <Text h='400'>{option.label}</Text>
  </div>
);

export const AvailabilityPanel: React.FC<Props> = ({
  readOnly,
  onClose,
  study,
  onSave,
  weekIdx,
  setWeekIdx,
  calAvailableSlots,
  disablePrevWeekButton,
  disableNextWeekButton,
  startDate,
  endDate,
  viewSettings,
  dates,
  timeSlot,
  changeTimeSlot,
  setCalAvailableSlots
}) => {
  const monday = dates[0];
  const sunday = dates[6];

  const onDatesChange = (values: Omit<InterviewDates, 'schedulingStyle'>) => {
    setWeekIdx(0);

    const calAvailSlots = {
      ...calAvailableSlots,
      start_date: getStringFromDate(values.startDate),
      end_date: values.endDate ? getStringFromDate(values.endDate) : null,
      available_days_in_future: undefined
    };
    onSave({
      id: study.id,
      cal_available_slots: calAvailSlots
    });

    setCalAvailableSlots(calAvailSlots);
  };

  return (
    <PanelWrapper onClose={onClose} title='Availability'>
      <FormGroup>
        <Label tooltip='Determines which dates participants will be able to schedule calls on.'>Scheduling dates</Label>
        {study.continuous && (
          <Select
            disabled={readOnly}
            options={CONTINUOUS_OPTIONS}
            className='overflow-hidden mb-2'
            renderLabel={renderLabel}
            renderSelected={renderLabel}
            onChange={(v) => {
              onDatesChange({
                startDate,
                endDate: v === 'date_range' ? addDaysToDate(startDate, 14) : null
              });
            }}
            value={endDate ? 'date_range' : 'continuous'}
          />
        )}
        <InterviewDatesDropdown
          disabled={readOnly}
          isDraft={study.state === 'draft'}
          initialState={{
            startDate,
            endDate
          }}
          onChange={onDatesChange}
          continuous={!endDate}
        />
      </FormGroup>
      <FormGroup className='overflow-auto tablet:overflow-visible w-full'>
        <Label tooltip='Determines what times participants will be able to schedule calls within the scheduling window.'>
          Scheduling hours
        </Label>
        {DAYS_SHORT_TEXT.map((day: string, i) => {
          const date = dates[i];

          const currentSlots = calAvailableSlots.slots.filter((e) => {
            const dayIsCurrent = i === 6 ? e.day === 0 : e.day === i + 1;

            return dayIsCurrent && (e.date === getStringFromDate(date) || !e.single);
          });

          const sortedSlots = currentSlots.sort((a, b) => a.time.localeCompare(b.time));

          return (
            <AvailabilityRow
              unavailable={Boolean(startDate > date || (endDate && endDate < date))}
              key={i}
              allSlots={calAvailableSlots.slots}
              currentSlots={sortedSlots}
              day={day}
              date={date}
              endTime={getHourMin(viewSettings.end_time || '19:00')}
              onChange={(events) => {
                setCalAvailableSlots({ ...calAvailableSlots, slots: fixSlots(events) });
                onSave({ id: study.id, cal_available_slots: { ...calAvailableSlots, slots: fixSlots(events) } });
              }}
              startHour={getHourMin(viewSettings.start_time || '7:00').hour}
              initialDuration={getInitialDuration(study.duration_in_minutes)}
              disabled={readOnly}
            />
          );
        })}

        <div className='flex items-center justify-center mt-6 space-x-2'>
          <PreviousWeekButton disablePrevWeekButton={disablePrevWeekButton} onClick={() => setWeekIdx(weekIdx - 1)} />
          <Text h='400'>{`Week of ${shortDateTemplate.render(monday)} - ${shortDateTemplate.render(sunday)}`}</Text>
          <NextWeekButton disableNextWeekButton={disableNextWeekButton} onClick={() => setWeekIdx(weekIdx + 1)} />
        </div>
      </FormGroup>
      <FormGroup>
        <Label tooltip="Sets the time zone for which you're setting up availability. When scheduling, participants will be able to see times in their local time zone.">
          Time zone
        </Label>
        <TimezonePicker
          readOnly={readOnly}
          selectedTimezone={timezoneFromName(timeSlot.timezone) as TimezoneDesc}
          setSelectedTimezone={(v) => {
            if (v?.name) changeTimeSlot('timezone', v?.name);
          }}
        />
      </FormGroup>
    </PanelWrapper>
  );
};
