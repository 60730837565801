import { TippyOrNot } from '@components/common';
import cn from 'classnames';
import { CaretSVG } from '@components/svgs';
import * as React from 'react';

interface Props {
  disablePrevWeekButton?: boolean;
  onClick: () => void;
  withBorder?: boolean;
}

export const PreviousWeekButton: React.FC<Props> = ({ withBorder, disablePrevWeekButton, onClick }) => (
  <TippyOrNot show={disablePrevWeekButton} content='Previous week is out of the date range'>
    <button
      disabled={disablePrevWeekButton}
      className={cn('focus:outline-none disabled:text-gray-400 flex items-center justify-center w-6 h-6 rounded-full', {
        'hover:bg-gray-50 hover:text-indigo-600': !disablePrevWeekButton,
        'border border-gray-200': withBorder
      })}
      onClick={onClick}
      name='previous week'
      aria-label='Previous week'
    >
      <CaretSVG className='transform rotate-180' />
    </button>
  </TippyOrNot>
);
