import { api } from '@api/reduxApi';

interface Checkout {
  payment_method_id?: string;
  plan_id?: number;
  study_id: number;
  incentive: number;
  participant_limit: number;
  amount: number;
}
const billingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    processStudyCheckout: builder.mutation<void, Checkout>({
      query: ({ study_id, ...checkout }) => ({
        url: '/billing/study_checkouts',
        method: 'POST',
        body: { study_id, checkout }
      })
    })
  })
});

export const { useProcessStudyCheckoutMutation } = billingApi;
