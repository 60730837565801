import { api } from '@api/reduxApi';

interface Hook {
  loading: boolean;
  studies: Study[];
  panelStudies?: Study[];
}
export const useStudies = (): Hook => {
  const { data, isLoading } = api.useGetStudiesQuery();
  const panelStudies = data?.filter((s) => s.style === 'panel' && s.state === 'active');

  return { panelStudies, studies: data || [], loading: isLoading };
};
