import { datadogRum } from '@datadog/browser-rum';

// const tokenEl = document.getElementById('datadog-token')
const env = process.env.NODE_ENV;

if (env === 'production') {
  const context = document.getElementById('datadog-rum-context');

  if (context) {
    const data = JSON.parse(context.content);

    datadogRum.init({
      env,
      applicationId: '2d6e52bf-1306-4606-9c26-6044d1c46d09',
      clientToken: 'pubee865a368215e5582529eb442b1ce5f9',
      site: 'datadoghq.com',
      service: 'great-question',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      startSessionReplayRecordingManually: true,
      defaultPrivacyLevel: 'mask-user-input'
    });

    datadogRum.setUser(data);

    if (data.record_session) {
      datadogRum.startSessionReplayRecording();
    }
  }
}
