import * as React from 'react';

import { useInputState } from '@components/CandidateAttrs/hooks/useInputState';
import { getWrapperClass } from '@components/CandidateAttrs/index';
import { Textarea } from '@components/common';

export const TextAreaInput: React.FC<CandidateAttrInput<string>> = ({
  value,
  label,
  styles,
  setValue,
  onSave,
  autoFocus,
  onFocus,
  onBlur
}) => {
  const { handleBlur, handleFocus, focused } = useInputState(value, setValue, onBlur, onFocus);

  const handleEnter = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      onSave && onSave(value);
    }
  };

  const onClickOutside = () => {
    onSave?.(value);
    handleBlur();
  };

  return (
    <tr data-testid='attr-field' className={getWrapperClass(styles, focused)}>
      <td className={styles?.label}>{label}</td>
      <td className={styles?.value}>
        <Textarea
          value={value}
          onBlur={onClickOutside}
          onFocus={handleFocus}
          onChange={setValue}
          onKeyDown={handleEnter}
          placeholder='Enter…'
        />
      </td>
    </tr>
  );
};
