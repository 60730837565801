import React, { SVGProps } from 'react';

export const SkipLogicSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.0835 3.9375C4.04999 3.9375 4.8335 3.154 4.8335 2.1875C4.8335 1.221 4.04999 0.4375 3.0835 0.4375C2.117 0.4375 1.3335 1.221 1.3335 2.1875C1.3335 3.154 2.117 3.9375 3.0835 3.9375Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0835 13.5625C4.04999 13.5625 4.8335 12.779 4.8335 11.8125C4.8335 10.846 4.04999 10.0625 3.0835 10.0625C2.117 10.0625 1.3335 10.846 1.3335 11.8125C1.3335 12.779 2.117 13.5625 3.0835 13.5625Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.91699 5.6875C9.88349 5.6875 10.667 4.904 10.667 3.9375C10.667 2.971 9.88349 2.1875 8.91699 2.1875C7.95049 2.1875 7.16699 2.971 7.16699 3.9375C7.16699 4.904 7.95049 5.6875 8.91699 5.6875Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.0835 3.9375V10.0625'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.91683 5.6875C8.91683 6.30634 8.671 6.89983 8.23341 7.33742C7.79583 7.775 7.20233 8.02083 6.5835 8.02083H4.8335C4.36937 8.02083 3.92425 8.20521 3.59606 8.5334C3.26787 8.86159 3.0835 9.3067 3.0835 9.77083'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
