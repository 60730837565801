import { cloneElement, ReactElement } from 'react';

export const mapReactChildren = (children: ReactElement[], closePopper, key?: string) =>
  children.map((child, index) =>
    cloneElement(child, {
      key: key ?? index,
      closePopper: (e) => {
        e.stopPropagation();
        closePopper();
      }
    })
  );
