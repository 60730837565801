import React from 'react';
import { useOnboardingContext } from '@components/Onboarding/Onboarding';

export const Stepper = () => {
  const { currentStep, totalSteps } = useOnboardingContext();
  const fillPercentage = ((currentStep || 0) / (totalSteps || 1)) * 100;

  return (
    <div className='w-full max-w-3xl mx-4 mx-auto h-2 my-4 overflow-hidden bg-gray-200 rounded'>
      <div className='h-full bg-indigo-600 rounded' style={{ width: `${fillPercentage}%` }} />
    </div>
  );
};
