import React, { SVGProps } from 'react';

export const CalendlyIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M0.501343 2.5H15.5013V15.5H0.501343V2.5Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M0.501343 6.5H15.5013' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4.50134 4V0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M11.5013 4V0.5' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
