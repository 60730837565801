import React, { SVGProps } from 'react';

export const SortZaSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.8125 13.5615V0.436523'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.1875 10.9365L4.8125 13.5615L7.4375 10.9365'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.18753 0.311523H11.2875C11.3733 0.311498 11.4578 0.332499 11.5336 0.372689C11.6094 0.412879 11.6742 0.471032 11.7223 0.542062C11.7705 0.613092 11.8004 0.694833 11.8096 0.780132C11.8189 0.865431 11.807 0.951688 11.7752 1.03136L9.22486 4.84111C9.1929 4.9208 9.18097 5.00713 9.19013 5.0925C9.19928 5.17788 9.22924 5.25971 9.27738 5.33082C9.32551 5.40193 9.39035 5.46014 9.46622 5.50037C9.54208 5.54059 9.62666 5.56159 9.71252 5.56152H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.8125 13.6863V9.75C11.8125 9.4019 11.6742 9.06806 11.4281 8.82192C11.1819 8.57578 10.8481 8.4375 10.5 8.4375C10.1519 8.4375 9.81806 8.57578 9.57192 8.82192C9.32578 9.06806 9.1875 9.4019 9.1875 9.75V13.6875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.1875 11.9365H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
