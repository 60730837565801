import React, { SVGProps } from 'react';

export const ShortlistSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.469 6.81988L11.368 9.19405H13.118C13.2062 9.19059 13.2934 9.21446 13.3675 9.26242C13.4417 9.31037 13.4993 9.38006 13.5323 9.46196C13.5654 9.54385 13.5724 9.63396 13.5523 9.71997C13.5322 9.80598 13.486 9.88368 13.4201 9.94247L11.9035 11.0012L12.7446 12.9344C12.7814 13.0229 12.7894 13.1207 12.7677 13.214C12.746 13.3074 12.6956 13.3916 12.6236 13.4548C12.5516 13.518 12.4615 13.557 12.3662 13.5664C12.2708 13.5759 12.1749 13.5551 12.0919 13.5072L10.0566 12.3633L8.02254 13.5072C7.93955 13.5551 7.84363 13.5759 7.74826 13.5664C7.65289 13.557 7.56286 13.518 7.49083 13.4548C7.4188 13.3916 7.36839 13.3074 7.34668 13.214C7.32496 13.1207 7.33305 13.0229 7.36979 12.9344L8.21154 11.0012L6.69487 9.94247C6.62875 9.88392 6.58235 9.80637 6.56203 9.72042C6.54171 9.63448 6.54846 9.54436 6.58135 9.4624C6.61425 9.38044 6.67169 9.31066 6.74579 9.26262C6.8199 9.21458 6.90704 9.19063 6.99529 9.19405H8.74529L9.64595 6.81988C9.6858 6.74536 9.74512 6.68306 9.8176 6.63962C9.89008 6.59619 9.973 6.57324 10.0575 6.57324C10.142 6.57324 10.2249 6.59619 10.2974 6.63962C10.3699 6.68306 10.4292 6.74536 10.469 6.81988Z'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M3.0625 6.13135H7' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.0625 8.31885H4.375' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M3.0625 10.5063H4.375' stroke='#374151' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M5.25 13.5688H1.3125C1.08044 13.5688 0.857876 13.4767 0.693782 13.3126C0.529687 13.1485 0.4375 12.9259 0.4375 12.6938V3.50635C0.4375 3.27428 0.529687 3.05172 0.693782 2.88763C0.857876 2.72353 1.08044 2.63135 1.3125 2.63135H3.5C3.5 2.05119 3.73047 1.49479 4.1407 1.08455C4.55094 0.674316 5.10734 0.443848 5.6875 0.443848C6.26766 0.443848 6.82406 0.674316 7.2343 1.08455C7.64453 1.49479 7.875 2.05119 7.875 2.63135H10.0625C10.2946 2.63135 10.5171 2.72353 10.6812 2.88763C10.8453 3.05172 10.9375 3.27428 10.9375 3.50635V4.81885'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.6875 2.19385C5.73076 2.19385 5.77306 2.20668 5.80903 2.23071C5.845 2.25475 5.87304 2.28891 5.8896 2.32889C5.90616 2.36886 5.91049 2.41284 5.90205 2.45527C5.89361 2.49771 5.87277 2.53668 5.84218 2.56728C5.81159 2.59787 5.77261 2.6187 5.73018 2.62714C5.68774 2.63559 5.64376 2.63125 5.60379 2.6147C5.56382 2.59814 5.52965 2.5701 5.50562 2.53413C5.48158 2.49816 5.46875 2.45586 5.46875 2.4126C5.46875 2.35458 5.4918 2.29894 5.53282 2.25792C5.57384 2.21689 5.62948 2.19385 5.6875 2.19385'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />

    <defs>
      <clipPath id='clip0_1472_11858'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
