import React, { SVGProps } from 'react';

export const ScreenerResultsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1779_5057)'>
      <path
        d='M12.6875 4.05183V1.3125C12.6875 1.08044 12.5953 0.857876 12.4312 0.693782C12.2671 0.529687 12.0446 0.4375 11.8125 0.4375H1.3125C1.08044 0.4375 0.857876 0.529687 0.693782 0.693782C0.529687 0.857876 0.4375 1.08044 0.4375 1.3125V10.9375C0.4375 11.1696 0.529687 11.3921 0.693782 11.5562C0.857876 11.7203 1.08044 11.8125 1.3125 11.8125H4.03258'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 3.0625H12.6875'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.27083 10.9375H7.4375V13.5625L10.0625 10.9375H12.9792C13.1339 10.9375 13.2822 10.876 13.3916 10.7666C13.501 10.6572 13.5625 10.5089 13.5625 10.3542V6.27083C13.5625 6.11612 13.501 5.96775 13.3916 5.85835C13.2822 5.74896 13.1339 5.6875 12.9792 5.6875H6.27083C6.11612 5.6875 5.96775 5.74896 5.85835 5.85835C5.74896 5.96775 5.6875 6.11612 5.6875 6.27083V10.3542C5.6875 10.5089 5.74896 10.6572 5.85835 10.7666C5.96775 10.876 6.11612 10.9375 6.27083 10.9375Z'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.4375 7.4375H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.4375 9.1875H11.8125'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1779_5057'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
