import { Alert, AlertMessage, Button, SlideOut, Text } from 'components/common';
import { ConflictedSlot } from '@components/Public/Scheduler/pages/ConflictedSlot';
import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { addMinutesToTime } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/components/NylasCalendar/utils';
import { LOCALES } from '../constants';
import { getAlertProps } from '../utils/getAlertProps';
import { useGQCalendarContext } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/hooks/useCalendarContext';
import { useNavigate } from 'react-router-dom';

interface Props {
  onClose: () => void;
  conflict: Conflict;
  eventDuration: number | null;
}

export const TroubleshootSlideOut: React.FC<Props> = ({ conflict, eventDuration, onClose }) => {
  const { time, alerts, day, displayText, use24hr } = conflict;

  const navigate = useNavigate();

  const { setActivePanel, studyId, ...rest } = useGQCalendarContext();

  const { i18n } = useTranslation('Timeslots');

  const getDateLabel = (day: string) => {
    const date = new Date(day + 'T00:00:00');
    return format(date, 'PPPP', { locale: LOCALES[i18n.language] });
  };

  const formatTo12hr = (time: string) => {
    const [hours, minutes] = time.split(':');
    const ampm = parseInt(hours, 10) < 12 ? 'AM' : 'PM';
    const hrs = parseInt(hours, 10) % 12 || 12;
    return `${hrs}:${minutes} ${ampm}`;
  };

  const startTime = use24hr ? time : formatTo12hr(time);

  const endTime = addMinutesToTime(time, eventDuration || 0, use24hr);

  const subtitle = eventDuration ? `${startTime} to ${endTime}` : startTime;

  const onCtaClick = (alert: ConflictAlert) => {
    switch (alert) {
      case 'outside_scheduling_dates':
        setActivePanel?.('availability');
        break;
      case 'minimum_scheduling_notice':
      case 'maximum_scheduling_notice':
      case 'buffer':
      case 'daily_limit':
      case 'weekly_limit':
        setActivePanel?.('additional_options');
        break;
      case 'participants_limit':
        navigate(`/studies/${studyId}/plan`);
        break;
      case 'long_duration':
      default:
        break;
    }
    onClose();
  };

  return (
    <SlideOut title={getDateLabel(day)} subtitle={subtitle} onClose={onClose}>
      <div className='p-6'>
        <ConflictedSlot slotTime={displayText} disabled />
        <Text bold className='mt-6 mb-4'>
          Why is this time slot unavailable?
        </Text>
        {alerts.map((alert) => {
          const { title, subtitle, cta } = getAlertProps({ ...rest })[alert];
          
          if (alerts.includes('calendar') && alert === 'buffer') {
            return null;
          }

          return (
            <Alert key={alert} className='mb-4' type='warning' heading={title}>
              <AlertMessage>{subtitle}</AlertMessage>
              {cta && (
                <Button className='mt-2' onClick={() => onCtaClick(alert)} medium>
                  {cta}
                </Button>
              )}
            </Alert>
          );
        })}
      </div>
    </SlideOut>
  );
};
