import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Header, Panel, Section } from './components';
import { AccordionContext, Context } from './hooks/useAccordionContext';

export interface Props extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  isExpandable?: boolean;
  isCollapsible?: boolean;
  expandedSections?: number[];
  onChange?: (expandedSections: number[]) => void;
}

export const AccordionComponent = forwardRef<HTMLDivElement, Props>(
  (
    {
      isExpandable,
      isCollapsible = true,
      expandedSections: defaultExpandedSections,
      onChange,
      className,
      children,
      ...rest
    },
    ref
  ) => {
    const [expandedSections, setExpandedSections] = useState<number[]>([0]);
    const currentIndexRef = useRef(0);

    const expandSection = useCallback<Context['expandSection']>(
      (index) => {
        if (isCollapsible && !isExpandable) {
          setExpandedSections([index]);
          return;
        }

        if (expandedSections.indexOf(index) < 0) {
          setExpandedSections([...expandedSections, index]);
          return;
        }

        setExpandedSections([...expandedSections.filter((i) => i !== index)]);
      },
      [expandedSections, setExpandedSections]
    );

    const getHeaderProps = useCallback<Context['getHeaderProps']>(
      (index) => ({
        role: 'button',
        'aria-expanded': expandedSections.includes(index),
        'data-section-index': index
      }),
      [expandedSections]
    );

    const getPanelProps = useCallback<Context['getPanelProps']>(
      ({ index }) => {
        const isExpanded = expandedSections.includes(index);

        return {
          style: {
            maxHeight: isExpanded ? 'initial' : '0',
            ...(!isExpanded && { paddingBottom: 0 })
          },
          'aria-hidden': !isExpanded,
          'data-section-index': index
        };
      },
      [expandedSections]
    );

    const context = useMemo<Context>(
      () => ({
        currentIndexRef,
        isExpandable,
        isCollapsible,
        defaultExpandedSections,
        expandedSections,
        getPanelProps,
        getHeaderProps,
        expandSection
      }),
      [
        currentIndexRef,
        isExpandable,
        isCollapsible,
        defaultExpandedSections,
        expandedSections,
        getPanelProps,
        expandSection
      ]
    );

    useEffect(() => {
      if (defaultExpandedSections) {
        setExpandedSections(defaultExpandedSections);
      }
    }, [defaultExpandedSections]);

    useEffect(() => {
      onChange?.(expandedSections);
    }, [expandedSections]);

    return (
      <AccordionContext.Provider value={context}>
        <div ref={ref} className={cn('bg-white rounded border border-b-0 border-gray-200', className)} {...rest}>
          {children}
        </div>
      </AccordionContext.Provider>
    );
  }
);

export const Accordion = AccordionComponent as typeof AccordionComponent & {
  Section: typeof Section;
  Header: typeof Header;
  Panel: typeof Panel;
};

Accordion.Section = Section;
Accordion.Header = Header;
Accordion.Panel = Panel;
