import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props extends HTMLAttributes<HTMLDivElement> {
  href?: string;
  icon?: ReactNode;
  targetBlank?: boolean;
  closePopper?: MouseEventHandler<HTMLElement>;
  remove?: boolean;
}

export const ArtifactAction = forwardRef<HTMLDivElement, Props>(
  ({ className, children, href, icon, targetBlank, closePopper, remove, onClick, ...rest }, ref) => {
    const element = (
      <div
        className={cn(
          'hover:bg-gray-50 flex items-center px-4 py-2 text-sm cursor-pointer border-b border-gray-200 text-gray-900',
          { 'text-red-600 hover:text-red-700': remove, 'hover:text-indigo-600 text-gray-900': !remove },
          className
        )}
        ref={ref}
        onClick={
          onClick &&
          ((e) => {
            closePopper?.(e);
            onClick?.(e);
          })
        }
        {...rest}
      >
        {icon && <span className='mr-2'>{icon}</span>}
        {children}
      </div>
    );

    return href ? (
      <Link target={targetBlank ? '_blank' : undefined} to={href} onClick={closePopper}>
        {element}
      </Link>
    ) : (
      element
    );
  }
);
