import React, { SVGProps } from 'react';

export const CaretDownSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.5625 5.26562L8.30917 11.5184C8.26859 11.559 8.22039 11.5913 8.16734 11.6132C8.11429 11.6352 8.05743 11.6466 8 11.6466C7.94257 11.6466 7.8857 11.6352 7.83266 11.6132C7.77961 11.5913 7.73141 11.559 7.69083 11.5184L1.4375 5.26562'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
