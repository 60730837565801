import React, { SVGProps } from 'react';

export const MoneySVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.23024 6.46918C9.47352 6.55772 9.74249 6.43228 9.83106 6.18903C9.91962 5.94578 9.79418 5.67678 9.5509 5.58822C9.30762 5.49965 9.03865 5.62512 8.95009 5.86837C8.86156 6.11165 8.987 6.38065 9.23024 6.46918Z'
      fill='currentColor'
    />
    <path
      d='M4.26514 3.66438C4.02186 3.57585 3.75289 3.70125 3.66433 3.94454C3.57576 4.18782 3.7012 4.45682 3.94448 4.54535C4.18776 4.63388 4.45673 4.50844 4.54529 4.26519C4.63386 4.02191 4.50842 3.75291 4.26514 3.66438Z'
      fill='currentColor'
    />
    <path
      d='M13.2868 3.98057C11.7314 2.7462 9.78798 2.01174 7.72243 1.9553C5.93844 1.90655 4.16019 1.24824 2.71529 0.101589C2.59745 0.00805807 2.44195 -0.0231918 2.29717 0.0174643C2.15236 0.0581516 2.03589 0.165839 1.98398 0.306994L0.0288951 5.624C-0.0409171 5.81391 0.0189577 6.02716 0.177457 6.15294C1.75317 7.4034 3.74038 8.12265 5.77306 8.17821C5.82928 8.17974 5.8854 8.1819 5.9415 8.18456C4.78756 8.51734 3.6921 9.0703 2.71332 9.84705C2.55551 9.97227 2.49539 10.1843 2.56392 10.3737L4.48775 15.6907C4.5391 15.8326 4.6555 15.941 4.80063 15.9822C4.94516 16.0232 5.10134 15.9925 5.21994 15.8984C6.66481 14.7518 8.44308 14.0934 10.2271 14.0447C12.2598 13.9892 14.247 13.2699 15.8227 12.0194C15.9814 11.8934 16.0412 11.6797 15.9709 11.4896L14.0158 6.20388C13.9637 6.06297 13.8472 5.95556 13.7025 5.91507C13.5578 5.8746 13.4025 5.90594 13.2848 5.99931C13.1122 6.13631 12.9342 6.26609 12.7515 6.38869L13.436 4.50804C13.5049 4.31845 13.4448 4.10598 13.2868 3.98057ZM5.79862 7.24106C4.08097 7.19412 2.40026 6.62184 1.0283 5.62244L2.64801 1.21762C4.14932 2.25342 5.91934 2.84389 7.69683 2.89245C9.39527 2.93886 11.0655 3.51145 12.4369 4.51166L11.5017 7.08106C10.6268 7.47356 9.68608 7.71803 8.72852 7.79668C7.78624 7.45562 6.80118 7.26843 5.79862 7.24106ZM13.353 7.11425L14.9712 11.4893C13.5994 12.4883 11.9188 13.0606 10.2015 13.1075C8.42502 13.156 6.65609 13.7458 5.15534 14.7804L3.5626 10.3785C5.69997 8.8194 7.65133 8.81202 8.69433 8.73993C8.69836 8.73968 8.70239 8.73933 8.70642 8.73899C10.3812 8.62118 11.9855 8.05515 13.353 7.11425Z'
      fill='currentColor'
    />
    <path
      d='M7.2287 3.74525C6.49835 3.47944 5.6921 3.85525 5.42623 4.58575C5.16107 5.3144 5.5381 6.12293 6.26673 6.38812C6.99985 6.6549 7.80423 6.27543 8.06917 5.54765C8.33498 4.81734 7.9591 4.01112 7.2287 3.74525ZM7.18817 5.227C7.09976 5.46984 6.83035 5.59562 6.58735 5.50715C6.34451 5.41875 6.21879 5.14925 6.3072 4.90637C6.39563 4.66344 6.66513 4.53781 6.90804 4.62622C7.15145 4.71481 7.27682 4.98353 7.18817 5.227Z'
      fill='currentColor'
    />
    <path
      d='M6.4491 11.4547C6.20581 11.5432 6.08038 11.8122 6.16894 12.0555C6.2575 12.2988 6.52647 12.4242 6.76975 12.3356C7.01303 12.2471 7.13847 11.9781 7.04991 11.7348C6.96135 11.4916 6.69235 11.3661 6.4491 11.4547Z'
      fill='currentColor'
    />
    <path
      d='M12.0555 10.4118C12.2988 10.3233 12.4242 10.0543 12.3356 9.811C12.2471 9.56772 11.9781 9.44228 11.7348 9.53085C11.4916 9.61941 11.3661 9.88838 11.4547 10.1317C11.5432 10.3749 11.8122 10.5004 12.0555 10.4118Z'
      fill='currentColor'
    />
    <path
      d='M8.77131 9.61174C8.04097 9.87755 7.66497 10.6837 7.93081 11.4141C8.19666 12.1445 9.00278 12.5205 9.73322 12.2546C10.4618 11.9895 10.8389 11.1809 10.5737 10.4522C10.3085 9.72355 9.49988 9.34655 8.77131 9.61174ZM9.4126 11.3736C9.16981 11.4621 8.90022 11.3364 8.81181 11.0935C8.72316 10.8501 8.84844 10.5813 9.09197 10.4927C9.33481 10.4043 9.60435 10.53 9.69278 10.7728C9.78119 11.0157 9.65547 11.2852 9.4126 11.3736Z'
      fill='currentColor'
    />
  </svg>
);
