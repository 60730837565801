import { api } from 'api/reduxApi';
import { FilterSearch } from 'components/CandidatesApp/CandidatesIndex/FilterSearch';
import { useSearchWithinFilter } from 'components/CandidatesApp/CandidatesIndex/hooks';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { Spinner } from '@components/common';

import { DropdownProps } from '../../types';

type Props<D = Candidate> = DropdownProps<D, number[]>;

export const HasParticipatedInStudies: React.FC<Props> = ({ value, onChange }) => {
  type Option = { label: string; value: number };
  const [options, setOptions] = useState<Option[]>([]);

  const { data: fetchedStudies, isSuccess, isFetching } = api.useGetStudiesQuery();

  useEffect(() => {
    if (isSuccess) {
      setOptions(
        fetchedStudies.filter(Boolean).map((s) => ({
          value: s.id,
          label: s.title
        }))
      );
    }
  }, [isSuccess]);

  const { searchValue, setSearchValue, filteredOptions } = useSearchWithinFilter<Option>({
    options,
    getFilteredOptions: (option, search) =>
      option.label.toLocaleLowerCase().includes(search?.toLocaleLowerCase() || ''),
    sort: 'alpha'
  });

  const toggleFilter = (option: number) => {
    const newValue = value?.includes(option) ? value.filter((opt) => opt !== option) : (value || []).concat(option);
    onChange({ value: newValue });
  };

  if (isFetching) {
    return (
      <div className='flex items-center justify-center h-40'>
        <Spinner className='w-5 h-5' />
      </div>
    );
  }

  return (
    <div>
      <FilterSearch value={searchValue} setValue={setSearchValue} />
      <div className='space-y-1.5 max-h-32 overflow-auto py-1'>
        {filteredOptions.map((option, i) => (
          <label
            key={'filterOption' + `${option}${i}`}
            className='max-w-md hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900 flex items-center text-sm leading-5 text-gray-700 normal-case'
          >
            <input
              type='checkbox'
              className='form-checkbox ml-1 mr-2'
              onChange={() => toggleFilter(option.value)}
              value={option.label}
              checked={value?.includes(option.value) || false}
            />
            {option ? (
              <span className='truncate'>{option.label}</span>
            ) : (
              <span className='italic text-gray-500 text-ellipsis'>Untitled study</span>
            )}
          </label>
        ))}
      </div>
    </div>
  );
};
