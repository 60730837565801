import React, { SVGProps } from 'react';

export const UploadZoomSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.70833 14.4167C11.4132 14.4167 14.4167 11.4132 14.4167 7.70833C14.4167 4.00342 11.4132 1 7.70833 1C4.00342 1 1 4.00342 1 7.70833C1 11.4132 4.00342 14.4167 7.70833 14.4167Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.33398 5.375H8.00065C8.31007 5.375 8.60682 5.49792 8.82561 5.71671C9.0444 5.9355 9.16732 6.23225 9.16732 6.54167V10.0417H4.50065C4.19123 10.0417 3.89449 9.91875 3.67569 9.69996C3.4569 9.48117 3.33398 9.18442 3.33398 8.875V5.375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.334 6.83333V8.58333L12.3757 10.0417V5.375L10.334 6.83333Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
