import { CopyURLButton } from 'components/shared/CopyURLButton';
import React from 'react';

import { FormGroup, Modal, Select, SelectOption, Text } from '@components/common';
import { ExternalLinkSVG } from '@components/svgs';

interface Props {
  onClose: () => void;
  options: SelectOption[];
  panelStudies: Study[];
}

export const PanelStudyModal: React.FC<Props> = ({ onClose, options, panelStudies }) => {
  const [publickPath, setPublickPath] = React.useState<string | undefined>(panelStudies?.[0]?.public_path);

  const onChange = (publickPath: string) => setPublickPath(publickPath);

  const copyHref = window.location.protocol + '//' + window.location.host + '/' + publickPath;

  return (
    <Modal
      size='md'
      renderFooter={() => (
        <CopyURLButton
          primary
          noTippy
          text={publickPath ? copyHref : ''}
          trackEvent='copied_panel_invite_link'
          trackProps={{ page: 'candidates' }}
        >
          Copy link
        </CopyURLButton>
      )}
      title='Invite via link'
      subtitle='Select which panel study to invite them to.'
      onClose={onClose}
    >
      <FormGroup>
        <Text as='label' bold>
          Select study
        </Text>

        <span className='h400 block mt-1 mb-3 text-gray-500'>We'll provide the invite link to share.</span>
        <div className='flex flex-col'>
          <Select
            overflowClass='visible'
            options={options}
            onChange={onChange}
            value={publickPath || ''}
            renderLabel={(option) => <div>{option.label}</div>}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <Text as='label' bold>
          Invite link
        </Text>
        <span className='h400 block my-1 text-gray-500'>Copy the link to share with invitees.</span>
        <div className='relative mb-4'>
          <input
            type='text'
            disabled
            className='bg-gray-50 w-full px-4 py-2 text-gray-700 border border-gray-200 rounded-md'
            value={publickPath ? copyHref : undefined}
          />
          <a
            target='_blank'
            className='bg-gray-50 absolute top-0 bottom-0 right-0 flex items-center pl-2 mt-1 mb-1 mr-4 cursor-pointer'
            href={copyHref}
          >
            <ExternalLinkSVG />
          </a>
        </div>
      </FormGroup>
    </Modal>
  );
};
