import React, { SVGProps } from 'react';

export const AddPeopleSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg fill='none' height='14' viewBox='0 0 14 14' width='14' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='.875'>
      <path d='m6.5625 10.0625c0 .9283.36875 1.8185 1.02513 2.4749.65637.6564 1.54661 1.0251 2.47487 1.0251.9283 0 1.8185-.3687 2.4749-1.0251s1.0251-1.5466 1.0251-2.4749c0-.92826-.3687-1.8185-1.0251-2.47487-.6564-.65638-1.5466-1.02513-2.4749-1.02513-.92826 0-1.8185.36875-2.47487 1.02513-.65638.65637-1.02513 1.54661-1.02513 2.47487z' />
      <path d='m10.0625 8.3125v3.5' />
      <path d='m8.3125 10.0625h3.5' />
      <path d='m.4375 10.0625c.000443-.6509.162192-1.29159.470784-1.86474.308596-.57315.754406-1.0609 1.297586-1.41962.54318-.35873 1.16679-.57727 1.81507-.63606.64829-.0588 1.30104.04398 1.89989.29913' />
      <path d='m1.96875 2.84375c0 .63818.25351 1.25022.70477 1.70148.45126.45125 1.0633.70477 1.70148.70477s1.25022-.25352 1.70148-.70477c.45126-.45126.70477-1.0633.70477-1.70148s-.25351-1.25022-.70477-1.70148c-.45126-.451255-1.0633-.70477-1.70148-.70477s-1.25022.253515-1.70148.70477c-.45126.45126-.70477 1.0633-.70477 1.70148z' />
    </g>
  </svg>
);
