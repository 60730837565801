import * as React from 'react';

export const candidates: React.FC = () => (
  <svg
    className='group-hover:text-gray-300 w-4 h-4 mr-3 text-white'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5 2.67585C10.5095 1.87447 9.27406 1.43726 8 1.43726C6.72594 1.43726 5.49046 1.87447 4.5 2.67585'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.95831 4.78451C6.53616 4.31719 7.25682 4.06226 7.99998 4.06226C8.74314 4.06226 9.4638 4.31719 10.0416 4.78451'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M1.4375 9.09375C1.4375 9.49986 1.59883 9.88934 1.88599 10.1765C2.17316 10.4637 2.56264 10.625 2.96875 10.625C3.37486 10.625 3.76434 10.4637 4.05151 10.1765C4.33867 9.88934 4.5 9.49986 4.5 9.09375C4.5 8.68764 4.33867 8.29816 4.05151 8.01099C3.76434 7.72383 3.37486 7.5625 2.96875 7.5625C2.56264 7.5625 2.17316 7.72383 1.88599 8.01099C1.59883 8.29816 1.4375 8.68764 1.4375 9.09375V9.09375Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M5.38317 12.6566C5.22847 12.2952 4.99457 11.9731 4.69872 11.7141C4.40288 11.4552 4.05265 11.2661 3.6739 11.1606C3.29515 11.0552 2.89755 11.0362 2.51047 11.105C2.12339 11.1738 1.75671 11.3287 1.4375 11.5582'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M11.5 9.09375C11.5 9.29484 11.5396 9.49395 11.6166 9.67973C11.6935 9.86551 11.8063 10.0343 11.9485 10.1765C12.0907 10.3187 12.2595 10.4315 12.4453 10.5084C12.631 10.5854 12.8302 10.625 13.0312 10.625C13.2323 10.625 13.4315 10.5854 13.6172 10.5084C13.803 10.4315 13.9718 10.3187 14.114 10.1765C14.2562 10.0343 14.369 9.86551 14.4459 9.67973C14.5229 9.49395 14.5625 9.29484 14.5625 9.09375C14.5625 8.89266 14.5229 8.69355 14.4459 8.50777C14.369 8.32199 14.2562 8.15318 14.114 8.01099C13.9718 7.8688 13.803 7.75601 13.6172 7.67906C13.4315 7.60211 13.2323 7.5625 13.0312 7.5625C12.8302 7.5625 12.631 7.60211 12.4453 7.67906C12.2595 7.75601 12.0907 7.8688 11.9485 8.01099C11.8063 8.15318 11.6935 8.32199 11.6166 8.50777C11.5396 8.69355 11.5 8.89266 11.5 9.09375V9.09375Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M10.6168 12.6566C10.7715 12.2952 11.0054 11.9731 11.3013 11.7141C11.5971 11.4552 11.9473 11.2661 12.3261 11.1606C12.7048 11.0552 13.1024 11.0362 13.4895 11.105C13.8766 11.1738 14.2433 11.3287 14.5625 11.5582'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M6.03125 8.65625C6.03125 9.17839 6.23867 9.67915 6.60788 10.0484C6.9771 10.4176 7.47785 10.625 8 10.625C8.52214 10.625 9.0229 10.4176 9.39212 10.0484C9.76133 9.67915 9.96875 9.17839 9.96875 8.65625C9.96875 8.1341 9.76133 7.63335 9.39212 7.26413C9.0229 6.89492 8.52214 6.6875 8 6.6875C7.47785 6.6875 6.9771 6.89492 6.60788 7.26413C6.23867 7.63335 6.03125 8.1341 6.03125 8.65625V8.65625Z'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path
      d='M11.5 14.5623C11.386 13.7149 10.9685 12.9375 10.3248 12.3746C9.68114 11.8117 8.85509 11.5015 8 11.5015C7.14491 11.5015 6.31886 11.8117 5.67519 12.3746C5.03152 12.9375 4.61395 13.7149 4.5 14.5623'
      stroke='white'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
  </svg>
);
