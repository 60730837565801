import cn from 'classnames';
import * as React from 'react';

import { Hook as UseMultiselectTags } from '../../useMultiselectTags';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  isOpen: boolean;
  readOnly?: boolean;
  className?: string;
  tabIndex?: number;
  multiselectTags: UseMultiselectTags;
}
export const TagListInput: React.FC<Props> = ({
  inputRef,
  isOpen,
  readOnly,
  tabIndex,
  className,
  multiselectTags: { getDropdownProps, getInputProps, setInputValue, openMenu }
}) => (
  <input
    className={cn('focus:outline-none input-styled-placeholder min-w-0 text-sm bg-transparent', className)}
    autoFocus={isOpen}
    {...getInputProps(
      {
        ...getDropdownProps({ preventKeyAction: isOpen }, { suppressRefError: true }),
        disabled: readOnly,
        tabIndex,
        onFocus: () => {
          if (!isOpen && !readOnly) openMenu();
        },
        onChange: (e) => {
          setInputValue(e.currentTarget.value);
        }
      },
      { suppressRefError: true }
    )}
    placeholder='Find or create a tag'
    ref={inputRef}
  />
);
