import { useEffect, useRef } from 'react';

const focusableElements = 'button:not(:disabled), [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const useTrapFocus = () => {
  const elementRef = useRef<HTMLDivElement>(null);

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (!elementRef.current) return;

    const focusableChildren = Array.from(
      elementRef.current.querySelectorAll(focusableElements) as NodeListOf<HTMLElement>
    );
    const firstChild = focusableChildren[0];
    const lastChild = focusableChildren[focusableChildren.length - 1];

    const isTabPressed = event.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    if (event.shiftKey) {
      if (document.activeElement === firstChild) {
        lastChild.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === lastChild) {
        firstChild.focus();
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
    };
  }, [elementRef.current]);

  return {
    ref: elementRef
  };
};
