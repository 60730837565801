import React, { FC, HTMLAttributes, ReactElement, useEffect, useState } from 'react';

import { Popper, Props as PopperProps } from '../Popper';
import { MenuContext } from './hooks/useMenuContext';

interface RenderTrigger {
  isOpen: boolean;
}

export interface Props extends HTMLAttributes<HTMLUListElement> {
  renderTrigger: (params: RenderTrigger) => ReactElement;
  onItemSelect?: (item: string | undefined) => void;
  popperProps?: Partial<Omit<PopperProps, 'content'>>;
}

export const Menu: FC<Props> = ({ renderTrigger, onItemSelect, popperProps, children, ...rest }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>();

  useEffect(() => {
    if (selectedItem) {
      onItemSelect?.(selectedItem);
      setSelectedItem(undefined);
    }
  }, [selectedItem]);

  return (
    <Popper
      placement='bottom-end'
      offset={[0, 8]}
      content={({ closePopper }) => (
        <MenuContext.Provider value={{ closeMenu: closePopper, setSelectedItem, selectedItem }}>
          <ul {...rest}>{typeof children === 'function' ? children({ closePopper }) : children}</ul>
        </MenuContext.Provider>
      )}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      closeOnEscape
      closeOnClickOutside
      {...popperProps}
    >
      {renderTrigger({ isOpen })}
    </Popper>
  );
};
