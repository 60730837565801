export const PATHS: Record<TemplateKind, string> = {
  EmailTemplate: '/study_templates/emails',
  InterviewTemplate: '/study_templates/interviews',
  SurveyTemplate: '/study_templates/surveys'
};
export const DEFAULT_TITLES: Record<TemplateKind, string> = {
  EmailTemplate: 'Untitled email template',
  InterviewTemplate: 'Untitled interview template',
  SurveyTemplate: 'Untitled survey template'
};
export const UPDATE_PERMISSIONS: Record<TemplateKind, Permission> = {
  EmailTemplate: 'updateInterviewTemplate',
  InterviewTemplate: 'updateInterviewTemplate',
  SurveyTemplate: 'updateInterviewTemplate'
};
export const RESOURCE_NAME: Record<TemplateKind, string> = {
  EmailTemplate: 'email template',
  InterviewTemplate: 'interview guide template',
  SurveyTemplate: 'survey template'
};
export const H1: Record<TemplateKind, string> = {
  EmailTemplate: 'Email templates',
  InterviewTemplate: 'Interview guide templates',
  SurveyTemplate: 'Survey templates'
};
export const TIPS_N_TRICKS: Record<TemplateKind, string> = {
  EmailTemplate: 'Write your copy ahead of time and re-use it for any study you’re working on.',
  InterviewTemplate:
    'Set up discussion guides, note-taking templates, or other structures to make it easier to conduct and synthesize the research.',
  SurveyTemplate: 'Use a Great Question template to start your next survey, or create your own for the team to use'
};
export const EMAIL_TEMPLATE_PURPOSES: Record<EmailTemplate['event'], string> = {
  screener: 'Screener invitation',
  invite: 'Participation invitation',
  invited_reminder: 'Participation invitation reminder',
  booked: 'Interview booking confirmation',
  welcome: 'Panel welcome',
  booked_reminder: 'Interview reminder',
  reschedule_request: 'Reschedule request',
  cancel_interview: 'Interview cancellation notice',
  cancel_task: 'Task cancellation notice',
  started_reminder: 'Finish task reminder',
  thanks: 'Thanks for completion',
  ad_hoc: 'Ad hoc message'
};

export const EMAIL_TEMPLATE_PURPOSE_DESCRIPTIONS: Record<EmailTemplate['event'], string> = {
  screener: 'When you send a screener to a candidate.',
  invite: 'Invite to participate in calls, surveys, or unmoderated tests.',
  invited_reminder: 'Candidate received an invite, but didn’t respond.',
  booked: 'Confirmation of an interview booking.',
  booked_reminder: 'Candidate has a scheduled interview.',
  reschedule_request: 'When you want to reschedule an interview.',
  cancel_interview: 'When you’ve canceled an upcoming interview.',
  cancel_task: 'When you’ve canceled a task or survey.',
  started_reminder: 'Remind a candidate to finish a started task.',
  welcome: 'When a participant joins a panel.',
  thanks: 'After a participant completes a study.',
  ad_hoc: 'When you want to send an ad hoc message to a participant.'
};

export const RESOURCE_LABEL: Record<TemplateKind, string> = {
  EmailTemplate: 'Email',
  InterviewTemplate: 'Interview',
  SurveyTemplate: 'Survey'
};
