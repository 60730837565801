import React, { SVGProps } from 'react';

export const VideoCallIconSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8 1.5C7.95055 1.5 7.90222 1.51466 7.86111 1.54213C7.82 1.5696 7.78795 1.60865 7.76903 1.65433C7.75011 1.70001 7.74516 1.75028 7.7548 1.79877C7.76445 1.84727 7.78826 1.89181 7.82322 1.92678C7.85819 1.96174 7.90273 1.98555 7.95123 1.9952C7.99972 2.00484 8.04999 1.99989 8.09567 1.98097C8.14135 1.96205 8.1804 1.93 8.20787 1.88889C8.23534 1.84778 8.25 1.79945 8.25 1.75C8.25 1.6837 8.22366 1.62011 8.17678 1.57322C8.12989 1.52634 8.0663 1.5 8 1.5Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.5 6.5C6.5 6.89782 6.65804 7.27936 6.93934 7.56066C7.22064 7.84196 7.60218 8 8 8C8.39782 8 8.77936 7.84196 9.06066 7.56066C9.34196 7.27936 9.5 6.89782 9.5 6.5C9.5 6.10218 9.34196 5.72064 9.06066 5.43934C8.77936 5.15804 8.39782 5 8 5C7.60218 5 7.22064 5.15804 6.93934 5.43934C6.65804 5.72064 6.5 6.10218 6.5 6.5V6.5Z'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5 10C10.2583 9.54689 9.89808 9.16798 9.45774 8.90377C9.01739 8.63956 8.51352 8.5 8 8.5C7.48648 8.5 6.98261 8.63956 6.54226 8.90377C6.10192 9.16798 5.74166 9.54689 5.5 10'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.5 2H14.5C14.7652 2 15.0196 2.10536 15.2071 2.29289C15.3946 2.48043 15.5 2.73478 15.5 3V11C15.5 11.2652 15.3946 11.5196 15.2071 11.7071C15.0196 11.8946 14.7652 12 14.5 12H1.5C1.23478 12 0.98043 11.8946 0.792893 11.7071C0.605357 11.5196 0.5 11.2652 0.5 11V3C0.5 2.73478 0.605357 2.48043 0.792893 2.29289C0.98043 2.10536 1.23478 2 1.5 2H5.5'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M10.5 15H5.5L6 12H10L10.5 15Z' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M4 15H12' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
