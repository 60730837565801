import fscreen from 'fscreen';
import { MutableRefObject, Ref, useEffect, useImperativeHandle, useReducer, useRef } from 'react';

import type { Reducer } from 'react';
import type { GenericAction, UseVideoPlayer, UseVideoPlayerState as State, UseVideoPlayerArgs as Args } from '../types';
import { useLocalStorage } from '@hooks/useLocalStorage';

enum Actions {
  SET_IS_READY = 'SET_IS_READY',
  SET_IS_PLAYING = 'SET_IS_PLAYING',
  SET_SOURCE = 'SET_SOURCE',
  SET_DURATION = 'SET_DURATION',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_VOLUME = 'SET_VOLUME',
  SET_FULLSCREEN = 'SET_FULLSCREEN',
  SET_BUFFER = 'SET_BUFFER',
  SET_PLAYBACK_SPEED = 'SET_PLAYBACK_SPEED',
  SET_IS_BUFFERING = 'SET_IS_BUFFERING'
}

type Action =
  | GenericAction<Actions.SET_IS_READY, boolean>
  | GenericAction<Actions.SET_IS_PLAYING, boolean>
  | GenericAction<Actions.SET_SOURCE, string>
  | GenericAction<Actions.SET_DURATION, number>
  | GenericAction<Actions.SET_CURRENT_TIME, number>
  | GenericAction<Actions.SET_VOLUME, number>
  | GenericAction<Actions.SET_FULLSCREEN, boolean>
  | GenericAction<Actions.SET_BUFFER, number>
  | GenericAction<Actions.SET_PLAYBACK_SPEED, number>
  | GenericAction<Actions.SET_IS_BUFFERING, boolean>;

export const INITIAL_STATE: State = {
  source: null,
  isPlaying: false,
  isReady: false,
  duration: 0,
  currentTime: 0,
  volume: 1,
  isFullscreen: false,
  buffer: 0,
  playbackSpeed: 1,
  isBuffering: false
};

const reducer = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case Actions.SET_IS_READY:
      return { ...state, isReady: action.payload };
    case Actions.SET_IS_PLAYING:
      return { ...state, isPlaying: action.payload };
    case Actions.SET_SOURCE:
      return { ...state, source: action.payload };
    case Actions.SET_DURATION:
      return { ...state, duration: action.payload };
    case Actions.SET_CURRENT_TIME:
      return { ...state, currentTime: action.payload };
    case Actions.SET_VOLUME:
      return { ...state, volume: action.payload };
    case Actions.SET_FULLSCREEN:
      return { ...state, isFullscreen: action.payload };
    case Actions.SET_BUFFER:
      return { ...state, buffer: action.payload };
    case Actions.SET_PLAYBACK_SPEED:
      return { ...state, playbackSpeed: action.payload };
    case Actions.SET_IS_BUFFERING:
      return { ...state, isBuffering: action.payload };
    default:
      return state;
  }
};

export const useVideoPlayer = ({
  showNativeControls,
  clip,
  autoPlay,
  videoRef: initialVideoRef,
  currentTime: initialCurrentTime
}: Args): UseVideoPlayer => {
  const videoRef = useRef<HTMLVideoElement>();

  const [defaultPlaybackSpeed, setDefaultPlaybackSpeed] = useLocalStorage('gq-default-playback-speed');

  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, {
    ...INITIAL_STATE,
    playbackSpeed: defaultPlaybackSpeed || 1,
    isPlaying: !!autoPlay
  });
  const [from, to] = clip ?? [];

  const isClip = from && to ? from >= 0 && to > 0 : false;

  const play = async () => {
    if (videoRef.current) {
      await videoRef.current?.play();

      dispatch({ type: Actions.SET_IS_PLAYING, payload: true });

      if (isClip && to && from && videoRef.current.currentTime >= to) {
        videoRef.current.currentTime = from;
        videoRef.current.play();
      }
    }
  };

  const pause = () => {
    if (videoRef.current) {
      if (state.isPlaying) {
        videoRef.current.pause();

        dispatch({ type: Actions.SET_IS_PLAYING, payload: false });
      }
    }
  };

  const setPlaybackSpeed = (rate: number) => {
    if (videoRef.current) {
      const speed = Number(rate) || 1;

      videoRef.current.playbackRate = speed;
      dispatch({ type: Actions.SET_PLAYBACK_SPEED, payload: speed });
      setDefaultPlaybackSpeed(speed);
    }
  };

  const setCurrentTime = (seconds: number) => {
    if (isNaN(seconds) || !videoRef.current) return;

    videoRef.current.currentTime = (from ?? 0) + seconds;

    dispatch({ type: Actions.SET_CURRENT_TIME, payload: seconds });
  };

  const setVolume = (volume: number) => {
    if (videoRef.current) {
      videoRef.current.volume = volume;

      dispatch({ type: Actions.SET_VOLUME, payload: volume });
    }
  };

  const rewind = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime -= seconds;
    }
  };

  const fastForward = (seconds: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime += seconds;
    }
  };

  const enterFullscreen = (element?: HTMLDivElement) => {
    if (videoRef.current) {
      fscreen.requestFullscreen(element ?? videoRef.current);

      dispatch({ type: Actions.SET_FULLSCREEN, payload: true });
    }
  };

  const exitFullscreen = () => {
    if (fscreen.fullscreenElement) {
      fscreen.exitFullscreen();
    }

    dispatch({ type: Actions.SET_FULLSCREEN, payload: false });
  };

  const handleOnTimeUpdate = () => {
    if (videoRef.current && videoRef.current.buffered.length > 0) {
      dispatch({
        type: Actions.SET_CURRENT_TIME,
        payload: videoRef.current.currentTime - (from ?? 0)
      });

      dispatch({
        type: Actions.SET_BUFFER,
        payload: videoRef.current.buffered.end(videoRef.current.buffered.length - 1)
      });

      if (isClip && to && videoRef.current.currentTime >= to) pause();
    }
  };

  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/duration#value
  const handleDurationChange = () => {
    if (videoRef.current) {
      dispatch({
        type: Actions.SET_DURATION,
        payload: videoRef.current.duration
      });
    }
  };

  const handleWaitingEvent = () => {
    dispatch({ type: Actions.SET_IS_BUFFERING, payload: true });
  };

  const handlePlayingEvent = () => {
    dispatch({ type: Actions.SET_IS_BUFFERING, payload: false });
  };

  const handleVideoEnded = () => {
    dispatch({ type: Actions.SET_IS_PLAYING, payload: false });
  };

  const getVideoProps = () => ({
    ref: (player: HTMLVideoElement) => {
      videoRef.current = player;
      if (!state.isReady) dispatch({ type: Actions.SET_IS_READY, payload: true });
    },
    controls: showNativeControls,
    onTimeUpdate: handleOnTimeUpdate,
    autoPlay,
    onPlayCapture: () => {
      if (videoRef.current) {
        videoRef.current.playbackRate = state.playbackSpeed || 1;
      }
    }
  });

  useEffect(() => {
    if (initialCurrentTime && initialCurrentTime > 0 && videoRef.current) {
      dispatch({ type: Actions.SET_CURRENT_TIME, payload: initialCurrentTime });

      videoRef.current.currentTime = initialCurrentTime;
    }
  }, [initialCurrentTime]);

  useEffect(() => {
    if (isClip && videoRef.current && from) {
      videoRef.current.currentTime = from;
    }
  }, [from, to]);

  useEffect(() => {
    if (state.isReady && videoRef.current) {
      if (isClip && to && from) {
        dispatch({
          type: Actions.SET_DURATION,
          payload: to - from
        });
      } else {
        videoRef.current.addEventListener('durationchange', handleDurationChange);
        videoRef.current.addEventListener('waiting', handleWaitingEvent);
        videoRef.current.addEventListener('playing', handlePlayingEvent);
        videoRef.current.addEventListener('ended', handleVideoEnded);
      }
    }

    return () => {
      if (state.isReady && videoRef.current) {
        videoRef.current.removeEventListener('durationchange', handleDurationChange);
        videoRef.current.removeEventListener('waiting', handleWaitingEvent);
        videoRef.current.removeEventListener('playing', handlePlayingEvent);
        videoRef.current.removeEventListener('ended', handleVideoEnded);
      }
    };
  }, [state.isReady, to, from]);

  const result: UseVideoPlayer = {
    getVideoProps,
    play,
    pause,
    setPlaybackSpeed,
    setCurrentTime,
    setVolume,
    rewind,
    fastForward,
    enterFullscreen,
    exitFullscreen,
    player: videoRef.current,
    showControls: !showNativeControls,
    state
  };

  useImperativeHandle(initialVideoRef, () => result);

  return result;
};
