import * as React from 'react';

import { api } from '@api/reduxApi';
import { ZoomRecordingsModal } from '@components/RepoSessionApp/RecordingWidget/ZoomRecordingsModal';
import { uid } from '@components/utils';

import { useBulkUploadActions, useBulkUploadState } from '../';

export const BulkZoomImportModal: React.FC = () => {
  const { closeModal, submitModal } = useBulkUploadActions();
  const { studyId } = useBulkUploadState();

  const [createRepoSession] = api.useCreateRepoSessionMutation();

  async function createItem(cloudRecording: ZoomRecording): Promise<UploadManagerItem> {
    return createRepoSession({ title: cloudRecording.topic, kind: 'recording', project_id: studyId })
      .unwrap()
      .then((session) => ({
        session,
        cloudRecording,
        id: uid(),
        title: cloudRecording.topic,
        status: 'queued',
        type: 'zoom',
        tagIds: []
      }));
  }

  async function createItems(cloudRecordings: ZoomRecording[]): Promise<UploadManagerItem[]> {
    return Promise.all(cloudRecordings.map(createItem));
  }

  return (
    <ZoomRecordingsModal
      allowMultiple
      open
      onClose={closeModal}
      onSelect={async (recordings) => {
        submitModal(await createItems(recordings));
      }}
    />
  );
};
