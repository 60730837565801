import { useCollectionView } from 'components/stores/view';
import * as React from 'react';

import { PageTitle } from '@components/common/helmets';
import { DashboardLayout, DashboardLayoutBody } from '@components/layouts/DashboardLayout';
import { Basic, Grid } from '@components/shared/Skeleton';
import { TableSkeleton } from 'components/shared/GridTable/components';

export const TableBodySkeleton = ({ layout }) => (
  <>
    {(!layout || layout === 'grid') && <Grid />}
    {layout === 'row' && <Grid height={170} mobile={1} tablet={1} desktop={1} monitor={1} />}
    {layout === 'list' && <TableSkeleton data-testid='studies-table-skeleton' className='w-full min-h-screen' />}
  </>
);

export const Skeleton: React.FC = () => {
  const {
    view: { layout }
  } = useCollectionView();

  return (
    <DashboardLayout>
      <PageTitle>Studies</PageTitle>
      <div className='px-page desktop:flex-row flex flex-col items-center py-4 border-b border-gray-200'>
        <h1 className='h700 desktop:mb-0 mb-2 font-bold'>Studies</h1>
        <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-3 flex flex-col items-center justify-end flex-1 space-y-2'>
          <Basic h='8' width={90} />
          <Basic h='8' width={95} />
          <Basic h='8' width={90} />
          <Basic h='8' width={100} />
        </div>
      </div>

      <DashboardLayoutBody className='h-full'>
        <Basic h='6' width={90} mb='4' />
        <TableBodySkeleton layout={layout} />
      </DashboardLayoutBody>
    </DashboardLayout>
  );
};
