import React, { SVGProps } from 'react';

export const FullStarSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M8.42526 1.69996L10.3771 5.56688L14.1338 5.93904C14.2232 5.94647 14.3086 5.97938 14.3799 6.03385C14.4512 6.08833 14.5055 6.1621 14.5361 6.24643C14.5668 6.33076 14.5727 6.42212 14.5531 6.50969C14.5335 6.59726 14.4892 6.67737 14.4254 6.74054L11.3338 9.80479L12.48 13.9686C12.5035 14.0569 12.5009 14.1502 12.4726 14.2371C12.4444 14.3239 12.3916 14.4008 12.3207 14.4584C12.2497 14.5161 12.1637 14.552 12.0728 14.5618C11.982 14.5717 11.8902 14.5551 11.8086 14.514L8.00001 12.6281L4.19667 14.5117C4.11503 14.5528 4.02327 14.5694 3.93243 14.5595C3.84158 14.5496 3.75552 14.5137 3.6846 14.4561C3.61367 14.3985 3.5609 14.3216 3.53263 14.2347C3.50436 14.1478 3.5018 14.0546 3.52526 13.9663L4.67151 9.80246L1.57751 6.73821C1.51377 6.67504 1.46946 6.59493 1.44984 6.50736C1.43022 6.41979 1.4361 6.32843 1.46679 6.2441C1.49747 6.15977 1.55168 6.086 1.623 6.03152C1.69431 5.97704 1.77974 5.94414 1.86917 5.93671L5.62584 5.56454L7.57476 1.69996C7.61484 1.62168 7.67575 1.55598 7.75078 1.5101C7.82582 1.46422 7.91206 1.43994 8.00001 1.43994C8.08795 1.43994 8.17419 1.46422 8.24923 1.5101C8.32426 1.55598 8.38517 1.62168 8.42526 1.69996Z'
      fill='#5850EC'
      stroke='#5850EC'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
