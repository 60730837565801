import React from 'react';
import { Text } from '@components/common';
import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';

interface Props {
  slotTime: string;
  onClick?: () => void;
  disabled?: boolean;
}

type ConflictedSlotProps = RequireOnlyOne<Props, 'onClick' | 'disabled'>;

const SlotComponent = createPolymorphicComponent(({ as: Component = 'div', innerRef, children, ...rest }) => (
  <Component
    ref={innerRef}
    className='w-full flex flex-col items-center justify-center space-y-2.5 rounded-md border border-yellow-600 bg-yellow-50 py-3'
    {...rest}
  >
    {children}
  </Component>
));

export const ConflictedSlot: React.FC<ConflictedSlotProps> = ({ slotTime, disabled, onClick }) => {
  return (
    <SlotComponent as={disabled ? 'div' : 'button'} onClick={onClick}>
      <Text className='leading-none'>{slotTime}</Text>
      <Text h='400' className='leading-none' bold>
        Unavailable
      </Text>
    </SlotComponent>
  );
};
