import { CSSProperties } from 'react';

import * as Types from '../types';

export const parseImageFragment = (storyBoardCueText: string): Types.ImageFragment | undefined => {
  try {
    const imageUrl = storyBoardCueText.split('#')[0];
    const [x, y, width, height] = storyBoardCueText
      .split('#')[1]
      .split('=')[1]
      .split(',')
      .map((n) => +n);

    return {
      x,
      y,
      width,
      height,
      imageUrl
    };
  } catch (error) {
    console.error('Error parsing image fragment', error);
  }
};

export const parseImageFragmentStyle = (imageFragment: Types.ImageFragment): CSSProperties => ({
  backgroundImage: `url(${imageFragment.imageUrl})`,
  backgroundPosition: `-${imageFragment.x}px -${imageFragment.y}px`,
  width: imageFragment.width,
  height: imageFragment.height
});
