import React, { SVGProps } from 'react';

export const PlayerSpeaker = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.21375 12.2501H3.8125C3.56386 12.2501 3.3254 12.3489 3.14959 12.5247C2.97377 12.7005 2.875 12.9389 2.875 13.1876V18.8126C2.875 19.0612 2.97377 19.2997 3.14959 19.4755C3.3254 19.6513 3.56386 19.7501 3.8125 19.7501H9.21375C9.90445 21.5186 11.0698 23.0623 12.5814 24.2111C14.093 25.3599 15.8923 26.0694 17.7813 26.2613C17.9116 26.2742 18.0431 26.2597 18.1675 26.2188C18.2918 26.1778 18.4063 26.1113 18.5034 26.0234C18.6005 25.9356 18.6782 25.8285 18.7314 25.7088C18.7846 25.5892 18.8123 25.4598 18.8125 25.3288V6.67133C18.8125 6.54034 18.785 6.4108 18.7318 6.29108C18.6786 6.17137 18.6009 6.06412 18.5038 5.97626C18.4066 5.8884 18.2921 5.82188 18.1677 5.78099C18.0432 5.74009 17.9116 5.72573 17.7813 5.73883C15.8923 5.93078 14.093 6.64022 12.5814 7.78904C11.0698 8.93787 9.90445 10.4815 9.21375 12.2501V12.2501Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M27.8287 20.4375C28.6752 19.1122 29.125 17.5725 29.125 16C29.125 14.4275 28.6752 12.8878 27.8287 11.5625'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M24.2025 18.8013C24.8336 18.0237 25.1781 17.0527 25.1781 16.0513C25.1781 15.0498 24.8336 14.0788 24.2025 13.3013'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
