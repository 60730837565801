import React, { createContext, PropsWithChildren, useContext } from 'react';

import { Context as ITableContext } from '../types';

const TableContext = createContext<ITableContext<any> | undefined>(undefined);

export const Provider = <D extends Record<string, any>>({
  children,
  value
}: PropsWithChildren<{ value: ITableContext<D> }>) => (
  <TableContext.Provider value={value}>{children}</TableContext.Provider>
);

export const useTableContext = <D extends Record<string, any>>() => {
  const tableContext = useContext<ITableContext<D> | undefined>(TableContext);

  if (tableContext === undefined) throw new Error();

  return tableContext;
};
