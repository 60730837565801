import { ChevronLeftSVG } from '@components/svgs';
import { Text, Tooltip } from '@components/common';
import React from 'react';
import { TextProps } from '@components/common/Text';
import cn from 'classnames';

interface PanelWrapperProps {
  onClose: () => void;
  title: string;
  className?: string;
}

export const PanelWrapper: React.FC<PanelWrapperProps> = ({ className, onClose, title, children }) => (
  <section className='flex flex-col w-full'>
    <button
      onClick={onClose}
      aria-label={`Close ${title} panel`}
      className='xx-close-panel focus:ring focus:outline-none cursor-pointer hover:bg-gray-50 hover:text-indigo-600 w-full px-4 py-3.5 mt-px desktop:px-6 flex items-center border-b border-gray-200'
    >
      <ChevronLeftSVG className='w-4 h-4' />
      <Text className='ml-4 flex-grow' bold>
        {title}
      </Text>
    </button>
    <div className='p-4 desktop:p-6 flex-grow'>{children}</div>
  </section>
);


export const FormGroup = ({ children, className = '' }) => <div className={cn('mb-6', className)}>{children}</div>;
export const Label: React.FC<{
  tooltip?: string;
  h?: TextProps['h'];
}> = ({ h = '400', children, tooltip }) => (
  <label className='flex items-center mb-2 space-x-2'>
    <Text h={h} bold>
      {children}
    </Text>
    {tooltip && <Tooltip>{tooltip}</Tooltip>}
  </label>
);
