import * as React from 'react';
import { RestrictedButton } from '@components/shared/RestrictedButton';

import { StudySlideOut } from '@components/StudiesApp/types';
import { CandidateSlideOut } from './types';
import { AddCustomers } from './AddCustomers';
import { SendMenu } from './SendMenu';

interface Props {
  teamId?: number | null;
  panelStudy?: boolean;
  setSlideOut: (slideOut: CandidateSlideOut | StudySlideOut) => void;
  selectedIds: number[];
  allSelected?: boolean;
  openPanelStudyModal: () => void;
}

export const PageHeader: React.FC<Props> = ({
  teamId,
  panelStudy,
  selectedIds,
  allSelected,
  setSlideOut,
  openPanelStudyModal
}) => {
  return (
    <div className='inline-flex items-center space-x-3'>
      {selectedIds.length === 0 && !allSelected ? (
        <RestrictedButton
          buttonProps={{ medium: true }}
          action='Add candidates'
          permission='addCandidate'
          limit='candidates'
        >
          <AddCustomers
            teamId={teamId}
            component='candidates_index_header'
            setSlideOut={setSlideOut}
            openPanelStudyModal={openPanelStudyModal}
          />
        </RestrictedButton>
      ) : (
        <RestrictedButton buttonProps={{ medium: true }} action='Compose' permission='addCandidate'>
          <SendMenu ids={selectedIds} panelStudy={panelStudy} setSlideOut={setSlideOut} />
        </RestrictedButton>
      )}
    </div>
  );
};
