import { useEffect } from 'react';

type UseOnKeypressOpts = { meta?: boolean; shift?: boolean; skipInput?: boolean };
export function useOnKeypress(key: string, opts: UseOnKeypressOpts, handler: () => void): void {
  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (opts?.skipInput && ['input', 'textarea'].includes((e.target as HTMLElement).tagName.toLowerCase())) return;
      if (e.key !== key) return;
      if (opts.meta && !e.metaKey) return;
      if (opts.shift && !e.shiftKey) return;
      e.preventDefault();
      e.stopPropagation();
      handler();
    };

    document.addEventListener('keydown', onKeydown);

    return () => document.removeEventListener('keydown', onKeydown);
  }, [key, handler]);
}
