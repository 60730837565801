import * as React from 'react';
import { Button } from '@components/common';
import { DuplicateSVG } from '@components/svgs';
import Tippy from '@tippyjs/react';

interface Props {
  onClick: React.MouseEventHandler;
}

export const DuplicateEmailButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Tippy className='bg-gray-500 rounded-lg' content='Duplicate template' arrow={false}>
      <Button text rounded small onClick={onClick} className='xx-duplicate-email-template'>
        <DuplicateSVG className='inline mr-1 -mt-1' />
      </Button>
    </Tippy>
  );
};
