import cn from 'classnames';
import * as React from 'react';

import { Text } from '@components/common';
import { capitalize } from '@components/utils';
import { usePopUp } from '@hooks/usePopUp';

type Props = {
  className?: string;
  value: FilterOp;
  onChange: (value: FilterOp) => void;
};
export const AnyAllToggle: React.FC<Props> = ({ className, value, onChange }) => {
  const { openPopUp, closePopUp, open, PopUp, ref } = usePopUp();

  return (
    <div className={cn(className, 'relative')} ref={ref}>
      <button className='hover:text-indigo-600 focus:outline-none cursor-pointer' name='any or all' onClick={openPopUp}>
        <Text h='400'>{capitalize(value)}</Text>
      </button>
      <PopUp className='right-0' open={open} style={{ zIndex: 50 }}>
        <div className='bg-white border border-gray-200 divide-y divide-gray-200 rounded-md shadow-lg'>
          <label className='p-3 cursor-pointer' htmlFor='op_all'>
            <div className='flex items-center mb-1'>
              <Text bold h='400' className='flex-1'>
                All
              </Text>
              <input
                id='op_all'
                type='radio'
                name='op'
                value='all'
                checked={value === 'all'}
                onChange={() => {
                  onChange('all');
                  closePopUp();
                }}
              />
            </div>
            <Text h='400' color='gray-500'>
              Results that match all filters
            </Text>
          </label>
          <label className='p-3 cursor-pointer' htmlFor='op_any'>
            <div className='flex items-center mb-1'>
              <Text bold h='400' className='flex-1'>
                Any
              </Text>
              <input
                id='op_any'
                type='radio'
                name='op'
                value='any'
                checked={value === 'any'}
                onChange={() => {
                  onChange('any');
                  closePopUp();
                }}
              />
            </div>
            <Text h='400' color='gray-500'>
              Results that match any filters
            </Text>
          </label>
        </div>
      </PopUp>
    </div>
  );
};
