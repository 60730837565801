import classNames from 'classnames';
import * as React from 'react';

import { NotificationErrorSVG, NotificationSuccessSVG, XSVG } from '@components/svgs';

import { Text } from '../';

type NotificationActionProps = {
  href: string;
  onClick?: () => void;
  text: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
};
interface Props {
  heading: string;
  text: string;
  icon: 'error' | 'success' | 'info';
  timeoutSeconds?: number;
  action?: NotificationActionProps;
  actions?: NotificationActionProps[];
  onClose: () => void;
}
const Notification: React.FC<Props> = ({ heading, text, action, actions, icon, onClose }) => {
  const NotifIcon = {
    error: NotificationErrorSVG,
    success: NotificationSuccessSVG,
    info: NotificationSuccessSVG
  }[icon];
  const bgClass = {
    error: 'bg-red-600',
    success: 'bg-green-600',
    info: 'bg-gray-600'
  }[icon];
  const notifActions = action && !actions ? [action] : actions;

  return (
    <div
      role='alert'
      className={classNames('w-full max-w-sm rounded-md shadow-lg pointer-events-auto text-white', bgClass)}
    >
      <div className='p-4'>
        <div className='flex items-start'>
          <div className='flex-shrink-0'>
            <NotifIcon />
          </div>
          <div className='ml-4 w-0 flex-1 pt-0.5'>
            <Text bold className='text-white font-medium leading-5 no-branding'>
              {heading}
            </Text>
            <Text h='400' className='text-white mt-1 text-sm leading-5 no-branding'>
              {Array.isArray(text)
                ? text.map((t) => (
                    <React.Fragment key={t}>
                      {t}
                      <br />
                    </React.Fragment>
                  ))
                : text}
            </Text>
            <div className='mt-2'>
              {notifActions &&
                notifActions.map((action, idx) => (
                  <React.Fragment key={`notif-action-${idx}`}>
                    <span className='inline-flex rounded-md shadow-sm'>
                      <a
                        target={action.target}
                        href={action.href}
                        onClick={action.onClick}
                        className='focus:outline-none text-sm font-medium font-bold leading-4 text-white underline no-branding'
                      >
                        {action.text}
                      </a>
                    </span>
                    <br />
                  </React.Fragment>
                ))}
            </div>
          </div>
          <div className='flex flex-shrink-0 ml-4'>
            <button name='dismiss' onClick={onClose} className='focus:outline-none inline-flex'>
              <XSVG className='text-white' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export { Notification, Props as NotificationProps };
