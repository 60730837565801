import { CalendarSVG, ContinuousSVG } from 'components/svgs';
import React from 'react';

export const DAYS_SHORT_TEXT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const DAYS_LONG_TEXT = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const CONTINUOUS_OPTIONS = [
  { value: 'date_range', label: 'Date Range', data: { icon: <CalendarSVG /> } },
  { value: 'continuous', label: 'Continuous', data: { icon: <ContinuousSVG /> } }
];
