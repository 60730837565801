import React from 'react';

import { Button, Modal } from '@components/common';

interface Props {
  title: string;
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const ConfirmModal: React.FC<Props> = ({ title, message, onCancel, onConfirm }) => {
  return (
    <Modal
      onClose={onCancel}
      title={title}
      renderFooter={() => (
        <>
          <Button onClick={onCancel}>Cancel</Button>
          <Button primary onClick={onConfirm}>
            Confirm
          </Button>
        </>
      )}
    >
      {message}
    </Modal>
  );
};
