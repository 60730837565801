import React, { SVGProps } from 'react';

export const BrowserSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.3125 12.6875C1.08044 12.6875 0.857876 12.5953 0.693782 12.4312C0.529687 12.2671 0.4375 12.0446 0.4375 11.8125V2.25458C0.438269 2.00496 0.537771 1.76579 0.71428 1.58928C0.890788 1.41277 1.12996 1.31327 1.37958 1.3125H12.6251C12.8735 1.31327 13.1115 1.41228 13.2871 1.58791C13.4627 1.76355 13.5617 2.00153 13.5625 2.24992V11.7454C13.5617 11.995 13.4622 12.2342 13.2857 12.4107C13.1092 12.5872 12.87 12.6867 12.6204 12.6875H1.3125Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.5625 3.9375H0.4375'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.625 6.5625L11.375 8.3125L9.625 10.0625'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.375 6.5625L2.625 8.3125L4.375 10.0625'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.875 6.5625L6.125 10.0625'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
