import { Toast } from '@stores/toaster';

export const successDelete = (): Toast => ({
  heading: 'Form deleted',
  icon: 'success',
  text: 'The form was removed from your account.'
});
export const successUpdate = (): Toast => ({
  heading: 'Form updated',
  icon: 'success',
  text: 'The consent form was successfully updated.'
});

export const failedDelete = (): Toast => ({
  heading: 'Failed to delete!',
  icon: 'error',
  text: 'Something went wrong deleting that file. Please try again later.'
});

export const failedCreate = (): Toast => ({
  heading: 'Failed to attach file!',
  icon: 'error',
  text: 'Something went wrong attaching that file. Please try again later.'
});

export const failedUpdate = (): Toast => ({
  heading: 'Failed to update!',
  icon: 'error',
  text: 'Something went wrong updating that file. Please try again later.'
});
