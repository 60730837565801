import * as React from 'react';
import { useEffect, useState } from 'react';

import { Button, Modal, ModalHeading, Radio, Text } from '@components/common';
import { track } from '@components/tracking';

export enum ChangeModeratorActions {
  'SET_AS_OWNER' = 'SET_AS_OWNER',
  'SWITCH_TO_COLLECTIVE' = 'SWITCH_TO_COLLECTIVE',
  'SWITCH_TO_ROUND_ROBIN' = 'SWITCH_TO_ROUND_ROBIN'
}
const CHANGE_MODERATOR_ACTIONS: ChangeModeratorActions[] = [
  ChangeModeratorActions.SET_AS_OWNER,
  ChangeModeratorActions.SWITCH_TO_COLLECTIVE,
  ChangeModeratorActions.SWITCH_TO_ROUND_ROBIN
];

type RadioLabelFn = (name: string) => string;
const RADIO_LABELS: Record<ChangeModeratorActions, RadioLabelFn> = {
  [ChangeModeratorActions.SET_AS_OWNER]: (name) => `Keep it 1:1, make ${name} the study owner and moderator.`,
  [ChangeModeratorActions.SWITCH_TO_COLLECTIVE]: (name) => `Switch to collective and add ${name} as a moderator.`,
  [ChangeModeratorActions.SWITCH_TO_ROUND_ROBIN]: (name) => `Switch to round robin and add ${name} as a moderator.`
};

type Props = {
  moderatorName: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (action: ChangeModeratorActions) => void;
};

export const ConfirmChangeModeratorModal: React.FC<Props> = ({ moderatorName, isLoading, onClose, onSubmit }) => {
  const [changeModeratorAction, setChangeModeratorAction] = useState<ChangeModeratorActions>(
    ChangeModeratorActions.SET_AS_OWNER
  );

  const handleClickSubmit = () => {
    track('submitted_change_moderator_modal', { action: changeModeratorAction });
    onSubmit(changeModeratorAction);
  };

  useEffect(() => {
    track('viewed_change_moderator_modal');
  }, []);

  return (
    <Modal
      size='sm'
      onClose={onClose}
      renderFooter={() => (
        <div className='flex items-center justify-end space-x-4'>
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={isLoading} loading={isLoading} primary onClick={handleClickSubmit}>
            Confirm
          </Button>
        </div>
      )}
    >
      <ModalHeading className='mb-2'>Switch moderators?</ModalHeading>
      <Text h='400' mb='6'>
        You can either switch the moderator, or switch the scheduling style and add {moderatorName} as a moderator.
      </Text>
      <div className='space-y-2'>
        {CHANGE_MODERATOR_ACTIONS.map((action) => (
          <Radio
            key={action}
            id={`change_moderator_action_${action.toLowerCase()}`}
            name='change_moderator_action'
            value={action}
            label={RADIO_LABELS[action](moderatorName)}
            checked={changeModeratorAction === action}
            onChange={() => setChangeModeratorAction(action)}
          />
        ))}
      </div>
    </Modal>
  );
};
