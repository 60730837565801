export const getStudyLabel = (attributes) => {
  let label;

  if (attributes.style === 'video_call' && attributes.focus_group) {
    label = 'Focus group';
  } else if (attributes.style === 'video_call' && !attributes.focus_group && !attributes.is_phantom) {
    label = 'Interview';
  } else if (attributes.style === 'video_call' && !attributes.focus_group && attributes.is_phantom) {
    label = 'Recording';
  } else if (attributes.style === 'survey') {
    label = 'Survey';
  } else if (attributes.style === 'online_task') {
    label = 'Online task';
  } else if (attributes.style === 'unmoderated_test') {
    label = 'Unmoderated test';
  } else if (attributes.style === 'panel') {
    label = 'Panel';
  }

  return label;
};
