import * as React from 'react';
import { useEffect } from 'react';

import { api } from '@api/reduxApi';
import { Button, Modal, ModalHeading } from '@components/common';
import { useToaster } from '@stores/toaster';

import * as toasts from '../../toasts';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  uuid: string;
  title: string;
}
export const DeleteSessionModal: React.FC<Props> = ({ open, onClose, onSubmit, uuid }) => {
  const showToast = useToaster();

  const [deleteSession, { isLoading, isError, isSuccess }] = api.useDestroyRepoSessionMutation();

  useEffect(() => {
    if (isSuccess) {
      onSubmit?.();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      showToast(toasts.failedDelete());
    }
  }, [isError]);

  if (!open) {
    return null;
  }

  const handleClick = () => deleteSession({ uuid });

  return (
    <Modal icon='danger' onClose={isLoading ? undefined : onClose}>
      <ModalHeading className='mb-2'>Delete session?</ModalHeading>
      <p className='pb-12 text-gray-700'>
        By deleting the session, you understand that all related data will also be removed including any recordings,
        transcripts, highlights, notes, and shareable links.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button disabled={isLoading} onClick={onClose}>
          Don’t delete
        </Button>
        <Button data-testid='confirm-delete' disabled={isLoading} onClick={handleClick} danger>
          Confirm & delete
        </Button>
      </div>
    </Modal>
  );
};
