import { api } from '@api/reduxApi';

const aiChatsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAiChat: builder.query<AiChat, string>({
      query: (uuid) => `/ai/chats/${uuid}`
    }),
    getAiChats: builder.query<AiChat[], void>({
      query: () => '/ai/chats'
    })
  })
});

export const { useGetAiChatsQuery, useGetAiChatQuery } = aiChatsApi;
