import React, { SVGProps } from 'react';

export const GLogoSVG = (props) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M14.2609 4.06021C14.3963 4.10992 14.5264 4.16385 14.6544 4.22096C14.8606 4.13318 15.0922 4.08876 15.3481 4.08876C15.878 4.08876 16.3127 4.31826 16.6807 4.7762C17.3005 4.56997 17.5987 4.13424 17.5987 3.46795C17.5987 2.66417 16.9568 2 15.969 2C14.9357 2 14.0568 2.69273 13.6084 3.8508C13.9225 3.93118 14.2271 4.04752 14.2609 4.06021Z'
      fill='#5850EC'
    />
    <path
      d='M13.7184 14.7442C13.0754 14.6289 11.9733 14.5147 11.3991 14.4227C11.1008 14.3772 10.8015 14.3306 10.5033 14.2619C9.92899 14.1466 9.65402 13.8949 9.65402 13.5956C9.65402 13.0901 10.2516 12.8373 11.4223 12.8373H11.5598C13.0299 12.8373 14.2461 12.4703 15.2107 11.712C16.1752 10.9537 16.6574 9.89824 16.6574 8.58893C16.6574 6.75187 15.6242 5.48909 13.9479 4.86828C13.9479 4.86828 13.6528 4.75406 13.3736 4.68426C12.8226 4.54677 12.225 4.47803 11.582 4.47803C10.0897 4.47803 8.87245 4.84502 7.88465 5.58005C6.89685 6.29182 6.41458 7.30183 6.41458 8.61114C6.41458 10.6089 7.72389 12.0557 9.7217 12.6068C7.90791 12.813 6.78262 13.6168 6.78262 14.8806C6.78262 15.6389 7.17288 16.1899 7.97666 16.5336C8.78043 16.8784 9.35471 16.9704 10.5255 17.1534C11.4667 17.2687 12.1098 17.3596 12.4313 17.4294C14.0621 17.7509 14.8881 18.3939 14.8881 19.3585C14.8881 20.6678 13.5333 21.4938 11.7883 21.4938C9.88246 21.4938 9.00994 20.506 8.94013 19.2432C8.89466 18.7377 8.94013 18.2332 9.07762 17.7277C7.07981 17.7509 6 18.4394 6 19.6112C6 20.4605 6.50554 21.0813 7.51554 21.517C8.54882 21.9538 9.8116 22.1601 11.3049 22.1601C15.4158 22.1601 17.5511 20.507 17.5511 18.027C17.5532 16.008 16.1752 15.181 13.7184 14.7442ZM10.297 5.97242C10.5498 5.39814 10.9855 5.12316 11.5598 5.12316C12.1341 5.12316 12.5476 5.3992 12.8004 5.97242C13.0531 6.52343 13.1906 7.39595 13.1906 8.61325C13.1906 11.0013 12.6396 12.1953 11.5598 12.1953C10.389 12.1953 9.90678 11.0246 9.90678 8.61325C9.90678 7.39595 10.0443 6.52343 10.297 5.97242Z'
      fill='#5850EC'
    />
  </svg>
);
