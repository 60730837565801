import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { CloseIcon, ModalBackdrop } from '@components/common/Modal';
import { PDFView } from '@components/shared/PDFView';
import { Portal } from '@components/shared/Portal';
import { useOnClickOutside, useOnEscape } from '@components/utils';
import { useAccount } from '@hooks/useAccount';

import { StandardConsentForm } from './StandardConsentForm';

interface Props {
  form?: ConsentForm;
  open: boolean;
  onClose: () => void;
}

export const ConsentPreviewModal: React.FC<Props> = ({ open, onClose, form }) => {
  const {
    account: { name }
  } = useAccount();
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setWidth(event[0].contentBoxSize[0].inlineSize);
    });

    if (wrapperRef?.current) {
      resizeObserver.observe(wrapperRef?.current);
    }

    return () => resizeObserver.disconnect();
  }, [wrapperRef?.current]);

  useOnEscape(onClose, []);

  useOnClickOutside(ref, onClose);

  if (!open) return null;

  return (
    <Portal>
      <ModalBackdrop>
        <div
          ref={ref}
          data-testid='modal'
          className='relative flex flex-col w-full max-w-2xl max-h-screen bg-white rounded-md'
        >
          <div className='flex items-center justify-between p-4 border-b border-gray-200'>
            <h3 className='text-base font-bold text-gray-700'>{form?.title || 'Standard GQ consent checkboxes'}</h3>
            <div onClick={onClose} className='h-7 flex items-center'>
              <button aria-label='Close panel' className='hover:text-indigo-500 text-gray-700'>
                <CloseIcon className='w-4 h-4' />
              </button>
            </div>
          </div>
          <div ref={wrapperRef} className='flex-auto min-h-0 overflow-y-auto w-full flex justify-center'>
            {form ? <PDFView width={width} url={form.url} /> : <StandardConsentForm name={name} />}
          </div>
        </div>
      </ModalBackdrop>
    </Portal>
  );
};
