import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { track } from '@components/tracking';
import { shuffle } from 'components/utils';
import { AskAiZDS } from 'components/svgs';
import { getUrlFromContextObj } from '../utils/context';

interface Props {
  setContent: (content: string) => void;
  context?: AiChatContext | null;
}

interface ExampleQueries {
  [key: string]: string[];
}

const exampleQueries: ExampleQueries = {
  interview: [
    'What were the top feature requests in this interview?',
    'What were the biggest pain points?',
    'What were the action items?',
    'How did the interviewee feel about the product?',
    'What suggestions were made for improvement?',
    'Were there any unexpected insights?',
    'Did the interviewee raise any concerns about pricing?',
    'How did the interviewee describe their workflow?',
    'What were the key themes in this interview?',
    'Write a 200-word report about this interview. Highlight the key themes and include a few of the most pertinent quotes'
  ],
  study: [
    'What were the top feature requests in this study?',
    'What were the biggest pain points?',
    'List the key questions asked in this study, and summarise the responses to each in one sentence',
    'What were the key themes in this study?',
    'Write a short 50-word summary of each interview in this study',
    'What are the key take-aways from this study?',
    'Write a 200-word report about this study for the CEO'
  ]
};

const NUM_EXAMPLES = 3;

export const AiChatZeroDataScreen: React.FC<Props> = ({ setContent, context }) => {
  const [randomQueries, setRandomQueries] = useState<string[]>([]);

  const handleExampleClick = (query) => {
    track('ai_chat_click_example', { context, query });
    setContent(query);
  };

  useEffect(() => {
    if (!context || context.context_type == null || exampleQueries[context.context_type] == null) {
      return;
    }
    setRandomQueries(shuffle(exampleQueries[context.context_type]).slice(0, NUM_EXAMPLES));
  }, [context?.context_type]);

  let scopeDescription: JSX.Element = <span>the current page</span>;
  if (context?.context_name != null) {
    const scopeUrl = context ? getUrlFromContextObj(context) : null;
    scopeDescription = (
      <div className='inline-block px-2 py-1 mt-1 rounded-md bg-gray-100 text-sm text-gray-600'>
        {scopeUrl ? <Link to={scopeUrl}>{context.context_name}</Link> : context.context_name}
      </div>
    );
  }

  const contentWithScope = (
    <>
      <h3 className='mt-8 mb-4 text-lg font-bold'>Hey there,</h3>
      <p className='mb-5 text-gray-500'>I'm a bot trained on the data from {scopeDescription}</p>
      <p className='mb-5 text-gray-500'>
        You can ask me any questions that come to mind. Below are some good examples:
      </p>
      <div className='flex flex-wrap justify-left gap-2 mb-5'>
        {randomQueries.map((query, index) => (
          <button
            key={index}
            className='px-4 py-1 text-left text-sm border border-gray-300 hover:bg-gray-100 focus:outline-none rounded-md'
            onClick={() => handleExampleClick(query)}
          >
            {query}
          </button>
        ))}
      </div>
    </>
  );

  const contentWithoutScope = (
    <>
      <h3 className='mt-8 mb-4 text-lg font-bold'>Hey there,</h3>
      <p className='mb-5 text-gray-500'>
        I'm a bot that can respond to queries about any video call study or interview with a transcript.
      </p>
      <p className='mb-5 text-gray-500'>Select a valid scope above to get started!</p>
    </>
  );

  return (
    <div className='flex items-left justify-left flex-grow p-10'>
      <div className='flex flex-col items-left text-left'>
        <div className='opacity-50 mt-10'>
          <AskAiZDS />
        </div>
        {context?.context_type ? contentWithScope : contentWithoutScope}
        <p className='mb-5 text-xs text-gray-500'>
          <b>Ask AI</b> is powered by{' '}
          <a href='https://openai.com/gpt-4' target='_blank'>
            GPT-4
          </a>
          .
        </p>
      </div>
    </div>
  );
};
