import React, { SVGProps } from 'react';

export const ClockSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.875 8C1.875 9.62445 2.52031 11.1824 3.66897 12.331C4.81763 13.4797 6.37555 14.125 8 14.125C9.62445 14.125 11.1824 13.4797 12.331 12.331C13.4797 11.1824 14.125 9.62445 14.125 8C14.125 6.37555 13.4797 4.81763 12.331 3.66897C11.1824 2.52031 9.62445 1.875 8 1.875C6.37555 1.875 4.81763 2.52031 3.66897 3.66897C2.52031 4.81763 1.875 6.37555 1.875 8V8Z'
      stroke='#6B7280'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M8 8V5.8125' stroke='#6B7280' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M8 8L10.7341 10.7347' stroke='#6B7280' strokeWidth='0.875' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);
