import React, { VFC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input } from '@components/common';

import { useCreateSearchMutation, useGetSearchQuery } from '../api';

export const PLACEHOLDER = 'What do you want to know?';

export const Header: VFC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState<string>('');

  const { data: result } = useGetSearchQuery({ id }, { skip: !id });
  const [search] = useCreateSearchMutation();

  const pending = result?.state === 'pending';

  const handleOnSearch = async () => {
    if (!inputValue) return;

    const newSearch = await search({ q: inputValue }).unwrap();
    navigate(`/searches/${newSearch.id}`);
  };

  const handleOnInputChange = (value: string) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (!result?.query) return;

    setInputValue(result.query);
  }, [result?.query]);

  return (
    <header className='px-12 py-3 bg-white border-b border-gray-200'>
      <div className='flex flex-row items-center w-1/2 mx-auto space-x-2'>
        <Input
          size='lg'
          icon='ai'
          placeholder={PLACEHOLDER}
          disabled={!!id}
          onChange={handleOnInputChange}
          value={inputValue}
        />

        {id ? (
          <Button spa href='/searches'>
            Clear
          </Button>
        ) : (
          <Button primary loading={pending} onClick={handleOnSearch}>
            Search
          </Button>
        )}
      </div>
    </header>
  );
};
