import { useCallback } from 'react';
import { STUDIES_COLUMN_LABELS } from '@components/StudiesApp/components/StudiesIndex/contants';
import { UseColumnLabelsReturnType } from 'components/shared/Table/types';

export const useStudiesColumnLabels = (): UseColumnLabelsReturnType => {
  const getColumnLabel = useCallback<UseColumnLabelsReturnType>((key: string) => {
    return STUDIES_COLUMN_LABELS[key] ?? key;
  }, []);

  return getColumnLabel;
};
