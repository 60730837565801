import React from 'react';

import { Button, Modal, Text } from '@components/common';

export const TermsModal: React.FC<{ onClose: () => void; onAccept: () => void }> = ({ onClose, onAccept }) => {
  return (
    <Modal
      title='Accept updated Terms of Service'
      subtitle="We've updated our Terms of Service to reflect OpenAI in Third Party Services."
      size='lg'
      onClose={() => onClose()}
      renderFooter={() => (
        <>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={() => {
              onAccept();
            }}
          >
            Accept Terms & Conditions
          </Button>
        </>
      )}
    >
      <Text mb='4'>The follow term has been updated to include OpenAI.</Text>
      <Text h='400' mb='4' bold>
        1.4 THIRD PARTY SERVICES
      </Text>
      <Text h='400' mb='2'>
        If you use any third party service with the Services (for example, Zoom, OpenAI, OpenAI, Slack or Zapier
        integrations or a web browser), you acknowledge that third party service may access or use the Customer Data.
        Great Question will not be responsible for any act or omission of the third party, including such third party’s
        use of Customer Data. Great Question does not warrant or support any such third party service, and you should
        contact that third party for any issues arising from your use of the third party service. Great Question may
        provide you with access to optional AI functionality on an opt-in basis (“AI Features”). These AI Features are
        provided through a third party service provider (currently, OpenAI OpCo, LLC). You acknowledge that enabling the
        AI Features in the Services will result in Customer Data being shared with OpenAI. Customer agrees that Great
        Question will not be responsible for any act or omission of OpenAI, including with respect to the accuracy of
        the results that it generates or its use of Customer Data. AI Features are disabled by default and will only
        apply if Customer opts-in to use them by enabling those features within the Services.
      </Text>
      <Text h='400' mb='6'>
        Great Question will make available a current list of its third-party AI service providers at
        http://greatquestion.co/subprocessors, and will provide at least ten (10) days prior notice before adding new
        providers, by updating the list and providing email notification to individuals who have subscribed to email
        notifications. You may disable the AI Features within the Services if you do not wish to use those services
        after such changes take effect.
      </Text>
      <div className='text-left'>
        <Button link href='https://greatquestion.co/terms' target='_blank' className='pl-2'>
          View full Terms of Service
        </Button>
      </div>
    </Modal>
  );
};
