import { Toast } from 'components/stores/toaster';

export const errorToast = (error: string): Toast => {
  const genericMessage = 'Something went wrong sending the message. Please try again later.';
  const message = error || genericMessage;

  const toast: Toast = {
    heading: `Failed to send!`,
    icon: 'error',
    text: message
  };

  return toast;
};

export const successToast = (
  onStudy: boolean,
  study?: Study,
  backgroundTask?: BackgroundTask,
  onCancel?: (task: BackgroundTask) => Promise<void>
): Toast => {
  const toast: Toast = {
    heading: 'Invites sent!',
    text: 'The invites will be sent shortly',
    icon: 'success',
    actions: [],
    timeoutSeconds: 10
  };

  if (backgroundTask && ['pending', 'processing'].includes(backgroundTask.status) && toast.actions) {
    toast.actions.push({
      text: 'Undo',
      href: '#',
      onClick: () => {
        if (onCancel) {
          onCancel(backgroundTask);
        }
      }
    });
  }

  if (!onStudy && study && toast.actions) {
    toast.actions.push({
      text: 'View study',
      href: `/studies/${study.id}`
    });
  }
  return toast;
};
