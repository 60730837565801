export const getHighlightDisplayTitle = (highlight: Highlight | undefined): string => {
  if (highlight?.title) {
    return highlight.title;
  }

  if (highlight?.clip?.title) {
    return highlight?.clip.title;
  }

  if (highlight?.clip?.candidate_first_name) {
    return `Untitled highlight with ${highlight?.clip.candidate_first_name}`;
  }

  return `Untitled highlight`;
};
