import * as React from 'react';
import { useState } from 'react';
import cn from 'classnames';
import { Droppable } from 'react-beautiful-dnd';

import { Spinner, Text } from '@components/common';
import { getBackgroundColor } from '@components/tags/colors';
import { blurOnEnter } from '@components/utils';

import { ColumnOptionsDropdown, DropdownOptionsCallback } from './ColumnOptionsDropdown';

type Props = {
  id?: number;
  name?: string;
  description?: string;
  color?: Tag['color'];
  isSolidWhite?: boolean;
  ungrouped?: boolean;
  ungroupedCount?: number;
  ungroupedLabel?: string;
  readOnly?: boolean;
  isUpdating?: boolean;
  shouldRenderOptionsDropdown?: boolean;
  shouldRenderDroppablePlaceholder?: boolean;
  onChangeTitle: (title: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeColor: (color: Tag['color']) => void;
  renderExtraInput?: () => React.ReactNode;
  dropdownOptions?: DropdownOptionsCallback | false;
};

export const Column: React.FC<Props> = ({
  id,
  children,
  name,
  description,
  color = 'default',
  isSolidWhite,
  ungrouped,
  ungroupedCount,
  ungroupedLabel,
  readOnly = false,
  isUpdating,
  shouldRenderDroppablePlaceholder,
  onChangeTitle,
  onChangeDescription,
  onChangeColor,
  renderExtraInput,
  dropdownOptions
}) => {
  const [value, setValue] = useState(name);
  const [descriptionValue, setDescriptionValue] = useState(description);

  return (
    <Droppable droppableId={ungrouped ? 'ungrouped' : String(id)}>
      {(provided, snapshot) => {
        const opacity = snapshot.isDraggingOver ? 0.3 : 0.15;
        const backgroundColor = getBackgroundColor(color, opacity);

        return (
          <div
            className={cn('relative flex flex-col flex-shrink-0 w-full h-full max-w-sm rounded-sm', {
              'bg-white border border-gray-200': isSolidWhite,
              'bg-gray-100': !isSolidWhite
            })}
            style={isSolidWhite ? {} : { backgroundColor }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className='p-4 pb-0'>
              <div className='top-5 right-5 absolute flex'>
                {isUpdating && (
                  <div className='mr-2'>
                    <Spinner className='w-4 h-4' />
                  </div>
                )}
                {!readOnly && dropdownOptions && (
                  <ColumnOptionsDropdown
                    color={color}
                    onChangeColor={id ? onChangeColor : undefined}
                    dropdownOptions={dropdownOptions}
                  />
                )}
              </div>
              {ungrouped && (
                <Text bold mb='2'>
                  Ungrouped{typeof ungroupedCount === 'number' && ` (${ungroupedCount})`}
                </Text>
              )}
              {!ungrouped && (
                <input
                  name='name'
                  aria-label='name'
                  autoComplete='off'
                  disabled={isUpdating || readOnly}
                  className='w-72 focus:outline-none mb-2 font-bold placeholder-gray-400 truncate bg-transparent border-0'
                  value={value}
                  placeholder='Add group name'
                  onChange={(e) => setValue(e.currentTarget.value)}
                  onBlur={(e) => onChangeTitle(e.currentTarget.value)}
                  {...blurOnEnter()}
                />
              )}
              {ungrouped && (
                <Text h='400' color='gray-500' mb='10'>
                  {ungroupedLabel}
                </Text>
              )}
              {!ungrouped && (
                <textarea
                  name='description'
                  aria-label='description'
                  autoComplete='off'
                  disabled={isUpdating || readOnly}
                  className='h400 focus:outline-none w-full p-1 mb-2 text-gray-500 placeholder-gray-400 bg-transparent border-0'
                  value={descriptionValue}
                  placeholder='Add description'
                  onChange={(e) => setDescriptionValue(e.currentTarget.value)}
                  onBlur={(e) => onChangeDescription(e.currentTarget.value)}
                  rows={2}
                  {...blurOnEnter()}
                />
              )}
              {!readOnly && renderExtraInput?.()}
            </div>
            <div className='w-full h-full px-4 mb-2 space-y-2 overflow-y-auto'>
              {shouldRenderDroppablePlaceholder && provided.placeholder}
              {children}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};
