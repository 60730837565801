import React, { useEffect, useRef } from 'react';

import { createPolymorphicComponent } from '@helpers/react/createPolymorphicComponent';

import { isSafeArea } from './helpers/safeAreaRegistry';

interface Props {
  onClick?: (event: MouseEvent) => void;
  isDisabled?: boolean;
}

export const ClickOutside = createPolymorphicComponent<Props, 'div'>(
  ({ as: Component = 'div', children, isDisabled, onClick, ...rest }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isDisabled) return;

      const element = ref.current;

      const handleClickOutside = (event: MouseEvent) => {
        if (element && !element.contains(event.target as Node) && !isSafeArea(event.target as Element)) {
          onClick?.(event);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isDisabled, onClick]);

    return (
      <Component ref={ref} className='contents' {...rest}>
        {children}
      </Component>
    );
  }
);
