export const updateClipsTime = <T>(initialClips) => {
  let currentTime = 0;
  const clips: T[] = [];

  initialClips?.forEach((clip) => {
    const diff = (clip.to ?? 0) - (clip.from ?? 0);
    clips.push({ ...clip, from: currentTime, to: currentTime + diff });
    currentTime += diff;
  });

  return clips;
};
