import { createContext, useContext } from 'react';

import { Models } from '@components/SurveyBuilder';

export interface Context {
  blocks: Models.Block[];
  deviceType: Study['device_type'];
}

export const UnmoderatedContext = createContext<Context | undefined>(undefined);

export const useUnmoderatedContext = () => {
  const unmoderatedContext = useContext<Context | undefined>(UnmoderatedContext);

  if (unmoderatedContext === undefined) throw new Error();

  return unmoderatedContext;
};
