import { Button, ExtraInfo, Text } from 'components/common';
import { UserAvatars } from 'components/shared/UserAvatars';
import { OptionsDropdown } from 'components/StudiesApp/components/StudiesIndex/components/OptionsDropdown';
import { getStudyProgress } from 'components/StudiesApp/components/StudiesIndex/hooks/getStudyProgress';
import { StudyStatus } from 'components/StudiesApp/components/StudyPublished/components/StudyStatus';
import { incentiveStr } from 'components/StudiesApp/components/StudyPublished/helpers/incentiveStr';
import { humanize } from 'components/utils';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { format as timeagoFormat } from 'timeago.js';
import tinytime from 'tinytime';

interface Props {
  records: Study[];
}

const dateTemplate = tinytime('{MM} {DD}, {YYYY}');

const InfoDot = () => <div className='w-1 h-1 mr-2 bg-gray-200 rounded-full' />;

export const RowViewTable: React.FC<Props> = ({ records: studies }) => {
  const navigate = useNavigate();

  if (!studies.length) return null;

  return (
    <>
      {studies.map((study) => {
        const progress = getStudyProgress(study);
        const url = study.state === 'draft' ? `/studies/${study.id}/edit` : `/studies/${study.id}`;

        return (
          <div key={study.id} className='xx-study-card relative mb-6'>
            <div className='top-4 right-4 absolute'>
              <OptionsDropdown study={study} />
            </div>
            <Link to={url} title={study.title}>
              <div className='desktop:w-auto hover:bg-gray-50 hover:border-indigo-600 w-full h-full text-gray-700 bg-white border border-gray-200 rounded-md'>
                <div className='p-4'>
                  <Text bold truncate className='mb-3'>
                    {study.title}
                  </Text>
                  <div className='flex flex-wrap items-center'>
                    <Text h='400' className='pb-4 mr-3'>
                      <StudyStatus status={study.state} />
                    </Text>
                    {study.style && (
                      <ExtraInfo tooltip='Study type' mr='2' className='pb-4' Icon={InfoDot}>
                        {study.style === 'video_call' && study.focus_group && 'Focus groups'}
                        {study.style === 'video_call' && !study.focus_group && !study.is_phantom && 'Interviews'}
                        {study.style === 'video_call' && !study.focus_group && study.is_phantom && 'Recordings'}
                        {study.style === 'survey' && 'Survey'}
                        {study.style === 'online_task' && 'Online task'}
                        {study.style === 'unmoderated_test' && 'Unmoderated test'}
                        {study.style === 'panel' && 'Panel'}
                      </ExtraInfo>
                    )}
                    {study.incentive_method && study.incentive_title && (
                      <ExtraInfo
                        mr='2'
                        className='pb-4'
                        tooltip={`${humanize(study.incentive_method)} incentive`}
                        Icon={InfoDot}
                      >
                        {incentiveStr(study)}
                      </ExtraInfo>
                    )}
                    {study.has_screener && (
                      <ExtraInfo mr='2' className='pb-4' Icon={InfoDot}>
                        Has screener
                      </ExtraInfo>
                    )}

                    <ExtraInfo mr='2' className='pb-4' Icon={InfoDot}>
                      Updated {timeagoFormat(study.updated_at as any)}
                    </ExtraInfo>

                    <ExtraInfo mr='2' className='pb-4' Icon={InfoDot}>
                      Created {dateTemplate.render(study.created_at)}
                    </ExtraInfo>
                  </div>

                  {progress.show && (
                    <div className='flex items-center'>
                      <div className='flex-1'>{progress.renderProgressBar?.()}</div>
                      <Text h='200' color='gray-500' uppercase className='w-32 text-right'>
                        {progress.text} completed
                      </Text>
                    </div>
                  )}
                  <div className='tablet:flex-row tablet:space-y-0 tablet:space-x-4 flex flex-col items-center mt-4 space-y-4'>
                    <UserAvatars user_ids={study.user_ids} className='flex-1' />
                    {study.has_screener && (
                      <Button
                        small
                        icon='studies'
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`${url}/screener`);
                        }}
                        className='whitespace-nowrap flex items-center'
                      >
                        View screeners
                      </Button>
                    )}
                    <Button
                      small
                      icon='repository'
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`${url}/repository`);
                      }}
                      className='whitespace-nowrap flex items-center'
                    >
                      View artifacts
                    </Button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </>
  );
};
