import * as React from 'react';

import { PageTitle } from '@components/common/helmets';
import { useFeature } from '@hooks/useFeature';
import { useStudies } from '@hooks/useStudies';
import { useTeams } from '@hooks/useTeams';
import { useShowAiChatBubble } from '@stores/aiChat';
import { Provider as CollectionView } from '@stores/view';

import { Skeleton } from './Skeleton';
import { OldStudiesListPage } from './OldStudiesListPage';
import { StudiesListPage } from './StudiesListPage';

type Props = {
  teamId?: number | null;
};
export const StudiesIndex: React.FC<Props> = ({ teamId }) => {
  useShowAiChatBubble();
  const { studies, loading } = useStudies();

  const { findTeam } = useTeams();

  const enableTeams = useFeature('teams');
  const hasPaginatedStudies = useFeature('paginated_studies');

  const team = enableTeams && teamId ? findTeam(teamId) : null;

  const filteredStudies = team ? studies.filter(({ team_id }) => team_id === team.id) : studies;

  return (
    <CollectionView
      scope={hasPaginatedStudies ? 'paginated_studies' : 'studies'}
      page={team ? `team-${team.id}` : undefined}
      defaultable
      default={{ sort: { value: 'updated_at', desc: true }, filters: '', layout: 'list' }}
    >
      {hasPaginatedStudies && <StudiesListPage team={team} />}

      {hasPaginatedStudies ? null : loading || !filteredStudies ? (
        <Skeleton />
      ) : (
        <>
          <PageTitle>Studies</PageTitle>
          <OldStudiesListPage team={team} studies={(filteredStudies || []).filter(({ is_phantom }) => !is_phantom)} />
        </>
      )}
    </CollectionView>
  );
};
