import * as React from 'react';

export const close: React.FC = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.4375 13.5619L13.5625 0.43689'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5625 13.5619L0.4375 0.43689'
      stroke='#374151'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
