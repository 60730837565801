import React, { forwardRef, HTMLAttributes, useEffect, useRef, useState } from 'react';
import mergeRefs from 'react-merge-refs';

import { Editor } from '@tiptap/react';

import { useOnEscape } from '@components/utils';

import { BubbleMenu as BubbleMenuPlugin, BubbleMenuPluginParams } from '../plugins';
import { MotionWrapper } from './MotionWrapper';

interface Props extends HTMLAttributes<HTMLDivElement> {
  editor: Editor;
  pluginKey?: string;
  popperOptions?: BubbleMenuPluginParams['popperOptions'];
  shouldShow?: BubbleMenuPluginParams['shouldShow'];
  hideOnEsc?: boolean;
}

const ANIMATE_CLASSNAME = {
  top: 'translate-y-10',
  bottom: '-translate-y-10',
  left: 'translate-x-10',
  right: '-translate-x-10'
};

export const BubbleMenu = forwardRef<
  HTMLDivElement,
  PropsWithChildrenFn<Props, { hide: () => void; isVisible: boolean }>
>(({ editor, pluginKey = 'bubbleMenu', hideOnEsc, popperOptions, shouldShow, className, children, ...rest }, ref) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const hide = () => setIsVisible(false);

  useOnEscape(() => {
    if (hideOnEsc) {
      hide();
    }
  }, [hideOnEsc]);

  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    if (editor.isDestroyed) {
      return;
    }

    const plugin = BubbleMenuPlugin({
      editor,
      pluginKey,
      element: menuRef.current,
      popperOptions,
      shouldShow,
      onShow: () => setIsVisible(true),
      onHide: () => setIsVisible(false)
    });

    editor.registerPlugin(plugin);
    return () => editor.unregisterPlugin(pluginKey);
  }, [editor, menuRef]);

  return (
    <MotionWrapper ref={mergeRefs([menuRef, ref])} isVisible={isVisible} placement={popperOptions?.placement} {...rest}>
      {isVisible && (typeof children === 'function' ? children({ hide, isVisible }) : children)}
    </MotionWrapper>
  );
});
