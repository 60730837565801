import * as React from 'react';

import cn from 'classnames';
import { AnimatedOnHoverImage } from 'components/shared/AnimatedOnHoverImage';
import { PlaceholderPlayer } from '.';

interface Props {
  aspectRatio?: keyof typeof RATIO_MAP;
  bg?: string;
  thumbnail?: string;
  gif?: string;
  className?: string;
  state?: 'processing' | 'errored' | 'uploading';
}

const DEFAULT_RATIO = '16:9';
const RATIO_MAP = {
  '16:9': '56.25%',
  '4:3': '75%',
  '3:2': '66.66%',
  '8:5': '62.5%'
};

export const ThumbnailPlayer: React.FC<Props> = ({
  aspectRatio = DEFAULT_RATIO,
  bg,
  thumbnail,
  gif,
  state,
  className
}) => (
  <section
    style={{ paddingBottom: RATIO_MAP[aspectRatio] ?? RATIO_MAP[DEFAULT_RATIO] }}
    className={cn('relative max-w-full bg-black', className)}
  >
    <div className='absolute flex justify-center w-full h-full'>
      {!thumbnail && !gif ? (
        <PlaceholderPlayer className={cn(bg, 'h-auto')} state={state} />
      ) : (
        <AnimatedOnHoverImage className='w-full' imageUrl={thumbnail as any} gifUrl={gif as any} />
      )}
    </div>
  </section>
);
