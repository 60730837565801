import * as Models from '../types/models';

export const parseScreenerFieldType = (fieldType: ScreenerFieldType): Models.BlockKind => {
  switch (fieldType) {
    case 'number_range':
      return 'linear_scale';
    case 'multiple_choice':
      return 'multi_select';
    case 'single_choice':
      return 'single_select';
    case 'task':
    case 'start_loom':
    case 'stop_loom':
      return 'info';
    default:
      return fieldType;
  }
};
