import React, { createContext, PropsWithChildren, useContext } from 'react';

const Context = createContext<SearchContext<any> | undefined>(undefined);

export const Provider = <D extends unknown>({ children, value }: PropsWithChildren<{ value: SearchContext<D> }>) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

export const useSearch = <D extends Record<string, any>>() => {
  const searchContext = useContext<SearchContext<D> | undefined>(Context);

  if (searchContext === undefined) throw new Error();

  return searchContext;
};
