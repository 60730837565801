import React, { SVGProps } from 'react';

export const EmailActionSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.56201 10.0637C6.56201 10.5233 6.65254 10.9784 6.82843 11.4031C7.00433 11.8277 7.26213 12.2135 7.58714 12.5385C7.91214 12.8635 8.29798 13.1213 8.72262 13.2972C9.14726 13.4731 9.60239 13.5637 10.062 13.5637C10.5216 13.5637 10.9768 13.4731 11.4014 13.2972C11.826 13.1213 12.2119 12.8635 12.5369 12.5385C12.8619 12.2135 13.1197 11.8277 13.2956 11.4031C13.4715 10.9784 13.562 10.5233 13.562 10.0637C13.562 9.60403 13.4715 9.14891 13.2956 8.72427C13.1197 8.29963 12.8619 7.91379 12.5369 7.58879C12.2119 7.26378 11.826 7.00597 11.4014 6.83008C10.9768 6.65419 10.5216 6.56366 10.062 6.56366C9.60239 6.56366 9.14726 6.65419 8.72262 6.83008C8.29798 7.00597 7.91214 7.26378 7.58714 7.58879C7.26213 7.91379 7.00433 8.29963 6.82843 8.72427C6.65254 9.14891 6.56201 9.60403 6.56201 10.0637V10.0637Z'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.31201 10.0637H11.812'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.812 10.0637L10.4995 11.3762'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.812 10.0637L10.4995 8.75116'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.81201 9.18866H1.31201C1.07995 9.18866 0.857388 9.09647 0.693293 8.93238C0.529199 8.76828 0.437012 8.54572 0.437012 8.31366V1.31366C0.437012 1.0816 0.529199 0.859036 0.693293 0.694941C0.857388 0.530847 1.07995 0.43866 1.31201 0.43866H11.812C12.0441 0.43866 12.2666 0.530847 12.4307 0.694941C12.5948 0.859036 12.687 1.0816 12.687 1.31366V5.24999'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.4887 0.758331L7.73802 4.4135C7.40078 4.67295 6.98722 4.81363 6.56172 4.81363C6.13623 4.81363 5.72267 4.67295 5.38543 4.4135L0.634766 0.758331'
        stroke='#374151'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
