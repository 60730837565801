import * as React from 'react';
import ReactSkeleton from 'react-loading-skeleton';

export const Skeleton: React.FC = () => (
  <div data-testid='attributes-table-skeleton' className='w-full'>
    <div className='flex justify-between py-4'>
      <ReactSkeleton duration={1} width={150} className='bg-gray-50 h-8 rounded-md' />
      <ReactSkeleton duration={1} width={175} className='bg-gray-50 h-8 rounded-md' />
    </div>

    <ReactSkeleton duration={1} className='bg-gray-50 block w-full h-8 rounded-md' count={12} />
  </div>
);
