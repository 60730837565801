import { Button, Modal, ModalHeading } from '@components/common';
import * as React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const LimitModal: React.FC<Props> = ({ open, onClose }) => {
  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeading className='mb-4 text-left'>Custom rules limit reached</ModalHeading>
      <p className='pb-12 text-gray-700 text-left'>
        Only 6 custom eligibility rules are allowed at this time. In order to add a new rule, you have to edit or delete
        an existing rule.
      </p>
      <div className='flex justify-end space-x-4'>
        <Button onClick={onClose} primary>
          Got it
        </Button>
      </div>
    </Modal>
  );
};
