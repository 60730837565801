import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';

import { api } from '@api/reduxApi';
import { Button, PopperDropdown, PopperDropdownButton } from '@components/common';
import { ExportCsvModal } from '@components/shared/ExportCsvModal';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { useToaster } from '@components/stores/toaster';
import { ExportSVG, PencilSVG, ShortlistSVG, TrashSVG } from '@components/svgs';
import { useFeature } from '@hooks/useFeature';
import { usePermission } from '@hooks/usePermission';
import Tippy from '@tippyjs/react';

import { BulkDeleteConfirmationModal } from './BulkDeleteConfirmationModal';
import { EditContactAccessModal } from './EditContactAccessModal';
import { CandidateSlideOut, FilterQuery } from './types';

interface MenuProps {
  selectedIds: number[];
  setSlideOut: (slideOut: CandidateSlideOut) => void;
  allSelected?: boolean;
  selectedCount: number;
  onStartBulkDelete: (ids: number[], backgroundTask: BackgroundTask) => void;
  onEditTeams: () => void;
  currentViewColumns: string[];
  query: FilterQuery;
  addCandidatesModal?: boolean;
  resetSelection: () => void;
  refetchCandidates: () => void;
}

export const BulkActions: React.FC<MenuProps> = ({
  onStartBulkDelete,
  onEditTeams,
  selectedIds,
  allSelected,
  setSlideOut,
  selectedCount,
  currentViewColumns,
  query,
  addCandidatesModal,
  resetSelection,
  refetchCandidates
}) => {
  const showToast = useToaster();

  const enableTeams = useFeature('teams');

  const canManageTeams = usePermission('manageTeams')();
  const canDelete = usePermission('deleteCandidate')();
  const canExportCsv = usePermission('exportCsv')();
  const canSeeExportButton = usePermission('canCreate')();

  const [
    bulkDeleteCandidates,
    { data, isLoading: isDeleteLoading, isSuccess: deleteSuccess, isError: isDeleteError, error: deleteError }
  ] = api.useBulkCandidateDeleteMutation();

  const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState<boolean>(false);
  const [csvExportModalOpen, setCsvExportModalOpen] = useState<boolean>(false);
  const [editContactAccessModalOpen, setEditContactAccessModalOpen] = useState<boolean>(false);

  const candidateParams = { ...(allSelected ? { query } : { ids: selectedIds }) };

  useEffect(() => {
    if (deleteSuccess && data) {
      setBulkDeleteModalOpen(false);
      onStartBulkDelete(selectedIds, data);
      resetSelection();
      showToast({
        icon: 'success',
        heading: 'Done!',
        text: `Deleted ${pluralize('candidate', selectedCount, true)}.`
      });
    }
  }, [deleteSuccess, data]);

  useEffect(() => {
    if (isDeleteError && deleteError) {
      const message =
        (deleteError as any)?.data?.error ||
        'Something went wrong when deleting the candidates. Please try again later.';
      setBulkDeleteModalOpen(false);
      refetchCandidates();
      showToast({
        icon: 'error',
        heading: 'Failed to delete!',
        text: message
      });
    }
  }, [deleteError, isDeleteError]);

  const handleBulkDelete = async (deletion_type: Candidate['deletion_type']) => {
    bulkDeleteCandidates({ ...candidateParams, deletion_type });
  };

  const handleClickDelete = () => {
    if (canDelete) {
      setBulkDeleteModalOpen(true);
    }
  };

  const showSlideOut = (slideOut: CandidateSlideOut) => {
    setSlideOut(slideOut);
  };

  if (!selectedIds.length) return null;

  return (
    <div className='inline-flex items-center space-x-4'>
      <RestrictedButton buttonProps={{ medium: true }} action='Manage' permission='addCandidate'>
        <PopperDropdown aria-label='Manage' medium buttonClassName='xx-send-menu' text='Manage'>
          <PopperDropdownButton className='flex items-center' disabled={!canDelete} onClick={handleClickDelete}>
            <TrashSVG />
            <div className='ml-2'>Delete</div>
          </PopperDropdownButton>

          {canSeeExportButton && (
            <PopperDropdownButton
              data-testid='export-csv'
              disabled={!canExportCsv}
              onClick={() => setCsvExportModalOpen(true)}
            >
              <Tippy content='Admin only'>
                <div className='flex items-center'>
                  <ExportSVG width='14' height='14' />
                  <div className='items-center ml-2'>Export CSV</div>
                </div>
              </Tippy>
            </PopperDropdownButton>
          )}
          <PopperDropdownButton
            className='xx-bulk-shortlist flex items-center'
            onClick={() => showSlideOut('BulkEdit')}
          >
            <PencilSVG width='14' height='14' />
            <div className='items-center ml-2'>Edit</div>
          </PopperDropdownButton>
          {enableTeams && canManageTeams && (
            <PopperDropdownButton
              className='xx-bulk-shortlist flex items-center'
              onClick={() => setEditContactAccessModalOpen(true)}
            >
              <PencilSVG width='14' height='14' />
              <div className='items-center ml-2'>Change contact access</div>
            </PopperDropdownButton>
          )}
        </PopperDropdown>
        {!addCandidatesModal && (
          <Button
            medium
            className='xx-bulk-shortlist flex items-center'
            onClick={() => showSlideOut('AddToStudyShortlist')}
          >
            <ShortlistSVG />
            <div className='items-center ml-2'>Shortlist</div>
          </Button>
        )}
        {editContactAccessModalOpen && (
          <EditContactAccessModal
            open
            {...candidateParams}
            onClose={() => setEditContactAccessModalOpen(false)}
            onSuccess={() => {
              setEditContactAccessModalOpen(false);
              onEditTeams();
              resetSelection();
            }}
          />
        )}
        {bulkDeleteModalOpen && (
          <div className='text-left'>
            <BulkDeleteConfirmationModal
              count={selectedCount}
              onConfirm={handleBulkDelete}
              onCancel={() => setBulkDeleteModalOpen(false)}
              loading={isDeleteLoading}
            />
          </div>
        )}
        {canExportCsv && csvExportModalOpen && (
          <ExportCsvModal
            query={api.useCreateCandidatesCsvExportMutation as any}
            queryParams={{
              columns: currentViewColumns,
              ...(allSelected ? { query } : { customer_ids: selectedIds })
            }}
            setCsvExportModalOpen={setCsvExportModalOpen}
            pluralizedCount={pluralize('candidate', selectedCount, true)}
          />
        )}
      </RestrictedButton>
    </div>
  );
};
