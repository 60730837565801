import cn from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import ReactSkeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { Text } from '@components/common';

interface DashboardHeaderProps {
  h1?: string;
  study?: boolean;
  breadcrumb?: [string, string];
  renderHeaderButtons?: () => React.ReactNode;
  sticky?: boolean;
  withTabs?: boolean;
  addCandidatesModal?: boolean;
  headerWrapperClass?: string;
  headerButtonsClass?: string;
}
export const DashboardHeaderSkeleton: React.FC = ({}) => (
  <div className='px-page pt-gutter pb-4 bg-white border-b border-gray-200'>
    <div className='flex pb-2'>
      <ReactSkeleton duration={1} width={500} className='bg-gray-50 h-10 rounded-md' />
    </div>
  </div>
);

export const DashboardBreadcrumb: React.FC<{ to: string }> = ({ to, children }) => (
  <nav className='flex items-center mb-3 text-sm leading-5 text-gray-500'>
    <Link className='gray-500' to={to}>
      <Text h='400' color='gray-500'>
        {children}
      </Text>
    </Link>
    <span className='pl-2'>/</span>
  </nav>
);

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  h1,
  study,
  breadcrumb,
  renderHeaderButtons,
  sticky = false,
  children,
  withTabs,
  addCandidatesModal,
  headerWrapperClass,
  headerButtonsClass
}) => {
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const wrapper = document.getElementById('app-main');

    if (!wrapper || !sticky) return;

    const handler = () => {
      setShrunk((isShrunk) => {
        if (!isShrunk && wrapper.scrollTop > 20) {
          return true;
        }

        if (isShrunk && wrapper.scrollTop < 4) {
          return false;
        }

        return isShrunk;
      });
    };

    wrapper.addEventListener('scroll', handler);
    return () => wrapper.removeEventListener('scroll', handler);
  }, []);

  return (
    <div
      className={cn('px-page bg-white border-b border-gray-200', {
        'sticky top-0 z-30': sticky,
        'pt-8': withTabs && !addCandidatesModal && !study,
        'py-8': !withTabs && !study,
        'pb-4': study
      })}
    >
      {breadcrumb && <DashboardBreadcrumb to={breadcrumb[1]}> {breadcrumb[0]} </DashboardBreadcrumb>}
      <div
        className={cn(
          'flex items-center mb-2 transition-height transition-padding duration-300 ease-in-out',
          headerWrapperClass,
          {
            'max-h-0 overflow-hidden pb-0': isShrunk,
            'max-h-128': sticky && !isShrunk
          }
        )}
      >
        {study ? <h2 className='h700 leading-relaxed'>{h1}</h2> : <h1 className='h800 leading-relaxed'>{h1}</h1>}
        {renderHeaderButtons && (
          <div className={cn('flex flex-grow space-x-4 justify-end items-center', headerButtonsClass)}>
            {renderHeaderButtons()}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export const DashboardHeaderShelf: React.FC = ({ children }) => (
  <div className='px-page py-4 bg-white border-b border-gray-200'>{children}</div>
);

export const DashboardLayout: React.FC = ({ children }) => (
  <div className='flex flex-col h-full mx-auto'>{children}</div>
);

export const DashboardLayoutBody: React.FC<{ className?: string }> = ({ className = '', children }) => (
  <div className={'relative px-page py-gutter ' + className}>{children}</div>
);
