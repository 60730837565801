import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { FigmaPrototypeTypes } from '@components/common/FigmaPrototype';

import * as Types from '../types';

interface Args {
  onEvent?: (events: Types.PrototypeTestEvent[]) => void;
  debounceDelay?: number;
}

interface Result {
  events: Types.PrototypeTestEvent[];
  onMousePress: FigmaPrototypeTypes.IframeProps['onPrototypeMousePress'];
  onNodeChange: FigmaPrototypeTypes.IframeProps['onPrototypePresentedNodeChanged'];
}

export const usePrototypeTaskTracking = (args: Args = {}): Result => {
  const { debounceDelay = 500, onEvent } = args;

  const [events, setEvents] = useState<Types.PrototypeTestEvent[]>([]);
  const cache = useRef<Types.PrototypeTestEvent[]>([]);

  const send = () => {
    onEvent?.(cache.current);
    setEvents(cache.current);
  };

  const { callback: debouncedSend } = useDebouncedCallback(send, debounceDelay);

  const onMousePress: Result['onMousePress'] = (data) => {
    if (data.targetNodeId) {
      cache.current.push({ at: new Date(), external_element_id: data.targetNodeId, event: 'click', raw_data: data });

      debouncedSend();
    }
  };

  const onNodeChange: Result['onNodeChange'] = (data) => {
    cache.current.push({ at: new Date(), external_element_id: data.presentedNodeId, event: 'load', raw_data: data });

    debouncedSend();
  };

  useEffect(() => {
    cache.current.push({ at: new Date(), external_element_id: '', event: 'mount', raw_data: {} });

    debouncedSend();
  }, []);

  return { events, onMousePress, onNodeChange };
};
