import React from 'react';

import { Loading } from '@components/common';
import { useStripe } from '@stripe/react-stripe-js';

export const StripeProvider: React.FC<{ children: (props: { stripe: any }) => React.ReactElement }> = ({
  children
}) => {
  const stripe = useStripe();
  if (!stripe) {
    return <Loading />;
  }
  return children({ stripe });
};
