import { useCallback, useEffect, useState } from 'react';

type Hook<T> = [T | null, (value: T | null) => void];

export const useLocalStorage = <T = any>(key: string | null): Hook<T> => {
  const getItem = useCallback((): T | null => {
    if (!key) {
      return null;
    }
    let item: T | null = null;
    try {
      const str = window.localStorage.getItem(key) || 'null';
      item = JSON.parse(str);
    } catch (err) {
      // ignore, localStorage returns errors when incognito,
      // or out of disk space.
    }
    return item;
  }, [key]);

  const [storedValue, setStoredValue] = useState<T | null>(getItem);

  useEffect(() => {
    setStoredValue(getItem());
  }, [getItem]);

  const setValue = useCallback(
    (value: T | null) => {
      setStoredValue(value);
      if (!key) {
        return null;
      }
      try {
        if (value === null) {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, JSON.stringify(value));
        }
      } catch (e) {
        // ignore, localStorage returns errors when incognito,
        // or out of disk space.
      }
    },
    [key]
  );

  return [storedValue, setValue];
};
