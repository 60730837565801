import { api } from '@api/reduxApi';
import { buildGuide } from 'api/builders';

const GuidesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGuides: builder.query<Guide[], void>({
      query: () => '/guides',
      transformResponse: (resp: any) => resp.data.map(buildGuide),
      providesTags: () => [{ type: 'Guide', id: 'LIST' }]
    }),
    getGuide: builder.query<Guide, string>({
      query: (token) => `/guides/${token}`,
      transformResponse: (resp: any) => buildGuide(resp.data),
      providesTags: (q, i, token) => [{ type: 'Guide', token }]
    }),
    deleteGuide: builder.mutation<void, string>({
      query: (token) => ({
        url: `/guides/${token}`,
        method: 'DELETE'
      }),
      invalidatesTags: () => [{ type: 'Guide', id: 'LIST' }]
    }),
    updateGuide: builder.mutation<Guide, Pick<Guide, 'token'> & Partial<Pick<Guide, 'title' | 'bg_color'>>>({
      query: ({ token, ...body }) => ({
        url: `/guides/${token}`,
        method: 'PUT',
        body: { guide: body }
      }),
      transformResponse: (resp: any) => buildGuide(resp.data),
      invalidatesTags: (q, i, { token }) => [{ type: 'Guide', token }]
    }),
    createGuide: builder.mutation<Guide, void>({
      query: () => ({
        url: `/guides`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildGuide(resp.data),
      invalidatesTags: () => [{ type: 'Guide', id: 'LIST' }]
    }),
    publishGuide: builder.mutation<Guide, string>({
      query: (token) => ({
        url: `/guides/${token}/publish`,
        method: 'POST'
      }),
      transformResponse: (resp: any) => buildGuide(resp.data),
      invalidatesTags: (q, i, token) => [{ type: 'Guide', token }]
    })
  })
});

export const {
  useGetGuidesQuery,
  useGetGuideQuery,
  useDeleteGuideMutation,
  useUpdateGuideMutation,
  useCreateGuideMutation,
  usePublishGuideMutation
} = GuidesApi;
