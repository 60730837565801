import React, { SVGProps } from 'react';

export const MultipleUsersSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.46875 9.84375C5.46875 10.2499 5.63008 10.6393 5.91724 10.9265C6.20441 11.2137 6.59389 11.375 7 11.375C7.40611 11.375 7.79559 11.2137 8.08276 10.9265C8.36992 10.6393 8.53125 10.2499 8.53125 9.84375C8.53125 9.43764 8.36992 9.04816 8.08276 8.76099C7.79559 8.47383 7.40611 8.3125 7 8.3125C6.59389 8.3125 6.20441 8.47383 5.91724 8.76099C5.63008 9.04816 5.46875 9.43764 5.46875 9.84375V9.84375Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.46401 13.5621C9.28555 13.0509 8.9525 12.6079 8.51101 12.2944C8.06953 11.9809 7.54147 11.8125 7.00001 11.8125C6.45855 11.8125 5.93049 11.9809 5.48901 12.2944C5.04752 12.6079 4.71448 13.0509 4.53601 13.5621'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.3703 1.96875C1.3703 2.16984 1.40991 2.36895 1.48686 2.55473C1.56381 2.74051 1.6766 2.90932 1.81879 3.05151C1.96098 3.1937 2.12979 3.30649 2.31557 3.38344C2.50135 3.46039 2.70046 3.5 2.90155 3.5C3.10264 3.5 3.30175 3.46039 3.48753 3.38344C3.67331 3.30649 3.84212 3.1937 3.98431 3.05151C4.1265 2.90932 4.23929 2.74051 4.31624 2.55473C4.39319 2.36895 4.4328 2.16984 4.4328 1.96875C4.4328 1.76766 4.39319 1.56855 4.31624 1.38277C4.23929 1.19699 4.1265 1.02818 3.98431 0.885993C3.84212 0.743803 3.67331 0.631012 3.48753 0.554059C3.30175 0.477107 3.10264 0.4375 2.90155 0.4375C2.70046 0.4375 2.50135 0.477107 2.31557 0.554059C2.12979 0.631012 1.96098 0.743803 1.81879 0.885993C1.6766 1.02818 1.56381 1.19699 1.48686 1.38277C1.40991 1.56855 1.3703 1.76766 1.3703 1.96875V1.96875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.36669 5.68798C5.18835 5.17656 4.85532 4.73331 4.41377 4.41966C3.97221 4.10601 3.44402 3.9375 2.9024 3.9375C2.36079 3.9375 1.83259 4.10601 1.39104 4.41966C0.949484 4.73331 0.616452 5.17656 0.43811 5.68798H5.36669Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.56726 1.96875C9.56726 2.37486 9.72859 2.76434 10.0158 3.05151C10.3029 3.33867 10.6924 3.5 11.0985 3.5C11.5046 3.5 11.8941 3.33867 12.1813 3.05151C12.4684 2.76434 12.6298 2.37486 12.6298 1.96875C12.6298 1.56264 12.4684 1.17316 12.1813 0.885993C11.8941 0.598828 11.5046 0.4375 11.0985 0.4375C10.6924 0.4375 10.3029 0.598828 10.0158 0.885993C9.72859 1.17316 9.56726 1.56264 9.56726 1.96875V1.96875Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.5626 5.68798C13.3842 5.17656 13.0512 4.73331 12.6096 4.41966C12.1681 4.10601 11.6399 3.9375 11.0983 3.9375C10.5566 3.9375 10.0285 4.10601 9.5869 4.41966C9.14535 4.73331 8.81231 5.17656 8.63397 5.68798H13.5626Z'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.1875 7.4375V9.625C2.1875 9.9731 2.32578 10.3069 2.57192 10.5531C2.81806 10.7992 3.1519 10.9375 3.5 10.9375H3.9375'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.8125 7.4375V9.625C11.8125 9.9731 11.6742 10.3069 11.4281 10.5531C11.1819 10.7992 10.8481 10.9375 10.5 10.9375H10.0625'
      stroke='currentColor'
      strokeWidth='0.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
