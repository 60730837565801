import classNames from 'classnames';
import * as React from 'react';

import { PlusSVG } from '@components/svgs';
import Tippy from '@tippyjs/react';

interface Props {
  onlyShowOnHover?: boolean;
  onClick: () => void;
  disabled?: boolean;
}
export const AddQuestionBar: React.FC<Props> = ({ onlyShowOnHover, disabled, onClick }) => (
  <button
    name='add'
    aria-label='Add question'
    className={classNames('group focus:outline-none flex w-full', {
      'opacity-0 hover:opacity-100': onlyShowOnHover,
      'cursor-not-allowed': disabled
    })}
    onClick={() => (disabled ? null : onClick())}
  >
    <div
      className={classNames('flex-1 h-4 border-b border-gray-200', { 'group-hover:border-indigo-600': !disabled })}
    ></div>
    <Tippy content='Add new question' disabled={disabled}>
      <div
        className={classNames(
          'flex items-center justify-center w-8 h-8 text-gray-700 bg-white border border-gray-200 rounded-full',
          { 'group-hover:text-indigo-600 hover:border-indigo-600': !disabled }
        )}
      >
        <PlusSVG className='current-color' />
      </div>
    </Tippy>
    <div
      className={classNames('flex-1 h-4 border-b border-gray-200', { 'group-hover:border-indigo-600': !disabled })}
    ></div>
  </button>
);
