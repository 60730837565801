import * as React from 'react';

export const HighlightsZDSIcon: React.FC = () => (
  <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M79.0663 79.2645H1.53845V1.43845H79.0663V79.2645Z' fill='#FFC32D' />
    <path d='M67.2446 34.8949H17.5236V36.6569H67.2446V34.8949Z' fill='white' />
    <path d='M67.2446 39.476H17.5236V41.2379H67.2446V39.476Z' fill='white' />
    <path d='M67.2446 44.0569H17.5236V45.8189H67.2446V44.0569Z' fill='white' />
    <path
      d='M14.5056 37.1839C15.1382 37.1839 15.651 36.6691 15.651 36.0341C15.651 35.399 15.1382 34.8842 14.5056 34.8842C13.873 34.8842 13.3601 35.399 13.3601 36.0341C13.3601 36.6691 13.873 37.1839 14.5056 37.1839Z'
      fill='white'
    />
    <path opacity='0.4' d='M67.2446 15.1555H17.5236V16.9174H67.2446V15.1555Z' fill='white' />
    <path opacity='0.4' d='M67.2446 19.7364H17.5236V21.4984H67.2446V19.7364Z' fill='white' />
    <path opacity='0.4' d='M67.2446 24.3175H17.5236V26.0794H67.2446V24.3175Z' fill='white' />
    <path
      opacity='0.4'
      d='M14.5056 17.2528C15.1382 17.2528 15.651 16.7379 15.651 16.1029C15.651 15.4678 15.1382 14.953 14.5056 14.953C13.873 14.953 13.3601 15.4678 13.3601 16.1029C13.3601 16.7379 13.873 17.2528 14.5056 17.2528Z'
      fill='white'
    />
    <path opacity='0.4' d='M67.2446 54.8261H17.5236V56.588H67.2446V54.8261Z' fill='white' />
    <path opacity='0.4' d='M67.2446 59.4071H17.5236V61.169H67.2446V59.4071Z' fill='white' />
    <path opacity='0.4' d='M67.2446 63.9881H17.5236V65.7501H67.2446V63.9881Z' fill='white' />
    <path
      opacity='0.4'
      d='M14.5056 56.9234C15.1382 56.9234 15.651 56.4086 15.651 55.7736C15.651 55.1385 15.1382 54.6237 14.5056 54.6237C13.873 54.6237 13.3601 55.1385 13.3601 55.7736C13.3601 56.4086 13.873 56.9234 14.5056 56.9234Z'
      fill='white'
    />
  </svg>
);
