import { api } from '@api/reduxApi';

const brandApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBrand: builder.query<Brand, { team_id?: string }>({
      query: ({ team_id }) => ({
        url: `/brand`,
        method: 'GET',
        params: { team_id }
      }),
      transformResponse: (resp: any) => resp.data.attributes,
      providesTags: ['Brand']
    }),
    deleteBrand: builder.mutation<void, { team_id?: string }>({
      query: ({ team_id }) => ({
        url: `/brand`,
        method: 'DELETE',
        params: { team_id }
      }),
      invalidatesTags: ['Brand']
    }),
    updateBrand: builder.mutation<void, { brand: Partial<Brand>; logo: File | undefined }>({
      query: ({ logo, brand }) => {
        const body = new FormData();
        [
          'text_color',
          'button_color',
          'text_color',
          'logo_alignment',
          'secondary_text_color',
          'button_text_color'
        ].forEach((key) => {
          if (brand[key]) body.append(`brand[${key}]`, brand[key] || '');
        });
        if (logo) {
          body.append('logo', logo);
        }
        if (brand.team_id) {
          body.append('team_id', brand.team_id.toString());
        }

        return {
          url: '/brand',
          method: 'PUT',
          body,
          formData: true
        };
      },
      invalidatesTags: ['Brand']
    })
  })
});

export const { useGetBrandQuery, useUpdateBrandMutation, useDeleteBrandMutation } = brandApi;
