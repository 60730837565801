import { TextCarousel } from 'components/ScreenerResults/ScreenerChart/components/TextCarousel';
import * as React from 'react';
import { useEffect, useState } from 'react';
// libs
import tinytime from 'tinytime';

import { useBrowseButtons } from './useBrowseButtons';
import { setHash } from '@components/utils';
import { ScreenerResponsesZDS } from '@components/ScreenerResults/components/ScreenerResponsesZDS';
import { Button } from '@components/common';
import { isDisplayable } from '@components/ScreenerResults/utils';
import { Card } from '@components/shared/Skeleton';
import { isAnswerOfFieldType } from '@helpers/isAnswerOfFieldType';
import { ProfileSlideout } from '@components/RepoSessionApp/ProfileSlideout';
import { ProfileContextProvider } from '@hooks/useProfileContext';
import { usePermission } from '@hooks/usePermission';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { ParticipationPill } from '@components/shared/ParticipationPill';
import { BulkActions } from '@components/StudiesApp/components/StudyPublished/components/BulkActions';
import { getParticipation } from '@api/queries';
import { SEND_INVITES, StudyAction } from '@components/StudiesApp/components/StudyPublished/components/ACTIONS';
import { EmailSlideOuts } from '@components/StudiesApp/components/StudyPublished/components/EmailSlideouts';
import { useToaster } from '@stores/toaster';
import { getProfileSlideOutSections } from '@components/StudiesApp/components/StudyPublished/components/ParticipantsTab/utils';

const dateTemplate = tinytime('{Do} {MM} {h}:{mm} {a}');

const dateOfBirth = tinytime('{DD} {MMMM} {YYYY}');

interface ScreenerAnswerProps {
  answer: ScreenerResponseAnswer;
}

export const ScreenerAnswer: React.FC<ScreenerAnswerProps> = ({ answer }) => {
  const noResponse = !answer;

  const renderAnswers = () => {
    if (isAnswerOfFieldType(answer, 'long_text')) {
      return <TextCarousel data={[answer.value]} total={1} />;
    }

    if (isAnswerOfFieldType(answer, 'date')) {
      return <span>{dateOfBirth.render(new Date(answer.value))}</span>;
    }

    if (isAnswerOfFieldType(answer, 'multiple_choice') && Array.isArray(answer.value)) {
      return (
        <ul>
          {answer.value.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      );
    }

    if (isAnswerOfFieldType(answer, 'prototype_test')) {
      if (answer.value) {
        return <span>{answer.value.completed ? 'Success' : 'Failed'}</span>;
      } else {
        return <span>No response</span>;
      }
    }

    return <span>{answer.value}</span>;
  };

  return (
    <div key={answer.label}>
      <span className='block pb-4 text-lg font-bold'>{answer.label}</span>
      {noResponse ? (
        <span>No response</span>
      ) : (
        <div className='bg-indigo-50 font-xl relative flex flex-col items-start justify-center w-full max-w-3xl p-4 text-gray-700 rounded-md'>
          {renderAnswers()}
        </div>
      )}
    </div>
  );
};

interface Props {
  withBorders?: boolean;
  responses?: ScreenerResponseResults['individual'];
  isLoading?: boolean;
  study: Study;
  setBackgroundTasks?: (backgroundTask: BackgroundTask) => void;
}

export const ScreenerResultsIndividual: React.FC<Props> = ({
  isLoading,
  withBorders,
  responses,
  study,
  setBackgroundTasks
}) => {
  const [fetchingParticipation, setFetchingParticipation] = useState(false);

  const canUpdate = usePermission<Study>('updateStudy')(study);

  const showToast = useToaster();

  const [mode, setMode] = useState<StudyAction | null>(null);

  const [participation, setParticipation] = useState<Participation>();

  const [profileSlideoutOpen, setProfileSlideoutOpen] = useState(false);

  const { create } = useCandidateAttrs();

  const hashToArray = window.location.hash.substring(1).split('/');

  const currentRespIndex = responses?.findIndex((r) => r.customer_id === parseInt(hashToArray[hashToArray.length - 1]));

  const { thing: response, renderBrowseButtons } = useBrowseButtons<ScreenerResponseResultsIndividual>({
    things: responses,
    initialIndex: currentRespIndex === -1 ? 0 : currentRespIndex,
    hashField: 'customer_id'
  });

  useEffect(() => {
    if (response && !window.location.hash) {
      setHash(`${response.customer_id}`);
    }
  }, [response]);

  useEffect(() => {
    if (response?.participation.id) {
      setFetchingParticipation(true);
      getParticipation(response?.participation.id)
        .then((res) => {
          setParticipation(res);
        })
        .catch(() => {
          showToast({
            heading: 'Something went wrong!',
            icon: 'error',
            text: `Something went wrong getting the participant data. Please try again later.`
          });
        })
        .finally(() => {
          setFetchingParticipation(false);
        });
    }
  }, [response?.participation.id]);

  const renderProfileSlideoutHeader = () => (
    <div className='flex items-center space-x-2'>
      <span className='h600 block'>{study.title}</span>
      {participation && <ParticipationPill participation={participation} />}
    </div>
  );

  const renderProfileSlideoutActions = (activeParticipation: Participation) => () =>
    canUpdate &&
    participation && (
      <BulkActions
        onSlideoutActions
        closeAll={() => {
          setMode(null);
          setProfileSlideoutOpen(false);
        }}
        selectedIds={[activeParticipation.id]}
        study={study}
        currentStatus={participation.status}
        onSuccess={(backgroundTask) => {
          if (backgroundTask) {
            setBackgroundTasks?.(backgroundTask);
          }
          setMode(null);
          setProfileSlideoutOpen(false);
        }}
        mode={mode}
        closeProfileSlideout={() => setProfileSlideoutOpen(false)}
        setMode={setMode}
        candidateId={activeParticipation.customer_id}
      />
    );

  if (isLoading || fetchingParticipation)
    return (
      <div aria-label='Loader' className='h-full'>
        <Card height='100%' />
      </div>
    );

  if (!response) {
    return <ScreenerResponsesZDS tab='individual' withBorders={withBorders} />;
  }

  if (!participation) return null;

  const submittedAtStr = response.answers_json[0]?.submitted_at;

  const submittedAt = submittedAtStr && dateTemplate.render(new Date(submittedAtStr));

  return (
    <div className='p-6 bg-white rounded-md'>
      <div className='pb-8'>
        <div className='mb-4'>{renderBrowseButtons()}</div>
        <div className='flex items-center'>
          <div className='flex-grow'>
            <button
              className='hover:text-indigo-600 text-2xl cursor-pointer'
              onClick={() => {
                setProfileSlideoutOpen(true);
                setHash(`candidates/${participation?.customer_id}`);
              }}
            >
              {participation?.name}
            </button>
          </div>
          <div className='flex space-x-4'>
            <Button
              data-testid='view-profile'
              onClick={() => {
                setProfileSlideoutOpen(true);
                setHash(`candidates/${participation?.customer_id}`);
              }}
              link
            >
              View profile
            </Button>
            {['shortlisted', 'started', 'fresh'].includes(participation.status) && (
              <Button data-testid='send-invite' onClick={() => setMode(SEND_INVITES)}>
                Send invite…
              </Button>
            )}
          </div>
        </div>
        {submittedAt && <span className='block pt-2 pb-4 text-sm text-gray-500'>Submitted {submittedAt}</span>}
      </div>
      <div className='pb-8 space-y-8'>
        {response.answers_json
          .filter(({ field_type }) => isDisplayable(field_type))
          .map((answer, idx) => (
            <ScreenerAnswer key={idx} answer={answer} />
          ))}
      </div>

      {profileSlideoutOpen && participation?.customer && (
        <ProfileContextProvider>
          <ProfileSlideout
            canUpdate={canUpdate}
            createAttr={create}
            header={renderProfileSlideoutHeader()}
            candidate={participation.customer}
            open={profileSlideoutOpen}
            activeParticipation={participation}
            onClose={() => setProfileSlideoutOpen(false)}
            renderActions={renderProfileSlideoutActions(participation)}
            showSections={getProfileSlideOutSections({ study, activeParticipation: participation })}
          />
        </ProfileContextProvider>
      )}

      <EmailSlideOuts
        study={study}
        participations={[participation]}
        onClose={() => setMode(null)}
        mode={mode}
        onSuccess={(backgroundTask) => {
          if (backgroundTask) {
            setBackgroundTasks?.(backgroundTask);
          }
          setMode(null);
        }}
      />
    </div>
  );
};
