import * as React from 'react';

import { Button } from '@components/common';
import { Basic } from '@components/shared/Skeleton';
import { useAccount } from '@hooks/useAccount';
import { useTeams } from '@hooks/useTeams';

interface Props {
  className?: string;
  edit?: boolean;
  study: Study;
}

export const BrandLogo: React.FC<Props> = ({ study, className = '', edit = false }) => {
  const {
    account: { logo, id }
  } = useAccount();

  const { findTeam, isLoading } = useTeams();

  if (study.team_id) {
    if (isLoading) {
      return <Basic className={className} width={90} height={40} />;
    }

    const team = findTeam(study.team_id);

    if (team?.brand?.logo) {
      return <img className={className} src={team.brand.logo} alt='company logo' data-testid='account-logo' />;
    }
  }

  if (!logo) {
    return edit ? (
      <Button icon='plus' href={`/accounts/${id}/edit`} className='text-sm' link>
        Add your logo
      </Button>
    ) : null;
  }
  return <img className={className} src={logo} alt='company logo' data-testid='account-logo' />;
};
