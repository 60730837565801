import React, { ChangeEvent, PropsWithChildren, forwardRef, useRef, useState } from 'react';

import { UploadFileModal, Props as UploadFileModalProps } from '@components/shared/UploadFileModal';
import { composeEventHandlers } from '@helpers/composeEventHandlers';

import { Button, ButtonProps } from '../Button';

interface Props extends ButtonProps {
  supportedFileTypes?: string[];
  hasFileUploadZone?: boolean;
  uploadZoneProps?: Omit<UploadFileModalProps, 'open' | 'onClose' | 'supportedFileTypes' | 'onDrop'>;
  onUploadFiles: (files: File[]) => void;
}

export const UploadButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    { children, supportedFileTypes = [], uploadZoneProps = {}, hasFileUploadZone, onUploadFiles, onClick, ...rest },
    ref
  ) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [fileUploadZoneOpen, setFileUploadZoneOpen] = useState(false);

    const handleOnButtonClick = () => {
      if (hasFileUploadZone) {
        setFileUploadZoneOpen(true);
      } else if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const handleOnInputFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files;

      if (files && files.length > 0) {
        onUploadFiles(Array.from(files));
      }
    };

    const handleFileZoneUploadFiles = (files: File[]) => {
      onUploadFiles(files);
      setFileUploadZoneOpen(false);
    };

    return (
      <>
        <Button ref={ref} {...rest} onClick={composeEventHandlers(handleOnButtonClick, onClick)}>
          {children}
        </Button>

        <>
          {hasFileUploadZone ? (
            <UploadFileModal
              {...uploadZoneProps}
              supportedFileTypes={supportedFileTypes}
              open={fileUploadZoneOpen}
              onClose={() => setFileUploadZoneOpen(false)}
              onDrop={handleFileZoneUploadFiles}
            />
          ) : (
            <input
              ref={fileInputRef}
              className='hidden'
              type='file'
              data-testid='file-upload-input'
              accept={supportedFileTypes.join(',')}
              onChange={handleOnInputFileChange}
              multiple
            />
          )}
        </>
      </>
    );
  }
);
