import React, { SVGProps } from 'react';

export const PlayerPlayNoCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M3.92188 5.06878V26.9313C3.92185 27.2555 4.00586 27.5741 4.16573 27.8561C4.3256 28.1381 4.55585 28.3739 4.83402 28.5403C5.11218 28.7068 5.42876 28.7983 5.75284 28.8059C6.07693 28.8135 6.39745 28.737 6.68312 28.5838L27.0869 17.6525C27.3858 17.4923 27.6356 17.254 27.8098 16.963C27.984 16.672 28.0759 16.3392 28.0759 16C28.0759 15.6609 27.984 15.3281 27.8098 15.0371C27.6356 14.7461 27.3858 14.5078 27.0869 14.3475L6.68312 3.41628C6.39745 3.26305 6.07693 3.18651 5.75284 3.19412C5.42876 3.20173 5.11218 3.29324 4.83402 3.45971C4.55585 3.62618 4.3256 3.86193 4.16573 4.14394C4.00586 4.42596 3.92185 4.7446 3.92188 5.06878V5.06878Z'
      stroke='currentColor'
      strokeWidth='1.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
