import { CandidateSlideOut } from '@components/CandidatesApp/CandidatesIndex/types';
import { StudySlideOut } from '@components/StudiesApp/types';
import * as React from 'react';
import { useState } from 'react';
import { useAccount } from '@hooks/useAccount';
import { GenerateCandidatesModal } from '@components/Sample/GenerateCandidatesModal';
import { PopperDropdownLink, PopperDropdown, PopperDropdownButton } from '@components/common';
import { AddPeopleSVG, ExternalCandidatesSVG, ImportSVG, LinkSVG } from '@components/svgs';
import { RestrictedButton } from '@components/shared/RestrictedButton';
import { useDisabledFeatures } from '@hooks/useDisabledFeatures';
import { useGetEnabledIntegrationsQuery } from './api';
import { useCreateIntegrationJobMutation } from '../../shared/IntegrationStatus/api';
import { humanize } from '@components/utils';

interface Props {
  teamId?: number | null;
  setSlideOut: (slideOut: CandidateSlideOut | StudySlideOut) => void;
  component: string;
  openPanelStudyModal?: () => void;
}

export const AddCustomers: React.FC<Props> = ({ teamId, component, setSlideOut, openPanelStudyModal }) => {
  const [showSample, setShowSample] = useState<boolean>();
  const { data: enabledIntegrations } = useGetEnabledIntegrationsQuery();
  const [createJob] = useCreateIntegrationJobMutation();

  const {
    account: { sandbox }
  } = useAccount();

  const disabledFeatures = useDisabledFeatures();

  return (
    <>
      {showSample && <GenerateCandidatesModal onClose={() => setShowSample(false)} />}
      <PopperDropdown
        aria-label='Add Candidates'
        medium
        primary
        text='Add Candidates'
        popperProps={{ zIndex: 50, placement: 'bottom-start' }}
      >
        {sandbox && (
          <PopperDropdownButton
            trackEvent='clicked_add_candidates_dropdown'
            trackProps={{ page: 'candidates', component, link: 'Generate demo data' }}
            onClick={() => setShowSample(true)}
            className='xx-add-manual text-indigo-600'
          >
            <AddPeopleSVG className='inline mr-2' />
            Generate demo data
          </PopperDropdownButton>
        )}
        <PopperDropdownButton
          aria-label='Add manually'
          trackEvent='clicked_add_candidates_dropdown'
          trackProps={{ page: 'candidates', component, link: 'Add manually' }}
          onClick={() => {
            setSlideOut('AddCandidate');
          }}
          className='xx-add-manual'
        >
          <AddPeopleSVG className='inline mr-2' />
          Add manually
        </PopperDropdownButton>
        <PopperDropdownButton
          aria-label='Invite via link'
          trackEvent='clicked_add_candidates_dropdown'
          trackProps={{ page: 'candidates', component, link: 'Invite via link' }}
          onClick={() => {
            openPanelStudyModal?.();
          }}
        >
          <LinkSVG className='inline mr-2' />
          Invite via link
        </PopperDropdownButton>
        <PopperDropdownLink
          aria-label='Import from CSV'
          trackEvent='clicked_add_candidates_dropdown'
          trackProps={{ page: 'candidates', component, link: 'Import from CSV' }}
          to={`/customer_imports/new${teamId ? `?team_id=${teamId}` : ''}`}
        >
          <ImportSVG className='inline mr-2' />
          Import from CSV
        </PopperDropdownLink>
        {enabledIntegrations &&
          enabledIntegrations.map((integration) => (
            <PopperDropdownButton
              key={integration}
              aria-label={`Import from ${humanize(integration)}`}
              trackEvent='clicked_add_candidates_dropdown'
              trackProps={{ page: 'candidates', component, link: `Import from ${humanize(integration)}` }}
              onClick={() => createJob({ provider: integration, job: 'pull' })}
            >
              <ImportSVG className='inline mr-2' />
              Import from {humanize(integration)}
            </PopperDropdownButton>
          ))}
        {!disabledFeatures.external_recruitment && (
          <RestrictedButton limit='studies' permission='createStudy' action='New study'>
            <PopperDropdownButton
              aria-label='Recruit external candidates'
              trackEvent='clicked_add_candidates_dropdown'
              trackProps={{ page: 'candidates', component, link: 'Recruit external candidates' }}
              onClick={() => setSlideOut('NewRecruitingStudySlideout')}
            >
              <ExternalCandidatesSVG className='inline mr-2' />
              Recruit external candidates
            </PopperDropdownButton>
          </RestrictedButton>
        )}
      </PopperDropdown>
    </>
  );
};
