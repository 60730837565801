import React, { SVGProps } from 'react';

export const MoveToLeftSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.0625 6.99878H13.5625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.25 4.81128L3.0625 6.99878L5.25 9.18628'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M0.4375 0.436279V13.5613'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
