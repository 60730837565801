import React, { SVGProps } from 'react';

export const SignOutSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_1972_70452)'>
      <path
        d='M0.4375 7.00232H9.625'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.4375 9.18982L9.625 7.00232L7.4375 4.81482'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.92871 9.68332C2.53074 10.9201 3.53154 11.9182 4.76992 12.5169C6.0083 13.1156 7.41216 13.2801 8.75538 12.9838C10.0986 12.6874 11.303 11.9476 12.1745 10.8834C13.0461 9.81927 13.534 8.49272 13.5598 7.11745C13.5856 5.74217 13.1478 4.39825 12.3167 3.30214C11.4857 2.20604 10.3099 1.42156 8.97878 1.07507C7.64762 0.728586 6.23858 0.840256 4.97861 1.3921C3.71865 1.94394 2.68112 2.90383 2.03313 4.11715'
        stroke='currentColor'
        strokeWidth='0.875'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1972_70452'>
        <rect width='14' height='14' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
