import { Toast } from '@stores/toaster';

export const successUpdate = (): Toast => ({
  heading: 'Updated',
  text: 'We’ve updated the candidate.',
  icon: 'success'
});

export const failedUpdate = (): Toast => ({
  heading: 'Something went wrong',
  text: "We couldn't updated the candidate.",
  icon: 'error'
});
export const failedDelete = (): Toast => ({
  heading: 'Something went wrong',
  text: "We couldn't delete the candidate. Please try again later.",
  icon: 'error'
});

export const successShortlisted = ({ candidate, participation }): Toast => ({
  heading: `Successfully shortlisted!`,
  text: `${candidate.first_name} was shortlisted to ${participation.study.title}.`,
  icon: 'success',
  action: {
    text: 'View study',
    href: `/studies/${participation.study.id}#candidates/${candidate.id}`
  }
});
