import { SCHEDULING_INCREMENT } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';

export const getInitialDuration = (duration: number | null) => {
  if (!duration) return 30;

  if (duration % SCHEDULING_INCREMENT === 0) return duration;

  let currentDuration = duration + 1;

  while (currentDuration % SCHEDULING_INCREMENT !== 0) {
    currentDuration++;
  }

  return currentDuration;
};