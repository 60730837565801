import React, { SVGProps } from 'react';

export const ArrowRightSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g id='streamline-icon-arrow-left@14x14 (3) 1' clipPath='url(#clip0_3420_11003)'>
      <g id='Group'>
        <path
          id='Vector'
          d='M0.4375 7L13.5625 7'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M7.4375 13.125L13.5625 7L7.4375 0.875'
          stroke='currentColor'
          strokeWidth='0.875'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_3420_11003'>
        <rect width='14' height='14' fill='white' transform='matrix(-1 0 0 -1 14 14)' />
      </clipPath>
    </defs>
  </svg>
);
