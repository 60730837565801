import React, { useEffect } from 'react';

import { Alert } from '@components/common';

import { usePlans } from '../hooks/usePlans';
import { PlansHeading } from './PlansHeading';
import { PricingTierCard } from './PricingTierCard';
import { SchedToggle } from './SchedToggle';

useEffect;
const SCHED_MAPPING: Record<Plan['interval'], Sched> = {
  month: 'monthly',
  year: 'annual'
};

interface Props {
  currentPlan?: Pick<Plan, 'id' | 'name' | 'interval' | 'key'>;
  isSignedIn: boolean;
  isTrial?: boolean;
  trialEndsAt?: Date;
  sched: Sched;
  setSched: (s: Sched) => void;
}

const isEnterprise = (key: Plan['key'] | undefined): boolean => !!key && key.endsWith('enterprise');
const isRecommended = (key: Plan['key'] | undefined): boolean =>
  !!key && (key.endsWith('business') || key.endsWith('team'));

export const PricingTierCardsList: React.FC<Props> = ({
  currentPlan,
  isSignedIn,
  isTrial,
  trialEndsAt,
  sched,
  setSched
}) => {
  const plans = usePlans();

  const displayPlans: Plan[] = plans.filter((p) => SCHED_MAPPING[p.interval] === sched || p.key === 'free');

  const showToggle = !currentPlan || currentPlan.interval === 'month';

  const currentInTable =
    currentPlan && displayPlans.find((plan) => plan.id === currentPlan.id && currentPlan.key === plan.public_key);

  return (
    <div className='py-gutter px-page pb-24 mx-auto'>
      {!isSignedIn && <PlansHeading />}
      <div className='tablet:flex tablet:flex-col tablet:items-center flex flex-col mt-6'>
        {isSignedIn && (
          <>
            <h2 className='tablet:h900 h800 text-center'>Plans & Pricing</h2>
            <p className='mt-2 text-base text-center text-gray-500'>Choose a plan tailored to your needs</p>
          </>
        )}
        {showToggle && <SchedToggle sched={sched} setSched={setSched} />}
      </div>
      {isTrial && (
        <div className=' max-w-screen-xl mx-auto'>
          <Alert type='warning' border heading={`You are currently on a trial plan`} className='mt-6'>
            Your trial ends on <span className='font-bold'>{trialEndsAt?.toLocaleDateString()}</span>, at which point
            you will be automatically migrated to a free Personal plan.
          </Alert>
        </div>
      )}

      {!currentInTable && currentPlan && currentPlan.key !== 'free' && !isTrial && (
        <div className=' max-w-screen-xl mx-auto'>
          <Alert
            type='success'
            border
            heading={`You are currently subscribed to: ${currentPlan.name} (${currentPlan.interval}ly)`}
            className='mt-6'
          >
            {isEnterprise(currentPlan.key)
              ? 'You cannot manually change plans. Please contact your CSM for plan updates'
              : 'You can change it to any plan from the table below'}
          </Alert>
        </div>
      )}
      <div className='tablet:flex-row flex flex-col max-w-screen-xl mx-auto mt-2 overflow-x-auto'>
        {displayPlans.map((plan, i) => {
          let display_features = plan.display_features;
          if (i !== 0) {
            display_features = plan.display_features.filter((f) => !displayPlans[i - 1].display_features.includes(f));
          }
          return (
            <PricingTierCard
              previousTierName={displayPlans[i - 1]?.name}
              key={[plan.public_key, i].join('-')}
              plan={{ ...plan, display_features }}
              isSignedIn={isSignedIn}
              canBuy={!currentPlan || !isEnterprise(currentPlan.key)}
              canTrial={(!currentPlan || currentPlan.key === 'free') && !trialEndsAt}
              isCurrent={
                (currentPlan?.id === plan.id && currentPlan?.key == plan.public_key) ||
                (isEnterprise(currentPlan?.key) && isEnterprise(plan.public_key))
              }
              recommended={
                (!currentPlan || ['free', 'trial'].includes(currentPlan.key)) && isRecommended(plan.public_key)
              }
            />
          );
        })}
      </div>
    </div>
  );
};
