import React from 'react';
import ReactDatePicker from 'react-datepicker';

import { Text } from '@components/common';

export const DateRange = ({ value, onChange }) => {
  const [min, max] = value || [];
  const changeMin = (newMin) => onChange([newMin, max]);
  const changeMax = (newMax) => onChange([min, newMax]);

  return (
    <div>
      <ReactDatePicker
        wrapperClassName='w-full'
        selected={min ? new Date(min) : null}
        onChange={changeMin}
        placeholderText='Enter…'
        className='w-full text-left border border-gray-300 rounded-md'
      />
      <Text h='400' color='gray-500' className='py-4'>
        and
      </Text>
      <ReactDatePicker
        wrapperClassName='w-full'
        selected={max ? new Date(max) : null}
        onChange={changeMax}
        placeholderText='Enter…'
        className='w-full text-left border border-gray-300 rounded-md'
      />
    </div>
  );
};
