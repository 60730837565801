import * as React from 'react';
import { useState } from 'react';

import { PlansProvider } from '@components/PlansApp/components/PlansProvider';
import { PricingTierCardsList } from '@components/PlansApp/components/PricingTierCardsList';
import { renderComp } from '@components/utils';

interface Props {
  plans: [string, number, Plan['interval']][];
}

export const StaticPricingPage: React.FC<Props> = () => {
  const [sched, setSched] = useState<Sched>('monthly');

  return (
    <PlansProvider>
      <PricingTierCardsList sched={sched} setSched={setSched} isSignedIn={false} />
    </PlansProvider>
  );
};

export const mount = (): void => {
  renderComp('static-pricing-page', StaticPricingPage);
};
