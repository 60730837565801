import React from 'react';
import { Column } from 'react-table';

import { compact } from '@components/utils';

import { Candidate } from './cells/Candidate/Candidate';
import { Date as DateCell } from './cells/Date';
import { Extra } from './cells/Extra';
import { Name } from './cells/Name';
import { Status } from './cells/Status';
import { Study } from './cells/Study';
import { Tags } from './cells/Tags';
import { Thumbnail } from './cells/Thumbnail';

type Options = {
  showDate?: boolean;
  studyId?: number;
};
export const buildColumns = (opts?: Options): Column<UploadManagerItem>[] =>
  compact([
    {
      id: 'thumbnail',
      Cell: Thumbnail
    },
    {
      id: 'name',
      Cell: Name,
      width: 160
    },
    {
      id: 'status',
      accessor: 'status',
      Cell: Status,
      width: 120
    },
    opts?.showDate
      ? {
          id: 'date',
          Cell: DateCell
        }
      : false,
    {
      id: 'participant',
      Cell: (props) => <Candidate studyId={opts?.studyId} {...props} />,
      width: 224
    },
    {
      id: 'study',
      Cell: (props) => <Study studyId={opts?.studyId} {...props} />,
      width: 240
    },
    { id: 'tags', Cell: Tags, width: 256 },
    {
      id: 'extra',
      Header: () => null,
      Cell: Extra
    }
  ]);
