import { api } from '@api/reduxApi';

import { SamlAuthProvider } from './types';

type SamlProviderResponse = Pick<SamlAuthProvider, 'idp_sso_target_url'>;

const samlAuthApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProvider: builder.query<SamlProviderResponse, void>({
      query: () => `/saml_auth/provider`,
      providesTags: (q, i, d) => [{ type: 'SamlProvider' }]
    }),
    setProvider: builder.mutation<SamlProviderResponse, SamlAuthProvider>({
      query: (provider) => ({
        method: 'POST',
        url: `/saml_auth/provider`,
        body: { provider }
      }),
      invalidatesTags: (q, i, d) => [{ type: 'SamlProvider' }]
    })
  })
});

export const { useGetProviderQuery, useSetProviderMutation } = samlAuthApi;
