import cn from 'classnames';
import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { Tooltip } from '@components/shared/Tooltip';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  tooltip?: string;
  isDisabled?: boolean;
}

export const MenuBarButton = forwardRef<HTMLButtonElement, Props>(
  ({ isActive, children, isDisabled, tooltip = '', className, ...rest }, ref) => {
    return (
      <Tooltip content={tooltip}>
        <button
          aria-disabled={isDisabled}
          ref={ref}
          className={cn(
            'focus:outline-none flex items-center justify-center rounded-md',
            { 'bg-gray-100': isActive },
            { 'opacity-50 cursor-not-allowed': isDisabled },
            { 'hover:bg-gray-100': !isDisabled },
            className
          )}
          disabled={isDisabled}
          {...rest}
        >
          {children}
        </button>
      </Tooltip>
    );
  }
);
