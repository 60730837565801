import { humanize } from '@components/utils';

const FULL_STATUSES = {
  default: {
    fresh: 'new to study',
    invited: 'sent screener',
    rejected: 'removed'
  },
  video_call: {
    booked: 'interview booked',
    requested: 'invited to interview'
  },
  online_task: {
    requested: 'invited',
    started: 'started task',
    completed: 'completed task'
  },
  unmoderated_test: {
    requested: 'invited',
    started: 'started test',
    completed: 'completed test'
  },
  survey: {
    requested: 'invited',
    started: 'started survey',
    completed: 'completed survey'
  }
};

export default function participationStatus(status, style = 'video_call') {
  const val =
    {
      ...FULL_STATUSES.default,
      ...(FULL_STATUSES[style] || {})
    }[status] || status;
  return humanize(val);
}
