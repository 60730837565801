import { formatBarLabels } from 'components/ScreenerResults/ScreenerChart/helpers';
import * as React from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLabel } from 'victory';

const VERTICAL_BAR_STYLE = {
  data: {
    fill: '#E5EDFF',
    width: 32
  },
  labels: {
    fontSize: 12,
    fontFamily: 'inherit'
  }
};

const VERTICAL_BAR_HEIGHT = 200;

const VERTICAL_AXIS_STYLE = { axis: { stroke: 'none' }, axisLabel: { fontSize: 14, fontFamily: 'inherit' } };

const VERTICAL_BAR_PADDINGS = { bottom: 0, left: 0, right: 0, top: 0 };

interface Props {
  data: any[];
  total: number;
}
export const VerticalBarChart: React.FC<Props> = ({ data, total }) => {
  // work out what this formula should be
  const width = data.length * 32 + (data.length - 1) * 16;

  return (
    <div>
      <VictoryChart
        domainPadding={{ x: 16, y: [0, 0] }}
        height={VERTICAL_BAR_HEIGHT}
        width={width}
        padding={{ bottom: 50 }}
        style={{ parent: { width: width } }}
      >
        <VictoryBar
          animate
          barRatio={0.5}
          barWidth={32}
          cornerRadius={8}
          style={VERTICAL_BAR_STYLE}
          data={data}
          labels={({ datum }) => formatBarLabels(datum, total)}
          labelComponent={<VictoryLabel verticalAnchor={'start'} y={VERTICAL_BAR_HEIGHT - 60} />}
          padding={VERTICAL_BAR_PADDINGS}
        />

        <VictoryAxis
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          style={VERTICAL_AXIS_STYLE}
          tickFormat={data.map(({ x }) => x)}
        />
      </VictoryChart>
    </div>
  );
};
