import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { EmailSVG, HashtagSVG, LinkSVG } from '@components/svgs';

import { InputProps } from '../types';
import { RULES } from '../SurveyQuestion';

export const ShortTextInput: React.FC<InputProps> = ({
  field: { id, required },
  placeholder = 'placeholder_enter_answer',
  register,
  onFocus,
  autofocus,
  disabled,
  error,
  ariaLabel,
  rules
}) => {
  const { t } = useTranslation('SurveyQuestion');
  const inputClass = cn('focus-border-custom-brand form-input py-3 block w-full', {
    'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red': error,
    'bg-gray-50': disabled
  });

  return (
    <div className='relative w-full rounded-md shadow-sm'>
      <input
        type='text'
        className={inputClass}
        autoFocus={autofocus}
        disabled={disabled}
        onFocus={onFocus}
        placeholder={t(placeholder)}
        name={String(id)}
        id={String(id)}
        ref={register({ required, ...rules })}
        aria-label={ariaLabel}
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={`description-for-${id}`}
      />
    </div>
  );
};

type InputWithIconProps = InputProps & {
  icon: React.FC<any>;
  type: string;
};

export const InputWithIcon: React.FC<InputWithIconProps> = ({
  field: { id, required },
  rules,
  placeholder,
  type,
  readOnly = false,
  disabled = false,
  onFocus,
  register,
  icon: Icon,
  ariaLabel,
  error,
  autofocus
}) => {
  const { t } = useTranslation('SurveyQuestion');

  const inputClass = cn(
    'focus-border-custom-brand tablet:text-sm block w-full pl-12 border-gray-300 rounded-md shadow-sm',
    {
      'bg-gray-50': disabled || readOnly,
      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red': error
    }
  );

  return (
    <div className='relative flex items-center mt-1'>
      <Icon className='left-5 absolute' />
      <input
        id={String(id)}
        className={inputClass}
        placeholder={placeholder}
        type={type}
        readOnly={readOnly}
        disabled={disabled}
        name={String(id)}
        onFocus={onFocus}
        autoFocus={autofocus}
        ref={register({ required, ...rules })}
        aria-label={ariaLabel}
        aria-invalid={error ? 'true' : 'false'}
        aria-describedby={`description-for-${id}`}
        aria-required={required ? 'true' : 'false'}
      />
    </div>
  );
};

export const NumberInput: React.FC<InputProps> = (props) => (
  <InputWithIcon icon={HashtagSVG} type='number' {...props} />
);

export const EmailInput: React.FC<InputProps> = (props) => (
  <InputWithIcon icon={EmailSVG} rules={{ ...RULES.email, required: props.field.required }} type='email' {...props} />
);

export const WebsiteInput: React.FC<InputProps> = (props) => <InputWithIcon icon={LinkSVG} type='text' {...props} />;
