import * as React from 'react';
import { VictoryAxis, VictoryChart, VictoryLegend, VictoryPie } from 'victory';

import { colors } from '@components/ScreenerResults/ScreenerChart/constatnts';

import { ChartDataPoint } from '../../types';
import { CustomLabel } from '../shared';

const PIE_LEGEND_STYLE = { labels: { fontSize: 14, fontFamily: 'inherit' } };

const PIE_AXIS_STYLE = {
  axis: { stroke: 'transparent' },
  ticks: { stroke: 'transparent' },
  tickLabels: { fill: 'transparent' }
};

const PIE_PADDINGS = { left: 0, top: 0, bottom: 0 };

const PIE_STYLE = {
  labels: { fontSize: 14, fill: 'white', fontFamily: 'inherit' }
};

const BAR_ORIGIN = { x: 0, y: undefined };

export const PieChart: React.FC<{ data: ChartDataPoint[]; total: number }> = ({ data: rawData, total }) => {
  const height = 200;
  const width = 600;
  const data = rawData.sort(({ y: aY }, { y: bY }) => (aY > bY ? -1 : 1));

  return (
    <VictoryChart
      padding={{ left: 0, top: 0, bottom: 0, right: width - 200 }}
      domainPadding={0}
      height={height}
      width={width}
      style={{
        parent: { height, width }
        // axis: {stroke: "none"},
        // ticks: {stroke: "none"},
        // tickLabels: { fill:"none"}
      }}
    >
      <VictoryAxis style={PIE_AXIS_STYLE} />

      <VictoryPie
        origin={BAR_ORIGIN as any}
        data={data}
        labelRadius={50}
        width={200}
        labels={({ datum }) => {
          const percentage = Math.round((datum.y * 100) / total);
          return percentage > 3 ? percentage + '%' : '';
        }}
        style={PIE_STYLE}
        colorScale={colors}
        padding={PIE_PADDINGS}
      />

      <VictoryLegend
        x={280}
        y={20}
        data={data.map(({ x }, i) => ({ name: x, symbol: { fill: colors[i] } }))}
        style={PIE_LEGEND_STYLE}
        labelComponent={<CustomLabel />}
        centerTitle
      />
    </VictoryChart>
  );
};
