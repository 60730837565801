import { getOSShortcut, Keys } from 'helpers/getOSShortcut';
import { useEffect, useRef } from 'react';

interface Params {
  keys: Partial<Keys>;
  handler: () => void;
  disabled?: boolean;
  disableWhenInputFocused?: boolean;
}

interface Result {
  shortcut: string[];
}

const IS_MAC = window.navigator.userAgent.includes('Macintosh');

export const useKeyboardShortcut = ({
  keys: givenKeys,
  handler,
  disabled,
  disableWhenInputFocused = false
}: Params): Result => {
  const { shift, alt, meta, ...rest } = givenKeys;
  const keys = [...Object.keys(rest)];
  const pressedKeysRef = useRef<string[]>([]);

  const checkIfAllKeysPressed = () => keys.every((key) => pressedKeysRef.current.includes(key.toLowerCase()));

  const canRunHandler = (event: KeyboardEvent) => {
    if (!disableWhenInputFocused) {
      return true;
    }

    const target = event.target as HTMLElement | null;

    const isProsemirrorDoc = target?.closest('.ProseMirror') !== null;

    return !(['input', 'textarea'].includes(target?.tagName?.toLowerCase() || '') || isProsemirrorDoc);
  };

  const handleOnKeyDown = (event: KeyboardEvent) => {
    if (shift && !event.shiftKey) {
      return;
    } else if (alt && !event.altKey) {
      return;
    } else if (IS_MAC && meta && !event.metaKey) {
      return;
    } else if (!IS_MAC && meta && !event.ctrlKey) {
      return;
    } else {
      if (event.key) {
        pressedKeysRef.current.push(event.key.toLowerCase());
      }
      if (!checkIfAllKeysPressed()) return;

      if (!canRunHandler(event)) return;

      event.preventDefault();
      event.stopPropagation();
      handler();
    }
  };

  const handleOnKeyUp = () => {
    pressedKeysRef.current = [];
  };

  useEffect(() => {
    if (disabled) {
      document.removeEventListener('keydown', handleOnKeyDown);
      document.removeEventListener('keyup', handleOnKeyUp);
      return;
    }

    document.addEventListener('keydown', handleOnKeyDown);
    document.addEventListener('keyup', handleOnKeyUp);

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown);
      document.removeEventListener('keyup', handleOnKeyUp);
    };
  }, [keys, disabled]);

  return { shortcut: getOSShortcut(givenKeys) };
};
