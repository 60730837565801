import React, { forwardRef, HTMLProps, ReactElement } from 'react';
import cn from 'classnames';

type Props = HTMLProps<HTMLInputElement> & {
  start?: ReactElement | null;
  end?: ReactElement | null;
  wrapperClassName?: string;
};

export const MediaInput = forwardRef<HTMLInputElement, Props>(({ wrapperClassName, start, end, ...rest }, ref) => (
  <div
    className={cn(
      'focus-within:border-indigo-500 tablet:text-sm focus:ring-indigo-500 flex items-stretch  border border-gray-200 rounded-md',
      wrapperClassName
    )}
  >
    {start && <div>{start}</div>}
    <div className='flex-1 px-4'>
      <input ref={ref} className='h-10 w-full placeholder-gray-400 border-none outline-none' {...rest} />
    </div>
    {end && <div>{end}</div>}
  </div>
));
