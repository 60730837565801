import React, { HTMLAttributes, MutableRefObject, ReactNode, useEffect, useState } from 'react';

import { Chapter } from './components/Chapter';
import { Preview } from './components/Preview';
import { Thumb } from './components/Thumb';
import { Track } from './components/Track';
import { useGetImageFragment } from './hooks/useGetImageFragment';
import { useVideoSeekBarContext } from './hooks/useVideoSeekBarContext';

import * as Types from './types';

interface Props<M extends Record<string, unknown>> extends HTMLAttributes<HTMLDivElement> {
  bufferTime: number;
  chapters: Types.Chapter<M>[];
  currentTime: number;
  innerRef?: MutableRefObject<HTMLDivElement | null>;
  max: number;
  textTracks?: TextTrack[];
  onTimeChange?: (time: number) => void;
  renderPreview?: (metadata: M, time: number, imageFragment?: Types.ImageFragment) => ReactNode;
}

export const VideoSeekBar = <M extends Record<string, unknown>>({
  bufferTime,
  chapters: initialChapters,
  currentTime: initialTime,
  innerRef,
  max,
  textTracks,
  onTimeChange,
  renderPreview,
  ...rest
}: Props<M>) => {
  const [activeChapterIdx, setActiveChapterIdx] = useState<number>(0);

  const {
    state: { hoverTime },
    setBufferTime,
    setCurrentTime,
    setMax
  } = useVideoSeekBarContext();

  const imageFragment = useGetImageFragment(textTracks?.[0]);

  const chapters = initialChapters.length === 0 ? [{ from: 0, metadata: {} as M }] : initialChapters;

  const getChapterEndTime = (index: number) => {
    return index === chapters.length - 1 ? max : chapters[index + 1].from;
  };

  useEffect(() => {
    setBufferTime(bufferTime);
    setCurrentTime(initialTime);
    setMax(max);
  }, [bufferTime, initialTime, max]);

  return (
    <div ref={innerRef} {...rest}>
      <Track onTimeChange={onTimeChange}>
        {chapters.map((chapter, index) => (
          <Chapter
            key={chapter.from}
            startTime={chapter.from}
            endTime={getChapterEndTime(index)}
            onMouseEnter={() => setActiveChapterIdx(index)}
          />
        ))}

        <Thumb />
      </Track>

      {imageFragment && (
        <Preview imageFragment={imageFragment}>
          {renderPreview?.(chapters[activeChapterIdx].metadata, hoverTime, imageFragment)}
        </Preview>
      )}
    </div>
  );
};
