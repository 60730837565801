import React, { SVGProps } from 'react';

export const AdditionalOptionsSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <g clipPath='url(#clip0_1677_8896)'>
      <path
        d='M15.4531 16.1914C15.4531 16.5644 15.6013 16.9221 15.865 17.1858C16.1287 17.4495 16.4864 17.5977 16.8594 17.5977C17.2323 17.5977 17.59 17.4495 17.8537 17.1858C18.1175 16.9221 18.2656 16.5644 18.2656 16.1914C18.2656 15.8184 18.1175 15.4608 17.8537 15.197C17.59 14.9333 17.2323 14.7852 16.8594 14.7852C16.4864 14.7852 16.1287 14.9333 15.865 15.197C15.6013 15.4608 15.4531 15.8184 15.4531 16.1914Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0529 10.7475L18.4673 12.1087C18.5355 12.3348 18.687 12.5266 18.8911 12.6452C19.0952 12.7639 19.3368 12.8007 19.567 12.7481L20.9479 12.4275C21.2096 12.3685 21.4833 12.3949 21.7288 12.5028C21.9744 12.6107 22.1789 12.7945 22.3125 13.0271C22.446 13.2597 22.5016 13.529 22.4709 13.7954C22.4403 14.0619 22.3252 14.3115 22.1423 14.5078L21.1767 15.5494C21.0154 15.7228 20.9257 15.9509 20.9257 16.1878C20.9257 16.4247 21.0154 16.6528 21.1767 16.8262L22.1423 17.8678C22.3254 18.0639 22.4408 18.3136 22.4716 18.5801C22.5024 18.8466 22.4468 19.116 22.3132 19.3487C22.1796 19.5813 21.9749 19.765 21.7291 19.8727C21.4834 19.9804 21.2096 20.0065 20.9479 19.9472L19.567 19.6275C19.3369 19.5744 19.0952 19.6108 18.891 19.7294C18.6868 19.848 18.5353 20.0398 18.4673 20.2659L18.0529 21.6272C17.9756 21.8839 17.8177 22.109 17.6025 22.2689C17.3873 22.4289 17.1263 22.5153 16.8581 22.5153C16.59 22.5153 16.3289 22.4289 16.1137 22.2689C15.8985 22.109 15.7406 21.8839 15.6633 21.6272L15.2489 20.2659C15.1809 20.0398 15.0294 19.848 14.8252 19.7294C14.621 19.6108 14.3793 19.5744 14.1492 19.6275L12.7683 19.9472C12.5066 20.0065 12.2328 19.9804 11.9871 19.8727C11.7413 19.765 11.5366 19.5813 11.403 19.3487C11.2694 19.116 11.2138 18.8466 11.2446 18.5801C11.2754 18.3136 11.3908 18.0639 11.5739 17.8678L12.5395 16.8262C12.7008 16.6528 12.7905 16.4247 12.7905 16.1878C12.7905 15.9509 12.7008 15.7228 12.5395 15.5494L11.5739 14.5078C11.391 14.3115 11.2759 14.0619 11.2453 13.7954C11.2146 13.529 11.2702 13.2597 11.4037 13.0271C11.5373 12.7945 11.7418 12.6107 11.9874 12.5028C12.2329 12.3949 12.5066 12.3685 12.7683 12.4275L14.1492 12.7481C14.3794 12.8009 14.6211 12.7643 14.8253 12.6455C15.0295 12.5268 15.1809 12.3349 15.2489 12.1087L15.6633 10.7475C15.7406 10.4907 15.8985 10.2657 16.1137 10.1057C16.3289 9.94576 16.59 9.85938 16.8581 9.85938C17.1263 9.85938 17.3873 9.94576 17.6025 10.1057C17.8177 10.2657 17.9756 10.4907 18.0529 10.7475Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.15547 6.79297L6.15578 9.45734C6.09522 9.53788 6.01808 9.60449 5.92957 9.65265C5.84106 9.70082 5.74324 9.72942 5.64272 9.73653C5.5422 9.74364 5.44133 9.72909 5.34692 9.69387C5.25251 9.65864 5.16676 9.60356 5.09547 9.53234L4.01172 8.44766'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.15547 13.125L6.15578 15.7847C6.09522 15.8652 6.01808 15.9318 5.92957 15.98C5.84106 16.0282 5.74324 16.0568 5.64272 16.0639C5.5422 16.071 5.44133 16.0564 5.34692 16.0212C5.25251 15.986 5.16676 15.9309 5.09547 15.8597L4.01172 14.775'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5723 1.40625C12.7262 1.40622 12.8786 1.43651 13.0208 1.49538C13.163 1.55426 13.2922 1.64056 13.401 1.74938L17.9882 6.33656C18.097 6.4454 18.1833 6.57461 18.2422 6.71681C18.3011 6.85901 18.3314 7.01141 18.3313 7.16531'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.3828 7.21875H13.6953C13.3845 7.21875 13.0864 7.09529 12.8667 6.87552C12.6469 6.65575 12.5234 6.35768 12.5234 6.04688V1.35938'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.4551 22.3516H2.48633C2.11337 22.3516 1.75568 22.2034 1.49196 21.9397C1.22824 21.676 1.08008 21.3183 1.08008 20.9453V2.66406C1.08008 2.2911 1.22824 1.93342 1.49196 1.66969C1.75568 1.40597 2.11337 1.25781 2.48633 1.25781H12.4501'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1677_8896'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
