import * as React from 'react';

import { BookabilityRuleAlert } from './BookabilityRuleAlert';

export const NoScreenerQuestionsAlert: BookabilityRuleAlertComponent = ({ study, level, onClickCta }) => (
  <BookabilityRuleAlert
    level={level}
    heading='Screener has no questions'
    message='Please add screener questions for participants to complete.'
    ctas={[
      {
        children: 'Edit screener',
        href: `/studies/${study.id}/screener`,
        onClick: onClickCta
      }
    ]}
  />
);
