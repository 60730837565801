import React from 'react';
import { HexColorPicker } from 'react-colorful';

import { InputWithAddons, Popper } from '@components/common';
import { Text } from '@components/common';

interface Props {
  edit: boolean;
  color?: string;
  onChange: (v: string) => void;
  ariaLabel?: string;
}
export const BrandColor: React.FC<Props> = ({ edit, color = '', onChange, ariaLabel }) => {
  if (edit) {
    return (
      <div className='flex items-center space-x-2'>
        <InputWithAddons
          pl='12'
          width='full max-w-sm'
          prefix='Hex'
          ariaLabel={ariaLabel}
          suffix=''
          value={(color ?? '').toUpperCase()}
          onChange={(v) => onChange?.(v as string)}
        />
        <Popper
          placement='bottom'
          offset={[0, 8]}
          content={() => <HexColorPicker color={(color ?? '').toUpperCase()} onChange={onChange} />}
          closeOnEscape
          closeOnClickOutside
        >
          <div className='w-4 h-4 mt-1 border border-gray-200 cursor-pointer' style={{ backgroundColor: color }} />
        </Popper>
      </div>
    );
  }
  return (
    <div className='flex items-center my-3 space-x-2'>
      {color && <div className='w-4 h-4 border border-gray-200' style={{ backgroundColor: color }} />}
      <Text>{(color ?? 'not set').toUpperCase()}</Text>
    </div>
  );
};
