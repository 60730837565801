import * as React from 'react';

export const navExpand: React.FC = () => (
  <svg className='text-white' width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.9064 3.78125L21.9264 7.80125C21.9525 7.82734 21.9732 7.85832 21.9874 7.89242C22.0015 7.92652 22.0088 7.96308 22.0088 8C22.0088 8.03692 22.0015 8.07348 21.9874 8.10758C21.9732 8.14168 21.9525 8.17266 21.9264 8.19875L17.9064 12.2188'
      stroke='white'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></path>
    <path d='M14.5 3.99799L1.5 3.99799' stroke='white' strokeLinecap='round' strokeLinejoin='round'></path>
    <path d='M14.5 7.99799L1.5 7.99799' stroke='white' strokeLinecap='round' strokeLinejoin='round'></path>
    <path d='M14.5 11.998L1.5 11.998' stroke='white' strokeLinecap='round' strokeLinejoin='round'></path>
  </svg>
);
